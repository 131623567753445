import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { CustomerRequestRoutes } from '../../../../../routes/routes';

const CustomerRequestHeader: React.FC = () => {
    return (
        <div className="page-header pt-4 pb-md-3 mb-4">
            <Container className="py-3">
                <Row>
                    <Col xs="12" sm="6">
                        <h1>
                            <Trans i18nKey="customer-request.title">Žiadosti</Trans>
                        </h1>
                    </Col>

                    <Col xs="12" sm="6 text-right">
                        <Link to={CustomerRequestRoutes.SELECT_NEW} className="btn btn-primary btn-block btn-md-inline">
                            <small className="mr-2 button-icon">
                                <FontAwesomeIcon icon={faPlus} />
                            </small>
                            <Trans i18nKey="customer-request.new-request">Nová žiadosť</Trans>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CustomerRequestHeader;
