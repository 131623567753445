import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerrequestCustomerRequestSummary as PagedResponseCustomerRequestSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery as CustomerRequestSearchQuery,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary as CustomerRequest,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { CustomerRequestActions } from '../../../../actions/customer-request-actions';
import Checkbox from '../../../../components/common/checkbox';
import { ClickableElement } from '../../../../components/common/clickable-element';
import PageableTable from '../../../../components/common/table/pageable-table';
import TableFilterControl from '../../../../components/common/table/table-filter-control';
import { IColumnConfiguration } from '../../../../components/common/table/table-interfaces';
import { can } from '../../../../components/permissions';
import { useApi } from '../../../../hooks/use-api';
import useCodeList from '../../../../hooks/use-code-list';
import { useKeyValueStore } from '../../../../hooks/use-key-value';
import { usePaginatedQueryWithError } from '../../../../hooks/use-paginated-query-with-error';
import { useUrlQuery } from '../../../../hooks/use-url-query';
import { CustomerRequestStatusFilterEnum } from '../../../../models/enums';
import { IRootState } from '../../../../reducers';
import Routes from '../../../../routes/routes';
import { QueryKeysEnum } from '../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../utils/utils';
import { CustomerRequestsHeader } from '../../../delivery-points/detail/customer-requests/customer-requests-header';
import { TABSEnums } from '../../../delivery-points/detail/delivery-point-detail';
import { customerRequestColumnConfigurations } from '../../components/customer-request-column-configuration';
import { CodeListTypeEnum } from '../../config/enums';
import { isRequestStatusCancelled, isRequestStatusSent } from '../../customer-request-utils';
import CustomerRequestAddNewModal from '../../modal/customer-request-add-new-modal';
import CustomerRequestFilterModal from '../../modal/customer-request-filter-modal';
import { statusesOfAllRequests, statusesOfOpenRequest } from '../my-list-customer-request/my-list-customer-request';
import { CustomerRequestsFilterDescription } from './customer-requests-filter-description';

type Props = {
    unitedDeliveryPoint?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    getDeliveryPointSwitch: (additionalButton?: { label: React.ReactNode }) => React.ReactNode;
};

export interface IRequestsAdditionalFilter {
    openOnly: boolean;
}

export const DeliveryPointCustomerRequests: React.FC<Props> = ({ unitedDeliveryPoint, getDeliveryPointSwitch }) => {
    const pageSize = 10;

    const history = useHistory();
    const urlQuery = useUrlQuery();
    const [t] = useTranslation();
    const api = useApi();
    const dispatch = useDispatch();
    const params = useParams<{ unitedDeliveryPointId: string; deliveryPointId?: string; tab: TABSEnums }>();

    const [modal, setModal] = useState<boolean>(false);
    const [modalRequest, setModalRequest] = useState<boolean>(false);
    // const [type, setType] = useState<CommodityEnum>(CommodityEnum.GAS);
    const [filter, setFilter] = useKeyValueStore<Partial<CustomerRequestSearchQuery>>(`delivery-point-customer-table-filter`, {});
    const [onlyOpen, setOnlyOpen] = useKeyValueStore<boolean>(`delivery-point-customer-table-additional-filter`, false);
    const [columnConfigurations, setColumnConfigurations] = useState<IColumnConfiguration<CustomerRequest>[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [, setStoredPageIndex] = useKeyValueStore(Routes.INVOICES + '@pageIndex', 0);

    const { customer } = useSelector((state: IRootState) => state.user);
    const employee = useSelector((state: IRootState) => state.user.employee);

    const fetchOpenCustomerRequests = async () => {
        if (params.deliveryPointId == null && customer?.id != null) {
            return api.customers.customerSearchCustomerRequests(
                customer.id,
                { statuses: statusesOfOpenRequest, unitedDeliveryPointUuid: params.unitedDeliveryPointId },
                undefined,
                { secure: true },
            );
        }
        const foundDp = unitedDeliveryPoint?.deliveryPoints?.find((x) => x.id === params.deliveryPointId);
        const contractId = foundDp?.contract?.id;
        if (contractId != null) {
            return api.deliveryPoints.deliveryPointSearchCustomerRequests(
                contractId,
                {
                    statuses: statusesOfOpenRequest,
                    customerUuid: customer?.id,
                    unitedDeliveryPointUuid: params.unitedDeliveryPointId,
                    deliveryPointId: params.deliveryPointId,
                },
                { secure: true },
            );
        }
        return null;
    };

    const { data: statusCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CUSTOMER_REQUEST_STATUS,
        codeListTypeEnum: CodeListTypeEnum.CUSTOMER_REQUEST_STATUS,
        paging: {
            size: 20,
        },
    });

    const fetchCustomerRequests = async (query: CustomerRequestSearchQuery = {}) => {
        const status = query.statuses?.slice() || [];
        query.statuses?.forEach((item) => {
            if (isRequestStatusCancelled(item)) {
                status.push(CustomerRequestStatusFilterEnum.SAP_CANCELLED);
                status.push(CustomerRequestStatusFilterEnum.CANCELLED_BY_USER);
                status.push(CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER);
            }
            if (isRequestStatusSent(item)) {
                status.push(CustomerRequestStatusFilterEnum.CREATED);
                status.push(CustomerRequestStatusFilterEnum.REGISTERED);
                status.push(CustomerRequestStatusFilterEnum.SAP_OPEN);
            }
        });
        const uniqueStatus = [...new Set(status)];
        query.statuses = uniqueStatus;

        if (onlyOpen && isObjectEmpty(filter)) {
            query.statuses = statusesOfOpenRequest;
        }
        if (query.statuses == null || query.statuses.length === 0) {
            query.statuses = statusesOfAllRequests;
        }

        if (params.deliveryPointId == null && customer?.id != null) {
            const queryWithUdp = { ...query, unitedDeliveryPointUuid: params.unitedDeliveryPointId };
            return api.customers.customerSearchCustomerRequests(customer.id, queryWithUdp, undefined, { secure: true });
        }
        const foundDp = unitedDeliveryPoint?.deliveryPoints?.find((x) => x.id === params.deliveryPointId);
        const contractId = foundDp?.contract?.id;
        if (contractId != null) {
            return api.deliveryPoints.deliveryPointSearchCustomerRequests(
                contractId,
                {
                    ...query,
                    customerUuid: customer?.id,
                    unitedDeliveryPointUuid: params.unitedDeliveryPointId,
                    deliveryPointId: params.deliveryPointId,
                },
                { secure: true },
            );
        }
        return null;
    };

    const { resolvedData: customerRequestsData } = usePaginatedQueryWithError<PagedResponseCustomerRequestSummary | null>({
        queryKey: [QueryKeysEnum.DELIVERY_POINT_CUSTOMER_REQUESTS_SEARCH, filter, pageIndex, params.deliveryPointId, onlyOpen],
        queryFn: () => fetchCustomerRequests({ ...filter, paging: { page: pageIndex, size: pageSize } }).then((response) => response?.data || null),
        config: { enabled: employee ? can('ENTITY_REQUESTS_VIEW', employee) : true },
    });

    const { resolvedData: openCustomerRequests } = usePaginatedQueryWithError<PagedResponseCustomerRequestSummary | null>({
        queryKey: [QueryKeysEnum.DELIVERY_POINT_OPEN_REQUESTS_SEARCH, params.deliveryPointId],
        queryFn: () => fetchOpenCustomerRequests().then((response) => response?.data || null),
        config: { enabled: employee ? can('ENTITY_REQUESTS_VIEW', employee) : true },
    });

    useEffect(() => {
        dispatch(
            CustomerRequestActions.setAdditionalData({
                bpuuid: unitedDeliveryPoint?.businessPartner?.id,
                dpuuid: params.deliveryPointId,
                udpuid: params.unitedDeliveryPointId,
                udpd: unitedDeliveryPoint,
            }),
        );
    }, [unitedDeliveryPoint, params, dispatch]);

    useEffect(() => {
        setColumnConfigurations(customerRequestColumnConfigurations());
    }, [t]);

    const openRequestDetail = (requestId: string) => {
        history.push(`${Routes.DELIVERY_POINTS}/${params.unitedDeliveryPointId}/${params.tab}/${params.deliveryPointId || ''}/${requestId}`);
    };

    const onNewRequest = () => {
        setModalRequest(true);
    };

    const queriedPage = Number(urlQuery.get('page')) || undefined;

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    return (
        <>
            <CustomerRequestsHeader createNewRequest={onNewRequest} getDeliveryPointSwitch={getDeliveryPointSwitch} />

            <Container>
                <Row className="align-items-center">
                    <Col xs={6}>
                        {isObjectEmpty(filter) && (
                            <Checkbox
                                checked={onlyOpen}
                                onChange={() => setOnlyOpen(!onlyOpen)}
                                id="onlyOpen"
                                name="onlyOpen"
                                label={t('customer-request.only-open', { count: openCustomerRequests?.paging?.total })}
                            />
                        )}
                    </Col>
                    <Col xs={6}>
                        {isObjectEmpty(filter) && (
                            <div className="mb-3 float-right text-right">
                                <ClickableElement isText onClick={() => setModal(true)} className="text-decoration-underline-inverse">
                                    <Trans i18nKey="common.tables.show-filter" />
                                </ClickableElement>
                            </div>
                        )}
                    </Col>
                    {!isObjectEmpty(filter) && (
                        <Col xs={{ size: isObjectEmpty(filter) ? 6 : 12, offset: isObjectEmpty(filter) ? 6 : 0 }}>
                            <TableFilterControl
                                hideFilterButton
                                isFilterActive={!isObjectEmpty(filter)}
                                onEditFilter={() => setModal(true)}
                                onRemoveFilter={() => {
                                    setFilter({});
                                    onPageChange(0);
                                }}
                                filterDescription={
                                    <CustomerRequestsFilterDescription filter={filter} deliveryPoints={[]} statusCodeList={statusCodeList || []} />
                                }
                            />
                        </Col>
                    )}
                </Row>

                <PageableTable<CustomerRequest>
                    data={customerRequestsData?.result ? customerRequestsData.result : []}
                    columnConfigurations={columnConfigurations}
                    page={pageIndex}
                    pageSize={pageSize}
                    totalDataCount={customerRequestsData?.paging?.total}
                    onPageChange={onPageChange}
                    onRowSelect={(item) => openRequestDetail(item.uuid ?? '')}
                    collapseIntoCards
                    tableLayoutFixed
                />

                <CustomerRequestFilterModal
                    statusCodeList={statusCodeList ?? []}
                    isOpen={modal}
                    onCloseModal={() => setModal(false)}
                    filter={filter}
                    onFilterChange={(newFilter) => {
                        setFilter(newFilter);
                        onPageChange(0);
                    }}
                />

                <CustomerRequestAddNewModal
                    isOpen={modalRequest}
                    onCloseModal={() => setModalRequest(false)}
                    unitedDeliveryPoint={unitedDeliveryPoint}
                />
            </Container>
        </>
    );
};
