import React from 'react';
import { useTranslation } from 'react-i18next';
import { IInvoiceNumber } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: string | IInvoiceNumber[];
    bold?: boolean;
};

const InvoiceNumberItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.invoice-choice.invoice-number')}>
                    {Array.isArray(data) ? (
                        <>
                            {data.map((item, index) => (
                                <ItemPreviewContent key={index} bold={bold}>
                                    {item.number}
                                </ItemPreviewContent>
                            ))}
                        </>
                    ) : (
                        <ItemPreviewContent bold={bold}>{data}</ItemPreviewContent>
                    )}
                </ItemPreview>
            )}
        </>
    );
};

export default InvoiceNumberItem;
