import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerrequestCustomerRequestSummary as CustomerRequestSummaryResponse,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { LoadingIndicator } from '../../../../components/common';
import { useApi } from '../../../../hooks/use-api';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { RequestTemplateCode, can } from '../../../../main';
import { IRootState } from '../../../../reducers';
import { QueryKeysEnum, buildDeliveryPointsRequestsSearchQueryKey } from '../../../../utils/react-query-utils';
import DeliveryPointDetailContactData from './delivery-point-detail-contact-data';
import DeliveryPointDetailContractData from './delivery-point-detail-contract-data';
import DeliveryPointDetailInvoiceData from './delivery-point-detail-invoice-data';
import DeliveryPointDetailPaymentData from './delivery-point-detail-payment-data';

interface IDeliveryPointDetailDataProps {
    deliveryPointId: string;
    contractId: string;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null;
    canActivateEInvoice: boolean;
    setActivateEInvoiceButtonClicked: (val: boolean) => void;
    setIsActivateEInvoiceModalOpen: (val: boolean) => void;
}

const DeliveryPointDetailData: React.FC<IDeliveryPointDetailDataProps> = ({
    deliveryPointId,
    contractId,
    unitedDeliveryPoint,
    canActivateEInvoice,
    setActivateEInvoiceButtonClicked,
    setIsActivateEInvoiceModalOpen,
}) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const employee = useSelector((state: IRootState) => state.user.employee);
    const { i18n } = useTranslation();
    const api = useApi();

    const { isLoading: isLoadingDeliveryPoint, data: deliveryPointData } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        [QueryKeysEnum.DELIVERY_POINT, contractId, i18n.language],
        async () => api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data),
    );

    const requestSearchObject: SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery = {
        customerUuid: loggedInCustomer?.id,
        deliveryPointId: deliveryPointId,
        unitedDeliveryPointUuid: unitedDeliveryPoint?.id,
        statuses: ['CREATED', 'REGISTERED', 'GENERATED', 'SAP_OPEN', 'SAP_IN_PROGRESS'],
    };
    const { isLoading: isLoadingCustomerRequests, data: customerRequestsData } = useQueryWithError<CustomerRequestSummaryResponse | null>(
        buildDeliveryPointsRequestsSearchQueryKey(contractId, requestSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? null
                : api.deliveryPoints.deliveryPointSearchCustomerRequests(contractId, requestSearchObject, { secure: true }).then((res) => res.data),
        { enabled: employee ? can('ENTITY_REQUESTS_VIEW', employee) : true },
    );

    const isFieldChangeRequested = (code: RequestTemplateCode) => {
        if (customerRequestsData?.result == null) return false;
        const foundRequest = customerRequestsData.result.find((req) => req.customerRequestTemplate?.code === code);
        return foundRequest != null;
    };

    const isUdpSharedToMe = unitedDeliveryPoint?.sharing?.by != null;
    const isDpInactive = deliveryPointData?.status !== 'ACTIVE';

    const [isViewExpanded, setIsViewExpanded] = useState(false);

    return (
        <>
            {(isLoadingDeliveryPoint || isLoadingCustomerRequests) && <LoadingIndicator fullscreen />}

            <Col xs="12">
                <div className={classNames('row', { 'max-height-600-md': !isViewExpanded })}>
                    {unitedDeliveryPoint?.id != null && deliveryPointData && (
                        <>
                            <div className="col-12 col-lg-6">
                                <div className="my-4">
                                    <DeliveryPointDetailPaymentData
                                        deliveryPoint={deliveryPointData}
                                        isFieldChangeRequested={isFieldChangeRequested}
                                        canEditOverride={!isUdpSharedToMe && !isDpInactive}
                                    />
                                </div>
                                <div className="my-4">
                                    <DeliveryPointDetailInvoiceData
                                        deliveryPoint={deliveryPointData}
                                        canActivateEInvoice={canActivateEInvoice}
                                        setActivateEInvoiceButtonClicked={setActivateEInvoiceButtonClicked}
                                        setIsActivateEInvoiceModalOpen={setIsActivateEInvoiceModalOpen}
                                        canEditOverride={!isUdpSharedToMe && !isDpInactive}
                                    />
                                </div>
                                <div className="my-4">
                                    <DeliveryPointDetailContractData
                                        deliveryPoint={deliveryPointData}
                                        canEditOverride={!isUdpSharedToMe && !isDpInactive}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mt-4">
                                    <DeliveryPointDetailContactData
                                        deliveryPoint={deliveryPointData}
                                        canEditOverride={!isUdpSharedToMe && !isDpInactive}
                                        unitedDeliveryPointId={unitedDeliveryPoint.id}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {isViewExpanded ? (
                        <div className="col-12 order-last mt-4 d-block d-md-none text-center">
                            <Button onClick={() => setIsViewExpanded(false)}>
                                <Trans i18nKey="delivery-point.detail.show-less" />
                                <i className="icon-chevron-top-16 ml-1 fa-lg"></i>
                            </Button>
                        </div>
                    ) : (
                        <div className="col-12 order-last mt-4 d-flex d-md-none text-center top-down-gradient-effect">
                            <Button onClick={() => setIsViewExpanded(true)}>
                                <Trans i18nKey="delivery-point.detail.show-more" />
                                <i className="icon-chevron-down-16 ml-1 fa-lg"></i>
                            </Button>
                        </div>
                    )}
                </div>
            </Col>
        </>
    );
};

export default DeliveryPointDetailData;
