import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { BusinessPartnerTypeEnum, CommodityEnum } from '../models/enums';

type Props = {
    comodity: CommodityEnum;
    type?: BusinessPartnerTypeEnum;
};

const LINK_EE = 'https://www.spp.sk/sk/domacnosti/elektrina/kalkulacka-preddavkov/';
const LINK_EE_MPO = 'https://www.spp.sk/sk/male-podnikanie-a-organizacie/elektrina/kalkulacka-preddavkov/';
const LINK_ZP = 'https://www.spp.sk/sk/domacnosti/plyn/tarify-a-ceny/kalkulacka-preddavkov/';
const LINK_ZP_MPO = 'https://www.spp.sk/sk/male-podnikanie-a-organizacie/plyn/pre-male-podnikanie-a-organizacie/tarify-a-ceny/kalkulacka-preddavkov/';

const CalculatorCard: React.FC<Props> = ({ comodity, type }) => {
    const [t] = useTranslation();
    let link = LINK_ZP;
    if (comodity === CommodityEnum.ELECTRICITY) {
        if (type === BusinessPartnerTypeEnum.COMPANY) {
            link = LINK_EE_MPO;
        } else {
            link = LINK_EE;
        }
    } else if (type === BusinessPartnerTypeEnum.COMPANY) {
        link = LINK_ZP_MPO;
    }

    return (
        <Card className="mt-3 mb-5">
            <div className="card-body text-center py-5">
                <p className="mb-0">{t('delivery-point.detail.payment-data.tariffs.cant-decide')}</p>
                <p>{t('delivery-point.detail.payment-data.tariffs.help-yourself-advance-payment-calculator')}:</p>
                <a className="btn btn-secondary d-block d-md-inline-block mt-4" href={link} target="_blank" rel="noopener noreferrer">
                    {t('delivery-point.detail.payment-data.tariffs.advance-payment-calculator')}&nbsp;
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
            </div>
        </Card>
    );
};

export default CalculatorCard;
