import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';
import useCodeList from './../../../../../../hooks/use-code-list';

interface IAddressWithEmailandPhone extends IAddress {
    email?: string;
    phoneNumber?: string;
}

type Props = {
    data: IAddressWithEmailandPhone;
    bold?: boolean;
};

const NewCorrespondenceAddressItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
        },
    });

    const country = useMemo(() => {
        return countryCodeList?.find((item) => item.uuid === data?.country?.uuid)?.name;
    }, [countryCodeList, data.country]);

    const title = t('customer-request.steps.new-data.new-correspondence-address');

    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={title}>
            {data?.street && (
                <ItemPreviewContent bold={bold}>{`${data?.street || ''} ${data?.number || ''}, ${data?.postalCode || ''} ${data?.city ||
                    ''}${`, ${country}` || ''}`}</ItemPreviewContent>
            )}
            {data?.email && (
                <>
                    {t('customer-request.steps.contact.email')}
                    <ItemPreviewContent bold={bold}>{data.email}</ItemPreviewContent>
                </>
            )}
            {data?.phoneNumber && (
                <>
                    {t('customer-request.steps.contact.phone-number')}
                    <ItemPreviewContent bold={bold}>{data.phoneNumber}</ItemPreviewContent>
                </>
            )}
        </ItemPreview>
    );
};

export default NewCorrespondenceAddressItem;
