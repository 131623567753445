import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ClickableElement } from '../../../../components/common';
import HelpText from '../../../../components/help/help-text';
import { ReCaptchaInfoMessage } from '../../../../components/recaptcha/ReCaptchaInfoMessage';
import { useApi } from '../../../../hooks/use-api';
import Routes from '../../../../routes/routes';

type PropsType = {
    email: string;
};

const ConfirmMessage: React.FC<PropsType> = ({ email }) => {
    const history = useHistory();
    const api = useApi();
    const { addToast } = useToasts();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchaVisible, setRecaptchaVisible] = useState(false);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const [key, setKey] = useState(0);
    const { t, i18n } = useTranslation();
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';

    useEffect(() => {
        if (!email) history.replace(Routes.REGISTRATION);
    }, [email, history]);

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const resend = () => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !recaptchaVisible) {
            setRecaptchaVisible(true);
            return;
        }
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        api.customers
            .resendCustomerRegistration({ email }, { headers: reCaptchaHelp ? { 'X-GoogleReCaptcha-Code': reCaptchaHelp } : {}, secure: true })
            .then(() => addToast(<Trans i18nKey="registration.email-resend-success"></Trans>, { appearance: 'success' }))
            .catch(() => addToast(<Trans i18nKey="registration.email-resend-fail"></Trans>, { appearance: 'error' }))
            .finally(() => {
                if (process.env.REACT_APP_RECAPTCHA === 'true') {
                    recaptchaRef?.current?.reset();
                    setRecaptchaVisible(false);
                    setReCaptchaError(false);
                }
            });
    };

    return (
        <div className="col">
            <h2 style={{ letterSpacing: 'initial', lineHeight: 'initial' }}>
                <Trans i18nKey="registration.thank-you-for-registration" defaults="Už len krok Vás delí od dokončenia registrácie" />
            </h2>
            <h4>
                <Trans i18nKey="registration.link-in-email" values={{ email: email }} components={{ strong: <strong /> }}>
                    Pre dokončenie registrácie kliknite na odkaz, ktorý sme vám poslali na adresu <strong>{{ email }}</strong>.
                </Trans>
            </h4>
            <small>{t('registration.email-not-received')}</small>

            <div className="mt-4">
                <HelpText screen="COM" field="COM_FAST_CONTACT" />
            </div>

            <div className="text-center mt-5">
                <div style={{ display: recaptchaVisible ? 'block' : 'none' }}>
                    <ReCAPTCHA
                        key={key}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA === 'true' ? recaptchaSiteKey : 'undefined'}
                        onChange={resend}
                        hl={i18n.language === 'en' ? 'en' : 'sk'}
                    />
                    {reCaptchaError && <span style={{ color: 'red', fontWeight: 'bold' }}>{t('common.input-rules.required')}</span>}
                </div>
                <Trans
                    i18nKey="registration.email-not-received-resend"
                    components={{ Button: <ClickableElement className="text-decoration-underline-inverse" isText onClick={resend} /> }}
                >
                    Neprišiel vám e-mail?
                    <br />
                    <button>Poslať znova</button>
                </Trans>
                <div style={{ display: recaptchaVisible ? 'block' : 'none' }}>
                    <ReCaptchaInfoMessage />
                </div>
            </div>
        </div>
    );
};

export default ConfirmMessage;
