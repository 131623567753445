import {
    SkSppNzpBeApiCustomerrequestCustomerRequest,
    SkSppNzpCommonsApiCustomerrequestComponentEntityReference,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseSelect from '../../../../../components/common/base-select';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IAttachments } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { formatBusinessPartner } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import Attachments from '../../../../delivery-points/detail/data/contact-data-modals/component/block-attachments';
import { CustomerRequestTypeEnum } from '../../../config/enums';
import ComplaintFormFields from './components/complaint-form-fields';

type ComplaintFormType = {
    complaint: string;
    businessPartnerId: string;
};

const BlockComplaintShort: React.FC = () => {
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const [files, setFiles] = useState<IAttachments[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const { additionalData, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const api = useApi();
    const initializeRequestCreationProcess = useSendRequest();

    const searchQueryData = useMemo(
        () => ({
            deliveryPoint: { hidden: false },
            pairingDone: true,
            paging: { size: 1000 },
        }),
        [],
    );

    const { register, handleSubmit, errors, trigger, setValue, watch, getValues } = useForm<ComplaintFormType>({
        defaultValues: { complaint: '', businessPartnerId: additionalData?.bpuuid },
    });

    const [mutatePartnerDeliveryPoints, { data: unitedDeliveryPointsData }] = useMutationWithError(async (customerId: string) =>
        api.customers
            .searchUnitedDeliveryPoint(customerId, searchQueryData, undefined, {
                secure: true,
            })
            .then((res) => (res.data?.result ? res.data?.result : null))
            .finally(() => setLoading(false)),
    );

    const onSubmit = (formData: ComplaintFormType) => {
        if (!customerRequestTemplate) {
            return;
        }
        const { complaint, businessPartnerId } = formData;

        const content = {
            complaint,
        };

        const metadata = { businessPartnerId: additionalData?.bpuuid || businessPartnerId };

        const entityReferences: SkSppNzpCommonsApiCustomerrequestComponentEntityReference[] = [];
        additionalData?.udpd?.deliveryPoints?.forEach((dp) => {
            if (dp.contract?.contractAccount?.businessPartner?.id === additionalData?.bpuuid)
                entityReferences.push({
                    contractId: dp.contract?.id,
                    deliveryPointId: dp.id,
                    contractAccountId: dp.contract?.contractAccount?.id,
                });
        });

        const data: SkSppNzpBeApiCustomerrequestCustomerRequest = {
            content: {
                metadata,
                ...content,
                type: CustomerRequestTypeEnum.ZOP_P,
                entityReferences,
            },
            customerRequestTemplate,
        };

        setLoading(true);
        initializeRequestCreationProcess(data, files, setFiles)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setLoading(false));
    };

    const businessPartnerOptions = useMemo(() => {
        const availableBusinessPartners = unitedDeliveryPointsData?.filter(
            (value, index, self) => self.map((x) => x.businessPartner?.id).indexOf(value.businessPartner?.id) === index,
        );
        const sorted = availableBusinessPartners?.sort((a, b) =>
            formatBusinessPartner(a.businessPartner).localeCompare(formatBusinessPartner(b.businessPartner)),
        );
        return sorted;
    }, [unitedDeliveryPointsData]);

    useEffect(() => {
        additionalData?.bpuuid && setValue('businessPartnerId', additionalData?.bpuuid);
    }, [businessPartnerOptions, setValue, additionalData]);

    useEffect(() => {
        if (!additionalData?.bpuuid && loggedInCustomer?.id) {
            setLoading(true);
            mutatePartnerDeliveryPoints(loggedInCustomer.id);
        }
    }, [additionalData?.bpuuid, loggedInCustomer?.id, mutatePartnerDeliveryPoints]);

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const isSubmitAllowed = useMemo(() => !!getValues('complaint') && (additionalData?.bpuuid || !!getValues('businessPartnerId')), [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        watch && watch('businessPartnerId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        watch && watch('complaint'),
        getValues,
        additionalData?.bpuuid,
    ]);

    return (
        <>
            {loading && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {!additionalData?.bpuuid && (
                    <BaseSelect ref={register} name="businessPartnerId" label={t('common.customer')} className="form-control-filter">
                        {businessPartnerOptions?.map((unitedDeliveryPoint, index) => (
                            <option key={index} value={unitedDeliveryPoint.businessPartner?.id}>
                                {formatBusinessPartner(unitedDeliveryPoint.businessPartner)}
                            </option>
                        ))}
                    </BaseSelect>
                )}
                <ComplaintFormFields register={register} errors={errors} trigger={trigger} fieldName={'complaint'} />
                <Attachments files={files} setFiles={setFiles} label={t('customer-request.steps.attachments.attachments-title3')} />

                <BaseButton type="submit" disabled={!isSubmitAllowed}>
                    <Trans i18nKey="customer-request.steps.complaint.send-complaint">Odoslať podnet</Trans>
                </BaseButton>
            </form>
            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default BlockComplaintShort;
