import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerrequestCustomerRequestAttachment } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { ClickableElement } from '../../../../../../components/common';
import { useApi } from '../../../../../../hooks/use-api';
import useMutationWithError from '../../../../../../hooks/use-mutation-with-error';
import { downloadHandler } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: SkSppNzpBeApiCustomerrequestCustomerRequestAttachment[];
    cropped?: boolean;
    bold?: boolean;
};

const AttachmentsItem: React.FC<Props> = ({ data, bold, cropped }) => {
    const api = useApi();
    const [t] = useTranslation();

    const [mutateAttachmentContent] = useMutationWithError<Blob | null, unknown, { fileId: string }>(
        async ({ fileId }) => api.customerRequests.getAttachmentContent(fileId, { secure: true }).then((res) => res.blob()),
        {
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const downloadFileItem = async (uuid: string) => {
        const file = data.find((item) => item.uuid === uuid);
        if (file?.uuid) {
            const fileBlob = await mutateAttachmentContent({ fileId: file.uuid });
            if (!!fileBlob) downloadHandler(fileBlob, file.name ?? `attachment-${new Date().toISOString()}`, file.mimeType);
        }
    };

    if (!data.length) return <></>;

    return (
        <ItemPreview title={t('customer-request.steps.attachments.attachments-title')}>
            {data.map((item, index) => (
                <ItemPreviewContent key={index} bold={bold} classNames="overflow-hidden">
                    <div className={`text-decoration-none d-flex justify-content-between align-items-center ${item.draftMetadata ? 'mb-2' : ''}`}>
                        <FontAwesomeIcon className="mr-2" icon={faFile} />
                        {item.draftMetadata ? (
                            <span className="text-cut-dots">
                                <small className="mr-auto">{t(`customer-request.steps.attachments.attachment-${item.draftMetadata}`)}:</small>
                                <br />
                                <span className="mr-auto"> {item.name}</span>
                            </span>
                        ) : (
                            <span className="mr-auto text-cut-dots"> {item.name}</span>
                        )}
                        {cropped === false && (
                            <>
                                <ClickableElement onClick={() => item.uuid && downloadFileItem(item.uuid)}>
                                    <FontAwesomeIcon
                                        id={`download-attachment-id-${index}`}
                                        className="text-danger cursor-pointer"
                                        icon={faFileDownload}
                                    />
                                </ClickableElement>
                                <UncontrolledTooltip placement="top" target={`download-attachment-id-${index}`}>
                                    {t('customer-request.steps.attachments.download-tooltip')}
                                </UncontrolledTooltip>
                            </>
                        )}
                    </div>
                </ItemPreviewContent>
            ))}
        </ItemPreview>
    );
};

export default AttachmentsItem;
