import React from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { KINDcodeHome, Routes } from '../../../main';

interface IUnableToFindRequestModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    businessPartnerKindCode?: string;
}

const UnableToFindRequestModal: React.FC<IUnableToFindRequestModalProps> = ({ isOpen, onCloseModal, businessPartnerKindCode }) => {
    return (
        <>
            <Modal isOpen={isOpen} centered style={{ maxWidth: '550px' }}>
                <ModalBody>
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <div className="p-4 ">
                        <Trans i18nKey="delivery-point.detail.unable-to-find-request-modal.message" />
                        <NavLink to={Routes.CONTACT}>
                            {businessPartnerKindCode === KINDcodeHome ? (
                                <Trans i18nKey="delivery-point.detail.unable-to-find-request-modal.support" />
                            ) : (
                                <Trans i18nKey="delivery-point.detail.unable-to-find-request-modal.business-support" />
                            )}
                        </NavLink>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UnableToFindRequestModal;
