import { SkSppNzpBeApiCustomeraccessRegistration } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { ErrorOption } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ClickableElement } from '../../../../components/common';
import useSso from '../../../../hooks/sso/use-sso';
import { useApi } from '../../../../hooks/use-api';
import { useUrlQuery } from '../../../../hooks/use-url-query';
import { IResponseError, ISocialLoginJWTPayload } from '../../../../models/model';
import Routes from '../../../../routes/routes';
import { handlePasswordError } from '../../../../utils/error-handling';
import PageHeader from './page-header';
import RegistrationForm from './registration-form';

export type RegistrationFormDataType = {
    email: string;
    'new-password': string;
    firstName: string;
    lastName: string;
    termsAndConditions: boolean;
    privacyPolicy: boolean;
};

type RegistrationStepProps = {
    saveEmail: (arg0: string) => void;
    email: string;
    firstName?: string;
    lastName?: string;
    jwt?: string;
    redirectUrl?: string;
    socialLoginType?: ISocialLoginJWTPayload['typ'];
};

const RegistrationStep: React.FC<RegistrationStepProps> = ({ saveEmail, email, firstName, lastName, jwt, redirectUrl, socialLoginType }) => {
    const location = useLocation();
    const history = useHistory();
    const query = useUrlQuery();
    const [t] = useTranslation();
    const { redirectToSso } = useSso();

    const { addToast } = useToasts();
    const api = useApi();

    const navigateToNextScreen = () => {
        query.set('regform', 'submitted');
        history.push(location.pathname + '?' + query.toString());
    };

    const onFormSubmit = (
        formData: RegistrationFormDataType,
        setError: (name: string, error: ErrorOption) => void,
        recaptcha?: string | null,
        onSuccess?: () => void,
    ) => {
        const newCustomer: SkSppNzpBeApiCustomeraccessRegistration = {
            email: formData.email,
            password: formData['new-password'],
            firstName: formData.firstName,
            lastName: formData.lastName,
            approvals: [
                { type: 'CONTRACT_CONDITIONS', approval: formData.termsAndConditions },
                { type: 'PROTECTION_PERSONAL_DATA', approval: formData.privacyPolicy },
            ],
            jwt: jwt || '',
        };

        if (jwt) {
            api.customers
                .registerCustomerWithSocialLogin(newCustomer, { headers: recaptcha ? { 'X-GoogleReCaptcha-Code': recaptcha } : {}, secure: true })
                .then((resp) => {
                    newCustomer.email && saveEmail(newCustomer.email);
                    if (resp.data?.status === 'ACTIVE' && redirectUrl) {
                        redirectToSso(redirectUrl);
                        //window.location.replace(redirectUrl);
                    } else {
                        onSuccess && onSuccess();
                        navigateToNextScreen();
                    }
                })
                .catch(onReject);
        } else {
            api.customers
                .registerCustomer(newCustomer, { headers: recaptcha ? { 'X-GoogleReCaptcha-Code': recaptcha } : {}, secure: true })
                .then(() => {
                    newCustomer.email && saveEmail(newCustomer.email);
                    onSuccess && onSuccess();
                    navigateToNextScreen();
                })
                .catch(onReject);
        }

        function onReject(errResponse: { error: IResponseError }) {
            const err = errResponse.error;
            if (err) {
                if (err.code === 2001) {
                    const addError = (obj: ErrorOption) => setError('new-password', obj);
                    handlePasswordError(t, addError, err);
                    addToast(<Trans i18nKey="error.2001" />, { appearance: 'error' });
                    return;
                }
                if (err.code === 2015) {
                    addToast(<Trans i18nKey="registration.jwt-missing-info" />, { appearance: 'error' });
                    return;
                }
                if (err.code === 2016) {
                    addToast(
                        <Trans
                            i18nKey="registration.another-social-account-already-connected"
                            components={{ service: socialLoginType === 'google' ? 'Google' : 'Facebook' }}
                        />,
                        {
                            appearance: 'error',
                        },
                    );
                    return;
                }
                const errText = err.code + ' ' + (err.message ?? '') + (err.errors ? err.errors?.join(', ') : '');
                addToast(<Trans i18nKey="registration.form-rejected" components={{ Message: errText }} />, { appearance: 'error' });
            } else {
                addToast(<Trans i18nKey="registration.form-rejected" components={{ Message: t('error.default') }} />, { appearance: 'error' });
            }
        }
    };

    return (
        <div className="col" style={{ paddingBottom: '150px' }}>
            {/* The paddingBottom is needed due to the correct displaying of Password Tooltip */}
            <PageHeader jwt={jwt} />
            <RegistrationForm
                onFormSubmit={onFormSubmit}
                email={email}
                firstName={firstName}
                lastName={lastName}
                hidePassword={!!email || !!firstName || !!lastName}
                hideSocialLoginBtns={!!jwt}
            />
            <div className="mt-5">
                <hr className="mt-3" />
                <ClickableElement
                    isText
                    onClick={() => history.push(Routes.LOGIN_ISSUES)}
                    className="text-decoration-underline-inverse text-secondary"
                >
                    <Trans i18nKey="registration.problem">Mám problém s registráciou</Trans>
                </ClickableElement>
            </div>
        </div>
    );
};

export default RegistrationStep;
