import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { changeUrlIfBlacklisted, FetchedTokensType, getStateFromSessionStorage, useAuthUtils } from '../../hooks/sso/use-auth-utils';
import useSilentRefresh, { ISilentRefreshResult } from '../../hooks/sso/use-silent-refresh';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IAccountInfo } from '../../reducers/user-reducer';
import Routes from '../../routes/routes';
import LoginFallback from './login-fallback';

interface IAuthSuccess {
    onSuccessLogin?: (tokens: FetchedTokensType, backUrl: string) => void;
}

const AuthSuccess: React.FC<IAuthSuccess> = ({ onSuccessLogin }) => {
    const [loginFinished, setLoginFinished] = useState<boolean>(false);
    const [loginFailed, setLoginFailed] = useState<boolean>(false);
    const ssoClientId = '' + process.env.REACT_APP_SSO_CLIENT_ID;
    const history = useHistory();

    const query = useUrlQuery();
    const scope = '' + query.get('scope');
    const authorizationCode = '' + query.get('code');
    const stateAsUuid = '' + query.get('state');
    const customerScope = scope === 'openid customer';
    const { getTokens, getCustomer, getAdmin, getVerifierCodeFromSessionStorage, dispatchCustomer } = useAuthUtils();
    const [silentRefresh] = useSilentRefresh();
    const logoutCode = query.get('logoutcode');

    useEffect(() => {
        if (loginFinished) return;

        if (logoutCode) {
            console.log('logoutCode ', JSON.stringify(logoutCode));
            history.replace(Routes.HOME);
            return;
        }

        const publicUrl = process.env.REACT_APP_PUBLIC_URL;
        const redirectUri = window.location.origin + (publicUrl ? publicUrl : '') + Routes.SSO_SUCCESS;
        const stateParams = getStateFromSessionStorage(stateAsUuid);
        const routeBlacklist = [Routes.AUTO_LOGOUT_CONFIRMATION];
        const urlFallback = Routes.HOME;

        let backUrl = (stateParams ?? '/').replace('/admin', '');
        backUrl = changeUrlIfBlacklisted(backUrl, routeBlacklist, urlFallback);

        const codeVerifier = getVerifierCodeFromSessionStorage();

        if (codeVerifier) {
            getTokens(ssoClientId, scope, authorizationCode, redirectUri)
                .then((tokens) => {
                    if (customerScope) {
                        getCustomer(
                            tokens,
                            backUrl,
                            onSuccessLogin
                                ? (user: IAccountInfo) => {
                                      dispatchCustomer(user, tokens, () => onSuccessLogin(tokens, backUrl));
                                  }
                                : undefined,
                        );
                    } else {
                        getAdmin(tokens, backUrl);
                    }
                    setLoginFinished(true);
                    setLoginFailed(false);
                })
                .catch((err: Record<string, any>) => {
                    console.log('getTokens network error', err);
                    setLoginFinished(true);
                    setLoginFailed(true);
                });
        } else {
            const scope = '' + process.env.REACT_APP_SSO_SCOPE;
            silentRefresh(ssoClientId, scope)
                .then((result: ISilentRefreshResult) => {
                    setLoginFinished(true);
                    if (!result.error) {
                        const redirectUri = '' + process.env.REACT_APP_SSO_RESOLVE_URL;
                        getTokens(ssoClientId, scope, result.code, redirectUri)
                            .then((tokens) => {
                                if (customerScope) {
                                    getCustomer(tokens, backUrl);
                                } else {
                                    getAdmin(tokens, backUrl);
                                }
                            })
                            .catch((err: Record<string, any>) => console.log('getTokens network error', err));
                    } else {
                        setLoginFailed(true);
                    }
                })
                .catch((err) => {
                    setLoginFinished(true);
                    setLoginFailed(true);
                });
        }
    }, [
        loginFinished,
        scope,
        customerScope,
        authorizationCode,
        stateAsUuid,
        getTokens,
        getCustomer,
        getAdmin,
        silentRefresh,
        getVerifierCodeFromSessionStorage,
        ssoClientId,
        logoutCode,
        history,
        onSuccessLogin,
        dispatchCustomer,
    ]);

    if (loginFinished && loginFailed) {
        return <LoginFallback />;
    }

    return <></>;
};

export const MobileAuthSuccess: React.FC = () => {
    const query = useUrlQuery();
    const clientId = 'lmnzp';
    const scope = '' + query.get('scope');
    const authorizationCode = '' + query.get('code');
    const publicUrl = process.env.REACT_APP_PUBLIC_URL;
    const redirectUri = window.location.origin + (publicUrl ? publicUrl : '') + Routes.SSO_MOBILE_SUCCESS;
    const { getTokens, getCustomer } = useAuthUtils();
    const history = useHistory();

    useEffect(() => {
        getTokens(clientId, scope, authorizationCode, redirectUri)
            .then((tokens) => {
                getCustomer(tokens, '/', (newUser) => {
                    console.log('mam noveho usera', tokens, newUser);
                    history.replace('/');
                });
            })
            .catch((err: Record<string, any>) => {
                console.log('getTokens network error', err);
            });
    }, [getTokens, scope, authorizationCode, getCustomer, history, redirectUri]);

    return <></>;
};

export default AuthSuccess;
