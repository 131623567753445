import { SkSppNzpBeApiCustomerprofileInvoicePaymentInfo } from '@spp/spp-meru-frontend-common';
import { QRCodeSVG } from 'qrcode.react';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import payBySquareBackground from '../../../../assets/images/pay-bottom-dark.png';
import CopyToClipboard from '../../../../components/common/copy-to-clipboard';
import { BaseSelect, formatCurrency, LoadingIndicator } from '../../../../main';
import { ConsumptionCategoryEnum } from '../../../../models/enums';
import useFetchPaymentProviders from '../api/use-fetch-payment-providers';
import EpayMethodItem from '../components/e-pay-method-item';

//TODO: enable when BE ready
//const CARDPAY_METHOD = 'CARDPAY';

type FormDataType = {
    paymentMethod: string;
};

type Props = {
    onPaymentMethodPick: (paymentMethod: string) => void;
    variableSymbol: string;
    amountToPay: number;
    cc: ConsumptionCategoryEnum | undefined;
    isLoading: boolean;
    commodity?: 'EE' | 'ZP';
    paymentInfoData?: SkSppNzpBeApiCustomerprofileInvoicePaymentInfo | null;
    invoiceNumbers: string[];
};

const BANK_TRANSFER = 'bankTransfer';

const QRCODE = {
    frameWidth: 384,
    frameHeight: 450,
    qrCodeSize: 300,
    margin: 42,
    scale: 1 / 2,
};

const PaymentMethod: React.FC<Props> = ({
    onPaymentMethodPick,
    variableSymbol,
    amountToPay,
    cc,
    isLoading,
    commodity,
    paymentInfoData,
    invoiceNumbers,
}) => {
    const paymentDetail = invoiceNumbers.length === 1 ? paymentInfoData?.singleInvoice : paymentInfoData?.multipleInvoices;
    const prefferedBank = (paymentDetail?.banks || []).find((bank) => bank.preffered === true);
    const { register, handleSubmit, errors, watch } = useForm({ defaultValues: { bank: prefferedBank?.iban } }); // { defaultValues: { paymentMethod: CARDPAY_METHOD } }
    const { t } = useTranslation();
    const isError = !paymentInfoData;
    const clientCode = paymentInfoData?.queue === 'INDIVIDUAL' ? 'NZP_IND' : 'NZP';

    const selectedIban = watch('bank');
    const selectedBank = useMemo(() => {
        return (paymentDetail?.banks || []).find((bank) => bank.iban === selectedIban);
    }, [selectedIban, paymentDetail?.banks]);
    const paymentMethod = watch('paymentMethod');

    const onInitiateEpay = (data: FormDataType) => {
        if (data.paymentMethod) onPaymentMethodPick(data.paymentMethod);
    };

    const { isLoading: isLoadingProviders, data: epayProviders } = useFetchPaymentProviders(clientCode);

    return (
        <>
            {(isLoading || isLoadingProviders) && <LoadingIndicator />}

            <div className="row pb-4 bigger">
                <div className="col-auto">
                    <div className="vertical-line">
                        <Trans i18nKey="invoices.e-pay.method-header-invoice-price">Celková suma:</Trans>
                        <br />
                        <strong>{formatCurrency(amountToPay)}</strong>
                    </div>
                </div>
                <div className="col">
                    <div className="vertical-line">
                        <Trans i18nKey="invoices.e-pay.variable-symbol">Variabilný symbol:</Trans>
                        <br />
                        <strong>{variableSymbol}</strong>
                    </div>
                </div>
            </div>

            {isError ? (
                <div className="card my-3">
                    <div className="card-body">
                        <Trans i18nKey="invoices.e-pay.payment-not-available">
                            Online platba nie je aktuálne dostupná. Prosím skúste neskôr alebo zvoľte iný spôsob platby.
                        </Trans>
                    </div>
                </div>
            ) : (
                <>
                    <h3 className="mt-1">
                        <Trans i18nKey="invoices.e-pay.method-pick-title">Zvoľte platobnú metódu:</Trans>
                    </h3>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card mt-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col mt-2">
                                        {epayProviders?.map((epaymethod) => (
                                            <EpayMethodItem
                                                register={register}
                                                errors={errors}
                                                key={epaymethod.providerCode}
                                                value={epaymethod.providerCode}
                                                name={epaymethod.providerName}
                                                imageUrl={epaymethod.resourcePath}
                                            />
                                        ))}
                                        <EpayMethodItem
                                            register={register}
                                            errors={errors}
                                            key={BANK_TRANSFER}
                                            value={BANK_TRANSFER}
                                            name={t('invoices.e-pay.pay-by-bank')}
                                        />
                                        {paymentMethod !== BANK_TRANSFER && (
                                            <button className="btn btn-primary btn-lg btn-block" type="button" onClick={handleSubmit(onInitiateEpay)}>
                                                {t('invoices.e-pay.initiate-epay-button', { sum: formatCurrency(amountToPay) })}
                                            </button>
                                        )}
                                    </div>
                                    {!prefferedBank?.payBySquare?.error && (
                                        <div className={`col text-center ${paymentMethod === BANK_TRANSFER ? 'd-none d-sm-block' : ''}`}>
                                            <b>
                                                <Trans i18nKey="invoices.e-pay.pay-by-square">Platba QR kódom</Trans>
                                            </b>
                                            <p>
                                                <Trans i18nKey="invoices.e-pay.pay-by-square-info">Naskenujte kamerou mobilneho telefonu</Trans>
                                            </p>
                                            <div className="d-flex justify-content-center align-items-center text-left">
                                                <div
                                                    style={{
                                                        width: QRCODE.frameWidth * QRCODE.scale,
                                                        height: QRCODE.frameHeight * QRCODE.scale,
                                                        backgroundImage: `url(${payBySquareBackground})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                    }}
                                                    className=""
                                                >
                                                    {prefferedBank?.payBySquare?.qrCode && (
                                                        <div
                                                            style={{
                                                                marginLeft: QRCODE.margin * QRCODE.scale,
                                                                marginTop: QRCODE.margin * QRCODE.scale,
                                                            }}
                                                        >
                                                            <QRCodeSVG
                                                                value={prefferedBank.payBySquare.qrCode}
                                                                size={QRCODE.qrCodeSize * QRCODE.scale}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Collapse isOpen={paymentMethod === BANK_TRANSFER} className="pt-3">
                            <div className="card">
                                <div className="card-body">
                                    {paymentDetail && (
                                        <div className="row">
                                            <div className="col-auto">
                                                {paymentDetail.banks && paymentDetail.banks.length > 0 && (
                                                    <BaseSelect
                                                        ref={register({})}
                                                        name="bank"
                                                        label={t('invoices.e-pay.bank')}
                                                        className="form-control-filter"
                                                    >
                                                        {paymentDetail.banks.map((bank, index) => (
                                                            <option key={'singleBank' + bank.iban + index} value={bank.iban}>
                                                                {bank.name}
                                                            </option>
                                                        ))}
                                                    </BaseSelect>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {selectedBank && (
                                        <>
                                            <div className="row pb-1">
                                                <div className="col">
                                                    <div>
                                                        <Trans i18nKey="invoices.e-pay.IBAN">IBAN:</Trans>
                                                    </div>
                                                    <strong className="bigger">
                                                        {selectedBank.iban} &nbsp; <CopyToClipboard text={selectedBank.iban} />
                                                    </strong>
                                                    <div className="pt-3">
                                                        <Trans i18nKey="invoices.e-pay.method-header-invoice-price">Celková suma:</Trans>
                                                    </div>
                                                    <strong>
                                                        {formatCurrency(paymentDetail?.amount || 0)} &nbsp;
                                                        <CopyToClipboard text={paymentDetail?.amount} />
                                                    </strong>
                                                    <div className="pt-3">
                                                        <Trans i18nKey="invoices.e-pay.variable-symbol">Variabilný symbol:</Trans>
                                                    </div>
                                                    <strong>
                                                        {paymentDetail?.vs} &nbsp;
                                                        <CopyToClipboard text={paymentDetail?.vs} />
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className="pt-3 text-secondary">
                                                <Trans i18nKey="invoices.e-pay.variable-symbol-info"></Trans>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Collapse>
                    </form>
                </>
            )}
        </>
    );
};

export default PaymentMethod;
