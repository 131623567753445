import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useApi } from '../../../../../../hooks/use-api';
import { useQueryWithError } from '../../../../../../hooks/use-query-with-error';
import { ConsumptionCategoryEnum, DefaultFormType, KINDcodeHome, LoadingIndicator, Rates } from '../../../../../../main';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { ratesForSpecificDistributionAreaBasedOnOriginalDeliveryPointRate } from '../../../../customer-request-utils';
import TariffFields from '../../components/tariff-fields';

interface IBlockTariffSelection extends DefaultFormType {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    setTariffs: (val: TariffRate[]) => void;
    consumptionCategory: ConsumptionCategoryEnum;
}

export const TranscriptRateSelection: React.FC<IBlockTariffSelection> = ({ deliveryPoint, register, errors, setTariffs, consumptionCategory }) => {
    const api = useApi();
    const currentTariff = deliveryPoint?.tariffRate?.uuid;

    const { data: tariffRates, isLoading: isLoadingTariffRates } = useQueryWithError<TariffRate[] | undefined>(
        [QueryKeysEnum.PRODUCT_TARIFF_RATE, { type: 'EE', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }],
        async () =>
            consumptionCategory == null
                ? undefined
                : api.productCatalogs
                      .getTariffsAndRates({ type: 'EE', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }, { secure: true })
                      .then((res) => res.data?.result),
    );

    const originalDeliveryPointConsumptionCategory = useMemo(
        () =>
            deliveryPoint.contract?.contractAccount?.businessPartner?.kind?.code === KINDcodeHome
                ? ConsumptionCategoryEnum.HOME
                : ConsumptionCategoryEnum.RETAIL,
        [deliveryPoint.contract],
    );

    const specificRates = (rates?: TariffRate[]) => {
        if (consumptionCategory === originalDeliveryPointConsumptionCategory) {
            return rates;
        }
        return ratesForSpecificDistributionAreaBasedOnOriginalDeliveryPointRate(
            rates,
            deliveryPoint.tariffRate?.code as Rates,
            deliveryPoint.distributionArea?.code,
        );
    };

    useEffect(() => {
        tariffRates && setTariffs(tariffRates);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tariffRates]);

    return (
        <>
            {isLoadingTariffRates && <LoadingIndicator />}
            {specificRates(tariffRates) && (
                <TariffFields
                    register={register}
                    errors={errors}
                    data={specificRates(tariffRates) || []}
                    currentTariff={currentTariff}
                    name={`deliveryPoint.${deliveryPoint.id}.`}
                    transcript
                />
            )}
            {tariffRates && tariffRates.length === 0 && consumptionCategory === 'WHOLESALE' && (
                <div className="alert alert-danger p-4 d-flex mb-5" role="alert">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                    <div className="ml-4">
                        <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-tariff-not-supported"></Trans>
                    </div>
                </div>
            )}
        </>
    );
};
