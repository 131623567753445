import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import Input from '../../../../../../components/common/input';
import { TooltipWrapper } from '../../../../../../components/common/tooltip-wrapper';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';

const ContactFields: React.FC<DefaultFormType> = ({ register, errors, trigger }) => {
    const { requiredTrimed, formRules, phoneRules } = useFormRules();
    const { t } = useTranslation();
    return (
        <>
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.email })}
                errors={errors}
                type="text"
                name="email"
                label={<Trans i18nKey="common.email" />}
                autoCapitalize="off"
                autoCorrect="off"
                characterFilter={CharacterFilter.LATIN}
            />

            <TooltipWrapper id="phone" tooltipContent={t('common.input-rules.phone-number-info')}>
                <Input
                    id="phone"
                    trigger={trigger}
                    ref={register({ ...formRules.required, ...phoneRules })}
                    errors={errors}
                    type="tel"
                    name="phone"
                    label={<Trans i18nKey="delivery-point.detail.contact-data.telephone-number" />}
                    characterFilter={CharacterFilter.LATIN}
                />
            </TooltipWrapper>
        </>
    );
};
export default ContactFields;
