import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClickableElement } from '../../common';

type BrandProps = {
    extraClass?: string;
    onClick: () => void;
    onDoubleClick?: () => void;
    customerRequestType?: 'fullScreen' | 'modal';
};

const Brand: React.FC<BrandProps> = ({ extraClass, onClick, onDoubleClick, customerRequestType }) => {
    const { t } = useTranslation();
    return (
        <ClickableElement className={`navbar-brand ${extraClass}`} onClick={onClick} onDoubleClick={onDoubleClick}>
            <img
                src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/logo.svg`}
                alt={t('navbar.my-spp')}
                width="90"
                className={customerRequestType === 'fullScreen' ? 'navbar-brand-fullScreenCustomerRequest' : ''}
            />
        </ClickableElement>
    );
};

export default Brand;
