import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ClickableElement } from '../../../../components/common/clickable-element';
import Routes from '../../../../routes/routes';

type FormListItemProps = {
    customerRequestUuid: string;
    hideArrow?: boolean;
};

const RequestInProcess: React.FC<FormListItemProps> = ({ customerRequestUuid, hideArrow }) => {
    const history = useHistory();
    return (
        <>
            {!hideArrow && <div className="request-in-process-arrow-up"></div>}
            <div className="bg-spp-color py-2 px-4 rounded text-black">
                <Trans i18nKey="delivery-point.detail.request-in-process" />{' '}
                <ClickableElement
                    isText
                    onClick={() =>
                        history.push({
                            pathname: Routes.CUSTOMER_REQUESTS + '/' + customerRequestUuid,
                        })
                    }
                    className="text-decoration-underline-inverse attribute-info"
                >
                    <Trans i18nKey="delivery-point.detail.display-request" />
                </ClickableElement>
            </div>
        </>
    );
};

export default RequestInProcess;
