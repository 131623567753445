import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { CommodityEnum, QueryKeysEnum } from '../../../../../../main';
import { IComplainedInvoiceProblemDescription } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IComplainedInvoiceProblemDescription & string;
    bold?: boolean;
    deliveryPointType?: CommodityEnum;
};

const ComplainedInvoiceProblemDescriptionItem: React.FC<Props> = ({ data, bold, deliveryPointType }) => {
    const [t] = useTranslation();

    const { data: complainedProblemCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COMPLAINED_INVOICE_PROBLEM,
        codeListTypeEnum: CodeListTypeEnum.COMPLAINED_INVOICE_PROBLEM,
        paging: {
            size: 30,
        },
    });

    const complainedProblem = useMemo(() => {
        return complainedProblemCodeList?.find((item) => item.uuid === data.problemDescription?.uuid)?.name;
    }, [complainedProblemCodeList, data.problemDescription]);

    if (isObjectEmpty(data)) return <></>;
    return (
        <>
            <ItemPreview title={t('customer-request.steps.problem-description.title')}>
                {data?.problemDescription && <ItemPreviewContent bold={bold}>{complainedProblem}</ItemPreviewContent>}
                {data?.meterNumber && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.problem-description.gauge-number')}: ${
                        data.meterNumber
                    }`}</ItemPreviewContent>
                )}
                {data?.complainedMeterReading && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.problem-description.invoice-status-claimed')}: ${
                        data.complainedMeterReading
                    }`}</ItemPreviewContent>
                )}
                {data?.readingDate && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.problem-description.according-date-invoice',
                    )}: ${formatDateToPreview(data.readingDate)}`}</ItemPreviewContent>
                )}
                {data?.requiredMeterReading && (
                    <ItemPreviewContent bold={bold}>
                        {t('customer-request.steps.problem-description.customer-status-required')}:{' '}
                        {data?.requiredMeterReadingNT ? (
                            <div className="ml-2">
                                <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" />: {data.requiredMeterReading} kWh
                                <br />
                                <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" />: {data.requiredMeterReadingNT} kWh
                            </div>
                        ) : (
                            <>
                                {` ${data.requiredMeterReading}`}{' '}
                                {deliveryPointType === CommodityEnum.GAS ? (
                                    <>
                                        m<sup>3</sup>
                                    </>
                                ) : (
                                    'kWh'
                                )}
                            </>
                        )}
                    </ItemPreviewContent>
                )}

                {data?.requiredReadingDate && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.problem-description.customer-deducation-date',
                    )}: ${formatDateToPreview(data.requiredReadingDate)}`}</ItemPreviewContent>
                )}
                {data?.currentMeterReading && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.problem-description.current-state')}: ${
                        data.currentMeterReading
                    }`}</ItemPreviewContent>
                )}
                {data.currentReadingDate && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.problem-description.current-deducation-date',
                    )}: ${formatDateToPreview(data.currentReadingDate)}`}</ItemPreviewContent>
                )}
                {data.description && <ItemPreviewContent bold={bold}> {data.description}</ItemPreviewContent>}
                {typeof data === 'string' && <ItemPreviewContent bold={bold}> {data}</ItemPreviewContent>}
            </ItemPreview>
        </>
    );
};

export default ComplainedInvoiceProblemDescriptionItem;
