import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import moment from 'moment';
import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import {
    BaseTextarea,
    ClickableElement,
    CodeListTypeEnum,
    CommodityEnum,
    LoadingIndicator,
    QueryKeysEnum,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IAttachments } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatDate, formatIBAN, formatPhoneNumber } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { displayTextRelationToRealEstate } from '../../../customer-request-utils';
import Email from '../block-new-customer-email/components/email';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';
import AppendixItem from './items/appendix-item';

const NOTE_MAX_COUNT = 510;

export const BlockCompletionTerminationContract: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const { content, customerRequestTemplate, attachments, deliveryPoint, metadata } = useSelector((state: IRootState) => state.customerRequest);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);

    const [files, setFiles] = useState<IAttachments[]>(attachments || []);
    const [showNote, setShowNote] = useState<boolean>(false);
    const [messageCounter, setMessageCounter] = useState<number>(0);

    const deliveryPoints = [...(content.deliveryPointsZP ?? []), ...(content.deliveryPointsEE ?? [])];

    const { register, handleSubmit, errors, trigger, setValue, watch } = useForm<{
        email: string;
        invoiceDeliveryEmail: string;
        note: string;
    }>({
        defaultValues: { email: content.newCustomerEmail, invoiceDeliveryEmail: content.invoiceDelivery?.email, note: content.note },
    });

    const [invoiceDeliveryInput, setInvoiceDeliveryInput] = useState(false);

    const { formRules } = useFormRules();

    const message: string | undefined = watch('note');
    useEffect(() => {
        dispatch(CustomerRequestActions.setData({ note: message }));
        setMessageCounter(message?.length ?? 0);
    }, [message, dispatch]);

    const isContainEE = deliveryPoints.find((dp) => dp.type === CommodityEnum.ELECTRICITY) ? true : false;
    const isContainZP = deliveryPoints.find((dp) => dp.type === CommodityEnum.GAS) ? true : false;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const { data: contractTerminationReasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CONTRACT_TERMINATION_REASON,
        codeListTypeEnum: CodeListTypeEnum.CONTRACT_TERMINATION_REASON,
        paging: {
            size: 64,
        },
    });

    const { data: overPaymentSettlementTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.OVERPAYMENT_SETTLEMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.OVERPAYMENT_SETTLEMENT_TYPE,
        paging: {
            size: 300,
        },
    });

    const terminationReasonFullObject = useMemo(() => {
        return contractTerminationReasonCodeList?.find((item) => {
            return content.contractTerminationReason?.reason.uuid === item.uuid;
        });
    }, [contractTerminationReasonCodeList, content.contractTerminationReason?.reason.uuid]);

    const overPaymentSettlementTypeObject = useMemo(() => {
        return overPaymentSettlementTypeCodeList?.find((item) => {
            return content.address ? 'ADDRESS' === item.code : 'BANK_ACCOUNT' === item.code;
        });
    }, [overPaymentSettlementTypeCodeList, content.address]);

    const dateForContractTermination =
        content.contractTerminationDate && moment(content.contractTerminationDate).isValid()
            ? formatDate(new Date(content.contractTerminationDate))
            : '';
    const onRequestSentSuccessConfirmed = () => {
        history.goBack();
    };

    const onSubmit = (data: { email: string; invoiceDeliveryEmail: string; note: string }) => {
        if (invoiceDeliveryInput) {
            content.invoiceDelivery &&
                dispatch(CustomerRequestActions.setData({ invoiceDelivery: { ...content.invoiceDelivery, email: data.invoiceDeliveryEmail } }));
            setInvoiceDeliveryInput(false);
            return;
        }

        const newInvoiceDeliveryEmail = data.invoiceDeliveryEmail ?? content.invoiceDelivery?.email;
        const addressCountry = countryCodeList?.find((item) => item.code === deliveryPoint?.address?.country);

        let dp: SkSppNzpBeApiCustomerprofileDeliveryPoint = deliveryPoint || {};
        if (content.deliveryPointsZP?.length) {
            dp = content.deliveryPointsZP[0];
        } else if (content.deliveryPointsEE?.length) {
            dp = content.deliveryPointsEE[0];
        }
        const requestData: any = {
            content: {
                metadata: {
                    businessPartnerId: metadata.businessPartnerId,
                },
                deliveryPoint:
                    deliveryPoints.length > 0
                        ? undefined
                        : {
                              ...dp,
                              address: {
                                  ...dp?.address,
                                  postalCode: dp?.address?.zipCode,
                                  country: addressCountry,
                                  number: dp?.address?.streetNumber,
                              },
                              contractNumber: dp?.contract?.externalId,
                              deliveryPointNumber: dp?.externalId,
                          },
                invoiceDelivery: { email: newInvoiceDeliveryEmail },
                overpaymentSettlement: {
                    address: content.address,
                    bankConnection: {
                        iban: content.bankConnection?.iban,
                        bicSwift: content.bankConnection?.bicSwift,
                    },
                    overpaymentSettlementType: overPaymentSettlementTypeObject,
                },
                preferredContact: content.preferredContact?.phoneNumber ? { phoneNumber: content.preferredContact?.phoneNumber } : undefined,
                address: undefined,
                newBusinessPartnerAddress: content.address,
                newBusinessPartnerCorrespondenceAddress: content.correspondenceAddress ?? content.address,
                type: customerRequestTemplate?.code,
                note: data.note,
                entityReferences: [
                    {
                        contractId: dp?.contract?.id,
                        deliveryPointId: dp?.id,
                        contractAccountId: dp?.contract?.contractAccount?.id,
                    },
                ],
                contractTerminationReason: content.contractTerminationReason,
                contractTerminationDate: content.contractTerminationDate,
            },
            customerRequestTemplate: customerRequestTemplate,
        };

        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(requestData, files, setFiles)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
                <ListGroup>
                    <ItemPreview title={t('common.delivery-point')}>
                        {deliveryPoints.length > 0 && (
                            <>
                                <ItemPreviewContent bold>
                                    {formatAddress(deliveryPoints[0].address)}
                                    <br />
                                    {isContainZP && t('enums.CommodityEnum.ZP')}
                                    {isContainEE && (isContainZP ? `, ${t('enums.CommodityEnum.EE')}` : t('enums.CommodityEnum.EE'))}
                                </ItemPreviewContent>
                            </>
                        )}
                    </ItemPreview>

                    <ItemPreview title={t('customer-request.steps.completion-transcript.termination-reason')}>
                        <>
                            <ItemPreviewContent bold>{terminationReasonFullObject?.name}</ItemPreviewContent>
                        </>
                    </ItemPreview>
                    {content.contractTerminationDate && (
                        <>
                            <ItemPreview title={t('customer-request.steps.completion-transcript.termination-date')}>
                                <>
                                    <ItemPreviewContent bold>{dateForContractTermination}</ItemPreviewContent>
                                    {displayTextRelationToRealEstate(content.contractTerminationReason?.reason?.code) && (
                                        <p>{t('customer-request.steps.completion-transcript.termination-date-statement')}</p>
                                    )}
                                </>
                            </ItemPreview>
                            {content.preferredContact?.phoneNumber && (
                                <ItemPreview title={t('customer-request.steps.termination-contract-date.phone-number-technician')}>
                                    <ItemPreviewContent bold>{formatPhoneNumber(content.preferredContact?.phoneNumber)}</ItemPreviewContent>
                                </ItemPreview>
                            )}
                        </>
                    )}

                    {attachments && <AppendixItem data={attachments} bold />}

                    <ItemPreview title={t('customer-request.steps.completion-transcript.invoice-send')}>
                        <ItemPreviewContent bold>
                            <span className={invoiceDeliveryInput ? 'd-none' : ''}>{content.invoiceDelivery?.email}</span>
                            {!invoiceDeliveryInput && (
                                <ClickableElement
                                    isText
                                    onClick={() => {
                                        setInvoiceDeliveryInput(true);
                                        nextTick(() => {
                                            setValue('invoiceDeliveryEmail', content.invoiceDelivery?.email);
                                        });
                                    }}
                                    className="text-decoration-underline-inverse float-right"
                                >
                                    {t('customer-request.steps.completion-transcript.edit')}
                                </ClickableElement>
                            )}
                            {invoiceDeliveryInput && (
                                <div className="text-center">
                                    <Email
                                        register={register}
                                        errors={errors}
                                        trigger={trigger}
                                        name="invoiceDeliveryEmail"
                                        label="E-mail"
                                        canBeLoggedInUser
                                    />
                                    <button type="submit" className="btn btn-primary">
                                        <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary ml-4"
                                        onClick={() => {
                                            setValue('invoiceDeliveryEmail', content.invoiceDelivery?.email);
                                            setInvoiceDeliveryInput(false);
                                        }}
                                    >
                                        <Trans i18nKey="common.cancel">Zrušiť</Trans>
                                    </button>
                                </div>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                    {content.bankConnection && (
                        <ItemPreview title={t('customer-request.steps.completion-transcript.bank-account-overpayment')}>
                            <ItemPreviewContent bold>
                                {formatIBAN(content.bankConnection?.iban)}
                                {content.bankConnection?.bicSwift && (
                                    <>
                                        <br />
                                        BIC/SWIFT: {content.bankConnection?.bicSwift}
                                    </>
                                )}
                            </ItemPreviewContent>
                        </ItemPreview>
                    )}
                    {content.address && (
                        <ItemPreview title={t('customer-request.steps.completion-transcript.postal-voucher-overpayment')}>
                            <ItemPreviewContent>
                                <p>
                                    <b>
                                        {content.address?.name || ''} <br />
                                        {content.address?.street || ''} {content.address?.number || ''}
                                        <br />
                                        {content.address?.city || ''} {content.address?.postalCode || ''}
                                        <br />
                                        {countryCodeList?.find((item) => item.uuid === content.address?.country?.uuid)?.name || ''}
                                    </b>
                                </p>
                                {content.address?.floor && (
                                    <>
                                        {t('customer-request.steps.address.floor')}
                                        <p>
                                            <b>{content.address?.floor}.</b>
                                        </p>
                                    </>
                                )}
                                {content.address?.flatNumber && (
                                    <>
                                        {t('customer-request.steps.address.appartments-number')}
                                        <p>
                                            <b>{content.address?.flatNumber}</b>
                                        </p>
                                    </>
                                )}
                            </ItemPreviewContent>
                        </ItemPreview>
                    )}
                </ListGroup>

                <div className="my-2">
                    <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                        <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                    </ClickableElement>
                </div>
                {(showNote || messageCounter > 0) && (
                    <BaseTextarea
                        ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                        errors={errors}
                        name={'note'}
                        maxLength={NOTE_MAX_COUNT}
                        label={t('customer-request.steps.attachments.title')}
                        counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                        optional
                    />
                )}

                <Button size="lg" color="primary" block type="submit" className="mt-4" disabled={invoiceDeliveryInput}>
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>

                {terminationReasonFullObject?.code !== 'SUPPLIER_CHANGE' && (
                    <div className="d-flex  mt-3 mx-1 p-4" style={{ backgroundColor: '#dddbdb', color: 'black', fontWeight: 700 }}>
                        <div className="mr-3 my-auto">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" color="gray" />
                        </div>
                        {t('customer-request.steps.completion-transcript.termination-final-note')}
                    </div>
                )}
            </form>
            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
