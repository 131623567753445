import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import { TFunction } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { AutoComplete, IFilterableColumnConfiguration, TextColumnFilter } from '../../../components/common';
import { ClickableElement, CommodityEnum, isObjectEmpty } from '../../../main';
import { DeliveryPointsTableFilterData } from '../component/list-delivery-points-table-filter';
import { AddressDetailsCell } from '../component/table-cells/address-details-cell';
import { BusinessPartnerDetailsCell } from '../component/table-cells/business-partner-details-cell';
import { CheckboxCell } from '../component/table-cells/checkbox-cell';
import { ExternalIdsCell } from '../component/table-cells/external-ids-cell';
import { ServiceTypesCell } from '../component/table-cells/service-types-cell';
import { ShareButtonCell } from '../component/table-cells/share-button-cell';
import { SharedIconCell } from '../component/table-cells/shared-icon-cell';

const textFilter = (fieldName: 'dpExternalId' | 'address' | 'customer', placeholder: string) => (
    filter: Partial<DeliveryPointsTableFilterData>,
    onChange: (change: Partial<DeliveryPointsTableFilterData>) => void,
    onSubmit: () => void,
): JSX.Element => (
    <TextColumnFilter
        key={fieldName}
        name={fieldName}
        placeholder={placeholder}
        filterValue={filter[fieldName] ?? ''}
        onFilterChange={(value: string) => onChange({ [fieldName]: value })}
        onFilterSubmit={onSubmit}
        className="mb-1"
    />
);

const clearAllFilter = () => {
    return (filter: Partial<DeliveryPointsTableFilterData>, onChange: (change: Partial<DeliveryPointsTableFilterData>) => void): JSX.Element => (
        <th scope="col" style={{ textAlign: 'center', fontWeight: 'normal', textTransform: 'none', fontSize: '0.875rem', letterSpacing: 'normal' }}>
            {!isObjectEmpty(filter) && (
                <ClickableElement
                    onClick={() =>
                        onChange({
                            dpExternalId: undefined,
                            address: undefined,
                            type: [],
                            customer: undefined,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" className="mr-1 text-decoration-none mx-auto d-block" />
                    <Trans i18nKey="common.tables.remove-filter" />
                </ClickableElement>
            )}
        </th>
    );
};

const autocompleteFilter = (t: TFunction) => (
    filter: Partial<DeliveryPointsTableFilterData>,
    onChange: (change: Partial<DeliveryPointsTableFilterData>) => void,
    onSubmit: () => void,
): JSX.Element => {
    const options = [
        { name: t('common.commodity.gas'), value: CommodityEnum.GAS },
        { name: t('common.commodity.electricity'), value: CommodityEnum.ELECTRICITY },
    ];
    return (
        <th scope="col" style={{ minWidth: '210px' }}>
            <div className="autocomplete-search-box">
                <AutoComplete<{
                    name: string;
                    value: CommodityEnum;
                }>
                    hideChevron
                    options={[
                        { name: t('common.commodity.gas'), value: CommodityEnum.GAS },
                        { name: t('common.commodity.electricity'), value: CommodityEnum.ELECTRICITY },
                    ]}
                    placeholder={t('common.choose-the-option')}
                    onSelect={(values) => onChange({ type: values.map((x) => x.value) })}
                    multiple
                    defaultSelected={options.filter((op) => filter.type?.includes(op.value))}
                    inputGroupAppendChildren={
                        <button type="button" className="btn text-primary" onClick={onSubmit}>
                            <i className="icon-search fa-lg" />
                        </button>
                    }
                />
            </div>
        </th>
    );
};

type ShareDeliveryPointsColumnConfigurationType = IFilterableColumnConfiguration<UnitedDeliveryPointSummary, DeliveryPointsTableFilterData>[];

export const getShareDeliveryPointsColumnConfiguration = (t: TFunction): ShareDeliveryPointsColumnConfigurationType => [
    {
        label: '',
        columnId: 'item-checkbox',
        columnWidth: '55px',
        cellComponent: (unitedDeliveryPoint) => <CheckboxCell key={`checkbox-${unitedDeliveryPoint.id}`} id={unitedDeliveryPoint.id} />,
    },
    {
        label: t('delivery-point.place-number'),
        columnId: 'place-number',
        columnWidth: '15%',
        filterComponent: textFilter('dpExternalId', t('delivery-point.place-number')),
        cellComponent: (unitedDeliveryPoint) => (
            <ExternalIdsCell key={`placeNumber-${unitedDeliveryPoint.id}`} deliveryPoints={unitedDeliveryPoint.deliveryPoints} />
        ),
    },
    {
        label: t('delivery-point.address'),
        columnId: 'street',
        // columnWidth: '20%',
        sortable: true,
        filterComponent: textFilter('address', t('delivery-point.address')),
        cellComponent: (unitedDeliveryPoint) => (
            <AddressDetailsCell key={`address-${unitedDeliveryPoint.id}`} fullAddress={unitedDeliveryPoint.address} />
        ),
    },
    {
        label: '',
        columnId: 'is-shared',
        columnWidth: '35px',
        cellComponent: (unitedDeliveryPoint) => (
            <SharedIconCell
                key={`isSharedIcon-${unitedDeliveryPoint.id}`}
                id={unitedDeliveryPoint.id}
                showIcon={!!unitedDeliveryPoint.sharing?.to?.length}
            />
        ),
    },
    {
        label: t('delivery-point.services'),
        columnId: 'type',
        columnWidth: '24%',
        sortable: true,
        cellComponent: (unitedDeliveryPoint) => (
            <ServiceTypesCell key={`serviceType-${unitedDeliveryPoint.id}`} deliveryPoints={unitedDeliveryPoint.deliveryPoints} />
        ),
        filterComponent: autocompleteFilter(t),
    },
    {
        label: t('delivery-point.customer'),
        columnId: 'bpName',
        // columnWidth: '24%',
        sortable: true,
        filterComponent: textFilter('customer', t('delivery-point.customer')),
        cellComponent: (unitedDeliveryPoint) => (
            <BusinessPartnerDetailsCell
                key={`businessPartnerDetails-${unitedDeliveryPoint.id}`}
                businessPartner={unitedDeliveryPoint.businessPartner}
            />
        ),
    },
    {
        label: '',
        columnId: 'share-button',
        columnWidth: '12%',
        cellComponent: (unitedDeliveryPoint) => <ShareButtonCell key={`shareButton-${unitedDeliveryPoint.id}`} id={unitedDeliveryPoint.id} />,
        filterComponent: clearAllFilter(),
    },
];
