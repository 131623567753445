import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions';
import { BaseRadio } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { IInvoiceDelivery } from '../../../../../models/customer-request-model';
import { InvoiceDeliveryStepEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { customerEmails, isInsertedEmailCustom } from '../../../../../utils/utils';
import { CustomerRequestDataEnum } from '../../../config/enums';
import InvoiceDeliveryFormFieldsOnlyEmail from './components/invoice-delivery-form-fields-only-email';

const BlockInvoiceDeliveryOnlyEmailCleanEnergy: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner, additionalData } = useSelector((state: IRootState) => state.customerRequest);
    const { customer } = useSelector((state: IRootState) => state.user);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.INVOICE_DELIVERY;

    const isEmailCustom = useMemo(() => isInsertedEmailCustom(content, additionalData, customer, businessPartner?.email), [
        content,
        additionalData,
        customer,
        businessPartner?.email,
    ]);

    const emailList = customerEmails(additionalData, customer, businessPartner?.email);

    const [displayEmailInput, setDisplayEmailInput] = useState<boolean>(isEmailCustom);
    const [radioButtonInput, setRadioButtonInput] = useState<string | undefined>(content.invoiceDelivery?.email || emailList[0] || '');

    const objectData = {
        type: InvoiceDeliveryStepEnum.EMAIL,
        email: content.invoiceDelivery?.email || emailList[0] || '',
    };

    const { register, handleSubmit, errors, setValue, trigger } = useForm<IInvoiceDelivery>({
        defaultValues: objectData,
    });

    const onSubmit = (data: IInvoiceDelivery) => {
        const newData = { type: data.type, email: displayEmailInput ? data.email : radioButtonInput };
        const payload: CustomerRequestPayloadType = {
            [objectName]: newData,
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {!displayEmailInput && (
                <>
                    {emailList.map((email, index) => (
                        <Card className="mb-3" key={`email_${index}`}>
                            <CardBody>
                                <BaseRadio
                                    value={businessPartner?.email}
                                    id={`businessPartnerEmailRadio_${index}`}
                                    name={`email_${index}`}
                                    className="mt-0"
                                    label={<>{email}</>}
                                    onClick={() => {
                                        setRadioButtonInput(email);
                                        setValue('email', email);
                                    }}
                                    checked={radioButtonInput === email}
                                />
                            </CardBody>
                        </Card>
                    ))}
                </>
            )}

            <div className="mb-5">
                <div
                    className="cursor-pointer clickable-element "
                    style={{ textAlign: 'center', textDecoration: 'underline' }}
                    onClick={() => {
                        if (displayEmailInput) {
                            setRadioButtonInput(emailList[0]);
                        }

                        setDisplayEmailInput(!displayEmailInput);
                    }}
                >
                    {displayEmailInput ? (
                        <p>
                            <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.chose-from-list" />
                        </p>
                    ) : (
                        <p className="mb-0">
                            <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.type-email" />
                        </p>
                    )}
                </div>
                <div style={displayEmailInput ? {} : { display: 'none' }}>
                    <InvoiceDeliveryFormFieldsOnlyEmail register={register} errors={errors} trigger={trigger} />
                </div>
            </div>
            <p style={{ width: '300px', margin: '10px auto', textAlign: 'center' }}>
                <i className="icon-circle-info-16 fa-lg"></i>
                <br />
                <Trans i18nKey="customer-request.steps.block-clear-electricity-invoice-delivery.ee-invoice-info"></Trans>
            </p>

            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockInvoiceDeliveryOnlyEmailCleanEnergy;
