import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClickableElement } from '../../../../../../components/common/clickable-element';
import HelpModal from '../../../../../../components/help/help-modal';
import { IMarketingConsents } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IMarketingConsents;
    bold?: boolean;
};

const ConsentsItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const { COMMUNICATION_INT, COMMUNICATION_LET, COMMUNICATION_PAG, COMMUNICATION_TEL, NEWS_3RD_PARTY, NEWS_SPP } = data;
    return (
        <>
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />
            {data && (
                <ItemPreview title={t('settings.marketing-consents.communication-option')}>
                    {COMMUNICATION_INT != null && (
                        <ItemPreviewContent bold={bold}>
                            {t('settings.marketing-consents.email')}: <b>{COMMUNICATION_INT ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                    {COMMUNICATION_PAG != null && (
                        <ItemPreviewContent bold={bold}>
                            {t('settings.marketing-consents.sms')}: <b>{COMMUNICATION_PAG ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                    {COMMUNICATION_TEL != null && (
                        <ItemPreviewContent bold={bold}>
                            {t('settings.marketing-consents.phone')}: <b>{COMMUNICATION_TEL ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                    {COMMUNICATION_LET != null && (
                        <ItemPreviewContent bold={bold}>
                            {t('settings.marketing-consents.letter')}: <b>{COMMUNICATION_LET ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                    {NEWS_3RD_PARTY != null && (
                        <ItemPreviewContent bold={bold}>
                            {t('settings.marketing-consents.news-3rd-party')}: <b>{NEWS_3RD_PARTY ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                    {NEWS_SPP != null && (
                        <ItemPreviewContent bold={bold}>
                            <Trans
                                i18nKey="settings.marketing-consents.info-processing-request-detail"
                                components={{
                                    Link: (
                                        <ClickableElement
                                            isText
                                            onClick={() => setIsPersonalInfoProcessingConcentOpen(true)}
                                            className="text-decoration-underline-inverse text-black"
                                        >
                                            &nbsp
                                        </ClickableElement>
                                    ),
                                }}
                            />
                            : <b>{NEWS_SPP ? t('common.yes') : t('common.no')}</b>
                        </ItemPreviewContent>
                    )}
                </ItemPreview>
            )}
        </>
    );
};

export default ConsentsItem;
