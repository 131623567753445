import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import { CharacterFilter } from '../../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../../components/common/input-with-append';
import HelpLinkWithModal from '../../../../../../../components/help/help-link-with-modal';
import { useFormRules } from '../../../../../../../hooks/use-form-rules';
import { useQueryWithError } from '../../../../../../../hooks/use-query-with-error';
import { buildDeliveryPointQueryKey, CommodityEnum, DatePicker, formatDate, Input, IRootState, useApi } from '../../../../../../../main';
import { DefaultFormType } from '../../../../../../../models/model';

type Props = DefaultFormType & {
    deliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary;
};

const DetailIncorrectMeterReading: React.FC<Props> = ({ register, errors, trigger, setValue, getValues, deliveryPoint, watch }) => {
    const { requiredTrimed, formRules, dateRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    const [t] = useTranslation();
    const api = useApi();
    const date = watch('date');

    const { data: dataDeliveryPointContractData } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        buildDeliveryPointQueryKey(deliveryPoint?.contract?.id),
        async () =>
            deliveryPoint?.contract?.id
                ? api.deliveryPoints.getByContractUuid(deliveryPoint.contract.id, {}, { secure: true }).then((res) => res.data)
                : null,
    );

    const dual = useMemo(() => {
        return dataDeliveryPointContractData?.type === CommodityEnum.ELECTRICITY && dataDeliveryPointContractData?.contract?.eeTariffCount === 2;
    }, [dataDeliveryPointContractData]);
    return (
        <>
            <div className="text-center  my-2 my-lg-5">
                <h3>{<Trans i18nKey="customer-request.steps.reason-for-claim-detail.title-2">Údaje k nesprávnemu odpočtu</Trans>}</h3>
            </div>
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                name="meterNumber"
                label={<Trans i18nKey="delivery-point.detail.contract-data.meter-device-number" />}
                touched={touched}
                disabled={deliveryPoint?.deviceNumber ? true : false}
            />
            <div className="mb-4">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                <HelpLinkWithModal
                    className="d-inline"
                    classNameClickableElement="text-decoration-underline-inverse"
                    linkTitle="customer-request.steps.reason-for-claim-detail.where-can-be-data-found"
                    field="NZD_MORE_ABOUT_METER_NUMBER"
                />
            </div>
            <FormGroup className="d-flex flex-row mb-3">
                <DatePicker
                    register={register({ ...formRules.required, ...dateRules().notFutureDate })}
                    name="date"
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    errors={errors}
                    label={t('customer-request.steps.reason-for-claim-detail.date-of-meter-reading')}
                    maxDate={new Date()}
                    touched={touched}
                />
            </FormGroup>
            {dataDeliveryPointContractData?.type === CommodityEnum.ELECTRICITY &&
                (dual ? (
                    <Row>
                        <Col className="col-12">
                            <InputWithAppend
                                type="number"
                                aria-describedby="inputPayment"
                                placeholder=" "
                                name="valueHigh"
                                ref={register({
                                    ...formRules.requiredInteger,
                                    ...formRules.min(0),
                                })}
                                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                errors={errors}
                                trigger={trigger}
                                label={
                                    moment(date).isValid()
                                        ? t('customer-request.steps.reason-for-claim-detail.required-meter-value-to-date', {
                                              date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                                          }) +
                                          ' - ' +
                                          t('delivery-point.detail.consumption.meter-value-electricity-high')
                                        : t('customer-request.steps.reason-for-claim-detail.required-meter-value')
                                }
                                inputGroupAppendChildren={
                                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                        kWh
                                    </button>
                                }
                            />
                            <InputWithAppend
                                type="number"
                                aria-describedby="inputPayment"
                                placeholder=" "
                                name="valueLow"
                                ref={register({
                                    ...formRules.requiredInteger,
                                    ...formRules.min(0),
                                })}
                                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                errors={errors}
                                trigger={trigger}
                                label={
                                    moment(date).isValid()
                                        ? t('customer-request.steps.reason-for-claim-detail.required-meter-value-to-date', {
                                              date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                                          }) +
                                          ' - ' +
                                          t('delivery-point.detail.consumption.meter-value-electricity-low')
                                        : t('customer-request.steps.reason-for-claim-detail.required-meter-value')
                                }
                                inputGroupAppendChildren={
                                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                        kWh
                                    </button>
                                }
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col className="col-12">
                            <InputWithAppend
                                type="number"
                                aria-describedby="inputPayment"
                                placeholder=" "
                                name="valueHigh"
                                ref={register({
                                    ...formRules.requiredInteger,
                                    ...formRules.min(0),
                                })}
                                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                errors={errors}
                                trigger={trigger}
                                label={
                                    moment(date).isValid()
                                        ? t('customer-request.steps.reason-for-claim-detail.required-meter-value-to-date', {
                                              date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                                          })
                                        : t('customer-request.steps.reason-for-claim-detail.required-meter-value')
                                }
                                inputGroupAppendChildren={
                                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                        kWh
                                    </button>
                                }
                            />
                        </Col>
                    </Row>
                ))}
            {dataDeliveryPointContractData?.type === CommodityEnum.GAS && (
                <Row>
                    <Col className="col-12">
                        <InputWithAppend
                            type="number"
                            aria-describedby="inputPayment"
                            placeholder=" "
                            name="valueHigh"
                            ref={register({
                                ...formRules.requiredInteger,
                                ...formRules.min(0),
                            })}
                            characterFilter={CharacterFilter.POSITIVE_INTEGER}
                            errors={errors}
                            min={`${0}`}
                            trigger={trigger}
                            label={
                                moment(date).isValid()
                                    ? t('customer-request.steps.reason-for-claim-detail.required-meter-value-to-date', {
                                          date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                                      })
                                    : t('customer-request.steps.reason-for-claim-detail.required-meter-value')
                            }
                            inputGroupAppendChildren={
                                <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                    m<sup>3</sup>
                                </button>
                            }
                        />
                    </Col>
                </Row>
            )}
            <Trans i18nKey="customer-request.steps.gauge-condition.meter-condition-help2" />
            <div className="my-4">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                <HelpLinkWithModal
                    className="d-inline"
                    classNameClickableElement="text-decoration-underline-inverse"
                    title="customer-request.steps.gauge-condition.meter-reading-help"
                    field={
                        dataDeliveryPointContractData?.type === 'ZP'
                            ? 'SOT_GAS_METER_WHERE_I_FIND_IT'
                            : dataDeliveryPointContractData?.contract?.eeTariffCount === 2
                            ? 'SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT'
                            : 'SOT_ELECTRICITY_METER_WHERE_I_FIND_IT'
                    }
                />
            </div>
        </>
    );
};

export default DetailIncorrectMeterReading;
