import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import Checkbox from '../../../../../components/common/checkbox';
import { CommodityEnum, dateFromIsoToFormat, LoadingIndicator, PRICE_LINK_EE, PRICE_LINK_ZP, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { clearObjectProperties, formatAddress, formatBusinessPartnerName } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { CustomerRequestTypeEnum } from '../../../config/enums';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

interface IBlockCompletionRequestOfficialTestMeterShort {
    condition: boolean;
}

export const BlockCompletionConfirmationAmountLiabilities: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const { deliveryPoint, content, customerRequestTemplate, metadata, businessPartner, partnersDeliveryPoints, paid } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { formRules } = useFormRules();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm<IBlockCompletionRequestOfficialTestMeterShort>({
        defaultValues: {},
    });

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const onFormSubmit = (formData: IBlockCompletionRequestOfficialTestMeterShort) => {
        const entity = clearObjectProperties({
            contractId: metadata?.contractId,
            deliveryPointId: metadata?.deliveryPointId,
            contractAccountId: metadata.contractAccountId,
        });

        const data: any = {
            content: {
                metadata: {
                    businessPartnerId: metadata?.businessPartnerId ?? businessPartner?.id,
                    deliveryPointId: deliveryPoint && metadata?.deliveryPointId,
                    contractId: deliveryPoint && metadata?.contractId,
                },
                date: content.date,
                type: CustomerRequestTypeEnum.ZOM_POVZ,
                entityReferences: deliveryPoint && Object.keys(entity).length > 0 ? [entity] : undefined,
                paymentApproval: formData.condition,
            },
            customerRequestTemplate,
        };
        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const checkBoxLabel = paid?.paid && (
        <>
            <Trans i18nKey="customer-request.steps.accept-current-price-list" /> <br />
            <a href={paid.comodity === CommodityEnum.ELECTRICITY ? PRICE_LINK_EE : PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                {t('customer-request.steps.current-price-list')}&nbsp;
            </a>
        </>
    );

    const businessPartnerDPcount = useMemo(
        () =>
            partnersDeliveryPoints && businessPartner?.id && partnersDeliveryPoints[businessPartner.id].length
                ? partnersDeliveryPoints[businessPartner.id].length
                : 0,
        [partnersDeliveryPoints, businessPartner],
    );

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('enums.CustomerRequestType.ZOM_POVZ')}>
                    <ItemPreviewContent bold>
                        {businessPartner ? (
                            <>
                                {t('customer-request.steps.completion.for-bp')}:{' '}
                                {`${formatBusinessPartnerName(businessPartner)} - ${t('settings.e-invoices.delivery-points-count', {
                                    count: businessPartnerDPcount,
                                })}`}{' '}
                                <br />({t('delivery-point.customer-number')} {businessPartner.externalId})
                            </>
                        ) : (
                            <>
                                {t('customer-request.steps.completion.for-dp')}:{' '}
                                {`${formatAddress(deliveryPoint?.address)} · ${t(`enums.CommodityEnum.${deliveryPoint?.type}`)}`} <br />(
                                {t('delivery-point.contract-number', {
                                    number: deliveryPoint?.contract?.externalId,
                                })}
                                )
                            </>
                        )}
                    </ItemPreviewContent>
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.date-selection.required-date')}>
                    <ItemPreviewContent bold>{dateFromIsoToFormat(content.date, 'DD.MM.YYYY')}</ItemPreviewContent>
                </ItemPreview>
            </ListGroup>

            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                {checkBoxLabel && (
                    <div className="p-3 my-4 rounded" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            id="condition"
                            name="condition"
                            label={checkBoxLabel}
                            className="mb-1"
                        />
                    </div>
                )}

                <Button type="submit" size="lg" color="primary" block className="mt-5">
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>
            </form>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
