import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import AddPhoneNumber from '../../../../../components/common/phone-confirm/add-phone-number';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { StepsEnum } from '../phone-number-verification';

type PropsType = {
    phoneNumber: string;
    setStep: (nextStep: StepsEnum, phoneNumber: string, challengeCode: string) => void;
    onDismiss: () => void;
};

const EnterPhoneNumberScreen: React.FC<PropsType> = ({ setStep, onDismiss }) => {
    const [isModalOpened] = useState<boolean>(true);

    const onSubmit = (phone: string, challengeCode: string) => {
        setStep(StepsEnum.VerifyPhoneNumber, phone, challengeCode);
    };

    return (
        <Modal isOpen={isModalOpened} centered>
            <ModalBody>
                <button type="button" onClick={onDismiss} className="close ml-auto" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">
                    <Trans i18nKey="phone-validation.header">Overenie telefónneho čísla</Trans>
                </h3>

                <hr />
                <p className="font-weight-bold">
                    <Trans i18nKey="phone-validation.more-security-reason">
                        Pre zvýšenie bezpečnosti potrebujeme ešte overiť vaše telefónne číslo.
                    </Trans>
                </p>
                <HelpLinkWithModal className="mb-5" title="phone-validation.why" field="OTC_PHONE_VERIFICATION_PURPOSE" />

                <AddPhoneNumber onSuccess={onSubmit} />

                <p className="text-center mt-5">
                    <ClickableElement onClick={onDismiss} isText>
                        <Trans i18nKey="phone-validation.setup-later">Nastaviť neskôr</Trans>
                    </ClickableElement>
                </p>
            </ModalBody>
        </Modal>
    );
};

export default EnterPhoneNumberScreen;
