import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary as DeliveryPointSummary,
    SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { hasRequestOriginalValues, isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data?: DeliveryPointSummary;
    bold?: boolean;
    requestContent: any;
    code: SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent['type'];
};

const DeliveryPointSummaryItem: React.FC<Props> = ({ data, bold, requestContent, code }) => {
    const [t] = useTranslation();
    if (!data) return null;
    const { address } = data;
    let displayAddress = address;
    if (code && requestContent.originalValues?.deliveryPointAddress && hasRequestOriginalValues(code)) {
        displayAddress = {
            ...requestContent.originalValues.deliveryPointAddress,
            streetNumber: requestContent.originalValues.deliveryPointAddress.number,
            zipCode: requestContent.originalValues.deliveryPointAddress.postalCode,
            country: address?.country,
        };
    }

    return (
        <>
            {address && !isObjectEmpty(address) && (
                <ItemPreview title={t('customer-request.steps.delivery-point.title')}>
                    <>
                        <ItemPreviewContent bold={bold}>{`${displayAddress?.street} ${displayAddress?.streetNumber} - ${t(
                            `enums.CommodityEnum.${data.type}`,
                        )}`}</ItemPreviewContent>
                        <ItemPreviewContent bold={bold}>{`${displayAddress?.zipCode}, ${displayAddress?.city}${
                            displayAddress?.country === 'SK' ? '' : ', ' + displayAddress?.country
                        }`}</ItemPreviewContent>
                        <ItemPreviewContent bold={bold}>
                            {t('delivery-point.contract-number', {
                                number: data?.contract?.externalId || '-',
                            })}
                        </ItemPreviewContent>
                    </>
                </ItemPreview>
            )}
        </>
    );
};

export default DeliveryPointSummaryItem;
