import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { BaseButton } from '../../../components/common';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

type Props = {
    loadDeliveryPoint: () => void;
};

const ListDeliveryPointsFirstLoad = ({ loadDeliveryPoint }: Props): ReactElement => {
    return (
        <div className="row justify-content-center">
            <div className="col col-lg-6 text-center">
                <div className="card my-3">
                    <div className="card-body text-center px-3 py-5">
                        <p>
                            <Trans i18nKey="delivery-point.list-delivery-points.dont-see-you-delivery-points">
                                Ste zákazník SPP a nevidíte svoje odberné miesta? Začnite stlačením tlačidla:
                            </Trans>
                        </p>
                        <FontAwesomeIcon icon={faArrowDown} className="fa-lg text-center m-auto d-block" />
                        <BaseButton onClick={loadDeliveryPoint} className="btn btn-primary mt-4">
                            <Trans i18nKey="delivery-point.list-delivery-points.load-my-delivery-points">
                                Načítať moje odberné miesta
                            </Trans>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListDeliveryPointsFirstLoad;
