import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { LoadingIndicator } from '../../../components/common';
import { ClickableElement } from '../../../components/common/clickable-element';
import HelpLinkWithModal from '../../../components/help/help-link-with-modal';
import HelpText from '../../../components/help/help-text';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { useApi } from '../../../main';
import { IRootState } from '../../../reducers';
import DeactivateAccountForm from './deactivate-account-form';

interface IDeactivateAccountModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

const DeactivateAccountModal: React.FC<IDeactivateAccountModalProps> = ({ isOpen, onCloseModal }) => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const { customer: loggedInCustomer, employee: loggedInEmployee } = useSelector((store: IRootState) => store.user);
    const isEmployee = !!loggedInEmployee;

    const api = useApi();
    const [mutateDeactivation, { isLoading }] = useMutationWithError(
        async (deactivationData: { reason: string }) =>
            loggedInCustomer?.id != null
                ? api.customers.verifyDeleteCustomerAccount(loggedInCustomer.id, { reason: deactivationData.reason }, { secure: true })
                : null,
        {
            onSuccess: () => {
                setIsSubmitted(true);
            },
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const [mutateResendDeactivation, { isLoading: isLoadingResend }] = useMutationWithError(async () =>
        loggedInCustomer?.id == null
            ? null
            : api.customers.resendDeleteCustomerAccount(loggedInCustomer.id, { secure: true }).then((res) => res.data),
    );
    const onResend = () => mutateResendDeactivation();

    const onSubmit = (values: { consequencesAccepted: boolean }) => {
        if (values.consequencesAccepted) {
            mutateDeactivation({ reason: '' });
        }
    };

    const closeModal = () => {
        setIsSubmitted(false);
        onCloseModal();
    };

    if (loggedInCustomer == null) return null;
    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="settings.deactivate-account.account-deactivation" />
                    </h3>
                    <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody className="px-4 pb-5">
                    {(isLoading || isLoadingResend) && <LoadingIndicator />}

                    {!isSubmitted && (
                        <>
                            <div className="alert p-4 d-flex bg-danger text-white">
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                                <div className="ml-4 font-weight-bold">
                                    <Trans i18nKey="settings.deactivate-account.warning-lose-SPP-access" />
                                    {'. '}
                                    <Trans i18nKey="settings.deactivate-account.action-is-irreversible" />
                                    {'. '}
                                    <Trans i18nKey="settings.deactivate-account.deactivation-is-not-recommended" />
                                    {'. '}
                                </div>
                            </div>
                            <DeactivateAccountForm onSubmit={onSubmit} disabled={isEmployee} />
                        </>
                    )}

                    {isSubmitted && (
                        <>
                            <h4 className="mb-3">
                                <Trans i18nKey="settings.deactivate-account.deactivation-link-sent" />
                            </h4>
                            <p>
                                <Trans
                                    i18nKey="settings.deactivate-account.click-on-deactivation-link"
                                    components={{
                                        Link: (
                                            <a href={`mailto:${loggedInCustomer.email}`} className="font-weight-bold">
                                                &nbsp;
                                            </a>
                                        ),
                                    }}
                                    values={{ email: loggedInCustomer.email }}
                                />
                                {'. '}
                                <Trans i18nKey="settings.deactivate-account.until-then-account-remains-active" />.
                            </p>
                            <p>
                                <p>
                                    <Trans i18nKey="settings.deactivate-account.check-spam-folder" />
                                    {'. '}
                                    <Trans i18nKey="settings.deactivate-account.if-problems-persist" />
                                </p>
                            </p>

                            <div className="mt-2">
                                <HelpText screen="COM" field="COM_FAST_CONTACT" />
                            </div>

                            <div className="text-center mt-2">
                                <Trans i18nKey="settings.deactivate-account.did-not-receive-email" />
                                <br />
                                <ClickableElement className="text-decoration-underline-inverse" isText onClick={onResend}>
                                    <Trans i18nKey="settings.deactivate-account.send-again">Poslať znova</Trans>
                                </ClickableElement>
                            </div>

                            <HelpLinkWithModal className="mt-4" showAsCard field="NUC_ACCOUNT_DEACTIVATION_CONSEQUENCES" />
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default DeactivateAccountModal;
