import AdditionalServiceItem from '../screen/steps-customer-request/block-completion/items/additional-service-item';
import AddressItem from '../screen/steps-customer-request/block-completion/items/address-item';
import AdvanceItem from '../screen/steps-customer-request/block-completion/items/advance-item';
import AdvancePaymentPeriodItem from '../screen/steps-customer-request/block-completion/items/advance-payment-period-item';
import ApprovalsItem from '../screen/steps-customer-request/block-completion/items/agreements-item';
import AmountItem from '../screen/steps-customer-request/block-completion/items/amount-item';
import AssumedConsumptionItem from '../screen/steps-customer-request/block-completion/items/assumed-consumption-item';
import BankConnectionItem from '../screen/steps-customer-request/block-completion/items/bank-connection-item';
import BusinessPartnerItem from '../screen/steps-customer-request/block-completion/items/business-partner-item';
import ComplainedAdvanceListItem from '../screen/steps-customer-request/block-completion/items/complained-advance-list-item';
import ComplainedInvoiceItem from '../screen/steps-customer-request/block-completion/items/complained-invoice-item';
import ComplainedInvoiceProblemDescriptionItem from '../screen/steps-customer-request/block-completion/items/complained-invoice-problem-description-item';
import ComplaintItem from '../screen/steps-customer-request/block-completion/items/complaint-item';
import ConsentsItem from '../screen/steps-customer-request/block-completion/items/consents-item';
import ContactItem from '../screen/steps-customer-request/block-completion/items/contact-item';
import ContractDeliveryItem from '../screen/steps-customer-request/block-completion/items/contract-delivery-item';
import ContractTerminationDateItem from '../screen/steps-customer-request/block-completion/items/contract-termination-date-item';
import ContractTerminationReasonItem from '../screen/steps-customer-request/block-completion/items/contract-termination-reason-item';
import CorrespondanceAddressItem from '../screen/steps-customer-request/block-completion/items/correspondance-item';
import DateItem from '../screen/steps-customer-request/block-completion/items/date-item';
import DealAmountItem from '../screen/steps-customer-request/block-completion/items/deal-amount-item';
import DeliveryPointEeConnectionItem from '../screen/steps-customer-request/block-completion/items/delivery-point-ee-connection-item';
import DeliveryPointItem from '../screen/steps-customer-request/block-completion/items/delivery-point-item';
import DeliveryPointZpConnectionItem from '../screen/steps-customer-request/block-completion/items/delivery-point-zp-connection-item';
import EeConnectionDetailItem from '../screen/steps-customer-request/block-completion/items/ee-connection-detail-item';
import InvoiceDeliveryItem from '../screen/steps-customer-request/block-completion/items/invoice-delivery-item';
import InvoiceNumberItem from '../screen/steps-customer-request/block-completion/items/invoice-number-item';
import LastInvoiceDeliveryItem from '../screen/steps-customer-request/block-completion/items/last-invoice-delivery-item';
import MeterReadingItem from '../screen/steps-customer-request/block-completion/items/meter-reading-item';
import NewAddressItem from '../screen/steps-customer-request/block-completion/items/new-address-item';
import NewAdvancePaymentPeriodItem from '../screen/steps-customer-request/block-completion/items/new-advance-payment-period';
import NewBankConnectionItem from '../screen/steps-customer-request/block-completion/items/new-bank-connection-item';
import NewBusinessPartnerItem from '../screen/steps-customer-request/block-completion/items/new-business-partner';
import NewBusinessPartnerDataItem from '../screen/steps-customer-request/block-completion/items/new-business-partner-data';
import NewContactItem from '../screen/steps-customer-request/block-completion/items/new-contact-item';
import NewCorrespondenceAddressItem from '../screen/steps-customer-request/block-completion/items/new-correspondance-address-item';
import NewDeliveryPointAddressItem from '../screen/steps-customer-request/block-completion/items/new-delivery-point-address';
import NewLastNameItem from '../screen/steps-customer-request/block-completion/items/new-last-name-item';
import NewPaymentMethodItem from '../screen/steps-customer-request/block-completion/items/new-payment-method';
import OverpaymentSettlementItem from '../screen/steps-customer-request/block-completion/items/overpayment-settlement-item';
import OwnerAddressItem from '../screen/steps-customer-request/block-completion/items/owner-address';
import OwnerContactItem from '../screen/steps-customer-request/block-completion/items/owner-contact-tem';
import OwnerCorrespondenceAddressItem from '../screen/steps-customer-request/block-completion/items/owner-corespondence-address';
import OwnerItem from '../screen/steps-customer-request/block-completion/items/owner-item';
import PaymentMethodItem from '../screen/steps-customer-request/block-completion/items/payment-method-item';
import ProductsItem from '../screen/steps-customer-request/block-completion/items/products-item';
import ReadingItem from '../screen/steps-customer-request/block-completion/items/reading-item';
import ReadingsItem from '../screen/steps-customer-request/block-completion/items/readings-item';
import ReasonEnumItem from '../screen/steps-customer-request/block-completion/items/reason-enum-item';
import StatementItem from '../screen/steps-customer-request/block-completion/items/statement-item';
import TariffItem from '../screen/steps-customer-request/block-completion/items/tariff-item';
import TechnicianPhoneItem from '../screen/steps-customer-request/block-completion/items/technician-phone';
import ChangeLastNameReasonItem from './../screen/steps-customer-request/block-completion/items/change-last-name-reason-item';
import NoteItem from './../screen/steps-customer-request/block-completion/items/note-item';
import ProductLevelItem from './../screen/steps-customer-request/block-completion/items/product-level-item';
import UnderchangeSettlementInvoiceItem from './../screen/steps-customer-request/block-completion/items/undercharge-settlement-invoice-item';
import { CustomerRequestDataEnum, CustomerRequestEnum, CustomerRequestTypeEnum } from './enums';
import {
    carbonShankShort,
    changeElectricityRateShort,
    changeGasTariffShort,
    claimInvoiceShort,
    cleanElectricityShort,
    complaintShort,
    confirmationAmountLiabilitiesShort,
    confirmationContractualRelationshipShort,
    eeSupplyNew,
    repaymentAgreementShort,
    requestInteruptionAndResumptionConsuptionShort,
    requestInvoiceOutCycleShort,
    requestOfficialTestMeterShort,
    requestTerminationContractShort,
    transcriptNew,
    transcriptNewCustomer,
    zpSupplyNew,
} from './request-config';
import { ComplaintDataType, CustomerRequestListType } from './types';

export const requestList: CustomerRequestListType = {
    [CustomerRequestEnum.CHANGE_ELECTRICITY_RATE_SHORT]: changeElectricityRateShort, // shorter (quick) version of classic request
    [CustomerRequestEnum.CHANGE_GAS_TARIFF_SHORT]: changeGasTariffShort, // shorter (quick) version of classic request
    [CustomerRequestEnum.CLAIM_INVOICE_SHORT]: claimInvoiceShort, // shorter (quick) version of classic request
    [CustomerRequestEnum.COMPLAINT_SHORT]: complaintShort, // shorter (quick) version of classic request
    [CustomerRequestEnum.TRANSCRIPT_NEW]: transcriptNew,
    [CustomerRequestEnum.TRANSCRIPT_NEW_CUSTOMER]: transcriptNewCustomer,
    [CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW]: eeSupplyNew,
    [CustomerRequestEnum.GAS_SUPPLY_NEW]: zpSupplyNew,
    [CustomerRequestEnum.REQUEST_TERMINATION_CONTRACT_SHORT]: requestTerminationContractShort,
    [CustomerRequestEnum.REQUEST_INTERRUPTION_AND_RESUMPTION_CONSUPTION_SHORT]: requestInteruptionAndResumptionConsuptionShort,
    [CustomerRequestEnum.REQUEST_OFFICIAL_TEST_METER_SHORT]: requestOfficialTestMeterShort,
    [CustomerRequestEnum.REQUEST_INVOICE_OUT_CYCLE_SHORT]: requestInvoiceOutCycleShort,
    [CustomerRequestEnum.REPAYMENT_AGREEMENT_SHORT]: repaymentAgreementShort,
    [CustomerRequestEnum.CONFIRMATION_AMOUNT_LIABILITIES_SHORT]: confirmationAmountLiabilitiesShort,
    [CustomerRequestEnum.CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT]: confirmationContractualRelationshipShort,
    [CustomerRequestEnum.CARBON_SHANK_SHORT]: carbonShankShort,
    [CustomerRequestEnum.CLEAN_ELECTRICITY_SHORT]: cleanElectricityShort,
};

export const complaintDataComponent: ComplaintDataType = {
    [CustomerRequestDataEnum.ADDITIONAL_SERVICES]: AdditionalServiceItem,
    [CustomerRequestDataEnum.ADDRESS]: AddressItem,
    [CustomerRequestDataEnum.ADVANCE]: AdvanceItem,
    [CustomerRequestDataEnum.ADVANCE_PAYMENT]: AdvanceItem,
    [CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD]: AdvancePaymentPeriodItem,
    [CustomerRequestDataEnum.AMOUNT]: AmountItem,
    [CustomerRequestDataEnum.APPROVALS]: ApprovalsItem,
    [CustomerRequestDataEnum.ASSUMED_CONSUMPTION]: AssumedConsumptionItem,
    [CustomerRequestDataEnum.ASSUMED_CONSUMPTION_ZP]: AssumedConsumptionItem,
    [CustomerRequestDataEnum.ASSUMED_CONSUMPTION_EE]: AssumedConsumptionItem,
    [CustomerRequestDataEnum.BANK_CONNECTION]: BankConnectionItem,
    [CustomerRequestDataEnum.BILLING_DELIVERY]: InvoiceDeliveryItem,
    [CustomerRequestDataEnum.BUSINESS_PARTNER]: BusinessPartnerItem,
    [CustomerRequestDataEnum.COMPLAINED_ADVANCES]: ComplainedAdvanceListItem,
    [CustomerRequestDataEnum.COMPLAINED_INVOICE]: ComplainedInvoiceItem,
    [CustomerRequestDataEnum.COMPLAINED_INVOICE_PROBLEM_DESCRIPTION]: ComplainedInvoiceProblemDescriptionItem,
    [CustomerRequestDataEnum.COMPLAINT]: ComplaintItem,
    [CustomerRequestDataEnum.CONECTION]: EeConnectionDetailItem,
    [CustomerRequestDataEnum.CONECTION_DETAIL_EE]: EeConnectionDetailItem,
    [CustomerRequestDataEnum.CONTACT]: ContactItem,
    [CustomerRequestDataEnum.CONTRACT_TERMINATION_DATE]: ContractTerminationDateItem,
    [CustomerRequestDataEnum.CONTRACT_TERMINATION_REASON]: ContractTerminationReasonItem,
    [CustomerRequestDataEnum.CONTRACT_DELIVERY]: ContractDeliveryItem,
    [CustomerRequestDataEnum.CORRESPONDACE_ADDRESS]: CorrespondanceAddressItem,
    [CustomerRequestDataEnum.DATE]: DateItem,
    [CustomerRequestDataEnum.DEAL_AMOUNT]: DealAmountItem,
    [CustomerRequestDataEnum.DEAL_AMOUNT_EE]: DealAmountItem,
    [CustomerRequestDataEnum.DEAL_AMOUNT_ZP]: DealAmountItem,
    [CustomerRequestDataEnum.DELIVERY_POINT]: DeliveryPointItem,
    [CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_EE]: DeliveryPointEeConnectionItem,
    [CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_ZP]: DeliveryPointZpConnectionItem,
    [CustomerRequestDataEnum.EE_METER_READING]: MeterReadingItem,
    [CustomerRequestDataEnum.EE_TARIF]: TariffItem,
    [CustomerRequestDataEnum.FINAL_INVOICE_DELIVERY]: InvoiceDeliveryItem,
    [CustomerRequestDataEnum.INVOICE_DELIVERY]: InvoiceDeliveryItem,
    [CustomerRequestDataEnum.INVOICE_NUMBER]: InvoiceNumberItem,
    [CustomerRequestDataEnum.INVOICE_NUMBERS]: InvoiceNumberItem,
    [CustomerRequestDataEnum.LAST_INVOICE]: LastInvoiceDeliveryItem,
    [CustomerRequestDataEnum.LAST_NAME_CHANGE_REASON]: ChangeLastNameReasonItem,
    [CustomerRequestDataEnum.METER_READING]: MeterReadingItem,
    [CustomerRequestDataEnum.METER_READING_EE]: MeterReadingItem,
    [CustomerRequestDataEnum.METER_READING_ZP]: MeterReadingItem,
    [CustomerRequestDataEnum.NEW_ADDRESS]: NewAddressItem,
    [CustomerRequestDataEnum.NEW_BANK_CONNECTION]: NewBankConnectionItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER]: NewBusinessPartnerItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_DATA]: NewBusinessPartnerDataItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_ADDRESS]: NewAddressItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_ADVANCE_PAYMENT_PERIOD]: NewAdvancePaymentPeriodItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_CONTACT]: NewContactItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_CORRESPONDENCE_ADDRESS]: NewCorrespondenceAddressItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_INVOICE_DELIVERY]: InvoiceDeliveryItem,
    [CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_PAYMENT_METHOD]: NewPaymentMethodItem,
    [CustomerRequestDataEnum.NEW_CORRESPONDACE_ADDRESS]: NewCorrespondenceAddressItem,
    [CustomerRequestDataEnum.NEW_DELIVERY_POINT_ADDRESS]: NewDeliveryPointAddressItem,
    [CustomerRequestDataEnum.NEW_LAST_NAME]: NewLastNameItem,
    [CustomerRequestDataEnum.NOTE]: NoteItem,
    [CustomerRequestDataEnum.OVERPAYMENT_SETTLEMENT]: OverpaymentSettlementItem,
    [CustomerRequestDataEnum.OWNER]: OwnerItem,
    [CustomerRequestDataEnum.OWNER_ADDRESS]: OwnerAddressItem,
    [CustomerRequestDataEnum.OWNER_CONTACT]: OwnerContactItem,
    [CustomerRequestDataEnum.OWNER_CORRESPONDANCE_ADDRESS]: OwnerCorrespondenceAddressItem,
    [CustomerRequestDataEnum.PAYMENT_METHOD]: PaymentMethodItem,
    [CustomerRequestDataEnum.PREFERRED_CONTACT]: TechnicianPhoneItem,
    [CustomerRequestDataEnum.PROBLEM_DESCRIPTION]: ComplainedInvoiceProblemDescriptionItem,
    [CustomerRequestDataEnum.PRODUCTS]: ProductsItem,
    [CustomerRequestDataEnum.PRODUCT_LEVEL]: ProductLevelItem,
    [CustomerRequestDataEnum.READING]: ReadingItem,
    [CustomerRequestDataEnum.READINGS]: ReadingsItem,
    [CustomerRequestDataEnum.REASON]: ReasonEnumItem,
    [CustomerRequestDataEnum.STATEMENT]: StatementItem,
    [CustomerRequestDataEnum.TARIFF]: TariffItem,
    [CustomerRequestDataEnum.TARIFF_EE]: TariffItem,
    [CustomerRequestDataEnum.TARIFF_ZP]: TariffItem,
    [CustomerRequestDataEnum.ZP_ASSUMED_CONSUMPTION]: AssumedConsumptionItem,
    [CustomerRequestDataEnum.ZP_METER_READING]: MeterReadingItem,
    [CustomerRequestDataEnum.ZP_TARIFF]: TariffItem,
    [CustomerRequestDataEnum.UNDERCHARGE_SETTLEMENT_INVOICE]: UnderchangeSettlementInvoiceItem,
    [CustomerRequestDataEnum.CONSENT]: ConsentsItem,
};

export const contentElementOrder: { [key: string]: string[] } = {
    [CustomerRequestTypeEnum.ZOM_ZODE]: [
        CustomerRequestDataEnum.TARIFF_EE,
        CustomerRequestDataEnum.ASSUMED_CONSUMPTION_EE,
        CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD,
        CustomerRequestDataEnum.CONECTION_DETAIL_EE,
        CustomerRequestDataEnum.PAYMENT_METHOD,
        CustomerRequestDataEnum.INVOICE_DELIVERY,
        CustomerRequestDataEnum.DELIVERY_POINT,
        CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_EE,
        CustomerRequestDataEnum.OWNER,
        CustomerRequestDataEnum.OWNER_ADDRESS,
        CustomerRequestDataEnum.OWNER_CORRESPONDANCE_ADDRESS,
        CustomerRequestDataEnum.OWNER_CONTACT,
        CustomerRequestDataEnum.BUSINESS_PARTNER,
        CustomerRequestDataEnum.ADDRESS,
        CustomerRequestDataEnum.CORRESPONDACE_ADDRESS,
        CustomerRequestDataEnum.CONTACT,
        CustomerRequestDataEnum.CONTRACT_DELIVERY,
        CustomerRequestDataEnum.APPROVALS,
        CustomerRequestDataEnum.NOTE,
    ],
    [CustomerRequestTypeEnum.ZOM_ZODP]: [
        CustomerRequestDataEnum.TARIFF_ZP,
        CustomerRequestDataEnum.ASSUMED_CONSUMPTION_ZP,
        CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD,
        CustomerRequestDataEnum.PAYMENT_METHOD,
        CustomerRequestDataEnum.INVOICE_DELIVERY,
        CustomerRequestDataEnum.DELIVERY_POINT,
        CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_ZP,
        CustomerRequestDataEnum.BUSINESS_PARTNER,
        CustomerRequestDataEnum.ADDRESS,
        CustomerRequestDataEnum.CORRESPONDACE_ADDRESS,
        CustomerRequestDataEnum.CONTACT,
        CustomerRequestDataEnum.CONTRACT_DELIVERY,
        CustomerRequestDataEnum.APPROVALS,
        CustomerRequestDataEnum.NOTE,
    ],
};

export const advancePaymentBasedOnTariff: { [key: string]: { minimal: number; recommended: number } } = {
    DD1: { minimal: 0, recommended: 0 },
    DD2: { minimal: 0, recommended: 0 },
    DD3: { minimal: 0, recommended: 0 },
    DD4: { minimal: 0, recommended: 0 },
    DD5: { minimal: 0, recommended: 0 },
    DD6: { minimal: 0, recommended: 0 },
    DD7: { minimal: 0, recommended: 0 },
    DD8: { minimal: 0, recommended: 0 },
    C1: { minimal: 0, recommended: 0 },
    C2: { minimal: 0, recommended: 0 },
    C3: { minimal: 0, recommended: 0 },
    C4: { minimal: 0, recommended: 0 },
    C5: { minimal: 0, recommended: 0 },
    C6: { minimal: 0, recommended: 0 },
    C7: { minimal: 0, recommended: 0 },
    C8: { minimal: 0, recommended: 0 },
    C9: { minimal: 0, recommended: 0 },
    C9A: { minimal: 0, recommended: 0 },
    C9B: { minimal: 0, recommended: 0 },
    C10: { minimal: 0, recommended: 0 },
    C11: { minimal: 0, recommended: 0 },
    NN: { minimal: 0, recommended: 0 },
    VN: { minimal: 0, recommended: 0 },
    VVN: { minimal: 0, recommended: 0 },
    X1: { minimal: 0, recommended: 0 },
    X2: { minimal: 0, recommended: 0 },
    'X2-D': { minimal: 0, recommended: 0 },
    'X2-S': { minimal: 0, recommended: 0 },
    X2_ADAPT: { minimal: 0, recommended: 0 },
    'X3-C11': { minimal: 0, recommended: 0 },
    'X3-C9A': { minimal: 0, recommended: 0 },
    'X3-C9B': { minimal: 0, recommended: 0 },
    D1: { minimal: 3.46, recommended: 0 },
    D2: { minimal: 7.03, recommended: 0 },
    D3: { minimal: 10.49, recommended: 0 },
    D4: { minimal: 16.15, recommended: 0 },
    D5: { minimal: 51.06, recommended: 0 },
    D6: { minimal: 62.26, recommended: 0 },
    D7: { minimal: 153.32, recommended: 0 },
    D8: { minimal: 341.32, recommended: 0 },
    M1: { minimal: 0, recommended: 0 },
    M2: { minimal: 0, recommended: 0 },
    M3: { minimal: 0, recommended: 0 },
    M4: { minimal: 0, recommended: 0 },
    M5: { minimal: 0, recommended: 0 },
    M6: { minimal: 0, recommended: 0 },
    M7: { minimal: 0, recommended: 0 },
    M8: { minimal: 0, recommended: 0 },
};
