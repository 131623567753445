import { latinCharactersRegex } from '../../utils/utils';

export enum CharacterFilter {
    POSITIVE_INTEGER,
    INTEGER,
    POSITIVE_DECIMAL,
    DECIMAL,
    LATIN,
}

export const CharacterFiltering = {
    matchKey: (filter: CharacterFilter | undefined, key: string) => {
        switch (filter) {
            case CharacterFilter.POSITIVE_INTEGER:
                if (key < '0' || key > '9') {
                    return false;
                }
                break;
            case CharacterFilter.INTEGER:
                if (key !== '-' && (key < '0' || key > '9')) {
                    return false;
                }
                break;
            case CharacterFilter.POSITIVE_DECIMAL:
                if (key !== '.' && key !== ',' && (key < '0' || key > '9')) {
                    return false;
                }
                break;
            case CharacterFilter.DECIMAL:
                if (key !== '.' && key !== ',' && key !== '-' && (key < '0' || key > '9')) {
                    return false;
                }
                break;
            case CharacterFilter.LATIN:
                return latinCharactersRegex.test(key);
                break;
        }

        return true;
    },

    matchText: (filter: CharacterFilter | undefined, text: string) => {
        const strValue = text.toLowerCase();
        const numValue = Number(strValue);
        switch (filter) {
            case CharacterFilter.POSITIVE_INTEGER:
            case CharacterFilter.INTEGER:
            case CharacterFilter.POSITIVE_DECIMAL:
            case CharacterFilter.DECIMAL:
                if (strValue.indexOf('e') >= 0 || !isFinite(numValue) || isNaN(numValue)) {
                    return false;
                }
        }

        switch (filter) {
            case CharacterFilter.POSITIVE_INTEGER:
                if (numValue < 0 || strValue.indexOf('.') >= 0) {
                    return false;
                }
                break;
            case CharacterFilter.INTEGER:
                if (strValue.indexOf('.') >= 0) {
                    return false;
                }
                break;
            case CharacterFilter.POSITIVE_DECIMAL:
                if (numValue < 0) {
                    return false;
                }
                break;
        }
        return true;
    },
};
