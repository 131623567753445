import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

type Props = { id?: string; showIcon: boolean };

export const SharedIconCell: React.FC<Props> = ({ id, showIcon }) => {
    const iconRef = useRef(null);
    const { t } = useTranslation();
    return (
        <td>
            {showIcon && (
                <>
                    <i ref={iconRef} id={`icon-${id}`} className="icon-users-16" />
                    <UncontrolledTooltip placement="top" target={iconRef}>
                        {t('delivery-point.delivey-point-share-to-another-person')}
                    </UncontrolledTooltip>
                </>
            )}
        </td>
    );
};
