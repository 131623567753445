import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileInvoice } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { InvoiceState } from '../../../../models/enums';
import { formatDate } from '../../../../utils/utils';

interface IDetailInvoiceStatus {
    invoice: SkSppNzpBeApiCustomerprofileInvoice;
}

const DetailInvoiceStatus: React.FC<IDetailInvoiceStatus> = ({ invoice }) => {
    return (
        <>
            {invoice.status === InvoiceState.PAID ? (
                <div className="text-success font-sm-bold mb-0">
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {invoice.typeGroup === 'CREDIT' ? (
                        <Trans i18nKey="invoices.detail.payment-overpaid" />
                    ) : (
                        <Trans i18nKey={`enums.InvoiceState.${invoice?.status}`} />
                    )}
                </div>
            ) : (
                <>
                    {invoice.unpaid != null && invoice.unpaid < 0 ? (
                        <>
                            <div className="text-success font-sm-bold mb-0 d-inline-flex align-items-center">
                                <Trans i18nKey="invoices.detail.payment-overpaid" />
                                <span className="ml-2" id={`invoice_state_preplatok_${invoice.id}_${invoice.dueAt}`}>
                                    <i className="icon-circle-info-16 text-black"></i>
                                    {invoice.dueAt && (
                                        <UncontrolledTooltip placement="top" target={`invoice_state_preplatok_${invoice.id}_${invoice.dueAt}`}>
                                            <Trans
                                                i18nKey="invoices.table.invoice-overpayment-return"
                                                values={{ date: formatDate(new Date(invoice.dueAt)) }}
                                            />
                                        </UncontrolledTooltip>
                                    )}
                                </span>
                            </div>
                        </>
                    ) : invoice.containsPaymentPlan ? (
                        <div className="text-success font-sm-bold mb-0 d-inline-flex align-items-center">
                            <span className="mr-2">
                                <i className="icon-circle-info-16"></i>
                            </span>
                            <Trans i18nKey="enums.InvoiceState.contains-payment-plan" />
                        </div>
                    ) : (
                        invoice.status &&
                        (invoice.status === 'INVALID' ||
                            invoice.status === 'UNPAID' ||
                            invoice.status === 'PARTIALLY_PAID' ||
                            invoice.status === 'CANCELLED') && (
                            <>
                                <div className="text-danger font-sm-bold mb-0 d-inline-flex align-items-center">
                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                    <Trans i18nKey={`enums.InvoiceState.${invoice?.status}`} />
                                </div>
                                {invoice?.typeGroup === 'CREDIT' && (
                                    <span style={{ color: 'black' }}>
                                        {' - '}
                                        <Trans i18nKey="invoices.table.from-spp-site" />
                                    </span>
                                )}
                            </>
                        )
                    )}
                </>
            )}
        </>
    );
};

export default DetailInvoiceStatus;
