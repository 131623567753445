import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../reducers';
import { KeyValueStoreActions } from './../actions/key-value-store-actions';

type StateSetter<T> = T | ((prevState: T) => T);

export const useKeyValueStore = <T>(
    key: string,
    defaultValue: T,
    useDefaultValue?: boolean,
): [Partial<T>, (setter: StateSetter<Partial<T>>) => void] => {
    const keyValueStore = useSelector((root: IRootState) => root.keyValueStore);
    const value = useDefaultValue ? defaultValue : (keyValueStore[key] as Partial<T> | undefined) ?? defaultValue;

    const dispatch = useDispatch();

    const setValue = useCallback(
        (setter: StateSetter<Partial<T>>) => {
            const newValue = setter instanceof Function ? setter(value) : setter;
            dispatch(KeyValueStoreActions.setValue(key, newValue));
        },
        [key, value, dispatch],
    );

    return [value, setValue];
};
