import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Form, ListGroup, ListGroupItem } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseFileInputButton from '../../../../../components/common/base-file-input-button';
import { IAttachments } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import FileItemShort from './components/file-item-short';

type AttachmentsFormType = {
    note: string;
};
// file size in MB
const FILE_SIZE = 10;

const MAX_FILE_SIZE = 1024 * 1024 * FILE_SIZE;

const MAX_FILES_LIMIT = 10;

const AttachmentsShort: React.FC = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const { content, attachments } = useSelector((state: IRootState) => state.customerRequest);
    const [error, setError] = useState<string>('');

    const { handleSubmit } = useForm<AttachmentsFormType>({ defaultValues: { note: content.note } });

    const [files, setFiles] = useState<IAttachments[]>(attachments ?? []);

    const onSubmit = () => {
        if (files.length === 0) {
            setError(t('common.input-rules.required'));
            return;
        }
        dispatch(CustomerRequestActions.setAttachments(files));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const onFilePick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files || [];

        if (fileList?.length > 0) {
            if (fileList[0].size > MAX_FILE_SIZE) {
                addToast(t('common.file-size', { count: FILE_SIZE }), { appearance: 'error' });
                return;
            }
            if (files.length >= MAX_FILES_LIMIT) {
                addToast(t('common.file-count-limit-exceeded', { count: MAX_FILES_LIMIT }), { appearance: 'error' });
                return;
            }
            setError('');
            setFiles((prevState) => [
                ...prevState,
                {
                    uuid: uuidv4(),
                    file: fileList[0],
                },
            ]);
        }
    };

    const onFileDelete = (uuid: string) => {
        setFiles((prevState) => {
            return [...prevState.filter((i: IAttachments) => uuid !== i.uuid)];
        });
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <>
                    <div>
                        <Trans i18nKey="customer-request.steps.attachments-short.description" />
                    </div>
                    <ListGroup className="mb-5">
                        {files.map((item) => (
                            <FileItemShort key={item.uuid} item={item} onDelete={onFileDelete} />
                        ))}

                        <ListGroupItem>
                            <BaseFileInputButton onChange={onFilePick}>
                                <div className="text-decoration-none d-flex justify-content-between align-items-center">
                                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                                    <span className="mr-auto">
                                        <Trans i18nKey="notifications.email.add-attachment">Nahrať prílohu</Trans>
                                    </span>
                                </div>
                            </BaseFileInputButton>
                        </ListGroupItem>
                        {error && <div className="text-danger my-2">{error}</div>}
                        <p>
                            <Trans i18nKey="customer-request.steps.attachments-short.example" />
                        </p>
                    </ListGroup>
                </>

                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </Form>
        </>
    );
};

export default AttachmentsShort;
