import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { format, getYear } from 'date-fns';
import { sk } from 'date-fns/locale';
import moment from 'moment';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { dateFromFormatToIso, dateFromIsoToFormat } from '../../../utils/date-utils';
import { accessNestedProperty } from '../../../utils/utils';
import './react-datepicker.scss';
registerLocale('sk', sk);

interface IDatePicker {
    label: string | JSX.Element;
    name?: string;
    value?: Date | undefined;
    onChange?: (value: Date | undefined) => void;
    position?: 'R' | 'L';
    register?: (options: any) => any;
    errors?: FieldErrors;
    border?: boolean;
    minDate?: Date;
    maxDate?: Date;
    getValues?: any;
    setValue?: any;
    trigger?: (name?: any) => Promise<boolean>;
    includeDay?: boolean;
    placeholder?: string;
    showInputWhileError?: boolean;
    optional?: boolean;
    touched?: boolean;
    disabled?: boolean;
}

export function formatDate(date: string | undefined): string | undefined {
    return date ? moment(date, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY'], true).format('YYYY-MM-DDT00:00:00') : undefined;
}

const yearsArray = () => {
    const currentYear = new Date().getFullYear();
    const yearsHelp = [];
    for (let index = currentYear + 10; index > currentYear - 100; index--) {
        yearsHelp.push(index);
    }
    return yearsHelp;
};

const DatePicker = ({
    label,
    value,
    onChange,
    position,
    register,
    errors,
    border = true,
    name = 'date',
    minDate,
    maxDate,
    getValues,
    setValue,
    trigger,
    includeDay,
    placeholder,
    showInputWhileError = true,
    optional = false,
    touched = false,
    disabled = false,
}: IDatePicker): ReactElement => {
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [input, setInput] = useState<Date | undefined>(value);
    const [inputHelp, setInputHelp] = useState<string>();
    const refCalendar = useRef<ReactDatePicker>(null);
    const refInput = useRef<HTMLInputElement>(null);

    const rgx = /^[0-9.-]+$|^$/; //regex only numbers dots and hyphens
    const lang = i18n.language;

    const handleDatepicker = (val: boolean) => {
        refCalendar?.current?.setOpen(val);
    };

    useEffect(() => {
        if (getValues && getValues(name)) {
            setInputHelp(dateFromIsoToFormat(getValues(name), 'DD.MM.YYYY') || undefined);
        }
    }, [getValues, name]);

    const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));

    const isValid = errors == null || errorProperty == null;
    const [isTouched, setIsTouched] = useState(touched);

    const manualErrorCheck = useCallback(() => {
        trigger && trigger(name);
    }, [name, trigger]);

    useEffect(() => {
        !isValid && manualErrorCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, manualErrorCheck]);

    const [isFocused, setIsFocused] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const setHiddenInputValue = (newValue: string) => {
        setValue && setValue(name, newValue);
        manualErrorCheck();
    };

    return (
        <>
            <input type="text" style={{ display: 'none' }} ref={register} name={name} />
            <ReactDatePicker
                ref={refCalendar}
                locale={lang === 'en' ? 'en' : 'sk'}
                fixedHeight
                popperClassName={position === 'R' ? 'right-position-class' : ''}
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: '0px, 0px',
                    },
                }}
                // if calendar jumps around when changing months https://jira-server.isdd.sk/browse/PMERU-1432
                // https://github.com/Hacker0x01/react-datepicker/issues/688
                // fixedHeight solves it partly (calendar will render 5 weeks even if it's from another month)
                minDate={minDate}
                maxDate={maxDate}
                renderCustomHeader={({ date, decreaseMonth, increaseMonth, decreaseYear, increaseYear, changeYear }) => (
                    <div id="ui-datepicker-div" className="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all">
                        <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                            <span className="ui-datepicker-prev ui-corner-all ui-state-disabled" onClick={decreaseYear}>
                                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                            </span>
                            <span className="ui-datepicker-prev ui-corner-all ui-state-disabled" onClick={decreaseMonth}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </span>
                            <span className="ui-datepicker-next ui-corner-all" data-handler="next" data-event="click" onClick={increaseMonth}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </span>
                            <span className="ui-datepicker-next ui-corner-all" data-handler="next" data-event="click" onClick={increaseYear}>
                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                            </span>
                            <div className="ui-datepicker-title">
                                <span className="ui-datepicker-month">{format(date, 'LLLL', { locale: lang === 'en' ? undefined : sk })}</span>
                                &nbsp;
                                <div onClick={() => setDropdownOpen(!dropdownOpen)} className="datepicker-year">
                                    {getYear(date)} <FontAwesomeIcon icon={faAngleDown} />
                                </div>
                                <div className={classNames('year-select', { 'd-none': !dropdownOpen })}>
                                    <div className="year-select-box">
                                        {yearsArray().map((year) => (
                                            <div
                                                key={`years_select_${year}`}
                                                className={classNames('year-list', { 'year-list-selected': getYear(date) === year })}
                                                onClick={() => {
                                                    changeYear(year);
                                                    setDropdownOpen(!dropdownOpen);
                                                }}
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="fadeout"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                selected={
                    inputHelp && moment(inputHelp, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY'], true).isValid()
                        ? moment(inputHelp, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY']).toDate()
                        : input
                }
                customInput={
                    <>
                        <div
                            className={classNames('input-group', 'datepicker', 'validated-datepicker', {
                                'is-invalid': !isValid,
                                'is-valid': isValid && isTouched,
                                'is-focused': isFocused, // hack for IE11 as IE11 doesn't support focus-within
                                'position-right-borders': position === 'R',
                                'position-left-borders': position === 'L',
                            })}
                            style={{ border: border ? '' : 'none' }}
                        >
                            <input
                                ref={refInput}
                                className={classNames(
                                    'form-control',
                                    {
                                        'position-right-borders': position === 'R',
                                    },
                                    {
                                        'position-left-borders': position === 'L',
                                    },
                                    {
                                        'is-invalid': !isValid,
                                        'is-valid': isValid && isTouched,
                                    },
                                    { 'datepicker-opened': datePickerOpen, datepicker: !datePickerOpen },
                                    { 'text-sm-transparent': !isValid && !isFocused && !showInputWhileError },
                                )}
                                type={'text'}
                                value={inputHelp ?? ''}
                                placeholder={placeholder ?? ' '}
                                required // for style application only, is not validated as required
                                autoComplete="off"
                                maxLength={10}
                                name={name}
                                onClick={() => handleDatepicker(true)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isTouched === false) {
                                        setIsTouched(true);
                                    }
                                    if (!rgx.test(e.target.value)) {
                                        return;
                                    }
                                    setInputHelp(e.target.value);
                                    const date = moment(e.target.value, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY'], true);

                                    if (date.isValid()) {
                                        setHiddenInputValue(dateFromFormatToIso(e.target.value, 'DD.MM.YYYY HH:mm:ss', includeDay));
                                        onChange && onChange(new Date(dateFromFormatToIso(date.toString(), 'DD.MM.YYYY HH:mm:ss', includeDay)));
                                        setInput(date.toDate());
                                        handleDatepicker(false);
                                    } else {
                                        setHiddenInputValue(e.target.value);
                                    }
                                }}
                            />
                            <label className={classNames('control-label', { 'd-block d-sm-none': !isValid && !isFocused && !showInputWhileError })}>
                                {label}
                                {` ${optional ? t('common.input-rules.optional') : ''}`}
                            </label>
                            <div className="input-group-append"></div>
                            {!isValid && errors != null && !isFocused && (
                                <>
                                    <div
                                        className={`invalid-feedback small d-none d-sm-block ${
                                            errors && errors[name]?.message?.length > 30 ? 'long-error' : ''
                                        }`}
                                        onClick={() => {
                                            refInput.current?.focus();
                                            handleDatepicker(true);
                                        }}
                                    >
                                        <ErrorMessage errors={errors} name={name} />
                                    </div>
                                    <div className="invalid-feedback small d-sm-none">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                                    </div>
                                </>
                            )}
                        </div>
                        {!isValid && errors != null && (
                            <div className="font-weight-bold text-danger ml-3 d-sm-none">
                                <ErrorMessage errors={errors} name={name} />
                            </div>
                        )}
                    </>
                }
                renderDayContents={(day) => {
                    return <span className="my_class">{day}</span>;
                }}
                onCalendarClose={() => setDatePickerOpen(false)}
                onCalendarOpen={() => setDatePickerOpen(true)}
                onChange={(date) => {
                    if (isTouched === false) {
                        setIsTouched(true);
                    }

                    if (date === null) {
                        onChange && onChange(undefined);
                    } else if (date instanceof Date) {
                        onChange && onChange(date);
                        const formatedDate = moment(date).format('DD.MM.yyyy');
                        setHiddenInputValue(dateFromFormatToIso(formatedDate, 'DD.MM.YYYY HH:mm:ss', includeDay));
                        setInput(date);
                        setInputHelp(formatedDate);
                    }
                }}
                disabled={disabled}
            />
        </>
    );
};
export default DatePicker;
