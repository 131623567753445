import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoiceSearch,
    SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import InvoicesFilterForm, { IInvoiceBP } from './invoices-filter-form';

export interface IInvoicesTableFilter extends SkSppNzpBeApiCustomerprofileInvoiceSearch {
    deliveryPointIds?: string[];
}

interface IInvoicesTableFilterModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    filter: Partial<IInvoicesTableFilter>;
    onFilterChange: (newFilter: Partial<IInvoicesTableFilter>) => void;
    deliveryPointId?: string;
    unitedDeliveryPointId?: string;
    commodity?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
    customer?: IInvoiceBP;
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    unitedDeliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null;
    hasNonCommodity?: boolean;
    availableTypeGroups?: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary['availableTypeGroups'];
    availableCommodities?: Exclude<SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'], undefined>[];
}

const InvoicesTableFilterModal: React.FC<IInvoicesTableFilterModalProps> = ({
    isOpen,
    onCloseModal,
    filter,
    onFilterChange,
    deliveryPointId,
    unitedDeliveryPointId,
    commodity,
    deliveryPoints,
    unitedDeliveryPoints,
    customer,
    hasNonCommodity,
    availableTypeGroups,
    availableCommodities,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} centered style={{ maxWidth: '550px' }}>
                <ModalBody>
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="common.tables.filter" />
                    </h3>
                    <InvoicesFilterForm
                        currentFilter={filter}
                        onSubmit={(newFilter) => {
                            onFilterChange(newFilter);
                            onCloseModal();
                        }}
                        unitedDeliveryPointId={unitedDeliveryPointId}
                        deliveryPointId={deliveryPointId}
                        commodity={commodity}
                        deliveryPoints={deliveryPoints}
                        unitedDeliveryPoints={unitedDeliveryPoints}
                        customer={customer}
                        hasNonCommodity={hasNonCommodity}
                        availableTypeGroups={availableTypeGroups}
                        availableCommodities={availableCommodities}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default InvoicesTableFilterModal;
