import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { BaseRadio, CodeListTypeEnum, formatAddressShort, QueryKeysEnum, useCodeList } from '../../../../../main';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';

const BlockDeliveryPointTerminateContract: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { additionalData, content } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPoints = additionalData?.udpd?.deliveryPoints;

    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint>();

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    useEffect(() => {
        deliveryPoints && dispatch(CustomerRequestActions.setDeliveryPoint(deliveryPoints[0])); //just for adress of delivery point in request header
        let selectedDpUuid = additionalData?.dpuuid;
        if (content.deliveryPointsZP?.length) {
            selectedDpUuid = content.deliveryPointsZP[0].id;
        } else if (content.deliveryPointsEE?.length) {
            selectedDpUuid = content.deliveryPointsEE[0].id;
        }

        if (selectedDpUuid) {
            const selected = deliveryPoints?.find((db) => db.id === selectedDpUuid);
            if (selected) {
                setSelectedDeliveryPoint(selected);
                return;
            }
        }
        if (deliveryPoints?.length === 1) {
            setSelectedDeliveryPoint(deliveryPoints[0]);
        }
    }, [dispatch, content.deliveryPointsZP, content.deliveryPointsEE, additionalData, deliveryPoints]);

    return (
        <>
            <p className="text-left text-lg mb-4 mt-0 ">
                <Trans i18nKey="customer-request.steps.delivery-point-terminate-contract.requested-invoice" />
            </p>
            {deliveryPoints?.map((dp) => (
                <Card key={`deliveryPoint_${dp.id}`} className="mb-3">
                    <CardBody className="py-2 px-4">
                        <BaseRadio
                            className="mt-1"
                            id={`radio-delivery-point-${dp.id}`}
                            onChange={() => setSelectedDeliveryPoint(dp)}
                            checked={selectedDeliveryPoint?.id === dp.id}
                            label={
                                <>
                                    <b>
                                        {formatAddressShort(dp.address)} -{' '}
                                        {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')}
                                    </b>
                                    <p className="mb-0"> {t('delivery-point.contract-number', { number: dp.contract?.externalId })} </p>
                                </>
                            }
                        />
                    </CardBody>
                </Card>
            ))}
            <BaseButton
                type="button"
                onClick={() => {
                    if (selectedDeliveryPoint?.type === CommodityEnum.ELECTRICITY) {
                        dispatch(CustomerRequestActions.setDeliveryPointsEE([selectedDeliveryPoint]));
                        dispatch(CustomerRequestActions.setDeliveryPointsZP([]));
                    } else {
                        dispatch(CustomerRequestActions.setDeliveryPointsZP([selectedDeliveryPoint as SkSppNzpBeApiCustomerprofileDeliveryPoint]));
                        dispatch(CustomerRequestActions.setDeliveryPointsEE([]));
                    }
                    dispatch(
                        CustomerRequestActions.setData({
                            selectedDPorBPCountry: countryCodeList?.find((item) => item.code === selectedDeliveryPoint?.address?.country),
                        }),
                    );
                    nextTick(() => {
                        dispatch(CustomerRequestActions.nextStep());
                    });
                }}
                disabled={deliveryPoints?.find((db) => db.id === selectedDeliveryPoint?.id) ? false : true}
            >
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockDeliveryPointTerminateContract;
