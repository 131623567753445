import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import { SAP_DELETE } from '../../../../../../models/enums';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IBusinessPartner;
    bold?: boolean;
    title: string | JSX.Element;
};

const BusinessPartnerCompanyItem: React.FC<Props> = ({ data, bold, title }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { name, bpNumber, companyRegistrationNumber, taxIdNumber, vatRegistrationNumber, legalForm, statutoryName } = data;

    return (
        <>
            <ItemPreview title={title}>
                {name && <ItemPreviewContent bold={bold}>{name}</ItemPreviewContent>}

                {bpNumber && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.businessPartner.customer-number-spp',
                    )}: ${bpNumber}`}</ItemPreviewContent>
                )}

                {companyRegistrationNumber && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.businessPartner.ico',
                    )}: ${companyRegistrationNumber}`}</ItemPreviewContent>
                )}
                {taxIdNumber && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.businessPartner.dic')}: ${taxIdNumber}`}</ItemPreviewContent>
                )}
                {vatRegistrationNumber && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.businessPartner.ic-dph')}: ${
                        vatRegistrationNumber === SAP_DELETE ? '' : vatRegistrationNumber
                    }`}</ItemPreviewContent>
                )}
                {legalForm && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.businessPartner.legal-form')}: ${legalForm}`}</ItemPreviewContent>
                )}
                {statutoryName && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.businessPartner.name-statutory-representative',
                    )}: ${statutoryName}`}</ItemPreviewContent>
                )}
            </ItemPreview>
        </>
    );
};

export default BusinessPartnerCompanyItem;
