import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { SessionStorageEnum, useSessionStorage } from '../../../../hooks/use-session-storage';
import { IRootState } from '../../../../reducers';
import { QueryKeysEnum, useReloadQueryCache } from '../../../../utils/react-query-utils';
import ActivateEInvoiceSelectEmailForm from './activate-einvoice-select-email-form';
import ActivateEInvoiceSuccess from './activate-einvoice-success';

enum ActivationStepsEnum {
    SELECT_EMAIL = 1,
    SUCCESS,
}

interface IActivateEInvoiceModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    unitedDeliveryPointId: string | undefined;
    onActivate?: (unitedDeliveryPointId: string, email: string) => void;
    canBulkActivate?: boolean;
    startBulkActivate?: () => void;
}

const ActivateEInvoiceModal: React.FC<IActivateEInvoiceModalProps> = ({
    isOpen,
    onCloseModal,
    unitedDeliveryPointId,
    onActivate,
    canBulkActivate,
    startBulkActivate,
}) => {
    const [activationStep, setActivationStep] = useState<ActivationStepsEnum>(ActivationStepsEnum.SELECT_EMAIL);
    const [activatedEmail, setActivatedEmail] = useState<string | undefined>(undefined);
    const [reloadRequired, setReloadRequired] = useState<boolean>(false);
    const [showInactiveDP] = useSessionStorage<boolean>(SessionStorageEnum.showInactiveDeliveryPoints);
    useEffect(() => {
        if (isOpen) {
            setActivationStep(ActivationStepsEnum.SELECT_EMAIL);
            setActivatedEmail(undefined);
        }
    }, [isOpen]);

    const api = useApi();
    const reloadQueryCache = useReloadQueryCache();
    const {
        isLoading: isLoadingUnitedDeliveryPoint,
        data: unitedDeliveryPointData,
    } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, unitedDeliveryPointId, showInactiveDP],
        async () =>
            unitedDeliveryPointId == null
                ? null
                : api.unitedDeliveryPoints
                      .getUdpByUuid(unitedDeliveryPointId, { includeInactive: showInactiveDP }, { secure: true })
                      .then((x) => x.data),
    );

    const requestEInvoiceActivation = async ({ udpId, email }: { udpId: string; email: string }) =>
        api.unitedDeliveryPoints.activateEInvoiceDeliveryPoint(
            udpId,
            {
                email: email,
            },
            { secure: true },
        );
    const [mutateEInvoiceActivation, { isLoading: isLoadingActivation }] = useMutationWithError(requestEInvoiceActivation, {
        onSuccess: (data: unknown, variables: { udpId: string; email: string }) => {
            onActivate && onActivate(variables.udpId, variables.email);
            setActivationStep(ActivationStepsEnum.SUCCESS);
            setReloadRequired(true);
        },
        onErrorWithGlobalErrorHandling: () => false,
    });

    const onEmailSelect = async (selectedEmail: string) => {
        if (unitedDeliveryPointData?.id != null && !isLoadingActivation) {
            setActivatedEmail(selectedEmail);
            mutateEInvoiceActivation({ udpId: unitedDeliveryPointData.id, email: selectedEmail });
        }
    };

    const onSuccessConfirm = () => {
        onCloseModal();
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
    };

    const onSuccessConfirmBulk = () => {
        onCloseModal();
        startBulkActivate && startBulkActivate();
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
    };

    const deliveryPointForActivation = useSelector((state: IRootState) => state.eInvoiceSettings.deliveryPointForActivation?.deliveryPoints);
    const alreadyActivatedDeliveryPoint = deliveryPointForActivation?.find((dp) => dp.einvoice?.activated) ? true : false;
    const onlyNotActivatedDeliveryPoint = deliveryPointForActivation?.filter((dp) => !dp.einvoice?.activated);

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="settings.e-invoices.einvoice-activation" />
                    </h3>
                    <button
                        type="button"
                        onClick={() => {
                            onCloseModal();
                            reloadRequired && reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody>
                    {(isLoadingActivation || isLoadingUnitedDeliveryPoint) && <LoadingIndicator />}

                    {activationStep === ActivationStepsEnum.SELECT_EMAIL && unitedDeliveryPointData != null && (
                        <ActivateEInvoiceSelectEmailForm
                            onSelect={onEmailSelect}
                            unitedDeliveryPoint={unitedDeliveryPointData}
                            alreadyActivatedDeliveryPoint={alreadyActivatedDeliveryPoint}
                            onlyNotActivatedDeliveryPoint={onlyNotActivatedDeliveryPoint}
                        />
                    )}
                    {activationStep === ActivationStepsEnum.SUCCESS && unitedDeliveryPointData != null && activatedEmail != null && (
                        <ActivateEInvoiceSuccess
                            onConfirm={onSuccessConfirm}
                            onConfirmBulk={onSuccessConfirmBulk}
                            canBulkActivate={!!canBulkActivate}
                            address={{ street: unitedDeliveryPointData.address?.street, streetNumber: unitedDeliveryPointData.address?.streetNumber }}
                            email={activatedEmail}
                            alreadyActivatedDeliveryPoint={alreadyActivatedDeliveryPoint}
                            onlyNotActivatedDeliveryPoint={onlyNotActivatedDeliveryPoint}
                        />
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default ActivateEInvoiceModal;
