import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'hacktimer';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import App from './app';
import CrashFallback from './components/crash-fallback';
import { CustomToast, CustomToastContainer } from './components/custom-toast';
import './index.css';
import Loader from './loader';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';

const store = createStore(rootReducer, composeWithDevTools());
const langWhitelist = ['sk', 'en', 'xx'];
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pkg = require('../package.json');

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'sk',
        lng: localStorage.getItem('i18n.locale') || (langWhitelist.includes(navigator.language) && navigator.language) || 'sk',
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}.json?v=' + (pkg.version || ''),
        },
        supportedLngs: langWhitelist,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function(value, format) {
                if (format === 'uppercase') return value.toUpperCase();
                if (format === 'lowercase') return value.toLowerCase();
                if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
                return value;
            },
        },
    });

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ToastProvider autoDismiss placement="top-center" components={{ ToastContainer: CustomToastContainer, Toast: CustomToast }}>
                <Suspense fallback={<Loader />}>
                    <ErrorBoundary FallbackComponent={CrashFallback}>
                        <App />
                    </ErrorBoundary>
                </Suspense>
            </ToastProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
