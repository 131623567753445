import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseSelect } from '.';
import { CodeListTypeEnum, DefaultFormType, LoadingIndicator, QueryKeysEnum, useCodeList } from '../../main';

interface IDistributionAreaSelectProps extends DefaultFormType {
    onChange?: (value: string) => void;
}

export const DistributionAreaSelect: React.FC<IDistributionAreaSelectProps> = ({ register, onChange }) => {
    const { data: distributionAreaCodelist, isLoading } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 5000,
        },
    });

    const { t } = useTranslation();

    const [isPreselected, setIsPreselected] = useState<boolean>(false);

    useEffect(() => {
        if (isPreselected || distributionAreaCodelist == null) {
            return;
        }
        const preselectedValue = distributionAreaCodelist[0];
        if (preselectedValue.code != null && onChange != null) {
            onChange(preselectedValue.code);
            setIsPreselected(true);
        }
    }, [distributionAreaCodelist, onChange, isPreselected]);

    return (
        <>
            {isLoading && <LoadingIndicator />}
            {distributionAreaCodelist && (
                <BaseSelect
                    ref={register({})}
                    name="distributionArea"
                    label={<Trans i18nKey="customer-request.steps.electricity-connection.distribution-area" />}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange && onChange(event.target.value)}
                >
                    <option value="">{t('customer-request.steps.rate-selection.choose')}</option>
                    {distributionAreaCodelist?.map((item) => (
                        <option key={item.code} value={item.code}>
                            {item.name}
                        </option>
                    ))}
                </BaseSelect>
            )}
        </>
    );
};
