import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { DatePicker, Input } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IAssumedConsumption } from '../../../../../models/customer-request-model';
import { DeliveryPointEeConnectionEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { CustomerRequestDataEnum } from '../../../config/enums';

const BlockAssumedConsumptionZp: React.FC = () => {
    const dispatch = useDispatch();
    const { dateRules, formRules, podNumberFormat } = useFormRules();
    const { currentBlock, content } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.ASSUMED_CONSUMPTION;
    const objectData = content[objectName] as IAssumedConsumption;

    const { register, errors, handleSubmit, getValues, setValue, trigger } = useForm<IAssumedConsumption>({ defaultValues: objectData });

    const onSubmit = (data: IAssumedConsumption) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                ...data,
            },
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="mb-5">
                    <FormGroup className="d-flex flex-row mb-1">
                        <DatePicker
                            register={register({
                                ...dateRules().isValidDate.validate,
                                ...dateRules('NOT_OLDER_THAN', new Date().toISOString()).isValidDate,
                            })}
                            minDate={new Date()}
                            setValue={setValue}
                            getValues={getValues}
                            errors={errors}
                            name={`date`}
                            trigger={trigger}
                            label={<Trans i18nKey="customer-request.steps.electricity-consumption.subs-start-date" />}
                        />
                    </FormGroup>
                    <div className="d-flex mx-1 p-2">
                        <div className="mr-3 my-auto">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                        </div>
                        {content.deliveryPointConnectionZp?.type === DeliveryPointEeConnectionEnum.MIGRATION ? (
                            <Trans i18nKey="customer-request.steps.consumption.start-date-of-subscription-info">
                                Dátum začatia odberu závisí od viazanosti s Vaším aktuálnym dodávateľom a taktiež od distribútora. Ak dátum nezadáte,
                                zmena bude vykonaná v najbližšom možnom termíne.
                            </Trans>
                        ) : (
                            <Trans i18nKey="customer-request.steps.consumption.start-date-of-subscription-info-new-ZP">
                                Dátum začatia odberu závisí od termínu montáže meradla.
                            </Trans>
                        )}
                    </div>
                </div>

                <div className="mb-5">
                    <InputWithAppend
                        trigger={trigger}
                        ref={register(formRules.requiredInteger)}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        type="number"
                        name={`value`}
                        label={<Trans i18nKey="customer-request.steps.electricity-consumption.expected-consumption" />}
                        componentClassName="mb-1"
                        inputGroupAppendChildren={
                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                m<sup>3</sup>
                            </button>
                        }
                    />
                    <div className="d-flex mx-1 p-2">
                        <div className="mr-3 my-auto">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                        </div>
                        {content.deliveryPointConnectionZp?.type === DeliveryPointEeConnectionEnum.MIGRATION ? (
                            <Trans i18nKey="customer-request.steps.consumption.expected-consumption-info">
                                Predpokladaný ročný odber môžete určiť napríklad podľa fakturácie u Vášho aktuálneho dodávateľa.
                            </Trans>
                        ) : (
                            <Trans i18nKey="customer-request.steps.consumption.expected-consumption-info-for-new">
                                Predpokladaný ročný odber môžete určiť podľa projektovej dokumentácie alebo zmluvy o pripojení s distribúciou.
                            </Trans>
                        )}
                    </div>
                </div>
                {content.deliveryPointConnectionZp?.type === DeliveryPointEeConnectionEnum.MIGRATION && (
                    <>
                        <div className="mb-5">
                            <Input
                                trigger={trigger}
                                ref={register(podNumberFormat)}
                                errors={errors}
                                type="text"
                                name="deliveryPointNumber"
                                label={<Trans i18nKey="customer-request.steps.delivery-point.pod-number">POD kód odberného miesta</Trans>}
                                componentClassName="mb-0"
                            />
                            <div className="d-flex mx-1 p-2">
                                <div className="mr-3 my-auto">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <Trans i18nKey="customer-request.steps.consumption.pod-info">
                                    POD kód nájdete v zmluve o pripojení, ktorú ste uzavreli s prevádzkovateľom distribučnej siete SPP – distribúcia,
                                    a.s alebo na Vašej faktúre od súčasného dodávateľa.
                                </Trans>
                            </div>
                        </div>
                    </>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockAssumedConsumptionZp;
