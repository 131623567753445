import { SkSppNzpBeApiCodelistAddress, SkSppNzpBeApiCustomerprofileAddress } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AutoComplete from '../../../../../../components/common/autoComplete/autoComplete';
import BaseSelect from '../../../../../../components/common/base-select';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import Input from '../../../../../../components/common/input';
import { useApi } from '../../../../../../hooks/use-api';
import useCodeList from '../../../../../../hooks/use-code-list';
import { useDebouncing } from '../../../../../../hooks/use-debouncing';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../../hooks/use-mutation-with-error';
import { DefaultFormType } from '../../../../../../models/model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { combineRefs, normalizedUpperCaseText } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../../customer-request/config/enums';

type Props = DefaultFormType & {
    objectName?: string;
    address?: SkSppNzpBeApiCustomerprofileAddress;
    hideCountry?: boolean;
    type?: 'search' | 'normal';
    requireSkPostalCode?: boolean;
    clearErrors?: (name?: any) => void;
    onAddressSelected?: (address: SkSppNzpBeApiCustomerprofileAddress) => void;
    emptyCountry?: boolean;
};

interface IOptions {
    name: string;
    value: SkSppNzpBeApiCodelistAddress;
}

const minValueLengthForAddressSearch = 2;

const AddressFields: React.FC<Props> = ({
    register,
    errors,
    trigger,
    setValue,
    watch,
    address,
    objectName = '',
    hideCountry = false,
    emptyCountry = false,
    type = 'normal',
    requireSkPostalCode = false,
    setError,
    clearErrors,
    onAddressSelected,
}) => {
    const { requiredTrimed, formRules, mergeValidations, latinCharacters } = useFormRules();
    const [onlyStreetAndCountry, setOnlyStreetAndCountry] = useState<boolean>(type === 'search' ? true : false);
    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const api = useApi();
    const country = watch && watch(`${objectName}country`);
    const postalCode = watch && watch(`${objectName}zipCode`);
    const { t } = useTranslation();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [addressOptions, setAddressOptions] = useState<IOptions[]>([]);
    const [debounceLoading, setDebounceLoading] = useState<boolean>(false);

    const isSKorCZPostalCode = useMemo<boolean>(() => {
        const skCodeItem = countryCodeList?.find((item) => item.uuid === country);
        return skCodeItem?.code === 'SK' || skCodeItem?.code === 'CZ';
    }, [countryCodeList, country]);

    useEffect(() => {
        if (type === 'search') {
            if (isSKorCZPostalCode) {
                setOnlyStreetAndCountry(true);
            } else {
                setOnlyStreetAndCountry(false);
            }
        } else {
            setOnlyStreetAndCountry(false);
        }
    }, [isSKorCZPostalCode, type]);

    useEffect(() => {
        const countryItemCode = countryCodeList?.find((item) => item.uuid === country)?.code;
        if (countryItemCode !== 'SK' && countryItemCode !== 'CZ') {
            /**cancel of postal code fomart on (xxx xx) format  */
            if (localRef.current?.value?.charAt(3) === ' ') {
                const currentValue = localRef.current?.value;
                const newValue = currentValue.replace(/(.{3})./g, '$1').trim();
                localRef.current.value = newValue;
                trigger && trigger(`${objectName}zipCode`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    useEffect(() => {
        if (countryCodeList != null) {
            const country = address?.country ?? 'SK';
            const countryItem = countryCodeList.find((item) => item.code === country);
            if (setValue) {
                setValue(`${objectName}country`, emptyCountry ? undefined : countryItem?.uuid);
            }
        }
    }, [countryCodeList, setValue, address, objectName, emptyCountry]);

    const localRef = useRef<HTMLInputElement>(null);

    /**validate postal code on (xxx xx) format  */
    useEffect(() => {
        if (!isSKorCZPostalCode) return;
        const length = localRef.current?.value.length || 0;
        let position = localRef.current?.selectionEnd || length || 0;
        const preview = localRef.current?.value
            .replace(/[^\da-zA-Z]/g, '')
            .replace(/(.{3})/g, '$1 ')
            .trim();
        if (localRef.current) {
            localRef.current.value = preview || '';
            localRef.current.selectionEnd = position +=
                localRef.current.value.charAt(position - 1) === ' ' &&
                localRef.current.value.charAt(length - 1) === ' ' &&
                length !== localRef.current.value.length
                    ? 1
                    : 0;
        }
        if (!!postalCode && postalCode.length > 0) {
            trigger && trigger(`${objectName}zipCode`);
        }
    }, [postalCode, trigger, isSKorCZPostalCode, objectName]);

    const addressSelected = (opt: IOptions[]) => {
        if (setValue) {
            if (!!opt.length) {
                const zipCode = opt[0].value.zipCodes != null ? opt[0].value.zipCodes[0].zip : '';
                setValue(`${objectName}street`, opt[0].value.street);
                setValue(`${objectName}zipCode`, zipCode);
                setValue(`${objectName}city`, opt[0].value.city);
                clearErrors && clearErrors(`${objectName}street`);
                onAddressSelected &&
                    onAddressSelected({
                        street: opt[0].value.street,
                        city: opt[0].value.city,
                        zipCode: zipCode,
                    });
            } else {
                setValue(`${objectName}street`, '');
                setValue(`${objectName}streetNumber`, '');
                setValue(`${objectName}zipCode`, '');
                setValue(`${objectName}city`, '');
                if (setError) {
                    setError(`${objectName}street`, {
                        message: t('common.input-rules.required'),
                    });
                    setError(`${objectName}streetNumber`, {
                        message: t('common.input-rules.required'),
                    });
                }
            }
        }
    };

    const debounce = useDebouncing();

    const addressName = (address: SkSppNzpBeApiCodelistAddress) => {
        const zipCode = address.zipCodes != null ? address.zipCodes[0].zip : undefined;
        return address.street + ', ' + address.city + (zipCode ? `, ${zipCode}` : '');
    };

    const onAddressPhraseChange = async (val: string) => {
        const searchValue = normalizedUpperCaseText(val)
            .replace(/[\,\.\-]/g, ' ') //replace "," , "." , "-" for signle space
            .replace(/\s\s+/g, ' ') //replace multiple spaces, tabs, newlines for single space
            .trim();
        //search phrase must be at least minValueLengthForAddressSearch chars long
        if (searchValue.length >= minValueLengthForAddressSearch) {
            let adresses = data;

            //similar to split function - split searchValue by whitespaces
            const searchValueArray: string[] = searchValue.match(/[^ ]+/g) || [];
            //new search only if first 2 chars have been changed
            if (searchValue.substring(0, 2) !== searchPhrase.substring(0, 2)) {
                const searchValueContainsSpace = searchValue.substring(0, 2).includes(' '); //e.g "m s"
                adresses = await mutateAddressSearch({ phrase: searchValue.substring(0, searchValueContainsSpace ? 3 : 2) });
            }
            const newAddresses: IOptions[] = [];
            if (adresses) {
                for (const item of adresses) {
                    const { street, city, zipCodes } = item;
                    if (searchValueArray?.length === 1) {
                        if (
                            street &&
                            normalizedUpperCaseText(street)
                                .split(' ')
                                .find((item) => item.startsWith(searchValueArray[0]))
                        ) {
                            item.zipCodes?.map((zipCode) => {
                                const itemWithUniqueZipCode = { ...item, zipCodes: [zipCode] };
                                if (
                                    itemWithUniqueZipCode &&
                                    !newAddresses.find((item) => itemWithUniqueZipCode && item.name === addressName(itemWithUniqueZipCode))
                                ) {
                                    newAddresses.push({ name: addressName(itemWithUniqueZipCode), value: itemWithUniqueZipCode });
                                }
                            });
                        }
                    } else {
                        let found = true;
                        for (const phrase of searchValueArray) {
                            if (
                                !normalizedUpperCaseText(street || '')
                                    .split(' ')
                                    .find((item) => item.startsWith(phrase)) &&
                                !normalizedUpperCaseText(city || '')
                                    .split(' ')
                                    .find((item) => item.startsWith(phrase)) &&
                                !zipCodes?.find(
                                    (item) =>
                                        item.zip?.replace(/\s/g, '').startsWith(phrase) ||
                                        item.zip?.split(' ').find((item) => item.startsWith(phrase)),
                                )
                            ) {
                                found = false;
                                break;
                            }
                        }
                        if (found) {
                            item.zipCodes?.map((zipCode) => {
                                const itemWithUniqueZipCode = { ...item, zipCodes: [zipCode] };
                                if (!newAddresses.find((item) => item.name === addressName(itemWithUniqueZipCode))) {
                                    newAddresses.push({ name: addressName(itemWithUniqueZipCode), value: itemWithUniqueZipCode });
                                }
                            });
                        }
                    }

                    if (newAddresses.length > 20) {
                        break;
                    }
                }
            }
            setAddressOptions(newAddresses);
        }
        setDebounceLoading(false);
        setSearchPhrase(searchValue);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const [mutateAddressSearch, { isLoading, data }] = useMutationWithError<SkSppNzpBeApiCodelistAddress[] | null, unknown, { phrase: string }>(
        async ({ phrase }) => api.addresses.searchAddresses({ ft: phrase }, { secure: true }).then((res) => res.data),
    );

    return (
        <>
            {type === 'search' && (
                <div className="mb-3">
                    <AutoComplete<IOptions>
                        options={addressOptions}
                        placeholder={t('customer-request.steps.address.street')}
                        onSelect={addressSelected}
                        errors={errors}
                        onChange={(val) => {
                            if (val) {
                                if (val.length >= minValueLengthForAddressSearch) {
                                    setDebounceLoading(true);
                                }
                                debounce(() => onAddressPhraseChange(val), 400);
                            }
                        }}
                        defaultSelected={
                            address
                                ? [
                                      {
                                          name: address.street + ', ' + address.city + ', ' + address.zipCode,
                                          value: {
                                              area: '',
                                              city: address.city || '',
                                              street: address.street || '',
                                              zipCodes: [{ zip: address.zipCode || '' }],
                                          },
                                      },
                                  ]
                                : undefined
                        }
                        className="rbt-input-form form-control"
                        name={`${objectName}street`}
                        loading={isLoading || debounceLoading}
                        hideChevron
                        hideInputWhenOptionSelected
                    />
                </div>
            )}
            <Input
                trigger={trigger}
                ref={register(mergeValidations({ ...requiredTrimed.validate, ...latinCharacters.validate }))}
                errors={errors}
                type="text"
                name={`${objectName}street`}
                label={<Trans i18nKey="customer-request.steps.address.street">Ulica</Trans>}
                autoFocus
                containerClassName={type === 'search' ? 'd-none' : ''}
                characterFilter={CharacterFilter.LATIN}
            />
            <Input
                trigger={trigger}
                ref={register(mergeValidations({ ...requiredTrimed.validate, ...latinCharacters.validate }))}
                errors={errors}
                type="text"
                name={`${objectName}streetNumber`}
                label={<Trans i18nKey="customer-request.steps.address.number">Číslo</Trans>}
                characterFilter={CharacterFilter.LATIN}
            />
            <Input
                trigger={trigger}
                ref={register(mergeValidations({ ...requiredTrimed.validate, ...latinCharacters.validate }))}
                errors={errors}
                type="text"
                name={`${objectName}city`}
                label={<Trans i18nKey="customer-request.steps.address.city">Mesto</Trans>}
                disabled={onlyStreetAndCountry}
                characterFilter={CharacterFilter.LATIN}
            />
            <Input
                trigger={trigger}
                ref={combineRefs<HTMLInputElement>([
                    register({ ...requiredTrimed, ...(isSKorCZPostalCode || requireSkPostalCode ? formRules.postalCodeSk : formRules.postalCode) }),
                    localRef,
                ])}
                errors={errors}
                type="text"
                maxLength={isSKorCZPostalCode ? 6 : 13}
                name={`${objectName}zipCode`}
                label={<Trans i18nKey="customer-request.steps.address.postcode">PSČ</Trans>}
                disabled={onlyStreetAndCountry}
                characterFilter={CharacterFilter.LATIN}
            />
            {!hideCountry && (
                <BaseSelect
                    ref={register(requiredTrimed)}
                    name={`${objectName}country`}
                    label={<Trans i18nKey="customer-request.steps.address.country">Štát</Trans>}
                    errors={errors}
                >
                    {countryCodeList?.map((item, index) => (
                        <option key={index} value={item.uuid}>
                            {item.name}
                        </option>
                    ))}
                </BaseSelect>
            )}
        </>
    );
};
export default AddressFields;
