import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Routes from '../../../routes/routes';
import { ClickableElement } from '../../common/clickable-element';

type ProfileMenuPropsType = {
    linkClicked: () => void;
    userDetails: Record<string, any>;
    accountSettings?: boolean;
    logout: () => void;
    isEmployeesMenu?: boolean;
};

const ProfileMenu: React.FC<ProfileMenuPropsType> = ({ linkClicked, userDetails, accountSettings, logout }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const fullName = userDetails?.firstName + ` ${userDetails?.lastName || ''}`;

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        linkClicked();
        logout();
    };

    return (
        <div className={`dropdown d-none d-lg-block order-2 order-lg-4 ${isOpen ? 'show' : ''}`} onClick={toggle}>
            <div className="text-primary cursor-pointer navbar-nav" aria-haspopup="true" aria-expanded="false" onClick={linkClicked}>
                <i className="icon-user fa-lg mr-3 d-lg-none"></i>
                <div
                    className={`mt-n2 nav-link nav-link2 text-truncate ${
                        window.location.pathname.includes(Routes.CUSTOMER_SETTINGS) ? 'link2-active' : ''
                    }`}
                    style={{ fontSize: '18px', maxWidth: '320px' }}
                >
                    <span className="d-block forms-headline d-lg-none">
                        <Trans i18nKey="navbar.logged-as">Prihlásený ako</Trans>
                    </span>
                    <i className="icon-user fa-lg mr-2" />
                    {fullName}
                </div>
            </div>
            <div className={`dropdown-menu dropdown-menu-right py-0 px-0 ${isOpen ? 'show' : ''}`}>
                <div className="dropdown-item d-flex flex-row py-4 border-bottom" style={{ cursor: 'default' }}>
                    <i className="icon-user fa-lg mr-3"></i>
                    <div className="text-truncate" style={{ textDecoration: 'none' }}>
                        <div className="d-block text-secondary small">
                            <Trans i18nKey="navbar.logged-as">Prihlásený ako</Trans>
                        </div>
                        {fullName}
                    </div>
                </div>
                {accountSettings ? (
                    <Link to={Routes.CUSTOMER_SETTINGS} className="dropdown-item py-4 border-bottom">
                        <i className="icon-settings fa-lg mr-3"></i>
                        <span>
                            <Trans i18nKey="navbar.settings">Nastavenia</Trans>
                        </span>
                    </Link>
                ) : null}
                <Link to={Routes.ABOUT_APPLICATION} className="dropdown-item py-4 border-bottom">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-3" size="lg" />
                    <span>
                        <Trans i18nKey="navbar.about-app">O aplikácii</Trans>
                    </span>
                </Link>

                <ClickableElement className="dropdown-item py-4 d-block" onClick={handleLogout}>
                    <i className="icon-logout fa-lg mr-3"></i>
                    <span>
                        <Trans i18nKey="action.logout">Odhlásiť sa</Trans>
                    </span>
                </ClickableElement>
            </div>
        </div>
    );
};

export default ProfileMenu;
