import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CardHeader } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { BaseButton, BaseRadio } from '../../../../../components/common';
import ConfirmDialog from '../../../../../components/dialog/confirm-dialog';
import { useApi } from '../../../../../hooks/use-api';
import useCodeList from '../../../../../hooks/use-code-list';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import Routes from '../../../../../routes/routes';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { formatAddressShort } from '../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../config/enums';
import { blocksTranscriptNewCustomerOnlyEE, blocksTranscriptNewCustomerOnlyZP } from '../../../config/request-config';
interface IConfirmed {
    deliveryPointId?: string;
    confirmed?: boolean;
}

interface IError {
    deliveryPointId?: string;
    error: boolean;
}

const BlockMeterReadingConfirm: React.FC = () => {
    const dispatch = useDispatch();
    const { content, step } = useSelector((state: IRootState) => state.customerRequest);
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const api = useApi();
    const history = useHistory();
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);

    const [confirmed, setConfirmed] = useState<IConfirmed[]>();
    const [errors, setErrors] = useState<IError[]>();

    const [modalVisible, setModalVisible] = useState(false);

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const onSubmit = () => {
        const errorsTmp: IError[] = [];
        confirmed?.forEach((item) => {
            if (item.confirmed !== true) {
                errorsTmp.push({ deliveryPointId: item.deliveryPointId, error: true });
            }
        });
        if (errorsTmp.length > 0) {
            setErrors(errorsTmp);
            return;
        }
        dispatch(
            CustomerRequestActions.setData({
                selectedDPorBPCountry: countryCodeList?.find((item) => item.code === 'SK'),
            }),
        );
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const deliveryPoints = useMemo(() => [...(content.deliveryPointsZP ?? []), ...(content.deliveryPointsEE ?? [])], [
        content.deliveryPointsEE,
        content.deliveryPointsZP,
    ]);

    useEffect(() => {
        setConfirmed(deliveryPoints.map((dp) => ({ deliveryPointId: dp.id, confirmed: step.lastOpened > 0 ? true : undefined })));
    }, [deliveryPoints, step]);

    useEffect(() => {
        if (step.lastOpened === 0 && (content.deliveryPointsZP?.length === 0 || content.deliveryPointsEE?.length === 0)) {
            if (content.deliveryPointsZP?.length === 0) {
                dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomerOnlyEE));
            } else {
                dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomerOnlyZP));
            }
        }
    }, [content.deliveryPointsEE?.length, content.deliveryPointsZP?.length, dispatch, step.lastOpened]);

    const isProblemWithMeterReading = useMemo(() => (confirmed?.find((item) => item.confirmed === false) ? true : false), [confirmed]);

    const requestRejectRequest = useCallback(
        async () =>
            loggedInCustomer?.id && content.transcriptRequestId
                ? api.customers
                      .rejectCustomerRequests(
                          loggedInCustomer.id,
                          content.transcriptRequestId,
                          {
                              rejectReason: 'REJECTED_METER_READING',
                          },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
        [loggedInCustomer, content.transcriptRequestId, api],
    );

    const [mutateRejectRequest] = useMutationWithError(requestRejectRequest, {
        onSuccess: () => {
            dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
            addToast(t('common.success-reject-request'), {
                appearance: 'success',
            });
            setTimeout(() => {
                history.push(`${Routes.DELIVERY_POINTS}`);
            }, 1000);
        },
        onErrorWithGlobalErrorHandling: () => {
            addToast(t('common.error-reject-request'), {
                appearance: 'error',
            });
            return true;
        },
    });

    const handleNavigationClick = (action: string) => {
        if (action === 'confirm') {
            mutateRejectRequest();
        }
        setModalVisible(false);
    };

    return (
        <>
            {deliveryPoints.map((dp) => {
                const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                return (
                    <CardHeader key={dp.id} className="border-bottom-0 rounded mb-4 p-4" style={{ backgroundColor: 'white' }}>
                        {formatAddressShort(dp.address)} - {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')}{' '}
                        ({t('common.delivery-point')} {dp.externalId})
                        <p>
                            <b>{t('customer-request.steps.meter-confirm-transcript.meter-condition')}</b>:{' '}
                            {meterReading?.value ? (
                                <b>
                                    <span style={{ fontSize: 18 }}>
                                        {meterReading.value}{' '}
                                        {dp.type === CommodityEnum.GAS ? (
                                            <>
                                                m<sup>3</sup>
                                            </>
                                        ) : (
                                            'kWh'
                                        )}
                                    </span>
                                </b>
                            ) : (
                                <b>
                                    <br />
                                    <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" /> :{' '}
                                    <span style={{ fontSize: 18 }}>
                                        {meterReading?.valueHigh}
                                        {' kWh'}
                                    </span>{' '}
                                    <br />
                                    <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" /> :{' '}
                                    <span style={{ fontSize: 18 }}>
                                        {meterReading?.valueLow}
                                        {' kWh'}
                                    </span>
                                </b>
                            )}
                        </p>
                        <hr />
                        <BaseRadio
                            id={`confirm_${dp.id}_yes`}
                            label={t('customer-request.steps.meter-confirm-transcript.confirm')}
                            onClick={() => {
                                const tmpConfirmed = confirmed?.filter((item) => item.deliveryPointId !== dp.id) ?? [];
                                tmpConfirmed.push({ deliveryPointId: dp.id, confirmed: true });
                                setConfirmed(tmpConfirmed);
                                setErrors(errors?.filter((item) => item.deliveryPointId !== dp.id));
                            }}
                            checked={confirmed?.find((item) => item.deliveryPointId === dp.id)?.confirmed === true}
                        />
                        <BaseRadio
                            id={`confirm_${dp.id}_no`}
                            label={t('customer-request.steps.meter-confirm-transcript.not-confirm')}
                            onClick={() => {
                                const tmpConfirmed = confirmed?.filter((item) => item.deliveryPointId !== dp.id) ?? [];
                                tmpConfirmed.push({ deliveryPointId: dp.id, confirmed: false });
                                setConfirmed(tmpConfirmed);
                                setErrors(errors?.filter((item) => item.deliveryPointId !== dp.id));
                            }}
                            checked={confirmed?.find((item) => item.deliveryPointId === dp.id)?.confirmed === false}
                        />
                        {errors?.find((item) => item.deliveryPointId === dp.id)?.error === true && (
                            <div className="mt-2 ml-2" style={{ color: 'red' }}>
                                {t('common.input-rules.required')}
                            </div>
                        )}
                    </CardHeader>
                );
            })}

            {isProblemWithMeterReading && (
                <div className="p-4 rounded my-4" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                    <p className="mb-2 text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </p>
                    <p className="px-3 m-0 text-center">{t('customer-request.steps.meter-confirm-transcript.warning')}</p>
                </div>
            )}
            {isProblemWithMeterReading ? (
                <BaseButton type="button" onClick={() => setModalVisible(true)}>
                    <Trans i18nKey="customer-request.detail.cancel-request">Zrušiť žiadosť</Trans>
                </BaseButton>
            ) : (
                <BaseButton type="button" disabled={isProblemWithMeterReading} onClick={onSubmit}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            )}

            <ConfirmDialog
                visible={modalVisible}
                header={t(`customer-request.leave-header`)}
                body={t('customer-request.steps.meter-confirm-transcript.cancel-request-info')}
                onClick={handleNavigationClick}
                confirmButton={t(`customer-request.leave-confirm`)}
                cancelButton={t(`customer-request.leave-cancel`)}
            />
        </>
    );
};

export default BlockMeterReadingConfirm;
