import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PortalAccountsRoutes, SystemManagementRoutes } from '../../../routes/routes';
import { Can } from '../../permissions';

type NavLinksEmployeePropsType = {
    linkClicked: () => void;
};

const NavLinksEmployee: React.FC<NavLinksEmployeePropsType> = ({ linkClicked }) => {
    const pathname = window.location.pathname;
    return (
        <ul className="navbar-nav mr-auto ml-0 ml-lg-4 pt-3 pt-lg-0">
            <Can permissions="CUSTOMERS_VIEW">
                <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                    <Link
                        className={`nav-link py-3 ${pathname.includes(PortalAccountsRoutes.CUSTOMERS) ? 'link-active' : ''}`}
                        to={PortalAccountsRoutes.CUSTOMERS}
                        onClick={linkClicked}
                    >
                        <i className="icon-user fa-lg mr-3 d-lg-none"></i>
                        <Trans i18nKey="navbar.navigation.customers">Zákazníci</Trans>
                    </Link>
                </li>
            </Can>

            <Can permissions={['AUDIT_LOGS_EMPLOYEES', 'AUDIT_LOGS_CUSTOMERS_WITH_EMPLOYEES', 'AUDIT_LOGS_CUSTOMERS']}>
                <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                    <Link
                        className={`nav-link py-3 ${pathname.includes(PortalAccountsRoutes.EMPLOYEE_ACTIVITY_LOGS) ? 'link-active' : ''}`}
                        to={PortalAccountsRoutes.EMPLOYEE_ACTIVITY_LOGS}
                        onClick={linkClicked}
                    >
                        <i className="icon-file-text fa-lg mr-3 d-lg-none"></i>
                        <Trans i18nKey="navbar.navigation.activity">Aktivita</Trans>
                    </Link>
                </li>
            </Can>

            <Can permissions="REPORTS_VIEW">
                <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                    <Link
                        className={`nav-link py-3 ${pathname.includes(PortalAccountsRoutes.REPORT_DETAIL) ? 'link-active' : ''}`}
                        to={PortalAccountsRoutes.REPORTS}
                        onClick={linkClicked}
                    >
                        <i className="icon-clipboard fa-lg mr-3 d-lg-none"></i>
                        <Trans i18nKey="navbar.navigation.reports">Reporty</Trans>
                    </Link>
                </li>
            </Can>

            {/* <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                <Link className="nav-link py-3" to={PortalAccountsRoutes.MEDIATIONS} onClick={linkClicked}>
                    <i className="icon-clipboard fa-lg mr-3 d-lg-none"></i>
                    <Trans i18nKey="navbar.navigation.mediations">Sprostredkovania</Trans>
                </Link>
            </li> */}

            <Can permissions={['ACCESS_GROUPS_VIEW', 'NOTIFICATION_TEMPLATES_VIEW', 'COMPONENT_HELP_VIEW']}>
                <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                    <Link
                        className={`nav-link py-3 ${pathname.includes(SystemManagementRoutes.SYSTEM_MANAGEMENT) ? 'link-active' : ''}`}
                        to={SystemManagementRoutes.SYSTEM_MANAGEMENT}
                        onClick={linkClicked}
                    >
                        <i className="icon-clipboard fa-lg mr-3 d-lg-none"></i>
                        <Trans i18nKey="navbar.navigation.system-management">Správa systému</Trans>
                    </Link>
                </li>
            </Can>
        </ul>
    );
};

export default NavLinksEmployee;
