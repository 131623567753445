import React from 'react';
import EnterRegistrationDataScreen from './screen/enter-registration-data-screen';

const Registration: React.FC = () => {
    return (
        <>
            <EnterRegistrationDataScreen />
        </>
    );
};

export default Registration;
