import { SkSppNzpBeApiCustomeraccessChallenge } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../components/common/loading-indicator';
import useSso from '../../hooks/sso/use-sso';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IApiResponse } from '../../main';
import { IRootState } from '../../reducers';

export const AccountDeactivationConfirmation: React.FC = () => {
    const urlQuery = useUrlQuery();
    const code = urlQuery.get('challengeCode');
    const uuid = urlQuery.get('challengeCodeUuid');
    const isSilentRefreshResolved = useSelector((s: IRootState) => s.auth.isSilentRefreshResolved);
    const accessToken = useSelector((s: IRootState) => s.auth.accessToken);
    const [isLinkNotExpired, setIsLinkNotExpired] = useState<boolean | undefined>(undefined);
    const [t] = useTranslation();
    const [wrongChallange, setWrongChallange] = useState<boolean>(false);

    const { ssoLogout } = useSso();
    const logoutCustomer = () => {
        ssoLogout(null);
    };

    const api = useApi();
    const [mutateDeactivation] = useMutationWithError(
        async (deactivationData: SkSppNzpBeApiCustomeraccessChallenge) => api.customers.deleteCustomerAccount(deactivationData, { secure: true }),
        {
            onSuccess: () => {
                setIsLinkNotExpired(true);
                if (accessToken) logoutCustomer();
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error?.code === 2010) {
                    setIsLinkNotExpired(false);
                    return true;
                }
                if (error?.code === 2005) {
                    setWrongChallange(true);
                    return true;
                }
                return false;
            },
        },
    );

    useEffect(() => {
        if (isSilentRefreshResolved) {
            mutateDeactivation({
                code: code || '',
                uuid: uuid || '',
            });
        }
    }, [mutateDeactivation, code, uuid, isSilentRefreshResolved]);

    return (
        <>
            <div className="container">
                <div className="row text-center py-5">
                    {!wrongChallange && isLinkNotExpired === undefined && <LoadingIndicator size="large" fullscreen />}
                    {isLinkNotExpired === false && (
                        <div className="col-12 position-static text-center">
                            <i className="icon-circle-alert-16 fa-5x text-danger d-block my-3"></i>
                            <h3 className="my-3 text-danger">
                                <Trans i18nKey="confirm-account-deactivation.error" />
                            </h3>
                            <div className="text-center mt-4">{t('confirm-account-deactivation.confirm-deactivation-email-expired')}</div>
                        </div>
                    )}
                    {wrongChallange && (
                        <div className="col-12 position-static text-center">
                            <i className="icon-circle-alert-16 fa-5x text-danger d-block my-3"></i>
                            <h3 className="my-3 text-danger">
                                <Trans i18nKey="settings.login-details.acount-deactivation-failed" />
                            </h3>
                        </div>
                    )}
                    {isLinkNotExpired === true && (
                        <div className="col-12 position-static">
                            <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                            <h3 className="my-3 text-success">
                                <Trans i18nKey="confirm-account-deactivation.success" />
                            </h3>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
