import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoiceSearch,
    SkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PageableTable from '../../components/common/table/pageable-table';
import TableFilterControl from '../../components/common/table/table-filter-control';
import { IColumnConfiguration, ISort } from '../../components/common/table/table-interfaces';
import { can } from '../../components/permissions';
import { useApi } from '../../hooks/use-api';
import useCodeList from '../../hooks/use-code-list';
import { useKeyValueStore } from '../../hooks/use-key-value';
import { usePaginatedQueryWithError } from '../../hooks/use-paginated-query-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IRootState } from '../../reducers';
import Routes from '../../routes/routes';
import { QueryKeysEnum } from '../../utils/react-query-utils';
import { isObjectEmpty } from '../../utils/utils';
import { CodeListTypeEnum } from '../customer-request/config/enums';
import EpayModal from './e-pay/e-pay-modal';
import { invoicesColumnConfigurations } from './invoices-column-configuration';
import { InvoicesFilterDescription } from './invoices-filter/invoices-filter-description';
import { IInvoiceBP } from './invoices-filter/invoices-filter-form';
import InvoicesTableFilterModal, { IInvoicesTableFilter } from './invoices-filter/invoices-filter-modal';

export type Statuses = NonNullable<SkSppNzpBeApiCustomerprofileInvoiceSearch['statuses']>;

export interface IInvoicesFilter {
    deliveryPoint: string;
    commodity: string;
    type: string;
    state: string;
    from: Date | undefined;
    to: Date | undefined;
}

export interface IInvoicesAdditionalFilter {
    unpaidOnly: boolean;
}

export interface IUnpaidInvoices {
    count: number;
    price: number;
}

interface IInvoicesTableProps {
    deliveryPointId?: string;
    contractId?: string;
    unitedDeliveryPointId?: string;
    isDeliveryPointDetail?: boolean;
    commodity?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    unitedDeliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null;
    setShowInfo: (value: boolean) => void;
    customer?: IInvoiceBP;
    invoicesSummary: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined;
    availableCommodities?: Exclude<SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'], undefined>[];
}

const InvoicesTable: React.FC<IInvoicesTableProps> = ({
    deliveryPointId,
    contractId,
    unitedDeliveryPointId,
    isDeliveryPointDetail,
    commodity,
    deliveryPoints,
    unitedDeliveryPoints,
    setShowInfo,
    customer,
    invoicesSummary,
    availableCommodities,
}) => {
    const pageSize = 10;
    const urlQuery = useUrlQuery();

    const [selectedInvoice, setSelectedInvoice] = useState<SkSppNzpBeApiCustomerprofileInvoice | undefined>(undefined);
    const [isPaymentModal, setPaymentModal] = useState(false);
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const [pageIndex, setPageIndex] = useState(0);
    const [, setStoredPageIndex] = useKeyValueStore(Routes.INVOICES + '@pageIndex', 0);
    const user = useSelector((state: IRootState) => state.user);

    const [filter, setFilter] = useKeyValueStore<Partial<IInvoicesTableFilter>>(
        deliveryPointId ? 'delivery-point-invoices-table-filter' : 'invoices-table-filter',
        {},
    );
    const [_sort, setSort] = useKeyValueStore<ISort | undefined>(
        deliveryPointId ? 'delivery-point-invoices-table-sort' : 'invoices-table-sort',
        undefined,
    );
    const unpaidOnlyFromUrl = Array.from(urlQuery.keys()).includes('unpaidOnly');
    const [additionalFilter, setAdditionalFilter] = useKeyValueStore<IInvoicesAdditionalFilter>(
        deliveryPointId ? 'delivery-point-invoices-table-additional-filter' : 'invoices-table-additional-filter',
        {
            unpaidOnly: unpaidOnlyFromUrl || false,
        },
        unpaidOnlyFromUrl,
    );

    useEffect(() => {
        if (unpaidOnlyFromUrl) {
            setAdditionalFilter((filter) => ({ ...filter, unpaidOnly: unpaidOnlyFromUrl }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sort = useMemo<ISort | undefined>(() => {
        // validate partial sort
        if (_sort != null && _sort.attribute != null && _sort.direction != null) {
            return { attribute: _sort.attribute, direction: _sort.direction };
        }
        return undefined;
    }, [_sort]);

    // reset page on delivery point change
    useEffect(() => {
        if (unitedDeliveryPointId || deliveryPointId) {
            onPageChange(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitedDeliveryPointId, deliveryPointId]);

    const api = useApi();
    const employee = useSelector((state: IRootState) => state.user.employee);

    const { data: invoiceTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_INVOICE_TYPE,
        codeListTypeEnum: CodeListTypeEnum.INVOICE_TYPE,
        paging: {
            size: 64,
        },
    });

    const invoiceRequestSearchParameters: SkSppNzpBeApiCustomerprofileInvoiceSearch = {
        //TODO change for filter, see delivery-list-customer-request.tsx
        customerUnpaid: additionalFilter.unpaidOnly,
        issueAt: filter.issueAt,
        invoiceGroup: filter.invoiceGroup,
        productType: filter.productType,
        businessPartnerId: filter.businessPartnerId,
        paging: {
            size: pageSize,
            page: pageIndex,
            sort: sort?.attribute
                ? [
                      {
                          attribute: sort?.attribute,
                          direction: sort?.direction,
                      },
                      {
                          attribute: 'invoiceExternalId',
                          direction: 'DESC',
                      },
                  ]
                : [
                      {
                          attribute: 'INVOICE_ISSUE_AT',
                          direction: 'DESC',
                      },
                      {
                          attribute: 'invoiceExternalId',
                          direction: 'DESC',
                      },
                  ],
        },
        shared: true,
    };

    const getUnitedDeliveryPointIdsForCustomerInvoiceSearch = () => {
        if (filter.unitedDeliveryPointIds && filter.unitedDeliveryPointIds.length > 0) {
            if (unitedDeliveryPointId != null) {
                return filter.unitedDeliveryPointIds.concat([unitedDeliveryPointId]);
            }
            return filter.unitedDeliveryPointIds;
        }
        if (unitedDeliveryPointId != null) {
            return [unitedDeliveryPointId];
        }
        return undefined;
    };

    const getContractIdsForInvoiceSearch = (dpIds: string[]) => {
        const resultContractIds: string[] = [];
        dpIds?.forEach((dpId) => {
            const foundDp = deliveryPoints?.find((x) => x.id === dpId);
            const foundContractId = foundDp?.contract?.id;
            if (foundContractId) {
                resultContractIds.push(foundContractId);
            }
        });
        return resultContractIds;
    };

    const requestCustomerInvoices = () => {
        return loggedInCustomer?.id
            ? api.customers
                  .customerInvoiceSearch(
                      loggedInCustomer.id,
                      {
                          ...invoiceRequestSearchParameters,
                          unitedDeliveryPointIds: getUnitedDeliveryPointIdsForCustomerInvoiceSearch(),
                          contractIds: filter.deliveryPointIds != null ? getContractIdsForInvoiceSearch(filter.deliveryPointIds) : undefined,
                          businessPartnerId: filter.businessPartnerId && !customer ? filter.businessPartnerId : undefined,
                      },
                      { secure: true },
                  )
                  .then((res) => res.data)
            : null;
    };

    const requestDeliveryPointInvoices = () => {
        return contractId
            ? api.deliveryPoints
                  .invoiceSearchByContract(
                      contractId,
                      {
                          ...invoiceRequestSearchParameters,
                          contractIds: filter.deliveryPointIds != null ? getContractIdsForInvoiceSearch(filter.deliveryPointIds) : undefined,
                          businessPartnerId: customer ? undefined : filter.businessPartnerId,
                          productType: deliveryPointId ? undefined : filter.productType,
                      },

                      { secure: true },
                  )
                  .then((res) => res.data)
            : null;
    };

    const {
        data,
        isLoading,
        isPreviousData,
    } = usePaginatedQueryWithError<SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileInvoiceSummary | null>({
        // TODO use correct key string
        queryKey: [
            //TODO hook to filter, see MyListCustomerRequest
            deliveryPointId ? 'DeliveryPointInvoices' : 'CustomerInvoices',
            deliveryPointId,
            additionalFilter.unpaidOnly,
            sort?.direction,
            sort?.attribute,
            filter?.issueAt,
            filter?.invoiceGroup,
            filter?.productType,
            filter?.deliveryPointIds,
            filter?.unitedDeliveryPointIds,
            filter?.businessPartnerId,
            pageSize,
            pageIndex,
        ],
        queryFn: async () => (deliveryPointId ? requestDeliveryPointInvoices() : loggedInCustomer?.id ? requestCustomerInvoices() : null),
        config: { enabled: employee ? can('ENTITY_INVOICES_VIEW', employee) : true },
    });

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const history = useHistory();

    const [columnConfigurations, setColumnConfigurations] = useState<IColumnConfiguration<SkSppNzpBeApiCustomerprofileInvoiceSummary>[]>([]);

    const isEmployee = useSelector((state: IRootState) => !!state.user.employee);

    const onRowSelect = (invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary) => {
        unitedDeliveryPointId
            ? history.push(`${Routes.DELIVERY_POINTS}/${unitedDeliveryPointId}/${deliveryPointId || ''}/invoice/${invoice.id}`)
            : history.push(`${Routes.INVOICES_DETAIL}/${invoice.id}`);
    };

    const payAction = useCallback((invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary) => {
        setSelectedInvoice(invoice);
        setPaymentModal(true);
    }, []);

    useEffect(() => {
        if (invoiceTypeCodeList) {
            setColumnConfigurations(invoicesColumnConfigurations({ invoiceTypeCodeList }, payAction, isEmployee, user.customer?.email));
        }
    }, [invoiceTypeCodeList, user, payAction, isEmployee]);

    const queriedPage = Number(urlQuery.get('page')) || undefined;

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    useEffect(() => {
        if (data?.result && data.result.length > 0) {
            setShowInfo(true);
        } else {
            setShowInfo(false);
        }
    }, [setShowInfo, data]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    const activeFilter = () => {
        if (deliveryPointId) {
            if (filter.invoiceGroup || filter.issueAt) {
                return true;
            } else {
                return false;
            }
        }
        return !isObjectEmpty(filter);
    };

    return (
        <>
            <Container>
                <Row className="align-items-center">
                    <Col xs={6}>
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="checkboxInvoice"
                                checked={additionalFilter.unpaidOnly}
                                onChange={() => {
                                    setAdditionalFilter((prev) => ({ ...prev, unpaidOnly: !prev.unpaidOnly }));
                                    onPageChange(0);
                                }}
                            />
                            <label className="custom-control-label" htmlFor="checkboxInvoice">
                                <Trans i18nKey="invoices.onlyUnpaid" />
                            </label>
                        </div>
                    </Col>
                    <Col xs={isObjectEmpty(filter) ? 6 : 12}>
                        <TableFilterControl
                            isFilterActive={activeFilter()}
                            onEditFilter={() => setIsFilterModalOpen(true)}
                            onRemoveFilter={() => {
                                setFilter({});
                                onPageChange(0);
                            }}
                            filterDescription={
                                <InvoicesFilterDescription
                                    filter={filter}
                                    unitedDeliveryPoints={unitedDeliveryPoints || undefined}
                                    deliveryPoints={deliveryPoints}
                                    deliveryPointId={deliveryPointId}
                                />
                            }
                        />
                    </Col>
                </Row>
                <PageableTable<SkSppNzpBeApiCustomerprofileInvoiceSummary>
                    data={data?.result ? data?.result : null}
                    columnConfigurations={columnConfigurations}
                    page={pageIndex}
                    pageSize={pageSize}
                    totalDataCount={data?.paging?.total}
                    onPageChange={onPageChange}
                    sort={sort}
                    onSortChange={setSort}
                    onRowSelect={onRowSelect}
                    collapseIntoCards
                    tableLayoutFixed
                    showLoadingIndicator={isLoading || isPreviousData}
                />
            </Container>

            {selectedInvoice != null && selectedInvoice.unpaid && (
                <EpayModal
                    isOpen={isPaymentModal}
                    invoiceAmount={selectedInvoice.unpaid}
                    closeModal={() => setPaymentModal(false)}
                    invoice={selectedInvoice}
                    commodity={selectedInvoice?.deliveryPoints && selectedInvoice.deliveryPoints[0]?.type}
                    invoiceTypeCodeList={invoiceTypeCodeList}
                />
            )}

            <InvoicesTableFilterModal
                isOpen={isFilterModalOpen}
                onCloseModal={() => setIsFilterModalOpen(false)}
                filter={filter}
                onFilterChange={(newFilter) => {
                    setFilter(newFilter);
                    onPageChange(0);
                }}
                deliveryPointId={deliveryPointId}
                unitedDeliveryPointId={isDeliveryPointDetail ? undefined : unitedDeliveryPointId}
                commodity={commodity}
                customer={customer}
                deliveryPoints={deliveryPoints}
                unitedDeliveryPoints={unitedDeliveryPoints}
                hasNonCommodity={invoicesSummary?.hasNonCommodity}
                availableTypeGroups={invoicesSummary?.availableTypeGroups}
                availableCommodities={availableCommodities}
            />
        </>
    );
};

export default InvoicesTable;
