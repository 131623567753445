import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

interface IInfoDialogProps {
    visible: boolean;
    header?: string | JSX.Element;
    body?: string | JSX.Element;
    onClick: () => void;
    style?: CSSProperties;
}

const InfoDialog: React.FC<IInfoDialogProps> = ({ visible, header, body, onClick, style }) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={visible} centered style={style}>
            <ModalBody>
                <button type="button" onClick={onClick} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">{header || t('common.information')}</h3>
                <div>{body || t('common.confirmation-question')}</div>
            </ModalBody>
        </Modal>
    );
};

export default InfoDialog;
