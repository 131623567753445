export enum AppSettingsActionsEnum {
    PRIVACY_SCREEN = 'app-settings-store/PRIVACY_SCREEN',
}

export interface ISetPrivacyScreenAction {
    type: AppSettingsActionsEnum.PRIVACY_SCREEN;
    payload: boolean;
}

export type AppSettingsActionsType = ISetPrivacyScreenAction;

export const AppSettingsActions = {
    setPrivacyScreen: (mode: boolean): ISetPrivacyScreenAction => ({
        type: AppSettingsActionsEnum.PRIVACY_SCREEN,
        payload: mode,
    }),
};
