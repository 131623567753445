import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Routes from '../../../../../routes/routes';

type PropsType = {
    userData: { email: string | undefined; firstName: string | undefined; lastName: string | undefined };
};

const SubmissionMessageCard: React.FC<PropsType> = ({ userData }) => {
    return (
        <Card className="my-4">
            <CardBody>
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="../../assets/images/user-yellow.svg" alt="" className="mb-3" />
                        <h4>
                            <Trans i18nKey="customer-request.message.track-status-request">
                                Chcete sledovať stav, <br /> vašej žiadosti online?
                            </Trans>
                        </h4>
                        <p className="mt-3">
                            <Trans i18nKey="customer-request.message.complete-registration-text">
                                Stačí už len dokončiť registráciu do Moje SPP a získate množstvo výhod.
                            </Trans>
                        </p>
                        <Link to={{ pathname: Routes.REGISTRATION, state: { userData: userData } }} className="btn btn-primary">
                            <Trans i18nKey="customer-request.message.complete-registration">Dokončiť registráciu do Moje SPP</Trans>
                        </Link>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default SubmissionMessageCard;
