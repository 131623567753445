import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../../../actions/user';
import { IRootState } from '../../../../reducers';
import EnterPhoneNumberScreen from './screen/enter-phone-number-screen';
import VerifyPhoneNumberScreen from './screen/verify-phone-number-screen';

export enum StepsEnum {
    Finished,
    EnterPhoneNumber,
    VerifyPhoneNumber,
}

const PhoneNumberVerification: React.FC = () => {
    const isVerificationSkipped = useSelector((state: IRootState) => state.user.userSkippedPhoneVerification);
    const storedPhoneNumber = useSelector((state: IRootState) => state.user.customer?.phone);
    const dispatch = useDispatch();

    const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
    const [newPhoneChallengeCode, setNewPhoneChallengeCode] = useState<string>('');
    const [actualStep, setActualStep] = useState<StepsEnum>();

    const usersPhoneNumberAlreadyExisted = (): boolean => {
        return !!(storedPhoneNumber && storedPhoneNumber.length);
    };

    const userDoesntWantToVerifyNow = () => {
        dispatch(UserActions.userSkippedPhoneVerification(true));
    };

    const dismissModal = () => {
        userDoesntWantToVerifyNow();
    };

    const handleSetStep = (nextStep: StepsEnum, newPhoneNumber: string, challengeCode: string) => {
        switch (nextStep) {
            case StepsEnum.VerifyPhoneNumber:
                setNewPhoneNumber(newPhoneNumber);
                setNewPhoneChallengeCode(challengeCode);
                setActualStep(nextStep);
                break;
            case StepsEnum.Finished:
                setActualStep(nextStep);
                break;
            default:
                return;
        }
    };

    const renderStep = () => {
        switch (actualStep) {
            case StepsEnum.Finished:
                return <></>;
            case StepsEnum.VerifyPhoneNumber:
                return (
                    <VerifyPhoneNumberScreen
                        phoneNumber={newPhoneNumber}
                        challengeCode={newPhoneChallengeCode}
                        onDismiss={dismissModal}
                        setStep={handleSetStep}
                    />
                );
            default:
                return <EnterPhoneNumberScreen phoneNumber={newPhoneNumber} onDismiss={dismissModal} setStep={handleSetStep} />;
        }
    };

    return <>{usersPhoneNumberAlreadyExisted() || isVerificationSkipped ? <></> : renderStep()}</>;
};

export default PhoneNumberVerification;
