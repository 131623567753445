import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { KeyValueStoreActions } from '../actions/key-value-store-actions';
import { IRootState } from '../reducers';
import AboutApplication from '../views/about-application/about-application';
import { AccountDeactivationConfirmation } from '../views/confirmations/account-deactivation-confirmation';
import { AutoLogoutConfirmation } from '../views/confirmations/auto-logout-confirmation';
import ConfirmRegistration from '../views/confirmations/confirm-registration';
import { EmailChangeConfirmation } from '../views/confirmations/email-change-confirmation';
import ForgotPasswordConfirmation from '../views/confirmations/forgot-password-confirmation';
import { LogoutConfirmation } from '../views/confirmations/logout-confirmation';
import { RequestConfirmation } from '../views/confirmations/request-confirmation';
import Contact from '../views/contact/contact';
import Cookies from '../views/cookies/cookies';
import { CustomerRequestTranscriptNewCustomer } from '../views/customer-request-transcript-new-customer/continue-request';
import CustomerRequest from '../views/customer-request/customer-request';
import DetailCustomerRequest from '../views/customer-request/screen/detail-customer-request/detail-customer-request';
import ListCustomerRequest from '../views/customer-request/screen/list-customer-request/list-customer-request';
import DeliveryPointDetail from '../views/delivery-points/detail/delivery-point-detail';
import SelfRead from '../views/delivery-points/detail/self-read';
import ListDeliveryPoints from '../views/delivery-points/list-delivery-points';
import ManageDeliveryPoints from '../views/delivery-points/manage-delivery-points';
import ShareDeliveryPoints from '../views/delivery-points/share-delivery-points/share-delivery-points';
import InsufficientPrivileges from '../views/errors/insufficient-privileges';
import { getProperHomepage } from '../views/home/get-proper-homepage';
import InvoiceDetail from '../views/invoices/detail/invoice-detail';
import PaymentStatus from '../views/invoices/e-pay/screen/e-pay-status';
import Invoices from '../views/invoices/invoices';
import LoginIssues from '../views/login-issues/login-issues';
import Registration from '../views/registration/registration';
import ReportIssue from '../views/report-issue/report-issue';
import MarketingConsentsListBusinessPartner from '../views/settings/marketing_consents/list-business-partners';
import Settings from '../views/settings/settings';
import AuthSuccess, { MobileAuthSuccess } from '../views/sso/auth-success';
import { ChatHelpProtectedRouter } from './chat-help-protected-route';
import { ChatHelpRoute } from './chat-help-route';
import Routes, { ErrorRoutes } from './routes';

export const CustomerAndEmployeeAsCustomerRoutes: React.FC = (): ReactElement => {
    const user = useSelector((s: IRootState) => s.user);
    const dispatch = useDispatch();
    const userHomePage = user.isUserLogged ? getProperHomepage(user) : '/';
    const url = window.location.href;

    useEffect(() => {
        if (!url.includes('invoice') && !url.includes('new-request')) {
            dispatch(KeyValueStoreActions.setValue('invoices-table-filter', {}));
            dispatch(KeyValueStoreActions.setValue('invoices-table-sort', undefined));
            dispatch(KeyValueStoreActions.setValue('invoices-table-additional-filter', undefined));
            dispatch(KeyValueStoreActions.setValue(Routes.INVOICES + '@pageIndex', 0));
        }
        if (!url.includes('customer-request')) {
            dispatch(KeyValueStoreActions.setValue('my-list-customer-table-filter', {}));
            dispatch(KeyValueStoreActions.setValue('request-table-additional-filter', false));
            dispatch(KeyValueStoreActions.setValue(Routes.CUSTOMER_REQUESTS + '@pageIndex', 0));
        }
        if (!url.includes('delivery-point')) {
            dispatch(KeyValueStoreActions.setValue(Routes.DELIVERY_POINTS + '@fullText', ''));
            dispatch(KeyValueStoreActions.setValue(Routes.DELIVERY_POINTS + '@pageIndex', 0));
            dispatch(KeyValueStoreActions.setValue(Routes.DELIVERY_POINTS + '@sharedWithMe', false));
            dispatch(KeyValueStoreActions.setValue(Routes.DELIVERY_POINTS + '@sort', undefined));
            dispatch(KeyValueStoreActions.setValue(Routes.DELIVERY_POINTS + '@tableColumnFilter', {}));
        }
        if (!url.includes('delivery-points/')) {
            dispatch(KeyValueStoreActions.setValue('delivery-point-invoices-table-filter', {}));
            dispatch(KeyValueStoreActions.setValue('delivery-point-invoices-table-sort', undefined));
            dispatch(KeyValueStoreActions.setValue('delivery-point-invoices-table-additional-filter', undefined));

            dispatch(KeyValueStoreActions.setValue('delivery-point-customer-table-filter', undefined));
            dispatch(KeyValueStoreActions.setValue('delivery-point-customer-table-additional-filter', false));

            dispatch(KeyValueStoreActions.setValue('consumption-filter', {}));
            dispatch(KeyValueStoreActions.setValue('consumption-additional-filter', undefined));
        }
    }, [url, dispatch]);

    return (
        <Switch>
            <ChatHelpProtectedRouter path={Routes.CUSTOMER_REQUEST_TRANSCRIPT_NEW_CUSTOMER} component={CustomerRequestTranscriptNewCustomer} />
            <ChatHelpRoute exact path={Routes.REPORT_ISSUE} contextualHelp={'NPA'} component={ReportIssue} />
            <ChatHelpProtectedRouter
                exact
                path={Routes.MANAGE_DELIVERY_POINTS}
                component={ManageDeliveryPoints}
                contextualHelp={'SOM'}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={Routes.SHARE_DELIVERY_POINTS}
                component={ShareDeliveryPoints}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId${Routes.SELF_READ}`}
                component={SelfRead}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId`}
                component={DeliveryPointDetail}
                contextualHelp={'DOM'}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/:tab`}
                component={DeliveryPointDetail}
                contextualHelp={'DOM'}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/:deliveryPointId/invoice/:invoiceId`}
                component={InvoiceDetail}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId//invoice/:invoiceId`}
                component={InvoiceDetail}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/:tab/:deliveryPointId${Routes.EPAY_STATUS}/:transactionId`}
                component={PaymentStatus}
                contextualHelp={'DOM'}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/:tab/:deliveryPointId`}
                component={DeliveryPointDetail}
                contextualHelp={'DOM'}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/requests/:deliveryPointId/new`}
                component={ListCustomerRequest}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/requests//new`}
                component={ListCustomerRequest}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/requests/:deliveryPointId/:requestId`}
                component={DetailCustomerRequest}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.DELIVERY_POINTS}/:unitedDeliveryPointId/requests//:requestId`}
                component={DetailCustomerRequest}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />
            <ChatHelpProtectedRouter
                exact
                path={Routes.DELIVERY_POINTS}
                contextualHelp={'ODM'}
                component={ListDeliveryPoints}
                permissions="ENTITY_DELIVERY_POINTS_VIEW"
            />

            <ChatHelpProtectedRouter
                exact
                path={`${Routes.INVOICES}${Routes.EPAY_STATUS}/:transactionId`}
                component={PaymentStatus}
                contextualHelp={'FAK'}
                permissions="ENTITY_INVOICES_VIEW"
            />
            <ChatHelpProtectedRouter exact path={Routes.INVOICES} component={Invoices} contextualHelp={'FAK'} permissions="ENTITY_INVOICES_VIEW" />
            <ChatHelpProtectedRouter
                exact
                path={`${Routes.INVOICES_DETAIL}/:invoiceId`}
                component={InvoiceDetail}
                permissions="ENTITY_INVOICES_VIEW"
            />
            <ChatHelpProtectedRouter path={`${Routes.MARKETING_CONSENTS}/:businessPartnerId?`} component={MarketingConsentsListBusinessPartner} />
            <Route path={Routes.CUSTOMER_REQUESTS} component={CustomerRequest} permissions="ENTITY_REQUESTS_VIEW" />
            <ChatHelpProtectedRouter path={Routes.CUSTOMER_SETTINGS} component={Settings} />

            <ChatHelpRoute exact path={Routes.REGISTRATION} component={Registration} contextualHelp={'REG'} />
            <ChatHelpRoute exact path={Routes.PASSWORD_RECOVERY_CONFIRMATION} component={ForgotPasswordConfirmation} />
            <ChatHelpRoute exact path={Routes.ACCOUNT_DEACTIVATION_CONFIRMATION} component={AccountDeactivationConfirmation} />
            <ChatHelpRoute exact path={Routes.REGISTRATION_CONFIRMATION} component={ConfirmRegistration} />
            <ChatHelpRoute exact path={Routes.EMAIL_CONFIRMATION} component={EmailChangeConfirmation} />
            <ChatHelpRoute exact path={Routes.REQUEST_CONFIRMATION} component={RequestConfirmation} />

            <ChatHelpRoute exact path={Routes.LOGOUT_CONFIRMATION} component={LogoutConfirmation} />
            <ChatHelpRoute exact path={Routes.AUTO_LOGOUT_CONFIRMATION} component={AutoLogoutConfirmation} />
            <ChatHelpRoute exact path={Routes.CONTACT} component={Contact} />
            <ChatHelpRoute exact path={Routes.ABOUT_APPLICATION} component={AboutApplication} />
            <ChatHelpRoute exact path={Routes.COOKIES} component={Cookies} />

            <ChatHelpRoute path={Routes.LOGIN_ISSUES} component={LoginIssues} contextualHelp={'RPR'} />
            <Route exact path={Routes.SSO_SUCCESS} component={AuthSuccess} />
            <Route exact path={Routes.SSO_MOBILE_SUCCESS} component={MobileAuthSuccess} />
            <Route exact path={ErrorRoutes.INSUFFICIENT_PRIVILEGES} component={InsufficientPrivileges} />

            {/* Redirect all 404's to home */}
            {user.isUserLogged && <Redirect exact from="/" to={userHomePage} />}
        </Switch>
    );
};
