import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClickableElement, TextColumnFilter } from '../../../components/common';
import AutoComplete from '../../../components/common/autoComplete/autoComplete';
import { CommodityEnum } from '../../../models/enums';

export type DeliveryPointsTableFilterData = {
    dpExternalId?: string;
    address?: string;
    type?: CommodityEnum[];
    customer?: string;
};

type Props = {
    filterBy: (filterData: DeliveryPointsTableFilterData) => void;
    filter: DeliveryPointsTableFilterData;
    showCheckboxColumn?: boolean;
};

interface IOptions {
    name: string;
    value: CommodityEnum;
}

const ListDeliveryPointsTableFilter = (props: Props): ReactElement => {
    const [dpExternalId, setDpExternalId] = useState<string>(props.filter.dpExternalId || '');
    const [addressValue, setAddressValue] = useState<string>(props.filter.address || '');
    const [personValue, setPersonValue] = useState<string>(props.filter.customer || '');
    const [commodities, setComodities] = useState<CommodityEnum[]>(props.filter.type || []);
    const { t } = useTranslation();
    const options = [
        { name: t('common.commodity.gas'), value: CommodityEnum.GAS },
        { name: t('common.commodity.electricity'), value: CommodityEnum.ELECTRICITY },
    ];

    const doFiltering = useCallback(() => {
        props.filterBy({
            dpExternalId: dpExternalId,
            address: addressValue,
            type: commodities,
            customer: personValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dpExternalId, addressValue, commodities, personValue]);

    const comodityChange = (options: IOptions[]) => {
        setComodities(options.map((option) => option.value));
    };

    // as keyof typeof TABSEnums]
    return (
        <tr className="thead-light filter">
            {props.showCheckboxColumn && <th></th>}
            <TextColumnFilter
                name="dpExternalId"
                placeholder={t('delivery-point.place-number')}
                minWidth="170px"
                filterValue={dpExternalId}
                className="mb-1"
                onFilterChange={setDpExternalId}
                onFilterSubmit={doFiltering}
            />
            <TextColumnFilter
                name="address"
                placeholder={t('delivery-point.address')}
                minWidth="170px"
                filterValue={addressValue}
                className="mb-1"
                onFilterChange={setAddressValue}
                onFilterSubmit={doFiltering}
                colSpan={2}
            />
            <th key="commodity" scope="col" style={{ minWidth: '210px' }}>
                {/* Table filter*/}
                <div className="autocomplete-search-box">
                    <AutoComplete<IOptions>
                        hideChevron
                        options={options}
                        placeholder={t('common.choose-the-option')}
                        onSelect={comodityChange}
                        multiple
                        defaultSelected={options.filter((op) => commodities.includes(op.value))}
                        inputGroupAppendChildren={
                            <button type="button" className="btn text-primary" onClick={doFiltering}>
                                <i className="icon-search fa-lg" />
                            </button>
                        }
                    />
                </div>
                {/* / Table filter */}
            </th>
            <TextColumnFilter
                name="person"
                placeholder={t('delivery-point.customer')}
                filterValue={personValue}
                className="mb-1"
                onFilterChange={setPersonValue}
                onFilterSubmit={doFiltering}
            />

            <th
                scope="col"
                style={{ textAlign: 'center', fontWeight: 'normal', textTransform: 'none', fontSize: '0.875rem', letterSpacing: 'normal' }}
            >
                {(!!dpExternalId || !!addressValue || !!personValue || commodities.length > 0) && (
                    <ClickableElement
                        onClick={() => {
                            setDpExternalId('');
                            setAddressValue('');
                            setPersonValue('');
                            setComodities([]);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" className="fa-lg mr-1 text-decoration-none mx-auto d-block" />
                        <Trans i18nKey="common.tables.remove" />
                    </ClickableElement>
                )}
            </th>
        </tr>
    );
};

export default ListDeliveryPointsTableFilter;
