import { SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { IColumnConfiguration } from '../../components/common/table/table-interfaces';
import { formatCurrency, formatDate } from '../../utils/utils';

export const transactionsColumnConfigurations = (): IColumnConfiguration<SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary>[] => [
    {
        label: <Trans i18nKey="invoices.transactionTable.column-finished-at" />,
        columnId: 'TRANSACTION_FINISHED_AT',
        columnWidth: '50%',
        cellComponent: (transaction: SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary): JSX.Element => (
            <td key={`transaction_finishedAt_${transaction.transactionId}`} className="d-table-cell">
                {transaction.finishedAt && formatDate(new Date(transaction.finishedAt))}
            </td>
        ),
    },
    {
        label: <Trans i18nKey="invoices.transactionTable.column-sum" />,
        columnId: 'TRANSACTION_SUM',
        columnWidth: '50%',
        cellComponent: (transaction: SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary): JSX.Element => (
            <td key={`transaction_sum_${transaction.transactionId}`} className="d-table-cell">
                {formatCurrency(transaction.amount || 0)}
            </td>
        ),
    },
];
