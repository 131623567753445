import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { ClickableElement, CodeListTypeEnum, CommodityEnum, LoadingIndicator, QueryKeysEnum, useCodeList } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatIBAN } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import Email from '../block-new-customer-email/components/email';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

export const BlockCompletionTranscript: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { content, customerRequestTemplate, businessPartner } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPoints = [...(content.deliveryPointsZP ?? []), ...(content.deliveryPointsEE ?? [])];

    const { register, handleSubmit, errors, trigger, setValue } = useForm<{ email: string; invoiceDeliveryEmail: string }>({
        defaultValues: { email: content.newCustomerEmail, invoiceDeliveryEmail: content.invoiceDelivery?.email },
    });

    const [newCustomerInput, setNewCustomerInput] = useState(false);
    const [invoiceDeliveryInput, setInvoiceDeliveryInput] = useState(false);

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const isContainEE = deliveryPoints.find((dp) => dp.type === CommodityEnum.ELECTRICITY) ? true : false;
    const isContainZP = deliveryPoints.find((dp) => dp.type === CommodityEnum.GAS) ? true : false;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const onSubmit = (data: { email: string; invoiceDeliveryEmail: string }) => {
        if (newCustomerInput) {
            dispatch(CustomerRequestActions.setData({ newCustomerEmail: data.email }));
            setNewCustomerInput(false);
            return;
        }

        if (invoiceDeliveryInput) {
            content.invoiceDelivery &&
                dispatch(CustomerRequestActions.setData({ invoiceDelivery: { ...content.invoiceDelivery, email: data.invoiceDeliveryEmail } }));
            setInvoiceDeliveryInput(false);
            return;
        }

        const requestData: any = {
            completionCustomerEmail: content.newCustomerEmail,
            content: {
                metadata: {
                    businessPartnerId: businessPartner?.id,
                },
                deliveryPointsEe: (content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.map((dp) => {
                    const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                    const addressCountry = countryCodeList?.find((item) => item.code === dp.address?.country);
                    if (meterReading) {
                        return {
                            contractId: dp.contract?.id,
                            deliveryPointEe: {
                                deliveryPointNumber: dp.externalId,
                                address: {
                                    ...dp.address,
                                    postalCode: dp.address?.zipCode,
                                    country: addressCountry,
                                    number: dp.address?.streetNumber,
                                },
                                eic: dp.eic,
                            },
                            meterReadingEe: {
                                //date: new Date(),
                                value: meterReading.value,
                                valueLow: meterReading.valueLow,
                                valueHigh: meterReading.valueHigh,
                                type: meterReading.valueLow ? 'EE_2T' : 'EE_1T',
                            },
                        };
                    }
                    return undefined;
                }),
                deliveryPointsZp: (content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.map((dp) => {
                    const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                    const addressCountry = countryCodeList?.find((item) => item.code === dp.address?.country);
                    if (meterReading) {
                        return {
                            contractId: dp.contract?.id,
                            deliveryPointZp: {
                                deliveryPointNumber: dp.externalId,
                                address: {
                                    ...dp.address,
                                    postalCode: dp.address?.zipCode,
                                    country: addressCountry,
                                    number: dp.address?.streetNumber,
                                },
                                pod: dp.pod,
                            },
                            meterReadingZp: {
                                //date: new Date(),
                                value: meterReading.value,
                                type: 'GAS',
                            },
                        };
                    }
                    return undefined;
                }),
                lastInvoice: {
                    type: 'EMAIL',
                    email: content.invoiceDelivery?.email,
                },
                entityReferences: [
                    ...(content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[]),
                    ...(content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[]),
                ]?.map((dp) => ({
                    contractId: dp.contract?.id,
                    deliveryPointId: dp.id,
                    contractAccountId: dp.contract?.contractAccount?.id,
                })),
                overpaymentSettlement: {
                    bankConnection: content.bankConnection?.iban
                        ? {
                              iban: content.bankConnection?.iban,
                              bicSwift: content.bankConnection?.bicSwift,
                          }
                        : undefined,
                    address: content.address,
                },
                type: customerRequestTemplate?.code,
            },
            customerRequestTemplate: customerRequestTemplate,
        };

        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(requestData)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.completion-transcript.transcription-of-dp')}>
                    {deliveryPoints.length > 0 && (
                        <>
                            <ItemPreviewContent bold>
                                {formatAddress(deliveryPoints[0].address)}
                                <br />
                                {isContainZP && t('enums.CommodityEnum.ZP')}
                                {isContainEE && (isContainZP ? `, ${t('enums.CommodityEnum.EE')}` : t('enums.CommodityEnum.EE'))}
                            </ItemPreviewContent>
                        </>
                    )}
                </ItemPreview>

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.GAS)
                    .map((dp) => {
                        const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                        return (
                            <ItemPreview
                                title={`${t('customer-request.steps.gas-condition.title')} - ${t('delivery-point.contract-number', {
                                    number: dp?.contract?.externalId || '-',
                                })}`}
                                key={dp.id}
                            >
                                <ItemPreviewContent bold>
                                    {meterReading?.value} m<sup>3</sup>
                                </ItemPreviewContent>
                            </ItemPreview>
                        );
                    })}

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.ELECTRICITY)
                    .map((dp) => {
                        const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                        return (
                            <ItemPreview
                                title={`${t('customer-request.steps.electricity-condition.title')} - ${t('delivery-point.contract-number', {
                                    number: dp?.contract?.externalId || '-',
                                })}`}
                                key={dp.id}
                            >
                                <ItemPreviewContent bold>
                                    {meterReading?.value ? (
                                        <>{meterReading.value} kWh</>
                                    ) : (
                                        <>
                                            <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" /> :{' '}
                                            {meterReading?.valueHigh}
                                            {' kwh'} <br />
                                            <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" /> :{' '}
                                            {meterReading?.valueLow}
                                            {' kwh'}
                                        </>
                                    )}
                                </ItemPreviewContent>
                            </ItemPreview>
                        );
                    })}

                <ItemPreview title={t('customer-request.steps.completion-transcript.invoice-send')}>
                    <ItemPreviewContent bold>
                        <span className={invoiceDeliveryInput ? 'd-none' : ''}>{content.invoiceDelivery?.email}</span>
                        {!invoiceDeliveryInput && !newCustomerInput && (
                            <ClickableElement
                                isText
                                onClick={() => {
                                    setInvoiceDeliveryInput(true);
                                    nextTick(() => {
                                        setValue('invoiceDeliveryEmail', content.invoiceDelivery?.email);
                                    });
                                }}
                                className="text-decoration-underline-inverse float-right"
                            >
                                {t('customer-request.steps.completion-transcript.edit-email')}
                            </ClickableElement>
                        )}
                        {invoiceDeliveryInput && (
                            <div className="text-center">
                                <Email
                                    register={register}
                                    errors={errors}
                                    trigger={trigger}
                                    name="invoiceDeliveryEmail"
                                    label="E-mail"
                                    canBeLoggedInUser
                                />
                                <button type="submit" className="btn btn-primary">
                                    <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ml-4"
                                    onClick={() => {
                                        setValue('invoiceDeliveryEmail', content.invoiceDelivery?.email);
                                        setInvoiceDeliveryInput(false);
                                    }}
                                >
                                    <Trans i18nKey="common.cancel">Zrušiť</Trans>
                                </button>
                            </div>
                        )}
                    </ItemPreviewContent>
                </ItemPreview>
                {content.bankConnection && (
                    <ItemPreview title={t('customer-request.steps.completion-transcript.bank-account-overpayment')}>
                        <ItemPreviewContent bold>
                            {formatIBAN(content.bankConnection?.iban)}
                            {content.bankConnection?.bicSwift && (
                                <>
                                    <br />
                                    BIC/SWIFT: {content.bankConnection?.bicSwift}
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                )}
                {content.address && (
                    <ItemPreview title={t('customer-request.steps.completion-transcript.postal-voucher-overpayment')}>
                        <ItemPreviewContent>
                            <p>
                                <b>
                                    {content.address?.name || ''} <br />
                                    {content.address?.street || ''} {content.address?.number || ''}
                                    <br />
                                    {content.address?.city || ''} {content.address?.postalCode || ''}
                                    <br />
                                    {countryCodeList?.find((item) => item.uuid === content.address?.country?.uuid)?.name || ''}
                                </b>
                            </p>
                            {content.address?.floor && (
                                <>
                                    {t('customer-request.steps.address.floor')}
                                    <p>
                                        <b>{content.address?.floor}.</b>
                                    </p>
                                </>
                            )}
                            {content.address?.flatNumber && (
                                <>
                                    {t('customer-request.steps.address.appartments-number')}
                                    <p>
                                        <b>{content.address?.flatNumber}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                )}
            </ListGroup>

            <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-5 mt-4 text-center">
                <p>
                    {t('customer-request.steps.completion-transcript.new-customer-notificate')}

                    {!newCustomerInput && (
                        <>
                            <br />
                            <b>{content.newCustomerEmail}</b>
                        </>
                    )}
                </p>
                <div className="pt-1">
                    {!newCustomerInput && !invoiceDeliveryInput && (
                        <ClickableElement
                            isText
                            onClick={() => {
                                setNewCustomerInput(true);
                                nextTick(() => {
                                    setValue('email', content.newCustomerEmail);
                                });
                            }}
                            className="text-decoration-underline-inverse"
                        >
                            {t('customer-request.steps.completion-transcript.edit-email')}
                        </ClickableElement>
                    )}
                    {newCustomerInput && (
                        <>
                            <Email register={register} errors={errors} trigger={trigger} />
                            <button type="submit" className="btn btn-primary">
                                <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ml-4"
                                onClick={() => {
                                    setValue('email', content.newCustomerEmail);
                                    setNewCustomerInput(false);
                                }}
                            >
                                <Trans i18nKey="common.cancel">Zrušiť</Trans>
                            </button>
                        </>
                    )}
                </div>
            </div>

            <Button size="lg" color="primary" block type="submit" className="mt-4" disabled={newCustomerInput || invoiceDeliveryInput}>
                <Trans i18nKey="customer-request.steps.send-request" />
            </Button>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent
                        onClose={onRequestSentSuccessConfirmed}
                        label={t('customer-request.steps.completion-transcript.request-send-to-new-customer')}
                    />
                </ModalBody>
            </Modal>
        </form>
    );
};
