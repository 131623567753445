import { SkSppNzpBeApiCustomerprofileInvoice } from '@spp/spp-meru-frontend-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useApi } from '../../../hooks/use-api';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import InvoiceDetailView from './detail-view';

const InvoiceDetail = (): ReactElement => {
    const { invoiceId, unitedDeliveryPointId, deliveryPointId } = useParams<{
        unitedDeliveryPointId: string;
        deliveryPointId?: string;
        invoiceId: string;
    }>();
    const api = useApi();
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const { data: invoiceData, isLoading } = useQueryWithError<SkSppNzpBeApiCustomerprofileInvoice | null>(
        // TODO use correct key string
        ['Invoices', invoiceId],
        async () => (invoiceId ? api.invoices.getInvoiceById(invoiceId, { secure: true }).then((res) => res.data) : null),
        {
            onError: (err) => {
                if (err?.error?.code === 404) {
                    addToast(`${t(`invoices.detail.invoice-data-unavailable`)}`, { appearance: 'error' });
                    return true;
                }
                return false;
            },
        },
    );

    return (
        <InvoiceDetailView
            invoice={invoiceData}
            invoiceLoading={isLoading}
            unitedDeliveryPointId={unitedDeliveryPointId}
            deliveryPointId={deliveryPointId}
            commodity={invoiceData?.deliveryPoints && invoiceData.deliveryPoints[0]?.type}
        />
    );
};

export default InvoiceDetail;
