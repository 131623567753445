import { TFunction } from 'i18next';
import moment from 'moment';

export const MAX_DATE_SAP = '9999-12-31';

export const formatDateToPreview = (date: Date | string | undefined): string => {
    if (!date) return '';
    return moment(date).format('DD.MM.YYYY');
};

export function formatIsoDateToDuration(isoDateTime: string | undefined, t: TFunction): string {
    const createdAt = isoDateTime ? moment(isoDateTime) : undefined;
    if (!createdAt) {
        return '';
    } else if (moment().isSame(createdAt, 'day')) {
        // the same day as today
        return t('common.today') + ' ' + createdAt.format('HH:mm');
    } else if (
        moment()
            .add(-1, 'day')
            .isSame(createdAt, 'day')
    ) {
        // the same day as yesterday
        return t('common.yesterday') + ' ' + createdAt.format('HH:mm');
    } else if (
        moment()
            .add(-1, 'month')
            .isSameOrBefore(createdAt, 'day')
    ) {
        // not older than 30 days
        return createdAt.format('D.M.yyyy');
    } else {
        // older than 30 days
        const monthDiff = moment().diff(createdAt, 'months');
        if (monthDiff <= 1) {
            return createdAt.format('D.M.yyyy') + ' (' + t('common.before') + ' 1 ' + t('common.before-month-one') + ')';
        } else {
            return createdAt.format('D.M.yyyy') + ' (' + t('common.before') + ' ' + monthDiff + ' ' + t('common.before-month-plural') + ')';
        }
    }
}

export function dateFromFormatToIso(value?: string, format = 'DD.MM.YYYY HH:mm:ss', includeDay?: boolean): string {
    if (includeDay) {
        return moment(value, format)
            .utcOffset(0, true)
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toISOString();
    } else {
        return moment(value, format)
            .utcOffset(0, true)
            .toISOString();
    }
}

export function dateFromIsoToFormat(value?: string | Date, format = 'DD.MM.YYYY HH:mm:ss'): string {
    return moment(value)
        .utcOffset(0, true)
        .format(format);
}

export function parseFormattedTimeString(time: string): { hours: number; minutes: number; seconds: number } {
    const timeArray = time.split(':');
    const hours = timeArray[0] ? Number(timeArray[0]) : 0;
    const minutes = timeArray[1] ? Number(timeArray[1]) : 0;
    const seconds = timeArray[2] ? Number(timeArray[2]) : 0;
    return { hours, minutes, seconds };
}
