import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';
import { combineRefs } from '../../utils/utils';

type CheckboxProps = {
    name: string;
    label: ReactNode;
    id: string;
    checked?: boolean;
    errors?: { [key: string]: any };
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
    className?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    autoFocus?: boolean;
    defaultChecked?: boolean;
    labelClassName?: string;
    tooltipId?: string;
};

const Checkbox = forwardRef(
    ({ name, label, id, errors, checked, onChange, className, disabled, autoFocus, onClick, labelClassName, tooltipId }: CheckboxProps, ref: any) => {
        if (ref && !!checked) {
            throw new Error("This is not supported by Internet Explorer 11. Don't use Checkbox with 'checked' when 'ref' is provided!!!");
        }

        const localRef = useAutofocusRef<HTMLButtonElement>(autoFocus);
        const [t] = useTranslation();

        return (
            <div className={`custom-control custom-checkbox ${className ?? 'mb-3'}`} id={tooltipId}>
                <input
                    onChange={onChange}
                    ref={combineRefs<HTMLInputElement>([ref, localRef])}
                    checked={checked}
                    name={name}
                    type="checkbox"
                    className="custom-control-input"
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                />
                <label className={`custom-control-label ${labelClassName ?? ''}`} htmlFor={id}>
                    {label}
                </label>
                {errors && Object.keys(errors).length > 0 && (
                    <div style={{ color: 'red' }}>
                        {t(`common.input-rules.${errors[name]?.type}`) === `common.input-rules.${errors[name]?.type}` ? (
                            <ErrorMessage errors={errors} name={name} />
                        ) : (
                            <>{errors[name] && t(`common.input-rules.${errors[name]?.type}`)} </>
                        )}
                    </div>
                )}
            </div>
        );
    },
);

export default Checkbox;
