import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { ClickableElement, LoadingIndicator } from '../../../../components/common';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { downloadHandler, useApi } from '../../../../main';

interface IDownloadInvoiceAsPDFButtonProps {
    invoiceId: string | undefined;
    disabled?: boolean;
}

export const DownloadInvoiceAsPDFButton: React.FC<IDownloadInvoiceAsPDFButtonProps> = ({ invoiceId, disabled }) => {
    const { t } = useTranslation();
    const api = useApi();
    const { addToast } = useToasts();

    const [mutateFetchFile, { isLoading: isLoadingFileFetching }] = useMutationWithError(
        async () => invoiceId != null && api.invoices.getInvoiceFile(invoiceId, { secure: true }),
        {
            onSuccess: (response) => {
                if (!!response) {
                    response.blob().then((blob) => {
                        downloadHandler(blob, `invoice-${new Date().toISOString()}.pdf`, blob.type);
                    });
                }
            },
            onErrorWithGlobalErrorHandling: () => {
                addToast(t('invoices.detail.invoice-pdf-download-failed'), {
                    appearance: 'error',
                });
                return true;
            },
        },
    );

    return (
        <div className="mr-lg-2 mb-3 mb-lg-0 position-relative" hidden={disabled}>
            {isLoadingFileFetching && <LoadingIndicator size="medium" />}
            <FontAwesomeIcon icon={faDownload} />
            &nbsp;
            <ClickableElement
                className="text-decoration-underline-inverse"
                isText
                onClick={() => mutateFetchFile()}
                disabled={isLoadingFileFetching || disabled}
            >
                <span className="mr-2">
                    <Trans i18nKey="invoices.detail.pdf-show" />
                </span>
            </ClickableElement>
        </div>
    );
};
