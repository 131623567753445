import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { ListGroupItem } from 'reactstrap';
import { CommodityEnum } from '../../../../main';

interface IInactiveEinvoiceDpListitemProps {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPointSummary;
    showContractId?: boolean;
}

export const InactiveEinvoiceDpListitem: React.FC<IInactiveEinvoiceDpListitemProps> = ({ deliveryPoint, showContractId = false }) => {
    const commodityLabel =
        deliveryPoint.type === CommodityEnum.GAS ? <Trans i18nKey="common.commodity.gas" /> : <Trans i18nKey="common.commodity.electricity" />;

    return (
        <>
            <ListGroupItem>
                <div className="d-flex justify-content-between py-2">
                    <div className="d-block my-auto">
                        <strong>{commodityLabel}</strong> {showContractId && <>({deliveryPoint.contract?.externalId})</>}
                    </div>
                    <div className="text-right">
                        <p className="text-danger mb-0 d-block">
                            <Trans i18nKey="settings.e-invoices.einvoice-is-inactive" />
                        </p>
                    </div>
                </div>
            </ListGroupItem>
        </>
    );
};
