import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common/dist/api';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { ISort, SortDirection } from '../../../components/common';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useKeyValueStore } from '../../../hooks/use-key-value';
import ListDeliveryPointsTableFilter, { DeliveryPointsTableFilterData } from './list-delivery-points-table-filter';
import ListDeliveryPointsTableHeader from './list-delivery-points-table-header';
import ListDeliveryPointsTableRow from './list-delivery-points-table-row';

type Props = {
    deliveryPoints?: UnitedDeliveryPointSummary[];
    isFetching: boolean;
    onOpen: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
    onLoading: () => void;
    sort?: ISort;
    handleSortChange: (field: keyof DeliveryPointsTableFilterData, dir: SortDirection | undefined) => void;
    filterBy: (filterData: DeliveryPointsTableFilterData) => void;
    tableColumnFilter: DeliveryPointsTableFilterData;
};

const ListDeliveryPointsTable = ({
    deliveryPoints,
    isFetching,
    onOpen,
    onLoading,
    sort,
    handleSortChange,
    filterBy,
    tableColumnFilter,
}: Props): ReactElement => {
    const [openSharedModal, setOpenSharedModal] = useKeyValueStore<string>(`DPsOpenSharedModal`, '');
    const sharingBy = deliveryPoints?.find((deliveryPoint) => deliveryPoint.sharing?.by) ? true : false;
    return (
        <>
            <div className="row mb-4">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table table-light table-selectable">
                            <thead className="thead-dark">
                                <ListDeliveryPointsTableHeader<DeliveryPointsTableFilterData> sort={sort} onSortChange={handleSortChange} />
                                <ListDeliveryPointsTableFilter filterBy={filterBy} filter={tableColumnFilter} />
                            </thead>
                            {isFetching ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={5}>
                                            <LoadingIndicator fullscreen />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {deliveryPoints?.length === 0 ? (
                                        <tr>
                                            <td className="text-center" colSpan={5}>
                                                {<Trans i18nKey="common.tables.empty-table" />}
                                            </td>
                                        </tr>
                                    ) : (
                                        deliveryPoints?.map((deliveryPoint, index) => (
                                            <ListDeliveryPointsTableRow
                                                key={`table-row-${index}`}
                                                sharingBy={sharingBy}
                                                deliveryPointSummary={deliveryPoint}
                                                onOpen={onOpen}
                                                onLoading={onLoading}
                                                openSharedModal={openSharedModal}
                                                setOpenSharedModal={setOpenSharedModal}
                                            />
                                        ))
                                    )}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListDeliveryPointsTable;
