import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

type CustomCheckboxProps = {
    name: string;
    label: string | JSX.Element;
    labelSecondary?: string | JSX.Element;
    id: string;
    checked?: boolean;
    errors?: { [key: string]: any };
    onChange?: (e: React.FormEvent<HTMLInputElement>) => any;
    className?: string;
    disabled?: boolean;
};

const CustomCheckbox = forwardRef(
    ({ name, label, labelSecondary, id, errors, checked, onChange, className, disabled }: CustomCheckboxProps, ref: any) => {
        if (ref && !!checked) {
            throw new Error("This is not supported by Internet Explorer 11. Don't use CustomCheckbox with 'checked' when 'ref' is provided!!!");
        }

        return (
            <div className={`custom-control custom-switch ${className || ''}`}>
                <input
                    onChange={onChange}
                    ref={ref}
                    checked={checked}
                    name={name}
                    type="checkbox"
                    className="custom-control-input"
                    id={id}
                    disabled={disabled}
                />
                <label className={classNames('custom-control-label font-weight-bold cursor-pointer')} htmlFor={id}>
                    {label}
                    <br />
                    <small className="text-secondary font-weight-normal">{labelSecondary}</small>
                </label>
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name={name} />
                    </div>
                )}
            </div>
        );
    },
);

export default CustomCheckbox;
