import { t } from 'i18next';
import React from 'react';

type Props = {
    address?: string;
    phone?: string;
    email?: string;
    addressFieldName?: string;
};

const BlockEmailPhoneAddress: React.FC<Props> = ({ address, phone, email, addressFieldName }) => {
    return (
        <div>
            <div>
                {t('common.email')}: <span>{email}</span>
            </div>
            <div>
                {t('common.phone-number')}: <span>{phone}</span>
            </div>
            <div>
                {addressFieldName ? addressFieldName : t('customer-request.steps.new-data.new-correspondence-address')}: <span>{address}</span>
            </div>
        </div>
    );
};

export default BlockEmailPhoneAddress;
