import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import DatePickerIntervalContainer from '../../../components/common/datePicker/datepicker-interval-container';
import {
    AutoComplete,
    BaseSelect,
    clearObjectProperties,
    ClickableElement,
    CommodityEnum,
    DatePicker,
    formatAddress,
    formatBusinessPartnerName,
    InvoiceGroup,
    isObjectEmpty,
    useFormRules,
} from '../../../main';
import { IInvoicesTableFilter } from './invoices-filter-modal';

interface IInvoicesFilterFormProps {
    currentFilter: Partial<IInvoicesTableFilter>;
    onSubmit: (newFilter: Partial<IInvoicesTableFilter>) => void;
    deliveryPointId?: string;
    unitedDeliveryPointId?: string;
    customer?: IInvoiceBP;
    commodity: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    unitedDeliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null;
    hasNonCommodity?: boolean;
    availableTypeGroups?: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary['availableTypeGroups'];
    availableCommodities?: Exclude<SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'], undefined>[];
}

interface IOptions {
    name: string;
    businessPartnerId: string;
    value: string;
}

interface IDeliveryPointsSelected {
    deliveryPointId: string;
    businessPartnerId: string;
}

export interface IInvoiceBP {
    name: string;
    value: string;
}

const functionOptionsDP = (
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[],
    unitedDeliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null,
    businessPartnerId?: string,
) => {
    let options: IOptions[] = [];
    if (deliveryPoints) {
        // options = deliveryPoints.filter(deliveryPoint => businessPartnerId ? deliveryPoint.b ) .map((deliveryPoint) => {
        options = deliveryPoints.map((deliveryPoint) => {
            return { name: formatAddress(deliveryPoint.address), value: deliveryPoint.id || '', businessPartnerId: '' };
        });
    }
    if (unitedDeliveryPoints) {
        options = unitedDeliveryPoints
            .filter((deliveryPoint) => (businessPartnerId ? deliveryPoint.businessPartner?.id === businessPartnerId : true))
            .map((deliveryPoint) => {
                return {
                    name: formatAddress(deliveryPoint.address),
                    value: deliveryPoint.id || '',
                    businessPartnerId: deliveryPoint.businessPartner?.id || '',
                };
            });
    }
    return options;
};

const InvoicesFilterForm: React.FC<IInvoicesFilterFormProps> = ({
    currentFilter,
    onSubmit,
    deliveryPointId,
    unitedDeliveryPointId,
    commodity,
    deliveryPoints,
    unitedDeliveryPoints,
    customer,
    hasNonCommodity,
    availableTypeGroups,
    availableCommodities,
}) => {
    const defaultValues = { ...currentFilter };
    if (unitedDeliveryPointId) {
        defaultValues.deliveryPointIds = [unitedDeliveryPointId];
    }
    if (commodity) {
        defaultValues.productType = commodity;
    }
    if (customer) {
        defaultValues.businessPartnerId = customer.value;
    }

    const { register, handleSubmit, setValue, getValues, errors, trigger, watch } = useForm({ defaultValues: defaultValues });
    const { dateRules } = useFormRules();

    const canSelectDeliveryPoint = deliveryPointId == null && unitedDeliveryPointId == null;

    let deliveryPointsSelectedHelp: IDeliveryPointsSelected[] = [];
    if (deliveryPointId) {
        deliveryPointsSelectedHelp.push({ deliveryPointId: deliveryPointId, businessPartnerId: customer?.value || '' });
    }
    if (unitedDeliveryPointId) {
        deliveryPointsSelectedHelp.push({ deliveryPointId: unitedDeliveryPointId, businessPartnerId: customer?.value || '' });
    }
    if (!deliveryPointId && !unitedDeliveryPointId) {
        if (unitedDeliveryPoints) {
            deliveryPointsSelectedHelp =
                defaultValues.unitedDeliveryPointIds?.map((unitedDeliveryPointId) => {
                    const businessPartnerId = unitedDeliveryPoints.find((DP) => DP.id === unitedDeliveryPointId)?.businessPartner?.id;
                    return { deliveryPointId: unitedDeliveryPointId, businessPartnerId: businessPartnerId || '' };
                }) || [];
        } else {
            deliveryPointsSelectedHelp =
                defaultValues.deliveryPointIds?.map((deliveryPointId) => {
                    return { deliveryPointId: deliveryPointId, businessPartnerId: customer?.value || '' };
                }) || [];
        }
    }

    const [optionsDP, setOptionsDP] = useState<IOptions[]>(functionOptionsDP(deliveryPoints, unitedDeliveryPoints, getValues('businessPartnerId')));
    const [deliveryPointsSelected, setDeliveryPointsSelected] = useState<IDeliveryPointsSelected[] | undefined>(deliveryPointsSelectedHelp);

    const onFormSubmit = handleSubmit((data: IInvoicesTableFilter) => {
        const issueAt = isObjectEmpty(clearObjectProperties(data.issueAt ?? {})) ? undefined : data.issueAt;
        const deliveryPointIds =
            deliveryPoints && canSelectDeliveryPoint && deliveryPointsSelected != null && deliveryPointsSelected.length !== 0
                ? deliveryPointsSelected.map((DP) => DP.deliveryPointId)
                : undefined;
        const unitedDeliveryPointIds =
            unitedDeliveryPoints && canSelectDeliveryPoint && deliveryPointsSelected != null && deliveryPointsSelected.length !== 0
                ? deliveryPointsSelected.map((DP) => DP.deliveryPointId)
                : undefined;
        const businessPartnerId = customer ? undefined : data.businessPartnerId;
        const filter = {
            ...data,
            issueAt,
            unitedDeliveryPointIds: unitedDeliveryPointIds,
            deliveryPointIds: deliveryPointIds,
            businessPartnerId: businessPartnerId,
        };
        onSubmit(clearObjectProperties(filter));
    });

    const resetFilter = () => {
        onSubmit({});
    };

    const [t] = useTranslation();

    useEffect(() => {
        trigger('issueAt.to');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('issueAt.from')]);

    const invoiceBPs = useMemo<IInvoiceBP[]>(() => {
        const result: IInvoiceBP[] = [];
        unitedDeliveryPoints?.forEach((deliveryPoint) => {
            if (deliveryPoint.businessPartner?.id) {
                if (!result.find((businessPartner) => businessPartner.value === deliveryPoint.businessPartner?.id)) {
                    result.push({
                        name: formatBusinessPartnerName(deliveryPoint.businessPartner),
                        value: deliveryPoint.businessPartner.id,
                    });
                }
            }
        });
        if (customer && unitedDeliveryPoints?.find((item) => item.businessPartner?.id === customer.value) === undefined) {
            result.push(customer);
        }
        return result;
    }, [unitedDeliveryPoints, customer]);

    const deliveryPointSelected = (options: IOptions[]) => {
        setDeliveryPointsSelected(
            options.map((option) => {
                return { deliveryPointId: option.value, businessPartnerId: option.businessPartnerId };
            }),
        );
    };

    const businessPartnerId = getValues('businessPartnerId');
    useEffect(() => {
        setOptionsDP(functionOptionsDP(deliveryPoints, unitedDeliveryPoints, businessPartnerId));
    }, [businessPartnerId, deliveryPoints, unitedDeliveryPoints]);

    useEffect(() => {
        const businessPartnerId = getValues('businessPartnerId');
        businessPartnerId &&
            setDeliveryPointsSelected(deliveryPointsSelected?.filter((DPSelected) => DPSelected.businessPartnerId === businessPartnerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('businessPartnerId')]);

    const businessPartnerOptions = useMemo(() => {
        const availableBusinessPartners = invoiceBPs.filter((businessPartner) =>
            (deliveryPointsSelected?.length || 0) > 0
                ? deliveryPointsSelected?.find((DPselected) => DPselected.businessPartnerId === businessPartner.value)
                : true,
        );
        const sorted = availableBusinessPartners.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    }, [invoiceBPs, deliveryPointsSelected]);

    const selectableDeliveryPointOptions = useMemo(() => {
        return optionsDP.filter((option) => {
            const businessPartnerId = getValues('businessPartnerId');
            if (businessPartnerId) {
                return option.businessPartnerId === getValues('businessPartnerId');
            }
            return true;
        });
    }, [optionsDP, getValues]);

    const allCommodities = useMemo(() => {
        const commodities: { name: string; value: string }[] = availableCommodities
            ? availableCommodities.map((item) => ({ value: item, name: t(`enums.CommodityEnum.${item}`) }))
            : Object.entries(CommodityEnum).map((item) => ({ value: item[1], name: t(`enums.CommodityEnum.${item[1]}`) }));
        if (hasNonCommodity) {
            commodities.push({ value: 'N', name: t('enums.CommodityEnum.N') });
        }
        if (commodities.length > 1) {
            commodities.unshift({ value: '', name: t('invoices.filter.all-product') });
        }
        return commodities;
    }, [availableCommodities, hasNonCommodity, t]);

    return (
        <form onSubmit={onFormSubmit} noValidate>
            <BaseSelect
                ref={register({})}
                name="businessPartnerId"
                label={t('invoices.filter.customer')}
                disabled={customer}
                className="form-control-filter"
            >
                <option key="invoicesFilterFormAll" value="">
                    {t('invoices.filter.all-invoice-customers')}
                </option>
                {businessPartnerOptions.map((businessPartner) => (
                    <option key={'invoicesFilterForm-' + businessPartner.value} value={businessPartner.value}>
                        {businessPartner.name}
                    </option>
                ))}
            </BaseSelect>
            <div className="form-group">
                <AutoComplete<IOptions>
                    options={selectableDeliveryPointOptions}
                    placeholder={t('invoices.filter.delivery-point')}
                    onSelect={deliveryPointSelected}
                    multiple
                    defaultSelected={optionsDP.filter((op) => deliveryPointsSelected?.find((DPselected) => DPselected.deliveryPointId === op.value))}
                    disabled={!canSelectDeliveryPoint}
                    inFilter
                />
            </div>
            <BaseSelect ref={register} disabled={commodity} name="productType" label={t('invoices.filter.product')} className="form-control-filter">
                {allCommodities.map((item) => (
                    <option key={'commodity' + item.value} value={item.value}>
                        {item.name}
                    </option>
                ))}
            </BaseSelect>
            <BaseSelect
                ref={register({})}
                name="invoiceGroup"
                label={t('invoices.filter.group')}
                autoFocus={commodity}
                className="form-control-filter"
            >
                <option value="">{t('invoices.filter.all-invoice-types')}</option>
                {Object.entries(InvoiceGroup)
                    .filter((group) => (Array.isArray(availableTypeGroups) ? availableTypeGroups.includes(group[1]) : true))
                    .map((item, index) => (
                        <option key={'invopiceGroupOption_' + index} value={item[0]}>
                            {t(`enums.InvoiceGroup.${item[1]}`)}
                        </option>
                    ))}
            </BaseSelect>
            {/* <BaseSelect ref={register({})} name="state" label={t('invoices.filter.state')} className="form-control-filter">
                    <option value="">{t('invoices.filter.all-invoice-state')}</option>
                    {Object.entries(InvoiceState).map((item, index) => (
                        <option key={index} value={item[0]}>
                            {t(`enums.InvoiceState.${item[1]}`)}
                        </option>
                    ))}
                </BaseSelect> */}
            <p className="font-weight-bold">
                <Trans i18nKey="invoices.filter.time-period" />
            </p>
            <DatePickerIntervalContainer>
                <DatePicker
                    label={t('invoices.filter.time-from')}
                    errors={errors}
                    register={register({ ...dateRules().isValidDate })}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    position="L"
                    name="issueAt.from"
                    showInputWhileError={false}
                    border={false}
                />
                <DatePicker
                    label={t('invoices.filter.time-to')}
                    register={register({
                        ...dateRules('NOT_OLDER_THAN', getValues('issueAt.from') ? getValues('issueAt.from') : undefined).isValidDate,
                    })}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    position="R"
                    name="issueAt.to"
                    includeDay
                    showInputWhileError={false}
                    border={false}
                />
            </DatePickerIntervalContainer>
            <div className="text-center my-5">
                <ClickableElement onClick={resetFilter}>
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" /> <Trans i18nKey="common.tables.remove-filter" />
                </ClickableElement>
            </div>
            <Button type="submit" color="primary" size="lg" block>
                <Trans i18nKey="common.tables.apply-filter" />
            </Button>
        </form>
    );
};

export default InvoicesFilterForm;
