import React, { useEffect } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Modal, ModalBody } from 'reactstrap';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import PasswordInput from '../../../../../components/common/password-input';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IApiResponse, IResponseError, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { handlePasswordError } from '../../../../../utils/error-handling';
import { formOptions } from '../../../../../utils/utils';
import { LoginDetailsModalTypeEnum } from '../../login-details-settings';

interface IPasswordChangeFormFields {
    currentPassword: string;
    newPassword: string;
}

interface ILoginPasswordChangeModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    type?: LoginDetailsModalTypeEnum;
    mutateMe: (password?: string) => void;
    email?: string;
}

const LoginPasswordChangeModal: React.FC<ILoginPasswordChangeModalProps> = ({ isOpen, onCloseModal, type, mutateMe, email }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const { addToast } = useToasts();
    const [t] = useTranslation();
    const api = useApi();

    const { register, handleSubmit, errors, setError, trigger, reset } = useForm<IPasswordChangeFormFields>(formOptions());
    const { formRules, passwordRules, requiredTrimed } = useFormRules();

    useEffect(() => {
        reset();
    }, [isOpen, reset]);

    const handleResponseErrors = (error: IResponseError) => {
        if (error.code === 1003) {
            setError('currentPassword', {
                type: 'incorrect-password',
                message: t('common.input-rules.incorrect-password'),
            });
            return true;
        } else if (error.code === 2001) {
            const addError = (obj: ErrorOption) => setError('newPassword', obj);
            handlePasswordError(t, addError, error);
            return true;
        }
        return false;
    };

    const [mutatePasswordChange, { isLoading }] = useMutationWithError(
        async (passwordData: { currentPassword: string; newPassword: string }) =>
            loggedInCustomer?.id != null ? api.customers.changePassword(loggedInCustomer.id, passwordData, { secure: true }) : null,
        {
            onSuccess: (_, passwordData) => {
                addToast(t('settings.login-details.password-has-been-changed-email-activation'), {
                    appearance: 'success',
                });
                mutateMe(passwordData.newPassword);
                reset();
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error == null) return false;
                return handleResponseErrors(error);
            },
        },
    );

    const onFormSubmit = handleSubmit((fields) => {
        mutatePasswordChange(fields);
    });

    return (
        <>
            {/* padding-bottom-200 is added here due to required space for showing password criteria */}
            <Modal isOpen={isOpen} className="padding-bottom-200 pt-2" centered>
                <ModalBody>
                    {isLoading && <LoadingIndicator />}
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        {type === LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD ? (
                            <Trans i18nKey="settings.login-details.activation-email-login" />
                        ) : (
                            <Trans i18nKey="settings.change-password" />
                        )}
                    </h3>
                    {type === LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD && (
                        <>
                            <div className="mb-4">
                                <span className="text-secondary">{t('settings.login-details.login-email')}</span>
                                <br />
                                <b className="text-black">{email}</b>
                            </div>
                            <p className="mb-2 text-black">
                                <b>{t('settings.login-details.create-login-password')}:</b>
                            </p>
                        </>
                    )}
                    <form onSubmit={onFormSubmit} noValidate>
                        {type !== LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD && (
                            <PasswordInput
                                ref={register({ ...requiredTrimed })}
                                id="currentPassword"
                                errors={errors}
                                name={'currentPassword'}
                                label={t('settings.login-details.current-password')}
                                doNotValidate
                                trigger={trigger}
                                autoFocus
                            />
                        )}

                        <PasswordInput
                            ref={register({ ...passwordRules.isValidPassword, ...formRules.minLengthX(8) })}
                            id="newPassword"
                            errors={errors}
                            name={'newPassword'}
                            label={t('settings.login-details.new-password')}
                            trigger={trigger}
                        />

                        <Button type="submit" color="primary" size="lg" className="my-2">
                            {type === LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD ? (
                                <Trans i18nKey="settings.login-details.activate" />
                            ) : (
                                <Trans i18nKey="settings.login-details.accept-changes" />
                            )}
                        </Button>
                    </form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default LoginPasswordChangeModal;
