import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
} from '@spp/spp-meru-frontend-common';
import { IUdpToCustomerRequestMapping } from './use-fetch-multiple-customer-requests';

export const isEInvoiceActiveForDP = (deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPointSummary): boolean => {
    return deliveryPoint.einvoice?.email != null && deliveryPoint.einvoice.activated === true;
};

export const isEInvoiceActive = (unitedDeliveryPoint: UnitedDeliveryPointSummary): boolean => {
    return (
        unitedDeliveryPoint.deliveryPoints != null &&
        unitedDeliveryPoint.deliveryPoints.filter((dp) => dp.status === 'ACTIVE').every((dp) => isEInvoiceActiveForDP(dp))
    );
};

export const getActiveRequestsForUdp = (
    udpId: string | undefined,
    customerRequests: IUdpToCustomerRequestMapping[] | null | undefined,
): SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | undefined => {
    if (udpId == null || customerRequests == null) return undefined;
    const requestsForUDP = customerRequests.find((req) => req.udpId === udpId)?.data;
    return requestsForUDP;
};

export const hasActiveRequest = (udpId: string | undefined, customerRequests: IUdpToCustomerRequestMapping[] | null | undefined): boolean => {
    const activeRequests = getActiveRequestsForUdp(udpId, customerRequests);
    return activeRequests != null && activeRequests.length > 0;
};

export const getEInvoiceEmailsForUDP = (udpSummary: UnitedDeliveryPointSummary): string[] => {
    const emails: string[] = [];
    udpSummary.deliveryPoints?.forEach((dp) => {
        if (dp.einvoice?.email && !emails.includes(dp.einvoice.email)) {
            emails.push(dp.einvoice?.email);
        }
    });
    return emails;
};

export const getEInvoiceEmailsInUDPSummaries = (udpSummaries: UnitedDeliveryPointSummary[]): string[] => {
    let emails: string[] = [];
    udpSummaries.forEach((udp) => {
        const udpEmails = getEInvoiceEmailsForUDP(udp);
        emails = emails.concat(udpEmails.filter((email) => !emails.includes(email)));
    });
    return emails;
};

export const getEInvoiceEmailsInUDPs = (unitedDeliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint[]): string[] => {
    const emails: string[] = [];
    unitedDeliveryPoints.forEach((udp) =>
        udp.deliveryPoints?.forEach((dp) => {
            if (dp.einvoice?.email && !emails.includes(dp.einvoice.email)) {
                emails.push(dp.einvoice?.email);
            }
        }),
    );
    return emails;
};
