import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common';
import BaseSwitch from '../../../../../components/common/base-switch';
import DatePicker from '../../../../../components/common/datePicker/datePicker';
import DatePickerIntervalContainer from '../../../../../components/common/datePicker/datepicker-interval-container';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { ISwitchButton } from '../../../../../main';
import { ConsumptionValueUnits, DateScaleConsumption } from '../../../../../models/enums';
import { IConsumptionAdditionalFilter, IConsumptionFilter } from '../consumption';

interface IEmployeeActivityLogsFilterFormProps {
    currentFilter: Partial<IConsumptionFilter>;
    currentAdditionalFilter: Partial<IConsumptionAdditionalFilter>;
    onSubmit: (newFilter: Partial<IConsumptionFilter>, newAdditionalFilter: Partial<IConsumptionAdditionalFilter>) => void;
    selectedCommodity?: 'ZP' | 'EE';
    onRemoveFilter: () => void;
}

const ConsumptionFilterForm: React.FC<IEmployeeActivityLogsFilterFormProps> = ({
    currentFilter,
    currentAdditionalFilter,
    onSubmit,
    selectedCommodity,
    onRemoveFilter,
}) => {
    // const [filter, setFilter] = useState<Partial<IConsumptionFilter>>(currentFilter);
    const [additionalFilter, setAdditionalFilter] = useState<Partial<IConsumptionAdditionalFilter>>(currentAdditionalFilter);

    const { register, handleSubmit, getValues, setValue, errors, trigger, watch } = useForm<{
        consumptionTimestampFrom: string;
        consumptionTimestampTo: string;
    }>({
        defaultValues: currentFilter,
    });

    const onFormSubmit = handleSubmit((data) => {
        const filter = { ...data };
        onSubmit(filter, additionalFilter);
    });

    const [t] = useTranslation();
    const { dateRules } = useFormRules();

    useEffect(() => {
        trigger('consumptionTimestampTo');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('consumptionTimestampFrom')]);

    const dataButtons: ISwitchButton[] = [
        { id: ConsumptionValueUnits.KWH, title: t('delivery-point.detail.consumption.metering-units.kwh'), className: 'text-transform-none' },
        {
            id: ConsumptionValueUnits.CUBIC_METRE,
            title: (
                <div>
                    {t('delivery-point.detail.consumption.metering-units.m3')}
                    <sup>3</sup>
                </div>
            ),
            className: 'text-transform-none',
            disabled: selectedCommodity === 'EE',
        },
    ];

    return (
        <>
            <form>
                <h5 className="forms-headline mb-3">
                    <Trans i18nKey="delivery-point.detail.consumption.timestamp" />
                </h5>
                <DatePickerIntervalContainer>
                    <DatePicker
                        label={t('delivery-point.detail.consumption.time-from')}
                        register={register(dateRules().isValidDate)}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        errors={errors}
                        name="consumptionTimestampFrom"
                        position="L"
                        showInputWhileError={false}
                        border={false}
                    />
                    <DatePicker
                        label={t('delivery-point.detail.consumption.time-to')}
                        register={register({
                            ...dateRules('NOT_OLDER_THAN', getValues('consumptionTimestampFrom') ? getValues('consumptionTimestampFrom') : undefined)
                                .isValidDate,
                        })}
                        getValues={getValues}
                        setValue={setValue}
                        position="R"
                        trigger={trigger}
                        errors={errors}
                        name="consumptionTimestampTo"
                        includeDay
                        showInputWhileError={false}
                        border={false}
                    />
                </DatePickerIntervalContainer>
                {selectedCommodity === 'ZP' && (
                    <Row>
                        <Col className="col-12">
                            <h5 className="forms-headline mb-3">
                                <Trans i18nKey="delivery-point.detail.consumption.units" />
                            </h5>
                        </Col>
                        <Col className="col-12">
                            <BaseSwitch
                                selectedValue={additionalFilter.consumptionUnits ? additionalFilter.consumptionUnits : DateScaleConsumption.ALL}
                                dataButtons={dataButtons.slice(0, selectedCommodity === 'ZP' ? 2 : 1)}
                                onValueSelect={(value) => {
                                    setAdditionalFilter((prev) => ({ ...prev, consumptionUnits: value as ConsumptionValueUnits }));
                                }}
                                className="mb-3"
                            />
                        </Col>
                    </Row>
                )}
                {selectedCommodity === 'ZP' && (
                    <Row>
                        <Col tag={'p'} className="col-12">
                            <Trans i18nKey="delivery-point.detail.consumption.approximated-values" />
                        </Col>
                        <Col tag={'p'} className="col-12">
                            <Trans i18nKey="delivery-point.detail.consumption.approximated-info" />
                            <sup>3</sup>.
                        </Col>
                    </Row>
                )}

                <ClickableElement className="text-decoration-none d-block text-center my-5" onClick={onRemoveFilter} isText>
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" /> <Trans i18nKey="common.tables.remove-filter" />
                </ClickableElement>

                <Button type="button" color="primary" size="lg" block onClick={onFormSubmit}>
                    <Trans i18nKey="common.tables.apply-filter" />
                </Button>
            </form>
        </>
    );
};

export default ConsumptionFilterForm;
