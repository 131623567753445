import { SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import InvoicesTransactions from './invoices-transactions';

interface IInvoicesTableFilterProps {
    isOpen: boolean;
    onCloseModal: () => void;
    transactionSummary: SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary[];
}

const TransactionModal: React.FC<IInvoicesTableFilterProps> = ({ isOpen, onCloseModal, transactionSummary }) => {
    return (
        <Modal isOpen={isOpen} centered>
            <ModalBody>
                <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <div>
                    <InvoicesTransactions transactionSummary={transactionSummary} />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default TransactionModal;
