import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import { UserActions } from '../../../../../actions/user';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import AddPhoneNumber from '../../../../../components/common/phone-confirm/add-phone-number';
import VerifyPhoneNumber from '../../../../../components/common/phone-confirm/verify-phone-number';
import { IRootState } from '../../../../../reducers';

interface ITelephoneNumberChangeModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    password?: string;
}

enum TelephoneChangeStepsEnum {
    INPUT_TELEPHONE_NUMBER,
    INPUT_SMS_CODE,
}

const TelephoneNumberChangeModal: React.FC<ITelephoneNumberChangeModalProps> = ({ isOpen, onCloseModal, password }) => {
    const [formStep, setFormStep] = useState<TelephoneChangeStepsEnum>(TelephoneChangeStepsEnum.INPUT_TELEPHONE_NUMBER);
    const [newTelephoneNumber, setNewTelephoneNumber] = useState<string>('');
    const [challengeCode, setChallengeCode] = useState<string>('');
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const { addToast } = useToasts();
    const [t] = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setFormStep(TelephoneChangeStepsEnum.INPUT_TELEPHONE_NUMBER);
            setNewTelephoneNumber('');
        }
    }, [isOpen]);

    const onTelNumberSubmit = (phoneNumber: string, challengeCode: string) => {
        setChallengeCode(challengeCode);
        setNewTelephoneNumber(phoneNumber);
        setFormStep(TelephoneChangeStepsEnum.INPUT_SMS_CODE);
    };

    const dispatch = useDispatch();
    const onSmsCodeSubmit = () => {
        dispatch(UserActions.setTelephoneNumber(newTelephoneNumber));
        addToast(t('settings.login-details.telephone-number-changed'), {
            appearance: 'success',
        });
        onCloseModal();
    };

    return (
        <>
            <Modal
                isOpen={
                    isOpen && (formStep === TelephoneChangeStepsEnum.INPUT_TELEPHONE_NUMBER || formStep === TelephoneChangeStepsEnum.INPUT_SMS_CODE)
                }
                centered
            >
                <ModalBody className="px-4 pb-5">
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        {formStep === TelephoneChangeStepsEnum.INPUT_SMS_CODE && (
                            <ClickableElement onClick={() => setFormStep(TelephoneChangeStepsEnum.INPUT_TELEPHONE_NUMBER)}>
                                <FontAwesomeIcon icon={faArrowLeft} size="sm" className="mr-4 text-muted" />
                            </ClickableElement>
                        )}
                        <Trans i18nKey="settings.login-details.change-telephone-number" />
                    </h3>
                    {formStep === TelephoneChangeStepsEnum.INPUT_TELEPHONE_NUMBER && (
                        <AddPhoneNumber value={loggedInCustomer?.phone} onSuccess={onTelNumberSubmit} />
                    )}
                    {formStep === TelephoneChangeStepsEnum.INPUT_SMS_CODE && (
                        <VerifyPhoneNumber onSuccess={onSmsCodeSubmit} phone={newTelephoneNumber} phoneChallengeCode={challengeCode} />
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default TelephoneNumberChangeModal;
