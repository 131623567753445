import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, ListGroup, Modal, ModalBody } from 'reactstrap';
import HelpLinkWithModal from '../../../../components/help/help-link-with-modal';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IApiResponse, IRootState, LoadingIndicator, useApi, useReloadQueryCache } from '../../../../main';
import { UserListGroupItem } from '../../detail/data/modals/share-delivery-point-modal';
import { BulkShareDeliveryPointFormFields } from './bulk-share-delivery-point-form-fields';

interface IBulkShareDeliveryPointsModalProps {
    isOpen: boolean;
    close: () => void;
    unitedDeliveryPointIds: string[];
}

export const BulkShareDeliveryPointsModal: React.FC<IBulkShareDeliveryPointsModalProps> = ({ isOpen, close, unitedDeliveryPointIds }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const { register, handleSubmit, errors, trigger, setValue } = useForm<{ email: string }>();

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [emailsList, setEmailsList] = useState<string[]>([]);

    useEffect(() => {
        setEmailsList([]);
    }, [isOpen]);

    const reloadQueryCache = useReloadQueryCache();

    const api = useApi();

    const [mutateAddSharing, { isLoading: isLoadingAddSharing }] = useMutationWithError<
        unknown,
        IApiResponse,
        { udpIds: string[]; emails: string[] }
    >(
        async ({ udpIds, emails }) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers.createSharingDeliveryPoints(
                      loggedInCustomer.id,
                      { sharingUnitedDeliveryPoints: udpIds.map((x) => ({ unitedDeliveryPointUuid: x, emails: emails })) },
                      { secure: true },
                  ),
        {
            onSuccess: (data, variables) => {
                addToast(t('delivery-point.share-delivery-points.bulk-sharing-successful'), {
                    appearance: 'success',
                });
                reloadQueryCache('delivery-points-search');
                close();
                // TODo invalidate query (list OM)
            },
            onErrorWithGlobalErrorHandling: (responseError, variables) => {
                // addToast('TODO: (t) ERROR', {
                //     appearance: 'error',
                // });
                // return true;
                return false;
            },
        },
    );

    const removeEmail = (email: string) => {
        setEmailsList(emailsList.filter((x) => x !== email));
    };

    const addEmail = (email: string) => {
        if (!emailsList.includes(email)) {
            setEmailsList(emailsList.concat([email]));
            setValue('email', '');
        } else {
            addToast(t('delivery-point.share-delivery-points.email-is-already-in-list'), {
                appearance: 'warning',
            });
        }
    };

    const onSubmit = (formFields: { email: string }) => {
        addEmail(formFields.email);
    };

    const onShareButtonClicked = () => {
        if (emailsList.length > 0) {
            mutateAddSharing({ udpIds: unitedDeliveryPointIds, emails: emailsList });
        } else {
            addToast(t('delivery-point.share-delivery-points.emails-list-is-empty'), {
                appearance: 'warning',
            });
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <ModalBody>
                    <button type="button" onClick={close} className="close ml-auto d-block" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <div className="text-center mt-4 mb-5">
                        <i className="icon-users-16 fa-2x mb-3 d-block"></i>
                        <h3 className="mb-4">
                            <Trans i18nKey="delivery-point.share-delivery-points.bulk-delivery-point-sharing" />
                        </h3>
                    </div>

                    {emailsList.length > 0 && (
                        <>
                            <h5 className="forms-headline my-3">{t('delivery-point.detail.actions.share-delivery-point.shared-to')}:</h5>
                            <Card>
                                <ListGroup>
                                    {emailsList.map((email) => (
                                        <UserListGroupItem key={email} sharingInfo={{ email: email }} onRemove={() => removeEmail(email)} />
                                    ))}
                                </ListGroup>
                            </Card>
                        </>
                    )}

                    <h5 className="forms-headline mt-4 mb-2">{t('delivery-point.detail.actions.share-delivery-point.share-to')}:</h5>

                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <BulkShareDeliveryPointFormFields register={register} errors={errors} trigger={trigger} />
                    </form>

                    <Button type="button" block color="primary" size="lg" onClick={onShareButtonClicked} className="mt-5">
                        <Trans i18nKey="common.share" />
                    </Button>

                    <HelpLinkWithModal
                        showAsCard
                        className="my-4"
                        title="delivery-point.detail.actions.share-delivery-point.what-rights-does-the-person-i-share-with-have"
                        field="ZOM_RIGHTS_OF_PERSON_WHO_I_AM_SHARING_WITH"
                    />

                    {isLoadingAddSharing && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
