import React from 'react';
import { useTranslation } from 'react-i18next';
import { IContact } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IContact;
    bold?: boolean;
};

const ContactItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { email, phoneNumber } = data;

    return (
        <>
            {(email || phoneNumber) && (
                <ItemPreview title={t('customer-request.steps.contact.title')}>
                    {email && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.contact.email')}: ${email}`}</ItemPreviewContent>}
                    {phoneNumber && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.contact.phone-number')}: ${phoneNumber}`}</ItemPreviewContent>
                    )}
                </ItemPreview>
            )}
        </>
    );
};

export default ContactItem;
