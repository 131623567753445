import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, FormGroup, ListGroupItem, Row } from 'reactstrap';
import BaseSelect from '../../../../../../components/common/base-select';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import DatePicker from '../../../../../../components/common/datePicker/datePicker';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';
import { IComplainedAdvanceData } from './complained-advances-form-fields';

type Props = DefaultFormType & {
    item: IComplainedAdvanceData;
    onDelete: (uuid: string) => void;
    codeList: CodeListItem[];
    index: number;
};

const ComplainedAdvancesItem: React.FC<Props> = ({ register, item, index, onDelete, errors, setValue, getValues, trigger, codeList }) => {
    const [t] = useTranslation();
    const { formRules, dateRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <ListGroupItem className="mb-3 rounded">
            <Row>
                <Col lg={12}>
                    <FormGroup className=" d-flex flex-row">
                        <DatePicker
                            register={register({ ...formRules.required, ...dateRules().notFutureDate })}
                            name={`${item?.uuid}.date`}
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                            errors={errors}
                            label={t('customer-request.steps.complained-advances.payment-date')}
                            maxDate={new Date()}
                            touched={touched}
                        />
                    </FormGroup>

                    <Input
                        trigger={trigger}
                        type="number"
                        ref={register(formRules.requiredNumber)}
                        characterFilter={CharacterFilter.POSITIVE_DECIMAL}
                        errors={errors}
                        name={`${item?.uuid}.amount`}
                        label={<Trans i18nKey="customer-request.steps.complained-advances.amount-paid" />}
                        touched={touched}
                    />

                    <Input
                        trigger={trigger}
                        type="number"
                        ref={register({ ...formRules.number })}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        name={`${item?.uuid}.vs`}
                        label={<Trans i18nKey="customer-request.steps.complained-invoice.variable-symbol" />}
                        optional
                        touched={touched}
                    />
                    <BaseSelect
                        ref={register({})}
                        name={`${item?.uuid}.paymentType.uuid`}
                        label={t('customer-request.steps.complained-advances.payment-method')}
                    >
                        {codeList.map((item, index) => (
                            <option key={item?.uuid} value={item.uuid}>
                                {t(`enums.payment-type.${item.code}`)}
                            </option>
                        ))}
                    </BaseSelect>
                </Col>
                <Col lg={12} className="d-flex justify-content-center align-items-center">
                    {index !== 0 && <FontAwesomeIcon className="text-danger cursor-pointer" onClick={() => onDelete(item?.uuid)} icon={faTrashAlt} />}
                </Col>
            </Row>
        </ListGroupItem>
    );
};

export default ComplainedAdvancesItem;
