import { SkSppNzpBeApiCustomerprofileBusinessPartnerSummary } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../../models/customer-request-model';
import { KINDcodeHome } from '../../../../../../models/enums';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';
import useCodeList from './../../../../../../hooks/use-code-list';

interface IAddressWithEmailandPhone extends IAddress {
    email?: string;
    phoneNumber?: string;
}

type Props = {
    data: IAddressWithEmailandPhone;
    bold?: boolean;
    businessPartner?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
};

const NewAddressItem: React.FC<Props> = ({ data, bold, businessPartner }) => {
    const [t] = useTranslation();

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
        },
    });

    const country = useMemo(() => {
        return countryCodeList?.find((item) => item.uuid === data?.country?.uuid)?.name;
    }, [countryCodeList, data.country]);

    const title = businessPartner?.kind?.code
        ? businessPartner.kind.code === KINDcodeHome
            ? t('delivery-point.detail.contact-data.primary-address-home')
            : t('delivery-point.detail.contact-data.primary-address-company')
        : t('customer-request.steps.new-data.new-address');

    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={title}>
            {data?.street && (
                <ItemPreviewContent bold={bold}>
                    {`${data?.street || ''} ${data?.number || ''}`}, {`${data?.postalCode || ''} ${data?.city || ''}${`, ${country}` || ''}`}
                </ItemPreviewContent>
            )}
            {data?.floor && (
                <>
                    {t('customer-request.steps.address.floor')}
                    <ItemPreviewContent bold={bold} classNames={data?.flatNumber ? 'mb-1' : ''}>
                        {data.floor}.
                    </ItemPreviewContent>
                </>
            )}
            {data?.flatNumber && (
                <>
                    {t('customer-request.steps.address.appartments-number')}
                    <ItemPreviewContent bold={bold}>{data.flatNumber}</ItemPreviewContent>
                </>
            )}
            {data?.email && (
                <>
                    {t('customer-request.steps.contact.email')}
                    <ItemPreviewContent bold={bold}>{data.email}</ItemPreviewContent>
                </>
            )}
            {data?.phoneNumber && (
                <>
                    {t('customer-request.steps.contact.phone-number')}
                    <ItemPreviewContent bold={bold}>{data.phoneNumber}</ItemPreviewContent>
                </>
            )}
        </ItemPreview>
    );
};

export default NewAddressItem;
