import React, { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import HelpText from '../../../components/help/help-text';
import { IRootState, Routes } from '../../../main';

const ErrorScreen: React.FC = () => {
    const { t } = useTranslation();

    const errorMsg = useSelector((state: IRootState) => state.addDeliveryPoint.errorMsg); // error code

    const errorMessageTrans: ReactNode = useMemo(() => {
        switch (errorMsg) {
            case '4002':
                return (
                    <Trans
                        i18nKey="delivery-point.add-delivery-point.error.unknown-pairing-error"
                        components={{ ul: <ul></ul>, li: <li></li> }}
                    ></Trans>
                );
            case '4003':
                return t('error.4003');
            case '4004':
                return t('error.4004');
            case '4005':
                return t('error.4005');
            case '4006':
                return t('error.4006');
            case '4007':
                return t('error.4007');
            case '4008':
                return t('error.4008');
            case '4009':
                return t('error.4009');
            case '4010':
                return t('error.4010');
            case '4011':
                return t('error.4011');
            default:
                return t('delivery-point.add-delivery-point.error.msg-pair-request');
        }
    }, [errorMsg, t]);

    return (
        <div>
            <h4>
                <Trans i18nKey="delivery-point.add-delivery-point.error.title">Upps..</Trans>
            </h4>
            <p>{errorMessageTrans}</p>
            <HelpText screen="COM" field="COM_FAST_CONTACT" />

            <div className="text-center mt-5">
                <NavLink to={Routes.CONTACT} className="nav-link pl-0">
                    <Trans i18nKey="delivery-point.add-delivery-point.error.constact-all">Všetky kontakty</Trans>
                </NavLink>
            </div>
        </div>
    );
};

export default ErrorScreen;
