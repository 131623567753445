import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';

export enum EInvoiceSettingsActionType {
    OPEN_MODAL = 'EInvoiceSettings/OPEN_MODAL',
    SET_DELIVERY_POINTS = 'EInvoiceSettings/SET_DELIVERY_POINTS',
    SET_DELIVERY_POINT_EMAIL_CHANGE = 'EInvoiceSettings/SET_DELIVERY_POINT_EMAIL_CHANGE',
    SET_DELIVERY_POINT_ACTIVATION = 'EInvoiceSettings/SET_DELIVERY_POINT_ACTIVATION',
    RESET = 'EInvoiceSettings/RESET',
}

export enum EInvoiceSettingsModalTypes {
    EDIT_DELIVERY_POINT_EMAIL_MODAL = 1,
    ACTIVATE_EINVOICE_MODAL,
    BULK_ACTIVATE_EINVOICE_MODAL,
}
type ResetAction = {
    type: EInvoiceSettingsActionType.RESET;
};

type OpenModalAction = {
    type: EInvoiceSettingsActionType.OPEN_MODAL;
    payload: { modalType: EInvoiceSettingsModalTypes | undefined };
};
type SetDeliveryPointsAction = {
    type: EInvoiceSettingsActionType.SET_DELIVERY_POINTS;
    payload: { deliveryPoints: UnitedDeliveryPointSummary[] };
};
type SetDeliveryPointForEmailChangeAction = {
    type: EInvoiceSettingsActionType.SET_DELIVERY_POINT_EMAIL_CHANGE;
    payload: { deliveryPoint: UnitedDeliveryPointSummary | undefined };
};
type SetDeliveryPointForActivationAction = {
    type: EInvoiceSettingsActionType.SET_DELIVERY_POINT_ACTIVATION;
    payload: { deliveryPoint: UnitedDeliveryPointSummary | undefined };
};

export type eInvoiceSettingsAction =
    | SetDeliveryPointsAction
    | OpenModalAction
    | SetDeliveryPointForEmailChangeAction
    | SetDeliveryPointForActivationAction
    | ResetAction;

export const eInvoiceSettingsActions = {
    openModal: (modalType: EInvoiceSettingsModalTypes | undefined): OpenModalAction => ({
        type: EInvoiceSettingsActionType.OPEN_MODAL,
        payload: { modalType: modalType },
    }),
    setDeliveryPoints: (deliveryPoints: UnitedDeliveryPointSummary[]): SetDeliveryPointsAction => ({
        type: EInvoiceSettingsActionType.SET_DELIVERY_POINTS,
        payload: { deliveryPoints: deliveryPoints },
    }),
    setDeliveryPointForEmailChange: (deliveryPoint: UnitedDeliveryPointSummary | undefined): SetDeliveryPointForEmailChangeAction => ({
        type: EInvoiceSettingsActionType.SET_DELIVERY_POINT_EMAIL_CHANGE,
        payload: { deliveryPoint: deliveryPoint },
    }),
    setDeliveryPointForActivation: (deliveryPoint: UnitedDeliveryPointSummary | undefined): SetDeliveryPointForActivationAction => ({
        type: EInvoiceSettingsActionType.SET_DELIVERY_POINT_ACTIVATION,
        payload: { deliveryPoint: deliveryPoint },
    }),
    reset: (): ResetAction => ({ type: EInvoiceSettingsActionType.RESET }),
};
