export enum HelpActionTypesEnum {
    SET_AVAILABLE_HELPS = 'help/SET_AVAILABLE_HELPS',
    INITIALIZE_HELPS = 'help/INITIALIZE',
    SET_HELP_PAGE = 'help/SET_HELP_PAGE',
}

export interface ISetHelpAction {
    type: HelpActionTypesEnum;
    payload?: { availableHelps?: string[]; helpPage?: string };
    appVersion?: string;
}

export type HelpActionType = ISetHelpAction;

export const HelpActions = {
    setAvailableHelps: (availableHelps: string[]): ISetHelpAction => ({
        type: HelpActionTypesEnum.SET_AVAILABLE_HELPS,
        payload: { availableHelps: availableHelps },
    }),
    initializeHelp: (appVersion: string): ISetHelpAction => ({
        type: HelpActionTypesEnum.INITIALIZE_HELPS,
        appVersion: appVersion,
    }),
    setHelpPage: (helpPage: string): ISetHelpAction => ({
        type: HelpActionTypesEnum.SET_HELP_PAGE,
        payload: { helpPage },
    }),
};
