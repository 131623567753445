import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncing } from '../../../hooks/use-debouncing';

interface ITextColumnFilterProps {
    name: string;
    placeholder: string;
    minWidth?: string;
    filterValue: string;
    className?: string;
    onFilterChange: (value: string) => void;
    onFilterSubmit: () => void;
    colSpan?: number;
}

const TextColumnFilter: React.FC<ITextColumnFilterProps> = ({
    name,
    placeholder,
    minWidth,
    filterValue,
    className,
    onFilterChange,
    onFilterSubmit,
    colSpan,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const debounce = useDebouncing();

    const applyFilter = useCallback(() => {
        onFilterSubmit && onFilterSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onFilterSubmit]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onFilterChange(newValue);
    };

    const [isFirstRender, setIsFirstRender] = useState(true);
    useEffect(() => {
        if (!isFirstRender) {
            debounce(() => {
                applyFilter();
            }, 600);
        }
        setIsFirstRender(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, applyFilter, debounce]);

    return (
        <>
            <th key={name} scope="col" colSpan={colSpan}>
                {/* IE 11 hack for CSS problem with display:flex and position: relative */}
                <div
                    style={{ width: '100%', minWidth: minWidth || '100px', height: '40px', display: 'block', position: 'relative' }}
                    className={className}
                >
                    <div
                        className={classNames('input-group', 'text-truncate', 'search-box', {
                            'is-focused': isFocused, // hack for IE11 as IE11 doesn't support focus-within
                        })}
                        style={{ position: 'absolute' }}
                    >
                        <input
                            type="text"
                            className="form-control text-truncate"
                            aria-describedby="filter-text"
                            placeholder={placeholder}
                            value={filterValue}
                            onChange={onChange}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    applyFilter();
                                }
                            }}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => {
                                setIsFocused(false);
                            }}
                        />
                        <div className="input-group-append">
                            <button type="button" id="button-addon-filter1" className="btn text-primary" onClick={applyFilter}>
                                <i className="icon-search fa-lg"></i>
                            </button>
                        </div>
                        <div className={`search-input--clear pr-3 fade ${!!filterValue && isFocused ? 'show' : ''}`}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="cursor-pointer" onClick={() => onFilterChange('')} />
                        </div>
                    </div>
                </div>
            </th>
        </>
    );
};

export default TextColumnFilter;
