import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import useSso from '../../hooks/sso/use-sso';
import { EmployeeTypeEnum } from '../../models/enums';
import { IRootState } from '../../reducers';
import Routes from '../../routes/routes';
import { getProperHomepage } from '../../views/home/get-proper-homepage';
import NavbarCustomer from './navbar-customer/navbar-customer';
import NavbarEmployee from './navbar-employee/navbar-employee';

type Props = {
    noInternetConnection?: boolean;
};

const Navbar: React.FC<Props> = ({ noInternetConnection }) => {
    const user = useSelector((state: IRootState) => state.user);
    const isUserLogged = useSelector((s: IRootState) => s.user.isUserLogged);
    const secondaryAccessToken = useSelector((s: IRootState) => s.auth.secondaryAccessToken);
    const history = useHistory();
    const location = useLocation();
    const { ssoLogout, ssoActorEmployeePartialLogout } = useSso();

    const homeRouteMatch = useRouteMatch(Routes.HOME);

    const [isCustomer, setIsCustomer] = useState<boolean>(false);
    const [isEmployee, setIsEmployee] = useState<boolean>(false);
    const [employeeLoggedAs, setEmployeeLoggedAs] = useState<EmployeeTypeEnum | undefined>(undefined);

    useEffect(() => {
        if (!user) return;
        setIsCustomer(!!user.customer);
        setIsEmployee(!!user.employee);
        setEmployeeLoggedAs(!!secondaryAccessToken ? EmployeeTypeEnum.EMPLOYEE_AS_CUSTOMER : EmployeeTypeEnum.EMPLOYEE);
    }, [user, secondaryAccessToken]);

    const logoutUser = () => {
        ssoLogout(Routes.LOGOUT_CONFIRMATION);
    };

    const logoutPartialCustomer = () => {
        //actorEmployee partial logout, this will log out only from acting customer, employee stays logged in
        ssoActorEmployeePartialLogout();
    };

    const goHome = () => {
        const properHomepage = getProperHomepage(user);
        if (properHomepage !== location.pathname) {
            history.push(properHomepage);
        }
    };

    //Home should not render navbar, only reroute user to his homepage
    if (homeRouteMatch?.isExact) return null;

    if (!isUserLogged || (isCustomer && !isEmployee)) {
        //not logged or logged as customer
        return <NavbarCustomer isLogged={isUserLogged} user={user} logout={logoutUser} goHome={goHome} noInternetConnection={noInternetConnection} />;
    }

    if (isEmployee && employeeLoggedAs !== undefined)
        //logged as employee or actorEmployee
        return (
            <>
                <NavbarEmployee
                    customer={user.customer}
                    employee={user.employee}
                    employeeAsCustomer={isCustomer && !!secondaryAccessToken}
                    logout={logoutUser}
                    goHome={goHome}
                />
                {isCustomer && secondaryAccessToken && (
                    <NavbarCustomer isLogged={isUserLogged} user={user} logout={logoutPartialCustomer} goHome={goHome} />
                )}
            </>
        );

    //will not come to this, have to be defined because of React.FC output cannot be undefined
    return null;
};

export default Navbar;
