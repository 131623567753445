import { SkSppNzpBeApiCustomeraccessChallenge, SkSppNzpBeApiCustomeraccessPasswordChallenge } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Col, Row } from 'reactstrap';
import { Benefits } from '../../components/common';
import PasswordInput from '../../components/common/password-input';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { formOptions, IApiResponse, LoadingIndicator, useFormRules } from '../../main';
import { handlePasswordError } from '../../utils/error-handling';

type PasswordInputType = {
    password: string;
};

const ForgotPasswordConfirmation: React.FC = () => {
    const urlQuery = useUrlQuery();
    const history = useHistory();
    const api = useApi();
    const [t] = useTranslation();
    const { addToast } = useToasts();
    const { formRules, passwordRules } = useFormRules();

    const [password, setPassword] = useState<string>('');
    const [isRecoverySuccessful, setIsRecoverySuccessful] = useState<boolean>(false);
    const [isLinkNotExpired, setIsLinkNotExpired] = useState<boolean | undefined>(undefined);

    const code = urlQuery.get('challengeCode');
    const uuid = urlQuery.get('challengeCodeUuid');

    const onNewPasswordSubmitted = (formData: FormData & PasswordInputType) => {
        setPassword(formData.password);
    };

    const { register, errors, handleSubmit, setError, trigger, reset } = useForm(formOptions());

    const [mutatePasswordRecoveryChallenge] = useMutationWithError(
        async (passwordChallengeData: SkSppNzpBeApiCustomeraccessChallenge) => api.customers.passwordRecoveryChallengeValidity(passwordChallengeData),
        {
            onSuccess() {
                setIsLinkNotExpired(true);
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error?.code === 2010) {
                    setIsLinkNotExpired(false);
                    return true;
                }
                if (error?.code === 404) {
                    addToast(t('settings.login-details.password-challange-failed'), { appearance: 'error' });
                    return true;
                }
                return false;
            },
        },
    );

    useEffect(() => {
        if (!code || !uuid) return;
        const passwordChallengeData = {
            code,
            uuid,
        };
        mutatePasswordRecoveryChallenge(passwordChallengeData);
    }, [code, uuid, mutatePasswordRecoveryChallenge, history]);

    const [mutatePasswordRecoveryConfirm] = useMutationWithError(
        async (passwordChallengeData: SkSppNzpBeApiCustomeraccessPasswordChallenge) => api.customers.confirmPasswordRecovery(passwordChallengeData),
        {
            onSuccess() {
                reset();
                addToast(<Trans i18nKey="settings.login-details.password-has-been-changed">Heslo bolo zmenené</Trans>, { appearance: 'success' });
                setIsRecoverySuccessful(true);
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error != null && error.code === 2001) {
                    const addError = (obj: ErrorOption) => setError('password', obj);
                    handlePasswordError(t, addError, error);
                    return true;
                }
                return false;
            },
        },
    );

    useEffect(() => {
        if (!code || !uuid || !password) return;
        const passwordChallengeData = {
            code,
            uuid,
            password,
        };
        mutatePasswordRecoveryConfirm(passwordChallengeData);
    }, [code, uuid, password, mutatePasswordRecoveryConfirm, history]);

    return (
        <div className="container my-5">
            <Row>
                <Benefits />
                <Col>
                    {isLinkNotExpired === undefined && <LoadingIndicator />}
                    {isLinkNotExpired === false && (
                        <div className="col-12 position-static text-center">
                            <i className="icon-circle-alert-16 fa-5x text-danger d-block my-3"></i>
                            <h3 className="my-3 text-danger">
                                <Trans i18nKey="confirm-password-recovery.error" />
                            </h3>
                            <div className="text-center mt-4">{t('confirm-password-recovery.password-recovery-email-expired')}</div>
                        </div>
                    )}
                    {isLinkNotExpired === true &&
                        (isRecoverySuccessful ? (
                            <div className="col-12 position-static text-center">
                                <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                                <h3 className="my-3 text-success">
                                    <Trans i18nKey="confirm-password-recovery.success">Vaše heslo bolo zmenené. Môžete sa prihlásiť.</Trans>
                                </h3>
                            </div>
                        ) : (
                            <div className="col" style={{ paddingBottom: '250px' }}>
                                {/* The paddingBottom is needed due to the correct displaying of Password Tooltip */}
                                <h2>
                                    <Trans i18nKey="login-issues.password-recovery">Obnova hesla</Trans>
                                </h2>
                                <p className="mb-5">
                                    <Trans i18nKey="login-issues.password-recovery-instruction">Pre dokončenie zadajte nové heslo</Trans>
                                </p>
                                <form onSubmit={handleSubmit(onNewPasswordSubmitted)} noValidate>
                                    <PasswordInput
                                        ref={register({ ...passwordRules.isValidPassword, ...formRules.minLengthX(8) })}
                                        id="password"
                                        name="password"
                                        errors={errors}
                                        label={t('login-issues.new-password')}
                                        trigger={trigger}
                                        autoFocus
                                    />
                                    <button type="submit" className="btn btn-primary btn-block btn-lg my-5">
                                        <Trans i18nKey="common.send">Odoslať</Trans>
                                    </button>
                                </form>
                            </div>
                        ))}
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPasswordConfirmation;
