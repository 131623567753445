import {
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse as DeliveryPointNotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
} from '@spp/spp-meru-frontend-common/src/api';
import React, { useMemo } from 'react';
import { RefetchOptions } from 'react-query/types/core/query';
import { Card, CardHeader } from 'reactstrap';
import { NotificationTemplateGroup } from '../../../models/model';
import { formatAddressShort } from '../../../utils/utils';
import DeliveryPointNotificationSettingsCheckboxGrid from './delivery-point-notification-settings-checkbox-grid';

interface IDeliveryPointNotificationSettingCardProps {
    deliveryPointNotificationSetting: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse;
    fetchNotificationSettings: (options?: RefetchOptions | undefined) => Promise<DeliveryPointNotificationSettingResponse[] | null | undefined>;
}

const DeliveryPointNotificationSettingCard: React.FC<IDeliveryPointNotificationSettingCardProps> = ({
    deliveryPointNotificationSetting,
    fetchNotificationSettings,
}) => {
    const unitedDeliveryPoint = deliveryPointNotificationSetting.unitedDeliveryPoint;
    const notificationSettings = deliveryPointNotificationSetting.notificationSettings;

    const notificationTemplateGroups = useMemo(() => {
        const groupSet: Set<NotificationTemplateGroup> = new Set();
        notificationSettings?.forEach((nt) => {
            const group = nt.notificationTemplate?.group;
            if (group == null) {
                return;
            }
            if (group === 'LIMIT_OVERFLOW') {
                if (unitedDeliveryPoint?.deliveryPoints?.every((x) => !x.hasLimit)) {
                    return;
                }
            }
            groupSet.add(group);
        });
        return Array.from(groupSet);
    }, [unitedDeliveryPoint, notificationSettings]);

    return (
        <>
            <Card>
                <CardHeader className="notification-spp-left-border">
                    <p className="small font-weight-bold d-flex mb-0">
                        {formatAddressShort({
                            street: unitedDeliveryPoint?.address?.street,
                            streetNumber: unitedDeliveryPoint?.address?.streetNumber,
                        })}
                    </p>
                </CardHeader>

                <DeliveryPointNotificationSettingsCheckboxGrid
                    deliveryPointNotificationSetting={deliveryPointNotificationSetting}
                    notificationTemplateGroups={notificationTemplateGroups}
                    fetchNotificationSettings={fetchNotificationSettings}
                />
            </Card>
        </>
    );
};

export default DeliveryPointNotificationSettingCard;
