import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import {
    SkSppNzpBeApiCustomerprofileBusinessPartner as BusinessPartner,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Collapse } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import BaseSwitch from '../../../../../components/common/base-switch';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useCodeList from '../../../../../hooks/use-code-list';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IAddress, IBusinessPartner, IContact, INewAccountInfo } from '../../../../../models/customer-request-model';
import {
    BusinessPartnerTypeEnum,
    ConsumptionCategoryEnum,
    CustomerAccountTypeEnum,
    HaveAccountEnum,
    KINDcodeHome,
} from '../../../../../models/enums';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties, formatBusinessPartnerName, formatPhoneNumber, isObjectEmpty } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import {
    blocksTranscriptNewCustomer,
    blocksTranscriptNewCustomerOnlyEE,
    blocksTranscriptNewCustomerOnlyZP,
    blocksTranscriptNewCustomerWithoutRateAndTariff,
} from '../../../config/request-config';
import AddressFields from '../block-address/components/address-fields';
import ContactFormFields from '../block-contact/components/contact-form-fields';
import BusinessPartnerForm from './components/business-partner-form';
import CustomerAccountInfoForm from './components/customer-account-info-form';

type BlockCustomerFormType = IBusinessPartner & {
    businessPartnerId: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    idCardNumber: string;
    name: string;
    companyRegistrationNumber: string;
    taxIdNumber: string;
    address?: IAddress;
    correspondenceAddress?: IAddress;
    correspondenceFields?: boolean;
    contact?: IContact;
    accountInfo: INewAccountInfo;
};

const firstStepFieldNamesPerson: Array<'firstName' | 'lastName' | 'birthDate' | 'idCardNumber'> = [
    'firstName',
    'lastName',
    'birthDate',
    'idCardNumber',
];
const firstStepFieldNamesCompany: Array<'name' | 'companyRegistrationNumber' | 'taxIdNumber'> = ['name', 'companyRegistrationNumber', 'taxIdNumber'];
const secondStepFieldNames = [
    'address.street',
    'address.number',
    'address.city',
    'address.postalCode',
    'address.country.uuid',
    'correspondenceAddress.street',
    'correspondenceAddress.number',
    'correspondenceAddress.city',
    'correspondenceAddress.postalCode',
    'correspondenceAddress.country.uuid',
];

const BlockBusinessPartnerExtended: React.FC = () => {
    const api = useApi();
    const dispatch = useDispatch();
    const { additionalData, partnersDeliveryPoints, businessPartner, currentBlock, content, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );

    const loggedInUser = useSelector((state: IRootState) => state.user.customer);
    const { requiredTrimed } = useFormRules();
    const { t } = useTranslation();

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.BUSINESS_PARTNER;
    const objectData = content[objectName] as IBusinessPartner;

    const [loading, setLoading] = useState<boolean>(false);

    const [insertNewCustomer, setInsertNewCustomer] = useState(content.businessPartner ? true : false);

    const [selectedType, setSelectedType] = useState<BusinessPartnerTypeEnum>(
        objectData?.type ?? content.accountInfo?.category ?? BusinessPartnerTypeEnum.PERSON,
    );

    const addressName = currentBlock?.dataKeys?.length ? currentBlock.dataKeys[0] : CustomerRequestDataEnum.ADDRESS;
    const correspondenceAddressName = currentBlock?.dataKeys?.length ? currentBlock.dataKeys[1] : CustomerRequestDataEnum.CORRESPONDACE_ADDRESS;
    const contactName = CustomerRequestDataEnum.CONTACT;

    const addressData = content[addressName] as IAddress;
    const correspondenceAddressData = content[correspondenceAddressName] as IAddress;
    const contactData = content[contactName] as IContact;

    const { register, errors, handleSubmit, getValues, trigger, watch, setValue, clearErrors, unregister, setError } = useForm<BlockCustomerFormType>(
        {
            defaultValues: {
                ...objectData,
                address: {
                    name: addressData?.name ?? content.newDeliveryPointAddress?.name,
                    street: addressData?.street ?? content.newDeliveryPointAddress?.street,
                    number: addressData?.number ?? content.newDeliveryPointAddress?.number,
                    city: addressData?.city ?? content.newDeliveryPointAddress?.city,
                    country: addressData?.country ?? content.selectedDPorBPCountry,
                    postalCode: addressData?.postalCode ?? content.newDeliveryPointAddress?.postalCode,
                    floor: addressData?.floor ?? content.newDeliveryPointAddress?.floor,
                    flatNumber: addressData?.flatNumber ?? content.newDeliveryPointAddress?.flatNumber,
                },
                contact: contactData ?? { email: loggedInUser?.email, phoneNumber: loggedInUser?.phone || '+421' },
                correspondenceAddress: { ...correspondenceAddressData, country: correspondenceAddressData?.country ?? content.selectedDPorBPCountry },
                correspondenceFields: !!correspondenceAddressData,
                businessPartnerId: businessPartner?.id ?? additionalData?.bpuuid,
                accountInfo: content.accountInfo,
            },
        },
    );

    const [mutateBusinessPartner] = useMutationWithError<BusinessPartner | null, unknown, { uuid: string }>(
        async ({ uuid }) => api.businessPartners.getBusinessPartnerById(uuid, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: () => {
                setLoading(false);
                return false;
            },
        },
    );

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const areBusinessPartnersPresented = businessPartners && Object.keys(businessPartners).length > 0 ? true : false;

    const [insertAccountInfo, setInsertAccountInfo] = useState(content.accountInfo ? true : false);
    const [haveAccount, setHaveAccount] = useState<HaveAccountEnum>(
        !areBusinessPartnersPresented && content.businessPartner ? HaveAccountEnum.NO : HaveAccountEnum.YES,
    );

    useEffect(() => {
        if (!areBusinessPartnersPresented) {
            setInsertAccountInfo(true);
        } else {
            setHaveAccount(HaveAccountEnum.YES);
            setInsertAccountInfo(content.accountInfo ? true : false);
        }
    }, [areBusinessPartnersPresented, content.accountInfo]);

    const [mutatePhoneValidate] = useMutationWithError(async (phone: string) => api.validations.checkPasswordComplexityWithPhone({ phone }), {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error) {
                setLoading(false);
                if (haveAccount === HaveAccountEnum.YES) {
                    window.scrollTo(0, document.body.scrollHeight);
                    setError('accountInfo.phoneNumber', {
                        message: t('common.input-rules.phoneNumber'),
                    });
                } else {
                    setError('contact.phoneNumber', {
                        message: t('common.input-rules.phoneNumber'),
                    });
                }
                return true;
            }
            return false;
        },
    });

    const onSubmit = useCallback(async () => {
        await trigger();
        const data = getValues();
        setLoading(true);
        let selectedOptionType = selectedType;
        if (insertNewCustomer) {
            const telephoneNumber = formatPhoneNumber(data?.contact?.phoneNumber || '');
            const result = telephoneNumber ? await mutatePhoneValidate(telephoneNumber) : undefined;
            if (telephoneNumber && !result) {
                if (content.saveRequested) {
                    dispatch(
                        CustomerRequestActions.setData({
                            saveRequested: false,
                        }),
                    );
                }
                setLoading(false);
                return;
            }
            const partnerData = data && clearObjectProperties(data);

            const address = data?.address && clearObjectProperties(data.address);
            const correspondenceAddress = data?.correspondenceAddress && clearObjectProperties(data.correspondenceAddress);
            const contact = data?.contact && clearObjectProperties(data.contact);

            delete partnerData?.address;
            delete partnerData?.correspondenceAddress;
            delete partnerData.contact;

            const payloadPartnerData = isObjectEmpty(partnerData) ? null : { ...partnerData, type: selectedType };

            let payload: CustomerRequestPayloadType = {
                [objectName]: {
                    ...payloadPartnerData,
                    bp: data?.businessPartnerId,
                },
                accountInfo: undefined,
                [addressName]: address,
                [correspondenceAddressName]: correspondenceAddress,
                [contactName]: {
                    email: contact?.email || '',
                    phoneNumber: (contact?.phoneNumber && formatPhoneNumber(contact?.phoneNumber)) || '',
                },
                //must be reset because in invoice delivery block we show address based on selection
                invoiceDelivery: undefined,
                saved: content.saveRequested ? true : undefined,
            };

            if (content.businessPartner?.type !== data.type) {
                payload = { ...payload, tariffEe: undefined, tariffZp: undefined };
            }

            if (objectName === CustomerRequestDataEnum.BUSINESS_PARTNER) {
                dispatch(CustomerRequestActions.setBusinessPartner(undefined));
                dispatch(
                    CustomerRequestActions.setMetaData({
                        businessPartnerId: undefined,
                        category: selectedType === BusinessPartnerTypeEnum.COMPANY ? ConsumptionCategoryEnum.RETAIL : ConsumptionCategoryEnum.HOME,
                    }),
                );
            }
            dispatch(CustomerRequestActions.setData(payload));
            dispatch(CustomerRequestActions.setBusinessPartner(undefined));
        } else if (insertAccountInfo) {
            const telephoneNumber = formatPhoneNumber(data?.accountInfo?.phoneNumber || '');
            const result = telephoneNumber ? await mutatePhoneValidate(telephoneNumber) : undefined;
            if (telephoneNumber && !result) {
                if (content.saveRequested) {
                    dispatch(
                        CustomerRequestActions.setData({
                            saveRequested: false,
                        }),
                    );
                }
                setLoading(false);
                return;
            }
            const accountInfo: INewAccountInfo = {
                phoneNumber: telephoneNumber,
                email: data.accountInfo?.email,
                bpNumber: data.accountInfo?.bpNumber,
                category: selectedType,
            };

            let payload: CustomerRequestPayloadType = {
                accountInfo,
                businessPartner: undefined,
                address: undefined,
                correspondenceAddress: undefined,
                contact: undefined,
                saved: content.saveRequested ? true : undefined,
            };

            if (content.accountInfo?.category !== selectedType) {
                payload = { ...payload, tariffEe: undefined, tariffZp: undefined };
            }
            dispatch(CustomerRequestActions.setData(payload));
            dispatch(CustomerRequestActions.setBusinessPartner(undefined));
        } else {
            const partner = data.businessPartnerId ? await mutateBusinessPartner({ uuid: data.businessPartnerId }) : null;
            if (!partner && !content.saveRequested) {
                return;
            }
            if (partner) {
                dispatch(CustomerRequestActions.setBusinessPartner(partner));
                const { primaryAddress } = partner;

                let extraPayload: CustomerRequestPayloadType = {
                    address: {
                        street: primaryAddress?.street,
                        number: primaryAddress?.streetNumber,
                        city: primaryAddress?.city,
                        postalCode: primaryAddress?.zipCode,
                    },
                };
                if (businessPartner?.id !== partner.id) {
                    extraPayload = { ...extraPayload, tariffEe: undefined, tariffZp: undefined, invoiceDelivery: undefined };
                }
                selectedOptionType = partner.kind?.code === KINDcodeHome ? BusinessPartnerTypeEnum.PERSON : BusinessPartnerTypeEnum.COMPANY;
                dispatch(CustomerRequestActions.setData(extraPayload));
                dispatch(
                    CustomerRequestActions.setData({
                        businessPartner: undefined,
                        address: undefined,
                        correspondenceAddress: undefined,
                        accountInfo: undefined,
                        selectedDPorBPCountry: countryCodeList?.find((item) => item.code === primaryAddress?.country),
                        saved: content.saveRequested ? true : undefined,
                    }),
                );
            } else {
                dispatch(
                    CustomerRequestActions.setData({
                        saved: content.saveRequested ? true : undefined,
                    }),
                );
            }
        }

        if (content.saveRequested) {
            return;
        }

        if (customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_P && content.deliveryPointsEE?.length !== 0) {
            const deliveryPointsForRates = (content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.filter((dp) => {
                const dpType =
                    dp.contract?.contractAccount?.businessPartner?.kind?.code === KINDcodeHome
                        ? BusinessPartnerTypeEnum.PERSON
                        : BusinessPartnerTypeEnum.COMPANY;
                if (dpType === selectedOptionType) {
                    return false;
                }
                return true;
            });
            if (deliveryPointsForRates.length === 0) {
                if (content.deliveryPointsZP?.length === 0) {
                    dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomerWithoutRateAndTariff));
                } else {
                    dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomerOnlyZP));
                }
                dispatch(CustomerRequestActions.setData({ rates: undefined, deliveryPointsForRates: undefined }));
            } else {
                if (content.deliveryPointsZP?.length === 0) {
                    dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomerOnlyEE));
                } else {
                    dispatch(CustomerRequestActions.replaceBlocks(blocksTranscriptNewCustomer));
                }
                dispatch(CustomerRequestActions.setData({ deliveryPointsForRates: deliveryPointsForRates }));
            }
        }
        setLoading(false);
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    }, [
        dispatch,
        getValues,
        mutateBusinessPartner,
        trigger,
        insertNewCustomer,
        objectName,
        selectedType,
        addressName,
        correspondenceAddressName,
        insertAccountInfo,
        contactName,
        countryCodeList,
        content.saveRequested,
        mutatePhoneValidate,
        businessPartner?.id,
        content.accountInfo?.category,
        content.businessPartner?.type,
        content.deliveryPointsEE,
        content.deliveryPointsZP?.length,
        customerRequestTemplate?.code,
    ]);

    useEffect(() => {
        if (content.saveRequested) {
            onSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    const [isFirstCollapseVerified, setIsFirstCollapseVerified] = useState<boolean>(false);
    const [isSecondCollapseVerified, setIsSecondCollapseVerified] = useState<boolean>(false);
    const [isFourthCollapseVerified, setIsFourthCollapseVerified] = useState<boolean>(false);

    const [isFirstExpanded, setIsFirstExpanded] = useState<boolean>(true);
    const [isSecondExpanded, setIsSecondExpanded] = useState<boolean>(false);
    const [isFourthExpanded, setIsFourthExpanded] = useState<boolean>(false);

    const openFirstCollapse = (value = true) => {
        setIsFirstExpanded(value);
        setIsSecondExpanded(false);
        setIsFourthExpanded(false);
    };

    const openSecondCollapse = (value = true) => {
        setIsFirstExpanded(false);
        setIsSecondExpanded(value);
        setIsFourthExpanded(false);
    };

    const openFourthCollapse = (value = true) => {
        setIsFirstExpanded(false);
        setIsSecondExpanded(false);
        setIsFourthExpanded(value);
    };

    // after initialization run check weather we have already stored some data.
    useEffect(() => {
        if (insertNewCustomer) {
            handleFirstButton(true);
        } else {
            setIsFirstCollapseVerified(false);
            setIsSecondCollapseVerified(false);
            openFirstCollapse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insertNewCustomer]);

    useEffect(() => {
        if (insertAccountInfo && areBusinessPartnersPresented) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [insertAccountInfo, areBusinessPartnersPresented]);

    const handleFirstButton = async (nextStepValidation?: boolean) => {
        const data = getValues();
        if (
            nextStepValidation === false ||
            data?.firstName ||
            data?.lastName ||
            data?.birthDate ||
            data?.idCardNumber ||
            data?.taxIdNumber ||
            data?.companyRegistrationNumber ||
            data?.taxIdNumber ||
            data?.name
        ) {
            trigger(selectedType === BusinessPartnerTypeEnum.PERSON ? firstStepFieldNamesPerson : firstStepFieldNamesCompany).finally(() => {
                if (isErrorInFirstBlock()) {
                    setIsFirstCollapseVerified(false);
                } else {
                    if (!addressData && selectedType === BusinessPartnerTypeEnum.COMPANY) {
                        setValue('address.name', '');
                        setValue('address.street', '');
                        setValue('address.number', '');
                        setValue('address.city', '');
                        setValue('address.postalCode', '');
                        setValue('address.floor', '');
                        setValue('address.flatNumber', '');
                    }
                    openSecondCollapse();
                    setIsFirstCollapseVerified(true);
                    nextStepValidation && handleSecondButton(true);
                }
            });
        }
    };

    const handleSecondButton = async (nextStepValidation?: boolean) => {
        trigger(secondStepFieldNames).finally(() => {
            if (isErrorInFirstBlock() || isErrorInSecondBlock()) {
                setIsSecondCollapseVerified(false);
            } else {
                openFourthCollapse();
                nextStepValidation && handleFourthBlock();
                setIsSecondCollapseVerified(true);
            }
        });
    };

    const handleFourthBlock = async () => {
        trigger(['contact.email', 'contact.phoneNumber']).finally(() => {
            if (errors && Object.keys(errors).length > 0) {
                setIsFourthCollapseVerified(false);
            } else {
                setIsFourthCollapseVerified(true);
            }
        });
    };

    useEffect(() => {
        if (isSecondCollapseVerified) {
            trigger([...firstStepFieldNamesPerson, ...firstStepFieldNamesCompany]).finally(() => {
                selectedType === BusinessPartnerTypeEnum.COMPANY && clearErrors(firstStepFieldNamesPerson);
                selectedType === BusinessPartnerTypeEnum.PERSON && clearErrors(firstStepFieldNamesCompany);
            });
            setIsFirstCollapseVerified(false);
        } else {
            selectedType === BusinessPartnerTypeEnum.COMPANY && clearErrors(firstStepFieldNamesPerson);
            selectedType === BusinessPartnerTypeEnum.PERSON && clearErrors(firstStepFieldNamesCompany);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, trigger, clearErrors]);

    const isErrorInFirstBlock = () => {
        let result = false;
        selectedType === BusinessPartnerTypeEnum.PERSON &&
            firstStepFieldNamesPerson.forEach((field) => {
                if (errors && errors[field]) {
                    result = true;
                }
            });

        selectedType === BusinessPartnerTypeEnum.COMPANY &&
            firstStepFieldNamesCompany.forEach((field) => {
                if (errors && errors[field]) {
                    result = true;
                }
            });

        return result;
    };

    const isErrorInSecondBlock = () => {
        let result = false;
        const properties: Array<'number' | 'street' | 'city' | 'postalCode'> = ['street', 'number', 'city', 'postalCode'];
        properties.forEach((field) => {
            if (errors.address && errors.address[field]) {
                result = true;
            }
            if (errors.correspondenceAddress && errors.correspondenceAddress[field]) {
                result = true;
            }
        });
        if (errors.address?.country?.uuid) {
            result = true;
        }

        return result;
    };

    return (
        <>
            <p className="text-left text-lg mb-4 ">
                {areBusinessPartnersPresented
                    ? customerRequestTemplate?.code === 'ZOP_US'
                        ? t('customer-request.steps.block-carbon-footprint-business-partner.subtitle-1')
                        : t(`customer-request.steps.new-customer-choice.select-customer`)
                    : t(`customer-request.steps.new-customer-choice.insert-personal-info`)}
            </p>
            {loading && <LoadingIndicator fullscreen />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {!insertNewCustomer &&
                    Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
                        const businessPartner = deliveryPoints[0].businessPartner;
                        //for carbon footprint show only collective business partners
                        if (
                            businessPartner?.queue === CustomerAccountTypeEnum.INDIVIDUAL &&
                            customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_US
                        ) {
                            return null;
                        }
                        //for new EE/ZP show only business partners with KIND code 141 or 142
                        // if (
                        //     (customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE ||
                        //         customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODP) &&
                        //     businessPartner?.kind?.code &&
                        //     !['141', '142'].includes(businessPartner?.kind?.code)
                        // ) {
                        //     return null;
                        // }

                        // for carbon shank show only business partners with KIND code HOME
                        if (customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_US && businessPartner?.kind?.code !== KINDcodeHome) {
                            return null;
                        }
                        return businessPartner == null ? null : (
                            <Card key={bpId} className="mb-3">
                                <CardBody>
                                    <BaseRadio
                                        ref={register({ ...(insertAccountInfo ? () => true : requiredTrimed) })}
                                        value={bpId}
                                        id={`businessPartnerDeliveryPointsRadio${bpId}`}
                                        name="businessPartnerId"
                                        onClick={() => setInsertAccountInfo(false)}
                                        className="mt-0"
                                        label={
                                            <>
                                                <b>{`${formatBusinessPartnerName(businessPartner)}`}</b>
                                                <br />
                                                {t('delivery-point.customer-number')} {businessPartner.externalId}
                                            </>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        );
                    })}
                {areBusinessPartnersPresented && !insertNewCustomer && (
                    <Card className="mb-3">
                        <CardBody
                            onClick={() => {
                                setInsertNewCustomer(true);
                                setHaveAccount(HaveAccountEnum.NO);
                                setInsertAccountInfo(false);
                            }}
                        >
                            <div className="row px-4 cursor-pointer clickable-element">
                                <div>
                                    <FontAwesomeIcon icon={faPlus} className="mr-3" />
                                </div>
                                <div>
                                    <p className="m-0">
                                        {customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE ||
                                        customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODP
                                            ? t('customer-request.steps.new-customer-choice.order-as-new-customer')
                                            : customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_US
                                            ? t('customer-request.steps.new-customer-choice.title')
                                            : t('customer-request.steps.new-customer-choice.add-new-customer')}
                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name="businessPartnerId" />
                    </div>
                )}
                {areBusinessPartnersPresented && (
                    <div className="my-4 text-center">
                        <ClickableElement
                            isText
                            onClick={() => {
                                setValue('businessPartnerId', undefined);
                                setInsertNewCustomer(false);
                                setInsertAccountInfo(true);
                                setHaveAccount(HaveAccountEnum.YES);
                                unregister('businessPartnerId');
                            }}
                            className="text-decoration-underline-inverse"
                        >
                            <Trans i18nKey="customer-request.steps.new-customer-choice.can-not-see-my-account" />
                        </ClickableElement>
                    </div>
                )}

                {!areBusinessPartnersPresented && (
                    <>
                        <p>{t('customer-request.steps.new-customer-choice.are-you-spp-customer')}</p>

                        <BaseSwitch
                            selectedValue={haveAccount}
                            onValueSelect={(value) => {
                                setHaveAccount(value as HaveAccountEnum);
                                setInsertNewCustomer(value === HaveAccountEnum.YES ? false : true);
                            }}
                            className="my-4 mt-lg-0"
                            dataButtons={[
                                {
                                    id: HaveAccountEnum.YES,
                                    title: <Trans i18nKey="common.yes">Áno</Trans>,
                                },
                                {
                                    id: HaveAccountEnum.NO,
                                    title: <Trans i18nKey="common.no">Nie</Trans>,
                                },
                            ]}
                        />
                    </>
                )}
                {insertAccountInfo && haveAccount === HaveAccountEnum.YES && (
                    <CustomerAccountInfoForm
                        register={register}
                        trigger={trigger}
                        errors={errors}
                        onCancel={() => setInsertAccountInfo(false)}
                        showCancelButton={areBusinessPartnersPresented ? true : false}
                        objectName="accountInfo."
                        onSwitchChange={setSelectedType}
                        activeType={selectedType}
                        customerRequestTemplate={customerRequestTemplate}
                    />
                )}
                {!insertNewCustomer && (
                    <BaseButton type="submit">
                        <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                    </BaseButton>
                )}
                {insertNewCustomer && (
                    <>
                        {areBusinessPartnersPresented && (
                            <div
                                className="cursor-pointer clickable-element mb-2"
                                style={{ textAlign: 'center', textDecoration: 'underline' }}
                                onClick={() => {
                                    setInsertNewCustomer(false);
                                }}
                            >
                                {t('customer-request.steps.new-customer-choice.choose-from-list')}
                            </div>
                        )}
                        <h3
                            className={`mt-4 ${!isErrorInFirstBlock() && isFirstCollapseVerified ? 'cursor-pointer' : ''}`}
                            onClick={() => !isErrorInFirstBlock() && isFirstCollapseVerified && openFirstCollapse(!isFirstExpanded)}
                        >
                            {isFirstCollapseVerified && <i className="icon-circle-ok-16 mr-3"></i>}
                            {t('customer-request.steps.new-customer-choice.title')}{' '}
                            <i
                                className={`la-xs text-secondary float-right mr-2 ml-5 my-auto smooth-transition ${
                                    isFirstExpanded ? 'icon-chevron-down-16' : 'icon-chevron-left-16'
                                }`}
                            />
                        </h3>
                        <Collapse key={`first`} isOpen={isFirstExpanded}>
                            <p className="mb-2">{t('customer-request.steps.businessPartner.set-partner')}</p>
                            <div className="customer-request-new-customer-fields">
                                <BusinessPartnerForm
                                    opNumberRequired={false}
                                    register={register}
                                    errors={errors}
                                    onSwitchChange={setSelectedType}
                                    activeType={selectedType}
                                    watch={watch}
                                    getValues={getValues}
                                    setValue={setValue}
                                    trigger={trigger}
                                    min18years={false}
                                    requiredIdCardNumber={false}
                                    hideBpNumber
                                    hideIdCardNumber
                                    customerRequestTemplate={customerRequestTemplate}
                                />
                            </div>
                            <BaseButton onClick={() => handleFirstButton(false)} type="button">
                                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                            </BaseButton>
                        </Collapse>
                        <hr className="mb-2" />
                        <h3
                            className={`my-4 ${!isErrorInFirstBlock() && isFirstCollapseVerified ? 'cursor-pointer' : ''}`}
                            onClick={() => !isErrorInFirstBlock() && isFirstCollapseVerified && openSecondCollapse(!isSecondExpanded)}
                        >
                            {isSecondCollapseVerified && <i className="icon-circle-ok-16 mr-3"></i>}
                            {selectedType === BusinessPartnerTypeEnum.PERSON
                                ? t('customer-request.steps.new-customer-address.title')
                                : t('customer-request.steps.new-customer-address.title-company')}{' '}
                            <i
                                className={`la-xs text-secondary float-right mr-2 my-auto smooth-transition ${
                                    isSecondExpanded ? 'icon-chevron-down-16' : 'icon-chevron-left-16'
                                }`}
                            />
                        </h3>
                        <Collapse key={`second`} isOpen={isSecondExpanded}>
                            <div className="customer-request-new-customer-fields">
                                <AddressFields register={register} errors={errors} watch={watch} setValue={setValue} trigger={trigger} />
                            </div>

                            <BaseButton onClick={() => handleSecondButton()} type="button">
                                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                            </BaseButton>
                        </Collapse>
                        <hr className="mb-2" />
                        <h3
                            className={`mt-4 ${
                                !isErrorInFirstBlock() && !isErrorInSecondBlock() && isFirstCollapseVerified && isSecondCollapseVerified
                                    ? 'cursor-pointer'
                                    : ''
                            }`}
                            onClick={() => {
                                !isErrorInFirstBlock() &&
                                    !isErrorInSecondBlock() &&
                                    isFirstCollapseVerified &&
                                    isSecondCollapseVerified &&
                                    openFourthCollapse(!isFourthExpanded);
                                isFirstCollapseVerified && isSecondCollapseVerified && handleFourthBlock();
                            }}
                        >
                            {isFourthCollapseVerified && <i className="icon-circle-ok-16 mr-3"></i>}
                            {t('customer-request.steps.contact.title')}{' '}
                            <i
                                className={`la-xs text-secondary float-right mr-2 ml-5 my-auto smooth-transition ${
                                    isFourthExpanded ? 'icon-chevron-down-16' : 'icon-chevron-left-16'
                                }`}
                            />
                        </h3>
                        <Collapse key={`fourth`} isOpen={isFourthExpanded}>
                            <p className="mb-2">{t('customer-request.steps.contact.subtitle')} </p>
                            <div className="customer-request-new-customer-fields">
                                <ContactFormFields register={register} errors={errors} trigger={trigger} objectName="contact." />
                            </div>

                            <BaseButton type="submit">
                                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                            </BaseButton>
                        </Collapse>
                        {/* everything verified and closed(collapsed) */}
                        {Object.keys(errors).length === 0 &&
                            isFirstCollapseVerified &&
                            isSecondCollapseVerified &&
                            isFourthCollapseVerified &&
                            !isFirstExpanded &&
                            !isSecondExpanded &&
                            !isFourthExpanded && (
                                <>
                                    <hr className="mb-2" />
                                    <BaseButton type="submit">
                                        <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                                    </BaseButton>
                                </>
                            )}
                    </>
                )}
            </form>
        </>
    );
};

export default BlockBusinessPartnerExtended;
