import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';

interface IBulkActivateSuccessProps {
    onConfirm: () => void;
}

const BulkActivateSuccess: React.FC<IBulkActivateSuccessProps> = ({ onConfirm }) => {
    return (
        <>
            <div className="text-center">
                <i className="icon-circle-ok-16 fa-5x text-success d-block mb-3"></i>
                <h4 className="text-success mb-5">
                    <Trans i18nKey="settings.e-invoices.bulk-activation.bulk-e-invoice-activated" />
                </h4>
                <p>
                    <Trans i18nKey="settings.e-invoices.bulk-activation.thank-you-for-respect-for-environment" />
                </p>

                <Button type="button" color="primary" size="lg" block className="mt-5" onClick={onConfirm}>
                    <Trans i18nKey="common.ok" />
                </Button>
            </div>
        </>
    );
};

export default BulkActivateSuccess;
