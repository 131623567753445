import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery as CustomerRequestSearchQuery,
    SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ClickableElement, Input } from '../../../components/common';
import AutoComplete from '../../../components/common/autoComplete/autoComplete';
import BaseSelect from '../../../components/common/base-select';
import DatePicker from '../../../components/common/datePicker/datePicker';
import DatePickerIntervalContainer from '../../../components/common/datePicker/datepicker-interval-container';
import { useFormRules } from '../../../hooks/use-form-rules';
import { CommodityEnum, CustomerRequestStatusFilterEnum } from '../../../models/enums';
import { RequestTemplateCode } from '../../../models/model';
import { clearObjectProperties, formatAddressShort, isObjectEmpty } from '../../../utils/utils';
import { CustomerRequestTypeEnum } from '../config/enums';

type Props = {
    extra?: boolean;
    currentFilter: Partial<CustomerRequestSearchQuery>;
    onSubmit: (newFilter: Partial<CustomerRequestSearchQuery>) => void;
    deliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[];
    statusCodeList: CodeListItem[];
};
export type RequestTypeOptionsType = {
    name: string;
    value: CustomerRequestTypeEnum;
};

export type RequestStatusOptionsType = {
    name: string;
    value: CustomerRequestStatusFilterEnum;
};

const CustomerRequestFilterForm: React.FC<Props> = ({ currentFilter, onSubmit, extra = false, deliveryPoints, statusCodeList }) => {
    const [types, setTypes] = useState<CustomerRequestTypeEnum[]>((currentFilter.codes as CustomerRequestTypeEnum[]) ?? []);
    const [statuses, setStatuses] = useState<CustomerRequestStatusFilterEnum[]>((currentFilter.statuses as CustomerRequestStatusFilterEnum[]) ?? []);

    const [t] = useTranslation();

    const { register, handleSubmit, getValues, setValue, errors, trigger, watch } = useForm({
        defaultValues: currentFilter,
    });
    const typeOptions = useMemo(() => {
        return Object.values(CustomerRequestTypeEnum)
            .filter((item) => item !== CustomerRequestTypeEnum.ZOM_ZODE_ZODP)
            .map((item) => {
                return {
                    name: t(`enums.CustomerRequestType.${item}`),
                    value: item,
                };
            })
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    }, [t]);

    const statusOptions = useMemo(() => {
        let sent = false;
        let canceled = false;
        const result = statusCodeList
            ?.map((item) => {
                if (
                    item.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
                    item.code === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
                    item.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
                ) {
                    if (canceled) {
                        return { name: '', value: '' as any };
                    } else {
                        canceled = true;
                        return {
                            name: t('customer-request.filter.status-option-canceled'),
                            value: item?.code as CustomerRequestStatusFilterEnum,
                        };
                    }
                }
                if (
                    item.code === CustomerRequestStatusFilterEnum.CREATED ||
                    item.code === CustomerRequestStatusFilterEnum.REGISTERED ||
                    item.code === CustomerRequestStatusFilterEnum.SAP_OPEN
                ) {
                    if (sent) {
                        return { name: '', value: '' as any };
                    } else {
                        sent = true;
                        return {
                            name: t('customer-request.filter.status-option-sent'),
                            value: item?.code as CustomerRequestStatusFilterEnum,
                        };
                    }
                }
                return {
                    name: item?.name ?? '',
                    value: item?.code as CustomerRequestStatusFilterEnum,
                };
            })
            .filter((item) => item.value !== '');

        const orderedResult: RequestStatusOptionsType[] = [];
        result.forEach((item) => orderedResult.push(item));
        result.forEach((item) => {
            if (item.value === CustomerRequestStatusFilterEnum.PRE_CREATED) {
                orderedResult.splice(0, 1, item);
            }
            if (
                item.value === CustomerRequestStatusFilterEnum.CREATED ||
                item.value === CustomerRequestStatusFilterEnum.REGISTERED ||
                item.value === CustomerRequestStatusFilterEnum.SAP_OPEN
            ) {
                orderedResult.splice(1, 1, item);
            }
            if (item.value === CustomerRequestStatusFilterEnum.SAP_IN_PROGRESS) {
                orderedResult.splice(2, 1, item);
            }
            if (item.value === CustomerRequestStatusFilterEnum.SAP_FINISHED) {
                orderedResult.splice(3, 1, item);
            }
            if (item.value === 'GENERATED') {
                orderedResult.splice(4, 1, item);
            }
            if (
                item.value === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
                item.value === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
                item.value === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
            ) {
                orderedResult.splice(5, 1, item);
            }
        });
        return orderedResult;
    }, [statusCodeList, t]);

    const generateOptions = (data: string[] | undefined, enumName: string) => {
        if (!data) return [];
        return data.map((item) => {
            return {
                name: t(`enums.${enumName}.${item}`),
                value: item,
            };
        });
    };

    const generateStatusOptions = useMemo(() => {
        if (!statuses) return [];
        return (
            statuses.map((item) => {
                let name = statusCodeList?.find((i) => i.code === item)?.name ?? '';
                if (
                    item === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
                    item === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
                    item === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
                ) {
                    name = t('customer-request.filter.status-option-canceled');
                }

                if (
                    item === CustomerRequestStatusFilterEnum.CREATED ||
                    item === CustomerRequestStatusFilterEnum.REGISTERED ||
                    item === CustomerRequestStatusFilterEnum.SAP_OPEN
                ) {
                    name = t('customer-request.filter.status-option-sent');
                }
                return {
                    name: name,
                    value: item,
                };
            }) ?? []
        );
    }, [statusCodeList, statuses, t]);

    const typeChange = (options: RequestTypeOptionsType[]) => {
        setTypes(options.map((option) => option.value));
    };

    const statusChange = (options: RequestStatusOptionsType[]) => {
        setStatuses(options.map((option) => option.value) as CustomerRequestStatusFilterEnum[]);
    };

    const onFormSubmit = handleSubmit((data: CustomerRequestSearchQuery) => {
        const createdAt = isObjectEmpty(clearObjectProperties(data.createdAt ?? {})) ? undefined : data.createdAt;
        const codes = types.length ? (types as RequestTemplateCode[]) : undefined;
        const status = statuses.length ? statuses : undefined;
        const filter = { ...data, createdAt, statuses: status, codes };
        onSubmit(clearObjectProperties(filter));
    });

    const resetFilter = () => {
        onSubmit({});
    };

    const { dateRules } = useFormRules();

    useEffect(() => {
        trigger('createdAt.to');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('createdAt.from')]);

    return (
        <form onSubmit={onFormSubmit} noValidate>
            {extra && (
                <>
                    <BaseSelect
                        ref={register({})}
                        autoFocus
                        name="unitedDeliveryPointUuid"
                        label={t('customer-request.filter.delivery-point')}
                        className="form-control-filter"
                    >
                        <option value="">{t('customer-request.filter.delivery-point-default')}</option>
                        {deliveryPoints?.map((item) => (
                            <option key={item.id} value={item.id}>
                                {`${formatAddressShort(item.address)} (${item.businessPartner?.externalId || '-'})`}
                                {/* {`${item?.address?.street ?? ''} ${item.address?.streetNumber}`} */}
                            </option>
                        ))}
                    </BaseSelect>
                    <BaseSelect ref={register({})} name="product" label={t('customer-request.filter.product')} className="form-control-filter">
                        <option value="">{t('customer-request.filter.product-default')}</option>
                        {Object.entries(CommodityEnum).map((item, index) => (
                            <option key={index} value={item[1]}>
                                {t(`enums.CommodityEnum.${item[1]}`)}
                            </option>
                        ))}
                    </BaseSelect>
                </>
            )}

            <div className="mt-3">
                <AutoComplete<RequestTypeOptionsType>
                    options={typeOptions}
                    placeholder={t('customer-request.filter.request-type')}
                    onSelect={typeChange}
                    multiple
                    style={{ zIndex: 101, position: 'relative' }}
                    defaultSelected={generateOptions(types, 'CustomerRequestType') as RequestTypeOptionsType[]}
                    inFilter
                />
            </div>

            <div className="mt-3 ">
                <AutoComplete<RequestStatusOptionsType>
                    options={statusOptions ?? []}
                    placeholder={t('customer-request.filter.request-status')}
                    onSelect={statusChange}
                    multiple
                    defaultSelected={generateStatusOptions}
                    inFilter
                />
            </div>

            <div className="mt-3 mb-3">
                <Input
                    ref={register()}
                    errors={errors}
                    className="form-control-filter"
                    type="text"
                    name="externalId"
                    label={t('customer-request.filter.request-number')}
                    trigger={trigger}
                />
            </div>

            <p className="font-weight-bold">
                <Trans i18nKey="customer-request.filter.display-period">Zobrazované obdobie</Trans>
            </p>

            <DatePickerIntervalContainer>
                <DatePicker
                    register={register(dateRules().isValidDate)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    trigger={trigger}
                    label={t('customer-request.filter.datepicker.from')}
                    name="createdAt.from"
                    position="L"
                    showInputWhileError={false}
                    border={false}
                />
                <DatePicker
                    register={register({
                        ...dateRules('NOT_OLDER_THAN', getValues('createdAt.from') ? getValues('createdAt.from') : undefined).isValidDate,
                    })}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    trigger={trigger}
                    label={t('customer-request.filter.datepicker.to')}
                    name="createdAt.to"
                    position="R"
                    includeDay
                    showInputWhileError={false}
                    border={false}
                />
            </DatePickerIntervalContainer>

            {/* <a type="button" className="text-decoration-none d-block text-center my-5" onClick={resetFilter} href="#/"> */}
            <ClickableElement className="d-block text-center my-5" onClick={resetFilter}>
                <FontAwesomeIcon icon={faTrashAlt} size="lg" /> <Trans i18nKey="common.tables.remove-filter" />
            </ClickableElement>

            <Button type="submit" color="primary" size="lg" block>
                <Trans i18nKey="customer-request.filter.apply" />
            </Button>
        </form>
    );
};

export default CustomerRequestFilterForm;
