import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { ListGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { IComplainedAdvance } from '../../../../../../models/customer-request-model';
import { DefaultFormType } from '../../../../../../models/model';
import ComplainedAdvancesItem from './complained-advances-item';

type Props = DefaultFormType & {
    data: IComplainedAdvanceData[];
    addField: (item: IComplainedAdvanceData) => void;
    removeField: (uuid: string) => void;
    codeList: CodeListItem[];
};

export interface IComplainedAdvanceData extends IComplainedAdvance {
    uuid: string;
}

const ComplainedAdvancesFormFields: React.FC<Props> = ({ register, errors, getValues, setValue, data, addField, removeField, trigger, codeList }) => {
    const addAdvances = () => {
        addField({ uuid: uuidv4(), amount: 0, vs: '', date: new Date().toString(), paymentType: codeList[0] });
    };
    return (
        <ListGroup>
            {data?.map((item, index) => (
                <ComplainedAdvancesItem
                    index={index}
                    codeList={codeList}
                    key={item?.uuid}
                    item={item}
                    getValues={getValues}
                    setValue={setValue}
                    onDelete={removeField}
                    register={register}
                    errors={errors}
                    trigger={trigger}
                />
            ))}

            <div onClick={addAdvances}>
                <div role="button" className="text-decoration-none d-flex justify-content-between align-items-center mb-4">
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    <span className="mr-auto">
                        <Trans i18nKey="customer-request.steps.reason-for-claim-detail.claim-another-payment" />
                    </span>
                </div>
            </div>
        </ListGroup>
    );
};

export default ComplainedAdvancesFormFields;
