import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { can } from '../../components/permissions';
import { IRootState } from '../../reducers';

const InsufficientPrivileges = (): ReactElement => {
    const user = useSelector((state: IRootState) => state.user);
    return (
        <>
            {/* Page Header */}
            <div className="page-header py-4">
                <div className="container">
                    <div className="d-flex justify-content-between my-4">
                        <h2 className="mb-0">
                            <Trans i18nKey="generic-errors.insufficient-privileges.title">Prístup k neoprávnenému zdroju</Trans>
                        </h2>
                    </div>
                </div>
            </div>
            {/* / Page Header */}

            <div className="container" style={{ position: 'relative' }}>
                <div className="alert p-4 d-flex bg-danger text-white mt-5">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                    <div className="ml-4 font-weight-bold">
                        {can(['PORTAL_ACCESS'], user.employee) ? (
                            <Trans i18nKey="generic-errors.no-roles.content">
                                Nemáte pridelené žiadne role. Požiadajte prosím NZP administrátora o pridelenie práv.
                            </Trans>
                        ) : (
                            <Trans i18nKey="generic-errors.insufficient-privileges.content">Je nám ľúto ale nemáte prístup k príslušnej URL.</Trans>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InsufficientPrivileges;
