import React from 'react';
import { Trans } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';

type Props = DefaultFormType;

const ElectricityConsumptionV2FormFields: React.FC<Props> = ({ register, errors, trigger }) => {
    const { formRules } = useFormRules();
    return (
        <>
            <InputWithAppend
                trigger={trigger}
                ref={register({ ...formRules.requiredInteger, ...formRules.min(0) })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                type="number"
                name="valueHigh"
                label={<Trans i18nKey="customer-request.steps.electricity-consumption.expected-consumption-vt" />}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        kWh
                    </button>
                }
            />
            <InputWithAppend
                trigger={trigger}
                ref={register({ ...formRules.requiredInteger, ...formRules.min(0) })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                type="number"
                componentClassName="mb-1"
                name="valueLow"
                label={<Trans i18nKey="customer-request.steps.electricity-consumption.expected-consumption-nt" />}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        kWh
                    </button>
                }
            />
        </>
    );
};

export default ElectricityConsumptionV2FormFields;
