import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { BaseButton } from '../../../../../components/common';
import { IMeterReadingWithDpId } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { clearObjectProperties } from '../../../../../utils/utils';
import SingleMeterReading from './components/single-meter-rading';

type MeterValues = {
    value: number;
    valueHigh: number;
    valueLow: number;
};

type MeterReading = {
    [key: string]: MeterValues;
};

const BlockMeterReadingTranscript: React.FC = () => {
    const dispatch = useDispatch();
    const { content } = useSelector((state: IRootState) => state.customerRequest);

    const { register, errors, handleSubmit, trigger } = useForm<MeterReading>({
        defaultValues: content.meterReadings?.reduce((o, key) => ({ ...o, [key.deliveryPointId]: key }), {}),
    });

    const onSubmit = (data: MeterReading) => {
        const meterReadings: IMeterReadingWithDpId[] = [];

        Object.entries((data as Record<string, MeterValues>) ?? []).forEach(([dpId, values]) => {
            meterReadings.push({
                deliveryPointId: dpId,
                ...clearObjectProperties({
                    value: values.value,
                    valueLow: values.valueLow,
                    valueHigh: values.valueHigh,
                }),
            });
        });

        dispatch(CustomerRequestActions.setData({ meterReadings }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <>
                <div className="p-2 rounded my-4" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                    <p className="mb-2 text-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </p>
                    <p className="px-3 m-0 text-center">
                        <Trans i18nKey="customer-request.steps.meter-reading-transcript.info" />
                    </p>
                </div>
                {[...(content.deliveryPointsEE ?? []), ...(content.deliveryPointsZP ?? [])]
                    ?.filter(
                        (dp) =>
                            (content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.find((dpEE) => dpEE.id === dp.id) ||
                            (content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.find((dpZP) => dpZP.id === dp.id),
                    )
                    .map((dp, index) => (
                        <SingleMeterReading key={dp.id} deliveryPoint={dp} index={index} register={register} trigger={trigger} errors={errors} />
                    ))}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </>
        </form>
    );
};

export default BlockMeterReadingTranscript;
