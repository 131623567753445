import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequest,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { BaseTextarea, CustomerRequestTypeEnum, LoadingIndicator, RequestTemplateCode, useApi, useFormRules } from '../../../../../main';
import { IAttachments } from '../../../../../models/customer-request-model';
import { ChangeFormStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import AddressDefaultFields from '../../../../customer-request/screen/steps-customer-request/components/address-default-fields';
import { useSendRequest } from '../../customer-requests/use-send-request';
import ChangeRequestSent from '../change-request-sent';
import Attachments from './component/block-attachments';

interface IChangeDeliveryPointAddressModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
}

interface IPersonalDataForm {
    street?: string;
    number?: string;
    city?: string;
    postalCode?: string;
    floor?: string;
    flatNumber?: string;
    message?: string;
}

const NOTE_MAX_COUNT = 510;

export const ChangeDeliveryPointAddressModal: React.FC<IChangeDeliveryPointAddressModalProps> = ({ isOpen, onClose, deliveryPoint }) => {
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [loading, setLoading] = useState<boolean>(false);
    const { requiredTrimed, formRules } = useFormRules();
    const api = useApi();
    const [template, setTemplate] = useState<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate>();

    const dispatch = useDispatch();
    const [files, setFiles] = useState<IAttachments[]>([]);
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const [canSubmit, setCanSubmit] = useState(false);

    const initializeRequestCreationProcess = useSendRequest();

    useEffect(() => {
        if (isOpen) {
            setStep(ChangeFormStepEnum.EDITING);
            setFiles([]);
        }
    }, [isOpen, deliveryPoint.id, deliveryPoint.contract?.id, dispatch]);

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const formData: IPersonalDataForm = {
        ...deliveryPoint.address,
        message: '',
        postalCode: deliveryPoint.address?.zipCode,
        number: deliveryPoint.address?.streetNumber,
    };

    const { register, handleSubmit, errors, trigger, watch, setValue } = useForm<IPersonalDataForm>({
        defaultValues: formData,
    });

    const message: string | undefined = watch('message');
    const street: string | undefined = watch('street');
    const number: string | undefined = watch('number');
    const city: string | undefined = watch('city');
    const postalCode: string | undefined = watch('postalCode');
    const floor: string | undefined = watch('floor');
    const flatNumber: string | undefined = watch('flatNumber');

    const { t } = useTranslation();
    useEffect(() => {
        setMessageCounter(message?.length ?? 0);
    }, [message]);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    useEffect(() => {
        getRequestTemplates(
            { code: CustomerRequestTypeEnum.ZOM_ZAOM },
            {
                onSuccess: (data) => {
                    if (data && data[0] != null) {
                        const foundTemplate = data[0];
                        setTemplate(foundTemplate);
                    } else {
                        onClose();
                    }
                },
                onError: () => {
                    onClose();
                },
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            deliveryPoint.address?.street !== street ||
            deliveryPoint.address?.streetNumber !== number ||
            deliveryPoint.address?.city !== city ||
            deliveryPoint.address?.zipCode !== postalCode ||
            floor ||
            flatNumber ||
            files.length > 0 ||
            message
        ) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [street, number, city, postalCode, floor, flatNumber, deliveryPoint, files, message]);

    const onFormSubmit = (formData: IPersonalDataForm) => {
        if (template) {
            const { street, number, city, postalCode, message, floor, flatNumber } = formData;

            const content = {
                newDeliveryPointAddress: {
                    city,
                    postalCode,
                    number,
                    flatNumber,
                    floor,
                    street,
                },
                deliveryPoint: { id: deliveryPoint.id },
                entityReferences: [
                    {
                        contractId: deliveryPoint?.contract?.id,
                        deliveryPointId: deliveryPoint.id,
                        contractAccountId: deliveryPoint?.contract?.contractAccount?.id,
                    },
                ],
                note: message,
            };

            const metadata = { businessPartnerId: deliveryPoint?.contract?.contractAccount?.businessPartner?.id, deliveryPointId: deliveryPoint.id };

            const data: SkSppNzpBeApiCustomerrequestCustomerRequest = {
                content: { metadata, ...content, type: CustomerRequestTypeEnum.ZOM_ZAOM },
                customerRequestTemplate: template,
            };
            setLoading(true);
            initializeRequestCreationProcess(data, files, setFiles)
                .then(() => goToNextStep())
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.edit-dp-address">
                            Zmena adresy odberného miesta
                        </Trans>
                    </h3>
                    {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onClose} />}
                    {step === ChangeFormStepEnum.EDITING && (
                        <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                            <h6 className="mb-3 mt-4">
                                <Trans i18nKey="customer-request.steps.new-delivery-point.title">Nová adresa odberného miesta</Trans>
                            </h6>
                            <AddressDefaultFields
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                                trigger={trigger}
                                disableCountry
                            />
                            <h6 className="mb-3 mt-4">{t('customer-request.steps.reason.title')}</h6>
                            <BaseTextarea
                                ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT), ...requiredTrimed })}
                                errors={errors}
                                name={'message'}
                                maxLength={NOTE_MAX_COUNT}
                                label={t('customer-request.steps.new-delivery-point.reason-for-change')}
                                counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                                bottomLabel={t('customer-request.steps.attachments.description-placeholder')}
                            />
                            <Attachments setFiles={setFiles} files={files} label={t('customer-request.steps.attachments.attachments-title')} />
                            <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!canSubmit}>
                                <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.change-info">Zmeniť údaje</Trans>
                            </Button>
                        </form>
                    )}
                    {loading && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
