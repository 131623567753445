import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import { useApi } from '../../../../../hooks/use-api';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IAddress, IAttachments, IBusinessPartner, IContact } from '../../../../../models/customer-request-model';
import { BusinessPartnerTypeEnum } from '../../../../../models/enums';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties, formatPhoneNumber } from '../../../../../utils/utils';
import Attachments from '../../../../delivery-points/detail/data/contact-data-modals/component/block-attachments';
import { CustomerRequestDataEnum } from '../../../config/enums';
import AddressFields from '../block-address/components/address-fields';
import BusinessPartnerFields from '../block-business-partner/components/business-partner-fields';
import ContactFormFields from '../block-contact/components/contact-form-fields';
import StepsBlockHeader from '../components/steps-block-header';

type OwnerObjectFormType = {
    ownerDifferent: string;
    businessPartner: IBusinessPartner;
    address: IAddress;
    correspondenceAddress: IAddress;
    contact: IContact;
};

const BlockOwnerData: React.FC = () => {
    const api = useApi();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currentBlock, content, attachments } = useSelector((state: IRootState) => state.customerRequest);
    const [files, setFiles] = useState<IAttachments[]>(attachments ?? []);
    const { formRules } = useFormRules();

    const customerName = currentBlock?.dataKeys?.length ? currentBlock.dataKeys[0] : CustomerRequestDataEnum.OWNER;
    const addressName = currentBlock?.dataKeys?.length ? currentBlock.dataKeys[1] : CustomerRequestDataEnum.OWNER_ADDRESS;
    const correspondenceAddressName = currentBlock?.dataKeys?.length
        ? currentBlock.dataKeys[2]
        : CustomerRequestDataEnum.OWNER_CORRESPONDANCE_ADDRESS;
    const contactName = currentBlock?.dataKeys?.length ? currentBlock.dataKeys[3] : CustomerRequestDataEnum.OWNER_CONTACT;

    const customerData = content[customerName] as IBusinessPartner;
    const addressData = content[addressName] as IAddress;
    const correspondenceAddressData = content[correspondenceAddressName] as IAddress;
    const contactData = content[contactName] as IContact;

    const opNumberRequired = currentBlock?.params?.opNumberRequired ?? false;

    const [selectedType, setSelectedType] = useState<BusinessPartnerTypeEnum>(customerData?.type ?? BusinessPartnerTypeEnum.PERSON);

    const { register, errors, setError, handleSubmit, watch, setValue, trigger, getValues, clearErrors } = useForm<OwnerObjectFormType>({
        defaultValues: {
            businessPartner: { ...customerData },
            address: { ...addressData },
            correspondenceAddress: { ...correspondenceAddressData },
            contact: { ...contactData },
            ownerDifferent: !!customerData?.type ? 'true' : 'false',
        },
    });
    const ownerDifferent = watch('ownerDifferent');

    const [mutatePhoneValidate] = useMutationWithError(async (phone: string) => api.validations.checkPasswordComplexityWithPhone({ phone }), {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error) {
                setError('contact.phoneNumber', {
                    message: t('common.input-rules.phoneNumber'),
                });
                return true;
            }
            return false;
        },
    });

    const onSubmit = (data: OwnerObjectFormType) => {
        const contact = data?.contact && clearObjectProperties(data.contact);

        // do validation of the phone number (contact.phoneNumber)
        if (contact?.phoneNumber) {
            contact.phoneNumber = formatPhoneNumber(contact.phoneNumber);
            mutatePhoneValidate(contact.phoneNumber).then((result) => {
                if (!!result) {
                    data.contact.phoneNumber = contact.phoneNumber || '';
                    onSubmitContinue(data);
                }
            });
        } else {
            onSubmitContinue(data);
        }
    };

    const onSubmitContinue = (data: OwnerObjectFormType) => {
        const contact = data?.contact && clearObjectProperties(data.contact);
        const address = data?.address && clearObjectProperties(data.address);
        const correspondenceAddress = data?.correspondenceAddress && clearObjectProperties(data.correspondenceAddress);
        const businessPartner = data?.businessPartner && clearObjectProperties(data.businessPartner);

        if (ownerDifferent === 'true' && files.filter((item) => item.info === 'owner').length === 0 && !content.saveRequested) {
            setError('files', { type: 'required', message: 'Povinný údaj' });
            return;
        }
        const payload: CustomerRequestPayloadType = {
            [customerName]:
                ownerDifferent === 'true'
                    ? {
                          type: selectedType,
                          ...businessPartner,
                      }
                    : undefined,
            [addressName]: address,
            [correspondenceAddressName]: correspondenceAddress,
            [contactName]: contact,
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setAttachments(ownerDifferent === 'true' ? files : files.filter((item) => item.info !== 'owner')));
        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Card className="mb-3">
                <CardBody className="py-2 px-4">
                    <BaseRadio
                        ref={register(formRules.required)}
                        value="false"
                        id="ownerDifferent_false"
                        name="ownerDifferent"
                        className="mt-0 py-2"
                        label={<Trans i18nKey="customer-request.steps.owner-object.owner-same" />}
                    />
                </CardBody>
            </Card>

            <Card className="mb-3">
                <CardBody className="py-2 px-4">
                    <BaseRadio
                        ref={register(formRules.required)}
                        value="true"
                        id="ownerDifferent_true"
                        name="ownerDifferent"
                        className="mt-0 py-2"
                        label={<Trans i18nKey="customer-request.steps.owner-object.owner-different" />}
                    />
                </CardBody>
            </Card>

            {ownerDifferent === 'true' && (
                <>
                    <StepsBlockHeader title={t(`customer-request.steps.businessPartner.title`)} />
                    <BusinessPartnerFields
                        opNumberRequired={opNumberRequired}
                        objectsName="businessPartner."
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                        onSwitchChange={setSelectedType}
                        activeType={selectedType as BusinessPartnerTypeEnum}
                        trigger={trigger}
                        min18years={false}
                        hideBpNumber
                        hideIdCardNumber
                        hideBirthDate
                    />
                    <StepsBlockHeader title={t(`customer-request.steps.address.title`)} />
                    <AddressFields register={register} errors={errors} watch={watch} setValue={setValue} trigger={trigger} />
                    <StepsBlockHeader title={t(`customer-request.steps.contact.title`)} />
                    <ContactFormFields register={register} errors={errors} objectName={`contact.`} trigger={trigger} />
                    <div className="mt-5">
                        <Attachments
                            files={files}
                            setFiles={setFiles}
                            label={t('customer-request.steps.owner-object.attachment-title')}
                            errors={errors}
                            clearErrors={clearErrors}
                            info="owner"
                        />
                    </div>
                </>
            )}
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockOwnerData;
