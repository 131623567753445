import {
    SkSppNzpBeApiCustomeraccessCustomerApproval,
    SkSppNzpBeApiCustomeraccessCustomerApprovalBase,
    SkSppNzpBeApiCustomerprofileAddress,
    SkSppNzpBeApiCustomerprofileBusinessPartnerConsent,
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
    SkSppNzpBeApiIdentitymanagementCustomer,
    SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent,
} from '@spp/spp-meru-frontend-common';
import { SkSppNzpBeApiCommonSharing } from '@spp/spp-meru-frontend-common/src/api';
import { FieldValues, UseFormOptions } from 'react-hook-form';
import {
    AuthEnum,
    ConsumptionCategoryEnum,
    ConsumptionValueUnits,
    MARKETING_CONSENTS_LIMIT_IN_DAYS_FOR_EXPIRE_SOON,
    MARKETING_CONSENTS_REJECTED,
} from '../models/enums';
import { CustomerRequestAdditionalDataType, CustomerRequestPayloadType } from '../reducers/interfaces/customer-request-state';
import { CustomerRequestEnum, CustomerRequestTypeEnum } from '../views/customer-request/config/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withoutProperty = <T extends Record<string, any>>(object: T, propertyName: string): Partial<T> => {
    const newObject: Partial<T> = { ...object };
    delete newObject[propertyName];
    return newObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// if clearEmptyObjects is true, empty object properties are deleted ( property === {} )
export const clearObjectProperties = <T extends Record<string, any>>(object: T, clearEmptyObjects?: boolean): Partial<T> => {
    const newObject: Partial<T> = {};
    Object.keys(object).forEach((key) =>
        !!object[key] && (clearEmptyObjects ? Object.keys(object[key]).length !== 0 : true) ? (newObject[key as keyof T] = object[key]) : null,
    );
    return newObject;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const isObjectEmpty = (object: Record<string, any>): boolean => {
    return Object.keys(object).length === 0;
};

export const fileToBase64 = (file: File): Promise<string> =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const merge = <T>(changes: Partial<T>) => (previousState: T): T => ({ ...previousState, ...changes });

export const accessNestedProperty = (object: Record<string, any>, propertyNames: string[]): any => {
    return propertyNames.reduce<any>((acc, propertyName) => (acc instanceof Object ? acc[propertyName] : undefined), object);
};

export const createIndexedGroups = <T extends Record<string, any>>(
    array: T[],
    indexKeySelector: (root: T) => string | number | undefined,
): Record<string, T[]> => {
    return array.reduce<Record<string, T[]>>((acc, item) => {
        const indexKey = indexKeySelector(item);
        if (indexKey) {
            if (acc[indexKey] === undefined) {
                acc[indexKey] = [item];
            } else {
                acc[indexKey].push(item);
            }
        }
        return acc;
    }, {});
};

export const formatAddress = (address: SkSppNzpBeApiCustomerprofileAddress | undefined, zipCodeAndCityTogether?: boolean): string => {
    if (address == null) return '';
    return `${address.street}\u00a0${address.streetNumber}, ${address.zipCode}${zipCodeAndCityTogether ? '\u00a0' : ' '}${address.city}`;
};

export const formatAddressShort = (address: { street?: string | undefined; streetNumber?: string | undefined } | undefined): string => {
    if (address == null) return '';
    return `${address.street || ''} ${address.streetNumber || ''}`;
};

export const formatDate = (date: Date | undefined): string => {
    if (date == null) return '';
    return (
        date
            .toLocaleDateString('en-GB')
            // https://stackoverflow.com/questions/21413757/tolocaledatestring-changes-in-ie11#comment52437188_21413757
            // https://jira-server.isdd.sk/browse/SNAT-2764 comment
            .replace(/\u200E/g, '')
            .split('/')
            .join('.')
    );
};
export const formatDateMonthYearWithTime = (date: Date | undefined): string => {
    if (date == null) return '';
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const day = date.getDate();
    const month = date.getMonth() + 1; //months from 1-12
    const year = date.getFullYear();

    const newdate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;

    return newdate;
};

export const formatDateDayMonthYearTime = (date: Date | undefined): string => {
    if (date == null) return '';
    const hours = date.getHours();
    const day = date.getDate();
    const month = date.getMonth() + 1; //months from 1-12
    const year = date.getFullYear();

    if (hours === 0 && day === 1 && month === 1) {
        return `${year}`;
    }
    if (hours === 0 && day === 1) {
        return `${month < 10 ? '0' + month : month}/${year}`;
    }
    if (hours === 12) {
        return `${hours}:00`;
    }
    if (hours === 0) {
        return `${day}.${month}.`;
    }

    const newdate = `${day}.${month}.${year} ${hours}:00`;

    return newdate;
};

export const formatDateMonthYear = (date: Date | undefined): string => {
    if (date == null) return '';
    const month = date.getMonth() + 1; //months from 1-12
    const year = date.getFullYear();

    const newdate = `${month}/${year}`;

    return newdate;
};

export const formatDateYear = (date: Date | undefined): string => {
    if (date == null) return '';
    const year = date.getFullYear();

    const newdate = year.toString();

    return newdate;
};

export const clearCodeVerifierFromSessionStorage = (): void => {
    sessionStorage.removeItem(AuthEnum.CODE_VERIFIER_NAME);
};

export const formatBusinessPartnerName = (bp?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary): string => {
    if (bp == null) return '';
    if (bp.name != null) {
        return bp.name;
    }
    if (bp.firstName != null && bp.lastName != null) {
        return `${bp.firstName} ${bp.lastName}`;
    }
    return '';
};

export const formatBusinessPartner = (bp?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary): string => {
    return bp ? formatBusinessPartnerName(bp) + ' (' + bp.externalId + ')' : '';
};

export const formatSharingBy = (sharing?: SkSppNzpBeApiCommonSharing): string => {
    return sharing ? (sharing.by?.firstName || '') + ' ' + (sharing.by?.lastName || '') + ' (' + sharing.by?.email + ')' : '';
};

export const formatPhoneNumber = (phoneNumber: string): string => {
    let formatedPhone = phoneNumber.replace(/[^0-9+]/g, '');
    if (formatedPhone.startsWith('00')) formatedPhone = formatedPhone.replace('00', '+');
    if (formatedPhone.startsWith('09') || formatedPhone.startsWith('02')) formatedPhone = formatedPhone.replace('0', '+421');
    return formatedPhone;
};

export const formatSkPhoneNumber = (phoneNumber: string): string => {
    let formatedPhone = formatPhoneNumber(phoneNumber);
    if (formatedPhone.startsWith('+421')) {
        formatedPhone = formatedPhone.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
    }
    return formatedPhone;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const formOptions = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    options?: UseFormOptions<TFieldValues, TContext>,
): UseFormOptions<TFieldValues, TContext> => ({
    mode: 'onChange',
    ...options,
});

export const getAddressFromUnitedDeliveryPoint = (unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint): string | undefined => {
    const unitedDeliveryPointAddress =
        unitedDeliveryPoint.address?.street != null && unitedDeliveryPoint.address?.streetNumber != null
            ? { street: unitedDeliveryPoint.address?.street, streetNumber: unitedDeliveryPoint.address?.streetNumber }
            : undefined;
    return formatAddressShort(unitedDeliveryPointAddress);
};

type RefType<T> = ((instance: T | null) => void) | React.MutableRefObject<T | null> | null;
export const combineRefs = <T>(refs: RefType<T>[]) => {
    return (value: T | null): void => {
        refs.forEach((ref: RefType<T>) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

export type DownloadHandlerType = (data: Blob, filename: string, mime: string | undefined) => void;

const downloadFile: DownloadHandlerType = (data, filename, mime) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
        return;
    }
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
    }, 100);
};

export let downloadHandler: DownloadHandlerType = downloadFile;

// Handler for mobile app
export const setDownloadHandler = (handler: DownloadHandlerType): void => {
    downloadHandler = handler;
};

export function getUnits(units: ConsumptionValueUnits): string {
    return units === ConsumptionValueUnits.KWH ? 'kWh' : 'm³';
}

function isNewApprovalInOldApprovals(
    newApproval: SkSppNzpBeApiCustomeraccessCustomerApproval,
    oldApprovals: SkSppNzpBeApiCustomeraccessCustomerApproval[],
): boolean {
    let typeFound = false;
    oldApprovals.forEach((oldApproval) => {
        if (oldApproval.type === newApproval.type) {
            typeFound = true;
        }
    });
    return typeFound;
}

function modifyExistingApproval(
    newApproval: SkSppNzpBeApiCustomeraccessCustomerApproval,
    oldApprovals: SkSppNzpBeApiCustomeraccessCustomerApproval[],
) {
    oldApprovals.forEach((oldApproval) => {
        if (oldApproval.type === newApproval.type) {
            oldApproval.approval = newApproval.approval;
        }
    });
}

export function mergeApprovals(
    newApprovals: SkSppNzpBeApiCustomeraccessCustomerApprovalBase[],
    oldApprovals: SkSppNzpBeApiCustomeraccessCustomerApprovalBase[],
): SkSppNzpBeApiCustomeraccessCustomerApprovalBase[] {
    newApprovals.forEach((newApproval) =>
        isNewApprovalInOldApprovals(newApproval, oldApprovals) ? modifyExistingApproval(newApproval, oldApprovals) : oldApprovals.push(newApproval),
    );
    return oldApprovals;
}

export const validateIban = (value: string): boolean => {
    const trimmedValue = value.replace(/ /g, '');
    const rearrange = trimmedValue.substring(4, trimmedValue.length) + trimmedValue.substring(0, 4);
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const alphaMap: { [key: string]: number } = {};
    const number: number[] = [];

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });

    rearrange.split('').forEach((value, index) => {
        number[index] = alphaMap[value] || Number(value);
    });

    return modulo(number.join('').toString(), 97) === 1;
};

const modulo = (aNumStr: string, aDiv: number) => {
    let tmp = '';
    for (let i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        const r = Number(tmp) % aDiv;
        tmp = r.toString();
    }
    return Number(tmp) / 1;
};

export const getConsumptionCategory = (partner?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary): ConsumptionCategoryEnum | undefined => {
    if (partner?.queue === 'INDIVIDUAL') {
        return ConsumptionCategoryEnum.WHOLESALE;
    }
    if (!!partner?.firstName && !!partner?.lastName) {
        return ConsumptionCategoryEnum.HOME;
    }
    if (!!partner) {
        return ConsumptionCategoryEnum.RETAIL;
    }
    return undefined;
};

export const formatCurrency = (numericValue: number, isCredit?: boolean): string => {
    const formatted = (isCredit ? Math.abs(numericValue) : numericValue)
        .toLocaleString('sk', {
            style: 'currency',
            currency: 'EUR',
            ...(numericValue === Math.abs(numericValue) ? { maximumFractionDigits: 2, minimumFractionDigits: 2 } : {}),
        })
        .replace('EUR', '€');
    return (isCredit ? '+' : '') + formatted;
};

/**
 * return request type (fullScreen | modal | undefined )
 * @param requestTypeCode
 */
export const customerRequestType = (requestTypeCode?: CustomerRequestEnum): 'fullScreen' | 'modal' | undefined => {
    const fullScreenRequests = [
        CustomerRequestEnum.CHANGE_ELECTRICITY_RATE_SHORT,
        CustomerRequestEnum.CHANGE_GAS_TARIFF_SHORT,
        CustomerRequestEnum.COMPLAINT_SHORT,
        CustomerRequestEnum.CLAIM_INVOICE_SHORT,
        CustomerRequestEnum.REQUEST_OFFICIAL_TEST_METER_SHORT,
        CustomerRequestEnum.REQUEST_INVOICE_OUT_CYCLE_SHORT,
        CustomerRequestEnum.REPAYMENT_AGREEMENT_SHORT,
        CustomerRequestEnum.CONFIRMATION_AMOUNT_LIABILITIES_SHORT,
        CustomerRequestEnum.REQUEST_INTERRUPTION_AND_RESUMPTION_CONSUPTION_SHORT,
        CustomerRequestEnum.TRANSCRIPT_NEW,
        CustomerRequestEnum.TRANSCRIPT_NEW_CUSTOMER,
        CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW,
        CustomerRequestEnum.GAS_SUPPLY_NEW,
        CustomerRequestEnum.CLEAN_ELECTRICITY_SHORT,
        CustomerRequestEnum.CARBON_SHANK_SHORT,
        CustomerRequestEnum.CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT,
        CustomerRequestEnum.REQUEST_TERMINATION_CONTRACT_SHORT,
    ];
    if (requestTypeCode && fullScreenRequests.includes(requestTypeCode)) {
        return 'fullScreen';
    }
    return undefined;
};

/**
 * return boolean
 * @param requestTypeCode
 */
export const hideCustomerRequestBlockHeader = (requestTypeCode?: CustomerRequestEnum): boolean => {
    const requestWithoutHeader = [CustomerRequestEnum.CLAIM_INVOICE_SHORT];
    if (requestTypeCode && requestWithoutHeader.includes(requestTypeCode)) {
        return true;
    }
    return false;
};

export const deleteLeadingZeros = (value?: string): string | undefined => {
    if (value?.startsWith('00')) {
        return value.substr(2);
    }
    if (value?.startsWith('0')) {
        return value.substr(1);
    }
    return value;
};

export const isCustomerRequestPaid = (template: SkSppNzpBeApiCustomerrequestCustomerRequestTemplate, queue: 'INDIVIDUAL' | 'COLLECTIVE'): boolean => {
    if (template.priceCollective && queue === 'COLLECTIVE') {
        return true;
    }
    if (template.priceIndividual && queue === 'INDIVIDUAL') {
        return true;
    }
    return false;
};

export function formatNumber(value: number): string {
    return new Intl.NumberFormat('sk-SK', { maximumFractionDigits: 2 }).format(value);
}

export function normalizedUpperCaseText(value: string): string {
    return value
        .toUpperCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
}

//List of emails for invoice delivery
export function customerEmails(
    additionalData?: CustomerRequestAdditionalDataType,
    customer?: SkSppNzpBeApiIdentitymanagementCustomer,
    businessPartnerEmail?: string,
    accountInfoEmail?: string,
    contentEmail?: string,
): string[] {
    const emails: string[] = [];
    businessPartnerEmail && emails.push(businessPartnerEmail);
    accountInfoEmail && emails.push(accountInfoEmail);
    contentEmail && emails.push(contentEmail);
    additionalData?.udpd?.businessPartner?.email && emails.push(additionalData?.udpd?.businessPartner?.email);
    additionalData?.udpd?.deliveryPoints?.forEach((item) => {
        item.einvoice?.email && emails.push(item.einvoice?.email);
    });
    customer?.email && emails.push(customer?.email);
    return [...new Set(emails)];
}

//Is inserted email address from list of customerEmails
export function isInsertedEmailCustom(
    content: CustomerRequestPayloadType,
    additionalData?: CustomerRequestAdditionalDataType,
    customer?: SkSppNzpBeApiIdentitymanagementCustomer,
    businessPartnerEmail?: string,
    accountInfoEmail?: string,
    contentEmail?: string,
): boolean {
    if (
        content.invoiceDelivery?.email &&
        content.invoiceDelivery?.email !== businessPartnerEmail &&
        content.invoiceDelivery?.email !== customer?.email &&
        content.invoiceDelivery?.email !== accountInfoEmail &&
        content.invoiceDelivery?.email !== contentEmail &&
        additionalData?.udpd?.businessPartner?.email !== customer?.email &&
        !additionalData?.udpd?.deliveryPoints?.find((item) => item.einvoice?.email === content.invoiceDelivery?.email)
    ) {
        return true;
    }
    return false;
}

export function showRequestForNotLoggedInCustomer(requestType: CustomerRequestTypeEnum): boolean {
    const customerRequestCodesForNotLoggedInCustomer = [
        CustomerRequestTypeEnum.ZOM_ZODE,
        CustomerRequestTypeEnum.ZOM_ZODP,
        CustomerRequestTypeEnum.ZOP_US,
    ];
    if (customerRequestCodesForNotLoggedInCustomer.includes(requestType)) {
        return true;
    }
    return false;
}

export const latinCharactersRegex = /^[a-zA-Z0-9\s.,ÁÀÂÄǍĂĀÃÅǺĄĆĊĈČÇĎḌÉÈĖÊËĚĔĒĘẸƎĘáàâäǎăāãåąćçĉčďéèėêëěĕēęÍÌİÏǏƘĹĽŁNŃN̈ŇÑÓÒÔÖǑŎŌÕŐíìiîïǐĭĺłľŉńn̈ňñŋóòôöǒŏōõőọŔŘŖŚŜŠŤÚÙÛÜŮǓŬŪŨŰƯÝỲŶŸȲŹŻŽŕřśŝšşťúùûüǔŭūũűůưýỳŷÿȳỹźžżß§`´¸ˇ°–_\xc2\xa0.,*;'\/€\$\%!@#\^\&*()\-+<>\|?:\[\]"„“=]+$/;

export const formatIBAN = (iban?: string): string => {
    return iban ? iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ') : '';
};

export const replaceSpaceinString = (value?: string, newChar?: string): string => value?.replace(/ /g, newChar || '\u00a0') || '';

export const addressReplaceSpaceinZipCodeAndCity = (address?: SkSppNzpBeApiCustomerprofileAddress): SkSppNzpBeApiCustomerprofileAddress => ({
    ...address,
    zipCode: replaceSpaceinString(address?.zipCode),
    city: replaceSpaceinString(address?.city),
});

export const hasRequestOriginalValues = (requestType: SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent['type']): boolean => {
    return requestType === CustomerRequestTypeEnum.ZOM_ZAOM || requestType === CustomerRequestTypeEnum.ZOP_ZOUA;
};

const dateDifferenceInDays = (date?: Date): number => {
    if (!date) {
        return 0;
    }
    const currentDate = new Date();
    const timeDifference: number = date.getTime() - currentDate.getTime();
    const daysDifference: number = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
};

// Return false if marketing consents expire in more days than MARKETING_CONSENTS_LIMIT_IN_DAYS_FOR_EXPIRE_SOON, or return the minimum number of days until marketing consent expires.
export const expireMarketingConsentsSoon = (consents?: SkSppNzpBeApiCustomerprofileBusinessPartnerConsent[]): number | false => {
    const dateDiffEmail = expireMarketingConsentSoon(consents?.find((consent) => consent.consentCommunicationChannel === 'EMAIL'));
    const dateDiffPhone = expireMarketingConsentSoon(consents?.find((consent) => consent.consentCommunicationChannel === 'PHONE'));
    const dateDiffLetter = expireMarketingConsentSoon(consents?.find((consent) => consent.consentCommunicationChannel === 'POST'));
    const dateDiffSms = expireMarketingConsentSoon(consents?.find((consent) => consent.consentCommunicationChannel === 'SMS'));

    if (dateDiffEmail || dateDiffPhone || dateDiffLetter || dateDiffSms) {
        return Math.min(dateDiffEmail || 1000, dateDiffPhone || 1000, dateDiffLetter || 1000, dateDiffSms || 1000);
    }
    return false;
};

const expireMarketingConsentSoon = (consent?: SkSppNzpBeApiCustomerprofileBusinessPartnerConsent): number | false => {
    const dateDiff = (consent?.validTo && dateDifferenceInDays(new Date(consent?.validTo))) || 1000;
    if (dateDiff <= MARKETING_CONSENTS_LIMIT_IN_DAYS_FOR_EXPIRE_SOON) {
        return dateDiff;
    }
    return false;
};

export const atLeastOneMarketingConsentExpireSoon = (consents?: SkSppNzpBeApiCustomerprofileBusinessPartnerConsent[]): boolean => {
    return !!consents?.find((consent) => expireMarketingConsentSoon(consent));
};

export const eachMarketingConsentIsRejected = (consents?: SkSppNzpBeApiCustomerprofileBusinessPartnerConsent[]): boolean => {
    return !!consents?.every((consent) => consent.consent === MARKETING_CONSENTS_REJECTED);
};

export const eachRejectedMarketingConsentIsExpired = (consents?: SkSppNzpBeApiCustomerprofileBusinessPartnerConsent[]): boolean => {
    return consents
        ? consents?.filter((consent) => consent.consent === MARKETING_CONSENTS_REJECTED).every((consent) => expireMarketingConsentSoon(consent))
        : true;
};
