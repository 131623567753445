import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { LoadingIndicator } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import { ConsumptionCategoryEnum, useFormRules } from '../../../../../main';
import { ITariff } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { buildDeliveryPointQueryKey, QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties } from '../../../../../utils/utils';
import { EeRateRadioButton } from '../../../../delivery-points/detail/data/forms/tariffs-and-rates/ee-rate-radio';
import { CustomerRequestDataEnum } from '../../../config/enums';

export const BlockRateSelectionShort: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();

    const { currentBlock, content, metadata } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.TARIFF;
    const objectData = content[objectName] as ITariff | undefined;

    const { register, handleSubmit, errors, getValues, watch } = useForm<{ tariff: string | undefined }>({
        defaultValues: { tariff: objectData?.tariff.uuid },
    });

    const [consumptionCategory, setConsumptionCategory] = useState<ConsumptionCategoryEnum>();

    const { data: deliveryPointData, isLoading: isLoadingDeliveryPoint } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        buildDeliveryPointQueryKey(metadata.contractId),
        async () =>
            metadata.contractId != null
                ? api.deliveryPoints.getByContractUuid(metadata.contractId, {}, { secure: true }).then((res) => res.data)
                : null,
        {
            onSuccess: (data) => {
                dispatch(CustomerRequestActions.setDeliveryPointEe(data || undefined));
                if (data?.deliveryCategory != null) {
                    setConsumptionCategory(ConsumptionCategoryEnum[data.deliveryCategory]);
                } else {
                    setConsumptionCategory(ConsumptionCategoryEnum.HOME);
                }
            },
            onError: () => {
                dispatch(CustomerRequestActions.setDeliveryPoint(undefined));
                return false;
            },
        },
    );

    const eicBasedDistributionArea = useMemo(() => {
        const eic = deliveryPointData?.eic;
        if (eic == null) {
            return undefined;
        }
        const distributionArea = eic.substr(2, 3);
        return distributionArea;
    }, [deliveryPointData]);

    const { data: tariffRates, isLoading } = useQueryWithError<TariffRate[] | undefined>(
        [
            QueryKeysEnum.PRODUCT_TARIFF_RATE,
            {
                type: 'EE',
                status: 'ACTIVE',
                distributionArea: deliveryPointData?.distributionArea?.code || eicBasedDistributionArea,
                category: consumptionCategory,
                paging: { size: 5000 },
            },
        ],
        async () =>
            consumptionCategory != null
                ? api.productCatalogs
                      .getTariffsAndRates(
                          {
                              type: 'EE',
                              status: 'ACTIVE',
                              distributionArea: deliveryPointData?.distributionArea?.code || eicBasedDistributionArea,
                              category: consumptionCategory,
                              paging: { size: 5000 },
                          },
                          { secure: true },
                      )
                      .then((res) => res.data?.result)
                : undefined,
        {
            enabled: (deliveryPointData?.distributionArea?.code != null || eicBasedDistributionArea) && consumptionCategory != null,
        },
    );

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const handleIdenticalNewValue = () => {
        addToast(t('delivery-point.detail.given-data-is-the-same-as-current-data'), {
            appearance: 'warning',
        });
    };

    const isNewValueEqualToCurrentValue = (newValue: { tariff: string | undefined }) => {
        return newValue.tariff != null && newValue.tariff === deliveryPointData?.tariffRate?.uuid;
    };

    const onSubmit = (data: { tariff: string | undefined }) => {
        if (isNewValueEqualToCurrentValue(data)) {
            handleIdenticalNewValue();
            return;
        }

        const payload: CustomerRequestPayloadType = {
            [objectName]: { tariff: clearObjectProperties({ uuid: data.tariff }) },
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const { formRules } = useFormRules();

    const isSubmitAllowed = useMemo(
        () => !!getValues('tariff'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watch && watch('tariff'), getValues],
    );
    return (
        <>
            {(isLoading || isLoadingDeliveryPoint) && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {tariffRates && tariffRates.length > 0 && (
                    <>
                        {/* {tariffRates && <TariffFields register={register} errors={errors} data={tariffRates} trigger={trigger} />} */}
                        {errors && (
                            <div style={{ color: 'red' }}>
                                <ErrorMessage errors={errors} name="tariff" />
                            </div>
                        )}
                        {tariffRates?.map((tariffRate) =>
                            tariffRate.uuid != null ? (
                                <EeRateRadioButton
                                    key={tariffRate.uuid}
                                    register={register({ ...formRules.required })}
                                    value={tariffRate.uuid}
                                    label={tariffRate.name || '--'}
                                    description={tariffRate.description || ''}
                                    isPreviouslySelected={deliveryPointData?.tariffRate?.uuid === tariffRate.uuid}
                                />
                            ) : null,
                        )}
                        <BaseButton type="submit" disabled={!isSubmitAllowed}>
                            <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                        </BaseButton>
                    </>
                )}
                {tariffRates && tariffRates.length === 0 && consumptionCategory === 'WHOLESALE' && (
                    <div className="alert alert-danger p-4 d-flex mb-5" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                        <div className="ml-4">
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-rate-not-supported"></Trans>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
};
