import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
    SkSppNzpCommonsApiCodelistCodeListItem,
    SkSppNzpCommonsApiCustomerrequestComponentCombinedAdvancePaymentAndPeriod,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { ButtonGroup, Modal, ModalBody } from 'reactstrap';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    BaseSelect,
    CodeListTypeEnum,
    CommodityEnum,
    QueryKeysEnum,
    buildDeliveryPointQueryKey,
    formatAddressShort,
    useCodeList,
    useReloadQueryCache,
} from '../../../../../main';
import { ChangeFormStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import ChangeRequestSent from '../change-request-sent';
import ChangeAdvancePaymentForm from '../forms/change-advance-payment-form';

interface IChangeAdvancePaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
}

export const ChangeAdvancePaymentModal: React.FC<IChangeAdvancePaymentModalProps> = ({ isOpen, onClose, deliveryPoint }) => {
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [newPaymentAmount, setNewPaymentAmount] = useState<number | undefined>();

    const { t } = useTranslation();
    const { data: advancePaymentPeriodCodelist } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CONTRACT_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum.CONTRACT_ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 5000,
        },
    });

    const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState<SkSppNzpCommonsApiCodelistCodeListItem | undefined>(
        deliveryPoint.contract?.advancePaymentPeriod,
    );

    useEffect(() => {
        if (advancePaymentPeriodCodelist != null && advancePaymentPeriodCodelist.length > 0 && selectedPaymentPeriod == null) {
            // preselect first available peyment period option
            const firstAvailableOption = advancePaymentPeriodCodelist.find((item) => item.code === '01');
            setSelectedPaymentPeriod(firstAvailableOption);
        }
    }, [advancePaymentPeriodCodelist, selectedPaymentPeriod]);

    useEffect(() => {
        if (isOpen) {
            setStep(ChangeFormStepEnum.EDITING);
            setNewPaymentAmount(undefined);
        }
    }, [isOpen]);

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const reloadQueryCache = useReloadQueryCache();

    const api = useApi();
    const [mutateCreateRequest, { isLoading: isLoadingCreateRequest }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary | null,
        unknown,
        SkSppNzpCommonsApiCustomerrequestComponentCombinedAdvancePaymentAndPeriod
    >(
        async (requestData) =>
            deliveryPoint.contract?.id != null
                ? api.deliveryPoints.changeAdvancePayment(deliveryPoint.contract?.id, requestData, { secure: true }).then((res) => res.data)
                : null,
        {
            onSuccess: () => {
                reloadQueryCache(buildDeliveryPointQueryKey(deliveryPoint.id));
                reloadQueryCache(QueryKeysEnum.DELIVERY_POINT_REQUESTS_SEARCH);
                goToNextStep();
            },
        },
    );

    // TODO call universal endpoint for creating requests

    const createRequestForAdvancePaymentChange = async (newValue: number, reason?: string) => {
        const isSelectedPeriodSameAsOriginal = deliveryPoint.contract?.advancePaymentPeriod?.uuid === selectedPaymentPeriod?.uuid;
        const requestData: SkSppNzpCommonsApiCustomerrequestComponentCombinedAdvancePaymentAndPeriod = {
            advancePayment: {
                amount: newValue,
                reason: reason,
                // date: new Date().toISOString(),
            },
            advancePaymentPeriod: {
                period: {
                    uuid: selectedPaymentPeriod?.uuid,
                },
            },
            processManualOverride: isSelectedPeriodSameAsOriginal ? (reason ? true : false) : true,
        };
        mutateCreateRequest(requestData);
    };

    const { addToast } = useToasts();
    const handleIdenticalNewValue = () => {
        addToast(t('delivery-point.detail.given-data-is-the-same-as-current-data'), {
            appearance: 'warning',
        });
    };

    const onFormSubmit = (formData: number, reason?: string) => {
        if (formData === deliveryPoint.advancePayment && selectedPaymentPeriod?.uuid === deliveryPoint.contract?.advancePaymentPeriod?.uuid) {
            handleIdenticalNewValue();
            return;
        }
        setNewPaymentAmount(formData);
        createRequestForAdvancePaymentChange(formData, reason);
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.change-advance-payment">Zmena preddavkovej platby</Trans>
                    </h3>
                    <button type="button" onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                {step === ChangeFormStepEnum.EDITING && (
                    <div className="modal-header">
                        <span>
                            {`${formatAddressShort(deliveryPoint.address)} - ${
                                deliveryPoint.type === CommodityEnum.GAS ? t('common.commodity.gas') : t('common.commodity.electricity')
                            } `}
                        </span>
                    </div>
                )}
                <ModalBody>
                    {step === ChangeFormStepEnum.EDITING && (
                        <>
                            <h4>
                                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.how-often-will-you-pay">
                                    Ako často budete platiť?
                                </Trans>
                            </h4>
                            <ButtonGroup className="d-none d-md-inline-flex custom-buttons-group mb-4 w-100">
                                {advancePaymentPeriodCodelist
                                    ?.filter(
                                        (advancePaymentPeriodCodelistItem) =>
                                            advancePaymentPeriodCodelistItem.code &&
                                            ['12', '01', '03', '06'].includes(advancePaymentPeriodCodelistItem.code),
                                    )
                                    .map((advancePaymentPeriodCodelistItem) => (
                                        <button
                                            key={advancePaymentPeriodCodelistItem.uuid}
                                            onClick={() => setSelectedPaymentPeriod(advancePaymentPeriodCodelistItem)}
                                            className={classNames('btn', {
                                                active: selectedPaymentPeriod?.uuid === advancePaymentPeriodCodelistItem.uuid,
                                            })}
                                            style={{ padding: '.375rem .7rem' }}
                                        >
                                            {advancePaymentPeriodCodelistItem.name}
                                        </button>
                                    ))}
                            </ButtonGroup>
                            <div className="mb-4 d-block d-md-none">
                                <BaseSelect
                                    label={t('delivery-point.detail.payment-data.advance-payments.interval')}
                                    className="form-control-filter"
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                        setSelectedPaymentPeriod(advancePaymentPeriodCodelist?.find((payment) => payment.uuid === event.target.value))
                                    }
                                >
                                    {advancePaymentPeriodCodelist
                                        ?.filter(
                                            (advancePaymentPeriodCodelistItem) =>
                                                advancePaymentPeriodCodelistItem.code &&
                                                ['12', '01', '03', '06'].includes(advancePaymentPeriodCodelistItem.code),
                                        )
                                        .map((advancePaymentPeriodCodelistItem) => (
                                            <option
                                                key={advancePaymentPeriodCodelistItem.uuid}
                                                value={advancePaymentPeriodCodelistItem.uuid}
                                                selected={selectedPaymentPeriod?.uuid === advancePaymentPeriodCodelistItem.uuid}
                                            >
                                                {advancePaymentPeriodCodelistItem.name}
                                            </option>
                                        ))}
                                </BaseSelect>
                            </div>

                            <ChangeAdvancePaymentForm
                                currentValue={{
                                    paymentPeriod: deliveryPoint.contract?.advancePaymentPeriod,
                                    paymentAmount: deliveryPoint.advancePayment,
                                }}
                                editedValues={{
                                    paymentPeriod: selectedPaymentPeriod,
                                    paymentAmount: newPaymentAmount,
                                }}
                                onSubmit={onFormSubmit}
                                onReset={onClose}
                                isLoading={isLoadingCreateRequest}
                            />
                        </>
                    )}

                    {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onClose} />}

                    {/* {isLoadingCreateRequest && <LoadingIndicator />} */}
                </ModalBody>
            </Modal>
        </>
    );
};
