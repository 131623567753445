/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## EPAYAPI WAS CREATED MANUALLY                              ##
 * ## SOME PARTS ARE COPPIED FROM api.ts                        ##
 * ---------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------
 * ## COPIED FROM api.ts                                        ##
 * ---------------------------------------------------------------
 */
export type RequestParams = Omit<RequestInit, 'body' | 'method'> & {
    secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
    baseUrl?: string;
    baseApiParams?: RequestParams;
    securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
type TPromise<ResolveType, RejectType = any> = Omit<Promise<ResolveType>, 'then' | 'catch'> & {
    then<TResult1 = ResolveType, TResult2 = never>(
        onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ): TPromise<TResult1 | TResult2, RejectType>;
    catch<TResult = never>(
        onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
    ): TPromise<ResolveType | TResult, RejectType>;
};

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D | null;
    error: E | null;
}

enum BodyType {
    Json,
    FormData,
}

class HttpClient<SecurityDataType> {
    public baseUrl: string = 'http://meru-nzp-dev.isdd.sk/be';
    private securityData: SecurityDataType = null as any;
    private securityWorker: null | ApiConfig<SecurityDataType>['securityWorker'] = null;

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType) => {
        this.securityData = data;
    };

    private addQueryParam(query: RequestQueryParamsType, key: string) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(',') : query[key]);
    }

    protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys.length
            ? `?${keys
                  .map((key) =>
                      typeof query[key] === 'object' && !Array.isArray(query[key])
                          ? this.addQueryParams(query[key] as object).substring(1)
                          : this.addQueryParam(query, key),
                  )
                  .join('&')}`
            : '';
    }

    private bodyFormatters: Record<BodyType, (input: any) => any> = {
        [BodyType.Json]: JSON.stringify,
        [BodyType.FormData]: (input: any) =>
            Object.keys(input).reduce((data, key) => {
                data.append(key, input[key]);
                return data;
            }, new FormData()),
    };

    private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params,
            ...(securityParams || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params.headers || {}),
                ...((securityParams && securityParams.headers) || {}),
            },
        };
    }

    private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
        const r = response.clone() as HttpResponse<T, E>;
        r.data = null;
        r.error = null;

        return response
            .json()
            .then((data) => {
                if (r.ok) {
                    r.data = data;
                } else {
                    r.error = data;
                }
                return r;
            })
            .catch((e) => {
                r.error = e;
                return r;
            });
    };

    public request = <T = any, E = any>(
        path: string,
        method: string,
        { secure, ...params }: RequestParams = {},
        body?: any,
        bodyType?: BodyType,
        secureByDefault?: boolean,
    ): TPromise<HttpResponse<T, E>> => {
        const requestUrl = `${this.baseUrl}${path}`;
        const secureOptions = (secureByDefault || secure) && this.securityWorker ? this.securityWorker(this.securityData) : {};
        const requestOptions = {
            ...this.mergeRequestOptions(params, secureOptions),
            method,
            body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
        };

        return fetch(requestUrl, requestOptions).then(async (response) => {
            const data = await this.safeParseResponse<T, E>(response);
            if (!response.ok) throw data;
            return data;
        });
    };
}
/*
 * ---------------------------------------------------------------
 * ## COPIED FROM api.ts                                        ##
 * ---------------------------------------------------------------
 */

export interface PaymentProviderQuery {
    clientCode: string;
}

export interface PaymentProviderResponse {
    providerCode: string;
    providerName: string;
    resourcePath?: string;
    initialized?: boolean;
}

export interface InitTransactionRequest {
    clientCode: string;
    providerCode: string;
    amount: number;
    variableSymbol: string;
    constantSymbol: string;
    returnUrl: string;
    description?: string;
    name?: string;
}

export interface InitTransactionResponse {
    transactionId: string;
    specificSymbol: string;
    transactionUrl: string;
}

export interface TransactionStatusResponse {
    id: string;
    amount: number;
    currency: string;
    variableSymbol: string;
    specificSymbol: string;
    constantSymbol: string;
    status: TransactionStatus;
    lastChangeAt: string; //TODO: verify
}

export type TransactionStatus =
    | 'INIT' // transaction created
    | 'REDIRECTED_TO_IB' //payment requested at bank API
    | 'ONLINE_RESPONSE_OK' //online payment confirmation from bank, payment processed successfully
    | 'MAIL_RESPONSE_OK' //mail confirmation from bank, with payment result OK
    | 'ONLINE_RESPONSE_BANK_TIMEOUT' //online payment confirmation from bank, bank API not active(maintenance, ...)
    | 'ONLINE_RESPONSE_FAILED' //online payment confirmation from bank, payment failed
    | 'MAIL_RESPONSE_FAILED' //mail confirmation from bank, payment failed
    | 'TRANSACTION_TIMEOUT'; //transaction has timed out

export declare namespace epay {
    /**
     * @name getPaymentProviders
     * @request GET:/epay/provider/
     * @response `200` `(PaymentProviderResponse)[]` OK
     */
    export namespace GetPaymentProviders {
        export type RequestQuery = PaymentProviderQuery;
        export type RequestBody = never;
        export type ResponseBody = PaymentProviderResponse[];
    }

    /**
     * @name initTransaction
     * @request POST:/epay/init
     * @response `200` `InitTransactionResponse` OK
     */
    export namespace InitTransaction {
        export type RequestQuery = {};
        export type RequestBody = InitTransactionRequest;
        export type ResponseBody = InitTransactionResponse;
    }

    /**
     * @name bankRequest
     * @request GET:/epay/request/{transactionId}/{transactionHash}
     * @response `200` `any` OK
     */
    export namespace BankRequest {
        export type RequestQuery = {};
        export type RequestBody = never;
        export type ResponseBody = any;
    }

    /**
     * @name transactionStatus
     * @request GET:/epay/status/{transactionId}
     * @response `200` `TransactionStatusResponse` OK
     */
    export namespace TransactionStatus {
        export type RequestQuery = {};
        export type RequestBody = never;
        export type ResponseBody = TransactionStatusResponse;
    }
}
export class EpayApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
    epay = {
        /**
         * @name getPaymentProviders
         * @request GET:/epay/providers
         * @response `200` `(PaymentProviderResponse)[]` OK
         */
        getPaymentProviders: (query: PaymentProviderQuery, params?: RequestParams) =>
            this.request<PaymentProviderResponse[], any>(`/providers${this.addQueryParams(query)}`, 'GET', params),

        /**
         * @name initTransaction
         * @request GET:/epay/providers
         * @response `200` `InitTransactionResponse` OK
         */
        initTransaction: (data: InitTransactionRequest, params?: RequestParams) =>
            this.request<InitTransactionResponse, any>(`/init`, 'POST', params, data),

        /**
         * @name bankRequest
         * @request GET:/epay/request/{transactionId}/{transactionHash}
         * @response `200` `any` OK
         */
        bankRequest: (transactionUrl: string, params?: RequestParams) => this.request<any, any>(`${transactionUrl}`, 'GET', params),

        /**
         * @name transactionStatus
         * @request GET:/epay/status/{transactionId}
         * @response `200` `TransactionStatusResponse` OK
         */
        transactionStatus: (transactionId: string, params?: RequestParams) =>
            this.request<TransactionStatusResponse, any>(`/status/${transactionId}`, 'GET', params),
    };
}
