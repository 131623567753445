import { SkSppNzpBeApiCustomerprofileBusinessPartner, SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomerRequestActions } from '../../../../actions';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { CustomerRequestEnum, CustomerRequestRoutes, IRequestPaid, RequestTemplateCode } from '../../../../main';
import { IAttachments } from '../../../../models/customer-request-model';
import { CustomerRequestPayloadType, IStep } from '../../../../reducers/interfaces/customer-request-state';
import { requestList } from '../../../customer-request/config/request-data';
import { CustomerRequestMetadataType, CustomerRequestStepType } from '../../../customer-request/config/types';

export const useOpenRequestFromDeliveryPointDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const api = useApi();

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    const openPrepare = (request: CustomerRequestStepType) => {
        dispatch(CustomerRequestActions.setRequestKeys(request.key, request.type));
        if (request.skipIntro) {
            const code = request.type as RequestTemplateCode;
            getRequestTemplates(
                { code: code },
                {
                    onSuccess: (data) => {
                        if (data && data[0] != null) {
                            const foundTemplate = data[0];
                            dispatch(CustomerRequestActions.setCustomerRequestTemplate(foundTemplate));
                            history.push(`${CustomerRequestRoutes.NEW_REQUEST}/${request.key}`);
                        }
                    },
                },
            );
            api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => {
                if (res.data?.result) {
                }
            });
        } else {
            history.push({ pathname: CustomerRequestRoutes.PREPARE });
        }
    };

    const initializeRequestCreationProcess = (
        requestTypeCode: CustomerRequestEnum,
        requestMetadata: CustomerRequestMetadataType,
        content?: CustomerRequestPayloadType,
        paid?: IRequestPaid,
        step?: IStep,
        uuid?: string,
        businessPartner?: SkSppNzpBeApiCustomerprofileBusinessPartner,
        attachments?: IAttachments[],
    ) => {
        const foundRequestConfig = requestList[requestTypeCode];
        if (foundRequestConfig != null) {
            openPrepare(foundRequestConfig);
            dispatch(CustomerRequestActions.setMetaData(requestMetadata));
            content && dispatch(CustomerRequestActions.setData(content));
            paid && dispatch(CustomerRequestActions.setRequestIsPaid(paid));
            step && dispatch(CustomerRequestActions.setStep(step));
            uuid && dispatch(CustomerRequestActions.setUuid(uuid));
            businessPartner && dispatch(CustomerRequestActions.setBusinessPartner(businessPartner));
            attachments && dispatch(CustomerRequestActions.setAttachments(attachments));
        }
    };

    return initializeRequestCreationProcess;
};
