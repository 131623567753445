import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileAddress,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequest,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, CardBody, CardHeader, Collapse, Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import { CustomAddressModal } from '../../../../../components/common/custom-address-modal';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import {
    ClickableElement,
    CommodityEnum,
    KINDcodeHome,
    LoadingIndicator,
    QueryKeysEnum,
    RequestTemplateCode,
    SAP_DELETE,
    deleteLeadingZeros,
    useApi,
    useCodeList,
} from '../../../../../main';
import { IAttachments } from '../../../../../models/customer-request-model';
import { IApiResponse } from '../../../../../models/model';
import { ChangePersonalDataStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import {
    addressReplaceSpaceinZipCodeAndCity,
    createIndexedGroups,
    formatAddress,
    formatAddressShort,
    formatPhoneNumber,
    formatSkPhoneNumber,
} from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestEnum, CustomerRequestTypeEnum } from '../../../../customer-request/config/enums';
import { useOpenRequestFromDeliveryPointDetail } from '../../customer-requests/use-open-request-from-dp-detail';
import { useSendRequest } from '../../customer-requests/use-send-request';
import AddressFields from './component/address-fields';
import Attachments from './component/block-attachments';
import BlockEmailPhoneAddress from './component/block-email-phone-address';
import ChangeBusinessPartnerRequestSent from './component/change-business-partner-request-sent';
import CompanyFields from './component/company-fields';
import ContactFields from './component/contact-fields';
import PersonalFields from './component/personal-fields';

interface IChangePersonalDataModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
}

interface IPersonalDataForm {
    titleFront?: string;
    titleBehind?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    primaryAddress?: SkSppNzpBeApiCustomerprofileAddress;
    companyRegistrationNumber?: string;
    name?: string;
    taxIdNumber?: string;
    vatRegistrationNumber?: string;
}

enum BlockEnum {
    PERSONAL_BLOCK = 'personalBlock',
    ADDRESS_BLOCK = 'addressBlock',
}

export const ChangePersonalDataModal: React.FC<IChangePersonalDataModalProps> = ({ isOpen, onClose, deliveryPoint }) => {
    const [step, setStep] = useState<ChangePersonalDataStepEnum>(ChangePersonalDataStepEnum.EDITING);
    const [loading, setLoading] = useState<boolean>(false);
    const businessPartnerData = deliveryPoint.contract?.contractAccount?.businessPartner;
    const dispatch = useDispatch();
    const [files, setFiles] = useState<IAttachments[]>([]);
    const [openCustomAddressModal, setOpenCustomAddressModal] = useState<boolean>(false);
    const [disableAddress, setDisableAddress] = useState<boolean>(true);
    const [customAddressInserted, setCustomAddressInserted] = useState<boolean>(false);
    const [customAddress, setCustomAddress] = useState<SkSppNzpBeApiCustomerprofileAddress>();
    const [newAddress, setNewAddress] = useState<SkSppNzpBeApiCustomerprofileAddress>();
    const [updateOtherServices, setUpdateOtherServices] = useState<boolean>(true);
    const initializeRequestCreationProcess = useSendRequest();

    const api = useApi();
    const [template, setTemplate] = useState<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate>();
    const { addToast } = useToasts();
    const initializeTranscriptRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeTranscriptRequestCreationProcess(requestName, {}, {});
    };

    const [isPersonalBlockChanged, setIsPersonalBlockChanged] = useState<boolean>(false);
    const [isContactBlockChanged, setIsContactBlockChanged] = useState<boolean>(false);
    const [isAddressBlockChanged, setIsAddressBlockChanged] = useState<boolean>(false);

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });
    useEffect(() => {
        if (isOpen) {
            setStep(ChangePersonalDataStepEnum.EDITING);
            setFiles([]);
            setNewAddress(deliveryPoint.contract?.contractAccount?.businessPartner?.primaryAddress);
        }
    }, [isOpen, deliveryPoint.id, deliveryPoint.contract, dispatch]);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    useEffect(() => {
        isOpen &&
            getRequestTemplates(
                { code: CustomerRequestTypeEnum.ZOP_ZOUA },
                {
                    onSuccess: (data) => {
                        if (data && data[0] != null) {
                            const foundTemplate = data[0];
                            setTemplate(foundTemplate);
                        } else {
                            addToast(t('error-customer-request.default'), {
                                appearance: 'error',
                            });
                            onClose();
                        }
                    },
                    onError: () => {
                        addToast(t('error-customer-request.default'), {
                            appearance: 'error',
                        });
                        onClose();
                    },
                },
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [mutatePhoneValidate] = useMutationWithError(async (phone: string) => api.validations.checkPasswordComplexityWithPhone({ phone }), {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error) {
                setError('phone', {
                    message: t('common.input-rules.phoneNumber'),
                });
                setExpandedBlock('PERSONAL');
                return true;
            }
            return false;
        },
    });

    const { data: deliveryPointsSummaryData, isLoading: isDeliveryPointsSummaryDataLoading } = useQueryWithError(
        [businessPartnerData?.id, isOpen],
        async () =>
            businessPartnerData?.id &&
            isOpen === true &&
            api.businessPartners
                .unitedDeliveryPointFilter(businessPartnerData.id, { condition: 'CA_SAME_AEP' }, { secure: true })
                .then((res) => res.data),
        {
            onError: () => {
                onClose();
                return false;
            },
        },
    );

    const onFormSubmit = async (formData: IPersonalDataForm) => {
        if (step === ChangePersonalDataStepEnum.EDITING) {
            const phone = formatPhoneNumber(formData.phone || '');
            if (!phone) {
                setError('phone', {
                    message: t('common.input-rules.phoneNumber'),
                });
                setExpandedBlock('PERSONAL');
                return;
            }
            mutatePhoneValidate(phone).then((result) => {
                if (!!result) {
                    if ((isAddressBlockChanged || isContactBlockChanged) && deliveryPointsSummaryData && deliveryPointsSummaryData.length >= 1) {
                        setStep(ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES);
                    } else {
                        onSubmit(formData, false);
                    }
                }
            });
        }
    };

    const onSubmit = (formData: IPersonalDataForm, updateOtherServices: boolean) => {
        setUpdateOtherServices(updateOtherServices || false);
        if (template) {
            const {
                firstName,
                lastName,
                name,
                companyRegistrationNumber,
                vatRegistrationNumber,
                taxIdNumber,
                primaryAddress,
                phone,
                email,
                titleBehind,
                titleFront,
            } = formData;
            const primaryAddressStreetNumber = primaryAddress?.streetNumber;
            delete primaryAddress?.streetNumber;

            let newBusinessPartnerData = undefined;
            let newAddress = undefined;

            const titleFrontItem = titleFrontList?.find((item) => item.code === titleFront);
            const titleBehindItem = titleBehindList?.find((item) => item.code === titleBehind);

            /*
                    newBusinessPartnerData = {
                        firstName: businessPartnerData?.firstName === firstName ? '' : firstName,
                        lastName: businessPartnerData?.lastName === lastName ? '' : lastName,
                        titleFront:
                            (businessPartnerData?.titleFront?.externalId ?? '') === (titleFront ?? '')
                                ? undefined
                                : titleFront
                                ? titleFrontItem
                                : { code: SAP_DELETE },
                        titleBehind:
                            (businessPartnerData?.titleBehind?.externalId ?? '') === (titleBehind ?? '')
                                ? undefined
                                : titleBehind
                                ? titleBehindItem
                                : { code: SAP_DELETE },
                    };
            */

            if (isHome) {
                if (isPersonalBlockChanged) {
                    newBusinessPartnerData = {
                        firstName,
                        lastName,
                        titleFront: titleFront ? titleFrontItem : undefined,
                        titleBehind: titleBehind ? titleBehindItem : undefined,
                    };
                }
                if (isAddressBlockChanged || isContactBlockChanged) {
                    newAddress = {
                        ...primaryAddress,
                        city: getValues('primaryAddress.city'),
                        postalCode: getValues('primaryAddress.zipCode'),
                        country: { uuid: primaryAddress?.country },
                        number: primaryAddressStreetNumber,
                        email,
                        phoneNumber: phone,
                    };
                }
            }

            if (!isHome) {
                if (isPersonalBlockChanged) {
                    newBusinessPartnerData = {
                        name: businessPartnerData?.name === name ? '' : name,
                        companyRegistrationNumber:
                            businessPartnerData?.companyRegistrationNumber === companyRegistrationNumber ? '' : companyRegistrationNumber,
                        taxIdNumber: deleteLeadingZeros(businessPartnerData?.taxIdNumber) === taxIdNumber ? '' : taxIdNumber,
                        vatRegistrationNumber:
                            (businessPartnerData?.vatRegistrationNumber ?? '') === '' + (vatRegistrationNumber ?? '')
                                ? ''
                                : !vatRegistrationNumber
                                ? SAP_DELETE
                                : vatRegistrationNumber,
                    };
                }
                if (isAddressBlockChanged || isContactBlockChanged) {
                    newAddress = {
                        ...primaryAddress,
                        city: getValues('primaryAddress.city'),
                        postalCode: getValues('primaryAddress.zipCode'),
                        country: { uuid: primaryAddress?.country },
                        number: primaryAddressStreetNumber,
                        email,
                        phoneNumber: phone,
                    };
                }
            }

            const primaryAddressCountry = countryCodeList?.find((item) => item.uuid === getValues('primaryAddress.country'));
            // manualne spracovanie ak zakaznik zadal vlastnu adresu, alebo je stat iny ako 'SK'
            let processManualOverride = customAddressInserted || primaryAddressCountry?.code !== 'SK';
            // manualne spracovanie ak sa meni firma a sucasne sa meni ICO, alebo DIC
            if (
                !processManualOverride &&
                !isHome &&
                ((businessPartnerData?.name ?? '') !== (name ?? '') ||
                    (deleteLeadingZeros(businessPartnerData?.taxIdNumber) ?? '') !== (taxIdNumber ?? '') ||
                    (businessPartnerData?.companyRegistrationNumber ?? '') !== (companyRegistrationNumber ?? ''))
            ) {
                processManualOverride = true;
            }
            // manualne spracovanie ak je domacnost a meni sa meno alebo priezvisko
            if (
                !processManualOverride &&
                isHome &&
                ((businessPartnerData?.firstName ?? '') !== (firstName ?? '') || (businessPartnerData?.lastName ?? '') !== (lastName ?? ''))
            ) {
                processManualOverride = true;
            }

            const otherEntityReferences =
                deliveryPointsSummaryData &&
                deliveryPointsSummaryData.map((dp) => ({
                    contractAccountId: dp.contract?.contractAccountSummary?.id,
                    deliveryPointId: dp.id,
                    contractId: dp.contract?.id,
                }));

            const content = {
                newBusinessPartnerData,
                newAddress,
                entityReferences: updateOtherServices && otherEntityReferences ? otherEntityReferences : [],
                appliedTo: updateOtherServices && otherEntityReferences ? 'BUSINESS_PARTNER_CONTRACT_ACCOUNTS' : 'BUSINESS_PARTNER',
            };

            const metadata = {
                businessPartnerId: deliveryPoint?.contract?.contractAccount?.businessPartner?.id,
                processManualOverride,
            };

            const data: SkSppNzpBeApiCustomerrequestCustomerRequest = {
                content: { metadata, ...content, type: CustomerRequestTypeEnum.ZOP_ZOUA },
                customerRequestTemplate: template,
            };
            setLoading(true);
            initializeRequestCreationProcess(data, files, setFiles)
                .then(() => setStep(ChangePersonalDataStepEnum.SUCCESS))
                .catch((err) => {
                    setStep(ChangePersonalDataStepEnum.EDITING);
                    if (err.message === 'Files error') {
                        setExpandedBlock('PERSONAL');
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const onError = (formErrors: DeepMap<IPersonalDataForm, FieldError>) => {
        if (formErrors.primaryAddress?.street && disableAddress) {
            addToast(`${t('customer-request.steps.address.street')} - ${formErrors.primaryAddress?.street.message}`, {
                appearance: 'error',
            });
        }
        if (formErrors.primaryAddress?.city && disableAddress) {
            addToast(`${t('customer-request.steps.address.city')} - ${formErrors.primaryAddress?.city.message}`, {
                appearance: 'error',
            });
        }

        if (isHome) {
            if (formErrors.firstName || formErrors.lastName || formErrors.phone || formErrors.email) {
                setExpandedBlock('PERSONAL');
                return;
            }
            if (formErrors.primaryAddress) {
                setExpandedBlock('ADDRESS');
                return;
            }
        } else {
            if (
                formErrors.name ||
                formErrors.companyRegistrationNumber ||
                formErrors.taxIdNumber ||
                formErrors.vatRegistrationNumber ||
                formErrors.primaryAddress ||
                formErrors.phone ||
                formErrors.email
            ) {
                setExpandedBlock('PERSONAL');
                return;
            }
        }
    };

    const formData: IPersonalDataForm = {
        firstName: businessPartnerData?.firstName,
        lastName: businessPartnerData?.lastName,
        email: businessPartnerData?.email,
        phone: businessPartnerData?.phone,
        primaryAddress: businessPartnerData?.primaryAddress,
        companyRegistrationNumber: businessPartnerData?.companyRegistrationNumber,
        name: businessPartnerData?.name,
        taxIdNumber: deleteLeadingZeros(businessPartnerData?.taxIdNumber),
        vatRegistrationNumber: businessPartnerData?.vatRegistrationNumber,
        titleBehind: businessPartnerData?.titleBehind?.externalId,
        titleFront: businessPartnerData?.titleFront?.externalId,
    };

    const { register, handleSubmit, errors, trigger, watch, setValue, getValues, unregister, setError, clearErrors } = useForm<IPersonalDataForm>({
        defaultValues: formData,
    });
    const { t } = useTranslation();

    const [expandedBlock, setExpandedBlock] = useState<'PERSONAL' | 'ADDRESS' | undefined>('PERSONAL');

    const isHome = deliveryPoint.contract?.contractAccount?.businessPartner?.kind?.code === KINDcodeHome;

    const showNewPrimaryAddress = () => {
        return formatAddress(
            addressReplaceSpaceinZipCodeAndCity({
                street: getValues('primaryAddress.street'),
                streetNumber: getValues('primaryAddress.streetNumber'),
                city: getValues('primaryAddress.city'),
                zipCode: getValues('primaryAddress.zipCode'),
                country: getValues('primaryAddress.country'),
            }),
            true,
        );
    };

    const { data: titleBehindList } = useCodeList({
        queryKey: QueryKeysEnum.TITLE_BEHIND,
        codeListTypeEnum: CodeListTypeEnum.TITLE_BEHIND,
        paging: {
            size: 300,
            sort: ['description,ASC'],
        },
    });

    const { data: titleFrontList } = useCodeList({
        queryKey: QueryKeysEnum.TITLE_FRONT,
        codeListTypeEnum: CodeListTypeEnum.TITLE_FRONT,
        paging: {
            size: 300,
            sort: ['description,ASC'],
        },
    });

    const isBlockChanged = useCallback(
        (block: BlockEnum) => {
            if (block === BlockEnum.PERSONAL_BLOCK) {
                const isPersonalBlockSame =
                    (getValues('firstName') || '') === (businessPartnerData?.firstName || '') &&
                    (getValues('lastName') || '') === (businessPartnerData?.lastName || '') &&
                    (getValues('titleFront') === (businessPartnerData?.titleFront?.externalId || '') || getValues('titleFront') === undefined) &&
                    (getValues('titleBehind') === (businessPartnerData?.titleBehind?.externalId || '') || getValues('titleBehind') === undefined) &&
                    (getValues('name') || '') === (businessPartnerData?.name || '') &&
                    (getValues('companyRegistrationNumber') || '') === (businessPartnerData?.companyRegistrationNumber || '') &&
                    (getValues('taxIdNumber') || '') === (deleteLeadingZeros(businessPartnerData?.taxIdNumber) || '') &&
                    (getValues('vatRegistrationNumber') || '') === (businessPartnerData?.vatRegistrationNumber || '') &&
                    files.length === 0;

                setIsPersonalBlockChanged(!isPersonalBlockSame);

                const isContactBlockSame =
                    (getValues('email') || '') === (businessPartnerData?.email || '') &&
                    (getValues('phone') || '') === (businessPartnerData?.phone || '');

                setIsContactBlockChanged(!isContactBlockSame);
            }

            if (block === BlockEnum.ADDRESS_BLOCK) {
                if (getValues('primaryAddress.street') === undefined) {
                    setIsAddressBlockChanged(false);
                } else {
                    const item = countryCodeList?.find((item) => item.code === businessPartnerData?.primaryAddress?.country);

                    const primaryAddressBlock = JSON.stringify({
                        street: getValues('primaryAddress.street'),
                        streetNumber: getValues('primaryAddress.streetNumber'),
                        city: getValues('primaryAddress.city'),
                        zipCode: getValues('primaryAddress.zipCode'),
                        country: getValues('primaryAddress.country'),
                    });
                    const primaryAddressBP = JSON.stringify({
                        ...businessPartnerData?.primaryAddress,
                        country: item?.uuid,
                    });
                    const isSame = primaryAddressBlock === primaryAddressBP;
                    setIsAddressBlockChanged(!isSame);
                }
            }
        },
        [businessPartnerData, countryCodeList, getValues, files],
    );

    const isSendAllowed = () => {
        if (!isHome) {
            return isPersonalBlockChanged || isContactBlockChanged || isAddressBlockChanged || !!files.length;
        } else {
            return isPersonalBlockChanged || isContactBlockChanged || isAddressBlockChanged;
        }
    };

    const watchedValues: string[] = useMemo(() => {
        const watched = ['primaryAddress', 'phone', 'email'];
        if (!isHome) {
            watched.push('name');
            watched.push('companyRegistrationNumber');
            watched.push('taxIdNumber');
            watched.push('vatRegistrationNumber');
        } else {
            watched.push('firstName');
            watched.push('lastName');
            watched.push('titleFront');
            watched.push('titleBehind');
        }
        return watched;
    }, [isHome]);

    useEffect(() => {
        isBlockChanged(BlockEnum.PERSONAL_BLOCK);
        isBlockChanged(BlockEnum.ADDRESS_BLOCK);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch && watch(watchedValues), isHome, isBlockChanged]);

    useEffect(() => {
        const country = countryCodeList?.find((item) => item.uuid === getValues('primaryAddress.country'));
        if (country?.code === 'SK') {
            setDisableAddress(true);
            const addressObject: SkSppNzpBeApiCustomerprofileAddress = {
                street: getValues('primaryAddress.street'),
                streetNumber: getValues('primaryAddress.streetNumber'),
                city: getValues('primaryAddress.city'),
                zipCode: getValues('primaryAddress.zipCode'),
                country: country?.code,
            };

            if (newAddress && JSON.stringify(addressObject) !== JSON.stringify(newAddress)) {
                setNewAddress(undefined);
                setValue('primaryAddress.street', '');
                setValue('primaryAddress.streetNumber', '');
                setValue('primaryAddress.city', '');
                setValue('primaryAddress.zipCode', '');
            }
        } else {
            setDisableAddress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('primaryAddress.country')]);

    const setAddressFromCustomAddressModal = (data: SkSppNzpBeApiCustomerprofileAddress) => {
        const selectedCountry = countryCodeList?.find((item) => item.uuid === data.country);
        data.country = selectedCountry?.code;
        setNewAddress(data);
        setCustomAddress(data);
        setValue('primaryAddress.street', data.street);
        setValue('primaryAddress.streetNumber', data.streetNumber);
        setValue('primaryAddress.city', data.city);
        setValue('primaryAddress.zipCode', data.zipCode);
        setValue('primaryAddress.country', selectedCountry?.uuid);
        setCustomAddressInserted(true);
        setOpenCustomAddressModal(false);
    };

    const groupingUDP = useMemo(
        () =>
            createIndexedGroups<SkSppNzpBeApiCustomerprofileDeliveryPointSummary>(deliveryPointsSummaryData || [], (dp) => dp.unitedDeliveryPointId),
        [deliveryPointsSummaryData],
    );

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>

                    {step === ChangePersonalDataStepEnum.EDITING && (
                        <>
                            <h3 className="mb-4">
                                <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.title">Zákaznícke údaje</Trans>
                            </h3>
                            <div className="row pt-4 pb-1 m-1">
                                <div className="col-auto p-0">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <div className="col">
                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.info-when-to-use">
                                        Žiadosť použite pri úradnej zmene mena, alebo pri chybnom zápise mena (po sobáši, rozvode, nesprávne uvedenom
                                        mene...).
                                    </Trans>
                                </div>
                            </div>
                            <div className="row pt-1 pb-1 m-1">
                                <div className="col-auto p-0">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <div className="col">
                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.info-when-not-to-use">
                                        Žiadosť nie je určená na prepis odberného miesta z osoby na inú osobu (po úmrtí vlastníka odberného miesta,
                                        dedení, kúpe/predaji, prenájme alebo darovaní nehnuteľnosti).
                                    </Trans>
                                </div>
                            </div>
                            <div className="row pt-1 pb-4 m-1">
                                <div className="col-auto p-0">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <div className="col">
                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.for-change-customer-use">
                                        Na zmenu odberateľa slúži
                                    </Trans>{' '}
                                    <ClickableElement
                                        isText
                                        className="text-decoration-underline-inverse"
                                        onClick={() => startRequestInRequestsTab(CustomerRequestEnum.TRANSCRIPT_NEW)}
                                    >
                                        {t('delivery-point.detail.contact-data.change-personal-data-modal.transcript-request')}.
                                    </ClickableElement>
                                </div>
                            </div>
                        </>
                    )}
                    {step === ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES && (
                        <h3 className="mb-4">
                            <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.change-contact-for-other-services">
                                Prajete si aktualizovať aj kontaktné údaje?
                            </Trans>
                            <span className="attribute-info">
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-2" id="infoTooltip" />
                                <UncontrolledTooltip placement="right" target="infoTooltip">
                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.change-contact-for-other-services-help">
                                        Kontaktné údaje, ktoré primárne používame na kontakt s Vami.
                                    </Trans>
                                </UncontrolledTooltip>
                            </span>
                        </h3>
                    )}

                    <form onSubmit={handleSubmit(onFormSubmit, onError)} noValidate>
                        {/* ------------------------
                         step === ChangePersonalDataStepEnum.EDITING
                         ------------------------ */}
                        {isDeliveryPointsSummaryDataLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <div hidden={!(step === ChangePersonalDataStepEnum.EDITING)}>
                                <Card className="w-100 mb-3">
                                    <CardHeader className={classNames({ 'border-bottom-0': expandedBlock !== 'PERSONAL' })}>
                                        <ClickableElement
                                            onClick={() => setExpandedBlock(expandedBlock === 'PERSONAL' ? undefined : 'PERSONAL')}
                                            className="w-100"
                                        >
                                            <p className="small font-weight-bold d-flex mb-0">
                                                {!isHome ? (
                                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.company-info">
                                                        Firemné údaje
                                                    </Trans>
                                                ) : (
                                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.private-info">
                                                        Osobné údaje
                                                    </Trans>
                                                )}
                                                {(isPersonalBlockChanged || isContactBlockChanged) && (
                                                    <span className="ml-3 text-secondary">
                                                        <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.edited">
                                                            Upravili ste
                                                        </Trans>
                                                    </span>
                                                )}
                                                <i
                                                    className={`icon-chevron-down-16 la-xs text-secondary ml-auto my-auto smooth-transition ${
                                                        expandedBlock === 'PERSONAL' ? 'rotateZ-180' : ''
                                                    }`}
                                                ></i>
                                            </p>
                                        </ClickableElement>
                                    </CardHeader>

                                    <Collapse isOpen={expandedBlock === 'PERSONAL'}>
                                        <CardBody>
                                            {!isHome ? (
                                                <>
                                                    <h6 className="my-3">
                                                        <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.fundamental-info">
                                                            Základné údaje
                                                        </Trans>
                                                    </h6>
                                                    <CompanyFields
                                                        register={register}
                                                        errors={errors}
                                                        trigger={trigger}
                                                        setValue={setValue}
                                                        unregister={unregister}
                                                        canBeCompanyRegistrationNumberEmpty={
                                                            businessPartnerData?.companyRegistrationNumber ? false : true
                                                        }
                                                        canBeTaxNumberEmpty={businessPartnerData?.taxIdNumber ? false : true}
                                                    />
                                                    <ContactFields register={register} errors={errors} trigger={trigger} />
                                                    <div className="p-3 m-1 rounded row" style={{ backgroundColor: '#f0f0f0' }}>
                                                        <div className="col-auto p-0">
                                                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                                                        </div>
                                                        <div className="col">
                                                            <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.warning-change-company-info">
                                                                Zmenu firemných údajov musíte dokladovať príslušnými dokumentami (napr. výpis z
                                                                obchodného / živnostenského registra a pod.)
                                                            </Trans>
                                                        </div>
                                                    </div>
                                                    <Attachments files={files} setFiles={setFiles} />
                                                </>
                                            ) : (
                                                <>
                                                    <PersonalFields
                                                        register={register}
                                                        errors={errors}
                                                        trigger={trigger}
                                                        isTitlePresented={
                                                            !!businessPartnerData?.titleFront?.id || !!businessPartnerData?.titleBehind?.id
                                                        }
                                                    />
                                                    <ContactFields register={register} errors={errors} trigger={trigger} />
                                                </>
                                            )}
                                        </CardBody>
                                    </Collapse>
                                </Card>
                                <Card className="w-100 mb-3">
                                    <CardHeader className={classNames({ 'border-bottom-0': expandedBlock !== 'ADDRESS' })}>
                                        <ClickableElement
                                            onClick={() => setExpandedBlock(expandedBlock === 'ADDRESS' ? undefined : 'ADDRESS')}
                                            className="w-100"
                                        >
                                            <p className="small font-weight-bold d-flex mb-0">
                                                {!isHome ? (
                                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.company-address">
                                                        Sídlo spoločnosti
                                                    </Trans>
                                                ) : (
                                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.address">
                                                        Adresa trvalého pobytu
                                                    </Trans>
                                                )}

                                                {isAddressBlockChanged && (
                                                    <span className="ml-3 text-secondary">
                                                        <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.edited">
                                                            Upravili ste
                                                        </Trans>
                                                    </span>
                                                )}
                                                <i
                                                    className={`icon-chevron-down-16 la-xs text-secondary ml-auto my-auto smooth-transition ${
                                                        expandedBlock === 'ADDRESS' ? 'rotateZ-180' : ''
                                                    }`}
                                                ></i>
                                            </p>
                                        </ClickableElement>
                                    </CardHeader>

                                    <Collapse isOpen={expandedBlock === 'ADDRESS'}>
                                        <CardBody>
                                            <AddressFields
                                                register={register}
                                                errors={errors}
                                                trigger={trigger}
                                                setValue={setValue}
                                                address={newAddress}
                                                watch={watch}
                                                objectName="primaryAddress."
                                                type={openCustomAddressModal ? 'normal' : disableAddress ? 'search' : 'normal'}
                                                setError={setError}
                                                clearErrors={clearErrors}
                                                onAddressSelected={(address) => {
                                                    setCustomAddressInserted(false);
                                                    setNewAddress(address);
                                                }}
                                            />
                                            <div className="mb-2">
                                                <ClickableElement
                                                    isText
                                                    onClick={() => {
                                                        setOpenCustomAddressModal(true);
                                                    }}
                                                    className="text-decoration-underline-inverse"
                                                >
                                                    {t('customer-request.steps.custom-address-modal.my-address-not-in-list')}
                                                </ClickableElement>
                                            </div>
                                        </CardBody>
                                    </Collapse>
                                </Card>

                                <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!isSendAllowed()}>
                                    <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.change-business-partner-info">
                                        Zmeniť zákaznícke údaje
                                    </Trans>
                                </Button>
                            </div>
                        )}
                        {/* ------------------------
                         step === ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES
                         ------------------------ */}
                        <div hidden={!(step === ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES)}>
                            <div className={classNames({ 'd-none': step !== ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES })}>
                                <div className="mb-1">{t('delivery-point.detail.contact-data.change-contact-data-modal.new')}:</div>
                                <div className="mb-3">
                                    <BlockEmailPhoneAddress
                                        email={getValues('email')}
                                        phone={formatSkPhoneNumber(getValues('phone') || '')}
                                        address={showNewPrimaryAddress()}
                                        addressFieldName={
                                            isHome
                                                ? t('delivery-point.detail.contact-data.change-personal-data-modal.address')
                                                : t('delivery-point.detail.contact-data.change-personal-data-modal.company-address')
                                        }
                                    />
                                </div>
                                {t('delivery-point.detail.contact-data.change-contact-data-modal.for-your-services')}:
                                {Object.entries(groupingUDP).map(([id, udp]) => {
                                    const ee = udp.filter((dp) => dp.type === CommodityEnum.ELECTRICITY && dp.status === 'ACTIVE');
                                    const zp = udp.filter((dp) => dp.type === CommodityEnum.GAS && dp.status === 'ACTIVE');
                                    return (
                                        <p key={'udp' + id} className="text-black font-weight-500 mb-0">
                                            {formatAddressShort(udp[0].address)} - {zp.length > 1 ? `${zp.length} x ` : ''}
                                            {!!zp.length && t('enums.CommodityEnum.ZP')}
                                            {ee.length > 0 && zp.length > 0 ? ', ' : ''} {ee.length > 1 ? `${ee.length} x ` : ''}
                                            {!!ee.length && t('enums.CommodityEnum.EE')}
                                        </p>
                                    );
                                })}
                                <Button type="button" block color="primary" size="lg" className="mt-5" onClick={() => onSubmit(getValues(), true)}>
                                    {t('delivery-point.detail.contact-data.change-contact-data-modal.update-for-all-services')}
                                </Button>
                                <div onClick={() => null} className="text-center mt-2">
                                    <ClickableElement isText onClick={() => onSubmit(getValues(), false)} className="font-weight-500 text-black">
                                        {t('delivery-point.detail.contact-data.change-contact-data-modal.no-update')}
                                    </ClickableElement>
                                </div>
                            </div>
                        </div>
                        <div hidden={!(step === ChangePersonalDataStepEnum.SUCCESS)}>
                            <ChangeBusinessPartnerRequestSent
                                onClose={onClose}
                                deliveryPoints={deliveryPointsSummaryData || []}
                                selectedDeliveryPoint={deliveryPoint}
                                allServicesSame={updateOtherServices}
                                newInfo={{
                                    email: getValues('email'),
                                    phoneNumber: formatSkPhoneNumber(getValues('phone') || ''),
                                    address: showNewPrimaryAddress(),
                                    firstName: getValues('firstName'),
                                    lastName: getValues('lastName'),
                                    titleFront: titleFrontList?.find((item) => item.code === getValues('titleFront'))?.name,
                                    titleBehind: titleBehindList?.find((item) => item.code === getValues('titleBehind'))?.name,
                                    name: getValues('name'),
                                    companyRegistrationNumber: getValues('companyRegistrationNumber'),
                                    taxIdNumber: getValues('taxIdNumber'),
                                    vatRegistrationNumber: getValues('vatRegistrationNumber'),
                                }}
                                isHome={isHome}
                            />
                        </div>
                    </form>
                    {loading && <LoadingIndicator />}
                </ModalBody>
            </Modal>
            {openCustomAddressModal && (
                <CustomAddressModal
                    closeModal={() => setOpenCustomAddressModal(false)}
                    onFormSubmit={setAddressFromCustomAddressModal}
                    address={customAddress}
                />
            )}
        </>
    );
};
