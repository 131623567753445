import { SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IOverpaymentSettlement } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';
import { IBankConnection } from './../../../../../../models/customer-request-model';

type Props = {
    data: IOverpaymentSettlement & IBankConnection;
    bold?: boolean;
    requestContent: any;
    code?: SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent['type'];
};

const OverpaymentSettlementItem: React.FC<Props> = ({ data, bold, code, requestContent }) => {
    const [t] = useTranslation();

    const { data: overpaymentCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 30,
        },
    });

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const overpaymentProblem = useMemo(() => {
        return overpaymentCodeList?.find((item) => item.uuid === data.overpaymentSettlementType?.uuid)?.name;
    }, [overpaymentCodeList, data.overpaymentSettlementType]);

    if (data && isObjectEmpty(data)) return <></>;

    const { address, bankConnection } = data;

    if (code === CustomerRequestTypeEnum.ZOM_P && requestContent[CustomerRequestDataEnum.LAST_INVOICE]) {
        return <></>;
    }

    return (
        <ItemPreview title={t('customer-request.steps.settlement-overpayment.title')}>
            {overpaymentProblem && <ItemPreviewContent bold={bold}>{overpaymentProblem}</ItemPreviewContent>}
            {data.overpaymentSettlementType?.code === 'ADDRESS' && (
                <div className="pb-1">{t('customer-request.steps.settlement-overpayment.address')}</div>
            )}
            {data.overpaymentSettlementType?.code === 'BANK_ACCOUNT' && (
                <div className="pb-1">{t('customer-request.steps.settlement-overpayment.bank-account')}</div>
            )}
            {address && !isObjectEmpty(address) && (
                <>
                    <p>
                        {address?.name || ''} <br />
                        {address?.street || ''} {address?.number || ''}
                        <br />
                        {address?.city || ''} {address?.postalCode || ''}
                        <br />
                        {countryCodeList?.find((item) => item.uuid === address?.country?.uuid)?.name || ''}
                    </p>
                    {address?.floor && (
                        <>
                            {t('customer-request.steps.address.floor')}
                            <p>{address?.floor}.</p>
                        </>
                    )}
                    {address?.flatNumber && (
                        <>
                            {t('customer-request.steps.address.appartments-number')}
                            <p>{address?.flatNumber}</p>
                        </>
                    )}
                </>
            )}
            {bankConnection && (
                <>
                    {bankConnection.iban && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${
                            bankConnection.iban
                        }`}</ItemPreviewContent>
                    )}
                    {bankConnection.bicSwift && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.bic-swift')}: ${
                            bankConnection.bicSwift
                        }`}</ItemPreviewContent>
                    )}
                </>
            )}
            {data?.iban && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${data?.iban}`}</ItemPreviewContent>}
            {data?.bicSwift && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.bic-swift')}: ${data?.bicSwift}`}</ItemPreviewContent>
            )}
        </ItemPreview>
    );
};

export default OverpaymentSettlementItem;
