import React, { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import LoadingIndicator from '../loading-indicator';
import Paginator from '../paginator';
import ColumnHeaderToolbar from './column-header-toolbar';
import { IColumnConfiguration, ISort, SortDirection } from './table-interfaces';
import './table.scss';

interface IPageableTableProps<T> {
    tableId?: string;
    data: T[] | null;
    totalDataCount?: number;
    columnConfigurations: IColumnConfiguration<T>[];
    page: number;
    pageSize: number;
    onPageChange?: (newPageIndex: number) => void;
    sort?: ISort | undefined;
    onSortChange?: (sort: ISort | undefined) => void;
    filterToolbar?: ReactNode;
    onRowSelect?: (dataItem: T) => void;
    collapseIntoCards?: boolean;
    noDataMessage?: string;
    showLoadingIndicator?: boolean;
    tableLayoutFixed?: boolean;
}

const PageableTable = <T,>({
    tableId,
    data,
    totalDataCount,
    columnConfigurations,
    page,
    onPageChange,
    pageSize,
    sort,
    onSortChange,
    filterToolbar,
    onRowSelect,
    collapseIntoCards,
    noDataMessage,
    showLoadingIndicator,
    tableLayoutFixed,
}: IPageableTableProps<T>): JSX.Element => {
    const [tableUuid] = useState<string>(tableId ? tableId : btoa(Math.random().toString()));

    const handleSortChange = (sortAttribute: string, dir: SortDirection | undefined) => {
        if (onSortChange) {
            if (dir) {
                onSortChange({ attribute: sortAttribute, direction: dir });
            } else {
                onSortChange(undefined);
            }
        }
    };

    const getDataItemCells = (dataItem: T, rowIndex: number) => {
        return (
            <tr onClick={() => (onRowSelect ? onRowSelect(dataItem) : null)} key={`${tableUuid}_row${rowIndex}`}>
                {columnConfigurations.map((col) => {
                    return col.cellComponent(dataItem);
                })}
            </tr>
        );
    };

    const renderEmptyTableMessage = () => {
        return (
            <tr className="row-empty-table">
                <td className="text-center" colSpan={columnConfigurations.length}>
                    {noDataMessage ?? <Trans i18nKey="common.tables.empty-table" />}
                </td>
            </tr>
        );
    };

    const renderNoDataMessage = () => {
        return (
            <tr>
                <td className="text-center" colSpan={columnConfigurations.length}>
                    {noDataMessage ?? <Trans i18nKey="common.tables.no-data-table" />}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="table-responsive">
                        <table
                            className={`table table-light position-relative ${onRowSelect ? 'table-selectable' : ''} ${
                                collapseIntoCards ? 'table-action' : ''
                            }`}
                            style={tableLayoutFixed ? { tableLayout: 'fixed' } : undefined}
                        >
                            <thead className="thead-dark">
                                <tr className={`${collapseIntoCards ? 'd-none d-lg-table-row' : ''}`}>
                                    <ColumnHeaderToolbar
                                        sort={sort}
                                        onSortChange={handleSortChange}
                                        columnConfigurations={columnConfigurations}
                                        tableLayoutFixed={tableLayoutFixed}
                                    />
                                </tr>
                                {filterToolbar}
                            </thead>
                            <tbody className="table-light">
                                {showLoadingIndicator && (
                                    <tr>
                                        <td className="p-0 border-0">
                                            <LoadingIndicator layer={'10'} />
                                        </td>
                                    </tr>
                                )}
                                {!data && !showLoadingIndicator && renderNoDataMessage()}
                                {data && data.length === 0 && renderEmptyTableMessage()}
                                {data && data.length > 0 && data.map((dataItem, index) => getDataItemCells(dataItem, index))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>

            <Paginator pageIndex={page} dataLength={totalDataCount} pageSize={pageSize} onPageChanged={onPageChange} />
        </>
    );
};

export default PageableTable;
