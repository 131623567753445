import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useUniqueToasts } from '../hooks/use-unique-toasts';
import { IResponseError } from '../models/model';

const handledErrorCodes = [
    400,
    401,
    403,
    404,
    409,
    500,
    503,
    1001,
    1002,
    1003,
    1004,
    1005,
    2001,
    2005,
    2006,
    2007,
    2008,
    2010,
    3001,
    3003,
    3004,
    4001,
    4002,
    4003,
    5001,
    6001,
    7001,
    8001,
    8002,
    9001,
    9002,
    20001,
    21001,
    21002,
    21003,
    21004,
    21005,
    21006,
    22000,
    22001,
    22002,
    22003,
    22004,
    22005,
    22006,
];

export default function useGlobalErrorHandling(): { globalErrorHandlingFn: (error: IResponseError['code']) => void } {
    const { addUniqueToast } = useUniqueToasts();
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const globalErrorHandlingFn = useCallback(
        (error: IResponseError['code']) => {
            if (error) {
                if (handledErrorCodes.includes(error)) {
                    addToast(`${t(`error.${error}`)}`, { appearance: 'error' });
                } else {
                    addUniqueToast(`${t(`error.default`)}`, { appearance: 'error' });
                }
            } else {
                addUniqueToast(`${t(`error.default`)}`, { appearance: 'error' });
            }
        },
        [addToast, addUniqueToast, t],
    );

    return { globalErrorHandlingFn };
}
