import React from 'react';

type PropsType = {
    headline: JSX.Element;
};

const ScreenHeadline: React.FC<PropsType> = ({ headline }) => {
    return (
        <div className="d-flex">
            <h2>{headline}</h2>
        </div>
    );
};

export default ScreenHeadline;
