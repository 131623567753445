import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { ITariffWithDpId } from '../../../../../../models/customer-request-model';
import { DefaultFormType } from '../../../../../../models/model';

interface IProps extends DefaultFormType {
    currentValue: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    editedValues: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    deliveryPointId?: string;
    deliveryPointType?: 'EE' | 'ZP';
    transcript?: boolean;
    tariff?: Partial<ITariffWithDpId>;
}

const getNumericValueForContractPaymentPeriod = (paymentPeriod: string) => {
    return Number(paymentPeriod);
};

const ChangeAdvancePaymentForm: React.FC<IProps> = ({
    currentValue,
    editedValues,
    watch,
    register,
    errors,
    trigger,
    deliveryPointId,
    deliveryPointType,
    transcript,
    tariff,
}) => {
    const displayValue = {
        paymentPeriod: editedValues.paymentPeriod ?? currentValue.paymentPeriod,
        paymentAmount: editedValues.paymentAmount ?? currentValue.paymentAmount,
    };

    const recommendedPaymentAmount = useMemo(() => {
        if (currentValue.paymentAmount != null && currentValue.paymentPeriod?.code != null && displayValue.paymentPeriod?.code != null) {
            // recalculated original value per 1 month
            const originalPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(currentValue.paymentPeriod.code);
            const currentPaymentAmountBase = Math.round(currentValue.paymentAmount / originalPaymentPeriodMultiplier);

            const editedPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(displayValue.paymentPeriod.code);

            return currentPaymentAmountBase * editedPaymentPeriodMultiplier;
        }
        return undefined;
    }, [currentValue.paymentAmount, currentValue.paymentPeriod?.code, displayValue.paymentPeriod?.code]);

    const minimalPaymentValue = useMemo(() => {
        if (recommendedPaymentAmount == null) {
            return 1;
        } else {
            return Math.round(Math.max(recommendedPaymentAmount * 0.8, 1));
        }
    }, [recommendedPaymentAmount]);

    const { formRules } = useFormRules();

    const advancePaymentInputValue = watch(`advancePayment.${deliveryPointId}`);

    return (
        <>
            <h4>
                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.amount-of-the-pay">Aká bude výška platby?</Trans>
            </h4>

            <InputWithAppend
                type="number"
                ref={register({
                    ...formRules.requiredInteger,
                    ...formRules.min(1),
                })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                name={`advancePayment.${deliveryPointId}`}
                id={`advancePayment.${deliveryPointId}`}
                label={<Trans i18nKey="delivery-point.detail.payment-data.advance-payments.advance-payment-amount">Výška preddavkovej platby</Trans>}
                trigger={trigger}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        €
                    </button>
                }
            />

            <div className="mb-4">
                {recommendedPaymentAmount != null && recommendedPaymentAmount > 0 && (
                    <>
                        <b>
                            <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.recommended-payment">Odporúčaná výška:</Trans>{' '}
                            {recommendedPaymentAmount} € / {displayValue.paymentPeriod?.name}
                        </b>
                        <br />
                    </>
                )}
                {currentValue.paymentPeriod && currentValue.paymentAmount && (
                    <>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.minimum-payment">Minimálna výška:</Trans>{' '}
                        {minimalPaymentValue} € / {displayValue.paymentPeriod?.name}
                        <br />
                    </>
                )}
                {recommendedPaymentAmount != null && recommendedPaymentAmount > 0 && (
                    <>
                        {tariff ? (
                            <>
                                {deliveryPointType === 'EE' ? (
                                    <>
                                        (
                                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-original-rate">
                                            určené podľa pôvodnej sadzby
                                        </Trans>{' '}
                                        {tariff.name})
                                    </>
                                ) : (
                                    <>
                                        (
                                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-original-tariff">
                                            určené podľa pôvodnej tarify
                                        </Trans>{' '}
                                        {tariff.name})
                                    </>
                                )}
                            </>
                        ) : (
                            <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-current-consumption">
                                určené na základe Vašej spotreby
                            </Trans>
                        )}
                    </>
                )}
            </div>

            {/* {currentValue != null && Number(advancePaymentInputValue) < minimalPaymentValue && (
                <div className="mb-4">
                    <h4>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.reduction-reason">Dôvod zníženia</Trans>
                    </h4>
                    <p>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.less-than-minimum">
                            Zadali ste výšku platby, ktoré je nižšia ako minimálna. Uveďte dôvod, prečo požadujete zníženie.
                        </Trans>
                    </p>
                    <BaseTextarea
                        ref={register(requiredTrimed)}
                        errors={errors}
                        name="reason"
                        rows={3}
                        maxLength={200}
                        label={<Trans i18nKey="customer-request.steps.amount-advances.reason" />}
                    />
                </div>
            )} */}

            {/* <hr /> */}

            <div className="text-center">
                {currentValue.paymentPeriod && currentValue.paymentAmount && (
                    <>
                        <span>
                            <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.current-payment">Aktuálna platba:</Trans>{' '}
                            {currentValue.paymentAmount} € / {currentValue.paymentPeriod?.name}
                        </span>
                        <br />
                    </>
                )}
                {advancePaymentInputValue != null && Number(advancePaymentInputValue) > 0 && (
                    <b>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.requested-payment">Požadovaná platba:</Trans>{' '}
                        {advancePaymentInputValue} € / {editedValues.paymentPeriod?.name}
                    </b>
                )}
            </div>
            {transcript && (
                <div className="mt-3 text-center">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
                    {deliveryPointType === 'EE' ? (
                        <Trans i18nKey="customer-request.steps.advance-payment.info">
                            Výška platby sa odvíja od predpokladanej spotreby. Môže sa stať, že prevádzkovateľ distribučnej siete stanoví inú výšku
                            platby.
                        </Trans>
                    ) : (
                        <Trans i18nKey="customer-request.steps.advance-payment.info-short">Výška platby sa odvíja od predpokladanej spotreby.</Trans>
                    )}
                </div>
            )}
        </>
    );
};

export default ChangeAdvancePaymentForm;
