import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ClickableElement } from '../../../../../../components/common/clickable-element';
import { CommodityEnum } from '../../../../../../main';
import { IRootState } from '../../../../../../reducers';
import Routes from '../../../../../../routes/routes';
import { QueryKeysEnum, useReloadQueryCache } from '../../../../../../utils/react-query-utils';
import {
    addressReplaceSpaceinZipCodeAndCity,
    createIndexedGroups,
    formatAddress,
    formatAddressShort,
    formatBusinessPartnerName,
    formatSkPhoneNumber,
} from '../../../../../../utils/utils';
import BlockEmailPhoneAddress from './block-email-phone-address';

type Props = {
    onClose: () => void;
    selectedDeliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    allServicesSame: boolean;
    newInfo: { email?: string; phoneNumber?: string; address?: string };
};

const ChangeRequestSent: React.FC<Props> = ({ onClose, selectedDeliveryPoint, deliveryPoints, allServicesSame, newInfo }) => {
    const reloadQueryCache = useReloadQueryCache();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const history = useHistory();
    const businessPartnerData = selectedDeliveryPoint?.contract?.contractAccount?.businessPartner;
    const contractAccount = selectedDeliveryPoint?.contract?.contractAccount;

    const correspondenceAddress = useMemo<string | undefined>(() => {
        if (!!contractAccount?.postAddress?.city || !!businessPartnerData?.primaryAddress) {
            return formatAddress(addressReplaceSpaceinZipCodeAndCity(businessPartnerData?.primaryAddress || contractAccount?.postAddress), true);
        }
        return undefined;
    }, [contractAccount?.postAddress, businessPartnerData?.primaryAddress]);

    const grouping = useMemo(() => {
        const selectedDPIndentifier = `${newInfo?.email}-${newInfo?.phoneNumber}-${newInfo?.address}`;
        const grouped = createIndexedGroups<SkSppNzpBeApiCustomerprofileDeliveryPoint>(
            deliveryPoints?.filter((dp) => dp.id !== selectedDeliveryPoint?.id) || [],
            (dp) =>
                `${dp.contract?.contractAccount?.email}-${formatSkPhoneNumber(dp.contract?.contractAccount?.phone || '')}-${formatAddress(
                    dp.contract?.contractAccount?.postAddress,
                )}`,
        );
        if (selectedDeliveryPoint) {
            if (grouped[selectedDPIndentifier]) {
                const tmpDPs = grouped[selectedDPIndentifier];
                tmpDPs.push(selectedDeliveryPoint);
                grouped[selectedDPIndentifier] = tmpDPs;
            } else {
                grouped[selectedDPIndentifier] = [selectedDeliveryPoint];
            }
        }
        return grouped;
    }, [deliveryPoints, selectedDeliveryPoint, newInfo]);

    const displayUdpWithCommodity = (udp: SkSppNzpBeApiCustomerprofileDeliveryPoint[]) => {
        const ee = udp.filter((dp) => dp.type === CommodityEnum.ELECTRICITY && dp.status === 'ACTIVE');
        const zp = udp.filter((dp) => dp.type === CommodityEnum.GAS && dp.status === 'ACTIVE');
        return `${formatAddressShort(udp[0].contract?.contractAccount?.postAddress)} - ${zp.length > 1 ? `${zp.length} x ` : ''}${
            zp.length ? t('enums.CommodityEnum.ZP') : ''
        }${ee.length > 0 && zp.length > 0 ? ', ' : ''} ${ee.length > 1 ? `${ee.length} x ` : ''}${ee.length ? t('enums.CommodityEnum.EE') : ''}`;
    };

    return (
        <div className="py-4">
            <i className="icon-circle-ok-16 fa-lg text-black" style={{ fontSize: 38 }} />
            <h3 className="mt-4">
                <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.request-sent">Židadosť o zmenu odoslaná</Trans>
            </h3>
            {loggedInCustomer && (
                <p className="text-black">
                    <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.check-state">
                        Stav vybavenia môžete sledovať v časti
                    </Trans>{' '}
                    <ClickableElement isText onClick={() => history.push(Routes.CUSTOMER_REQUESTS)} className="text-decoration-underline-inverse">
                        {t('delivery-point.detail.contact-data.change-contact-data-modal.requests')}
                    </ClickableElement>
                    .
                </p>
            )}
            <hr className="my-4" />
            <div className="mb-2 font-size16 font-weight-500 text-black">
                {t('delivery-point.detail.contact-data.change-contact-data-modal.check-your-data')}:
            </div>

            <div className="font-size16 font-weight-500 text-black">{t('delivery-point.detail.contact-data.change-personal-data-modal.title')}</div>
            <div className="mb-1">
                {formatBusinessPartnerName(businessPartnerData)} ({t('delivery-point.customer-number')}: {businessPartnerData?.externalId})
            </div>
            <BlockEmailPhoneAddress
                email={businessPartnerData?.email || contractAccount?.email}
                phone={businessPartnerData?.phone || contractAccount?.phone}
                address={correspondenceAddress}
            />
            <div className="font-size16 font-weight-500 text-black mt-4">
                {t('delivery-point.detail.contact-data.change-contact-data-modal.title')}
            </div>
            {deliveryPoints?.length === 1 || allServicesSame || Object.keys(grouping).length === 1 ? (
                <BlockEmailPhoneAddress email={newInfo.email} phone={newInfo.phoneNumber} address={newInfo.address} />
            ) : (
                <>
                    {Object.entries(grouping).map(([id, item]) => (
                        <>
                            <div className="font-size16 font-weight-500 text-black mt-2 mb-1">{displayUdpWithCommodity(item)}</div>
                            <BlockEmailPhoneAddress
                                email={item[0].id === selectedDeliveryPoint?.id ? newInfo.email : item[0].contract?.contractAccount?.email}
                                phone={formatSkPhoneNumber(
                                    (item[0].id === selectedDeliveryPoint?.id ? newInfo.phoneNumber : item[0].contract?.contractAccount?.phone) || '',
                                )}
                                address={
                                    item[0].id === selectedDeliveryPoint?.id
                                        ? newInfo.address
                                        : formatAddress(addressReplaceSpaceinZipCodeAndCity(item[0].contract?.contractAccount?.postAddress), true)
                                }
                            />
                        </>
                    ))}
                </>
            )}

            <hr className="mt-4" />
            <Button
                color="primary"
                className="mt-3"
                size="lg"
                block
                onClick={() => {
                    reloadQueryCache(QueryKeysEnum.DELIVERY_POINT);
                    onClose();
                }}
            >
                <Trans i18nKey="delivery-point.detail.modals.ok">OK</Trans>
            </Button>
        </div>
    );
};

export default ChangeRequestSent;
