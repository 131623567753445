import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPreferredContact } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IPreferredContact;
    bold?: boolean;
};

const TechnicianPhoneItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.termination-contract-date.phone-number-technician')}>
            <ItemPreviewContent bold={bold}>{data?.phoneNumber}</ItemPreviewContent>
        </ItemPreview>
    );
};

export default TechnicianPhoneItem;
