import { faHourglass } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { ReactElement, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { CustomerRequestActions } from '../../../actions/customer-request-actions';
import { ClickableElement } from '../../../components/common/clickable-element';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import Routes from '../../../routes/routes';
import { formatBusinessPartner } from '../../../utils/utils';
import CustomerRequestAddNewModal from '../../customer-request/modal/customer-request-add-new-modal';
import ShareDeliveryPointModal from '../detail/data/modals/share-delivery-point-modal';
import { DeliveryPointCommodityBadges } from './delivery-point-commodity-badges';

type DeliveryPointTableRowProps = {
    deliveryPointSummary: UnitedDeliveryPointSummary;
    onOpen: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
    onLoading: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
    openSharedModal: string;
    setOpenSharedModal: (val: string) => void;
    sharingBy: boolean;
};

const ListDeliveryPointsTableRow = ({
    deliveryPointSummary,
    onOpen,
    onLoading,
    openSharedModal,
    setOpenSharedModal,
    sharingBy,
}: DeliveryPointTableRowProps): ReactElement => {
    const { t } = useTranslation();
    const loading = deliveryPointSummary.pairingStatus === 'IN_PROGRESS';
    const [modalRequest, setModalRequest] = useState<boolean>(false);
    const dispatch = useDispatch();
    const isUdpSharedToMe = deliveryPointSummary.sharing?.by != null;
    const isUdpSharedToOther = deliveryPointSummary.sharing?.to && deliveryPointSummary.sharing.to.length > 0;
    const isUdpInactive = deliveryPointSummary?.deliveryPoints?.every((dp) => dp.status !== 'ACTIVE');
    const api = useApi();

    const onClick = () => {
        if (loading) {
            onLoading(deliveryPointSummary);
        } else {
            onOpen(deliveryPointSummary);
        }
    };

    const [mutateLoadUnitedDeliveryPointDetail] = useMutationWithError(async (udpId: string) =>
        api.unitedDeliveryPoints.getUdpByUuid(udpId, { fetch: ['CONTRACT'] }, { secure: true }).then((res) => res.data),
    );

    const onNewRequest = () => {
        mutateLoadUnitedDeliveryPointDetail(deliveryPointSummary.id).then((udpDetail) => {
            dispatch(
                CustomerRequestActions.setAdditionalData({
                    bpuuid: deliveryPointSummary?.businessPartner?.id,
                    udpuid: deliveryPointSummary.id,
                    udpd: udpDetail || undefined,
                }),
            );
            setModalRequest(true);
        });
    };

    const dpIds =
        deliveryPointSummary.deliveryPoints == null
            ? []
            : deliveryPointSummary.deliveryPoints
                  .sort((a, b) => (a.type == null || b.type == null ? 0 : b.type.localeCompare(a.type)))
                  .map((dp) => dp.externalId)
                  .filter((dpId) => dpId != null);

    const rowRef = useRef<HTMLTableRowElement>(null);
    const divLoadingRef = useRef<HTMLDivElement>(null);

    const isShareModalOpen = openSharedModal === deliveryPointSummary.id ? true : false;
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState<boolean>(false);

    const isInteractable = deliveryPointSummary.access == null;

    return (
        <>
            <tr
                className={classNames({ loading: loading, 'bg-white': !isInteractable, 'cursor-default': !isInteractable })}
                onClick={isInteractable ? onClick : undefined}
                style={{ height: sharingBy ? 95 : 'initial' }}
                ref={rowRef}
            >
                <td className={`${loading ? 'opacity-50' : ''}`}>
                    {dpIds.length === 1 && <div>{dpIds[0]}</div>}
                    {dpIds.length === 2 && (
                        <div>
                            {dpIds[0]},<br />
                            {dpIds[1]}
                        </div>
                    )}
                    {dpIds.length > 2 && (
                        <div>
                            {dpIds[0]},<br />
                            {dpIds[1]}, ...
                        </div>
                    )}
                </td>
                <td className={`${loading ? 'opacity-50' : ''}`}>
                    {deliveryPointSummary.address?.street} {deliveryPointSummary.address?.streetNumber}
                    <br />
                    <small className="text-secondary">{deliveryPointSummary.address?.city}</small>
                </td>
                <td>
                    {loading ? (
                        <>
                            <div className="text-center d-block position-absolute loading-icon" style={{ marginTop: '-10px' }} ref={divLoadingRef}>
                                <FontAwesomeIcon icon={faHourglass} className="fa-lg" />
                            </div>
                            <UncontrolledTooltip container={rowRef} placement="top" target={divLoadingRef}>
                                {t('delivery-point.list-delivery-points.loading-in-progress') +
                                    '. ' +
                                    t('delivery-point.list-delivery-points.loading-in-progress-more-info')}
                            </UncontrolledTooltip>
                        </>
                    ) : deliveryPointSummary.sharing?.to?.length ? (
                        <div className={`text-right" ${loading ? 'opacity-50' : ''}`}>
                            <i id={`tooltip_${deliveryPointSummary.id}_share-icon`} className="icon-users-16" />
                            <UncontrolledTooltip container={rowRef} placement="top" target={`tooltip_${deliveryPointSummary.id}_share-icon`}>
                                {t('delivery-point.delivey-point-share-to-another-person')}
                            </UncontrolledTooltip>
                        </div>
                    ) : null}
                </td>
                <td className={`${loading ? 'opacity-20' : ''}`}>
                    {deliveryPointSummary.deliveryPoints && <DeliveryPointCommodityBadges deliveryPoints={deliveryPointSummary.deliveryPoints} />}
                </td>
                <td className={`${loading ? 'opacity-50' : ''}`}>
                    {deliveryPointSummary.sharing?.by && (
                        <small className="text-secondary">
                            {t('delivery-point.delivey-point-share-from-another-person')}
                            <br />
                        </small>
                    )}
                    {formatBusinessPartner(deliveryPointSummary.businessPartner)}
                </td>
                <td onClick={(e) => e.stopPropagation()}>
                    {isInteractable && (!isUdpInactive || isUdpSharedToOther) && (
                        <Dropdown
                            isOpen={isMenuDropdownOpen}
                            toggle={() => setIsMenuDropdownOpen((prev) => !prev)}
                            className="d-inline dropdown-place-detail"
                        >
                            <DropdownToggle
                                data-toggle="dropdown"
                                aria-expanded="false"
                                className="btn-light btn-round"
                                id={'dropdownMenuButton3001b' + deliveryPointSummary.id}
                            >
                                <i className="icon-context-points" />
                            </DropdownToggle>{' '}
                            <DropdownMenu right aria-labelledby={'dropdownMenuButton3001b' + deliveryPointSummary.id} className="dropdownMenuButtons">
                                {!isUdpSharedToMe && (!isUdpInactive || isUdpSharedToOther) && (
                                    <ClickableElement
                                        className="dropdown-item py-3"
                                        onClick={() => {
                                            {
                                                deliveryPointSummary.id && setOpenSharedModal(deliveryPointSummary.id);
                                                setIsMenuDropdownOpen(false);
                                            }
                                        }}
                                    >
                                        <Trans i18nKey="delivery-point.detail.actions.share-delivery-point.button-text">
                                            Zdieľať odberné miesto inej osobe
                                        </Trans>
                                    </ClickableElement>
                                )}

                                {!isUdpSharedToMe && !isUdpInactive && (
                                    <ClickableElement
                                        onClick={() => {
                                            onNewRequest();
                                            setIsMenuDropdownOpen(false);
                                        }}
                                        className="dropdown-item py-3"
                                    >
                                        <Trans i18nKey="delivery-point.detail.actions.submit-a-request">Podať novú žiadosť</Trans>
                                    </ClickableElement>
                                )}
                                {!isUdpInactive && (
                                    <Link to={Routes.MANAGE_DELIVERY_POINTS} className="dropdown-item py-3 text-danger">
                                        <Trans i18nKey="delivery-point.detail.actions.hide-delivery-point">Skryť odberné miesto</Trans>
                                    </Link>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </td>
            </tr>
            {isShareModalOpen && (
                <ShareDeliveryPointModal
                    isModalOpen={isShareModalOpen}
                    closeModal={() => setOpenSharedModal('')}
                    unitedDeliveryPoint={deliveryPointSummary}
                />
            )}
            <CustomerRequestAddNewModal
                key={deliveryPointSummary.id}
                isOpen={modalRequest}
                onCloseModal={() => setModalRequest(false)}
                unitedDeliveryPoint={deliveryPointSummary}
            />
        </>
    );
};

export default ListDeliveryPointsTableRow;
