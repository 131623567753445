import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useApi } from '../../../main';
import { IRootState } from '../../../reducers';
import { ClickableElement } from '../../common';
import { ConfirmDialog } from '../../dialog';

export const DeleteAllNotifications: React.FC = () => {
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const { addToast } = useToasts();
    const { t } = useTranslation();
    const api = useApi();

    const deleteAllNotifications = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsConfirmModalOpen(true);
    };

    const confirmDeleteAllAction = (action: 'confirm' | 'cancel') => {
        if (action === 'confirm') {
            if (loggedInCustomer?.id) {
                api.customers.deleteAllCustomerVisibleNotifications(loggedInCustomer.id, { secure: true }).then(() => {
                    addToast(t('notification-bar.deletion.success-message-all'), {
                        appearance: 'success',
                    });
                });
            }
        }
        setIsConfirmModalOpen(false);
    };

    return (
        <>
            <div className="d-flex justify-content-center flex-row cursor-pointer p-1 p-lg-0">
                <ClickableElement isText onClick={deleteAllNotifications}>
                    <Trans i18nKey="notification-bar.deletion.all">Vymazať všetky notifikácie</Trans>
                </ClickableElement>
            </div>

            <ConfirmDialog visible={isConfirmModalOpen} body={t('notification-bar.deletion.confirm-message-all')} onClick={confirmDeleteAllAction} />
        </>
    );
};
