import * as React from 'react';
import { Trans } from 'react-i18next';
import { Container } from 'reactstrap';
import { Breadcrumb, Routes } from '../../../main';

const Header: React.FC = () => {
    return (
        <div className="page-header pt-0 pb-md-1 mb-2">
            <Container className="pb-2">
                <div className="mb-4 pb-2">
                    <Breadcrumb route={Routes.CUSTOMER_SETTINGS} title={<Trans i18nKey="settings.title">Nastavenia</Trans>} />
                </div>
                <h3>
                    <Trans i18nKey="settings.marketing-consents.title">Zasielanie noviniek a informácií</Trans>
                </h3>
            </Container>
        </div>
    );
};

export default Header;
