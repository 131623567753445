import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import DatePicker from '../../../../../../components/common/datePicker/datePicker';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

type Props = DefaultFormType & {
    objectName?: string;
    opNumberRequired: boolean;
    requiredIdCardNumber: boolean;
    min18years: boolean;
    hideBpNumber?: boolean;
    hideIdCardNumber?: boolean;
    hideBirthDate?: boolean;
};

const BusinessPartnerPerson: React.FC<Props> = ({
    register,
    errors,
    objectName = '',
    setValue,
    getValues,
    trigger,
    opNumberRequired,
    requiredIdCardNumber,
    min18years,
    hideBpNumber = false,
    hideIdCardNumber = false,
    hideBirthDate = false,
}) => {
    const { requiredTrimed, formRules, dateRules, firstCharMustBe } = useFormRules();
    const birthDateThreshold = new Date();
    birthDateThreshold.setFullYear(new Date().getFullYear() - 18);
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <>
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                name={`${objectName}firstName`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.name">Meno</Trans>}
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                name={`${objectName}lastName`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.surname">Priezvisko</Trans>}
                touched={touched}
            />
            {!hideBpNumber && (
                <Input
                    trigger={trigger}
                    ref={register({
                        ...requiredTrimed,
                        validate: {
                            ...(opNumberRequired ? requiredTrimed.validate : () => true),
                            ...firstCharMustBe('5', opNumberRequired ? false : true).validate,
                            ...formRules.length(10).validate,
                        },
                    })}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name={`${objectName}bpNumber`}
                    label={<Trans i18nKey="customer-request.steps.businessPartner.customer-number-spp">Zákaznícke číslo v SPP</Trans>}
                    optional={!opNumberRequired}
                    touched={touched}
                />
            )}
            {!hideBirthDate && (
                <FormGroup className=" d-flex flex-row">
                    <DatePicker
                        register={register({
                            ...formRules.required,
                            validate: {
                                ...(min18years ? dateRules().dateMin18Age : dateRules().notFutureDate).validate,
                                ...dateRules().dateMax150Age.validate,
                            },
                        })}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                        name={`${objectName}birthDate`}
                        maxDate={min18years ? birthDateThreshold : new Date()}
                        label={<Trans i18nKey="customer-request.steps.businessPartner.birthday">Dátum narodenia</Trans>}
                        touched={touched}
                    />
                </FormGroup>
            )}
            {!hideIdCardNumber && (
                <Input
                    trigger={trigger}
                    ref={register(requiredIdCardNumber ? requiredTrimed : () => true)}
                    errors={errors}
                    type="text"
                    name={`${objectName}idCardNumber`}
                    label={<Trans i18nKey="customer-request.steps.businessPartner.identity-document">Doklad totožnosti</Trans>}
                    touched={touched}
                />
            )}
        </>
    );
};
export default BusinessPartnerPerson;
