import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as pageResponse,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { ColumnFilterToolbar, ISort, PageableTable } from '../../../components/common';
import Breadcrumb from '../../../components/common/breadcrumb';
import ScreenHeader from '../../../components/common/screen-header';
import Routes from '../../../routes/routes';
import { DeliveryPointsTableFilterData } from '../component/list-delivery-points-table-filter';
import ShareSelectedDeliveryPointsToolbar from '../component/share-selected-delivery-points-toolbar';
import ShareDeliveryPointModal from '../detail/data/modals/share-delivery-point-modal';
import { useShareSingleDeliveryPointContext } from './contexts/share-single-delivery-point-context';
import ScreenHeadline from './screen-headline';
import { getShareDeliveryPointsColumnConfiguration } from './share-delivery-points-columns-configuration';

type ShareDeliveryPointsProps = {
    deliveryPoints: pageResponse | null | undefined;
    page: number;
    pageChangedHandler: (page: number) => void;
    tableColumnFilter: Partial<DeliveryPointsTableFilterData>;
    onFilterChange: (filterData: DeliveryPointsTableFilterData) => void;
    sort?: ISort | undefined;
    onSortChange?: (sort: ISort | undefined) => void;
    isLoading: boolean;
};

const ShareDeliveryPointsScreen: React.FC<ShareDeliveryPointsProps> = ({
    deliveryPoints,
    page,
    pageChangedHandler,
    tableColumnFilter,
    onFilterChange,
    sort,
    onSortChange,
    isLoading,
}) => {
    const [t] = useTranslation();

    const { shareSingleDeliveryPointModalId, setShareSingleDeliveryPointModal } = useShareSingleDeliveryPointContext();
    const deliveryPointClickedToBeShared = deliveryPoints?.result?.find((dp) => dp.id === shareSingleDeliveryPointModalId);

    const closeShareModal = () => {
        setShareSingleDeliveryPointModal('');
    };

    const deliveryPointsTitle = <Trans i18nKey="delivery-point.list-delivery-points.title" defaults="Odberné miesta" />;
    const shareDeliveryPointsHeadline = <Trans i18nKey="delivery-point.share-delivery-points.title" defaults="Zdieľanie odberných miest" />;

    const columnConfiguration = useMemo(() => {
        return getShareDeliveryPointsColumnConfiguration(t);
    }, [t]);

    return (
        <>
            <ScreenHeader>
                <Breadcrumb route={Routes.DELIVERY_POINTS} title={deliveryPointsTitle} />
                <ScreenHeadline headline={shareDeliveryPointsHeadline} />
            </ScreenHeader>
            <Container>
                <ShareSelectedDeliveryPointsToolbar />
                <PageableTable<UnitedDeliveryPointSummary>
                    data={deliveryPoints?.result ?? null}
                    columnConfigurations={columnConfiguration}
                    page={deliveryPoints?.paging?.page || page || 0}
                    pageSize={deliveryPoints?.paging?.size || 0}
                    onPageChange={pageChangedHandler}
                    totalDataCount={deliveryPoints?.paging?.total || 0}
                    sort={sort}
                    onSortChange={onSortChange}
                    filterToolbar={
                        <ColumnFilterToolbar<UnitedDeliveryPointSummary, DeliveryPointsTableFilterData>
                            columnConfigurations={columnConfiguration}
                            filter={tableColumnFilter}
                            onFilterChange={onFilterChange}
                        />
                    }
                    showLoadingIndicator={isLoading}
                    tableLayoutFixed
                />
            </Container>

            {!!deliveryPointClickedToBeShared && (
                <ShareDeliveryPointModal isModalOpen closeModal={closeShareModal} unitedDeliveryPoint={deliveryPointClickedToBeShared} />
            )}
        </>
    );
};

export default ShareDeliveryPointsScreen;
