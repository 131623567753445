import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import { CustomerRequestActions } from '../../../actions/customer-request-actions';
import { useApi } from '../../../hooks/use-api';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { useWindowWidthBreakpoints } from '../../../hooks/use-window-width-breakpoints';
import { can, ClickableElement, CustomerRequestEnum, IRootState, LoadingIndicator } from '../../../main';
import Routes from '../../../routes/routes';
import { QueryKeysEnum, useReloadQueryCache } from '../../../utils/react-query-utils';
import { formatAddress, formatBusinessPartnerName } from '../../../utils/utils';
import { IInvoiceBP } from '../../invoices/invoices-filter/invoices-filter-form';
import InvoicesSummary from '../../invoices/invoices-summary';
import InvoicesTable from '../../invoices/invoices-table';
import ActivateEInvoiceModal from '../../settings/e-invoices/activate-einvoice/activate-einvoice-modal';
import BulkActivateEInvoiceModal from '../../settings/e-invoices/bulk-activate-einvoice/bulk-activate-einvoice-modal';
import UnableToFindRequestModal from '../component/unable-to-find-request-modal';
import Consumption from './consumption/consumption';
import { DeliveryPointDetailRequestsTab } from './customer-requests/delivery-point-detail-requests-tab';
import { useOpenRequestFromDeliveryPointDetail } from './customer-requests/use-open-request-from-dp-detail';
import DeliveryPointDetailData from './data/delivery-point-detail-data';
import DeliveryPointDetailNavigationData, { TabsData } from './delivery-point-detail-navigation-data';
import { DeliveryPointSwitch } from './delivery-point-switch/delivery-point-switch';
import DeliveryPointDetailHeader from './header/delivery-point-detail-header';
import DeliveryPointNavigation from './navigation/delivery-point-navigation';

export enum TABSEnums {
    data = 'data',
    invoices = 'invoices',
    consumption = 'consumption',
    requests = 'requests',
    notes = 'notes',
    notes2 = 'notes2',
}

const DeliveryPointDetail: React.FC = () => {
    const employee = useSelector((state: IRootState) => state.user.employee);
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const [customer, setCustomer] = useState<IInvoiceBP>();
    const [showInfo, setShowInfo] = useState(false);
    const [canActivateEInvoice, setCanActivateEInvoice] = useState(false);
    const [showInactiveDeliveryPoints, setShowInactiveDeliveryPoints] = useState(false);
    const [isUnableToFindRequestModalOpen, setIsUnableToFindRequestModalOpen] = useState(false);
    const [selectableDeliveryPoints, setSelectableDeliveryPoints] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint[]>([]);
    const dispatch = useDispatch();
    const [activateEInvoiceButtonClicked, setActivateEInvoiceButtonClicked] = useState<boolean>(false);
    const [isActivateEInvoiceModalOpen, setIsActivateEInvoiceModalOpen] = useState<boolean>(false);
    const [isBulkActivateEInvoiceModalOpen, setIsBulkActivateEInvoiceModalOpen] = useState<boolean>(false);
    const [isSelectedByUser, setIsSelectedByUser] = useState(false);

    const urlParams = useParams<{ unitedDeliveryPointId: string; deliveryPointId?: string; tab?: TABSEnums }>();

    const selectedDeliveryPoint = useMemo<SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined>(() => {
        return selectableDeliveryPoints.find((dp) => dp.id === urlParams.deliveryPointId);
    }, [urlParams.deliveryPointId, selectableDeliveryPoints]);

    const api = useApi();
    const history = useHistory();
    const reloadQueryCache = useReloadQueryCache();
    const navigateTo = useCallback(
        (newTab: TABSEnums | undefined, newDpId?: string, options?: { replaceUrl?: boolean; hash?: string }) => {
            const url = `${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}/${newTab || ''}/${newDpId || ''}`;
            if (options?.replaceUrl) {
                history.replace({ pathname: url, hash: options?.hash || '' });
            } else {
                history.push({ pathname: url, hash: options?.hash || '' });
            }
        },
        [history, urlParams.unitedDeliveryPointId],
    );

    const windowBreakpoints = useWindowWidthBreakpoints();

    useEffect(() => {
        // set default tab
        if (!urlParams.tab && windowBreakpoints?.md) {
            const defaultTab = TABSEnums.invoices;
            navigateTo(defaultTab, undefined, { replaceUrl: true });
        }
    }, [urlParams.tab, windowBreakpoints?.md, navigateTo]);

    const preselectDeliveryPoint = useCallback(
        (newTab: TABSEnums, options?: { replaceUrl?: boolean; hash?: string }) => {
            let preselectedDeliveryPoint = selectableDeliveryPoints.find((dp) => dp.status === 'ACTIVE');
            if (preselectedDeliveryPoint === undefined) {
                preselectedDeliveryPoint = selectableDeliveryPoints[0];
            }
            if (preselectedDeliveryPoint != null) {
                navigateTo(newTab, preselectedDeliveryPoint.id, options);
            }
        },
        [selectableDeliveryPoints, navigateTo],
    );

    useEffect(() => {
        // preselect delivery point on tabs without "All" option - replace url
        if (!urlParams.deliveryPointId && !!urlParams.tab && [TABSEnums.invoices, TABSEnums.requests].includes(urlParams.tab) === false) {
            preselectDeliveryPoint(urlParams.tab, { replaceUrl: true });
        }
    }, [urlParams.tab, urlParams.deliveryPointId, preselectDeliveryPoint]);

    const onDeliveryPointSelect = useCallback(
        (dpId: string | undefined, options?: { replaceUrl?: boolean; hash?: string }) => {
            navigateTo(urlParams.tab, dpId, options);
        },
        [urlParams.tab, navigateTo],
    );

    const onTabChange = useCallback(
        (tab: TABSEnums, options?: { replaceUrl?: boolean; hash?: string }) => {
            if (isSelectedByUser) {
                navigateTo(tab, urlParams.deliveryPointId, options);
            } else {
                // if user did not explicitly selected DP - then set default value in switch
                navigateTo(tab, undefined, options);
            }
        },
        [urlParams.deliveryPointId, navigateTo, isSelectedByUser],
    );

    const {
        isLoading: isLoadingUnitedDeliveryPoint,
        data: unitedDeliveryPointData,
    } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, urlParams.unitedDeliveryPointId, 'CONTRACT', 'READING_CYCLE_INFO'],
        async () =>
            urlParams.unitedDeliveryPointId == null
                ? null
                : api.unitedDeliveryPoints
                      .getUdpByUuid(
                          urlParams.unitedDeliveryPointId,
                          { includeInactive: true, fetch: ['CONTRACT', 'READING_CYCLE_INFO'] },
                          { secure: true },
                      )
                      .then((x) => x.data),
    );

    useEffect(() => {
        const deliveryPoints = unitedDeliveryPointData?.deliveryPoints;
        if (deliveryPoints == null || deliveryPoints.length === 0) {
            return;
        }
        const filteredDps = deliveryPoints.filter((dp) => dp.id != null && dp.type != null);
        const sortedDps = filteredDps.sort((a, b) => (a.type == null || b.type == null ? 0 : b.type.localeCompare(a.type)));

        setCustomer({
            name: formatBusinessPartnerName(unitedDeliveryPointData?.businessPartner),
            value: unitedDeliveryPointData?.businessPartner?.id || '',
        });
        setSelectableDeliveryPoints(sortedDps);
    }, [unitedDeliveryPointData]);

    const availableCommodities = useMemo(() => {
        return unitedDeliveryPointData?.deliveryPoints?.reduce<Array<Exclude<SkSppNzpBeApiCustomerprofileDeliveryPoint['type'], undefined>>>(
            (acc, dp) => {
                if (dp.type && !acc.includes(dp.type)) {
                    acc.push(dp.type);
                    return acc;
                }
                return acc;
            },
            [],
        );
    }, [unitedDeliveryPointData]);

    const { data: invoicesSearchSummary } = useQueryWithError<SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined>(
        [urlParams.unitedDeliveryPointId, loggedInCustomer?.id],
        async () =>
            loggedInCustomer?.id
                ? api.customers
                      .invoiceSearchSummary(
                          loggedInCustomer?.id,
                          {
                              unitedDeliveryPointIds: [urlParams.unitedDeliveryPointId],
                              statuses: ['UNPAID', 'PARTIALLY_PAID'],
                          },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
        { enabled: employee ? can('ENTITY_INVOICES_VIEW', employee) : true },
    );

    const isUdpSharedToMe = unitedDeliveryPointData?.sharing?.by != null;

    const isUdpInactive = useMemo(() => {
        const allDpsInactive = unitedDeliveryPointData?.deliveryPoints?.every((dp) => dp.status !== 'ACTIVE');
        setShowInactiveDeliveryPoints(allDpsInactive ? true : false);
        return allDpsInactive;
    }, [unitedDeliveryPointData]);

    const hasUdpInactiveDps = useMemo(() => {
        return unitedDeliveryPointData?.deliveryPoints?.some((dp) => dp.status !== 'ACTIVE');
    }, [unitedDeliveryPointData]);

    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeRequestCreationProcess(requestName, {});
    };

    useEffect(() => {
        dispatch(
            CustomerRequestActions.setAdditionalData({
                bpuuid: unitedDeliveryPointData?.businessPartner?.id,
                dpuuid: urlParams.deliveryPointId,
                udpuid: urlParams.unitedDeliveryPointId,
                udpd: unitedDeliveryPointData || undefined,
            }),
        );
    }, [unitedDeliveryPointData, urlParams.deliveryPointId, urlParams.unitedDeliveryPointId, dispatch]);

    // if user show inactive delivery point and switch off show inactive delivery points, then redirect to active deliveryPoint
    useEffect(() => {
        if (showInactiveDeliveryPoints === false && !isUdpInactive && urlParams.deliveryPointId && unitedDeliveryPointData?.deliveryPoints) {
            const dp = unitedDeliveryPointData?.deliveryPoints.find((dp) => dp.id === urlParams.deliveryPointId);
            if (dp && dp.status === 'INACTIVE') {
                setIsSelectedByUser(false);
                navigateTo(urlParams.tab, undefined, { replaceUrl: true });
            }
        }
    }, [showInactiveDeliveryPoints, isUdpInactive, urlParams.deliveryPointId, navigateTo, unitedDeliveryPointData, urlParams.tab]);

    const getInvoicesSummaryRequest = () => {
        if (selectedDeliveryPoint?.contract?.id != null) {
            return api.deliveryPoints.getInvoicesSummary(selectedDeliveryPoint?.contract?.id, { secure: true }).then((res) => res.data);
        }
        if (urlParams.unitedDeliveryPointId != null) {
            return api.unitedDeliveryPoints
                .getInvoicesSummaryUdp(urlParams.unitedDeliveryPointId, { includeInactive: showInactiveDeliveryPoints }, { secure: true })
                .then((res) => res.data);
        }
        return null;
    };

    const { data: invoicesSummaryData } = useQueryWithError<SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined>(
        ['invoicesSummary', urlParams.deliveryPointId, loggedInCustomer?.id, urlParams.unitedDeliveryPointId, showInactiveDeliveryPoints],
        async () => getInvoicesSummaryRequest(),
        {
            enabled: employee ? can('ENTITY_INVOICES_VIEW', employee) : true,
        },
    );

    const getDeliveryPointSwitch = useCallback(
        (additionalButton?: { label: React.ReactNode }) => {
            const onSelect = (id: string | undefined) => {
                if (id == null) {
                    setIsSelectedByUser(false);
                } else {
                    setIsSelectedByUser(true);
                }
                onDeliveryPointSelect(id);
            };

            return (
                <DeliveryPointSwitch
                    deliveryPoints={selectableDeliveryPoints}
                    selectedDeliveryPointId={selectedDeliveryPoint?.id}
                    onSelect={onSelect}
                    showInactive={showInactiveDeliveryPoints}
                    toggleInactive={isUdpInactive || !hasUdpInactiveDps ? undefined : () => setShowInactiveDeliveryPoints((prev) => !prev)}
                    additionalButton={additionalButton}
                />
            );
        },
        [selectableDeliveryPoints, selectedDeliveryPoint?.id, showInactiveDeliveryPoints, isUdpInactive, hasUdpInactiveDps, onDeliveryPointSelect],
    );

    return (
        <>
            {isLoadingUnitedDeliveryPoint && <LoadingIndicator fullscreen />}

            {unitedDeliveryPointData != null && (
                <DeliveryPointDetailHeader
                    tab={urlParams.tab}
                    onTabChange={onTabChange}
                    unitedDeliveryPoint={unitedDeliveryPointData}
                    showInactiveDeliveryPoints={showInactiveDeliveryPoints}
                    toggleInactiveDeliveryPoints={
                        isUdpInactive || !hasUdpInactiveDps ? undefined : () => setShowInactiveDeliveryPoints((prev) => !prev)
                    }
                    setCanActivateEInvoice={setCanActivateEInvoice}
                    setActivateEInvoiceButtonClicked={setActivateEInvoiceButtonClicked}
                    setIsActivateEInvoiceModalOpen={setIsActivateEInvoiceModalOpen}
                />
            )}
            {unitedDeliveryPointData != null && (
                <DeliveryPointDetailNavigationData
                    onTabChange={onTabChange}
                    activeTab={urlParams.tab}
                    unpaidExpired={invoicesSearchSummary?.unpaidExpired?.count}
                    unpaid={invoicesSearchSummary?.unpaid?.count}
                >
                    {(navigationProps: TabsData) => {
                        return (
                            <DeliveryPointNavigation
                                showExtraClasses={!!unitedDeliveryPointData?.sharing?.by}
                                navigationProps={navigationProps}
                                activeTab={urlParams.tab}
                                onTabChange={onTabChange}
                                isUdpInactive={isUdpInactive}
                                isUdpSharedToMe={isUdpSharedToMe}
                                isSelectedDpActive={selectedDeliveryPoint?.status === 'ACTIVE'}
                                unitedDeliveryPoint={unitedDeliveryPointData}
                            />
                        );
                    }}
                </DeliveryPointDetailNavigationData>
            )}

            <TabContent activeTab={urlParams.tab}>
                <TabPane tabId={TABSEnums.data}>
                    {urlParams.tab === TABSEnums.data && urlParams.deliveryPointId && unitedDeliveryPointData != null && (
                        <>
                            <div className="page-header bg-white d-md-none mb-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 mt-4 mb-3">
                                            <h2 className="my-2">
                                                <Trans i18nKey="delivery-point.detail.data-title" />
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-two-colors d-md-none mb-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">{getDeliveryPointSwitch()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="container mb-5">
                                <div className="row">
                                    <div className="col-12 mb-3 d-none d-md-block mb-4">{getDeliveryPointSwitch()}</div>
                                    {selectedDeliveryPoint?.id && selectedDeliveryPoint?.contract?.id && (
                                        <DeliveryPointDetailData
                                            unitedDeliveryPoint={unitedDeliveryPointData}
                                            deliveryPointId={selectedDeliveryPoint?.id}
                                            contractId={selectedDeliveryPoint?.contract?.id}
                                            canActivateEInvoice={canActivateEInvoice}
                                            setActivateEInvoiceButtonClicked={setActivateEInvoiceButtonClicked}
                                            setIsActivateEInvoiceModalOpen={setIsActivateEInvoiceModalOpen}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </TabPane>
                <TabPane tabId={TABSEnums.consumption}>
                    {urlParams.tab === TABSEnums.consumption && selectedDeliveryPoint && (
                        <Consumption
                            deliveryPointType={selectedDeliveryPoint.type}
                            deliveryPointId={selectedDeliveryPoint.id}
                            contractId={selectedDeliveryPoint?.contract?.id}
                            address={formatAddress(unitedDeliveryPointData?.address)}
                            showIntervalGraph={selectedDeliveryPoint?.meteringInterval}
                            getDeliveryPointSwitch={getDeliveryPointSwitch}
                        />
                    )}
                </TabPane>
                <TabPane tabId={TABSEnums.invoices}>
                    {urlParams.tab === TABSEnums.invoices && unitedDeliveryPointData != null && (
                        <>
                            <div className="page-header bg-white d-md-none mb-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 mt-4 mb-3">
                                            <h2 className="my-2">
                                                <Trans i18nKey="invoices.header" />
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-two-colors d-md-none mb-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            {getDeliveryPointSwitch({
                                                label: <Trans i18nKey="delivery-point.delivery-point-switch.select-all-invoices" />,
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container mb-5">
                                <div className="row">
                                    <div className="col-12 d-none d-md-block mb-4">
                                        {getDeliveryPointSwitch({
                                            label: <Trans i18nKey="delivery-point.delivery-point-switch.select-all-invoices" />,
                                        })}
                                    </div>
                                    <InvoicesSummary showInfo={showInfo} invoicesSummary={invoicesSummaryData} />
                                    <InvoicesTable
                                        deliveryPointId={urlParams.deliveryPointId}
                                        contractId={selectedDeliveryPoint?.contract?.id}
                                        unitedDeliveryPointId={urlParams.unitedDeliveryPointId}
                                        setShowInfo={setShowInfo}
                                        commodity={selectedDeliveryPoint?.type}
                                        deliveryPoints={unitedDeliveryPointData?.deliveryPoints?.filter((dp) => dp.status === 'ACTIVE')}
                                        customer={customer}
                                        unitedDeliveryPoints={unitedDeliveryPointData != null ? [unitedDeliveryPointData] : []}
                                        invoicesSummary={invoicesSummaryData}
                                        availableCommodities={availableCommodities}
                                    />
                                </div>
                                <div className="mt-4">
                                    <ClickableElement
                                        onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REQUEST_INVOICE_OUT_CYCLE_SHORT)}
                                        isText
                                        className="clickable-element--text"
                                    >
                                        <Trans i18nKey="customer-request.steps.invoice-out-of-cycle-data.ask-for-invoice-out-of-cycle">
                                            Požiadať o vystavenie faktúry mimo cyklu
                                        </Trans>
                                    </ClickableElement>

                                    <span className="selection-dot ml-2" style={{ fontSize: '1.2rem' }}>
                                        &bull;
                                    </span>

                                    <ClickableElement
                                        onClick={() => setIsUnableToFindRequestModalOpen(true)}
                                        isText
                                        className="clickable-element--text ml-2"
                                    >
                                        <Trans i18nKey="delivery-point.detail.unable-to-find-request-modal.title">Nenašiel som faktúru</Trans>
                                    </ClickableElement>

                                    <UnableToFindRequestModal
                                        isOpen={isUnableToFindRequestModalOpen}
                                        onCloseModal={() => setIsUnableToFindRequestModalOpen(false)}
                                        businessPartnerKindCode={unitedDeliveryPointData?.businessPartner?.kind?.code}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </TabPane>
                <TabPane tabId={TABSEnums.requests}>
                    {urlParams.tab === TABSEnums.requests && unitedDeliveryPointData != null && (
                        <DeliveryPointDetailRequestsTab
                            unitedDeliveryPoint={unitedDeliveryPointData}
                            getDeliveryPointSwitch={getDeliveryPointSwitch}
                        />
                    )}
                </TabPane>
                <TabPane tabId={TABSEnums.notes}>
                    {urlParams.tab === TABSEnums.notes && (
                        <Consumption
                            deliveryPointType={selectedDeliveryPoint?.type}
                            deliveryPointId={selectedDeliveryPoint?.id}
                            contractId={selectedDeliveryPoint?.contract?.id}
                            address={formatAddress(unitedDeliveryPointData?.address)}
                            notes
                            getDeliveryPointSwitch={getDeliveryPointSwitch}
                        />
                    )}
                </TabPane>
                <TabPane tabId={TABSEnums.notes2}>
                    {urlParams.tab === TABSEnums.notes2 && (
                        <Consumption
                            deliveryPointType={selectedDeliveryPoint?.type}
                            deliveryPointId={selectedDeliveryPoint?.id}
                            contractId={selectedDeliveryPoint?.contract?.id}
                            address={formatAddress(unitedDeliveryPointData?.address)}
                            notes
                            getDeliveryPointSwitch={getDeliveryPointSwitch}
                        />
                    )}
                </TabPane>
            </TabContent>

            {unitedDeliveryPointData?.id && activateEInvoiceButtonClicked && (
                <ActivateEInvoiceModal
                    isOpen={isActivateEInvoiceModalOpen}
                    onCloseModal={() => setIsActivateEInvoiceModalOpen(false)}
                    unitedDeliveryPointId={unitedDeliveryPointData.id}
                    startBulkActivate={() => setIsBulkActivateEInvoiceModalOpen(true)}
                    onActivate={() => reloadQueryCache([QueryKeysEnum.CUSTOMER_REQUESTS_SEARCH])}
                />
            )}

            <BulkActivateEInvoiceModal
                isOpen={isBulkActivateEInvoiceModalOpen}
                onCloseModal={() => setIsBulkActivateEInvoiceModalOpen(false)}
                onBulkActivate={() => reloadQueryCache([QueryKeysEnum.CUSTOMER_REQUESTS_SEARCH])}
            />
        </>
    );
};

export default DeliveryPointDetail;
