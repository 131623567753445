import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import BaseSelect from '../../../../../../components/common/base-select';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import { ClickableElement } from '../../../../../../components/common/clickable-element';
import Input from '../../../../../../components/common/input';
import useCodeList from '../../../../../../hooks/use-code-list';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { CodeListTypeEnum } from '../../../../../customer-request/config/enums';

interface IPersonalFields extends DefaultFormType {
    isTitlePresented: boolean;
}

const PersonalFields: React.FC<IPersonalFields> = ({ register, errors, trigger, isTitlePresented }) => {
    const { requiredTrimed, formRules } = useFormRules();
    const { t } = useTranslation();
    const [showTitleInputs, setShowTitleInputs] = useState(isTitlePresented ? true : false);

    const { data: titleBehindList } = useCodeList({
        queryKey: QueryKeysEnum.TITLE_BEHIND,
        codeListTypeEnum: CodeListTypeEnum.TITLE_BEHIND,
        paging: {
            size: 300,
            sort: ['description,ASC'],
        },
    });

    const { data: titleFrontList } = useCodeList({
        queryKey: QueryKeysEnum.TITLE_FRONT,
        codeListTypeEnum: CodeListTypeEnum.TITLE_FRONT,
        paging: {
            size: 300,
            sort: ['description,ASC'],
        },
    });

    return (
        <>
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.validName })}
                errors={errors}
                type="text"
                name="firstName"
                label={t('common.name')}
                autoFocus
                characterFilter={CharacterFilter.LATIN}
            />

            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.validName })}
                errors={errors}
                type="text"
                name="lastName"
                label={t('common.surname')}
                characterFilter={CharacterFilter.LATIN}
                componentClassName="mb-2"
            />

            {!showTitleInputs && (
                <div className="mb-3">
                    <ClickableElement isText onClick={() => setShowTitleInputs(true)} className="text-decoration-underline-inverse">
                        <Trans i18nKey="delivery-point.detail.contact-data.change-personal-data-modal.add-titles">Pridať akademické tituly</Trans>
                    </ClickableElement>
                </div>
            )}

            {showTitleInputs && (
                <Row>
                    <Col xs={12} sm={6}>
                        <BaseSelect ref={register({})} name="titleFront" label={<Trans i18nKey="common.title_front">Titul pred menom</Trans>}>
                            <option value="">-</option>
                            {titleFrontList?.map((item, index) => (
                                <option key={index} value={item.code}>
                                    {item.name}
                                </option>
                            ))}
                        </BaseSelect>
                    </Col>
                    <Col xs={12} sm={6}>
                        <BaseSelect ref={register({})} name="titleBehind" label={<Trans i18nKey="common.title_behind">Titul za menom</Trans>}>
                            <option value="">-</option>
                            {titleBehindList?.map((item, index) => (
                                <option key={index} value={item.code}>
                                    {item.name}
                                </option>
                            ))}
                        </BaseSelect>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default PersonalFields;
