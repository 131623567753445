import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../main';
import { IRootState } from '../../../reducers';
import { formatPhoneNumber } from '../../../utils/utils';
import Input from '../input';
import LoadingIndicator from '../loading-indicator';
import { TooltipWrapper } from '../tooltip-wrapper';

type Props = {
    onSuccess: (phoneNumber: string, challengeCode: string) => void;
    onError?: () => void;
    value?: string;
};

interface IPhoneChangeFormFields {
    phone: string;
}

const AddPhoneNumber: React.FC<Props> = ({ value, onSuccess }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const { register, handleSubmit, errors, setError, trigger, reset } = useForm<IPhoneChangeFormFields>({
        defaultValues: { phone: value ? value : '+421' },
    });
    const { formRules, phoneRules } = useFormRules();
    const { t } = useTranslation();
    const api = useApi();

    const requestPhoneChange = useCallback(
        async (phoneChangeData: IPhoneChangeFormFields) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers
                      .requestPhoneChange(
                          loggedInCustomer.id,
                          {
                              phone: phoneChangeData.phone,
                          },
                          { secure: true },
                      )
                      .then((res) => res.data),
        [api, loggedInCustomer],
    );
    const [mutatePhoneChange, { isLoading }] = useMutationWithError(requestPhoneChange, {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (!response || !response.error) {
                return false;
            }
            if (response.error.code === 2007 || (response.error.code === 400 && response.error.errors)) {
                // 2007 not really happens here ... backend known issue!!!
                setError('phone', {
                    message: t('common.input-rules.phoneNumber'),
                });
                return true;
            }
            return false;
        },
    });

    const onSubmit = (formData: IPhoneChangeFormFields) => {
        const cleanPhoneNumber = formatPhoneNumber(formData.phone);
        mutatePhoneChange(
            { phone: cleanPhoneNumber },
            {
                onSuccess: (data) => {
                    if (data?.uuid != null) {
                        reset();
                        onSuccess(formData.phone, data.uuid);
                    }
                },
            },
        );
    };

    return (
        <form className="my-3" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TooltipWrapper id="phone" tooltipContent={t('common.input-rules.phone-number-info')}>
                <Input
                    id="phone"
                    ref={register({ ...formRules.required, ...phoneRules })}
                    errors={errors}
                    type="text"
                    name="phone"
                    label={t('phone-validation.your-phone-number')}
                    trigger={trigger}
                    autoFocus
                    autoFocusDelay={550} // fix for tooltip placement during modal opening
                />
            </TooltipWrapper>

            {isLoading && <LoadingIndicator />}

            <button type="submit" className="btn btn-primary btn-block btn-lg">
                <Trans i18nKey="common.next">Ďalej</Trans>
            </button>
        </form>
    );
};

export default AddPhoneNumber;
