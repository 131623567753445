import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary as SingleDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';

type Props = {
    deliveryPoints?: SingleDeliveryPointSummary[];
};

export const ExternalIdsCell: React.FC<Props> = ({ deliveryPoints }) => {
    const dpIds =
        deliveryPoints == null
            ? []
            : deliveryPoints
                  .sort((a, b) => (a.type == null || b.type == null ? 0 : b.type.localeCompare(a.type)))
                  .map((dp) => dp.externalId)
                  .filter((dpId) => dpId != null);
    return (
        <td>
            <div>
                {dpIds.length === 1 && <>{dpIds[0]}</>}
                {dpIds.length === 2 && (
                    <>
                        {dpIds[0]},<br />
                        {dpIds[1]}
                    </>
                )}
                {dpIds.length > 2 && (
                    <>
                        {dpIds[0]},<br />
                        {dpIds[1]}, ...
                    </>
                )}
            </div>
        </td>
    );
};
