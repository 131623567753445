import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { SettingsRoutes } from '../../../routes/routes';

const InvoiceHeader: React.FC = () => {
    return (
        <div className="page-header pt-4 pb-md-3 mb-4">
            <Container className="my-3">
                <Row>
                    <Col>
                        <h1>
                            <Trans i18nKey="invoices.header">Faktúry</Trans>
                        </h1>
                    </Col>
                    <Col className="text-right">
                        <Link
                            color="primary"
                            className="btn btn-primary btn-block d-none d-md-inline-block btn-md-inline"
                            to={SettingsRoutes.EINVOICES}
                        >
                            <Trans i18nKey="invoices.eAdministration">Správa eFaktúr</Trans>
                        </Link>
                        <UncontrolledDropdown className="d-inline dropdown-place-detail">
                            <DropdownToggle data-toggle="dropdown" className="btn-light d-md-none btn-round">
                                <i className="icon-context-points" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <Link className="dropdown-item py-3" to={SettingsRoutes.EINVOICES}>
                                    <Trans i18nKey="invoices.eAdministration">Správa eFaktúr</Trans>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InvoiceHeader;
