import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: Date;
    bold?: boolean;
};

const ContractTerminationDateItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.termination-contract-date.title')}>
                    <ItemPreviewContent bold={bold}>{formatDateToPreview(data)}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default ContractTerminationDateItem;
