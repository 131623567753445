import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { HelpText } from '.';

type HelpFullWidthStripProps = {
    screen: string;
    field: string;
    backgroundColor?: string;
    textColor?: string;
    classes?: string;
    warning?: boolean;
};

const HelpFullWidthStrip: React.FC<HelpFullWidthStripProps> = ({ screen, field, backgroundColor, textColor, classes, warning }) => {
    const [showHelp, setShowHelp] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<boolean>(false);

    const stripStyles = {
        backgroundColor: backgroundColor,
        color: textColor,
    };

    const handleStripClicked = () => {
        setExpanded(true);
    };

    const handleStripClosed = () => {
        setShowHelp(false);
    };

    return showHelp ? (
        <div className={`help-full-width-strip ${expanded && 'expanded'} ${classes ? classes : ''}`} style={stripStyles} onClick={handleStripClicked}>
            <div className="container">
                <div className="d-flex align-items-center">
                    {warning && (
                        <div className="d-none d-md-block">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="help-icon" />
                        </div>
                    )}
                    <HelpText screen={screen} field={field} />
                    <i
                        className="icon-Times align-self-start ml-auto px-2"
                        aria-hidden="true"
                        style={{ fontSize: '1.5rem' }}
                        onClick={handleStripClosed}
                    ></i>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default HelpFullWidthStrip;
