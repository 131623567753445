import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUrlQuery } from '../../hooks/use-url-query';
import Routes from '../../routes/routes';
import LoginIssuesView from './login-issues-view';

export type LoginIssuesStepsType = 'mainStep' | 'fogotPasswordSubmittedStep';

export enum LoginIssuesSteps {
    MAIN_STEP = 'mainStep',
    FORGOT_PASSWORD_SUBMITTED_STEP = 'fogotPasswordSubmittedStep',
}

const LoginIssues: React.FC = () => {
    const urlQuery = useUrlQuery();
    const card = urlQuery.get('card') === 'forgotten-password' ? 1 : 0;

    const [activeCard, setActiveCard] = useState<number | null>(card);
    const [step, setStep] = useState<LoginIssuesStepsType>(LoginIssuesSteps.MAIN_STEP);
    const location = useLocation();

    const navigateToStep = useCallback(() => {
        switch (location.pathname) {
            case Routes.FORGOT_PASSWORD_CONFIRMATION:
                setStep(LoginIssuesSteps.FORGOT_PASSWORD_SUBMITTED_STEP);
                break;
            default:
                setStep(LoginIssuesSteps.MAIN_STEP);
                break;
        }
    }, [location]);

    useEffect(() => {
        navigateToStep();
    }, [navigateToStep]);

    //accordion
    const toggleActiveCard = (event: React.MouseEvent, index: number | null) => {
        event.preventDefault();
        index === activeCard ? setActiveCard(null) : setActiveCard(index);
    };

    return (
        <>
            <LoginIssuesView activeCard={activeCard} toggleActive={toggleActiveCard} step={step} />
        </>
    );
};

export default LoginIssues;
