import { SkSppNzpCommonsApiEmployeeaccessAccessRightSummary } from '@spp/spp-meru-frontend-common';
import { SkSppNzpCommonsApiEmployeeaccessEmployee } from '@spp/spp-meru-frontend-common/src/api';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../reducers';

export type Permission =
    | 'ACCESS_GROUPS_EDIT'
    | 'ACCESS_GROUPS_VIEW'
    | 'AUDIT_LOGS_CUSTOMERS'
    | 'AUDIT_LOGS_CUSTOMERS_WITH_EMPLOYEES'
    | 'AUDIT_LOGS_EMPLOYEES'
    | 'BUSINESS_PARTNERS_PAIRING'
    | 'BUSINESS_PARTNERS_UNPAIRING'
    | 'COMPONENT_HELP_EDIT'
    | 'COMPONENT_HELP_VIEW'
    | 'CUSTOMERS_ACTIVATION'
    | 'CUSTOMERS_ACTOR_EMPLOYEE'
    | 'CUSTOMERS_BATCH_IMPORT'
    | 'CUSTOMERS_DEACTIVATION'
    | 'CUSTOMERS_DELETE_ACCOUNT'
    | 'CUSTOMERS_PASSWORD_RECOVERY'
    | 'CUSTOMERS_REGISTRATION'
    | 'CUSTOMERS_VIEW'
    | 'CUSTOMERS_EDIT'
    | 'ENTITY_BUSINESS_PARTNERS_VIEW'
    | 'ENTITY_DELIVERY_POINTS_VIEW'
    | 'ENTITY_INVOICES_VIEW'
    | 'ENTITY_REQUESTS_VIEW'
    | 'NOTIFICATION_TEMPLATES_EDIT'
    | 'NOTIFICATION_TEMPLATES_VIEW'
    | 'PORTAL_ACCESS'
    | 'REPORTS_EDIT'
    | 'REPORTS_VIEW'
    | 'SSO_ADMIN';

interface ICanProps {
    permissions?: Permission | Permission[];
    queue?: 'INDIVIDUAL' | 'COLLECTIVE' | 'ALL';
}

const usePermissions = (): SkSppNzpCommonsApiEmployeeaccessAccessRightSummary[] | undefined =>
    useSelector((store: IRootState) => store.user.employee?.accessRights);

//check if specific permission or array of permissions of Can component are matched. // <Can permissions={['some', 'permission']}> ... </Can>
//if no permissions are set on Can component, checkMatch will return true - user can access the component, because no restrictions are present // <Can>...</Can> -> used as fallback in PermissionsSwitch
const checkMatch = (userPermissions: SkSppNzpCommonsApiEmployeeaccessAccessRightSummary[] | undefined, canProps: ICanProps) => {
    let match = false;
    const { permissions = [], queue } = canProps;
    const permissionsArr = Array.isArray(permissions) ? permissions : [permissions];

    if (permissionsArr.length === 0) {
        match = true;
    } else {
        // has valid permission and has valid queue
        match =
            !!userPermissions &&
            userPermissions.some(
                (p) =>
                    p.code &&
                    permissionsArr.includes(p.code as Permission) &&
                    (queue ? p.queue === queue || p.queue === undefined || queue === 'ALL' : true),
            );
    }

    return match;
};

//'Can' component - wrapper, restricting access of unauthorized users to wrapped components
//true => render wrapped content;
//false => render null
export const Can: React.FC<ICanProps> = (props) => {
    const { children } = props;
    const userPermissions = usePermissions();
    const match = checkMatch(userPermissions, props);

    if (match) {
        return <>{children}</>;
    } else {
        return null;
    }
};

//'can' function returns boolean saying user has/has not specified permissions
export const can = (
    permissions: Permission | Permission[],
    employee: SkSppNzpCommonsApiEmployeeaccessEmployee | undefined,
    queue?: 'INDIVIDUAL' | 'COLLECTIVE',
): boolean => checkMatch(employee?.accessRights, { permissions, queue });

//similar to react-router Switch behaviour
//can be used to wrap several 'Can' components, and render only the first 'Can' whose checkMatch returns true
//render first 'Can' child which meets the criteria, to solve use-case where multiple 'Can' components would meet the criteria but you need to render only one of them (the first one)
///last 'Can' could be used without specifying any permissions/criteria and would serve us as fallback
export const PermissionsSwitch: React.FC = ({ children }) => {
    const userPermissions = usePermissions();

    let element: React.ReactNode = null;
    let match = false;

    React.Children.forEach(children, (child) => {
        if (!match && React.isValidElement(child) && child.type === Can) {
            element = child;
            match = checkMatch(userPermissions, child.props as ICanProps);
        }
    });
    return match ? element : null;
};
