import { SkSppNzpBeApiCustomerprofilePairBusinessPartnerResponse as PairBusinessPartnerResponse } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { ClickableElement } from '../../../components/common/clickable-element';
import Input from '../../../components/common/input';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../models/model';
import { IRootState } from '../../../reducers';
import Breadcrumb from './add-delivery-point-breadcrumb';

type VerifyPhoneNumberScreenProps = {
    challengeUuid?: string;
    lastThreePhoneDigits?: string;
    onVerified: (pairingResponse: PairBusinessPartnerResponse) => void;
    onError: () => void;
    goBack: () => void;
};

type BusinessPairingChallengeData = {
    smsCode: string;
};

const VerifyBusinessPartnerPhoneNumberScreen: React.FC<VerifyPhoneNumberScreenProps> = ({
    challengeUuid,
    lastThreePhoneDigits,
    onVerified,
    onError,
    goBack,
}) => {
    const { register, handleSubmit, errors, trigger, setError } = useForm<BusinessPairingChallengeData>();
    const { requiredTrimed } = useFormRules();
    const { t } = useTranslation();
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);
    const api = useApi();
    const { addToast } = useToasts();

    const onSubmit = (formData: BusinessPairingChallengeData) => {
        mutateBusinesPartnerParingRequestChallenge(formData.smsCode);
    };

    const requestBusinessPartnerPairingChallenge = async (verifyCode: string) => {
        if (loggedInCustomer?.id) {
            return api.customers
                .pairChallenge(loggedInCustomer?.id, { code: verifyCode, uuid: challengeUuid }, { secure: true })
                .then((res) => res.data);
        }
    };

    const [mutateBusinesPartnerParingRequestChallenge, { isLoading }] = useMutation(requestBusinessPartnerPairingChallenge, {
        onError: (err: IApiResponse) => {
            if (err?.error?.code === 2005) {
                setError('smsCode', {
                    type: 'incorrect-sms-code',
                    message: t('common.input-rules.incorrect-sms-code'),
                });
            } else {
                onError();
            }
        },
        onSuccess: (data) => {
            if (data) {
                onVerified(data);
            } else {
                onError();
            }
        },
    });

    const requestResendBusinessPartnerPairRequest = async () => {
        if (loggedInCustomer?.id) {
            return api.customers.resendBusinessPartnerPairRequest(loggedInCustomer.id, { challengeId: challengeUuid }, { secure: true });
        }
    };

    const [mutateResendSmsCode, { isLoading: isLoadingResend }] = useMutationWithError(requestResendBusinessPartnerPairRequest, {
        onSuccess: () => {
            addToast(t('phone-validation.sms-sent'), {
                appearance: 'success',
            });
        },
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error?.code === 2006) {
                addToast(t('phone-validation.reached-sms-request-limit'), {
                    appearance: 'error',
                });
                return true;
            }
            return false;
        },
    });

    return (
        <>
            <Breadcrumb goBack={goBack} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4 className="text-center">
                    <Trans i18nKey="delivery-point.add-delivery-point.enter-phone.title">Overenie telefónneho čísla</Trans>
                </h4>
                <p className="text-center">
                    <Trans
                        i18nKey="delivery-point.add-delivery-point.enter-phone.msg"
                        defaults="Verifikačný kód bol poslaný na telefónne číslo uvedené na zadanej zmluve, končiace: <bold>{lastThreePhoneDigits}</bold>. V
                        prípade, že Vám neprišiel sms kód kontaktujte našu zákaznícku linku."
                        values={{ lastThreePhoneDigits: lastThreePhoneDigits }}
                        components={{ bold: <strong /> }}
                    />
                </p>

                {(isLoading || isLoadingResend) && <LoadingIndicator />}

                <Input
                    ref={register(requiredTrimed)}
                    trigger={trigger}
                    errors={errors}
                    type="text"
                    name="smsCode"
                    label={t('phone-validation.enter-sms-code')}
                />

                <button type="submit" className="btn btn-primary btn-block btn-lg">
                    <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                </button>

                <div className="text-center mt-5">
                    <Trans i18nKey="phone-validation.sms-not-received">Neprišiel vám SMS kód?</Trans>
                    <br />
                    <ClickableElement onClick={() => mutateResendSmsCode()} isText>
                        <Trans i18nKey="phone-validation.resend-sms-code">Poslať znova</Trans>
                    </ClickableElement>
                </div>
            </form>
        </>
    );
};

export default VerifyBusinessPartnerPhoneNumberScreen;
