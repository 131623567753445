import React from 'react';
import { Trans } from 'react-i18next';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

type PropsType = {
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const CantLoginViaSocialCard: React.FC<PropsType> = ({ activeCard, toggleActive }) => {
    const index = 3;
    return (
        <Card>
            <CardHeader role="tab" id={`heading${index}`}>
                <a
                    className={activeCard === index ? '' : 'collapsed'}
                    onClick={(event) => toggleActive(event, index)}
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                >
                    <div className="d-flex">
                        <strong>
                            <Trans i18nKey="login-issues.login-via-social">Neviem sa prihlásiť cez Facebook alebo Google</Trans>
                        </strong>
                        <i className="icon-chevron-down-16 la-xs text-secondary ml-auto my-auto"></i>
                    </div>
                </a>
            </CardHeader>
            <Collapse isOpen={activeCard === index}>
                <CardBody>
                    <p>
                        <Trans i18nKey="login-issues.login-via-social-instructions">
                            Ak máte problémy s prihlásením cez svoje Facebook alebo Google konto, najskôr sa uistite, že ste sa do Moje SPP skutočne
                            zaregistrovali cez daný účet/sociálnu sieť. Overte tiež, že zadávate správne heslo. Overiť si to môže tak, že sa daným
                            heslom prihlásite do svojho konta priamo na stránkach facebook.com alebo google.sk. V prípade, že ste heslo zabudli,
                            obnoviť si ho môžete priamo na týchto stránkach. Môže sa stať, že dočasne nefunguje spojenie s Facebook-om alebo Google
                            (výpadok spojenia, aktualizácie). Skúste sa prihlásiť neskôr. Ak problémy pretrvávajú, kontaktujte prosím Zákaznícku linku
                            SPP.
                        </Trans>
                    </p>
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default CantLoginViaSocialCard;
