import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

interface IConfirmDialogProps {
    visible: boolean;
    header?: string | JSX.Element;
    body?: string | JSX.Element;
    confirmButton?: string;
    cancelButton?: string;
    reversedButtonColors?: boolean;
    onClick: (action: 'confirm' | 'cancel') => void;
    onClosed?: () => void;
    style?: CSSProperties;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
    visible,
    header,
    body,
    confirmButton,
    cancelButton,
    reversedButtonColors,
    onClick,
    onClosed,
    style,
}) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={visible} onClosed={onClosed} centered style={style}>
            <ModalBody>
                <button
                    type="button"
                    onClick={() => onClick('cancel')}
                    className="close text-right ml-auto mb-4"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">{header || t('common.confirmation')}</h3>
                <div>{body || t('common.confirmation-question')}</div>
                <div className="row mt-5 justify-content-center">
                    {cancelButton && (
                        <div className="col-md-auto mb-3 mb-md-0" style={{ minWidth: 233 }}>
                            <button
                                type="button"
                                className={`btn btn-block ${reversedButtonColors ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={() => onClick('cancel')}
                            >
                                {cancelButton || t('common.cancel')}
                            </button>
                        </div>
                    )}
                    <div className="col-md-auto" style={{ minWidth: 233 }}>
                        <button
                            type="button"
                            className={`btn btn-block ${reversedButtonColors ? 'btn-secondary' : 'btn-primary'}`}
                            onClick={() => onClick('confirm')}
                        >
                            {confirmButton || t('common.confirm')}
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ConfirmDialog;
