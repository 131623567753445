import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ClickableElement } from '../../../components/common/clickable-element';
import HelpText from '../../../components/help/help-text';
import { ReCaptchaInfoMessage } from '../../../components/recaptcha/ReCaptchaInfoMessage';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../models/model';
import Routes from '../../../routes/routes';

type LocationState = {
    email: string | undefined;
    validTo: string | undefined;
};

function localizedValidTo(validTo: string | undefined, lang: string) {
    if (!validTo) return;
    moment.locale(lang);
    return moment(validTo, 'YYYY-MM-DDTHH:mm:ss:SSSSSS ZZ').format('DD.MM.YYYY HH:mm');
}

const ForgotPasswordSubmitted: React.FC = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { i18n } = useTranslation();
    const api = useApi();
    const [t] = useTranslation();
    const { addToast } = useToasts();
    const [email] = useState(location.state?.email);
    const [validTo] = useState(() => localizedValidTo(location.state?.validTo, i18n.language));
    const [key, setKey] = useState(0);

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchaVisible, setRecaptchaVisible] = useState(false);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';

    const [mutatePasswordRecovery] = useMutationWithError<any, IApiResponse, { email?: string; recaptcha?: string | null }>(
        async ({ email, recaptcha }) =>
            api.customers.resendPasswordRecovery({ email }, { headers: recaptcha ? { 'X-GoogleReCaptcha-Code': recaptcha } : {}, secure: true }),
        {
            onSuccess: () => {
                addToast(<Trans i18nKey="login-issues.resend-link-has-been-sent"></Trans>, { appearance: 'success' });
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;

                if (error?.code === 2006) {
                    addToast(<Trans i18nKey="login-issues.resend-link-called-many-times"></Trans>, { appearance: 'error' });
                    return true;
                }
                return false;
            },
        },
    );

    useEffect(() => {
        if (!email) history.push(Routes.LOGIN_ISSUES);
    }, [email, history]);

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const resend = () => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !recaptchaVisible) {
            setRecaptchaVisible(true);
            return;
        }
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        mutatePasswordRecovery({ email, recaptcha: reCaptchaHelp }).finally(() => {
            if (process.env.REACT_APP_RECAPTCHA === 'true') {
                recaptchaRef?.current?.reset();
                setRecaptchaVisible(false);
                setReCaptchaError(false);
            }
        });
    };

    return (
        <>
            <h2>
                <Trans i18nKey="login-issues.check-your-email">Skontrolujte si email</Trans>
            </h2>
            <h4>
                <Trans i18nKey="login-issues.link-sent">
                    Na adresu {{ email }} sme vám poslali odkaz, na ktorý je potrebné kliknúť do {{ validTo }}
                </Trans>
            </h4>
            <small>
                <Trans i18nKey="login-issues.check-spam">
                    Ak ste aktivačný odkaz neobdržali, skontrolujte si prosím priečinok s nevyžiadanou poštou (spamom).
                </Trans>

                <div className="mt-4">
                    <HelpText screen="COM" field="COM_FAST_CONTACT" />
                </div>
            </small>

            <div className="text-center mt-5">
                <div style={{ display: recaptchaVisible ? 'block' : 'none' }}>
                    <ReCAPTCHA
                        key={key}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA === 'true' ? recaptchaSiteKey : 'undefined'}
                        onChange={resend}
                        hl={i18n.language === 'en' ? 'en' : 'sk'}
                    />
                    {reCaptchaError && <span style={{ color: 'red', fontWeight: 'bold' }}>{t('common.input-rules.required')}</span>}
                </div>
                <Trans
                    i18nKey="registration.email-not-received-resend"
                    components={{ Button: <ClickableElement className="text-decoration-underline-inverse" isText onClick={resend} /> }}
                >
                    Neprišiel vám e-mail?
                    <br />
                    <button>Poslať znova</button>
                </Trans>
                <div style={{ display: recaptchaVisible ? 'block' : 'none' }}>
                    <ReCaptchaInfoMessage />
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordSubmitted;
