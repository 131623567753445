import { useCallback, useEffect, useState } from 'react';

export function useDebouncing(): (callback: () => unknown, msDelay: number) => void {
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const handler = useCallback((callback: () => unknown, msDelay: number) => {
        if (timer) clearTimeout(timer);
        const newTimer = setTimeout(() => {
            callback();
        }, msDelay);
        setTimer(newTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [timer]);

    return handler;
}
