import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface ILoadingIndicatorProps {
    size?: 'large' | 'medium' | 'small';
    fullscreen?: boolean;
    layer?: 'parent' | 'dialog' | 'always-top' | '10';
    transparentMask?: boolean;
}

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ size, layer, fullscreen, transparentMask }) => {
    const getSpinnerSizeClassName = () => {
        switch (size) {
            case 'large':
                return 'spinner-large';
            case 'medium':
                return 'spinner-medium';
            case 'small':
                return 'spinner-small';
            default:
                return 'spinner-large';
        }
    };

    const getMaskLayerClassName = () => {
        switch (layer) {
            case 'parent':
                return 'loading-mask__layer--parent';
            case 'dialog':
                return 'loading-mask__layer--dialog';
            case 'always-top':
                return 'loading-mask__layer--always-top';
            case '10':
                return 'loading-mask__layer--10';
            default:
                return 'loading-mask__layer--parent';
        }
    };

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => setIsLoaded(true), 0);
        return () => clearTimeout(timeoutId);
    }, []);

    const classNamesList = classNames(
        'loading-indicator fade',
        { show: isLoaded, 'loading-indicator-fullscreen': fullscreen },
        getMaskLayerClassName(),
        { 'transparent-background-mask': transparentMask },
    );

    return (
        <div className={classNamesList}>
            <div className={`spinner ${getSpinnerSizeClassName()}`} />
        </div>
    );
};

export default LoadingIndicator;
