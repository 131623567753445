import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { HelpActions } from '../actions/help-actions';

interface IChatHelpProps extends RouteProps {
    component: React.ComponentType<any>;
    contextualHelp?: string;
}

export const ChatHelpRoute: React.FC<IChatHelpProps> = ({ component, contextualHelp, ...rest }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(HelpActions.setHelpPage(contextualHelp ? contextualHelp : ''));
    }, [contextualHelp, dispatch]);
    const MyComponent = component;
    return <Route {...rest} render={(routeProps) => <MyComponent {...routeProps} />} />;
};
