import {
    SkSppNzpBeApiCustomerprofileBusinessPartner as BusinessPartner,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate as CustomerRequestTemplate,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary as DeliveryPointSummary,
    SkSppNzpBeApiCustomeraccessReCaptchaTokenResponse,
    SkSppNzpBeApiCustomeraccessRegistration,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import {
    IAddress,
    IAdvance,
    IAdvancePaymenWithDpId,
    IAdvancePaymentPeriod,
    IAssumedConsumption,
    IAssumedEeConsumption,
    IAssumedZpConsumption,
    IAttachments,
    IBankConnection,
    ICarbonStopProductLevel,
    IComplainedAdvance,
    IComplainedInvoiceProblemDescription,
    IContact,
    IContractDelivery,
    IContractTerminationReason,
    IDealAmountEe,
    IDealAmountZp,
    IDeliveryPoint,
    IDeliveryPointEeConnection,
    IEeConnectionDetail,
    IInvoice,
    IInvoiceDelivery,
    IMarketingConsents,
    IMeterReading,
    IMeterReadingWithDpId,
    INewAccountInfo,
    IOverpaymentSettlement,
    IPaymentMethod,
    IPreferredContact,
    IProducts,
    ISupplier,
    ITariff,
    ITariffWithDpId,
    IUnderchargeSettlementInvoice,
} from '../../models/customer-request-model';
import { LastNameChangeReasonEnum } from '../../models/enums';
import { CustomerRequestEnum } from '../../views/customer-request/config/enums';
import {
    CustomerRequestBlockType,
    CustomerRequestPartnersDeliveryPointsType,
    CustomerRequestStepType,
} from '../../views/customer-request/config/types';
import { IAdditionalService, IApprovals, IBusinessPartner, IInvoiceNumber } from './../../models/customer-request-model';
import { IRequestPaid, RequestTemplateCodeExtra } from './../../models/model';
import { CustomerRequestMetadataType } from './../../views/customer-request/config/types';

export interface IStep {
    current: number;
    lastOpened: number;
    max: number;
}
export interface ICustomerRequestState {
    key?: CustomerRequestEnum;
    code?: RequestTemplateCodeExtra;
    currentBlock?: CustomerRequestBlockType;
    request: CustomerRequestStepType | undefined;
    step: IStep;
    content: CustomerRequestPayloadType;
    metadata: Partial<CustomerRequestMetadataType>;
    additionalData?: CustomerRequestAdditionalDataType;
    partnersDeliveryPoints?: CustomerRequestPartnersDeliveryPointsType;
    businessPartner?: BusinessPartner;
    deliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPointZp?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPointEe?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    customerRequestTemplate?: CustomerRequestTemplate;
    customerRequestTemplates?: CustomerRequestTemplate[];
    attachments?: IAttachments[];
    send?: boolean;
    invoices?: SkSppNzpBeApiCustomerprofileInvoiceSummary[];
    paid?: IRequestPaid;
    uuid?: string;
}

export enum CustomerRequestActionType {
    ADD_REGISTRATION_BLOCK = 'customerRequest/ADD_REGISTRATION_BLOCK',
    SET_UUID = 'customerRequest/SET_UUID',
    SET_REQUEST_KEYS = 'customerRequest/SET_REQUEST_KEYS',
    SET_REQUEST = 'customerRequest/SET_REQUEST',
    CLOSE_REQUEST = 'customerRequest/CLOSE_REQUEST',
    SET_STEP = 'customerRequest/SET_STEP',
    NEXT_STEP = 'customerRequest/NEXT_STEP',
    PREVIOUS_STEP = 'customerRequest/PREVIOUS_STEP',
    OPEN_STEP = 'customerRequest/OPEN_STEP',
    SET_DATA = 'customerRequest/SET_DATA',
    SET_ATTACHMENTS = 'customerRequest/SET_ATTACHMENTS',
    SET_METADATA = 'customerRequest/SET_METADATA',
    REMOVE_REGISTRATION_BLOCK = 'customerRequest/REMOVE_REGISTRATION_BLOCK',
    REPLACE_BLOCKS = 'customerRequest/REPLACE_BLOCKS',
    SET_PARTNERS_DELIVERY_POINTS = 'customerRequest/SET_PARTNERS_DELIVERY_POINTS',
    SET_BUSINESS_PARTNER = 'customerRequest/SET_BUSINESS_PARTNER',
    SET_DELIVERY_POINT = 'customerRequest/SET_DELIVERY_POINT',
    SET_DELIVERY_POINT_ZP = 'customerRequest/SET_DELIVERY_POINT_ZP',
    SET_DELIVERY_POINT_EE = 'customerRequest/SET_DELIVERY_POINT_EE',
    SET_CUSTOMER_REQUEST_TEMPLATE = 'customerRequest/SET_CUSTOMER_REQUEST_TEMPLATE',
    SET_CUSTOMER_REQUEST_TEMPLATES = 'customerRequest/SET_CUSTOMER_REQUEST_TEMPLATES',
    SET_ADDITIONAL_DATA = 'customerRequest/SET_ADDITIONAL_DATA',
    CLEAR_ADDITIONAL_DATA = 'customerRequest/CLEAR_ADDITIONAL_DATA',
    SET_SEND = 'customerRequest/SET_SEND',
    SET_INVOICES = 'customerRequest/SET_INVOICES',
    SET_DELIVERY_POINTS_EE = 'customerRequest/SET_DELIVERY_POINTS_EE',
    SET_DELIVERY_POINTS_ZP = 'customerRequest/SET_DELIVERY_POINTS_ZP',
    SET_REQUEST_IS_PAID = 'customerRequest/SET_REQUEST_IS_PAID',
}

export type CustomerRequestAction = {
    type: CustomerRequestActionType;
    key?: CustomerRequestEnum;
    code?: RequestTemplateCodeExtra;
    request?: CustomerRequestStepType;
    step?: number;
    stepBlock?: IStep;
    payload?: CustomerRequestPayloadType;
    metadata?: CustomerRequestMetadataType;
    blocks?: CustomerRequestBlockType[];
    partnersDeliveryPoints?: CustomerRequestPartnersDeliveryPointsType;
    businessPartner?: BusinessPartner;
    deliveryPoint?: DeliveryPointSummary;
    deliveryPointZp?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPointEe?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPointsZp?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
    deliveryPointsEe?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
    customerRequestTemplate?: CustomerRequestTemplate;
    customerRequestTemplates?: CustomerRequestTemplate[];
    additionalData?: CustomerRequestAdditionalDataType;
    attachments?: IAttachments[];
    send?: boolean;
    invoices?: SkSppNzpBeApiCustomerprofileInvoiceSummary[];
    paid?: IRequestPaid;
    uuid?: string;
};

export type CustomerRequestAdditionalDataType = {
    udpd?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    udpuid?: string;
    bpuuid?: string;
    dpuuid?: string;
};

export type CustomerRequestPayloadType = {
    accountInfo?: INewAccountInfo;
    additionalServices?: IAdditionalService[];
    address?: IAddress;
    advance?: IAdvance;
    advancePayment?: IAdvance;
    advancePaymentPeriod?: IAdvancePaymentPeriod;
    advancePayments?: IAdvancePaymenWithDpId[];
    amount?: number;
    approvals?: IApprovals;
    assumedConsumption?: IAssumedConsumption;
    assumedConsumptionEe?: IAssumedEeConsumption;
    assumedConsumptionZp?: IAssumedZpConsumption;
    bankConnection?: IBankConnection;
    billingDelivery?: IInvoiceDelivery;
    businessPartner?: Partial<IBusinessPartner>;
    consent?: IMarketingConsents;
    complainedAdvances?: IComplainedAdvance[];
    complainedInvoice?: IInvoice;
    complainedInvoiceProblemDescription?: IComplainedInvoiceProblemDescription | string;
    complaint?: string;
    confirmationCode?: string;
    connection?: IEeConnectionDetail;
    connectionDetailEe?: IEeConnectionDetail;
    contact?: IContact;
    contractDelivery?: IContractDelivery;
    contractTerminationDate?: string;
    contractTerminationReason?: IContractTerminationReason;
    correspondenceAddress?: IAddress;
    date?: string;
    dealAmount?: IDealAmountEe | IDealAmountZp;
    dealAmountEe?: IDealAmountEe;
    dealAmountZp?: IDealAmountZp;
    deliveryPoint?: IDeliveryPoint;
    deliveryPointConnectionZp?: IDeliveryPointEeConnection;
    deliveryPointConnectionEe?: IDeliveryPointEeConnection;
    deliveryPointsZP?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] | SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    deliveryPointsEE?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] | SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    deliveryPointsForRates?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    eeAssumedConsumption?: IAssumedEeConsumption;
    eeMeterReading?: IMeterReading;
    eeTariff?: ITariff;
    finalInvoiceDelivery?: IInvoiceDelivery;
    invoiceDelivery?: IInvoiceDelivery;
    invoiceNumber?: string;
    invoice?: SkSppNzpBeApiCustomerprofileInvoice;
    invoiceNumbers?: IInvoiceNumber[];
    lastInvoice?: IInvoiceDelivery;
    lastNameChangeReason?: LastNameChangeReasonEnum;
    meterNumber?: string;
    meterReading?: IMeterReading;
    meterReadings?: IMeterReadingWithDpId[];
    meterReadingEe?: IMeterReading;
    meterReadingZp?: IMeterReading;
    newAddress?: IAddress;
    newBankConnection?: IBankConnection;
    newBusinessPartner?: IBusinessPartner;
    originalCustomerBusinessPartner?: BusinessPartner;
    newBusinessPartnerData?: IBusinessPartner;
    newBusinessPartnerAddress?: IAddress;
    newBusinessPartnerAdvancePaymentPeriod?: IAdvancePaymentPeriod;
    newBusinessPartnerContact?: IContact;
    newBusinessPartnerCorrespondenceAddress?: IAddress;
    newBusinessPartnerInvoiceDelivery?: IInvoiceDelivery;
    newBusinessPartnerPaymentMethod?: IPaymentMethod;
    newCorrespondenceAddress?: IAddress;
    newCustomerEmail?: string;
    newDeliveryPointAddress?: IAddress;
    newLastName?: string;
    note?: string;
    overpaymentSettlement?: IOverpaymentSettlement | IBankConnection;
    owner?: IBusinessPartner;
    ownerAddress?: IAddress;
    ownerContact?: IContact;
    ownerCorrespondenceAddress?: IAddress;
    ownerAttachments?: IAttachments[];
    paymentMethod?: IPaymentMethod;
    preferredContact?: IPreferredContact;
    problemDescription?: IComplainedInvoiceProblemDescription | string;
    products?: IProducts;
    productLevel?: ICarbonStopProductLevel;
    transcriptRequestId?: string;
    rates?: ITariffWithDpId[];
    reading?: IMeterReading;
    readings?: IMeterReading[];
    reason?: string;
    reCaptchaToken?: SkSppNzpBeApiCustomeraccessReCaptchaTokenResponse;
    registration?: SkSppNzpBeApiCustomeraccessRegistration;
    saveRequested?: boolean;
    saved?: boolean;
    savedRequestBlocks?: CustomerRequestBlockType[];
    selectedDPorBPCountry?: SkSppNzpCommonsApiCodelistCodeListItem;
    statement?: boolean;
    supplier?: ISupplier;
    underchargeSettlementInvoice?: IUnderchargeSettlementInvoice;
    tariff?: ITariff;
    tariffEe?: ITariff;
    tariffZp?: ITariff;
    tariffs?: ITariffWithDpId[];
    zpAssumedConsumption?: IAssumedZpConsumption;
    zpMeterReading?: IMeterReading;
    zpTariff?: ITariff;
};
