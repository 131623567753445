import classNames from 'classnames';
import React, { CSSProperties, ReactElement, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { accessNestedProperty } from '../../../utils/utils';
import Typeahead from '../typeahead/type-ahead';
import './style.scss';

interface IAutoComplete<T> {
    options: T[];
    onSelect: (val: T[]) => void;
    onChange?: (val: string) => void;
    multiple?: boolean;
    defaultSelected?: T[];
    placeholder: string;
    errors?: FieldErrors;
    name?: string;
    onResize?: (width: number, height: number) => void;
    disabled?: boolean;
    inputGroupAppendChildren?: ReactElement;
    style?: CSSProperties;
    hideChevron?: boolean;
    hideInputWhenOptionSelected?: boolean;
    inFilter?: boolean;
    className?: string;
    loading?: boolean;
    menuOptionsMaxWidth?: string;
}

function AutoComplete<T>({
    options,
    onSelect,
    onChange,
    multiple,
    defaultSelected,
    placeholder,
    onResize,
    disabled,
    inputGroupAppendChildren,
    style,
    hideChevron,
    hideInputWhenOptionSelected,
    inFilter,
    errors,
    name,
    loading,
    className,
    menuOptionsMaxWidth,
}: IAutoComplete<T>): ReactElement {
    const { t } = useTranslation();
    const [focused, setFocused] = useState(false);
    const [optionsShown, setOptionsShown] = useState(false);

    const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));
    const isValid = errors == null || errorProperty == null;

    return (
        <InputGroup
            style={style ? style : { zIndex: optionsShown ? 100 : 0, position: 'relative', overflow: optionsShown ? '' : 'hidden' }}
            className={classNames({ 'is-focused': focused, 'form-control-filter': inFilter, 'is-invalid': !isValid })}
        >
            <Typeahead
                labelKey="name"
                multiple={multiple}
                onSelect={onSelect}
                onChange={onChange}
                placeholder={placeholder}
                options={options}
                defaultSelected={defaultSelected}
                emptyLabel={t('common.no-match')}
                hideChevron={hideChevron}
                hideInputWhenOptionSelected={hideInputWhenOptionSelected}
                optionsShown={optionsShown}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onResize={onResize}
                disabled={disabled}
                setOptionsShown={setOptionsShown}
                loading={loading}
                errors={errors}
                name={name}
                className={className}
                menuOptionsMaxWidth={menuOptionsMaxWidth}
            />
            {!!inputGroupAppendChildren && <InputGroupAddon addonType="append">{inputGroupAppendChildren}</InputGroupAddon>}
        </InputGroup>
    );
}

export default AutoComplete;
