import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { ClickableElement } from '../../../../components/common/clickable-element';
import { ReservedValuesEnum } from '../../../../models/enums';
import { CustomerRequestFieldUpdateType } from '../../../../models/model';
import { DetailContactFormFieldEnum } from '../../../../reducers/interfaces/delivery-point-detail-state';
import { formatDateToPreview, MAX_DATE_SAP } from '../../../../utils/date-utils';
import { formatAddress } from '../../../../utils/utils';
import { ChangeDeliveryPointAddressModal } from './contact-data-modals/change-delivery-point-address-modal';
import FormListItem from './form-list-item';
import { ForChangeContactSalesManagerModal } from './payment-data-modals/for-change-contact-sales-manager-modal';

interface IDeliveryPointDetailContractData {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    canEditOverride: boolean;
}

const getReadingMonthTranslation = (t: any, readingMonth?: number) => {
    switch (readingMonth) {
        case 1:
            return t('delivery-point.detail.payment-data.reading-month.january');
        case 2:
            return t('delivery-point.detail.payment-data.reading-month.february');
        case 3:
            return t('delivery-point.detail.payment-data.reading-month.march');
        case 4:
            return t('delivery-point.detail.payment-data.reading-month.april');
        case 5:
            return t('delivery-point.detail.payment-data.reading-month.may');
        case 6:
            return t('delivery-point.detail.payment-data.reading-month.june');
        case 7:
            return t('delivery-point.detail.payment-data.reading-month.july');
        case 8:
            return t('delivery-point.detail.payment-data.reading-month.august');
        case 9:
            return t('delivery-point.detail.payment-data.reading-month.september');
        case 10:
            return t('delivery-point.detail.payment-data.reading-month.october');
        case 11:
            return t('delivery-point.detail.payment-data.reading-month.november');
        case 12:
            return t('delivery-point.detail.payment-data.reading-month.december');
        default:
            return '-';
    }
};

const DeliveryPointDetailContractData: React.FC<IDeliveryPointDetailContractData> = ({ deliveryPoint, canEditOverride }) => {
    const { t } = useTranslation();
    const [contactSalesManagerModalOpen, setContactSalesManagerModalOpen] = useState(false);
    const [label, setLabel] = useState('');
    const [reservedValueType, setReservedValueType] = useState<ReservedValuesEnum>();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [editedFormField, setEditedFormField] = useState<DetailContactFormFieldEnum | undefined>();
    const onStartEditFormField = (formField: DetailContactFormFieldEnum) => () => {
        setEditedFormField(formField);
    };
    const onModalClose = () => {
        setEditedFormField(undefined);
    };

    const hasDeliveryPointRequest = (type: CustomerRequestFieldUpdateType) => {
        const fieldUpdates = deliveryPoint.fieldUpdates?.filter((fu) => fu.type === type);
        const customerRequestUuids = fieldUpdates?.map((x) => x.customerRequest?.uuid).filter((x, i, a) => a.indexOf(x) === i) || [];
        return {
            result: fieldUpdates != null && fieldUpdates?.length > 0,
            customerRequestUuid: customerRequestUuids.length === 1 ? customerRequestUuids[0] : undefined,
        };
    };

    const getTariffCountText = (): string => {
        switch (deliveryPoint.contract?.eeTariffCount) {
            case 1:
                return t('delivery-point.detail.contract-data.tariff-count-single-tariff');
            case 2:
                return t('delivery-point.detail.contract-data.tariff-count-dual-tariff');
            default:
                return '-';
        }
    };

    const labelForModal = (type: ReservedValuesEnum) => {
        if (type === ReservedValuesEnum.MRK) {
            return t('delivery-point.detail.consumption.contact-sale-manager-maximal-reserved-capacity');
        }
        if (type === ReservedValuesEnum.RK) {
            return t('delivery-point.detail.consumption.contact-sale-manager-reserved-capacity');
        }
        return t('delivery-point.detail.consumption.contact-sale-manager-maximal-daily-capacity');
    };

    const deliveryPointAddressRequest = hasDeliveryPointRequest('DELIVERY_POINT_ADDRESS');

    return (
        <>
            <div className="card card-without-border py-2 px-1">
                <ul className="list-group list-group-flush">
                    <FormListItem
                        valueText={
                            <h3 className="mt-2">
                                <Trans i18nKey="delivery-point.detail.contract-data.title">Zmluvné údaje</Trans>
                            </h3>
                        }
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contract-data.contract-account-number">Číslo zmluvného účtu</Trans>}
                        valueText={<span className="attribute-info">{deliveryPoint.contract?.contractAccount?.externalId || '-'}</span>}
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contract-data.contract-number">Číslo zmluvy</Trans>}
                        valueText={<span className="attribute-info">{deliveryPoint.contract?.externalId || '-'}</span>}
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contract-data.meter-device-number">Číslo meradla</Trans>}
                        valueText={<span className="attribute-info">{deliveryPoint.deviceNumber || '-'}</span>}
                    />
                    {deliveryPoint.type === 'ZP' ? (
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.POD-number">POD</Trans>}
                            valueText={<span className="attribute-info">{deliveryPoint.pod || '-'}</span>}
                        />
                    ) : (
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.EIC-number">EIC</Trans>}
                            valueText={<span className="attribute-info">{deliveryPoint.eic || '-'}</span>}
                        />
                    )}
                    <Collapse isOpen={isExpanded}>
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.contract-binding">Viazanosť zmluvy</Trans>}
                            valueText={
                                <span className="attribute-info">
                                    {deliveryPoint.contract?.bindingTo === MAX_DATE_SAP || deliveryPoint.contract?.bindingTo === null ? (
                                        <Trans i18nKey="delivery-point.detail.contract-data.unlimited" />
                                    ) : (
                                        formatDateToPreview(deliveryPoint.contract?.bindingTo) || '-'
                                    )}
                                </span>
                            }
                        />
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.contract-valid">Platnosť zmluvy</Trans>}
                            valueText={
                                <span className="attribute-info">{`${t('delivery-point.detail.contract-data.from')} ${formatDateToPreview(
                                    deliveryPoint.contract?.effectiveFrom,
                                ) || '-'} ${
                                    deliveryPoint.contract?.effectiveTo === MAX_DATE_SAP || deliveryPoint.contract?.effectiveTo === null
                                        ? t('delivery-point.detail.contract-data.unlimited-small')
                                        : t('delivery-point.detail.contract-data.to') + formatDateToPreview(deliveryPoint.contract?.effectiveTo) ||
                                          '-'
                                }`}</span>
                            }
                        />
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.signature-at">Dátum uzatvorenia zmluvy</Trans>}
                            valueText={
                                <span className="attribute-info">
                                    {deliveryPoint.contract?.signatureAt === MAX_DATE_SAP || deliveryPoint.contract?.signatureAt === null ? (
                                        <Trans i18nKey="delivery-point.detail.contract-data.unlimited" />
                                    ) : (
                                        formatDateToPreview(deliveryPoint.contract?.signatureAt) || '-'
                                    )}
                                </span>
                            }
                        />
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.payment-data.bill-cycle">Fakturačný cyklus</Trans>}
                            valueText={<span className="attribute-info">{deliveryPoint.contract?.billCycle?.name || '-'}</span>}
                        />
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.payment-data.reading-cycle-type">Odpočtový cyklus</Trans>}
                            valueText={
                                <span className="attribute-info">{`${deliveryPoint.readingCycleType?.name || '-'} ${
                                    !!deliveryPoint.readingCycleInfo?.period
                                        ? `(${getReadingMonthTranslation(t, deliveryPoint.readingCycleInfo?.period)})`
                                        : ''
                                } `}</span>
                            }
                        />
                        {deliveryPoint.contract?.noticePeriod != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.notice-period">Výpovedná doba</Trans>}
                                valueText={
                                    <span className="attribute-info">
                                        {
                                            <Trans
                                                i18nKey="delivery-point.detail.contract-data.notice-period-days-count"
                                                values={{ count: deliveryPoint.contract?.noticePeriod || 0 }}
                                            ></Trans>
                                        }
                                    </span>
                                }
                            />
                        )}
                        {deliveryPoint.type === 'EE' && deliveryPoint.contract?.eeTariffCount != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.tariff-count">Tarifikácia</Trans>}
                                valueText={<span className="attribute-info">{getTariffCountText()}</span>}
                            />
                        )}
                        {deliveryPoint.circuitPhaseCount != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.circuit-phase-count">Počet fáz</Trans>}
                                valueText={<span className="attribute-info">{deliveryPoint.circuitPhaseCount || '-'}</span>}
                            />
                        )}
                        {deliveryPoint.circuitBreaker != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.circuit-breaker">Hlavný istič</Trans>}
                                valueText={<span className="attribute-info">{deliveryPoint.circuitBreaker || '-'}</span>}
                            />
                        )}
                        {deliveryPoint.meteringIntervalType != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.metering-interval-type">Typ merania</Trans>}
                                valueText={<span className="attribute-info">{deliveryPoint.meteringIntervalType || '-'}</span>}
                            />
                        )}
                        {deliveryPoint.distributionTariff != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.distribution-tariff">Distribučná tarifa</Trans>}
                                valueText={<span className="attribute-info">{deliveryPoint.distributionTariff || '-'}</span>}
                            />
                        )}
                        {deliveryPoint.maximumDailyAmount?.amount && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.maximum-daily-amount"></Trans>}
                                valueText={
                                    <span className="attribute-info">{`${deliveryPoint.maximumDailyAmount?.amount || '-'} ${
                                        deliveryPoint.maximumDailyAmount?.units
                                    }`}</span>
                                }
                                onClick={() => {
                                    setReservedValueType(ReservedValuesEnum.DMM);
                                    setLabel(labelForModal(ReservedValuesEnum.DMM));
                                    setContactSalesManagerModalOpen(true);
                                }}
                                isEditable={deliveryPoint.contract?.contractAccount?.businessPartner?.queue === 'INDIVIDUAL'}
                            />
                        )}
                        {deliveryPoint.reserveAmount?.amount && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.reserve-amount"></Trans>}
                                valueText={
                                    <span className="attribute-info">{`${deliveryPoint.reserveAmount?.amount || '-'} ${
                                        deliveryPoint.reserveAmount?.units
                                    }`}</span>
                                }
                                onClick={() => {
                                    setReservedValueType(ReservedValuesEnum.RK);
                                    setLabel(labelForModal(ReservedValuesEnum.RK));
                                    setContactSalesManagerModalOpen(true);
                                }}
                                isEditable={deliveryPoint.contract?.contractAccount?.businessPartner?.queue === 'INDIVIDUAL'}
                            />
                        )}
                        {deliveryPoint.maximumReserveAmount?.amount && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contract-data.maximum-reserve-amount"></Trans>}
                                valueText={
                                    <span className="attribute-info">{`${deliveryPoint.maximumReserveAmount?.amount || '-'} ${
                                        deliveryPoint.maximumReserveAmount?.units
                                    }`}</span>
                                }
                                onClick={() => {
                                    setReservedValueType(ReservedValuesEnum.MRK);
                                    setLabel(labelForModal(ReservedValuesEnum.MRK));
                                    setContactSalesManagerModalOpen(true);
                                }}
                                isEditable={deliveryPoint.contract?.contractAccount?.businessPartner?.queue === 'INDIVIDUAL'}
                            />
                        )}
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contract-data.delivery-point-number">Číslo odberného miesta</Trans>}
                            valueText={<span className="attribute-info">{deliveryPoint.externalId || '-'}</span>}
                        />
                        <FormListItem
                            label={<Trans i18nKey="customer-request.steps.new-data.delivery-point-address">Adresa odberného miesta</Trans>}
                            valueText={<span className="attribute-info">{formatAddress(deliveryPoint.address)}</span>}
                            onClick={onStartEditFormField(DetailContactFormFieldEnum.DP_ADDRESS)}
                            isEditable={canEditOverride}
                            isChangeRequested={deliveryPointAddressRequest.result}
                            customerRequestUuid={deliveryPointAddressRequest.customerRequestUuid}
                        />
                    </Collapse>
                    <li className="list-group-item p-0">
                        <hr className="mb-0" />
                    </li>
                    <FormListItem
                        valueText={
                            <ClickableElement className={isExpanded ? '' : 'collapsed'} onClick={() => setIsExpanded(!isExpanded)}>
                                <div className="font-weight-bold d-flex">
                                    <i className={`icon-chevron-down-16 la-xs mr-2 my-auto smooth-transition ${isExpanded ? 'rotateZ-180' : ''}`} />
                                    {isExpanded ? t('delivery-point.detail.contract-data.less') : t('delivery-point.detail.contract-data.more')}
                                </div>
                            </ClickableElement>
                        }
                    />
                </ul>
            </div>
            <ForChangeContactSalesManagerModal
                isOpen={contactSalesManagerModalOpen}
                onClose={() => setContactSalesManagerModalOpen(false)}
                manager={deliveryPoint.contract?.contractAccount?.businessPartner?.accountManager}
                label={label}
                deliveryPoint={deliveryPoint}
                reservedValueType={reservedValueType}
                button
            />
            <ChangeDeliveryPointAddressModal
                isOpen={editedFormField === DetailContactFormFieldEnum.DP_ADDRESS}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
            />
        </>
    );
};

export default DeliveryPointDetailContractData;
