import { SkSppNzpBeApiCustomerrequestCustomerRequest, SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    CodeListTypeEnum,
    CommodityEnum,
    CustomerRequestTypeEnum,
    dateFromIsoToFormat,
    LoadingIndicator,
    QueryKeysEnum,
    RequestTemplateCode,
    useApi,
    useCodeList,
} from '../../../../../main';
import { IAttachments, IComplainedInvoiceProblemDescription } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatDate } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { InvoiceType } from '../../../../invoices/components/invoice-type';
import { ReasonEnum } from '../block-problem-description/block-problem-description-short';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from './components/item-preview';
import AppendixItem from './items/appendix-item';
import ComplainedAdvanceItemInvoiceShort from './items/complained-advance-item-invoice-short';

export const BlockCompletionClaimInvoice: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { content, deliveryPoint, attachments = [], businessPartner, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState<boolean>(false);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const invoice = content.invoice;
    const problemDescription = content.complainedInvoiceProblemDescription as IComplainedInvoiceProblemDescription;
    const initializeRequestCreationProcess = useSendRequest();
    const api = useApi();
    const [template, setTemplate] = useState<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate>();
    const [files, setFiles] = useState<IAttachments[]>(attachments);
    const { addToast } = useToasts();

    useEffect(() => {
        dispatch(CustomerRequestActions.setAttachments(files));
    }, [files, dispatch]);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    useEffect(() => {
        if (content.problemDescription === ReasonEnum.INCORRECT_ADVANCE_PAYMENTS) {
            getRequestTemplates(
                { code: CustomerRequestTypeEnum.ZOM_RPVF },
                {
                    onSuccess: (data) => {
                        if (data && data[0] != null) {
                            const foundTemplate = data[0];
                            setTemplate(foundTemplate);
                        } else {
                            addToast(t('error-customer-request.default'), {
                                appearance: 'error',
                            });
                        }
                    },
                    onError: () => {
                        addToast(t('error-customer-request.default'), {
                            appearance: 'error',
                        });
                    },
                },
            );
        }
    }, [content.problemDescription, getRequestTemplates, addToast, t]);

    const createRequest = () => {
        let data: SkSppNzpBeApiCustomerrequestCustomerRequest = { content: {}, customerRequestTemplate: {} };
        if (content.problemDescription === ReasonEnum.INCORRECT_ADVANCE_PAYMENTS && template) {
            const metadata = {
                businessPartnerId: businessPartner?.id,
                deliveryPointId: deliveryPoint?.id,
                contractId: deliveryPoint?.contract?.id,
            };

            const requestContent = {
                complainedInvoice: {
                    vs: invoice?.reference,
                    issueDate: invoice?.issueAt,
                },
                complainedAdvances: content.complainedAdvances,

                entityReferences: [
                    {
                        contractId: deliveryPoint?.contract?.id,
                        deliveryPointId: deliveryPoint?.id,
                        contractAccountId: invoice?.contractAccount?.id,
                    },
                ],
            };

            data = {
                content: { metadata, ...requestContent, type: CustomerRequestTypeEnum.ZOM_RPVF },
                customerRequestTemplate: template,
            };
        } else {
            if (customerRequestTemplate) {
                const content = {
                    complainedInvoice: {
                        vs: invoice?.reference,
                        issueDate: invoice?.issueAt,
                    },
                    complainedInvoiceProblemDescription: {
                        problemDescription: {
                            uuid: problemDescription?.problemDescription?.uuid,
                        },
                        meterNumber: problemDescription?.meterNumber,
                        requiredMeterReading: problemDescription?.requiredMeterReading,
                        requiredMeterReadingNT: problemDescription?.requiredMeterReadingNT,
                        requiredReadingDate: problemDescription?.requiredReadingDate,
                    },
                    note: problemDescription?.description,

                    entityReferences: [
                        {
                            contractId: deliveryPoint?.contract?.id,
                            deliveryPointId: deliveryPoint?.id,
                            contractAccountId: invoice?.contractAccount?.id,
                        },
                    ],
                };

                const metadata = {
                    businessPartnerId: businessPartner?.id,
                    processManualOverride:
                        problemDescription.problemDescription.code === ReasonEnum.WRONG_METER_READING && businessPartner?.queue === 'INDIVIDUAL'
                            ? true
                            : false,
                };

                data = {
                    content: { metadata, ...content, type: customerRequestTemplate.code },
                    customerRequestTemplate: customerRequestTemplate,
                };
            }
        }

        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(data, files, setFiles)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => {
                if (err.message === 'Files error') {
                    nextTick(() => {
                        dispatch(CustomerRequestActions.previousStep());
                    });
                }
            })
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const onRequestSentSuccessConfirmed = () => {
        history.goBack();
    };

    const { data: invoiceTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_INVOICE_TYPE,
        codeListTypeEnum: CodeListTypeEnum.INVOICE_TYPE,
        paging: {
            size: 64,
        },
    });

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <div className="text-center my-2 my-lg-5">
                <h3>{<Trans i18nKey="customer-request.steps.completion.title">Dokončenie</Trans>}</h3>
                <p>
                    {<Trans i18nKey="customer-request.steps.reason-for-claim-detail.completion-info">Skontrolujte údaje a odošlite žiadosť.</Trans>}
                </p>
            </div>
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.complained-invoice.title')}>
                    {deliveryPoint?.address != null && (
                        <ItemPreviewContent bold>
                            {invoice && (
                                <>
                                    <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />{' '}
                                    <Trans i18nKey="customer-request.steps.reason-for-claim.number">č.</Trans> {invoice.reference} (
                                    {t('invoices.detail.date-issue')} {invoice?.issueAt && formatDate(new Date(invoice?.issueAt))})
                                    <br />
                                    {formatAddress(deliveryPoint.address)} -{' '}
                                    {deliveryPoint.type === 'EE' ? t('enums.CommodityEnum.EE') : t('enums.CommodityEnum.ZP')}
                                </>
                            )}
                        </ItemPreviewContent>
                    )}
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.reason-for-claim.title')}>
                    <ItemPreviewContent bold>
                        {problemDescription ? (
                            problemDescription.problemDescription.name
                        ) : (
                            <Trans i18nKey={`enums.ResonForClaimInvoice.${content.problemDescription}`} />
                        )}
                    </ItemPreviewContent>
                </ItemPreview>

                {(content.problemDescription === ReasonEnum.WRONG_TARIFF ||
                    content.problemDescription === ReasonEnum.OTHER ||
                    content.problemDescription === ReasonEnum.WRONG_CUSTOMER_DATA) && (
                    <ItemPreview title={t('customer-request.steps.reason-for-claim-detail.detailed-description')}>
                        <ItemPreviewContent bold>{problemDescription.description}</ItemPreviewContent>
                    </ItemPreview>
                )}
                {content.problemDescription === ReasonEnum.INCORRECT_ADVANCE_PAYMENTS && (
                    <ItemPreview title={t('customer-request.steps.reason-for-claim-detail.claimed-advance-payments')}>
                        {content.complainedAdvances?.map((advancePayment, index) => (
                            <ItemPreviewContent bold key={`advance_payments_${index}`}>
                                <ComplainedAdvanceItemInvoiceShort
                                    data={advancePayment}
                                    order={index + 1}
                                    count={content.complainedAdvances?.length}
                                />
                            </ItemPreviewContent>
                        ))}
                    </ItemPreview>
                )}
                {content.problemDescription === ReasonEnum.WRONG_METER_READING && (
                    <ItemPreview title={t('customer-request.steps.reason-for-claim-detail.title-2')}>
                        <ItemPreviewContent bold>
                            <ItemPreviewHeader title={t('customer-request.steps.problem-description.gauge-number')} />
                            <ItemPreviewContent bold>{problemDescription.meterNumber}</ItemPreviewContent>
                            <ItemPreviewHeader title={t('customer-request.steps.reason-for-claim-detail.date-of-meter-reading')} />
                            <ItemPreviewContent bold>{dateFromIsoToFormat(problemDescription.requiredReadingDate, 'DD.MM.YYYY')}</ItemPreviewContent>
                            <ItemPreviewHeader
                                title={`Požadovaný stav meradla ku ${dateFromIsoToFormat(problemDescription.requiredReadingDate, 'DD.MM.YYYY')} `}
                            />
                            <ItemPreviewContent bold>
                                {problemDescription.requiredMeterReadingNT ? (
                                    <div>
                                        <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" /> :{' '}
                                        {problemDescription.requiredMeterReading}
                                        {' kwh'} <br />
                                        <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" /> :{' '}
                                        {problemDescription.requiredMeterReadingNT}
                                        {' kwh'}
                                    </div>
                                ) : (
                                    <>
                                        {problemDescription.requiredMeterReading}
                                        {invoice?.deliveryPoints &&
                                        invoice?.deliveryPoints.length > 0 &&
                                        invoice?.deliveryPoints[0].type === CommodityEnum.GAS ? (
                                            <>
                                                {' '}
                                                m<sup>3</sup>
                                            </>
                                        ) : (
                                            ' kWh'
                                        )}
                                    </>
                                )}
                            </ItemPreviewContent>
                        </ItemPreviewContent>
                    </ItemPreview>
                )}

                {attachments && <AppendixItem data={attachments} bold />}
            </ListGroup>

            <Button size="lg" color="primary" block onClick={createRequest} className="mt-5">
                <Trans i18nKey="customer-request.steps.reason-for-claim.send-claim" />
            </Button>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
