import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { QueryKeysEnum } from '../../../../../../main';
import { IAdvancePaymentPeriod } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAdvancePaymentPeriod;
    bold?: boolean;
    requestContent?: any;
};

const AdvancePaymentPeriodItem: React.FC<Props> = ({ data, bold, requestContent }) => {
    const [t] = useTranslation();

    const { data: periodCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum[data.period.type as keyof typeof CodeListTypeEnum] ?? CodeListTypeEnum.ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 30,
        },
    });

    const period = useMemo(() => {
        return periodCodeList?.find((item) => item.uuid === data.period?.uuid)?.name || null;
    }, [periodCodeList, data.period]);

    if (
        (requestContent?.type === CustomerRequestTypeEnum.ZOM_ZODE && !isObjectEmpty(requestContent?.assumedConsumptionEe)) ||
        (requestContent?.type === CustomerRequestTypeEnum.ZOM_ZODP && !isObjectEmpty(requestContent?.assumedConsumptionZp)) ||
        isObjectEmpty(data) ||
        !period
    ) {
        return <></>;
    }
    return (
        <>
            <ItemPreview title={t('customer-request.steps.payment-period.title')}>
                <ItemPreviewContent bold={bold}>{period}</ItemPreviewContent>
            </ItemPreview>
        </>
    );
};

export default AdvancePaymentPeriodItem;
