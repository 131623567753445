import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions';
import { BaseRadio } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import useCodeList from '../../../../../hooks/use-code-list';
import { IInvoiceDelivery } from '../../../../../models/customer-request-model';
import { CustomerAccountTypeEnum, InvoiceDeliveryStepEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { customerEmails, formatBusinessPartnerName, isInsertedEmailCustom } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import InvoiceDeliveryFormFieldsOnlyEmail from './components/invoice-delivery-form-fields-only-email';

const BlockInvoiceDeliveryTranscript: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner, additionalData, request } = useSelector((state: IRootState) => state.customerRequest);
    const { customer } = useSelector((state: IRootState) => state.user);
    const [t] = useTranslation();

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.NEW_BUSINESS_PARTNER_INVOICE_DELIVERY;

    const isEmailCustom = useMemo(
        () => isInsertedEmailCustom(content, additionalData, customer, businessPartner?.email, content.accountInfo?.email, content.contact?.email),
        [content, additionalData, customer, businessPartner?.email],
    );

    const emailList = customerEmails(additionalData, customer, businessPartner?.email, content.accountInfo?.email, content.contact?.email);

    const [displayEmailInput, setDisplayEmailInput] = useState<boolean>(isEmailCustom);
    const [radioButtonInput, setRadioButtonInput] = useState<string | undefined>(
        content.invoiceDelivery?.type === InvoiceDeliveryStepEnum.ADDRESS ? 'post' : content.invoiceDelivery?.email || emailList[0] || '',
    );

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const address = useMemo(() => {
        if (content.invoiceDelivery?.address) {
            return content.invoiceDelivery?.address;
        }
        if (content.correspondenceAddress) {
            return { ...content.correspondenceAddress, name: formatBusinessPartnerName(content.businessPartner) };
        }
        if (content.address) {
            return { ...content.address, name: formatBusinessPartnerName(content.businessPartner) };
        }
        return {
            name: formatBusinessPartnerName(businessPartner),
            street: businessPartner?.primaryAddress?.street,
            number: businessPartner?.primaryAddress?.streetNumber,
            city: businessPartner?.primaryAddress?.city,
            country: countryCodeList?.find((item) => item.code === businessPartner?.primaryAddress?.country),
            postalCode: businessPartner?.primaryAddress?.zipCode,
        };
    }, [content.invoiceDelivery, content.correspondenceAddress, content.address, content.businessPartner, businessPartner, countryCodeList]);

    const objectData = {
        type:
            content.newBusinessPartnerInvoiceDelivery?.type === InvoiceDeliveryStepEnum.ADDRESS
                ? InvoiceDeliveryStepEnum.ADDRESS
                : InvoiceDeliveryStepEnum.EMAIL,
        email: content.invoiceDelivery?.email || emailList[0] || '',
        address: address,
    };

    const { register, handleSubmit, errors, trigger, getValues } = useForm<IInvoiceDelivery>({
        defaultValues: objectData,
    });

    const onSubmit = (data: IInvoiceDelivery) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                type: radioButtonInput === 'post' ? InvoiceDeliveryStepEnum.ADDRESS : InvoiceDeliveryStepEnum.EMAIL,
                email: radioButtonInput === 'post' ? undefined : radioButtonInput === 'custom-email' ? data.email : radioButtonInput,
                address: radioButtonInput === 'post' ? address : undefined,
            },
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    const postLabel = useMemo(() => {
        if (radioButtonInput === 'post') {
            let label = t('customer-request.steps.invoice-delivery.to-address');
            if (content.accountInfo) {
                return (
                    <div>
                        <p>
                            <b>{t('customer-request.steps.invoice-delivery.via-post')}</b>
                        </p>
                        <Trans
                            i18nKey="customer-request.steps.invoice-delivery.to-address-from-for-customer"
                            defaults="Faktúry budeme zasielať na vašu korešpondečnú adresu evidovanú k zákazníckemu číslu {{number}}"
                            values={{
                                number: content.accountInfo.bpNumber,
                            }}
                            components={{ bold: <strong /> }}
                        />
                    </div>
                );
            }
            if (content.correspondenceAddress || content.address) {
                label = t('customer-request.steps.invoice-delivery.to-correspondence-address');
            }
            return (
                <div>
                    <p>
                        <b>{t('customer-request.steps.invoice-delivery.via-post')}</b>
                    </p>
                    {label}:
                    <br />
                    {address.name} <br />
                    {address.street || ''} {address.number || ''}
                    <br />
                    {address.city || ''} {address.postalCode || ''}
                    <br />
                    {address.country?.name}
                </div>
            );
        } else {
            return (
                <div>
                    <p className="mb-0">
                        <b>{t('customer-request.steps.invoice-delivery.via-post')}</b>
                    </p>
                </div>
            );
        }
    }, [content, radioButtonInput, t, address]);

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    const showOptionByPost =
        (request?.type === CustomerRequestTypeEnum.ZOM_ZODE || request?.type === CustomerRequestTypeEnum.ZOM_ZODP) &&
        businessPartner?.queue === CustomerAccountTypeEnum.INDIVIDUAL
            ? true
            : false;

    return (
        <>
            <p className="text-left text-lg mb-4 ">
                {showOptionByPost
                    ? t(`customer-request.steps.invoice-delivery-zp-ee.delivery-option`)
                    : t(`customer-request.steps.invoice-delivery-zp-ee.delivery-option-only-email`)}
            </p>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {!displayEmailInput && (
                    <>
                        {emailList.map((email, index) => (
                            <Card className="mb-3" key={`email_${index}`}>
                                <CardBody>
                                    <BaseRadio
                                        value={businessPartner?.email}
                                        id={`businessPartnerEmailRadio_${index}`}
                                        name={`email_${index}`}
                                        className="mt-0"
                                        label={
                                            <>
                                                <b>{t('customer-request.steps.invoice-delivery.via-email')}</b>
                                                <br />
                                                {email}
                                            </>
                                        }
                                        onClick={() => {
                                            setRadioButtonInput(email);
                                        }}
                                        checked={radioButtonInput === email}
                                    />
                                </CardBody>
                            </Card>
                        ))}
                        {showOptionByPost && (
                            <Card className="mb-3">
                                <CardBody>
                                    <BaseRadio
                                        value={customer?.email}
                                        id={`post`}
                                        name="post"
                                        className="mt-0"
                                        label={postLabel}
                                        onClick={() => {
                                            setRadioButtonInput('post');
                                        }}
                                        checked={radioButtonInput === 'post'}
                                    />
                                </CardBody>
                            </Card>
                        )}
                    </>
                )}

                <div className="mb-4">
                    <div
                        className="cursor-pointer clickable-element "
                        style={{ textAlign: 'center', textDecoration: 'underline' }}
                        onClick={() => {
                            if (displayEmailInput) {
                                setRadioButtonInput(undefined);
                            } else {
                                setRadioButtonInput('custom-email');
                            }
                            setDisplayEmailInput(!displayEmailInput);
                        }}
                    >
                        {displayEmailInput ? (
                            <p>
                                <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.chose-from-list" />
                            </p>
                        ) : (
                            <p className="mb-0">
                                <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.type-email" />
                            </p>
                        )}
                    </div>
                    <div style={displayEmailInput ? {} : { display: 'none' }}>
                        <InvoiceDeliveryFormFieldsOnlyEmail register={register} errors={errors} trigger={trigger} />
                    </div>
                </div>
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockInvoiceDeliveryTranscript;
