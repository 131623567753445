import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IAssumedEeConsumption, IAssumedZpConsumption } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { formatCurrency, isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IAssumedEeConsumption & IAssumedZpConsumption;
    requestContent?: any;
    bold?: boolean;
    code?: string;
};

const AssumedConsumptionItem: React.FC<Props> = ({ data, bold, code, requestContent }) => {
    const [t] = useTranslation();

    const { advancePayment, value, valueHigh, valueLow, date } = data;

    let units = <span></span>;
    if (code === CustomerRequestTypeEnum.ZOM_ZODE) {
        units = <span>kWh</span>;
    }

    if (code === CustomerRequestTypeEnum.ZOM_ZODP) {
        units = (
            <>
                m<sup>3</sup>
            </>
        );
    }

    const { data: periodCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum:
            CodeListTypeEnum[requestContent?.advancePaymentPeriod?.period?.type as keyof typeof CodeListTypeEnum] ??
            CodeListTypeEnum.ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 30,
        },
    });

    const period = useMemo(() => {
        return periodCodeList?.find((item) => item.uuid === requestContent?.advancePaymentPeriod?.period?.uuid)?.name || null;
    }, [periodCodeList, requestContent?.advancePaymentPeriod?.period]);

    if (isObjectEmpty(data)) return <></>;

    return (
        <>
            <ItemPreview>
                {advancePayment && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.electricity-consumption.amount-adnvance-payment')} />
                        <ItemPreviewContent bold={bold}>{formatCurrency(advancePayment)}</ItemPreviewContent>
                    </>
                )}
                {(requestContent?.type === CustomerRequestTypeEnum.ZOM_ZODE || requestContent?.type === CustomerRequestTypeEnum.ZOM_ZODP) &&
                    requestContent.advancePaymentPeriod && (
                        <>
                            <ItemPreviewHeader title={t('customer-request.steps.payment-period.title')} />
                            <ItemPreviewContent bold={bold}>{period}</ItemPreviewContent>
                        </>
                    )}
                {value && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.electricity-consumption.expected-consumption')} />
                        <ItemPreviewContent bold={bold}>
                            {value} {units}
                        </ItemPreviewContent>
                    </>
                )}
                {valueHigh && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.electricity-consumption.expected-consumption-vt')} />
                        <ItemPreviewContent bold={bold}>
                            {valueHigh} {units}
                        </ItemPreviewContent>
                    </>
                )}
                {valueLow && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.electricity-consumption.expected-consumption-nt')} />
                        <ItemPreviewContent bold={bold}>
                            {valueLow} {units}
                        </ItemPreviewContent>
                    </>
                )}
                <ItemPreviewHeader title={t('customer-request.steps.electricity-consumption.subs-start-date')} />
                {date ? (
                    <ItemPreviewContent bold={bold}>{formatDateToPreview(date)}</ItemPreviewContent>
                ) : (
                    <ItemPreviewContent bold={bold}>{t('customer-request.steps.consumption.first-available-time-slot')}</ItemPreviewContent>
                )}
            </ItemPreview>
        </>
    );
};

export default AssumedConsumptionItem;
