import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import { dateFromIsoToFormat } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data?: IBusinessPartner;
    bold?: boolean;
    title: string | JSX.Element;
};

const BusinessPartnerPersonItem: React.FC<Props> = ({ data, bold, title }) => {
    const [t] = useTranslation();
    if (data && isObjectEmpty(data)) return <></>;
    const birthDate = data?.birthDate && dateFromIsoToFormat(data.birthDate, 'DD.MM.YYYY');
    const name = `${data?.titleFront?.name ?? ''} ${data?.firstName ?? ''} ${data?.lastName ?? ''} ${data?.titleBehind?.name ?? ''}`;

    return (
        <ItemPreview title={title}>
            {data?.lastName && <ItemPreviewContent bold={bold}>{name.trim()}</ItemPreviewContent>}
            {birthDate && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.businessPartner.birthday')}: ${birthDate}`}</ItemPreviewContent>
            )}
            {data?.idCardNumber && (
                <ItemPreviewContent bold={bold}>
                    {' '}
                    {`${t('customer-request.steps.businessPartner.identity-document')}: ${data?.idCardNumber}`}
                </ItemPreviewContent>
            )}
            {data?.bpNumber && (
                <ItemPreviewContent bold={bold}> {`${t('customer-request.prepare.customer-number')}: ${data?.bpNumber}`}</ItemPreviewContent>
            )}
        </ItemPreview>
    );
};

export default BusinessPartnerPersonItem;
