import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import HelpText from '../../../components/help/help-text';
import { useApi } from '../../../hooks/use-api';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { SessionStorageEnum, useSessionStorage } from '../../../hooks/use-session-storage';
import Routes from '../../../routes/routes';
import { QueryKeysEnum } from '../../../utils/react-query-utils';
import { getAddressFromUnitedDeliveryPoint } from '../../../utils/utils';
import ConsumptionReadCard from './consumption-read-card';

const SelfRead: React.FC = () => {
    const { unitedDeliveryPointId } = useParams<{ unitedDeliveryPointId: string }>();
    const api = useApi();
    const [showInactiveDP] = useSessionStorage<boolean>(SessionStorageEnum.showInactiveDeliveryPoints);

    const { data } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, unitedDeliveryPointId, showInactiveDP, 'CONTRACT', 'READING_CYCLE_INFO'],
        async () =>
            api.unitedDeliveryPoints
                .getUdpByUuid(unitedDeliveryPointId, { includeInactive: showInactiveDP, fetch: ['CONTRACT', 'READING_CYCLE_INFO'] }, { secure: true })
                .then((res) => res.data),
    );

    const [showHelpForGasReading, setShowHelpForGasReading] = useState(false);
    const [showHelpForElectricity1, setShowHelpForElectricity1] = useState(false);
    const [showHelpForElectricity2, setShowHelpForElectricity2] = useState(false);

    return (
        <>
            {/* <!-- Breadcrumb with more items --> */}
            <nav aria-label="breadcrumb" className="breadcrumb-more-items border-primary">
                <div className="container ">
                    <ol className="breadcrumb d-inline-flex p-0 mb-0">
                        {unitedDeliveryPointId && (
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={`./data`}>{data && getAddressFromUnitedDeliveryPoint(data)}</Link>
                            </li>
                        )}
                        <li className="breadcrumb-item active" aria-current="page">
                            <Link to="#">
                                <Trans i18nKey="delivery-point.detail.consumption.self-meter-read" />
                            </Link>
                        </li>
                    </ol>
                </div>
            </nav>
            {/* <!-- / Breadcrumb with more items --> */}

            <div className="container my-5">
                <div className="row justify-content-center flex-lg-row">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <h2>
                            <Trans i18nKey="delivery-point.detail.consumption.self-meter-read" />
                        </h2>
                        <HelpText screen="SOT" field="SOT_HELP" />
                        {showHelpForElectricity1 && <HelpText screen="SOT" field="SOT_ELECTRICITY_METER_WHERE_I_FIND_IT" />}
                        {showHelpForElectricity1 && showHelpForElectricity2 && (
                            <p>
                                <br />
                            </p>
                        )}
                        {showHelpForElectricity2 && <HelpText screen="SOT" field="SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT" />}
                        {(showHelpForElectricity1 || showHelpForElectricity2) && showHelpForGasReading && (
                            <p>
                                <br />
                            </p>
                        )}
                        {showHelpForGasReading && <HelpText screen="SOT" field="SOT_GAS_METER_WHERE_I_FIND_IT" />}

                        {/* <div className="card text-decoration-none mb-5">
                            <div className="card-body text-center">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                        </div> */}
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        {data?.deliveryPoints?.map((dp) => (
                            <ConsumptionReadCard
                                key={dp.id}
                                deliveryPoint={dp}
                                backUrl={`${Routes.DELIVERY_POINTS}/${unitedDeliveryPointId}/data/${dp.id}`}
                                setShowHelpForGasReading={setShowHelpForGasReading}
                                setShowHelpForElectricity1={setShowHelpForElectricity1}
                                setShowHelpForElectricity2={setShowHelpForElectricity2}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelfRead;
