import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, CardHeader, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    BaseTextarea,
    BusinessPartnerTypeEnum,
    Checkbox,
    ClickableElement,
    CodeListTypeEnum,
    ContractBindingEnum,
    ContractDeliveryEnum,
    ContractTerminationEnum,
    CustomerAccountTypeEnum,
    DeliveryPointEeConnectionEnum,
    DistributorOptionsEnum,
    HelpModal,
    InvoiceDeliveryStepEnum,
    LoadingIndicator,
    PaymentMethodCodeEnum,
    PhasesConsumptionEnum,
    QueryKeysEnum,
    formatBusinessPartnerName,
    formatDateToPreview,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IPaymentMethod } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { eeSupllyRequest } from '../../../customer-request-utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from './components/item-preview';

const NOTE_MAX_COUNT = 510;

export const BlockCompletionSupplyEE: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const customerRequest = useSelector((state: IRootState) => state.customerRequest);
    const { content, businessPartner, attachments } = customerRequest;
    const [paymentType, setPaymentType] = useState<PaymentMethodCodeEnum>(PaymentMethodCodeEnum.INKASO);
    const [showNote, setShowNote] = useState<boolean>(content.note ? true : false);
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const useRecaptcha =
        process.env.REACT_APP_RECAPTCHA === 'true' &&
        !loggedInCustomer &&
        (content.reCaptchaToken?.remainingUsesCount === undefined || content.reCaptchaToken?.remainingUsesCount === 0);
    const [key, setKey] = useState(0);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const { register, handleSubmit, errors, watch } = useForm<{ note: string }>({
        defaultValues: { note: content.note },
    });

    const message: string | undefined = watch('note');
    const { formRules } = useFormRules();

    useEffect(() => {
        setMessageCounter(message?.length ?? 0);
        dispatch(CustomerRequestActions.setData({ note: message }));
    }, [message, dispatch]);

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const [personalInfoProcessingConcentShown, setPersonalInfoProcessingConcentShown] = useState(false);

    const [isPersonalInfoProcessingOpen, setIsPersonalInfoProcessingOpen] = useState(false);
    const [personalInfoProcessingShown, setPersonalInfoProcessingShown] = useState(false);

    const showPersonalInfoProcessingConcent = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingConcentOpen(true);
    };

    const showPersonalInfoProcessing = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingOpen(true);
    };

    useEffect(() => {
        if (isPersonalInfoProcessingConcentOpen) setPersonalInfoProcessingConcentShown(true);
    }, [isPersonalInfoProcessingConcentOpen, setPersonalInfoProcessingConcentShown]);

    useEffect(() => {
        if (isPersonalInfoProcessingOpen) setPersonalInfoProcessingShown(true);
    }, [isPersonalInfoProcessingOpen, setPersonalInfoProcessingShown]);

    const api = useApi();
    const [mutateRecaptchaToken] = useMutationWithError(
        async (recaptcha: string) =>
            api.customers.reCaptchaToken(
                { tokenType: 'ANONYMOUS_CUSTOMER_REQUEST' },
                { headers: { 'X-GoogleReCaptcha-Code': recaptcha }, secure: true },
            ),
        {
            onSuccess: (resp) => {
                if (resp.data) {
                    dispatch(CustomerRequestActions.setData({ reCaptchaToken: resp.data }));
                }
            },
        },
    );

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const { data: distributionAreaCodelist } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 5000,
        },
    });

    const onSubmit = async (formData: { personalInfoProcessingConcent: boolean; personalInfoProcessing: boolean }) => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (useRecaptcha && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        useRecaptcha && recaptchaRef?.current?.reset();

        if (!loggedInCustomer && reCaptchaHelp) {
            const result = await mutateRecaptchaToken(reCaptchaHelp);
            if (result === undefined) {
                return;
            }
        }

        const requestData = eeSupllyRequest(customerRequest, false, {
            personalInfoProcessingConcent: formData.personalInfoProcessingConcent,
            personalInfoProcessing: formData.personalInfoProcessing,
        });
        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(requestData, attachments)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    useEffect(() => {
        // payment
        const recastPaymentMethod: (IPaymentMethod & { paymentTypeId?: string }) | undefined = content.paymentMethod;

        if (recastPaymentMethod?.paymentTypeId) {
            setPaymentType(
                (paymentTypeCodeList?.find((item) => item.uuid === recastPaymentMethod?.paymentTypeId)?.code as PaymentMethodCodeEnum) ??
                    PaymentMethodCodeEnum.SIPO,
            );
        }
    }, [paymentTypeCodeList, content]);

    useEffect(() => {
        if (content.saveRequested) {
            dispatch(CustomerRequestActions.setData({ saved: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />
            <HelpModal
                show={isPersonalInfoProcessingOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_CONFIRMATION_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingOpen(false)}
            />
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.completion-supply.order')}>
                    <ItemPreviewContent bold>{t('customer-request.steps.completion-supply.product')}</ItemPreviewContent>
                </ItemPreview>
                <ItemPreview title={t('customer-request.detail.rate')}>
                    <ItemPreviewContent bold>{content.tariffEe?.tariff.name}</ItemPreviewContent>
                    <ItemPreviewHeader title={t('customer-request.steps.electricity-connection.distribution-area')} />
                    <ItemPreviewContent bold>
                        {distributionAreaCodelist?.find((item) => content.tariffEe?.distributionArea === item.code)?.name}
                    </ItemPreviewContent>
                </ItemPreview>
                <ItemPreview title={t('customer-request.steps.advance-payment.title')}>
                    <ItemPreviewContent bold>
                        {content.advancePayment?.amount}€ / {content.advancePaymentPeriod?.period.name}
                    </ItemPreviewContent>
                </ItemPreview>
                <ItemPreview title={t('customer-request.steps.block-carbon-footprint-completion.payment-method')}>
                    <ItemPreviewContent bold>
                        <>
                            {paymentType === PaymentMethodCodeEnum.INKASO && t('customer-request.steps.payment-method.inkaso')}
                            {paymentType === PaymentMethodCodeEnum.BANK_TRANSFER && t('customer-request.steps.payment-method.bank-transfer')}
                            {paymentType === PaymentMethodCodeEnum.SIPO && t('customer-request.steps.payment-method.sipo')}
                            <br />

                            {content.paymentMethod?.iban && (
                                <>
                                    {t('customer-request.steps.block-carbon-footprint-completion.iban-number')}
                                    {content.paymentMethod?.iban}
                                    <p className="mb-0">{content.paymentMethod?.bicSwift && ' BIC/SWIFT: ' + content.paymentMethod?.bicSwift}</p>
                                </>
                            )}
                            {paymentType === PaymentMethodCodeEnum.SIPO && (
                                <>
                                    {content.paymentMethod?.sipoNumber ? (
                                        <>
                                            {t('customer-request.steps.payment-method.sipo-number')}: {content.paymentMethod?.sipoNumber}
                                        </>
                                    ) : (
                                        <Trans i18nKey="delivery-point.detail.contact-data.no-sipo-number" />
                                    )}
                                </>
                            )}
                        </>
                    </ItemPreviewContent>
                </ItemPreview>
                <ItemPreview title={t('customer-request.steps.completion-transcript.invoice-delivery')}>
                    <ItemPreviewContent>
                        {content.invoiceDelivery?.type === InvoiceDeliveryStepEnum.ADDRESS ? (
                            <span>
                                {t('customer-request.steps.invoice-delivery.via-post')}:
                                <br />
                                {content.accountInfo ? (
                                    <b>
                                        <Trans
                                            i18nKey="customer-request.steps.invoice-delivery.to-address-from-for-customer"
                                            defaults="Faktúry budeme zasielať na vašu korešpondečnú adresu evidovanú k zákazníckemu číslu {{number}}"
                                            values={{
                                                number: content.accountInfo.bpNumber,
                                            }}
                                            components={{ bold: <strong /> }}
                                        />
                                    </b>
                                ) : (
                                    <b>
                                        {content.invoiceDelivery?.address?.name || ''} <br />
                                        {content.invoiceDelivery?.address?.street || ''} {content.invoiceDelivery?.address?.number || ''}
                                        <br />
                                        {content.invoiceDelivery?.address?.city || ''} {content.invoiceDelivery?.address?.postalCode || ''}
                                        <br />
                                        {content.invoiceDelivery?.address?.country?.name}
                                    </b>
                                )}
                            </span>
                        ) : (
                            <span>
                                {t('customer-request.steps.invoice-delivery.via-email')}: <b>{content.invoiceDelivery?.email}</b>
                            </span>
                        )}
                    </ItemPreviewContent>
                </ItemPreview>
                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.completion-supply.address-for-ee-supply')}>
                        <ItemPreviewContent>
                            {t('customer-request.steps.completion-transcript.address')}
                            <p>
                                <b>
                                    {content.newDeliveryPointAddress?.street || ''} {content.newDeliveryPointAddress?.number || ''}
                                    <br />
                                    {content.newDeliveryPointAddress?.city || ''}, {content.newDeliveryPointAddress?.postalCode || ''}
                                    <br />
                                    {countryCodeList?.find((item) => item.code === 'SK')?.name || ''}
                                </b>
                            </p>
                            {content.newDeliveryPointAddress?.floor && (
                                <>
                                    {t('customer-request.steps.address.floor')}
                                    <p>
                                        <b>{content.newDeliveryPointAddress?.floor}.</b>
                                    </p>
                                </>
                            )}
                            {content.newDeliveryPointAddress?.flatNumber && (
                                <>
                                    {t('customer-request.steps.address.appartments-number')}
                                    <p>
                                        <b>{content.newDeliveryPointAddress?.flatNumber}</b>
                                    </p>
                                </>
                            )}
                            {t('customer-request.steps.connection.subtitle')}
                            <p>
                                <b>
                                    {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION ? (
                                        <Trans i18nKey="customer-request.steps.connection.yes-switch-to-spp" />
                                    ) : (
                                        <Trans i18nKey="customer-request.steps.connection.no-new-connection" />
                                    )}
                                </b>
                            </p>
                            {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.NEW ? (
                                <>
                                    <div>{t('customer-request.steps.completion-supply.contract-conection')}</div>
                                    {attachments
                                        ?.filter((item) => item.info === 'connection')
                                        .map((item, index) => (
                                            <p className="mb-0" key={`connection_file_${index}}`}>
                                                <b>{item.file?.name || item.name}</b>
                                            </p>
                                        ))}
                                </>
                            ) : (
                                <>
                                    {t('customer-request.steps.electricity-connection.original-supplier')}
                                    <p>
                                        <b>
                                            {content.deliveryPointConnectionEe?.currentDistributor === 'other' ? (
                                                content.deliveryPointConnectionEe.currentDistributorCustom
                                            ) : (
                                                <>
                                                    {
                                                        DistributorOptionsEnum[
                                                            content.deliveryPointConnectionEe
                                                                ?.currentDistributor as keyof typeof DistributorOptionsEnum
                                                        ]
                                                    }
                                                </>
                                            )}
                                        </b>
                                    </p>
                                    <Trans i18nKey="customer-request.steps.change-supplier.arrange-contract-termination-on-your-behalf">
                                        Prajete si, aby sme ukončenie zmluvy s vaším aktuálnym dodávateľom vybavili za vás?
                                    </Trans>
                                    <p>
                                        <b>
                                            {content.supplier?.contractTermination === ContractTerminationEnum.YES ? (
                                                <Trans i18nKey="common.yes" />
                                            ) : (
                                                <Trans i18nKey="customer-request.steps.change-supplier.no-arrange-termination-myself" />
                                            )}
                                        </b>
                                    </p>
                                    {content.supplier?.contractTermination === ContractTerminationEnum.YES ? (
                                        <>
                                            <Trans i18nKey="customer-request.steps.change-supplier.binding-on-your-contract">
                                                Máte na zmluve s vaším aktuálnym dodávateľom viazanosť?
                                            </Trans>
                                            <p>
                                                <b>
                                                    {content.supplier.contractBinding === ContractBindingEnum.YES ? (
                                                        <Trans i18nKey="common.yes" />
                                                    ) : (
                                                        <Trans i18nKey="customer-request.steps.change-supplier.contract-no-binding" />
                                                    )}
                                                </b>
                                            </p>
                                            {content.supplier.contractBinding === ContractBindingEnum.YES && (
                                                <>
                                                    {t('customer-request.steps.complained-advances.payment-bindingTo')}
                                                    <p>
                                                        <b>{formatDateToPreview(content.supplier.bindingTo)}</b>
                                                    </p>
                                                    {t('customer-request.steps.electricity-connection.notice-period')}
                                                    <p>
                                                        <b>{content.supplier.noticePeriod}</b>
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div>{t('customer-request.steps.completion-supply.proof-of-termination-of-the-contract')}</div>
                                            {attachments
                                                ?.filter((item) => item.info === 'supplier')
                                                .map((item, index) => (
                                                    <p className="mb-0" key={`supplier_file_${index}}`}>
                                                        <b>{item.file?.name || item.name}</b>
                                                    </p>
                                                ))}
                                        </>
                                    )}
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>
                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.completion-supply.property-owner')}>
                        <ItemPreviewContent>
                            {content.owner ? (
                                <>
                                    {content.owner?.type === BusinessPartnerTypeEnum.COMPANY
                                        ? t('customer-request.steps.completion-transcript.company-name')
                                        : t('customer-request.steps.completion-transcript.name-and-surname')}
                                    <p>
                                        <b>{formatBusinessPartnerName(content.owner)}</b>
                                    </p>
                                    {content.owner?.companyRegistrationNumber && (
                                        <>
                                            {t('delivery-point.detail.contact-data.company-registration-number')}
                                            <p>
                                                <b>{content.owner?.companyRegistrationNumber}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.owner?.taxIdNumber && (
                                        <>
                                            {t('delivery-point.detail.contact-data.tax-id-number')}
                                            <p>
                                                <b>{content.owner?.taxIdNumber}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.owner?.vatRegistrationNumber && (
                                        <>
                                            {t('delivery-point.detail.contact-data.vat-registration-number')}
                                            <p>
                                                <b>{content.owner?.vatRegistrationNumber}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.owner?.legalForm && (
                                        <>
                                            {t('customer-request.steps.businessPartner.legal-form')}
                                            <p>
                                                <b>{content.owner?.legalForm}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.owner?.statutoryName && (
                                        <>
                                            {t('customer-request.steps.businessPartner.name-statutory-representative')}
                                            <p>
                                                <b>{content.owner?.statutoryName}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.owner?.birthDate && (
                                        <>
                                            {t('customer-request.steps.completion-transcript.birth-date')}
                                            <p>
                                                <b>{formatDateToPreview(content.owner.birthDate)}</b>
                                            </p>
                                        </>
                                    )}
                                    {content?.ownerAddress && (
                                        <>
                                            {t('customer-request.steps.completion-transcript.address')}
                                            <p>
                                                <b>
                                                    {content?.ownerAddress?.street || ''} {content?.ownerAddress?.number || ''}
                                                    <br />
                                                    {content?.ownerAddress?.city || ''}, {content?.ownerAddress?.postalCode || ''}
                                                    <br />
                                                    {countryCodeList?.find((item) => item.uuid === content.ownerAddress?.country?.uuid)?.name || ''}
                                                </b>
                                            </p>
                                            {content.ownerAddress?.floor && (
                                                <>
                                                    {t('customer-request.steps.address.floor')}
                                                    <p>
                                                        <b>{content.ownerAddress?.floor}.</b>
                                                    </p>
                                                </>
                                            )}
                                            {content.ownerAddress?.flatNumber && (
                                                <>
                                                    {t('customer-request.steps.address.appartments-number')}
                                                    <p>
                                                        <b>{content.ownerAddress?.flatNumber}</b>
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {content.ownerAddress && (
                                        <>
                                            {t('customer-request.steps.completion-transcript.correspondence-address')}
                                            <p>
                                                <b>
                                                    {content.ownerCorrespondenceAddress?.street ?? (content.ownerAddress?.street || '')}{' '}
                                                    {content.ownerCorrespondenceAddress?.number ?? (content.ownerAddress?.number || '')}
                                                    <br />
                                                    {content.ownerCorrespondenceAddress?.city ?? (content.ownerAddress?.city || '')}
                                                    {', '}
                                                    {content.ownerCorrespondenceAddress?.postalCode ?? (content.ownerAddress?.postalCode || '')}
                                                    <br />
                                                    {!content.ownerCorrespondenceAddress &&
                                                        (countryCodeList?.find((item) => item.uuid === content.ownerAddress?.country?.uuid)?.name ||
                                                            '')}
                                                </b>
                                            </p>
                                        </>
                                    )}
                                    {content.ownerContact && (
                                        <>
                                            {t('customer-request.steps.completion-transcript.contact')}
                                            <p>
                                                <b>
                                                    {`${t('customer-request.steps.contact.phone-number')}: ${content.ownerContact?.phoneNumber}`}
                                                    <br />
                                                    {`${t('customer-request.steps.contact.email')}: ${content.ownerContact?.email}`}
                                                </b>
                                            </p>
                                        </>
                                    )}
                                    <div>{t('customer-request.steps.completion-supply.property-owner')}</div>
                                    {attachments
                                        ?.filter((item) => item.info === 'owner')
                                        .map((item, index) => (
                                            <p className="mb-0" key={`owner_file_${index}}`}>
                                                <b>{item.file?.name || item.name}</b>
                                            </p>
                                        ))}
                                </>
                            ) : (
                                <p>
                                    <b>
                                        {t('customer-request.steps.completion-supply.owner-is-customer')}{' '}
                                        {businessPartner
                                            ? formatBusinessPartnerName(businessPartner)
                                            : formatBusinessPartnerName(content.businessPartner)}
                                    </b>
                                </p>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>
                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.completion-supply.customer-supplier')}>
                        <ItemPreviewContent>
                            {content.accountInfo
                                ? ''
                                : content.businessPartner?.name || businessPartner?.name
                                ? t('customer-request.steps.completion-transcript.company-name')
                                : t('customer-request.steps.completion-transcript.name-and-surname')}
                            <p>
                                <b>{formatBusinessPartnerName(businessPartner ?? content.businessPartner)}</b>
                            </p>
                            {content.businessPartner?.companyRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.company-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.companyRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.taxIdNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.tax-id-number')}
                                    <p>
                                        <b>{content.businessPartner?.taxIdNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.vatRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.vat-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.vatRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.legalForm && (
                                <>
                                    {t('customer-request.steps.businessPartner.legal-form')}
                                    <p>
                                        <b>{content.businessPartner?.legalForm}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.statutoryName && (
                                <>
                                    {t('customer-request.steps.businessPartner.name-statutory-representative')}
                                    <p>
                                        <b>{content.businessPartner?.statutoryName}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.birthDate && (
                                <>
                                    {t('customer-request.steps.completion-transcript.birth-date')}
                                    <p>
                                        <b>{formatDateToPreview(content.businessPartner.birthDate)}</b>
                                    </p>
                                </>
                            )}
                            {businessPartner?.primaryAddress && (
                                <>
                                    {t('customer-request.steps.completion-transcript.address')}
                                    <p>
                                        <b>
                                            {businessPartner?.primaryAddress?.street || ''} {businessPartner?.primaryAddress?.streetNumber || ''}
                                            <br />
                                            {businessPartner?.primaryAddress?.city || ''}, {businessPartner?.primaryAddress?.zipCode || ''}
                                            <br />
                                            {businessPartner?.primaryAddress?.country}
                                        </b>
                                    </p>
                                </>
                            )}
                            {content.address && (
                                <>
                                    {content.businessPartner?.type === BusinessPartnerTypeEnum.COMPANY
                                        ? t('customer-request.steps.completion-transcript.permanent-address-company')
                                        : t('customer-request.steps.completion-transcript.permanent-address')}
                                    <p>
                                        <b>
                                            {content.address?.street || ''} {content.address?.number || ''}
                                            <br />
                                            {content.address?.city || ''}, {content.address?.postalCode || ''}
                                            <br />
                                            {countryCodeList?.find((item) => item.uuid === content.address?.country?.uuid)?.name || ''}
                                        </b>
                                    </p>
                                    {content.address?.floor && (
                                        <>
                                            {t('customer-request.steps.address.floor')}
                                            <p>
                                                <b>{content.address.floor}.</b>
                                            </p>
                                        </>
                                    )}
                                    {content.address?.flatNumber && (
                                        <>
                                            {t('customer-request.steps.address.appartments-number')}
                                            <p>
                                                <b>{content.address.flatNumber}</b>
                                            </p>
                                        </>
                                    )}
                                </>
                            )}

                            {content.address && (
                                <>
                                    {t('customer-request.steps.completion-transcript.correspondence-address')}
                                    <p>
                                        <b>
                                            {content.correspondenceAddress?.street ?? (content.address?.street || '')}{' '}
                                            {content.correspondenceAddress?.number ?? (content.address?.number || '')}
                                            <br />
                                            {content.correspondenceAddress?.city ?? (content.address?.city || '')}
                                            {', '}
                                            {content.correspondenceAddress?.postalCode ?? (content.address?.postalCode || '')}
                                            <br />
                                            {countryCodeList?.find(
                                                (item) =>
                                                    item.uuid === (content.correspondenceAddress?.country?.uuid ?? content.address?.country?.uuid),
                                            )?.name || ''}
                                        </b>
                                    </p>
                                </>
                            )}

                            {content.contact && (
                                <>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        <b>
                                            {`${t('customer-request.steps.contact.phone-number')}: ${content.contact?.phoneNumber}`}
                                            <br />
                                            {`${t('customer-request.steps.contact.email')}: ${content.contact?.email}`}
                                        </b>
                                    </p>
                                </>
                            )}

                            {content.accountInfo && (
                                <>
                                    {t('customer-request.steps.businessPartner.customer-number-spp')}
                                    <p>
                                        <b>{content.accountInfo.bpNumber}</b>
                                    </p>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        {t('customer-request.steps.contact.phone-number')}: <b>{content.accountInfo?.phoneNumber}</b>
                                        <br />
                                        {t('customer-request.steps.contact.email')}: <b>{content.accountInfo?.email}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>

                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.consumption.title')}>
                        <ItemPreviewContent>
                            {t('customer-request.steps.electricity-consumption.subs-start-date')}
                            <p>
                                <b>
                                    {content.assumedConsumption?.date
                                        ? formatDateToPreview(content.assumedConsumption?.date)
                                        : t('customer-request.steps.consumption.first-available-time-slot')}
                                </b>
                            </p>
                            {t('customer-request.steps.electricity-consumption.expected-consumption')}
                            <p>
                                {content.assumedConsumption?.value ? (
                                    <b>{content.assumedConsumption?.value} kWh</b>
                                ) : (
                                    <>
                                        <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" /> :{' '}
                                        <b>{content?.assumedConsumption?.valueHigh}</b>
                                        {' kWh'} <br />
                                        <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" /> :{' '}
                                        <b>{content?.assumedConsumption?.valueLow}</b>
                                        {' kWh'}
                                    </>
                                )}
                            </p>
                            {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION && (
                                <>
                                    {content.assumedConsumption?.deliveryPointNumber && (
                                        <>
                                            {t('customer-request.steps.delivery-point.eic')}
                                            <p>
                                                <b>{content.assumedConsumption?.deliveryPointNumber}</b>
                                            </p>
                                        </>
                                    )}
                                    {content.assumedConsumption?.phase && (
                                        <>
                                            {t('customer-request.steps.consumption.phases-number')}
                                            <p>
                                                <b>
                                                    {content.assumedConsumption?.phase === PhasesConsumptionEnum.F1 ? (
                                                        <Trans i18nKey="customer-request.steps.consumption.1-phase">1 fáza</Trans>
                                                    ) : (
                                                        <Trans i18nKey="customer-request.steps.consumption.3-phase">3 fázy</Trans>
                                                    )}
                                                </b>
                                            </p>
                                        </>
                                    )}
                                    {t('customer-request.steps.consumption.main-circuit-breaker')}
                                    <p>
                                        <b>{content.assumedConsumption?.mainCircuitValue}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>

                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.contract-delivery.title')}>
                        <ItemPreviewContent>
                            {t('customer-request.steps.contract-delivery.way')}
                            <p>
                                <b>
                                    {content.contractDelivery?.type === ContractDeliveryEnum.POST ? (
                                        <Trans i18nKey="customer-request.steps.contract-delivery.post" />
                                    ) : (
                                        <Trans i18nKey="customer-request.steps.contract-delivery.delivery-man" />
                                    )}
                                </b>
                            </p>
                            {t('customer-request.steps.contract-delivery.address-contract-delivery')}
                            <p>
                                <b>
                                    {content.contractDelivery?.address.name}
                                    <br />
                                    {content.contractDelivery?.address?.street || ''} {content.contractDelivery?.address?.number || ''}
                                    <br />
                                    {content.contractDelivery?.address?.city || ''}, {content.contractDelivery?.address?.postalCode || ''}
                                    <br />
                                    {countryCodeList?.find((item) => item.uuid === content.contractDelivery?.address?.country?.uuid)?.name || ''}
                                </b>
                            </p>
                            {content.contractDelivery?.address?.floor && (
                                <>
                                    {t('customer-request.steps.address.floor')}
                                    <p>
                                        <b>{content.contractDelivery?.address?.floor}.</b>
                                    </p>
                                </>
                            )}
                            {content.contractDelivery?.address?.flatNumber && (
                                <>
                                    {t('customer-request.steps.address.appartments-number')}
                                    <p>
                                        <b>{content.contractDelivery?.address?.flatNumber}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>
            </ListGroup>

            <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                <Checkbox
                    ref={register({ ...formRules.required })}
                    errors={errors}
                    className="mb-1"
                    id="personalInfoProcessingConcent"
                    name="personalInfoProcessingConcent"
                    label={
                        <Trans
                            i18nKey="customer-request.steps.terms-condition.personal-info-processing-consent"
                            components={{
                                Link: (
                                    <ClickableElement
                                        isText
                                        onClick={showPersonalInfoProcessingConcent}
                                        className="text-decoration-underline-inverse"
                                    >
                                        &nbsp
                                    </ClickableElement>
                                ),
                            }}
                        />
                    }
                    onChange={() => {
                        if (!personalInfoProcessingConcentShown) setIsPersonalInfoProcessingConcentOpen(true);
                    }}
                />
            </CardHeader>
            <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                <Checkbox
                    ref={register({ ...formRules.required })}
                    errors={errors}
                    className="mb-1"
                    id="personalInfoProcessing"
                    name="personalInfoProcessing"
                    label={
                        <Trans
                            i18nKey="customer-request.steps.terms-condition.personal-info-processing"
                            components={{
                                Link: (
                                    <ClickableElement isText onClick={showPersonalInfoProcessing} className="text-decoration-underline-inverse">
                                        &nbsp
                                    </ClickableElement>
                                ),
                            }}
                        />
                    }
                    onChange={() => {
                        if (!personalInfoProcessingShown) setIsPersonalInfoProcessingOpen(true);
                    }}
                />
            </CardHeader>

            <div className="my-2">
                <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                    <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                </ClickableElement>
            </div>
            {(showNote || messageCounter > 0) && (
                <BaseTextarea
                    ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                    errors={errors}
                    name={'note'}
                    maxLength={NOTE_MAX_COUNT}
                    label={t('customer-request.steps.attachments.title')}
                    counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                    optional
                />
            )}

            {useRecaptcha && (
                <div>
                    <ReCAPTCHA
                        key={key}
                        ref={recaptchaRef}
                        sitekey={useRecaptcha ? recaptchaSiteKey : 'undefined'}
                        onChange={() => setReCaptchaError(false)}
                        hl={i18n.language === 'en' ? 'en' : 'sk'}
                    />
                    {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                </div>
            )}

            <Button type="submit" size="lg" color="primary" block className="mt-5">
                <Trans i18nKey="customer-request.steps.block-clean-energy-completion.order" />
            </Button>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent
                        onClose={onRequestSentSuccessConfirmed}
                        subTitle={
                            <p className="p-2">
                                {content.registration && content.registration.email && (
                                    <>
                                        <Trans
                                            i18nKey="registration.link-in-email"
                                            values={{ email: content.registration.email }}
                                            components={{ strong: <strong /> }}
                                        >
                                            Pre dokončenie registrácie kliknite na odkaz, ktorý sme vám poslali na adresu
                                            <strong>{content.registration.email}</strong>.
                                        </Trans>
                                        <br />
                                    </>
                                )}
                                {businessPartner?.queue === CustomerAccountTypeEnum.INDIVIDUAL ? (
                                    <Trans i18nKey="customer-request.steps.completion-supply.agreement-delivery-individual" />
                                ) : (
                                    <Trans i18nKey="customer-request.steps.completion-supply.agreement-delivery" />
                                )}
                            </p>
                        }
                    />
                </ModalBody>
            </Modal>
        </form>
    );
};
