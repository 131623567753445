import { Reducer } from 'react';
import {
    AddDeliveryPointAction,
    AddDeliveryPointActionType,
    AddDeliveryPointBackScreenMap,
    AddDeliveryPointScreen,
    AddDeliveryPointState,
    CommodityChoice,
} from '../actions/add-delivery-point-actions';

const AddDeliveryPointReducer: Reducer<AddDeliveryPointState, AddDeliveryPointAction> = (state = {}, action): AddDeliveryPointState => {
    switch (action.type) {
        case AddDeliveryPointActionType.START:
            if (action.onlyLoad) {
                return {
                    screen: AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY,
                    isOpen: true,
                    onlyLoad: true,
                };
            }
            return {
                screen: AddDeliveryPointScreen.CHOOSE_EXISTING_OR_NEW,
                isOpen: true,
            };
        case AddDeliveryPointActionType.CANCEL:
            return {
                ...state,
                isOpen: false,
            };
        case AddDeliveryPointActionType.FINISHED:
            return {};
        case AddDeliveryPointActionType.CHOOSE_ADDING_EXISTING_DP:
            return {
                ...state,
                screen: AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY,
            };
        case AddDeliveryPointActionType.CHOOSE_ADDING_NEW_DP:
            return {
                ...state,
                screen: AddDeliveryPointScreen.CHOOSE_COMMODITY,
                commodity: CommodityChoice.GAS,
            };
        case AddDeliveryPointActionType.SUCCESS_PAIRING:
            return {
                ...state,
                screen: AddDeliveryPointScreen.ENTERED_DATA,
                approvalRequired: action.approvalRequired,
            };
        case AddDeliveryPointActionType.CHOOSE_PERSON:
            return {
                ...state,
                screen: AddDeliveryPointScreen.ENTER_PERSON_DATA,
            };
        case AddDeliveryPointActionType.CHOOSE_COMPANY:
            return {
                ...state,
                screen: AddDeliveryPointScreen.ENTER_COMPANY_DATA,
            };
        case AddDeliveryPointActionType.ENTER_PERSON_DATA:
            return {
                ...state,
                screen: action.phoneChallengeCode ? AddDeliveryPointScreen.VERIFY_PHONE_NUMBER : AddDeliveryPointScreen.ENTERED_DATA,
                personData: action.personData,
                phoneChallengeCode: action.phoneChallengeCode,
                lastThreePhoneDigits: action.lastThreePhoneDigits,
            };
        case AddDeliveryPointActionType.ENTER_COMPANY_DATA:
            return {
                ...state,
                screen: action.phoneChallengeCode ? AddDeliveryPointScreen.VERIFY_PHONE_NUMBER : AddDeliveryPointScreen.ENTERED_DATA,
                companyData: action.companyData,
                phoneChallengeCode: action.phoneChallengeCode,
                lastThreePhoneDigits: action.lastThreePhoneDigits,
            };
        case AddDeliveryPointActionType.ENTER_COMMODITY:
            return {
                ...state,
                commodity: action.commodity,
            };
        case AddDeliveryPointActionType.SET_PAIRED_BUSINESS_PARTNER: {
            return {
                ...state,
                pairedBusinessPartner: action.pairedBusinessPartner,
            };
        }
        case AddDeliveryPointActionType.SET_EMAIL_SYNC_REQUESTED: {
            return {
                ...state,
                emailSyncRequested: true,
            };
        }
        case AddDeliveryPointActionType.BACK:
            if (state.screen) {
                return {
                    ...state,
                    screen: AddDeliveryPointBackScreenMap.get(state.screen),
                };
            }
            return state;
        case AddDeliveryPointActionType.ERROR:
            return {
                ...state,
                screen: AddDeliveryPointScreen.ERROR,
                errorMsg: action.errorMsg,
            };
        default:
            return state;
    }
};

export default AddDeliveryPointReducer;
