import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../../main';
import { DeliveryPointSwitchLabel } from '../delivery-point-switch-label';
import { DeliveryPointSwitchMenu } from './delivery-point-switch-menu';
import { DeliveryPointSwitchModalButton } from './delivery-point-switch-modal-button';

interface IDeliveryPointSwitchModalProps {
    isOpen: boolean;
    closeModal: () => void;
    selectedDpId: string | undefined;
    onSelect: (dpId: string | undefined) => void;
    deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    showInactive: boolean;
    toggleInactive: (() => void) | undefined;
    additionalButton?: { label: React.ReactNode };
    showEeInvoiceNumbers?: boolean;
    showZpInvoiceNumbers?: boolean;
}

export const DeliveryPointSwitchModal: React.FC<IDeliveryPointSwitchModalProps> = ({
    isOpen,
    closeModal,
    selectedDpId,
    onSelect,
    deliveryPoints,
    showInactive,
    toggleInactive,
    additionalButton,
    showEeInvoiceNumbers,
    showZpInvoiceNumbers,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="delivery-point.delivery-point-switch.select-option" />
                    </h3>
                    <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>

                {showInactive && toggleInactive != null && (
                    <div className="justify-content-center text-center w-100 p-2 bg-light">
                        <div className="d-inline-block">
                            <Trans i18nKey="delivery-point.delivery-point-switch.showing-inactive-products" />
                        </div>
                        <ClickableElement isText className="text-decoration-underline-inverse d-inline-block mx-2" onClick={toggleInactive}>
                            <Trans i18nKey="delivery-point.detail.header.hide-inactive-products" />
                        </ClickableElement>
                    </div>
                )}
                <ModalBody>
                    <div>
                        {toggleInactive && (
                            <div className="d-flex justify-content-end w-100 mb-2">
                                <DeliveryPointSwitchMenu toggleInactive={toggleInactive} showInactive={showInactive} />
                            </div>
                        )}
                    </div>

                    {additionalButton != null && (
                        <DeliveryPointSwitchModalButton
                            isSelected={selectedDpId == null}
                            onClick={() => {
                                onSelect(undefined);
                                closeModal();
                            }}
                        >
                            <div>{additionalButton.label}</div>
                        </DeliveryPointSwitchModalButton>
                    )}

                    {deliveryPoints.map((dp) => (
                        <DeliveryPointSwitchModalButton
                            key={`deliverypoint-modal-${dp.type}-${dp.id}`}
                            isSelected={selectedDpId === dp.id}
                            onClick={() => {
                                if (dp.id) {
                                    onSelect(dp.id);
                                    closeModal();
                                }
                            }}
                        >
                            <DeliveryPointSwitchLabel
                                deliveryPoint={dp}
                                showInvoiceNumber={dp.type === 'ZP' ? showZpInvoiceNumbers : showEeInvoiceNumbers}
                            />
                        </DeliveryPointSwitchModalButton>
                    ))}

                    {!showInactive && toggleInactive != null && (
                        <div className="text-center mt-4">
                            <div>
                                <Trans i18nKey="delivery-point.delivery-point-switch.inactive-products-not-shown" />
                            </div>
                            <ClickableElement isText className="text-decoration-underline-inverse d-block" onClick={toggleInactive}>
                                <Trans i18nKey="delivery-point.detail.header.show-inactive-products" />
                            </ClickableElement>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};
