import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    email?: string;
    bold?: boolean;
    completionView?: 'OWNER' | 'COMPLETION';
};

const NewPropertyOwnerSummaryItem: React.FC<Props> = ({ email, bold, completionView }) => {
    const [t] = useTranslation();
    if (!email) return null;
    return (
        <ItemPreview title={t('customer-request.steps.completion-transcript.new-customer')}>
            {t('common.email')}
            <ItemPreviewContent bold={bold}>{email}</ItemPreviewContent>
            {completionView === 'OWNER' && (
                <div className="my-1">
                    <b>{t('customer-request.steps.new-customer-transcript.new-customer-was-notified-by-email')}</b>
                </div>
            )}
        </ItemPreview>
    );
};

export default NewPropertyOwnerSummaryItem;
