import React from 'react';
import { useTranslation } from 'react-i18next';
import { IApprovals } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IApprovals;
    bold?: boolean;
};

const ApprovalsItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data) || (!data.conditions && !data.personalInfoProcessing)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.terms-condition.title')}>
            {data.conditions && (
                <ItemPreviewContent bold={bold}>{t(`customer-request.steps.terms-condition.business-conditions`)}</ItemPreviewContent>
            )}
            {data.personalInfoProcessingConcent && (
                <ItemPreviewContent bold={bold}>{t(`customer-request.steps.terms-condition.processing-data-consent`)}</ItemPreviewContent>
            )}
            {data.personalInfoProcessing && (
                <ItemPreviewContent bold={bold}>{t(`customer-request.steps.terms-condition.processing-data`)}</ItemPreviewContent>
            )}
            {data.marketing === true && (
                <ItemPreviewContent bold={bold}>{t(`customer-request.steps.terms-condition.marketing-data-true`)}</ItemPreviewContent>
            )}
            {data.marketing === false && (
                <ItemPreviewContent bold={bold}>{t(`customer-request.steps.terms-condition.marketing-data-false`)}</ItemPreviewContent>
            )}
        </ItemPreview>
    );
};

export default ApprovalsItem;
