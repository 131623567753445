import IBAN from 'iban';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { dateFromIsoToFormat, parseFormattedTimeString } from '../utils/date-utils';
import { formatPhoneNumber, latinCharactersRegex } from '../utils/utils';

// eslint-disable-next-line no-control-regex
export const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const postalCodeSkRegex = /^[0-9]{3}[" "]?[0-9]{2}$/;
export const postalCodeRegex = /^[0-9a-zA-Z ]*$/;

export const useFormRules = () => {
    const { t } = useTranslation();

    const lowerCaseRegex = /[ěščřžýáíéóúůďťňa-z]+/;
    const upperCaseRegex = /[ĎŇŤŠČŘŽÝÁÍÉÚŮÓA-Z]+/;
    const numbersRegex = /\d+/;
    const onlyNumbersRegex = /^[0-9]*$/;
    const specialCharRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00a1\u00a2\u00a3\u00a4\u00a5\u00a6\u00a7\u00a8\u00a9\u00aa\u00ab\u00ac\u00ad\u00ae\u00af\u00b0\u00b1\u00b2\u00b3\u00b4\u00b5\u00b6\u00b7\u00b8\u00b9\u00ba\u00bb\u00bc\u00bd\u00be\u00bf\u00d7\u00f7\u2013\u2014\u2015\u2017\u2018\u2019\u201a\u201b\u201c\u201d\u201e\u2020\u2021\u2022\u2026\u2030\u2032\u2033\u2039\u203a\u203c\u203e\u2044\u204a\u20a0\u20a1\u20a2\u20a3\u20a4\u20a5\u20a6\u20a7\u20a8\u20a9\u20aa\u20ab\u20ac\u20ad\u20ae\u20af\u20b0\u20b1\u20b2\u20b3\u20b4\u20b5\u20b6\u20b7\u20b8\u20b9\u20ba\u20bb\u20bc\u20bd\u20be]/;
    // const specialCharRegex = /[^0-9a-zA-Z]+/;
    // eslint-disable-next-line
    const phoneValidCharacters = /^[0-9\+\s\/.,\-()]*$/;
    const phoneNumberInternationalSKRegex = /(^$)|(^([+]|00)421[1-9][0-9]{8}$)/;
    const phoneNumberInternationalRegex = /(^$)|(^([+]|00)[0-9]{9,15}$)/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    // const ibanRegex = /^([a-zA-Z]{2}[0-9]{2}(?:[ ]?([0-9]|[A-Z]){4}){1}([ ]?[0-9]{4}){2}(([ ]?[0-9]{4}){1,3})?((([ ]?[0-9]{4})?([ ]?[0-9]{1,2}))|(([ ]?[0-9]{1,4})|([ ]?[0-9]{1,2})))[ ]?)$/;
    const bicSwiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}$/;
    const validNameRegex = /^([a-zA-Z\u00C0-\u024F][a-zA-Z.,' \-\u00C0-\u024F\u1E00-\u1EFF]*)$/;
    const numberWithSpecialCharactersRegex = /^[0-9a-zA-Z \-/]*$/;
    const numberWithLettersRegex = /^[0-9a-zA-Z]*$/;
    // const sppRegex = /@spp.sk\s*$/;
    const eicNumberRegex = /^24([A-Z]{3})[0-9A-Z]*$/;
    //const taxIdNumberRegex = /^[\w ]{8,18}$/;
    const taxIdNumberRegex = /^\d{10}$/;
    const vatRegistrationNumberRegex = /^[A-Z]{2}[a-zA-Z0-9 +*-]{5,20}$/;
    const podnumberRegex = /^[a-zA-Z]{8}[0-9]{12}$/;
    const companyRegistrationNumberRegex = /^\d{8}$/;
    const businessPartnerExternalIdRegex = /^51[0-9]{8}$/;
    // const AllLatisCharactersRegex = /^[a-zA-Z\s.,ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]+$/;

    const formRules = {
        required: { required: { value: true, message: t('common.input-rules.required') } },
        requiredNumber: {
            validate: {
                doValidation: (value: string) => {
                    const num = Number(value);
                    if (!value || value.indexOf('e') >= 0 || isNaN(num) || !isFinite(num)) {
                        return t('common.input-rules.requiredNumber');
                    }
                },
            },
        },
        number: {
            validate: {
                numberValidation: (value: string) => {
                    const num = Number(value);
                    if (value.indexOf('e') >= 0 || isNaN(num) || !isFinite(num)) {
                        return t('common.input-rules.requiredNumber');
                    }
                },
            },
        },
        requiredInteger: {
            validate: {
                doValidation: (value: string) => {
                    const num = Number(value);
                    if (!value || value.indexOf('e') >= 0 || value.indexOf('.') >= 0 || value.indexOf(',') >= 0 || isNaN(num) || !isFinite(num)) {
                        return t('common.input-rules.requiredInteger');
                    }
                },
            },
        },
        length: (length: number) => {
            return {
                validate: {
                    length: (value: string) => {
                        if (value && value.length !== length) {
                            return t('common.input-rules.value-length', { count: length });
                        }
                    },
                },
            };
        },
        minLengthX: (x: number) => {
            return { minLength: { value: x, message: t('common.input-rules.minLength', { minLength: x }) } };
        },
        maxLengthX: (x: number) => {
            return { maxLength: { value: x, message: t('common.input-rules.maxLength', { maxLength: x }) } };
        },
        min: (x: number) => {
            return { min: { value: x, message: t('common.input-rules.min', { min: x }) } };
        },
        max: (x: number) => {
            return { max: { value: x, message: t('common.input-rules.max', { max: x }) } };
        },
        //Issue with following patterns is that only one will be applied. If you need multiple-pattern combination, create new complex pattern or separate rules object e.g. passwordRules
        containNumber: { pattern: { value: numbersRegex, message: t('common.input-rules.mustContainNumber') } },
        containSpecialChar: { pattern: { value: specialCharRegex, message: t('common.input-rules.mustContainSpecialChar') } },
        email: { pattern: { value: emailRegex, message: t('common.input-rules.email') } },
        taxIdNumber: { pattern: { value: taxIdNumberRegex, message: t('common.input-rules.taxIdNumber') } },
        vatRegistrationNumber: { pattern: { value: vatRegistrationNumberRegex, message: t('common.input-rules.vatRegistrationNumber') } },
        url: { pattern: { value: urlRegex, message: t('common.input-rules.url') } },
        postalCodeSk: { pattern: { value: postalCodeSkRegex, message: t('common.input-rules.postalCode') } },
        postalCode: { pattern: { value: postalCodeRegex, message: t('common.input-rules.postalCode') } },
        iban: {
            validate: {
                validIban: (value: string) => {
                    if (!IBAN.isValid(value)) {
                        return t('common.input-rules.iban');
                    }
                },
            },
        },
        validDate: { pattern: { value: dateRegex, message: t('common.input-rules.date') } },
        validName: { pattern: { value: validNameRegex, message: t('common.input-rules.invalid-name') } },
        numberWithCharacters: { pattern: { value: numberWithSpecialCharactersRegex, message: t('common.input-rules.invalid-name') } },
        numberWithLetters: { pattern: { value: numberWithLettersRegex, message: t('common.input-rules.invalid-name') } },
    };

    const required = t('common.input-rules.required');
    const requiredTrimed = {
        validate: { requiredTrimedNotEmpty: (value: string) => (value && String(value).trim() !== '') || required },
    };

    const numericRules = ({ min, max }: { min?: number; max?: number }) => {
        return {
            validate: {
                number: (value: string) => {
                    const m = t('common.input-rules.only-numbers', { max: max });
                    return onlyNumbersRegex.test(value) || m;
                },
                lo: (value: number | string) => {
                    const m = t('common.input-rules.min', { min: min });
                    return min == null || value >= min || m;
                },
                hi: (value: number | string) => {
                    const m = t('common.input-rules.max', { max: max });
                    return max == null || value <= max || m;
                },
            },
        };
    };

    const notEqualsMessage = (other: string | number) => t('common.input-rules.value-must-not-equal', { other: other });
    const notEquals = (other: string | number, message?: string) => ({
        validate: {
            str: (value: string | number) => {
                return value.toString() !== other.toString() || message || notEqualsMessage(other);
            },
        },
    });

    const firstCharMustBeMessage = (char: string) => t('common.input-rules.value-first-char-must-be', { char });
    const firstCharMustBe = (char: string, optional = false) => ({
        validate: {
            str: (value: string) => {
                if (optional && value === '') {
                    return true;
                }
                return value.charAt(0) === char || firstCharMustBeMessage(char);
            },
        },
    });

    const emailSameAsLoggedInCustomer = (customerEmail?: string) => ({
        validate: {
            str: (value: string) => {
                if (value === customerEmail) {
                    return t('common.input-rules.email-same-as-logged-in-customer');
                }
            },
        },
    });

    const bicSwift = (iban?: string) => ({
        validate: {
            validBicSwift: (value: string) => {
                if (!bicSwiftRegex.test(value)) {
                    return t('common.input-rules.bicSwift');
                }
                if (iban && IBAN.isValid(iban)) {
                    const ibanFirstTwoChars = iban.substring(0, 2).toUpperCase();
                    const bicSwiftChars = value.substring(4, 6).toUpperCase();
                    if (ibanFirstTwoChars !== bicSwiftChars) {
                        return t('common.input-rules.bicSwift-to-iban');
                    }
                }
            },
        },
    });

    const eicNumberFormat = (distributionAreas?: string[]) => {
        return {
            validate: {
                pattern: (value?: string) => {
                    if (value == null || value?.length === 0) {
                        return t('common.input-rules.required');
                    }
                    if (!value?.startsWith('24Z')) {
                        return t('common.input-rules.invalid-eic-number-prefix-format');
                    }
                    if (value && value.length !== 16) {
                        return t('common.input-rules.invalid-eic-number-length-format', { length: 16 });
                    }
                    const knownDistributionAreas = distributionAreas || ['ZZS', 'ZSS', 'ZVS'];
                    const match = value.match(eicNumberRegex);
                    if (match == null) {
                        return t('common.input-rules.invalid-characters-eic-number-format');
                    }
                    const distributionArea = match[1];
                    if (distributionArea != null && !knownDistributionAreas.includes(distributionArea)) {
                        return t('common.input-rules.invalid-eic-number-unknown-distribution-area');
                    }
                },
            },
        };
    };

    const companyRegistrationNumber = {
        validate: {
            pattern: (value?: string) => {
                if (!value) {
                    return true;
                }
                if (Number.isNaN(value)) {
                    return t('common.input-rules.invalid-date-format');
                }
                // má požadovaný tvar?
                const match = value.match(companyRegistrationNumberRegex);
                if (match == null) {
                    return t('common.input-rules.invalid-characters-eic-number-format');
                }

                //  kontrolní součet
                let a = 0;
                for (let i = 0; i < 7; i++) {
                    a += Number(value[i]) * (8 - i);
                }

                let c = 0;
                a = a % 11;
                if (a === 0) {
                    c = 1;
                } else if (a === 1) {
                    c = 0;
                } else {
                    c = 11 - a;
                }

                if (Number(value[7]) === c) {
                    return true;
                }

                return t('common.input-rules.invalid-characters-eic-number-format');
            },
        },
    };

    const podNumberFormat = {
        validate: {
            pattern: (value?: string) => {
                if (value == null || value?.length === 0) {
                    return t('common.input-rules.required');
                }
                if (!value?.startsWith('SKSPPDIS')) {
                    return t('common.input-rules.invalid-pod-number-prefix-format');
                }
                const match = value.match(podnumberRegex);
                if (match == null) {
                    return t('common.input-rules.invalid-characters-eic-number-format');
                }
                if (value && value.length !== 20) {
                    return t('common.input-rules.invalid-eic-number-length-format', { length: 20 });
                }
            },
        },
    };

    const value10_100000 = t('common.input-rules.value-10-100000');
    const value1_24 = t('common.input-rules.value-1-24');
    const batchSize = {
        validate: {
            batchSize: (value: number) => (value && value >= 10 && value <= 100000) || value10_100000,
        },
    };
    const batchDelay = {
        validate: {
            batchDelay: (value: number) => (value && value >= 1 && value <= 24) || value1_24,
        },
    };

    const email = t('common.input-rules.email');
    const oneCharDomain = t('common.input-rules.oneCharDomain');
    const onlyNumberDomain = t('common.input-rules.onlyNumberDomain');
    const sppDomain = {
        validate: {
            required: (value: string) => value !== '' || required,
            email: (value: string) => emailRegex.test(value) || email,
            oneCharDomain: (value: string) => value?.split('@')[1]?.split('.')[1]?.length > 1 || oneCharDomain,
            onlyNumberDomain: (value: string) => {
                const domain = value?.split('@')[1]?.split('.')[1];
                return Number(domain) !== parseInt(domain, 10) || onlyNumberDomain;
            },
            // sppDomain: (value: string) => !sppRegex.test(value),
        },
    };

    const passwordRules = {
        isValidPassword: {
            validate: (value: string) => {
                let fulfilConditions = 0;
                if (!value) {
                    return required;
                }
                const errorMessage = [];
                if (lowerCaseRegex.test(value)) {
                    fulfilConditions++;
                } else {
                    errorMessage.push('mustContainLowerCase');
                }
                if (upperCaseRegex.test(value)) {
                    fulfilConditions++;
                } else {
                    errorMessage.push('mustContainUpperCase');
                }
                if (numbersRegex.test(value)) {
                    fulfilConditions++;
                } else {
                    errorMessage.push('mustContainNumber');
                }
                if (specialCharRegex.test(value)) {
                    fulfilConditions++;
                } else {
                    errorMessage.push('mustContainSpecialChar');
                }
                if (fulfilConditions < 3) {
                    return t('common.input-rules.password-does-not-meet-requirements');
                }
                return true;
            },
        },
    };

    const phoneNumber = t('common.input-rules.phoneNumber');
    const phoneNumberValidCharacters = t('common.input-rules.phoneValidCharacters');
    const phoneRules = {
        validate: {
            validateCharacters: (value: string) => phoneValidCharacters.test(value) || phoneNumberValidCharacters,
            validateNumberInternational: (value: string) => {
                const formatedPhoneNumber = formatPhoneNumber(value);
                return (
                    (formatedPhoneNumber.startsWith('+421')
                        ? phoneNumberInternationalSKRegex.test(formatedPhoneNumber)
                        : phoneNumberInternationalRegex.test(formatedPhoneNumber)) || phoneNumber
                );
            },
        },
    };

    const timeRules = (format = 'HH:mm', requiredTime = false, timeToCompare?: string) => {
        return {
            isValidTime: {
                validate: {
                    timeFormat: (time: string) => {
                        if (time === '' && !requiredTime) {
                            return true;
                        }
                        if (moment(time, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm'], true).isValid()) {
                            return true;
                        }
                        if (!time) {
                            return required;
                        }
                        return t('common.input-rules.invalid-time-format', { format: format });
                    },
                },
            },
            isOlderThan: {
                validate: {
                    olderThan: (time: string) => {
                        if (
                            !timeToCompare ||
                            !time ||
                            !moment(timeToCompare, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm'], true).isValid() ||
                            !moment(time, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm'], true).isValid()
                        ) {
                            return true;
                        }
                        const timeWithDate = moment()
                            .set(parseFormattedTimeString(time))
                            .toDate()
                            .getTime();
                        const timeToCompareWithDate = moment()
                            .set(parseFormattedTimeString(timeToCompare))
                            .toDate()
                            .getTime();
                        if (timeToCompareWithDate > timeWithDate) {
                            return t('common.input-rules.time-not-be-older-than', { time: timeToCompare });
                        }
                        return true;
                    },
                },
            },
        };
    };

    const requiredTrimedFunction = (requiredValue = false) => {
        return {
            validate: {
                requiredTrimedNotEmpty: (value: string) => {
                    if (!requiredValue) {
                        return true;
                    } else {
                        return (value && String(value).trim() !== '') || required;
                    }
                },
            },
        };
    };

    const dateRules = (type?: 'BETWEEN' | 'NOT_OLDER_THAN' | 'NOT_NEWER_THAN', date1?: string, date2?: string) => {
        return {
            isValidDate: {
                validate: {
                    instance: (dateStringISO: string) => {
                        if (!dateStringISO) {
                            return true;
                        }
                        let date1asDate: Date | undefined = undefined;
                        let date2asDate: Date | undefined = undefined;
                        let dateInstance = new Date(dateStringISO);
                        if (Number.isNaN(dateInstance.valueOf())) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (dateInstance.toISOString() !== dateStringISO) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (Number.isNaN(dateInstance.valueOf()) || dateInstance.toISOString() !== dateStringISO) {
                            return true;
                        }
                        const partsDate1 = date1 && date1.slice(0, -1).split('T');
                        if (partsDate1) {
                            date1asDate = moment(partsDate1[0])
                                .utcOffset(0, true)
                                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                .toDate();
                        }
                        const partsDate2 = date2 && date2.slice(0, -1).split('T');
                        if (partsDate2) {
                            date2asDate = moment(partsDate2[0])
                                .utcOffset(0, true)
                                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                .toDate();
                        }
                        const parts = dateStringISO.slice(0, -1).split('T');
                        if (dateInstance) {
                            dateInstance = moment(parts[0])
                                .utcOffset(0, true)
                                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                .toDate();
                        }
                        if (type === 'NOT_OLDER_THAN') {
                            if (date1asDate && dateInstance.getTime() < date1asDate?.getTime()) {
                                return t('common.input-rules.date-not-be-older-than', {
                                    date: dateFromIsoToFormat(
                                        moment(date1asDate)
                                            .utcOffset(0, true)
                                            .toISOString(),
                                        'DD.MM.YYYY',
                                    ),
                                });
                            }
                        }
                        if (type === 'NOT_NEWER_THAN') {
                            if (date1asDate && dateInstance.getTime() > date1asDate?.getTime()) {
                                return t('common.input-rules.date-not-be-newer-than', {
                                    date: dateFromIsoToFormat(
                                        moment(date1asDate)
                                            .utcOffset(0, true)
                                            .toISOString(),
                                        'DD.MM.YYYY',
                                    ),
                                });
                            }
                        }
                        if (type === 'BETWEEN') {
                            if (
                                date1asDate &&
                                date2asDate &&
                                (date1asDate?.getTime() > dateInstance.getTime() || date2asDate?.getTime() < dateInstance.getTime())
                            ) {
                                return t('common.input-rules.date-must-be-between', {
                                    date1: dateFromIsoToFormat(
                                        moment(date1asDate)
                                            .utcOffset(0, true)
                                            .toISOString(),
                                        'DD.MM.YYYY',
                                    ),
                                    date2: dateFromIsoToFormat(
                                        moment(date2asDate)
                                            .utcOffset(0, true)
                                            .toISOString(),
                                        'DD.MM.YYYY',
                                    ),
                                });
                            }
                        }
                        return true;
                    },
                },
            },
            notFutureDate: {
                validate: {
                    notFutureDate: (dateStringISO: string) => {
                        if (!dateStringISO) {
                            return true;
                        }
                        const dateInstance = new Date(dateStringISO);
                        if (Number.isNaN(dateInstance.valueOf())) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (dateInstance.toISOString() !== dateStringISO) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (Number.isNaN(dateInstance.valueOf()) || dateInstance.toISOString() !== dateStringISO) {
                            return true;
                        }
                        if (dateInstance > new Date()) {
                            return t('common.input-rules.date-must-be-in-future');
                        }
                        return true;
                    },
                },
            },
            notPastDate: {
                validate: {
                    notFutureDate: (dateStringISO: string) => {
                        if (!dateStringISO) {
                            return true;
                        }
                        const dateInstance = new Date(dateStringISO);
                        if (Number.isNaN(dateInstance.valueOf())) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (dateInstance.toISOString() !== dateStringISO) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        if (Number.isNaN(dateInstance.valueOf()) || dateInstance.toISOString() !== dateStringISO) {
                            return true;
                        }
                        const todayMidnight = new Date();
                        todayMidnight.setHours(0, 0, 0);
                        if (dateInstance.getTime() < todayMidnight.getTime()) {
                            return t('common.input-rules.date-must-be-in-past');
                        }
                        return true;
                    },
                },
            },
            dateMin18Age: {
                validate: {
                    dateMin18Age: (dateStringISO: string) => {
                        if (!dateStringISO) {
                            return true;
                        }
                        const dateInstance = new Date(dateStringISO);
                        if (Number.isNaN(dateInstance.valueOf()) || dateInstance.toISOString() !== dateStringISO) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        const birthDateThreshold = new Date();
                        birthDateThreshold.setFullYear(new Date().getFullYear() - 18);
                        if (dateInstance > birthDateThreshold) {
                            return t('common.input-rules.date-must-be-at-least-18-age');
                        }
                        return true;
                    },
                },
            },
            dateMax150Age: {
                validate: {
                    dateMax150Age: (dateStringISO: string) => {
                        if (!dateStringISO) {
                            return true;
                        }
                        const dateInstance = new Date(dateStringISO);
                        if (Number.isNaN(dateInstance.valueOf()) || dateInstance.toISOString() !== dateStringISO) {
                            return t('common.input-rules.invalid-date-format');
                        }
                        const birthDateThreshold = new Date();
                        birthDateThreshold.setFullYear(new Date().getFullYear() - 150);
                        if (dateInstance < birthDateThreshold) {
                            return t('common.input-rules.date-must-be-max-150-age');
                        }
                        return true;
                    },
                },
            },
        };
    };

    const businessPartnerNumber = {
        validate: {
            pattern: (value?: string) => {
                if (!value || String(value).trim() === '') {
                    return t('common.input-rules.required');
                }
                if (value.length !== 10) {
                    return t('common.input-rules.invalid-eic-number-length-format', { length: 10 });
                }
                if (value.match(businessPartnerExternalIdRegex) === null) {
                    return t('common.input-rules.invalid-characters-eic-number-format');
                }
            },
        },
    };

    const latinCharacters = {
        validate: {
            pattern: (value?: string) => {
                if (value && value.match(latinCharactersRegex) === null) {
                    return t('common.input-rules.invalid-name');
                }
                return true;
            },
        },
    };

    const mergeValidations = (validations: { [key: string]: (value: any) => string | boolean }) => {
        const validator = {
            validate: {
                ...validations,
            },
        };
        return validator;
    };

    return {
        formRules,
        passwordRules,
        requiredTrimed,
        phoneRules,
        sppDomain,
        batchSize,
        batchDelay,
        notEquals,
        dateRules,
        numericRules,
        timeRules,
        requiredTrimedFunction,
        mergeValidations,
        firstCharMustBe,
        emailSameAsLoggedInCustomer,
        eicNumberFormat,
        companyRegistrationNumber,
        podNumberFormat,
        bicSwift,
        businessPartnerNumber,
        latinCharacters,
    };
};
