import React, { createContext, ReactNode, useContext } from 'react';
import { useKeyValueStore } from '../../../../hooks/use-key-value';

type ChildrenType = {
    children: ReactNode | ReactNode[];
};
type StateSetter<T> = T | ((prevState: T) => T);
type ContextValueType = {
    shareSingleDeliveryPointModalId: string;
    setShareSingleDeliveryPointModal: (setter: StateSetter<string>) => void;
};

const initialValue: ContextValueType = { shareSingleDeliveryPointModalId: '', setShareSingleDeliveryPointModal: () => undefined };
const ShareSingleDeliveryPointContext = createContext(initialValue);

export const ShareSingleDeliveryPointModalProvider: React.FC<ChildrenType> = ({ children }) => {
    const [shareSingleDeliveryPointModalId, setShareSingleDeliveryPointModal] = useKeyValueStore<string>('DPsOpenSharedModal', '');

    return (
        <ShareSingleDeliveryPointContext.Provider value={{ shareSingleDeliveryPointModalId, setShareSingleDeliveryPointModal }}>
            {children}
        </ShareSingleDeliveryPointContext.Provider>
    );
};

export const useShareSingleDeliveryPointContext = (): ContextValueType => useContext(ShareSingleDeliveryPointContext);
