import i18n from 'i18next';
import React from 'react';
import { useHelpText } from '../../hooks/use-help-text';
import ShowError from '../show-error';

type HelpTextProps = {
    className?: string;
    screen: string;
    field: string;
};

const HelpText: React.FC<HelpTextProps> = ({ className, screen, field }) => {
    const [content, error] = useHelpText(screen, field, i18n.language);

    return (
        <>
            <ShowError className={className} error={error} />

            {!content ? null : <div className={className} dangerouslySetInnerHTML={{ __html: '' + content }} />}
        </>
    );
};

export default HelpText;
