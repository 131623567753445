export const KINDcodeHome = '141';
export const SK_CODE = 'SK';
export const SAP_DELETE = 'ZMAZAT';
export const MARKETING_CONSENTS_LIMIT_IN_DAYS_FOR_EXPIRE_SOON = 30;
export const MARKETING_CONSENTS_ACCEPTED = 'ACCEPTED';
export const MARKETING_CONSENTS_REJECTED = 'REJECTED';

export const PRE_REGISTRATION = 'pre_registration';

export const ANALYTICAL_COOKIES = 'analytics_storage';
export const MARKETING_COOKIES = 'ad_storage';
export const SPP_DATA_PRIVACY_PAGE_EN = 'https://www.spp.sk/en/data-privacy-and-personal-data-processing';
export const SPP_DATA_PRIVACY_PAGE_SK = 'https://www.spp.sk/ochrana-osobnych-udajov';

export enum CommodityEnum {
    GAS = 'ZP',
    ELECTRICITY = 'EE',
}

export enum BusinessPartnerTypeEnum {
    PERSON = 'PRIVATE_PERSON',
    COMPANY = 'COMPANY',
}

export enum PaymentMethodCodeEnum {
    SIPO = 'S',
    BANK_TRANSFER = 'P',
    INKASO = 'I',
    VOUCHER = 'U',
    INKASO_OTHER_BANK = 'F',
}

export enum ComplainedAdvancesPaymentMethodCodeEnum {
    SIPO = 'S',
    BANK_TRANSFER = 'P',
    INKASO = 'I',
    POST = 'U',
}

export enum InvoiceDeliveryStepEnum {
    EMAIL = 'EMAIL',
    ADDRESS = 'ADDRESS',
}

export enum MeterReadingEnum {
    GAS = 'GAS',
    EE_1T = 'EE_1T',
    EE_2T = 'EE_2T',
}

export enum AssumedEeConsumptionEnum {
    T1 = '1T',
    T2 = '2T',
}

export enum PhasesConsumptionEnum {
    F1 = '1',
    F3 = '3',
}

export enum DeliveryPointZpConnectionEnum {
    RENEWAL = 'RENEWAL',
    NEW = 'NEW',
}
export enum DeliveryPointEeConnectionEnum {
    NEW = 'NEW',
    MIGRATION = 'MIGRATION',
}

export enum ContractDeliveryEnum {
    POST = 'POST',
    DELIVERY_MAN = 'COURIER',
}

export enum ContractTerminationEnum {
    YES = 'YES',
    NO = 'NO',
}

export enum ContractBindingEnum {
    YES = 'YES',
    NO = 'NO',
}

export enum ReasonEnum {
    STOP = 'STOP',
    RESTORE = 'RESTORE',
}

export enum OverpaymentSettlementEnum {
    // AFTER_DUEDATE_SETTLEMENT = 'AFTER_DUEDATE_SETTLEMENT',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    ADDRESS = 'ADDRESS',
}

export enum ComplainedInvoiceProblemDescriptionEnum {
    WRONG_METER_READING = 'WRONG_METER_READING',
    WRONG_TARIFF = 'WRONG_TARIFF',
    WRONG_CUSTOMER_DATA = 'WRONG_CUSTOMER_DATA',
    OTHER = 'OTHER',
}

export enum ConnectionElectricityEnum {
    TARIF1 = 'TARIF1',
    TARIF2 = 'TARIF2',
}

export enum InvoiceGroup {
    INVOICE = 'INVOICE',
    CREDIT = 'CREDIT',
    ADVANCE_INVOICE = 'ADVANCE_INVOICE',
    REPAYMENT_PLAN = 'REPAYMENT_PLAN',
}

export enum InvoiceState {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    INVALID = 'INVALID',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    CANCELLED = 'CANCELLED',
}

export enum PaymentType {
    INKASO = 'INKASO',
    BANK_TRANSFER = 'BANK_TRANSFER',
    POSTAL_ORDER = 'POSTAL_ORDER',
    SIPO = 'SIPO',
}

export enum CustomerTypeEnum {
    'ONE' = '1',
    'TWO' = '2',
    'THREE' = '3',
}

export enum CustomerStateEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PRE_REGISTRATION = 'PRE_REGISTRATION',
    DELETED = 'DELETED',
    UNVERIFIED = 'UNVERIFIED',
    PRE_REGISTRATION_BATCH = 'PRE_REGISTRATION_BATCH',
    PRE_REGISTRATION_SAP = 'PRE_REGISTRATION_SAP',
}
export enum CustomerAccountTypeEnum {
    'ALL' = 'ALL',
    'BULK' = 'COLLECTIVE',
    'INDIVIDUAL' = 'INDIVIDUAL',
}

export enum AuthEnum {
    CODE_VERIFIER_NAME = 'cdvrfr',
    CODE_CHALLENGE_METHOD = 'S256',
    RESPONSE_TYPE_CODE = 'code',
}

// example activities TODO:
export enum EmployeeActivityTypeEnum {
    LOGIN_AS_CUSTOMER = 'LOGIN_AS_CUSTOMER',
    CREATE_PORTAL_ACCOUNT = 'CREATE_PORTAL_ACCOUNT',
}

// example activities TODO:
export enum CustomerActivityTypeEnum {
    CUSTOMER_LOGIN = 'CUSTOMER_LOGIN',
    CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT',
    CUSTOMER_LOCK = 'CUSTOMER_LOCK',
    CUSTOMER_PASSWORD_RECOVERY_REQUEST = 'CUSTOMER_PASSWORD_RECOVERY_REQUEST',
    CUSTOMER_PASSWORD_RECOVERY_SUCCESS = 'CUSTOMER_PASSWORD_RECOVERY_SUCCESS',
    CUSTOMER_SHARING_OWNER_GRANT = 'CUSTOMER_SHARING_OWNER_GRANT',
    CUSTOMER_SHARING_CONSUMER_GRANT = 'CUSTOMER_SHARING_CONSUMER_GRANT',
    CUSTOMER_SHARING_OWNER_REVOKE = 'CUSTOMER_SHARING_OWNER_REVOKE',
    CUSTOMER_SHARING_CONSUMER_REVOKE = 'CUSTOMER_SHARING_CONSUMER_REVOKE',
}
export enum CustomerRequestStatusEnum {
    PRE_CREATED = 'PRE_CREATED',
    CREATED = 'CREATED',
    REGISTERED = 'REGISTERED',
    CANCELLED_BY_USER = 'CANCELLED_BY_USER',
    GENERATED = 'GENERATED',
    SAP_OPEN = 'SAP_OPEN',
    SAP_IN_PROGRESS = 'SAP_IN_PROGRESS',
    SAP_CANCELLED_BY_USER = 'SAP_CANCELLED_BY_USER',
    SAP_CANCELLED = 'SAP_CANCELLED',
    SAP_FINISHED = 'SAP_FINISHED',
    COMPLETION_IN_PROGRESS = 'COMPLETION_IN_PROGRESS',
    COMPLETION_REJECTED = 'COMPLETION_REJECTED',
    EXPIRED = 'EXPIRED',
}
export enum CustomerRequestStatusFilterEnum {
    PRE_CREATED = 'PRE_CREATED',
    CREATED = 'CREATED',
    REGISTERED = 'REGISTERED',
    CANCELLED_BY_USER = 'CANCELLED_BY_USER',
    SAP_OPEN = 'SAP_OPEN',
    SAP_IN_PROGRESS = 'SAP_IN_PROGRESS',
    SAP_CANCELLED_BY_USER = 'SAP_CANCELLED_BY_USER',
    SAP_CANCELLED = 'SAP_CANCELLED',
    SAP_FINISHED = 'SAP_FINISHED',
}

export enum NotificationStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum NotificationTypeEnum {
    CUSTOMER_NOTICE = 'CUSTOMER_NOTICE',
    CUSTOMER_SYSTEM = 'CUSTOMER_SYSTEM',
    EMPLOYEE_SYSTEM = 'EMPLOYEE_SYSTEM',
}

export enum NotificationGroupEnum {
    INVOICE = 'INVOICE',
    CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
    LIMIT_OVERFLOW = 'LIMIT_OVERFLOW',
}

export enum NotificationPriorityEnum {
    LOW = 'LOW',
    HIGH = 'HIGH',
}

export enum NotificationTypesEnum {
    InvoicesAndPayments = 'INVOICES_AND_PAYMENTS',
    Requests = 'CUSTOMER_REQUESTS',
    TechnicalNotifications = 'TECHNICAL_NOTIFICATIONS',
    SystemNotifications = 'SYSTEM_NOTIFICATIONS',
    CapacityExceeded = 'CAPACITY_EXCEEDED',
    SpecialOffers = 'SPECIAL_OFFERS',
    CUSTOMER_REQUEST_STATUS_CHANGE = 'CUSTOMER_REQUEST_STATUS_CHANGE',
}
export enum EmployeeTypeEnum {
    EMPLOYEE = 'EMPLOYEE',
    EMPLOYEE_AS_CUSTOMER = 'EMPLOYEE_AS_CUSTOMER',
}

export enum BussinessPartnerState {
    UPLOADED = 'UPLOADED',
    UPLOADING = 'UPLOADING',
    CONFIRMWAITING = 'CONFIRMWAITING',
}

export enum ConsumptionValueUnits {
    KWH = 'KWH',
    KW = 'KW',
    CUBIC_METRE = 'M3',
}

export enum DateScaleConsumption {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    ALL = 'ALL',
}

export enum MeteringTypes {
    SAP = 'SAP',
    INFO = 'INFO',
}
export enum HelpVariantCommodityEnum {
    GAS = 'GAS',
    ELECTRICITY = 'ELECTRICITY',
    ALL = 'ALL',
}

export enum HelpVariantSegmentEnum {
    HOME = 'HOME',
}

export enum ReportJoinTypesEnum {
    INNER_JOIN = 'INNER_JOIN',
    OUTER_JOIN = 'OUTER_JOIN',
    EXISTS = 'EXISTS',
    NOT_EXISTS = 'NOT_EXISTS',
}

export enum ReportFilterConditionOperatorsEnum {
    IS_EQUAL = 'IS_EQUAL',
    IS_NOT_EQUAL = 'IS_NOT_EQUAL',
    IS_GREATER_THAN = 'IS_GREATER_THAN',
    IS_GREATER_THAN_OR_EQUAL = 'IS_GREATER_THAN_OR_EQUAL',
    IS_LESS_THAN = 'IS_LESS_THAN',
    IS_LESS_THAN_OR_EQUAL = 'IS_LESS_THAN_OR_EQUAL',
    IS_NULL = 'IS_NULL',
    IS_NOT_NULL = 'IS_NOT_NULL',
    IS_BETWEEN = 'IS_BETWEEN',
    IS_OLDER_THAN = 'IS_OLDER_THAN',
    IS_NOT_OLDER_THAN = 'IS_NOT_OLDER_THAN',
}

export enum ReportUnitTypesEnum {
    years = 'years',
    months = 'months',
    weeks = 'weeks',
    days = 'days',
    hours = 'hours',
    minutes = 'minutes',
    seconds = 'seconds',
}

export enum BusinessPartnerStateEnum {
    PAIRED = 'PAIRED',
    PAIRING_VERIFICATION = 'PAIRING_VERIFICATION',
    PAIRING_CREATED = 'PAIRING_CREATED',
    UNPAIRING_CREATED = 'UNPAIRING_CREATED',
}

export enum LastNameChangeReasonEnum {
    MARRIAGE = 'MARRIAGE',
    DIVORCE = 'DIVORCE',
    OTHER = 'OTHER',
}

export enum ConsumptionCategoryEnum {
    HOME = 'HOME',
    RETAIL = 'RETAIL',
    WHOLESALE = 'WHOLESALE',
}

export enum ConsumptionUnits {
    KWH = '[kWh]',
    MWH = '[MWh]',
    GWH = '[GWh]',
    M3 = '[m³]',
}

export enum ConsumptionGraphFormattedUnits {
    KWH = 'kWh',
    MWH = 'MWh',
    GWH = 'GWh',
    M3 = 'm³',
}

export enum ConsumptionPowerGraphFormattedUnits {
    KW = 'kW',
    MW = 'MW',
    GW = 'GW',
    M3 = 'm³',
}

export enum AdvancePaymentPeriodEnum {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    HALF_YEARLY = 'HALF_YEARLY',
    YEARLY = 'YEARLY',
}

export enum ReservedValuesEnum {
    DMM = 'DMM',
    RK = 'RK',
    MRK = 'MRK',
}

export enum HaveAccountEnum {
    YES = 'YES',
    NO = 'NO',
}

export enum DistributorOptionsEnum {
    ZSE = 'ZSE Energia',
    SSE = 'Stredoslovenská energetika',
    VSE = 'Východoslovenská energetika',
}

export enum DistributionAreasEnum {
    ZVS = 'ZVS',
    ZSS = 'ZSS',
    ZZS = 'ZZS',
}

export enum Rates {
    'C2-X3 (TDO1)' = 'C2-X3 (TDO1)',
    'C2-X3 (TDO2)' = 'C2-X3 (TDO2)',
    'C2-X3 (TDO3)' = 'C2-X3 (TDO3)',
    'C2-X3 (TDO8)' = 'C2-X3 (TDO8)',
    'X3-C2 (TDO1)' = 'X3-C2 (TDO1)',
    'X3-C2 (TDO2)' = 'X3-C2 (TDO2)',
    'X3-C2 (TDO3)' = 'X3-C2 (TDO3)',
    'X3-C2 (TDO8)' = 'X3-C2 (TDO8)',
    DD1 = 'DD1',
    DD2 = 'DD2',
    DD3 = 'DD3',
    DD4 = 'DD4',
    DD5 = 'DD5',
    DD6 = 'DD6',
    DD7 = 'DD7',
    DD8 = 'DD8',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    C6 = 'C6',
    C7 = 'C7',
    C8 = 'C8',
}

export const MANDATE_CID = 'SK40ZZZ70000000032';
