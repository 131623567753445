import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAddress } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAddress;
    bold?: boolean;
};

const NewDeliveryPointAddressItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.new-data.new-delivery-point-address')}>
            {data?.street && <ItemPreviewContent bold={bold}>{`${data?.street || ''} ${data?.number || ''}`}</ItemPreviewContent>}
            {data?.city && <ItemPreviewContent bold={bold}>{`${data?.postalCode || ''} ${data?.city || ''}`}</ItemPreviewContent>}
            {data?.floor && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.address.floor')}: ${data?.floor || ''}`}</ItemPreviewContent>
            )}
            {data?.flatNumber && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.address.appartments-number')}: ${data?.flatNumber ||
                    ''} `}</ItemPreviewContent>
            )}
        </ItemPreview>
    );
};

export default NewDeliveryPointAddressItem;
