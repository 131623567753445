import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint, SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, CardHeader } from 'reactstrap';
import { ClickableElement, LoadingIndicator } from '../../../components/common';
import BaseSelect from '../../../components/common/base-select';
import BaseTextarea from '../../../components/common/base-textarea';
import Checkbox from '../../../components/common/checkbox';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { useApi } from '../../../main';
import { PRICE_LINK_ZP, RequestTemplateCode } from '../../../models/model';
import { IRootState } from '../../../reducers';
import { ChangeFormStepEnum } from '../../../reducers/interfaces/delivery-point-detail-state';
import { useSendRequest } from '../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../delivery-points/detail/data/change-request-sent';
import { CustomerRequestTypeEnum } from '../config/enums';

interface IPromptResumptionOfGasDataForm {
    deliveryPointId: string;
    condition1: boolean;
    condition2: boolean;
    note?: string;
}

const NOTE_MAX_COUNT = 510;
interface IPromptResumptionOfGasProps {
    onClose: () => void;
    unitedDeliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
}

const PromptResumptionOfGas: React.FC<IPromptResumptionOfGasProps> = ({ onClose, unitedDeliveryPoints }) => {
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const [showNote, setShowNote] = useState<boolean>(false);
    const initializeRequestCreationProcess = useSendRequest();
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [loading, setLoading] = useState<boolean>(false);
    const additionalData = useSelector((state: IRootState) => state.customerRequest.additionalData);

    const { register, handleSubmit, errors, watch, setValue, getValues } = useForm<IPromptResumptionOfGasDataForm>({
        defaultValues: { deliveryPointId: additionalData?.dpuuid, condition1: false, condition2: false },
    });
    const message: string | undefined = watch('note');
    const { formRules } = useFormRules();
    const [t] = useTranslation();
    const api = useApi();
    const [template, setTemplate] = useState<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate>();

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    useEffect(() => {
        getRequestTemplates(
            { code: CustomerRequestTypeEnum.ZOM_ZOZODZP },
            {
                onSuccess: (data) => {
                    if (data && data[0] != null) {
                        const foundTemplate = data[0];
                        setTemplate(foundTemplate);
                    } else {
                        onClose();
                    }
                },
                onError: () => {
                    onClose();
                },
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormSubmit = async (formData: IPromptResumptionOfGasDataForm) => {
        const deliveryPointFullInfo = unitedDeliveryPoints.deliveryPoints?.find(
            (dp) => dp.id === (formData.deliveryPointId ? formData.deliveryPointId : gasDeliveryPoints && gasDeliveryPoints[0].id),
        );

        if (template && deliveryPointFullInfo) {
            const data: any = {
                content: {
                    metadata: {
                        businessPartnerId: additionalData?.bpuuid,
                        deliveryPointId: formData.deliveryPointId ? formData.deliveryPointId : gasDeliveryPoints && gasDeliveryPoints[0].id,
                    },
                    statement: formData.condition1 && formData.condition2,
                    note: formData.note,
                    type: CustomerRequestTypeEnum.ZOM_ZOZODZP,
                    deliveryPoint: { id: deliveryPointFullInfo.id },
                    entityReferences: [
                        {
                            contractId: deliveryPointFullInfo?.contract?.id,
                            deliveryPointId: deliveryPointFullInfo.id,
                            contractAccountId: deliveryPointFullInfo.contract?.contractAccount?.id,
                        },
                    ],
                    paymentApproval: formData.condition2,
                },
                customerRequestTemplate: template,
            };
            setLoading(true);
            initializeRequestCreationProcess(data)
                .then(() => goToNextStep())
                .catch((err) => err.message)
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        setMessageCounter(message?.length ?? 0);
    }, [message]);

    const gasDeliveryPoints = useMemo(() => unitedDeliveryPoints.deliveryPoints?.filter((deliveryPoint) => deliveryPoint.type === 'ZP'), [
        unitedDeliveryPoints,
    ]);

    useEffect(() => {
        setValue(
            'deliveryPointId',
            (gasDeliveryPoints?.find((deliveryPoint) => deliveryPoint.id === additionalData?.dpuuid) && additionalData?.dpuuid) ??
                (gasDeliveryPoints && gasDeliveryPoints[0].id),
        );
    }, [gasDeliveryPoints, setValue, additionalData]);

    const label = (
        <>
            <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.condition-2" />{' '}
            <a href={PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                {t('customer-request.prepare.actual-external-price-link')}&nbsp;
            </a>
        </>
    );

    const isSubmitAllowed = useMemo(
        () => getValues('condition1') && getValues('condition2'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watch && watch('condition1'), watch && watch('condition2'), getValues],
    );

    return (
        <>
            {loading && <LoadingIndicator />}
            {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onClose} />}
            {step === ChangeFormStepEnum.EDITING && (
                <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                    {gasDeliveryPoints && gasDeliveryPoints.length > 1 && (
                        <BaseSelect ref={register({})} name="deliveryPointId" label={t('common.delivery-point')}>
                            {gasDeliveryPoints.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {`${t('enums.CommodityEnum.ZP')} - ${t('delivery-point.detail.contract-data.contract-number')}  ${
                                        item?.contract?.externalId
                                    }`}
                                </option>
                            ))}
                        </BaseSelect>
                    )}
                    <CardHeader className="border-bottom-0 rounded w-100 mb-3" style={{ backgroundColor: 'white' }}>
                        <Checkbox
                            ref={register(formRules.required)}
                            errors={errors}
                            id="condition1"
                            name="condition1"
                            label={<Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.condition-1" />}
                        />
                    </CardHeader>
                    <CardHeader className="border-bottom-0 rounded w-100 mb-3" style={{ backgroundColor: 'white' }}>
                        <Checkbox ref={register(formRules.required)} errors={errors} id="condition2" name="condition2" label={label} />
                    </CardHeader>
                    <div className="my-2">
                        <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                            <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                        </ClickableElement>
                    </div>
                    {showNote && (
                        <BaseTextarea
                            ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                            errors={errors}
                            name={'note'}
                            maxLength={NOTE_MAX_COUNT}
                            label={t('customer-request.steps.attachments.title')}
                            counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                            optional
                        />
                    )}
                    <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!isSubmitAllowed}>
                        <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.ask-for-resumption">Požiadať o obnovenie dodávky</Trans>
                    </Button>
                </form>
            )}
        </>
    );
};

export default PromptResumptionOfGas;
