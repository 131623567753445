import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { AppSettingsActions } from '../../actions/app-settings-actions';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';
import { accessNestedProperty, combineRefs } from '../../main';
import Routes from '../../routes/routes';

const strengthEval = [
    { strength: 'very-weak', color: '#800020' },
    { strength: 'weak', color: '#DC2A2A' },
    { strength: 'medium', color: '#AA5D00' },
    { strength: 'strong', color: '#458600' },
    { strength: 'very-strong', color: '#006D00' },
];

type PasswordInputPropsType = {
    id: string;
    label?: string | JSX.Element;
    name: string;
    errors: FieldErrors;
    doNotValidate?: boolean;
    disableReveal?: boolean;
    trigger: ((name: any) => Promise<boolean>) | undefined;
    autoFocus?: boolean;
};

const PasswordInput = forwardRef(
    ({ errors, name, id, label, doNotValidate, disableReveal, trigger, autoFocus }: PasswordInputPropsType, ref: any) => {
        const [password, setPassword] = useState<string | undefined>();
        const [isPasswordRevealed, setIsPasswordRevealed] = useState<boolean>(false);
        const [strengthIndex, setStrengthIndex] = useState<number>(0);
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);
        const { t, i18n } = useTranslation();
        const history = useHistory();
        const isRegistration = history.location.pathname === Routes.REGISTRATION;
        const passwordId = useMemo(() => `password-row-${id}`, [id]);
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(AppSettingsActions.setPrivacyScreen(true));
            return () => {
                dispatch(AppSettingsActions.setPrivacyScreen(false));
            };
        }, [dispatch]);

        const revealPassword = (value: boolean) => {
            if (!disableReveal) {
                setIsPasswordRevealed(value);
            }
        };
        const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            setTooltipOpen(true);
        };

        useEffect(() => {
            //password == null <=> (password === null || password === undefined)
            if (password == null) return;
            import('zxcvbn').then((zxcvbn) => {
                setStrengthIndex(zxcvbn.default(password).score);
            });
        }, [password]);

        const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));

        const isValid = errors == null || errorProperty == null;
        const [isTouched, setIsTouched] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
            !isValid && manualErrorCheck();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [i18n.language]);

        const manualErrorCheck = () => {
            trigger && trigger(name);
        };

        const localRef = useAutofocusRef<HTMLInputElement>(autoFocus);
        const tooltipWrapperId = `${id}-tooltip-wrapper`;
        const isMobile = process.env.REACT_APP_MOBILE;

        return (
            <>
                <span id={tooltipWrapperId}>
                    <div className="form-group">
                        <div
                            className={classNames('input-group', {
                                'is-invalid': !isValid,
                                'is-valid': isValid && isTouched,
                                'is-focused': isFocused, // hack for IE11 as IE11 doesn't support focus-within
                            })}
                            id={passwordId}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            data-html="true"
                            title=""
                        >
                            <input
                                ref={combineRefs<HTMLInputElement>([ref, localRef])}
                                type={isPasswordRevealed ? 'text' : 'password'}
                                id={id}
                                name={name}
                                className={classNames('form-control', {
                                    'is-invalid': !isValid,
                                    'is-valid': isValid && isTouched,
                                })}
                                placeholder=" "
                                autoComplete={name}
                                aria-label="Heslo"
                                aria-describedby="password-addon"
                                required //  no effect, style only
                                onChange={(e) => {
                                    handlePasswordChanged(e);
                                    manualErrorCheck();
                                    if (isTouched === false) {
                                        setIsTouched(true);
                                    }
                                }}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                            />
                            <label className="control-label">{label}</label>
                            {!disableReveal && (
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn text-primary px-3 focus-highlight-heavy"
                                        onMouseDown={() => revealPassword(true)}
                                        onMouseUp={() => revealPassword(false)}
                                        onMouseLeave={() => revealPassword(false)}
                                        onTouchStart={() => revealPassword(true)}
                                        onTouchEnd={() => revealPassword(false)}
                                        onKeyDown={(e) => {
                                            ['Enter', ' '].includes(e.key) && revealPassword(true);
                                        }}
                                        onKeyUp={() => revealPassword(false)}
                                        onBlur={() => revealPassword(false)}
                                        style={{ width: '54px' }}
                                    >
                                        <FontAwesomeIcon icon={isPasswordRevealed ? faEye : faEyeSlash} size="lg" />
                                    </button>
                                </div>
                            )}
                            {!isValid && errors != null && (
                                <>
                                    <div className="invalid-feedback small d-none d-sm-block">
                                        <ErrorMessage errors={errors} name={name} />
                                    </div>
                                    <div className="invalid-feedback small d-sm-none">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                                    </div>
                                </>
                            )}
                        </div>

                        {!isValid && errors != null && (
                            <div className="font-weight-bold text-danger ml-3 d-sm-none">
                                <ErrorMessage errors={errors} name={name} />
                            </div>
                        )}
                    </div>
                    <Tooltip
                        placement="top"
                        container={tooltipWrapperId}
                        style={{ maxWidth: '95vw' }}
                        isOpen={tooltipOpen && !doNotValidate}
                        target={passwordId}
                        toggle={toggle}
                    >
                        <div className={`tooltip-password ${isMobile ? 'mt-1' : 'mt-4 mb-4'}`}>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        width: `${strengthIndex * 25 > 0 ? strengthIndex * 25 : 5}%`,
                                        backgroundColor: strengthEval[strengthIndex].color,
                                    }}
                                    aria-valuenow={strengthIndex * 25}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                ></div>
                            </div>
                            <p>
                                <small>
                                    {t('registration.password-tooltip.level', 'Úroveň hesla:')}
                                    {t(`registration.password-tooltip.levels.${strengthEval[strengthIndex].strength}`, 'Veľmi slabé')}
                                </small>
                            </p>
                            {isMobile ? (
                                <span className="password-input-conditions d-block">
                                    <p>
                                        <small>
                                            <Trans i18nKey="registration.password-tooltip.must-contain.mobile-title"></Trans>
                                        </small>
                                    </p>
                                </span>
                            ) : (
                                <span className="password-input-conditions d-block pt-3 mb-4">
                                    <p>
                                        <small>
                                            <Trans i18nKey="registration.password-tooltip.must-contain.title">Heslo musí obsahovať:</Trans>
                                        </small>
                                    </p>
                                    <ul>
                                        {[1, 5, 8].map((ruleId) => {
                                            if (isRegistration && ruleId === 2) {
                                                //exclude condition with previous email addresses in registration
                                                return null;
                                            } else {
                                                return (
                                                    <li key={ruleId}>
                                                        <small>
                                                            <Trans i18nKey={`registration.password-tooltip.must-contain.rule${ruleId}`}></Trans>
                                                        </small>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>

                                    <small className="text-secondary">
                                        <Trans i18nKey={`registration.password-tooltip.must-contain.rule3`}></Trans>
                                    </small>
                                </span>
                            )}
                        </div>
                    </Tooltip>
                </span>
            </>
        );
    },
);

export default PasswordInput;
