import { Reducer } from 'react';
import { CustomerRequestBlockTitleEnum } from '../views/customer-request/config/enums';
import BlockInlineRegistration from '../views/customer-request/screen/steps-customer-request/block-inline-registration/block-inline-registration';
// import { BlockTypeInlineRegistration } from '../views/customer-request/config/request-config';
import { CustomerRequestAction, CustomerRequestActionType, ICustomerRequestState } from './interfaces/customer-request-state';

const initialState: ICustomerRequestState = {
    step: {
        current: 0,
        lastOpened: 0,
        max: 0,
    },
    content: {},
    metadata: {},
    request: undefined,
    send: false,
};

const CustomerRequestReducer: Reducer<ICustomerRequestState, CustomerRequestAction> = (state = initialState, action): ICustomerRequestState => {
    switch (action.type) {
        case CustomerRequestActionType.SET_REQUEST_KEYS:
            return {
                ...state,
                key: action.key,
                code: action.code,
            };

        case CustomerRequestActionType.SET_REQUEST:
            const request = action.request;
            const stepsCount = action.request?.blocks?.length ?? 1;

            return {
                ...state,
                request,
                step: {
                    ...state.step,
                    max: stepsCount,
                },
                currentBlock: request?.blocks && request.blocks.length ? request?.blocks[0] : undefined,
            };
        case CustomerRequestActionType.CLOSE_REQUEST:
            return {
                ...initialState,
                additionalData: state.additionalData,
            };
        case CustomerRequestActionType.SET_STEP: {
            return {
                ...state,
                step: action.stepBlock ? action.stepBlock : state.step,
            };
        }
        case CustomerRequestActionType.NEXT_STEP: {
            if (state.request == null) {
                return {
                    ...state,
                };
            }

            const nextStep = state.request?.blocks[state.step.current + 1] ? state.step.current + 1 : state.step.current;
            const lastOpenedStep = state.step.lastOpened < nextStep ? nextStep : state.step.lastOpened;
            return {
                ...state,
                step: {
                    ...state.step,
                    current: nextStep,
                    lastOpened: lastOpenedStep,
                },
                currentBlock: state.request?.blocks[nextStep],
            };
        }
        case CustomerRequestActionType.PREVIOUS_STEP: {
            const previousStep = state.step.current > 0 ? state.step.current - 1 : state.step.current;

            return {
                ...state,
                step: {
                    ...state.step,
                    current: previousStep,
                },
                currentBlock: state.request?.blocks[previousStep],
            };
        }
        case CustomerRequestActionType.OPEN_STEP:
            return {
                ...state,
                step: {
                    ...state.step,
                    current: action.step ?? state.step.current,
                },
                currentBlock: state.request?.blocks[action.step ?? state.step.current],
            };
        case CustomerRequestActionType.SET_DATA:
            return {
                ...state,
                content: {
                    ...state.content,
                    ...action.payload,
                },
            };
        case CustomerRequestActionType.SET_ATTACHMENTS:
            return {
                ...state,
                attachments: action.attachments,
            };
        case CustomerRequestActionType.SET_METADATA:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    ...action.metadata,
                },
            };
        case CustomerRequestActionType.REPLACE_BLOCKS: {
            if (state.request == null) {
                return {
                    ...state,
                };
            }

            return {
                ...state,
                request: { ...state.request, blocks: action.blocks ?? [] },
                step: {
                    ...state.step,
                    max: action.blocks?.length ?? state.step.max,
                },
            };
        }
        case CustomerRequestActionType.ADD_REGISTRATION_BLOCK: {
            if (state.request == null) {
                return state;
            }
            const newBlocks = state.request.blocks || [];
            const isInlineRegistrationPresented = newBlocks.find((block) => block.key === CustomerRequestBlockTitleEnum.BLOCK_INLINE_REGISTRATION)
                ? true
                : false;
            if (isInlineRegistrationPresented) {
                return state;
            }
            newBlocks.splice(newBlocks.length - 1, 0, {
                key: CustomerRequestBlockTitleEnum.BLOCK_INLINE_REGISTRATION,
                component: BlockInlineRegistration,
            });
            return {
                ...state,
                request: { ...state.request, blocks: newBlocks },
            };
        }
        case CustomerRequestActionType.REMOVE_REGISTRATION_BLOCK: {
            if (state.request == null) {
                return {
                    ...state,
                };
            }
            const newBlocks = state.request.blocks.filter((block) => block.key !== CustomerRequestBlockTitleEnum.BLOCK_INLINE_REGISTRATION);
            return {
                ...state,
                request: { ...state.request, blocks: newBlocks },
                content: { ...state.content, registration: undefined },
            };
        }
        case CustomerRequestActionType.SET_PARTNERS_DELIVERY_POINTS: {
            return {
                ...state,
                partnersDeliveryPoints: action.partnersDeliveryPoints,
            };
        }
        case CustomerRequestActionType.SET_BUSINESS_PARTNER: {
            return {
                ...state,
                businessPartner: action.businessPartner,
            };
        }
        case CustomerRequestActionType.SET_DELIVERY_POINT: {
            return {
                ...state,
                deliveryPoint: action.deliveryPoint,
            };
        }
        case CustomerRequestActionType.SET_DELIVERY_POINT_ZP: {
            return {
                ...state,
                deliveryPointZp: action.deliveryPointZp,
            };
        }
        case CustomerRequestActionType.SET_DELIVERY_POINT_EE: {
            return {
                ...state,
                deliveryPointEe: action.deliveryPointEe,
            };
        }
        case CustomerRequestActionType.SET_CUSTOMER_REQUEST_TEMPLATE: {
            return {
                ...state,
                customerRequestTemplate: action.customerRequestTemplate,
            };
        }
        case CustomerRequestActionType.SET_CUSTOMER_REQUEST_TEMPLATES: {
            return {
                ...state,
                customerRequestTemplates: action.customerRequestTemplates,
            };
        }
        case CustomerRequestActionType.SET_ADDITIONAL_DATA: {
            return {
                ...state,
                additionalData: action.additionalData,
            };
        }
        case CustomerRequestActionType.CLEAR_ADDITIONAL_DATA: {
            return {
                ...state,
                additionalData: {},
            };
        }
        case CustomerRequestActionType.SET_SEND: {
            return {
                ...state,
                send: action.send,
            };
        }
        case CustomerRequestActionType.SET_INVOICES: {
            return {
                ...state,
                invoices: action.invoices,
            };
        }
        case CustomerRequestActionType.SET_REQUEST_IS_PAID: {
            return {
                ...state,
                paid: action.paid,
            };
        }
        case CustomerRequestActionType.SET_DELIVERY_POINTS_EE: {
            return {
                ...state,
                content: {
                    ...state.content,
                    deliveryPointsEE: action.deliveryPointsEe,
                },
            };
        }
        case CustomerRequestActionType.SET_DELIVERY_POINTS_ZP: {
            return {
                ...state,
                content: {
                    ...state.content,
                    deliveryPointsZP: action.deliveryPointsZp,
                },
            };
        }
        case CustomerRequestActionType.SET_UUID: {
            return {
                ...state,
                uuid: action.uuid,
            };
        }
        default:
            return state;
    }
};

export default CustomerRequestReducer;
