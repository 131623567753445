import { AddToast, useToasts } from 'react-toast-notifications';

export const useUniqueToasts = () => {
    const { addToast, toastStack } = useToasts();
    const addUniqueToast: AddToast = (content, options, callback) => {
        if (!toastStack.some((toast) => toast.content === content)) {
            addToast(content, options, callback);
        }
    };
    return { addUniqueToast };
};
