import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: number;
    bold?: boolean;
};

const NewLastNameItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.new-data.new-last-name')}>
                    <ItemPreviewContent bold={bold}>{data}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default NewLastNameItem;
