import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { ClickableElement } from '../../../../components/common';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { useKeyValueStore } from '../../../../hooks/use-key-value';
import Routes from '../../../../routes/routes';
import { getAddressFromUnitedDeliveryPoint } from '../../../../utils/utils';
import { DeliveryPointCommodityBadges } from '../../component/delivery-point-commodity-badges';
import { TABSEnums } from '../delivery-point-detail';
import { MyDeductionsDropdownMenu } from '../navigation/my-deductions-dropdown-menu';
import DeliveryPointDetailActionButtons from './delivery-point-detail-action-buttons';

interface IDeliveryPointDetailHeader {
    tab: TABSEnums | undefined;
    onTabChange: (value: TABSEnums, options?: { replaceUrl?: boolean; hash?: string }) => void;
    unitedDeliveryPoint?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    showInactiveDeliveryPoints: boolean;
    toggleInactiveDeliveryPoints: (() => void) | undefined;
    setCanActivateEInvoice: (val: boolean) => void;
    setActivateEInvoiceButtonClicked: (val: boolean) => void;
    setIsActivateEInvoiceModalOpen: (val: boolean) => void;
}

const DeliveryPointDetailHeader: React.FC<IDeliveryPointDetailHeader> = ({
    tab,
    onTabChange,
    unitedDeliveryPoint,
    showInactiveDeliveryPoints,
    toggleInactiveDeliveryPoints,
    setCanActivateEInvoice,
    setActivateEInvoiceButtonClicked,
    setIsActivateEInvoiceModalOpen,
}) => {
    const urlParams = useParams<{ unitedDeliveryPointId: string; deliveryPointId: string; tab: TABSEnums }>();
    const [t] = useTranslation();
    const [storedPageIndex] = useKeyValueStore(Routes.DELIVERY_POINTS + '@pageIndex', 0);

    const isUdpSharedToMe = unitedDeliveryPoint?.sharing?.by != null;
    const isUdpInactive = unitedDeliveryPoint?.deliveryPoints?.every((dp) => dp.status !== 'ACTIVE');

    return (
        <>
            {isUdpSharedToMe && (
                <div className="p-3 bg-light text-center">
                    <b>
                        <Trans
                            i18nKey="delivery-point.detail.header.delivery-point-shared-by"
                            values={{
                                name: unitedDeliveryPoint?.sharing?.by?.firstName || '',
                                surname: unitedDeliveryPoint?.sharing?.by?.lastName || '',
                            }}
                        />
                    </b>
                </div>
            )}
            {isUdpInactive && (
                <div className="p-3 bg-dark text-light text-center">
                    <b>
                        <Trans i18nKey="delivery-point.detail.header.delivery-point-has-no-active-products" />
                    </b>
                </div>
            )}

            {!!tab && (
                <nav aria-label="breadcrumb" className="breadcrumb-more-items border-primary d-md-none">
                    <div className="container">
                        <ol className="breadcrumb d-inline-flex p-0 mb-0">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={`${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}`}>
                                    {unitedDeliveryPoint && getAddressFromUnitedDeliveryPoint(unitedDeliveryPoint)}
                                </Link>
                            </li>
                            {tab === 'notes2' && (
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link
                                        to={`${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}/consumption/${urlParams.deliveryPointId ||
                                            ''}`}
                                    >
                                        {t(`delivery-point.detail.navigation.consumption`)}
                                    </Link>
                                </li>
                            )}
                            <li className="breadcrumb-item active" aria-current="page">
                                <Link to={`./${urlParams.deliveryPointId || ''}`}>
                                    {t(`delivery-point.detail.navigation.${tab !== 'notes2' ? tab : 'notes'}`)}
                                </Link>
                            </li>
                        </ol>
                    </div>
                </nav>
            )}

            <div className={classNames({ 'page-header bg-white pb-5 pb-md-3': true, 'd-none d-md-block': !!tab })}>
                <div className="container position-relative">
                    <Breadcrumb
                        route={`${Routes.DELIVERY_POINTS}?page=${storedPageIndex + 1}`}
                        title={<Trans i18nKey="delivery-point.list-delivery-points.title">Odberné miesta</Trans>}
                    />

                    <div className="row">
                        {showInactiveDeliveryPoints && toggleInactiveDeliveryPoints != null ? (
                            <div className="col-12 mb-4 mt-2">
                                <div className="btn-group justify-content-center text-center w-100 p-2 ">
                                    <div>
                                        <Trans i18nKey="delivery-point.detail.header.inactive-products-are-shown" />
                                    </div>
                                    <ClickableElement
                                        isText
                                        className="text-decoration-underline-inverse d-block mx-2"
                                        onClick={toggleInactiveDeliveryPoints}
                                    >
                                        <Trans i18nKey="delivery-point.detail.header.hide-inactive-products" />
                                    </ClickableElement>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12 mt-lg-4" />
                        )}

                        <div className="col-12 col-lg-7 order-1 order-lg-0 mt-4 mt-md-0">
                            <h1 className="text-center text-lg-left mb-0 mt-2 mt-lg-0">
                                {unitedDeliveryPoint && getAddressFromUnitedDeliveryPoint(unitedDeliveryPoint)}
                            </h1>
                            <div className="text-center text-lg-left mb-1">{unitedDeliveryPoint?.address?.city}</div>
                            <div className="text-center text-lg-left">
                                <div className="text-nowrap mb-2">
                                    {unitedDeliveryPoint?.deliveryPoints && (
                                        <DeliveryPointCommodityBadges deliveryPoints={unitedDeliveryPoint.deliveryPoints} />
                                    )}
                                    {unitedDeliveryPoint?.sharing?.to != null && unitedDeliveryPoint.sharing.to.length > 0 && (
                                        <div className="d-none d-md-inline-block">
                                            <i className="icon-users-16 ml-2 mr-1" />
                                            <Trans i18nKey="delivery-point.detail.shared-by-me">Zdieľam</Trans>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-5 text-right order-0 order-lg-1">
                            {unitedDeliveryPoint && (
                                <DeliveryPointDetailActionButtons
                                    unitedDeliveryPoint={unitedDeliveryPoint}
                                    showInactiveDeliveryPoints={showInactiveDeliveryPoints}
                                    toggleInactiveDeliveryPoints={toggleInactiveDeliveryPoints}
                                    setCanActivateEInvoice={setCanActivateEInvoice}
                                    setIsActivateEInvoiceModalOpen={setIsActivateEInvoiceModalOpen}
                                    setActivateEInvoiceButtonClicked={setActivateEInvoiceButtonClicked}
                                />
                            )}
                        </div>
                        <div className="col-12 order-12">
                            <div className="d-none d-md-flex justify-content-end my-3 mx-3 position-relative">
                                {unitedDeliveryPoint && (
                                    <UncontrolledButtonDropdown group={false} direction="down">
                                        <DropdownToggle tag="div">
                                            <button
                                                type="button"
                                                id="dropdownMenuButton3001b"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                className="btn btn-white btn-round light-shadow"
                                            >
                                                <i className="icon-book-open fa-lg"></i>
                                            </button>
                                        </DropdownToggle>
                                        <MyDeductionsDropdownMenu
                                            canEdit={!isUdpInactive && !isUdpSharedToMe}
                                            onTabChange={onTabChange}
                                            unitedDeliveryPoint={unitedDeliveryPoint}
                                        />
                                    </UncontrolledButtonDropdown>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeliveryPointDetailHeader;
