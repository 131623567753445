import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

const InvoiceDeliveryFormFieldsOnlyEmail: React.FC<DefaultFormType> = ({ register, errors, trigger }) => {
    const { formRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <div className="mt-1">
            <Input
                trigger={trigger}
                ref={register({ ...formRules.email, ...formRules.required })}
                errors={errors}
                type="text"
                name="email"
                label={<Trans i18nKey="customer-request.steps.contact.email">E-mail</Trans>}
                autoCapitalize="off"
                autoCorrect="off"
                touched={touched}
            />
        </div>
    );
};

export default InvoiceDeliveryFormFieldsOnlyEmail;
