import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { Row } from 'reactstrap';

interface ICustomerRequestsHeaderProps {
    createNewRequest: () => void;
    getDeliveryPointSwitch: (additionalButton?: { label: React.ReactNode }) => React.ReactNode;
}

export const CustomerRequestsHeader: React.FC<ICustomerRequestsHeaderProps> = ({ createNewRequest, getDeliveryPointSwitch }) => {
    const headerContent = (
        <>
            <div className="container">
                <Row className="align-items-center justify-content-between mb-md-4">
                    <div className="col-12 d-md-none mt-4 mb-3">
                        <div className="d-flex flex-wrap w-100 justify-content-between">
                            <h2 className="my-2 mr-2" style={{ flex: '1 1 auto' }}>
                                <Trans i18nKey="customer-request.title" />
                            </h2>
                            <button type="button" className="btn btn-primary text-nowrap" onClick={createNewRequest} style={{ flex: '1 1 0' }}>
                                <small className="mr-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                </small>
                                <Trans i18nKey="customer-request.new-request">Nová žiadosť</Trans>
                            </button>
                        </div>
                    </div>

                    <div className="col-12 order-md-1 d-none d-md-block" style={{ minHeight: 50 }}>
                        {getDeliveryPointSwitch({ label: <Trans i18nKey="delivery-point.delivery-point-switch.select-all-requests" /> })}
                        <button
                            type="button"
                            className="btn btn-primary text-nowrap"
                            onClick={createNewRequest}
                            style={{ position: 'absolute', top: 0, right: 20 }}
                        >
                            <small className="mr-2">
                                <FontAwesomeIcon icon={faPlus} />
                            </small>
                            <Trans i18nKey="customer-request.new-request">Nová žiadosť</Trans>
                        </button>
                    </div>
                </Row>
            </div>
        </>
    );

    const headerContent2 = (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {getDeliveryPointSwitch({
                            label: <Trans i18nKey="delivery-point.delivery-point-switch.select-all-requests" />,
                        })}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="bg-white mb-0 d-md-none">{headerContent}</div>
            <div className="mb-3 d-none d-md-block">{headerContent}</div>

            <div className="bg-two-colors d-md-none mb-4">{headerContent2}</div>
        </>
    );
};
