import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary as BusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSearch,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import { Paginator } from '../../../components/common';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import { useKeyValueStore } from '../../../hooks/use-key-value';
import { usePaginatedQueryWithError } from '../../../hooks/use-paginated-query-with-error';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { IRootState } from '../../../reducers';
import Routes, { SettingsRoutes } from '../../../routes/routes';
import { QueryKeysEnum } from '../../../utils/react-query-utils';
import BusinessPartnerNotificationCard from './business-partner-notification-card';
import { NotificationSettingsInfo } from './notification-settings-info';
import { NotificationSettingsNotAvailable } from './notification-settings-list/notification-setting-not-available';
import NotificationsSettingsSubHeader from './notifications-subheader';

const NotificationsSettings: React.FC = () => {
    const pageSize = 10;
    const [expandedBusinessPartner, setExpandedBusinessPartner] = useState<string | undefined>(undefined);
    const [pageIndex, setPageIndex] = useState(0);
    const [, setStoredPageIndex] = useKeyValueStore(SettingsRoutes.NOTIFICATIONS + '@pageIndex', 0);

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const api = useApi();
    const history = useHistory();

    const businessPartnersFilter: SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSearch = {
        businessPartnerSearch: {
            paging: {
                page: pageIndex,
                size: pageSize,
                sort: [{ attribute: 'nameSearchable', direction: 'ASC' }],
            },
        },
        unitedDeliveryPointSearch: { includeInactive: false, shared: false, deliveryPoint: { hidden: false } },
        includeDeliveryPoints: true,
    };

    const {
        isFetching: isFetchingBusinessPartnersData,
        resolvedData: businessPartnersData,
        isLoading: isLoadingBusinessPartnersData,
    } = usePaginatedQueryWithError<BusinessPartnerSummary | null>({
        queryKey: [QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH, loggedInCustomer?.id, businessPartnersFilter],
        queryFn: async () => {
            return loggedInCustomer?.id == null
                ? null
                : api.customers.searchBusinessPartners(loggedInCustomer.id, businessPartnersFilter, { secure: true }).then((res) => res.data);
        },
        config: {
            onSuccess: (data) => {
                !expandedBusinessPartner && setExpandedBusinessPartner(data?.result?.[0]?.id);
            },
        },
    });

    const urlQuery = useUrlQuery();
    const queriedPage = Number(urlQuery.get('page')) || undefined;

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    return (
        <>
            {(isFetchingBusinessPartnersData || isLoadingBusinessPartnersData) && <LoadingIndicator fullscreen />}

            <Container>
                <Row className="justify-content-lg-center pb-5 pt-2 pt-lg-5">
                    <Col xs={12} xl={8} className="position-static">
                        <nav aria-label="breadcrumb" className="d-lg-none">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link to={Routes.CUSTOMER_SETTINGS} className="text-decoration-none">
                                        <i className="icon-arrow-left-16"></i> <Trans i18nKey="settings.title" />
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                        <NotificationsSettingsSubHeader canBulkSet={(businessPartnersData?.result?.length || 0) > 0} />
                        <div className="mb-4 pb-3">
                            <NotificationSettingsInfo />
                        </div>
                        {businessPartnersData?.paging?.total === 0 ? (
                            <NotificationSettingsNotAvailable />
                        ) : (
                            <>
                                {businessPartnersData?.result?.map((businessPartner) => (
                                    <BusinessPartnerNotificationCard
                                        key={`notifications_${businessPartner.id}`}
                                        businessPartner={businessPartner}
                                        onToggle={(bpId) => setExpandedBusinessPartner(bpId)}
                                        isExpanded={expandedBusinessPartner === businessPartner.id}
                                    />
                                ))}
                                <Paginator
                                    className="mt-3"
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    dataLength={businessPartnersData?.paging?.total}
                                    onPageChanged={onPageChange}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NotificationsSettings;
