import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import HelpLinkWithModal from '../../../../../../components/help/help-link-with-modal';

const InterruptionConsumptionExtraInfo: React.FC = () => {
    return (
        <>
            <hr className="my-4" />
            <div className="text-left p-4">
                <h3>
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.title">Čo by ste mali vedieť pri prerušení dodávky?</Trans>
                </h3>
                <p className="my-4">
                    <FontAwesomeIcon icon={faTimesCircle} size="2x" color="black" className="my-2" />
                    <br />
                    <b style={{ color: 'black' }}>
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.advance-payments">Preddavkové platby</Trans>
                    </b>
                    <br />
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.advance-payments-info">
                        Počas obdobia prerušenej dodávky vám nebudú vystavované preddavkové platby.
                    </Trans>
                </p>
                <p className="my-4">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" color="black" className="my-2" />
                    <br />
                    <b style={{ color: 'black' }}>
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.settlement-invoice">Vyúčtovacia faktúra</Trans>
                    </b>
                    <br />
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.settlement-invoice-info">
                        V prípade, ak Vám bude k termínu demontáže meradla vystavená vyúčtovacia faktúra, uhraďte ju prosím.
                    </Trans>
                </p>
                <p className="my-4">
                    <FontAwesomeIcon icon={faSync} size="2x" color="black" className="my-2" />
                    <br />
                    <b style={{ color: 'black' }}>
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-delivery">Obnovenie dodávky</Trans>
                    </b>
                    <br />
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-delivery-info-1">
                        Obnovenie dodávky plynu po skončení rekonštrukcie je potrebné riešiť osobne v
                    </Trans>{' '}
                    {/* <Link to="/contact">
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.customer-center">Zákazníckom centre SPP</Trans>
                    </Link> */}
                    <a href="https://www.spp.sk/sk/domacnosti/kontakty/#map" target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.customer-center">Zákazníckom centre SPP</Trans>
                    </a>{' '}
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-delivery-info-2">alebo u distribútora*.</Trans>
                </p>
                <HelpLinkWithModal
                    className="d-inline"
                    classNameClickableElement="text-decoration-underline-inverse"
                    title="customer-request.steps.interaption-extra-info.how-resumption-of-delivery"
                    field={'NZT_ZOM_ZOPAOO'}
                />
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                {/* <ClickableElement isText className="text-decoration-underline-inverse d-inline-block mb-4" onClick={() => null}>
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.how-resumption-of-delivery">
                        Ako postupovať pri obnovení dodávky?
                    </Trans>
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                </ClickableElement> */}
                <p>
                    <small className="text-secondary">
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.company-operates-distribution-network">
                            * spoločnosť, ktorá prevádzkuje distribučnú sieť
                        </Trans>
                    </small>
                </p>
            </div>
        </>
    );
};

export default InterruptionConsumptionExtraInfo;
