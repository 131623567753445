import { ErrorMessage } from '@hookform/error-message';
import { SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import React from 'react';
import TariffRadioButton from '../../../../../components/tariff-radio-button';
import { DefaultFormType } from '../../../../../models/model';
import { useFormRules } from './../../../../../hooks/use-form-rules';

type Props = DefaultFormType & {
    data: TariffRate[];
    currentTariff?: string;
    name?: string;
    transcript?: boolean;
};

const TariffFields: React.FC<Props> = ({ register, data, errors, currentTariff, name, transcript }) => {
    const { formRules } = useFormRules();
    return (
        <>
            {data.map((tarif) => (
                <TariffRadioButton
                    key={name ? name + tarif.uuid : tarif.uuid}
                    id={tarif.name ?? ''}
                    uuid={name ? name + tarif.uuid : undefined}
                    ref={register({ ...formRules.required })}
                    value={tarif.uuid}
                    name={name ? name + 'tariff.uuid' : 'tariff.uuid'}
                    description={tarif.description ?? ''}
                    isPreviouslySelected={currentTariff === tarif.uuid}
                    transcript={transcript}
                />
            ))}
            {errors && (
                <div style={{ color: 'red' }} className="mt-2">
                    <ErrorMessage errors={errors} name={name ? name + 'tariff.uuid' : 'tariff.uuid'} />
                </div>
            )}
        </>
    );
};

export default TariffFields;
