import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IContractDelivery } from '../../../../../../models/customer-request-model';
import { ContractDeliveryEnum } from '../../../../../../models/enums';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IContractDelivery;
    bold?: boolean;
};

const ContractDeliveryItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { address } = data;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
        },
    });

    const country = useMemo(() => {
        return countryCodeList?.find((item) => item.uuid === address?.country?.uuid)?.name;
    }, [countryCodeList, address.country]);

    const SK = countryCodeList?.find((item) => item.code === 'SK')?.name;
    const countryFinal = country || SK;

    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.contract-delivery.title')}>
            {address && !isObjectEmpty(address) && address.street && (
                <>
                    <p>
                        {data.type === ContractDeliveryEnum.POST
                            ? t('customer-request.steps.contract-delivery.post')
                            : t('customer-request.steps.contract-delivery.delivery-man')}
                    </p>
                    <ItemPreviewContent bold={bold}>{address.name}</ItemPreviewContent>
                    <ItemPreviewContent bold={bold}>{`${address.street} ${address.number}`}</ItemPreviewContent>
                    <p>
                        <ItemPreviewContent bold={bold}>
                            {`${address.postalCode}, ${address.city}`}
                            {countryFinal && (
                                <>
                                    <br />
                                    {countryFinal}
                                </>
                            )}
                        </ItemPreviewContent>
                    </p>
                    {address?.floor && (
                        <>
                            {t('customer-request.steps.address.floor')}
                            <p>{address?.floor}.</p>
                        </>
                    )}
                    {address?.flatNumber && (
                        <>
                            {t('customer-request.steps.address.appartments-number')}
                            <p>{address?.flatNumber}</p>
                        </>
                    )}
                </>
            )}
        </ItemPreview>
    );
};

export default ContractDeliveryItem;
