import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ListGroup, ListGroupItem, Modal, ModalBody } from 'reactstrap';
import { IRootState } from '../../../../../reducers';

type Props = {
    show: boolean;
    hide: () => void;
    onStepChange: (step: number) => void;
};

const StepsProgressModal: React.FC<Props> = ({ show, hide, onStepChange }) => {
    const { request, step } = useSelector((state: IRootState) => state.customerRequest);

    const [t] = useTranslation();

    return (
        <Modal isOpen={show} centered>
            <ModalBody className="d-flex flex-column px-4 pb-5 mb-2">
                <button type="button" onClick={hide} className="close ml-auto" data-dismiss="modal" aria-label="Close" style={{ width: 25 }}>
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">{t('customer-request.steps.modal-title')}</h3>
                <ListGroup>
                    {request?.blocks.map((item, index) => (
                        <ListGroupItem
                            key={item.key}
                            onClick={() => onStepChange(index)}
                            className="text-left"
                            tag="button"
                            disabled={index > step.current}
                            style={{ opacity: index > step.current ? 0.6 : 1, color: index > step.current ? '#777777' : 'black !important' }}
                        >
                            {`${t('customer-request.steps.step', { current: index + 1, max: step.max })} — ${t(
                                `customer-request.steps.${item.key}.title`,
                            )}`}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </ModalBody>
        </Modal>
    );
};

export default StepsProgressModal;
