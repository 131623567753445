import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import Checkbox from '../../../../../components/common/checkbox';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { BaseTextarea, CommodityEnum, LoadingIndicator, PRICE_LINK_EE, PRICE_LINK_ZP, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatDate } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { CustomerRequestTypeEnum } from '../../../config/enums';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

const NOTE_MAX_COUNT = 510;

interface IBlockCompletionRequestOfficialTestMeterShort {
    condition: boolean;
    note?: string;
}

export const BlockCompletionInvoiceOutOfCycleShort: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const { deliveryPointEe, deliveryPointZp, additionalData, content, customerRequestTemplate, paid } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [showNote, setShowNote] = useState<boolean>(false);
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { formRules } = useFormRules();
    const dispatch = useDispatch();

    const dateForInvoice =
        content.meterReading?.date && moment(content.meterReading?.date).isValid() ? formatDate(new Date(content.meterReading.date)) : '';

    const { register, handleSubmit, errors, watch } = useForm<IBlockCompletionRequestOfficialTestMeterShort>({
        defaultValues: { note: content.note },
    });

    const message: string | undefined = watch('note');

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    useEffect(() => {
        dispatch(CustomerRequestActions.setData({ note: message }));
        setMessageCounter(message?.length ?? 0);
    }, [message, dispatch]);

    const onFormSubmit = (formData: IBlockCompletionRequestOfficialTestMeterShort) => {
        const data: any = {
            content: {
                metadata: {
                    businessPartnerId: additionalData?.bpuuid,
                    deliveryPointId: deliveryPointEe?.id ?? deliveryPointZp?.id,
                },
                meterReading: {
                    date: content.meterReading?.date,
                    value: content.meterReading?.value,
                    valueHigh: content.meterReading?.valueHigh,
                    valueLow: content.meterReading?.valueLow,
                    type: deliveryPointZp ? 'GAS' : deliveryPointEe?.contract?.eeTariffCount === 2 ? 'EE_2T' : 'EE_1T',
                },
                deliveryPoint: { id: deliveryPointEe?.id ?? deliveryPointZp?.id },
                entityReferences: [
                    {
                        contractId: deliveryPointEe?.contract?.id ?? deliveryPointZp?.contract?.id,
                        deliveryPointId: deliveryPointEe?.id ?? deliveryPointZp?.id,
                        contractAccountId: deliveryPointEe?.contract?.contractAccount?.id ?? deliveryPointZp?.contract?.contractAccount?.id,
                    },
                ],
                note: formData.note,
                type: CustomerRequestTypeEnum.ZOM_ZOFMC,
                paymentApproval: formData.condition,
            },
            customerRequestTemplate,
        };
        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const checkBoxLabel = paid?.paid && (
        <>
            {t('customer-request.steps.accept-current-price-list')}
            <br />
            <a href={paid.comodity === CommodityEnum.GAS ? PRICE_LINK_ZP : PRICE_LINK_EE} target="_blank" rel="noopener noreferrer">
                {t('customer-request.steps.current-price-list')}&nbsp;
            </a>
        </>
    );

    const deliveryPoint = deliveryPointEe ?? deliveryPointZp;
    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.delivery-point.title')}>
                    {deliveryPoint?.address != null && (
                        <>
                            <ItemPreviewContent bold>
                                {formatAddress(deliveryPoint.address)} - {t(`enums.CommodityEnum.${deliveryPoint.type}`)} (
                                {t('delivery-point.contract-number', { number: deliveryPoint?.contract?.externalId || '-' })})
                            </ItemPreviewContent>
                        </>
                    )}
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.invoice-out-of-cycle-data.date-for-issue-invoice')}>
                    <ItemPreviewContent bold>{dateForInvoice}</ItemPreviewContent>
                </ItemPreview>

                <ItemPreview
                    title={t('customer-request.steps.invoice-out-of-cycle-data.meter-value-to-date', {
                        date: dateForInvoice,
                    })}
                >
                    <ItemPreviewContent bold>
                        {content.meterReading?.valueLow ? (
                            <>
                                {t('delivery-point.detail.consumption.meter-value-electricity-high')}: {content.meterReading?.valueHigh} kWh
                                <br />
                                {t('delivery-point.detail.consumption.meter-value-electricity-low')}: {content.meterReading?.valueLow} kWh
                            </>
                        ) : (
                            <>
                                {content.meterReading?.value}{' '}
                                {deliveryPointZp ? (
                                    <>
                                        {' '}
                                        m<sup>3</sup>
                                    </>
                                ) : (
                                    'kWh'
                                )}
                            </>
                        )}
                    </ItemPreviewContent>
                </ItemPreview>
            </ListGroup>
            <div className="my-2">
                <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                    <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                </ClickableElement>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                {(showNote || messageCounter > 0) && (
                    <BaseTextarea
                        ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                        errors={errors}
                        name={'note'}
                        maxLength={NOTE_MAX_COUNT}
                        label={t('customer-request.steps.attachments.title')}
                        counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                        optional
                    />
                )}
                {checkBoxLabel && (
                    <div className="p-3 my-4 rounded" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            id="condition"
                            name="condition"
                            label={checkBoxLabel}
                            className="mb-1"
                        />
                    </div>
                )}

                <Button type="submit" size="lg" color="primary" block className="mt-5">
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>
            </form>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
