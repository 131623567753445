import React, { Children, ReactNode } from 'react';

export const joinNodes = (nodes: ReactNode[], separator: string): ReactNode => {
    return Children.toArray(
        nodes.map((x, index) => (
            <>
                {index !== 0 && separator}
                {x}
            </>
        )),
    );
};
