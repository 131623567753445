import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonGroup } from 'reactstrap';
import { BaseSelect, DefaultFormType } from '../../../../../../main';
import { ITariffWithDpId } from '../../../../../../models/customer-request-model';
import { ISelectedPaymentPeriod } from '../block-advance-payment';
import ChangeAdvancePaymentForm from './change-advance-payment-form';

interface IChangeAdvancePaymentModalProps extends DefaultFormType {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    advancePaymentPeriodCodelist?: SkSppNzpCommonsApiCodelistCodeListItem[] | null;
    setSelectedPaymentPeriods: (val: ISelectedPaymentPeriod[]) => void;
    selectedPaymentPeriods: ISelectedPaymentPeriod[];
    transcript?: boolean;
    tariff?: ITariffWithDpId;
}

export const AdvancePayment: React.FC<IChangeAdvancePaymentModalProps> = ({
    deliveryPoint,
    register,
    trigger,
    watch,
    getValues,
    errors,
    advancePaymentPeriodCodelist,
    setSelectedPaymentPeriods,
    selectedPaymentPeriods,
    transcript,
    tariff,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <h4>
                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.how-often-will-you-pay">Ako často budete platiť?</Trans>
            </h4>
            <ButtonGroup className="d-none d-md-inline-flex custom-buttons-group mb-4 w-100">
                {advancePaymentPeriodCodelist
                    ?.filter(
                        (advancePaymentPeriodCodelistItem) =>
                            advancePaymentPeriodCodelistItem.code && ['12', '01', '03', '06'].includes(advancePaymentPeriodCodelistItem.code),
                    )
                    .sort((x, y) => {
                        if (y.code === '01') {
                            return 1;
                        }
                        return 0;
                    })
                    .map((advancePaymentPeriodCodelistItem) => (
                        <button
                            key={advancePaymentPeriodCodelistItem.uuid}
                            onClick={() => {
                                const otherPeriods = selectedPaymentPeriods.filter((item) => item.deliveryPoiontId !== deliveryPoint.id);
                                otherPeriods.push({ deliveryPoiontId: deliveryPoint.id, paymentPeriod: advancePaymentPeriodCodelistItem });
                                setSelectedPaymentPeriods(otherPeriods);
                            }}
                            className={classNames('btn', {
                                active:
                                    selectedPaymentPeriods.find((item) => item.deliveryPoiontId === deliveryPoint.id)?.paymentPeriod?.uuid ===
                                    advancePaymentPeriodCodelistItem.uuid,
                            })}
                            style={{ padding: '.375rem .7rem' }}
                            type="button"
                        >
                            {advancePaymentPeriodCodelistItem.name}
                        </button>
                    ))}
            </ButtonGroup>
            <div className="mb-4 d-block d-md-none">
                <BaseSelect
                    label={t('delivery-point.detail.payment-data.advance-payments.interval')}
                    className="form-control-filter"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        const otherPeriods = selectedPaymentPeriods.filter((item) => item.deliveryPoiontId !== deliveryPoint.id);
                        otherPeriods.push({ deliveryPoiontId: deliveryPoint.id, paymentPeriod: { uuid: event.target.value } });
                        setSelectedPaymentPeriods(otherPeriods);
                    }}
                >
                    {advancePaymentPeriodCodelist
                        ?.filter(
                            (advancePaymentPeriodCodelistItem) =>
                                advancePaymentPeriodCodelistItem.code && ['12', '01', '03', '06'].includes(advancePaymentPeriodCodelistItem.code),
                        )
                        .map((advancePaymentPeriodCodelistItem) => (
                            <option
                                key={advancePaymentPeriodCodelistItem.uuid}
                                value={advancePaymentPeriodCodelistItem.uuid}
                                selected={
                                    selectedPaymentPeriods.find((item) => item.deliveryPoiontId === deliveryPoint.id)?.paymentPeriod?.uuid ===
                                    advancePaymentPeriodCodelistItem.uuid
                                }
                            >
                                {advancePaymentPeriodCodelistItem.name}
                            </option>
                        ))}
                </BaseSelect>
            </div>

            <ChangeAdvancePaymentForm
                currentValue={{
                    paymentPeriod: deliveryPoint.contract?.advancePaymentPeriod,
                    paymentAmount: deliveryPoint.advancePayment,
                }}
                editedValues={{
                    paymentPeriod: selectedPaymentPeriods.find((item) => item.deliveryPoiontId === deliveryPoint.id)?.paymentPeriod,
                    paymentAmount: getValues && getValues(`advancePayment.${deliveryPoint.id}`),
                }}
                deliveryPointId={deliveryPoint.id}
                deliveryPointType={deliveryPoint.type}
                tariff={tariff ? tariff : deliveryPoint.tariffRate}
                register={register}
                watch={watch}
                trigger={trigger}
                errors={errors}
                transcript={transcript}
            />
        </>
    );
};
