import React, { useCallback, useEffect, useState } from 'react';
import { clearObjectProperties } from '../../../main';
import { IFilterableColumnConfiguration } from './table-interfaces';

interface IColumnFilterToolbarProps<T, S> {
    columnConfigurations: IFilterableColumnConfiguration<T, S>[];
    filter: Partial<S>;
    onFilterChange: (change: Partial<S>) => void;
}

const ColumnFilterToolbar = <T, S>({ columnConfigurations, filter, onFilterChange }: IColumnFilterToolbarProps<T, S>): JSX.Element => {
    const [filterValues, setFilterValues] = useState<Partial<S>>(filter);

    const filterString = JSON.stringify(filter);
    useEffect(() => {
        setFilterValues(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterString]);

    const onChange = (change: Partial<S>) => {
        setFilterValues((prevFilter: Partial<S>) => {
            return clearObjectProperties({ ...prevFilter, ...change });
        });
    };

    const onSubmit = useCallback(() => {
        onFilterChange(filterValues);
    }, [onFilterChange, filterValues]);

    return (
        <>
            <tr className="thead-light filter">
                {columnConfigurations.map((col) => {
                    return col.filterComponent ? col.filterComponent(filterValues, onChange, onSubmit) : <th key={col.columnId} scope="col" />;
                })}
            </tr>
        </>
    );
};

export default ColumnFilterToolbar;
