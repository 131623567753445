import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useApi } from '../../../../hooks/use-api';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { SessionStorageEnum, useSessionStorage } from '../../../../hooks/use-session-storage';
import { ClickableElement, CustomerRequestEnum, HelpText, IRootState, useKeyValueStore } from '../../../../main';
import Routes from '../../../../routes/routes';
import { QueryKeysEnum } from '../../../../utils/react-query-utils';
import { formatAddressShort } from '../../../../utils/utils';
import { useOpenRequestFromDeliveryPointDetail } from '../../../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';

const ListCustomerRequest: React.FC = () => {
    const urlParams = useParams<{ unitedDeliveryPointId: string; deliveryPointId?: string }>();
    const publicUrl = process.env.REACT_APP_PUBLIC_URL;
    const [showInactiveDP] = useSessionStorage<boolean>(SessionStorageEnum.showInactiveDeliveryPoints);
    const [storedPageIndex] = useKeyValueStore(Routes.CUSTOMER_REQUESTS + '@pageIndex', 0);
    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeRequestCreationProcess(requestName, {});
    };

    const api = useApi();
    const { t } = useTranslation();

    const { data: unitedDeliveryPoint } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, urlParams.unitedDeliveryPointId, showInactiveDP],
        async () =>
            urlParams.unitedDeliveryPointId == null
                ? null
                : api.unitedDeliveryPoints
                      .getUdpByUuid(urlParams.unitedDeliveryPointId, { includeInactive: showInactiveDP }, { secure: true })
                      .then((x) => x.data),
    );

    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);

    return (
        <>
            {unitedDeliveryPoint != null && (
                <nav aria-label="breadcrumb" className="breadcrumb-more-items border-primary">
                    <div className="container">
                        <ol className="breadcrumb d-inline-flex p-0 mb-0">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={`${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}`}>
                                    {formatAddressShort(unitedDeliveryPoint.address)}
                                </Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link
                                    to={`${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}/requests/${urlParams.deliveryPointId ||
                                        ''}?page=${storedPageIndex + 1}`}
                                >
                                    {t(`delivery-point.detail.navigation.requests`)}
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <Link to="">{t(`customer-request.new-request`)}</Link>
                            </li>
                        </ol>
                    </div>
                </nav>
            )}

            <div className="page-header pt-4 pb-md-3 mb-4">
                <div className="container my-3">
                    <Row>
                        <Col>
                            <h1>
                                <Trans i18nKey="customer-request.new-request" />
                            </h1>
                        </Col>
                    </Row>
                </div>
            </div>
            <Container className="m-auto">
                <p>
                    <b>{t('customer-request.request-groups.order-services')}</b>
                </p>
                <Row className="mb-4">
                    <Col sm={12} md={4} className="mb-3 request-card-container">
                        <div className="request-card mr-lg-2">
                            <div
                                style={{ backgroundImage: `url(${publicUrl ? publicUrl : ''}/products-cover-image/spp01.jpg)` }}
                                className="product-cover"
                            />
                            <div className="p-4">
                                <h6 className="mt-2">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOM_ZODE">Žiadosť o dodávku elektriny</Trans>
                                </h6>
                                <button
                                    className="btn btn-inverted select-request-button postion-absolute-bottom"
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW)}
                                >
                                    <Trans i18nKey="customer-request.select">Zvolit žiadosť</Trans>
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={4} className="mb-3 request-card-container">
                        <div className="request-card mx-lg-2">
                            <div
                                style={{ backgroundImage: `url(${publicUrl ? publicUrl : ''}/products-cover-image/spp02.jpg)` }}
                                className="product-cover"
                            />
                            <div className="p-4">
                                <h6 className="mt-2">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOM_ZODP">Žiadosť o dodávku plynu</Trans>
                                </h6>
                                <button
                                    className="btn btn-inverted select-request-button postion-absolute-bottom"
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.GAS_SUPPLY_NEW)}
                                >
                                    <Trans i18nKey="customer-request.select">Zvolit žiadosť</Trans>
                                </button>
                            </div>
                        </div>
                    </Col>
                    {/*<Col sm={12} md={4} className="mb-3 request-card-container">
                        <div className="request-card ml-lg-2">
                            <div
                                style={{ backgroundImage: `url(${publicUrl ? publicUrl : ''}/products-cover-image/spp03.jpg)` }}
                                className="product-cover"
                            />
                            <div className="p-4">
                                <h6 className="mt-2">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOP_US">Uhlíková stopka</Trans>
                                </h6>
                                <button
                                    className="btn btn-inverted select-request-button postion-absolute-bottom"
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CARBON_SHANK_SHORT)}
                                >
                                    <Trans i18nKey="customer-request.select">Zvolit žiadosť</Trans>
                                </button>
                            </div>
                        </div>
                                    </Col>*/}
                </Row>
                <Row className="mb-4">
                    {loggedInCustomer && (
                        <Col sm={12} md={4} className="py-4 request-card-container">
                            <div className="mr-lg-2">
                                <h6 className="mb-4">{t('customer-request.request-groups.dp-point-change-data-and-invoicing')}</h6>
                                <div className="text-secondary mb-2 mr-2">
                                    {t('customer-request.request-groups.dp-point-change-data-and-invoicing-description')}
                                </div>
                                <button className="btn btn-inverted mt-4">
                                    <Link to={Routes.DELIVERY_POINTS} className="text-decoration-none">
                                        {t('delivery-point.my-delivery-points')}
                                    </Link>
                                </button>
                            </div>
                        </Col>
                    )}
                    {loggedInCustomer && (
                        <Col sm={12} md={4} className="py-4 request-card-container">
                            <div className="mx-lg-2">
                                <h6 className="mb-4">{t('customer-request.request-groups.others')}</h6>
                                <ClickableElement
                                    isText
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CONFIRMATION_AMOUNT_LIABILITIES_SHORT)}
                                >
                                    <div className="my-3">
                                        <Trans i18nKey="enums.CustomerRequestType.ZOM_POVZ">Potvrdenie o výške záväzkov</Trans>
                                    </div>
                                </ClickableElement>
                                <ClickableElement
                                    isText
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT)}
                                >
                                    <div className="my-3">
                                        <Trans i18nKey="enums.CustomerRequestType.ZOM_POZV">Potvrdenie o zmluvnom vzťahu</Trans>
                                    </div>
                                </ClickableElement>
                                <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.COMPLAINT_SHORT)}>
                                    <div className="my-3">
                                        <Trans i18nKey="customer-request.steps.complaint.title">Podanie podnetu</Trans>
                                    </div>
                                </ClickableElement>
                            </div>
                        </Col>
                    )}
                    <Col sm={12} md={4} className="py-4 mb-3 request-card-container">
                        <div className={loggedInCustomer ? 'ml-lg-2' : ''}>
                            <h6 className="mb-4">{t('customer-request.request-groups.pdf')}</h6>
                            <HelpText screen="NZT" field="NZT_ZI_PMNUZOZDP" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ListCustomerRequest;
