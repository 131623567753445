import React from 'react';
import { Trans } from 'react-i18next';
import useConfig from '../../../hooks/use-config';
import CantLoginViaSocialCard from './accordion-cards/cant-login-via-social-card';
import DifferentIssueCard from './accordion-cards/different-issue-card';
import ForgotEmailCard from './accordion-cards/forgot-email-card';
import ForgotPasswordCard from './accordion-cards/forgot-password-card';

type PropsType = {
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const MainAccordion: React.FC<PropsType> = ({ activeCard, toggleActive }) => {
    const { data: config } = useConfig();

    return (
        <>
            <h2 className="mb-5">
                <Trans i18nKey="login-issues.login-and-registration">Riešenie problémov s prihlásením a registráciou</Trans>
            </h2>
            <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                <ForgotPasswordCard activeCard={activeCard} toggleActive={toggleActive} />
                <ForgotEmailCard activeCard={activeCard} toggleActive={toggleActive} />
                {(config?.facebookLogin || config?.googleLogin) && <CantLoginViaSocialCard activeCard={activeCard} toggleActive={toggleActive} />}
                <DifferentIssueCard activeCard={activeCard} toggleActive={toggleActive} />
            </div>
        </>
    );
};

export default MainAccordion;
