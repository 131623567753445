import { SkSppNzpBeApiCustomerrequestCustomerRequestSummary } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IRootState } from '../../../reducers';
import { ChangeFormStepEnum } from '../../../reducers/interfaces/delivery-point-detail-state';
import { QueryKeysEnum, useReloadQueryCache } from '../../../utils/react-query-utils';
import ChangeRequestSent from '../../delivery-points/detail/data/change-request-sent';
import ChangeEInvoiceEmailForm from '../../delivery-points/detail/data/forms/change-einvoice-email';
import { getEInvoiceEmailsForUDP } from './utils/einvoice-utils';

interface IChangeDeliveryPointEmailModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

const ChangeDeliveryPointEmailModal: React.FC<IChangeDeliveryPointEmailModalProps> = ({ isOpen, onCloseModal }) => {
    const [editEmailModalState, setEditEmailModalState] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const deliveryPointForEmailChange = useSelector((state: IRootState) => state.eInvoiceSettings.deliveryPointForEmailChange);

    const api = useApi();
    const reloadQueryCache = useReloadQueryCache();

    const [mutateEInvoiceEmail, { isLoading, reset: resetMutationState }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | null,
        unknown,
        { dpId: string; email: string }
    >(
        async ({ dpId, email }) => {
            return await api.unitedDeliveryPoints
                .updateEInvoiceEmailUdp(
                    dpId,
                    {
                        email: email,
                    },
                    { secure: true },
                )
                .then((res) => res.data);
        },
        {
            onErrorWithGlobalErrorHandling: () => false,
            onSuccess: () => {
                reloadQueryCache(QueryKeysEnum.CUSTOMER_REQUESTS_SEARCH);
                setEditEmailModalState(ChangeFormStepEnum.SUCCESS);
            },
        },
    );

    useEffect(() => {
        setEditEmailModalState(ChangeFormStepEnum.EDITING);
        resetMutationState();
    }, [isOpen, resetMutationState]);

    const currentEInvoiceEmails = deliveryPointForEmailChange != null ? getEInvoiceEmailsForUDP(deliveryPointForEmailChange) : [];
    const canUnifyEmails = (email: string) => {
        if (currentEInvoiceEmails.length === 1) {
            return email !== currentEInvoiceEmails[0];
        } else {
            return true;
        }
    };

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const onEmailFormSubmit = async (email: string) => {
        if (deliveryPointForEmailChange?.id == null) return;
        if (!canUnifyEmails(email)) {
            addToast(t('settings.e-invoices.change-email.current-email-warning'), {
                appearance: 'warning',
            });
            return;
        }
        mutateEInvoiceEmail({ dpId: deliveryPointForEmailChange.id, email });
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    {isLoading && <LoadingIndicator />}
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="settings.e-invoices.change-email.modal-title" />
                    </h3>
                    {editEmailModalState === ChangeFormStepEnum.EDITING && (
                        <ChangeEInvoiceEmailForm
                            currentValue={undefined}
                            editedValue={undefined}
                            onSubmit={onEmailFormSubmit}
                            onReset={onCloseModal}
                        />
                    )}
                    {editEmailModalState === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onCloseModal} />}
                </ModalBody>
            </Modal>
        </>
    );
};

export default ChangeDeliveryPointEmailModal;
