import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ColumnHeaderCell from '../../../components/common/table/column-header-cell';
import { ISort, SortDirection } from '../../../components/common/table/table-interfaces';

type ListDeliveryPointsTableHeaderProps<T> = {
    sort?: ISort | undefined;
    onSortChange?: (field: keyof T, value: SortDirection | undefined) => void;
};

const ListDeliveryPointsTableHeader = <T,>({ sort, onSortChange }: ListDeliveryPointsTableHeaderProps<T>): ReactElement => {
    const onColumnSortChange = (fieldName: keyof T) => (newSort: SortDirection | undefined) =>
        onSortChange ? onSortChange(fieldName, newSort) : null;
    const { t } = useTranslation();

    return (
        <>
            <tr className="d-table-row">
                <ColumnHeaderCell label={t('delivery-point.place-number')} width="15%" />
                <ColumnHeaderCell
                    label={t('delivery-point.address')}
                    width="35%"
                    colSpan={2}
                    sort={sort && sort.attribute === 'street' ? sort.direction : undefined}
                    sortable
                    onSortChange={onColumnSortChange ? onColumnSortChange('street' as keyof T) : () => null}
                />
                <ColumnHeaderCell
                    label={t('delivery-point.services')}
                    width="20%"
                    sort={sort && sort.attribute === 'type' ? sort.direction : undefined}
                    sortable
                    onSortChange={onColumnSortChange ? onColumnSortChange('type' as keyof T) : () => null}
                />
                <ColumnHeaderCell
                    label={t('delivery-point.customer')}
                    width="25%"
                    sort={sort && sort.attribute === 'bpName' ? sort.direction : undefined}
                    sortable
                    onSortChange={onColumnSortChange ? onColumnSortChange('bpName' as keyof T) : () => null}
                />
                <ColumnHeaderCell label="" width="5%" />
            </tr>
        </>
    );
};

export default ListDeliveryPointsTableHeader;
