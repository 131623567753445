import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import useCodeList from '../../../../../hooks/use-code-list';
import { IAttachments, IComplainedAdvance } from '../../../../../models/customer-request-model';
import { ComplainedAdvancesPaymentMethodCodeEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../utils/utils';
import Attachments from '../../../../delivery-points/detail/data/contact-data-modals/component/block-attachments';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import ComplainedAdvancesFormFields, { IComplainedAdvanceData } from './components/complained-advances-form-fields';

type ComplainedAdvance = IComplainedAdvance & {
    uuid: string;
};

type ComplainedAdvanceFormType = {
    [key: string]: ComplainedAdvance;
};

const BlockComplainedAdvances: React.FC = () => {
    const dispatch = useDispatch();
    const { currentBlock, content, attachments } = useSelector((state: IRootState) => state.customerRequest);
    const [files, setFiles] = useState<IAttachments[]>(attachments || []);
    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.COMPLAINED_ADVANCES;
    const objectData = content[objectName] as IComplainedAdvance[];
    const [t] = useTranslation();
    const [filesError, setFilesError] = useState<string>('');

    const defaultData = useMemo(
        () =>
            objectData?.reduce<Record<string, IComplainedAdvance>>((preview, item) => {
                preview[`${uuidv4()}`] = { ...item };
                return preview;
            }, {}),
        [objectData],
    );

    const defaultArray = useMemo(
        () =>
            defaultData
                ? Object.entries(defaultData)?.map(([key, item]) => {
                      return { ...item, uuid: key };
                  })
                : null,
        [defaultData],
    );
    const { register, handleSubmit, errors, setValue, getValues, trigger } = useForm({
        defaultValues: { ...defaultData },
    });

    const [fields, setFields] = useState<IComplainedAdvanceData[]>(
        defaultArray ?? [{ amount: 0, vs: '', date: '', paymentType: {}, uuid: uuidv4() }],
    );

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const customPaymentCodeList = useMemo(() => {
        return paymentTypeCodeList?.filter(
            (item) => item.code && (Object.values(ComplainedAdvancesPaymentMethodCodeEnum) as string[]).includes(item?.code),
        );
    }, [paymentTypeCodeList]);

    const onSubmit = (data: ComplainedAdvanceFormType) => {
        if (files.length === 0) {
            setFilesError(t('common.input-rules.required'));
            return;
        }
        const advances = isObjectEmpty(data) ? [] : Object.values(data);
        const payload: CustomerRequestPayloadType = {
            [objectName]: advances,
        };

        dispatch(CustomerRequestActions.setData(payload));
        dispatch(CustomerRequestActions.setAttachments(files));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const handleRemove = (uuid: string) => {
        setFields(fields.filter((item) => item?.uuid !== uuid) ?? []);
    };

    useEffect(() => {
        if (files.length > 0) {
            setFilesError('');
        }
    }, [files]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <ComplainedAdvancesFormFields
                    codeList={customPaymentCodeList ?? []}
                    register={register}
                    errors={errors}
                    data={fields}
                    removeField={handleRemove}
                    addField={(item) => setFields([...fields, item])}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                />
                <Attachments
                    setFiles={setFiles}
                    files={files}
                    label={t('customer-request.steps.reason-for-claim-detail.files-description')}
                    className={filesError ? 'class' : ''}
                />
                {filesError && <div className="text-danger mt-2 mb-5">{filesError}</div>}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockComplainedAdvances;
