import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

interface IEmail extends DefaultFormType {
    name?: string;
    label?: string;
    canBeLoggedInUser?: boolean;
}

const Email: React.FC<IEmail> = ({ register, errors, trigger, name, label, canBeLoggedInUser }) => {
    const { formRules, emailSameAsLoggedInCustomer } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);

    const touched = lastOpened > current ? true : false;
    return (
        <div className="mt-1">
            <Input
                trigger={trigger}
                ref={register({
                    ...formRules.email,
                    ...formRules.required,
                    validate: canBeLoggedInUser
                        ? () => true
                        : {
                              ...emailSameAsLoggedInCustomer(loggedInCustomer?.email).validate,
                          },
                })}
                errors={errors}
                type="text"
                name={name ? name : 'email'}
                label={label ? label : <Trans i18nKey="customer-request.steps.new-customer-transcript.input-label">E-mail nového zákazníka</Trans>}
                autoCapitalize="off"
                autoCorrect="off"
                touched={touched}
            />
        </div>
    );
};

export default Email;
