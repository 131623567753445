import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: CodeListItem;
    bold?: boolean;
    code: CustomerRequestTypeEnum;
};

const ReasonEnumItem: React.FC<Props> = ({ data, bold, code }) => {
    const [t] = useTranslation();

    const { data: reasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ZOM_ZOPAOO_REASON,
        codeListTypeEnum: CodeListTypeEnum.ZOM_ZOPAOO_REASON,
        paging: {
            size: 30,
        },
    });

    const reason = useMemo(() => {
        return reasonCodeList?.find((item) => item.uuid === data.uuid)?.name;
    }, [reasonCodeList, data]);

    if (code === CustomerRequestTypeEnum.ZOM_ZOPAOO) return <></>;
    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.interaption-reason.title')}>
            <ItemPreviewContent bold={bold}>{reason ?? []}</ItemPreviewContent>
        </ItemPreview>
    );
};

export default ReasonEnumItem;
