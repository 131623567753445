import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import { CustomerRequestTypeEnum } from '../../../../config/enums';
import BusinessPartnerCompanyItem from './business-partner-company-item';
import BusinessPartnerPersonItem from './business-partner-person-item';

type Props = {
    data: IBusinessPartner;
    bold?: boolean;
    requestContent?: any;
};

const NewBusinessPartnerItem: React.FC<Props> = ({ data, bold, requestContent }) => {
    const [t] = useTranslation();

    const showPersonItem = !data.name && (data.lastName || data.bpNumber);

    const title =
        requestContent?.type === CustomerRequestTypeEnum.ZOM_P
            ? t('customer-request.steps.new-data.new-business-partner')
            : t('customer-request.steps.new-data.new-business-partner-data');

    return (
        <>
            {showPersonItem ? (
                <BusinessPartnerPersonItem data={data} bold={bold} title={title} />
            ) : (
                <BusinessPartnerCompanyItem data={data} bold={bold} title={title}></BusinessPartnerCompanyItem>
            )}
        </>
    );
};

export default NewBusinessPartnerItem;
