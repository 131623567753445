import { ICookiesInformation } from './interface';

//fallback; We need to be able to display the cookies alert even if www.spp.sk is not working or not responding.
export const CookiesInformationFallback: ICookiesInformation = {
    config: {
        sk: {
            consent:
                '<p>Na preferenčné, analytické a marketingové účely na tejto webovej stránke využívame informácie uložené vo vašom (koncovom) zariadení, tzv. súbory cookies. Kliknutím na „Nastaviť cookies“ môžete kedykoľvek nastaviť, ktoré cookies a na aké účely chcete povoliť. Ďalšie informácie o používaní cookies a o spracovaní osobných údajov môžete kedykoľvek nájsť na podstránke <a href="https://www.spp.sk/ochrana-osobnych-udajov/" rel="noopener noreferrer" target="_blank">Ochrana súkromia a spracovanie osobných údajov</a></p>',
            about:
                '<p>Cookies sú malé textové súbory uložené vo vašom zariadení, ktoré sú využívané na zlepšenie užívateľského zážitku na webovej stránke a na zabezpečenie niektorých funkcionalít webu.</p><p>Podľa § 109 ods. 8 zákona č. 452/2021 Z. z. o elektronických komunikáciách môžeme ukladať alebo získavať prístup ku cookies vo vašom zariadení len na základe vami preukázateľne udeleného súhlasu. Súhlas nie je potrebný pre cookies, ktoré sú nevyhnutne nutné pre zabezpečenie fungovania tejto webovej stránky.</p><p>Táto webová stránka využíva rôzne typy cookies. Svoj súhlas s&nbsp;využívaním jednotlivých typov cookies môžete kedykoľvek udeliť, zmeniť alebo odvolať v&nbsp;časti „Podrobné nastavenia“. Toto okno môžete kedykoľvek opätovne otvoriť prostredníctvom tlačidla umiestneného v&nbsp;dolnom rohu webovej stránky.</p><p>Ďalšie informácie o&nbsp;používaní cookies a&nbsp;o spracúvaní osobných údajov môžete kedykoľvek nájsť&nbsp;na podstránke&nbsp;<a href="https://prodweb.spp.sk/ochrana-osobnych-udajov/" rel="noopener noreferrer" target="_blank">Ochrana súkromia a spracúvanie osobných údajov</a>.</p>',
        },
        en: {
            consent:
                '<p>We use information stored in your (end) device, so-called cookies, to improve the browsing, personalize content and advertisements, provide social networking features, analyse website traffic and to improve features of this website. By clicking on <strong>Details</strong>, you may voluntarily decide at any times which of these cookies and for what purpose you want to allow. More information about cookies and about processing of personal data may be found on page Privacy and personal data processing.</p>',
            about:
                '<p>Cookies are small text files stored on your device, which are used to improve the user experience on the website and to ensure functionality of some of the features on the website.</p><p>According to Section 109 subsection 8 of the Act no. 452/2021 Coll. on Electronic Communications, we may only store or obtain access to cookies on your device with your explicit consent. Such consent is not needed for the cookies, which are necessary for basic usability of this website.</p><p>This website uses different types of cookies. You can give, change or revoke your consent to the use of individual types of cookies at any time in the <strong>Details</strong> section. You can reopen this window at any time using the button located at the bottom of the website. More information about cookies and about processing of personal data may be found on page <a href="https://www.spp.sk/en/data-privacy-and-personal-data-processing/" rel="noopener noreferrer" target="_blank">Privacy and personal data processing.</a></p>',
        },
        name: 'tempspp',
        hash: 'b469ad69744a41b117f8a619b5ac06bf',
        validity: 365,
        types: [
            {
                id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                sk: {
                    name: 'HTTP',
                },
                en: {
                    name: 'HTTP',
                },
            },
            {
                id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                sk: {
                    name: 'HTML',
                },
                en: {
                    name: 'HTML',
                },
            },
            {
                id: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                sk: {
                    name: 'Pixel',
                },
                en: {
                    name: 'Pixel',
                },
            },
        ],
        categoryTypes: [
            {
                id: 'ad_storage',
                subIds: ['ad_user_data', 'ad_personalization'],
                uuid: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                sk: {
                    name: 'Marketingové',
                    description:
                        '<p>Marketingové cookies umožňujú personalizáciu marketingového obsahu na tomto webe. Využívajú sa predovšetkým na zobrazenie relevantnej reklamy pre užívateľa alebo návštevníka webovej stránky.</p>',
                },
                en: {
                    name: 'Marketing',
                    description:
                        '<p>Marketing cookies enable the personalization of marketing content on this website. They are primarily used to display relevant advertising for the user or website visitor.</p>',
                },
                active: true,
                necessary: false,
            },
            {
                id: 'security_storage',
                subIds: [],
                uuid: '88dd1ec6-c69f-469c-b900-3f981a797c19',
                sk: {
                    name: 'Nevyhnutné',
                    description:
                        '<p>Nevyhnutné cookies zabezpečujú základnú použiteľnosť webovej stránky a bez ich využitia by táto webová stránka nefungovala správne. Ide napríklad o funkcie ako navigácia webovej stránky apod.</p>',
                },
                en: {
                    name: 'Necessary',
                    description:
                        '<p>Necessary cookies ensure the basic usability of the website and without their use this website would not function properly. These are, for example, functions such as website navigation, etc.</p>',
                },
                active: true,
                necessary: true,
            },
            {
                id: 'personalization_storage',
                subIds: [],
                uuid: '88dd1ec6-c69f-469c-b900-3f981a797c20',
                sk: {
                    name: 'Preferenčné',
                    description:
                        '<p>Preferenčné cookies umožňujú, aby sa uchovali informácie o preferovanom používaní webovej stránky užívateľom. Ide napríklad o voľbu preferovaného jazyka a pod.</p>',
                },
                en: {
                    name: 'Preference',
                    description:
                        "<p>Preference cookies enable information about the user's preferred use of the website to be stored. This is, for example, the choice of preferred language, etc.</p>",
                },
                active: true,
                necessary: false,
            },
            {
                id: 'analytics_storage',
                subIds: [],
                uuid: '88dd1ec6-c69f-469c-b900-3f981a797c21',
                sk: {
                    name: 'Analytické',
                    description:
                        '<p>Analytické cookies nám pomáhajú lepšie porozumieť správaniu návštevníkov a užívateľov webovej stránky a tým aj zlepšovať funkcionality tohto webu. Analytické cookies môžu vytvárať anonymné štatistiky.</p>',
                },
                en: {
                    name: 'Analytical',
                    description:
                        '<p>Analytical cookies help us better understand the behavior of visitors and users of the website and thereby improve the functionality of this website. Analytical cookies can create anonymous statistics.</p>',
                },
                active: true,
                necessary: false,
            },
            {
                id: 'UNCLASSIFIED_COOKIES',
                subIds: [],
                uuid: '88dd1ec6-c69f-469c-b900-3f981a797c22',
                sk: {
                    name: 'Nezaradené',
                    description:
                        '<p>Nezaradené súbory cookies sú cookies, ktoré práve zaraďujeme, spoločne s poskytovateľmi jednotlivých súborov cookies.</p>',
                },
                en: {
                    name: 'Unclassified',
                    description:
                        '<p>Unclassified cookies are cookies that we are currently including together with the providers of individual cookies.</p>',
                },
                active: true,
                necessary: false,
            },
        ],
        categories: [
            {
                id: 'eb7ef623-9e87-4cef-8c76-eaffc2ec954f',
                categoryTypeFk: {
                    id: 'security_storage',
                },
                providers: [
                    {
                        id: '7fdee172-2434-4676-a914-d116f422f7a1',
                        sk: {
                            name: 'www.spp.sk',
                            link: 'https://spp.sk',
                        },
                        en: {
                            name: 'www.spp.sk',
                            link: 'https://spp.sk',
                        },
                        details: [
                            {
                                id: 'ec14f324-15c0-4c22-be8f-ad0e70d0031c',
                                sk: {
                                    name: '%cookieName%',
                                    description: "<p>Stores the user's cookie consent state for the current domain.</p>",
                                    validity: '1 rok',
                                },
                                en: {
                                    name: '%cookieName%',
                                    description: "<p>Stores the user's cookie consent state for the current domain.</p>",
                                    validity: '1 year',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: 'e3ae90e7-5266-4e70-8123-7f6e4d66b815',
                        sk: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: '106dad63-f0c3-4ef9-8aec-d45ec0cdafc5',
                                sk: {
                                    name: 'test_cookie',
                                    description: "<p>Used to check if the user's browser supports cookies.</p>",
                                    validity: '1 deň',
                                },
                                en: {
                                    name: 'test_cookie',
                                    description: "<p>Used to check if the user's browser supports cookies.</p>",
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: 'c5061fd3-75d5-4c56-843d-8ba943bbdcc7',
                        sk: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: 'a2138abc-7b87-47f2-91c9-f137046aabec',
                                sk: {
                                    name: 'CONSENT',
                                    description:
                                        '<p>Used to detect if the visitor has accepted the marketing category in the cookie banner. This cookie is necessary for GDPR-compliance of the website.</p>',
                                    validity: '2 roky',
                                },
                                en: {
                                    name: 'CONSENT',
                                    description:
                                        '<p>Used to detect if the visitor has accepted the marketing category in the cookie banner. This cookie is necessary for GDPR-compliance of the website.</p>',
                                    validity: '2 years',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: '58886845-bb03-423c-8ae5-57c24cb14424',
                        sk: {
                            name: 'www.avay.spp.sk',
                            link: 'https://avay.spp.sk',
                        },
                        en: {
                            name: 'www.avay.spp.sk',
                            link: 'https://avay.spp.sk',
                        },
                        details: [
                            {
                                id: 'e5ad5006-4ad7-4bda-bdd2-913f386d7ed5',
                                sk: {
                                    name: 'PHPSESSID [x2]',
                                    description: '<p>Preserves user session state across page requests.</p>',
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'PHPSESSID [x2]',
                                    description: '<p>Preserves user session state across page requests.</p>',
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: 'dd2b431d-7bf3-4ad1-bbce-ea24495afa8f',
                categoryTypeFk: {
                    id: 'personalization_storage',
                },
                providers: [
                    {
                        id: '023ad91f-dfd9-4bee-9711-cb6fe66b5c0b',
                        sk: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: '5068df9c-c826-43e2-b802-b4effd905ecd',
                                sk: {
                                    name: 'yt-player-bandwidth',
                                    description:
                                        "<p>Used to determine the optimal video quality based on the visitor's device and network settings.</p>",
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: 'yt-player-bandwidth',
                                    description:
                                        "<p>Used to determine the optimal video quality based on the visitor's device and network settings.</p>",
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: '5c29c3b9-cc23-4b39-b908-676181054ca0',
                categoryTypeFk: {
                    id: 'analytics_storage',
                },
                providers: [
                    {
                        id: 'f35e6872-2611-4145-9d22-167c899858cf',
                        sk: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: 'c8426fe4-4889-4180-bea3-f59740861fc6',
                                sk: {
                                    name: 'collect',
                                    description:
                                        "<p>Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'collect',
                                    description:
                                        "<p>Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                                },
                            },
                            {
                                id: '9d9cb302-f477-4d4b-998f-e738374b68ff',
                                sk: {
                                    name: '_ga',
                                    description:
                                        '<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p>',
                                    validity: '2 roky',
                                },
                                en: {
                                    name: '_ga',
                                    description:
                                        '<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p>',
                                    validity: '2 years',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '5587c16b-feb5-4f1c-9ad5-d17b4d497007',
                                sk: {
                                    name: '_ga_#',
                                    description:
                                        '<p>Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.</p>',
                                    validity: '2 roky',
                                },
                                en: {
                                    name: '_ga_#',
                                    description:
                                        '<p>Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.</p>',
                                    validity: '2 years',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: 'a7fbc845-c271-41e4-b9ac-0b011d762aaa',
                                sk: {
                                    name: '_gat',
                                    description: '<p>Used by Google Analytics to throttle request rate.</p>',
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_gat',
                                    description: '<p>Used by Google Analytics to throttle request rate.</p>',
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: 'cf1f2280-f316-46a1-8ca4-2fec15e1689b',
                                sk: {
                                    name: 'gid',
                                    description:
                                        '<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p>',
                                    validity: '1 deň',
                                },
                                en: {
                                    name: 'gid',
                                    description:
                                        '<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the website.</p>',
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: 'f0b5518e-f9b1-4e25-9cd2-5cb3a9eb9590',
                        sk: {
                            name: 'Hotjar',
                            link: 'https://www.hotjar.com/legal/policies/privacy/',
                        },
                        en: {
                            name: 'Hotjar',
                            link: 'https://www.hotjar.com/legal/policies/privacy/',
                        },
                        details: [
                            {
                                id: '8b138c16-1773-4234-ba84-4a36b35fb571',
                                sk: {
                                    name: '_hjAbsoluteSessionInProgress',
                                    description:
                                        '<p>This cookie is used to count how many times a website has been visited by different visitors - this is done by assigning the visitor an ID, so the visitor does not get registered twice.</p>',
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_hjAbsoluteSessionInProgress',
                                    description:
                                        '<p>This cookie is used to count how many times a website has been visited by different visitors - this is done by assigning the visitor an ID, so the visitor does not get registered twice.</p>',
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '58298d2c-df56-4e09-be41-58f1d97f73f4',
                                sk: {
                                    name: '_hjFirstSeen',
                                    description:
                                        '<p>This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.</p>',
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_hjFirstSeen',
                                    description:
                                        '<p>This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.</p>',
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '4efd0cd1-abcd-4ae0-af21-bd3e4ff1e60e',
                                sk: {
                                    name: '_hjIncludedInPageviewSample',
                                    description:
                                        "<p>Used to detect whether the user navigation and interactions are included in the website's data analytics.</p>",
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_hjIncludedInPageviewSample',
                                    description:
                                        "<p>Used to detect whether the user navigation and interactions are included in the website's data analytics.</p>",
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '09641c18-c86a-491f-8a05-fd2045ea9691',
                                sk: {
                                    name: '_hjIncludedInSessionSample',
                                    description:
                                        "<p>Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization.</p>",
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_hjIncludedInSessionSample',
                                    description:
                                        "<p>Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization.</p>",
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '8c95972b-6e2e-4ad6-9f69-595d56b75b2c',
                                sk: {
                                    name: '_hjSession_#',
                                    description:
                                        "<p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>",
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '_hjSession_#',
                                    description:
                                        "<p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>",
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '56c18310-aa31-47e0-93e8-ca1bbac0cbd0',
                                sk: {
                                    name: '_hjSessionUser_#',
                                    description:
                                        "<p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>",
                                    validity: '1 rok',
                                },
                                en: {
                                    name: '_hjSessionUser_#',
                                    description:
                                        "<p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>",
                                    validity: '1 year',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '863d8405-b019-4343-9848-476d1c1887f7',
                                sk: {
                                    name: '_hjTLDTest',
                                    description:
                                        "<p>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: '_hjTLDTest',
                                    description:
                                        "<p>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: 'e6fee4fa-ae2a-4f81-8fc4-4bb2105d66c5',
                        sk: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: 'ba60db2d-77bf-4d0d-b719-5e50b2f4286a',
                                sk: {
                                    name: 'yt-player-headers-readable',
                                    description:
                                        "<p>Used to determine the optimal video quality based on the visitor's device and network settings.</p>",
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: 'yt-player-headers-readable',
                                    description:
                                        "<p>Used to determine the optimal video quality based on the visitor's device and network settings.</p>",
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: '9acef87d-b31e-41ed-ac97-a0ad17674823',
                categoryTypeFk: {
                    id: 'ad_storage',
                },
                providers: [
                    {
                        id: 'a4248157-97e1-4489-9d52-c9e911cec2cf',
                        sk: {
                            name: 'Meta Platforms, Inc.',
                            link: 'https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0',
                        },
                        en: {
                            name: 'Meta Platforms, Inc.',
                            link: 'https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0',
                        },
                        details: [
                            {
                                id: '56fbd62b-402b-4d95-a5b5-dc424da016a8',
                                sk: {
                                    name: '_fbp',
                                    description:
                                        '<p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p>',
                                    validity: '3 mesiace',
                                },
                                en: {
                                    name: '_fbp',
                                    description:
                                        '<p>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.</p>',
                                    validity: '3 months',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: 'e004b594-19b3-4bfb-a57f-21632e9c76f5',
                        sk: {
                            name: 'Bloomreach',
                            link: 'https://exponea.com/legal/privacy-policy/',
                        },
                        en: {
                            name: '__exponea_etc__',
                            link: 'https://exponea.com/legal/privacy-policy/',
                        },
                        details: [
                            {
                                id: '8c5494a6-e753-4370-896e-b342c74004ec',
                                sk: {
                                    name: '__exponea_etc__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: '3 roky',
                                },
                                en: {
                                    name: '__exponea_etc__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: '3 years',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: 'bbab7036-c646-498b-9911-570f204314fe',
                                sk: {
                                    name: '__exponea_last_session_ping_timestamp__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: '__exponea_last_session_ping_timestamp__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '2bc3e872-6cc7-43e5-8c40-c2acd03c72c2',
                                sk: {
                                    name: '__exponea_last_session_start_timestamp__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: '__exponea_last_session_start_timestamp__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '6f209fa5-90a7-459d-9014-3151fdf9447d',
                                sk: {
                                    name: '__exponea_time2__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: '1 deň',
                                },
                                en: {
                                    name: '__exponea_time2__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: '1 day',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '06d91bf1-cdb4-4a94-82c0-39aa669dcafb',
                                sk: {
                                    name: '__exponea_tracking_definition__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Session',
                                },
                                en: {
                                    name: '__exponea_tracking_definition__',
                                    description:
                                        '<p>This cookie is used to determine which products the visitor has viewed. This information is used to promote related products and optimize ad-efficiency.</p>',
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                        ],
                    },
                    {
                        id: '18ab4773-53a5-4fb2-90dc-447fe6e99594',
                        sk: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'Google',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: '0eb8aa06-f4bc-4b8b-ac25-3552880d74bf',
                                sk: {
                                    name: 'IDE',
                                    description:
                                        "<p>Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</p>",
                                    validity: '1 rok',
                                },
                                en: {
                                    name: 'IDE',
                                    description:
                                        "<p>Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</p>",
                                    validity: '1 year',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '1122c967-f25a-423c-8e05-4168d322e347',
                                sk: {
                                    name: 'pagead/landing [x2]',
                                    description:
                                        '<p>Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement - This also allows the website to limit the number of times that they are shown the same advertisement.</p>',
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'pagead/landing [x2]',
                                    description:
                                        '<p>Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement - This also allows the website to limit the number of times that they are shown the same advertisement.</p>',
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                                },
                            },
                            {
                                id: '41a4ff00-3b76-474d-bf04-90dd0b0477c7',
                                sk: {
                                    name: 'ads/ga-audiences',
                                    description:
                                        "<p>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behaviour across websites.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'ads/ga-audiences',
                                    description:
                                        "<p>Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behaviour across websites.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                                },
                            },
                            {
                                id: 'adcf1e59-8e2c-4983-a0c7-6d2b9fe83f4f',
                                sk: {
                                    name: 'pagead/1p-user-list/#',
                                    description:
                                        '<p>Tracks if the user has shown interest in specific products or events across multiple websites and detects how the user navigates between sites. This is used for measurement of advertisement efforts and facilitates payment of referral-fees between websites.</p>',
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'pagead/1p-user-list/#',
                                    description:
                                        '<p>Tracks if the user has shown interest in specific products or events across multiple websites and detects how the user navigates between sites. This is used for measurement of advertisement efforts and facilitates payment of referral-fees between websites.</p>',
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '88dd1ec6-c69f-469c-b900-3f981a797c18',
                                },
                            },
                            {
                                id: '1f3580ab-ef36-4b08-949e-80b1afa5b792',
                                sk: {
                                    name: '_gcl_au',
                                    description:
                                        '<p>Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.</p>',
                                    validity: '3 mesiace',
                                },
                                en: {
                                    name: '_gcl_au',
                                    description:
                                        '<p>Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.</p>',
                                    validity: '3 month',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                    {
                        id: '0d6193a2-5f8d-4c4e-aabc-f608044960cc',
                        sk: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        en: {
                            name: 'YouTube',
                            link: 'https://policies.google.com/privacy',
                        },
                        details: [
                            {
                                id: 'ed50dc86-2dcf-4d4c-87dd-d03317a4f05f',
                                sk: {
                                    name: 'VISITOR_INFO1_LIVE',
                                    description: "<p>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</p>",
                                    validity: '179 dní',
                                },
                                en: {
                                    name: 'VISITOR_INFO1_LIVE',
                                    description: "<p>Tries to estimate the users' bandwidth on pages with integrated YouTube videos.</p>",
                                    validity: '179 days',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '162719f1-31b9-4053-9bb9-1b8fc60323d8',
                                sk: {
                                    name: 'YSC',
                                    description: '<p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>',
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'YSC',
                                    description: '<p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>',
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                            {
                                id: '23340148-6850-4ba6-87cc-6cd2a56c51cd',
                                sk: {
                                    name: 'ytidb::LAST_RESULT_ENTRY_KEY',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: 'ytidb::LAST_RESULT_ENTRY_KEY',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '188e8403-64c8-43bb-bbae-24bfe7198bb4',
                                sk: {
                                    name: 'yt-remote-cast-available',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'yt-remote-cast-available',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '759367e2-a241-4d6f-90a7-c5086dd22936',
                                sk: {
                                    name: 'yt-remote-cast-installed',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'yt-remote-cast-installed',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '9385600b-9f68-44e1-90ea-b3465ccca694',
                                sk: {
                                    name: 'yt-remote-connected-devices',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: 'yt-remote-connected-devices',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '70b518ba-4563-4aaa-b628-53bb8c72a51e',
                                sk: {
                                    name: 'yt-remote-device-id',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                en: {
                                    name: 'yt-remote-device-id',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Persistent',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '674ef644-1783-4388-a7fb-42a6b21725bb',
                                sk: {
                                    name: 'yt-remote-fast-check-period',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'yt-remote-fast-check-period',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: 'a87bb234-a1bc-49a3-bb4e-4899ffbdb6a7',
                                sk: {
                                    name: 'yt-remote-session-app',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'yt-remote-session-app',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                            {
                                id: '5835ccff-dd0b-43de-bd32-503c5be9923a',
                                sk: {
                                    name: 'yt-remote-session-name',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                en: {
                                    name: 'yt-remote-session-name',
                                    description: "<p>Stores the user's video player preferences using embedded YouTube video.</p>",
                                    validity: 'Session',
                                },
                                typeFk: {
                                    id: '2be12e63-5f31-4e6a-bec7-382aa2e642d1',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: 'eaf7960a-95cd-48e2-88cc-08440e58fa85',
                categoryTypeFk: {
                    id: 'UNCLASSIFIED_COOKIES',
                },
                providers: [
                    {
                        id: '07cccd95-dd67-4593-b94f-26f885c4e867',
                        sk: {
                            name: 'Exponea',
                            link: 'https://exponea.com/legal/privacy-policy/',
                        },
                        en: {
                            name: 'Exponea',
                            link: 'https://exponea.com/legal/privacy-policy/',
                        },
                        details: [
                            {
                                id: '36df859d-edc6-48bc-80b5-97ebf8ee437f',
                                sk: {
                                    name: '__EX_b21e8bc8dd2b4c86654b0e4120849056a6e966be__',
                                    description: '<p>Awaiting approval.</p>',
                                    validity: '3 roky',
                                },
                                en: {
                                    name: '__EX_b21e8bc8dd2b4c86654b0e4120849056a6e966be__',
                                    description: '<p>Awaiting approval.</p>',
                                    validity: '3 years',
                                },
                                typeFk: {
                                    id: 'a1ebded1-1464-42c9-8caf-73764cc0f593',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
