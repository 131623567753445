import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardHeader, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions';
import { LoadingIndicator } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { ReCaptchaInfoMessage } from '../../../../../components/recaptcha/ReCaptchaInfoMessage';
import {
    Checkbox,
    ClickableElement,
    CodeListTypeEnum,
    formatCurrency,
    HelpModal,
    PaymentMethodCodeEnum,
    QueryKeysEnum,
    Routes,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IPaymentMethod } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

type FinalPeriodeType = {
    price: number;
    monthPostfix: string;
};

type OrderCheckboxType = {
    personalInfoProcessingConcent: boolean;
    personalInfoProcessing: boolean;
};
const BlockCompletionClearElectricity: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { content, customerRequestTemplate, metadata, businessPartner, deliveryPoint } = useSelector((state: IRootState) => state.customerRequest);
    const { customer } = useSelector((state: IRootState) => state.user);

    const [loading, setLoading] = useState<boolean>(false);
    const recaptchaVisible = process.env.REACT_APP_RECAPTCHA === 'true' ? (customer?.id ? false : true) : false;

    const { formRules } = useFormRules();

    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const [finalPeriode, setFinalPeriode] = useState<FinalPeriodeType>();
    const [paymentType, setPaymentType] = useState<PaymentMethodCodeEnum>(PaymentMethodCodeEnum.INKASO);

    const { register, handleSubmit, errors } = useForm<OrderCheckboxType>();
    const initializeRequestCreationProcess = useSendRequest();

    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const [personalInfoProcessingConcentShown, setPersonalInfoProcessingConcentShown] = useState(false);

    const [isPersonalInfoProcessingOpen, setIsPersonalInfoProcessingOpen] = useState(false);
    const [personalInfoProcessingShown, setPersonalInfoProcessingShown] = useState(false);

    const showPersonalInfoProcessingConcent = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingConcentOpen(true);
    };

    const showPersonalInfoProcessing = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingOpen(true);
    };

    useEffect(() => {
        if (isPersonalInfoProcessingConcentOpen) setPersonalInfoProcessingConcentShown(true);
    }, [isPersonalInfoProcessingConcentOpen, setPersonalInfoProcessingConcentShown]);

    useEffect(() => {
        if (isPersonalInfoProcessingOpen) setPersonalInfoProcessingShown(true);
    }, [isPersonalInfoProcessingOpen, setPersonalInfoProcessingShown]);

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    useEffect(() => {
        // payment
        const recastPaymentMethod: (IPaymentMethod & { paymentTypeId?: string }) | undefined = content.paymentMethod;

        if (recastPaymentMethod?.paymentTypeId) {
            setPaymentType(
                (paymentTypeCodeList?.find((item) => item.uuid === recastPaymentMethod?.paymentTypeId)?.code as PaymentMethodCodeEnum) ??
                    PaymentMethodCodeEnum.SIPO,
            );
        }
    }, [paymentTypeCodeList, content]);

    useEffect(() => {
        const totalPrice = 2;

        const periodInMonths = parseInt(content.advancePaymentPeriod?.period?.attributes?.months || '1', 10);
        const price = periodInMonths * totalPrice;
        const monthPostfix = `${periodInMonths}months`;

        setFinalPeriode({ price, monthPostfix });
    }, [content]);

    const onSubmit = (formData: OrderCheckboxType) => {
        const data: any = {
            content: {
                metadata,
                ...content,
                approvals: {
                    personalInfoProcessingConcent: formData.personalInfoProcessingConcent ? true : false,
                    personalInfoProcessing: formData.personalInfoProcessing ? true : false,
                },
                type: customerRequestTemplate?.code,
                entityReferences: [
                    {
                        contractId: deliveryPoint?.contract?.id,
                        deliveryPointId: deliveryPoint?.id,
                        contractAccountId: (deliveryPoint as SkSppNzpBeApiCustomerprofileDeliveryPoint).contract?.contractAccount?.id,
                    },
                ],
                contact: undefined,
                paymentMethod: {
                    paymentType: {
                        paymentTypeId: undefined,
                        uuid: content.paymentMethod?.paymentTypeId,
                    },
                    sipoNumber: content.paymentMethod?.sipoNumber,
                    iban: content.paymentMethod?.iban,
                    bicSwift: content.paymentMethod?.bicSwift,
                },
            },
            customerRequestTemplate,
        };
        setLoading(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setLoading(false));
    };

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />
            <HelpModal
                show={isPersonalInfoProcessingOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_CONFIRMATION_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingOpen(false)}
            />
            <ListGroup>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.product')}>
                        <ItemPreviewContent bold>{t('customer-request.steps.block-clean-energy-completion.product-name')}</ItemPreviewContent>
                    </ItemPreview>

                    <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.customer')}>
                        <ItemPreviewContent bold>
                            <>
                                {businessPartner?.firstName +
                                    ' ' +
                                    businessPartner?.lastName +
                                    ' (' +
                                    t('delivery-point.customer-number') +
                                    ' ' +
                                    businessPartner?.externalId +
                                    ')'}
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>

                    <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.delivery-point')}>
                        <ItemPreviewContent bold>
                            <>{`${deliveryPoint?.address?.street} ${deliveryPoint?.address?.streetNumber} ${deliveryPoint?.address?.city} - ${t(
                                `enums.CommodityEnum.${deliveryPoint?.type}`,
                            )}`}</>
                        </ItemPreviewContent>
                    </ItemPreview>

                    {finalPeriode && (
                        <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.payment')}>
                            <ItemPreviewContent bold>
                                <>
                                    {formatCurrency(Number(finalPeriode?.price.toFixed(2)))} /{' '}
                                    {t('customer-request.steps.payment-period.' + finalPeriode?.monthPostfix)}
                                </>
                            </ItemPreviewContent>
                        </ItemPreview>
                    )}

                    <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.payment-method')}>
                        <ItemPreviewContent bold>
                            <>
                                {paymentType === PaymentMethodCodeEnum.INKASO && t('customer-request.steps.payment-method.inkaso')}
                                {paymentType === PaymentMethodCodeEnum.BANK_TRANSFER && t('customer-request.steps.payment-method.bank-transfer')}
                                {paymentType === PaymentMethodCodeEnum.SIPO && t('customer-request.steps.payment-method.sipo')}

                                <br />

                                {content.paymentMethod?.iban &&
                                    `${t('customer-request.steps.block-clean-energy-completion.iban-number')} ${content.paymentMethod?.iban}`}
                                {content.paymentMethod?.sipoNumber && content.paymentMethod?.sipoNumber}

                                <p className="mb-0">{content.paymentMethod?.bicSwift && ' BIC/SWIFT: ' + content.paymentMethod?.bicSwift}</p>
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>

                    <ItemPreview title={t('customer-request.steps.block-clean-energy-completion.invoice-delivery')}>
                        <ItemPreviewContent>
                            <b>{content.invoiceDelivery?.email}</b>
                            <br />
                            <small>
                                <Trans i18nKey="customer-rquest.steps.block-clean-energy-completion.email-for-invoice-delivery">
                                    Na tento email vám odteraz budeme zasielať aj faktúry za elektrinu.
                                </Trans>
                            </small>
                        </ItemPreviewContent>
                    </ItemPreview>

                    <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            className="mb-1"
                            id="personalInfoProcessingConcent"
                            name="personalInfoProcessingConcent"
                            label={
                                <Trans
                                    i18nKey="customer-request.steps.terms-condition.personal-info-processing-consent"
                                    components={{
                                        Link: (
                                            <ClickableElement
                                                isText
                                                onClick={showPersonalInfoProcessingConcent}
                                                className="text-decoration-underline-inverse"
                                            >
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            onChange={() => {
                                if (!personalInfoProcessingConcentShown) setIsPersonalInfoProcessingConcentOpen(true);
                            }}
                        />
                    </CardHeader>
                    <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            className="mb-1"
                            id="personalInfoProcessing"
                            name="personalInfoProcessing"
                            label={
                                <Trans
                                    i18nKey="customer-request.steps.terms-condition.personal-info-processing"
                                    components={{
                                        Link: (
                                            <ClickableElement
                                                isText
                                                onClick={showPersonalInfoProcessing}
                                                className="text-decoration-underline-inverse"
                                            >
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            onChange={() => {
                                if (!personalInfoProcessingShown) setIsPersonalInfoProcessingOpen(true);
                            }}
                        />
                    </CardHeader>

                    <BaseButton type="submit">
                        <Trans i18nKey="customer-request.steps.send-request" />
                    </BaseButton>
                </form>
                <div
                    className={classNames({
                        'd-none': !recaptchaVisible,
                    })}
                >
                    <ReCaptchaInfoMessage />
                </div>
            </ListGroup>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <Card className="w-100">
                        <div className="card-body text-center py-5">
                            <i className="icon-circle-ok-16 fa-lg"></i>
                            <h6>
                                <Trans i18nKey="customer-request.steps.block-clean-energy-completion.order-send">Žiadosť odoslaná</Trans>
                            </h6>
                            <h4 className="mt-5">
                                <Trans i18nKey="customer-request.steps.block-clean-energy-completion.confirmation-text">Žiadosť odoslaná</Trans>{' '}
                                {content.invoiceDelivery?.email}
                            </h4>
                            <p className="mt-5">
                                <Trans i18nKey="delivery-point.detail.modals.follow-requests-status-in-my-spp-reqests">
                                    Stav žiadosti môžete sledovať v Moje&nbsp;SPP - v časti Žiadosti.
                                </Trans>
                            </p>
                            <Link to={Routes.CUSTOMER_REQUESTS} className="btn btn-light btn-block btn-lg mt-3">
                                <Trans i18nKey="delivery-point.detail.modals.navigate-to-requests">Prejsť na žiadosti</Trans>
                            </Link>
                            <Button
                                color="primary"
                                block
                                size="lg"
                                className="mt-3"
                                onClick={() => {
                                    onRequestSentSuccessConfirmed();
                                }}
                            >
                                <Trans i18nKey="delivery-point.detail.modals.ok">OK</Trans>
                            </Button>
                        </div>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    );
};

export default BlockCompletionClearElectricity;
