import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseTextarea from '../../../../../../components/common/base-textarea';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import {
    BaseButton,
    BusinessPartnerTypeEnum,
    CalculatorCard,
    CommodityEnum,
    CustomerRequestTypeEnum,
    IRootState,
    KINDcodeHome,
} from '../../../../../../main';

type Props = {
    currentValue: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    editedValues: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    onSubmit: (newPaymentAmount: number, reason?: string) => void;
    onReset: () => void;
    hideMinPayment?: boolean;
    recommendedPayment?: number;
    minPayment?: number;
    deliveryPointType: CommodityEnum;
    tariff?: SkSppNzpCommonsApiCodelistCodeListItem;
};

const getNumericValueForContractPaymentPeriod = (paymentPeriod: string) => {
    return Number(paymentPeriod);
};

const AdvencePaymentSingleForm: React.FC<Props> = ({
    currentValue,
    editedValues,
    onSubmit,
    onReset,
    hideMinPayment,
    minPayment,
    recommendedPayment,
    deliveryPointType,
    tariff,
}) => {
    const saveRequested = useSelector((state: IRootState) => state.customerRequest.content.saveRequested);
    const { code, businessPartner, content } = useSelector((state: IRootState) => state.customerRequest);

    const displayValue = {
        paymentPeriod: editedValues.paymentPeriod ?? currentValue.paymentPeriod,
        paymentAmount: editedValues.paymentAmount ?? currentValue.paymentAmount,
    };

    const recommendedPaymentAmount = useMemo(() => {
        if (currentValue.paymentAmount != null && currentValue.paymentPeriod?.code != null && displayValue.paymentPeriod?.code != null) {
            // recalculated original value per 1 month
            const originalPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(currentValue.paymentPeriod.code);
            const currentPaymentAmountBase = Math.round(currentValue.paymentAmount / originalPaymentPeriodMultiplier);

            const editedPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(displayValue.paymentPeriod.code);

            return currentPaymentAmountBase * editedPaymentPeriodMultiplier;
        }
        return undefined;
    }, [currentValue.paymentAmount, currentValue.paymentPeriod?.code, displayValue.paymentPeriod?.code]);

    const minimalPaymentValue = useMemo(() => {
        if (recommendedPaymentAmount == null) {
            return 1;
        } else {
            return Math.round(Math.max(recommendedPaymentAmount * 0.8, 1));
        }
    }, [recommendedPaymentAmount]);

    const { register, handleSubmit, watch, errors, trigger, getValues } = useForm<{ advancePayment: string; reason?: string }>({
        defaultValues: { advancePayment: displayValue.paymentAmount ? Number(displayValue.paymentAmount).toString() : '', reason: '' },
    });

    const submitHelpFunction = (fields: { advancePayment: string; reason?: string | undefined }) => {
        const numericAdvancePayment = Number(fields.advancePayment);
        if (Number.isNaN(numericAdvancePayment)) {
            onSubmit(0);
        } else {
            onSubmit(numericAdvancePayment, fields.reason);
        }
    };

    const onFormSubmit = handleSubmit((fields) => {
        submitHelpFunction(fields);
    });

    const { formRules, requiredTrimed } = useFormRules();

    const advancePaymentInputValue = watch('advancePayment');

    useEffect(() => {
        if (saveRequested) {
            const data = getValues();
            submitHelpFunction(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveRequested]);

    return (
        <form onSubmit={onFormSubmit} noValidate onReset={onReset}>
            <p>
                <b>
                    <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.amount-of-the-pay">Aká bude výška platby?</Trans>
                </b>
            </p>

            <InputWithAppend
                type="number"
                ref={register({
                    ...formRules.requiredInteger,
                    ...formRules.min(minPayment || 1),
                })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                name="advancePayment"
                id={'advancePayment'}
                label={<Trans i18nKey="delivery-point.detail.payment-data.advance-payments.advance-payment-amount">Výška preddavkovej platby</Trans>}
                trigger={trigger}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        €
                    </button>
                }
                autoFocus
            />

            <div className="mb-4">
                {recommendedPayment != null && recommendedPayment > 0 && (
                    <>
                        <b>
                            <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.recommended-payment">Odporúčaná výška:</Trans>{' '}
                            {recommendedPayment} € / {displayValue.paymentPeriod?.name}
                        </b>
                        <br />
                    </>
                )}
                {!hideMinPayment && (
                    <>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.minimum-payment">Minimálna výška:</Trans> {minPayment} € /{' '}
                        {displayValue.paymentPeriod?.name}
                        <br />
                    </>
                )}
                {recommendedPayment != null && recommendedPayment > 0 && (
                    <>
                        {deliveryPointType === CommodityEnum.ELECTRICITY ? (
                            <>
                                (
                                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-selected-rate">
                                    určené podľa zvolenej sadzby
                                </Trans>{' '}
                                {tariff?.name})
                            </>
                        ) : (
                            <>
                                (
                                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-selected-tariff">
                                    určené podľa zvolenej tarify
                                </Trans>{' '}
                                {tariff?.name})
                            </>
                        )}
                    </>
                )}
            </div>

            {currentValue.paymentAmount != null && Number(advancePaymentInputValue) < minimalPaymentValue && (
                <div className="mb-4">
                    <h4>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.reduction-reason">Dôvod zníženia</Trans>
                    </h4>
                    <p>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.less-than-minimum">
                            Zadali ste výšku platby, ktoré je nižšia ako minimálna. Uveďte dôvod, prečo požadujete zníženie.
                        </Trans>
                    </p>
                    <BaseTextarea
                        ref={register(requiredTrimed)}
                        errors={errors}
                        name="reason"
                        rows={3}
                        maxLength={200}
                        label={<Trans i18nKey="customer-request.steps.amount-advances.reason" />}
                    />
                </div>
            )}

            {/* Help button */}
            {/* <HelpLinkWithModal showAsCard className="my-4" linkTitle="common.dialog-help" field="DOM_ADVANCE_PAYMENT_CHANGE_HELP" /> */}

            <hr />

            <div className="text-center">
                {/* <span>
                    <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.current-payment">Aktuálna platba:</Trans>{' '}
                    {currentValue.paymentAmount} € / {currentValue.paymentPeriod?.name}
                </span>
                <br /> */}
                {advancePaymentInputValue != null && Number(advancePaymentInputValue) > 0 && (
                    <b>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.requested-payment">Požadovaná platba:</Trans>{' '}
                        {advancePaymentInputValue} € / {editedValues.paymentPeriod?.name}
                    </b>
                )}
            </div>

            <div className="d-flex mt-4 mx-1 p-2">
                <div className="mr-3 my-auto">
                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                </div>
                <Trans i18nKey="customer-request.steps.advance-payment.info">Výška platby sa odvíja od predpokladanej spotreby.</Trans>
            </div>

            <CalculatorCard
                comodity={code === CustomerRequestTypeEnum.ZOM_ZODE ? CommodityEnum.ELECTRICITY : CommodityEnum.GAS}
                type={
                    (businessPartner?.kind?.code && businessPartner?.kind?.code !== KINDcodeHome) ||
                    content.businessPartner?.name ||
                    content.accountInfo?.category === BusinessPartnerTypeEnum.COMPANY
                        ? BusinessPartnerTypeEnum.COMPANY
                        : BusinessPartnerTypeEnum.PERSON
                }
            />

            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default AdvencePaymentSingleForm;
