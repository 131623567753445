import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileTariffRate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { ITariffWithDpId } from '../../../../../models/customer-request-model';
import { BusinessPartnerTypeEnum, CommodityEnum, ConsumptionCategoryEnum, KINDcodeHome } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties, formatAddressShort } from '../../../../../utils/utils';
import { CustomerRequestDataEnum } from '../../../config/enums';
import { TranscriptTariffSelection } from './components/transcript-tariff-selection';

interface IItem {
    tariff: {
        uuid: string;
    };
}

interface ITariff {
    deliveryPoint: {
        [key: string]: IItem;
    };
}

export const BlockTariffSelectionTranscript: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.TARIFFS;
    const { t } = useTranslation();

    const [tariffRates, setTariffRates] = useState<SkSppNzpBeApiCustomerprofileTariffRate[]>([]);

    const setTariffs = (tariffs: SkSppNzpBeApiCustomerprofileTariffRate[]) => {
        const newTariffs = [...tariffRates, ...tariffs];
        setTariffRates(newTariffs);
    };
    const defaultValues: ITariff = {
        deliveryPoint:
            content.tariffs?.reduce((o, key) => ({ ...o, [key.deliveryPointId]: { tariff: { uuid: key.id } } }), {}) ??
            (content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.reduce(
                (o, key) => ({
                    ...o,
                    [key.id || '']: { tariff: { uuid: key.tariffRate?.uuid } },
                }),
                {},
            ),
    };

    const { register, handleSubmit, errors } = useForm<ITariff>({ defaultValues });

    const onSubmit = (data: ITariff) => {
        const tariffs: ITariffWithDpId[] = [];

        Object.entries((data.deliveryPoint as Record<string, IItem>) ?? []).forEach(([dpId, values]) => {
            tariffs.push({
                deliveryPointId: dpId,
                ...clearObjectProperties({
                    name: tariffRates.find((rate) => rate.uuid === values?.tariff?.uuid)?.name,
                    id: values?.tariff?.uuid || '',
                }),
            });
        });

        const payload: CustomerRequestPayloadType = {
            [objectName]: tariffs,
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const consumptionCategory = useMemo(() => {
        if (
            businessPartner?.kind?.code === KINDcodeHome ||
            content.businessPartner?.type === BusinessPartnerTypeEnum.PERSON ||
            content.accountInfo?.category === BusinessPartnerTypeEnum.PERSON
        ) {
            return ConsumptionCategoryEnum.HOME;
        } else {
            return ConsumptionCategoryEnum.RETAIL;
        }
    }, [content.businessPartner, businessPartner, content.accountInfo]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {(content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.map((dp, index) => (
                <div className="my-4" key={`dp_${dp.id}`}>
                    {index !== 0 && <hr />}
                    <div className="tariff-select-dp-name mt-5">
                        <span>
                            {formatAddressShort(dp.address)} -{' '}
                            {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')} ({t('common.delivery-point')}{' '}
                            {dp.externalId})
                        </span>
                    </div>
                    <TranscriptTariffSelection
                        key={dp.id}
                        register={register}
                        errors={errors}
                        deliveryPoint={dp}
                        setTariffs={setTariffs}
                        consumptionCategory={consumptionCategory}
                    />
                </div>
            ))}
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};
