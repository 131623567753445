import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import { nextTick } from 'process';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseSelect from '../../../../../components/common/base-select';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import useCodeList from '../../../../../hooks/use-code-list';
import { CommodityEnum } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CodeListTypeEnum } from '../../../config/enums';
import { advancePaymentBasedOnTariff } from '../../../config/request-data';
import AdvencePaymentSingleForm from './components/change-advance-payment-form-single';

export interface ISelectedPaymentPeriod {
    deliveryPoiontId?: string;
    paymentPeriod?: SkSppNzpCommonsApiCodelistCodeListItem;
}

const BlockAdvancePaymentSingle: React.FC = () => {
    const dispatch = useDispatch();

    const { content } = useSelector((state: IRootState) => state.customerRequest);

    const { data: advancePaymentPeriodCodelist, isLoading } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum.ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 5000,
        },
    });

    const [newPaymentAmount, setNewPaymentAmount] = useState<number | undefined>(content.advancePayment?.amount);
    const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState<SkSppNzpCommonsApiCodelistCodeListItem | undefined>(
        content.advancePaymentPeriod?.period,
    );

    const { t } = useTranslation();

    const onFormSubmit = (formData: number, reason?: string) => {
        if (selectedPaymentPeriod) {
            setNewPaymentAmount(formData);
        }
        if ((formData && selectedPaymentPeriod) || content.saveRequested) {
            const payload: CustomerRequestPayloadType = {
                advancePayment: { amount: formData, reason: reason || '', date: new Date().toISOString() },
                advancePaymentPeriod: selectedPaymentPeriod && { period: selectedPaymentPeriod },
                saved: content.saveRequested ? true : undefined,
            };
            dispatch(CustomerRequestActions.setData(payload));
            !content.saveRequested &&
                nextTick(() => {
                    dispatch(CustomerRequestActions.nextStep());
                });
        }
    };

    useEffect(() => {
        if (advancePaymentPeriodCodelist != null && advancePaymentPeriodCodelist.length > 0 && !selectedPaymentPeriod) {
            // preselect first available peyment period option
            const firstAvailableOption = advancePaymentPeriodCodelist.find((item) => item.code === 'MONTHLY');
            setSelectedPaymentPeriod(firstAvailableOption);
        }
    }, [advancePaymentPeriodCodelist, selectedPaymentPeriod]);

    const findAdvancePaymentBasedOnTariff = useCallback(() => {
        if (content.tariffEe?.tariff?.code && advancePaymentBasedOnTariff[content.tariffEe.tariff.code]) {
            return advancePaymentBasedOnTariff[content.tariffEe.tariff.code];
        }
        if (content.tariffZp?.tariff?.code && advancePaymentBasedOnTariff[content.tariffZp.tariff.code]) {
            return advancePaymentBasedOnTariff[content.tariffZp.tariff.code];
        }
        return undefined;
    }, [content.tariffEe?.tariff?.code, content.tariffZp?.tariff?.code]);

    const foundAdvancePaymentBasedOnTariff = findAdvancePaymentBasedOnTariff();

    return (
        <>
            {isLoading && <LoadingIndicator />}
            <p className="mt-4">
                <b>
                    <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.how-often-will-you-pay">Ako často budete platiť?</Trans>
                </b>
            </p>
            <ButtonGroup className="d-none d-md-inline-flex custom-buttons-group mb-4 w-100">
                {advancePaymentPeriodCodelist?.map((advancePaymentPeriodCodelistItem) => (
                    <button
                        type="button"
                        key={advancePaymentPeriodCodelistItem.uuid}
                        onClick={() => setSelectedPaymentPeriod(advancePaymentPeriodCodelistItem)}
                        className={classNames('btn', {
                            active: selectedPaymentPeriod?.uuid === advancePaymentPeriodCodelistItem.uuid,
                        })}
                        style={{ padding: '.375rem .7rem' }}
                    >
                        {advancePaymentPeriodCodelistItem.name}
                    </button>
                ))}
            </ButtonGroup>
            <div className="mb-4 d-block d-md-none">
                <BaseSelect
                    label={t('delivery-point.detail.payment-data.advance-payments.interval')}
                    className="form-control-filter"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        setSelectedPaymentPeriod(advancePaymentPeriodCodelist?.find((payment) => payment.uuid === event.target.value))
                    }
                >
                    {advancePaymentPeriodCodelist?.map((advancePaymentPeriodCodelistItem) => (
                        <option
                            key={advancePaymentPeriodCodelistItem.uuid}
                            value={advancePaymentPeriodCodelistItem.uuid}
                            selected={selectedPaymentPeriod?.uuid === advancePaymentPeriodCodelistItem.uuid}
                        >
                            {advancePaymentPeriodCodelistItem.name}
                        </option>
                    ))}
                </BaseSelect>
            </div>

            <AdvencePaymentSingleForm
                currentValue={{
                    paymentPeriod: undefined,
                    paymentAmount: undefined,
                }}
                editedValues={{
                    paymentPeriod: selectedPaymentPeriod,
                    paymentAmount: newPaymentAmount,
                }}
                onSubmit={onFormSubmit}
                onReset={() => null}
                hideMinPayment={!(foundAdvancePaymentBasedOnTariff && foundAdvancePaymentBasedOnTariff.minimal !== 0)}
                minPayment={Math.ceil(
                    (foundAdvancePaymentBasedOnTariff?.minimal || 0) *
                        ((Number(selectedPaymentPeriod?.attributes?.months) < 12 ? Number(selectedPaymentPeriod?.attributes?.months) : 11) || 0),
                )}
                recommendedPayment={foundAdvancePaymentBasedOnTariff?.recommended}
                deliveryPointType={content.tariffEe ? CommodityEnum.ELECTRICITY : CommodityEnum.GAS}
                tariff={content.tariffEe?.tariff || content.tariffZp?.tariff}
            />
        </>
    );
};

export default BlockAdvancePaymentSingle;
