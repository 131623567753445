import { SkSppNzpBeApiCustomerrequestCustomerRequest } from '@spp/spp-meru-frontend-common';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { CustomerRequestActions } from '../../../../actions/customer-request-actions';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IAttachments } from '../../../../models/customer-request-model';
import { IApiResponse, IResponseError } from '../../../../models/model';
import { IRootState } from '../../../../reducers';

export const useSendRequest = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { content } = useSelector((state: IRootState) => state.customerRequest);
    const anonymousRequestRecaptchaToken = useSelector((state: IRootState) => state.customerRequest.content.reCaptchaToken?.token);
    const { customer } = useSelector((state: IRootState) => state.user);
    const accessToken = useSelector((state: IRootState) => state.auth.accessToken);
    const secondaryAccessToken = useSelector((s: IRootState) => s.auth.secondaryAccessToken);
    const api = useApi();
    const { t } = useTranslation();

    const [mutateSendAttachment] = useMutationWithError<Response, Response, { uuid: string; recaptcha?: string | null; attachment: IAttachments }>(
        async ({ uuid, recaptcha, attachment }) => {
            const formData = new FormData();
            attachment.file && formData.append('file', attachment.file);
            attachment.info && formData.append('draftMetadata', attachment.info);

            if (accessToken || secondaryAccessToken)
                return await fetch(`${process.env.REACT_APP_API_URL}/customer-requests/${uuid}/attachments`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${secondaryAccessToken || accessToken}`,
                    },
                });
            else
                return await fetch(`${process.env.REACT_APP_API_URL}/customer-requests/${uuid}/attachments/anonymous`, {
                    method: 'POST',
                    body: formData,
                    headers: recaptcha
                        ? {
                              'X-GoogleReCaptcha-Code': recaptcha,
                          }
                        : {},
                });
        },
    );

    const onAttachmentError = (
        errors: { attachment: IAttachments; body: IResponseError | undefined }[],
        files: IAttachments[],
        setFiles?: Dispatch<SetStateAction<IAttachments[]>>,
    ) => {
        let validatedAttachments: IAttachments[] = files.map((x) => ({ ...x, errorMessage: undefined }));

        // show validation toasts
        errors.forEach((err) => {
            if (err?.body?.code === 23001) {
                addToast(t('common.attachment-too-big-error', { name: err.attachment.file?.name }), { appearance: 'error' });
            } else if (err?.body?.code === 9005) {
                addToast(t('common.attachment-empty-file-error', { name: err.attachment.file?.name }), { appearance: 'error' });
            } else {
                addToast(t('common.attachment-send-error', { name: err.attachment.file?.name }), { appearance: 'error' });
            }
        });

        // assign validation messages to files
        errors.forEach((err) => {
            let getErrorMessage: (() => string) | undefined = undefined;
            if (err?.body == null) {
                getErrorMessage = () => t('common.attachment-listitem-file-unknown-error', { name: err.attachment.file?.name });
            } else if (err.body.code === 23001) {
                getErrorMessage = () => t('common.attachment-listitem-file-too-big-error', { name: err.attachment.file?.name });
            } else if (err.body.code === 9005) {
                getErrorMessage = () => t('common.attachment-listitem-empty-file-error', { name: err.attachment.file?.name });
            } else {
                getErrorMessage = () => t('common.attachment-is-invalid-error', { name: err.attachment.file?.name });
            }
            validatedAttachments = validatedAttachments.map((x) => {
                if (x.uuid === err.attachment.uuid) {
                    return { ...x, getErrorMessage: getErrorMessage };
                }
                return x;
            });
        });
        setFiles && setFiles(validatedAttachments);
    };

    const sendAttachments = async (uuid: string, files: IAttachments[], setFiles?: Dispatch<SetStateAction<IAttachments[]>>) => {
        const results = await Promise.all(
            files
                .filter((attachment) => attachment.file)
                .map(async (attachment) => ({
                    attachment: attachment,
                    response: await mutateSendAttachment({ uuid: uuid, attachment: attachment }),
                })),
        );

        const errorResponses = results.filter((result) => result?.response == null || result?.response?.ok === false);
        if (errorResponses.length > 0) {
            const errorMapping: { attachment: IAttachments; body: IResponseError }[] = await Promise.all(
                errorResponses.map(async (res) => {
                    if (res.response == null) {
                        return {
                            attachment: res.attachment,
                            body: undefined,
                        };
                    } else {
                        const body = await res.response?.json().catch((err) => console.error('Response parsing error', err));
                        return {
                            attachment: res.attachment,
                            body: body,
                        };
                    }
                }),
            );
            onAttachmentError(errorMapping, files, setFiles);
        }
        const allAttachmentsSent = errorResponses.length === 0;
        return allAttachmentsSent;
    };

    const [mutateCreateCustomerRequest] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequest | null,
        IApiResponse,
        { customerId: string; data: SkSppNzpBeApiCustomerrequestCustomerRequest }
    >(async ({ customerId, data }) => api.customers.createCustomerRequestForCustomer(customerId, data, { secure: true }).then((res) => res.data), {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            let foundError = false;
            response?.error?.errors?.forEach((item) => {
                const error = item.split(':');
                const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                foundError = true;
                addToast(`${field} - ${code}`, {
                    appearance: 'error',
                });
            });
            return foundError;
        },
    });

    const [mutateCreateCustomerRequestAnonymous] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequest | null,
        IApiResponse,
        { data: SkSppNzpBeApiCustomerrequestCustomerRequest }
    >(
        async ({ data }) =>
            api.customers
                .createCustomerRequest(data, { headers: { 'X-ReCaptcha-Token': anonymousRequestRecaptchaToken || '' }, secure: true })
                .then((res) => res.data)
                .finally(() => {
                    content.reCaptchaToken?.remainingUsesCount &&
                        dispatch(
                            CustomerRequestActions.setData({
                                reCaptchaToken: {
                                    ...content.reCaptchaToken,
                                    remainingUsesCount: content.reCaptchaToken?.remainingUsesCount - 1,
                                },
                            }),
                        );
                }),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                let foundError = false;
                response?.error?.errors?.forEach((item) => {
                    const error = item.split(':');
                    const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                    const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                    foundError = true;
                    addToast(code == 'customer-request:errors:code:undefined' ? `${field}` : `${field} - ${code}`, {
                        appearance: 'error',
                    });
                });

                return foundError;
            },
        },
    );

    const [mutateUpdateCustomerRequest] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequest | null,
        IApiResponse,
        { customerId: string; requestId: string; data: SkSppNzpBeApiCustomerrequestCustomerRequest }
    >(
        async ({ customerId, requestId, data }) =>
            api.customers.updateCustomerRequest(customerId, requestId, data, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                let foundError = false;
                response?.error?.errors?.forEach((item) => {
                    const error = item.split(':');
                    const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                    const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                    foundError = true;
                    addToast(`${field} - ${code}`, {
                        appearance: 'error',
                    });
                });
                return foundError;
            },
        },
    );

    const [mutateSendAnonymousCustomerRequest] = useMutationWithError<Response, Response, { uuid: string; attachments?: IAttachments[] }>(
        async ({ uuid, attachments }) => {
            const formData = new FormData();
            attachments?.forEach((attachment) => {
                attachment.file && formData.append('attachment', attachment.file);
            });

            return await fetch(`${process.env.REACT_APP_API_URL}/customers/anonymous/customer-requests/${uuid}/send`, {
                method: 'POST',
                body: attachments?.length ? formData : undefined,
                headers: anonymousRequestRecaptchaToken
                    ? {
                          'X-ReCaptcha-Token': anonymousRequestRecaptchaToken,
                      }
                    : {},
            }).finally(() => {
                content.reCaptchaToken?.remainingUsesCount &&
                    dispatch(
                        CustomerRequestActions.setData({
                            reCaptchaToken: {
                                ...content.reCaptchaToken,
                                remainingUsesCount: content.reCaptchaToken?.remainingUsesCount - 1,
                            },
                        }),
                    );
            });
        },
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                let foundError = false;
                response?.error?.errors?.forEach((item) => {
                    const error = item.split(':');
                    const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                    const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                    foundError = true;
                    addToast(`${field} - ${code}`, {
                        appearance: 'error',
                    });
                });
                return foundError;
            },
        },
    );

    const [mutateSendCustomerRequest] = useMutationWithError<SkSppNzpBeApiCustomerrequestCustomerRequest | null, IApiResponse, { requestId: string }>(
        async ({ requestId }) => api.customerRequests.sendCustomerRequest(requestId, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                let foundError = false;
                response?.error?.errors?.forEach((item) => {
                    const error = item.split(':');
                    const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                    const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                    foundError = true;
                    addToast(`${field} - ${code}`, {
                        appearance: 'error',
                    });
                });
                return foundError;
            },
        },
    );

    const submitRequest = async (
        data: SkSppNzpBeApiCustomerrequestCustomerRequest,
        files?: IAttachments[],
        setFiles?: Dispatch<SetStateAction<IAttachments[]>>,
        onlyCreate?: boolean,
    ) => {
        if (customer?.id) {
            let response: SkSppNzpBeApiCustomerrequestCustomerRequest | null | undefined;
            if (data.uuid) {
                response = await mutateUpdateCustomerRequest({ customerId: customer.id, requestId: data.uuid, data });
            } else {
                response = await mutateCreateCustomerRequest({ customerId: customer.id, data });
            }
            if (response?.uuid) {
                const isSuccess = await sendAttachments(response.uuid, files || [], setFiles);
                if (!isSuccess) {
                    throw new Error('Files error');
                }
                if (onlyCreate) {
                    return 'Request created';
                }
                const isSentSuccessfully = await mutateSendCustomerRequest({ requestId: response.uuid });
                if (!isSentSuccessfully) {
                    throw new Error(t('crash-fallback.headline'));
                }
                return 'Request sent';
            } else {
                throw new Error('Missing response ID');
            }
        } else {
            const response = await mutateCreateCustomerRequestAnonymous({ data });
            if (response?.uuid) {
                const responseSend = await mutateSendAnonymousCustomerRequest({ uuid: response.uuid, attachments: files });
                if (responseSend?.status !== 200) {
                    throw new Error(t('crash-fallback.headline'));
                }
                return 'Request sent';
            } else {
                throw new Error('Missing response ID');
            }
        }
    };

    const initializeRequestSendignProcess = (
        data: SkSppNzpBeApiCustomerrequestCustomerRequest,
        files?: IAttachments[],
        setFiles?: Dispatch<SetStateAction<IAttachments[]>>,
        onlyCreate?: boolean,
    ) => {
        return submitRequest(data, files, setFiles, onlyCreate)
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    };

    return initializeRequestSendignProcess;
};
