import { nextTick } from 'process';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestBlockTitleEnum } from '../../../config/enums';
import { confirmationAmountLiabilitiesShortBPBlocksData, confirmationAmountLiabilitiesShortDPBlocksData } from '../../../config/request-config';

const BlockSelectDPorBP: React.FC = () => {
    const dispatch = useDispatch();
    const { request } = useSelector((state: IRootState) => state.customerRequest);
    const { t } = useTranslation();

    const nextStep = (selected: 'DP' | 'BP') => {
        if (selected === 'BP') {
            if (request?.blocks[1].key === CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT) {
                dispatch(CustomerRequestActions.replaceBlocks(confirmationAmountLiabilitiesShortBPBlocksData));
                dispatch(CustomerRequestActions.setDeliveryPoint(undefined));
            }
        } else {
            if (request?.blocks[1].key === CustomerRequestBlockTitleEnum.BLOCK_BUSINESS_PARTNER) {
                dispatch(CustomerRequestActions.replaceBlocks(confirmationAmountLiabilitiesShortDPBlocksData));
            }
        }
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <div className="mt-4">
            <CardHeader className="border-bottom-0 rounded w-100 mb-4 light-shadow" style={{ backgroundColor: 'white', padding: '3rem' }}>
                <ClickableElement onClick={() => nextStep('DP')} className="w-100">
                    <h3>{t('customer-request.steps.select-dp-or-bp.for-dp')}</h3>
                    <p className="mb-0">{t('customer-request.steps.select-dp-or-bp.for-dp-info')}</p>
                </ClickableElement>
            </CardHeader>
            <CardHeader className="border-bottom-0 rounded w-100 mb-4 light-shadow" style={{ backgroundColor: 'white', padding: '3rem' }}>
                <ClickableElement onClick={() => nextStep('BP')} className="w-100">
                    <h3>{t('customer-request.steps.select-dp-or-bp.for-bp')}</h3>
                    <p className="mb-0">{t('customer-request.steps.select-dp-or-bp.for-bp')}</p>
                </ClickableElement>
            </CardHeader>
        </div>
    );
};

export default BlockSelectDPorBP;
