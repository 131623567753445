import { nextTick } from 'process';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions';
import { BaseRadio } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { customerEmails, isInsertedEmailCustom, LoadingIndicator } from '../../../../../main';
import { IInvoiceDelivery } from '../../../../../models/customer-request-model';
import { InvoiceDeliveryStepEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { CustomerRequestDataEnum } from '../../../config/enums';
import InvoiceDeliveryFormFieldsOnlyEmail from './components/invoice-delivery-form-fields-only-email';

const BlockInvoiceDeliveryOnlyEmail: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner, additionalData } = useSelector((state: IRootState) => state.customerRequest);
    const { customer } = useSelector((state: IRootState) => state.user);
    const { t, i18n } = useTranslation();
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const useRecaptcha =
        process.env.REACT_APP_RECAPTCHA === 'true' &&
        !loggedInCustomer &&
        (content.reCaptchaToken?.remainingUsesCount === undefined || content.reCaptchaToken?.remainingUsesCount === 0);
    const [key, setKey] = useState(0);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const api = useApi();
    const [mutateRecaptchaToken, { isLoading: isLoadingRecaptcha }] = useMutationWithError(
        async (recaptcha: string) =>
            api.customers.reCaptchaToken(
                { tokenType: 'ANONYMOUS_CUSTOMER_REQUEST' },
                { headers: { 'X-GoogleReCaptcha-Code': recaptcha }, secure: true },
            ),
        {
            onSuccess: (resp) => {
                const insertedCustomerEmail = content.accountInfo?.email || content.contact?.email;
                if (resp.data?.token && insertedCustomerEmail) {
                    dispatch(CustomerRequestActions.setData({ reCaptchaToken: resp.data }));
                    mutateCheckEmail({ email: insertedCustomerEmail, recaptchaToken: resp.data?.token });
                }
            },
        },
    );

    const [mutateCheckEmail, { isLoading: isLoadingEmail }] = useMutationWithError(
        async ({ email, recaptchaToken }: { email: string; recaptchaToken: string }) =>
            api.customers.checkEmailExists({ email: email }, { headers: { 'X-ReCaptcha-Token': recaptchaToken }, secure: true }),
        {
            onSuccess: (resp) => {
                if (resp.data?.exists === false) {
                    dispatch(CustomerRequestActions.addRegistrationBlock());
                } else {
                    dispatch(CustomerRequestActions.removeRegistrationBlock());
                }
                content.reCaptchaToken?.remainingUsesCount &&
                    dispatch(
                        CustomerRequestActions.setData({
                            reCaptchaToken: {
                                ...content.reCaptchaToken,
                                remainingUsesCount: content.reCaptchaToken?.remainingUsesCount - 1,
                            },
                        }),
                    );
                nextTick(() => {
                    dispatch(CustomerRequestActions.nextStep());
                });
            },
        },
    );

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.INVOICE_DELIVERY;

    const isEmailCustom = useMemo(
        () => isInsertedEmailCustom(content, additionalData, customer, businessPartner?.email, content.accountInfo?.email, content.contact?.email),
        [content, additionalData, customer, businessPartner?.email],
    );

    const emailList = customerEmails(additionalData, customer, businessPartner?.email, content.accountInfo?.email, content.contact?.email);

    const [displayEmailInput, setDisplayEmailInput] = useState<boolean>(isEmailCustom);
    const [radioButtonInput, setRadioButtonInput] = useState<string | undefined>(content.invoiceDelivery?.email || emailList[0] || '');

    const objectData = {
        type: InvoiceDeliveryStepEnum.EMAIL,
        email: content.invoiceDelivery?.email || emailList[0] || '',
    };

    const { register, handleSubmit, errors, setValue, trigger } = useForm<IInvoiceDelivery>({
        defaultValues: objectData,
    });

    const onSubmit = (data: IInvoiceDelivery) => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (useRecaptcha && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        useRecaptcha && recaptchaRef?.current?.reset();

        const newData = { type: data.type, email: displayEmailInput ? data.email : radioButtonInput };
        const payload: CustomerRequestPayloadType = {
            [objectName]: newData,
        };

        dispatch(CustomerRequestActions.setData(payload));

        if (!loggedInCustomer && reCaptchaHelp) {
            mutateRecaptchaToken(reCaptchaHelp);
            return;
        }
        if (!loggedInCustomer && content.accountInfo && content.reCaptchaToken?.token) {
            mutateCheckEmail({ email: content.accountInfo.email, recaptchaToken: content.reCaptchaToken.token });
            return;
        }

        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <>
            {(isLoadingEmail || isLoadingRecaptcha) && <LoadingIndicator />}

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {!displayEmailInput && (
                    <>
                        {emailList.map((email, index) => (
                            <Card className="mb-3" key={`email_${index}`}>
                                <CardBody>
                                    <BaseRadio
                                        value={businessPartner?.email}
                                        id={`businessPartnerEmailRadio_${index}`}
                                        name={`email_${index}`}
                                        className="mt-0"
                                        label={<>{email}</>}
                                        onClick={() => {
                                            setRadioButtonInput(email);
                                            setValue('email', email);
                                        }}
                                        checked={radioButtonInput === email}
                                    />
                                </CardBody>
                            </Card>
                        ))}
                    </>
                )}

                <div className="mb-4">
                    <div
                        className="cursor-pointer clickable-element "
                        style={{ textAlign: 'center', textDecoration: 'underline' }}
                        onClick={() => {
                            if (displayEmailInput) {
                                setRadioButtonInput(emailList[0]);
                            }
                            setDisplayEmailInput(!displayEmailInput);
                        }}
                    >
                        {displayEmailInput ? (
                            <p>
                                <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.chose-from-list" />
                            </p>
                        ) : (
                            <p className="mb-0">
                                <Trans i18nKey="customer-request.steps.block-carbon-footprint-invoice-delivery.type-email" />
                            </p>
                        )}
                    </div>
                    <div style={displayEmailInput ? {} : { display: 'none' }}>
                        <InvoiceDeliveryFormFieldsOnlyEmail register={register} errors={errors} trigger={trigger} />
                    </div>
                </div>

                {useRecaptcha && (
                    <div>
                        <ReCAPTCHA
                            key={key}
                            ref={recaptchaRef}
                            sitekey={useRecaptcha ? recaptchaSiteKey : 'undefined'}
                            onChange={() => setReCaptchaError(false)}
                            hl={i18n.language === 'en' ? 'en' : 'sk'}
                        />
                        {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                    </div>
                )}

                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockInvoiceDeliveryOnlyEmail;
