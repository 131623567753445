import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import BaseSelect from '../../../../../components/common/base-select';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import Input from '../../../../../components/common/input';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IAttachments, IDeliveryPointConnection, IDeliveryPointEeConnection } from '../../../../../models/customer-request-model';
import { DeliveryPointEeConnectionEnum, DistributorOptionsEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties, formatAddressShort } from '../../../../../utils/utils';
import Attachments from '../../../../delivery-points/detail/data/contact-data-modals/component/block-attachments';
import { CustomerRequestBlockTitleEnum, CustomerRequestDataEnum } from '../../../config/enums';
import {
    blocksEeSupplyMigrationConnection,
    blocksEeSupplyNewConnection,
    blocksZpSupplyMigrationConnection,
    blocksZpSupplyNewConnection,
} from '../../../config/request-config';

const BlockDeliveryPointConnection: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, attachments, request } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_EE;
    const objectData = content[objectName] as IDeliveryPointEeConnection;
    const { requiredTrimed } = useFormRules();
    const { t } = useTranslation();
    const [files, setFiles] = useState<IAttachments[]>(attachments ?? []);
    const [showModal, setShowModal] = useState(false);

    const { register, errors, handleSubmit, watch, trigger, setError, clearErrors, getValues } = useForm<IDeliveryPointConnection>({
        defaultValues: objectData,
    });

    const ZP = currentBlock?.params?.zp ?? false;

    const type = watch('type');
    const distributor = watch('currentDistributor');

    const { formRules } = useFormRules();

    const onSubmit = (data: IDeliveryPointEeConnection) => {
        if (type === DeliveryPointEeConnectionEnum.NEW && files.filter((item) => item.info === 'connection').length === 0 && !content.saveRequested) {
            setError('files', { type: 'required', message: 'Povinný údaj' });
            return;
        }
        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties({ ...data, type }),
            saved: content.saveRequested ? true : undefined,
            supplier: type === DeliveryPointEeConnectionEnum.NEW ? undefined : content.supplier,
        };
        dispatch(CustomerRequestActions.setData(payload));
        dispatch(
            CustomerRequestActions.setAttachments(
                type === DeliveryPointEeConnectionEnum.NEW ? files : files.filter((item) => item.info !== 'connection'),
            ),
        );
        if (content.saveRequested) {
            return;
        }
        if (ZP) {
            if (type === DeliveryPointEeConnectionEnum.NEW && request?.blocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_CHANGE_SUPPLIER_ZP) {
                dispatch(CustomerRequestActions.replaceBlocks(blocksZpSupplyNewConnection));
            }

            if (
                type === DeliveryPointEeConnectionEnum.MIGRATION &&
                request?.blocks[2]?.key !== CustomerRequestBlockTitleEnum.BLOCK_CHANGE_SUPPLIER_ZP
            ) {
                dispatch(CustomerRequestActions.replaceBlocks(blocksZpSupplyMigrationConnection));
            }
        } else {
            if (type === DeliveryPointEeConnectionEnum.NEW && request?.blocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_CHANGE_SUPPLIER) {
                dispatch(CustomerRequestActions.replaceBlocks(blocksEeSupplyNewConnection));
            }

            if (type === DeliveryPointEeConnectionEnum.MIGRATION && request?.blocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_OWNER_OBJECT) {
                dispatch(CustomerRequestActions.replaceBlocks(blocksEeSupplyMigrationConnection));
            }
        }

        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <p className="text-center font-weight-bold">{formatAddressShort({ ...content.address, streetNumber: content.address?.number })}</p>
            <Card className="mb-3">
                <CardBody className="py-2 px-4">
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={DeliveryPointEeConnectionEnum.MIGRATION}
                        id="zpeeMigration"
                        name="type"
                        className="mt-0 py-2"
                        onClick={() => clearErrors('files')}
                        label={<Trans i18nKey="customer-request.steps.connection.yes-switch-to-spp" />}
                    />
                </CardBody>
            </Card>

            {type === DeliveryPointEeConnectionEnum.MIGRATION && (
                <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-4 mb-3">
                    <BaseSelect
                        ref={register(requiredTrimed)}
                        name="currentDistributor"
                        label={<Trans i18nKey="customer-request.steps.electricity-connection.original-supplier" />}
                        className="form-control-filter"
                        errors={errors}
                    >
                        <option value="">{t('customer-request.steps.connection.chose')}</option>
                        {Object.keys(DistributorOptionsEnum).map((item, index) => (
                            <option key={`distributor_${index}`} value={item}>
                                {DistributorOptionsEnum[item as keyof typeof DistributorOptionsEnum]}
                            </option>
                        ))}
                        <option value="other">{t('customer-request.steps.connection.other')}</option>
                    </BaseSelect>
                    {distributor === 'other' && (
                        <Input
                            trigger={trigger}
                            ref={register({
                                ...formRules.required,
                            })}
                            errors={errors}
                            type="text"
                            name="currentDistributorCustom"
                            label={<Trans i18nKey="customer-request.steps.connection.distributor-custom" />}
                        />
                    )}
                </div>
            )}

            <Card className="mb-3">
                <CardBody className="py-2 px-4">
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={DeliveryPointEeConnectionEnum.NEW}
                        id="zpeeNew"
                        name="type"
                        className="mt-0 py-2"
                        label={<Trans i18nKey="customer-request.steps.connection.no-new-connection" />}
                    />
                </CardBody>
            </Card>

            {type === DeliveryPointEeConnectionEnum.NEW && (
                <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-4 mb-3">
                    <Attachments
                        className="mb-3"
                        files={files}
                        setFiles={setFiles}
                        subtitle={
                            <p>
                                {ZP
                                    ? t('customer-request.steps.connection-zp.attachment-subtitle')
                                    : t('customer-request.steps.connection.attachment-subtitle')}
                            </p>
                        }
                        label={t('customer-request.steps.connection.attachment-title')}
                        errors={errors}
                        clearErrors={clearErrors}
                        info="connection"
                    />
                    <div className="row py-3 m-0">
                        <div className="col-1 p-0">
                            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                        </div>
                        <div className="col px-0">
                            <ClickableElement className="text-decoration-underline-inverse" isText onClick={() => setShowModal(true)}>
                                <Trans i18nKey="customer-request.steps.connection.dont-have-contract-yet">
                                    Ešte nemám uzatvorenú zmluvu o pripojení. Ako ďalej?
                                </Trans>
                            </ClickableElement>
                        </div>
                    </div>
                </div>
            )}

            <Modal isOpen={showModal} centered>
                <ModalBody>
                    <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="close text-right ml-auto mb-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="customer-request.steps.connection.dont-have-contract" />
                    </h3>
                    {ZP ? (
                        <Trans
                            i18nKey="customer-request.steps.connection-zp.dont-have-contract-info"
                            defaults="<p>V prípade, ak ešte nemáte uzatvorenú zmluvu o pripojení, kontaktujte najskôr prevádzkovateľa distribučnej siete, SPP – distribúcia, a.s., a požiadajte ho o pripojenie. Až následne môžete podať Žiadosť o dodávku plynu od SPP.</p><br /><p>Viac informácií nájdete na <a href='https://www.spp-distribucia.sk' target='_blank'>webovej stránke distribútora</a>.</p>"
                            components={{ p: <p />, a: <a />, br: <br /> }}
                        />
                    ) : (
                        <Trans
                            i18nKey="customer-request.steps.connection.dont-have-contract-info"
                            defaults="<p>V prípade, ak ešte nemáte uzatvorenú zmluvu o pripojení, kontaktujte najskôr príslušného prevádzkovateľa distribučnej siete a požiadajte ho o pripojenie. Až následne môžete podať Žiadosť o dodávku elektriny od SPP.</p><p>Viac informácií nájdete na webových stránkach príslušného distribútora.</p>"
                            components={{ p: <p />, br: <br /> }}
                        />
                    )}
                </ModalBody>
            </Modal>

            {errors && (
                <div style={{ color: 'red' }} className="mt-2">
                    <ErrorMessage errors={errors} name={'type'} />
                </div>
            )}
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockDeliveryPointConnection;
