import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useCodeList from '../../../../../hooks/use-code-list';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { BusinessPartnerTypeEnum, KINDcodeHome, PaymentMethodCodeEnum } from '../../../../../models/enums';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties } from '../../../../../utils/utils';
import ChangePaymentMethodForm, { IPaymentMethodData } from '../../../../delivery-points/detail/data/forms/change-payment-method';
import { CodeListTypeEnum, CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';

const BlockPaymentMethodIbanBankTransferSipo: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();
    const { currentBlock, content, request, businessPartner, additionalData, partnersDeliveryPoints, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined = useSelector(
        (state: IRootState) => state.customerRequest.deliveryPoint,
    );

    const useAdditionalDataDeliveryPoint = additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.status === 'ACTIVE').length === 1;
    const showSipo =
        businessPartner?.kind?.code === KINDcodeHome ||
        content.accountInfo?.category === BusinessPartnerTypeEnum.PERSON ||
        !!content.businessPartner?.lastName;
    const [loadingIban, setLoadingIban] = useState<boolean>(false);
    const [newData, setNewData] = useState<IPaymentMethodData | undefined>({
        paymentType:
            content.paymentMethod?.paymentType?.code ??
            deliveryPoint?.contract?.contractAccount?.paymentType?.code ??
            (useAdditionalDataDeliveryPoint
                ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.contract?.contractAccount?.paymentType?.code
                : undefined),
        paymentTypeId:
            content.paymentMethod?.paymentTypeId ??
            deliveryPoint?.contract?.contractAccount?.paymentType?.uuid ??
            (useAdditionalDataDeliveryPoint
                ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.contract?.contractAccount?.paymentType?.uuid
                : undefined),
        iban:
            content.paymentMethod?.iban ??
            deliveryPoint?.contract?.contractAccount?.iban ??
            (useAdditionalDataDeliveryPoint
                ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.contract?.contractAccount?.iban
                : undefined),
        bicSwift: content.paymentMethod?.bicSwift,
        showSipo: showSipo,
        sipoNumber:
            content.paymentMethod?.sipoNumber ??
            deliveryPoint?.contract?.contractAccount?.sipo ??
            (useAdditionalDataDeliveryPoint
                ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.contract?.contractAccount?.sipo
                : undefined),
    });

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.PAYMENT_METHOD;

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId: string }
    >(
        async ({ contractId }) =>
            api.deliveryPoints
                .getByContractUuid(contractId, {}, { secure: true })
                .then((res) => res.data)
                .finally(() => setLoadingIban(false)),
        {
            onSuccess: (resp) => {
                if (resp) {
                    setNewData({
                        ...newData,
                        iban: resp.contract?.contractAccount?.iban,
                        sipoNumber: resp.contract?.contractAccount?.sipo,
                        paymentType: resp.contract?.contractAccount?.paymentType?.code,
                        paymentTypeId: resp.contract?.contractAccount?.paymentType?.uuid,
                    });
                }
            },
        },
    );

    useEffect(() => {
        if (!newData?.paymentTypeId || (!showSipo && content.paymentMethod?.paymentType?.code === PaymentMethodCodeEnum.SIPO)) {
            let contractId = undefined;
            //if businessPartner select contract id of selected businessPartner otherwise select first contract id - fetch data to get IBAN
            Object.entries((partnersDeliveryPoints as Record<string, SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[]>) ?? []).find(
                ([bpId, deliveryPoints]) => {
                    if (businessPartner && bpId !== businessPartner.id) {
                        return false;
                    }
                    return deliveryPoints.find((dps) =>
                        dps.deliveryPoints?.find((dp) => {
                            if (dp.status === 'ACTIVE' && dp.contract?.id) {
                                contractId = dp.contract?.id;
                                return true;
                            } else {
                                return false;
                            }
                        }),
                    );
                },
            );

            if (contractId) {
                setLoadingIban(true);
                mutateDeliveryPointByContractId({ contractId: contractId });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersDeliveryPoints]);

    const onSubmit = (data: IPaymentMethodData) => {
        const paymentType = paymentTypeCodeList?.find((item) => item.uuid === data.paymentTypeId);
        if (data.iban) {
            data.iban = data.iban.replace(/ /g, '').toUpperCase();
        }
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                ...clearObjectProperties(data),
                paymentType: paymentType,
            },
            saved: content.saveRequested ? true : undefined,
        };

        setNewData(data);
        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    return (
        <>
            {loadingIban && <LoadingIndicator />}
            <ChangePaymentMethodForm
                currentValue={{
                    showSipo: showSipo,
                    isUsedInCarbonFootprint: true,
                    paymentType: deliveryPoint?.contract?.contractAccount?.paymentType?.code,
                    paymentTypeId: deliveryPoint?.contract?.contractAccount?.paymentType?.code,
                    iban: content.paymentMethod?.iban || deliveryPoint?.contract?.contractAccount?.iban,
                    bicSwift: content.paymentMethod?.bicSwift,
                    sipoNumber: content.paymentMethod?.sipoNumber ?? deliveryPoint?.contract?.contractAccount?.sipo,
                    deliveryCategory: undefined,
                }}
                hideMessageAboutCurrentPaymentMethod={
                    customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_P ||
                    customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_US
                }
                requestType={request?.type}
                editedValue={newData}
                onSubmit={onSubmit}
                onReset={() => null}
            />
        </>
    );
};

export default BlockPaymentMethodIbanBankTransferSipo;
