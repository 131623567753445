import {
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse as DeliveryPointNotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingNotificationSettingRequest as NotificationSettingRequest,
    SkSppNzpBeApiNotificationSettingNotificationSettingResponse as NotificationSettingResponse,
    SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary,
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
} from '@spp/spp-meru-frontend-common/src/api';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import Checkbox from '../../../components/common/checkbox';
import { ClickableElement } from '../../../components/common/clickable-element';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { NotificationChannel, NotificationTemplateGroup } from '../../../models/model';
import { IRootState } from '../../../reducers';
import Routes from '../../../routes/routes';
import { formatBusinessPartnerName } from '../../../utils/utils';
import { NewsAndInformation } from '../login-details/components/news-and-information';
import DeliveryPointNotificationSettingCard from './delivery-point-notification-setting-card';

interface IDeliveryPointNotificationSettingCardProps {
    isExpanded: boolean;
    onToggle: (businessPartnerId?: string) => void;
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary;
}

const BusinessPartnerNotificationCard: React.FC<IDeliveryPointNotificationSettingCardProps> = ({ isExpanded, businessPartner, onToggle }) => {
    const { t } = useTranslation();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();

    const getDeliveryPointsId = (): string[] => {
        const result: string[] = [];
        businessPartner?.unitedDeliveryPoints?.forEach((udp) => udp.id && result.push(udp.id));
        return result;
    };

    const { isFetching: isFetchingSettings, refetch: fetchNotificationSettings, data: UnitedDeliveryPointNotificationSettings } = useQueryWithError<
        DeliveryPointNotificationSettingResponse[] | null
    >(
        ['delivery-point-notification-settings', businessPartner.id],
        async () => {
            if (loggedInCustomer?.id == null) return null;
            const dpsIds = getDeliveryPointsId();
            if (dpsIds.length) {
                return api.customers.getNotificationSettings(loggedInCustomer.id, dpsIds, { secure: true }).then((res) => res.data);
            }
            return null;
        },
        {
            enabled: false,
        },
    );

    useEffect(() => {
        isExpanded && fetchNotificationSettings();
    }, [isExpanded, fetchNotificationSettings]);

    const createSettingRequests = (notificationSetting: NotificationSettingResponse[]) =>
        notificationSetting.reduce<NotificationSettingRequest[]>(
            (acc, setting) =>
                setting.notificationTemplate?.code == null
                    ? acc
                    : acc.concat([
                          {
                              email: setting.email,
                              sms: setting.sms,
                              templateCode: setting.notificationTemplate?.code,
                          },
                      ]),
            [],
        );

    const [mutateNotificationSettings, { isLoading: isLoadingMutateNotificationSettings }] = useMutationWithError(
        async (notificationSettingData: { deliveryPointId: string; notificationSetting: NotificationSettingResponse[] }) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers.changeNotificationSettings(
                      loggedInCustomer.id,
                      {
                          unitedDeliveryPointIds: [notificationSettingData.deliveryPointId],
                          notificationSettings: createSettingRequests(notificationSettingData.notificationSetting),
                      },
                      { secure: true },
                  ),
        { onErrorWithGlobalErrorHandling: () => false, onSuccess: () => fetchNotificationSettings() },
    );

    const changeNotificationSetting = (
        notificationSetting?: NotificationSettingResponse[],
        unitedDeliveryPointNotificationSettings?: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
    ) => {
        if (unitedDeliveryPointNotificationSettings?.unitedDeliveryPoint?.id != null) {
            mutateNotificationSettings({
                deliveryPointId: unitedDeliveryPointNotificationSettings?.unitedDeliveryPoint?.id,
                notificationSetting: notificationSetting || [],
            });
        }
    };

    const notificationTemplateGroups = useCallback(
        (unitedDeliveryPointNotificationSettings?: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse) => {
            const groupSet: Set<NotificationTemplateGroup> = new Set();
            const udp = unitedDeliveryPointNotificationSettings?.unitedDeliveryPoint;
            const notificationSettings = unitedDeliveryPointNotificationSettings?.notificationSettings;

            notificationSettings?.forEach((nt) => {
                const group = nt.notificationTemplate?.group;
                if (group == null) {
                    return;
                }
                if (group === 'LIMIT_OVERFLOW') {
                    if (udp?.deliveryPoints?.every((x) => !x.hasLimit)) {
                        return;
                    }
                }
                groupSet.add(group);
            });
            return Array.from(groupSet);
        },
        [],
    );

    //return 0 - each setting = FALSE,
    //return 1 - each setting = TRUE,
    //return 2 - settings contain either true or false
    const isActive = (
        group: NotificationTemplateGroup,
        channel: NotificationChannel,
        unitedDeliveryPointNotificationSettings?: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
    ): 0 | 1 | 2 => {
        const groupSettings = unitedDeliveryPointNotificationSettings?.notificationSettings?.filter(
            (setting) => setting.notificationTemplate?.group === group,
        );
        const isTrueIncluded = groupSettings?.find((setting) => (channel === 'EMAIL' ? setting.email === true : setting.sms === true));
        const isFalseIncluded = groupSettings?.find((setting) => (channel === 'EMAIL' ? setting.email === false : setting.sms === false));
        if (isTrueIncluded && isFalseIncluded) {
            return 2;
        } else {
            if (groupSettings != null && groupSettings.every((setting) => (channel === 'EMAIL' ? setting.email === true : setting.sms === true))) {
                return 1;
            } else {
                return 0;
            }
        }
    };

    //return 0 - each setting = FALSE,
    //return 1 - each setting = TRUE,
    //return 2 - settings contain either true or false
    const areAllNotificationsActive = (
        channel: NotificationChannel,
        unitedDeliveryPointNotificationSettings?: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
    ): 0 | 1 | 2 => {
        const resultByGroups: number[] = [];
        notificationTemplateGroups(unitedDeliveryPointNotificationSettings).forEach((group) => {
            resultByGroups.push(isActive(group, channel, unitedDeliveryPointNotificationSettings));
        });
        return resultByGroups.includes(0) ? 0 : resultByGroups.includes(2) ? 2 : 1;
    };

    const areAllNotificationsActiveEmail = () => {
        const result = UnitedDeliveryPointNotificationSettings?.map((udp) => areAllNotificationsActive('EMAIL', udp));
        return result?.includes(0) ? 0 : result?.includes(2) ? 2 : 1;
    };

    const areAllNotificationsActiveSms = () => {
        const result = UnitedDeliveryPointNotificationSettings?.map((udp) => areAllNotificationsActive('SMS', udp));
        return result?.includes(0) ? 0 : result?.includes(2) ? 2 : 1;
    };

    const isAllEmailCheckBoxChecked = areAllNotificationsActiveEmail() === 0 ? false : true;
    const isAllSmsCheckBoxChecked = areAllNotificationsActiveSms() === 0 ? false : true;

    const toggleAllNotifications = (channel: NotificationChannel, action: 'enable' | 'disable') => () => {
        UnitedDeliveryPointNotificationSettings?.map((udp) => {
            const isAllActive = action === 'enable' ? false : true;
            const replaceValue = (setting: NotificationSettingResponse) =>
                channel === 'EMAIL' ? { ...setting, email: !isAllActive } : { ...setting, sms: !isAllActive };

            const newSettings = udp.notificationSettings?.map((setting) =>
                setting.notificationTemplate?.group == null ? setting : replaceValue(setting),
            );
            changeNotificationSetting(newSettings, udp);
        });
    };

    return (
        <Card key={businessPartner.id} className="my-4">
            <CardHeader className="bg-white">
                <ClickableElement onClick={() => onToggle(isExpanded ? undefined : businessPartner.id)} className="w-100 position-relative">
                    <small className="text-secondary">{t('common.customer')}</small>
                    <p className="small font-weight-bold d-flex mb-0">
                        {formatBusinessPartnerName(businessPartner)}
                        <i
                            className={`la-xs text-secondary ml-auto my-auto smooth-transition notification-card-arrow ${
                                isExpanded ? 'icon-chevron-top-16' : 'icon-chevron-down-16'
                            }`}
                        ></i>
                    </p>
                </ClickableElement>
            </CardHeader>
            <Collapse isOpen={isExpanded}>
                <CardBody className={classNames('py-2', { 'business-partner-notification-card-body': !isFetchingSettings })}>
                    {isFetchingSettings || isLoadingMutateNotificationSettings ? (
                        <LoadingIndicator />
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td className="w-50"></td>
                                        <td className="text-center w-25" scope="col">
                                            <span className="mr-2">
                                                <Trans i18nKey="settings.notifications.email" />
                                            </span>
                                            <Checkbox
                                                checked={isAllEmailCheckBoxChecked}
                                                onChange={toggleAllNotifications('EMAIL', isAllEmailCheckBoxChecked ? 'disable' : 'enable')}
                                                id={`toggleAllEmailNotifications_dp${businessPartner.id}`}
                                                name=""
                                                label="&nbsp;"
                                                className={classNames('mt-2', {
                                                    'checkbox-active-and-inactive': areAllNotificationsActiveEmail() === 2,
                                                })}
                                            />
                                        </td>
                                        <td className="text-center w-25" scope="col">
                                            <span className="mr-3">
                                                <Trans i18nKey="settings.notifications.sms" />
                                            </span>
                                            <Checkbox
                                                checked={isAllSmsCheckBoxChecked}
                                                onChange={toggleAllNotifications('SMS', isAllSmsCheckBoxChecked ? 'disable' : 'enable')}
                                                id={`toggleAllSMSNotification_dp${businessPartner.id}`}
                                                name=""
                                                label="&nbsp;"
                                                className={classNames('mt-2', {
                                                    'checkbox-active-and-inactive': areAllNotificationsActiveSms() === 2,
                                                })}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    {UnitedDeliveryPointNotificationSettings?.map((dpSetting, index) => (
                        <DeliveryPointNotificationSettingCard
                            key={index}
                            deliveryPointNotificationSetting={dpSetting}
                            fetchNotificationSettings={fetchNotificationSettings}
                        />
                    ))}
                </CardBody>
                {!isFetchingSettings && businessPartner.queue === 'COLLECTIVE' && (
                    <NewsAndInformation link={`${Routes.MARKETING_CONSENTS}/${businessPartner.id}`} />
                )}
            </Collapse>
        </Card>
    );
};
export default BusinessPartnerNotificationCard;
