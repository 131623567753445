import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEeConnectionDetail } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IEeConnectionDetail;
    bold?: boolean;
};

const EeConnectionDetailItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { mainCircuitBreakerValue, circuitPhaseCount } = data;

    return (
        <>
            <ItemPreview>
                {mainCircuitBreakerValue && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.connection-electricity.value-main-breaker')} />
                        <ItemPreviewContent bold={bold}>{mainCircuitBreakerValue}</ItemPreviewContent>
                    </>
                )}
                {circuitPhaseCount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.connection-electricity.phases-count')} />
                        <ItemPreviewContent bold={bold}>{circuitPhaseCount}</ItemPreviewContent>
                    </>
                )}
            </ItemPreview>
        </>
    );
};

export default EeConnectionDetailItem;
