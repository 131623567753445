import { SkSppNzpBeApiCustomerprofileAddress } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import AddressFields from '../../views/delivery-points/detail/data/contact-data-modals/component/address-fields';

interface ICustomAddressModalProps {
    onFormSubmit: (data: SkSppNzpBeApiCustomerprofileAddress) => void;
    closeModal: () => void;
    address?: SkSppNzpBeApiCustomerprofileAddress;
}

export const CustomAddressModal: React.FC<ICustomAddressModalProps> = ({ closeModal, onFormSubmit, address }) => {
    const { register, errors, trigger, watch, setValue, handleSubmit } = useForm({
        defaultValues: { address },
    });
    const [t] = useTranslation();

    const onSubmit = (data: { address: SkSppNzpBeApiCustomerprofileAddress }) => {
        onFormSubmit(data.address);
    };
    return (
        <Modal isOpen centered>
            <ModalBody>
                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>

                <h3 className="modal-title mb-4">
                    <Trans i18nKey="customer-request.steps.custom-address-modal.title" />
                </h3>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <AddressFields
                        register={register}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        objectName="address."
                        address={address}
                        emptyCountry={!address?.country}
                    />
                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-auto mb-3 mb-md-0" style={{ minWidth: 233 }}>
                            <button type="button" className="btn btn-block btn-secondary" onClick={closeModal}>
                                {t('customer-request.steps.custom-address-modal.cancel')}
                            </button>
                        </div>
                        <div className="col-md-auto" style={{ minWidth: 233 }}>
                            <button type="submit" className="btn btn-block btn-primary">
                                {t('customer-request.steps.custom-address-modal.insert')}
                            </button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
