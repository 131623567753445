import { SkSppNzpBeApiCustomerrequestSuspiciousActivity as SuspiciousActivity } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { IApiResponse } from '../../../main';
import { IRootState } from '../../../reducers';
import Routes from '../../../routes/routes';
import ReportIssueForm from './report-issue-form';
import ReportIssueHeader from './report-issue-header';

type StateType = {
    fromUrl?: string;
};

const ReportIssueStep: React.FC = () => {
    const location = useLocation<StateType>();
    const history = useHistory();
    const query = useUrlQuery();

    const api = useApi();

    const { customer } = useSelector((state: IRootState) => state.user);
    const [notificationId, setNotificationId] = useState<string>();

    const fromUrl = location.state?.fromUrl;

    useEffect(() => {
        const notificationUuid = query.get('notificationUuid');

        if (!!notificationUuid) {
            setNotificationId(notificationUuid);
        } else {
            history.push(Routes.HOME);
        }
    }, [query, history, fromUrl]);

    const navigateToNextScreen = () => {
        query.set('regform', 'submitted');
        history.push(location.pathname + '?' + query.toString());
    };

    const [reportSuspiciousActivity] = useMutationWithError<unknown, IApiResponse, { data: SuspiciousActivity; reCaptchaCode: string | null }>(
        async (variables) =>
            api.customers.reportSuspiciousActivity(variables.data, {
                headers: variables.reCaptchaCode ? { 'X-GoogleReCaptcha-Code': variables.reCaptchaCode } : {},
                secure: true,
            }),
        {
            onErrorWithGlobalErrorHandling: () => {
                return false;
            },
        },
    );

    const onFormSubmit = async (values: SuspiciousActivity, reCaptchaCode: string | null) => {
        const data: SuspiciousActivity = { ...values, notificationUuid: notificationId };
        reportSuspiciousActivity({ data, reCaptchaCode }, { onSuccess: () => navigateToNextScreen() });
    };

    return (
        <Col>
            <ReportIssueHeader />
            <ReportIssueForm
                onFormSubmit={onFormSubmit}
                customerData={{ email: customer?.email, firstName: customer?.firstName, lastName: customer?.lastName, phone: customer?.phone }}
            />
        </Col>
    );
};

export default ReportIssueStep;
