import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
    invoiceNumbers: string[];
};

const EpayMethodHeader: React.FC<Props> = ({ invoiceNumbers }) => {
    return (
        <>
            <Trans i18nKey="invoices.e-pay.header-method" count={invoiceNumbers.length}>
                Úhrada faktúry
            </Trans>
        </>
    );
};

export default EpayMethodHeader;
