import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import BaseRadio from '../../../../../../components/common/base-radio';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { formatAddress, formatBusinessPartnerName } from '../../../../../../utils/utils';

interface IDeliveryPointList extends DefaultFormType {
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    deliveryPoints: UnitedDeliveryPointSummary[];
    setSelectedDP?: (val: SkSppNzpBeApiCustomerprofileDeliveryPointSummary) => void;
    onlyEE?: boolean;
}

export const DeliveryPointList: React.FC<IDeliveryPointList> = ({ businessPartner, deliveryPoints, register, setSelectedDP, onlyEE }) => {
    const { t } = useTranslation();
    const { formRules } = useFormRules();

    return (
        <div className="my-4">
            <p className="font-weight-bold d-flex my-2">
                <div>
                    {formatBusinessPartnerName(businessPartner)}
                    <br />
                    <small className="text-secondary">
                        {t('delivery-point.customer-number')} {businessPartner.externalId}
                    </small>
                </div>
            </p>
            {deliveryPoints.map((item) => (
                <>
                    {item.deliveryPoints
                        ?.filter((dp) => (onlyEE ? dp.type === 'EE' : true))
                        .map((dp) => (
                            <Card key={`${businessPartner.externalId}_${dp.externalId}`} className="mb-3">
                                <CardBody className="p-3">
                                    <BaseRadio
                                        ref={register(formRules.required)}
                                        value={dp.id}
                                        id={`businessPartnerDeliveryPointsRadio${dp.externalId}`}
                                        onChange={() => setSelectedDP && setSelectedDP(dp)}
                                        name="deliveryPointId"
                                        className="mt-0"
                                        label={
                                            <>
                                                <b>{`${formatAddress(dp.address)} · ${t(`enums.CommodityEnum.${dp.type}`)}`}</b>
                                                <br />
                                                <small className="text-secondary">
                                                    {t('delivery-point.contract-number', {
                                                        number: dp.contract?.externalId,
                                                    })}
                                                </small>
                                            </>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        ))}
                </>
            ))}
        </div>
    );
};
