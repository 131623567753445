import { SkSppNzpBeApiCustomerrequestSuspiciousActivity as SuspiciousActivity } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import BaseButton from '../../../components/common/base-button';
import BaseTextarea from '../../../components/common/base-textarea';
import Input from '../../../components/common/input';
import { TooltipWrapper } from '../../../components/common/tooltip-wrapper';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../models/model';
import { formatPhoneNumber } from '../../../utils/utils';

type CustomerDataPresets = {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
};

type Props = {
    onFormSubmit: (data: SuspiciousActivity, reCaptchaCode: string | null) => void;
    customerData: CustomerDataPresets;
};

const ReportIssueForm: React.FC<Props> = ({ onFormSubmit, customerData }) => {
    const api = useApi();
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, setError, errors, trigger } = useForm({ defaultValues: { ...customerData } });
    const { formRules, requiredTrimed, phoneRules } = useFormRules();

    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const [mutatePhoneValidate] = useMutationWithError<unknown, IApiResponse, { phone: string }>(
        async ({ phone }) => api.validations.checkPasswordComplexityWithPhone({ phone }),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                if (response.error) {
                    setError('phone', {
                        message: t('common.input-rules.phoneNumber'),
                    });
                    return true;
                }
                return false;
            },
        },
    );

    const onSubmit = (data: SuspiciousActivity): void => {
        // do validation of the phone number (phone)
        if (data.phone) data.phone = formatPhoneNumber(data.phone);

        const reCaptchaCode = recaptchaRef?.current?.getValue() || null;
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !reCaptchaCode) {
            setReCaptchaError(true);
            return;
        }

        mutatePhoneValidate({ phone: data.phone }).then((result) => {
            if (!!result) {
                onFormSubmit(data, reCaptchaCode);
                process.env.REACT_APP_RECAPTCHA === 'true' && recaptchaRef?.current?.reset();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.validName })}
                errors={errors}
                type="text"
                name="firstName"
                label={<Trans i18nKey="common.name" />}
            />
            <Input
                trigger={trigger}
                ref={register({ ...formRules.validName })}
                errors={errors}
                type="text"
                name="lastName"
                label={<Trans i18nKey="common.last-name-not-required" />}
            />
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.email })}
                errors={errors}
                type="text"
                name="email"
                label={<Trans i18nKey="common.email" />}
                autoCapitalize="off"
                autoCorrect="off"
            />
            <TooltipWrapper id="phoneNumber" tooltipContent={t('common.input-rules.phone-number-info')}>
                <Input
                    id="phoneNumber"
                    trigger={trigger}
                    ref={register({ ...formRules.required, ...phoneRules })}
                    errors={errors}
                    type="text"
                    name="phone"
                    label={<Trans i18nKey="common.phone-number" />}
                />
            </TooltipWrapper>

            <BaseTextarea
                ref={register(requiredTrimed)}
                errors={errors}
                rows={5}
                name="description"
                label={<Trans i18nKey="report-issue.problem-descriptio-required" />}
            />

            <div style={{ display: process.env.REACT_APP_RECAPTCHA === 'true' ? 'block' : 'none' }}>
                <ReCAPTCHA
                    key={key}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA === 'true' ? recaptchaSiteKey : 'undefined'}
                    onChange={() => setReCaptchaError(false)}
                    hl={i18n.language === 'en' ? 'en' : 'sk'}
                />

                {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
            </div>

            <BaseButton type="submit">
                <Trans i18nKey="report-issue.continue" />
            </BaseButton>
        </form>
    );
};

export default ReportIssueForm;
