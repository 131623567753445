import BlockAddressSupply from '../screen/steps-customer-request/block-address/block-address -supply';
import BlockAdvancePayment from '../screen/steps-customer-request/block-advance-payment/block-advance-payment';
import BlockAdvancePaymentSingle from '../screen/steps-customer-request/block-advance-payment/block-advance-payment-single';
import BlockAssumedConsumption from '../screen/steps-customer-request/block-assumed-consumption/block-assumed-consumption';
import BlockAssumedConsumptionZp from '../screen/steps-customer-request/block-assumed-consumption/block-assumed-consumption-zp';
import AttachmentsShort from '../screen/steps-customer-request/block-attachments-short/block-attachments-short';
import BlockBankConnectionList from '../screen/steps-customer-request/block-bank-connection-list/block-bank-connection-list';
import BlockBusinessPartnerAmountLiabilitiesShort from '../screen/steps-customer-request/block-business-partner/block-business-partner-amount-liabilities-short';
import BlockBusinessPartnerCleanElectricityShort from '../screen/steps-customer-request/block-business-partner/block-business-partner-clean-electricity-short';
import BlockBusinessPartnerExtended from '../screen/steps-customer-request/block-business-partner/block-business-partner-extended';
import BlockCarbonFootprintProductLevel from '../screen/steps-customer-request/block-carbon-footprint-product-level/block-carbon-footprint-product-level';
import BlockComplaintShort from '../screen/steps-customer-request/block-complaint/block-complaint-short';
import BlockCompletionCarbonFootprint from '../screen/steps-customer-request/block-completion/block-completion-carbon-footprint';
import { BlockCompletionClaimInvoice } from '../screen/steps-customer-request/block-completion/block-completion-claim-invoice';
import BlockCompletionClearElectricity from '../screen/steps-customer-request/block-completion/block-completion-clear-electricity';
import { BlockCompletionConfirmationAmountLiabilities } from '../screen/steps-customer-request/block-completion/block-completion-confirmation-amount-liabilities';
import { BlockCompletionInvoiceOutOfCycleShort } from '../screen/steps-customer-request/block-completion/block-completion-invoice-out-of-cycle-short';
import { BlockCompletionRateChange } from '../screen/steps-customer-request/block-completion/block-completion-rate-and-tariff-change';
import { BlockCompletionRepaymentAgreement } from '../screen/steps-customer-request/block-completion/block-completion-repayment-agreement';
import { BlockCompletionInterruptionConsumptionShort } from '../screen/steps-customer-request/block-completion/block-completion-request-Interruption-consumption-short';
import { BlockCompletionRequestOfficialTestMeterShort } from '../screen/steps-customer-request/block-completion/block-completion-request-official-test-meter-short';
import { BlockCompletionSupplyEE } from '../screen/steps-customer-request/block-completion/block-completion-supply-ee';
import { BlockCompletionSupplyZP } from '../screen/steps-customer-request/block-completion/block-completion-supply-zp';
import { BlockCompletionTerminationContract } from '../screen/steps-customer-request/block-completion/block-completion-termination-contract';
import { BlockCompletionTranscript } from '../screen/steps-customer-request/block-completion/block-completion-transcript';
import { BlockCompletionTranscriptNewCustomer } from '../screen/steps-customer-request/block-completion/block-completion-transcript-new-customer';
import BlockContractDelivery from '../screen/steps-customer-request/block-contract-delivery/block-contract-delivery';
import BlockContractTerminationReasonShort from '../screen/steps-customer-request/block-contract-termination-reason-short/block-contract-termination-reason-short';
import BlockDate from '../screen/steps-customer-request/block-date/block-date';
import BlockDeliveryPointConnection from '../screen/steps-customer-request/block-delivery-point-ee-connection/block-delivery-point-connection';
import BlockDeliveryPointAmountLiabilitiesShort from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-amount-liabilities-short';
import BlockDeliveryPointConfirmationContractualRelationshipShort from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-confirmation-contractual-relationship-short';
import BlockDeliveryPointInterruptionConsumption from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-interruption-consumption';
import BlockDeliveryPointInvoiceOutOfCycle from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-invoice-out-of-cycle';
import BlockDeliveryPointShort from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-short';
import BlockDeliveryPointTerminateContract from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-terminate-contract';
import BlockDeliveryPointTranscriptNew from '../screen/steps-customer-request/block-delivery-point/block-delivery-point-transcript-new';
import BlockInvoiceDeliveryOnlyEmail from '../screen/steps-customer-request/block-invoice-delivery/block-invoice-delivery-only-email';
import BlockInvoiceDeliveryOnlyEmailCleanEnergy from '../screen/steps-customer-request/block-invoice-delivery/block-invoice-delivery-only-email-clean-energy';
import BlockInvoiceDeliveryOnlyEmailTerminationContract from '../screen/steps-customer-request/block-invoice-delivery/block-invoice-delivery-only-email-termination-contract';
import BlockInvoiceDeliveryTranscript from '../screen/steps-customer-request/block-invoice-delivery/block-invoice-delivery-transcript';
import BlockInvoiceOutOfCycleData from '../screen/steps-customer-request/block-invoice-out-of-cycle-data/block-invoice-out-of-cycle-data';
import BlockMeterNumber from '../screen/steps-customer-request/block-meter-number/block-meter-number';
import BlockMeterReadingConfirm from '../screen/steps-customer-request/block-meter-reading-transcript/block-meter-reading-confirm';
import BlockMeterReadingTranscript from '../screen/steps-customer-request/block-meter-reading-transcript/block-meter-reading-transcript';
import BlockMeterReadingZpShort from '../screen/steps-customer-request/block-meter-reading/block-meter-reading-zp-short';
import BlockNewCustomerEmail from '../screen/steps-customer-request/block-new-customer-email/block-new-customer-email';
import BlockNumberOfInstallments from '../screen/steps-customer-request/block-number-of-installments/block-number-of-installments';
import BlockOwnerData from '../screen/steps-customer-request/block-owner-data/block-owner-data';
import BlockPaymentMethodIbanBankTransferSipo from '../screen/steps-customer-request/block-payment-method/block-payment-method-iban-bank-transfer-sipo';
import BlockPaymentPeriodCarbonFootprint from '../screen/steps-customer-request/block-payment-period/block-payment-period-carbon-footprint';
import BlockPaymentPeriodCleanElectricity from '../screen/steps-customer-request/block-payment-period/block-payment-period-clean-electricity';
import BlockProblemDescriptionDetailShort from '../screen/steps-customer-request/block-problem-description/block-problem-description-detail-short';
import BlockProblemDescriptionShort from '../screen/steps-customer-request/block-problem-description/block-problem-description-short';
import { BlockRateSelectionShort } from '../screen/steps-customer-request/block-rate-selection/block-rate-selection-short';
import BlockRateSelectionWithDistributionArea from '../screen/steps-customer-request/block-rate-selection/block-rate-selection-with-distribution-area';
import { BlockRateSelectionTranscript } from '../screen/steps-customer-request/block-rate-selection/block-rate-transcript';
import BlockReason from '../screen/steps-customer-request/block-reason/block-reason';
import BlockSelectDPorBP from '../screen/steps-customer-request/block-select-dp-or-bp/block-select-dp-or-bp';
import BlockStatement from '../screen/steps-customer-request/block-statement/block-statement';
import BlockSupplier from '../screen/steps-customer-request/block-supplier/block-supplier';
import BlockTariff from '../screen/steps-customer-request/block-tariff/block-tariff';
import { BlockTariffSelectionShort } from '../screen/steps-customer-request/block-tariff/block-tariff-short';
import { BlockTariffSelectionTranscript } from '../screen/steps-customer-request/block-tariff/block-tariff-transcript';
import BlockUnpaidInvoices from '../screen/steps-customer-request/block-unpaid-invoices/block-unpaid-invoices';
import { CustomerRequestBlockTitleEnum, CustomerRequestDataEnum, CustomerRequestEnum, CustomerRequestTypeEnum } from './enums';
import { CustomerRequestBlockType, CustomerRequestStepType } from './types';

/**Ziadost 3c - Zmena sadzby elektriny.pdf */
const changeElectricityRateShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CHANGE_ELECTRICITY_RATE_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZSE,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_RATE_SELECTION,
            dataKey: CustomerRequestDataEnum.TARIFF_EE,
            component: BlockRateSelectionShort,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionRateChange,
            params: {
                ee: true,
            },
        },
    ],
};

const changeGasTariffShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CHANGE_GAS_TARIFF_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZTP,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_TARIFF_CHOICE,
            dataKey: CustomerRequestDataEnum.TARIFF_ZP,
            component: BlockTariffSelectionShort,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_GAS_CONDITION,
            dataKey: CustomerRequestDataEnum.METER_READING_ZP,
            component: BlockMeterReadingZpShort,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionRateChange,
            params: {
                zp: true,
            },
        },
    ],
};

const claimInvoiceBlocks: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.REASON_FOR_CLAIM,
        component: BlockProblemDescriptionShort,
    },
    {
        key: CustomerRequestBlockTitleEnum.REASON_FOR_CLAIM_DETAIL,
        component: BlockProblemDescriptionDetailShort,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionClaimInvoice,
    },
];

const claimInvoiceWithDPBlocks: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.REASON_FOR_CLAIM,
        component: BlockProblemDescriptionShort,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT,
        dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
        component: BlockDeliveryPointShort,
    },
    {
        key: CustomerRequestBlockTitleEnum.REASON_FOR_CLAIM_DETAIL,
        component: BlockProblemDescriptionDetailShort,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionClaimInvoice,
    },
];

/**Ziadost 6b - Reklamacia faktury.pdf */
const claimInvoiceShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CLAIM_INVOICE_SHORT,
    type: CustomerRequestTypeEnum.ZOM_RF,
    skipIntro: true,
    blocks: claimInvoiceBlocks,
};

/**Ziadost 7 - Staznost short*/
const complaintShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.COMPLAINT_SHORT,
    type: CustomerRequestTypeEnum.ZOP_P,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLAINT,
            dataKey: CustomerRequestDataEnum.COMPLAINT,
            component: BlockComplaintShort,
        },
    ],
};

const transcriptNew: CustomerRequestStepType = {
    key: CustomerRequestEnum.TRANSCRIPT_NEW,
    type: CustomerRequestTypeEnum.ZOM_P,
    skipIntro: true,
    requiredPartnerDeliveryPoints: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT,
            dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
            component: BlockDeliveryPointTranscriptNew,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_METER_READING_TRANSCRIPT,
            dataKey: CustomerRequestDataEnum.METER_READINGS,
            component: BlockMeterReadingTranscript,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_INVOICE_DELIVERY,
            dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
            component: BlockInvoiceDeliveryOnlyEmail,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_BANK_CONNECTION,
            dataKey: CustomerRequestDataEnum.BANK_CONNECTION,
            component: BlockBankConnectionList,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_NEW_CUSTOMER,
            component: BlockNewCustomerEmail,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionTranscript,
        },
    ],
};

const blocksTranscriptNewCustomerStart: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_METER_CONFIRM,
        component: BlockMeterReadingConfirm,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_NEW_CUSTOMER_BP,
        component: BlockBusinessPartnerExtended,
    },
];

const blocksTranscriptNewCustomerEnd: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ADVANCE_PAYMENTS,
        dataKey: CustomerRequestDataEnum.ADVANCE_PAYMENT,
        component: BlockAdvancePayment,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_PAYMENT_METHOD,
        dataKey: CustomerRequestDataEnum.PAYMENT_METHOD,
        component: BlockPaymentMethodIbanBankTransferSipo,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_NEW_CUSTOMER_INVOICE_DELIVERY,
        dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
        component: BlockInvoiceDeliveryTranscript,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionTranscriptNewCustomer,
    },
];

const blocksTranscriptNewCustomer: CustomerRequestBlockType[] = [
    ...blocksTranscriptNewCustomerStart,
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TARIFF_CHOICE,
        dataKey: CustomerRequestDataEnum.TARIFFS,
        component: BlockTariffSelectionTranscript,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_RATE_CHOICE,
        dataKey: CustomerRequestDataEnum.RATES,
        component: BlockRateSelectionTranscript,
    },
    ...blocksTranscriptNewCustomerEnd,
];

const transcriptNewCustomer: CustomerRequestStepType = {
    key: CustomerRequestEnum.TRANSCRIPT_NEW_CUSTOMER,
    type: CustomerRequestTypeEnum.ZOM_P,
    skipIntro: true,
    requiredPartnerDeliveryPoints: true,
    blocks: blocksTranscriptNewCustomer,
};

const blocksTranscriptNewCustomerOnlyZP: CustomerRequestBlockType[] = [
    ...blocksTranscriptNewCustomerStart,
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TARIFF_CHOICE,
        dataKey: CustomerRequestDataEnum.TARIFFS,
        component: BlockTariffSelectionTranscript,
    },
    ...blocksTranscriptNewCustomerEnd,
];

const blocksTranscriptNewCustomerOnlyEE: CustomerRequestBlockType[] = [
    ...blocksTranscriptNewCustomerStart,
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_RATE_CHOICE,
        dataKey: CustomerRequestDataEnum.RATES,
        component: BlockRateSelectionTranscript,
    },
    ...blocksTranscriptNewCustomerEnd,
];

const blocksTranscriptNewCustomerWithoutRateAndTariff: CustomerRequestBlockType[] = [
    ...blocksTranscriptNewCustomerStart,
    ...blocksTranscriptNewCustomerEnd,
];

const blocksEeSupplyStart: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ADDRESS_SUPPLY,
        dataKey: CustomerRequestDataEnum.NEW_DELIVERY_POINT_ADDRESS,
        component: BlockAddressSupply,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONNECTION,
        dataKey: CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_EE,
        component: BlockDeliveryPointConnection,
    },
];

const blocksEeSupplyMigration: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CHANGE_SUPPLIER,
        dataKey: CustomerRequestDataEnum.SUPPLIER,
        component: BlockSupplier,
    },
];

const blocksEeSupplyNew: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_OWNER_OBJECT,
        dataKeys: [
            CustomerRequestDataEnum.OWNER,
            CustomerRequestDataEnum.OWNER_ADDRESS,
            CustomerRequestDataEnum.OWNER_CORRESPONDANCE_ADDRESS,
            CustomerRequestDataEnum.OWNER_CONTACT,
            CustomerRequestDataEnum.OWNER_ATTACHMENTS,
        ],
        component: BlockOwnerData,
    },
];

const blocksEeSupplyEnd: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CUSTOMER,
        component: BlockBusinessPartnerExtended,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONSUMPTION,
        dataKey: CustomerRequestDataEnum.ASSUMED_CONSUMPTION,
        component: BlockAssumedConsumption,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_RATE_SELECTION,
        dataKey: CustomerRequestDataEnum.TARIFF_EE,
        component: BlockRateSelectionWithDistributionArea,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ADVANCE_PAYMENT,
        dataKey: CustomerRequestDataEnum.ADVANCE_PAYMENT,
        component: BlockAdvancePaymentSingle,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_PAYMENT_METHOD,
        dataKey: CustomerRequestDataEnum.PAYMENT_METHOD,
        component: BlockPaymentMethodIbanBankTransferSipo,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_INVOICE_DELIVERY_ZP_EE,
        dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
        component: BlockInvoiceDeliveryTranscript,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONTRACT_DELIVERY,
        dataKey: CustomerRequestDataEnum.CONTRACT_DELIVERY,
        component: BlockContractDelivery,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION_ORDER,
        component: BlockCompletionSupplyEE,
    },
];

const blocksEeSupplyNewConnection: CustomerRequestBlockType[] = [...blocksEeSupplyStart, ...blocksEeSupplyNew, ...blocksEeSupplyEnd];
const blocksEeSupplyMigrationConnection: CustomerRequestBlockType[] = [...blocksEeSupplyStart, ...blocksEeSupplyMigration, ...blocksEeSupplyEnd];

const eeSupplyNew: CustomerRequestStepType = {
    key: CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW,
    type: CustomerRequestTypeEnum.ZOM_ZODE,
    skipIntro: true,
    blocks: blocksEeSupplyMigrationConnection,
    requiredPartnerDeliveryPoints: true,
};

const blocksZpSupplyStart: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ADDRESS_SUPPLY_ZP,
        dataKey: CustomerRequestDataEnum.NEW_DELIVERY_POINT_ADDRESS,
        component: BlockAddressSupply,
        params: {
            zp: true,
        },
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONNECTION_ZP,
        dataKey: CustomerRequestDataEnum.DELIVERY_POINT_CONNECTION_ZP,
        component: BlockDeliveryPointConnection,
        params: {
            zp: true,
        },
    },
];

const blocksZpSupplyMigration: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CHANGE_SUPPLIER_ZP,
        dataKey: CustomerRequestDataEnum.SUPPLIER,
        component: BlockSupplier,
        params: {
            zp: true,
        },
    },
];

const blocksZpSupplyEnd: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CUSTOMER,
        component: BlockBusinessPartnerExtended,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONSUMPTION,
        dataKey: CustomerRequestDataEnum.ASSUMED_CONSUMPTION,
        component: BlockAssumedConsumptionZp,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TARIFF_CHOICE,
        dataKey: CustomerRequestDataEnum.TARIFF_ZP,
        component: BlockTariff,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ADVANCE_PAYMENT,
        dataKey: CustomerRequestDataEnum.ADVANCE_PAYMENT,
        component: BlockAdvancePaymentSingle,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_PAYMENT_METHOD,
        dataKey: CustomerRequestDataEnum.PAYMENT_METHOD,
        component: BlockPaymentMethodIbanBankTransferSipo,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_INVOICE_DELIVERY_ZP_EE,
        dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
        component: BlockInvoiceDeliveryTranscript,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_CONTRACT_DELIVERY,
        dataKey: CustomerRequestDataEnum.CONTRACT_DELIVERY,
        component: BlockContractDelivery,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION_ORDER,
        component: BlockCompletionSupplyZP,
    },
];

const blocksZpSupplyNewConnection: CustomerRequestBlockType[] = [...blocksZpSupplyStart, ...blocksZpSupplyEnd];
const blocksZpSupplyMigrationConnection: CustomerRequestBlockType[] = [...blocksZpSupplyStart, ...blocksZpSupplyMigration, ...blocksZpSupplyEnd];

const zpSupplyNew: CustomerRequestStepType = {
    key: CustomerRequestEnum.GAS_SUPPLY_NEW,
    type: CustomerRequestTypeEnum.ZOM_ZODP,
    skipIntro: true,
    blocks: blocksZpSupplyMigrationConnection,
    requiredPartnerDeliveryPoints: true,
};

/**Ziadost 12 - Ziadost o prerusenie a obnovu odberu.pdf */
const requestInteruptionAndResumptionConsuptionShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.REQUEST_INTERRUPTION_AND_RESUMPTION_CONSUPTION_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZOPAOO,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_INTERAPTION_DP,
            dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
            component: BlockDeliveryPointInterruptionConsumption,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_INTERAPTION_DATE,
            dataKey: CustomerRequestDataEnum.DATE,
            params: {
                minDate: true,
                phoneNumber: true,
            },
            component: BlockDate,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionInterruptionConsumptionShort,
        },
    ],
};

const blocksOfficialTestMeterWithoutMeterNumber: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_STATEMENT,
        dataKey: CustomerRequestDataEnum.STATEMENT,
        component: BlockStatement,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionRequestOfficialTestMeterShort,
    },
];

/**Ziadost 13 - Ziadost o uradnu skusku meradla.pdf */
const requestOfficialTestMeterShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.REQUEST_OFFICIAL_TEST_METER_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZOUSM,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_METER_NUMBER,
            dataKey: CustomerRequestDataEnum.METER_NUMBER,
            component: BlockMeterNumber,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_STATEMENT,
            dataKey: CustomerRequestDataEnum.STATEMENT,
            component: BlockStatement,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionRequestOfficialTestMeterShort,
        },
    ],
};

/**Ziadost 14 - Ziadost o fakturu mimo cyklu.pdf */
const requestInvoiceOutCycleShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.REQUEST_INVOICE_OUT_CYCLE_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZOFMC,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT_INVOICE_OUT_OF_CYCLE,
            dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
            component: BlockDeliveryPointInvoiceOutOfCycle,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_INVOICE_OUT_OF_CYCLE_DATA,
            dataKey: CustomerRequestDataEnum.METER_READING,
            component: BlockInvoiceOutOfCycleData,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
            component: BlockCompletionInvoiceOutOfCycleShort,
        },
    ],
};

export const repaymentAgreementBlocks: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_NUMBER_OF_INSTALLMENTS,
        component: BlockNumberOfInstallments,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_REASON,
        component: BlockReason,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionRepaymentAgreement,
    },
];

/**Ziadost 16a - Dohoda o splatkach.pdf */
const repaymentAgreementShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.REPAYMENT_AGREEMENT_SHORT,
    type: CustomerRequestTypeEnum.ZOM_DOS,
    skipIntro: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_UNPAID_INVOICES,
            dataKey: CustomerRequestDataEnum.BUSINESS_PARTNER,
            component: BlockUnpaidInvoices,
        },
        ...repaymentAgreementBlocks,
    ],
};

const confirmationAmountLiabilitiesShortFirstPartBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_SELECT_DP_OR_BP,
        dataKey: CustomerRequestDataEnum.BUSINESS_PARTNER,
        component: BlockSelectDPorBP,
    },
];

const confirmationAmountLiabilitiesDPBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT,
        dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
        component: BlockDeliveryPointAmountLiabilitiesShort,
    },
];

const confirmationAmountLiabilitiesBPBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_BUSINESS_PARTNER,
        dataKey: CustomerRequestDataEnum.BUSINESS_PARTNER,
        component: BlockBusinessPartnerAmountLiabilitiesShort,
    },
];

const confirmationAmountLiabilitiesShortLastPartBlocks: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_DATE_SELECTION,
        dataKey: CustomerRequestDataEnum.DATE,
        params: {
            maxDate: true,
            defaultCurrentDate: true,
        },
        component: BlockDate,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionConfirmationAmountLiabilities,
    },
];

/**Ziadost 16b - Potvrdenie o vyske zavazkov.pdf */
const confirmationAmountLiabilitiesShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CONFIRMATION_AMOUNT_LIABILITIES_SHORT,
    type: CustomerRequestTypeEnum.ZOM_POVZ,
    requiredPartnerDeliveryPoints: true,
    skipIntro: true,
    blocks: [
        ...confirmationAmountLiabilitiesShortFirstPartBlock,
        ...confirmationAmountLiabilitiesDPBlock,
        ...confirmationAmountLiabilitiesShortLastPartBlocks,
    ],
};

export const confirmationAmountLiabilitiesShortDPBlocksData: CustomerRequestBlockType[] = [
    ...confirmationAmountLiabilitiesShortFirstPartBlock,
    ...confirmationAmountLiabilitiesDPBlock,
    ...confirmationAmountLiabilitiesShortLastPartBlocks,
];
export const confirmationAmountLiabilitiesShortBPBlocksData: CustomerRequestBlockType[] = [
    ...confirmationAmountLiabilitiesShortFirstPartBlock,
    ...confirmationAmountLiabilitiesBPBlock,
    ...confirmationAmountLiabilitiesShortLastPartBlocks,
];

/**Ziadost 16c - Potvrdenie o zmluvno vztahu.pdf */
const confirmationContractualRelationshipShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT,
    type: CustomerRequestTypeEnum.ZOM_POZV,
    skipIntro: true,
    requiredPartnerDeliveryPoints: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT,
            dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
            component: BlockDeliveryPointConfirmationContractualRelationshipShort,
        },
    ],
};

const requestTerminationContractShortFirstBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_DELIVERY_POINT_TERMINATE_CONTRACT,
        dataKey: CustomerRequestDataEnum.DELIVERY_POINT,
        component: BlockDeliveryPointTerminateContract,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TERMINATION_REASON,
        dataKey: CustomerRequestDataEnum.CONTRACT_TERMINATION_REASON,
        component: BlockContractTerminationReasonShort,
    },
];

const requestTerminationContractShortDateBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TERMINATION_DATE,
        dataKey: CustomerRequestDataEnum.CONTRACT_TERMINATION_DATE,
        component: BlockDate,
        params: {
            minDate: true,
            phoneNumber: true,
        },
    },
];

const requestTerminationContractShortUploadFileBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_ATTACHMENTS,
        component: AttachmentsShort,
    },
];

const requestTerminationContractShortFinalBlock: CustomerRequestBlockType[] = [
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_INVOICE_DELIVERY,
        dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
        component: BlockInvoiceDeliveryOnlyEmailTerminationContract,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_TRANSCRIPT_BANK_CONNECTION,
        dataKey: CustomerRequestDataEnum.BANK_CONNECTION,
        component: BlockBankConnectionList,
    },
    {
        key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION,
        component: BlockCompletionTerminationContract,
    },
];

export const requestTerminationContractShortWithDateBlock: CustomerRequestBlockType[] = [
    ...requestTerminationContractShortFirstBlock,
    ...requestTerminationContractShortDateBlock,
    ...requestTerminationContractShortFinalBlock,
];

export const requestTerminationContractShortWithUploadFileBlock: CustomerRequestBlockType[] = [
    ...requestTerminationContractShortFirstBlock,
    ...requestTerminationContractShortDateBlock,
    ...requestTerminationContractShortUploadFileBlock,
    ...requestTerminationContractShortFinalBlock,
];

export const requestTerminationContractShortNormalFlowBlock: CustomerRequestBlockType[] = [
    ...requestTerminationContractShortFirstBlock,
    ...requestTerminationContractShortFinalBlock,
];

/**Ziadost 16 - Žiadosť o ukončenie zmluvy ZP/EE */
const requestTerminationContractShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.REQUEST_TERMINATION_CONTRACT_SHORT,
    type: CustomerRequestTypeEnum.ZOM_ZOUZ,
    skipIntro: true,
    blocks: [...requestTerminationContractShortNormalFlowBlock],
};

/**Uhlíková stopka */
const carbonShankShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CARBON_SHANK_SHORT,
    type: CustomerRequestTypeEnum.ZOP_US,
    skipIntro: true,
    requiredPartnerDeliveryPoints: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CARBON_FOOTPRINT_PRODUCT_LEVEL,
            dataKey: CustomerRequestDataEnum.PRODUCT_LEVEL,
            component: BlockCarbonFootprintProductLevel,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CARBON_FOOTPRINT_BUSINESS_PARTNER,
            dataKey: CustomerRequestDataEnum.BUSINESS_PARTNER,
            component: BlockBusinessPartnerExtended,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CARBON_FOOTPRINT_PAYMENT_PERIOD,
            dataKey: CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD,
            component: BlockPaymentPeriodCarbonFootprint,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_PAYMENT_METHOD,
            dataKey: CustomerRequestDataEnum.PAYMENT_METHOD,
            component: BlockPaymentMethodIbanBankTransferSipo,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CARBON_FOOTPRINT_INVOICE_DELIVERY,
            dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
            component: BlockInvoiceDeliveryOnlyEmail,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION_ORDER,
            component: BlockCompletionCarbonFootprint,
        },
    ],
};

const cleanElectricityShort: CustomerRequestStepType = {
    key: CustomerRequestEnum.CLEAN_ELECTRICITY_SHORT,
    type: CustomerRequestTypeEnum.ZOM_CE,
    skipIntro: true,
    requiredPartnerDeliveryPoints: true,
    blocks: [
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CLEAR_ELECTRICITY_BUSINESS_PARTNER,
            dataKey: CustomerRequestDataEnum.BUSINESS_PARTNER,
            component: BlockBusinessPartnerCleanElectricityShort,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CLEAR_ELECTRICITY_PAYMENT_PERIOD,
            dataKey: CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD,
            component: BlockPaymentPeriodCleanElectricity,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_PAYMENT_METHOD_CLEAN_ELECTRICITY,
            dataKey: CustomerRequestDataEnum.PAYMENT_METHOD,
            component: BlockPaymentMethodIbanBankTransferSipo,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_CLEAR_ELECTRICITY_INVOICE_DELIVERY,
            dataKey: CustomerRequestDataEnum.INVOICE_DELIVERY,
            component: BlockInvoiceDeliveryOnlyEmailCleanEnergy,
        },
        {
            key: CustomerRequestBlockTitleEnum.BLOCK_COMPLETION_CLEAR_ELECTRICITY,
            component: BlockCompletionClearElectricity,
        },
    ],
};

export {
    blocksOfficialTestMeterWithoutMeterNumber,
    blocksZpSupplyNewConnection,
    blocksEeSupplyNewConnection,
    blocksEeSupplyMigrationConnection,
    blocksZpSupplyMigrationConnection,
    changeElectricityRateShort,
    changeGasTariffShort,
    claimInvoiceShort,
    claimInvoiceBlocks,
    claimInvoiceWithDPBlocks,
    complaintShort,
    transcriptNew,
    transcriptNewCustomer,
    blocksTranscriptNewCustomer,
    blocksTranscriptNewCustomerOnlyEE,
    blocksTranscriptNewCustomerOnlyZP,
    blocksTranscriptNewCustomerWithoutRateAndTariff,
    eeSupplyNew,
    zpSupplyNew,
    requestTerminationContractShort,
    requestInteruptionAndResumptionConsuptionShort,
    requestOfficialTestMeterShort,
    requestInvoiceOutCycleShort,
    repaymentAgreementShort,
    confirmationAmountLiabilitiesShort,
    confirmationContractualRelationshipShort,
    carbonShankShort,
    cleanElectricityShort,
};
