import {
    SkSppNzpBeApiCustomerprofileBusinessPartner as BusinessPartner,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary as DeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate as CustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common';
import { IAttachments } from '../models/customer-request-model';
import { CustomerRequestAction, CustomerRequestActionType, CustomerRequestPayloadType, IStep } from '../reducers/interfaces/customer-request-state';
import {
    CustomerRequestBlockType,
    CustomerRequestMetadataType,
    CustomerRequestPartnersDeliveryPointsType,
    CustomerRequestStepType,
} from '../views/customer-request/config/types';
import { IRequestPaid, RequestTemplateCodeExtra } from './../models/model';
import { CustomerRequestAdditionalDataType } from './../reducers/interfaces/customer-request-state';
import { CustomerRequestEnum } from './../views/customer-request/config/enums';

export const CustomerRequestActions = {
    setRequestKeys: (key: CustomerRequestEnum, code: RequestTemplateCodeExtra): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_REQUEST_KEYS,
        key,
        code,
    }),
    setRequest: (request: CustomerRequestStepType): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_REQUEST,
        request,
    }),
    closeRequest: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.CLOSE_REQUEST,
    }),
    setStep: (step: IStep): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_STEP,
        stepBlock: step,
    }),
    nextStep: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.NEXT_STEP,
    }),
    previousStep: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.PREVIOUS_STEP,
    }),
    openStep: (step: number): CustomerRequestAction => ({
        type: CustomerRequestActionType.OPEN_STEP,
        step,
    }),
    setData: (payload: CustomerRequestPayloadType): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DATA,
        payload,
    }),
    setAttachments: (attachments?: IAttachments[]): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_ATTACHMENTS,
        attachments,
    }),
    setMetaData: (metadata: CustomerRequestMetadataType): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_METADATA,
        metadata,
    }),
    replaceBlocks: (blocks: CustomerRequestBlockType[]): CustomerRequestAction => ({
        type: CustomerRequestActionType.REPLACE_BLOCKS,
        blocks,
    }),
    setPartnersDeliveryPoints: (partnersDeliveryPoints: CustomerRequestPartnersDeliveryPointsType): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_PARTNERS_DELIVERY_POINTS,
        partnersDeliveryPoints,
    }),
    setBusinessPartner: (businessPartner: BusinessPartner | undefined): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_BUSINESS_PARTNER,
        businessPartner,
    }),
    setDeliveryPoint: (deliveryPoint: DeliveryPointSummary | undefined): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DELIVERY_POINT,
        deliveryPoint,
    }),
    setDeliveryPointZp: (deliveryPointZp: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DELIVERY_POINT_ZP,
        deliveryPointZp,
    }),
    setDeliveryPointEe: (deliveryPointEe: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DELIVERY_POINT_EE,
        deliveryPointEe,
    }),
    setCustomerRequestTemplate: (customerRequestTemplate: CustomerRequestTemplate): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_CUSTOMER_REQUEST_TEMPLATE,
        customerRequestTemplate,
    }),
    setCustomerRequestTemplates: (customerRequestTemplates: CustomerRequestTemplate[]): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_CUSTOMER_REQUEST_TEMPLATES,
        customerRequestTemplates,
    }),
    setAdditionalData: (additionalData: CustomerRequestAdditionalDataType): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_ADDITIONAL_DATA,
        additionalData,
    }),
    clearAdditionalData: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.CLEAR_ADDITIONAL_DATA,
    }),
    setSend: (send: boolean): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_SEND,
        send,
    }),
    setInvoices: (invoices: SkSppNzpBeApiCustomerprofileInvoiceSummary[]): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_INVOICES,
        invoices,
    }),
    setRequestIsPaid: (paid: IRequestPaid): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_REQUEST_IS_PAID,
        paid,
    }),
    setDeliveryPointsZP: (
        deliveryPointsZp: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] | SkSppNzpBeApiCustomerprofileDeliveryPoint[],
    ): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DELIVERY_POINTS_ZP,
        deliveryPointsZp,
    }),
    setDeliveryPointsEE: (
        deliveryPointsEe: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] | SkSppNzpBeApiCustomerprofileDeliveryPoint[],
    ): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_DELIVERY_POINTS_EE,
        deliveryPointsEe,
    }),
    setUuid: (uuid: string): CustomerRequestAction => ({
        type: CustomerRequestActionType.SET_UUID,
        uuid,
    }),
    addRegistrationBlock: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.ADD_REGISTRATION_BLOCK,
    }),
    removeRegistrationBlock: (): CustomerRequestAction => ({
        type: CustomerRequestActionType.REMOVE_REGISTRATION_BLOCK,
    }),
};
