import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import BaseSelect from '../../../../../components/common/base-select';
import Input from '../../../../../components/common/input';
import InputWithAppend from '../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { SK_CODE } from '../../../../../models/enums';
import { DefaultFormType } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { combineRefs } from '../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../config/enums';
import useCodeList from './../../../../../hooks/use-code-list';

type Props = DefaultFormType & {
    objectName?: string;
    disabled?: boolean;
    disableCountry?: boolean;
    disabledCountrySelect?: boolean;
    autoFocus?: boolean;
};

const AddressDefaultFields: React.FC<Props> = ({
    register,
    errors,
    watch,
    setValue,
    objectName = '',
    disabled = false,
    trigger,
    disableCountry,
    disabledCountrySelect,
    autoFocus,
    touched,
}) => {
    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });
    const { requiredTrimed, formRules } = useFormRules();
    const localRef = useRef<HTMLInputElement>(null);
    const { currentBlock } = useSelector((state: IRootState) => state.customerRequest);

    const ZP = currentBlock?.params?.zp ?? false;

    const country: string = watch && watch(`${objectName}country.uuid`);

    const postalCodeSk = useMemo(() => {
        if (disableCountry || disabledCountrySelect) return true;
        const skCodeItem = countryCodeList?.find((item) => item.uuid === country);
        return skCodeItem?.code === SK_CODE;
    }, [countryCodeList, country, disableCountry, disabledCountrySelect]);

    const postalCode: string = watch && watch(`${objectName}postalCode`);

    useEffect(() => {
        if (!country) {
            setValue && setValue('address.country.uuid', countryCodeList?.find((item) => item.code === 'SK')?.uuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodeList]);

    /**validate postal code on (xxx xx) format  */
    useEffect(() => {
        if (!postalCodeSk) return;
        // const code = postalCode?.replace(/ /g, '');
        const length = localRef.current?.value.length || 0;
        let position = localRef.current?.selectionEnd || length || 0;
        const preview = localRef.current?.value
            .replace(/[^\da-zA-Z]/g, '')
            .replace(/(.{3})/g, '$1 ')
            .trim();
        if (localRef.current) {
            localRef.current.value = preview || '';
            localRef.current.selectionEnd = position +=
                localRef.current.value.charAt(position - 1) === ' ' &&
                localRef.current.value.charAt(length - 1) === ' ' &&
                length !== localRef.current.value.length
                    ? 1
                    : 0;
        }
        if (postalCode?.length > 0) {
            trigger && trigger(`${objectName}postalCode`);
        }
    }, [postalCode, objectName, trigger, postalCodeSk]);

    return (
        <>
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                disabled={disabled}
                name={`${objectName}street`}
                label={<Trans i18nKey="customer-request.steps.address.street">Ulica</Trans>}
                autoFocus={autoFocus}
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed })}
                errors={errors}
                type="text"
                disabled={disabled}
                name={`${objectName}number`}
                label={<Trans i18nKey="customer-request.steps.address.number">Číslo</Trans>}
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                disabled={disabled}
                name={`${objectName}city`}
                label={<Trans i18nKey="customer-request.steps.address.city">Mesto</Trans>}
                touched={touched}
            />

            <Input
                trigger={trigger}
                ref={combineRefs<HTMLInputElement>([
                    register({ ...requiredTrimed, ...(postalCodeSk || disabledCountrySelect ? formRules.postalCodeSk : formRules.postalCode) }),
                    localRef,
                ])}
                errors={errors}
                type="text"
                maxLength={postalCodeSk ? 6 : 13}
                disabled={disabled}
                name={`${objectName}postalCode`}
                label={<Trans i18nKey="customer-request.steps.address.postcode">PSČ</Trans>}
                touched={touched}
            />

            {!disableCountry && !disabledCountrySelect && countryCodeList && (
                <BaseSelect
                    ref={register({})}
                    name={`${objectName}country.uuid`}
                    label={<Trans i18nKey="customer-request.steps.address.country">Štát</Trans>}
                    disabled={disabledCountrySelect}
                >
                    {countryCodeList?.map((item, index) => (
                        <option key={index} value={item.uuid}>
                            {item.name}
                        </option>
                    ))}
                </BaseSelect>
            )}

            {disabledCountrySelect && (
                <>
                    <InputWithAppend
                        trigger={trigger}
                        errors={errors}
                        type="text"
                        ref={register({})}
                        name={`${objectName}country.name`}
                        label={<Trans i18nKey="customer-request.steps.address.country">Štát</Trans>}
                        disabled
                        value={countryCodeList?.find((item) => item.code === 'SK')?.name}
                        inputGroupAppendChildren={
                            <div className="mr-2 my-auto" style={{ backgroundColor: '#e9ecef' }}>
                                <FontAwesomeIcon icon={faInfoCircle} id="tooltip_country" />
                            </div>
                        }
                    />
                    <UncontrolledTooltip placement="top" target="tooltip_country">
                        {ZP ? (
                            <Trans i18nKey="customer-request.steps.address-supply-zp.only-sr-supply">Dodávka plynu je možná len v rámci SR</Trans>
                        ) : (
                            <Trans i18nKey="customer-request.steps.address-supply.only-sr-supply">Dodávka elektriny je možná len v rámci SR</Trans>
                        )}
                    </UncontrolledTooltip>
                </>
            )}

            <Input
                trigger={trigger}
                ref={register({ ...formRules.numberWithCharacters })}
                errors={errors}
                type="text"
                disabled={disabled}
                name={`${objectName}floor`}
                label={<Trans i18nKey="customer-request.steps.address.floor">Poschodie</Trans>}
                optional
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register({ ...formRules.numberWithCharacters, ...formRules.maxLengthX(10) })}
                errors={errors}
                type="text"
                disabled={disabled}
                name={`${objectName}flatNumber`}
                label={<Trans i18nKey="customer-request.steps.address.appartments-number">Č. bytu alebo obch. priestoru</Trans>}
                optional
                touched={touched}
            />
        </>
    );
};

export default AddressDefaultFields;
