import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary as DeliveryPointSummary,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMeterReading } from '../../../../../../models/customer-request-model';
import { CustomerRequestStatusEnum } from '../../../../../../models/enums';
import { IDeliveryPointsEe, IDeliveryPointsZp } from '../../../../../../models/model';
import { formatAddress, isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data?: DeliveryPointSummary;
    bold?: boolean;
    contentInfoZp?: IDeliveryPointsZp[];
    contentInfoEe?: IDeliveryPointsEe[];
    isEe: boolean;
    status: CustomerRequestStatusEnum;
};

const DeliveryPointSummaryTranscript: React.FC<Props> = ({ data, bold, contentInfoZp, contentInfoEe, isEe, status }) => {
    const [t] = useTranslation();
    if (!data) return null;
    const { address } = data;
    let country = address?.country;

    const unit = () =>
        data.type === 'EE' ? (
            'kWh'
        ) : (
            <>
                m<sup>3</sup>
            </>
        );

    let meterReading: IMeterReading | undefined;
    let advancePaymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined;
    let advancePayment: string | undefined;
    let tariff: SkSppNzpCommonsApiCodelistCodeListItem | undefined;
    if (isEe) {
        meterReading = contentInfoEe?.find((item) => item.deliveryPointEe.eic === data?.eic)?.meterReadingEe;
        advancePaymentPeriod = contentInfoEe?.find((item) => item.deliveryPointEe.eic === data?.eic)?.advancePaymentPeriodEe?.period;
        advancePayment = contentInfoEe?.find((item) => item.deliveryPointEe.eic === data?.eic)?.assumedConsumptionEe?.advancePayment;
        tariff = contentInfoEe?.find((item) => item.deliveryPointEe.eic === data?.eic)?.tariffEe?.tariff;
        country = contentInfoEe?.find((item) => item.deliveryPointEe.eic === data?.eic)?.deliveryPointEe.address.country.name;
    } else {
        meterReading = contentInfoZp?.find((item) => item.deliveryPointZp.pod === data?.pod)?.meterReadingZp;
        advancePaymentPeriod = contentInfoZp?.find((item) => item.deliveryPointZp.pod === data?.pod)?.advancePaymentPeriodZp?.period;
        advancePayment = contentInfoZp?.find((item) => item.deliveryPointZp.pod === data?.pod)?.assumedConsumptionZp?.advancePayment;
        tariff = contentInfoZp?.find((item) => item.deliveryPointZp.pod === data?.pod)?.tariffZp?.tariff;
        country = contentInfoZp?.find((item) => item.deliveryPointZp.pod === data?.pod)?.deliveryPointZp.address.country.name;
    }
    return (
        <>
            {address && !isObjectEmpty(address) && (
                <ItemPreview title={t('customer-request.steps.delivery-point.for-transcript')}>
                    <>
                        <ItemPreviewContent bold={bold}>{`${formatAddress(address)} - ${t(`enums.CommodityEnum.${data.type}`)}`}</ItemPreviewContent>
                        <ItemPreviewContent bold={bold}>{`${address?.zipCode}, ${country}`}</ItemPreviewContent>
                        <ItemPreviewContent bold={bold}>
                            {t('common.delivery-point')} {data?.externalId}
                        </ItemPreviewContent>
                        {meterReading && (
                            <div className="mt-2">
                                {meterReading.value && (
                                    <p>
                                        <ItemPreviewContent bold>
                                            <b>
                                                {status === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS
                                                    ? t('customer-request.steps.gauge-condition.agreed-title')
                                                    : t('customer-request.steps.gauge-condition.agreed-title-confirmed')}
                                            </b>
                                        </ItemPreviewContent>
                                        <ItemPreviewContent>
                                            {meterReading.value} {unit()}
                                        </ItemPreviewContent>
                                    </p>
                                )}
                                {meterReading.valueHigh && (
                                    <p>
                                        <ItemPreviewContent bold>
                                            {status === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS
                                                ? t('customer-request.steps.gauge-condition.gauge-hight')
                                                : t('customer-request.steps.gauge-condition.gauge-hight-confirmed')}
                                        </ItemPreviewContent>
                                        <ItemPreviewContent>{`${meterReading.valueHigh} kWh`}</ItemPreviewContent>
                                    </p>
                                )}
                                {meterReading.valueLow && (
                                    <p>
                                        <ItemPreviewContent bold>
                                            {status === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS
                                                ? t('customer-request.steps.gauge-condition.gauge-low')
                                                : t('customer-request.steps.gauge-condition.gauge-low-confirmed')}
                                        </ItemPreviewContent>
                                        <ItemPreviewContent>{`${meterReading.valueLow} kWh`}</ItemPreviewContent>
                                    </p>
                                )}
                            </div>
                        )}
                        {advancePaymentPeriod && advancePayment && (
                            <p>
                                <ItemPreviewContent bold>{t('customer-request.steps.interaption-extra-info.advance-payments')}</ItemPreviewContent>
                                <ItemPreviewContent>{`${advancePayment}€ / ${advancePaymentPeriod.name}`}</ItemPreviewContent>
                            </p>
                        )}
                        {tariff && (
                            <>
                                <ItemPreviewContent bold>
                                    {isEe ? t('customer-request.detail.rate') : t('delivery-point.detail.payment-data.tariff')}
                                </ItemPreviewContent>
                                <ItemPreviewContent>{tariff.name}</ItemPreviewContent>
                            </>
                        )}
                    </>
                </ItemPreview>
            )}
        </>
    );
};

export default DeliveryPointSummaryTranscript;
