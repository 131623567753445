import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import HelpText from '../../components/help/help-text';
import { useApi } from '../../hooks/use-api';
import { useQueryWithError } from '../../hooks/use-query-with-error';
import { IRootState } from '../../reducers';

const AboutApplication = (): ReactElement => {
    const appVersion = useSelector((s: IRootState) => s.help.appVersion);
    const api = useApi();
    const { data, isLoading } = useQueryWithError<Record<string, string> | null>(
        'info',
        async () => api.about.getVersions().then((res) => res.data),
        {
            onError: () => {
                return true;
            },
        },
    );

    return (
        <>
            <div className="page-header py-4">
                <div className="container">
                    <div className="d-flex justify-content-between my-4">
                        <h2 className="mb-0">
                            <Trans i18nKey="about-application.title">O aplikácii</Trans>
                        </h2>
                    </div>
                </div>
            </div>

            <div className="container" style={{ position: 'relative' }}>
                <HelpText screen="ALL" field="ALL_ABOUT_APP" />
                <p className="text-center mt-2">
                    <Trans i18nKey="about-application.app-version">Verzia portálu</Trans>: {appVersion || ''}
                    {!isLoading && data && data['module'] && (
                        <>
                            <br />
                            <Trans i18nKey="about-application.app-version-be">Verzia rozhrania</Trans>: {data['module']}
                        </>
                    )}
                    {!isLoading && data && data['deployment'] && (
                        <>
                            <br />
                            <Trans i18nKey="about-application.config-version-be">Konfigurácia BE</Trans>: {data['deployment']}
                        </>
                    )}
                </p>
            </div>
        </>
    );
};

export default AboutApplication;
