import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Button } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { DetailPaymentFormFieldEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import BankConnectionFormFields from '../../../../customer-request/screen/steps-customer-request/block-bank-connection/components/bank-connection-form-fields';

export type BankAccountFields = {
    iban: string;
    bicSwift: string;
};

type Props = {
    currentValue: BankAccountFields;
    editedValue: BankAccountFields | undefined;
    onReset: () => void;
    onSubmit: (value: BankAccountFields) => void;
    setEditedFormField: (val: DetailPaymentFormFieldEnum) => void;
};

const ChangeBankAccountForm: React.FC<Props> = ({ currentValue, editedValue, onReset, onSubmit, setEditedFormField }) => {
    const displayValue = editedValue ?? currentValue;
    const { register, handleSubmit, errors, trigger, watch, setValue, getValues } = useForm<BankAccountFields>({
        defaultValues: {
            iban: displayValue.iban,
            bicSwift: displayValue.bicSwift,
        },
    });

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const handleIdenticalNewValue = () => {
        addToast(t('delivery-point.detail.given-data-is-the-same-as-current-data'), {
            appearance: 'warning',
        });
    };

    const isNewValueEqualToCurrentValue = (newValue: BankAccountFields) => {
        const iban = newValue.iban?.replace(/ /g, '').toUpperCase();
        return currentValue.iban === iban;
    };

    const onFormSubmit = handleSubmit((fields) => {
        if (isNewValueEqualToCurrentValue(fields)) {
            handleIdenticalNewValue();
        } else {
            onSubmit({
                iban: fields.iban,
                bicSwift: fields.bicSwift,
            });
        }
    });

    const isSubmitAllowed = useMemo(
        () => !isNewValueEqualToCurrentValue({ iban: getValues('iban'), bicSwift: getValues('bicSwift') }), // eslint-disable-next-line react-hooks/exhaustive-deps
        [watch && watch('iban'), watch && watch('bicSwift'), getValues, currentValue.iban],
    );

    return (
        <form onSubmit={onFormSubmit} noValidate onReset={onReset}>
            <BankConnectionFormFields register={register} errors={errors} trigger={trigger} watch={watch} setValue={setValue} autoFocus />
            {/* Help button */}
            <HelpLinkWithModal showAsCard className="my-4" linkTitle="common.dialog-help" field="DOM_BANK_ACCOUNT_CHANGE_HELP" />
            <Trans i18nKey="delivery-point.detail.contact-data.change-first-payment-method">Požadujete aj zmenu spôsobu platby? Najskôr zmeňte</Trans>
            <ClickableElement
                isText
                onClick={() => setEditedFormField(DetailPaymentFormFieldEnum.PAYMENT_METHOD)}
                className="text-decoration-underline-inverse font-weight-bold"
            >
                {' '}
                <Trans i18nKey="delivery-point.detail.payment-data.payment-method">Spôsob platby</Trans>
            </ClickableElement>
            .
            <Button type="submit" block color="primary" size="lg" className="mt-4" disabled={!isSubmitAllowed}>
                <Trans i18nKey="delivery-point.detail.modals.request-change" />
            </Button>
            <Button type="reset" block color="secondary" size="lg" className="mt-3">
                <Trans i18nKey="delivery-point.detail.modals.cancel" />
            </Button>
        </form>
    );
};

export default ChangeBankAccountForm;
