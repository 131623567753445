import { SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useSelector } from 'react-redux';
import { BusinessPartnerTypeEnum } from '../../../../../../models/enums';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';
import BusinessPartnerForm from './business-partner-form';
import BusinessPartnerLoggedIn from './business-partner-logged-in';

export type BusinessPartnerProps = DefaultFormType & {
    onSwitchChange: (type: BusinessPartnerTypeEnum) => void;
    activeType: BusinessPartnerTypeEnum;
    objectsName?: string;
    auth?: boolean;
    opNumberRequired: boolean;
    min18years?: boolean;
    requiredIdCardNumber?: boolean;
    hideBpNumber?: boolean;
    hideIdCardNumber?: boolean;
    hideBirthDate?: boolean;
    customerRequestTemplate?: SkSppNzpBeApiCustomerrequestCustomerRequestTemplate;
};

const BusinessPartnerFields: React.FC<BusinessPartnerProps> = ({
    register,
    errors,
    onSwitchChange,
    activeType,
    objectsName,
    auth,
    getValues,
    setValue,
    trigger,
    watch,
    opNumberRequired,
    hideBpNumber,
    min18years,
    hideIdCardNumber,
    hideBirthDate,
}) => {
    const { isUserLogged } = useSelector((state: IRootState) => state.user);
    return (
        <>
            {isUserLogged && auth ? (
                <BusinessPartnerLoggedIn
                    opNumberRequired={opNumberRequired}
                    register={register}
                    errors={errors}
                    onSwitchChange={onSwitchChange}
                    activeType={activeType}
                    objectsName={objectsName}
                    auth={auth}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    hideBirthDate={hideBirthDate}
                />
            ) : (
                <BusinessPartnerForm
                    opNumberRequired={opNumberRequired}
                    register={register}
                    errors={errors}
                    onSwitchChange={onSwitchChange}
                    activeType={activeType}
                    objectsName={objectsName}
                    auth={auth}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    min18years={min18years}
                    hideBpNumber={hideBpNumber}
                    hideIdCardNumber={hideIdCardNumber}
                    hideBirthDate={hideBirthDate}
                />
            )}
        </>
    );
};

export default BusinessPartnerFields;
