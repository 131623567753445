import { SkSppNzpBeApiCommonQueryStringPaging, SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import { useTranslation } from 'react-i18next';
import { QueryKeysEnum } from '../utils/react-query-utils';
import { CodeListTypeEnum } from '../views/customer-request/config/enums';
import { useApi } from './use-api';
import { useQueryWithError } from './use-query-with-error';

type Props = {
    queryKey: QueryKeysEnum;
    codeListTypeEnum: CodeListTypeEnum;
    paging?: SkSppNzpBeApiCommonQueryStringPaging;
    status?: string[];
    sort?: string[];
};

const useCodeList = ({ codeListTypeEnum, queryKey, paging = {}, status, sort }: Props): { data?: CodeListItem[] | null; isLoading?: boolean } => {
    const api = useApi();
    const { i18n } = useTranslation();

    const { data, isLoading } = useQueryWithError<CodeListItem[] | null>(
        [queryKey, codeListTypeEnum, paging, i18n.language],
        async () =>
            await api.codeLists
                .list(codeListTypeEnum, { paging, status, sort }, { secure: true })
                .then((res) => (res.data?.result ? res.data?.result : null)),
        {
            onError: () => {
                return false;
            },
            cacheTime: 10 * 60 * 1000,
            staleTime: 10 * 60 * 1000,
            keepPreviousData: true,
        },
    );

    return { data, isLoading };
};

export default useCodeList;
