import React from 'react';
import { Trans } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

interface IDeliveryPointSwitchMenuProps {
    showInactive: boolean;
    toggleInactive: () => void;
}

export const DeliveryPointSwitchMenu: React.FC<IDeliveryPointSwitchMenuProps> = ({ showInactive, toggleInactive }) => {
    return (
        <>
            <UncontrolledButtonDropdown group={false} direction="down" style={{ width: '80%' }}>
                <DropdownToggle tag="button" className="btn btn-white btn-round light-shadow float-right">
                    <i className="icon-context-points" />
                </DropdownToggle>

                <DropdownMenu right style={{ width: 'auto' }}>
                    <DropdownItem onClick={toggleInactive} className="py-3">
                        {showInactive ? (
                            <>
                                <b className="text-nowrap">
                                    <Trans i18nKey="delivery-point.detail.header.hide-inactive-products" />
                                </b>
                                <br />
                                <small className="text-secondary">
                                    <Trans i18nKey="delivery-point.detail.header.hide-inactive-products-description" />
                                </small>
                            </>
                        ) : (
                            <>
                                <b className="text-nowrap">
                                    <Trans i18nKey="delivery-point.detail.header.show-inactive-products" />
                                </b>
                                <br />
                                <small className="text-secondary">
                                    <Trans i18nKey="delivery-point.detail.header.show-inactive-products-description" />
                                </small>
                            </>
                        )}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>
    );
};
