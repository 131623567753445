import React, { useMemo, useState } from 'react';
import { Container, Row } from 'reactstrap';
import Benefits from '../../../components/common/benefits';
import { decodeJwt, getStateFromSessionStorage } from '../../../hooks/sso/use-auth-utils';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { PRE_REGISTRATION } from '../../../models/enums';
import { ISocialLoginJWTPayload } from '../../../models/model';
import EnterPreRegistrationDataScreen, { ISessionData } from '../../pre-registration/screen/enter-pre-registration-data-screen';
import ConfirmMessageStep from './components/confirm-message-step';
import RegistrationStep from './components/registration-step';

const EnterRegistrationDataScreen: React.FC = () => {
    const urlQuery = useUrlQuery();
    const step = urlQuery.get('regform');
    const stateAsUuid = '' + urlQuery.get('state');
    const stateParams = stateAsUuid ? getStateFromSessionStorage(stateAsUuid) : '';
    let sessionData: ISessionData = {};
    try {
        sessionData = JSON.parse(stateParams || '');
    } catch {}

    //useUrlQuery cannot be use because the url contains #fragment
    const urlHash = window.location.hash?.split('&');
    const jwt = urlHash.length && urlHash[0]?.split('jws=').length === 2 ? urlHash[0]?.split('jws=')[1] : undefined;
    const urlHashRedirectUrl = urlHash.find((item) => item.includes('redirect_uri'));
    const redirectUrl =
        urlHashRedirectUrl && urlHashRedirectUrl.split('redirect_uri=').length === 2
            ? urlHashRedirectUrl.split('redirect_uri=')[1] + '&reg=1'
            : undefined;

    const decodedJwt = useMemo(() => {
        try {
            return jwt ? decodeJwt<ISocialLoginJWTPayload>(jwt) : null;
        } catch (e) {
            return null;
        }
    }, [jwt]);

    const [email, setEmail] = useState<string>(decodedJwt?.email || '');
    const [session] = useState<ISessionData>(sessionData);
    const type = urlQuery.get('type') || session.type;

    const renderRegistrationStep = () => {
        switch (step) {
            case 'submitted':
                return <ConfirmMessageStep email={email} />;
            default:
                return (
                    <RegistrationStep
                        saveEmail={setEmail}
                        email={email}
                        firstName={decodedJwt?.first_name}
                        lastName={decodedJwt?.last_name}
                        socialLoginType={decodedJwt?.typ}
                        jwt={jwt}
                        redirectUrl={redirectUrl}
                    />
                );
        }
    };
    if (type === PRE_REGISTRATION) {
        return <EnterPreRegistrationDataScreen jwt={jwt} decodedJwtEmail={decodedJwt?.email} sessionData={session} />;
    }
    return (
        <Container className="my-5">
            <Row>
                <Benefits />
                {renderRegistrationStep()}
            </Row>
        </Container>
    );
};
export default EnterRegistrationDataScreen;
