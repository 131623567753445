import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

interface IRequirePasswordModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    text?: string;
    activateEmailLogin: () => void;
}

const RequirePasswordModal: React.FC<IRequirePasswordModalProps> = ({ isOpen, onCloseModal, text, activateEmailLogin }) => {
    const [t] = useTranslation();
    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody className="px-4 pb-5">
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3>{t('settings.login-details.require-password-modal-title')}</h3>
                    <p className="my-4">{text || t('settings.login-details.require-password-modal-text')}</p>
                    <button className="btn btn-lg btn-outline-primary" onClick={activateEmailLogin}>
                        <Trans i18nKey="settings.login-details.activate-email-login">Aktivovať prihlasovanie e-mailom</Trans>
                    </button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default RequirePasswordModal;
