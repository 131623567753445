import React from 'react';
import { Trans } from 'react-i18next';
import { NotificationTemplateGroup } from '../../../models/model';

export const translateNotificationTemplateGroup = (group: NotificationTemplateGroup): React.ReactNode => {
    switch (group) {
        case 'INVOICE':
            return <Trans i18nKey="settings.notifications.notification-groups.invoice" />;
        case 'CUSTOMER_REQUEST':
            return <Trans i18nKey="settings.notifications.notification-groups.customer-request" />;
        case 'LIMIT_OVERFLOW':
            return <Trans i18nKey="settings.notifications.notification-groups.limit-overflow" />;
        case 'SHARING':
            return <Trans i18nKey="settings.notifications.notification-groups.sharing" />;
        default:
            return group;
    }
};
