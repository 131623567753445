import {
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingNotificationSettingResponse as NotificationSettingResponse,
} from '@spp/spp-meru-frontend-common';
import { NotificationChannel, NotificationTemplateCode } from '../models/model';

export enum NotificationSettingsActionType {
    SET_DELIVERY_POINT_NOTIFICATION_SETTINGS_LIST = 'NotificationSettings/SET_DELIVERY_POINT_NOTIFICATION_SETTINGS_LIST',
    SET_NOTIFICATION_SETTING = 'NotificationSettings/SET_NOTIFICATION_SETTING',
    SET_BULK_NOTIFICATION_SETTING = 'NotificationSettings/SET_BULK_NOTIFICATION_SETTING',
}

type SetDeliveryPointNotificationSettingsAction = {
    type: NotificationSettingsActionType.SET_DELIVERY_POINT_NOTIFICATION_SETTINGS_LIST;
    payload: { deliveryPointNotificationSettings: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse[] };
};

type SetNotificationSettingAction = {
    type: NotificationSettingsActionType.SET_NOTIFICATION_SETTING;
    payload: { deliveryPointId: string; notificationSetting: NotificationSettingResponse[] };
};

type SetBulkNotificationSettingAction = {
    type: NotificationSettingsActionType.SET_BULK_NOTIFICATION_SETTING;
    payload: {
        deliveryPointIds: string[];
        notificationTemplateTypes: NotificationTemplateCode[];
        notificationChannel: NotificationChannel[];
        enabled: boolean;
    };
};

export type NotificationSettingsAction = SetDeliveryPointNotificationSettingsAction | SetNotificationSettingAction | SetBulkNotificationSettingAction;

export const notificationSettingsActions = {
    setDeliveryPointsNotificationSettings: (
        deliveryPointNotificationSettings: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse[],
    ): SetDeliveryPointNotificationSettingsAction => ({
        type: NotificationSettingsActionType.SET_DELIVERY_POINT_NOTIFICATION_SETTINGS_LIST,
        payload: { deliveryPointNotificationSettings },
    }),
    setNotificationSetting: (deliveryPointId: string, notificationSetting: NotificationSettingResponse[]): SetNotificationSettingAction => ({
        type: NotificationSettingsActionType.SET_NOTIFICATION_SETTING,
        payload: { deliveryPointId: deliveryPointId, notificationSetting: notificationSetting },
    }),
    setBulkNotificationSetting: (
        deliveryPointIds: string[],
        notificationTemplateTypes: NotificationTemplateCode[],
        notificationChannel: NotificationChannel[],
        enabled: boolean,
    ): SetBulkNotificationSettingAction => ({
        type: NotificationSettingsActionType.SET_BULK_NOTIFICATION_SETTING,
        payload: { deliveryPointIds, notificationTemplateTypes, notificationChannel, enabled },
    }),
};
