import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileInvoiceSearch,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FilterDescription } from '../../../components/common/filter-description';
import { formatAddressShort, formatDateToPreview } from '../../../main';
import { joinNodes } from '../../../utils/react-utils';
import { IInvoiceBP } from './invoices-filter-form';

interface IInvoicesFilterDescriptionProps {
    filter: Partial<SkSppNzpBeApiCustomerprofileInvoiceSearch>;
    unitedDeliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | undefined;
    deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[] | undefined;
    deliveryPointId?: string;
}

export const InvoicesFilterDescription: React.FC<IInvoicesFilterDescriptionProps> = ({
    filter,
    unitedDeliveryPoints,
    deliveryPoints,
    deliveryPointId,
}) => {
    const { t } = useTranslation();

    const translateInvoiceGroupValue = useCallback(() => {
        switch (filter.invoiceGroup) {
            case 'ADVANCE_INVOICE':
                return <Trans i18nKey="enums.InvoiceGroup.ADVANCE_INVOICE" />;
            case 'CREDIT':
                return <Trans i18nKey="enums.InvoiceGroup.CREDIT" />;
            case 'INVOICE':
                return <Trans i18nKey="enums.InvoiceGroup.INVOICE" />;
            case 'OTHERS':
                return <Trans i18nKey="enums.InvoiceGroup.OTHERS" />;
            case 'REPAYMENT_PLAN':
                return <Trans i18nKey="enums.InvoiceGroup.REPAYMENT_PLAN" />;
            default:
                return undefined;
        }
    }, [filter.invoiceGroup]);

    const invoiceBPs = useMemo<IInvoiceBP[]>(() => {
        const result: IInvoiceBP[] = [];
        unitedDeliveryPoints?.forEach((deliveryPoint) => {
            if (deliveryPoint.businessPartner?.id) {
                if (!result.find((businessPartner) => businessPartner.value === deliveryPoint.businessPartner?.id)) {
                    result.push({
                        name: deliveryPoint.businessPartner.name
                            ? deliveryPoint.businessPartner.name
                            : deliveryPoint.businessPartner.firstName + ' ' + deliveryPoint.businessPartner.lastName,
                        value: deliveryPoint.businessPartner.id,
                    });
                }
            }
        });
        return result;
    }, [unitedDeliveryPoints]);

    const elements: React.ReactNode[] = useMemo(() => {
        if (filter == null) {
            return [];
        }
        const result: React.ReactNode[] = [];

        if (!!filter?.issueAt) {
            if (!!filter.issueAt.from && !!filter.issueAt.to) {
                const repr = `${formatDateToPreview(filter.issueAt.from.split('T')[0]) || '  '} - ${formatDateToPreview(
                    filter.issueAt.to.split('T')[0],
                ) || '  '}`;
                result.push(repr);
            } else if (!!filter.issueAt.from) {
                const repr = `${t('invoices.filter.time-from')}: ${formatDateToPreview(filter.issueAt.from.split('T')[0]) || '  '}`;
                result.push(repr);
            } else if (!!filter.issueAt.to) {
                const repr = `${t('invoices.filter.time-to')}: ${formatDateToPreview(filter.issueAt.to.split('T')[0]) || '  '}`;
                result.push(repr);
            }
        }

        if (!!filter.invoiceGroup) {
            const element = translateInvoiceGroupValue();
            if (!!element) {
                result.push(element);
            }
        }

        if (!!filter?.productType) {
            const element =
                filter.productType === 'EE' ? (
                    <Trans i18nKey="enums.CommodityEnum.EE" />
                ) : filter.productType === 'ZP' ? (
                    <Trans i18nKey="enums.CommodityEnum.ZP" />
                ) : (
                    <Trans i18nKey="enums.CommodityEnum.N" />
                );
            !deliveryPointId && result.push(element);
        }

        if (!!filter?.businessPartnerId) {
            const customer = invoiceBPs.find((businessPartner) => businessPartner.value === filter?.businessPartnerId);
            const element = (
                <>
                    <Trans i18nKey="invoices.filter.customer" />: {customer?.name}{' '}
                </>
            );
            !deliveryPointId && customer && result.push(element);
        }

        if (filter.unitedDeliveryPointIds != null && unitedDeliveryPoints != null) {
            const udpIds = filter.unitedDeliveryPointIds;
            const addresses = unitedDeliveryPoints?.filter((x) => !!x.id && udpIds.includes(x.id)).map((x) => formatAddressShort(x.address));
            if (addresses.length > 0) {
                const element = (
                    <>
                        <Trans i18nKey="invoices.filter.delivery-point" />: {joinNodes(addresses, ' / ')}
                    </>
                );
                !deliveryPointId && result.push(element);
            }
        }

        if (filter.contractIds != null && deliveryPoints != null) {
            const cIds = filter.contractIds;
            const addresses = deliveryPoints
                ?.filter((x) => !!x.contract?.id && cIds.includes(x.contract?.id))
                .map((x) => formatAddressShort(x.address));
            if (addresses.length > 0) {
                const element = (
                    <>
                        <Trans i18nKey="invoices.filter.delivery-point" />: {joinNodes(addresses, ' / ')}
                    </>
                );
                !deliveryPointId && result.push(element);
            }
        }

        return result;
    }, [deliveryPoints, filter, translateInvoiceGroupValue, unitedDeliveryPoints, deliveryPointId, invoiceBPs, t]);

    return <FilterDescription elements={elements} />;
};
