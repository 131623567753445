import { SkSppNzpBeApiNotificationSettingNotificationSettingRequest as NotificationSettingRequest } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { notificationSettingsActions } from '../../../../actions/notification-settings-actions';
import { ClickableElement } from '../../../../components/common';
import Checkbox from '../../../../components/common/checkbox';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IRootState } from '../../../../main';
import { NotificationChannel, NotificationTemplateCode, NotificationTemplateGroup } from '../../../../models/model';

interface IBulkNotificationSettingOverviewProps {
    selectedDeliveryPointIds: string[];
    selectedNotificationTemplateGroups: NotificationTemplateGroup[];
    selectedNotificationTemplateTypes: NotificationTemplateCode[];
    notificationChannels: {
        email: boolean;
        sms: boolean;
    };
    setCommChannels: React.Dispatch<
        React.SetStateAction<{
            email: boolean;
            sms: boolean;
        }>
    >;
    startSelectDeliveryPoints: () => void;
    startSelectNotifications: () => void;
    onCancel: () => void;
    onSuccess: () => void;
}

const BulkNotificationSettingOverview: React.FC<IBulkNotificationSettingOverviewProps> = ({
    selectedDeliveryPointIds,
    selectedNotificationTemplateGroups,
    selectedNotificationTemplateTypes,
    notificationChannels,
    setCommChannels,
    startSelectDeliveryPoints,
    startSelectNotifications,
    onCancel,
    onSuccess,
}) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const api = useApi();
    const requestNotificationSettingsChange = async ({
        deliveryPointIds,
        notificationSettings,
    }: {
        deliveryPointIds: string[];
        notificationSettings: NotificationSettingRequest[];
    }) =>
        loggedInCustomer?.id == null
            ? null
            : await api.customers.changeNotificationSettings(
                  loggedInCustomer.id,
                  {
                      unitedDeliveryPointIds: deliveryPointIds,
                      notificationSettings: notificationSettings,
                  },
                  { secure: true },
              );

    const { addToast } = useToasts();
    const [mutateNotificationSetting, { isLoading }] = useMutationWithError(requestNotificationSettingsChange, {
        onSuccess: () => {
            addToast(<Trans i18nKey="settings.notifications.notifications-have-been-set" />, {
                appearance: 'success',
            });
            onSuccess();
        },
        onErrorWithGlobalErrorHandling: () => false,
    });

    const dispatch = useDispatch();
    const executeBulkSetting = (enabled: boolean) => async () => {
        const notificationChannelsArray: NotificationChannel[] = [];
        if (notificationChannels.email) notificationChannelsArray.push('EMAIL');
        if (notificationChannels.sms) notificationChannelsArray.push('SMS');
        dispatch(
            notificationSettingsActions.setBulkNotificationSetting(
                selectedDeliveryPointIds,
                selectedNotificationTemplateTypes,
                notificationChannelsArray,
                enabled,
            ),
        );
        const createNotificationSettingRequest = (code: NotificationTemplateCode) => {
            const notificationSettingRequest: NotificationSettingRequest = { templateCode: code };
            if (notificationChannels.email) notificationSettingRequest.email = enabled;
            if (notificationChannels.sms) notificationSettingRequest.sms = enabled;
            return notificationSettingRequest;
        };
        await mutateNotificationSetting({
            deliveryPointIds: selectedDeliveryPointIds,
            notificationSettings: selectedNotificationTemplateTypes.map((x) => createNotificationSettingRequest(x)),
        });
    };

    return (
        <>
            {isLoading && <LoadingIndicator />}
            <p>
                <Trans i18nKey="settings.notifications.bulk-notification-setting.notifications" />:
            </p>
            <Card className="mb-4">
                <CardBody>
                    <ClickableElement
                        className={classNames('text-center d-block font-weight-bold', {
                            'text-danger': selectedNotificationTemplateGroups.length === 0,
                        })}
                        onClick={startSelectNotifications}
                    >
                        {selectedNotificationTemplateGroups.length === 0 ? (
                            <Trans i18nKey="settings.notifications.bulk-notification-setting.select-notifications" />
                        ) : (
                            <Trans
                                i18nKey="settings.notifications.bulk-notification-setting.notifications-count"
                                values={{ count: selectedNotificationTemplateGroups.length }}
                            />
                        )}
                    </ClickableElement>
                </CardBody>
            </Card>

            <p className="mt-4">
                <Trans i18nKey="settings.notifications.bulk-notification-setting.for-delivery-points" />:
            </p>
            <Card className=" mb-4">
                <CardBody>
                    <ClickableElement
                        className={classNames('text-center d-block font-weight-bold', { 'text-danger': selectedDeliveryPointIds.length === 0 })}
                        onClick={startSelectDeliveryPoints}
                    >
                        {selectedDeliveryPointIds.length === 0 ? (
                            <Trans i18nKey="settings.notifications.bulk-notification-setting.select-delivery-points" />
                        ) : (
                            <Trans
                                i18nKey="settings.notifications.bulk-notification-setting.delivery-points-count"
                                values={{ count: selectedDeliveryPointIds.length }}
                            />
                        )}
                    </ClickableElement>
                </CardBody>
            </Card>

            <Row>
                <Col xs={12}>
                    <p className="mt-4">
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.for-comm-channel" />:
                    </p>
                </Col>
                <Col xs={12} sm={6}>
                    <Checkbox
                        checked={notificationChannels.email}
                        onChange={() => setCommChannels((prev) => ({ ...prev, email: !prev.email }))}
                        id="notificationChannelEmail"
                        name=""
                        label={<Trans i18nKey="settings.notifications.email" />}
                        className="mb-1"
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Checkbox
                        checked={notificationChannels.sms}
                        onChange={() => setCommChannels((prev) => ({ ...prev, sms: !prev.sms }))}
                        id="notificationChannelSMS"
                        name=""
                        label={<Trans i18nKey="settings.notifications.sms" />}
                        className="mb-1"
                    />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <Button
                        type="button"
                        disabled={
                            selectedDeliveryPointIds.length === 0 ||
                            selectedNotificationTemplateTypes.length === 0 ||
                            (!notificationChannels.email && !notificationChannels.sms)
                        }
                        block
                        color="primary"
                        className="mb-1"
                        onClick={executeBulkSetting(false)}
                    >
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.turn-off" />
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="button"
                        disabled={
                            selectedDeliveryPointIds.length === 0 ||
                            selectedNotificationTemplateTypes.length === 0 ||
                            (!notificationChannels.email && !notificationChannels.sms)
                        }
                        block
                        color="primary"
                        className="mb-1"
                        onClick={executeBulkSetting(true)}
                    >
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.turn-on" />
                    </Button>
                </Col>
            </Row>

            <Row className="mt-4 mb-3">
                <Col>
                    <hr className="mt-2" />
                </Col>
                <Col xs="auto" className="p-0">
                    <h5 className="forms-headline">
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.or" />
                    </h5>
                </Col>
                <Col>
                    <hr className="mt-2" />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button type="button" color="secondary" block onClick={onCancel} aria-label="Close">
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.cancel" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default BulkNotificationSettingOverview;
