import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { ConsumptionReadModalWithDpSelect } from '../consumption/components/consumption-read-modal-with-dp-select';
import { TABSEnums } from '../delivery-point-detail';

interface IMyDeductionsDropdownMenuProps {
    canEdit?: boolean;
    onTabChange: (value: TABSEnums, options?: { replaceUrl?: boolean; hash?: string }) => void;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
}

export const MyDeductionsDropdownMenu: React.FC<IMyDeductionsDropdownMenuProps> = ({ canEdit, onTabChange, unitedDeliveryPoint }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <DropdownMenu right>
                <DropdownItem
                    onClick={() => onTabChange(TABSEnums.notes, { hash: 'mynotes' })}
                    className={classNames('text-truncate py-3', {
                        'border-bottom': canEdit,
                    })}
                >
                    <Trans i18nKey="delivery-point.detail.show-my-notes" />
                </DropdownItem>
                {canEdit && (
                    <DropdownItem onClick={() => setIsModalOpen(true)} className="text-truncate py-3">
                        <Trans i18nKey="delivery-point.detail.add-new-self-deduction" />
                    </DropdownItem>
                )}
            </DropdownMenu>

            <ConsumptionReadModalWithDpSelect
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                myRead
                unitedDeliveryPoint={unitedDeliveryPoint}
                modalLabel={<Trans i18nKey="delivery-point.detail.consumption.my-diary-read" />}
            />
        </>
    );
};
