import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common/src/api';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardHeader, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions';
import { LoadingIndicator } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    BusinessPartnerTypeEnum,
    Checkbox,
    ClickableElement,
    CodeListTypeEnum,
    HelpModal,
    PaymentMethodCodeEnum,
    QueryKeysEnum,
    Routes,
    formatBusinessPartnerName,
    formatDateToPreview,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IPaymentMethod } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

type FinalPeriodeType = {
    price: number;
    monthPostfix: string;
};

type OrderCheckboxType = {
    personalInfoProcessingConcent: boolean;
    personalInfoProcessing: boolean;
};
const BlockCompletionCarbonFootprint: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { content, customerRequestTemplate, businessPartner } = useSelector((state: IRootState) => state.customerRequest);
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const useRecaptcha =
        process.env.REACT_APP_RECAPTCHA === 'true' &&
        !loggedInCustomer &&
        (content.reCaptchaToken?.remainingUsesCount === undefined || content.reCaptchaToken?.remainingUsesCount === 0);
    const [key, setKey] = useState(0);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const { formRules } = useFormRules();

    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const [ee, setEe] = useState<number>();
    const [zp, setZp] = useState<number>();
    const [finalPeriode, setFinalPeriode] = useState<FinalPeriodeType>();
    const [paymentType, setPaymentType] = useState<PaymentMethodCodeEnum>(PaymentMethodCodeEnum.INKASO);

    const { register, handleSubmit, errors } = useForm<OrderCheckboxType>();
    const initializeRequestCreationProcess = useSendRequest();

    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const [personalInfoProcessingConcentShown, setPersonalInfoProcessingConcentShown] = useState(false);

    const [isPersonalInfoProcessingOpen, setIsPersonalInfoProcessingOpen] = useState(false);
    const [personalInfoProcessingShown, setPersonalInfoProcessingShown] = useState(false);

    const showPersonalInfoProcessingConcent = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingConcentOpen(true);
    };

    const showPersonalInfoProcessing = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingOpen(true);
    };

    useEffect(() => {
        if (isPersonalInfoProcessingConcentOpen) setPersonalInfoProcessingConcentShown(true);
    }, [isPersonalInfoProcessingConcentOpen, setPersonalInfoProcessingConcentShown]);

    useEffect(() => {
        if (isPersonalInfoProcessingOpen) setPersonalInfoProcessingShown(true);
    }, [isPersonalInfoProcessingOpen, setPersonalInfoProcessingShown]);

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        // payment
        const recastPaymentMethod: (IPaymentMethod & { paymentTypeId?: string }) | undefined = content.paymentMethod;

        if (recastPaymentMethod?.paymentTypeId) {
            setPaymentType(
                (paymentTypeCodeList?.find((item) => item.uuid === recastPaymentMethod?.paymentTypeId)?.code as PaymentMethodCodeEnum) ??
                    PaymentMethodCodeEnum.SIPO,
            );
        }
    }, [paymentTypeCodeList, content]);

    useEffect(() => {
        //Products
        let prepareEePrice = undefined;
        let prepareZpPrice = undefined;
        const recastZpLevel: (SkSppNzpCommonsApiCodelistCodeListItem & { price?: string }) | undefined = {
            ...content.productLevel?.zpLevel,
            price: content.productLevel?.zpLevel?.attributes?.price,
        };
        if (recastZpLevel && recastZpLevel.price) prepareZpPrice = parseFloat(recastZpLevel.price || '0');

        const recastEeLevel: (SkSppNzpCommonsApiCodelistCodeListItem & { price?: string }) | undefined = content.productLevel?.eeLevel;
        if (recastEeLevel && recastEeLevel.price) prepareEePrice = parseFloat(recastEeLevel.price || '0');

        setEe(prepareEePrice);
        setZp(prepareZpPrice);
    }, [content]);

    useEffect(() => {
        // period

        const totalPrice = (ee || 0) + (zp || 0);
        const periodInMonths = parseInt(content.advancePaymentPeriod?.period?.attributes?.months || '1', 10);
        const price = periodInMonths * totalPrice;
        const monthPostfix = `${periodInMonths}months`;

        setFinalPeriode({ price, monthPostfix });
    }, [ee, zp, content]);

    const api = useApi();
    const [mutateRecaptchaToken] = useMutationWithError(
        async (recaptcha: string) =>
            api.customers.reCaptchaToken(
                { tokenType: 'ANONYMOUS_CUSTOMER_REQUEST' },
                { headers: { 'X-GoogleReCaptcha-Code': recaptcha }, secure: true },
            ),
        {
            onSuccess: (resp) => {
                if (resp.data) {
                    dispatch(CustomerRequestActions.setData({ reCaptchaToken: resp.data }));
                }
            },
        },
    );

    const onSubmit = async (formData: OrderCheckboxType) => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (useRecaptcha && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        useRecaptcha && recaptchaRef?.current?.reset();

        if (!loggedInCustomer && reCaptchaHelp) {
            const result = await mutateRecaptchaToken(reCaptchaHelp);
            if (result === undefined) {
                return;
            }
        }
        const data: any = {
            content: {
                metadata: {
                    businessPartnerId: businessPartner?.id,
                    customerAccountId: loggedInCustomer?.id,
                },
                invoiceDelivery: {
                    type: 'EMAIL',
                    email: content.invoiceDelivery?.email,
                },
                contact: content.accountInfo
                    ? {
                          phoneNumber: content.accountInfo.phoneNumber,
                          email: content.accountInfo.email,
                      }
                    : content.contact,
                address: content.address,
                correspondenceAddress: content.correspondenceAddress,
                businessPartner: content.accountInfo?.bpNumber ? { bpNumber: content.accountInfo?.bpNumber } : content.businessPartner,
                paymentMethod: content.paymentMethod,
                advancePaymentPeriod: content.advancePaymentPeriod,
                productLevel: content.productLevel?.zpLevel,
                approvals: {
                    personalInfoProcessingConcent: formData.personalInfoProcessingConcent,
                    personalInfoProcessing: formData.personalInfoProcessing,
                },
                type: customerRequestTemplate?.code,
            },
            customerRegistration: content.registration,
            customerRequestTemplate: customerRequestTemplate,
        };

        setLoading(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setLoading(false));
    };

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />
            <HelpModal
                show={isPersonalInfoProcessingOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_CONFIRMATION_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingOpen(false)}
            />
            <ListGroup>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <ItemPreview
                        title={
                            ee && zp
                                ? t('customer-request.steps.block-carbon-footprint-completion.products')
                                : t('customer-request.steps.block-carbon-footprint-completion.product')
                        }
                    >
                        <ItemPreviewContent>
                            <>
                                {ee && (
                                    <p className="mb-0">
                                        <b>
                                            {content.productLevel?.eeLevel?.name
                                                ? content.productLevel?.eeLevel?.name
                                                : t('customer-request.steps.block-carbon-footprint-completion.ee')}
                                        </b>
                                        <span className="ml-2">
                                            {ee + ' € / ' + t('customer-request.steps.block-carbon-footprint-completion.monthly')}
                                        </span>
                                    </p>
                                )}
                                {zp && (
                                    <p className="mb-0">
                                        <b>
                                            {content.productLevel?.zpLevel?.name
                                                ? content.productLevel?.zpLevel?.name
                                                : t('customer-request.steps.block-carbon-footprint-completion.zp')}
                                        </b>
                                        <span className="ml-2">
                                            {zp + ' € / ' + t('customer-request.steps.block-carbon-footprint-completion.monthly')}
                                        </span>
                                    </p>
                                )}
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>
                    <ItemPreview title={t('customer-request.steps.businessPartner.title')}>
                        <ItemPreviewContent>
                            {content.accountInfo
                                ? ''
                                : content.businessPartner?.name || businessPartner?.name
                                ? t('customer-request.steps.completion-transcript.company-name')
                                : t('customer-request.steps.completion-transcript.name-and-surname')}
                            <p>
                                <b>{formatBusinessPartnerName(businessPartner ?? content.businessPartner)}</b>
                            </p>
                            {content.businessPartner?.companyRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.company-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.companyRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.taxIdNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.tax-id-number')}
                                    <p>
                                        <b>{content.businessPartner?.taxIdNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.vatRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.vat-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.vatRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.legalForm && (
                                <>
                                    {t('customer-request.steps.businessPartner.legal-form')}
                                    <p>
                                        <b>{content.businessPartner?.legalForm}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.statutoryName && (
                                <>
                                    {t('customer-request.steps.businessPartner.name-statutory-representative')}
                                    <p>
                                        <b>{content.businessPartner?.statutoryName}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.birthDate && (
                                <>
                                    {t('customer-request.steps.completion-transcript.birth-date')}
                                    <p>
                                        <b>{formatDateToPreview(content.businessPartner.birthDate)}</b>
                                    </p>
                                </>
                            )}
                            {businessPartner?.primaryAddress && (
                                <>
                                    {t('customer-request.steps.completion-transcript.address')}
                                    <p>
                                        <b>
                                            {businessPartner?.primaryAddress?.street || ''} {businessPartner?.primaryAddress?.streetNumber || ''}
                                            <br />
                                            {businessPartner?.primaryAddress?.city || ''} {businessPartner?.primaryAddress?.zipCode || ''}
                                            <br />
                                            {businessPartner?.primaryAddress?.country}
                                        </b>
                                    </p>
                                </>
                            )}
                            {content.address && (
                                <>
                                    {content.businessPartner?.type === BusinessPartnerTypeEnum.COMPANY
                                        ? t('customer-request.steps.completion-transcript.permanent-address-company')
                                        : t('customer-request.steps.completion-transcript.permanent-address')}
                                    <p>
                                        <b>
                                            {content.address?.street || ''} {content.address?.number || ''}
                                            <br />
                                            {content.address?.city || ''} {content.address?.postalCode || ''}
                                            <br />
                                            {countryCodeList?.find((item) => item.uuid === content.address?.country?.uuid)?.name || ''}
                                        </b>
                                    </p>
                                    {content.address?.floor && (
                                        <>
                                            {t('customer-request.steps.address.floor')}
                                            <p>
                                                <b>{content.address?.floor}.</b>
                                            </p>
                                        </>
                                    )}
                                    {content.address?.flatNumber && (
                                        <>
                                            {t('customer-request.steps.address.appartments-number')}
                                            <p>
                                                <b>{content.address?.flatNumber}</b>
                                            </p>
                                        </>
                                    )}
                                </>
                            )}

                            {content.address && (
                                <>
                                    {t('customer-request.steps.completion-transcript.correspondence-address')}
                                    <p>
                                        <b>
                                            {content.correspondenceAddress?.street ?? (content.address?.street || '')}{' '}
                                            {content.correspondenceAddress?.number ?? (content.address?.number || '')}
                                            <br />
                                            {content.correspondenceAddress?.city ?? (content.address?.city || '')}{' '}
                                            {content.correspondenceAddress?.postalCode ?? (content.address?.postalCode || '')}
                                            <br />
                                            {countryCodeList?.find(
                                                (item) =>
                                                    item.uuid === (content.correspondenceAddress?.country?.uuid ?? content.address?.country?.uuid),
                                            )?.name || ''}
                                        </b>
                                    </p>
                                </>
                            )}

                            {content.contact && (
                                <>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        <b>
                                            {`${t('customer-request.steps.contact.phone-number')}: ${content.contact?.phoneNumber}`}
                                            <br />
                                            {`${t('customer-request.steps.contact.email')}: ${content.contact?.email}`}
                                        </b>
                                    </p>
                                </>
                            )}
                            {content.accountInfo && (
                                <>
                                    {t('customer-request.steps.businessPartner.customer-number-spp')}
                                    <p>
                                        <b>{content.accountInfo.bpNumber}</b>
                                    </p>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        {t('customer-request.steps.contact.phone-number')}: <b>{content.accountInfo?.phoneNumber}</b>
                                        <br />
                                        {t('customer-request.steps.contact.email')}: <b>{content.accountInfo?.email}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                    <ItemPreview title={t('customer-request.steps.block-carbon-footprint-completion.payment')}>
                        <ItemPreviewContent bold>
                            <>
                                {finalPeriode?.price.toFixed(2)} € / {t('customer-request.steps.payment-period.' + finalPeriode?.monthPostfix)}
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>
                    <ItemPreview title={t('customer-request.steps.block-carbon-footprint-completion.payment-method')}>
                        <ItemPreviewContent bold>
                            <>
                                {paymentType === PaymentMethodCodeEnum.INKASO
                                    ? t('customer-request.steps.payment-method.inkaso')
                                    : t('customer-request.steps.payment-method.bank-transfer')}
                                <br />
                                {t('customer-request.steps.block-carbon-footprint-completion.iban-number') + content.paymentMethod?.iban}
                                <p className="mb-0">{content.paymentMethod?.bicSwift && ' BIC/SWIFT: ' + content.paymentMethod?.bicSwift}</p>
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>
                    <ItemPreview title={t('customer-request.steps.block-carbon-footprint-completion.invoice-delivery')}>
                        <ItemPreviewContent bold>
                            <>{content.invoiceDelivery?.email}</>
                        </ItemPreviewContent>
                    </ItemPreview>
                    <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            className="mb-1"
                            id="personalInfoProcessingConcent"
                            name="personalInfoProcessingConcent"
                            label={
                                <Trans
                                    i18nKey="customer-request.steps.terms-condition.personal-info-processing-consent"
                                    components={{
                                        Link: (
                                            <ClickableElement
                                                isText
                                                onClick={showPersonalInfoProcessingConcent}
                                                className="text-decoration-underline-inverse"
                                            >
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            onChange={() => {
                                if (!personalInfoProcessingConcentShown) setIsPersonalInfoProcessingConcentOpen(true);
                            }}
                        />
                    </CardHeader>
                    <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            className="mb-1"
                            id="personalInfoProcessing"
                            name="personalInfoProcessing"
                            label={
                                <Trans
                                    i18nKey="customer-request.steps.terms-condition.personal-info-processing"
                                    components={{
                                        Link: (
                                            <ClickableElement
                                                isText
                                                onClick={showPersonalInfoProcessing}
                                                className="text-decoration-underline-inverse"
                                            >
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            onChange={() => {
                                if (!personalInfoProcessingShown) setIsPersonalInfoProcessingOpen(true);
                            }}
                        />
                    </CardHeader>
                    {useRecaptcha && (
                        <div className="mt-4">
                            <ReCAPTCHA
                                key={key}
                                ref={recaptchaRef}
                                sitekey={useRecaptcha ? recaptchaSiteKey : 'undefined'}
                                onChange={() => setReCaptchaError(false)}
                                hl={i18n.language === 'en' ? 'en' : 'sk'}
                            />
                            {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                        </div>
                    )}
                    <BaseButton type="submit">
                        <Trans i18nKey="customer-request.steps.block-clean-energy-completion.order" />
                    </BaseButton>
                </form>
            </ListGroup>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <Card className="w-100">
                        <div className="card-body text-center py-5">
                            <i className="icon-circle-ok-16 fa-lg mb-1"></i>
                            <h6>
                                <Trans i18nKey="customer-request.message.request-been-sent">Žiadosť bola odoslaná</Trans>
                            </h6>
                            <h4 className="mt-4">
                                <Trans
                                    i18nKey="customer-request.steps.block-carbon-footprint-completion.confirmation-text"
                                    defaults="Po spracovaní žiadosti Vám na zadanú emailovú adresu {{email}} zašleme návrh zmluvy."
                                    values={{
                                        email: content.invoiceDelivery?.email,
                                    }}
                                    components={{ br: <br /> }}
                                />
                            </h4>
                            {content.registration && content.registration.email && (
                                <>
                                    <h4>
                                        <Trans
                                            i18nKey="registration.link-in-email"
                                            values={{ email: content.registration.email }}
                                            components={{ strong: <strong /> }}
                                        >
                                            Pre dokončenie registrácie kliknite na odkaz, ktorý sme vám poslali na adresu
                                            <strong>{content.registration.email}</strong>.
                                        </Trans>
                                    </h4>
                                </>
                            )}

                            {loggedInCustomer && (
                                <>
                                    <p className="mt-5">
                                        <Trans i18nKey="delivery-point.detail.modals.follow-requests-status-in-my-spp-reqests">
                                            Stav žiadosti môžete sledovať v Moje&nbsp;SPP - v časti Žiadosti.
                                        </Trans>
                                    </p>
                                    <Link to={Routes.CUSTOMER_REQUESTS} className="btn btn-light btn-block btn-lg mt-3">
                                        <Trans i18nKey="delivery-point.detail.modals.navigate-to-requests">Prejsť na žiadosti</Trans>
                                    </Link>
                                </>
                            )}
                            <Button
                                color="primary"
                                block
                                size="lg"
                                className={loggedInCustomer ? 'mt-3' : 'mt-5'}
                                onClick={() => {
                                    onRequestSentSuccessConfirmed();
                                }}
                            >
                                <Trans i18nKey="delivery-point.detail.modals.ok">OK</Trans>
                            </Button>
                        </div>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    );
};

export default BlockCompletionCarbonFootprint;
