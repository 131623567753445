import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointAddress as UnitedDeliveryPointAddress } from '@spp/spp-meru-frontend-common';
import React from 'react';

type Props = { fullAddress?: UnitedDeliveryPointAddress };

export const AddressDetailsCell: React.FC<Props> = ({ fullAddress }) => {
    return (
        <td>
            {fullAddress?.street} {fullAddress?.streetNumber}
            <br />
            <small className="text-secondary">{fullAddress?.city}</small>
        </td>
    );
};
