import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IDeliveryPointZpConnection } from '../../../../../../models/customer-request-model';
import { DeliveryPointZpConnectionEnum } from '../../../../../../models/enums';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IDeliveryPointZpConnection;
    bold?: boolean;
};

const DeliveryPointZpConnectionItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.gas-connection.title')}>
                <div className="mt-1">
                    {data.type === DeliveryPointZpConnectionEnum.RENEWAL
                        ? t('customer-request.steps.gas-connection.switching-provider')
                        : t('customer-request.steps.gas-connection.new-connection')}
                </div>
                <div className="mt-1">
                    {data.currentDistributor && (
                        <>
                            <ItemPreviewHeader title={t('customer-request.steps.electricity-connection.original-supplier')} />
                            {data.currentDistributor}
                        </>
                    )}
                </div>
                {data.type !== DeliveryPointZpConnectionEnum.NEW && data.contractTermination != null && (
                    <div className="mt-1">
                        <ItemPreviewHeader title={t('customer-request.steps.change-supplier.arrange-contract-termination-on-your-behalf')} />
                        <p>
                            {data.contractTermination ? (
                                <Trans i18nKey="common.yes" />
                            ) : (
                                <Trans i18nKey="customer-request.steps.change-supplier.no-arrange-termination-myself" />
                            )}
                        </p>
                    </div>
                )}
                {data.contractTermination && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.change-supplier.binding-on-your-contract')} />
                        <p>
                            {data.bindingTo ? (
                                <Trans i18nKey="common.yes" />
                            ) : (
                                <Trans i18nKey="customer-request.steps.change-supplier.contract-no-binding" />
                            )}
                        </p>
                    </>
                )}
                {data.bindingTo && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.binding-to')}: ${formatDateToPreview(
                        data.bindingTo,
                    )}`}</ItemPreviewContent>
                )}
                {data.noticePeriod != null && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.notice-period')}: ${
                        data.noticePeriod
                    }`}</ItemPreviewContent>
                )}
            </ItemPreview>
        </>
    );
};

export default DeliveryPointZpConnectionItem;
