import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import HelpText from '../../components/help/help-text';

interface IContactModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

const ContactModal: React.FC<IContactModalProps> = ({ isOpen, onCloseModal }) => {
    return (
        <>
            <Modal isOpen={isOpen} centered modalClassName="modal-fullscreen" className="modal-wider">
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="contact.title">Kontakty</Trans>
                    </h3>
                    <button type="button" onClick={onCloseModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody>
                    <div className="container" style={{ position: 'relative' }}>
                        <HelpText screen="KTK" field="KTK_CONTENT" />
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ContactModal;
