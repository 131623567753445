import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { PaymentMethodCodeEnum } from '../../../../../../main';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

type Props = DefaultFormType;

const PaymentSipo: React.FC<Props> = ({ register, unregister, errors, trigger, setValue }) => {
    const { formRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const paymentMethod = useSelector((state: IRootState) => state.customerRequest.content.paymentMethod);
    const touched = lastOpened > current ? true : false;

    const [isInputDisabled, setIsInputDisabled] = useState(
        paymentMethod?.paymentTypeId && paymentMethod.paymentType.code === PaymentMethodCodeEnum.SIPO && !paymentMethod.sipoNumber ? true : false,
    );

    const onCheckboxClick = () => {
        const willBeDisabled = !isInputDisabled;
        if (willBeDisabled) {
            setValue && setValue('sipoNumber', '');
        }
        setIsInputDisabled(willBeDisabled);
    };

    useEffect(() => {
        if (isInputDisabled) {
            unregister && unregister('sipoNumber');
        }
    }, [isInputDisabled, unregister]);

    return (
        <>
            {!isInputDisabled && (
                <Input
                    ref={register(isInputDisabled ? {} : { ...formRules.required, ...formRules.length(10) })}
                    errors={errors}
                    type="number"
                    name="sipoNumber"
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    label={<Trans i18nKey="customer-request.steps.payment-method.sipo-number">Evidenčné číslo SIPO</Trans>}
                    trigger={trigger}
                    touched={touched}
                    disabled={isInputDisabled}
                />
            )}

            <div className="custom-control custom-checkbox mb-3">
                <input
                    checked={isInputDisabled}
                    type="checkbox"
                    className="custom-control-input"
                    id="sipoNumberNotAssigned"
                    onChange={onCheckboxClick}
                />
                <label className="custom-control-label" htmlFor="sipoNumberNotAssigned">
                    <Trans i18nKey="delivery-point.detail.contact-data.no-sipo-number" />
                </label>
            </div>
        </>
    );
};

export default PaymentSipo;
