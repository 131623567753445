import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { formatDate } from '../../../../../utils/utils';

type Props = {
    kindName?: string;
    reasonName?: string;
    description?: string;
    readAt?: string;
    value?: number;
    tariff?: string;
    isOpen: boolean;
    toggle: () => void;
    onDelete?: () => void;
    isInfo: boolean;
};

function ConsumptionRow({ kindName, reasonName, description, readAt, value, tariff, isOpen, toggle, onDelete, isInfo }: Props): ReactElement {
    const readAtFormatted = readAt && formatDate(new Date(readAt));
    const [t] = useTranslation();

    return (
        <Fragment>
            <tr data-toggle="collapse" data-parent="#tableConsumption" onClick={toggle} className="d-lg-none">
                <td>{readAtFormatted}</td>
                <td className="d-none d-lg-table-cell">
                    {kindName && <div>{kindName}</div>}
                    {reasonName && <small className="text-secondary">{reasonName}</small>}
                    {isInfo && <div>{t('delivery-point.detail.consumption.my-diary-read')}</div>}
                    {description && <small className="text-secondary">{description}</small>}
                </td>
                <td className="text-right">
                    {isInfo && <i className="icon-book-open"></i>} {value} {tariff && ` (${tariff})`}
                </td>
                <td className="text-nowrap text-right">
                    <i className={`${!isOpen ? 'icon-chevron-down-16' : 'icon-chevron-top-16'} d-lg-none`}></i>
                </td>
            </tr>
            <tr data-toggle="collapse" data-parent="#tableConsumption" className={'display-large-screen'}>
                <td>{readAtFormatted}</td>
                <td className="d-none d-lg-table-cell">
                    {kindName && <div>{kindName}</div>}
                    {reasonName && <small className="text-secondary">{reasonName}</small>}
                    {isInfo && <div>{t('delivery-point.detail.consumption.my-diary-read')}</div>}
                    {description && <small className="text-secondary">{description}</small>}
                </td>
                <td className="text-right">
                    {isInfo && <i className="icon-book-open"></i>} {value}
                </td>
                <td className="text-middle">{tariff}</td>
                <td className="text-nowrap text-right">
                    {onDelete && (
                        <button type="button" className="btn btn-link d-none d-lg-inline-block" onClick={onDelete}>
                            <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                        </button>
                    )}
                    <i className={`${!isOpen ? 'icon-chevron-down-16' : 'icon-chevron-top-16'} d-lg-none`}></i>
                </td>
            </tr>
            <tr>
                <td colSpan={4} style={{ display: isOpen ? '' : 'none' }}>
                    <Collapse isOpen={isOpen} tag={'div'} className={`text-center d-lg-none`}>
                        {kindName && <div>{kindName}</div>}
                        {reasonName && <small className="text-secondary">{reasonName}</small>}
                        {description && <small className="text-secondary">{description}</small>}
                        {onDelete && (
                            <div>
                                <button type="button" className="btn btn-link" onClick={onDelete}>
                                    <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                                </button>
                            </div>
                        )}
                    </Collapse>
                </td>
            </tr>
        </Fragment>
    );
}

export default ConsumptionRow;
