import { SkSppNzpBeApiHelpComponentHelp } from '@spp/spp-meru-frontend-common/src/api';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { useApi } from '../../hooks/use-api';

type HelpModalProps = {
    show: boolean;
    wide?: boolean;
    title: string;
    screen: string;
    field: string;
    cancel: () => void;
};

const HelpModal: React.FC<HelpModalProps> = ({ show, wide, title, screen, field, cancel }) => {
    const locale = i18n.language;
    const api = useApi();
    const [data, setData] = useState<SkSppNzpBeApiHelpComponentHelp[]>();
    const [error, setError] = useState<string>();

    const fetchComponentHelpContent = useCallback(() => {
        api.componentHelps
            .getAllComponents({
                content: true,
                screen: [screen],
                field: [field],
                locale,
            })
            .then((res) => {
                setError(undefined);
                setData(res.data ? res.data : undefined);
            })
            .catch((err) => {
                setError('' + err);
            });
    }, [screen, field, api.componentHelps, locale]);

    useEffect(() => {
        if (show) {
            fetchComponentHelpContent();
        }
    }, [show, fetchComponentHelpContent]);

    const content = data && data.length && data[0].i18ns && data[0].i18ns.length ? data[0].i18ns[0].content : '';
    const minWidth = wide ? '80%' : undefined;

    return (
        <Modal isOpen={show} centered style={{ minWidth }}>
            <ModalBody>
                <button type="button" onClick={cancel} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                {title && (
                    <h3 className="mb-4">
                        <Trans i18nKey={title}>{title}</Trans>
                    </h3>
                )}
                <span style={{ color: 'red' }}>{error ? error : null}</span>
                <div dangerouslySetInnerHTML={{ __html: '' + content }} />
            </ModalBody>
        </Modal>
    );
};

export default HelpModal;
