import { SkSppNzpBeApiCustomerprofileInvoice, SkSppNzpBeApiCustomerprofileInvoiceSummary } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { formatCurrency } from '../../../../main';

interface IInvoicePaymentButtonProps {
    invoice: SkSppNzpBeApiCustomerprofileInvoice;
    onClick: (invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary) => void;
    isEmployee: boolean;
}

export const InvoicePaymentButton: React.FC<IInvoicePaymentButtonProps> = ({ invoice, isEmployee, onClick }) => {
    const isPaymentButtonRendered = invoice.paymentVisibility !== 'HIDDEN';
    const isPaymentDisabled = invoice.paymentVisibility?.startsWith('DISABLED');

    const isButtonDisabled = isEmployee || isPaymentDisabled;
    const paymentButtonRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    return (
        <>
            {isPaymentButtonRendered && (
                <>
                    <div ref={paymentButtonRef}>
                        <button
                            type="button"
                            className={classNames('btn btn-primary btn-lg', { 'disabled cursor-default': isButtonDisabled })}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!isButtonDisabled) {
                                    onClick(invoice);
                                }
                            }}
                        >
                            {invoice?.typeGroup === 'REPAYMENT_PLAN'
                                ? t('invoices.detail.pay-all-repayments', { sum: formatCurrency(invoice.unpaid || 0) })
                                : t('invoices.detail.pay', { sum: formatCurrency(invoice.unpaid || 0) })}
                        </button>
                    </div>

                    {isPaymentDisabled && (
                        <UncontrolledTooltip placement="top" target={paymentButtonRef}>
                            {(invoice.paymentVisibility === 'DISABLED_PAYMENT_INIT' || invoice.paymentVisibility === 'DISABLED_PAYMENT_OK') && (
                                <Trans i18nKey="invoices.table.payment-in-processing" />
                            )}

                            {invoice.paymentVisibility === 'DISABLED_REPAYMENT_PLAN' && <Trans i18nKey="invoices.table.invoice-has-repayment-plan" />}

                            {invoice.paymentVisibility === 'DISABLED_COLLECION' && <Trans i18nKey="invoices.table.payment-expected-by-due-date" />}
                            {invoice.paymentVisibility === 'DISABLED_SYNC' && <Trans i18nKey="invoices.table.payment-sync" />}
                        </UncontrolledTooltip>
                    )}
                </>
            )}
        </>
    );
};
