import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomNavPill } from '../../../components/common/custom-nav-pill';
import { can } from '../../../components/permissions';
import { IRootState } from '../../../reducers';
import { SettingsRoutes } from '../../../routes/routes';

const SettingsNavigation: React.FC = () => {
    const [t] = useTranslation();

    const employee = useSelector((state: IRootState) => state.user.employee);

    return (
        <>
            <div className="d-flex flex-row justify-content-center">
                <CustomNavPill route={SettingsRoutes.LOGIN_DETAILS} label={t('settings.navigation.login-details')} />
                <CustomNavPill
                    route={SettingsRoutes.EINVOICES}
                    label={t('settings.navigation.e-invoices')}
                    disabled={employee && !can('ENTITY_DELIVERY_POINTS_VIEW', employee)}
                />
                <CustomNavPill
                    route={SettingsRoutes.NOTIFICATIONS}
                    label={t('settings.navigation.notifications')}
                    disabled={employee && !can('ENTITY_DELIVERY_POINTS_VIEW', employee)}
                />
            </div>
        </>
    );
};

export default SettingsNavigation;
