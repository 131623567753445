import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerconsumptionMeterReading,
    SkSppNzpBeApiCommonPaging,
    SkSppNzpBeApiCustomerconsumptionMeterReading,
    SkSppNzpBeApiCustomerconsumptionMeterReadingSearch,
} from '@spp/spp-meru-frontend-common';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/use-api';
import { usePaginatedQueryWithError } from '../../../../hooks/use-paginated-query-with-error';
import { MeteringTypes } from '../../../../models/enums';
import { QueryKeysEnum } from '../../../../utils/react-query-utils';
import { CommodityProps } from './consumption';

export interface IResultData extends SkSppNzpBeApiCustomerconsumptionMeterReading {
    isOpen: boolean;
    toggle: () => void;
}

type Data = {
    paging?: SkSppNzpBeApiCommonPaging;
    result?: IResultData[];
};

type Props = {
    contractId?: string;
    timestampFrom?: string;
    timestampTo?: string;
    paging: SkSppNzpBeApiCommonPaging;
    // TODO get from swagger
    categories: MeteringTypes[];
    units: SkSppNzpBeApiCustomerconsumptionMeterReadingSearch['units'];
    selectedCommodity: CommodityProps;
};

export default function useFetchMeterReading({
    contractId,
    timestampFrom,
    timestampTo,
    paging,
    categories,
    units,
    selectedCommodity,
}: Props): { isLoading: boolean; error: unknown; data: Data } {
    const api = useApi();
    const [mappedData, setMappedData] = useState<Data>({ paging: {}, result: [] });

    const fetchDeliveryPoint = useCallback(
        async (contractId: string, data: SkSppNzpBeApiCustomerconsumptionMeterReadingSearch = {}) => {
            if (!contractId) {
                return;
            }
            if (selectedCommodity.type === 'EE') {
                data.units = 'KWH';
            }

            return api.deliveryPoints.meterReadingSearch(contractId, data, { secure: true });
        },
        [api.deliveryPoints, selectedCommodity.type],
    );

    const {
        isLoading,
        error,
        data,
    } = usePaginatedQueryWithError<SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerconsumptionMeterReading | null>({
        queryKey: [QueryKeysEnum.CONSUMPTION_TABLE, contractId, timestampFrom, timestampTo, categories, paging, units],
        queryFn: () =>
            contractId == null
                ? null
                : fetchDeliveryPoint(contractId, {
                      readingAt: {
                          from: timestampFrom ? timestampFrom : '',
                          to: timestampTo ? timestampTo : '',
                      },
                      paging,
                      categories: categories,
                      units,
                  }).then((res) => (res != null ? res.data : null)),
    });

    useEffect(() => {
        if (data) {
            const mappedDataTmp = data?.result?.map((record) => {
                const { id } = record;
                return {
                    ...record,
                    isOpen: false,
                    toggle: () => {
                        setMappedData((prevMappedData) => {
                            return {
                                ...prevMappedData,
                                result: prevMappedData?.result?.map((prevRecord) => {
                                    if (prevRecord.id === id) {
                                        return {
                                            ...prevRecord,
                                            isOpen: !prevRecord.isOpen,
                                        };
                                    } else {
                                        return prevRecord;
                                    }
                                }),
                            };
                        });
                    },
                };
            });
            const mappedBoth = {
                ...data,
                result: mappedDataTmp,
            };
            setMappedData(mappedBoth);
        }
    }, [setMappedData, data]);

    return { isLoading, error, data: mappedData };
}
