import { faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../main';
import ContactModal from '../../../views/contact/contact-modal';
import { ClickableElement } from '../../common/clickable-element';

type RedirectLinksPropsType = {
    linkClicked: () => void;
    customerRequestType?: 'fullScreen' | 'modal';
};

const RedirectLinks: React.FC<RedirectLinksPropsType> = ({ linkClicked, customerRequestType }) => {
    const { i18n } = useTranslation();
    const sppUrl = 'https://www.spp.sk/';
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const toggleLanguage = () => {
        if (i18n.language === 'en') {
            localStorage.setItem('i18n.locale', 'sk');
            i18n.changeLanguage('sk');
        } else {
            localStorage.setItem('i18n.locale', 'en');
            i18n.changeLanguage('en');
        }
    };

    useEffect(() => {
        const keyPressed = ({ key, ctrlKey, altKey, shiftKey }: KeyboardEvent) => {
            if (key === 'X' && ctrlKey && altKey && shiftKey) {
                i18n.changeLanguage('xx');
            }
        };
        window.addEventListener('keyup', keyPressed);
        return () => {
            window.removeEventListener('keyup', keyPressed);
        };
    });

    return (
        <>
            <div
                className={classNames('upper-level', 'p-0', {
                    'position-relative': customerRequestType === 'fullScreen',
                })}
            >
                {customerRequestType !== 'fullScreen' && (
                    <a href={sppUrl} className="mr-lg-auto nav-link menu-item pl-0">
                        <i className="icon-chevron-right-16 mr-4 d-lg-none"></i>
                        <FontAwesomeIcon icon={faLevelDownAlt} className="d-none d-lg-inline-block mr-2 fa-rotate-180" />
                        <Trans i18nKey="navbar.navigation.go-to">Prejsť na</Trans> www.spp.sk
                    </a>
                )}
                <NavLink
                    to={Routes.CONTACT}
                    className={classNames('nav-link', 'menu-item', 'pl-0', {
                        'ml-lg-auto': customerRequestType === 'fullScreen',
                    })}
                    onClick={(e) => {
                        if (customerRequestType === 'fullScreen') {
                            e.preventDefault();
                            setIsFilterModalOpen(true);
                        } else {
                            linkClicked();
                        }
                    }}
                >
                    <i className="icon-chevron-right-16 mr-4 d-lg-none"></i>
                    <Trans i18nKey="navbar.navigation.contact">Kontakt</Trans>
                </NavLink>
                <NavLink to={Routes.ABOUT_APPLICATION} className="d-block d-md-none nav-link menu-item pl-0" onClick={linkClicked}>
                    <i className="icon-chevron-right-16 mr-4 d-lg-none"></i>
                    <Trans i18nKey="navbar.about-app">O aplikácii</Trans>
                </NavLink>
                <ClickableElement onClick={toggleLanguage} className="nav-link menu-item pl-0 pr-2 text-left">
                    <i className="icon-chevron-right-16 mr-4 d-lg-none"></i>
                    <Trans i18nKey="navbar.language">English</Trans>
                </ClickableElement>
            </div>

            <ContactModal isOpen={isFilterModalOpen} onCloseModal={() => setIsFilterModalOpen(false)} />
        </>
    );
};

export default RedirectLinks;
