import { SkSppNzpBeApiCustomerprofileBusinessPartnerSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import BaseButton from '../../../components/common/base-button';
import { LoadingIndicator } from '../../../main';

type EnteredDataScreenProps = {
    close: () => void;
    onUpdateBpEmail: (bpId: string, email: string) => void;
    approvalRequired: boolean;
    portalEmail?: string;
    businessPartner?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    isLoading: boolean;
    emailSyncRequested: boolean;
};

const EnteredDataScreen: React.FC<EnteredDataScreenProps> = ({
    close,
    approvalRequired,
    portalEmail,
    businessPartner,
    onUpdateBpEmail,
    isLoading,
    emailSyncRequested,
}) => {
    const isEmailSyncEnabled = businessPartner?.id && !!portalEmail && portalEmail.toLowerCase() !== businessPartner.email?.toLowerCase();

    const handleEmailSyncPress = () => {
        if (businessPartner?.id && portalEmail) {
            onUpdateBpEmail(businessPartner.id, portalEmail);
        }
    };
    return (
        <>
            {isLoading && <LoadingIndicator />}
            <div className="text-center py-5">
                <i className="icon-circle-ok-16 fa-5x text-success d-block mb-3"></i>
                <h4 className="text-center">
                    {approvalRequired ? (
                        <Trans i18nKey="delivery-point.add-delivery-point.entered-data.success-msg-individual">
                            Odberné miesta boli načítané a ešte musia byť schválené obsluhou portálu.
                        </Trans>
                    ) : (
                        <Trans i18nKey="delivery-point.add-delivery-point.entered-data.success-msg-collective">
                            Odberné miesta boli úspešne načítané.
                        </Trans>
                    )}
                </h4>

                {isEmailSyncEnabled && (
                    <>
                        {emailSyncRequested ? (
                            <span className="text-center">
                                <Trans i18nKey="delivery-point.add-delivery-point.entered-data.change-email-done-description" />
                            </span>
                        ) : (
                            <>
                                <Trans
                                    i18nKey="delivery-point.add-delivery-point.entered-data.change-email-description"
                                    values={{
                                        bpEmail: businessPartner?.email?.toLowerCase(),
                                        portalEmail,
                                    }}
                                />
                                <BaseButton onClick={handleEmailSyncPress} className="btn-block btn-secondary btn-lg mt-4">
                                    <Trans i18nKey="delivery-point.add-delivery-point.entered-data.change-email-button" />
                                </BaseButton>
                            </>
                        )}
                    </>
                )}

                <BaseButton onClick={close}>
                    <Trans i18nKey="delivery-point.add-delivery-point.back">Späť na odberné miesta</Trans>
                </BaseButton>
            </div>
        </>
    );
};

export default EnteredDataScreen;
