import React from 'react';
import { Trans } from 'react-i18next';
import { Container } from 'reactstrap';
import { Breadcrumb } from '../../../../../main';

type Props = {
    code?: string;
    goBack: () => void;
};

const PrepareHeader: React.FC<Props> = ({ code, goBack }) => {
    return (
        <div className="page-header pb-4">
            <Container>
                <Breadcrumb onClick={goBack} title={<Trans i18nKey="customer-request.select-one" />} />

                <div className="pt-2">
                    <h2>
                        <Trans i18nKey={'enums.CustomerRequestType.' + code}>{'enums.CustomerRequestType.' + code}</Trans>
                    </h2>
                </div>
                <Trans i18nKey={'enums.CustomerRequestType.' + code + '_DESC'}>&nbsp;</Trans>
            </Container>
        </div>
    );
};

export default PrepareHeader;
