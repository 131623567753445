import React from 'react';
import { IComplainedAdvance } from '../../../../../../models/customer-request-model';
import ComplainedAdvanceItem from './complained-advance-item';

type Props = {
    data: IComplainedAdvance[];
    bold?: boolean;
};

const ComplainedAdvanceListItem: React.FC<Props> = ({ data, bold }) => {
    return (
        <>
            {data.map((item, index) => (
                <ComplainedAdvanceItem key={index} data={item} bold={bold} />
            ))}
        </>
    );
};

export default ComplainedAdvanceListItem;
