import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { DeliveryPointSwitchLabel } from '../delivery-point-switch-label';
import { DeliveryPointSwitchModal } from './delivery-point-switch-modal';

interface IDeliveryPointSwitchSelectProps {
    selectedDeliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined;
    deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    onSelect: (id: string | undefined) => void;
    showInactive: boolean;
    toggleInactive: (() => void) | undefined;
    additionalButton?: { label: React.ReactNode };
}

export const DeliveryPointSwitchSelect: React.FC<IDeliveryPointSwitchSelectProps> = ({
    deliveryPoints,
    selectedDeliveryPoint,
    onSelect,
    showInactive,
    toggleInactive,
    additionalButton,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const hasMultipleElectricityDps = useMemo(() => {
        const electricityCount = deliveryPoints.filter((x) => x.type === 'EE').length;
        return electricityCount > 1;
    }, [deliveryPoints]);

    const hasMultipleGasDps = useMemo(() => {
        const gasCount = deliveryPoints.filter((x) => x.type === 'ZP').length;
        return gasCount > 1;
    }, [deliveryPoints]);

    return (
        <>
            <button
                className="btn bg-white delivery-point-switch-select text-left d-flex align-items-stretch p-0"
                onClick={() => setIsModalOpen(true)}
                type="button"
            >
                <div className="px-4 py-2 d-flex" style={{ minWidth: '180px' }}>
                    {selectedDeliveryPoint != null && (
                        <DeliveryPointSwitchLabel
                            deliveryPoint={selectedDeliveryPoint}
                            showInvoiceNumber={selectedDeliveryPoint.type === 'ZP' ? hasMultipleGasDps : hasMultipleElectricityDps}
                        />
                    )}
                    {selectedDeliveryPoint == null && additionalButton != null && <div className="my-auto">{additionalButton.label}</div>}
                </div>

                <div className="d-flex align-items-center border-left px-3">
                    <i className="icon-chevron-down-16 d-block"></i>
                </div>
            </button>

            <DeliveryPointSwitchModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                deliveryPoints={deliveryPoints}
                selectedDpId={selectedDeliveryPoint?.id}
                onSelect={onSelect}
                showInactive={showInactive}
                toggleInactive={toggleInactive}
                additionalButton={additionalButton}
                showEeInvoiceNumbers={hasMultipleElectricityDps}
                showZpInvoiceNumbers={hasMultipleGasDps}
            />
        </>
    );
};
