import classNames from 'classnames';
import React from 'react';

interface IDeliveryPointSwitchModalButtonProps {
    onClick: () => void;
    isSelected: boolean;
}

export const DeliveryPointSwitchModalButton: React.FC<IDeliveryPointSwitchModalButtonProps> = ({ onClick, isSelected, children }) => {
    return (
        <>
            <div className="delivery-point-switch-modal-button position-relative mb-1">
                <button type="button" className={classNames('btn bg-white btn-block text-left')} onClick={onClick} style={{ height: '64px' }}>
                    {children}
                </button>
                {isSelected && <div className="selection-dot"></div>}
            </div>
        </>
    );
};
