import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { QueryKeysEnum } from '../../../../../../main';
import { IAdvancePaymentPeriod } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAdvancePaymentPeriod;
    bold?: boolean;
};

const NewAdvancePaymentPeriodItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: periodCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum.ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 30,
        },
    });

    const period = useMemo(() => {
        return periodCodeList?.find((item) => item.uuid === data.period?.uuid)?.name || null;
    }, [periodCodeList, data.period]);

    if (isObjectEmpty(data) || !period) return <></>;
    return (
        <>
            <ItemPreview title={t('customer-request.steps.new-data.new-payment-period')}>
                <ItemPreviewContent bold={bold}>{period}</ItemPreviewContent>
            </ItemPreview>
        </>
    );
};

export default NewAdvancePaymentPeriodItem;
