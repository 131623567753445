import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common/src/api';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, Col, Collapse, Row, UncontrolledTooltip } from 'reactstrap';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import {
    ClickableElement,
    CustomerAccountTypeEnum,
    CustomerRequestTypeEnum,
    IRootState,
    KINDcodeHome,
    LoadingIndicator,
    MARKETING_CONSENTS_ACCEPTED,
    MARKETING_CONSENTS_REJECTED,
    RequestTemplateCode,
    Routes,
    useApi,
} from '../../../../main';
import { DetailContactFormFieldEnum } from '../../../../reducers/interfaces/delivery-point-detail-state';
import {
    atLeastOneMarketingConsentExpireSoon,
    eachMarketingConsentIsRejected,
    expireMarketingConsentsSoon,
    formatAddress,
    formatBusinessPartnerName,
    formatSkPhoneNumber,
} from '../../../../utils/utils';
import { useSendRequest } from '../customer-requests/use-send-request';
import { ChangeCustomerAccountDataModal } from './contact-data-modals/change-customer-account-data-modal';
import { ChangePersonalDataModal } from './contact-data-modals/change-personal-data-modal';
import FormListItem from './form-list-item';
import RequestInProcess from './request-in-process';

interface IDeliveryPointDetailContactData {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    canEditOverride: boolean;
    unitedDeliveryPointId: string;
}

enum NotificationCardStateEnum {
    REGULAR = 'REGULAR',
    EXPIRE_SOON = 'EXPIRE_SOON',
}

const shouldDisplayNotificationBanner = (bannerInvisibleUntil?: string): boolean => {
    if (!bannerInvisibleUntil) {
        return true;
    }
    const givenDate = new Date(bannerInvisibleUntil);
    const currentDate = new Date();
    if (givenDate.getTime() - currentDate.getTime() > 0) {
        return false;
    }
    return true;
};

const DeliveryPointDetailContactData: React.FC<IDeliveryPointDetailContactData> = ({ deliveryPoint, canEditOverride }) => {
    const businessPartner = useSelector((state: IRootState) => state.customerRequest.additionalData?.udpd?.businessPartner);
    const [editedFormField, setEditedFormField] = useState<DetailContactFormFieldEnum | undefined>();
    const [loadingSetMarketingConsents, setLoadingSetMarketingConsents] = useState<boolean>(false);
    const initializeRequestCreationProcess = useSendRequest();
    const { addToast } = useToasts();
    const [showNotificationBanner, setShowNotificationBanner] = useState(
        businessPartner?.queue === CustomerAccountTypeEnum.BULK &&
            !businessPartner.consentRequestId &&
            (eachMarketingConsentIsRejected(businessPartner?.consents) || atLeastOneMarketingConsentExpireSoon(businessPartner.consents)) &&
            shouldDisplayNotificationBanner(businessPartner?.consentBannerInvisibleUntil),
    );
    const notificationBannerState = expireMarketingConsentsSoon(businessPartner?.consents)
        ? NotificationCardStateEnum.EXPIRE_SOON
        : NotificationCardStateEnum.REGULAR;
    const [t] = useTranslation();
    const api = useApi();
    const history = useHistory();

    const onStartEditFormField = (formField: DetailContactFormFieldEnum) => () => {
        setEditedFormField(formField);
    };
    const onModalClose = () => {
        setEditedFormField(undefined);
    };
    const [isExpandedCustomer, setIsExpandedCustomer] = useState(false);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    const marketingConsentsKeepActualSetting = async () => {
        setLoadingSetMarketingConsents(true);
        const template = await getRequestTemplates({ code: CustomerRequestTypeEnum.X_MS });
        if (template?.[0] != null && businessPartner?.id) {
            const news3rdParty = !!businessPartner.consents?.find((consent) => consent.partnersConsent);
            const bpConsentsEmail = businessPartner.consents?.find((consent) => consent.consentCommunicationChannel === 'EMAIL');
            const bpConsentsPhone = businessPartner.consents?.find((consent) => consent.consentCommunicationChannel === 'PHONE');
            const bpConsentsLetter = businessPartner.consents?.find((consent) => consent.consentCommunicationChannel === 'POST');
            const bpConsentsSms = businessPartner.consents?.find((consent) => consent.consentCommunicationChannel === 'SMS');

            const data: any = {
                content: {
                    metadata: {
                        businessPartnerId: businessPartner.id,
                    },
                    type: CustomerRequestTypeEnum.X_MS,
                    consent: {
                        NEWS_SPP: true,
                        NEWS_3RD_PARTY: news3rdParty,
                        COMMUNICATION_INT: bpConsentsEmail?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
                        COMMUNICATION_PAG: bpConsentsSms?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
                        COMMUNICATION_LET: bpConsentsLetter?.consent === MARKETING_CONSENTS_ACCEPTED,
                        COMMUNICATION_TEL: bpConsentsPhone?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
                    },
                },
                customerRequestTemplate: template[0],
            };
            initializeRequestCreationProcess(data)
                .then(() => {
                    addToast(t('settings.marketing-consents.saved-success', { customer: formatBusinessPartnerName(businessPartner) }));
                    setShowNotificationBanner(false);
                })
                .catch((err) => err.message)
                .finally(() => setLoadingSetMarketingConsents(false));
        } else {
            setLoadingSetMarketingConsents(false);
            addToast(t('settings.marketing-consents.template-error', { appearance: 'error' }));
        }
    };

    const contractAccount = deliveryPoint.contract?.contractAccount;
    const businessPartnerData = contractAccount?.businessPartner;

    const billingAddress = useMemo<string | undefined>(() => {
        if (!!contractAccount?.billingAddress?.city) {
            return formatAddress(contractAccount?.billingAddress);
        }
        return undefined;
    }, [contractAccount?.billingAddress]);

    const correspondenceAddress = useMemo<string | undefined>(() => {
        if (!!contractAccount?.postAddress?.city) {
            return formatAddress(contractAccount?.postAddress);
        }
        return undefined;
    }, [contractAccount?.postAddress]);

    const primaryAddress = useMemo<string | undefined>(() => {
        if (!!businessPartnerData?.primaryAddress) {
            return formatAddress(businessPartnerData?.primaryAddress);
        }
        return undefined;
    }, [businessPartnerData?.primaryAddress]);

    const isRequested = useCallback(
        (type: 'BusinessPartner' | 'ContractAccount') => {
            const fields =
                type === 'BusinessPartner'
                    ? deliveryPoint.contract?.contractAccount?.businessPartner?.fieldUpdates
                    : deliveryPoint.contract?.contractAccount?.fieldUpdates;
            const fieldUpdates = fields?.filter(
                (fu) =>
                    fu.type === (type === 'BusinessPartner' ? 'BUSINESS_PARTNER_ATTRIBUTES' : 'CONTRACT_ACCOUNT_ATTRIBUTES') &&
                    fu.customerRequest?.customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_ZOUA,
            );
            if (fieldUpdates == null || fieldUpdates?.length === 0) {
                return { result: false };
            }
            const customerRequestUuids = fieldUpdates.map((x) => x.customerRequest?.uuid).filter((x, i, a) => a.indexOf(x) === i);

            return { result: true, customerRequestUuid: customerRequestUuids.length >= 1 ? customerRequestUuids[0] : undefined };
        },
        [deliveryPoint],
    );

    const businessPartnerName = `${businessPartnerData?.titleFront?.name ?? ''} ${formatBusinessPartnerName(
        businessPartnerData,
    )} ${businessPartnerData?.titleBehind?.name ?? ''}`;

    const isHome = deliveryPoint.contract?.contractAccount?.businessPartner?.kind?.code === KINDcodeHome;

    const notInterestedInNotifications = () => {
        businessPartner?.id && changeConsentBannerVisibilityMutation({ bpId: businessPartner.id });
    };

    const [changeConsentBannerVisibilityMutation] = useMutationWithError<void, unknown, { bpId: string }>(async ({ bpId }) =>
        api.businessPartners.changeConsentBannerVisibility(bpId, { secure: true }).then(() => setShowNotificationBanner(false)),
    );

    const NotificationCard = () => {
        if (notificationBannerState === NotificationCardStateEnum.REGULAR) {
            return (
                <>
                    <h4>{t('delivery-point.detail.news-notification-data.turn-on-news')}</h4>
                    <div>{t('delivery-point.detail.news-notification-data.special-offers')}</div>
                    <Button
                        color="primary"
                        className="btn my-4 w-50"
                        onClick={() => history.push(`${Routes.MARKETING_CONSENTS}/${businessPartner?.id}`)}
                    >
                        {t('delivery-point.detail.news-notification-data.turn-on')}
                    </Button>
                </>
            );
        }
        if (notificationBannerState === NotificationCardStateEnum.EXPIRE_SOON) {
            const allConsentsPresented = businessPartner?.consents?.length === 4; //SMS,PHONE,LETTER,EMAIL
            return (
                <>
                    <h4>{t('delivery-point.detail.news-notification-data.expire-soon')}</h4>
                    <Row className="my-4 align-items-center">
                        {allConsentsPresented && (
                            <Col lg={6} className="mb-4 mb-lg-0">
                                <Button color="primary" className="btn w-100" onClick={marketingConsentsKeepActualSetting}>
                                    {t('delivery-point.detail.news-notification-data.keep-current-setting')}
                                </Button>
                            </Col>
                        )}
                        <Col>
                            <ClickableElement
                                onClick={() => history.push(`${Routes.MARKETING_CONSENTS}/${businessPartner?.id}`)}
                                className="font-weight-500 text-decoration-underline-inverse ml-0 ml-lg-4"
                            >
                                {t('delivery-point.detail.news-notification-data.setting')}
                            </ClickableElement>
                            <i className="icon-chevron-right-16 ml-2" />
                        </Col>
                    </Row>
                </>
            );
        }
    };

    return (
        <>
            <div className="card card-without-border py-2 px-1">
                <ul className="list-group list-group-flush">
                    <FormListItem
                        valueText={
                            <h3 className="mt-2">
                                <Trans i18nKey="delivery-point.detail.contact-data.title">Zákazník</Trans>
                            </h3>
                        }
                        isEditable={isExpandedCustomer && canEditOverride}
                        onClick={onStartEditFormField(DetailContactFormFieldEnum.CONTACT_CUSTOMER_INFOROMATIOM)}
                        isChangeRequested={isRequested('BusinessPartner').result}
                    />
                    <li className="list-group-item pb-0">
                        <h4 className="mb-0">{businessPartnerName.trim() || '-'}</h4>
                        <span className="text-secondary">
                            {t('delivery-point.detail.contract-data.business-partner-number')}:{' '}
                            {deliveryPoint.contract?.contractAccount?.businessPartner?.externalId || '-'}
                        </span>
                        <hr className="mb-0" />
                    </li>
                    <Collapse isOpen={isExpandedCustomer}>
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contact-data.business-partner-email" />}
                            valueText={<span className="attribute-info">{businessPartnerData?.email || '-'}</span>}
                        />
                        <FormListItem
                            label={<Trans i18nKey="delivery-point.detail.contact-data.telephone-number">Telefónne číslo</Trans>}
                            valueText={<span className="attribute-info">{formatSkPhoneNumber(businessPartnerData?.phone || '') || '-'}</span>}
                        />
                        <FormListItem
                            label={
                                isHome
                                    ? t('delivery-point.detail.contact-data.primary-address-home')
                                    : t('delivery-point.detail.contact-data.primary-address-company')
                            }
                            valueText={<span className="attribute-info">{primaryAddress || '-'}</span>}
                        />
                        {businessPartnerData?.companyRegistrationNumber != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contact-data.company-registration-number" />}
                                valueText={<span className="attribute-info">{businessPartnerData?.companyRegistrationNumber || '-'}</span>}
                            />
                        )}
                        {businessPartnerData?.taxIdNumber != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contact-data.tax-id-number" />}
                                valueText={<span className="attribute-info">{businessPartnerData?.taxIdNumber || '-'}</span>}
                            />
                        )}
                        {businessPartnerData?.vatRegistrationNumber != null && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contact-data.vat-registration-number" />}
                                valueText={<span className="attribute-info">{businessPartnerData?.vatRegistrationNumber || '-'}</span>}
                            />
                        )}
                        {billingAddress && (
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.contact-data.billing-address">Fakturačná adresa</Trans>}
                                valueText={<span className="attribute-info">{billingAddress}</span>}
                            />
                        )}
                        <li className="list-group-item">
                            {isRequested('BusinessPartner').customerRequestUuid && (
                                <RequestInProcess customerRequestUuid={isRequested('BusinessPartner').customerRequestUuid || ''} hideArrow />
                            )}
                        </li>
                    </Collapse>
                    <FormListItem
                        valueText={
                            <ClickableElement
                                className={isExpandedCustomer ? '' : 'collapsed'}
                                onClick={() => setIsExpandedCustomer(!isExpandedCustomer)}
                            >
                                <div className="font-weight-bold d-flex">
                                    <i
                                        className={`icon-chevron-down-16 la-xs mr-2 my-auto smooth-transition ${
                                            isExpandedCustomer ? 'rotateZ-180' : ''
                                        }`}
                                    />
                                    {isExpandedCustomer
                                        ? t('delivery-point.detail.contract-data.less')
                                        : t('delivery-point.detail.contract-data.more')}
                                </div>
                            </ClickableElement>
                        }
                    />
                </ul>
            </div>

            <div className="card card-without-border py-2 px-1 my-4">
                <ul className="list-group list-group-flush">
                    <FormListItem
                        valueText={
                            <h3 className="mt-2">
                                <Trans i18nKey="delivery-point.detail.contact-data.correspondence-address-title">Kontaktné údaje</Trans>
                                <span className="attribute-info">
                                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" id="infoTooltip" />
                                    <UncontrolledTooltip placement="right" target="infoTooltip">
                                        <Trans i18nKey="delivery-point.detail.contact-data.correspondence-address-title-help">
                                            Kontaktné údaje, ktoré primárne používame na kontakt s Vami.
                                        </Trans>
                                    </UncontrolledTooltip>
                                </span>
                            </h3>
                        }
                        isEditable={canEditOverride}
                        onClick={onStartEditFormField(DetailContactFormFieldEnum.CONTACT_CUSTOMER_ACCOUNT_INFOROMATIOM)}
                        isChangeRequested={isRequested('ContractAccount').result}
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contact-data.customer-account-email" />}
                        valueText={<span className="attribute-info">{contractAccount?.email || businessPartnerData?.email || '-'}</span>}
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contact-data.telephone-number">Telefónne číslo</Trans>}
                        valueText={
                            <span className="attribute-info">
                                {formatSkPhoneNumber(contractAccount?.phone || businessPartnerData?.phone || '') || '-'}
                            </span>
                        }
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.contact-data.correspondence-address">Korešpondenčná adresa</Trans>}
                        valueText={<span className="attribute-info">{correspondenceAddress || primaryAddress || '-'}</span>}
                    />
                    <li className="list-group-item">
                        {isRequested('ContractAccount').customerRequestUuid && (
                            <RequestInProcess customerRequestUuid={isRequested('ContractAccount').customerRequestUuid || ''} hideArrow />
                        )}
                    </li>
                </ul>
            </div>

            {showNotificationBanner && (
                <div className="card card-without-border news-information-card py-2 px-1 my-4">
                    {loadingSetMarketingConsents && <LoadingIndicator />}
                    {notificationBannerState === NotificationCardStateEnum.REGULAR && (
                        <button type="button" onClick={notInterestedInNotifications} className="close close-button">
                            <i className="icon-Times" aria-hidden="true" />
                        </button>
                    )}
                    <ul className="list-group list-group-flush">{NotificationCard()}</ul>
                </div>
            )}

            <ChangePersonalDataModal
                isOpen={editedFormField === DetailContactFormFieldEnum.CONTACT_CUSTOMER_INFOROMATIOM}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
            />

            <ChangeCustomerAccountDataModal
                isOpen={editedFormField === DetailContactFormFieldEnum.CONTACT_CUSTOMER_ACCOUNT_INFOROMATIOM}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
                openChangePersonalDataModal={() => setEditedFormField(DetailContactFormFieldEnum.CONTACT_CUSTOMER_INFOROMATIOM)}
            />
        </>
    );
};

export default DeliveryPointDetailContactData;
