import { Reducer } from 'react';
import { KeyValueStoreEmployeeActionsEnum, KeyValueStoreEmployeeActionsType } from '../actions/key-value-store-employee-actions';
import { IKeyValueStore } from './key-value-store-reducer';

const initialState: IKeyValueStore = {};

export const KeyValueStoreEmployeeReducer: Reducer<IKeyValueStore, KeyValueStoreEmployeeActionsType> = (state = initialState, action) => {
    switch (action.type) {
        case KeyValueStoreEmployeeActionsEnum.SET_VALUE: {
            const key = action.payload.key;
            const value = action.payload.value;
            return {
                ...state,
                [key]: value,
            };
        }
        case KeyValueStoreEmployeeActionsEnum.CLEAR_ALL: {
            return {};
        }
        default:
            return state;
    }
};
