import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { eInvoiceSettingsActions, EInvoiceSettingsModalTypes } from '../../../actions/einvoice-settings-actions';

const BulkEInvoiceActivation: React.FC = () => {
    const dispatch = useDispatch();
    const openActivationModal = () => {
        dispatch(eInvoiceSettingsActions.openModal(EInvoiceSettingsModalTypes.BULK_ACTIVATE_EINVOICE_MODAL));
    };

    return (
        <>
            <div className="card">
                <div className="card-body text-center d-flex flex-column align-items-center p-5">
                    <h3>
                        <Trans i18nKey="settings.e-invoices.responsible-and-ecological" />
                    </h3>
                    <p>
                        <Trans i18nKey="settings.e-invoices.prompt-activate-einvoices-for-all-delivery-points" />
                    </p>
                    <Button color="primary" onClick={openActivationModal}>
                        <Trans i18nKey="settings.e-invoices.activate-einvoice-in-bulk" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default BulkEInvoiceActivation;
