import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';
import { combineRefs } from '../../../../../../utils/utils';

type Props = DefaultFormType & {
    objectName?: string;
};

const BankConnectionFormFields: React.FC<Props> = ({ register, errors, objectName = '', trigger, watch, setValue, autoFocus }) => {
    const { formRules, bicSwift } = useFormRules();

    const [isForeign, setIsForeign] = useState<boolean>(false);
    const localRef = useRef<HTMLInputElement>(null);
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;

    const iban: string = watch(`${objectName}iban`);

    useEffect(() => {
        const length = localRef.current?.value.length || 0;
        let position = localRef.current?.selectionEnd || 0;
        const preview = localRef.current?.value
            .replace(/[^\da-zA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim();
        if (localRef.current) {
            localRef.current.value = preview || '';
            localRef.current.selectionEnd = position +=
                localRef.current.value.charAt(position - 1) === ' ' &&
                localRef.current.value.charAt(length - 1) === ' ' &&
                length !== localRef.current.value.length
                    ? 1
                    : 0;
        }

        if (iban && iban.substring(0, 2).toUpperCase() !== 'SK') {
            setIsForeign(true);
            //iban && trigger && trigger(objectName + 'bicSwift');
            iban && trigger && trigger(objectName + 'iban');
        } else {
            setIsForeign(false);
            iban && trigger && trigger(objectName + 'iban');
        }
    }, [iban, objectName, setValue, trigger]);

    return (
        <>
            <Input
                trigger={trigger}
                ref={combineRefs<HTMLInputElement>([register({ ...formRules.required, ...formRules.iban }), localRef])}
                errors={errors}
                type="text"
                name={`${objectName}iban`}
                label={<Trans i18nKey="customer-request.steps.bank-details.iban">IBAN</Trans>}
                autoFocus={autoFocus}
                touched={touched}
            />
            {isForeign && (
                <Input
                    trigger={trigger}
                    ref={register({ ...formRules.required, ...bicSwift(iban) })}
                    errors={errors}
                    type="text"
                    name={`${objectName}bicSwift`}
                    label={<Trans i18nKey="customer-request.steps.bank-details.bic-swift">BIC/SWIFT</Trans>}
                    touched={touched}
                />
            )}
        </>
    );
};

export default BankConnectionFormFields;
