import { SkSppNzpBeApiCustomerrequestCustomerRequestSummary as CustomerRequest } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { IColumnConfiguration } from '../../../components/common/table/table-interfaces';
import { CustomerRequestStatusEnum, CustomerRequestStatusFilterEnum } from '../../../models/enums';
import { formatDateToPreview } from '../../../utils/date-utils';
import { deleteLeadingZeros, formatAddress, formatBusinessPartnerName } from '../../../utils/utils';
import { CustomerRequestTypeEnum } from '../config/enums';

// hack for react-i18next package, because there is not correct typescript description
const TransHack = Trans as any;

// type RequestStatusCellType = {
//     [key: string]: (uniqueKey: string) => JSX.Element;
// };

// const requestStatusCell: RequestStatusCellType = {
//     [CustomerRequestStatusEnum.PRE_CREATED]: (key) => (
//         <td className="flex-grow-0 align-self-end text-primary" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.PRE_CREATED}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.CREATED]: (key) => (
//         <td className="flex-grow-0 align-self-end text-primary" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.CREATED}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.]: (key) => (
//         <td className="flex-grow-0 align-self-end text-primary" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.CREATED_AS_PAPER}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.REGISTERED]: (key) => (
//         <td className="flex-grow-0 align-self-end text-info" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.REGISTERED}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.FINNISHED]: (key) => (
//         <td className="flex-grow-0 align-self-end text-success" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.FINNISHED}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.CANCELLATION]: (key) => (
//         <td className="flex-grow-0 align-self-end text-warning" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.CANCELLATION}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.CANCELLED]: (key) => (
//         <td className="flex-grow-0 align-self-end text-danger" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.CANCELLED}`} />
//         </td>
//     ),
//     [CustomerRequestStatusEnum.CANCELLED_BY_CUSTOMER]: (key) => (
//         <td className="flex-grow-0 align-self-end text-danger" key={key}>
//             <i className="icon-circle-alert-16 mr-2"></i>
//             <Trans i18nKey={`enums.CustomerRequestStatusEnum.${CustomerRequestStatusEnum.CANCELLED_BY_CUSTOMER}`} />
//         </td>
//     ),
// };

const CONTRACT_ACCOUNT_LIMIT = 4;

export const customerRequestColumnConfigurations = (): IColumnConfiguration<CustomerRequest>[] => [
    {
        label: <Trans i18nKey="customer-request.table.field-date" />,
        columnId: 'issuedAt',
        columnWidth: '100px',
        cellComponent: (item: CustomerRequest): JSX.Element => (
            <td className="d-none d-lg-table-cell font-weight-bold" key={`customerRequest_createdAt_${item.uuid}`}>
                {formatDateToPreview(item.issuedAt)}
            </td>
        ),
    },
    {
        label: <Trans i18nKey="customer-request.table.field-request" />,
        columnId: 'customerRequestTemplate',
        columnWidth: '50%',
        cellComponent: (item: CustomerRequest): JSX.Element => (
            <td className="flex-grow-1 flex-basis-0" key={`customerRequest_customerRequestTemplate_${item.uuid}`}>
                <p className="mb-1 mb-lg-0">
                    <strong>
                        <TransHack
                            i18nKey={[
                                `enums.CustomerRequestType.${item.customerRequestTemplate?.code}_WITHPARAMS`,
                                `enums.CustomerRequestType.${item.customerRequestTemplate?.code}`,
                            ]}
                            values={{
                                count: Array.isArray(item?.customerRequestContracts) ? item.customerRequestContracts.length : 0,
                                contractAccountId: Array.isArray(item?.customerRequestContracts)
                                    ? item.customerRequestContracts.reduce(
                                          (acc, c, i) =>
                                              acc.length > 0
                                                  ? i < CONTRACT_ACCOUNT_LIMIT
                                                      ? acc + ', ' + (deleteLeadingZeros(c.contractAccount?.externalId) || '')
                                                      : i === CONTRACT_ACCOUNT_LIMIT
                                                      ? acc + ' ...'
                                                      : acc
                                                  : deleteLeadingZeros(c.contractAccount?.externalId) || '',
                                          '',
                                      )
                                    : '',
                            }}
                        />
                    </strong>
                </p>
                {item.customerRequestTemplate?.code === CustomerRequestTypeEnum.X_MS && (
                    <div>
                        <small className="text-secondary">{formatBusinessPartnerName(item.businessPartner)}</small>
                    </div>
                )}
                <p className="text-secondary mb-0 d-block d-lg-none">
                    {item.customerRequestContracts?.map(
                        (contract, i) =>
                            contract.deliveryPoint && (
                                <span key={`contract${contract.contract?.id}_${i}`}>
                                    {`${contract.deliveryPoint.address?.street} ${contract.deliveryPoint.address?.streetNumber} `}
                                    <Trans i18nKey={`enums.CommodityEnum.${contract.deliveryPoint.type}`} />
                                </span>
                            ),
                    )}
                    <br />
                    {formatDateToPreview(item.issuedAt)}
                </p>
            </td>
        ),
    },
    {
        label: <Trans i18nKey="customer-request.table.field-delivery-point" />,
        columnId: 'deliveryPoint',
        columnWidth: '50%',
        cellComponent: (item: CustomerRequest): JSX.Element => (
            <td className="d-none d-lg-table-cell text-break" key={`item_deliveryPoint_${item.externalId}`}>
                {item?.customerRequestContracts &&
                    !!item?.customerRequestContracts.length &&
                    !!item?.customerRequestContracts[0].deliveryPoint &&
                    formatAddress(item?.customerRequestContracts[0].deliveryPoint.address)}
                <br />
                <small className="text-secondary">
                    {item?.customerRequestContracts &&
                        !!item?.customerRequestContracts.length &&
                        !!item?.customerRequestContracts[0].deliveryPoint && (
                            <Trans i18nKey={`enums.CommodityEnum.${item?.customerRequestContracts[0].deliveryPoint.type}`} />
                        )}
                </small>
                <br />
                {item?.customerRequestContracts &&
                    !!item?.customerRequestContracts.length &&
                    item?.customerRequestContracts.length > 1 &&
                    item?.customerRequestContracts[1].deliveryPoint && (
                        <div className="too-much">{formatAddress(item?.customerRequestContracts[1].deliveryPoint.address)}</div>
                    )}
            </td>
        ),
    },
    {
        label: <Trans i18nKey="customer-request.table.field-status" />,
        columnId: 'status',
        columnWidth: '200px',
        cellComponent: (item: CustomerRequest) => {
            let status;
            if (
                item?.status?.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
                item?.status?.code === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
                item?.status?.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
            ) {
                status = (
                    <>
                        <Trans i18nKey="customer-request.filter.status-option-canceled" />
                        <UncontrolledTooltip target={`request_${item.uuid}_tooltip_help`} placement="top">
                            {item?.status?.name}
                        </UncontrolledTooltip>
                    </>
                );
            }
            if (
                item?.status?.code === CustomerRequestStatusFilterEnum.CREATED ||
                item?.status?.code === CustomerRequestStatusFilterEnum.REGISTERED ||
                item?.status?.code === CustomerRequestStatusFilterEnum.SAP_OPEN
            ) {
                status = (
                    <>
                        <Trans i18nKey="customer-request.filter.status-option-sent" />
                        <UncontrolledTooltip target={`request_${item.uuid}_tooltip_help`} placement="top">
                            {item?.status?.name}
                        </UncontrolledTooltip>
                    </>
                );
            }
            const cancellationRequested = item.requestCancel && !item.finnished;
            if (cancellationRequested) {
                status = <Trans i18nKey="customer-request.detail.cancel-requested" />;
            }

            if (item?.status?.code === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS) {
                status = <Trans i18nKey="customer-request.detail.in-progress" />;
            }

            if (item?.status?.code === CustomerRequestStatusEnum.COMPLETION_REJECTED) {
                status = <Trans i18nKey="customer-request.detail.rejected" />;
            }

            if (item?.status?.code === CustomerRequestStatusEnum.EXPIRED) {
                status = <Trans i18nKey="customer-request.detail.expired" />;
            }

            return (
                <td className="flex-grow-0 align-self-end text-primary" key={item.uuid}>
                    {item?.status?.code != null && (
                        <span id={`request_${item.uuid}_tooltip_help`}>
                            <i className="icon-circle-alert-16 mr-2"></i>
                            {status ? status : item.status.name}
                        </span>
                    )}
                </td>
            );
        },
    },
];
