import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorOption, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ClickableElement } from '../../../../components/common';
import Checkbox from '../../../../components/common/checkbox';
import Input from '../../../../components/common/input';
import PasswordInput from '../../../../components/common/password-input';
import HelpModal from '../../../../components/help/help-modal';
import { ReCaptchaInfoMessage } from '../../../../components/recaptcha/ReCaptchaInfoMessage';
import { useFormRules } from '../../../../hooks/use-form-rules';
import { RegistrationFormDataType } from './registration-step';
import SocialButtons from './social-buttons';

type FormPropsType = {
    onFormSubmit: (
        data: RegistrationFormDataType,
        setError: (name: any, error: ErrorOption) => void,
        recaptcha?: string | null,
        onSuccess?: () => void,
    ) => void;
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    termsAndConditions?: boolean;
    privacyPolicy?: boolean;
    hideRecaptcha?: boolean;
    hidePassword?: boolean;
    hideSocialLoginBtns?: boolean;
};

type LocationStateType = {
    userData: {
        email?: string;
        firstName?: string;
        lastName?: string;
        password?: string;
        privacyPolicy?: boolean;
        termsAndConditions?: boolean;
    };
};

const RegistrationForm: React.FC<FormPropsType> = ({
    onFormSubmit,
    email,
    firstName,
    lastName,
    password,
    privacyPolicy,
    termsAndConditions,
    hideRecaptcha = false,
    hidePassword,
    hideSocialLoginBtns = false,
}) => {
    const location = useLocation<LocationStateType>();
    const { register, handleSubmit, errors, trigger, setError, reset } = useForm({
        defaultValues: { email, firstName, lastName, 'new-password': password, privacyPolicy, termsAndConditions },
    });
    const { t, i18n } = useTranslation();
    const [key, setKey] = useState(0);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';
    const useRecaptcha = hideRecaptcha === false && process.env.REACT_APP_RECAPTCHA === 'true';
    useEffect(() => {
        if (location.state?.userData) {
            reset(location.state.userData);
        }
    }, [reset, location]);

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        reset({
            email,
            firstName,
            lastName,
            privacyPolicy: false,
            termsAndConditions: false,
        });
        setPrivacyPolicyShown(false);
        setTermsAndConditionsShown(false);
    }, [email, firstName, lastName, reset]);

    const [isTermsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [termsAndConditionsShown, setTermsAndConditionsShown] = useState(false);
    const [privacyPolicyShown, setPrivacyPolicyShown] = useState(false);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const { formRules, passwordRules, requiredTrimed, sppDomain } = useFormRules();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const onSubmit = (data: RegistrationFormDataType) => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (useRecaptcha && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        useRecaptcha && recaptchaRef?.current?.reset();
        onFormSubmit(data, setError, reCaptchaHelp, () => reset());
    };

    const showTermsAndConditions = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setTermsAndConditionsOpen(true);
    };

    const showPrivacyPolicy = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setPrivacyPolicyOpen(true);
    };

    useEffect(() => {
        if (isPrivacyPolicyOpen) setPrivacyPolicyShown(true);
    }, [isPrivacyPolicyOpen, setPrivacyPolicyShown]);

    useEffect(() => {
        if (isTermsAndConditionsOpen) setTermsAndConditionsShown(true);
    }, [isTermsAndConditionsOpen, setTermsAndConditionsShown]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <HelpModal
                    show={isTermsAndConditionsOpen}
                    wide
                    title="consents.terms-and-conditions.title"
                    screen="REG"
                    field="REG_PORTAL_USAGE_CONDITIONS"
                    cancel={() => setTermsAndConditionsOpen(false)}
                />
                <HelpModal
                    show={isPrivacyPolicyOpen}
                    wide
                    title="consents.privacy-policy.title"
                    screen="REG"
                    field="REG_RULES_OF_PRIVATE_DATA_PROCESSING"
                    cancel={() => setPrivacyPolicyOpen(false)}
                />
                <Input
                    trigger={trigger}
                    ref={register({ ...requiredTrimed, ...formRules.validName })}
                    errors={errors}
                    type="text"
                    name="firstName"
                    label={<Trans i18nKey="common.name">Meno</Trans>}
                />
                <Input
                    trigger={trigger}
                    ref={register({ ...requiredTrimed, ...formRules.validName })}
                    errors={errors}
                    type="text"
                    name="lastName"
                    autoComplete="familyName"
                    label={<Trans i18nKey="common.surname">Priezvisko</Trans>}
                />
                <Input
                    trigger={trigger}
                    ref={register(Object.assign(requiredTrimed, sppDomain))}
                    errors={errors}
                    type="text"
                    name="email"
                    label={<Trans i18nKey="common.email">Email</Trans>}
                    autoCapitalize="off"
                    autoCorrect="off"
                    readOnly={!!email}
                />
                {!hidePassword /*!email && !firstName && !lastName*/ && (
                    <PasswordInput
                        id="registration-password"
                        ref={register({ ...passwordRules.isValidPassword, ...formRules.minLengthX(8) })}
                        errors={errors}
                        name="new-password" // new-password name will disable prefilling - any other name will enable autofill
                        label={<Trans i18nKey="common.password">Heslo</Trans>}
                        trigger={trigger}
                    />
                )}
                <Checkbox
                    className="mt-4 mb-3"
                    ref={register(formRules.required)}
                    errors={errors}
                    name="termsAndConditions"
                    label={
                        <Trans
                            i18nKey="consents.terms-and-conditions.check"
                            components={{
                                Link: (
                                    <ClickableElement isText onClick={showTermsAndConditions} className="text-decoration-underline-inverse">
                                        &nbsp
                                    </ClickableElement>
                                ),
                            }}
                        />
                    }
                    id={'checkboxTerms'}
                    onChange={() => {
                        if (!termsAndConditionsShown) setTermsAndConditionsOpen(true);
                    }}
                />
                <Checkbox
                    ref={register(formRules.required)}
                    errors={errors}
                    name="privacyPolicy"
                    label={
                        <Trans
                            i18nKey="consents.privacy-policy.check"
                            components={{
                                Link: (
                                    <ClickableElement isText onClick={showPrivacyPolicy} className="text-decoration-underline-inverse">
                                        &nbsp
                                    </ClickableElement>
                                ),
                            }}
                        />
                    }
                    id={'checkboxPrivacy'}
                    onChange={() => {
                        if (!privacyPolicyShown) setPrivacyPolicyOpen(true);
                    }}
                />
                <div style={{ display: useRecaptcha ? 'block' : 'none' }} className="mb-2">
                    <ReCAPTCHA
                        key={key}
                        ref={recaptchaRef}
                        sitekey={useRecaptcha ? recaptchaSiteKey : 'undefined'}
                        onChange={() => setReCaptchaError(false)}
                        hl={i18n.language === 'en' ? 'en' : 'sk'}
                    />

                    {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                </div>
                <button type="submit" className={`btn btn-primary btn-lg ${email ? 'mt-5 mb-3' : 'my-2'} `}>
                    <Trans i18nKey="action.sign-up">Registrovať</Trans>
                </button>

                <div style={{ display: useRecaptcha ? 'block' : 'none' }}>
                    <ReCaptchaInfoMessage />
                </div>
            </form>
            {!hideSocialLoginBtns && <SocialButtons />}
        </>
    );
};

export default RegistrationForm;
