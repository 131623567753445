import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { ClickableElement } from '../../../components/common';
import { DeliveryPointCommodityBadges } from './delivery-point-commodity-badges';

type DeliveryPointCardProps = {
    deliveryPointSummary: UnitedDeliveryPointSummary;
    onOpen: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
    onLoading: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
};

const ListDeliveryPointsCard = ({ deliveryPointSummary, onOpen, onLoading }: DeliveryPointCardProps): ReactElement => {
    const { t } = useTranslation();
    const loading = deliveryPointSummary.pairingStatus === 'IN_PROGRESS';

    const onClick = () => {
        if (loading) {
            onLoading(deliveryPointSummary);
        } else {
            onOpen(deliveryPointSummary);
        }
    };

    const divLoadingRef = useRef<HTMLDivElement>(null);

    const isClickable = deliveryPointSummary.access == null;

    return (
        <div className="col-12 col-lg-4" style={{ marginBottom: 20 }}>
            <ClickableElement
                onClick={isClickable ? onClick : () => null}
                className={classNames('card mb-1 mb-lg-0', { loading: loading, 'card-hoverable': isClickable, 'cursor-default': !isClickable })}
            >
                <div className={`card-body p-3 ${loading ? 'opacity-30' : ''}`}>
                    <div className="row" style={{ minHeight: '120px' }}>
                        <div className="col d-flex flex-column justify-content-between">
                            <div>
                                <h5 className="font-weight-bold mb-0" style={{ fontSize: '1.4rem' }}>
                                    {deliveryPointSummary.address?.street} {deliveryPointSummary.address?.streetNumber}
                                </h5>
                                <p>{deliveryPointSummary.address?.city}</p>
                            </div>
                            <div>
                                {deliveryPointSummary.deliveryPoints && (
                                    <DeliveryPointCommodityBadges deliveryPoints={deliveryPointSummary.deliveryPoints} />
                                )}
                            </div>
                        </div>
                        <div className="col-auto d-flex flex-column justify-content-between">
                            <i className="icon-chevron-right-16" />
                            {deliveryPointSummary.sharing?.to?.length ? (
                                <>
                                    <i id={`tooltip_${deliveryPointSummary.id}_share-icon`} className="icon-users-16" />
                                    <UncontrolledTooltip placement="top" target={`tooltip_${deliveryPointSummary.id}_share-icon`}>
                                        {t('delivery-point.delivey-point-share-to-another-person')}
                                    </UncontrolledTooltip>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                {loading && (
                    <>
                        <div className="text-center m-auto d-block position-absolute loading-icon" ref={divLoadingRef}>
                            <FontAwesomeIcon icon={faHourglass} className="fa-lg" />
                        </div>
                        <UncontrolledTooltip placement="top" target={divLoadingRef}>
                            {t('delivery-point.list-delivery-points.loading-in-progress') +
                                '. ' +
                                t('delivery-point.list-delivery-points.loading-in-progress-more-info')}
                        </UncontrolledTooltip>
                    </>
                )}
            </ClickableElement>
        </div>
    );
};

export default ListDeliveryPointsCard;
