import { SkSppNzpBeApiCustomeraccessChallengeValidity } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import Input from '../../../../components/common/input';
import { ReCaptchaInfoMessage } from '../../../../components/recaptcha/ReCaptchaInfoMessage';
import { useApi } from '../../../../hooks/use-api';
import { emailRegex, useFormRules } from '../../../../hooks/use-form-rules';
import { useUrlQuery } from '../../../../hooks/use-url-query';
import Routes from '../../../../routes/routes';

type InputsType = {
    email: string;
};

type PropsType = {
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const ForgotPasswordCard: React.FC<PropsType> = ({ activeCard, toggleActive }) => {
    const index = 1;

    const { t, i18n } = useTranslation();
    const api = useApi();
    const urlQuery = useUrlQuery();
    const emailParam = urlQuery.get('email');
    const email = emailParam && emailRegex.test(emailParam) ? emailParam : '';

    const { addToast } = useToasts();
    const { register, handleSubmit, errors, trigger } = useForm({ defaultValues: { email: email } });
    const { formRules } = useFormRules();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const history = useHistory();
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const [key, setKey] = useState(0);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';

    const navigateToNextStep = (email: string, validTo: string) => {
        if (!email) throw new Error('email cannot be empty');
        history.push({ pathname: Routes.FORGOT_PASSWORD_CONFIRMATION, state: { email: email, validTo: validTo } });
    };

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const onSubmit = (formData: FormData & InputsType) => {
        const email = formData.email;
        if (!email) return;
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (process.env.REACT_APP_RECAPTCHA === 'true' && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        api.customers
            .requestPasswordRecovery({ email: email }, { headers: reCaptchaHelp ? { 'X-GoogleReCaptcha-Code': reCaptchaHelp } : {}, secure: true })
            .then(onSuccess, onFail)
            .finally(() => {
                process.env.REACT_APP_RECAPTCHA === 'true' && recaptchaRef?.current?.reset();
            });

        function onSuccess(response: { data: SkSppNzpBeApiCustomeraccessChallengeValidity | null }) {
            if (!response.data) return;
            navigateToNextStep(email, response.data.validTo ?? '');
        }

        function onFail() {
            addToast(<Trans i18nKey="login-issues.recovery-password-request-failed"></Trans>, { appearance: 'error' });
        }
    };

    return (
        <Card>
            <CardHeader role="tab" id={`heading${index}`}>
                <a
                    className={activeCard === index ? '' : 'collapsed'}
                    onClick={(event) => toggleActive(event, index)}
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                >
                    <div className="d-flex">
                        <strong>
                            <Trans i18nKey="login-issues.forgot-password">Zabudol som heslo</Trans>
                        </strong>
                        <i className="icon-chevron-down-16 la-xs text-secondary ml-auto my-auto"></i>
                    </div>
                </a>
            </CardHeader>
            <Collapse isOpen={activeCard === index}>
                <CardBody>
                    <p>
                        <Trans i18nKey="login-issues.enter-email-for-recovery-password">
                            Zadajte e-mail, na ktorý vám pošleme odkaz pre nastavenie nového hesla.
                        </Trans>
                    </p>
                    <Row>
                        <Col>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <Input
                                        ref={register(formRules.email)}
                                        trigger={trigger}
                                        name="email"
                                        type="text"
                                        label={t('common.email')}
                                        errors={errors}
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                    />
                                </div>
                                <div style={{ display: process.env.REACT_APP_RECAPTCHA === 'true' ? 'block' : 'none' }}>
                                    <ReCAPTCHA
                                        key={key}
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA === 'true' ? recaptchaSiteKey : 'undefined'}
                                        onChange={() => setReCaptchaError(false)}
                                        hl={i18n.language === 'en' ? 'en' : 'sk'}
                                    />
                                    {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                                </div>
                                <button type="submit" className="btn btn-primary btn-block btn-lg my-2">
                                    <Trans i18nKey="common.send">Odoslať</Trans>
                                </button>
                                <div style={{ display: process.env.REACT_APP_RECAPTCHA === 'true' ? 'block' : 'none' }}>
                                    <ReCaptchaInfoMessage />
                                </div>
                            </form>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default ForgotPasswordCard;
