import { FieldErrors } from '@hookform/error-message/dist/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRadio } from '../../../../../../main';

interface IEeRateRadioButtonProps {
    register: (ref: any) => void;
    trigger?: ((name: any) => Promise<boolean>) | undefined;
    errors?: FieldErrors;
    value: string;
    label: string | JSX.Element;
    description: string | JSX.Element;
    isPreviouslySelected?: boolean;
    autoFocus?: boolean;
}

export const EeRateRadioButton: React.FC<IEeRateRadioButtonProps> = ({
    register,
    errors,
    value,
    label,
    description,
    isPreviouslySelected,
    autoFocus,
}) => {
    const [t] = useTranslation();
    const inputId = `radioEeRate-${value}`;

    return (
        <>
            <BaseRadio
                ref={register}
                errors={errors}
                id={inputId}
                name="tariff"
                value={value}
                label={
                    <>
                        <b className="d-block">
                            {label}
                            {'  '}
                            {isPreviouslySelected && `(${t('delivery-point.detail.payment-data.tariffs.your-current-rate')})`}
                        </b>
                        {description}
                    </>
                }
                disabled={isPreviouslySelected ? true : false}
                autoFocus={autoFocus}
            />
        </>
    );
};
