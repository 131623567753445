import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CommodityEnum } from '../../../../main';
import { joinNodes } from '../../../../utils/react-utils';

interface IDeliveryPointSwitchLabelProps {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    showInvoiceNumber?: boolean;
    showMeterNumber?: boolean;
}

export const DeliveryPointSwitchLabel: React.FC<IDeliveryPointSwitchLabelProps> = ({ deliveryPoint, showInvoiceNumber, showMeterNumber }) => {
    const { t } = useTranslation();

    const bottomText = useMemo(() => {
        const items: React.ReactNode[] = [];
        if (deliveryPoint.status === 'INACTIVE') {
            items.push(<span>{t('delivery-point.inactive')}</span>);
        }
        if (showInvoiceNumber) {
            items.push(<span> {t('delivery-point.contract-number', { number: deliveryPoint.contract?.externalId })} </span>);
        }
        if (showMeterNumber) {
            items.push(<span> {t('delivery-point.meter-number', { number: deliveryPoint.deviceNumber })} </span>);
        }
        return joinNodes(items, ' • ');
    }, [deliveryPoint, showInvoiceNumber, showMeterNumber, t]);

    return (
        <div style={showInvoiceNumber || showMeterNumber ? {} : { paddingTop: '10px', paddingBottom: '10px' }}>
            <div>
                {deliveryPoint.type === CommodityEnum.GAS ? (
                    <Trans i18nKey="common.commodity.gas" />
                ) : (
                    <Trans i18nKey="common.commodity.electricity" />
                )}
            </div>
            <div>
                <small className="text-secondary">{bottomText}</small>
            </div>
        </div>
    );
};
