import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import Checkbox from '../../../components/common/checkbox';
import HelpLinkWithModal from '../../../components/help/help-link-with-modal';
import { useFormRules } from '../../../hooks/use-form-rules';

type DeactivationFormFields = { consequencesAccepted: boolean };
interface IDeactivateAccountFormProps {
    onSubmit: (checks: DeactivationFormFields) => void;
    disabled?: boolean;
}

const DeactivateAccountForm: React.FC<IDeactivateAccountFormProps> = ({ onSubmit, disabled }) => {
    const { register, handleSubmit, errors } = useForm<DeactivationFormFields>();
    const { formRules } = useFormRules();

    const onFormSubmit = handleSubmit((fields) => {
        onSubmit({ consequencesAccepted: fields.consequencesAccepted });
    });

    const [t] = useTranslation();

    return (
        <>
            <form onSubmit={onFormSubmit} noValidate>
                <br />
                <Checkbox
                    ref={register({ ...formRules.required })}
                    errors={errors}
                    id="consequencesAcceptedCheckbox"
                    name="consequencesAccepted"
                    label={t('settings.deactivate-account.accept-consequences')}
                    className="mt-1 mt-sm-3 mb-3 mb-sm-5"
                />
                <HelpLinkWithModal showAsCard className="my-4 my-sm-5" field="NUC_ACCOUNT_DEACTIVATION_CONSEQUENCES" />

                <Button type="submit" color="primary" size="lg" className="d-block mx-auto" disabled={disabled}>
                    <Trans i18nKey="settings.deactivate-account.deactivate-account" />
                </Button>
            </form>
        </>
    );
};

export default DeactivateAccountForm;
