import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseTextarea from '../../../../../components/common/base-textarea';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IRootState } from '../../../../../reducers';

const NOTE_MAX_COUNT = 510;

const BlockReason: React.FC = () => {
    const { requiredTrimed, formRules } = useFormRules();
    const { content } = useSelector((state: IRootState) => state.customerRequest);
    const dispatch = useDispatch();
    const [messageCounter, setMessageCounter] = useState<number>(0);

    const { handleSubmit, register, errors, watch } = useForm({ defaultValues: { reason: content.reason } });

    const message: string | undefined = watch('reason');

    useEffect(() => {
        setMessageCounter(message?.length ?? 0);
    }, [message]);

    const onSubmit = ({ reason }: { reason: string }) => {
        dispatch(CustomerRequestActions.setData({ reason }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <BaseTextarea
                ref={register({ ...requiredTrimed, ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                errors={errors}
                name="reason"
                rows={5}
                label={<Trans i18nKey="customer-request.steps.reason-for-claim-detail.detailed-description" />}
                counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                maxLength={NOTE_MAX_COUNT}
            />

            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockReason;
