import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

type Props = DefaultFormType & {
    objectName?: string;
    hideBpNumber?: boolean;
};

const BusinessPartnerCompany: React.FC<Props> = ({ register, errors, objectName = '', trigger, hideBpNumber }) => {
    const { formRules, requiredTrimed, firstCharMustBe, companyRegistrationNumber } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <>
            <Input
                trigger={trigger}
                ref={register(requiredTrimed)}
                errors={errors}
                type="text"
                name={`${objectName}name`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.company-name">Názov spoločnosti</Trans>}
                touched={touched}
            />

            {!hideBpNumber && (
                <Input
                    trigger={trigger}
                    ref={register({
                        ...formRules.requiredInteger,
                        validate: { ...firstCharMustBe('5', true).validate, ...formRules.length(10).validate },
                    })}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name={`${objectName}bpNumber`}
                    label={<Trans i18nKey="customer-request.steps.businessPartner.customer-number-spp">Zákaznícke číslo v SPP</Trans>}
                    optional
                    touched={touched}
                />
            )}
            <Input
                trigger={trigger}
                ref={register({ validate: { ...requiredTrimed.validate, ...companyRegistrationNumber.validate } })}
                errors={errors}
                type="text"
                name={`${objectName}companyRegistrationNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.ico">IČO</Trans>}
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register({ ...requiredTrimed, ...formRules.taxIdNumber })}
                errors={errors}
                type="text"
                name={`${objectName}taxIdNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.dic">DIČ</Trans>}
                touched={touched}
            />

            <Input
                trigger={trigger}
                ref={register(formRules.vatRegistrationNumber)}
                errors={errors}
                type="text"
                name={`${objectName}vatRegistrationNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.ic-dph">IČ DPH</Trans>}
                optional
                touched={touched}
            />

            <Input
                trigger={trigger}
                ref={register({})}
                errors={errors}
                type="text"
                name={`${objectName}legalForm`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.legal-form">Právna forma</Trans>}
                optional
                touched={touched}
            />
            <Input
                trigger={trigger}
                ref={register({})}
                errors={errors}
                type="text"
                name={`${objectName}statutoryName`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.name-statutory-representative">Meno štatutárneho zástupcu</Trans>}
                optional
                touched={touched}
            />
        </>
    );
};
export default BusinessPartnerCompany;
