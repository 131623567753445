import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
    SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { LoadingIndicator } from '../../../../../components/common';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { PaymentMethodCodeEnum, useApi } from '../../../../../main';
import { ChangeFormStepEnum, DetailPaymentFormFieldEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import ChangeRequestSent from '../change-request-sent';
import ChangeBankAccountForm, { BankAccountFields } from '../forms/change-bank-account';

interface IChangeBankAccountModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    setEditedFormField: (val: DetailPaymentFormFieldEnum) => void;
}

export const ChangeBankAccountModal: React.FC<IChangeBankAccountModalProps> = ({ isOpen, onClose, deliveryPoint, setEditedFormField }) => {
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [newData, setNewData] = useState<BankAccountFields | undefined>();

    useEffect(() => {
        if (isOpen) {
            setStep(ChangeFormStepEnum.EDITING);
            setNewData(undefined);
        }
    }, [isOpen]);

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const api = useApi();

    const [mutateCreateRequest, { isLoading: isLoadingCreateRequest }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary | null,
        unknown,
        SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod
    >(
        async (requestData) =>
            deliveryPoint.contract?.id != null
                ? api.deliveryPoints.changePaymentMethod(deliveryPoint.contract?.id, requestData, { secure: true }).then((res) => res.data)
                : null,
        {
            onSuccess: () => {
                goToNextStep();
            },
        },
    );

    const createRequestForBankConnectionChange = async (bankConnectionData: BankAccountFields) => {
        let processManualOverride = deliveryPoint?.contract?.contractAccount?.billingIban ? true : false;
        if (
            deliveryPoint?.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.BANK_TRANSFER ||
            deliveryPoint?.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.INKASO
        ) {
            processManualOverride =
                deliveryPoint?.contract?.contractAccount?.iban && !deliveryPoint?.contract?.contractAccount?.billingIban ? false : true;
        }
        const iban = bankConnectionData?.iban?.replace(/ /g, '').toUpperCase();
        const requestData: SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod = {
            paymentType: deliveryPoint.contract?.contractAccount?.paymentType,
            iban: bankConnectionData.iban,
            bicSwift: bankConnectionData.bicSwift,
            sipoNumber: deliveryPoint.contract?.contractAccount?.sipo,
            processManualOverride: processManualOverride || (iban && !iban.startsWith('SK') ? true : false),
        };
        mutateCreateRequest(requestData);
    };

    const onFormSubmit = (formData: BankAccountFields) => {
        setNewData(formData);
        createRequestForBankConnectionChange(formData);
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="delivery-point.detail.contact-data.change-bank-account">Zmena bankového účtu</Trans>
                    </h3>

                    {step === ChangeFormStepEnum.EDITING && (
                        <ChangeBankAccountForm
                            currentValue={{
                                iban: deliveryPoint.contract?.contractAccount?.iban || '',
                                bicSwift: '',
                            }}
                            setEditedFormField={setEditedFormField}
                            editedValue={newData}
                            onSubmit={onFormSubmit}
                            onReset={onClose}
                        />
                    )}

                    {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onClose} />}

                    {isLoadingCreateRequest && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
