import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'reactstrap';
import { ClickableElement } from '../../../components/common';

type ChooseExistingOrNewScreenProps = {
    proceedWithExistingDeliveryPoint: () => void;
    proceedWithNewDeliveryPoint: () => void;
};

const ChooseExistingOrNewScreen: React.FC<ChooseExistingOrNewScreenProps> = (props) => {
    return (
        <div>
            <ClickableElement onClick={() => props.proceedWithExistingDeliveryPoint()}>
                <Card className="card-hoverable w-100 align-items-center mb-3">
                    <p className="card-body p-4 mb-3 w-100 text-center">
                        <FontAwesomeIcon icon={faArrowAltCircleDown} className="mb-3" size="2x" style={{ color: '#FFD500' }} />
                        <span className="d-block font-weight-bold">
                            <Trans i18nKey="delivery-point.add-delivery-point.new-or-existing.existing-title" />
                        </span>
                        <span className="font-weight-normal">
                            <Trans i18nKey="delivery-point.add-delivery-point.new-or-existing.existing-msg" />
                        </span>
                    </p>
                </Card>
            </ClickableElement>

            <ClickableElement onClick={() => props.proceedWithNewDeliveryPoint()}>
                <Card className="card-hoverable w-100 align-items-center">
                    <p className="card-body p-4 mb-3 w-100 text-center" style={{ fontSize: '1rem' }}>
                        <i className="icon-circle-plus-16 fa-2x" style={{ color: '#FFD500' }}></i>
                        <span className="d-block font-weight-bold mt-3">
                            <Trans i18nKey="delivery-point.add-delivery-point.new-or-existing.new-title" />
                        </span>
                        <span className="font-weight-normal">
                            <Trans i18nKey="delivery-point.add-delivery-point.new-or-existing.new-msg" />
                        </span>
                    </p>
                </Card>
            </ClickableElement>
        </div>
    );
};

export default ChooseExistingOrNewScreen;
