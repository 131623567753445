import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button } from 'reactstrap';
import BaseRadio from '../../../../components/common/base-radio';
import Input from '../../../../components/common/input';
import { useFormRules } from '../../../../hooks/use-form-rules';
import { IBusinessPartnerDeliveryPointData } from '../../../../models/model';
import { IRootState } from '../../../../reducers';
import { formatBusinessPartnerName } from '../../../../utils/utils';
import { getEInvoiceEmailsInUDPSummaries } from '../utils/einvoice-utils';

interface IUnifyEmailSelectEmailProps {
    onSelect: (email: string) => void;
    businessPartnerData: IBusinessPartnerDeliveryPointData;
}

const UnifyEmailSelectEmail: React.FC<IUnifyEmailSelectEmailProps> = ({ onSelect, businessPartnerData }) => {
    const { register, handleSubmit, errors, trigger } = useForm<{ email: string; customEmail: string }>();

    const portalAccountEmail = useSelector((state: IRootState) => state.user.customer?.email);
    const { formRules, requiredTrimed } = useFormRules();

    const [isCustom, setIsCustom] = useState(false);
    const { t } = useTranslation();

    const currentEInvoiceEmails = useMemo(() => getEInvoiceEmailsInUDPSummaries(businessPartnerData.unitedDeliveryPoints), [businessPartnerData]);

    const canUnifyEmails = (email: string) => {
        if (currentEInvoiceEmails.length === 1) {
            return email !== currentEInvoiceEmails[0];
        }
        return true;
    };

    const { addToast } = useToasts();
    const onFormSubmit = handleSubmit((fields) => {
        const selectedEmail = fields.customEmail || fields.email;
        if (!canUnifyEmails(selectedEmail)) {
            addToast(t('settings.e-invoices.change-email.current-email-warning'), {
                appearance: 'warning',
            });
            return;
        }
        onSelect(selectedEmail);
    });

    const additionalEmails = useMemo(() => {
        if (currentEInvoiceEmails.length === 1) {
            // emails are already unified for selected BP
            return [];
        }
        return currentEInvoiceEmails.filter((email) => email !== portalAccountEmail);
    }, [currentEInvoiceEmails, portalAccountEmail]);

    return (
        <>
            <p className="mb-4">{`${formatBusinessPartnerName(businessPartnerData.businessPartner)} (${
                businessPartnerData.businessPartner.externalId
            }) — ${t('settings.e-invoices.delivery-points-count', {
                count: businessPartnerData.unitedDeliveryPoints.length,
            })}`}</p>

            <h4 className="mb-3">
                <Trans i18nKey="settings.e-invoices.unify-email.select-email" />
            </h4>
            <form onSubmit={onFormSubmit} noValidate>
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`accountEmailRadio`}
                    name="email"
                    value={portalAccountEmail}
                    label={portalAccountEmail || ''}
                    className="mb-3"
                    onChange={() => setIsCustom(false)}
                />
                {additionalEmails.map((email, index) => (
                    <BaseRadio
                        key={index}
                        ref={register(formRules.required)}
                        errors={errors}
                        id={`emailRadio${index}`}
                        name="email"
                        value={email}
                        label={email}
                        className="mb-3"
                        onChange={() => setIsCustom(false)}
                    />
                ))}
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`enterCustomEmailRadio`}
                    name="email"
                    value={undefined}
                    label={<Trans i18nKey="settings.e-invoices.select-different-email" />}
                    className="mb-3"
                    onChange={() => setIsCustom(true)}
                />
                {isCustom && (
                    <Input
                        ref={register({ ...requiredTrimed, ...formRules.email })}
                        trigger={trigger}
                        errors={errors}
                        type="text"
                        name="customEmail"
                        label={<Trans i18nKey="common.email" />}
                        autoCapitalize="off"
                        autoCorrect="off"
                    />
                )}

                <Button type="submit" color="primary" size="lg" block className="mt-5">
                    <Trans i18nKey="settings.e-invoices.set-united-email" />
                </Button>
            </form>
        </>
    );
};

export default UnifyEmailSelectEmail;
