import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { formatAddressShort } from '../../../../utils/utils';

interface IActivateEInvoiceSuccessProps {
    onConfirm: () => void;
    onConfirmBulk: () => void;
    canBulkActivate: boolean;
    address: { street: string | undefined; streetNumber: string | undefined };
    email: string;
    alreadyActivatedDeliveryPoint: boolean;
    onlyNotActivatedDeliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
}

const ActivateEInvoiceSuccess: React.FC<IActivateEInvoiceSuccessProps> = ({
    onConfirm,
    onConfirmBulk,
    canBulkActivate,
    address,
    email,
    alreadyActivatedDeliveryPoint,
    onlyNotActivatedDeliveryPoint,
}) => {
    const { t } = useTranslation();
    const dpAddress = alreadyActivatedDeliveryPoint
        ? `${formatAddressShort(address)} (${t('delivery-point.contract')} ${onlyNotActivatedDeliveryPoint
              ?.map((dp) => dp.contract?.externalId)
              .join(', ')})`
        : formatAddressShort(address);
    return (
        <>
            <div className="text-center">
                <i className="icon-circle-ok-16 fa-lg text-center mx-auto mb-3 d-block"></i>
                <h4 className="text-center">
                    <Trans i18nKey="settings.e-invoices.e-invoice-activation-requested" />
                </h4>
                <p className="mb-4 text-center">
                    <Trans
                        i18nKey="settings.e-invoices.e-invoices-for-delivery-point-will-be-delivered-to-email"
                        components={{ Email: <span className="text-nowrap" /> }}
                        values={{ dpAddress: dpAddress, email: email }}
                    />
                </p>
                <Button type="button" color="light" block onClick={onConfirm}>
                    <Trans i18nKey="common.ok" />
                </Button>

                {canBulkActivate && (
                    <>
                        <hr className="my-4" />
                        <FontAwesomeIcon icon={faThumbsUp} size="lg" className="text-center mx-auto mb-3 d-block" />
                        <h4 className="text-center">
                            <Trans i18nKey="settings.e-invoices.protect-the-environment" />
                        </h4>
                        <p className="text-center">
                            <Trans i18nKey="settings.e-invoices.activate-einvoice-and-stop-deforestation" />
                        </p>
                        <Button type="button" color="primary" block onClick={onConfirmBulk} className="mb-2">
                            <Trans i18nKey="settings.e-invoices.activate-einvoice-in-bulk" />
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default ActivateEInvoiceSuccess;
