import React from 'react';
import { Trans } from 'react-i18next';
import useSso from '../../../hooks/sso/use-sso';
import Routes from '../../../routes/routes';

type LoginButtonPropsType = {
    loginClicked: () => void;
};

const LoginButton: React.FC<LoginButtonPropsType> = ({ loginClicked }) => {
    const { ssoLogin } = useSso();

    return (
        <ul className="navbar-nav justify-content-end w-100 container-login">
            <li className="nav-item">
                <button
                    type="submit"
                    className="btn btn-inverted my-3 my-lg-0 w-100 w-lg-auto btn-login"
                    onClick={(event) => {
                        loginClicked();
                        ssoLogin({ backUrl: Routes.HOME });
                    }}
                >
                    <Trans i18nKey="action.login">Prihlásiť sa</Trans>
                </button>
            </li>
        </ul>
    );
};

export default LoginButton;
