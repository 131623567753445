import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAdditionalService } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAdditionalService[];
    bold?: boolean;
};

const AdditionalServiceItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (data.length === 0) return <></>;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.additional-services.item-title')}>
                {data.map((item, index) => (
                    <>
                        {item && (
                            <ItemPreviewContent key={index} bold={bold}>
                                {t(`customer-request.steps.additional-services.${item?.name}`)}{' '}
                            </ItemPreviewContent>
                        )}
                    </>
                ))}
            </ItemPreview>
        </>
    );
};

export default AdditionalServiceItem;
