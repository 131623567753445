import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IAddress } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAddress;
    bold?: boolean;
};

const OwnerCorrespondenceAddressItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
        },
    });

    const country = useMemo(() => {
        return countryCodeList?.find((item) => item.uuid === data?.country?.uuid)?.name;
    }, [countryCodeList, data.country]);

    if (isObjectEmpty(data)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.new-data.owner-coresspondence-address')}>
            {data?.street && <ItemPreviewContent bold={bold}>{`${data?.street || ''} ${data?.number || ''}`}</ItemPreviewContent>}
            {data?.city && <ItemPreviewContent bold={bold}>{`${data?.postalCode || ''}, ${data?.city || ''} ${country || ''}`}</ItemPreviewContent>}
        </ItemPreview>
    );
};

export default OwnerCorrespondenceAddressItem;
