import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAdvance } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { formatCurrency, isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IAdvance;
    bold?: boolean;
};

const AdvanceItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    return (
        <ItemPreview>
            {data.amount != null && (
                <>
                    <ItemPreviewHeader title={t('customer-request.steps.amount-advances.amount')} />
                    <ItemPreviewContent bold={bold}>{formatCurrency(data.amount || 0)}</ItemPreviewContent>
                </>
            )}
            {!!data.reason && (
                <>
                    <ItemPreviewHeader title={t('customer-request.steps.amount-advances.reason')} />
                    <ItemPreviewContent bold={bold}>{data.reason}</ItemPreviewContent>
                </>
            )}
            {!!data.date && (
                <>
                    <ItemPreviewHeader title={t('customer-request.steps.amount-advances.date-change')} />
                    <ItemPreviewContent bold={bold}>{formatDateToPreview(data.date)}</ItemPreviewContent>
                </>
            )}
        </ItemPreview>
    );
};

export default AdvanceItem;
