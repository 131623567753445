import React from 'react';
import { Trans } from 'react-i18next';

const ReportIssueHeader: React.FC = () => (
    <>
        <h2>
            <Trans i18nKey="report-issue.report-suspicious-activity" />
        </h2>
        <h5 className="forms-headline">
            <Trans i18nKey="report-issue.problem-title" />
        </h5>
        <p>
            <Trans i18nKey="report-issue.problem-text" />
        </p>
    </>
);

export default ReportIssueHeader;
