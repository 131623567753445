import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import Input from '../../../../../../components/common/input';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';

const CompanyFields: React.FC<DefaultFormType & { canBeCompanyRegistrationNumberEmpty: boolean; canBeTaxNumberEmpty: boolean }> = ({
    register,
    errors,
    trigger,
    setValue,
    unregister,
    canBeCompanyRegistrationNumberEmpty,
    canBeTaxNumberEmpty,
}) => {
    const [isCompanyRegistrationNumberDisabled, setIsCompanyRegistrationNumberDisabled] = useState(false);
    const { requiredTrimed, formRules, mergeValidations, latinCharacters } = useFormRules();
    const { t } = useTranslation();
    const onCheckboxClick = () => {
        const willBeDisabled = !isCompanyRegistrationNumberDisabled;
        if (willBeDisabled) {
            setValue && setValue('companyRegistrationNumber', '');
        }
        setIsCompanyRegistrationNumberDisabled(willBeDisabled);
    };

    useEffect(() => {
        if (isCompanyRegistrationNumberDisabled) {
            unregister && unregister('companyRegistrationNumber');
        }
    }, [isCompanyRegistrationNumberDisabled, unregister]);

    return (
        <>
            <Input
                trigger={trigger}
                ref={register(mergeValidations({ ...requiredTrimed.validate, ...latinCharacters.validate }))}
                errors={errors}
                type="text"
                name={`name`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.company-name">Názov spoločnosti</Trans>}
                characterFilter={CharacterFilter.LATIN}
            />
            <Input
                trigger={trigger}
                ref={register(
                    isCompanyRegistrationNumberDisabled
                        ? {}
                        : mergeValidations({
                              ...requiredTrimed.validate,
                              ...latinCharacters.validate,
                              length: (value: string | undefined) => {
                                  if (value == null) return true;
                                  if (value.length >= 6 && value.length <= 8) return true;
                                  const errorMessage = t('common.input-rules.invalid-company-registration-number-format');
                                  return errorMessage;
                              },
                          }),
                )}
                value={isCompanyRegistrationNumberDisabled ? '' : undefined}
                errors={errors}
                type="text"
                name={`companyRegistrationNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.ico">IČO</Trans>}
                disabled={isCompanyRegistrationNumberDisabled}
                characterFilter={CharacterFilter.LATIN}
            />
            {canBeCompanyRegistrationNumberEmpty && (
                <div className="custom-control custom-checkbox mb-3">
                    <input
                        checked={isCompanyRegistrationNumberDisabled}
                        type="checkbox"
                        className="custom-control-input"
                        id="sipoNumberNotAssigned"
                        onChange={onCheckboxClick}
                    />
                    <label className="custom-control-label" htmlFor="sipoNumberNotAssigned">
                        <Trans i18nKey="customer-request.steps.businessPartner.ico-missing">Nemám IČO</Trans>
                    </label>
                </div>
            )}
            <Input
                trigger={trigger}
                ref={register(canBeTaxNumberEmpty ? {} : { ...requiredTrimed, ...formRules.taxIdNumber })}
                errors={errors}
                type="text"
                name={`taxIdNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.dic">DIČ</Trans>}
                characterFilter={CharacterFilter.LATIN}
            />

            <Input
                trigger={trigger}
                ref={register(formRules.vatRegistrationNumber)}
                errors={errors}
                type="text"
                name={`vatRegistrationNumber`}
                label={<Trans i18nKey="customer-request.steps.businessPartner.ic-dph">IČ DPH</Trans>}
                characterFilter={CharacterFilter.LATIN}
                optional
            />
        </>
    );
};
export default CompanyFields;
