import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
} from '@spp/spp-meru-frontend-common';
import { Query, QueryKey, useQueryCache } from 'react-query';

export enum QueryKeysEnum {
    CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
    CUSTOMER_REQUESTS_SEARCH = 'CUSTOMER_REQUESTS_SEARCH',
    CUSTOMER_OPEN_REQUESTS_SEARCH = 'CUSTOMER_OPEN_REQUESTS_SEARCH',
    DELIVERY_POINT_REQUESTS_SEARCH = 'DELIVERY_POINT_REQUESTS_SEARCH',
    DELIVERY_POINT_OPEN_REQUESTS_SEARCH = 'DELIVERY_POINT_OPEN_REQUESTS_SEARCH',
    UNITED_DELIVERY_POINTS_SEARCH = 'UNITED_DELIVERY_POINTS_SEARCH',
    UNITED_DELIVERY_POINT = 'UNITED_DELIVERY_POINT',
    DELIVERY_POINT = 'DELIVERY_POINT',
    CONSUMPTION_TABLE = 'CONSUMPTION_TABLE',
    CODE_LIST_ADVANCE_PAYMENT_PERIOD = 'CODE_LIST_ADVANCE_PAYMENT_PERIOD',
    CODE_LIST_ADVANCE_PAYMENT_TYPE = 'CODE_LIST_ADVANCE_PAYMENT_TYPE',
    CODE_LIST_COMPLAINED_INVOICE_PROBLEM = 'CODE_LIST_COMPLAINED_INVOICE_PROBLEM',
    CODE_LIST_OVERPAYMENT_SETTLEMENT_TYPE = 'CODE_LIST_OVERPAYMENT_SETTLEMENT_TYPE',
    CODE_LIST_ZOM_ZOPAOO_REASON = 'CODE_LIST_ZOM_ZOPAOO_REASON',
    CODE_LIST_TARIFF_RATE = 'CODE_LIST_TARIFF_RATE',
    CODE_LIST_METER_READING_KIND = 'CODE_LIST_METER_READING_KIND',
    CODE_LIST_METER_READING_REASON = 'CODE_LIST_METER_READING_REASON',
    CODE_LIST_INVOICE_TYPE = 'CODE_LIST_INVOICE_TYPE',
    CODE_LIST_INVOICE_SUBTYPE = 'CODE_LIST_INVOICE_SUBTYPE',
    CODE_LIST_CONTRACT_TERMINATION_REASON = 'CODE_LIST_CONTRACT_TERMINATION_REASON',
    CODE_LIST_CUSTOMER_REQUEST_STATUS = 'CODE_LIST_CUSTOMER_REQUEST_STATUS',
    CODE_LIST_CARBON_STOP_LEVEL = 'CODE_LIST_CARBON_STOP_LEVEL',
    CODE_LIST_COUNTRY = 'CODE_LIST_COUNTRY',
    CODE_LIST_DISTRIBUTION_AREA = 'CODE_LIST_DISTRIBUTION_AREA',
    CODE_LIST_CONTRACT_ADVANCE_PAYMENT_PERIOD = 'CODE_LIST_CONTRACT_ADVANCE_PAYMENT_PERIOD',
    PRODUCT_TARIFF_RATE = 'PRODUCT_TARIFF_RATE',
    DELIVERY_POINT_CUSTOMER_REQUESTS_SEARCH = 'DELIVERY_POINT_CUSTOMER_REQUESTS_SEARCH',
    CODE_LIST_AUDIT_LOG = 'CODE_LIST_AUDIT_LOG',
    DELIVERY_POINT_INVOICES_SUMMARY = 'DELIVERY_POINT_INVOICES_SUMMARY',
    METER_READING_EXPORT_COLUMN = 'METER_READING_EXPORT_COLUMN',
    CUSTOMER_ACCOUNT_EXPORT_COLUMN = 'CUSTOMER_ACCOUNT_EXPORT_COLUMN',
    AUDIT_LOG_EXPORT_COLUMN = 'AUDIT_LOG_EXPORT_COLUMN',
    OVERPAYMENT_SETTLEMENT_TYPE = 'OVERPAYMENT_SETTLEMENT_TYPE',
    TITLE_FRONT = 'TITLE_FRONT',
    TITLE_BEHIND = 'TITLE_BEHIND',
    INVOICE_SUMMARY = 'INVOICE-SUMMARY',
    COOKIES_INFORMATION = 'COOKIES_INFORMATION',
}

export const buildDeliveryPointQueryKey = (dpId: string | undefined): QueryKey[] => [QueryKeysEnum.DELIVERY_POINT, dpId];

export const buildDeliveryPointSelfReadQueryKey = (dpId: string | undefined): QueryKey[] => [QueryKeysEnum.DELIVERY_POINT, 'self-read', dpId];

export const buildUnitedDeliveryPointQueryKey = (udpId: string | undefined): QueryKey[] => [QueryKeysEnum.UNITED_DELIVERY_POINT, udpId];

export const buildUnitedDeliveryPointsSearchQueryKey = (
    userId: string | undefined,
    data: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch,
): QueryKey[] => [QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH, userId, data];

export const buildCustomerRequestsSearchQueryKey = (
    customerId: string | undefined,
    data: SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
): QueryKey[] => [QueryKeysEnum.CUSTOMER_REQUESTS_SEARCH, customerId, data];

export const buildDeliveryPointsRequestsSearchQueryKey = (
    dpId: string | undefined,
    data: SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
): QueryKey[] => [QueryKeysEnum.DELIVERY_POINT_REQUESTS_SEARCH, dpId, data];

export const buildDeliveryPointInvoicesSummaryQueryKey = (dpId: string | undefined): QueryKey[] => [
    QueryKeysEnum.DELIVERY_POINT_INVOICES_SUMMARY,
    dpId,
];

/** 
    - clear cache of given query to initial state \
    - unlike QueryCache.invalidateQueries(...), reloadQueryCache will trigger change of isLoading property
**/
const reloadQueryCache = (query: Query<unknown, unknown> | undefined): void => {
    if (query != null && !query.state.isFetching) {
        /*query.invalidate();*/
        query.remove();
        query.refetch();
    }
};

/**
    - returns function which will clear cache of given query to initial state \
    - unlike QueryCache.invalidateQueries(...), returned function will set isLoading to true
**/
export const useReloadQueryCache = (): ((qk: QueryKey) => void) => {
    const queryCache = useQueryCache();
    const reloadFunction = (queryKey: QueryKey) => {
        const foundQueries = queryCache.getQueries(queryKey);
        foundQueries.forEach((q) => reloadQueryCache(q));
    };
    return reloadFunction;
};
