import React from 'react';
import { Trans } from 'react-i18next';
import BaseSwitch from '../../../../../../components/common/base-switch';
import { BusinessPartnerTypeEnum } from '../../../../../../models/enums';
import { CustomerRequestTypeEnum } from '../../../../config/enums';
import BusinessPartnerCompany from './business-partner-company';
import { BusinessPartnerProps } from './business-partner-fields';
import BusinessPartnerPerson from './business-partner-person';

type Props = BusinessPartnerProps;

const BusinessPartnerForm: React.FC<Props> = ({
    register,
    opNumberRequired,
    errors,
    onSwitchChange,
    activeType,
    objectsName,
    getValues,
    setValue,
    trigger,
    min18years = true,
    requiredIdCardNumber = true,
    hideBpNumber = false,
    hideIdCardNumber = false,
    hideBirthDate = false,
    customerRequestTemplate,
}) => {
    return (
        <>
            {customerRequestTemplate?.code !== CustomerRequestTypeEnum.ZOP_US && (
                <BaseSwitch
                    selectedValue={activeType}
                    onValueSelect={(value) => onSwitchChange(value as BusinessPartnerTypeEnum)}
                    dataButtons={[
                        {
                            id: BusinessPartnerTypeEnum.PERSON,
                            title: <Trans i18nKey="customer-request.steps.businessPartner.person">Súkr. osoba</Trans>,
                        },
                        {
                            id: BusinessPartnerTypeEnum.COMPANY,
                            title: <Trans i18nKey="customer-request.steps.businessPartner.company">Firma</Trans>,
                        },
                    ]}
                />
            )}
            {activeType === BusinessPartnerTypeEnum.PERSON && (
                <BusinessPartnerPerson
                    opNumberRequired={opNumberRequired}
                    errors={errors}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    objectName={objectsName}
                    trigger={trigger}
                    min18years={min18years}
                    requiredIdCardNumber={requiredIdCardNumber}
                    hideBpNumber={hideBpNumber}
                    hideIdCardNumber={hideIdCardNumber}
                    hideBirthDate={hideBirthDate}
                />
            )}
            {activeType === BusinessPartnerTypeEnum.COMPANY && (
                <BusinessPartnerCompany errors={errors} register={register} objectName={objectsName} trigger={trigger} hideBpNumber={hideBpNumber} />
            )}
        </>
    );
};

export default BusinessPartnerForm;
