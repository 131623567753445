import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Card, CardBody, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { UserActions } from '../../../actions';
import { AppContext } from '../../../app-context';
import { ClickableElement, LoadingIndicator } from '../../../components/common';
import { PenComponent } from '../../../components/common/pen-component';
import ConfirmDialog from '../../../components/dialog/confirm-dialog';
import { useApi } from '../../../hooks/use-api';
import useConfig from '../../../hooks/use-config';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IResponseError } from '../../../models/model';
import { IRootState } from '../../../reducers';
import Routes, { SettingsRoutes } from '../../../routes/routes';
import { formatDateToPreview } from '../../../utils/date-utils';
import { formatSkPhoneNumber } from '../../../utils/utils';
import DeactivateAccountModal from '../deactivate-account/deactivate-account-modal';
import { NewsAndInformation } from './components/news-and-information';
import LoginEmailChangeModal from './modals/login-email-change/login-email-change-modal';
import LoginPasswordChangeModal from './modals/login-password-change/login-password-change-modal';
import NameChangeModal from './modals/name-change/name-change-modal';
import RemoveFacebookOrGoogleModal from './modals/remove-facebook-or-google/remove-facebook-or-goole';
import RequirePasswordModal from './modals/require-password/require-password-modal';
import TelephoneNumberChangeModal from './modals/telephone-number-change/telephone-number-change-modal';

export enum LoginDetailsModalTypeEnum {
    DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT',
    NAME_CHANGE = 'NAME_CHANGE',
    LOGIN_PASSWORD_CHANGE = 'LOGIN_PASSWORD_CHANGE',
    LOGIN_PASSWORD_ADD = 'LOGIN_PASSWORD_ADD',
    LOGIN_EMAIL_CHANGE = 'LOGIN_EMAIL_CHANGE',
    TELEPHONE_NUMBER_CHANGE = 'TELEPHONE_NUMBER_CHANGE',
    REMOVE_FACEBOOK = 'REMOVE_FACEBOOK',
    REMOVE_GOOGLE = 'REMOVE_GOOGLE',
    REMOVE_APPLE = 'REMOVE_APPLE',
}

enum ConfirmationModalTypeEnum {
    ACTIVATE_FACEBOOK = 'ENABLE_FACEBOOK',
    DEACTIVATE_FACEBOOK = 'DEACTIVATE_FACEBOOK',
    ACTIVATE_GOOGLE = 'ACTIVATE_GOOGLE',
    DEACTIVATE_GOOGLE = 'DEACTIVATE_GOOGLE',
    ACTIVATE_APPLE = 'ENABLE_APPLE',
    DEACTIVATE_APPLE = 'DEACTIVATE_APPLE',
    REMOVE_FACEBOOK = 'REMOVE_FACEBOOK', //only for admin
    REMOVE_GOOGLE = 'REMOVE_GOOGLE', //only for admin
    REMOVE_APPLE = 'REMOVE_APPLE', //only for admin
}

const LoginDetailsSettings: React.FC = () => {
    const [openedModalType, setOpenedModalType] = useState<LoginDetailsModalTypeEnum | undefined>(undefined);
    const [openedConfirmationModalType, setOpenedConfirmationModalType] = useState<ConfirmationModalTypeEnum | undefined>(undefined);
    const [formNextStep, setFormNextStep] = useState<LoginDetailsModalTypeEnum | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const currentUser = useSelector((store: IRootState) => store.user);
    const loggedInCustomer = currentUser.customer;
    const employee = currentUser.employee;
    const { t } = useTranslation();
    const cancelEditing = () => {
        setOpenedModalType(undefined);
        setPassword(undefined);
        setFormNextStep(undefined);
    };
    const api = useApi();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { data: config } = useConfig();
    const appContext = useContext(AppContext);

    const [mutateMe, { isLoading: isLoadingUserData }] = useMutationWithError(async () => api.me.getMe({ secure: true }).then((x) => x.data), {
        onSuccess: (userData) => {
            if (userData != null) {
                dispatch(UserActions.setUser({ ...userData, userSkippedPhoneVerification: currentUser.userSkippedPhoneVerification }));
            }
        },
    });

    useEffect(() => {
        mutateMe();
    }, [mutateMe]);

    const confirmationModalBody = () => {
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_FACEBOOK) {
            return t('settings.login-details.facebook-login-activate-confirmation');
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_FACEBOOK) {
            return t('settings.login-details.facebook-login-deactivate-confirmation');
        }
        if (
            openedModalType === LoginDetailsModalTypeEnum.REMOVE_FACEBOOK ||
            openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_FACEBOOK
        ) {
            return t('settings.login-details.facebook-login-delete-confirmation');
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_GOOGLE) {
            return t('settings.login-details.google-login-activate-confirmation');
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_GOOGLE) {
            return t('settings.login-details.google-login-deactivate-confirmation');
        }
        if (openedModalType === LoginDetailsModalTypeEnum.REMOVE_GOOGLE || openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_GOOGLE) {
            return t('settings.login-details.google-login-delete-confirmation');
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_APPLE) {
            return t('settings.login-details.apple-login-activate-confirmation');
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_APPLE) {
            return t('settings.login-details.apple-login-deactivate-confirmation');
        }
        if (openedModalType === LoginDetailsModalTypeEnum.REMOVE_APPLE || openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_APPLE) {
            return t('settings.login-details.apple-login-delete-confirmation');
        }
    };

    const requirePasswordModalText = () => {
        if (
            openedModalType === LoginDetailsModalTypeEnum.REMOVE_FACEBOOK ||
            openedModalType === LoginDetailsModalTypeEnum.REMOVE_GOOGLE ||
            openedModalType === LoginDetailsModalTypeEnum.REMOVE_APPLE
        ) {
            return t('settings.login-details.attemp-to-disconent-social-login');
        }
        if (openedModalType === LoginDetailsModalTypeEnum.TELEPHONE_NUMBER_CHANGE) {
            return t('settings.login-details.attemp-to-change-phone-number');
        }
        if (openedModalType === LoginDetailsModalTypeEnum.LOGIN_EMAIL_CHANGE) {
            return t('settings.login-details.attemp-to-change-email');
        }
    };

    const onConfirmAction = () => {
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_FACEBOOK) {
            api.customers
                .changeFbLoginSettings(loggedInCustomer?.id || '', { enabled: true }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_FACEBOOK) {
            api.customers
                .changeFbLoginSettings(loggedInCustomer?.id || '', { enabled: false }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_GOOGLE) {
            api.customers
                .changeGoogleLoginSettings(loggedInCustomer?.id || '', { enabled: true }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_GOOGLE) {
            api.customers
                .changeGoogleLoginSettings(loggedInCustomer?.id || '', { enabled: false }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.ACTIVATE_APPLE) {
            api.customers
                .changeAppleLoginSettings(loggedInCustomer?.id || '', { enabled: true }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.DEACTIVATE_APPLE) {
            api.customers
                .changeAppleLoginSettings(loggedInCustomer?.id || '', { enabled: false }, { secure: true })
                .then(() => {
                    mutateMe();
                })
                .catch(onReject);
        }

        //admin
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_FACEBOOK) {
            api.customers
                .unpairFbAccount(loggedInCustomer?.id || '', { password: '' }, { secure: true })
                .then(() => {
                    addToast(t('settings.login-details.facebook-login-delete-success'), {
                        appearance: 'success',
                    });
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_GOOGLE) {
            api.customers
                .unpairGoogleAccount(loggedInCustomer?.id || '', { password: '' }, { secure: true })
                .then(() => {
                    addToast(t('settings.login-details.google-login-delete-success'), {
                        appearance: 'success',
                    });
                    mutateMe();
                })
                .catch(onReject);
        }
        if (openedConfirmationModalType === ConfirmationModalTypeEnum.REMOVE_APPLE) {
            api.customers
                .unpairAppleAccount(loggedInCustomer?.id || '', { password: '' }, { secure: true })
                .then(() => {
                    addToast(t('settings.login-details.apple-login-delete-success'), {
                        appearance: 'success',
                    });
                    mutateMe();
                })
                .catch(onReject);
        }
        setOpenedConfirmationModalType(undefined);
    };

    function onReject(errResponse: { error: IResponseError }) {
        const err = errResponse.error;
        if (err) {
            if (err.code === 2017) {
                addToast(<Trans i18nKey="settings.login-details.no-other-valid-login-delete-error" />, { appearance: 'error' });
                return;
            }
            const errText = err.code + ' ' + (err.message ?? '') + (err.errors ? err.errors?.join(', ') : '');
            addToast(<Trans i18nKey="settings.login-details.form-rejected" components={{ Message: errText }} />, { appearance: 'error' });
        } else {
            addToast(<Trans i18nKey="settings.login-details.form-rejected" components={{ Message: t('error.default') }} />, { appearance: 'error' });
        }
    }

    if (loggedInCustomer == null) return null;
    return (
        <>
            <ConfirmDialog
                visible={!!openedConfirmationModalType}
                body={confirmationModalBody()}
                onClick={(action) => {
                    if (action === 'confirm') {
                        onConfirmAction();
                    } else {
                        setOpenedConfirmationModalType(undefined);
                    }
                }}
                onClosed={() => setOpenedConfirmationModalType(undefined)}
                confirmButton={t(`common.yes`)}
                cancelButton={t(`customer-request.leave-cancel`)}
            />
            <Container>
                <Row className="justify-content-md-center py-md-4">
                    <Col xs={12} xl={8} className="position-static">
                        <h2 className="mt-5 mb-3 d-none d-lg-block">
                            <Trans i18nKey="settings.login-details.login-details" />
                        </h2>
                        <h4 className="forms-headline mb-4 d-lg-none">
                            <Trans i18nKey="settings.login-details.login-details" />
                        </h4>
                        <Card>
                            <ListGroup>
                                <ListGroupItem>
                                    <Row className="text-decoration-none align-items-center">
                                        <Col>
                                            <small className="text-secondary d-block">
                                                <Trans i18nKey="settings.login-details.name-and-surname" />
                                            </small>
                                            <span className="attribute-info">{`${loggedInCustomer.firstName || ''} ${loggedInCustomer.lastName ||
                                                ''}`}</span>
                                        </Col>

                                        <PenComponent onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.NAME_CHANGE)} />
                                    </Row>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Row className="text-decoration-none align-items-center">
                                        <Col>
                                            <small className="text-secondary d-block">
                                                <Trans i18nKey="settings.login-details.login-email" />
                                            </small>
                                            <span className="attribute-info">{loggedInCustomer.email}</span>
                                        </Col>

                                        {!employee && (
                                            <PenComponent onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.LOGIN_EMAIL_CHANGE)} />
                                        )}
                                    </Row>
                                    {!employee && !loggedInCustomer.hasPassword && (
                                        <div className="mt-2 text-secondary">
                                            <small>
                                                {t('settings.login-details.not-active-email-login')}{' '}
                                                <ClickableElement
                                                    isText
                                                    onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD)}
                                                    className="text-decoration-underline-inverse"
                                                >
                                                    {t('settings.login-details.activate-email-login')}
                                                </ClickableElement>
                                            </small>
                                        </div>
                                    )}
                                </ListGroupItem>
                                {loggedInCustomer.hasPassword && (
                                    <ListGroupItem>
                                        <Row className="text-decoration-none align-items-center">
                                            <Col>
                                                <small className="text-secondary d-block">
                                                    <Trans i18nKey="settings.login-details.login-password" />
                                                </small>
                                                <span className="attribute-info">********</span>
                                            </Col>
                                            {!employee && (
                                                <PenComponent onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.LOGIN_PASSWORD_CHANGE)} />
                                            )}
                                        </Row>
                                    </ListGroupItem>
                                )}
                                <ListGroupItem>
                                    <Row className="text-decoration-none align-items-center">
                                        <Col>
                                            <small className="text-secondary d-block">
                                                <Trans i18nKey="settings.login-details.telephone-number" />
                                            </small>
                                            <span className="attribute-info">
                                                {loggedInCustomer.phone ? (
                                                    formatSkPhoneNumber(loggedInCustomer.phone)
                                                ) : (
                                                    <Trans i18nKey="settings.login-details.phonenumber-not-entered" />
                                                )}
                                            </span>
                                        </Col>
                                        {!employee && (
                                            <PenComponent onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.TELEPHONE_NUMBER_CHANGE)} />
                                        )}
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>

                        {((config?.googleLogin && loggedInCustomer.hasGoogleId) ||
                            (config?.facebookLogin && loggedInCustomer.hasFacebookId) ||
                            loggedInCustomer.hasAppleId) && (
                            <Card className="d-lg-block my-4">
                                <CardBody>
                                    <p>
                                        <b>{t('settings.login-details.login-via-social')}</b>
                                    </p>
                                    {loggedInCustomer.hasFacebookId && (
                                        <Row>
                                            <Col className="d-flex">
                                                <img
                                                    src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/facebook.svg`}
                                                    width="25"
                                                    className="mr-2"
                                                />
                                                <div>
                                                    <span className="attribute-info">
                                                        <Trans i18nKey="settings.login-details.facebook-login" />
                                                    </span>
                                                    <br />
                                                    <small className="text-secondary">
                                                        {t('settings.login-details.login-viac-account', {
                                                            account: loggedInCustomer.facebookUserName || '',
                                                        })}
                                                    </small>
                                                </div>
                                            </Col>
                                            <div
                                                className="text-danger mr-4 cursor-pointer disconnect-text"
                                                onClick={() => {
                                                    employee
                                                        ? setOpenedConfirmationModalType(ConfirmationModalTypeEnum.REMOVE_FACEBOOK)
                                                        : setOpenedModalType(LoginDetailsModalTypeEnum.REMOVE_FACEBOOK);
                                                }}
                                            >
                                                {t('settings.login-details.disconnect')}
                                            </div>
                                        </Row>
                                    )}
                                    {loggedInCustomer.hasGoogleId && (
                                        <Row className={loggedInCustomer.hasFacebookId ? 'mt-4' : ''}>
                                            <Col className="d-flex">
                                                <img
                                                    src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/google.svg`}
                                                    width="25"
                                                    className="mr-2"
                                                />
                                                <div>
                                                    <span className="attribute-info">
                                                        <Trans i18nKey="settings.login-details.google-login" />
                                                    </span>
                                                    <br />
                                                    <small className="text-secondary">
                                                        {t('settings.login-details.login-viac-account', {
                                                            account: loggedInCustomer.googleUserName || '',
                                                        })}
                                                    </small>
                                                </div>
                                            </Col>
                                            <div
                                                className="text-danger mr-4 cursor-pointer disconnect-text"
                                                onClick={() => {
                                                    employee
                                                        ? setOpenedConfirmationModalType(ConfirmationModalTypeEnum.REMOVE_GOOGLE)
                                                        : setOpenedModalType(LoginDetailsModalTypeEnum.REMOVE_GOOGLE);
                                                }}
                                            >
                                                {t('settings.login-details.disconnect')}
                                            </div>
                                        </Row>
                                    )}
                                    {loggedInCustomer.hasAppleId && (
                                        <Row className={loggedInCustomer.hasFacebookId || loggedInCustomer.hasGoogleId ? 'mt-4' : ''}>
                                            <Col className="d-flex">
                                                <img
                                                    src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/apple.svg`}
                                                    width="25"
                                                    className="mr-2"
                                                />
                                                <div>
                                                    <span className="attribute-info">
                                                        <Trans i18nKey="settings.login-details.apple-login" />
                                                    </span>
                                                    <br />
                                                    <small className="text-secondary">
                                                        {t('settings.login-details.login-viac-account', {
                                                            account: loggedInCustomer.appleUserName || '',
                                                        })}
                                                    </small>
                                                </div>
                                            </Col>
                                            <div
                                                className="text-danger mr-4 cursor-pointer disconnect-text"
                                                onClick={() => {
                                                    employee
                                                        ? setOpenedConfirmationModalType(ConfirmationModalTypeEnum.REMOVE_APPLE)
                                                        : setOpenedModalType(LoginDetailsModalTypeEnum.REMOVE_APPLE);
                                                }}
                                            >
                                                {t('settings.login-details.disconnect')}
                                            </div>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        )}
                        <NewsAndInformation bold link={Routes.MARKETING_CONSENTS} cardClassName="cardClassName" />

                        <Card className="d-none d-lg-block my-4">
                            <CardBody>
                                <p>
                                    <b>{t('settings.login-details.cancel-account')}</b>
                                </p>
                                <div className="text-secondary">{t('settings.login-details.cancel-account-attention')}</div>
                                <button
                                    type="submit"
                                    className="btn btn-lg my-4 btn-outline-danger"
                                    onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.DEACTIVATE_ACCOUNT)}
                                >
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                    {t('settings.login-details.cancel-account')}
                                </button>
                            </CardBody>
                        </Card>
                        <div className="m-1">
                            <label className="mb-3 text-secondary">
                                <small>
                                    <Trans i18nKey="settings.login-details-change-does-not-affect" />{' '}
                                    <Trans
                                        i18nKey="settings.account-created-on"
                                        values={{ date: formatDateToPreview(loggedInCustomer.registrationAt) }}
                                    />
                                </small>
                            </label>
                        </div>
                        <appContext.userSettingComponent />
                        <ListGroup className="nav flex-column d-block d-lg-none my-3">
                            <ListGroupItem className="nav-item">
                                <NavLink className="nav-link d-flex" to={SettingsRoutes.EINVOICES}>
                                    <Trans i18nKey="settings.navigation.e-invoices" />
                                    <i className="icon-chevron-right-16 ml-auto text-secondary"></i>
                                </NavLink>
                            </ListGroupItem>
                            <ListGroupItem className="nav-item">
                                <NavLink className="nav-link d-flex" to={SettingsRoutes.NOTIFICATIONS}>
                                    <Trans i18nKey="settings.navigation.notifications" />
                                    <i className="icon-chevron-right-16 ml-auto text-secondary"></i>
                                </NavLink>
                            </ListGroupItem>
                            <ListGroupItem className="nav-item">
                                <div
                                    onClick={() => setOpenedModalType(LoginDetailsModalTypeEnum.DEACTIVATE_ACCOUNT)}
                                    className="nav-link d-flex text-danger cursor-pointer align-items-center"
                                    style={{ padding: '10px' }}
                                >
                                    <Trans i18nKey="settings.navigation.account-deactivation" />
                                    <i className="icon-circle-alert-16 fa-lg text-danger ml-1"></i>
                                    <i className="icon-chevron-right-16 ml-auto text-secondary"></i>
                                </div>
                            </ListGroupItem>
                        </ListGroup>

                        <NameChangeModal isOpen={openedModalType === LoginDetailsModalTypeEnum.NAME_CHANGE} onCloseModal={cancelEditing} />
                        <LoginEmailChangeModal
                            isOpen={openedModalType === LoginDetailsModalTypeEnum.LOGIN_EMAIL_CHANGE && !!loggedInCustomer.hasPassword}
                            onCloseModal={cancelEditing}
                            password={password}
                        />
                        <RemoveFacebookOrGoogleModal
                            isOpen={
                                (openedModalType === LoginDetailsModalTypeEnum.REMOVE_GOOGLE ||
                                    openedModalType === LoginDetailsModalTypeEnum.REMOVE_FACEBOOK ||
                                    openedModalType === LoginDetailsModalTypeEnum.REMOVE_APPLE) &&
                                !!loggedInCustomer.hasPassword
                            }
                            type={
                                openedModalType === LoginDetailsModalTypeEnum.REMOVE_GOOGLE
                                    ? 'google'
                                    : openedModalType === LoginDetailsModalTypeEnum.REMOVE_FACEBOOK
                                    ? 'facebook'
                                    : 'apple'
                            }
                            onCloseModal={cancelEditing}
                            mutateMe={() => mutateMe()}
                            password={password}
                        />
                        <LoginPasswordChangeModal
                            isOpen={
                                openedModalType === LoginDetailsModalTypeEnum.LOGIN_PASSWORD_CHANGE ||
                                openedModalType === LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD
                            }
                            type={openedModalType}
                            onCloseModal={cancelEditing}
                            mutateMe={(password) => {
                                mutateMe();
                                setOpenedModalType(formNextStep);
                                if (formNextStep) {
                                    setPassword(password);
                                } else {
                                    cancelEditing();
                                }
                            }}
                            email={loggedInCustomer.email}
                        />
                        <TelephoneNumberChangeModal
                            isOpen={openedModalType === LoginDetailsModalTypeEnum.TELEPHONE_NUMBER_CHANGE}
                            onCloseModal={cancelEditing}
                            password={password}
                        />
                        <DeactivateAccountModal
                            isOpen={openedModalType === LoginDetailsModalTypeEnum.DEACTIVATE_ACCOUNT}
                            onCloseModal={cancelEditing}
                        />
                        <RequirePasswordModal
                            isOpen={
                                !loggedInCustomer.hasPassword &&
                                (openedModalType === LoginDetailsModalTypeEnum.REMOVE_FACEBOOK ||
                                    openedModalType === LoginDetailsModalTypeEnum.REMOVE_GOOGLE ||
                                    openedModalType === LoginDetailsModalTypeEnum.REMOVE_APPLE ||
                                    openedModalType === LoginDetailsModalTypeEnum.LOGIN_EMAIL_CHANGE)
                            }
                            text={requirePasswordModalText()}
                            onCloseModal={cancelEditing}
                            activateEmailLogin={() => {
                                cancelEditing();
                                setOpenedModalType(LoginDetailsModalTypeEnum.LOGIN_PASSWORD_ADD);
                                setFormNextStep(openedModalType);
                            }}
                        />
                    </Col>
                    {isLoadingUserData && <LoadingIndicator />}
                </Row>
            </Container>
        </>
    );
};

export default LoginDetailsSettings;
