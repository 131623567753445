import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from '../../hooks/use-api';
import { useInvoiceSummary } from '../../hooks/use-invoice-summary';
import { useQueryWithError } from '../../hooks/use-query-with-error';
import { IRootState } from '../../reducers';
import { buildUnitedDeliveryPointsSearchQueryKey } from '../../utils/react-query-utils';
import InvoiceHeader from './components/invoices-header';
import InvoicesSummary from './invoices-summary';
import InvoicesTable from './invoices-table';

export const searchQueryDataForUnitedDeliveryPointsData = {
    deliveryPoint: { hidden: false },
    pairingDone: true,
    paging: { size: 1000 },
};

const Invoices: React.FC = () => {
    const [showInfo, setShowInfo] = useState(false);
    const api = useApi();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const invoicesSummaryData = useInvoiceSummary();

    const { data: unitedDeliveryPointsData } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, searchQueryDataForUnitedDeliveryPointsData),
        async () =>
            loggedInCustomer?.id
                ? api.customers
                      .searchUnitedDeliveryPoint(loggedInCustomer?.id, searchQueryDataForUnitedDeliveryPointsData, undefined, {
                          secure: true,
                      })
                      .then((res) => (res.data?.result ? res.data?.result : null))
                : null,
    );

    return (
        <>
            <InvoiceHeader />

            <InvoicesSummary showInfo={showInfo} invoicesSummary={invoicesSummaryData} />
            <InvoicesTable unitedDeliveryPoints={unitedDeliveryPointsData} setShowInfo={setShowInfo} invoicesSummary={invoicesSummaryData} />
        </>
    );
};

export default Invoices;
