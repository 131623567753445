import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { BaseRadio, formatAddressShort, isCustomerRequestPaid } from '../../../../../main';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';

const BlockDeliveryPointInvoiceOutOfCycle: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { additionalData, deliveryPointZp, deliveryPointEe, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPoints = additionalData?.udpd?.deliveryPoints;

    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint>();

    useEffect(() => {
        dispatch(CustomerRequestActions.setRequestIsPaid({ paid: false }));
    }, []);

    useEffect(() => {
        deliveryPoints && dispatch(CustomerRequestActions.setDeliveryPoint(deliveryPoints[0])); //just for adress of delivery point in request header
        const selectedDpUuid = deliveryPointZp?.id ?? deliveryPointEe?.id ?? additionalData?.dpuuid;
        if (selectedDpUuid) {
            const selected = deliveryPoints?.find((db) => db.id === selectedDpUuid);
            if (selected) {
                setSelectedDeliveryPoint(selected);
                return;
            }
        }
        if (deliveryPoints?.length === 1) {
            setSelectedDeliveryPoint(deliveryPoints[0]);
        }
    }, [dispatch, deliveryPointEe?.id, deliveryPointZp?.id, additionalData, deliveryPoints]);

    const onSubmit = () => {
        if (!customerRequestTemplate || !additionalData?.udpd?.businessPartner?.queue) {
            return;
        }
        if (selectedDeliveryPoint?.type === CommodityEnum.ELECTRICITY) {
            dispatch(CustomerRequestActions.setDeliveryPointEe(selectedDeliveryPoint));
            dispatch(CustomerRequestActions.setDeliveryPointZp(undefined));
            if (isCustomerRequestPaid(customerRequestTemplate, additionalData.udpd.businessPartner.queue)) {
                dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.ELECTRICITY }));
            }
        } else {
            dispatch(CustomerRequestActions.setDeliveryPointZp(selectedDeliveryPoint));
            dispatch(CustomerRequestActions.setDeliveryPointEe(undefined));
            if (isCustomerRequestPaid(customerRequestTemplate, additionalData.udpd.businessPartner.queue)) {
                dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.GAS }));
            }
        }
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <>
            <p className="text-left text-lg mb-4 mt-0 ">
                <Trans i18nKey="customer-request.steps.delivery-point-invoice-out-of-cycle.requested-invoice" />
            </p>
            {deliveryPoints?.map((dp) => (
                <Card key={`deliveryPoint_${dp.id}`} className="mb-3">
                    <CardBody className="py-2 px-4">
                        <BaseRadio
                            className="mt-1"
                            id={`radio-delivery-point-${dp.id}`}
                            onChange={() => setSelectedDeliveryPoint(dp)}
                            checked={selectedDeliveryPoint?.id === dp.id}
                            label={
                                <>
                                    <b>
                                        {formatAddressShort(dp.address)} -{' '}
                                        {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')}
                                    </b>
                                    <p className="mb-0"> {t('delivery-point.contract-number', { number: dp.contract?.externalId })} </p>
                                </>
                            }
                        />
                    </CardBody>
                </Card>
            ))}
            <BaseButton type="button" onClick={onSubmit} disabled={deliveryPoints?.find((db) => db.id === selectedDeliveryPoint?.id) ? false : true}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockDeliveryPointInvoiceOutOfCycle;
