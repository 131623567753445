import { ErrorMessage } from '@hookform/error-message';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseRadio from '../../../components/common/base-radio';
import { useFormRules } from '../../../hooks/use-form-rules';
import { formatBusinessPartner } from '../../../utils/utils';

interface ISelectBusinessPartnerDeliveryPointsFormFieldProps {
    name: string;
    groupedDeliveryPoints: Record<string, UnitedDeliveryPointSummary[]>;
    register: (options: any) => any;
    errors?: {
        [key: string]: any;
    };
    autoFocus?: boolean;
}

export const SelectBusinessPartnerDeliveryPointsFormField: React.FC<ISelectBusinessPartnerDeliveryPointsFormFieldProps> = ({
    name,
    register,
    errors,
    groupedDeliveryPoints,
    children,
    autoFocus,
}) => {
    const { formRules } = useFormRules();
    const { t } = useTranslation();
    return (
        <>
            {Object.entries(groupedDeliveryPoints).map(([bpId, deliveryPoints], index) => {
                const businessPartner = deliveryPoints[0].businessPartner;
                return businessPartner == null ? null : (
                    <BaseRadio
                        key={bpId}
                        ref={register(formRules.required)}
                        id={`businessPartnerDeliveryPointsRadio${bpId}`}
                        name={name}
                        value={bpId}
                        label={`${formatBusinessPartner(businessPartner)} — ${t('settings.e-invoices.delivery-points-count', {
                            count: deliveryPoints.length,
                        })}`}
                        className="mb-3"
                        autoFocus={autoFocus && index === 0}
                    />
                );
            })}
            {children}
            {errors && (
                <div style={{ color: 'red' }} className="mt-2">
                    <ErrorMessage errors={errors} name={name} />
                </div>
            )}
        </>
    );
};
