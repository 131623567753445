import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import BaseFileInputButton from '../../../../../../components/common/base-file-input-button';
import { useApi } from '../../../../../../hooks/use-api';
import useMutationWithError from '../../../../../../hooks/use-mutation-with-error';
import { IAttachments } from '../../../../../../models/customer-request-model';
import FileItem from '../../../../../customer-request/screen/steps-customer-request/block-attachments/components/file-item';

// file size in MB
const FILE_SIZE = 10;
const MAX_FILE_SIZE = 1024 * 1024 * FILE_SIZE;
const MAX_FILES_LIMIT = 10;

interface IAttachmentsComponent {
    files: IAttachments[];
    setFiles: Dispatch<SetStateAction<IAttachments[]>>;
    label?: string;
    subtitle?: string | ReactNode;
    errors?: { [key: string]: any };
    clearErrors?: (name?: any) => void;
    className?: string;
    info?: string;
    singleAttachment?: boolean;
}

const Attachments: React.FC<IAttachmentsComponent> = ({
    files,
    setFiles,
    label,
    subtitle,
    errors,
    clearErrors,
    className,
    info,
    singleAttachment,
}) => {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const api = useApi();
    const [showErrorDescription, setShowErrorDescription] = useState<boolean>(false);

    const onFilePick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files || [];

        if (fileList?.length > 0) {
            clearErrors && clearErrors('files');
            if (fileList[0].size > MAX_FILE_SIZE) {
                setShowErrorDescription(true);
                addToast(t('common.file-size', { count: FILE_SIZE }), { appearance: 'error' });
                return;
            }
            if (files.length >= MAX_FILES_LIMIT) {
                setShowErrorDescription(true);
                addToast(t('common.file-count-limit-exceeded', { count: MAX_FILES_LIMIT }), { appearance: 'error' });
                return;
            }
            setFiles((prevState) => [
                ...prevState,
                {
                    uuid: uuidv4(),
                    file: fileList[0],
                    info: info,
                },
            ]);
        }
    };

    //delete already sent attachment
    const [mutateDeleteAttachment] = useMutationWithError(async (uuid: string) => api.customerRequests.deleteAttachment(uuid, { secure: true }), {
        onSuccess: (resp, uuid) => {
            setFiles((prevState) => {
                return [...prevState.filter((i: IAttachments) => uuid !== i.uuid)];
            });
        },
        onErrorWithGlobalErrorHandling: () => {
            addToast(t('common.attachment-listitem-file-delete-error'), {
                appearance: 'error',
            });
            return true;
        },
    });

    const onFileDelete = (item: IAttachments) => {
        if (!item.file) {
            mutateDeleteAttachment(item.uuid);
        } else {
            setFiles((prevState) => {
                return [...prevState.filter((i: IAttachments) => item.uuid !== i.uuid)];
            });
        }
    };

    return (
        <>
            <h6 className="my-3">{label ? label : <Trans i18nKey="customer-request.steps.attachments.attachments-title2" />}</h6>
            {subtitle}
            {showErrorDescription && (
                <div style={{ fontSize: 13, color: 'red' }} className="mb-2">
                    {t('customer-request.steps.attachments.max-files-limit')}
                    <b> {MAX_FILES_LIMIT}</b>
                    <br />
                    {t('customer-request.steps.attachments.max-file-size')}
                    <b> {FILE_SIZE}MB</b>
                    <br />
                    {t('customer-request.steps.attachments.allowed-files-type')} <b> {t('customer-request.steps.attachments.file-types')}</b>
                </div>
            )}
            <ListGroup className={className ? className : 'mb-4'}>
                {files
                    .filter((item) => (info ? item.info === info : true))
                    .map((item) => (
                        <FileItem key={item.uuid} item={item} onDelete={onFileDelete} />
                    ))}

                {(!singleAttachment || (singleAttachment && files.length !== 1)) && (
                    <ListGroupItem>
                        <BaseFileInputButton onChange={onFilePick}>
                            <div className="text-decoration-none d-flex justify-content-between align-items-center">
                                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                                <span className="mr-auto">
                                    <Trans i18nKey="notifications.email.add-attachment">Nahrať prílohu</Trans>
                                </span>
                            </div>
                        </BaseFileInputButton>
                    </ListGroupItem>
                )}
                {errors && (
                    <div style={{ color: 'red' }}>
                        {t(`common.input-rules.${errors?.files?.type}`) === `common.input-rules.${errors?.files?.type}` ? (
                            <ErrorMessage errors={errors} name={'files'} />
                        ) : (
                            <>{errors?.files && t(`common.input-rules.${errors.files.type}`)} </>
                        )}
                    </div>
                )}
            </ListGroup>
        </>
    );
};

export default Attachments;
