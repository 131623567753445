import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummaryResponse,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Checkbox from '../../../../components/common/checkbox';
import FullTextSearchBox from '../../../../components/common/full-text-search-box';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { buildUnitedDeliveryPointsSearchQueryKey, IRootState } from '../../../../main';
import { formatAddressShort } from '../../../../utils/utils';

interface IBulkNotificationSettingSelectDeliveryPointsProps {
    selectedDeliveryPointIds: string[];
    onSelect: (selectedDeliveryPointIds: string[]) => void;
}

interface IUnitedDeliveryPointSummary extends UnitedDeliveryPointSummary {
    id: string;
}

const BulkNotificationSettingSelectDeliveryPoints: React.FC<IBulkNotificationSettingSelectDeliveryPointsProps> = ({
    selectedDeliveryPointIds,
    onSelect,
}) => {
    const [deliveryPoints, setDeliveryPoints] = useState<IUnitedDeliveryPointSummary[]>([]);
    const [fullTextSearch, setFullTextSearch] = useState<string>('');
    const [selection, setSelection] = useState<string[]>(selectedDeliveryPointIds);

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const unitedDeliveryPointsFilter = {
        ft: fullTextSearch,
        deliveryPoint: { hidden: false },
        includeInactive: false,
        pairingDone: true,
        paging: { size: 5000, page: 0 },
    };
    const api = useApi();
    const { isLoading } = useQueryWithError<UnitedDeliveryPointSummaryResponse | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, unitedDeliveryPointsFilter),
        async () =>
            loggedInCustomer?.id == null
                ? null
                : api.customers
                      .searchUnitedDeliveryPoint(loggedInCustomer.id, unitedDeliveryPointsFilter, undefined, { secure: true })
                      .then((res) => res.data),
        {
            onSuccess: (data: UnitedDeliveryPointSummaryResponse | null) => {
                if (data?.result != null) {
                    const dpIds: IUnitedDeliveryPointSummary[] = data.result.reduce<IUnitedDeliveryPointSummary[]>(
                        (acc, dp) => (dp.id == null ? acc : acc.concat([{ ...dp, id: dp.id }])),
                        [],
                    );
                    setDeliveryPoints(dpIds);
                }
            },
        },
    );

    const areAllChecked = () => {
        return deliveryPoints.every((dp) => dp.id && selection.includes(dp.id));
    };
    const toggleAll = () => {
        if (areAllChecked()) {
            setSelection([]);
        } else {
            setSelection(deliveryPoints.map((dp) => dp.id));
        }
    };
    const onToggle = (dpId: string) => () => {
        if (selection.includes(dpId)) setSelection((prev) => prev.filter((x) => x !== dpId));
        else setSelection((prev) => prev.concat([dpId]));
    };

    const onAccept = () => {
        onSelect(selection);
    };

    const onFulltextChange = (newFulltext: string) => {
        setFullTextSearch(newFulltext);
        setSelection([]);
    };

    return (
        <>
            <h4 className="mb-3">
                <Trans i18nKey="settings.notifications.select-delivery-points" />
            </h4>

            <FullTextSearchBox className="mb-3" fullText={fullTextSearch} setFullText={onFulltextChange} />

            {isLoading && <LoadingIndicator />}
            {deliveryPoints.length > 0 && (
                <>
                    <form>
                        <Checkbox
                            checked={areAllChecked()}
                            onChange={toggleAll}
                            id={'selectAlldeliveryPoints'}
                            name=""
                            label={<Trans i18nKey="settings.notifications.select-all-delivery-points" />}
                        />

                        <hr className="w-100" />

                        {deliveryPoints.map((dp) => (
                            <Checkbox
                                key={dp.id}
                                id={`deliveryPoints.${dp.id}`}
                                name="deliveryPoints"
                                label={formatAddressShort({ street: dp.address?.street, streetNumber: dp.address?.streetNumber })}
                                checked={selection.includes(dp.id)}
                                onChange={onToggle(dp.id)}
                            />
                        ))}

                        <Button type="button" block color="primary" className="mt-4" onClick={onAccept}>
                            <Trans i18nKey="settings.notifications.confirm-delivery-points-selection" />
                        </Button>
                    </form>
                </>
            )}
        </>
    );
};

export default BulkNotificationSettingSelectDeliveryPoints;
