import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { DropdownToggle, Nav, UncontrolledButtonDropdown } from 'reactstrap';
import { TABSEnums } from '../delivery-point-detail';
import { TabsData } from '../delivery-point-detail-navigation-data';
import { MyDeductionsDropdownMenu } from './my-deductions-dropdown-menu';

interface IProps {
    navigationProps: TabsData;
    activeTab: TABSEnums | undefined;
    onTabChange: (value: TABSEnums, options?: { replaceUrl?: boolean; hash?: string }) => void;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    showExtraClasses: boolean;
    isUdpInactive?: boolean;
    isUdpSharedToMe?: boolean;
    isSelectedDpActive?: boolean;
}

function DeliveryPointNavigation({
    navigationProps,
    activeTab,
    onTabChange,
    showExtraClasses,
    isUdpInactive,
    isUdpSharedToMe,
    isSelectedDpActive,
    unitedDeliveryPoint,
}: IProps): ReactElement {
    return (
        <div className={classNames({ 'bg-two-colors': true, 'd-none d-md-block': !!activeTab })}>
            <nav className="container mb-5">
                <Nav className="nav nav-pills nav-justified align-items-stretch">
                    {Object.keys(navigationProps).map((tabKey) => {
                        const { classes, icon, active, clickEvent, name, message, extraClasses, visible } = navigationProps[
                            tabKey as keyof typeof TABSEnums
                        ];
                        if (visible === false) {
                            return null;
                        }
                        return (
                            <span
                                key={tabKey}
                                aria-current="page"
                                className={classNames(
                                    classes,
                                    'nav-link',
                                    { active: active },
                                    extraClasses != null ? { [extraClasses]: showExtraClasses } : {},
                                )}
                                onClick={clickEvent}
                            >
                                <span>
                                    <i className={icon}></i> <Trans i18nKey={name}></Trans>
                                    {!!message && <div className="font-weight-normal text-danger">{message}</div>}
                                </span>
                            </span>
                        );
                    })}
                </Nav>

                <div className="d-block d-md-none position-relative my-3 mx-3">
                    <UncontrolledButtonDropdown group={false} direction="down" className="custom-button-dropdown">
                        <DropdownToggle caret color="light">
                            <div>
                                <i className="icon-book-open fa-lg mr-3"></i>
                                <Trans i18nKey="delivery-point.detail.navigation.my-notes" />
                            </div>
                        </DropdownToggle>
                        <MyDeductionsDropdownMenu
                            canEdit={!isUdpInactive && !isUdpSharedToMe}
                            onTabChange={onTabChange}
                            unitedDeliveryPoint={unitedDeliveryPoint}
                        />
                    </UncontrolledButtonDropdown>
                </div>
            </nav>
        </div>
    );
}

export default DeliveryPointNavigation;
