import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import BusinessPartnerCompanyItem from './business-partner-company-item';
import BusinessPartnerPersonItem from './business-partner-person-item';

type Props = {
    data: IBusinessPartner;
    bold?: boolean;
};

const OwnerItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    if (!data.type) return null;
    return (
        <>
            {data.firstName ? (
                <BusinessPartnerPersonItem data={data} bold={bold} title={t('customer-request.steps.owner-object.title')} />
            ) : (
                <BusinessPartnerCompanyItem
                    data={data}
                    bold={bold}
                    title={t('customer-request.steps.owner-object.title')}
                ></BusinessPartnerCompanyItem>
            )}
        </>
    );
};

export default OwnerItem;
