import React, { useState } from 'react';
type Props = {
    children: JSX.Element | JSX.Element[] | string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    [key: string]: any;
    disabled?: boolean;
};

const BaseFileInputButton: React.FC<Props> = ({ children, onChange, disabled, ...params }) => {
    const [inputKey, setInputKey] = useState<string>('fileInput');

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
            setInputKey(`fileInput-${new Date()}`);
        }
    };

    return (
        <label style={{ cursor: disabled ? 'default' : 'pointer', margin: 0 }}>
            {children}
            <input key={inputKey} {...params} style={{ display: 'none' }} type="file" onChange={onValueChange} />
        </label>
    );
};

export default BaseFileInputButton;
