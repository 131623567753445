import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import BaseTextarea from '../../../../../components/common/base-textarea';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../hooks/use-form-rules';

type Props = {
    currentValue: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    editedValues: { paymentPeriod: SkSppNzpCommonsApiCodelistCodeListItem | undefined; paymentAmount: number | undefined };
    onSubmit: (newPaymentAmount: number, reason?: string) => void;
    onReset: () => void;
    isLoading?: boolean;
};

const getNumericValueForContractPaymentPeriod = (paymentPeriod: string) => {
    return Number(paymentPeriod);
};

const ChangeAdvancePaymentForm: React.FC<Props> = ({ currentValue, editedValues, onSubmit, onReset, isLoading }) => {
    const displayValue = {
        paymentPeriod: editedValues.paymentPeriod ?? currentValue.paymentPeriod,
        paymentAmount: editedValues.paymentAmount ?? currentValue.paymentAmount,
    };

    const recommendedPaymentAmount = useMemo(() => {
        if (currentValue.paymentAmount != null && currentValue.paymentPeriod?.code != null && displayValue.paymentPeriod?.code != null) {
            // recalculated original value per 1 month
            const originalPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(currentValue.paymentPeriod.code);
            const currentPaymentAmountBase = Math.round(currentValue.paymentAmount / originalPaymentPeriodMultiplier);

            const editedPaymentPeriodMultiplier = getNumericValueForContractPaymentPeriod(displayValue.paymentPeriod.code);

            return currentPaymentAmountBase * editedPaymentPeriodMultiplier;
        }
        return undefined;
    }, [currentValue.paymentAmount, currentValue.paymentPeriod?.code, displayValue.paymentPeriod?.code]);

    const minimalPaymentValue = useMemo(() => {
        if (recommendedPaymentAmount == null) {
            return 1;
        } else {
            return Math.round(Math.max(recommendedPaymentAmount * 0.8, 1));
        }
    }, [recommendedPaymentAmount]);

    const { register, handleSubmit, watch, errors, trigger, getValues } = useForm<{ advancePayment: string; reason?: string }>({
        defaultValues: { advancePayment: displayValue.paymentAmount ? Number(displayValue.paymentAmount).toString() : '', reason: '' },
    });

    const onFormSubmit = handleSubmit((fields) => {
        const numericAdvancePayment = Number(fields.advancePayment);
        if (Number.isNaN(numericAdvancePayment)) {
            onSubmit(0);
        } else {
            onSubmit(numericAdvancePayment, fields.reason);
        }
    });

    const { formRules, requiredTrimed } = useFormRules();

    const advancePaymentInputValue = watch('advancePayment');

    const isSubmitAllowed = () =>
        !(
            editedValues.paymentPeriod?.uuid === currentValue.paymentPeriod?.uuid &&
            Number(getValues('advancePayment')) === currentValue.paymentAmount
        );

    return (
        <form onSubmit={onFormSubmit} noValidate onReset={onReset}>
            <h4>
                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.amount-of-the-pay">Aká bude výška platby?</Trans>
            </h4>

            <InputWithAppend
                type="number"
                ref={register({
                    ...formRules.requiredInteger,
                    ...formRules.min(1),
                })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                name="advancePayment"
                id={'advancePayment'}
                label={<Trans i18nKey="delivery-point.detail.payment-data.advance-payments.advance-payment-amount">Výška preddavkovej platby</Trans>}
                trigger={trigger}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        €
                    </button>
                }
                autoFocus
            />

            <div className="mb-4">
                {recommendedPaymentAmount != null && recommendedPaymentAmount > 0 && (
                    <>
                        <b>
                            <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.recommended-payment">Odporúčaná výška:</Trans>{' '}
                            {recommendedPaymentAmount} € / {displayValue.paymentPeriod?.name}
                        </b>
                        <br />
                    </>
                )}
                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.minimum-payment">Minimálna výška:</Trans> {minimalPaymentValue} €
                / {displayValue.paymentPeriod?.name}
                <br />
                {recommendedPaymentAmount != null && recommendedPaymentAmount > 0 && (
                    <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.based-on-current-consumption">
                        určené na základe Vašej spotreby
                    </Trans>
                )}
                {/* <Trans i18nKey="delivery-point.detail.modals.based-on-your-consumption">určená na základe vašej spotreby</Trans> */}
            </div>

            {/* <div className="mb-4">
                <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.integer-positive-number">
                    Výška preddavkovej platby musí byť kladné celé číslo.
                </Trans>
                {currentValue != null && (
                    <>
                        {' '}
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.allowed-advance-payment-amount">
                            Minimálna výška vašej preddavkovej platby
                        </Trans>
                        {' ('}
                        <Trans i18nKey="delivery-point.detail.modals.based-on-your-consumption">určená na základe vašej spotreby</Trans>
                        {'): '}
                        {formatCurrency(minValueRestriction)}
                    </>
                )}
            </div> */}

            {currentValue != null && Number(advancePaymentInputValue) < minimalPaymentValue && (
                <div className="mb-4">
                    <h4>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.reduction-reason">Dôvod zníženia</Trans>
                    </h4>
                    <p>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.less-than-minimum">
                            Zadali ste výšku platby, ktoré je nižšia ako minimálna. Uveďte dôvod, prečo požadujete zníženie.
                        </Trans>
                    </p>
                    <BaseTextarea
                        ref={register(requiredTrimed)}
                        errors={errors}
                        name="reason"
                        rows={3}
                        maxLength={200}
                        label={<Trans i18nKey="customer-request.steps.amount-advances.reason" />}
                    />
                </div>
            )}

            {/* Help button */}
            {/* <HelpLinkWithModal showAsCard className="my-4" linkTitle="common.dialog-help" field="DOM_ADVANCE_PAYMENT_CHANGE_HELP" /> */}

            <hr />

            <div className="text-center">
                <span>
                    <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.current-payment">Aktuálna platba:</Trans>{' '}
                    {currentValue.paymentAmount} € / {currentValue.paymentPeriod?.name}
                </span>
                <br />
                {advancePaymentInputValue != null && Number(advancePaymentInputValue) > 0 && (
                    <b>
                        <Trans i18nKey="delivery-point.detail.payment-data.advance-payments.requested-payment">Požadovaná platba:</Trans>{' '}
                        {advancePaymentInputValue} € / {editedValues.paymentPeriod?.name}
                    </b>
                )}
            </div>

            <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!isSubmitAllowed() || isLoading}>
                <Trans i18nKey="delivery-point.detail.modals.request-change">Požiadať o zmenu</Trans>
            </Button>
            <Button type="reset" block color="secondary" size="lg" className="mt-3">
                <Trans i18nKey="delivery-point.detail.modals.cancel">Zrušiť</Trans>
            </Button>
        </form>
    );
};

export default ChangeAdvancePaymentForm;
