import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: boolean;
    bold?: boolean;
    code: string;
};

const StatementItem: React.FC<Props> = ({ data, bold, code }) => {
    const [t] = useTranslation();

    return (
        <>
            {data && (
                <ItemPreview title={t(`customer-request.steps.statement.title`)}>
                    <ItemPreviewContent bold={bold}>
                        <Trans i18nKey={`customer-request.steps.statement.${code}`} />
                    </ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default StatementItem;
