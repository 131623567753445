import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary, SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import BaseRadio from '../../../../components/common/base-radio';
import Input from '../../../../components/common/input';
import { useFormRules } from '../../../../hooks/use-form-rules';
import { IRootState } from '../../../../reducers';
import { formatAddressShort } from '../../../../utils/utils';
import { getEInvoiceEmailsInUDPs } from '../utils/einvoice-utils';

interface IActivateEInvoiceSelectEmailFormProps {
    onSelect: (email: string) => void;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    alreadyActivatedDeliveryPoint: boolean;
    onlyNotActivatedDeliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
}

const ActivateEInvoiceSelectEmailForm: React.FC<IActivateEInvoiceSelectEmailFormProps> = ({
    onSelect,
    unitedDeliveryPoint,
    alreadyActivatedDeliveryPoint,
    onlyNotActivatedDeliveryPoint,
}) => {
    const { register, handleSubmit, errors, trigger } = useForm<{ email: string; customEmail: string }>();
    const { t } = useTranslation();
    const portalAccountEmail = useSelector((state: IRootState) => state.user.customer?.email);
    const onFormSubmit = handleSubmit((fields) => {
        onSelect(fields.customEmail || fields.email);
    });

    const { formRules, requiredTrimed } = useFormRules();

    const [isCustom, setIsCustom] = useState(false);

    const additionalEmails = useMemo(() => {
        return getEInvoiceEmailsInUDPs([unitedDeliveryPoint]).filter((email) => email !== portalAccountEmail);
    }, [unitedDeliveryPoint, portalAccountEmail]);

    return (
        <>
            <p className="forms-headline mb-4">
                <Trans i18nKey="common.delivery-point" />:{' '}
                {formatAddressShort({
                    street: unitedDeliveryPoint.address?.street,
                    streetNumber: unitedDeliveryPoint.address?.streetNumber,
                })}
            </p>

            {alreadyActivatedDeliveryPoint && (
                <div className="mb-4 black-font">
                    {t('delivery-point.contract')} {onlyNotActivatedDeliveryPoint?.map((dp) => dp.contract?.externalId).join(', ')}
                </div>
            )}

            <h4 className="mb-3">
                <Trans i18nKey="settings.e-invoices.select-email" />
            </h4>
            <form onSubmit={onFormSubmit} noValidate>
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`accountEmailRadio`}
                    name="email"
                    value={portalAccountEmail}
                    label={portalAccountEmail || ''}
                    className="mb-3"
                    onChange={() => setIsCustom(false)}
                />
                {additionalEmails.map((email, index) => (
                    <BaseRadio
                        key={index}
                        ref={register(formRules.required)}
                        errors={errors}
                        id={`emailRadio${index}`}
                        name="email"
                        value={email}
                        label={email}
                        className="mb-3"
                        onChange={() => setIsCustom(false)}
                    />
                ))}
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`enterCustomEmailRadio`}
                    name="email"
                    value={undefined}
                    label={<Trans i18nKey="settings.e-invoices.select-different-email" />}
                    className="mb-3"
                    onChange={() => setIsCustom(true)}
                />
                {isCustom && (
                    <Input
                        ref={register({ ...requiredTrimed, ...formRules.email })}
                        trigger={trigger}
                        errors={errors}
                        type="text"
                        name="customEmail"
                        label={<Trans i18nKey="common.email" />}
                        autoCapitalize="off"
                        autoCorrect="off"
                    />
                )}

                <Button type="submit" color="primary" size="lg" block className="mt-5">
                    <Trans i18nKey="settings.e-invoices.einvoice-activation" />
                </Button>
            </form>
        </>
    );
};

export default ActivateEInvoiceSelectEmailForm;
