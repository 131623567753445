import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { AddDeliveryPointActions } from '../../actions/add-delivery-point-actions';
import { useInvoiceSummary } from '../../hooks/use-invoice-summary';
import { ClickableElement, Routes } from '../../main';

interface IDeliveryPointsHeaderProps {
    showInactiveDeliveryPointsToggler: boolean;
    showInactiveDeliveryPoints: boolean;
    toggleShowInactiveDeliveryPoints: () => void;
}

export const DeliveryPointsHeader: React.FC<IDeliveryPointsHeaderProps> = ({
    showInactiveDeliveryPointsToggler,
    showInactiveDeliveryPoints,
    toggleShowInactiveDeliveryPoints,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { unpaidExpired } = useInvoiceSummary();

    const dispatch = useDispatch();

    const addDeliveryPoint = function(event: React.MouseEvent) {
        event.preventDefault();
        setDropdownOpen(false);
        dispatch(AddDeliveryPointActions.start(false));
    };

    return (
        <>
            <div className="page-header" hidden={(unpaidExpired?.count || 0) <= 0} style={{ marginBottom: '0.1rem' }}>
                <div className="container">
                    {unpaidExpired ? (
                        <Link
                            to={Routes.INVOICES + '?unpaidOnly'}
                            className="font-weight-500 text-danger clickable-element clickable-element--text navbar-text"
                        >
                            &#9679;&nbsp;&nbsp;
                            <Trans i18nKey={'navbar.invoices-unpaid-expired'} values={{ count: unpaidExpired.count }}>
                                Máte faktúry po splatnosti
                            </Trans>
                            &nbsp;&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="page-header pt-4 pb-md-3 mb-4">
                <Container className="my-3">
                    <Row>
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center my-md-4">
                                <h1>
                                    <Trans i18nKey="delivery-point.list-delivery-points.title">Odberné miesta</Trans>
                                </h1>
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-block btn-md-inline mr-3 d-none d-md-inline"
                                        onClick={addDeliveryPoint}
                                    >
                                        <small className="mr-2 button-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </small>
                                        <Trans i18nKey="delivery-point.list-delivery-points.add">Pridať odberné miesto</Trans>
                                    </Button>
                                    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="d-inline">
                                        <DropdownToggle data-toggle="dropdown" aria-expanded={dropdownOpen} className="btn-light btn-round">
                                            <i className="icon-context-points" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <ClickableElement className="dropdown-item py-3 border-bottom" onClick={addDeliveryPoint}>
                                                <Trans i18nKey="delivery-point.list-delivery-points.add">Pridať odberné miesto</Trans>
                                            </ClickableElement>
                                            <Link className={'dropdown-item py-3 border-bottom'} to={Routes.MANAGE_DELIVERY_POINTS}>
                                                <Trans i18nKey="delivery-point.list-delivery-points.manage">Spravovať odberné miesta</Trans>
                                            </Link>
                                            <Link
                                                className={`dropdown-item py-3 d-none d-lg-block ${
                                                    showInactiveDeliveryPointsToggler ? 'border-bottom' : ''
                                                }`}
                                                to={Routes.SHARE_DELIVERY_POINTS}
                                            >
                                                <Trans i18nKey="delivery-point.list-delivery-points.share-multiple">Zdieľať odberné miesta</Trans>
                                            </Link>
                                            {showInactiveDeliveryPointsToggler && (
                                                <DropdownItem
                                                    className="dropdown-item py-3"
                                                    onClick={() => {
                                                        toggleShowInactiveDeliveryPoints();
                                                        setDropdownOpen(false);
                                                    }}
                                                >
                                                    {showInactiveDeliveryPoints ? (
                                                        <Trans i18nKey="delivery-point.list-delivery-points.hide-inactive">
                                                            Skryť neaktívne odberné miesta
                                                        </Trans>
                                                    ) : (
                                                        <Trans i18nKey="delivery-point.list-delivery-points.show-inactive">
                                                            Zobraziť neaktívne odberné miesta
                                                        </Trans>
                                                    )}
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
