import React, { createContext, ReactNode, useContext, useState } from 'react';

type ChildrenType = { children: ReactNode | ReactNode[] };
type ContextValueType = {
    selectedDeliveryPoints: string[];
    selectedChangedHandler: (newlySelecteds: string[]) => void;
};

const initialContextValue: ContextValueType = { selectedDeliveryPoints: [], selectedChangedHandler: () => undefined };
const SelectedPointsContext = createContext(initialContextValue);

export const SelectedDeliveryPointsProvider: React.FC<ChildrenType> = ({ children }) => {
    const [selectedDeliveryPoints, setSelectedDeliveryPoints] = useState(initialContextValue.selectedDeliveryPoints);

    const selectedChangedHandler: ContextValueType['selectedChangedHandler'] = (newlySelected) => {
        setSelectedDeliveryPoints([...newlySelected]);
    };

    return <SelectedPointsContext.Provider value={{ selectedDeliveryPoints, selectedChangedHandler }}>{children}</SelectedPointsContext.Provider>;
};

export const useSelectedPoints = (): ContextValueType => useContext(SelectedPointsContext);
