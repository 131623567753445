import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoiceSummary,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import {
    Checkbox,
    CodeListTypeEnum,
    CommodityEnum,
    CustomerRequestBlockTitleEnum,
    formatCurrency,
    formatDate,
    isCustomerRequestPaid,
    LoadingIndicator,
    QueryKeysEnum,
    useApi,
    useCodeList,
} from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { InvoiceType } from '../../../../invoices/components/invoice-type';
import { repaymentAgreementBlocks } from '../../../config/request-config';
import './style.scss';

type DeliveryPointFormType = {
    deliveryPointId: string;
};

const BlockUnpaidInvoices: React.FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const api = useApi();
    const [invoices, setInvoices] = useState<SkSppNzpBeApiCustomerprofileInvoiceSummary[]>();

    const [switchCommodidtyButton, setSwitchCommodidtyButton] = useState(true);

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const { additionalData, invoices: reduxInvoices, content, request, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [selectedInvoices, setSelectedInvoices] = useState<SkSppNzpBeApiCustomerprofileInvoiceSummary[]>(reduxInvoices || []);

    const [comodity, setComodity] = useState<CommodityEnum>(
        reduxInvoices
            ? reduxInvoices.find((item) => item.deliveryPoints && item.deliveryPoints[0].type === CommodityEnum.GAS)
                ? CommodityEnum.GAS
                : CommodityEnum.ELECTRICITY
            : CommodityEnum.GAS,
    );

    const { handleSubmit } = useForm<DeliveryPointFormType>();

    const onSubmit = () => {
        dispatch(CustomerRequestActions.setInvoices(selectedInvoices));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    useEffect(() => {
        let firstInvoice: SkSppNzpBeApiCustomerprofileInvoice | undefined = content.invoice;
        if (firstInvoice === undefined && invoices) {
            firstInvoice = invoices.find(
                (invoice) => invoice.deliveryPoints && invoice.deliveryPoints.length > 0 && invoice.deliveryPoints[0].type && invoice.businessPartner,
            );
        }
        if (
            !firstInvoice ||
            !firstInvoice.businessPartner?.queue ||
            !customerRequestTemplate ||
            !isCustomerRequestPaid(customerRequestTemplate, firstInvoice.businessPartner.queue)
        ) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: false }));
            return;
        }
        if (firstInvoice.deliveryPoints && firstInvoice.deliveryPoints[0].type === CommodityEnum.ELECTRICITY) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.ELECTRICITY }));
        } else {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.GAS }));
        }
    }, [invoices, dispatch, customerRequestTemplate, content.invoice]);

    useEffect(() => {
        if (
            content.invoice &&
            request?.blocks &&
            request?.blocks.length > 0 &&
            request?.blocks[0].key === CustomerRequestBlockTitleEnum.BLOCK_UNPAID_INVOICES
        ) {
            dispatch(CustomerRequestActions.replaceBlocks(repaymentAgreementBlocks));
            nextTick(() => {
                dispatch(CustomerRequestActions.openStep(0));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, request]);

    const { data: invoicesData, isLoading, error } = useQueryWithError<
        SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileInvoiceSummary | null | undefined
    >(['invoicesNewRequestDetailSummary', additionalData?.udpuid], async () =>
        loggedInCustomer?.id && additionalData?.udpuid
            ? api.customers
                  .customerInvoiceSearch(
                      loggedInCustomer?.id,
                      { unitedDeliveryPointIds: [additionalData?.udpuid], customerUnpaid: true, shared: false, paging: { size: 1000 } },
                      { secure: true },
                  )
                  .then((res) => res.data)
            : null,
    );

    const { data: invoiceTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_INVOICE_TYPE,
        codeListTypeEnum: CodeListTypeEnum.INVOICE_TYPE,
        paging: {
            size: 64,
        },
    });

    useEffect(() => {
        if (!isLoading && invoicesData && !error) {
            const filteredInvoices = invoicesData.result?.filter(
                (item) => item.installmentVisibility && item.deliveryPoints && item.deliveryPoints[0].type === comodity,
            );
            const EEinvoices = invoicesData.result?.find(
                (item) => item.installmentVisibility && item.deliveryPoints && item.deliveryPoints[0].type === CommodityEnum.ELECTRICITY,
            );
            const ZPinvoices = invoicesData.result?.find(
                (item) => item.installmentVisibility && item.deliveryPoints && item.deliveryPoints[0].type === CommodityEnum.GAS,
            );
            if (!ZPinvoices) {
                setComodity(CommodityEnum.ELECTRICITY);
            }
            if (!EEinvoices || !ZPinvoices) {
                setSwitchCommodidtyButton(false);
            }
            setInvoices(filteredInvoices);
        }
    }, [isLoading, invoicesData, error, comodity, switchCommodidtyButton]);

    const isInvoiceSelectionDisabled = (invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary): boolean => {
        if (selectedInvoices.length === 0) {
            return false;
        }
        if (selectedInvoices.find((item) => item.id === invoice.id)) {
            return false;
        }
        if (selectedInvoices.find((item) => item.contractAccount?.id !== invoice.contractAccount?.id)) {
            return true;
        }
        return false;
    };

    const overallPrice = () => {
        let price = 0;
        selectedInvoices.forEach((item) => (price = item.unpaid ? price + item.unpaid : price));
        return price;
    };

    return (
        <>
            {!invoices && <LoadingIndicator />}
            {switchCommodidtyButton && (
                <div className="text-center">
                    <ButtonGroup className="d-md-inline-flex custom-buttons-group mb-4 w-100" style={{ maxWidth: 350 }}>
                        <button
                            onClick={() => setComodity(CommodityEnum.GAS)}
                            className={classNames('btn', {
                                active: comodity === CommodityEnum.GAS,
                            })}
                            disabled={
                                selectedInvoices.find(
                                    (invoice) => invoice.deliveryPoints && invoice.deliveryPoints[0].type === CommodityEnum.ELECTRICITY,
                                )
                                    ? true
                                    : false
                            }
                        >
                            {t('enums.Commodity.GAS')}
                        </button>
                        <button
                            onClick={() => setComodity(CommodityEnum.ELECTRICITY)}
                            className={classNames('btn', {
                                active: comodity === CommodityEnum.ELECTRICITY,
                            })}
                            disabled={
                                selectedInvoices.find((invoice) => invoice.deliveryPoints && invoice.deliveryPoints[0].type === CommodityEnum.GAS)
                                    ? true
                                    : false
                            }
                        >
                            {t('enums.Commodity.ELECTRICITY')}
                        </button>
                    </ButtonGroup>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {invoices?.map((invoice) => (
                    <Card key={`deliveryPoint_${invoice.id}`} className="mb-3">
                        <CardBody className="py-2 px-4">
                            <Checkbox
                                className=" mr-2 d-inline-block"
                                checked={selectedInvoices.find((item) => item.id === invoice.id) ? true : false}
                                onChange={() => {
                                    if (selectedInvoices.find((item) => item.id === invoice.id)) {
                                        let tmpInvoices = [...selectedInvoices];
                                        tmpInvoices = tmpInvoices.filter((item) => item.id !== invoice.id);
                                        setSelectedInvoices(tmpInvoices);
                                    } else {
                                        const tmpInvoices = [...selectedInvoices];
                                        tmpInvoices.push(invoice);
                                        setSelectedInvoices(tmpInvoices);
                                    }
                                }}
                                disabled={isInvoiceSelectionDisabled(invoice)}
                                id={`invoice_id_${invoice.id}`}
                                name={`invoice_id_${invoice.id}`}
                                label={
                                    <p className="ml-3">
                                        <span className="black-font">
                                            <b>
                                                <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} /> z{' '}
                                                {invoice.issueAt && formatDate(new Date(invoice.issueAt))}
                                            </b>
                                        </span>
                                        <br />

                                        <small>
                                            {invoice.deliveryPoints && t(`enums.CommodityEnum.${invoice.deliveryPoints[0].type}`)} ·{' '}
                                            {t('customer-request.steps.unpaid-invoices.invoice-number', { number: invoice.reference })}
                                        </small>
                                        <br />
                                        <span className="mt-1 black-font">
                                            {t('customer-request.steps.unpaid-invoices.unpaid-price', { price: formatCurrency(invoice.unpaid || 0) })}{' '}
                                        </span>
                                    </p>
                                }
                            />
                        </CardBody>
                    </Card>
                ))}

                <div className="row p-4 m-1">
                    <div className="col-1 p-0">
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                    </div>
                    <div className="col">
                        <Trans i18nKey="customer-request.steps.unpaid-invoices.info">
                            Niektoré kombinácie faktúr nie je možné zvoliť v rámci jednej žiadosti, môžete však podať samostatnú žiadosť.
                        </Trans>
                    </div>
                </div>
                <div className="mt-2 mb-4 text-center overall-price">
                    {t('customer-request.steps.unpaid-invoices.overall-price', { price: formatCurrency(overallPrice()) })}
                </div>
                <BaseButton type="submit" disabled={selectedInvoices.length === 0}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockUnpaidInvoices;
