import { useMemo } from 'react';
import { useWindowSize } from './use-window-size';

interface IWidthBreakpoints {
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export const useWindowWidthBreakpoints = (): IWidthBreakpoints | undefined => {
    const windowSize = useWindowSize();

    const breakpoints = useMemo<IWidthBreakpoints | undefined>(() => {
        if (windowSize.width == null) {
            return undefined;
        }
        const width = windowSize.width;
        return {
            sm: width >= 576,
            md: width >= 768,
            lg: width >= 992,
            xl: width >= 1200,
        };
    }, [windowSize.width]);

    return breakpoints;
};
