import { ErrorMessage } from '@hookform/error-message';
import {
    SkSppNzpBeApiCustomerprofileBusinessPartner as BusinessPartner,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IBusinessPartner } from '../../../../../models/customer-request-model';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties, formatBusinessPartnerName, isCustomerRequestPaid } from '../../../../../utils/utils';

type BlockCustomerFormType = IBusinessPartner & {
    businessPartnerId: string;
};

const BlockBusinessPartnerAmountLiabilitiesShort: React.FC = () => {
    const api = useApi();
    const dispatch = useDispatch();
    const { businessPartner, additionalData, partnersDeliveryPoints, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const { formRules } = useFormRules();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const { register, errors, handleSubmit, getValues, trigger } = useForm<BlockCustomerFormType>({
        defaultValues: {
            businessPartnerId: businessPartner?.id,
        },
    });

    const [mutateBusinessPartner] = useMutationWithError<BusinessPartner | null, unknown, { uuid: string }>(
        async ({ uuid }) => api.businessPartners.getBusinessPartnerById(uuid, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const onSubmit = useCallback(async () => {
        await trigger();
        const data = getValues();
        setLoading(true);
        const partner = await mutateBusinessPartner({ uuid: data.businessPartnerId });
        if (partner) {
            dispatch(CustomerRequestActions.setBusinessPartner(partner));
            const { primaryAddress, phone, email } = partner;

            const extraPayload: CustomerRequestPayloadType = {
                address: {
                    street: primaryAddress?.street,
                    number: primaryAddress?.streetNumber,
                    city: primaryAddress?.city,
                    postalCode: primaryAddress?.zipCode,
                },
                contact: {
                    phoneNumber: phone ?? '',
                    email: email ?? '',
                },
            };
            if (customerRequestTemplate && partner.queue && isCustomerRequestPaid(customerRequestTemplate, partner.queue)) {
                const partnerDp = businessPartners && businessPartners[data.businessPartnerId];
                const udp = partnerDp && partnerDp.find((udp) => udp.deliveryPoints && udp.deliveryPoints[0]);
                const type = udp && udp.deliveryPoints && udp.deliveryPoints[0] && udp.deliveryPoints[0].type;
                dispatch(
                    CustomerRequestActions.setRequestIsPaid({
                        paid: true,
                        comodity: type === CommodityEnum.GAS ? CommodityEnum.GAS : CommodityEnum.ELECTRICITY,
                    }),
                );
            } else {
                dispatch(CustomerRequestActions.setRequestIsPaid({ paid: false }));
            }
            dispatch(CustomerRequestActions.setData(clearObjectProperties(extraPayload)));
        }
        setLoading(false);
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    }, [dispatch, getValues, mutateBusinessPartner, trigger, businessPartners, customerRequestTemplate]);

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}
            <p className="text-left text-lg mb-4 mt-0 ">{t('customer-request.steps.businessPartner.select-business-partner')}</p>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
                    const businessPartner = deliveryPoints[0].businessPartner;
                    return businessPartner == null ? null : (
                        <Card key={bpId} className="mb-3">
                            <CardBody>
                                <BaseRadio
                                    ref={register(formRules.required)}
                                    value={bpId}
                                    id={`businessPartnerDeliveryPointsRadio${bpId}`}
                                    name="businessPartnerId"
                                    className="mt-0"
                                    label={
                                        <>
                                            <b>{`${formatBusinessPartnerName(businessPartner)} - ${t('settings.e-invoices.delivery-points-count', {
                                                count: deliveryPoints.length,
                                            })}`}</b>
                                            <br />
                                            {t('delivery-point.customer-number')} {businessPartner.externalId}
                                        </>
                                    }
                                />
                            </CardBody>
                        </Card>
                    );
                })}
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name="businessPartnerId" />
                    </div>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockBusinessPartnerAmountLiabilitiesShort;
