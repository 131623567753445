import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ClickableElement, Routes } from '../../../../main';
import CustomerRequestAddNewModal from '../../../customer-request/modal/customer-request-add-new-modal';
import { isEInvoiceActiveForDP } from '../../../settings/e-invoices/utils/einvoice-utils';
import ShareDeliveryPointModal from '../data/modals/share-delivery-point-modal';
import SelfDeductionsButton from './self-deductions-button';

interface IDeliveryPointDetailActionButtonsProps {
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    showInactiveDeliveryPoints: boolean;
    toggleInactiveDeliveryPoints?: () => void;
    setCanActivateEInvoice: (val: boolean) => void;
    setActivateEInvoiceButtonClicked: (val: boolean) => void;
    setIsActivateEInvoiceModalOpen: (val: boolean) => void;
}

const DeliveryPointDetailActionButtons: React.FC<IDeliveryPointDetailActionButtonsProps> = ({
    unitedDeliveryPoint,
    showInactiveDeliveryPoints,
    toggleInactiveDeliveryPoints,
    setCanActivateEInvoice,
    setActivateEInvoiceButtonClicked,
    setIsActivateEInvoiceModalOpen,
}) => {
    const history = useHistory();

    const isUdpInactive = unitedDeliveryPoint?.deliveryPoints?.every((dp) => dp.status !== 'ACTIVE');
    const isDpSharedToMe = unitedDeliveryPoint.sharing?.by != null;
    const idDpSharedToOther = unitedDeliveryPoint.sharing?.to != null && unitedDeliveryPoint.sharing?.to.length > 0;
    const [modalRequest, setModalRequest] = useState<boolean>(false);

    const isEInvoiceActivated = unitedDeliveryPoint.deliveryPoints
        ?.filter((dp) => dp.status === 'ACTIVE')
        .every((dp) => isEInvoiceActiveForDP(dp) || dp.fieldUpdates?.find((item) => item.type === 'EINVOICE_ACTIVATION'));

    const canActivateEInvoice = useMemo(() => !isDpSharedToMe && !isEInvoiceActivated && !isUdpInactive, [
        isDpSharedToMe,
        isEInvoiceActivated,
        isUdpInactive,
    ]);

    useEffect(() => {
        setCanActivateEInvoice(canActivateEInvoice);
    }, [canActivateEInvoice, setCanActivateEInvoice]);

    const onNewRequest = () => {
        setModalRequest(true);
    };

    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState<boolean>(false);
    const toggleMenuDropdown = () => setIsMenuDropdownOpen((prevState: boolean) => !prevState);

    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
    const toggleShareModal = () => setIsShareModalOpen((prevState: boolean) => !prevState);

    const onDropdownItemClick = (callbacks?: (() => void)[]) => () => {
        callbacks?.forEach((callback) => callback());
    };

    const selfDeductionClick = () => history.push(`${Routes.DELIVERY_POINTS}/${unitedDeliveryPoint.id}${Routes.SELF_READ}`);

    return (
        <>
            {!isUdpInactive && (
                <SelfDeductionsButton
                    visible={
                        !!unitedDeliveryPoint?.deliveryPoints?.some(
                            (deliveryPoint) => deliveryPoint.readingCycleInfo?.requested && deliveryPoint.status === 'ACTIVE',
                        )
                    }
                    onClick={selfDeductionClick}
                />
            )}

            {canActivateEInvoice && (
                <Button
                    type="button"
                    color="success"
                    block
                    className="btn-md-inline my-3 mr-3 my-lg-0"
                    onClick={() => {
                        setActivateEInvoiceButtonClicked(true);
                        setIsActivateEInvoiceModalOpen(true);
                    }}
                >
                    <Trans i18nKey="delivery-point.detail.actions.activate-e-invoice">Aktivovať eFaktúru</Trans>
                </Button>
            )}

            {unitedDeliveryPoint?.sharing?.to != null && unitedDeliveryPoint.sharing.to.length > 0 && (
                <div
                    className="d-inline-block d-md-none mr-3"
                    style={{ borderRadius: '22.5px', fontWeight: 500, padding: '8px 14px', border: '1px solid #D9D9D9' }}
                >
                    <i className="icon-users-16 mr-2" />
                    <Trans i18nKey="delivery-point.detail.shared-by-me">Zdieľam</Trans>
                </div>
            )}

            {(!isUdpInactive || idDpSharedToOther) && (
                <Dropdown isOpen={isMenuDropdownOpen} toggle={toggleMenuDropdown} className="d-inline">
                    <DropdownToggle
                        type="button"
                        id="dropdownMenuButton3001b"
                        className="btn btn-light btn-round"
                        data-toggle="dropdown"
                        aria-haspopup
                        aria-expanded="false"
                    >
                        <i className="icon-context-points"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdownMenuButtons" right aria-labelledby="dropdownMenuButton3001b">
                        {(!isDpSharedToMe || idDpSharedToOther) && (
                            <ClickableElement
                                className="dropdown-item py-3"
                                onClick={onDropdownItemClick([
                                    () => {
                                        setIsShareModalOpen(true);
                                    },
                                    toggleMenuDropdown,
                                ])}
                            >
                                <Trans i18nKey="delivery-point.detail.actions.share-delivery-point.button-text">
                                    Zdieľať odberné miesto inej osobe
                                </Trans>
                            </ClickableElement>
                        )}

                        {!isUdpInactive && !isDpSharedToMe && (
                            <ClickableElement onClick={onNewRequest} className="dropdown-item py-3">
                                <Trans i18nKey="delivery-point.detail.actions.submit-a-request">Podať novú žiadosť</Trans>
                            </ClickableElement>
                        )}
                        {!isUdpInactive && toggleInactiveDeliveryPoints != null && (
                            <ClickableElement onClick={toggleInactiveDeliveryPoints} className="dropdown-item py-3">
                                {showInactiveDeliveryPoints ? (
                                    <Trans i18nKey="delivery-point.detail.header.hide-inactive-products" />
                                ) : (
                                    <Trans i18nKey="delivery-point.detail.header.show-inactive-products" />
                                )}
                            </ClickableElement>
                        )}
                        {!isUdpInactive && (
                            <Link to={Routes.MANAGE_DELIVERY_POINTS} className="dropdown-item py-3 text-danger">
                                <Trans i18nKey="delivery-point.detail.actions.hide-delivery-point">Skryť odberné miesto</Trans>
                            </Link>
                        )}
                    </DropdownMenu>
                </Dropdown>
            )}

            {isShareModalOpen && (
                <ShareDeliveryPointModal
                    isModalOpen={isShareModalOpen}
                    closeModal={() => setIsShareModalOpen(false)}
                    unitedDeliveryPoint={unitedDeliveryPoint}
                />
            )}

            <CustomerRequestAddNewModal isOpen={modalRequest} onCloseModal={() => setModalRequest(false)} unitedDeliveryPoint={unitedDeliveryPoint} />
        </>
    );
};

export default DeliveryPointDetailActionButtons;
