import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import UserYellow from '../../../../../assets/images/user-yellow.svg';
import useSso from '../../../../../hooks/sso/use-sso';
import { IRootState } from '../../../../../reducers';

const PrepareHasAccount: React.FC = () => {
    const { isUserLogged, customer } = useSelector((state: IRootState) => state.user);
    const { ssoLogin } = useSso();
    const fullName = (customer?.firstName || '') + ' ' + (customer?.lastName || '');

    return (
        <Col lg={6} className="mt-5">
            {isUserLogged ? (
                <Card className="d-block">
                    <CardBody>
                        <Row>
                            <Col className="text-center">
                                <img src={UserYellow} alt="" />
                                <p className="mt-3 text-truncate">
                                    <Trans i18nKey="customer-request.prepare.sign-in-as">Prihlásený ako</Trans> {fullName}
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            ) : (
                <Card className="register my-4 d-lg-block">
                    <CardBody>
                        <Row>
                            <div className="col-8">
                                <h4>
                                    <Trans i18nKey="customer-request.prepare.have-account">Máte už účet v Moje SPP?</Trans>
                                </h4>
                                <p>
                                    <Trans i18nKey="customer-request.prepare.already-have-account-text">
                                        Ak už máte účet v Moje SPP, prihláste sa a niektoré údaje vyplníme za Vás — ušetríte tak čas.
                                    </Trans>
                                </p>
                                <div role="button" className="btn btn-secondary" onClick={() => ssoLogin()}>
                                    <Trans i18nKey="customer-request.prepare.sign-in-to-spp">Prihlásiť sa do Moje SPP</Trans>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Col>
    );
};

export default PrepareHasAccount;
