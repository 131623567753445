export enum KeyValueStoreActionsEnum {
    SET_VALUE = 'key-value-store/SET_VALUE',
    CLEAR_ALL = 'key-value-store/CLEAR_ALL',
}

export interface ISetValueAction {
    type: KeyValueStoreActionsEnum.SET_VALUE;
    payload: { key: string; value: unknown };
}

export interface IClearAllAction {
    type: KeyValueStoreActionsEnum.CLEAR_ALL;
}

export type KeyValueStoreActionsType = ISetValueAction | IClearAllAction;

export const KeyValueStoreActions = {
    setValue: (key: string, value: unknown): ISetValueAction => ({
        type: KeyValueStoreActionsEnum.SET_VALUE,
        payload: { key: key, value: value },
    }),
    clearStore: (): IClearAllAction => ({
        type: KeyValueStoreActionsEnum.CLEAR_ALL,
    }),
};
