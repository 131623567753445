import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardHeader, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { LoadingIndicator } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import Checkbox from '../../../../../components/common/checkbox';
import { useApi } from '../../../../../hooks/use-api';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { CommodityEnum, isCustomerRequestPaid } from '../../../../../main';
import { IDeliveryPoint } from '../../../../../models/customer-request-model';
import { IApiResponse, PRICE_LINK_EE, PRICE_LINK_ZP } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import { DeliveryPointCollapseList } from './components/delivery-point-collapse-list';
import { DeliveryPointList } from './components/delivery-point-list';

type DeliveryPointFormTypeWithPricelistCheckbox = IDeliveryPoint & {
    deliveryPointId: string;
    priceListCheckbox: boolean;
};

const DP_MARGIN_THRESHOLD = 15;

const BlockDeliveryPointConfirmationContractualRelationshipShort: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { currentBlock, content, additionalData, partnersDeliveryPoints, metadata, customerRequestTemplate, paid } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { formRules } = useFormRules();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const initializeRequestCreationProcess = useSendRequest();
    const api = useApi();

    //for contract info
    const [selectedDP, setSelectedDP] = useState<{
        deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPointSummary;
        businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    }>();

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.DELIVERY_POINT;
    const objectData = content[objectName] as IDeliveryPoint;
    const newDp = currentBlock?.params?.newDp ?? false;

    const { register, handleSubmit, errors, watch, getValues } = useForm<DeliveryPointFormTypeWithPricelistCheckbox>({
        defaultValues: {
            ...{ ...objectData, ...(newDp && !objectData?.address ? { address: content.address } : {}) },
            deliveryPointId: metadata?.deliveryPointId ?? objectData?.dpId,
        },
    });

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId?: string }
    >(async ({ contractId }) => (contractId ? api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data) : null));

    const onSubmit = async (formData: DeliveryPointFormTypeWithPricelistCheckbox) => {
        const deliveryPointFullInfo = await mutateDeliveryPointByContractId({
            contractId: selectedDP?.deliveryPoint?.contract?.id,
        });

        if (deliveryPointFullInfo) {
            const data: any = {
                content: {
                    metadata: {
                        businessPartnerId: additionalData?.bpuuid || deliveryPointFullInfo.contract?.contractAccount?.businessPartner?.id,
                        deliveryPointId: formData.deliveryPointId && formData.deliveryPointId,
                    },
                    deliveryPoint: { id: formData.deliveryPointId },
                    entityReferences: [
                        {
                            contractId: selectedDP?.deliveryPoint?.contract?.id,
                            deliveryPointId: formData.deliveryPointId,
                            contractAccountId: deliveryPointFullInfo.contract?.contractAccount?.id,
                        },
                    ],
                    type: CustomerRequestTypeEnum.ZOM_POZV,
                    paymentApproval: formData.priceListCheckbox,
                },
                customerRequestTemplate: customerRequestTemplate,
            };
            setLoading(true);
            initializeRequestCreationProcess(data)
                .then(() => {
                    dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                    setIsRequestCreated(true);
                })
                .catch((err) => err.message)
                .finally(() => setLoading(false));
        }
    };

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            // return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index, _item]) => true));
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const deliveryPointsCount = useMemo(() => {
        let count = 0;

        Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).forEach(([bpId, deliveryPoints]) => {
            deliveryPoints.map((item) => item.deliveryPoints?.map(() => count++));
        });

        return count;
    }, [businessPartners]);

    const acceptCurrentPriceListLabel = selectedDP?.businessPartner.queue &&
        customerRequestTemplate &&
        isCustomerRequestPaid(customerRequestTemplate, selectedDP.businessPartner.queue) && (
            <>
                {t('customer-request.steps.accept-current-price-list') + ' '}
                {paid?.paid && paid?.comodity ? (
                    <a href={paid.comodity === CommodityEnum.ELECTRICITY ? PRICE_LINK_EE : PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                        {t('customer-request.steps.current-price-list')}&nbsp;
                    </a>
                ) : (
                    t('customer-request.steps.current-price-list')
                )}
            </>
        );

    const isSubmitAllowed = useMemo(
        () =>
            !selectedDP?.businessPartner.queue ||
            !customerRequestTemplate ||
            !isCustomerRequestPaid(customerRequestTemplate, selectedDP.businessPartner.queue) ||
            (getValues('priceListCheckbox') && getValues('deliveryPointId')),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watch && watch('priceListCheckbox'), watch && watch('deliveryPointId'), getValues, selectedDP, customerRequestTemplate],
    );

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    useEffect(() => {
        if (
            !customerRequestTemplate ||
            !selectedDP ||
            !selectedDP.deliveryPoint ||
            !selectedDP.businessPartner.queue ||
            !isCustomerRequestPaid(customerRequestTemplate, selectedDP.businessPartner.queue)
        ) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: false }));
            return;
        }
        if (selectedDP.deliveryPoint.type === CommodityEnum.ELECTRICITY) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.ELECTRICITY }));
        } else if (selectedDP.deliveryPoint.type === CommodityEnum.GAS) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.GAS }));
        }
    }, [selectedDP, selectedDP?.deliveryPoint, dispatch, customerRequestTemplate]);

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
                    const businessPartner = deliveryPoints[0].businessPartner;
                    return businessPartner == null ? null : (
                        <>
                            {deliveryPointsCount > DP_MARGIN_THRESHOLD && Object.entries(businessPartners ?? []).length > 1 ? (
                                <DeliveryPointCollapseList
                                    register={register}
                                    businessPartner={businessPartner}
                                    deliveryPoints={deliveryPoints}
                                    setSelectedDP={(dp) => setSelectedDP({ deliveryPoint: dp, businessPartner: businessPartner })}
                                />
                            ) : (
                                <DeliveryPointList
                                    register={register}
                                    businessPartner={businessPartner}
                                    deliveryPoints={deliveryPoints}
                                    setSelectedDP={(dp) => setSelectedDP({ deliveryPoint: dp, businessPartner: businessPartner })}
                                />
                            )}
                        </>
                    );
                })}

                {acceptCurrentPriceListLabel && (
                    <CardHeader className="border-bottom-0 rounded w-100" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            className="mb-0"
                            ref={register(formRules.required)}
                            errors={errors}
                            id="priceListCheckbox"
                            name="priceListCheckbox"
                            label={acceptCurrentPriceListLabel}
                        />
                    </CardHeader>
                )}

                <BaseButton type="submit" disabled={!isSubmitAllowed}>
                    <Trans i18nKey="customer-request.steps.send-request">Odoslať žiadosť</Trans>
                </BaseButton>
            </form>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default BlockDeliveryPointConfirmationContractualRelationshipShort;
