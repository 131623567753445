import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: string;
    bold?: boolean;
    code: CustomerRequestTypeEnum;
};

const DateItem: React.FC<Props> = ({ data, bold, code }) => {
    const [t] = useTranslation();
    const title =
        code === CustomerRequestTypeEnum.ZOM_ZOPAOO
            ? t('customer-request.steps.interaption-date.required-date')
            : code === CustomerRequestTypeEnum.ZOM_POVZ
            ? t('customer-request.steps.date-selection.required-date')
            : t('customer-request.steps.gauge-condition.date');
    return (
        <>
            {data && (
                <ItemPreview title={title}>
                    <ItemPreviewContent bold={bold}>{formatDateToPreview(data)}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default DateItem;
