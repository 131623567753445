import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Modal, ModalBody } from 'reactstrap';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IApiResponse, IRootState, LoadingIndicator, useApi, useReloadQueryCache } from '../../../../main';

interface IBulkRemoveSharingDeliveryPointsModalProps {
    isOpen: boolean;
    close: () => void;
    unitedDeliveryPointIds: string[];
}

export const BulkRemoveSharingDeliveryPointsModal: React.FC<IBulkRemoveSharingDeliveryPointsModalProps> = ({
    isOpen,
    close,
    unitedDeliveryPointIds,
}) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const { addToast } = useToasts();
    const { t } = useTranslation();

    const reloadQueryCache = useReloadQueryCache();

    const api = useApi();

    const [mutateRemoveSharing, { isLoading: isLoadingRemoveSharing }] = useMutationWithError<unknown, IApiResponse, { udpIds: string[] }>(
        async ({ udpIds }) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers.removeSharingDeliveryPoints(
                      loggedInCustomer.id,
                      { sharingUnitedDeliveryPoints: udpIds.map((x) => ({ unitedDeliveryPointUuid: x })) },
                      { secure: true },
                  ),
        {
            onSuccess: (data, variables) => {
                addToast(t('delivery-point.share-delivery-points.sharing-has-been-removed-message'), {
                    appearance: 'success',
                });
                reloadQueryCache('delivery-points-search');
                close();
            },
            onErrorWithGlobalErrorHandling: (responseError, variables) => {
                // addToast('TODO: (t) ERROR', {
                //     appearance: 'error',
                // });
                // return true;
                return false;
            },
        },
    );

    const onRemoveSharingButtonClicked = () => {
        mutateRemoveSharing({ udpIds: unitedDeliveryPointIds });
    };

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <ModalBody>
                    <button type="button" onClick={close} className="close ml-auto d-block" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <div className="my-5 text-center">
                        <h4 className="mb-3">
                            <Trans
                                i18nKey="delivery-point.share-delivery-points.remove-sharing-warning"
                                values={{ count: unitedDeliveryPointIds.length }}
                            />
                        </h4>
                        <h4>
                            <Trans i18nKey="delivery-point.share-delivery-points.remove-sharing-warning-question" />
                        </h4>
                    </div>
                    <div className="d-flex my-4">
                        <Button type="button" color="primary" block size="lg" onClick={onRemoveSharingButtonClicked} className="mx-auto">
                            <Trans i18nKey="delivery-point.share-delivery-points.confirm-remove-sharing" />
                        </Button>
                    </div>
                    <div className="d-flex">
                        <Button type="button" color="secondary" block size="lg" onClick={close} className="mx-auto">
                            <Trans i18nKey="delivery-point.share-delivery-points.reject-remove-sharing" />
                        </Button>
                    </div>

                    {isLoadingRemoveSharing && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
