import React from 'react';
import { Trans } from 'react-i18next';
import { ClickableElement } from '../../common/clickable-element';

type UserLinksProps = {
    userDetails: Record<string, any>;
    linkClicked: () => void;
    logout: () => void;
};

const UserLinks: React.FC<UserLinksProps> = ({ linkClicked, logout, userDetails }) => {
    const fullName = userDetails ? userDetails.firstName + ` ${userDetails.lastName || ''}` : '';

    const handleLogout = () => {
        linkClicked();
        logout();
    };
    return (
        <>
            <ul className="navbar-nav">
                <li className="nav-item menu-item">
                    <div
                        className="nav-link mt-1 mb-2 my-lg-0 py-3 py-lg-0 d-flex flex-row d-lg-none cursor-default"
                        style={{ fontWeight: 'normal' }}
                    >
                        <i className="icon-user fa-lg mr-3 d-lg-none"></i>
                        <div className="mt-n2 text-truncate">
                            <span className="d-block text-secondary d-lg-none small">
                                <Trans i18nKey="navbar.logged-as">Prihlásený ako</Trans>
                            </span>
                            {fullName}
                        </div>
                    </div>
                </li>
                <li className="nav-item d-lg-none mb-3">
                    <ClickableElement className="py-3 nav-link menu-item" onClick={handleLogout}>
                        <i className="icon-logout fa-lg mr-3 d-lg-none"></i>
                        <Trans i18nKey="action.logout">Odhlásiť sa</Trans>
                    </ClickableElement>
                </li>
            </ul>
        </>
    );
};

export default UserLinks;
