import React from 'react';
import { ListGroupItem } from 'reactstrap';

type Props = {
    title?: string | JSX.Element;
    bold?: boolean;
};

type ListItemProps = {
    bold?: boolean;
    classNames?: string;
};

type ListItemHeaderProps = {
    title: string | JSX.Element;
};

export const ItemPreviewHeader: React.FC<ListItemHeaderProps> = ({ title }) => {
    return (
        <>
            <small className="text-secondary">{title}</small>
            <br />
        </>
    );
};

export const ItemPreviewContent: React.FC<ListItemProps> = ({ bold, children, classNames }) => {
    return <div className={`${bold ? 'font-weight-500' : ''} mb-0 overflow-auto customer-request-itempreview ${classNames ?? ''}`}>{children}</div>;
};

const ItemPreview: React.FC<Props> = ({ title, children }) => {
    return (
        <>
            <ListGroupItem>
                <div className="text-decoration-none">
                    {title && <ItemPreviewHeader title={title} />}
                    {children}
                </div>
            </ListGroupItem>
        </>
    );
};

export default ItemPreview;
