import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerconsumptionConsumptionInfoResponse, SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ClickableElement, LoadingIndicator, TableFilterControl } from '../../../../components/common';
import BaseSwitch from '../../../../components/common/base-switch';
import CustomButtonDropdown from '../../../../components/common/custom-button-dropdown/custom-button-dropdown';
import ExportModal, { IExportQueryOptions, triggerDownloadFile } from '../../../../components/common/export/export-modal';
import { useApi } from '../../../../hooks/use-api';
import useCodeList from '../../../../hooks/use-code-list';
import { useKeyValueStore } from '../../../../hooks/use-key-value';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { CommodityEnum, ConsumptionValueUnits, DateScaleConsumption } from '../../../../models/enums';
import Routes from '../../../../routes/routes';
import { buildDeliveryPointQueryKey, QueryKeysEnum } from '../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../utils/utils';
import { CodeListTypeEnum } from '../../../customer-request/config/enums';
import useMutationWithError from './../../../../hooks/use-mutation-with-error';
import ConsumptionFilterModal from './components/consumption-filter-modal';
import ConsumptionGraph from './components/consumption-graph';
import ConsumptionMeteringGraph from './components/consumption-metering-graph';
import ConsumptionReadModal from './components/consumption-read-modal';
import ConsumptionTable from './components/consumption-table';
import { consumptionExportConfigurations } from './consumption-export-configuration';
import { ConsumptionFilterDescription } from './consumption-filter-description';
import { ConsumptionHeader } from './consumption-header';
import useFetchConsumption from './use-fetch-consumption';

interface IProps {
    deliveryPointId: SkSppNzpBeApiCustomerprofileDeliveryPoint['id'];
    deliveryPointType: SkSppNzpBeApiCustomerprofileDeliveryPoint['type'];
    contractId?: string;
    address: string;
    notes?: boolean;
    showIntervalGraph?: boolean;
    getDeliveryPointSwitch: () => React.ReactNode;
}

export type CommodityProps = {
    id?: SkSppNzpBeApiCustomerprofileDeliveryPoint['id'];
    type?: SkSppNzpBeApiCustomerprofileDeliveryPoint['type'];
};

export enum MeteringTypeEnum {
    ALL_METERING = 'ALL_METERING',
    DISTRIBUTION_METERING = 'DISTRIBUTION_METERING',
    MY_DIARY = 'MY_DIARY',
}
export interface IConsumptionFilter {
    consumptionTimestampFrom: string;
    consumptionTimestampTo: string;
}

export interface IConsumptionAdditionalFilter {
    /* TODO deliveryPoint/unitedDeliveryPoint carries info about how often metering is,
     so filter should only have options that is available for this deliveryPoint/unitedDeliveryPoint */
    consumptionUnits: ConsumptionValueUnits;
}

const getDefaultAdditionalFilter = (type: SkSppNzpBeApiCustomerprofileDeliveryPoint['type']): { consumptionUnits: ConsumptionValueUnits } => {
    return {
        consumptionUnits: type === 'EE' ? ConsumptionValueUnits.KWH : ConsumptionValueUnits.CUBIC_METRE,
    };
};
export interface IConsumptionAFilter {
    consumptionScale: DateScaleConsumption;
    meteringType: MeteringTypeEnum;
}

/* < UF-6 WF-3002 WF-9002 */
const Consumption: React.FC<IProps> = ({
    deliveryPointId,
    deliveryPointType,
    contractId,
    address,
    notes,
    showIntervalGraph,
    getDeliveryPointSwitch,
}) => {
    const [t] = useTranslation();
    const history = useHistory();
    const urlParams = useParams<{ unitedDeliveryPointId: string; deliveryPointId: string }>();

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useKeyValueStore<Partial<IConsumptionFilter>>('consumption-filter', {});

    const [additionalFilter, setAdditionalFilter] = useKeyValueStore<IConsumptionAdditionalFilter>(
        `consumption-additional-filter-${deliveryPointId}`,
        getDefaultAdditionalFilter(deliveryPointType),
    );

    const [scaleAndTypeFilter, setScaleAndTypeFilter] = useState<IConsumptionAFilter>({
        consumptionScale: DateScaleConsumption.MONTH,
        meteringType:
            history.location.hash === '#mynotes' || history.location.hash === '#newreading'
                ? MeteringTypeEnum.MY_DIARY
                : MeteringTypeEnum.ALL_METERING,
    });
    const windowSize = useWindowSize();
    const [onlyMyReading, setOnlyMyReading] = useState<boolean>(history.location.hash === '#mynotes' || history.location.hash === '#newreading');

    const [isExportModalOpen, setExportModalOpen] = useState(false);
    const [isSelfReadModalOpen, setIsSelfReadModalOpen] = useState(history.location.hash === '#newreading');

    const sectionDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (history.location.hash === '#mynotes' || history.location.hash === '#newreading') {
            setScaleAndTypeFilter((prev) => ({ ...prev, meteringType: MeteringTypeEnum.MY_DIARY }));
            setOnlyMyReading(true);
            sectionDiv.current?.scrollIntoView();
            if (history.location.hash === '#newreading') {
                setIsSelfReadModalOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location, sectionDiv.current]);

    const api = useApi();

    const { data: dataDeliveryPoint } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        buildDeliveryPointQueryKey(contractId),
        async () => (contractId ? api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data) : null),
        {
            onSuccess: (data) => {
                setAdditionalFilter(getDefaultAdditionalFilter(data?.type));
            },
        },
    );

    const { data: columnsForExport } = useCodeList({
        queryKey: QueryKeysEnum.METER_READING_EXPORT_COLUMN,
        codeListTypeEnum: CodeListTypeEnum.METER_READING_EXPORT_COLUMN,
        paging: {
            size: 100,
        },
    });

    const isSmallScreen = useCallback(
        function(): boolean {
            return !!windowSize.width && windowSize.width < 768;
        },
        [windowSize.width],
    );

    const requestExport = async (exportQuery: IExportQueryOptions) => {
        setExportModalOpen(false);
        if (contractId == null) return;
        const categories: ('SAP' | 'INFO')[] = [];
        if ([MeteringTypeEnum.DISTRIBUTION_METERING, MeteringTypeEnum.ALL_METERING].includes(scaleAndTypeFilter.meteringType)) categories.push('SAP');
        if ([MeteringTypeEnum.MY_DIARY, MeteringTypeEnum.ALL_METERING].includes(scaleAndTypeFilter.meteringType)) categories.push('INFO');

        if (isSmallScreen()) {
            categories.length = 0;
            if (onlyMyReading) {
                categories.push('INFO');
            } else {
                categories.push('INFO');
                categories.push('SAP');
            }
        }

        const unit = exportQuery.unit;
        delete exportQuery.unit;

        return api.deliveryPoints
            .meterReadingSearchExport(
                contractId,
                exportQuery,
                {
                    categories,
                    readingAt: {
                        from: filter.consumptionTimestampFrom,
                        to: filter.consumptionTimestampTo,
                    },
                    units: deliveryPointType === 'EE' ? 'KWH' : unit,
                },
                { secure: true },
            )
            .then((response: Response) => response.blob())
            .then(triggerDownloadFile);
    };

    const [mutateExport, { reset: resetExportState }] = useMutationWithError(requestExport, {
        onSuccess: () => {
            resetExportState();
        },
        onErrorWithGlobalErrorHandling: () => false,
    });

    const additionalFilterEqualsDefaultFilter = () => {
        if (additionalFilter.consumptionUnits !== getDefaultAdditionalFilter(deliveryPointType).consumptionUnits) {
            return false;
        }
        return true;
    };

    const onRemoveFilter = () => {
        setFilter({});
        setAdditionalFilter(getDefaultAdditionalFilter(deliveryPointType));
        setShowFilterModal(false);
    };

    const isObjectEmptyOrUndefined = (filter: Record<string, string | undefined>): boolean => {
        return isObjectEmpty(filter) || Object.values(filter).every((value) => value === undefined || value.length === 0);
    };

    const {
        isLoading: isLoadingConsumptionInfo,
        data: dataConsumptionInfo,
    } = useQueryWithError<SkSppNzpBeApiCustomerconsumptionConsumptionInfoResponse | null>(['consumption-info', contractId], async () =>
        contractId ? api.deliveryPoints.consumptionInfo(contractId, { secure: true }).then((response) => response.data) : null,
    );

    const { data: dataBarGraphOverall, isLoading: isLoadingOverall } = useFetchConsumption({
        callMutation: !isLoadingConsumptionInfo && !!dataConsumptionInfo?.deliveryPointFact,
        contractId: contractId,
        timestampFrom: undefined,
        timestampTo: undefined,
        units: deliveryPointType === 'EE' ? ConsumptionValueUnits.KWH : additionalFilter.consumptionUnits || ConsumptionValueUnits.KWH,
    });

    return (
        <>
            {isLoadingOverall && <LoadingIndicator layer="parent" fullscreen />}

            <ConsumptionHeader getDeliveryPointSwitch={getDeliveryPointSwitch} setIsSelfReadModalOpen={setIsSelfReadModalOpen} notes={notes} />

            <div className={`container mb-5 ${notes ? 'odpocty' : ''}`}>
                <div className="section-spotreba">
                    {deliveryPointId && (
                        <>
                            <Row className="mb-4">
                                <Col>
                                    <ConsumptionGraph
                                        contractId={contractId}
                                        timestampFrom={filter.consumptionTimestampFrom}
                                        timestampTo={filter.consumptionTimestampTo}
                                        units={
                                            deliveryPointType === 'EE'
                                                ? ConsumptionValueUnits.KWH
                                                : additionalFilter.consumptionUnits || ConsumptionValueUnits.KWH
                                        }
                                        dataBarGraphOverall={dataBarGraphOverall}
                                        isLoadingOverall={isLoadingOverall}
                                        isLoadingConsumptionInfo={isLoadingConsumptionInfo}
                                        deliveryPointFact={!!dataConsumptionInfo?.deliveryPointFact}
                                    />
                                </Col>
                            </Row>
                            {showIntervalGraph && dataConsumptionInfo?.intervalMeterReading?.from && dataConsumptionInfo?.intervalMeterReading?.to && (
                                <>
                                    <Row className="mb-5">
                                        <Col>
                                            <ConsumptionMeteringGraph
                                                contractId={contractId}
                                                commodityType={deliveryPointType}
                                                timestampFrom={filter?.consumptionTimestampFrom}
                                                timestampTo={filter?.consumptionTimestampTo}
                                                units={
                                                    deliveryPointType === 'EE'
                                                        ? ConsumptionValueUnits.KW
                                                        : (additionalFilter.consumptionUnits === ConsumptionValueUnits.KWH
                                                              ? ConsumptionValueUnits.KW
                                                              : additionalFilter.consumptionUnits) || ConsumptionValueUnits.KW
                                                }
                                                scale={scaleAndTypeFilter?.consumptionScale}
                                                changeScale={(value) => {
                                                    setScaleAndTypeFilter((prev) => ({
                                                        ...prev,
                                                        consumptionScale: value,
                                                    }));
                                                }}
                                                minimumTimestamp={new Date(dataConsumptionInfo.intervalMeterReading.from)}
                                                maximumTimestamp={new Date(dataConsumptionInfo.intervalMeterReading.to)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )}
                    <ClickableElement
                        onClick={() =>
                            history.push(`${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}${Routes.NOTES2}/${urlParams.deliveryPointId}`)
                        }
                        className="card text-decoration-none d-md-none"
                    >
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col font-weight-bold">
                                    <i className="icon-clipboard fa-lg"></i>
                                    <Trans i18nKey="delivery-point.detail.navigation.notes" />
                                </div>
                                <div className="col-auto">
                                    <i className="icon-chevron-right-16"></i>
                                </div>
                            </div>
                        </div>
                    </ClickableElement>
                </div>

                <div className="row">
                    <ConsumptionFilterModal
                        isOpen={showFilterModal}
                        onCloseModal={() => setShowFilterModal(false)}
                        filter={filter}
                        additionalFilter={additionalFilter}
                        selectedCommodity={deliveryPointType}
                        onFilterChange={(newFilter) => {
                            setFilter(newFilter);
                        }}
                        onAdditionalFilterChange={(value) => {
                            setAdditionalFilter((prev) => ({ ...prev, ...value }));
                        }}
                        onRemoveFilter={onRemoveFilter}
                    />
                    <Col
                        xs={{
                            size: isObjectEmptyOrUndefined(filter) && additionalFilterEqualsDefaultFilter() ? 6 : 12,
                            offset: isObjectEmptyOrUndefined(filter) && additionalFilterEqualsDefaultFilter() ? 6 : 0,
                        }}
                        lg={{
                            size: 12,
                            offset: 0,
                        }}
                    >
                        <TableFilterControl
                            isFilterActive={!isObjectEmptyOrUndefined(filter) || !additionalFilterEqualsDefaultFilter()}
                            onEditFilter={() => setShowFilterModal(true)}
                            onRemoveFilter={onRemoveFilter}
                            filterDescription={<ConsumptionFilterDescription filter={filter} additionalFilter={additionalFilter} />}
                        />
                    </Col>
                    <div className="w-100 d-none d-md-block"></div>
                </div>

                <div className="section-odpocty pt-4" ref={sectionDiv}>
                    <Row className="align-items-center mb-4">
                        <Col xs={0} sm={12} lg={7} className="d-none d-md-block">
                            <BaseSwitch
                                selectedValue={scaleAndTypeFilter.meteringType}
                                dataButtons={[
                                    { id: MeteringTypeEnum.ALL_METERING, title: t('delivery-point.detail.consumption.metering-type.all') },
                                    {
                                        id: MeteringTypeEnum.DISTRIBUTION_METERING,
                                        title: t('delivery-point.detail.consumption.metering-type.distribution'),
                                    },
                                    { id: MeteringTypeEnum.MY_DIARY, title: t('delivery-point.detail.consumption.metering-type.my-diary') },
                                ]}
                                onValueSelect={(value) => {
                                    setScaleAndTypeFilter((prev) => ({ ...prev, meteringType: value as MeteringTypeEnum }));
                                }}
                                className="mb-3 mb-lg-0"
                            />
                        </Col>

                        <Col xs={6} md={9} lg={3} className="text-right d-md-block d-none">
                            <button type="button" className="btn btn-primary" onClick={() => setIsSelfReadModalOpen(true)}>
                                <small>
                                    <FontAwesomeIcon icon={faPlus} />
                                </small>{' '}
                                <Trans i18nKey="delivery-point.detail.consumption.new-read" />
                            </button>
                        </Col>

                        <Col xs={6} md={3} lg={2}>
                            <CustomButtonDropdown
                                title={<Trans i18nKey="portal-account.activity-log.actions.actions" />}
                                buttons={[
                                    {
                                        id: 'export',
                                        onClick: () => setExportModalOpen(true),
                                        label: <Trans i18nKey="portal-account.activity-log.actions.export" />,
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={6} className="text-right d-md-none d-block">
                            <i
                                className={classNames('icon-book-open', 'consumption-only-reading', {
                                    'consumption-only-reading-selected': onlyMyReading,
                                })}
                                onClick={() => setOnlyMyReading(!onlyMyReading)}
                            ></i>
                        </Col>
                    </Row>
                    {deliveryPointId && (
                        <ConsumptionTable
                            deliveryPointUuid={deliveryPointId}
                            timestampFrom={filter?.consumptionTimestampFrom}
                            timestampTo={filter?.consumptionTimestampTo}
                            meteringType={
                                isSmallScreen()
                                    ? onlyMyReading
                                        ? MeteringTypeEnum.MY_DIARY
                                        : MeteringTypeEnum.ALL_METERING
                                    : scaleAndTypeFilter?.meteringType
                            }
                            units={additionalFilter?.consumptionUnits || ConsumptionValueUnits.KWH}
                            selectedCommodity={{ id: dataDeliveryPoint?.id, type: dataDeliveryPoint?.type }}
                            contractId={contractId}
                        />
                    )}
                    {/* Some extra space */}
                    <div className="py-5" />
                </div>

                {dataDeliveryPoint && dataDeliveryPoint.contract?.id && (
                    <ConsumptionReadModal
                        defaultSelectedCommodity={{ id: dataDeliveryPoint?.id, type: dataDeliveryPoint?.type }}
                        contractId={dataDeliveryPoint.contract?.id}
                        modalLabel={t('delivery-point.detail.consumption.my-diary-read')}
                        isOpen={isSelfReadModalOpen}
                        dual={dataDeliveryPoint?.type === CommodityEnum.ELECTRICITY && dataDeliveryPoint?.contract?.eeTariffCount === 2}
                        onClose={() => {
                            setIsSelfReadModalOpen(false);
                            setScaleAndTypeFilter({ ...scaleAndTypeFilter, meteringType: MeteringTypeEnum.MY_DIARY });
                        }}
                        title={address}
                        myRead
                        lastLowValue={dataDeliveryPoint?.readingCycleInfo?.lastLowValue}
                        lastHighValue={dataDeliveryPoint?.readingCycleInfo?.lastHighValue}
                    />
                )}

                {columnsForExport && (
                    <ExportModal
                        modalLabel={t('export.modal-header-consumption')}
                        isOpen={isExportModalOpen}
                        onClose={() => setExportModalOpen(false)}
                        exportConfiguration={consumptionExportConfigurations(columnsForExport)}
                        onExport={mutateExport}
                        selectedCommodity={deliveryPointType}
                        displayUnitsKWH_M3
                    />
                )}
            </div>
        </>
    );
};

export default Consumption;
