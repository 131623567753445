import React from 'react';
import { Trans } from 'react-i18next';

const PageHeader: React.FC<{ jwt?: string }> = ({ jwt }) => {
    return (
        <>
            <h2 className="mb-3">
                {jwt ? (
                    <Trans i18nKey="common.completion-registration">Dokončenie registrácie</Trans>
                ) : (
                    <Trans i18nKey="common.registration">Registrácia</Trans>
                )}
            </h2>
            <p className="d-block mb-4">
                <Trans i18nKey="registration.fill-out-form-and-sign-up">Vyplňte nasledujúce údaje a zaregistrujte si účet</Trans>
            </p>
        </>
    );
};

export default PageHeader;
