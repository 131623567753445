import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import useCodeList from '../../../../../hooks/use-code-list';
import { IRootState } from '../../../../../reducers';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import { claimInvoiceBlocks, claimInvoiceWithDPBlocks } from '../../../config/request-config';

export enum ReasonEnum {
    WRONG_METER_READING = 'WRONG_METER_READING',
    INCORRECT_ADVANCE_PAYMENTS = 'INCORRECT_ADVANCE_PAYMENTS',
    WRONG_TARIFF = 'WRONG_TARIFF',
    WRONG_CUSTOMER_DATA = 'WRONG_CUSTOMER_DATA',
    OTHER = 'OTHER',
}

const BlockProblemDescriptionShort: React.FC = () => {
    const dispatch = useDispatch();
    const { deliveryPoint, step, content } = useSelector((state: IRootState) => state.customerRequest);
    const [reason, setReason] = useState<ReasonEnum>(
        (content.problemDescription as ReasonEnum) ||
            (content.invoice?.typeGroup === 'ADVANCE_INVOICE'
                ? ReasonEnum.INCORRECT_ADVANCE_PAYMENTS
                : content.invoice?.typeGroup === 'INVOICE' || content.invoice?.typeGroup === 'CREDIT'
                ? ReasonEnum.WRONG_METER_READING
                : ReasonEnum.WRONG_CUSTOMER_DATA),
    );

    useEffect(() => {
        if (step.lastOpened > step.current && (content.invoice?.deliveryPoints?.length || 0) > 1) {
            dispatch(CustomerRequestActions.setDeliveryPoint(undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nextStep = () => {
        const codeListProblem = complainedProblemCodeList?.find((item) => item.code === reason);
        const payload = {
            [CustomerRequestDataEnum.PROBLEM_DESCRIPTION]: reason,
            complainedInvoiceProblemDescription: codeListProblem ? { problemDescription: codeListProblem } : undefined,
        };
        if (!deliveryPoint && content.invoice?.deliveryPoints && content.invoice?.deliveryPoints?.length > 0) {
            dispatch(CustomerRequestActions.replaceBlocks(claimInvoiceWithDPBlocks));
        } else {
            dispatch(CustomerRequestActions.replaceBlocks(claimInvoiceBlocks));
        }
        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const { data: complainedProblemCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COMPLAINED_INVOICE_PROBLEM,
        codeListTypeEnum: CodeListTypeEnum.COMPLAINED_INVOICE_PROBLEM,
        paging: {
            size: 30,
        },
    });

    return (
        <>
            <div className="text-center my-2 my-lg-5">
                <h3>{<Trans i18nKey="customer-request.steps.reason-for-claim.title-1">Vyberte dôvod reklamácie:</Trans>}</h3>
            </div>
            {complainedProblemCodeList?.map((item, index) => (
                <>
                    {index === 0 && content.invoice?.typeGroup === 'ADVANCE_INVOICE' && (
                        <Card className="mb-3">
                            <CardBody>
                                <BaseRadio
                                    className="mt-1"
                                    id="radio-reason-incorect-advance-payments"
                                    onChange={() => setReason(ReasonEnum.INCORRECT_ADVANCE_PAYMENTS)}
                                    checked={reason === ReasonEnum.INCORRECT_ADVANCE_PAYMENTS}
                                    label={
                                        <b>
                                            <Trans i18nKey="enums.ResonForClaimInvoice.INCORRECT_ADVANCE_PAYMENTS">
                                                Nesprávne zaúčtované preddavkové platby
                                            </Trans>
                                        </b>
                                    }
                                />
                            </CardBody>
                        </Card>
                    )}
                    {(item.code === ReasonEnum.WRONG_TARIFF || item.code === ReasonEnum.WRONG_METER_READING) &&
                    content.invoice?.typeGroup !== 'INVOICE' &&
                    content.invoice?.typeGroup !== 'CREDIT' ? null : (
                        <Card className="mb-3" key={index}>
                            <CardBody>
                                <BaseRadio
                                    className="mt-1"
                                    id={item.code}
                                    onChange={() => setReason(ReasonEnum[item.code as keyof typeof ReasonEnum])}
                                    checked={reason === ReasonEnum[item.code as keyof typeof ReasonEnum]}
                                    label={<b>{item.name}</b>}
                                />
                            </CardBody>
                        </Card>
                    )}
                </>
            ))}

            <BaseButton type="button" onClick={nextStep}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockProblemDescriptionShort;
