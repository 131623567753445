import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { PersonData } from '../../../actions/add-delivery-point-actions';
import { CharacterFilter } from '../../../components/common/character-filtering';
import Input from '../../../components/common/input';
import LoadingIndicator from '../../../components/common/loading-indicator';
import HelpLinkWithModal from '../../../components/help/help-link-with-modal';
import { useFormRules } from '../../../hooks/use-form-rules';
import DeliveryPointBreadcrumb from './add-delivery-point-breadcrumb';

type EnterPersonDataScreenProps = {
    enterData: (personData: PersonData) => void;
    goBack: () => void;
    isLoading: boolean;
    additionalPhone?: string;
};

type FormValuesType = {
    customerNumber: string;
    contractNumber: string;
    additionalPhone: string;
};

const EnterPersonDataScreen: React.FC<EnterPersonDataScreenProps> = (props) => {
    const { register, handleSubmit, errors, trigger } = useForm({ defaultValues: { additionalPhone: props.additionalPhone || '+421' } });
    const { requiredTrimed, formRules, firstCharMustBe } = useFormRules();
    const { t } = useTranslation();

    const onSubmit = (formData: FormValuesType) => {
        props.enterData(formData);
    };

    return (
        <>
            <DeliveryPointBreadcrumb goBack={props.goBack} />
            {props.isLoading && <LoadingIndicator />}
            <h4 className="text-center">
                <Trans i18nKey="delivery-point.add-delivery-point.enter-person.title">Zadajte vaše údaje</Trans>:
            </h4>

            <form className="my-3" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                    trigger={trigger}
                    ref={register({
                        ...formRules.requiredInteger,
                        validate: { ...requiredTrimed.validate, ...firstCharMustBe('5', true).validate, ...formRules.length(10).validate },
                    })}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name="customerNumber"
                    label={t('delivery-point.add-delivery-point.enter-person.customer-number-placeholder')}
                />

                <p className="mb-4">
                    <Trans i18nKey="delivery-point.add-delivery-point.enter-person.customer-number-msg">
                        Zákaznícke číslo nájdete na zmluve a na každej faktúre od SPP.
                    </Trans>
                </p>

                <p>
                    <span className="ml-1">
                        <Trans i18nKey="delivery-point.add-delivery-point.enter-person.contract-number-msg2">
                            Zadajte číslo zmluvy na dodávku elektriny alebo plynu
                        </Trans>
                    </span>
                    <Input
                        trigger={trigger}
                        ref={register({
                            ...formRules.requiredInteger,
                            validate: { ...requiredTrimed.validate, ...firstCharMustBe('9').validate, ...formRules.length(10).validate },
                        })}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        type="number"
                        name="contractNumber"
                        label={t('delivery-point.add-delivery-point.enter-person.contract-number-placeholder')}
                    />
                </p>

                <p>
                    <Trans i18nKey="delivery-point.add-delivery-point.enter-person.contract-number-msg"></Trans>
                </p>

                <HelpLinkWithModal
                    className="text-center d-block mb-5"
                    classNameClickableElement="text-decoration-underline-inverse"
                    linkTitle="delivery-point.add-delivery-point.where-can-be-data-found"
                    title={t('help.screen-field-title.CONTRACT_NUMBER_PLACEMENT_ON_CONTRACT')}
                    field={'ODM_CONTRACT_NUMBER_PLACEMENT_ON_CONTRACT'}
                />

                <button type="submit" className="btn btn-primary btn-block btn-lg mt-3">
                    <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                </button>
            </form>
        </>
    );
};

export default EnterPersonDataScreen;
