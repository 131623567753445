import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useEffect } from 'react';
import BaseRadio from '../../../../../../components/common/base-radio';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';

type Props = DefaultFormType & {
    codeList?: CodeListItem[];
    setDisplayNewOwnerCard(value: boolean): void;
};

// const reasonList = [
//     'change-supplier',
//     'switch-fuel',
//     'customer-death',
//     'company-desolution',
//     'sale-property',
//     'demolition-work',
//     'termination-lease',
//     'without-reason',
// ];

const ContractTerminationReasonFormFieldsShort: React.FC<Props> = ({ register, watch, codeList, setDisplayNewOwnerCard }) => {
    const reason = watch('reason');
    const { formRules } = useFormRules();
    useEffect(() => {
        const selectedReason = codeList?.find((item) => item.uuid === reason?.uuid);
        if (selectedReason && selectedReason.code === 'REAL_ESTATE_SALE') {
            setDisplayNewOwnerCard(true);
        } else setDisplayNewOwnerCard(false);
    }, [reason, codeList, setDisplayNewOwnerCard]);

    return (
        <>
            {codeList?.map((item) => (
                <BaseRadio
                    ref={register({ ...formRules.required })}
                    key={item.uuid}
                    id={item.uuid}
                    value={item.uuid}
                    name="reason.uuid"
                    label={item.name}
                />
            ))}
        </>
    );
};

export default ContractTerminationReasonFormFieldsShort;
