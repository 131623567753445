import React, { useEffect, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseSelect from '../../../../../../components/common/base-select';
import Checkbox from '../../../../../../components/common/checkbox';
import Input from '../../../../../../components/common/input';
import useCodeList from '../../../../../../hooks/use-code-list';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { SK_CODE } from '../../../../../../models/enums';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { combineRefs } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import AddressDefaultFields from '../../components/address-default-fields';

type Props = DefaultFormType & {
    disabled?: boolean;
    disabledCountrySelect?: boolean;
};

const AddressFields: React.FC<Props> = ({ register, errors, watch, setValue, disabled = false, trigger, disabledCountrySelect = false }) => {
    const correspondenceFields = watch('correspondenceFields') ?? false;
    const localRef = useRef<HTMLInputElement>(null);
    const { requiredTrimed, formRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const postalCode: string = watch && watch('correspondenceAddress.postalCode');
    const country: string = watch && watch(`correspondenceAddress.country.uuid`);

    const postalCodeSk = useMemo(() => {
        const skCodeItem = countryCodeList?.find((item) => item.uuid === country);
        return skCodeItem?.code === SK_CODE;
    }, [countryCodeList, country]);

    /**validate postal code on (xxx xx) format  */
    useEffect(() => {
        if (!postalCodeSk) return;
        const length = localRef.current?.value.length || 0;
        let position = localRef.current?.selectionEnd || length || 0;
        const preview = localRef.current?.value
            .replace(/[^\da-zA-Z]/g, '')
            .replace(/(.{3})/g, '$1 ')
            .trim();
        if (localRef.current) {
            localRef.current.value = preview || '';
            localRef.current.selectionEnd = position +=
                localRef.current.value.charAt(position - 1) === ' ' &&
                localRef.current.value.charAt(length - 1) === ' ' &&
                length !== localRef.current.value.length
                    ? 1
                    : 0;
        }
        if (postalCode?.length > 0) {
            trigger && trigger('correspondenceAddress.postalCode');
        }
    }, [trigger, postalCode, postalCodeSk]);

    useEffect(() => {
        if (!country) {
            setValue && setValue('correspondenceAddress.country.uuid', countryCodeList?.find((item) => item.code === 'SK')?.uuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodeList, correspondenceFields]);

    return (
        <>
            <AddressDefaultFields
                register={register}
                errors={errors}
                disabled={disabled}
                disabledCountrySelect={disabledCountrySelect}
                watch={watch}
                setValue={setValue}
                objectName="address."
                trigger={trigger}
                touched={touched}
            />
            {!disabled && !disabledCountrySelect && (
                <Checkbox
                    ref={register}
                    id="correspondenceFields"
                    name="correspondenceFields"
                    label={<Trans i18nKey="customer-request.steps.address.address-different">Korešpondenčná adresa je iná</Trans>}
                />
            )}
            {correspondenceFields && (
                <>
                    <Input
                        trigger={trigger}
                        ref={register(requiredTrimed)}
                        errors={errors}
                        type="text"
                        name="correspondenceAddress.street"
                        label={<Trans i18nKey="customer-request.steps.address.street">Ulica</Trans>}
                        touched={touched}
                    />
                    <Input
                        trigger={trigger}
                        ref={register({ ...requiredTrimed, ...formRules.numberWithCharacters })}
                        errors={errors}
                        type="text"
                        name="correspondenceAddress.number"
                        label={<Trans i18nKey="customer-request.steps.address.number">Číslo</Trans>}
                        touched={touched}
                    />
                    <Input
                        trigger={trigger}
                        ref={register(requiredTrimed)}
                        errors={errors}
                        type="text"
                        name="correspondenceAddress.city"
                        label={<Trans i18nKey="customer-request.steps.address.city">Mesto</Trans>}
                        touched={touched}
                    />
                    <Input
                        trigger={trigger}
                        ref={combineRefs<HTMLInputElement>([
                            register({ ...requiredTrimed, ...(postalCodeSk ? formRules.postalCodeSk : formRules.postalCode) }),
                            localRef,
                        ])}
                        errors={errors}
                        type="text"
                        maxLength={6}
                        name="correspondenceAddress.postalCode"
                        label={<Trans i18nKey="customer-request.steps.address.postcode">PSČ</Trans>}
                        touched={touched}
                    />
                    <BaseSelect
                        ref={register({})}
                        name={`correspondenceAddress.country.uuid`}
                        label={<Trans i18nKey="customer-request.steps.address.country">Štát</Trans>}
                    >
                        {countryCodeList?.map((item, index) => (
                            <option key={index} value={item.uuid}>
                                {item.name}
                            </option>
                        ))}
                    </BaseSelect>
                </>
            )}
        </>
    );
};

export default AddressFields;
