import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { IComplainedInvoiceProblemDescription } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import BlockComplainedAdvances from '../block-complained-advances/block-complained-advances';
import { ReasonEnum } from './block-problem-description-short';
import DetailIncorrectMeterReading from './components/short/detail-incorrect-meter-reading';
import DetailInvoiceIncorrect from './components/short/detail-invoice-incorrect';

interface IBlockProblemDescriptionDetailForm {
    reason: string;
    meterNumber: string;
    date: string;
    valueHigh: number;
    valueLow: number;
}

const BlockProblemDescriptionDetailShort: React.FC = () => {
    const dispatch = useDispatch();
    const { content, deliveryPoint } = useSelector((state: IRootState) => state.customerRequest);

    const complainedInvoiceProblemDescription = content.complainedInvoiceProblemDescription as IComplainedInvoiceProblemDescription;

    const { register, handleSubmit, errors, trigger, setValue, getValues, watch } = useForm<IBlockProblemDescriptionDetailForm>({
        defaultValues: {
            meterNumber: complainedInvoiceProblemDescription?.meterNumber || deliveryPoint?.deviceNumber || '',
            reason: complainedInvoiceProblemDescription?.description,
            date: complainedInvoiceProblemDescription?.requiredReadingDate,
            valueHigh: complainedInvoiceProblemDescription?.requiredMeterReading,
            valueLow: complainedInvoiceProblemDescription?.requiredMeterReadingNT,
        },
    });

    const nextStep = () => {
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const onFormSubmit = handleSubmit((formData: IBlockProblemDescriptionDetailForm) => {
        if (
            content.problemDescription === ReasonEnum.WRONG_TARIFF ||
            content.problemDescription === ReasonEnum.OTHER ||
            content.problemDescription === ReasonEnum.WRONG_CUSTOMER_DATA
        ) {
            dispatch(
                CustomerRequestActions.setData({
                    ...content,
                    complainedInvoiceProblemDescription: {
                        ...complainedInvoiceProblemDescription,
                        description: formData.reason,
                    },
                }),
            );
        }
        if (content.problemDescription === ReasonEnum.WRONG_METER_READING) {
            dispatch(
                CustomerRequestActions.setData({
                    ...content,
                    complainedInvoiceProblemDescription: {
                        ...complainedInvoiceProblemDescription,
                        requiredMeterReading: formData.valueHigh,
                        requiredMeterReadingNT: formData.valueLow,
                        requiredReadingDate: formData.date,
                        meterNumber: formData.meterNumber ?? deliveryPoint?.deviceNumber,
                    },
                }),
            );
        }
        nextStep();
    });

    return (
        <>
            {content.problemDescription === ReasonEnum.INCORRECT_ADVANCE_PAYMENTS ? (
                <>
                    <div className="text-center my-2 my-lg-5">
                        <h3>
                            <Trans i18nKey="customer-request.steps.reason-for-claim-detail.claimed-advance-payments" />
                        </h3>
                        <p>
                            <Trans i18nKey="customer-request.steps.reason-for-claim-detail.specify-claimed-advance-payments" />
                        </p>
                    </div>
                    <BlockComplainedAdvances />
                </>
            ) : (
                <form onSubmit={onFormSubmit} noValidate>
                    {(content.problemDescription === ReasonEnum.WRONG_TARIFF ||
                        content.problemDescription === ReasonEnum.OTHER ||
                        content.problemDescription === ReasonEnum.WRONG_CUSTOMER_DATA) && (
                        <DetailInvoiceIncorrect register={register} errors={errors} problem={content.problemDescription as string} watch={watch} />
                    )}
                    {content.problemDescription === ReasonEnum.WRONG_METER_READING && (
                        <DetailIncorrectMeterReading
                            register={register}
                            errors={errors}
                            trigger={trigger}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                            deliveryPoint={deliveryPoint}
                        />
                    )}

                    <BaseButton type="submit">
                        <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                    </BaseButton>
                </form>
            )}
        </>
    );
};

export default BlockProblemDescriptionDetailShort;
