import React from 'react';

type Props = {
    title: JSX.Element | string;
    subtitle?: JSX.Element | string;
};

const StepsBlockHeader: React.FC<Props> = ({ title, subtitle }) => {
    return (
        <>
            <h4 className="text-left text-lg mt-2 mt-lg-5">{title}</h4>
            {subtitle && <p className="text-left text-lg mb-4 ">{subtitle}</p>}
        </>
    );
};

export default StepsBlockHeader;
