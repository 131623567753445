import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import { Reducer } from 'react';
import { eInvoiceSettingsAction, EInvoiceSettingsActionType, EInvoiceSettingsModalTypes } from '../actions/einvoice-settings-actions';

export interface IEInvoiceSettingsState {
    deliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[];
    deliveryPointForEmailChange: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary | undefined;
    deliveryPointForActivation: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary | undefined;
    modalType: EInvoiceSettingsModalTypes | undefined;
}

const mockState = {
    deliveryPoints: [],
    deliveryPointForEmailChange: undefined,
    deliveryPointForActivation: undefined,
    modalType: undefined,
};

const initState: IEInvoiceSettingsState = mockState;

const EInvoiceSettingsReducer: Reducer<IEInvoiceSettingsState, eInvoiceSettingsAction> = (state = initState, action) => {
    switch (action.type) {
        case EInvoiceSettingsActionType.OPEN_MODAL:
            return {
                ...state,
                modalType: action.payload.modalType,
            };
        case EInvoiceSettingsActionType.SET_DELIVERY_POINTS:
            return {
                ...state,
                deliveryPoints: action.payload.deliveryPoints,
            };
        case EInvoiceSettingsActionType.SET_DELIVERY_POINT_EMAIL_CHANGE:
            return {
                ...state,
                deliveryPointForEmailChange: action.payload.deliveryPoint,
            };
        case EInvoiceSettingsActionType.SET_DELIVERY_POINT_ACTIVATION:
            return {
                ...state,
                deliveryPointForActivation: action.payload.deliveryPoint,
            };
        case EInvoiceSettingsActionType.RESET:
            return initState;
        default:
            return state;
    }
};

export default EInvoiceSettingsReducer;
