import React from 'react';

export type ErrorObject = {
    url: string;
    error: {
        code: number;
        exception: string;
        message: string;
        requestId: string;
        timestamp: string;
    };
};

type ShowErrorProps = {
    className?: string;
    error: ErrorObject | null;
};

const ShowError: React.FC<ShowErrorProps> = ({ className, error }) => {
    if (error) {
        const errorObject = error as ErrorObject;
        if (errorObject.error) {
            const errorUrl = errorObject.url;
            const errorCode = errorObject.error.code;
            const errorMessage = errorObject.error.message;
            const errorException = errorObject.error.exception;
            return (
                <div className={className}>
                    <b style={{ color: 'red' }}>Failed to execute {errorUrl}</b>
                    <br />
                    <b style={{ color: 'red' }}>
                        Error {errorCode} : {errorMessage}
                    </b>
                    <div style={{ color: 'red', maxHeight: '300px', overflow: 'auto' }}>{errorException}</div>
                </div>
            );
        }
    }
    return null;
};

export default ShowError;
