import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as PagedResponseUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointVisibility as DeliveryPointVisibility,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { Fragment, ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { UncontrolledTooltip } from 'reactstrap';
import { ClickableElement } from '../../components/common';
import Breadcrumb from '../../components/common/breadcrumb';
import LoadingIndicator from '../../components/common/loading-indicator';
import Paginator from '../../components/common/paginator';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { usePaginatedQueryWithError } from '../../hooks/use-paginated-query-with-error';
import { CommodityEnum } from '../../models/enums';
import { IApiResponse } from '../../models/model';
import { IRootState } from '../../reducers';
import Routes from '../../routes/routes';

interface IVisibilityParams {
    uuid: string;
    params: DeliveryPointVisibility;
}

const ManageDeliveryPoints = (): ReactElement => {
    const [paging, setPaging] = useState<{ size: number; page: number }>({ page: 0, size: 10 });
    const [deliveryPoints, setDeliveryPoints] = useState<UnitedDeliveryPointSummary[]>();
    const [deliveryPointsPaginationTotalCount, setDeliveryPointsPaginationTotalCount] = useState<number>(0);
    const user = useSelector((s: IRootState) => s.user.customer);
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const { t } = useTranslation();
    const api = useApi();
    const { addToast } = useToasts();
    const queryCache = useQueryCache();
    const saveVisibility = async ({ uuid, params }: IVisibilityParams) =>
        loggedInCustomer?.id == null ? null : api.customers.visibility(loggedInCustomer.id, uuid, params, { secure: true });

    const [mutate, { isLoading: isLoadingVisible }] = useMutationWithError(saveVisibility, {
        onSuccess: (data: unknown, variables: IVisibilityParams) => {
            addToast(
                variables.params.hidden
                    ? t('delivery-point.manage-delivery-points.successfully-hide')
                    : t('delivery-point.manage-delivery-points.successfully-unhide'),
                {
                    appearance: 'success',
                },
            );
            queryCache.invalidateQueries('delivery-points');
        },
        onErrorWithGlobalErrorHandling: (err: IApiResponse) => {
            return false;
        },
    });

    usePaginatedQueryWithError<PagedResponseUnitedDeliveryPointSummary | null>({
        queryKey: ['delivery-points', paging, user?.id],
        queryFn: async () =>
            user?.id
                ? api.customers
                      .getUnitedDeliveryPointsByCustomerAccount(
                          user.id,
                          {
                              queryStringPaging: {
                                  page: paging.page,
                                  size: paging.size,
                                  sort: ['bpExternalId', 'ASC', 'city', 'ASC', 'street', 'ASC'],
                              },
                          },
                          { secure: true },
                      )
                      .then((response) => response.data)
                : null,
        config: {
            onSuccess: (data: PagedResponseUnitedDeliveryPointSummary | null) => {
                setDeliveryPoints(data?.result || undefined);
                setDeliveryPointsPaginationTotalCount(data?.paging?.total || 0);
            },
            onError: (err: IApiResponse) => {
                return false;
            },
        },
    });

    const gropingKey = function(dp: UnitedDeliveryPointSummary): string {
        return dp.businessPartner?.externalId || '';
    };

    const getGroupedResolveData = function() {
        const groupdData = deliveryPoints
            ? deliveryPoints.reduce(
                  (entryMap, e) => entryMap.set(gropingKey(e), [...(entryMap.get(gropingKey(e)) || []), e]),
                  new Map<string, UnitedDeliveryPointSummary[]>(),
              )
            : new Map<string, UnitedDeliveryPointSummary[]>();
        return [...groupdData.values()];
    };

    return (
        <>
            {isLoadingVisible && <LoadingIndicator fullscreen />}
            <div className="page-header pb-4">
                <div className="container">
                    <Breadcrumb
                        route={Routes.DELIVERY_POINTS}
                        title={<Trans i18nKey="delivery-point.list-delivery-points.title">Odberné miesta</Trans>}
                    />

                    <div className="d-flex justify-content-between">
                        <h2>
                            <Trans i18nKey="delivery-point.manage-delivery-points.title">Správa odberných miest</Trans>
                        </h2>
                    </div>
                    <p>
                        <Trans i18nKey="delivery-point.manage-delivery-points.description">
                            Tu si môžete skryť odberné miesta, ktoré nechcete zobrazovať vo vašom účte. Skrytie odberného miesta nemá vplyv na zmluvy
                            ani dodávku komodít.
                        </Trans>
                    </p>
                </div>
            </div>
            {/* Page Header */}
            <br />

            <div className="container py-2">
                {/* Card */}

                {getGroupedResolveData().map((list) => (
                    <div className="card mb-4" key={gropingKey(list[0])}>
                        <div className="card-header">
                            <p className="mb-0">{list[0].businessPartner?.name}</p>
                            <p className="mb-0">
                                <Trans i18nKey="delivery-point.customer-number">Zákaznícke číslo</Trans>: {list[0].businessPartner?.externalId}
                            </p>
                        </div>
                        <ul className="list-group list-group-flush">
                            {list.map((item, i) => (
                                <li className="list-group-item" key={i}>
                                    <div className="row text-decoration-none">
                                        <div className="col">
                                            <p className="font-weight-bold mb-0">
                                                {item.address?.street + ' ' + item.address?.streetNumber + ', ' + item.address?.city}
                                            </p>
                                            {item.deliveryPoints?.map((dbPoint) => (
                                                <Fragment key={dbPoint.externalId}>
                                                    {dbPoint.type === CommodityEnum.ELECTRICITY && (
                                                        <p className="mb-0 text-secondary">
                                                            <Trans i18nKey="delivery-point.place-number-ee">Číslo miesta pre elektrinu</Trans>:{' '}
                                                            {dbPoint.externalId}
                                                        </p>
                                                    )}
                                                    {dbPoint.type === CommodityEnum.GAS && (
                                                        <p className="mb-0 text-secondary">
                                                            <Trans i18nKey="delivery-point.place-number-zp">Číslo miesta pre plyn</Trans>:{' '}
                                                            {dbPoint.externalId}
                                                        </p>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </div>
                                        <ClickableElement
                                            className="col-auto d-flex align-items-center"
                                            onClick={() => {
                                                if (item.id) {
                                                    mutate({
                                                        uuid: item.id,
                                                        params: { hidden: !item.hidden },
                                                    });
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon id={`tooltip_${item.id}_visibility`} icon={item.hidden ? faEyeSlash : faEye} size="2x" />
                                            <UncontrolledTooltip target={`tooltip_${item.id}_visibility`} placement="top">
                                                {item.hidden
                                                    ? t('delivery-point.manage-delivery-points.tooltip-unhide')
                                                    : t('delivery-point.manage-delivery-points.tooltip-hide')}
                                            </UncontrolledTooltip>
                                        </ClickableElement>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                <Paginator
                    pageIndex={paging.page}
                    pageSize={paging.size}
                    dataLength={deliveryPointsPaginationTotalCount}
                    onPageChanged={(newPage) => setPaging((prev) => ({ ...prev, page: newPage }))}
                />
            </div>
        </>
    );
};

export default ManageDeliveryPoints;
