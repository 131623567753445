import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { sk } from 'date-fns/locale';
import moment from 'moment';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { accessNestedProperty } from '../../../utils/utils';
import './react-datepicker.scss';
registerLocale('sk', sk);

interface IDatePicker {
    label: string | JSX.Element;
    name?: string;
    onChange?: (value: Date | undefined) => void;
    value?: Date | undefined;
    position?: 'R' | 'L';
    register?: (options: any) => any;
    errors?: FieldErrors;
    border?: boolean;
    minTime?: Date;
    maxTime?: Date;
    getValues?: any;
    setValue?: any;
    trigger?: (name: any) => Promise<boolean>;
    placeholder?: string;
    timeIntervals?: number;
    timeFormat?: string;
}

const returnDateWithSetTime = (time: string) => {
    if (!moment(time, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm'], true).isValid()) {
        return new Date(0, 0, 0, 0, 0, 0, 0);
    } else {
        const timeArray = time.split(':');
        return new Date(
            0,
            0,
            0,
            timeArray[0] ? Number(timeArray[0]) : 0,
            timeArray[1] ? Number(timeArray[1]) : 0,
            timeArray[2] ? Number(timeArray[2]) : 0,
            timeArray[3] ? Number(timeArray[3]) : 0,
        );
    }
};

const TimePicker = ({
    label,
    onChange,
    position,
    register,
    errors,
    border = true,
    name = 'date',
    getValues,
    setValue,
    trigger,
    placeholder,
    timeIntervals = 15,
    timeFormat = 'HH:mm',
    minTime,
    maxTime,
}: IDatePicker): ReactElement => {
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
    const { i18n } = useTranslation();
    const [input, setInput] = useState<Date>();
    const [inputHelp, setInputHelp] = useState<string>();
    const refCalendar = useRef<ReactDatePicker>(null);
    const rgx = /^[0-9:]+$|^$/; //regex only numbers and colons
    const lang = i18n.language;
    const [t] = useTranslation();

    const handleTimepicker = (val: boolean) => {
        refCalendar?.current?.setOpen(val);
    };

    useEffect(() => {
        if (getValues && getValues(name)) {
            setInputHelp(getValues(name));
        }
    }, [getValues, name, timeFormat]);

    const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));

    const isValid = errors == null || errorProperty == null;
    const [isTouched, setIsTouched] = useState(false);

    const manualErrorCheck = useCallback(() => {
        trigger && trigger(name);
    }, [name, trigger]);

    useEffect(() => {
        !isValid && manualErrorCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, manualErrorCheck]);

    const [isFocused, setIsFocused] = useState(false);

    const setHiddenInputValue = (newValue: string) => {
        setValue && setValue(name, newValue);
        manualErrorCheck();
    };

    if ((minTime && !maxTime) || (!minTime && maxTime)) {
        throw new Error('TIME PICKER - Invalid configuration: Both minTime and maxTime must be provided or both must be omitted.');
    }

    return (
        <>
            <input type="text" style={{ display: 'none' }} ref={register} name={name} />
            <ReactDatePicker
                ref={refCalendar}
                locale={lang === 'en' ? 'en' : 'sk'}
                popperClassName={position === 'R' ? 'right-position-class' : ''}
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: '0px, 0px',
                    },
                }}
                minTime={minTime}
                maxTime={maxTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={timeIntervals}
                timeCaption={t('common.time')}
                selected={
                    inputHelp && moment(inputHelp, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY'], true).isValid()
                        ? moment(inputHelp, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY']).toDate()
                        : input
                }
                customInput={
                    <>
                        <div
                            className={classNames('input-group', 'validated-datepicker', {
                                'is-invalid': !isValid,
                                'is-valid': isValid && isTouched,
                                'is-focused': isFocused, // hack for IE11 as IE11 doesn't support focus-within
                                'position-right-borders': position === 'R',
                            })}
                            style={{ border: border ? '' : 'none' }}
                        >
                            <input
                                className={classNames(
                                    'form-control',
                                    {
                                        'position-right-borders': position === 'R',
                                    },
                                    {
                                        'is-invalid': !isValid,
                                        'is-valid': isValid && isTouched,
                                    },
                                    { 'datepicker-opened': datePickerOpen, datepicker: !datePickerOpen },
                                )}
                                type={'text'}
                                value={inputHelp ?? ''}
                                placeholder={placeholder ?? ' '}
                                required // for style application only, is not validated as required
                                autoComplete="off"
                                maxLength={10}
                                name={name}
                                onClick={() => handleTimepicker(true)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const time = e.target.value;
                                    if (isTouched === false) {
                                        setIsTouched(true);
                                    }
                                    if (!rgx.test(time)) {
                                        return;
                                    }
                                    setInputHelp(time);
                                    setHiddenInputValue(time);
                                    if (moment(time, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm'], true).isValid()) {
                                        setInput(returnDateWithSetTime(time));
                                        handleTimepicker(false);
                                    }
                                }}
                            />
                            <label className="control-label">{label}</label>
                            {errors != null && !isFocused && (
                                <div className="invalid-feedback small">
                                    <ErrorMessage errors={errors} name={name} />
                                </div>
                            )}
                        </div>
                    </>
                }
                renderDayContents={(day) => {
                    return <span className="my_class">{day}</span>;
                }}
                onCalendarClose={() => setDatePickerOpen(false)}
                onCalendarOpen={() => setDatePickerOpen(true)}
                onChange={(date) => {
                    if (isTouched === false) {
                        setIsTouched(true);
                    }
                    if (date === null) {
                        onChange && onChange(undefined);
                    } else if (date instanceof Date) {
                        onChange && onChange(date);
                        const formatedDate = moment(date).format(timeFormat);
                        setHiddenInputValue(formatedDate);
                        setInput(date);
                        setInputHelp(formatedDate);
                    }
                }}
            />
        </>
    );
};
export default TimePicker;
