import { SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary } from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { IColumnConfiguration, PageableTable } from '../../components/common';
import { transactionsColumnConfigurations } from './transactions-column-configuration';

interface IInvoicesTableFilterProps {
    transactionSummary: SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary[];
}

const InvoicesTransactions: React.FC<IInvoicesTableFilterProps> = ({ transactionSummary }) => {
    const [pageIndex, setPageIndex] = useState(0);

    const [columnConfigurations] = useState<IColumnConfiguration<SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary>[]>(
        transactionsColumnConfigurations(),
    );

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
    };
    return (
        <PageableTable<SkSppNzpBeApiCustomerprofileInvoicesummaryCustomerTransactionSummary>
            data={transactionSummary}
            columnConfigurations={columnConfigurations}
            page={pageIndex}
            pageSize={transactionSummary.length}
            totalDataCount={transactionSummary.length}
            onPageChange={onPageChange}
        />
    );
};

export default InvoicesTransactions;
