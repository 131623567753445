import { QueryConfig, QueryFunction, QueryKey, QueryResult, useQuery as useReactQuery } from 'react-query';
import { IApiResponse } from '../models/model';
import useGlobalErrorHandling from '../utils/global-error-handling-fn';

export interface IQueryConfigWithError<TResult = unknown, TError = unknown> extends QueryConfig<TResult, TError> {
    onError?: (err: TError) => boolean;
}
export interface IUseQueryObjectConfig<TResult = unknown, TError = unknown> {
    queryKey: QueryKey;
    queryFn: QueryFunction<TResult>;
    queryConfig?: IQueryConfigWithError<TResult, TError>;
}

export function useQueryWithError<TResult = unknown, TError extends IApiResponse = IApiResponse>(
    queryKey: QueryKey,
    queryFn: QueryFunction<TResult>,
    queryConfig?: IQueryConfigWithError<TResult, TError>,
): QueryResult<TResult, TError> {
    const { globalErrorHandlingFn } = useGlobalErrorHandling();

    return useReactQuery<TResult, TError>(queryKey, queryFn, {
        ...queryConfig,
        onError: (err: TError) => {
            if (queryConfig?.onError == null || (queryConfig?.onError != null && queryConfig.onError(err) === false)) {
                globalErrorHandlingFn(err?.error?.code);
            }
        },
    });
}
