import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { joinNodes } from '../../utils/react-utils';

interface IFilterDescriptionProps {
    elements: React.ReactNode[];
}

export const FilterDescription: React.FC<IFilterDescriptionProps> = ({ elements }) => {
    const maxShownItems = 3;

    const ref = useRef<HTMLSpanElement>(null);

    return (
        <>
            {joinNodes(elements.slice(0, maxShownItems), ', ')}
            {elements.length > maxShownItems && (
                <>
                    <span ref={ref}>
                        <Trans i18nKey="common.tables.plus-another-parameters" values={{ count: elements.length - maxShownItems }} />
                    </span>
                    <UncontrolledTooltip placement="top" target={ref}>
                        {joinNodes(elements.slice(maxShownItems), ', ')}
                    </UncontrolledTooltip>
                </>
            )}
        </>
    );
};
