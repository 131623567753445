import React, { ReactElement, useEffect } from 'react';
import classNames from 'classnames';

export type HighlightedItem<T> = {
    l1: string;
    l2: string;
    l3: string;
    option: T;
};

interface ITypeaheadItem<T> {
    item: HighlightedItem<T>;
    index: number;
    selectedIndex: number;
    addOption: (item: HighlightedItem<T>) => void;
}

function TypeaheadItem<T>({ item, index, selectedIndex, addOption }: ITypeaheadItem<T>): ReactElement {
    const itemElement = React.useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (index === selectedIndex && itemElement.current) {
            itemElement.current.scrollIntoView(true);
        }
    }, [itemElement, selectedIndex, index]);

    return (
        <span
            ref={itemElement}
            key={index}
            role="option"
            aria-selected={selectedIndex === index}
            className={classNames('dropdown-item', {
                active: selectedIndex === index,
            })}
            onClick={() => addOption(item)}
        >
            {item.l1}
            <b>{item.l2}</b>
            {item.l3}
        </span>
    );
}

export default TypeaheadItem;
