import React from 'react';
import { Trans } from 'react-i18next';
import SettingsNavigation from './settings-navigation';

interface ISettingsHeaderProps {
    isSubpage?: boolean;
}

const SettingsHeader: React.FC<ISettingsHeaderProps> = ({ isSubpage }) => {
    return (
        <>
            {isSubpage ? (
                <div className="page-header py-4 d-none d-lg-block">
                    <div className="container py-3">
                        <h1 className="text-center">
                            <Trans i18nKey="settings.title" />
                        </h1>
                    </div>
                </div>
            ) : (
                <div className="page-header py-4">
                    <div className="container py-3">
                        <h1 className="text-center">
                            <Trans i18nKey="settings.title" />
                        </h1>
                    </div>
                </div>
            )}
            <div className="bg-white d-lg-block d-none">
                <div className="container">
                    <SettingsNavigation />
                </div>
            </div>
        </>
    );
};

export default SettingsHeader;
