import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery as CustomerRequestSearchQuery,
    SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import CustomerRequestFilterForm from './customer-request-filter-form';
interface ICustomerRequestFilterProps {
    isOpen: boolean;
    onCloseModal: () => void;
    filter: Partial<CustomerRequestSearchQuery>;
    onFilterChange: (newFilter: Partial<CustomerRequestSearchQuery>) => void;
    extra?: boolean;
    deliveryPoints?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[];
    statusCodeList: CodeListItem[];
}

const CustomerRequestFilterModal: React.FC<ICustomerRequestFilterProps> = ({
    isOpen,
    onCloseModal,
    filter,
    onFilterChange,
    extra,
    deliveryPoints,
    statusCodeList,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="common.tables.filter" />
                    </h3>
                    <CustomerRequestFilterForm
                        statusCodeList={statusCodeList}
                        deliveryPoints={deliveryPoints ?? []}
                        extra={extra}
                        currentFilter={filter}
                        onSubmit={(newFilter) => {
                            onFilterChange(newFilter);
                            onCloseModal();
                        }}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default CustomerRequestFilterModal;
