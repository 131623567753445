import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseButton, ClickableElement, Input, useFormRules } from '../../../../../../main';
import { IAddress } from '../../../../../../models/customer-request-model';
import { DefaultFormType } from '../../../../../../models/model';
import AddressDefaultFields from '../../components/address-default-fields';

type Props = DefaultFormType & {
    address?: IAddress;
    countryCodeList?: SkSppNzpCommonsApiCodelistCodeListItem[] | null;
    label?: boolean;
    loggedInCustomer?: boolean;
    setEditAddress: (val: boolean) => void;
    editAddress: boolean;
};

const AddressFormFields: React.FC<Props> = ({
    register,
    errors,
    trigger,
    watch,
    setValue,
    address,
    countryCodeList,
    label = true,
    loggedInCustomer = true,
    setEditAddress,
    editAddress,
}) => {
    const { requiredTrimed } = useFormRules();
    const { t } = useTranslation();

    return (
        <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-4 mt-4">
            {label && (
                <div className="row  m-1">
                    <div className="col-1 p-0 m-auto">
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                    </div>
                    <div className="col">
                        <b style={{ fontSize: 16 }}>{t('customer-request.steps.bank-connection-transcript.overpayment-address')}</b>
                    </div>
                </div>
            )}
            <div className="row m-1">
                {label && <div className="col-1 p-0 m-auto"></div>}
                <div className="col">
                    {editAddress ? (
                        <div className="mt-2">
                            <Input
                                trigger={trigger}
                                ref={register(requiredTrimed)}
                                errors={errors}
                                type="text"
                                name="address.name"
                                label={
                                    <Trans i18nKey="customer-request.steps.bank-connection-transcript.name-or-company-name">
                                        Meno\ Názov spoločnosti
                                    </Trans>
                                }
                            />
                            <AddressDefaultFields
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                                objectName="address."
                                trigger={trigger}
                            />
                            <BaseButton type="submit">
                                <Trans i18nKey="common.save">Uložiť</Trans>
                            </BaseButton>
                        </div>
                    ) : (
                        <>
                            <div className="mb-2" style={{ fontSize: '1rem' }}>
                                {address?.name}
                                <br />
                                {address?.street || ''} {address?.number || ''}
                                <br />
                                {address?.city || ''}, {address?.postalCode || ''}
                                <br />
                                {address?.country?.name ?? countryCodeList?.find((item) => item.uuid === address?.country?.uuid)?.name}
                                {address?.floor && (
                                    <>
                                        <br />
                                        {`${t('customer-request.steps.address.floor')}: ${address?.floor}`}
                                    </>
                                )}
                                {address?.flatNumber && (
                                    <>
                                        <br />
                                        {`${t('customer-request.steps.address.appartments-number')}: ${address?.flatNumber}`}
                                    </>
                                )}
                            </div>

                            <ClickableElement isText onClick={() => setEditAddress(!editAddress)} className="text-decoration-underline-inverse">
                                {t('customer-request.steps.bank-connection-transcript.different-address')}
                            </ClickableElement>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddressFormFields;
