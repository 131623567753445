import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, ListGroup, UncontrolledTooltip } from 'reactstrap';
import { eInvoiceSettingsActions, EInvoiceSettingsModalTypes } from '../../../../actions/einvoice-settings-actions';
import { PenComponent } from '../../../../components/common/pen-component';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { IEInvoiceActivationCustomerRequest, LoadingIndicator, QueryKeysEnum, useApi } from '../../../../main';
import { formatAddressShort } from '../../../../utils/utils';
import { getActiveRequestsForUdp } from '../utils/einvoice-utils';
import { IUdpToCustomerRequestMapping } from '../utils/use-fetch-multiple-customer-requests';
import { ActiveEinvoiceDpListitem } from './active-einvoice-dp-listitem';
import { InactiveEinvoiceDpListitem } from './inactive-einvoice-dp-listitem';

interface IActiveEinvoiceUdpGroupProps {
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary;
    customerRequests: IUdpToCustomerRequestMapping[] | null | undefined;
}

export const ActiveEinvoiceUdpGroup: React.FC<IActiveEinvoiceUdpGroupProps> = ({ unitedDeliveryPoint, customerRequests }) => {
    const isDpEinvoiceActive = (dp: SkSppNzpBeApiCustomerprofileDeliveryPointSummary) => dp.einvoice?.email != null && dp.einvoice.activated === true;

    const dispatch = useDispatch();
    const onEditEmail = () => {
        dispatch(eInvoiceSettingsActions.setDeliveryPointForEmailChange(unitedDeliveryPoint));
        dispatch(eInvoiceSettingsActions.openModal(EInvoiceSettingsModalTypes.EDIT_DELIVERY_POINT_EMAIL_MODAL));
    };

    const activeRequests = getActiveRequestsForUdp(unitedDeliveryPoint.id, customerRequests);
    const isEmailChangeRequested = activeRequests != null && activeRequests.length > 0;
    const api = useApi();

    const activeRequestId = activeRequests == null ? undefined : activeRequests[0]?.uuid;

    const { isLoading: isLoadingCustomerRequest, data: customerRequestData } = useQueryWithError<IEInvoiceActivationCustomerRequest | null>(
        [QueryKeysEnum.CUSTOMER_REQUEST, activeRequestId],
        async () => {
            if (activeRequestId == null) return null;
            return api.customerRequests.getCustomerRequest(activeRequestId, { secure: true }).then((res) => res.data);
        },
        {
            enabled: activeRequestId != null,
        },
    );
    const requestedEmailForEInvoiceEmailChange = customerRequestData?.content.invoiceDelivery?.email;

    const tooltipDivRef = useRef<HTMLDivElement>(null);

    return (
        <div className="mb-4">
            <Card>
                <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-weight-bold my-1">{formatAddressShort(unitedDeliveryPoint.address)}</h5>
                        <div>
                            {isEmailChangeRequested ? (
                                <>
                                    <div className="py-1 px-2 mr-n2" ref={tooltipDivRef}>
                                        <FontAwesomeIcon icon={faHourglassHalf} size="lg" className="text-secondary" />
                                    </div>
                                    <UncontrolledTooltip placement="top" target={tooltipDivRef}>
                                        <Trans
                                            i18nKey="settings.e-invoices.pending-email-change"
                                            values={{ email: requestedEmailForEInvoiceEmailChange || '' }}
                                        />
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <PenComponent onClick={onEditEmail} iconClass="fa-lg" />
                            )}
                        </div>
                    </div>
                </CardHeader>

                <ListGroup flush>
                    {unitedDeliveryPoint.deliveryPoints?.map((dp) =>
                        isDpEinvoiceActive(dp) ? (
                            <ActiveEinvoiceDpListitem
                                key={dp.id}
                                deliveryPoint={dp}
                                showContractId={(unitedDeliveryPoint.deliveryPoints?.filter((item) => item.type === dp.type)?.length || 0) > 1}
                            />
                        ) : (
                            <InactiveEinvoiceDpListitem
                                key={dp.id}
                                deliveryPoint={dp}
                                showContractId={(unitedDeliveryPoint.deliveryPoints?.filter((item) => item.type === dp.type)?.length || 0) > 1}
                            />
                        ),
                    )}
                </ListGroup>
            </Card>

            <div className="my-3 text-right position-relative">
                {isLoadingCustomerRequest ? (
                    <LoadingIndicator size="medium" transparentMask />
                ) : (
                    <>
                        {isEmailChangeRequested && (
                            <>
                                <p className="text-success d-block mt-1 font-weight-bold">
                                    <Trans
                                        i18nKey="settings.e-invoices.pending-email-change"
                                        values={{ email: requestedEmailForEInvoiceEmailChange || '' }}
                                    />
                                </p>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
