import React from 'react';
import { Trans } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';

export const NotificationSettingsNotAvailable: React.FC = () => {
    return (
        <>
            <Card>
                <CardBody className="text-center">
                    <i className="icon-circle-alert-16 fa-lg d-block mb-2"></i>
                    <p>
                        <Trans i18nKey="settings.notifications.user-has-no-delivery-points" />
                        <br />
                        <Trans i18nKey="settings.notifications.add-delivery-point-to-set-notifications" />
                    </p>
                </CardBody>
            </Card>
        </>
    );
};
