import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import './custom-button-dropdown.scss';

interface ICustomButtonDropdownProps {
    title: string | JSX.Element;
    buttons: { id: string; label: string | JSX.Element; onClick?: () => void; className?: string; disabled?: boolean }[];
}

const CustomButtonDropdown: React.FC<ICustomButtonDropdownProps> = ({ title, buttons }) => {
    return (
        <>
            <UncontrolledButtonDropdown group={false} direction="down" className="custom-button-dropdown">
                <DropdownToggle caret color="white" className="bg-white border-primary">
                    {title}
                </DropdownToggle>
                <DropdownMenu right>
                    {buttons.map((button) => (
                        <DropdownItem
                            key={button.id}
                            onClick={button.onClick}
                            className={button.className || 'text-truncate'}
                            disabled={button.disabled}
                        >
                            {button.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>
    );
};

export default CustomButtonDropdown;
