import React from 'react';
import { Container } from 'reactstrap';

const ScreenHeader: React.FC<React.ReactNode> = ({ children }) => {
    return (
        <div className="page-header pt-4 pb-md-3 mb-4">
            <div className="page-header pb-4">
                <Container>{children}</Container>
            </div>
        </div>
    );
};

export default ScreenHeader;
