import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { BaseSwitch, DatePicker, Input } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../components/common/input-with-append';
import useCodeList from '../../../../../hooks/use-code-list';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IAssumedConsumption } from '../../../../../models/customer-request-model';
import { AssumedEeConsumptionEnum, DeliveryPointEeConnectionEnum, PhasesConsumptionEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import ElectricityConsumptionV1FormFields from './components/electricity-consumption-v1-form-fields';
import ElectricityConsumptionV2FormFields from './components/electricity-consumption-v2-form-fields';

const BlockAssumedConsumption: React.FC = () => {
    const dispatch = useDispatch();
    const { dateRules, eicNumberFormat, formRules } = useFormRules();
    const { currentBlock, content, deliveryPointEe } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.ASSUMED_CONSUMPTION;
    const objectData = content[objectName] as IAssumedConsumption;

    const [activeType, setActiveType] = useState<AssumedEeConsumptionEnum>(
        content.assumedConsumption?.tariff ??
            (deliveryPointEe?.contract?.eeTariffCount === 2 ? AssumedEeConsumptionEnum.T2 : AssumedEeConsumptionEnum.T1),
    );

    const [phases, setPhases] = useState<PhasesConsumptionEnum>(
        content.assumedConsumption?.phase ?? (deliveryPointEe?.contract?.eeTariffCount === 2 ? PhasesConsumptionEnum.F3 : PhasesConsumptionEnum.F1),
    );

    const { data: distributionAreaCodelist } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 5000,
        },
    });

    const { register, errors, handleSubmit, getValues, setValue, trigger } = useForm<IAssumedConsumption>({ defaultValues: objectData });

    const onSubmit = (data: IAssumedConsumption) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                ...data,
                tariff: activeType,
                phase: content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION ? phases : undefined,
            },
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    const distributionAreas = distributionAreaCodelist?.map((area) => area.code || ' ');

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="mb-5">
                    <FormGroup className="d-flex flex-row mb-1">
                        <DatePicker
                            register={register({
                                ...dateRules().isValidDate.validate,
                                ...dateRules('NOT_OLDER_THAN', new Date().toISOString()).isValidDate,
                            })}
                            minDate={new Date()}
                            setValue={setValue}
                            getValues={getValues}
                            errors={errors}
                            name={`date`}
                            trigger={trigger}
                            label={<Trans i18nKey="customer-request.steps.electricity-consumption.subs-start-date" />}
                        />
                    </FormGroup>
                    <div className="d-flex mx-1 p-2">
                        <div className="mr-3 my-auto">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                        </div>
                        {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION ? (
                            <Trans i18nKey="customer-request.steps.consumption.start-date-of-subscription-info">
                                Dátum začatia odberu závisí od viazanosti s Vaším aktuálnym dodávateľom a taktiež od distribútora. Ak dátum nezadáte,
                                zmena bude vykonaná v najbližšom možnom termíne.
                            </Trans>
                        ) : (
                            <Trans i18nKey="customer-request.steps.consumption.start-date-of-subscription-info-new">
                                Dátum začatia odberu závisí od termínu doručenia a spracovania zmluvy o dodávke elektriny, ktorú Vám odošleme po
                                spracovaní tejto žiadosti.
                            </Trans>
                        )}
                    </div>
                </div>

                <div className="mb-5">
                    <BaseSwitch
                        selectedValue={activeType}
                        onValueSelect={(value) => setActiveType(value as AssumedEeConsumptionEnum)}
                        dataButtons={[
                            {
                                id: AssumedEeConsumptionEnum.T1,
                                title: <Trans i18nKey="customer-request.steps.gauge-condition.tariff1">1-tarifné</Trans>,
                                disabled: deliveryPointEe?.contract?.eeTariffCount === 2,
                            },
                            {
                                id: AssumedEeConsumptionEnum.T2,
                                title: <Trans i18nKey="customer-request.steps.gauge-condition.tariff2">2-tarifné</Trans>,
                                disabled: deliveryPointEe?.contract?.eeTariffCount === 1,
                            },
                        ]}
                    />
                    {activeType === AssumedEeConsumptionEnum.T1 && (
                        <ElectricityConsumptionV1FormFields
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            errors={errors}
                            trigger={trigger}
                        />
                    )}
                    {activeType === AssumedEeConsumptionEnum.T2 && (
                        <ElectricityConsumptionV2FormFields
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            errors={errors}
                            trigger={trigger}
                        />
                    )}
                    <div className="d-flex mx-1 p-2">
                        <div className="mr-3 my-auto">
                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                        </div>
                        {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION ? (
                            <Trans i18nKey="customer-request.steps.consumption.expected-consumption-info">
                                Predpokladaný ročný odber môžete určiť napríklad podľa fakturácie u Vášho aktuálneho dodávateľa.
                            </Trans>
                        ) : (
                            <Trans i18nKey="customer-request.steps.consumption.expected-consumption-info-for-new">
                                Predpokladaný ročný odber môžete určiť podľa projektovej dokumentácie alebo zmluvy o pripojení s distribúciou.
                            </Trans>
                        )}
                    </div>
                </div>
                {content.deliveryPointConnectionEe?.type === DeliveryPointEeConnectionEnum.MIGRATION && (
                    <>
                        <div className="mb-5">
                            <Input
                                trigger={trigger}
                                ref={register(eicNumberFormat(distributionAreas))}
                                errors={errors}
                                type="text"
                                name="deliveryPointNumber"
                                label={<Trans i18nKey="customer-request.steps.delivery-point.eic-number">EIC kód odberného miesta</Trans>}
                                componentClassName="mb-0"
                            />
                            <div className="d-flex mx-1 p-2">
                                <div className="mr-3 my-auto">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <Trans i18nKey="customer-request.steps.consumption.eic-info">
                                    EIC kód nájdete v zmluve o pripojení, ktorú ste uzavreli s prevádzkovateľom distribučnej siete (napr.
                                    Západoslovenská distribučná, a.s.)
                                </Trans>
                            </div>
                        </div>
                        <div className="mb-5">
                            <p>
                                <Trans i18nKey="customer-request.steps.consumption.phases-number">Počet fáz hlavného ističa</Trans>
                            </p>
                            <BaseSwitch
                                selectedValue={phases}
                                className="mb-2"
                                onValueSelect={(value) => setPhases(value as PhasesConsumptionEnum)}
                                dataButtons={[
                                    {
                                        id: PhasesConsumptionEnum.F1,
                                        title: <Trans i18nKey="customer-request.steps.consumption.1-phase">1 fáza</Trans>,
                                    },
                                    {
                                        id: PhasesConsumptionEnum.F3,
                                        title: <Trans i18nKey="customer-request.steps.consumption.3-phase">3 fázy</Trans>,
                                    },
                                ]}
                            />
                            <div className="d-flex mx-1 p-2">
                                <div className="mr-3 my-auto">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <Trans i18nKey="customer-request.steps.consumption.phase-info">
                                    Počet fáz hlavného ističa nájdete v zmluve o pripojení.
                                </Trans>
                            </div>
                        </div>
                        <div className="mb-5">
                            <InputWithAppend
                                trigger={trigger}
                                ref={register({ ...formRules.requiredInteger, ...formRules.min(0) })}
                                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                errors={errors}
                                type="number"
                                componentClassName="mb-1"
                                name="mainCircuitValue"
                                label={<Trans i18nKey="customer-request.steps.consumption.main-circuit-breaker" />}
                                inputGroupAppendChildren={
                                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                        A
                                    </button>
                                }
                            />
                            <div className="d-flex mx-1 p-2">
                                <div className="mr-3 my-auto">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                </div>
                                <Trans i18nKey="customer-request.steps.consumption.main-circuit-breaker-info">
                                    Hodnotu hlavného ističa nájdete v zmluve o pripojení.
                                </Trans>
                            </div>
                        </div>
                    </>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockAssumedConsumption;
