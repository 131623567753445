import { ErrorMessage } from '@hookform/error-message';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IBusinessPartner } from '../../../../../models/customer-request-model';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties } from '../../../../../utils/utils';
import { DeliveryPointCollapseList } from '../block-delivery-point/components/delivery-point-collapse-list';
import { DeliveryPointList } from '../block-delivery-point/components/delivery-point-list';

type BlockCustomerFormType = IBusinessPartner & {
    businessPartnerId: string;
    deliveryPointId: string;
};

const DP_MARGIN_THRESHOLD = 15;

const BlockBusinessPartnerCleanElectricityShort: React.FC = () => {
    const api = useApi();
    const dispatch = useDispatch();
    const { additionalData, partnersDeliveryPoints, deliveryPoint } = useSelector((state: IRootState) => state.customerRequest);

    const [loading, setLoading] = useState<boolean>(false);

    const { register, errors, handleSubmit } = useForm<BlockCustomerFormType>({
        defaultValues: {
            businessPartnerId: additionalData?.bpuuid,
            deliveryPointId: deliveryPoint?.id,
        },
    });

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId?: string }
    >(async ({ contractId }) => (contractId ? api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data) : null));

    const onSubmit = async (data: BlockCustomerFormType) => {
        let businessPartnerId: string | undefined = undefined;
        let groupedBusinessPartner: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary | undefined = undefined;
        let foundDP: SkSppNzpBeApiCustomerprofileDeliveryPointSummary | undefined = undefined;
        setLoading(true);
        Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).find(([bpId, deliveryPoints]) => {
            deliveryPoints.find((item) => {
                return item.deliveryPoints?.find((dp) => {
                    if (dp.id === data.deliveryPointId) {
                        businessPartnerId = bpId;
                        foundDP = dp;
                        return true;
                    }
                });
            });
        });

        if (businessPartnerId) {
            groupedBusinessPartner =
                partnersDeliveryPoints && partnersDeliveryPoints[businessPartnerId] && partnersDeliveryPoints[businessPartnerId].length
                    ? partnersDeliveryPoints[businessPartnerId][0]
                    : undefined;
        }

        const deliveryPointFullInfo = await mutateDeliveryPointByContractId({
            contractId: foundDP && (foundDP as SkSppNzpBeApiCustomerprofileDeliveryPointSummary)?.contract?.id,
        });

        if (deliveryPointFullInfo && groupedBusinessPartner) {
            dispatch(CustomerRequestActions.setBusinessPartner(groupedBusinessPartner.businessPartner));
            const { address } = groupedBusinessPartner;

            const extraPayload: CustomerRequestPayloadType = {
                address: {
                    street: address?.street,
                    number: address?.streetNumber,
                    city: address?.city,
                    postalCode: address?.zipCode,
                },
            };
            dispatch(CustomerRequestActions.setData(clearObjectProperties(extraPayload)));

            dispatch(
                CustomerRequestActions.setMetaData({
                    deliveryPointId: deliveryPointFullInfo.id,
                    contractId: deliveryPointFullInfo.contract?.id,
                    businessPartnerId: businessPartnerId,
                }),
            );
            dispatch(CustomerRequestActions.setDeliveryPoint(deliveryPointFullInfo));
            setLoading(false);
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
        }
        setLoading(false);
    };

    const deliveryPointsCount = useMemo(() => {
        let count = 0;

        Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).forEach(([bpId, deliveryPoints]) => {
            deliveryPoints.forEach((item) => item.deliveryPoints?.forEach((dp) => dp.type === 'EE' && count++));
        });

        return count;
    }, [businessPartners]);

    return (
        <>
            {loading && <LoadingIndicator fullscreen />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
                    const businessPartner = deliveryPoints[0].businessPartner;
                    return businessPartner == null ? null : (
                        <>
                            {deliveryPointsCount > DP_MARGIN_THRESHOLD && Object.entries(businessPartners ?? []).length > 1 ? (
                                <DeliveryPointCollapseList
                                    register={register}
                                    businessPartner={businessPartner}
                                    deliveryPoints={deliveryPoints}
                                    onlyEE
                                />
                            ) : (
                                <DeliveryPointList register={register} businessPartner={businessPartner} deliveryPoints={deliveryPoints} onlyEE />
                            )}
                        </>
                    );
                })}
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name="businessPartnerId" />
                    </div>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockBusinessPartnerCleanElectricityShort;
