import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';

interface ICustomNavPillProps {
    route: string;
    label: ReactNode;
    disabled?: boolean;
}

export const CustomNavPill: React.FC<ICustomNavPillProps> = ({ route, label, disabled }) => {
    const routeMatch = useRouteMatch({ path: route, exact: true });
    const history = useHistory();
    const onClick = () => history.push({ pathname: route });
    return (
        <div className="nav-pills-container">
            <Button onClick={onClick} color="light" className={classNames('custom-nav-pill', { active: routeMatch != null })} disabled={disabled}>
                {label}
            </Button>
            {routeMatch != null && <div className="arrow-up"></div>}
        </div>
    );
};
