import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Button } from 'reactstrap';
import Input from '../../../../../components/common/input';
import { useFormRules } from '../../../../../hooks/use-form-rules';

type Props = {
    currentValue: string | undefined;
    editedValue: string | undefined;
    onReset: () => void;
    onSubmit: (value: string) => void;
};

const ChangeEInvoiceEmailForm: React.FC<Props> = ({ currentValue, editedValue, onReset, onSubmit }) => {
    const displayValue = editedValue ?? currentValue;
    const { register, handleSubmit, errors, trigger } = useForm<{ email: string }>({ defaultValues: { email: displayValue } });

    const { formRules, requiredTrimed } = useFormRules();

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const handleIdenticalNewValue = () => {
        addToast(t('delivery-point.detail.given-data-is-the-same-as-current-data'), {
            appearance: 'warning',
        });
    };

    const onFormSubmit = handleSubmit((field) => {
        if (field.email === currentValue) {
            handleIdenticalNewValue();
        } else {
            onSubmit(field.email);
        }
    });

    return (
        <form onSubmit={onFormSubmit} onReset={onReset} noValidate>
            <Input
                trigger={trigger}
                ref={register({ ...formRules.email, ...requiredTrimed })}
                errors={errors}
                type="text"
                name="email"
                label={<Trans i18nKey="delivery-point.detail.contact-data.email-einvoice-notification" />}
                autoFocus
                autoFocusDelay={550}
                autoCapitalize="off"
                autoCorrect="off"
            />

            <Button type="submit" block color="primary" size="lg" className="mt-5">
                <Trans i18nKey="delivery-point.detail.modals.request-change" />
            </Button>
            <Button type="reset" block color="secondary" size="lg" className="mt-3">
                <Trans i18nKey="delivery-point.detail.modals.cancel" />
            </Button>
        </form>
    );
};

export default ChangeEInvoiceEmailForm;
