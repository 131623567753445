import { SkSppNzpBeApiCustomeraccessCustomerApprovalBase, SkSppNzpBeApiIdentitymanagementCustomer } from '@spp/spp-meru-frontend-common';
import { IAccountInfo } from '../reducers/user-reducer';

export enum UserActionTypesEnum {
    SET_USER = 'user/SET_USER',
    LOGOUT_USER = 'user/LOGOUT_USER',
    LOGOUT_CUSTOMER = 'user/LOGOUT_CUSTOMER',
    LOGOUT_EMPLOYEE = 'user/LOGOUT_EMPLOYEE',
    SET_NAME = 'user/SET_NAME',
    SET_LOGIN_EMAIL = 'user/SET_LOGIN_EMAIL',
    SET_TELEPHONE_NUMBER = 'user/SET_TELEPHONE_NUMBER',
    USER_SKIPPED_PHONE_VERIFICATION = 'user/USER_SKIPPED_PHONE_VERIFICATION',
    CHANGE_APPROVAL = 'user/CHANGE_APPROVAL',
    SET_CUSTOMER = 'user/SET_CUSTOMER',
}

interface ISetUserAction {
    type: UserActionTypesEnum;
    payload: IAccountInfo;
}
interface ILogoutUserAction {
    type: UserActionTypesEnum;
}
interface ILogoutCustomerAction {
    type: UserActionTypesEnum;
}
interface ILogoutEmployeeAction {
    type: UserActionTypesEnum;
}

interface IUserNameSetAction {
    type: UserActionTypesEnum;
    payload: { name: string; surname?: string };
}

interface IUserLoginEmailSetAction {
    type: UserActionTypesEnum;
    payload: { loginEmail: string };
}

interface IUserTelephoneSetAction {
    type: UserActionTypesEnum;
    payload: { telephoneNumber: string };
}

interface ISkippedPhoneVerificationAction {
    type: UserActionTypesEnum;
    payload: { userSkippedPhoneVerification: boolean };
}

interface IUserApprovalsChangeAction {
    type: UserActionTypesEnum;
    payload: { approval: SkSppNzpBeApiCustomeraccessCustomerApprovalBase };
}

interface ISetCustomerAction {
    type: UserActionTypesEnum;
    payload: SkSppNzpBeApiIdentitymanagementCustomer;
}

export type UserActionType = ISetUserAction &
    ILogoutUserAction &
    ILogoutCustomerAction &
    ILogoutEmployeeAction &
    IUserNameSetAction &
    IUserLoginEmailSetAction &
    IUserTelephoneSetAction &
    ISkippedPhoneVerificationAction &
    IUserApprovalsChangeAction &
    ISetCustomerAction;

export const UserActions = {
    setUser: (user: IAccountInfo): ISetUserAction => ({
        type: UserActionTypesEnum.SET_USER,
        payload: user,
    }),
    logoutUser: (): ILogoutUserAction => ({ type: UserActionTypesEnum.LOGOUT_USER }),
    logoutCustomer: (): ILogoutCustomerAction => ({ type: UserActionTypesEnum.LOGOUT_CUSTOMER }),
    logoutEmployee: (): ILogoutEmployeeAction => ({ type: UserActionTypesEnum.LOGOUT_EMPLOYEE }),
    setName: (name: string, surname?: string): IUserNameSetAction => ({
        type: UserActionTypesEnum.SET_NAME,
        payload: { name: name, surname: surname },
    }),
    setLoginEmail: (loginEmail: string): IUserLoginEmailSetAction => ({
        type: UserActionTypesEnum.SET_LOGIN_EMAIL,
        payload: { loginEmail: loginEmail },
    }),
    setTelephoneNumber: (telephoneNumber: string): IUserTelephoneSetAction => ({
        type: UserActionTypesEnum.SET_TELEPHONE_NUMBER,
        payload: { telephoneNumber: telephoneNumber },
    }),
    userSkippedPhoneVerification: (userSkippedPhoneVerification: boolean): ISkippedPhoneVerificationAction => ({
        type: UserActionTypesEnum.USER_SKIPPED_PHONE_VERIFICATION,
        payload: { userSkippedPhoneVerification: userSkippedPhoneVerification },
    }),
    changeApprovals: (approval: SkSppNzpBeApiCustomeraccessCustomerApprovalBase): IUserApprovalsChangeAction => ({
        type: UserActionTypesEnum.CHANGE_APPROVAL,
        payload: { approval },
    }),
    setCustomer: (customer: SkSppNzpBeApiIdentitymanagementCustomer): ISetCustomerAction => ({
        type: UserActionTypesEnum.SET_CUSTOMER,
        payload: customer,
    }),
};
