import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerrequestCustomerRequest } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, CardHeader, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    BaseTextarea,
    BusinessPartnerTypeEnum,
    Checkbox,
    ClickableElement,
    CodeListTypeEnum,
    CommodityEnum,
    CustomerRequestDataEnum,
    formatDateToPreview,
    HelpModal,
    IApiResponse,
    InvoiceDeliveryStepEnum,
    LoadingIndicator,
    PaymentMethodCodeEnum,
    QueryKeysEnum,
    useApi,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IPaymentMethod } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatBusinessPartnerName } from '../../../../../utils/utils';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

const NOTE_MAX_COUNT = 510;

export const BlockCompletionTranscriptNewCustomer: React.FC = () => {
    const history = useHistory();
    const api = useApi();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [paymentType, setPaymentType] = useState<PaymentMethodCodeEnum>(PaymentMethodCodeEnum.INKASO);
    const { customer } = useSelector((state: IRootState) => state.user);
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const { addToast } = useToasts();

    const { content, businessPartner, customerRequestTemplate, request } = useSelector((state: IRootState) => state.customerRequest);
    const { formRules } = useFormRules();
    const deliveryPoints = [...(content.deliveryPointsZP ?? []), ...(content.deliveryPointsEE ?? [])];

    const { register, handleSubmit, errors, watch } = useForm<{ note: string }>({
        defaultValues: { note: content.note },
    });

    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const [showNote, setShowNote] = useState<boolean>(false);
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const message: string | undefined = watch('note');

    useEffect(() => {
        dispatch(CustomerRequestActions.setData({ note: message }));
        setMessageCounter(message?.length ?? 0);
    }, [message, dispatch]);

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const isContainEE = deliveryPoints.find((dp) => dp.type === CommodityEnum.ELECTRICITY) ? true : false;
    const isContainZP = deliveryPoints.find((dp) => dp.type === CommodityEnum.GAS) ? true : false;

    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const [personalInfoProcessingConcentShown, setPersonalInfoProcessingConcentShown] = useState(false);

    const [isPersonalInfoProcessingOpen, setIsPersonalInfoProcessingOpen] = useState(false);
    const [personalInfoProcessingShown, setPersonalInfoProcessingShown] = useState(false);

    const showPersonalInfoProcessingConcent = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingConcentOpen(true);
    };

    const showPersonalInfoProcessing = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingOpen(true);
    };

    useEffect(() => {
        if (isPersonalInfoProcessingConcentOpen) setPersonalInfoProcessingConcentShown(true);
    }, [isPersonalInfoProcessingConcentOpen, setPersonalInfoProcessingConcentShown]);

    useEffect(() => {
        if (isPersonalInfoProcessingOpen) setPersonalInfoProcessingShown(true);
    }, [isPersonalInfoProcessingOpen, setPersonalInfoProcessingShown]);

    const [mutateUpdateCustomerRequest] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequest | null,
        IApiResponse,
        { customerId: string; requestId: string; data: SkSppNzpBeApiCustomerrequestCustomerRequest }
    >(
        async ({ customerId, requestId, data }) =>
            api.customers.updateCustomerRequest(customerId, requestId, data, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                response?.error?.errors?.forEach((item) => {
                    const error = item.split(':');
                    const field = t(`customer-request:errors:object:${error[0].split(/\./)[0]}-error:${error[0]}`, { keySeparator: ':' });
                    const code = t(`customer-request:errors:code:${error[1]}`, { keySeparator: ':' });
                    addToast(`${field} - ${code}`, {
                        appearance: 'error',
                    });
                    request?.blocks.forEach((item, index) => {
                        const objectKey = error[0].split(/\./)[0];
                        if (
                            (item.dataKey && item.dataKey === objectKey) ||
                            (item.dataKeys && item.dataKeys.includes(objectKey as CustomerRequestDataEnum))
                        ) {
                            return dispatch(CustomerRequestActions.openStep(index));
                        }
                    });
                });
                if (response?.error?.errors) {
                    return true;
                }
                return false;
            },
            onSuccess: (resp) => {
                if (resp?.uuid) {
                    mutateSendCustomerRequest({ requestId: resp.uuid });
                }
            },
        },
    );

    const [mutateSendCustomerRequest] = useMutationWithError<SkSppNzpBeApiCustomerrequestCustomerRequest | null, unknown, { requestId: string }>(
        async ({ requestId }) => api.customerRequests.sendCustomerRequest(requestId, { secure: true }).then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: () => false,
            onSuccess: () => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            },
        },
    );

    const onSubmit = async (data: { note: string; personalInfoProcessingConcent: boolean; personalInfoProcessing: boolean }) => {
        const invoiceDeliveryAddressCountry = countryCodeList?.find((item) => item.code === content.invoiceDelivery?.address?.country?.name);

        const requestData: any = {
            content: {
                metadata: {
                    businessPartnerId: content.originalCustomerBusinessPartner?.id,
                },
                deliveryPointsEe: (content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.map((dp) => {
                    const advancePayment = content.advancePayments?.find((reading) => reading.deliveryPointId === dp.id);
                    const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                    const addressCountry = countryCodeList?.find((item) => item.code === dp.address?.country);
                    const rate: { id?: string } = content.rates?.find((reading) => reading.deliveryPointId === dp.id) || { id: dp.tariffRate?.uuid };
                    if (rate) {
                        return {
                            contractId: dp.contract?.externalId,
                            deliveryPointEe: {
                                deliveryPointNumber: dp.externalId,
                                address: {
                                    ...dp.address,
                                    postalCode: dp.address?.zipCode,
                                    country: addressCountry,
                                    number: dp.address?.streetNumber,
                                },
                                eic: dp.eic,
                            },
                            tariffEe: {
                                tariff: { uuid: rate?.id ?? dp.tariffRate?.uuid },
                            },
                            advancePaymentPeriodEe: {
                                period: {
                                    uuid: advancePayment?.period?.uuid,
                                },
                            },
                            assumedConsumptionEe: {
                                advancePayment: advancePayment?.amount,
                            },
                            meterReadingEe: {
                                //date: new Date(),
                                value: meterReading?.value,
                                valueLow: meterReading?.valueLow,
                                valueHigh: meterReading?.valueHigh,
                                type: meterReading?.valueLow ? 'EE_2T' : 'EE_1T',
                            },
                        };
                    }
                }),
                deliveryPointsZp: (content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[])?.map((dp) => {
                    const tariff = content.tariffs?.find((reading) => reading.deliveryPointId === dp.id);
                    const advancePayment = content.advancePayments?.find((reading) => reading.deliveryPointId === dp.id);
                    const addressCountry = countryCodeList?.find((item) => item.code === dp.address?.country);
                    const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                    if (tariff) {
                        return {
                            contractId: dp.contract?.externalId,
                            deliveryPointZp: {
                                deliveryPointNumber: dp.externalId,
                                address: {
                                    ...dp.address,
                                    postalCode: dp.address?.zipCode,
                                    country: addressCountry,
                                    number: dp.address?.streetNumber,
                                },
                                pod: dp.pod,
                            },
                            tariffZp: {
                                tariff: { uuid: tariff?.id },
                            },
                            advancePaymentPeriodZp: {
                                period: {
                                    uuid: advancePayment?.period?.uuid,
                                },
                            },
                            assumedConsumptionZp: {
                                advancePayment: advancePayment?.amount,
                            },
                            meterReadingZp: {
                                //date: new Date(),
                                value: meterReading?.value,
                                type: 'GAS',
                            },
                        };
                    }
                }),

                paymentMethod: {
                    paymentType: { uuid: content.paymentMethod?.paymentTypeId },
                    iban: content.paymentMethod?.iban,
                    bicSwift: content.paymentMethod?.bicSwift,
                    sipoNumber: content.paymentMethod?.sipoNumber,
                },
                invoiceDelivery: {
                    type: content.invoiceDelivery?.type,
                    address: { ...content.invoiceDelivery?.address, country: invoiceDeliveryAddressCountry },
                    email: content.invoiceDelivery?.email,
                },
                approvals: {
                    personalInfoProcessingConcent: data.personalInfoProcessingConcent ? true : false,
                    personalInfoProcessing: data.personalInfoProcessing ? true : false,
                },
                newBusinessPartner:
                    content.businessPartner ??
                    (content.accountInfo
                        ? { bpNumber: content.accountInfo?.bpNumber, type: content.accountInfo.category }
                        : { bpNumber: businessPartner?.externalId }),
                newBusinessPartnerAddress: businessPartner?.externalId ? undefined : content.address,
                newBusinessPartnerContact: content.accountInfo
                    ? { email: content.accountInfo.email, phoneNumber: content.accountInfo.phoneNumber }
                    : content.contact,
                newBusinessPartnerCorrespondenceAddress: content.correspondenceAddress ?? content.address,
                note: data.note,
                entityReferences: [
                    ...(content.deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[]),
                    ...(content.deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[]),
                ]?.map((dp) => ({
                    contractId: dp.contract?.id,
                    deliveryPointId: dp.id,
                    contractAccountId: dp.contract?.contractAccount?.id,
                })),
                type: customerRequestTemplate?.code,
            },
        };

        setIsLoadingCreateRequest(true);
        await mutateUpdateCustomerRequest({ customerId: customer?.id || '', requestId: content.transcriptRequestId || '', data: requestData });
        setIsLoadingCreateRequest(false);
    };

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    useEffect(() => {
        // payment
        const recastPaymentMethod: (IPaymentMethod & { paymentTypeId?: string }) | undefined = content.paymentMethod;

        if (recastPaymentMethod?.paymentTypeId) {
            setPaymentType(
                (paymentTypeCodeList?.find((item) => item.uuid === recastPaymentMethod?.paymentTypeId)?.code as PaymentMethodCodeEnum) ??
                    PaymentMethodCodeEnum.SIPO,
            );
        }
    }, [paymentTypeCodeList, content]);

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />
            <HelpModal
                show={isPersonalInfoProcessingOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_CONFIRMATION_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingOpen(false)}
            />
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.completion-transcript.transcription-of-dp')}>
                    {deliveryPoints.length > 0 && (
                        <>
                            <ItemPreviewContent bold>
                                {formatAddress(deliveryPoints[0].address)}
                                <br />
                                {isContainZP && t('enums.CommodityEnum.ZP')}
                                {isContainEE && (isContainZP ? `, ${t('enums.CommodityEnum.EE')}` : t('enums.CommodityEnum.EE'))}
                            </ItemPreviewContent>
                        </>
                    )}
                </ItemPreview>

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.GAS)
                    .map((dp) => {
                        const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                        return (
                            <ItemPreview
                                title={`${t('customer-request.steps.completion-transcript.agreed-condition-gas')} - ${t('common.delivery-point')} ${
                                    dp.externalId
                                }`}
                                key={dp.id}
                            >
                                <ItemPreviewContent bold>
                                    {t('customer-request.steps.completion-transcript.confirm')} {meterReading?.value} m<sup>3</sup>
                                </ItemPreviewContent>
                            </ItemPreview>
                        );
                    })}

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.ELECTRICITY)
                    .map((dp) => {
                        const meterReading = content.meterReadings?.find((reading) => reading.deliveryPointId === dp.id);
                        return (
                            <ItemPreview
                                title={`${t('customer-request.steps.completion-transcript.agreed-condition-electricity')} - ${t(
                                    'common.delivery-point',
                                )} ${dp.externalId}`}
                                key={dp.id}
                            >
                                <ItemPreviewContent bold>
                                    {meterReading?.value ? (
                                        <>
                                            {t('customer-request.steps.completion-transcript.confirm')} {meterReading.value} kWh
                                        </>
                                    ) : (
                                        <>
                                            {t('customer-request.steps.completion-transcript.confirm')}
                                            <br />
                                            <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" /> :{' '}
                                            {meterReading?.valueHigh}
                                            {' kwh'} <br />
                                            <Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" /> :{' '}
                                            {meterReading?.valueLow}
                                            {' kwh'}
                                        </>
                                    )}
                                </ItemPreviewContent>
                            </ItemPreview>
                        );
                    })}

                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.completion-transcript.new-customer')}>
                        <ItemPreviewContent>
                            {(content.businessPartner || businessPartner) && (
                                <>
                                    {content.businessPartner?.name || businessPartner?.name
                                        ? t('customer-request.steps.completion-transcript.company-name')
                                        : t('customer-request.steps.completion-transcript.name-and-surname')}
                                    <p>
                                        <b>{formatBusinessPartnerName(businessPartner ?? content.businessPartner)}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.companyRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.company-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.companyRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.taxIdNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.tax-id-number')}
                                    <p>
                                        <b>{content.businessPartner?.taxIdNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.vatRegistrationNumber && (
                                <>
                                    {t('delivery-point.detail.contact-data.vat-registration-number')}
                                    <p>
                                        <b>{content.businessPartner?.vatRegistrationNumber}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.legalForm && (
                                <>
                                    {t('customer-request.steps.businessPartner.legal-form')}
                                    <p>
                                        <b>{content.businessPartner?.legalForm}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.statutoryName && (
                                <>
                                    {t('customer-request.steps.businessPartner.name-statutory-representative')}
                                    <p>
                                        <b>{content.businessPartner?.statutoryName}</b>
                                    </p>
                                </>
                            )}
                            {content.businessPartner?.birthDate && (
                                <>
                                    {t('customer-request.steps.completion-transcript.birth-date')}
                                    <p>
                                        <b>{formatDateToPreview(content.businessPartner.birthDate)}</b>
                                    </p>
                                </>
                            )}
                            {businessPartner?.primaryAddress && (
                                <>
                                    {t('customer-request.steps.completion-transcript.address')}
                                    <p>
                                        <b>
                                            {businessPartner?.primaryAddress?.street || ''} {businessPartner?.primaryAddress?.streetNumber || ''}
                                            <br />
                                            {businessPartner?.primaryAddress?.city || ''} {businessPartner?.primaryAddress?.zipCode || ''}
                                            <br />
                                            {countryCodeList?.find((item) => item.code === businessPartner.primaryAddress?.country)?.name ||
                                                businessPartner?.primaryAddress?.country}
                                        </b>
                                    </p>
                                </>
                            )}
                            {content.address && (
                                <>
                                    {content.businessPartner?.type === BusinessPartnerTypeEnum.COMPANY
                                        ? t('customer-request.steps.completion-transcript.permanent-address-company')
                                        : t('customer-request.steps.completion-transcript.permanent-address')}
                                    <p>
                                        <b>
                                            {content.address?.street || ''} {content.address?.number || ''}
                                            <br />
                                            {content.address?.city || ''} {content.address?.postalCode || ''}
                                            <br />
                                            {countryCodeList?.find((item) => item.uuid === content.address?.country?.uuid)?.name || ''}
                                        </b>
                                    </p>
                                    {content.address?.floor && (
                                        <>
                                            {t('customer-request.steps.address.floor')}
                                            <p>
                                                <b>{content.address?.floor}.</b>
                                            </p>
                                        </>
                                    )}
                                    {content.address?.flatNumber && (
                                        <>
                                            {t('customer-request.steps.address.appartments-number')}
                                            <p>
                                                <b>{content.address?.flatNumber}</b>
                                            </p>
                                        </>
                                    )}
                                </>
                            )}

                            {content.address && !businessPartner?.externalId && (
                                <>
                                    {t('customer-request.steps.completion-transcript.correspondence-address')}
                                    <p>
                                        <b>
                                            {content.correspondenceAddress?.street ?? (content.address?.street || '')}{' '}
                                            {content.correspondenceAddress?.number ?? (content.address?.number || '')}
                                            <br />
                                            {content.correspondenceAddress?.city ?? (content.address?.city || '')}{' '}
                                            {content.correspondenceAddress?.postalCode ?? (content.address?.postalCode || '')}
                                            <br />
                                            {countryCodeList?.find(
                                                (item) =>
                                                    item.uuid === (content.correspondenceAddress?.country?.uuid ?? content.address?.country?.uuid),
                                            )?.name || ''}
                                        </b>
                                    </p>
                                </>
                            )}

                            {content.contact && (
                                <>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        <b>
                                            {`${t('customer-request.steps.contact.phone-number')}: ${content.contact?.phoneNumber}`}
                                            <br />
                                            {`${t('customer-request.steps.contact.email')}: ${content.contact?.email}`}
                                        </b>
                                    </p>
                                </>
                            )}
                            {content.accountInfo && (
                                <>
                                    {t('customer-request.steps.businessPartner.customer-number-spp')}
                                    <p>
                                        <b>{content.accountInfo.bpNumber}</b>
                                    </p>
                                    {t('customer-request.steps.completion-transcript.contact')}
                                    <p>
                                        {t('customer-request.steps.contact.phone-number')}: <b>{content.accountInfo?.phoneNumber}</b>
                                        <br />
                                        {t('customer-request.steps.contact.email')}: <b>{content.accountInfo?.email}</b>
                                    </p>
                                </>
                            )}
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.GAS)
                    .map((dp) => {
                        return (
                            <div className="mt-2" key={`produkt_${dp.id}`}>
                                <ItemPreview title={t('customer-request.steps.completion.product')}>
                                    <ItemPreviewContent bold>
                                        {t('enums.CommodityEnum.ZP')} - {t('common.delivery-point')} {dp.externalId}
                                    </ItemPreviewContent>
                                </ItemPreview>
                                <ItemPreview title={t('delivery-point.detail.payment-data.tariff')}>
                                    <ItemPreviewContent bold>
                                        {t('delivery-point.detail.consumption.tariff')}{' '}
                                        {content.tariffs?.find((item) => item.deliveryPointId === dp.id)?.name}
                                    </ItemPreviewContent>
                                </ItemPreview>
                                <ItemPreview title={t('customer-request.steps.advance-payments.title')}>
                                    <ItemPreviewContent bold>
                                        {content.advancePayments?.find((item) => item.deliveryPointId === dp.id)?.amount} € /{' '}
                                        {content.advancePayments?.find((item) => item.deliveryPointId === dp.id)?.period?.name}
                                    </ItemPreviewContent>
                                </ItemPreview>
                            </div>
                        );
                    })}

                {deliveryPoints
                    .filter((dp) => dp.type === CommodityEnum.ELECTRICITY)
                    .map((dp: SkSppNzpBeApiCustomerprofileDeliveryPoint) => {
                        const rate = content.rates?.find((item) => item.deliveryPointId === dp.id)?.name;
                        return (
                            <div className="mt-2" key={`produkt_${dp.id}`}>
                                <ItemPreview title={`${t('customer-request.steps.completion.product')}`}>
                                    <ItemPreviewContent bold>
                                        {t('enums.CommodityEnum.EE')} - {t('common.delivery-point')} {dp.externalId}
                                    </ItemPreviewContent>
                                </ItemPreview>
                                <ItemPreview title={t('delivery-point.detail.payment-data.rate')}>
                                    <ItemPreviewContent bold>
                                        {t('delivery-point.detail.payment-data.rate')} {rate || dp.tariffRate?.name}{' '}
                                        {!rate && <small>({t('customer-request.steps.completion-transcript.rate-original-customer')})</small>}
                                    </ItemPreviewContent>
                                </ItemPreview>
                                <ItemPreview title={t('customer-request.steps.advance-payments.title')}>
                                    <ItemPreviewContent bold>
                                        {content.advancePayments?.find((item) => item.deliveryPointId === dp.id)?.amount} € /{' '}
                                        {content.advancePayments?.find((item) => item.deliveryPointId === dp.id)?.period?.name}
                                    </ItemPreviewContent>
                                </ItemPreview>
                            </div>
                        );
                    })}

                <div className="mt-2">
                    <ItemPreview title={t('customer-request.steps.block-carbon-footprint-completion.payment-method')}>
                        <ItemPreviewContent bold>
                            <>
                                {paymentType === PaymentMethodCodeEnum.INKASO && t('customer-request.steps.payment-method.inkaso')}
                                {paymentType === PaymentMethodCodeEnum.BANK_TRANSFER && t('customer-request.steps.payment-method.bank-transfer')}
                                {paymentType === PaymentMethodCodeEnum.SIPO && t('customer-request.steps.payment-method.sipo')}
                                <br />

                                {content.paymentMethod?.iban && (
                                    <>
                                        {t('customer-request.steps.block-carbon-footprint-completion.iban-number')}
                                        {content.paymentMethod?.iban}
                                        <p className="mb-0">{content.paymentMethod?.bicSwift && ' BIC/SWIFT: ' + content.paymentMethod?.bicSwift}</p>
                                    </>
                                )}
                                {paymentType === PaymentMethodCodeEnum.SIPO && (
                                    <>
                                        {content.paymentMethod?.sipoNumber ? (
                                            <>
                                                {t('customer-request.steps.payment-method.sipo-number')}: {content.paymentMethod?.sipoNumber}
                                            </>
                                        ) : (
                                            <Trans i18nKey="delivery-point.detail.contact-data.no-sipo-number" />
                                        )}
                                    </>
                                )}
                            </>
                        </ItemPreviewContent>
                    </ItemPreview>
                </div>
                <ItemPreview title={t('customer-request.steps.completion-transcript.invoice-delivery')}>
                    <ItemPreviewContent bold>
                        {content.invoiceDelivery?.type === InvoiceDeliveryStepEnum.ADDRESS ? (
                            <span>
                                <p>{t('customer-request.steps.invoice-delivery.via-post')}:</p>
                                {content.invoiceDelivery?.address?.name || ''} <br />
                                {content.invoiceDelivery?.address?.street || ''} {content.invoiceDelivery?.address?.number || ''}
                                <br />
                                {content.invoiceDelivery?.address?.city || ''} {content.invoiceDelivery?.address?.postalCode || ''}
                                <br />
                                {content.invoiceDelivery?.address?.country?.name}
                            </span>
                        ) : (
                            <span>
                                {t('customer-request.steps.invoice-delivery.via-email')}: {content.invoiceDelivery?.email}
                            </span>
                        )}
                    </ItemPreviewContent>
                </ItemPreview>
            </ListGroup>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                    <Checkbox
                        ref={register({ ...formRules.required })}
                        errors={errors}
                        className="mb-1"
                        id="personalInfoProcessingConcent"
                        name="personalInfoProcessingConcent"
                        label={
                            <Trans
                                i18nKey="customer-request.steps.terms-condition.personal-info-processing-consent"
                                components={{
                                    Link: (
                                        <ClickableElement
                                            isText
                                            onClick={showPersonalInfoProcessingConcent}
                                            className="text-decoration-underline-inverse"
                                        >
                                            &nbsp
                                        </ClickableElement>
                                    ),
                                }}
                            />
                        }
                        onChange={() => {
                            if (!personalInfoProcessingConcentShown) setIsPersonalInfoProcessingConcentOpen(true);
                        }}
                    />
                </CardHeader>
                <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                    <Checkbox
                        ref={register({ ...formRules.required })}
                        errors={errors}
                        className="mb-1"
                        id="personalInfoProcessing"
                        name="personalInfoProcessing"
                        label={
                            <Trans
                                i18nKey="customer-request.steps.terms-condition.personal-info-processing"
                                components={{
                                    Link: (
                                        <ClickableElement isText onClick={showPersonalInfoProcessing} className="text-decoration-underline-inverse">
                                            &nbsp
                                        </ClickableElement>
                                    ),
                                }}
                            />
                        }
                        onChange={() => {
                            if (!personalInfoProcessingShown) setIsPersonalInfoProcessingOpen(true);
                        }}
                    />
                </CardHeader>
                <div className="my-2">
                    <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                        <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                    </ClickableElement>
                </div>
                {(showNote || messageCounter > 0) && (
                    <BaseTextarea
                        ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                        errors={errors}
                        name={'note'}
                        maxLength={NOTE_MAX_COUNT}
                        label={t('customer-request.steps.attachments.title')}
                        counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                        optional
                    />
                )}
                <Button type="submit" size="lg" color="primary" block className="mt-4">
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>
            </form>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
