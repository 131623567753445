import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, FormGroup } from 'reactstrap';
import { DefaultFormType, Input, useFormRules } from '../../../../main';

type IShareDeliveryPointFormProps = DefaultFormType;

export const BulkShareDeliveryPointFormFields: React.FC<IShareDeliveryPointFormProps> = ({ register, errors, trigger }) => {
    const { requiredTrimed, formRules } = useFormRules();

    const [t] = useTranslation();

    return (
        <>
            <FormGroup>
                <Input
                    trigger={trigger}
                    ref={register({ ...requiredTrimed, ...formRules.email })}
                    errors={errors}
                    type="text"
                    name="email"
                    label={t('delivery-point.detail.actions.share-delivery-point.insert-email')}
                    autoFocus
                    autoCapitalize="off"
                    autoCorrect="off"
                />
            </FormGroup>

            <div className="d-flex">
                <Button type="submit" color="secondary" size="lg" className="mx-auto">
                    <Trans i18nKey="delivery-point.share-delivery-points.add-email-to-list" />
                </Button>
            </div>
        </>
    );
};
