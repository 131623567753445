import { ErrorMessage } from '@hookform/error-message';
import { nextTick } from 'process';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import HelpText from '../../../../../components/help/help-text';
import useCodeList from '../../../../../hooks/use-code-list';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';

const BlockCarbonFootprintProductLevel: React.FC = () => {
    const dispatch = useDispatch();
    const { currentBlock, content, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);
    // const [totalPrice, setTotalPrice] = useState(0);
    const [finalList, setFinalList] = useState(content.productLevel?.zpLevel);
    const [customError, setCustomError] = useState();

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.PRODUCT_LEVEL;

    const { handleSubmit } = useForm();

    const { t } = useTranslation();

    const { data: zpLevelCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CARBON_STOP_LEVEL,
        codeListTypeEnum: CodeListTypeEnum.CARBON_STOP_LEVEL,
        paging: {
            size: 30,
        },
        status: ['ACTIVE'],
    });

    const onSubmit = () => {
        setCustomError(undefined);
        if (!finalList) return setCustomError(t('common.input-rules.required'));

        const payload: CustomerRequestPayloadType = {
            [objectName]: { zpLevel: finalList },
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <>
            <div className="my-2">
                <HelpText screen="NZT" field="NZT_ZOP_US" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {zpLevelCodeList && (
                    <>
                        {zpLevelCodeList?.map((item, index) => {
                            const label = () => (
                                <div style={{ marginLeft: '2rem' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{item.name}</span>
                                        <div style={{ float: 'right', fontSize: '1rem' }}>
                                            {Number(item.attributes?.price).toFixed(2)} € /{' '}
                                            {t('customer-request.steps.block-carbon-footprint-product-level.month')}{' '}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="mt-4" style={{ color: '#4a4a4a' }}>
                                            <HelpText screen="NZT" field={`NZT_${customerRequestTemplate?.code}_${item.code}`} />
                                        </div>
                                    </div>
                                </div>
                            );
                            return (
                                <CardHeader key={index} className="border-bottom-0 rounded w-100 mb-3" style={{ backgroundColor: 'white' }}>
                                    <BaseRadio
                                        className="mb-0"
                                        id={item.uuid || 'zp'}
                                        name={'zpList.' + item.uuid}
                                        label={label()}
                                        onClick={() => {
                                            // setTotalPrice(item.attributes?.price ? Number(item.attributes.price) : 0);
                                            setFinalList(item);
                                        }}
                                        onChange={() => {
                                            // setTotalPrice(item.attributes?.price ? Number(item.attributes.price) : 0);
                                            setFinalList(item);
                                        }}
                                        checked={finalList?.uuid === item.uuid}
                                    />
                                </CardHeader>
                            );
                        })}
                    </>
                )}

                {customError && (
                    <div style={{ color: 'red' }} className="mt-2">
                        <ErrorMessage errors={customError} name={'ee'} />
                    </div>
                )}

                {/* <p className="mt-4 mb-4" style={{ textAlign: 'center' }}>
                    {t('customer-request.steps.block-carbon-footprint-product-level.total-price')}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                        {totalPrice.toFixed(2)} € / {t('customer-request.steps.block-carbon-footprint-product-level.month')}
                    </span>
                </p> */}

                <BaseButton type="submit" disabled={!finalList}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockCarbonFootprintProductLevel;
