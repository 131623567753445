import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { ButtonGroup } from 'reactstrap';
import { DeliveryPointSwitchButton } from './delivery-point-switch-button';
import { DeliveryPointSwitchSelect } from './select/delivery-point-switch-select';

interface IDeliveryPointSwitchProps {
    deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    selectedDeliveryPointId: string | undefined;
    onSelect: (dpId: string | undefined) => void;
    showInactive: boolean;
    toggleInactive: (() => void) | undefined;
    additionalButton?: { label: React.ReactNode };
}

export const DeliveryPointSwitch: React.FC<IDeliveryPointSwitchProps> = ({
    deliveryPoints,
    selectedDeliveryPointId,
    onSelect,
    showInactive,
    toggleInactive,
    additionalButton,
}) => {
    const selectedDeliveryPoint = useMemo<SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined>(() => {
        if (deliveryPoints.length === 0) {
            return undefined;
        }
        if (selectedDeliveryPointId == null) {
            if (additionalButton != null) {
                return undefined;
            }
            return deliveryPoints[0];
        }
        const foundDp = deliveryPoints.find((x) => x.id === selectedDeliveryPointId);
        if (foundDp != null) {
            return foundDp;
        }
        return undefined;
    }, [selectedDeliveryPointId, deliveryPoints, additionalButton]);

    const filteredDeliveryPoints = useMemo(() => {
        if (showInactive) {
            return deliveryPoints;
        }
        return deliveryPoints.filter((x) => x.status === 'ACTIVE');
    }, [deliveryPoints, showInactive]);

    const hasMultipleElectricityDps = useMemo(() => {
        const electricityCount = filteredDeliveryPoints.filter((x) => x.type === 'EE').length;
        return electricityCount > 1;
    }, [filteredDeliveryPoints]);

    const hasMultipleGasDps = useMemo(() => {
        const gasCount = filteredDeliveryPoints.filter((x) => x.type === 'ZP').length;
        return gasCount > 1;
    }, [filteredDeliveryPoints]);

    return (
        <div className="delivery-point-switch">
            <div className="d-block d-md-none">
                {filteredDeliveryPoints.length > 1 && (
                    <DeliveryPointSwitchSelect
                        selectedDeliveryPoint={selectedDeliveryPoint}
                        deliveryPoints={filteredDeliveryPoints}
                        onSelect={onSelect}
                        showInactive={showInactive}
                        toggleInactive={toggleInactive}
                        additionalButton={additionalButton}
                    />
                )}
            </div>

            <div className="d-none d-md-block">
                {filteredDeliveryPoints.length <= 1 && <> </>}

                {filteredDeliveryPoints.length > 1 && filteredDeliveryPoints.length <= 3 && (
                    <ButtonGroup style={{ background: 'none' }}>
                        {additionalButton != null && (
                            <>
                                <button
                                    onClick={() => onSelect(undefined)}
                                    className={classNames('btn text-left', 'delivery-point-switch-button', {
                                        active: selectedDeliveryPointId == null,
                                    })}
                                >
                                    <div>{additionalButton.label}</div>
                                </button>
                            </>
                        )}
                        {filteredDeliveryPoints.map((dp) => (
                            <DeliveryPointSwitchButton
                                key={dp.id}
                                deliveryPoint={dp}
                                isSelected={selectedDeliveryPointId === dp.id}
                                onClick={onSelect}
                                showInvoiceNumber={dp.type === 'ZP' ? hasMultipleGasDps : hasMultipleElectricityDps}
                            />
                        ))}
                    </ButtonGroup>
                )}

                {filteredDeliveryPoints.length > 3 && (
                    <>
                        <DeliveryPointSwitchSelect
                            selectedDeliveryPoint={selectedDeliveryPoint}
                            deliveryPoints={filteredDeliveryPoints}
                            onSelect={onSelect}
                            showInactive={showInactive}
                            toggleInactive={toggleInactive}
                            additionalButton={additionalButton}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
