import { SkSppNzpBeApiCustomeraccessRegistration } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { IRootState } from '../../../../../reducers';
import RegistrationForm from '../../../../registration/screen/components/registration-form';

export type RegistrationFormDataType = {
    email: string;
    'new-password': string;
    firstName: string;
    lastName: string;
    termsAndConditions: boolean;
    privacyPolicy: boolean;
};

const BlockInlineRegistration: React.FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const { content } = useSelector((state: IRootState) => state.customerRequest);

    const onFormSubmit = (formData: RegistrationFormDataType) => {
        const newCustomer: SkSppNzpBeApiCustomeraccessRegistration = {
            email: formData.email,
            password: formData['new-password'],
            firstName: formData.firstName,
            lastName: formData.lastName,
            approvals: [
                { type: 'CONTRACT_CONDITIONS', approval: formData.termsAndConditions },
                { type: 'PROTECTION_PERSONAL_DATA', approval: formData.privacyPolicy },
            ],
            jwt: '',
        };
        dispatch(CustomerRequestActions.setData({ registration: newCustomer }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <>
            <div className="card p-5">
                <h3 className="text-center">{t('customer-request.steps.inline-registration.registration')}</h3>
                <hr className="my-4" />
                <RegistrationForm
                    onFormSubmit={onFormSubmit}
                    email={content.registration?.email || content.accountInfo?.email || content.contact?.email}
                    firstName={content.registration?.firstName || content.businessPartner?.firstName}
                    lastName={content.registration?.lastName || content.businessPartner?.lastName}
                    privacyPolicy={!!content.registration?.approvals?.find((appproval) => appproval.type === 'PROTECTION_PERSONAL_DATA')?.approval}
                    termsAndConditions={!!content.registration?.approvals?.find((appproval) => appproval.type === 'CONTRACT_CONDITIONS')?.approval}
                    hideRecaptcha
                    hidePassword={false}
                    hideSocialLoginBtns
                />
                <div className="text-center">{t('customer-request.steps.inline-registration.after-registration-proceed-to-summary')}</div>
            </div>
            <div className="mt-4 text-center">
                <ClickableElement
                    isText
                    onClick={() => {
                        dispatch(CustomerRequestActions.setData({ registration: undefined }));
                        nextTick(() => {
                            dispatch(CustomerRequestActions.nextStep());
                        });
                    }}
                    className="text-decoration-underline-inverse"
                >
                    <Trans i18nKey="customer-request.steps.inline-registration.not-interested-in-account" />
                </ClickableElement>
            </div>
        </>
    );
};

export default BlockInlineRegistration;
