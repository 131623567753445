import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import BaseRadio from '../../../../components/common/base-radio';
import Input from '../../../../components/common/input';
import { useFormRules } from '../../../../hooks/use-form-rules';
import { formatBusinessPartnerName } from '../../../../main';
import { IBusinessPartnerDeliveryPointData } from '../../../../models/model';
import { IRootState } from '../../../../reducers';
import { getEInvoiceEmailsInUDPSummaries } from '../utils/einvoice-utils';

interface IBulkActivateSelectEmailProps {
    onSelect: (email: string) => void;
    businessPartnerData: IBusinessPartnerDeliveryPointData;
}

const BulkActivateSelectEmail: React.FC<IBulkActivateSelectEmailProps> = ({ onSelect, businessPartnerData }) => {
    const { register, handleSubmit, errors, trigger } = useForm<{ email: string; customEmail: string }>();

    const portalAccountEmail = useSelector((state: IRootState) => state.user.customer?.email);

    const onFormSubmit = handleSubmit((fields) => {
        onSelect(fields.customEmail || fields.email);
    });

    const { formRules, requiredTrimed, sppDomain } = useFormRules();

    const [isCustom, setIsCustom] = useState(false);
    const { t } = useTranslation();

    const additionalEmails = useMemo(() => {
        return getEInvoiceEmailsInUDPSummaries(businessPartnerData.unitedDeliveryPoints).filter((email) => email !== portalAccountEmail);
    }, [businessPartnerData, portalAccountEmail]);

    return (
        <>
            <p className="mb-4">{`${formatBusinessPartnerName(businessPartnerData.businessPartner)} (${
                businessPartnerData.businessPartner.externalId
            }) — ${t('settings.e-invoices.delivery-points-count', {
                count: businessPartnerData.unitedDeliveryPoints.length,
            })}`}</p>
            <hr />
            <h4 className="mb-2">
                <Trans i18nKey="settings.e-invoices.select-email-for-einvoice" />
            </h4>
            <p className="mt-0 mb-4">
                <Trans i18nKey="settings.e-invoices.some-delivery-points-do-not-have-assigned-email" />
            </p>
            <form onSubmit={onFormSubmit} noValidate>
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`accountEmailRadio`}
                    name="email"
                    value={portalAccountEmail}
                    label={portalAccountEmail || ''}
                    className="mb-3"
                    onChange={() => setIsCustom(false)}
                />
                {additionalEmails.map((email, index) => (
                    <BaseRadio
                        key={index}
                        ref={register(formRules.required)}
                        errors={errors}
                        id={`emailRadio${index}`}
                        name="email"
                        value={email}
                        label={email}
                        className="mb-3"
                        onChange={() => setIsCustom(false)}
                    />
                ))}
                <BaseRadio
                    ref={register(formRules.required)}
                    errors={errors}
                    id={`enterCustomEmailRadio`}
                    name="email"
                    value={undefined}
                    label={<Trans i18nKey="settings.e-invoices.select-different-email" />}
                    className="mb-3"
                    onChange={() => setIsCustom(true)}
                />
                {isCustom && (
                    <Input
                        ref={register({ ...requiredTrimed, ...sppDomain })}
                        trigger={trigger}
                        errors={errors}
                        type="text"
                        name="customEmail"
                        label={<Trans i18nKey="common.email" />}
                        autoCapitalize="off"
                        autoCorrect="off"
                    />
                )}

                <Button type="submit" color="primary" size="lg" block className="mt-5">
                    <Trans i18nKey="settings.e-invoices.activate-einvoice-in-bulk" />
                </Button>
                <p className="text-center text-secondary mt-3 mx-2">
                    <Trans i18nKey="settings.e-invoices.activate-einvoice-for-all-delivery-points-by-pressing-button" />
                </p>
            </form>
        </>
    );
};

export default BulkActivateSelectEmail;
