import React from 'react';
import { ClickableElement } from '../../../components/common/clickable-element';

type DeliveryPointBreadcrumbProps = {
    goBack: () => void;
};

const DeliveryPointBreadcrumb: React.FC<DeliveryPointBreadcrumbProps> = ({ goBack }) => {
    return (
        <nav aria-label="breadcrumb" className="mb-1 color-danger">
            <ClickableElement onClick={goBack}>
                <i className="icon-arrow-left-16 fa-lg mr-3 text-muted"></i>
            </ClickableElement>
        </nav>
    );
};

export default DeliveryPointBreadcrumb;
