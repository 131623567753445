import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
} from '@spp/spp-meru-frontend-common';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { buildCustomerRequestsSearchQueryKey, createIndexedGroups, IRootState, useApi } from '../../../../main';

export interface IUdpToCustomerRequestMapping {
    udpId: string | undefined;
    data: SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | undefined;
}

type UdpToCustomerRequestResult = { isFetching: boolean; data: IUdpToCustomerRequestMapping[] | undefined };

type RequestsIndexedByCA = { contractAccountId?: string; request: SkSppNzpBeApiCustomerrequestCustomerRequestSummary };

export const useFetchMultipleCustomerRequests = (
    unitedDeliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | undefined,
): UdpToCustomerRequestResult => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();

    const customerRequestsSearchObject: SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery = useMemo(
        () => ({
            unitedDeliveryPointUuids: unitedDeliveryPoints?.map((udp) => udp.id || ''),
            statuses: ['CREATED', 'REGISTERED', 'SAP_OPEN', 'SAP_IN_PROGRESS'],
            codes: ['ZZU_ADEF'],
            paging: { page: 0, size: 5000 },
        }),
        [unitedDeliveryPoints],
    );

    const { isFetching: isFetchingCustomerRequests, data: customerRequestsData } = useQueryWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | undefined
    >(
        buildCustomerRequestsSearchQueryKey(loggedInCustomer?.id, customerRequestsSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? undefined
                : api.customers
                      .customerSearchCustomerRequests(
                          loggedInCustomer.id,
                          customerRequestsSearchObject,
                          { APPEND_NOT_SYNCED: true },
                          { secure: true },
                      )
                      .then((res) => res.data?.result),
        {
            enabled: unitedDeliveryPoints != null,
        },
    );

    // udpId => RequestsIndexedByCA
    const requestsIndexedByCA = useMemo(() => {
        const result: RequestsIndexedByCA[] = [];
        customerRequestsData?.map((request) => {
            request.customerRequestContracts?.forEach((contract) => {
                result.push({ contractAccountId: contract.contractAccount?.id, request: request });
            });
        });
        return createIndexedGroups<RequestsIndexedByCA>(result, (x) => x.contractAccountId);
    }, [customerRequestsData]);

    // pair contract account to UDP
    const udpToCustomerRequestMappings = useMemo<IUdpToCustomerRequestMapping[] | undefined>(() => {
        if (unitedDeliveryPoints == null || requestsIndexedByCA == null) {
            return undefined;
        }
        const udpMap: IUdpToCustomerRequestMapping[] = [];
        unitedDeliveryPoints.forEach((udp) => {
            const udpId = udp.id;
            const dps = udp.deliveryPoints;
            if (udpId == null || dps == null) {
                return;
            }

            const activeRequestsForUdp = dps
                .map((dp) => {
                    const dpCaId = dp.einvoice?.contractAccountId;
                    const activeRequestsForCA = dpCaId != null ? requestsIndexedByCA[dpCaId]?.[0]?.request : [];
                    return activeRequestsForCA || [];
                })
                .flat();

            const udpToCustomerRequestMapping: IUdpToCustomerRequestMapping = { udpId: udpId, data: activeRequestsForUdp };
            udpMap.push(udpToCustomerRequestMapping);
        });
        return udpMap;
    }, [unitedDeliveryPoints, requestsIndexedByCA]);

    return { isFetching: isFetchingCustomerRequests, data: udpToCustomerRequestMappings };
};
