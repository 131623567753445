import { ErrorMessage } from '@hookform/error-message';
import { SkSppNzpBeApiCustomerprofileBusinessPartner } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from 'reactstrap/lib/CardHeader';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import useCodeList from '../../../../../hooks/use-code-list';
import { ClickableElement, KINDcodeHome } from '../../../../../main';
import { IContractTerminationReason } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { useOpenRequestFromDeliveryPointDetail } from '../../../../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';
import { CodeListTypeEnum, CustomerRequestDataEnum, CustomerRequestEnum } from '../../../config/enums';
import { requestTerminationContractShortWithDateBlock, requestTerminationContractShortWithUploadFileBlock } from '../../../config/request-config';
import ContractTerminationReasonFormFieldsShort from './components/contract-termination-reason-form-fields-short';

const BlockContractTerminationReasonShort: React.FC = () => {
    const SUPPORTED_REASONS = useMemo(
        () => ['REAL_ESTATE_SALE', 'DEMOLITION_WORKS', 'LEASE_TERMINATION', 'SUPPLIER_CHANGE', 'FUEL_CHANGE', 'WITHOUT_GIVING_REASON'],
        [],
    );
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const { currentBlock, content, additionalData } = useSelector((state: IRootState) => state.customerRequest);
    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeRequestCreationProcess(requestName, {}, {});
    };

    const bp: SkSppNzpBeApiCustomerprofileBusinessPartner = additionalData?.udpd?.businessPartner || {};
    const isHome = bp?.kind?.code === KINDcodeHome;
    // if businessPartner is not home add company termination as one of supported reasons
    if (!isHome) {
        SUPPORTED_REASONS.push('COMPANY_TERMINATION');
    }
    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.CONTRACT_TERMINATION_REASON;
    const objectData = content[objectName] as IContractTerminationReason;

    const { register, handleSubmit, errors, watch, trigger, setError } = useForm<IContractTerminationReason>({ defaultValues: objectData });
    const [displayNewOwnerCard, setDisplayNewOwnerCard] = useState<boolean>(false);

    const { data: contractTerminationReasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CONTRACT_TERMINATION_REASON,
        codeListTypeEnum: CodeListTypeEnum.CONTRACT_TERMINATION_REASON,
        paging: {
            size: 64,
        },
    });

    const terminationCodeList = useMemo(() => {
        const terminationList = contractTerminationReasonCodeList?.filter((item) => {
            return SUPPORTED_REASONS.includes(item?.code ?? '');
        });
        let customerOrderedTerminationList = terminationList && [...terminationList];
        terminationList?.forEach((item) => {
            if (item.code === 'SUPPLIER_CHANGE') {
                customerOrderedTerminationList?.splice(0, 1, item);
            }
            if (item.code === 'FUEL_CHANGE') {
                customerOrderedTerminationList?.splice(1, 1, item);
            }
            if (item.code === 'REAL_ESTATE_SALE') {
                customerOrderedTerminationList?.splice(2, 1, item);
            }
            if (item.code === 'LEASE_TERMINATION') {
                customerOrderedTerminationList?.splice(3, 1, item);
            }
            if (item.code === 'COMPANY_TERMINATION') {
                customerOrderedTerminationList?.splice(4, 1, item);
            }
            if (item.code === 'DEMOLITION_WORKS') {
                customerOrderedTerminationList?.splice(isHome ? 4 : 5, 1, item);
            }
            if (item.code === 'WITHOUT_GIVING_REASON') {
                customerOrderedTerminationList?.splice(isHome ? 5 : 6, 1, item);
            }
        });
        if (content.deliveryPointsZP?.length === 0) {
            customerOrderedTerminationList = customerOrderedTerminationList?.filter((item) => item.code !== 'FUEL_CHANGE');
        }
        return customerOrderedTerminationList;
    }, [contractTerminationReasonCodeList, isHome, SUPPORTED_REASONS, content.deliveryPointsZP?.length]);

    const onSubmit = (data: IContractTerminationReason) => {
        const selectedReason = terminationCodeList?.find((item) => item.uuid === data.reason.uuid);
        if (!selectedReason) {
            setError('reason.uuid', {
                message: t('common.input-rules.required'),
            });
        }
        const payload: CustomerRequestPayloadType = {
            [objectName]: { reason: selectedReason },
        };

        dispatch(CustomerRequestActions.setData(payload));

        //replace blocks
        if (selectedReason && selectedReason.code === 'DEMOLITION_WORKS') {
            dispatch(CustomerRequestActions.setData({ contractTerminationDate: undefined }));
            dispatch(CustomerRequestActions.replaceBlocks(requestTerminationContractShortWithUploadFileBlock));
        } else {
            dispatch(CustomerRequestActions.replaceBlocks(requestTerminationContractShortWithDateBlock));
        }

        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const handleSet = (value: boolean) => {
        setDisplayNewOwnerCard(value);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <ContractTerminationReasonFormFieldsShort
                    codeList={terminationCodeList ?? []}
                    register={register}
                    watch={watch}
                    trigger={trigger}
                    setDisplayNewOwnerCard={handleSet}
                />
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name="reason.uuid" />
                    </div>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
            {displayNewOwnerCard && (
                <div>
                    <CardHeader className="border-bottom-0 rounded w-100 mt-3 " style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <h6>
                            <Trans i18nKey="customer-request.steps.termination-reason.new-owner" />
                        </h6>
                        <p>
                            <Trans i18nKey="customer-request.steps.termination-reason.new-owner-description" />
                        </p>
                        <p>
                            <ClickableElement
                                isText
                                onClick={() => {
                                    dispatch(CustomerRequestActions.closeRequest());
                                    nextTick(() => {
                                        startRequestInRequestsTab(CustomerRequestEnum.TRANSCRIPT_NEW);
                                    });
                                }}
                                className="text-decoration-underline-inverse"
                            >
                                <Trans i18nKey="customer-request.steps.termination-reason.new-owner-link" />
                            </ClickableElement>
                        </p>
                    </CardHeader>
                </div>
            )}
        </>
    );
};

export default BlockContractTerminationReasonShort;
