import React from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'reactstrap';
import { ClickableElement } from '../../../main';
import DeliveryPointBreadcrumb from './add-delivery-point-breadcrumb';

type ChoosePersonOrCompanyScreenProps = {
    onlyLoad?: boolean;
    proceedWithPerson: () => void;
    proceedWithCompany: () => void;
    goBack: () => void;
};

const ChoosePersonOrCompanyScreen: React.FC<ChoosePersonOrCompanyScreenProps> = (props) => {
    return (
        <>
            {!props.onlyLoad && <DeliveryPointBreadcrumb goBack={props.goBack} />}

            <h4 className="text-center">
                <Trans i18nKey="common.choose-the-option">Zvoľte možnosť</Trans>:
            </h4>

            <ClickableElement onClick={() => props.proceedWithPerson()}>
                <Card className="card-hoverable w-100 align-items-center mb-3">
                    <div className="card-body p-4 w-100">
                        <h4 className="text-center mb-0">
                            <i className="icon-user fa-lg d-block mb-3" style={{ color: '#FFD500' }} />
                            <Trans i18nKey="delivery-point.add-delivery-point.person-or-company.person">Som osoba</Trans>
                        </h4>
                    </div>
                </Card>
            </ClickableElement>

            <ClickableElement onClick={() => props.proceedWithCompany()}>
                <Card className="card-hoverable w-100 align-items-center">
                    <div className="card-body p-4 w-100 ">
                        <h4 className="text-center mb-0">
                            <i className="icon-briefcase fas fa-briefcase fa-lg d-block mb-3" style={{ color: '#FFD500' }} />
                            <Trans i18nKey="delivery-point.add-delivery-point.person-or-company.company">Som firma / živnostník</Trans>
                        </h4>
                    </div>
                </Card>
            </ClickableElement>
        </>
    );
};

export default ChoosePersonOrCompanyScreen;
