import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const AutoLogoutConfirmation: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="row text-center py-5">
                <div className="col-12 position-static">
                    <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                    <h3 className="my-3 text-success">
                        <Trans i18nKey="confirm-logout-auto.success" />
                    </h3>
                    <div className="text-center mt-4">
                        <Trans i18nKey="confirm-logout.description">{{ login: t('action.login') }}</Trans>
                        <br />
                        <br />
                        <Trans i18nKey="confirm-logout.thank-you" />
                    </div>
                </div>
            </div>
        </div>
    );
};
