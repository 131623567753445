import { faExclamationTriangle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerrequestCustomerRequestTemplate as CustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { Checkbox } from '../../../../components/common';
import BaseButton from '../../../../components/common/base-button';
import HelpText from '../../../../components/help/help-text';
import { useApi } from '../../../../hooks/use-api';
import { IRootState, PRICE_LINK_EE, PRICE_LINK_ZP } from '../../../../main';
import { CustomerRequestRoutes } from '../../../../routes/routes';
import { CustomerRequestActions } from './../../../../actions/customer-request-actions';
import LoadingIndicator from './../../../../components/common/loading-indicator';
import { useFormRules } from './../../../../hooks/use-form-rules';
import PrepareHasAccount from './components/prepare-has-account';
import PrepareHeader from './components/prepare-header';

type FormType = {
    agreements: boolean;
};

const PrepareCustomerRequest: React.FC = () => {
    const history = useHistory();
    const api = useApi();
    const dispatch = useDispatch();
    const { key, code } = useSelector((state: IRootState) => state.customerRequest);

    const { formRules } = useFormRules();
    const { t } = useTranslation();

    const { register, handleSubmit, errors } = useForm<FormType>();

    const fetchCustomerRequestTemplate = useCallback(
        (code) => async () =>
            await api.customerRequestTemplates
                .searchTemplates({ codes: [code] }, { secure: true })
                .then((res) => res.data?.result && res.data?.result[0]),
        [api.customerRequestTemplates],
    );

    const { data: customerRequestTemplate, isLoading } = useQuery<CustomerRequestTemplate>({
        queryKey: ['customer-request-tempaltes', [code]],
        queryFn: code && fetchCustomerRequestTemplate(code),
    });

    const showAttention = useMemo(() => {
        return !!(customerRequestTemplate?.priceCollective || customerRequestTemplate?.priceIndividual);
    }, [customerRequestTemplate]);

    useEffect(() => {
        if (!code) {
            history.replace(CustomerRequestRoutes.SELECT_NEW);
        }
    }, [code, history]);

    useEffect(() => {
        if (customerRequestTemplate) {
            dispatch(CustomerRequestActions.setCustomerRequestTemplate(customerRequestTemplate));
        }
    }, [customerRequestTemplate, dispatch]);

    const checkRequest = () => {
        nextTick(() => {
            openStepManager();
        });
    };

    const openStepManager = () => {
        history.push(`${CustomerRequestRoutes.NEW_REQUEST}/${key}`);
    };

    return (
        <>
            {isLoading && <LoadingIndicator fullscreen />}

            <PrepareHeader code={code} goBack={history.goBack} />

            <Container>
                <Row className="flex-column-reverse flex-lg-row">
                    <div className="col-12 col-lg-6 text-center text-lg-left mt-5">
                        {code && <HelpText screen="NZT" field={`NZT_${code}`} />}
                        {showAttention && (
                            <>
                                <p className="font-weight-bold mb-0">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                    <Trans i18nKey="customer-request.prepare.attention-text" />
                                </p>
                                <a className="btn btn-secondary d-block  mt-4" href={PRICE_LINK_EE} target="_blank" rel="noopener noreferrer">
                                    {t('customer-request.prepare.external-price-link-ee')}&nbsp;
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <a className="btn btn-secondary d-block  mt-4" href={PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                                    {t('customer-request.prepare.external-price-link-zp')}&nbsp;
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <Checkbox
                                    ref={register(formRules.required)}
                                    errors={errors}
                                    className="mt-3 mb-4"
                                    id="attention"
                                    name="attention"
                                    label={<Trans i18nKey="customer-request.prepare.attention-approvements" />}
                                />
                            </>
                        )}

                        <BaseButton onClick={handleSubmit(checkRequest)} className="btn-primary btn-lg mt-3 mb-5">
                            <Trans i18nKey="customer-request.prepare.continue"> Pokračovať</Trans>
                        </BaseButton>
                    </div>

                    <PrepareHasAccount />
                </Row>
            </Container>
        </>
    );
};

export default PrepareCustomerRequest;
