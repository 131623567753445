import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import { useWindowSize } from '../../../hooks/use-window-size';
import { PRE_REGISTRATION, useKeyValueStore } from '../../../main';
import { IRootState } from '../../../reducers';
import { customerRequestType } from '../../../utils/utils';
import Brand from '../components/brand';
import HamburgerNavButton from '../components/hamburger-nav-button';
import NoInternetConnection from '../components/no-internet-connection';
import ProfileMenu from '../components/profile-menu';
import RedirectLinks from '../components/redirect-links';
import Notifications from '../notifications/notifications';
import LoginButton from './login-button';
import NavLinksLogged from './nav-links-logged';
import UserLinks from './user-links';

type NavbarCustomerPropsType = {
    isLogged: boolean;
    user: Record<string, any>;
    logout: () => void;
    goHome: () => void;
    noInternetConnection?: boolean;
};

const NavbarCustomer: React.FC<NavbarCustomerPropsType> = ({ isLogged, user, logout, goHome, noInternetConnection }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isClosedMenu, setClosedMenu] = useState(true);
    const customerRequestKey = useSelector((s: IRootState) => s.customerRequest.key);
    const windowSize = useWindowSize();
    const [isPreRegistrationPage] = useKeyValueStore(PRE_REGISTRATION, false);

    const toggleMenu = () => {
        setCollapsed((o) => !o);
    };

    const closeMenu = () => {
        setCollapsed(true);
    };

    const onExitedMenu = () => {
        setClosedMenu(true);
    };

    const onEnteringMenu = () => {
        setClosedMenu(false);
    };

    isLogged || isPreRegistrationPage ? document.body.classList.remove('not-logged') : document.body.classList.add('not-logged');
    isClosedMenu ? document.body.classList.remove('modal-open') : document.body.classList.add('modal-open');

    const onBrandButtonClicked = () => {
        closeMenu();
        goHome();
    };

    return (
        <div className={classNames('nav-container', { 'bg-primary': !isPreRegistrationPage })}>
            <div className="container-lg">
                <div
                    className={classNames('navbar', 'navbar-expand-lg', 'navbar-light', 'position-relative', 'px-0', {
                        'navbar-fullScreenCustomerRequest': customerRequestType(customerRequestKey) === 'fullScreen' && isLogged,
                    })}
                >
                    {isLogged ? (
                        <>
                            <Brand
                                extraClass={customerRequestType(customerRequestKey) === 'fullScreen' ? 'order-1 mr-auto' : 'order-1'}
                                onClick={onBrandButtonClicked}
                                customerRequestType={customerRequestType(customerRequestKey)}
                            />
                            {customerRequestType(customerRequestKey) !== 'fullScreen' && <Notifications />}
                            <HamburgerNavButton extraClass="order-3" collapsed={collapsed} onToggle={toggleMenu} />
                            <Collapse
                                isOpen={!collapsed}
                                onExited={onExitedMenu}
                                onEntering={onEnteringMenu}
                                navbar
                                className="order-4 order-lg-2"
                                id="navbarSupportedContent"
                            >
                                {customerRequestType(customerRequestKey) !== 'fullScreen' && (
                                    <>
                                        <NavLinksLogged linkClicked={closeMenu} employee={user.employee} />
                                        <hr className="navigation-divider"></hr>
                                    </>
                                )}

                                {customerRequestType(customerRequestKey) !== 'fullScreen' && (
                                    <>
                                        <UserLinks linkClicked={closeMenu} logout={logout} userDetails={user.customer} />
                                        <hr className="navigation-divider"></hr>
                                    </>
                                )}

                                <RedirectLinks linkClicked={closeMenu} customerRequestType={customerRequestType(customerRequestKey)} />
                            </Collapse>
                            {customerRequestType(customerRequestKey) !== 'fullScreen' && (
                                <ProfileMenu linkClicked={closeMenu} userDetails={user.customer} accountSettings logout={logout} />
                            )}
                        </>
                    ) : (
                        <>
                            {!isPreRegistrationPage && <Brand onClick={goHome} />}
                            <HamburgerNavButton collapsed={collapsed} onToggle={toggleMenu} />
                            <Collapse
                                isOpen={!collapsed}
                                onExited={onExitedMenu}
                                onEntering={onEnteringMenu}
                                style={!!windowSize.width && windowSize.width >= 992 ? { height: 0 } : {}}
                                navbar
                                className="bg-primary"
                            >
                                <RedirectLinks linkClicked={closeMenu} />
                            </Collapse>
                            {!isPreRegistrationPage && <LoginButton loginClicked={closeMenu} />}
                        </>
                    )}
                </div>
            </div>
            {collapsed && isClosedMenu && noInternetConnection && <NoInternetConnection />}
        </div>
    );
};

export default NavbarCustomer;
