import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { useWindowSize } from '../../../../../hooks/use-window-size';
import { IRootState } from '../../../../../reducers';
import StepsProgressModal from './steps-progress-modal';

const StepsProgressView: React.FC = () => {
    /**max step titles on preview, if > limitStepsPreview then will show tooltip  */
    const limitStepsPreview = 10;

    const [showStepModal, setShowStepModal] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { request, step } = useSelector((state: IRootState) => state.customerRequest);

    const [t] = useTranslation();

    const windowSize = useWindowSize();

    const isMobileScreen = useCallback(
        function(): boolean {
            return !!windowSize.width && windowSize.width <= 1024;
        },
        [windowSize.width],
    );

    const openStepModal = () => {
        isMobileScreen() && setShowStepModal(true);
    };

    const changeStep = (value: number) => {
        if (value <= step.current) dispatch(CustomerRequestActions.openStep(value));
        setShowStepModal(false);
    };

    const previousStep = () => {
        dispatch(CustomerRequestActions.previousStep());
    };

    return (
        <>
            <div className="row mt-3 mb-5">
                <div className="col-12 d-flex d-lg-block justify-content-between align-center align-content-center">
                    <div className="text-decoration-none d-block d-lg-none" style={{ fontSize: '1.4rem' }}>
                        {step.current !== 0 && <i onClick={previousStep} className="icon-arrow-left-16"></i>}
                    </div>
                    <div className="md-stepper-horizontal d-none d-lg-table">
                        {request?.blocks &&
                            request.blocks.map((item, index) => (
                                <div
                                    className={classNames('md-step', { active: index <= step.current, 'cursor-pointer': index <= step.current })}
                                    onClick={() => changeStep(index)}
                                    key={`${item.key}-${index}`}
                                >
                                    <div className="md-step-circle" id={`circle_${item.key}-${index}`}>
                                        <span>
                                            {index <= step.current ? (
                                                <FontAwesomeIcon icon={faCheckCircle} color="black" />
                                            ) : (
                                                <i className={classNames('icon-circle-16', { active: index <= step.current })}></i>
                                            )}
                                        </span>
                                    </div>
                                    {step.max <= limitStepsPreview || index === step.current ? (
                                        <div className="md-step-title">{t(`customer-request.steps.${item.key}.title`)}</div>
                                    ) : (
                                        <UncontrolledTooltip placement="bottom" target={`circle_${item.key}-${index}`}>
                                            {t(`customer-request.steps.${item.key}.title`)}
                                        </UncontrolledTooltip>
                                    )}
                                    <div className={classNames('md-step-bar-left', { active: index <= step.current })}></div>
                                    <div className={classNames('md-step-bar-right', { active: index + 1 <= step.current })}></div>
                                </div>
                            ))}
                    </div>
                    <ul onClick={openStepModal} className="row step-wizard d-flex flex-row list-unstyled justify-content-center d-lg-none">
                        {request?.blocks &&
                            request.blocks.map((item, index) => (
                                <li
                                    key={`${item.key}-${index}`}
                                    className={classNames('col', { active: index <= step.current, 'flex-grow-0 mr-4': index === 0 })}
                                    style={{ maxWidth: 350 }}
                                >
                                    {index !== 0 && index !== request.blocks.length && <div className="line"></div>}
                                    <div
                                        className={`step cursor-pointer ${index === 0 ? 'first-step' : ''}`}
                                        onClick={() => changeStep(index)}
                                        id={`${item.key}-${index}`}
                                    >
                                        <i className="icon-circle-16"></i>
                                        <br />
                                        {step.max <= limitStepsPreview ? (
                                            t(`customer-request.steps.${item.key}.title`)
                                        ) : (
                                            <UncontrolledTooltip placement="bottom" target={`${item.key}-${index}`}>
                                                {t(`customer-request.steps.${item.key}.title`)}
                                            </UncontrolledTooltip>
                                        )}
                                    </div>
                                </li>
                            ))}
                    </ul>
                    <div className="d-lg-none mt-1" style={{ textDecoration: 'underline' }} onClick={openStepModal}>
                        {t('customer-request.steps.step', { current: step.current + 1, max: step.max })}
                    </div>
                </div>
                <StepsProgressModal show={showStepModal} hide={() => setShowStepModal(false)} onStepChange={changeStep} />
            </div>
            <nav aria-label="breadcrumb" style={{ position: 'absolute' }} className="d-none d-lg-inline">
                <ol className="breadcrumb">
                    {step.current !== 0 && (
                        <li className="breadcrumb-item" aria-current="page">
                            <ClickableElement className="customer-request-step-back" onClick={previousStep}>
                                <i className="icon-arrow-left-16"></i> {t(`customer-request.steps.step-back`)}
                            </ClickableElement>
                        </li>
                    )}
                </ol>
            </nav>
        </>
    );
};

export default StepsProgressView;
