import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerconsumptionMeterReading,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
} from '@spp/spp-meru-frontend-common';
import moment from 'moment';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { BaseButton, DatePicker, LoadingIndicator } from '../../../../../components/common';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../components/common/input-with-append';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { useApi } from '../../../../../hooks/use-api';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IAssumedEeConsumption } from '../../../../../models/customer-request-model';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties, formatDate } from '../../../../../utils/utils';
import { CustomerRequestDataEnum } from '../../../config/enums';

const BlockInvoiceOutOfCycleData: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentBlock, content, deliveryPointEe, deliveryPointZp } = useSelector((state: IRootState) => state.customerRequest);
    const { formRules, dateRules } = useFormRules();
    const api = useApi();

    const [minDate, setMinDate] = useState(
        moment()
            .add(-2, 'days')
            .toDate(),
    );

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.READING;

    const [mutateMeterReading, { isLoading: meterReadingLoading }] = useMutationWithError<
        SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerconsumptionMeterReading | null,
        IApiResponse,
        { contractId?: string }
    >(async ({ contractId }) =>
        contractId
            ? api.deliveryPoints
                  .meterReadingSearch(
                      contractId,
                      { reason: 'REASON_01', paging: { size: 1, sort: [{ attribute: 'readAt', direction: 'DESC' }] } },
                      { secure: true },
                  )
                  .then((res) => res.data)
            : null,
    );

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId?: string }
    >(async ({ contractId }) => (contractId ? api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data) : null));

    useEffect(() => {
        if (deliveryPointZp) {
            const fetchDataToSetDateRules = async () => {
                const meterReading = await mutateMeterReading({
                    contractId: deliveryPointZp.contract?.id,
                });
                let lastMeterReading: string[] = [];
                let lastMeterReadingDate: Date | null = null;
                if (meterReading?.result?.length && meterReading?.result[0].readAt) {
                    lastMeterReading = meterReading?.result[0].readAt?.split('T');
                } else {
                    const deliveryPointFullInfo = await mutateDeliveryPointByContractId({
                        contractId: deliveryPointZp.contract?.id,
                    });
                    if (deliveryPointFullInfo?.contract?.effectiveFrom) {
                        lastMeterReading = deliveryPointFullInfo?.contract?.effectiveFrom?.split('T');
                    }
                }

                if (lastMeterReading.length) {
                    lastMeterReadingDate = new Date(lastMeterReading[0]);
                    lastMeterReadingDate.setHours(12, 0, 0, 0);
                    setMinDate(
                        moment(lastMeterReadingDate)
                            .add(1, 'days')
                            .toDate(),
                    );
                }
            };
            fetchDataToSetDateRules();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { register, errors, handleSubmit, getValues, setValue, trigger, watch } = useForm({
        defaultValues: {
            date: content.meterReading?.date ?? new Date().toISOString(),
            value: content.meterReading?.value,
            valueHigh: content.meterReading?.valueHigh,
            valueLow: content.meterReading?.valueLow,
        },
    });
    const onSubmit = (data: IAssumedEeConsumption) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                ...clearObjectProperties({ ...data }),
            },
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const date = watch('date');

    // SNVI-421
    // remove control for both comodities

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {meterReadingLoading && <LoadingIndicator />}
            <FormGroup className=" d-flex flex-row">
                <DatePicker
                    register={register({
                        ...dateRules('BETWEEN', minDate.toISOString(), new Date().toISOString()).isValidDate,
                        ...formRules.required,
                    })}
                    name="date"
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    errors={errors}
                    label={t('customer-request.steps.invoice-out-of-cycle-data.date-for-issue-invoice')}
                    maxDate={new Date()}
                    minDate={minDate}
                />
            </FormGroup>
            {deliveryPointEe?.contract?.eeTariffCount === 2 ? (
                <>
                    <InputWithAppend
                        trigger={trigger}
                        ref={register({ ...formRules.requiredInteger })}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        type="number"
                        name="valueHigh"
                        label={
                            t('customer-request.steps.invoice-out-of-cycle-data.meter-value-to-date', {
                                date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                            }) +
                            ' - ' +
                            t('delivery-point.detail.consumption.meter-value-electricity-high')
                        }
                        inputGroupAppendChildren={
                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                kWh
                            </button>
                        }
                    />
                    <InputWithAppend
                        trigger={trigger}
                        ref={register({ ...formRules.requiredInteger })}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        type="number"
                        name="valueLow"
                        label={
                            t('customer-request.steps.invoice-out-of-cycle-data.meter-value-to-date', {
                                date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                            }) +
                            ' - ' +
                            t('delivery-point.detail.consumption.meter-value-electricity-low')
                        }
                        inputGroupAppendChildren={
                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                kWh
                            </button>
                        }
                    />
                </>
            ) : (
                <InputWithAppend
                    trigger={trigger}
                    ref={register({ ...formRules.requiredInteger })}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name="value"
                    id="value"
                    label={t('customer-request.steps.invoice-out-of-cycle-data.meter-value-to-date', {
                        date: moment(date).isValid() ? formatDate(new Date(date)) : '',
                    })}
                    inputGroupAppendChildren={
                        <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                            {deliveryPointZp ? (
                                <>
                                    {' '}
                                    m<sup>3</sup>
                                </>
                            ) : (
                                'kWh'
                            )}
                        </button>
                    }
                />
            )}
            <Trans i18nKey="customer-request.steps.gauge-condition.meter-condition-help2" />
            <div className="my-2">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                <HelpLinkWithModal
                    className="d-inline"
                    classNameClickableElement="text-decoration-underline-inverse"
                    title="customer-request.steps.gauge-condition.meter-reading-help"
                    hideTitle
                    field={
                        deliveryPointZp
                            ? 'SOT_GAS_METER_WHERE_I_FIND_IT'
                            : deliveryPointEe?.contract?.eeTariffCount === 2
                            ? 'SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT'
                            : 'SOT_ELECTRICITY_METER_WHERE_I_FIND_IT'
                    }
                />
            </div>

            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockInvoiceOutOfCycleData;
