/** CustomerRequestDataEnum for blocks data definition*/
export enum CustomerRequestDataEnum {
    ADDITIONAL_SERVICES = 'additionalServices',
    ADDRESS = 'address',
    ADVANCE = 'advance',
    ADVANCE_PAYMENT = 'advancePayment',
    ADVANCE_PAYMENT_PERIOD = 'advancePaymentPeriod',
    AMOUNT = 'amount',
    APPROVALS = 'approvals',
    ASSUMED_CONSUMPTION = 'assumedConsumption',
    ASSUMED_CONSUMPTION_ZP = 'assumedConsumptionZp',
    ASSUMED_CONSUMPTION_EE = 'assumedConsumptionEe',
    BANK_CONNECTION = 'bankConnection',
    BILLING_DELIVERY = 'billingDelivery',
    BUSINESS_PARTNER = 'businessPartner',
    CONSENT = 'consent',
    COMPLAINED_ADVANCES = 'complainedAdvances',
    COMPLAINED_INVOICE = 'complainedInvoice',
    COMPLAINED_INVOICE_PROBLEM_DESCRIPTION = 'complainedInvoiceProblemDescription',
    COMPLAINT = 'complaint',
    CONECTION = 'connection',
    CONECTION_DETAIL_EE = 'connectionDetailEe',
    CONTACT = 'contact',
    CONTRACT_TERMINATION_DATE = 'contractTerminationDate',
    CONTRACT_TERMINATION_REASON = 'contractTerminationReason',
    CONTRACT_DELIVERY = 'contractDelivery',
    CORRESPONDACE_ADDRESS = 'correspondenceAddress',
    DATE = 'date',
    DEAL_AMOUNT = 'dealAmount',
    DEAL_AMOUNT_EE = 'dealAmountEe',
    DEAL_AMOUNT_ZP = 'dealAmountZp',
    DELIVERY_POINT = 'deliveryPoint',
    DELIVERY_POINT_CONNECTION_EE = 'deliveryPointConnectionEe',
    DELIVERY_POINT_CONNECTION_ZP = 'deliveryPointConnectionZp',
    EE_METER_READING = 'eeMeterReading',
    EE_TARIF = 'eeTariff',
    FINAL_INVOICE_DELIVERY = 'finalInvoiceDelivery',
    INVOICE_DELIVERY = 'invoiceDelivery',
    INVOICE_NUMBER = 'invoiceNumber',
    INVOICE_NUMBERS = 'invoiceNumbers',
    LAST_INVOICE = 'lastInvoice',
    LAST_NAME_CHANGE_REASON = 'lastNameChangeReason',
    METER_NUMBER = 'meterNumber',
    METER_READING = 'meterReading',
    METER_READINGS = 'meterReadings',
    METER_READING_ZP = 'meterReadingZp',
    METER_READING_EE = 'meterReadingEe',
    NEW_ADDRESS = 'newAddress',
    NEW_BANK_CONNECTION = 'newBankConnection',
    NEW_BUSINESS_PARTNER = 'newBusinessPartner',
    NEW_BUSINESS_PARTNER_DATA = 'newBusinessPartnerData',
    NEW_BUSINESS_PARTNER_ADDRESS = 'newBusinessPartnerAddress',
    NEW_BUSINESS_PARTNER_ADVANCE_PAYMENT_PERIOD = 'newBusinessPartnerAdvancePaymentPeriod',
    NEW_BUSINESS_PARTNER_CONTACT = 'newBusinessPartnerContact',
    NEW_BUSINESS_PARTNER_CORRESPONDENCE_ADDRESS = 'newBusinessPartnerCorrespondenceAddress',
    NEW_BUSINESS_PARTNER_INVOICE_DELIVERY = 'newBusinessPartnerInvoiceDelivery',
    NEW_BUSINESS_PARTNER_PAYMENT_METHOD = 'newBusinessPartnerPaymentMethod',
    NEW_CORRESPONDACE_ADDRESS = 'newCorrespondenceAddress',
    NEW_DELIVERY_POINT_ADDRESS = 'newDeliveryPointAddress',
    NEW_LAST_NAME = 'newLastName',
    NOTE = 'note',
    OVERPAYMENT_SETTLEMENT = 'overpaymentSettlement',
    OWNER = 'owner',
    OWNER_ADDRESS = 'ownerAddress',
    OWNER_CONTACT = 'ownerContact',
    OWNER_CORRESPONDANCE_ADDRESS = 'ownerCorrespondenceAddress',
    OWNER_ATTACHMENTS = 'ownerAttachments',
    PAYMENT_METHOD = 'paymentMethod',
    PREFERRED_CONTACT = 'preferredContact',
    PROBLEM_DESCRIPTION = 'problemDescription',
    PRODUCTS = 'products',
    PRODUCT_LEVEL = 'productLevel',
    READING = 'reading',
    READINGS = 'readings',
    REASON = 'reason',
    RATES = 'rates',
    STATEMENT = 'statement',
    SUPPLIER = 'supplier',
    UNDERCHARGE_SETTLEMENT_INVOICE = 'underchargeSettlementInvoice',
    TARIFF = 'tariff',
    TARIFF_EE = 'tariffEe',
    TARIFF_ZP = 'tariffZp',
    ZP_ASSUMED_CONSUMPTION = 'zpAssumedConsumption',
    ZP_METER_READING = 'zpMeterReading',
    ZP_TARIFF = 'zpTariff',
    TARIFFS = 'tariffs',
}

/** request group enum for groupt title */
export enum CustomerRequestGroupsEnum {
    DELIVERY_REQUESTS = 'delivery-requests',
    CHANGE_REQUESTS = 'change-requests',
    CONFIRMATION_DOCUMENTS = 'confirmation-documents',
    CLAIM = 'claim',
    OTHERS = 'others',
}

/**Key for customer-requests (application forms) */
export enum CustomerRequestEnum {
    CHANGE_ELECTRICITY_RATE_SHORT = 'change-electricity-rate-short',
    CHANGE_GAS_TARIFF_SHORT = 'change-gas-tariff-short',
    CLAIM_INVOICE_SHORT = 'claim-invoice-short', // Ziadost 6b - Reklamacia faktury.pdf
    COMPLAINT_SHORT = 'complaint-short', // Stažnost
    TRANSCRIPT_NEW = 'transcript-new', // Ziadost 8 - Prepis.pdf
    TRANSCRIPT_NEW_CUSTOMER = 'transcript-new-customer', // Ziadost 8 - Prepis.pdf
    ELECTRICITY_SUPPLY_NEW = 'electricity-supply-new', // Ziadost 9 - Dodavka elektriny - nova.pdf
    GAS_SUPPLY_NEW = 'gas-supply-new', // Ziadost 10 - Dodavka plynu.pdf
    REQUEST_TERMINATION_CONTRACT_SHORT = 'request-termination-contract-short', // Ziadost 16 - Žiadosť o ukončenie zmluvy ZP/EE short
    REQUEST_INTERRUPTION_AND_RESUMPTION_CONSUPTION_SHORT = 'request-interruption-and-resumption-consuption-short', // Ziadost 12 - Ziadost o prerusenie a obnovu odberu.pdf
    REQUEST_OFFICIAL_TEST_METER_SHORT = 'request-official-test-meter-short', // Ziadost 13 - Ziadost o uradnu skusku meradla.pdf
    REQUEST_INVOICE_OUT_CYCLE_SHORT = 'request-invoice-out-cycle-short', // Ziadost 14 - Ziadost o fakturu mimo cyklu.pdf
    REQUEST_ACCELERATED_RESUMPTION_GAS_SUPPLY = 'request-accelerated-resumption-gas-supply', // Ziadost 15 - Ziadost o zrychlene obnovenie dodavky plynu.pdf
    REPAYMENT_AGREEMENT_SHORT = 'repayment-agreement-short', // Ziadost 16a - Dohoda o splatkach.pdf
    CONFIRMATION_AMOUNT_LIABILITIES_SHORT = 'confirmation-amount-liabilities-short', // Ziadost 16b - Potvrdenie o vyske zavazkov.pdf
    CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT = 'confirmation-contractual-relationship-short', // Ziadost 16c - Potvrdenie o zmluvno vztahu.pdf
    CARBON_SHANK_SHORT = 'carbon-shank-short', // Žiadosť obchodný partner - Uhlíková stopka
    CLEAN_ELECTRICITY_SHORT = 'clean-electricity-short', // Žiadosť Cista elektrika
}

export enum CustomerRequestTypeEnum {
    // ZOM_ZOUOP = 'ZOM_ZOUOP',
    ZOM_CE = 'ZOM_CE',
    ZOM_ZAOM = 'ZOM_ZAOM',
    //ZOM_ZSPAPPP = 'ZOM_ZSPAPPP',
    ZOM_ZVAPPP = 'ZOM_ZVAPPP',
    ZOM_ZSE = 'ZOM_ZSE',
    ZOM_ZTP = 'ZOM_ZTP',
    ZOM_RPVF = 'ZOM_RPVF',
    ZOM_RF = 'ZOM_RF',
    ZOP_P = 'ZOP_P',
    ZOM_P = 'ZOM_P',
    ZOM_ZODE = 'ZOM_ZODE',
    ZOM_ZODP = 'ZOM_ZODP',
    ZOM_ZODE_ZODP = 'ZOM_ZODE_ZODP',
    ZOM_ZOUZ = 'ZOM_ZOUZ',
    ZOM_ZOPAOO = 'ZOM_ZOPAOO',
    ZOM_ZOUSM = 'ZOM_ZOUSM',
    ZOM_ZOFMC = 'ZOM_ZOFMC',
    ZOM_ZOZODZP = 'ZOM_ZOZODZP',
    ZOM_DOS = 'ZOM_DOS',
    ZOM_POVZ = 'ZOM_POVZ',
    ZOM_POZV = 'ZOM_POZV',
    ZOM_ZOVDF = 'ZOM_ZOVDF',
    ZOM_ZODS = 'ZOM_ZODS',
    ZOP_RS = 'ZOP_RS',
    ZZU_ADEF = 'ZZU_ADEF',
    ZOP_ZOUA = 'ZOP_ZOUA',
    ZOP_ZP = 'ZOP_ZP',
    // ZOP_ZFU = 'ZOP_ZFU',
    ZOP_ZBU = 'ZOP_ZBU',
    ZOP_US = 'ZOP_US',
    X_MS = 'X_MS',
}

/**Key for step(block) header  - {title, subtitle} */
export enum CustomerRequestBlockTitleEnum {
    BLOCK_ADDRESS = 'address',
    BLOCK_ADDRESS_SUPPLY = 'address-supply',
    BLOCK_ADDRESS_SUPPLY_ZP = 'address-supply-zp',
    BLOCK_AMOUNT_ELECTRICITY = 'amount-electricity',
    BLOCK_AMOUNT_GAS = 'amount-gas',
    BLOCK_ATTACHMENTS = 'attachments',
    BLOCK_ATTACHMENTS_SHORT = 'attachments-short',
    BLOCK_BANK_DETAIL = 'bank-details',
    BLOCK_CONNECTION_ELECTRICITY = 'connection-electricity',
    BLOCK_CONNECTION = 'connection',
    BLOCK_CONNECTION_ZP = 'connection-zp',
    BLOCK_CONTACT = 'contact',
    BLOCK_CUSTOMER = 'customer',
    BLOCK_BUSINESS_PARTNER = 'businessPartner',
    BLOCK_CARBON_FOOTPRINT_BUSINESS_PARTNER = 'block-carbon-footprint-business-partner',
    BLOCK_CLEAR_ELECTRICITY_BUSINESS_PARTNER = 'block-clear-electricity-business-partner',
    BLOCK_DELIVERY_POINT = 'delivery-point',
    BLOCK_DELIVERY_POINT_INVOICE_OUT_OF_CYCLE = 'delivery-point-invoice-out-of-cycle',
    BLOCK_DELIVERY_POINT_TERMINATE_CONTRACT = 'delivery-point-terminate-contract',
    BLOCK_NEW_DELIVERY_POINT = 'new-delivery-point',
    BLOCK_GAUGE_CONDITION = 'gauge-condition',
    BLOCK_GAUGE_CONDITION_V1 = 'gauge-condition-v1',
    BLOCK_INVOICE_DELIVERY = 'invoice-delivery',
    BLOCK_INVOICE_DELIVERY_ZP_EE = 'invoice-delivery-zp-ee',
    BLOCK_CONTRACT_DELIVERY = 'contract-delivery',
    BLOCK_CARBON_FOOTPRINT_INVOICE_DELIVERY = 'block-carbon-footprint-invoice-delivery',
    BLOCK_CLEAR_ELECTRICITY_INVOICE_DELIVERY = 'block-clear-electricity-invoice-delivery',
    BLOCK_INVOICE_OUT_OF_CYCLE_DATA = 'invoice-out-of-cycle-data',
    BLOCK_INLINE_REGISTRATION = 'inline-registration',
    BLOCK_NEW_BUSINESS_PARTNER_DATA = 'new-data',
    BLOCK_NEW_COMPANY_DATA = 'new-data',
    BLOCK_NEW_BANK_DETAIL = 'new-bank-detail',
    BLOCK_PAYMENT_METHOD = 'payment-method',
    BLOCK_PAYMENT_METHOD_CLEAN_ELECTRICITY = 'payment-method-clean-electricity',
    BLOCK_PAYMENT_PERIOD = 'payment-period',
    BLOCK_CARBON_FOOTPRINT_PAYMENT_PERIOD = 'block-carbon-footprint-payment-period',
    BLOCK_CLEAR_ELECTRICITY_PAYMENT_PERIOD = 'block-clear-electricity-payment-period',
    BLOCK_RATE_SELECTION = 'rate-selection',
    BLOCK_RATE_CHOICE = 'rate-choice',
    BLOCK_TARIFF_CHOICE = 'tariff-choice',
    BLOCK_CHANGE_LAST_NAME = 'change-lastname',
    BLOCK_CHANGE_LAST_NAME_REASON = 'change-lastname-reason',
    BLOCK_CHANGE_SUPPLIER = 'change-supplier',
    BLOCK_CHANGE_SUPPLIER_ZP = 'change-supplier-zp',
    BLOCK_AMOUNT_ADVANCES = 'amount-advances',
    BLOCK_ADVANCE_PAYMENTS = 'advance-payments',
    BLOCK_ADVANCE_PAYMENT = 'advance-payment',
    BLOCK_COMPLAINT = 'complaint',
    BLOCK_COMPLETION = 'completion',
    BLOCK_COMPLETION_ORDER = 'completion-order',
    BLOCK_COMPLETION_CLEAR_ELECTRICITY = 'completion-clear-electricity',
    BLOCK_CARBON_FOOTPRINT_COMPLETION = 'block-carbon-footprint-completion',
    BLOCK_COMPLAINED_INVOICE = 'complained-invoice',
    BLOCK_COMPLAINED_ADVANCES = 'complained-advances',
    BLOCK_SELECT_DP_OR_BP = 'select-dp-or-bp',
    BLOCK_SETTTLEMENT_OVERPAYMENT = 'settlement-overpayment',
    BLOCK_SETTTLEMENT_OVERPAYMENT_STATIC = 'settlement-overpayment-static',
    BLOCK_PROBLEM_DESCRIPTION = 'problem-description',
    REASON_FOR_CLAIM = 'reason-for-claim',
    REASON_FOR_CLAIM_DETAIL = 'reason-for-claim-detail',
    BLOCK_PRODUCT_LEVEL = 'product-level',
    BLOCK_CARBON_FOOTPRINT_PRODUCT_LEVEL = 'block-carbon-footprint-product-level',
    BLOCK_CLOSING_INVOICE = 'closing-invoice',
    BLOCK_SENDING_OVERPAYMENT = 'sending-overpayment',
    BLOCK_SENDING_OVERPAYMENT_DEFECT = 'sending-overpayment-defect',
    BLOCK_NEW_BUSINESS_PARTNER_CHOICE = 'new-customer-choice',
    BLOCK_NEW_BUSINESS_PARTNER = 'new-customer',
    BLOCK_NEW_BUSINESS_PARTNER_ADDRESS = 'new-customer-address',
    BLOCK_NEW_BUSINESS_PARTNER_CONTACT = 'new-customer-contact',
    BLOCK_NUMBER_OF_INSTALLMENTS = 'number-of-installments',
    BLOCK_GAS_CONSUMPTION = 'gas-consumption',
    BLOCK_ELECTRICITY_CONSUMPTION = 'electricity-consumption',
    BLOCK_CONSUMPTION = 'consumption',
    BLOCK_GAS_CONDITION = 'gas-condition',
    BLOCK_ELECTRICITY_CONDITION = 'electricity-condition',
    BLOCK_TERMS_CONDITION = 'terms-condition',
    BLOCK_ELECTRICITY_CONNECTION = 'electricity-connection',
    BLOCK_OWNER_OBJECT = 'owner-object',
    BLOCK_GAS_CONNECTION = 'gas-connection',
    BLOCK_INTERAPTION_REASON = 'interaption-reason',
    BLOCK_INTERAPTION_DATE = 'interaption-date',
    BLOCK_INTERAPTION_DP = 'interaption-delivery-point',
    BLOCK_DELIVERY_CLOSED_INVOICE = 'delivery-closed-invoice',
    BLOCK_BILLING_DELIVERY = 'billing-delivery',
    BLOCK_REASON = 'reason',
    BLOCK_STATEMENT = 'statement',
    BLOCK_STATEMENT_V1 = 'statement-v1',
    BLOCK_STATEMENT_V2 = 'statement-v2',
    BLOCK_INVOICE_DELIVERY_V1 = 'invoice-delivery-v1',
    BLOCK_OVERDUE_INVOICE = 'overdue-invoice',
    BLOCK_DATE_SELECTION = 'date-selection',
    BLOCK_INVOICE_CHOICE = 'invoice-choice',
    BLOCK_ADDITIONAL_SERVICES = 'additional-services',
    BLOCK_TERMINATION_REASON = 'termination-reason',
    BLOCK_TERMINATION_DATE = 'termination-contract-date',
    BLOCK_UNDERCHARGE_SETTLEMENT_INVOICE = 'undercharge-settlement-invoice',
    BLOCK_UNPAID_INVOICES = 'unpaid-invoices',
    BLOCK_METER_NUMBER = 'meter-number',
    BLOCK_METER_READING_TRANSCRIPT = 'meter-reading-transcript',
    BLOCK_TRANSCRIPT_INVOICE_DELIVERY = 'invoice-delivery-transcript',
    BLOCK_TRANSCRIPT_BANK_CONNECTION = 'bank-connection-transcript',
    BLOCK_TRANSCRIPT_NEW_CUSTOMER = 'new-customer-transcript',
    BLOCK_TRANSCRIPT_METER_CONFIRM = 'meter-confirm-transcript',
    BLOCK_TRANSCRIPT_NEW_CUSTOMER_BP = 'new-customer-bp-transcript',
    BLOCK_TRANSCRIPT_NEW_CUSTOMER_INVOICE_DELIVERY = 'new-customer-invoice-delivery-transcript',
}

export enum CodeListTypeEnum {
    ADVANCE_PAYMENT_PERIOD = 'ADVANCE_PAYMENT_PERIOD',
    PAYMENT_TYPE = 'PAYMENT_TYPE',
    COMPLAINED_INVOICE_PROBLEM = 'COMPLAINED_INVOICE_PROBLEM',
    OVERPAYMENT_SETTLEMENT_TYPE = 'OVERPAYMENT_SETTLEMENT_TYPE',
    ZOM_ZOPAOO_REASON = 'ZOM_ZOPAOO_REASON',
    TARIFF_RATE = 'TARIFF_RATE',
    METER_READING_KIND = 'METER_READING_KIND',
    METER_READING_REASON = 'METER_READING_REASON',
    AUDIT_LOG_CODE = 'AUDIT_LOG_CODE',
    INVOICE_TYPE = 'INVOICE_TYPE',
    INVOICE_SUBTYPE = 'INVOICE_SUB_TYPE',
    CONTRACT_TERMINATION_REASON = 'CONTRACT_TERMINATION_REASON',
    CUSTOMER_REQUEST_STATUS = 'CUSTOMER_REQUEST_STATUS',
    CARBON_STOP_LEVEL = 'CARBON_STOP_LEVEL',
    METER_READING_EXPORT_COLUMN = 'METER_READING_EXPORT_COLUMN',
    METER_READING_REGISTER_KIND = 'METER_READING_REGISTER_KIND',
    COUNTRY = 'COUNTRY',
    DISTRIBUTION_AREA = 'DISTRIBUTION_AREA',
    CUSTOMER_ACCOUNT_EXPORT_COLUMN = 'CUSTOMER_ACCOUNT_EXPORT_COLUMN',
    AUDIT_LOG_EXPORT_COLUMN = 'AUDIT_LOG_EXPORT_COLUMN',
    CONTRACT_ADVANCE_PAYMENT_PERIOD = 'CONTRACT_ADVANCE_PAYMENT_PERIOD',
    TITLE_FRONT = 'TITLE_FRONT',
    TITLE_BEHIND = 'TITLE_BEHIND',
}

export enum CanceledRequestStatuses {
    CANCELLED_BY_USER = 'CANCELLED_BY_USER',
    SAP_CANCELLED_BY_USER = 'SAP_CANCELLED_BY_USER',
    SAP_CANCELLED = 'SAP_CANCELLED',
    SAP_FINISHED = 'SAP_FINISHED',
    COMPLETION_REJECTED = 'COMPLETION_REJECTED',
    EXPIRED = 'EXPIRED',
}
