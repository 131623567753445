import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useUrlQuery } from '../../../../hooks/use-url-query';
import { DeliveryPointCustomerRequests } from '../../../customer-request/screen/delivery-point-customer-requests/delivery-point-customer-requests';
import StepsManagerCustomerRequest from '../../../customer-request/screen/steps-customer-request/steps-manager-customer-request';

interface IDeliveryPointDetailRequestsTabProps {
    unitedDeliveryPoint?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
    getDeliveryPointSwitch: (additionalButton?: { label: React.ReactNode }) => React.ReactNode;
}

export const DeliveryPointDetailRequestsTab: React.FC<IDeliveryPointDetailRequestsTabProps> = ({ unitedDeliveryPoint, getDeliveryPointSwitch }) => {
    const urlQuery = useUrlQuery();
    const requestIdQuery = urlQuery.get('request');

    return (
        <>
            {!!requestIdQuery ? (
                <StepsManagerCustomerRequest />
            ) : (
                <DeliveryPointCustomerRequests unitedDeliveryPoint={unitedDeliveryPoint} getDeliveryPointSwitch={getDeliveryPointSwitch} />
            )}
        </>
    );
};
