import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import { LoadingIndicator } from '../../../../../components/common';
import BaseButton from '../../../../../components/common/base-button';
import { DistributionAreaSelect } from '../../../../../components/common/distribution-area-select';
import { useApi } from '../../../../../hooks/use-api';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import { BusinessPartnerTypeEnum, ConsumptionCategoryEnum, DistributionAreasEnum, KINDcodeHome, useCodeList } from '../../../../../main';
import { ITariff } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import { ratesForSpecificDistributionAreaBasedOnRateType } from '../../../customer-request-utils';
import TariffFields from '../components/tariff-fields';

const BlockRateSelectionWithDistributionArea: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();

    const { currentBlock, content, additionalData, businessPartner, partnersDeliveryPoints } = useSelector(
        (state: IRootState) => state.customerRequest,
    );

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.TARIFF;
    const objectData = content[objectName] as ITariff;

    const useAdditionalDataDeliveryPoint = additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.status === 'ACTIVE').length === 1;
    const additionalDataDeliveryCategory = useAdditionalDataDeliveryPoint
        ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.deliveryCategory
        : undefined;
    const [consumptionCategory, setConsumptionCategory] = useState<ConsumptionCategoryEnum | undefined>(
        content.tariffEe?.tariff?.category ?? (additionalDataDeliveryCategory ? ConsumptionCategoryEnum[additionalDataDeliveryCategory] : undefined),
    );

    const eicBasedDistributionArea = useMemo(() => {
        const eic = content.assumedConsumption?.deliveryPointNumber;
        if (eic == null) {
            return undefined;
        }
        const distributionArea = eic.substr(2, 3);
        return distributionArea;
    }, [content.assumedConsumption?.deliveryPointNumber]);

    const { register, handleSubmit, errors, watch, trigger, getValues } = useForm<ITariff>({
        defaultValues: { ...objectData, distributionArea: objectData?.distributionArea || eicBasedDistributionArea || '' },
    });

    const distributionArea = watch('distributionArea');

    const { data: distributionAreaCodelist } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 5000,
        },
    });

    useEffect(() => {
        if (!consumptionCategory) {
            if (businessPartner) {
                businessPartner.kind?.code === KINDcodeHome
                    ? setConsumptionCategory(ConsumptionCategoryEnum.HOME)
                    : setConsumptionCategory(ConsumptionCategoryEnum.RETAIL);
            } else if (content.accountInfo) {
                content.accountInfo.category === BusinessPartnerTypeEnum.COMPANY
                    ? setConsumptionCategory(ConsumptionCategoryEnum.RETAIL)
                    : setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            } else if (content.businessPartner) {
                content.businessPartner.type === BusinessPartnerTypeEnum.COMPANY
                    ? setConsumptionCategory(ConsumptionCategoryEnum.RETAIL)
                    : setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            } else {
                setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersDeliveryPoints]);

    const { data: tariffRates, isLoading } = useQueryWithError<TariffRate[] | undefined>(
        [
            QueryKeysEnum.PRODUCT_TARIFF_RATE,
            {
                type: 'EE',
                status: 'ACTIVE',
                distributionArea: distributionArea || eicBasedDistributionArea,
                category: consumptionCategory,
                paging: { size: 5000 },
            },
        ],
        async () =>
            consumptionCategory == null
                ? undefined
                : api.productCatalogs
                      .getTariffsAndRates(
                          {
                              type: 'EE',
                              status: 'ACTIVE',
                              distributionArea: distributionArea || eicBasedDistributionArea,
                              category: consumptionCategory,
                              paging: { size: 5000 },
                          },
                          { secure: true },
                      )
                      .then((res) => res.data?.result),
        {
            enabled: (distributionArea || eicBasedDistributionArea) && consumptionCategory != null,
        },
    );

    const onSubmit = (data: ITariff) => {
        const selectedTariff = tariffRates?.find((rate) => rate.uuid === data.tariff.uuid);
        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties({ ...data, tariff: selectedTariff }),
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    const ratesBasedOnRateType = useMemo(
        () =>
            ratesForSpecificDistributionAreaBasedOnRateType(
                tariffRates,
                content.assumedConsumption?.tariff,
                consumptionCategory,
                distributionArea as DistributionAreasEnum,
            ),
        [tariffRates, content.assumedConsumption?.tariff, consumptionCategory, distributionArea],
    );
    return (
        <>
            {isLoading && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="mb-4">
                    <DistributionAreaSelect register={register} />
                    {eicBasedDistributionArea && (
                        <span>
                            <Trans
                                i18nKey="customer-request.steps.rate-selection.recommended"
                                defaults="Odporúčané územie podľa vášho EIC kódu: <bold>{{name}}</bold>"
                                values={{
                                    name: distributionAreaCodelist?.find((item) => item.code === eicBasedDistributionArea)?.name,
                                }}
                                components={{ bold: <strong /> }}
                            />
                        </span>
                    )}
                </div>
                {ratesBasedOnRateType && (
                    <>
                        <TariffFields register={register} errors={errors} data={ratesBasedOnRateType} trigger={trigger} />
                        <BaseButton type="submit" disabled={ratesBasedOnRateType?.length === 0}>
                            <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                        </BaseButton>
                    </>
                )}
                {ratesBasedOnRateType && ratesBasedOnRateType.length === 0 && (
                    <div className="alert alert-danger p-4 d-flex my-4" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                        <div className="ml-4">
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-rate-not-supported"></Trans>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
};

export default BlockRateSelectionWithDistributionArea;
