import {
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse as DeliveryPointNotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingNotificationSettingRequest as NotificationSettingRequest,
    SkSppNzpBeApiNotificationSettingNotificationSettingResponse as NotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { RefetchOptions } from 'react-query/types/core/query';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSettingsActions } from '../../../actions/notification-settings-actions';
import Checkbox from '../../../components/common/checkbox';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { NotificationChannel, NotificationTemplateGroup } from '../../../models/model';
import { IRootState } from '../../../reducers';
import { translateNotificationTemplateGroup } from './notification-setting-utils';

interface IDeliveryPointNotificationSettingsCheckboxGridProps {
    deliveryPointNotificationSetting: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse;
    notificationTemplateGroups: NotificationTemplateGroup[];
    fetchNotificationSettings: (options?: RefetchOptions | undefined) => Promise<DeliveryPointNotificationSettingResponse[] | null | undefined>;
}

const DeliveryPointNotificationSettingsCheckboxGrid: React.FC<IDeliveryPointNotificationSettingsCheckboxGridProps> = ({
    deliveryPointNotificationSetting,
    notificationTemplateGroups,
    fetchNotificationSettings,
}) => {
    const deliveryPoint = deliveryPointNotificationSetting.unitedDeliveryPoint;
    const settings = deliveryPointNotificationSetting.notificationSettings;

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const dispatch = useDispatch();
    const createSettingRequests = (notificationSetting: NotificationSettingResponse[]) =>
        notificationSetting.reduce<NotificationSettingRequest[]>(
            (acc, setting) =>
                setting.notificationTemplate?.code == null
                    ? acc
                    : acc.concat([
                          {
                              email: setting.email,
                              sms: setting.sms,
                              templateCode: setting.notificationTemplate?.code,
                          },
                      ]),
            [],
        );

    const api = useApi();
    const [mutateNotificationSettings, { isLoading: isLoadingMutateNotificationSettings }] = useMutationWithError(
        async (notificationSettingData: { deliveryPointId: string; notificationSetting: NotificationSettingResponse[] }) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers.changeNotificationSettings(
                      loggedInCustomer.id,
                      {
                          unitedDeliveryPointIds: [notificationSettingData.deliveryPointId],
                          notificationSettings: createSettingRequests(notificationSettingData.notificationSetting),
                      },
                      { secure: true },
                  ),
        { onErrorWithGlobalErrorHandling: () => false, onSuccess: () => fetchNotificationSettings() },
    );

    const changeNotificationSetting = (notificationSetting: NotificationSettingResponse[] | undefined) => {
        if (deliveryPoint?.id != null) {
            dispatch(notificationSettingsActions.setNotificationSetting(deliveryPoint.id, notificationSetting || []));
            mutateNotificationSettings({ deliveryPointId: deliveryPoint.id, notificationSetting: notificationSetting || [] });
        }
    };

    //return 0 - each setting = FALSE,
    //return 1 - each setting = TRUE,
    //return 2 - settings contain either true or false
    const isActive = (group: NotificationTemplateGroup, channel: NotificationChannel): 0 | 1 | 2 => {
        const groupSettings = settings && settings.filter((setting) => setting.notificationTemplate?.group === group);
        const isTrueIncluded = groupSettings?.find((setting) => (channel === 'EMAIL' ? setting.email === true : setting.sms === true));
        const isFalseIncluded = groupSettings?.find((setting) => (channel === 'EMAIL' ? setting.email === false : setting.sms === false));
        if (isTrueIncluded && isFalseIncluded) {
            return 2;
        } else {
            if (groupSettings != null && groupSettings.every((setting) => (channel === 'EMAIL' ? setting.email === true : setting.sms === true))) {
                return 1;
            } else {
                return 0;
            }
        }
    };

    const toggleNotification = (group: NotificationTemplateGroup, channel: NotificationChannel, value: boolean) => {
        const replaceValue = (setting: NotificationSettingResponse) =>
            channel === 'EMAIL' ? { ...setting, email: value } : { ...setting, sms: value };
        const newSettings = settings?.map((setting) => (setting.notificationTemplate?.group === group ? replaceValue(setting) : setting));
        changeNotificationSetting(newSettings);
    };

    if (deliveryPoint?.id == null) return null;
    return (
        <>
            <div className="table-responsive mb-4">
                <table className="table table-borderless">
                    <tbody>
                        {isLoadingMutateNotificationSettings && <LoadingIndicator />}
                        {notificationTemplateGroups.map((notificationTemplateGroup) => (
                            <tr key={notificationTemplateGroup}>
                                <td className="w-50">{translateNotificationTemplateGroup(notificationTemplateGroup)}</td>
                                <td className="text-center w-25">
                                    <Checkbox
                                        checked={isActive(notificationTemplateGroup, 'EMAIL') === 0 ? false : true}
                                        onChange={(e) => toggleNotification(notificationTemplateGroup, 'EMAIL', e.target.checked)}
                                        id={`${notificationTemplateGroup}_EMAIL_dp${deliveryPoint.id}`}
                                        name=""
                                        label="&nbsp;"
                                        className={isActive(notificationTemplateGroup, 'EMAIL') === 2 ? 'checkbox-active-and-inactive' : ''}
                                    />
                                </td>
                                <td className="text-center w-25">
                                    <Checkbox
                                        checked={isActive(notificationTemplateGroup, 'SMS') === 0 ? false : true}
                                        onChange={(e) => toggleNotification(notificationTemplateGroup, 'SMS', e.target.checked)}
                                        id={`${notificationTemplateGroup}_SMS_dp${deliveryPoint.id}`}
                                        name=""
                                        label="&nbsp;"
                                        className={isActive(notificationTemplateGroup, 'SMS') === 2 ? 'checkbox-active-and-inactive' : ''}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default DeliveryPointNotificationSettingsCheckboxGrid;
