import { SkSppNzpBeApiCustomerrequestCustomerRequestSummary, SkSppNzpBeApiCustomerrequestTariffChange } from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { CodeListTypeEnum, LoadingIndicator, QueryKeysEnum, useApi, useCodeList } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { formatAddress } from '../../../../../utils/utils';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

export const BlockCompletionRateChange: React.FC = () => {
    const history = useHistory();
    const api = useApi();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { content, deliveryPointEe, deliveryPointZp, currentBlock } = useSelector((state: IRootState) => state.customerRequest);

    const { data: tariffRateCodeList } = useCodeList({
        queryKey: QueryKeysEnum.PRODUCT_TARIFF_RATE,
        codeListTypeEnum: CodeListTypeEnum.TARIFF_RATE,
        paging: {
            size: 5000,
        },
    });

    const deliveryPoint = currentBlock?.params?.zp ? deliveryPointZp : deliveryPointEe;
    const selectedRate = deliveryPoint?.type === 'ZP' ? content.tariffZp?.tariff : content.tariffEe?.tariff;
    const currentMeterState = content.meterReadingZp?.value;

    const selectedRateCodelistItem = useMemo(() => {
        const foundCodelistItem = tariffRateCodeList?.find((item) => selectedRate?.uuid === item.uuid);
        return foundCodelistItem;
    }, [tariffRateCodeList, selectedRate]);

    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);

    const [mutateCreateRequest, { isLoading: isLoadingCreateRequest }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary | null,
        unknown,
        SkSppNzpBeApiCustomerrequestTariffChange
    >(
        async (requestData) =>
            deliveryPoint?.contract?.id != null
                ? api.deliveryPoints.changeTariff(deliveryPoint.contract.id, requestData, { secure: true }).then((res) => res.data)
                : null,
        {
            onSuccess: () => {
                dispatch(CustomerRequestActions.setSend(true));
                setIsRequestCreated(true);
            },
        },
    );

    const createRequestForTariffChange = async () => {
        let requestData: SkSppNzpBeApiCustomerrequestTariffChange = {};

        if (deliveryPoint?.type === 'ZP') {
            requestData = {
                tariffUuid: selectedRateCodelistItem?.uuid,
                changedFrom: new Date().toISOString(),
                meterReading: currentMeterState,
                //meterReadingDate: new Date().toISOString(),
            };
        } else {
            requestData = {
                tariffUuid: selectedRateCodelistItem?.uuid,
                changedFrom: new Date().toISOString(),
            };
        }
        mutateCreateRequest(requestData);
    };

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const paidDistributionAreaCodes = ['ZSS', 'ZVS'];

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.delivery-point.title')}>
                    {deliveryPoint?.address != null && (
                        <>
                            <ItemPreviewContent bold>{formatAddress(deliveryPoint.address)}</ItemPreviewContent>
                        </>
                    )}
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.completion.product')}>
                    <ItemPreviewContent bold>
                        <span>{deliveryPoint?.type === 'ZP' ? t('common.commodity.gas') : t('common.commodity.electricity')}</span>{' '}
                        <span>{t('delivery-point.contract-number', { number: deliveryPoint?.contract?.externalId || '-' })}</span>
                    </ItemPreviewContent>
                </ItemPreview>

                <ItemPreview
                    title={
                        deliveryPoint?.type === 'ZP'
                            ? t('customer-request.steps.completion.selected-tariff')
                            : t('customer-request.steps.completion.selected-rate')
                    }
                >
                    <ItemPreviewContent bold>{selectedRateCodelistItem?.name}</ItemPreviewContent>
                </ItemPreview>
                {deliveryPoint?.type === 'ZP' && (
                    <ItemPreview title={t('customer-request.steps.completion.current-meter-state')}>
                        <ItemPreviewContent bold>{currentMeterState} m³</ItemPreviewContent>
                    </ItemPreview>
                )}
            </ListGroup>

            <div className="my-3 mx-2">
                <label>
                    <span className="mr-2">
                        <i className="icon-circle-info-16"></i>
                    </span>
                    {deliveryPoint?.type === 'ZP' ? (
                        <Trans i18nKey="customer-request.steps.completion.tariff-change-warning" />
                    ) : (
                        <Trans i18nKey="customer-request.steps.completion.rate-change-warning" />
                    )}
                </label>
            </div>

            {deliveryPoint?.type === 'EE' &&
                deliveryPoint?.distributionArea?.code &&
                paidDistributionAreaCodes.includes(deliveryPoint?.distributionArea?.code) && (
                    <div className="my-3 mx-2">
                        <label>
                            <span className="mr-2">
                                <i className="icon-circle-info-16"></i>
                            </span>
                            <Trans i18nKey="customer-request.steps.completion.possibly-paid" />
                        </label>
                    </div>
                )}

            <Button size="lg" color="primary" block onClick={createRequestForTariffChange}>
                <Trans i18nKey="customer-request.steps.send-request" />
            </Button>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
