//these routes will most probably change in future
enum Routes {
    HOME = '/',
    INVOICES = '/invoices',
    INVOICES_DETAIL = '/invoice',
    EPAY_STATUS = '/epay/status',
    DELIVERY_POINTS = '/delivery-points',
    MANAGE_DELIVERY_POINTS = '/delivery-points/manage',
    SHARE_DELIVERY_POINTS = '/delivery-points/share',
    CUSTOMER_REQUEST_TRANSCRIPT_NEW_CUSTOMER = '/complete-customer-request',
    CUSTOMER_REQUESTS = '/customer-requests',
    CUSTOMER_SETTINGS = '/settings',
    MARKETING_CONSENTS = '/settings/marketing-consents',
    REPORT_ISSUE = '/report-issue',
    REGISTRATION = '/registration',
    LOGIN_ISSUES = '/login-issues',
    FORGOT_PASSWORD_CONFIRMATION = '/login-issues/forgot-password-confirmation',
    PASSWORD_RECOVERY = '/login-issues/password-recovery',
    SSO_SUCCESS = '/authentication/success',
    SSO_MOBILE_SUCCESS = '/authentication/success/lmnzp',
    EMAIL_CONFIRMATION = '/confirm-email-change',
    ACCOUNT_DEACTIVATION_CONFIRMATION = '/confirm-account-removal',
    REGISTRATION_CONFIRMATION = '/confirm-registration',
    PASSWORD_RECOVERY_CONFIRMATION = '/confirm-password-recovery',
    LOGOUT_CONFIRMATION = '/logout-confirmation',
    AUTO_LOGOUT_CONFIRMATION = '/auto-logout-confirmation',
    CONTACT = '/contact',
    ABOUT_APPLICATION = '/about-application',
    COOKIES = '/cookies',
    NOTES = '/notes',
    NOTES2 = '/notes2', // 'notes2'' is used to distinguish if we come to notes directly or from consumption page
    SELF_READ = '/self-read',
    REQUEST_CONFIRMATION = '/confirm-customer-request',
}

export enum SystemManagementRoutes {
    SYSTEM_MANAGEMENT = '/system-settings',
    SYSTEM_MANAGEMENT_NOTIFICATIONS = '/system-settings/notifications',
    SYSTEM_MANAGEMENT_HELP = '/system-settings/help',
    SYSTEM_MANAGEMENT_ROLES = '/system-settings/roles',
    SYSTEM_MANAGEMENT_BATCH_REGISTRATION = '/system-settings/batch-registration',
}

export enum SettingsRoutes {
    LOGIN_DETAILS = '/settings',
    EINVOICES = '/settings/e-invoices',
    NOTIFICATIONS = '/settings/notifications',
    MEDIATOR = '/settings/mediator',
}

export enum CustomerRequestRoutes {
    MY_LIST = '/customer-requests',
    SELECT_NEW = '/customer-requests/requests',
    DETAIL = '/customer-requests/detail',
    PREPARE = '/customer-requests/request/prepare',
    NEW_REQUEST = '/customer-requests/new-request/manage',
    SUBMISSION = '/customer-requests/new-request/submission-message',
    LOGIN = '/customer-requests/new-request/login',
}

export enum PortalAccountsRoutes {
    CUSTOMERS = '/portal-accounts/customers',
    REPORTS = '/portal-accounts/reports',
    REPORT_DETAIL = '/portal-accounts/report',
    MEDIATIONS = '/portal-accounts/mediations',
    CUSTOMERS_INDEX = '/portal-accounts/customers',
    CUSTOMER_DETAIL = '/portal-accounts/customers/:customerId/detail',
    EMPLOYEE_ACTIVITY_LOGS = '/portal-accounts/activity-logs/employee',
    CUSTOMER_ACTIVITY_LOGS = '/portal-accounts/activity-logs/customer/:customerId',
}

export enum ErrorRoutes {
    INSUFFICIENT_PRIVILEGES = '/insufficient-privileges',
}

export const routesForNotLoggedOnly = [
    Routes.REGISTRATION,
    Routes.REGISTRATION_CONFIRMATION,
    Routes.FORGOT_PASSWORD_CONFIRMATION,
    Routes.PASSWORD_RECOVERY,
    Routes.PASSWORD_RECOVERY_CONFIRMATION,
    Routes.LOGIN_ISSUES,
    Routes.ACCOUNT_DEACTIVATION_CONFIRMATION,
];

export default Routes;
