import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiNotificationCustomerNotificationSummary } from '@spp/spp-meru-frontend-common/src/api';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { formatIsoDateToDuration } from '../../../utils/date-utils';
import { ClickableElement } from '../../common';

const getIconFromEntityType = (notificationEntityType: SkSppNzpBeApiNotificationCustomerNotificationSummary['entityType']) => {
    switch (notificationEntityType) {
        case 'INVOICE':
            return <i className={'icon-file-text fa-lg'} />;
        case 'CUSTOMER_REQUEST':
            return <i className={'icon-attachment-16 fa-lg'} />;
        case 'DELIVERY_POINT':
            return <i className={'icon-home fa-lg'} />;
        default:
            return <i className={'icon-bell fa-lg'} />;
    }
};

const getIconFromCategory = (notificationCategory: string | undefined) => {
    switch (notificationCategory) {
        case 'INVOICE':
            return <i className={'icon-file-text fa-lg'} />;
        case 'CUSTOMER_REQUEST':
            return <i className={'icon-attachment-16 fa-lg'} />;
        case 'DELIVERY_POINT':
            return <i className={'icon-home fa-lg'} />;
        case 'USER':
            return <i className={'icon-user fa-lg'} />;
        case 'PAYMENT':
            return <i className={'icon-credit-card fa-lg'} />;
        case 'DOCUMENT':
            return <i className={'icon-briefcase fa-lg'} />;
        case 'CONSUMPTION':
            return <i className={'icon-chart-bar fa-lg'} />;
        default:
            return <i className={'icon-bell fa-lg'} />;
    }
};

type NotificationsDropdownItemProps = {
    notification: SkSppNzpBeApiNotificationCustomerNotificationSummary;
    isClickable: (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => boolean;
    isReaded: (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => boolean;
    onClick: (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => void;
    onDelete: (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => void;
};

const NotificationsDropdownItem: React.FC<NotificationsDropdownItemProps> = ({ notification, isClickable, isReaded, onClick, onDelete }) => {
    const { t } = useTranslation();
    const clickable = isClickable(notification);
    const readed = isReaded(notification);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [opacity, setOpacity] = useState(0.5);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const { addToast, removeAllToasts } = useToasts();

    const deleteNotification = (event: React.MouseEvent) => {
        event.stopPropagation();
        removeAllToasts();
        if (!!timer) {
            clearTimeout(timer);
            setTimer(undefined);
        }
        if (!confirmDelete) {
            addToast(t('notification-bar.deletion.success-message'), {
                appearance: 'success',
            });
            const timer = setTimeout(() => {
                onDelete(notification);
            }, 5000);
            setTimer(timer);
        }
        setConfirmDelete(!confirmDelete);
    };

    const icon = useMemo(() => {
        if (notification.executionType === 'AUTOMATIC') {
            return getIconFromEntityType(notification.entityType);
        } else {
            return getIconFromCategory(notification.category);
        }
    }, [notification]);

    return (
        <>
            <div
                onClick={clickable ? () => onClick(notification) : undefined}
                className={classNames('dropdown-item', 'd-flex', 'flex-row', 'py-3', 'border-bottom', 'text-decoration-none', 'position-relative', {
                    active: !readed,
                    hoverable: clickable,
                })}
                style={readed ? { padding: 18, background: '#F0F0F0', opacity: '0.75' } : { paddingLeft: 18 }}
            >
                <div style={{ textDecoration: 'none', opacity: confirmDelete ? 0.5 : 1 }}>
                    <span className="text-secondary" style={{ fontSize: '1.2rem', textDecoration: 'none' }}>
                        {icon}
                    </span>
                </div>
                <div
                    className="badge badge-third"
                    style={{ textDecoration: 'none', visibility: readed ? 'hidden' : 'visible', opacity: confirmDelete ? 0.5 : 1 }}
                />
                <div className={classNames('mt-n2 ml-3 mr-4')} style={{ textDecoration: 'none', opacity: confirmDelete ? 0.5 : 1, flex: '1 1 auto' }}>
                    <span
                        className="d-block hovered-from-parent notification-style-reset"
                        dangerouslySetInnerHTML={{ __html: '' + notification.header }}
                    ></span>
                    <span className="text-secondary text-decoration-none" style={{ textDecoration: 'none' }}>
                        {formatIsoDateToDuration(notification.createdAt, t)}
                    </span>
                </div>
                <ClickableElement onClick={deleteNotification} style={{ position: 'absolute', marginTop: '-8px', height: '40px', right: '15px' }}>
                    {!confirmDelete && (
                        <FontAwesomeIcon
                            className="fa-lg"
                            icon={faTrash}
                            style={{ marginLeft: '10px', marginTop: '10px', opacity: opacity }}
                            onMouseEnter={() => setOpacity(1)}
                            onMouseLeave={() => setOpacity(0.5)}
                        />
                    )}
                    {!!confirmDelete && <FontAwesomeIcon className="fa-lg" icon={faUndo} style={{ marginLeft: '10px', marginTop: '10px' }} />}
                </ClickableElement>
            </div>
        </>
    );
};

export default NotificationsDropdownItem;
