import { faClipboard, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faClipboard as faClipboardSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { AppContext } from '../../app-context';

interface ICopyToClipboardProps {
    text?: string | number;
}

const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ text }) => {
    const [icon, setIcon] = useState<IconDefinition>(faClipboard);
    const { copyToClipboard } = useContext(AppContext);
    const { addToast } = useToasts();
    const { t } = useTranslation();

    if (text === undefined) {
        return null;
    }

    return (
        <span
            onClick={() => copyToClipboard('' + text).then(() => addToast(t('common.copied-to-clipboard')))}
            style={{ cursor: 'pointer', fontSize: '1.2em' }}
            className="color-info"
            onMouseOver={() => {
                setIcon(faClipboardSolid);
            }}
            onMouseOut={() => {
                setIcon(faClipboard);
            }}
        >
            <FontAwesomeIcon icon={icon} size="sm" className="mr-4" />
        </span>
    );
};

export default CopyToClipboard;
