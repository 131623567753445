import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseButton } from '../../../../components/common';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IRootState } from '../../../../reducers';
import { CustomerRequestRoutes } from '../../../../routes/routes';
import Routes from './../../../../routes/routes';
import { downloadHandler } from './../../../../utils/utils';
import SubmissionMessageCard from './components/submission-message-card';

export type SubmissionStateType = {
    userData: {
        email: string;
        firstName: string;
        lastName: string;
    };
    uuid?: string;
    attachmentUuid?: string;
    attachmentUuids?: string[];
};

const SumbissionCustomerRequest: React.FC = () => {
    const history = useHistory();
    const location = useLocation<SubmissionStateType>();
    const api = useApi();

    useEffect(() => {
        if (!location.state?.userData.email) {
            history.replace(CustomerRequestRoutes.SELECT_NEW);
        }
    }, [location.state, history]);

    const {
        user: { isUserLogged },
    } = useSelector((state: IRootState) => state);

    const [pdfBlob, setPdfBlob] = useState<Blob>();

    const { additionalData } = useSelector((state: IRootState) => state.customerRequest);

    const isMultiplePaier = location.state?.attachmentUuids && location.state.attachmentUuids.length > 0;
    const isPaier = location.state?.attachmentUuid;

    const fetchAttachmentContent = useCallback(
        (uuid: string) => api.customerRequests.getAttachmentContent(uuid, { secure: true }).then((res) => res.blob()),
        [api],
    );

    // const fetchAttachmentContentAnonymus = useCallback(
    //     (uuid: string) => api.customerRequests.getAnonymousAttachmentContent(uuid, { secure: true }).then((res) => res.blob()),
    //     [api],
    // );

    const [mutateAttachmentContent] = useMutationWithError<Blob | null, unknown, { uuid: string }>(
        async ({ uuid }) => (isUserLogged ? fetchAttachmentContent(uuid) : null),
        {
            onSuccess: (res) => {
                if (!!res) {
                    setPdfBlob(res);
                    downloadPdfRequest(res);
                }
            },
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const downloadPdfRequest = (blob: Blob) => {
        if (blob) downloadHandler(blob, `request-${new Date().toISOString()}.pdf`, 'application/pdf');
    };

    const handlePdfDownloadClick = () => {
        if (pdfBlob) {
            downloadPdfRequest(pdfBlob);
        } else {
            location.state?.attachmentUuid && mutateAttachmentContent({ uuid: location.state?.attachmentUuid });
        }
    };

    const downloadMultipleRequests = () => {
        const attachmetsUuids = location.state?.attachmentUuids ?? [];
        return Promise.all(
            attachmetsUuids.map(async (uuid) => {
                await mutateAttachmentContent({ uuid });
            }),
        );
    };

    const openRequestDetail = () => {
        if (additionalData?.udpuid) {
            history.push(`${Routes.DELIVERY_POINTS}/${additionalData.udpuid}/requests/${additionalData.dpuuid}/${location.state?.uuid}`);
        } else {
            history.push(`${CustomerRequestRoutes.MY_LIST}/${location.state?.uuid}`);
        }
    };

    const renderPreviewContent = () => {
        if (isMultiplePaier) {
            return (
                <>
                    <h4 className="text-center">
                        <Trans i18nKey="customer-request.message.requests-been-created" />
                    </h4>
                    <p className="text-center">
                        <Trans i18nKey="customer-request.message.requests-must-download" />
                    </p>
                    <BaseButton onClick={downloadMultipleRequests}>
                        <Trans i18nKey="customer-request.message.download-pdf" />
                    </BaseButton>
                </>
            );
        } else if (isPaier) {
            return (
                <>
                    <h4 className="text-center">
                        <Trans i18nKey="customer-request.message.request-been-created" />
                    </h4>
                    <p className="text-center">
                        <Trans i18nKey="customer-request.message.request-must-download" />
                    </p>

                    <BaseButton onClick={handlePdfDownloadClick}>
                        <Trans i18nKey="customer-request.message.download-pdf" />
                    </BaseButton>
                </>
            );
        } else {
            return (
                <>
                    <h4 className="text-center">
                        <Trans i18nKey="customer-request.message.request-been-sent">Žiadosť bola odoslaná</Trans>
                    </h4>
                    <p className="text-center">
                        <Trans i18nKey="customer-request.message.operators-will-inform">
                            Naši operátori vás budú informovať o vybavení vašej žiadosti e-mailom na
                        </Trans>
                        &nbsp;{location.state?.userData.email}.
                    </p>{' '}
                </>
            );
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-lg-center mt-3 mb-5">
                <div className="col-12 col-lg-6">
                    <i className="icon-circle-ok-16 text-success text-center mb-3 d-block" style={{ fontSize: '3em' }}></i>

                    {renderPreviewContent()}

                    {isUserLogged ? (
                        <>
                            {location.state?.uuid && (
                                // <Link to={`${CustomerRequestRoutes.MY_LIST}/${location.state?.uuid}`}>
                                <BaseButton onClick={openRequestDetail}>
                                    <Trans i18nKey="common.ok" />
                                </BaseButton>
                                // </Link>
                            )}
                        </>
                    ) : (
                        <>{!isPaier && !isMultiplePaier && <SubmissionMessageCard userData={location.state?.userData} />}</>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SumbissionCustomerRequest;
