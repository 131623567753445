import { SkSppNzpBeApiCustomeraccessChallenge } from '@spp/spp-meru-frontend-common';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../../components/common';
import useSso from '../../hooks/sso/use-sso';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IApiResponse } from '../../main';
import Routes from '../../routes/routes';

const ConfirmRegistration: React.FC = () => {
    const api = useApi();
    const history = useHistory();
    const urlQuery = useUrlQuery();
    const { ssoLogin } = useSso();
    const backUrl = Routes.HOME;
    const [t] = useTranslation();

    const code = urlQuery.get('challengeCode');
    const uuid = urlQuery.get('challengeCodeUuid');

    const [mutateEmailActivation, { isLoading, error }] = useMutationWithError<any, IApiResponse, SkSppNzpBeApiCustomeraccessChallenge, unknown>(
        async (emailChallengeData: SkSppNzpBeApiCustomeraccessChallenge) => api.customers.confirmRegistration(emailChallengeData, { secure: true }),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                if (response.error?.code === 2010 || response.error?.code === 2005) {
                    return true;
                }

                return false;
            },
        },
    );

    useEffect(() => {
        mutateEmailActivation({
            code: code || '',
            uuid: uuid || '',
        });
    }, [mutateEmailActivation, code, uuid]);

    if (isLoading) return <LoadingIndicator size="large" fullscreen />;

    return (
        <>
            <div className="container">
                <div className="row text-center py-5">
                    <div className="col-12 position-static">
                        {error ? (
                            <>
                                <i className="icon-circle-alert-16 fa-5x text-danger d-block my-3"></i>
                                <h3 className="my-3 text-danger">
                                    <Trans i18nKey="confirm-registration.error" />
                                </h3>
                                <div className="text-center mt-4">
                                    {error?.error?.code === 2010
                                        ? t('confirm-registration.validation-email-expired')
                                        : error?.error?.code === 2005
                                        ? t('confirm-registration.challange-code-error')
                                        : t('confirm-registration.try-again')}
                                    <div className="mt-3">
                                        <button className="btn btn-primary" onClick={() => history.replace(Routes.REGISTRATION)}>
                                            <Trans i18nKey="action.sign-up" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                                <h3 className="my-3 text-success">
                                    <Trans i18nKey="confirm-registration.success" />
                                </h3>
                                <div className="text-center mt-4">
                                    <Trans i18nKey="confirm-registration.success-message">
                                        Your registration was successful. You can now proceed to login.
                                    </Trans>

                                    <div className="mt-3">
                                        <button className="btn btn-primary" onClick={() => ssoLogin({ backUrl })}>
                                            <Trans i18nKey="action.login">Prihlasit sa</Trans>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmRegistration;
