import { SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BaseSwitch from '../../../../../../components/common/base-switch';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import { ClickableElement } from '../../../../../../components/common/clickable-element';
import Input from '../../../../../../components/common/input';
import { TooltipWrapper } from '../../../../../../components/common/tooltip-wrapper';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { BusinessPartnerTypeEnum } from '../../../../../../models/enums';
import { DefaultFormType } from '../../../../../../models/model';
import { CustomerRequestTypeEnum } from '../../../../config/enums';

type Props = DefaultFormType & {
    objectName?: string;
    onCancel: () => void;
    showCancelButton: boolean;
    onSwitchChange: (type: BusinessPartnerTypeEnum) => void;
    activeType: BusinessPartnerTypeEnum;
    customerRequestTemplate?: SkSppNzpBeApiCustomerrequestCustomerRequestTemplate;
};

const CustomerAccountInfoForm: React.FC<Props> = ({
    register,
    errors,
    objectName,
    trigger,
    onCancel,
    showCancelButton,
    onSwitchChange,
    activeType,
    customerRequestTemplate,
}) => {
    const { formRules, phoneRules, businessPartnerNumber } = useFormRules();
    const { t } = useTranslation();
    return (
        <div
            className={classNames({
                'p-5 card': showCancelButton,
            })}
        >
            {showCancelButton && (
                <ClickableElement isText onClick={onCancel} className="text-decoration-underline-inverse form-cancel-button">
                    <Trans i18nKey="customer-request.steps.new-customer-choice.cancel" />
                </ClickableElement>
            )}
            {showCancelButton && <h3 className="mt-1">{t('customer-request.steps.new-customer-choice.enter-customer-number-and-contact')}</h3>}
            <div className="mt-4">
                {customerRequestTemplate?.code !== CustomerRequestTypeEnum.ZOP_US && (
                    <BaseSwitch
                        selectedValue={activeType}
                        onValueSelect={(value) => onSwitchChange(value as BusinessPartnerTypeEnum)}
                        dataButtons={[
                            {
                                id: BusinessPartnerTypeEnum.PERSON,
                                title: <Trans i18nKey="customer-request.steps.businessPartner.person">Súkr. osoba</Trans>,
                            },
                            {
                                id: BusinessPartnerTypeEnum.COMPANY,
                                title: <Trans i18nKey="customer-request.steps.businessPartner.company">Firma</Trans>,
                            },
                        ]}
                    />
                )}
                <Input
                    trigger={trigger}
                    ref={register(businessPartnerNumber)}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name={`${objectName}bpNumber`}
                    label={<Trans i18nKey="customer-request.steps.businessPartner.customer-number-spp">Zákaznícke číslo v SPP</Trans>}
                />
                <Input
                    trigger={trigger}
                    ref={register({ ...formRules.email, ...formRules.required })}
                    errors={errors}
                    type="text"
                    name={`${objectName}email`}
                    label={<Trans i18nKey="customer-request.steps.contact.email">E-mail</Trans>}
                    autoCapitalize="off"
                    autoCorrect="off"
                />
                <TooltipWrapper id="phoneNumber" tooltipContent={t('common.input-rules.phone-number-info')}>
                    <Input
                        id="phoneNumber"
                        trigger={trigger}
                        ref={register({ ...formRules.required, ...phoneRules })}
                        errors={errors}
                        type="tel"
                        name={`${objectName}phoneNumber`}
                        label={<Trans i18nKey="customer-request.steps.contact.phone-number">Tel. číslo</Trans>}
                    />
                </TooltipWrapper>
            </div>
        </div>
    );
};

export default CustomerAccountInfoForm;
