import {
    SkSppNzpBeApiCustomeraccessCustomerAccount,
    SkSppNzpBeApiCustomeraccessCustomerApprovalBase,
    SkSppNzpCommonsUtilsValidListSkSppNzpBeApiCustomeraccessCustomerApprovalBase,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import { Checkbox, ClickableElement, LoadingIndicator } from '../../components/common';
import { HelpModal } from '../../components/help';
import { useApi } from '../../hooks/use-api';
import { useFormRules } from '../../hooks/use-form-rules';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { mergeApprovals } from '../../utils/utils';

type TermsModalType = {
    customer?: SkSppNzpBeApiCustomeraccessCustomerAccount;
};

const TermsModal = ({ customer }: TermsModalType) => {
    const [isTermsAndConditionsOpen, setTermsAndConditionsOpen] = useState<boolean>(false);
    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState<boolean>(false);
    const [termsAndConditionsShown, setTermsAndConditionsShown] = useState<boolean>(false);
    const [privacyPolicyShown, setPrivacyPolicyShown] = useState<boolean>(false);

    const [isModalOpened, setIsModalOpened] = useState<boolean>(true);
    const { formRules } = useFormRules();
    const { register, handleSubmit, errors } = useForm();

    const api = useApi();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const showTermsAndConditions = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setTermsAndConditionsOpen(true);
    };

    const showPrivacyPolicy = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setPrivacyPolicyOpen(true);
    };

    useEffect(() => {
        if (isPrivacyPolicyOpen) setPrivacyPolicyShown(true);
    }, [isPrivacyPolicyOpen, setPrivacyPolicyShown]);

    useEffect(() => {
        if (isTermsAndConditionsOpen) setTermsAndConditionsShown(true);
    }, [isTermsAndConditionsOpen, setTermsAndConditionsShown]);

    const [mutateApprovals, { isLoading }] = useMutationWithError<
        unknown,
        { data: any | null; error: any | null },
        SkSppNzpCommonsUtilsValidListSkSppNzpBeApiCustomeraccessCustomerApprovalBase
    >(async (variables) => (customer?.id ? api.customers.changeApprovals(customer.id, variables, { secure: true }) : null), {
        onSuccess: () => setIsModalOpened(false),
        onErrorWithGlobalErrorHandling: () => {
            addToast(t('change-approvals.error-message'), {
                appearance: 'error',
            });
            return true;
        },
    });

    type FormDataType = {
        termsAndConditions: boolean;
        privacyPolicy: boolean;
    };

    const onSubmit = (formData: FormDataType) => {
        const termsAndConditions = 'CONTRACT_CONDITIONS';
        const privacyPolicy = 'PROTECTION_PERSONAL_DATA';
        const formValues: SkSppNzpBeApiCustomeraccessCustomerApprovalBase[] = [
            { type: termsAndConditions, approval: formData.termsAndConditions },
            { type: privacyPolicy, approval: formData.privacyPolicy },
        ];
        const previousApprovals =
            customer?.approvals?.map((a) => {
                return { type: a.type, approval: a.approval };
            }) || [];
        const mergedApprovals = mergeApprovals(formValues, previousApprovals);
        mutateApprovals({ values: mergedApprovals });
    };

    if (!customer?.id) return null;

    return (
        <>
            <Modal isOpen={isModalOpened} backdrop="static" keyboard={false} centered>
                <div className="modal-header">
                    <h3 className="modal-title">{t('change-approvals.headline')}</h3>
                </div>
                <ModalBody>
                    <p>{t('change-approvals.body-copy')}</p>
                    <hr />
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Checkbox
                            ref={register(formRules.required)}
                            errors={errors}
                            name="termsAndConditions"
                            label={
                                <Trans
                                    i18nKey="consents.terms-and-conditions.check"
                                    components={{
                                        Link: (
                                            <ClickableElement isText onClick={showTermsAndConditions} className="text-decoration-underline-inverse">
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            id={'modalCheckboxTerms'}
                            onChange={() => {
                                if (!termsAndConditionsShown) setTermsAndConditionsOpen(true);
                            }}
                        />
                        <Checkbox
                            ref={register(formRules.required)}
                            errors={errors}
                            name="privacyPolicy"
                            label={
                                <Trans
                                    i18nKey="consents.privacy-policy.check"
                                    components={{
                                        Link: (
                                            <ClickableElement isText onClick={showPrivacyPolicy} className="text-decoration-underline-inverse">
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            id={'modalCheckboxPrivacy'}
                            onChange={() => {
                                if (!privacyPolicyShown) setPrivacyPolicyOpen(true);
                            }}
                        />
                        <button type="submit" className="btn btn-primary btn-block btn-lg my-2">
                            {t('change-approvals.submit')}
                        </button>
                    </form>
                    {isLoading && <LoadingIndicator />}
                </ModalBody>
            </Modal>
            <HelpModal
                show={isTermsAndConditionsOpen}
                wide
                title="consents.terms-and-conditions.title"
                screen="REG"
                field="REG_PORTAL_USAGE_CONDITIONS"
                cancel={() => setTermsAndConditionsOpen(false)}
            />
            <HelpModal
                show={isPrivacyPolicyOpen}
                wide
                title="consents.privacy-policy.title"
                screen="REG"
                field="REG_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setPrivacyPolicyOpen(false)}
            />
        </>
    );
};

export default TermsModal;
