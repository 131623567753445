export enum AuthActionTypesEnum {
    SET_IS_SILENT_REFRESH_RESOLVED = 'SET_IS_SILENT_REFRESH_RESOLVED',
    SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
    SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
    SET_CONTEXT_TOKEN = 'SET_CONTEXT_TOKEN',
    SET_ID_TOKEN = 'SET_ID_TOKEN',
    SET_TOKEN_EXPIRES_IN = 'SET_TOKEN_EXPIRES_IN',
    SET_SECONDARY_ACCESS_TOKEN = 'SET_SECONDARY_ACCESS_TOKEN',
    SET_SECONDARY_REFRESH_TOKEN = 'SET_SECONDARY_REFRESH_TOKEN',
    SET_SECONDARY_CONTEXT_TOKEN = 'SET_SECONDARY_CONTEXT_TOKEN',
    SET_SECONDARY_ID_TOKEN = 'SET_SECONDARY_ID_TOKEN',
    SET_AUTH_TOKENS = 'SET_AUTH_TOKENS',
    SET_SECONDARY_AUTH_TOKENS = 'SET_SECONDARY_AUTH_TOKENS',
}

export type IsSilentRefreshResolvedType = {
    type: AuthActionTypesEnum;
    isTrue: boolean;
};

export type AuthTokensPayload = {
    accessToken: string;
    refreshToken: string;
    idToken: string;
    expiresIn?: number;
    clientId?: string;
};

export type AuthActionType = {
    type: AuthActionTypesEnum;
    payload?: string;
    isTrue?: boolean;
    expiresIn?: number;
    tokens?: AuthTokensPayload;
    accessTokenExpiresIn?: number;
};

export const AuthActions = {
    setIsSilentRefreshResolved: (isResolved: boolean): IsSilentRefreshResolvedType => ({
        type: AuthActionTypesEnum.SET_IS_SILENT_REFRESH_RESOLVED,
        isTrue: isResolved,
    }),
    setAccessToken: (accessToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_ACCESS_TOKEN,
        payload: accessToken,
    }),
    setRefreshToken: (refreshToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_REFRESH_TOKEN,
        payload: refreshToken,
    }),
    setContextToken: (contextToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_CONTEXT_TOKEN,
        payload: contextToken,
    }),
    setIdToken: (idToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_ID_TOKEN,
        payload: idToken,
    }),
    setTokenExpiresIn: (expiresIn: number): AuthActionType => ({
        type: AuthActionTypesEnum.SET_TOKEN_EXPIRES_IN,
        expiresIn: expiresIn,
    }),
    setSecondaryAccessToken: (accessToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_SECONDARY_ACCESS_TOKEN,
        payload: accessToken,
    }),
    setSecondaryRefreshToken: (refreshToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_SECONDARY_REFRESH_TOKEN,
        payload: refreshToken,
    }),
    setSecondaryContextToken: (contextToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_SECONDARY_CONTEXT_TOKEN,
        payload: contextToken,
    }),
    setSecondaryIdToken: (idToken: string): AuthActionType => ({
        type: AuthActionTypesEnum.SET_SECONDARY_ID_TOKEN,
        payload: idToken,
    }),
    setAuthTokens: (
        accessToken: string,
        refreshToken: string,
        idToken: string,
        clientId: string,
        expiresIn?: number,
        accessTokenExpiresIn?: number,
    ): AuthActionType => ({
        type: AuthActionTypesEnum.SET_AUTH_TOKENS,
        accessTokenExpiresIn,
        tokens: {
            accessToken,
            refreshToken,
            idToken,
            expiresIn,
            clientId,
        },
    }),
    setSecondaryAuthTokens: (accessToken: string, refreshToken: string, idToken: string, expiresIn?: number): AuthActionType => ({
        type: AuthActionTypesEnum.SET_SECONDARY_AUTH_TOKENS,
        tokens: {
            accessToken,
            refreshToken,
            idToken,
            expiresIn,
        },
    }),
};
