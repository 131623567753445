import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { buildDeliveryPointQueryKey, LoadingIndicator, QueryKeysEnum, useApi } from '../../../../main';
import { DeliveryPointSwitch } from './delivery-point-switch';

interface IDeliveryPointsSwitchFetchWrappedProps {
    selectedDeliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined;
    onSelect: (dp: SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined) => void;
    unitedDeliveryPointId: string;
    setOptionsCount: (x: number) => void;
}

export const DeliveryPointsSwitchFetchWrapped: React.FC<IDeliveryPointsSwitchFetchWrappedProps> = ({
    selectedDeliveryPoint,
    onSelect,
    unitedDeliveryPointId,
    setOptionsCount,
}) => {
    const [selectableDeliveryPoints, setSelectableDeliveryPoints] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint[]>([]);

    const [selectedDeliveryPointId, setSelectedDeliveryPointId] = useState<string | undefined>(selectedDeliveryPoint?.id);

    useEffect(() => {
        setSelectedDeliveryPointId(selectedDeliveryPoint?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDeliveryPoint?.id]);

    useEffect(() => {
        setOptionsCount(selectableDeliveryPoints.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableDeliveryPoints]);

    const api = useApi();

    const { isLoading: isLoadingUnitedDeliveryPoint } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, unitedDeliveryPointId, 'CONTRACT', 'READING_CYCLE_INFO', { includeInactive: false }],
        async () =>
            api.unitedDeliveryPoints
                .getUdpByUuid(unitedDeliveryPointId, { includeInactive: false, fetch: ['CONTRACT', 'READING_CYCLE_INFO'] }, { secure: true })
                .then((x) => x.data),
        {
            onSuccess: (data) => {
                const deliveryPoints = data?.deliveryPoints;
                if (deliveryPoints == null || deliveryPoints.length === 0) {
                    return;
                }
                const filteredDps = deliveryPoints.filter((dp) => dp.id != null && dp.type != null && dp.contract?.id != null);
                const sortedDps = filteredDps.sort((a, b) => (a.type == null || b.type == null ? 0 : b.type.localeCompare(a.type)));

                setSelectableDeliveryPoints(sortedDps);
                if (sortedDps.length > 0) {
                    const dp = sortedDps[0];
                    setSelectedDeliveryPointId(dp?.id);
                }
            },
        },
    );

    const { data: deliveryPointData, isLoading: isLoadingDeliveryPointData } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        buildDeliveryPointQueryKey(selectedDeliveryPointId),
        async () => {
            const foundDp = selectableDeliveryPoints.find((x) => x.id === selectedDeliveryPointId);
            const contractId = foundDp?.contract?.id;
            if (contractId != null) {
                return api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data);
            }
            return null;
        },
    );
    console.log(selectableDeliveryPoints);
    useEffect(() => {
        if (deliveryPointData != null) {
            onSelect(deliveryPointData);
        }
    }, [deliveryPointData, onSelect]);

    return (
        <>
            {(isLoadingUnitedDeliveryPoint || isLoadingDeliveryPointData) && <LoadingIndicator />}

            <DeliveryPointSwitch
                selectedDeliveryPointId={selectedDeliveryPointId}
                onSelect={setSelectedDeliveryPointId}
                deliveryPoints={selectableDeliveryPoints}
                showInactive={false}
                toggleInactive={undefined}
            />
        </>
    );
};
