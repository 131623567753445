import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import VerifyPhoneNumber from '../../../../../components/common/phone-confirm/verify-phone-number';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { QueryKeysEnum, useReloadQueryCache } from '../../../../../utils/react-query-utils';
import { StepsEnum } from '../phone-number-verification';

type PropsType = {
    phoneNumber: string;
    challengeCode: string;
    setStep: (nextStep: StepsEnum, phoneNumber: string, challengeCode: string) => void;
    onDismiss: () => void;
};

const PhoneNumberVerificationScreen: React.FC<PropsType> = ({ phoneNumber, challengeCode, setStep, onDismiss }) => {
    const [isModalOpened] = useState<boolean>(true);

    const reloadCache = useReloadQueryCache();

    const { addToast } = useToasts();
    const { t } = useTranslation();
    const onSubmit = () => {
        setStep(StepsEnum.Finished, phoneNumber, challengeCode);
        addToast(t('settings.login-details.telephone-number-added'), {
            appearance: 'success',
        });
        reloadCache('delivery-points-search');
        reloadCache('my-delivery-points-search-count');
        reloadCache('shared-delivery-points-search-count');
        reloadCache('delivery-points-search-count-active');
        reloadCache('delivery-points-search-count');
        reloadCache(QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH);
    };

    return (
        <Modal isOpen={isModalOpened} modalClassName="modal-fullscreen" centered>
            <ModalBody>
                <button type="button" onClick={onDismiss} className="close ml-auto" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">
                    <Trans i18nKey="phone-validation.header">Overenie telefónneho čísla</Trans>
                </h3>

                <hr />
                <p className="font-weight-bold">
                    <Trans i18nKey="phone-validation.more-security-reason">
                        Pre zvýšenie bezpečnosti potrebujeme ešte overiť vaše telefónne číslo.
                    </Trans>
                </p>
                <HelpLinkWithModal className="mb-5" title="phone-validation.why" field="OTC_PHONE_VERIFICATION_PURPOSE" />

                <VerifyPhoneNumber
                    phone={phoneNumber ? phoneNumber : ''}
                    phoneChallengeCode={challengeCode ? challengeCode : ''}
                    onSuccess={onSubmit}
                />

                <p className="text-center mt-5">
                    <ClickableElement onClick={onDismiss} isText>
                        <Trans i18nKey="phone-validation.setup-later">Nastaviť neskôr</Trans>
                    </ClickableElement>
                </p>
            </ModalBody>
        </Modal>
    );
};

export default PhoneNumberVerificationScreen;
