import React from 'react';
import { PenComponent } from '../../../../components/common/pen-component';
import RequestInProcess from './request-in-process';

type FormListItemProps = {
    label?: React.ReactNode;
    valueText: React.ReactNode;
    onClick?: () => void;
    isEditable?: boolean;
    isChangeRequested?: boolean;
    customerRequestUuid?: string;
};

const FormListItem: React.FC<FormListItemProps> = ({ label, valueText, onClick, isChangeRequested, isEditable, customerRequestUuid }) => {
    return (
        <li className="list-group-item">
            <div className="row align-items-center">
                <div className="col">
                    {label && (
                        <>
                            <small className="text-secondary">{label}</small>
                            <br />
                        </>
                    )}
                    {valueText}
                </div>
                {isEditable && <PenComponent onClick={onClick || (() => null)} disabled={isChangeRequested} />}
            </div>
            {customerRequestUuid && <RequestInProcess customerRequestUuid={customerRequestUuid} />}
        </li>
    );
};

export default FormListItem;
