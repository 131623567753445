import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { formatBusinessPartner, formatSharingBy } from '../../../utils/utils';
import ListDeliveryPointsCard from './list-delivery-points-card';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common/dist/api';

type Props = {
    deliveryPoints?: UnitedDeliveryPointSummary[];
    onOpen: (deliveryPointSummary: UnitedDeliveryPointSummary) => void;
    onLoading: () => void;
};

const renderNoDataMessage = () => {
    return (
        <table className="table table-light">
            <tbody>
                <tr>
                    <td className="text-center">
                        <Trans i18nKey="common.tables.empty-table" />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const ListDeliveryPointsCards = ({ deliveryPoints, onOpen, onLoading }: Props): ReactElement => {
    let prevDeliveryPoint: UnitedDeliveryPointSummary;

    return (
        <>
            {!deliveryPoints?.length && renderNoDataMessage()}

            <div className="row">
                {deliveryPoints?.map((deliveryPoint, index) => {
                    if (
                        !prevDeliveryPoint ||
                        prevDeliveryPoint.sharing?.by?.email !== deliveryPoint.sharing?.by?.email ||
                        prevDeliveryPoint.businessPartner?.id !== deliveryPoint.businessPartner?.id
                    ) {
                        prevDeliveryPoint = deliveryPoint;
                        return [
                            <div className="col-12 mt-4" key={`card-div-${index}`}>
                                {deliveryPoint.sharing?.by ? (
                                    <>
                                        <h5 className="forms-headline">
                                            <Trans i18nKey="delivery-point.list-delivery-points.shared-with-you">Zdieľa s Vami</Trans>
                                        </h5>
                                        <p>{formatSharingBy(deliveryPoint.sharing)}</p>
                                        <p>{deliveryPoint.businessPartner?.name}</p>
                                    </>
                                ) : (
                                    <p>{formatBusinessPartner(deliveryPoint.businessPartner)}</p>
                                )}
                            </div>,
                            <ListDeliveryPointsCard
                                key={`card-${index}`}
                                deliveryPointSummary={deliveryPoint}
                                onOpen={onOpen}
                                onLoading={onLoading}
                            />,
                        ];
                    }
                    prevDeliveryPoint = deliveryPoint;
                    return (
                        <ListDeliveryPointsCard key={`card-${index}`} deliveryPointSummary={deliveryPoint} onOpen={onOpen} onLoading={onLoading} />
                    );
                })}
            </div>
        </>
    );
};

export default ListDeliveryPointsCards;
