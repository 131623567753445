import React from 'react';
import { Trans } from 'react-i18next';
import { DefaultFormType } from '../../../../../../main';
import BankConnectionFormFields from '../../block-bank-connection/components/bank-connection-form-fields';

type Props = DefaultFormType;

const PaymentBankTransfer: React.FC<Props> = ({ register, errors, trigger, watch, setValue }) => {
    return (
        <>
            <BankConnectionFormFields register={register} errors={errors} trigger={trigger} watch={watch} setValue={setValue} />

            <p className="mt-2 mb-4">
                <Trans i18nKey="customer-request.steps.payment-method.bank-transfer-text"></Trans>
            </p>
        </>
    );
};

export default PaymentBankTransfer;
