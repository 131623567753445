import { useCallback } from 'react';
import { AuthEnum } from '../../models/enums';
import { usePkce } from './use-pkce';

export const useCodeChallenge = (): (() => Promise<string>) => {
    const { createCodeVerifier, createCodeChallenge } = usePkce();

    const getCodeVerifier = useCallback(() => {
        let codeVerifier = getCodeVerifierFromSessionStorage();

        if (!codeVerifier) {
            codeVerifier = createCodeVerifier();
            setCodeVerifierToSessionStorage(codeVerifier);
        }

        return codeVerifier;
    }, [createCodeVerifier]);

    const getCodeChallenge = useCallback(() => {
        return createCodeChallenge(getCodeVerifier());
    }, [createCodeChallenge, getCodeVerifier]);

    return getCodeChallenge;
};

/////////////////////////////////////////////////

function getCodeVerifierFromSessionStorage() {
    return sessionStorage.getItem(AuthEnum.CODE_VERIFIER_NAME);
}

function setCodeVerifierToSessionStorage(codeVerifier: string) {
    sessionStorage.setItem(AuthEnum.CODE_VERIFIER_NAME, codeVerifier);
}
