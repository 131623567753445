import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Cookies from 'universal-cookie';
import { ClickableElement } from '../../components/common/clickable-element';
import LoadingIndicator from '../../components/common/loading-indicator';
import { ANALYTICAL_COOKIES, MARKETING_COOKIES, QueryKeysEnum, SPP_DATA_PRIVACY_PAGE_EN, SPP_DATA_PRIVACY_PAGE_SK } from '../../main';
import CookiesButtons from './components/cookies-buttons';
import { CookieSettingsModal } from './cookies-settings-modal';
import { CookiesInformationFallback } from './cookiesInformationFallback';
import { ICategoryType, ICookiesInformation } from './interface';

export const fetchCookiesInformation = async (): Promise<ICookiesInformation> => {
    const response = await fetch(process.env.REACT_APP_COOKIES_ALERT_URL || '');
    const data = await response.json();
    return data;
};

export enum AcceptCookiesEnum {
    ALL = 'ALL',
    NECESSARY = 'NECESSARY',
}
const cookies = new Cookies();
let GOOGLE_TAG_MANAGER_INITIALIZED = false;

const CookiesAlert = (): JSX.Element => {
    const { t, i18n } = useTranslation();
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [cookiesInformation, setCookiesInformation] = useState<ICookiesInformation>();
    const { data, isLoading, error } = useQuery(QueryKeysEnum.COOKIES_INFORMATION, fetchCookiesInformation, { retry: false });
    const sppDataPrivacyPage = i18n.language === 'en' ? SPP_DATA_PRIVACY_PAGE_EN : SPP_DATA_PRIVACY_PAGE_SK;
    const cookiesName =
        cookiesInformation?.config.name?.concat(cookiesInformation?.config.hash) ??
        CookiesInformationFallback.config.name?.concat(CookiesInformationFallback.config.hash);
    const [showAlert, setShowAlert] = useState(false);
    const cookiesValue: string = cookies.get(cookiesName);

    const handleCookies = (value: string[] | AcceptCookiesEnum) => {
        const days = cookiesInformation?.config.validity ?? 365;
        const currentDate = new Date();
        const validity = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);
        const categoryTypesIds: string[] = [];
        let categoryTypes: ICategoryType[] | undefined = cookiesInformation?.config.categoryTypes;
        if (value === AcceptCookiesEnum.NECESSARY) {
            categoryTypes = cookiesInformation?.config.categoryTypes.filter((categoryType) => categoryType.necessary);
        } else if (value !== AcceptCookiesEnum.ALL) {
            categoryTypes = cookiesInformation?.config.categoryTypes.filter((categoryType) => value.includes(categoryType.id));
        }
        categoryTypes?.forEach((categoryType) => {
            categoryTypesIds?.push(categoryType.id);
            categoryType.subIds.forEach((subId) => categoryTypesIds?.push(subId));
        });

        const newCookiesValue = categoryTypesIds?.join(',');
        cookies.set(cookiesName, newCookiesValue, { path: '/', domain: process.env.REACT_APP_COOKIES_DOMAIN, expires: validity });
        setShowSettingsModal(false);
        setShowAlert(false);
    };

    useEffect(() => {
        if (data && !isLoading) {
            setCookiesInformation(data);
        }
        if (error) {
            setCookiesInformation(CookiesInformationFallback);
        }
    }, [isLoading, data, error]);

    useEffect(() => {
        if (!cookiesInformation) {
            return;
        }
        setShowAlert(cookies.get(cookiesName) ? false : true);
    }, [cookiesName, cookiesInformation]);

    useEffect(() => {
        if (!cookiesInformation) {
            return;
        }
        if (
            !!cookiesValue &&
            !GOOGLE_TAG_MANAGER_INITIALIZED &&
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID &&
            process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID !== 'false'
        ) {
            if (cookiesValue.includes(ANALYTICAL_COOKIES) && cookiesValue.includes(MARKETING_COOKIES)) {
                const tagManagerArgs = {
                    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
                };
                GOOGLE_TAG_MANAGER_INITIALIZED = true;
                TagManager.initialize(tagManagerArgs);
            }
        }
    }, [cookiesValue, cookiesInformation]);

    return (
        <>
            {showAlert && (
                <div className="fixed-bottom bg-white py-4 cookies-alert">
                    {isLoading && <LoadingIndicator />}
                    <p>
                        {t('cookies-alert.info')}
                        <ClickableElement
                            onClick={() => window.open(sppDataPrivacyPage, '_blank')}
                            className="font-weight-500 text-decoration-underline-inverse cookies-clickable"
                        >
                            <span>{t('cookies-alert.privacy-and-personal-data-processing')}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* unbreakable space text with icon always together*/}
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="cookies-external-link" />
                        </ClickableElement>
                    </p>
                    <CookiesButtons setShowSettingsModal={setShowSettingsModal} handleCookies={handleCookies} />
                </div>
            )}
            {showSettingsModal && <CookieSettingsModal cookiesInformation={cookiesInformation} handleCookies={handleCookies} />}
        </>
    );
};

export default CookiesAlert;
