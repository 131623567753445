import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { CodeListTypeEnum, CommodityEnum, IApiResponse, LoadingIndicator, QueryKeysEnum, useApi, useCodeList } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { DeliveryPointCollapseComodity } from './components/delivery-point-collapse-comodity';

type DeliveryPointFormTypeWithComodity = {
    unitedDeliveryPoint: string;
};
const BlockDeliveryPointTranscriptNew: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const { additionalData, partnersDeliveryPoints, content, metadata } = useSelector((state: IRootState) => state.customerRequest);

    const [selectedDeliveryPoints, setSelectedDeliveryPoints] = useState<SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] | undefined>([
        ...(content.deliveryPointsEE ?? []),
        ...(content.deliveryPointsZP ?? []),
    ]);

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const { register, handleSubmit, errors, watch } = useForm<DeliveryPointFormTypeWithComodity>({
        defaultValues: { unitedDeliveryPoint: metadata.unitedDeliveryPointId ?? additionalData?.udpuid },
    });

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId: string }
    >(async ({ contractId }) => api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data));

    const onSubmit = async (formData: DeliveryPointFormTypeWithComodity) => {
        if (!selectedDeliveryPoints) {
            return;
        }
        setLoading(true);
        const deliveryPointsFullInfo: SkSppNzpBeApiCustomerprofileDeliveryPoint[] = [];

        await Promise.all(
            selectedDeliveryPoints?.map(async (dp: any) => {
                const deliveryPoint = await mutateDeliveryPointByContractId({ contractId: dp.contract.id });
                if (deliveryPoint) {
                    deliveryPointsFullInfo.push(deliveryPoint);
                }
            }),
        );
        if (deliveryPointsFullInfo.length > 0) {
            dispatch(CustomerRequestActions.setBusinessPartner(deliveryPointsFullInfo[0].contract?.contractAccount?.businessPartner));
        }
        const selectedZP = deliveryPointsFullInfo?.filter((dp) => dp?.type === CommodityEnum.GAS) ?? [];
        const selectedEE = deliveryPointsFullInfo?.filter((dp) => dp?.type === CommodityEnum.ELECTRICITY) ?? [];

        dispatch(CustomerRequestActions.setDeliveryPointsZP(selectedZP));
        dispatch(CustomerRequestActions.setDeliveryPointsEE(selectedEE));
        if (deliveryPointsFullInfo && deliveryPointsFullInfo?.length > 0) {
            dispatch(
                CustomerRequestActions.setData({
                    selectedDPorBPCountry: countryCodeList?.find((item) => item.code === deliveryPointsFullInfo[0]?.address?.country),
                }),
            );
        }
        dispatch(CustomerRequestActions.setMetaData({ unitedDeliveryPointId: formData.unitedDeliveryPoint }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
        setLoading(false);
    };

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            const unsorted = Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
            const new_obj: Record<string, UnitedDeliveryPointSummary[]> = {};
            Object.keys(unsorted)
                .sort(function(a) {
                    if (a === additionalData.udpuid) {
                        return 1;
                    }
                    return -1;
                })
                .forEach(function(key) {
                    new_obj[key] = unsorted[key];
                });
            return new_obj;
        }
    }, [partnersDeliveryPoints, additionalData]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {loading && <LoadingIndicator />}
            {Object.entries(businessPartners ?? []).map(([bpId, unitedDeliveryPoints]) => {
                const businessPartner = unitedDeliveryPoints[0].businessPartner;
                return businessPartner == null ? null : (
                    <DeliveryPointCollapseComodity
                        register={register}
                        businessPartner={businessPartner}
                        unitedDeliveryPoints={unitedDeliveryPoints}
                        errors={errors}
                        selectedDeliveryPoints={selectedDeliveryPoints}
                        setSelectedDeliveryPoints={setSelectedDeliveryPoints}
                        watch={watch}
                    />
                );
            })}

            <BaseButton type="submit" disabled={selectedDeliveryPoints === undefined || selectedDeliveryPoints?.length === 0}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockDeliveryPointTranscriptNew;
