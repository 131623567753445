import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef } from 'react';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';
import { combineRefs } from '../../utils/utils';

type CheckboxProps = {
    name: string;
    label: string | JSX.Element;
    id: string;
    value?: number | string | undefined;
    checked?: boolean;
    errors?: { [key: string]: any };
    onChange?: () => void;
    className?: string;
    autoFocus?: boolean;
    autoFocusDelay?: number;
    [key: string]: any;
};

const BaseRadio = forwardRef(
    ({ name, label, id, value, checked, errors, onChange, className, autoFocus, autoFocusDelay, ...params }: CheckboxProps, ref: any) => {
        const localRef = useAutofocusRef<HTMLInputElement>(autoFocus, autoFocusDelay);

        if (ref && !!checked) {
            throw new Error("This is not supported by Internet Explorer 11. Don't use BaseRadio with 'checked' when 'ref' is provided!!!");
        }

        return (
            <div
                className={`custom-control custom-radio ${className ?? 'mt-3'}`}
                onClick={() => {
                    if (localRef.current !== null) {
                        localRef.current.click();
                    }
                }}
            >
                <input
                    onChange={onChange}
                    ref={combineRefs<HTMLInputElement>([ref, localRef])}
                    name={name}
                    value={value}
                    type="radio"
                    checked={checked}
                    className="custom-control-input"
                    id={id}
                    {...params}
                />
                <label className="custom-control-label" htmlFor={id}>
                    {label}
                </label>
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name={name} />
                    </div>
                )}
            </div>
        );
    },
);

export default BaseRadio;
