import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import useCodeList from '../../../../../hooks/use-code-list';
import { IAdvancePaymenWithDpId } from '../../../../../models/customer-request-model';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { formatAddressShort } from '../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../config/enums';
import { AdvancePayment } from './components/advance-payment';

type AdvancePaymentValue = {
    advancePayment: { [key: string]: string };
};

export interface ISelectedPaymentPeriod {
    deliveryPoiontId?: string;
    paymentPeriod?: SkSppNzpCommonsApiCodelistCodeListItem;
}

const BlockAdvancePayment: React.FC = () => {
    const dispatch = useDispatch();

    const { content } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[] = useMemo(
        () => [...(content.deliveryPointsZP ?? []), ...(content.deliveryPointsEE ?? [])],
        [content.deliveryPointsEE, content.deliveryPointsZP],
    );

    const [selectedPaymentPeriods, setSelectedPaymentPeriods] = useState<ISelectedPaymentPeriod[]>(
        content.advancePayments?.map((item) => ({ deliveryPoiontId: item.deliveryPointId, paymentPeriod: item.period })) ??
            deliveryPoints.map((dp) => ({ deliveryPoiontId: dp.id, paymentPeriod: dp.contract?.advancePaymentPeriod })),
    );

    const { t } = useTranslation();

    const { data: advancePaymentPeriodCodelist, isLoading } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CONTRACT_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum.CONTRACT_ADVANCE_PAYMENT_PERIOD,
        sort: ['code', 'asc'],
        paging: {
            size: 5000,
        },
    });

    const defaultValues: AdvancePaymentValue = {
        advancePayment:
            content.advancePayments?.reduce((o, key) => ({ ...o, [key.deliveryPointId]: key.amount }), {}) ??
            deliveryPoints.reduce((o, key) => ({ ...o, [key.id || '']: key.advancePayment }), {}) ??
            {},
    };

    const { register, handleSubmit, errors, trigger, watch, getValues } = useForm<AdvancePaymentValue>({ defaultValues });

    const onSubmit = (data: AdvancePaymentValue) => {
        const advancePayments: IAdvancePaymenWithDpId[] = [];

        Object.entries((data.advancePayment as Record<string, string>) ?? []).forEach(([dpId, value]) => {
            advancePayments.push({
                deliveryPointId: dpId,
                amount: Number.isNaN(value) ? Number(0) : Number(value),
                period: selectedPaymentPeriods.find((period) => period.deliveryPoiontId === dpId)?.paymentPeriod,
            });
        });
        dispatch(CustomerRequestActions.setData({ advancePayments }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    useEffect(() => {
        if (
            advancePaymentPeriodCodelist != null &&
            advancePaymentPeriodCodelist.length > 0 &&
            selectedPaymentPeriods.find((item) => !item.paymentPeriod)
        ) {
            // preselect first available peyment period option
            const firstAvailableOption = advancePaymentPeriodCodelist.find((item) => item.code === '01');
            const newAdvancePaymentPeriods = selectedPaymentPeriods.filter((item) => item.paymentPeriod);
            selectedPaymentPeriods
                .filter((item) => !item.paymentPeriod)
                .forEach((item) => newAdvancePaymentPeriods.push({ ...item, paymentPeriod: firstAvailableOption }));
            setSelectedPaymentPeriods(newAdvancePaymentPeriods);
        }
    }, [advancePaymentPeriodCodelist, selectedPaymentPeriods]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {isLoading && <LoadingIndicator />}
            {deliveryPoints?.map((dp, index) => {
                const tariff = content.tariffs?.find((item) => item.deliveryPointId === dp.id);
                return (
                    <div key={`dp_${dp.id}`}>
                        {index !== 0 && <hr />}
                        <div className="tariff-select-dp-name my-4">
                            <span>
                                {formatAddressShort(dp.address)} -{' '}
                                {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')} (
                                {t('common.delivery-point')} {dp.externalId})
                            </span>
                        </div>
                        <AdvancePayment
                            key={dp.id}
                            register={register}
                            errors={errors}
                            trigger={trigger}
                            deliveryPoint={dp}
                            watch={watch}
                            transcript
                            tariff={tariff}
                            advancePaymentPeriodCodelist={advancePaymentPeriodCodelist}
                            selectedPaymentPeriods={selectedPaymentPeriods}
                            setSelectedPaymentPeriods={setSelectedPaymentPeriods}
                            getValues={getValues}
                        />
                    </div>
                );
            })}
            <BaseButton type="submit" disabled={isLoading}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockAdvancePayment;
