import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import useCodeList from '../../../../../hooks/use-code-list';
import { IAdvancePaymentPeriod } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import PaymentPeriodFormFieldsCarbonFootprint from './components/payment-period-form-fields-carbon-footprint';

const BlockPaymentPeriodCarbonFootprint: React.FC = () => {
    const dispatch = useDispatch();
    const [enhancedCodeList, setEnhancedCodeList] = useState<any>(); //TODO: change any
    const [totalPrice, setTotalPrice] = useState(0);
    const { currentBlock, content } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.ADVANCE_PAYMENT_PERIOD;
    const objectData = (content[objectName] as IAdvancePaymentPeriod) ?? { period: 'MONTHLY' };

    const { register, handleSubmit, errors, trigger } = useForm<IAdvancePaymentPeriod>({ defaultValues: objectData });

    const { data: periodCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_PERIOD,
        codeListTypeEnum: CodeListTypeEnum.ADVANCE_PAYMENT_PERIOD,
        paging: {
            size: 10,
        },
    });

    useEffect(() => {
        let prepareTotalPrice = 0;
        const recastZpLevel: (SkSppNzpCommonsApiCodelistCodeListItem & { price?: string }) | undefined = {
            ...content.productLevel?.zpLevel,
            price: content.productLevel?.zpLevel?.attributes?.price,
        };
        if (recastZpLevel && recastZpLevel.price) prepareTotalPrice += parseFloat(recastZpLevel.price || '0');

        const recastEeLevel: (SkSppNzpCommonsApiCodelistCodeListItem & { price?: string }) | undefined = content.productLevel?.eeLevel;
        if (recastEeLevel && recastEeLevel.price) prepareTotalPrice += parseFloat(recastEeLevel.price || '0');

        setTotalPrice(prepareTotalPrice);
    }, [content]);

    useEffect(() => {
        const alteredCodeList = periodCodeList?.map((item) => {
            const months = parseInt(item.attributes?.months || '1', 10);
            const monthPostfix = `${months}months`;

            return { months, monthPostfix, ...item };
        });

        setEnhancedCodeList(alteredCodeList);
    }, [periodCodeList]);

    const onSubmit = (data: IAdvancePaymentPeriod) => {
        const selectedPeriod = periodCodeList?.find((item) => item.uuid === data.period.uuid);
        data.period.attributes = selectedPeriod?.attributes;
        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties(data),
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <PaymentPeriodFormFieldsCarbonFootprint
                register={register}
                errors={errors}
                codeList={enhancedCodeList ?? []}
                totalPrice={totalPrice}
                trigger={trigger}
            />
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockPaymentPeriodCarbonFootprint;
