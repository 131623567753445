import React from 'react';
import { Trans } from 'react-i18next';
import { useShareSingleDeliveryPointContext } from '../../share-delivery-points/contexts/share-single-delivery-point-context';

type PropsType = {
    id?: string;
};
export const ShareButtonCell: React.FC<PropsType> = ({ id }) => {
    const { setShareSingleDeliveryPointModal } = useShareSingleDeliveryPointContext();
    return (
        <td>
            <button
                className="btn btn-primary"
                onClick={() => {
                    id && setShareSingleDeliveryPointModal(id);
                }}
            >
                <Trans i18nKey="common.share" defaults="Zdieľať" />
            </button>
        </td>
    );
};
