import { ErrorMessage } from '@hookform/error-message';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { CommodityEnum, isCustomerRequestPaid } from '../../../../../main';
import { IDeliveryPoint } from '../../../../../models/customer-request-model';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestDataEnum } from '../../../config/enums';
import { DeliveryPointCollapseList } from './components/delivery-point-collapse-list';
import { DeliveryPointList } from './components/delivery-point-list';

type DeliveryPointFormType = IDeliveryPoint & {
    deliveryPointId: string;
};

const BlockDeliveryPointAmountLiabilitiesShort: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, additionalData, partnersDeliveryPoints, metadata, customerRequestTemplate } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const { t } = useTranslation();
    const api = useApi();

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.DELIVERY_POINT;
    const objectData = content[objectName] as IDeliveryPoint;

    const { register, handleSubmit, errors } = useForm<DeliveryPointFormType>({
        defaultValues: {
            deliveryPointId: metadata?.deliveryPointId ?? objectData?.dpId,
        },
    });

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId?: string }
    >(async ({ contractId }) => (contractId ? api.deliveryPoints.getByContractUuid(contractId, {}, { secure: true }).then((res) => res.data) : null));

    const onSubmit = async (data: DeliveryPointFormType) => {
        let foundDp: SkSppNzpBeApiCustomerprofileDeliveryPointSummary | undefined;
        let businessPartnerId: string | undefined;
        let businessPartnerQueue: 'COLLECTIVE' | 'INDIVIDUAL' | undefined;

        Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).find(([bpId, deliveryPoints]) => {
            deliveryPoints.find((item) => {
                return item.deliveryPoints?.find((dp) => {
                    if (dp.id === data.deliveryPointId) {
                        foundDp = dp;
                        businessPartnerId = bpId;
                        businessPartnerQueue = item.businessPartner?.queue;
                        return true;
                    }
                });
            });
        });
        if (foundDp && businessPartnerQueue && businessPartnerId && customerRequestTemplate) {
            const deliveryPoint = await mutateDeliveryPointByContractId({
                contractId: (foundDp as SkSppNzpBeApiCustomerprofileDeliveryPointSummary).contract?.id,
            });
            dispatch(
                CustomerRequestActions.setMetaData({
                    deliveryPointId: data.deliveryPointId,
                    contractId: (foundDp as SkSppNzpBeApiCustomerprofileDeliveryPointSummary).contract?.id,
                    businessPartnerId: businessPartnerId,
                    contractAccountId: deliveryPoint?.contract?.contractAccount?.id,
                }),
            );
            if (isCustomerRequestPaid(customerRequestTemplate, businessPartnerQueue)) {
                dispatch(
                    CustomerRequestActions.setRequestIsPaid({
                        paid: true,
                        comodity: deliveryPoint?.type === 'EE' ? CommodityEnum.ELECTRICITY : CommodityEnum.GAS,
                    }),
                );
            } else {
                dispatch(CustomerRequestActions.setRequestIsPaid({ paid: false }));
            }
            dispatch(CustomerRequestActions.setDeliveryPoint(foundDp));
            dispatch(CustomerRequestActions.setBusinessPartner(undefined));

            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
        }
    };

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const deliveryPointsCount = useMemo(() => {
        let count = 0;
        Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
            deliveryPoints.forEach((item) => item.deliveryPoints?.forEach(() => count++));
        });
        return count;
    }, [businessPartners]);

    return (
        <>
            <p className="text-left text-lg mb-4 mt-0 ">{t('customer-request.steps.delivery-point.select-delivery-point')}</p>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {Object.entries((businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []).map(([bpId, deliveryPoints]) => {
                    const businessPartner = deliveryPoints[0].businessPartner;
                    return businessPartner == null ? null : (
                        <>
                            {deliveryPointsCount > 15 && Object.entries(businessPartners ?? []).length > 1 ? (
                                <DeliveryPointCollapseList
                                    register={register}
                                    businessPartner={businessPartner}
                                    errors={errors}
                                    deliveryPoints={deliveryPoints}
                                    selectedBusinessPartnerId={metadata?.businessPartnerId}
                                />
                            ) : (
                                <DeliveryPointList
                                    register={register}
                                    businessPartner={businessPartner}
                                    errors={errors}
                                    deliveryPoints={deliveryPoints}
                                />
                            )}
                        </>
                    );
                })}
                {errors && (
                    <div style={{ color: 'red' }}>
                        <ErrorMessage errors={errors} name="deliveryPointId" />
                    </div>
                )}
                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockDeliveryPointAmountLiabilitiesShort;
