export enum KeyValueStoreEmployeeActionsEnum {
    SET_VALUE = 'key-value-store-employee/SET_VALUE',
    CLEAR_ALL = 'key-value-store-employee/CLEAR_ALL',
}

export interface ISetValueEmployeeAction {
    type: KeyValueStoreEmployeeActionsEnum.SET_VALUE;
    payload: { key: string; value: unknown };
}

export interface IClearAllEmployeeAction {
    type: KeyValueStoreEmployeeActionsEnum.CLEAR_ALL;
}

export type KeyValueStoreEmployeeActionsType = ISetValueEmployeeAction | IClearAllEmployeeAction;

export const KeyValueStoreEmployeeActions = {
    setValue: (key: string, value: unknown): ISetValueEmployeeAction => ({
        type: KeyValueStoreEmployeeActionsEnum.SET_VALUE,
        payload: { key: key, value: value },
    }),
    clearStore: (): IClearAllEmployeeAction => ({
        type: KeyValueStoreEmployeeActionsEnum.CLEAR_ALL,
    }),
};
