import React from 'react';
import { Trans } from 'react-i18next';
import { CardHeader, CardBody, Card, Collapse } from 'reactstrap';
import HelpText from '../../../../components/help/help-text';

type PropsType = {
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const DifferentIssueCard: React.FC<PropsType> = ({ activeCard, toggleActive }) => {
    const index = 4;
    return (
        <Card>
            <CardHeader role="tab" id={`heading${index}`}>
                <a
                    className={activeCard === index ? '' : 'collapsed'}
                    onClick={(event) => toggleActive(event, index)}
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                >
                    <div className="d-flex">
                        <strong>
                            <Trans i18nKey="login-issues.other-issue">Mám iný problém</Trans>
                        </strong>
                        <i className="icon-chevron-down-16 la-xs text-secondary ml-auto my-auto"></i>
                    </div>
                </a>
            </CardHeader>
            <Collapse isOpen={activeCard === index}>
                <CardBody>
                    <p>
                        <Trans i18nKey="login-issues.contact-customer-support">Kontaktujte Zákaznícku linku SPP a naši operátori Vám pomôžu.</Trans>
                    </p>
                    <HelpText screen="COM" field="COM_FAST_CONTACT" />
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default DifferentIssueCard;
