import { SkSppNzpBeApiCustomerprofileInvoice, SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';

interface IDownloadInvoiceAsPDFButtonProps {
    invoice?: SkSppNzpBeApiCustomerprofileInvoice | null;
    invoiceTypeCodeList?: SkSppNzpCommonsApiCodelistCodeListItem[] | null;
}

export const InvoiceType: React.FC<IDownloadInvoiceAsPDFButtonProps> = ({ invoice, invoiceTypeCodeList }) => {
    const getInvoiceType = () => {
        if (invoice?.type) {
            // display subtype name for invoice type 'M2' (clean electricity / carbon stop)
            if (invoice.type.code === 'M2') {
                const subTypeName = invoice?.subType?.name;
                if (subTypeName) return subTypeName;
            }
            return invoiceTypeCodeList?.find((code) => code.code === invoice?.type?.code)?.name;
        }
    };

    return (
        <>
            {invoice?.typeGroup &&
            (invoice?.typeGroup === 'INVOICE' ||
                invoice?.typeGroup === 'CREDIT' ||
                invoice?.typeGroup === 'ADVANCE_INVOICE' ||
                invoice?.typeGroup === 'REPAYMENT_PLAN') ? (
                <Trans i18nKey={`enums.InvoiceGroup.${invoice?.typeGroup}`} />
            ) : (
                <>{getInvoiceType()}</>
            )}
        </>
    );
};
