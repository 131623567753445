import React, { useEffect, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useApi } from '../hooks/use-api';
import { IRootState } from '../reducers';

const CrashFallback: React.FC<FallbackProps> = ({ error }) => {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const [errorLog, setErrorLog] = useState('');
    const history = useHistory();
    const api = useApi();
    const reduxState = useSelector((state: IRootState) => state);
    useEffect(() => {
        console.error('APP CRASH: ', error);
        const errorMessage = `${error.name}: ${error.message}`;
        const errorStack = JSON.stringify(error.stack);
        const errorTime = new Date().toISOString();
        const errorUrl = `${window.location}`;
        const errorHistoryLocation = JSON.stringify(history.location);
        const errorRedux = JSON.stringify(reduxState);

        // report log to BE
        api.external.logs({
            message: errorMessage,
            params: {
                time: errorTime,
                message: errorMessage,
                stack: errorStack,
                url: errorUrl,
                location: errorHistoryLocation,
                redux: errorRedux,
            },
        });

        setErrorLog(`
**************
TIME
${errorTime}

**************
LOCATION
${errorUrl}
${errorHistoryLocation}

**************
ERROR
${errorMessage}
${errorStack}

**************
REDUX
${errorRedux}
**************`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyErrorToClipboard = () => {
        // IE solution to copy text to clipboard
        const errorLogElement = document.createElement('textarea');
        document.body.appendChild(errorLogElement);
        errorLogElement.setAttribute('id', 'errorLogElement');
        errorLogElement.value = errorLog;
        errorLogElement.select();
        document.execCommand('copy');
        document.body.removeChild(errorLogElement);
        addToast(t('crash-fallback.copy-toast'));
    };
    return (
        <>
            <div className="container">
                <div className="row text-center py-5">
                    <div className="col-12 position-static">
                        <i className="icon-circle-info-16 fa-5x text-warning d-block my-3"></i>
                        <h3 className="my-3 text-warning">
                            <Trans i18nKey="crash-fallback.headline">Ups, niečo sa pokazilo</Trans>
                        </h3>
                        <div className="text-center my-4 ">
                            <Trans i18nKey="crash-fallback.message">Zaznamenali sme interný problem s aplikáciou</Trans>
                        </div>
                        <button className="btn btn-primary btn-lg" onClick={copyErrorToClipboard}>
                            <Trans i18nKey="crash-fallback.copy">Kopírovať chybový záznam</Trans>
                        </button>
                        <br />
                        <br />
                        <button className="btn btn-secondary btn-lg" onClick={() => window.location.replace('/')}>
                            <Trans i18nKey="crash-fallback.refresh">Obnoviť</Trans>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CrashFallback;
