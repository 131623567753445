import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import Checkbox from '../../../../../components/common/checkbox';
import useCodeList from '../../../../../hooks/use-code-list';
import { IAddress, IBankConnection } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties, formatBusinessPartnerName } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import AddressFormFields from './components/address-form-fields';
import BankConnectionFormFields from './components/bank-connection-form-fields';

interface IForm extends Partial<IBankConnection> {
    address?: IAddress;
}

const BlockBankConnectionList: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner, additionalData } = useSelector((state: IRootState) => state.customerRequest);
    const dpArray: SkSppNzpBeApiCustomerprofileDeliveryPoint[] = additionalData?.udpd?.deliveryPoints || [];
    const parsedBussinessPartnerFromAdditionalData = dpArray[0]?.contract?.contractAccount?.businessPartner;
    const [editAddress, setEditAddress] = useState(false);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.BANK_CONNECTION;
    const bankConnection = content[objectName] as IBankConnection;
    const [radioButtonInput, setRadioButtonInput] = useState<string | undefined>(content.bankConnection?.iban);
    const { t } = useTranslation();

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const [displayAddress, setDisplayAddress] = useState<boolean>(content.address ? true : false);

    const address = useMemo(
        () =>
            content.address ??
            (businessPartner?.primaryAddress && {
                ...businessPartner?.primaryAddress,
                number: businessPartner?.primaryAddress?.streetNumber,
                postalCode: businessPartner?.primaryAddress?.zipCode,
                country: content.selectedDPorBPCountry ?? countryCodeList?.find((item) => item.code === businessPartner?.primaryAddress?.country),
                name: formatBusinessPartnerName(businessPartner),
            }) ?? {
                ...parsedBussinessPartnerFromAdditionalData?.primaryAddress,
                number: parsedBussinessPartnerFromAdditionalData?.primaryAddress?.streetNumber,
                postalCode: parsedBussinessPartnerFromAdditionalData?.primaryAddress?.zipCode,
                country:
                    content.selectedDPorBPCountry ??
                    countryCodeList?.find((item) => item.code === parsedBussinessPartnerFromAdditionalData?.primaryAddress?.country),
                name: formatBusinessPartnerName(parsedBussinessPartnerFromAdditionalData),
            },
        [businessPartner, content.address, countryCodeList, parsedBussinessPartnerFromAdditionalData, content.selectedDPorBPCountry],
    );

    const objectData = {
        ...bankConnection,
        address: address,
    };

    const { register, handleSubmit, errors, trigger, watch, setValue, unregister } = useForm<IForm>({ defaultValues: { ...objectData } });

    const onSubmit = (data: IForm) => {
        const iban = data.iban?.replace(/ /g, '').toUpperCase();
        const dataAddress = displayAddress ? data.address ?? address : undefined;
        delete data.address;
        const payload: CustomerRequestPayloadType = {
            [objectName]: dataAddress ? undefined : clearObjectProperties({ ...data, iban }),
            address: dataAddress,
        };

        dispatch(CustomerRequestActions.setData(payload));
        if (editAddress) {
            setEditAddress(false);
            return;
        }

        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const IbanList = useMemo(() => {
        //only ibans start with SK (slovak once) otherwise we missing bic swift
        const list: string[] = [];
        content.deliveryPointsZP?.forEach(
            (dp: SkSppNzpBeApiCustomerprofileDeliveryPoint) =>
                dp.contract?.contractAccount?.iban &&
                dp.contract?.contractAccount?.iban.startsWith('SK') &&
                list.push(dp.contract.contractAccount.iban),
        );
        content.deliveryPointsEE?.forEach(
            (dp: SkSppNzpBeApiCustomerprofileDeliveryPoint) =>
                dp.contract?.contractAccount?.iban &&
                dp.contract?.contractAccount?.iban.startsWith('SK') &&
                list.push(dp.contract.contractAccount.iban),
        );
        if (list.length === 0) {
            return [];
        }
        return [...new Set(list)];
    }, [content.deliveryPointsEE, content.deliveryPointsZP]);

    useEffect(() => {
        setValue('address', { country: { uuid: address.country?.uuid } });
    }, [address, setValue]);

    const isIbanCustom = useMemo(() => {
        if (!content.bankConnection?.iban || IbanList.find((iban) => iban === content.bankConnection?.iban)) {
            return false;
        }
        return true;
    }, [content.bankConnection?.iban, IbanList]);

    const [displayIbanInput, setDisplayIbanInput] = useState<boolean>(content.address ? true : isIbanCustom);

    useEffect(() => {
        if (IbanList.length > 0 && !content.bankConnection?.iban) {
            setValue('iban', IbanList[0]);
            setRadioButtonInput(IbanList[0]);
        }
        if (IbanList.length === 0) {
            setDisplayIbanInput(true);
        }
    }, [IbanList, content.bankConnection?.iban, setValue]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {!displayIbanInput && (
                    <>
                        {IbanList.map((iban) => (
                            <Card key="bpKey" className="mb-3">
                                <CardBody>
                                    <BaseRadio
                                        value={iban}
                                        id={`businessPartnerEmailRadio_${iban}`}
                                        name="iban"
                                        className="mt-0"
                                        label={<>{iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ')}</>}
                                        onClick={() => {
                                            setRadioButtonInput(iban);
                                            setValue('iban', iban);
                                            setValue('bicSwift', iban);
                                        }}
                                        checked={radioButtonInput === iban}
                                    />
                                </CardBody>
                            </Card>
                        ))}
                    </>
                )}
                <div className="mb-4">
                    {IbanList.length > 0 && (
                        <div
                            className="cursor-pointer clickable-element mb-1"
                            style={{ textAlign: 'center', textDecoration: 'underline' }}
                            onClick={() => {
                                if (displayIbanInput) {
                                    setRadioButtonInput(undefined);
                                    setDisplayAddress(false);
                                    setValue('iban', '');
                                }
                                setDisplayIbanInput(!displayIbanInput);
                            }}
                        >
                            {displayIbanInput ? (
                                <p>
                                    <Trans i18nKey="customer-request.steps.bank-connection-transcript.choose-from-list" />
                                </p>
                            ) : (
                                <p className="mb-0">
                                    <Trans i18nKey="customer-request.steps.bank-connection-transcript.different-account" />
                                </p>
                            )}
                        </div>
                    )}
                    <div style={displayIbanInput ? {} : { display: 'none' }}>
                        {
                            <div style={{ opacity: displayAddress ? 0 : 1 }}>
                                <BankConnectionFormFields
                                    register={register}
                                    errors={errors}
                                    trigger={trigger}
                                    watch={watch}
                                    setValue={setValue}
                                    disabled={displayAddress}
                                />
                            </div>
                        }
                        <Checkbox
                            id="not-bank-account"
                            name="not-bank-account"
                            label={t('customer-request.steps.bank-connection-transcript.dont-have-bank-account')}
                            onChange={() => {
                                unregister('iban');
                                unregister('bicSwift');
                                setDisplayAddress(!displayAddress);
                            }}
                            checked={displayAddress}
                        />
                        {displayAddress && (
                            <AddressFormFields
                                register={register}
                                editAddress={editAddress}
                                setEditAddress={setEditAddress}
                                errors={errors}
                                trigger={trigger}
                                watch={watch}
                                setValue={setValue}
                                address={address}
                                countryCodeList={countryCodeList}
                            />
                        )}
                    </div>
                </div>

                <BaseButton type="submit" disabled={editAddress}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockBankConnectionList;
