import React from 'react';
import { useTranslation } from 'react-i18next';
import { IInvoice } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IInvoice;
    bold?: boolean;
};

const ComplainedInvoiceItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { billingPeriod, issueDate, vs } = data;

    return (
        <>
            <ItemPreview>
                {vs && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-invoice.variable-symbol')} />
                        <ItemPreviewContent bold={bold}>{vs}</ItemPreviewContent>
                    </>
                )}
                {issueDate && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-invoice.invoice-date')} />
                        <ItemPreviewContent bold={bold}>{formatDateToPreview(issueDate)}</ItemPreviewContent>
                    </>
                )}

                {billingPeriod && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-invoice.invoice-period')} />
                        <ItemPreviewContent bold={bold}>{`${formatDateToPreview(billingPeriod.from)} - ${formatDateToPreview(
                            billingPeriod.to,
                        )}`}</ItemPreviewContent>
                    </>
                )}
            </ItemPreview>
        </>
    );
};

export default ComplainedInvoiceItem;
