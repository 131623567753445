import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../components/common';
import { NotificationTemplateCode, NotificationTemplateGroup } from '../../../../models/model';
import BulkNotificationSettingOverview from './bulk-notification-setting-overview';
import BulkNotificationSettingSelectDeliveryPoints from './bulk-notification-setting-select-delivery-points';
import BulkNotificationSettingSelectNotifications from './bulk-notification-setting-select-notifications';

enum BulkNotificationSettingScreens {
    OVERVIEW,
    SET_NOTIFICATIONS,
    SET_DELIVERY_POINTS,
}

interface IBulkNotificationSettingModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

const BulkNotificationSettingModal: React.FC<IBulkNotificationSettingModalProps> = ({ isOpen, onCloseModal }) => {
    const [selectedNotificationTemplateGroups, setSelectedNotificatonGroups] = useState<NotificationTemplateGroup[]>([]);
    const [selectedNotificationTemplateTypes, setSelectedNotificatonTypes] = useState<NotificationTemplateCode[]>([]);

    const [selectedDeliveryPointIds, setSelectedDeliveryPointIds] = useState<string[]>([]);
    const [notificationChannels, setCommChannels] = useState<{ email: boolean; sms: boolean }>({ email: false, sms: false });
    const [screen, setScreen] = useState<BulkNotificationSettingScreens>(BulkNotificationSettingScreens.OVERVIEW);

    const onSelectNotifications = (
        notificationTemplateGroups: NotificationTemplateGroup[],
        notificationTempalateTypes: NotificationTemplateCode[],
    ) => {
        setSelectedNotificatonGroups(notificationTemplateGroups);
        setSelectedNotificatonTypes(notificationTempalateTypes);
        setScreen(BulkNotificationSettingScreens.OVERVIEW);
    };

    const onSelectDeliveryPoints = (deliveryPointIds: string[]) => {
        setSelectedDeliveryPointIds(deliveryPointIds);
        setScreen(BulkNotificationSettingScreens.OVERVIEW);
    };

    const onSuccess = () => {
        onCloseModal();
    };

    useEffect(() => {
        if (isOpen) {
            setSelectedNotificatonGroups([]);
            setSelectedDeliveryPointIds([]);
            setCommChannels({ email: false, sms: false });
            setScreen(BulkNotificationSettingScreens.OVERVIEW);
        }
    }, [isOpen]);

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="settings.notifications.bulk-notification-setting.bulk-notification-setting" />
                    </h3>
                    <button type="button" onClick={onCloseModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody className="px-4 pb-5">
                    {screen === BulkNotificationSettingScreens.OVERVIEW && (
                        <BulkNotificationSettingOverview
                            selectedNotificationTemplateGroups={selectedNotificationTemplateGroups}
                            selectedNotificationTemplateTypes={selectedNotificationTemplateTypes}
                            selectedDeliveryPointIds={selectedDeliveryPointIds}
                            notificationChannels={notificationChannels}
                            setCommChannels={setCommChannels}
                            startSelectNotifications={() => setScreen(BulkNotificationSettingScreens.SET_NOTIFICATIONS)}
                            startSelectDeliveryPoints={() => setScreen(BulkNotificationSettingScreens.SET_DELIVERY_POINTS)}
                            onCancel={onCloseModal}
                            onSuccess={onSuccess}
                        />
                    )}
                    {screen === BulkNotificationSettingScreens.SET_NOTIFICATIONS && (
                        <>
                            <div className="mb-4">
                                <ClickableElement onClick={() => setScreen(BulkNotificationSettingScreens.OVERVIEW)}>
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-3 text-muted" />
                                </ClickableElement>
                            </div>
                            <BulkNotificationSettingSelectNotifications
                                selectedNotificationTemplateGroups={selectedNotificationTemplateGroups}
                                onSelect={onSelectNotifications}
                            />
                        </>
                    )}
                    {screen === BulkNotificationSettingScreens.SET_DELIVERY_POINTS && (
                        <>
                            <div className="mb-4">
                                <ClickableElement onClick={() => setScreen(BulkNotificationSettingScreens.OVERVIEW)}>
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-3 text-muted" />
                                </ClickableElement>
                            </div>
                            <BulkNotificationSettingSelectDeliveryPoints
                                selectedDeliveryPointIds={selectedDeliveryPointIds}
                                onSelect={onSelectDeliveryPoints}
                            />
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default BulkNotificationSettingModal;
