import { SkSppNzpBeApiCustomerprofileAddress, SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, CardBody } from 'reactstrap';
import { LoadingIndicator } from '../../../../../components/common';
import useCodeList from '../../../../../hooks/use-code-list';
import {
    BaseRadio,
    BaseSelect,
    Checkbox,
    ClickableElement,
    formatAddress,
    formatBusinessPartnerName,
    HelpModal,
    IRootState,
    QueryKeysEnum,
    RequestTemplateCodeExtra,
    useFormRules,
} from '../../../../../main';
import { ConsumptionCategoryEnum, PaymentMethodCodeEnum } from '../../../../../models/enums';
import { CodeListTypeEnum } from '../../../../customer-request/config/enums';
import BankConnectionFormFields from '../../../../customer-request/screen/steps-customer-request/block-bank-connection/components/bank-connection-form-fields';
import PaymentBankTransfer from '../../../../customer-request/screen/steps-customer-request/block-payment-method/components/payment-bank-transfer';
import PaymentSipo from '../../../../customer-request/screen/steps-customer-request/block-payment-method/components/payment-sipo';

export interface IPaymentMethodData {
    paymentType: string | undefined;
    paymentTypeId: string | undefined;
    iban?: string;
    bicSwift?: string;
    sipoNumber?: string;
    deliveryCategory?: ConsumptionCategoryEnum;
    isUsedInCarbonFootprint?: boolean;
    showSipo?: boolean;
    inkasoEmail?: string;
    processManualOverride?: boolean;
}

interface IPaymentMethodFormFields {
    iban?: string;
    bicSwift?: string;
    sipoNumber?: string;
    inkasoEmail?: string;
}

type Props = {
    currentValue: IPaymentMethodData;
    editedValue: IPaymentMethodData | undefined;
    onReset: () => void;
    onSubmit: (value: IPaymentMethodData) => void;
    correspondenceAddress?: SkSppNzpBeApiCustomerprofileAddress;
    hideMessageAboutCurrentPaymentMethod?: boolean;
    requestType?: RequestTemplateCodeExtra;
    inkasoEmails?: string[];
    deliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
};

const ChangePaymentMethodForm: React.FC<Props> = ({
    currentValue,
    editedValue,
    onReset,
    onSubmit,
    hideMessageAboutCurrentPaymentMethod = false,
    requestType,
    inkasoEmails = [],
    deliveryPoint,
}) => {
    const displayValue = editedValue ?? currentValue;
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodCodeEnum>(PaymentMethodCodeEnum.INKASO);
    const [isInkasoPrivacyPolicyOpen, setInkasoPrivacyPolicyOpen] = useState<boolean>(false);

    const saveRequested = useSelector((state: IRootState) => state.customerRequest.content.saveRequested);

    const { formRules } = useFormRules();

    useEffect(() => {
        setSelectedPaymentMethod(
            displayValue.paymentType == null ||
                !Object.values(PaymentMethodCodeEnum).includes(displayValue.paymentType as PaymentMethodCodeEnum) ||
                displayValue.paymentType === PaymentMethodCodeEnum.VOUCHER ||
                displayValue.paymentType === PaymentMethodCodeEnum.INKASO_OTHER_BANK ||
                (displayValue.paymentType === PaymentMethodCodeEnum.SIPO && !displayValue.showSipo) ||
                (displayValue.paymentType === PaymentMethodCodeEnum.INKASO && !displayValue.inkasoEmail)
                ? PaymentMethodCodeEnum.INKASO
                : (displayValue.paymentType as PaymentMethodCodeEnum),
        );
    }, [displayValue.paymentType, displayValue.showSipo, displayValue.inkasoEmail]);

    const { register, unregister, handleSubmit, errors, trigger, watch, setValue, getValues } = useForm<IPaymentMethodFormFields>({
        defaultValues: {
            iban: displayValue.iban,
            bicSwift: displayValue.bicSwift,
            sipoNumber: displayValue.sipoNumber,
            inkasoEmail: displayValue.inkasoEmail,
        },
    });

    useEffect(() => {
        if (displayValue.iban) {
            const preview = displayValue.iban
                .replace(/[^\da-zA-Z]/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim();
            setValue('iban', preview);
            trigger('iban');
        }
        if (displayValue.sipoNumber) {
            setValue('sipoNumber', displayValue.sipoNumber);
        }
        if (displayValue.inkasoEmail) {
            setValue('inkasoEmail', displayValue.inkasoEmail);
        }
    }, [displayValue.iban, displayValue.sipoNumber, displayValue.inkasoEmail, selectedPaymentMethod, setValue, trigger]);

    const { data: codelistsData, isLoading: isLoadingCodelists } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const isNewValueEqualToCurrentValue = useCallback(
        (newValue: IPaymentMethodFormFields) => {
            const normalize = (x: string | undefined) => x?.replace(/ /g, '').toUpperCase() || '';
            if (selectedPaymentMethod === currentValue.paymentType) {
                if (selectedPaymentMethod === PaymentMethodCodeEnum.SIPO) {
                    return normalize(newValue.sipoNumber) === normalize(currentValue.sipoNumber);
                }
                if (selectedPaymentMethod === PaymentMethodCodeEnum.BANK_TRANSFER) {
                    return (
                        normalize(newValue.iban) === normalize(currentValue.iban) && normalize(newValue.bicSwift) === normalize(currentValue.bicSwift)
                    );
                }
                if (selectedPaymentMethod === PaymentMethodCodeEnum.INKASO) {
                    return (
                        normalize(newValue.iban) === normalize(currentValue.iban) &&
                        normalize(newValue.bicSwift) === normalize(currentValue.bicSwift) &&
                        normalize(newValue.inkasoEmail) === normalize(currentValue.inkasoEmail)
                    );
                }
                if (selectedPaymentMethod === PaymentMethodCodeEnum.VOUCHER) {
                    return true;
                }
                return true;
            }
            return false;
        },
        [
            selectedPaymentMethod,
            currentValue.paymentType,
            currentValue.sipoNumber,
            currentValue.iban,
            currentValue.bicSwift,
            currentValue.inkasoEmail,
        ],
    );

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const handleUnchangedValue = () => {
        addToast(t('delivery-point.detail.given-data-is-the-same-as-current-data'), {
            appearance: 'warning',
        });
    };

    const submitHelpFunction = (fields: IPaymentMethodFormFields) => {
        const codelist = codelistsData?.find((codelist) => codelist.code === selectedPaymentMethod);
        const selectedPaymentTypeId = codelist?.uuid;
        let processManualOverride = deliveryPoint?.contract?.contractAccount?.billingIban ? true : false;
        if (
            deliveryPoint?.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.BANK_TRANSFER ||
            deliveryPoint?.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.INKASO
        ) {
            processManualOverride =
                deliveryPoint?.contract?.contractAccount?.iban && !deliveryPoint?.contract?.contractAccount?.billingIban ? false : true;
        }
        if (isNewValueEqualToCurrentValue(fields) && !currentValue.isUsedInCarbonFootprint) {
            handleUnchangedValue();
            return;
        }
        if (selectedPaymentMethod === PaymentMethodCodeEnum.SIPO) {
            onSubmit({
                paymentType: selectedPaymentMethod,
                paymentTypeId: selectedPaymentTypeId,
                sipoNumber: fields.sipoNumber,
                processManualOverride,
            });
        } else if (selectedPaymentMethod === PaymentMethodCodeEnum.BANK_TRANSFER || selectedPaymentMethod === PaymentMethodCodeEnum.INKASO) {
            const iban = fields?.iban?.replace(/ /g, '').toUpperCase();

            onSubmit({
                paymentType: selectedPaymentMethod,
                paymentTypeId: selectedPaymentTypeId,
                iban,
                bicSwift: fields.bicSwift,
                ...(selectedPaymentMethod === PaymentMethodCodeEnum.INKASO ? { inkasoEmail: fields.inkasoEmail } : {}),
                processManualOverride: processManualOverride,
            });
        } else {
            onSubmit({ paymentType: selectedPaymentMethod, paymentTypeId: selectedPaymentTypeId, processManualOverride });
        }
    };

    const onFormSubmit = handleSubmit((fields: IPaymentMethodFormFields) => {
        submitHelpFunction(fields);
    });

    useEffect(() => {
        if (saveRequested) {
            const data = getValues();
            submitHelpFunction(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveRequested]);

    const valIban = watch('iban');
    const valBic = watch('bicSwift');
    const valSipo = watch('sipoNumber');
    const valInkasoEmail = watch('inkasoEmail');
    const isSubmitAllowed = useMemo(() => {
        return (
            !isNewValueEqualToCurrentValue({
                iban: valIban,
                bicSwift: valBic,
                sipoNumber: valSipo,
                inkasoEmail: valInkasoEmail,
            }) || currentValue.isUsedInCarbonFootprint
        );
    }, [valIban, valBic, valSipo, valInkasoEmail, currentValue.isUsedInCarbonFootprint, isNewValueEqualToCurrentValue]);

    return (
        <>
            {isLoadingCodelists && <LoadingIndicator />}

            <form onSubmit={onFormSubmit} noValidate onReset={onReset}>
                <Card className="mb-3">
                    <CardBody>
                        <BaseRadio
                            id="radio-payment-method-inkaso"
                            onChange={() => setSelectedPaymentMethod(PaymentMethodCodeEnum.INKASO)}
                            checked={selectedPaymentMethod === PaymentMethodCodeEnum.INKASO}
                            label={
                                <>
                                    <b>
                                        <Trans i18nKey="customer-request.steps.payment-method.inkaso">Inkaso</Trans>
                                    </b>
                                    <span className="ml-3 d-inline-block">
                                        {!hideMessageAboutCurrentPaymentMethod && currentValue.paymentType === PaymentMethodCodeEnum.INKASO && (
                                            <Trans i18nKey="delivery-point.detail.contact-data.current-payment-method" />
                                        )}
                                    </span>
                                </>
                            }
                        />

                        {selectedPaymentMethod === PaymentMethodCodeEnum.INKASO && (
                            <>
                                <div className="my-4">
                                    <Trans i18nKey="customer-request.payment-will-be-processed-by-advance-payment-or-invoice-due-date" />
                                </div>
                                {deliveryPoint?.contract?.contractAccount?.businessPartner && (
                                    <div className="my-4">
                                        <Trans i18nKey="customer-request.steps.payment-method.payer-info">Údaje platiteľa:</Trans>
                                        <div className="attribute-info">
                                            {formatBusinessPartnerName(deliveryPoint.contract.contractAccount.businessPartner)}
                                            <br />
                                            {formatAddress(deliveryPoint.contract.contractAccount.businessPartner.primaryAddress)}
                                        </div>
                                    </div>
                                )}
                                <div className="my-4">
                                    <BankConnectionFormFields
                                        register={register}
                                        errors={errors}
                                        trigger={trigger}
                                        watch={watch}
                                        setValue={setValue}
                                    />
                                </div>
                                {Array.isArray(inkasoEmails) && inkasoEmails.length > 0 ? (
                                    <>
                                        <div className="my-4">
                                            <Trans i18nKey="customer-request.inkaso-activation-mandat-instructions-will-be-sent-by-email" />

                                            <BaseSelect ref={register({ ...formRules.required })} name="inkasoEmail" label={t('common.email')}>
                                                {inkasoEmails.map((emailOption) => (
                                                    <option key={'emailOption' + emailOption} value={emailOption}>
                                                        {emailOption}
                                                    </option>
                                                ))}
                                            </BaseSelect>
                                        </div>
                                        <Checkbox
                                            ref={register({ ...formRules.required })}
                                            errors={errors}
                                            className="mb-1"
                                            id="personalInfoProcessingConcent"
                                            name="personalInfoProcessingConcent"
                                            label={
                                                <Trans
                                                    i18nKey="customer-request.steps.payment-method.inkaso-statement"
                                                    components={{
                                                        Link: (
                                                            <ClickableElement
                                                                isText
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setInkasoPrivacyPolicyOpen(true);
                                                                }}
                                                                className="text-decoration-underline-inverse"
                                                            >
                                                                &nbsp
                                                            </ClickableElement>
                                                        ),
                                                    }}
                                                />
                                            }
                                            onChange={(e) => {
                                                if (e.target.checked) setInkasoPrivacyPolicyOpen(true);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <div>
                                        <p>
                                            <Trans i18nKey="customer-request.inkaso-activation-instructions-will-be-sent-by-email" />
                                        </p>
                                    </div>
                                )}
                                <HelpModal
                                    show={isInkasoPrivacyPolicyOpen}
                                    title="consents.mandate-for-inkaso.title"
                                    screen="NZD"
                                    field="NZD_MANDATE_FOR_INKASO"
                                    cancel={() => setInkasoPrivacyPolicyOpen(false)}
                                />
                            </>
                        )}
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardBody>
                        <BaseRadio
                            id="radio-payment-method-bank-transfer"
                            onChange={() => setSelectedPaymentMethod(PaymentMethodCodeEnum.BANK_TRANSFER)}
                            checked={selectedPaymentMethod === PaymentMethodCodeEnum.BANK_TRANSFER}
                            label={
                                <>
                                    {' '}
                                    <b>
                                        <Trans i18nKey="customer-request.steps.payment-method.bank-transfer">Bankový prevod</Trans>
                                    </b>
                                    <span className="ml-3 d-inline-block">
                                        {!hideMessageAboutCurrentPaymentMethod &&
                                            currentValue.paymentType === PaymentMethodCodeEnum.BANK_TRANSFER && (
                                                <Trans i18nKey="delivery-point.detail.contact-data.current-payment-method" />
                                            )}
                                    </span>
                                </>
                            }
                        />

                        {selectedPaymentMethod === PaymentMethodCodeEnum.BANK_TRANSFER && (
                            <>
                                <div className="my-4">
                                    <PaymentBankTransfer register={register} errors={errors} trigger={trigger} watch={watch} setValue={setValue} />
                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>

                {(currentValue.deliveryCategory === ConsumptionCategoryEnum.HOME ||
                    currentValue.paymentType === PaymentMethodCodeEnum.SIPO ||
                    currentValue.showSipo) && (
                    <Card className="mb-3">
                        <CardBody>
                            <BaseRadio
                                id="radio-payment-method-sipo"
                                onChange={() => setSelectedPaymentMethod(PaymentMethodCodeEnum.SIPO)}
                                checked={selectedPaymentMethod === PaymentMethodCodeEnum.SIPO}
                                label={
                                    <>
                                        <b>
                                            <Trans i18nKey="customer-request.steps.payment-method.sipo">SIPO</Trans>
                                        </b>
                                        <span className="ml-3 d-inline-block">
                                            {currentValue.paymentType === PaymentMethodCodeEnum.SIPO && (
                                                <Trans i18nKey="delivery-point.detail.contact-data.current-payment-method" />
                                            )}
                                        </span>
                                    </>
                                }
                            />

                            {selectedPaymentMethod === PaymentMethodCodeEnum.SIPO && requestType !== 'ZOM_CE' && (
                                <>
                                    <div className="my-4">
                                        <PaymentSipo
                                            register={register}
                                            unregister={unregister}
                                            errors={errors}
                                            trigger={trigger}
                                            setValue={setValue}
                                        />
                                    </div>
                                </>
                            )}
                        </CardBody>
                    </Card>
                )}
                {currentValue.paymentType === PaymentMethodCodeEnum.VOUCHER && (
                    <Card className="mb-3">
                        <CardBody>
                            <BaseRadio
                                className="mt-1"
                                id="radio-payment-method-voucher"
                                onChange={() => setSelectedPaymentMethod(PaymentMethodCodeEnum.VOUCHER)}
                                checked={selectedPaymentMethod === PaymentMethodCodeEnum.VOUCHER}
                                disabled
                                label={
                                    <>
                                        <b>
                                            <Trans i18nKey="customer-request.steps.payment-method.voucher">Poštová poukážka</Trans>
                                        </b>
                                        <span className="ml-3 d-inline-block">
                                            {currentValue.paymentType === PaymentMethodCodeEnum.VOUCHER && (
                                                <Trans i18nKey="delivery-point.detail.contact-data.current-payment-method" />
                                            )}
                                        </span>
                                    </>
                                }
                            />

                            {/* {selectedPaymentMethod === PaymentMethodCodeEnum.VOUCHER && ( */}
                            {/* <> */}
                            <div className="my-4">
                                <p>
                                    <Trans i18nKey="delivery-point.detail.contact-data.voucher-will-be-sent-to-correspondence-address" />
                                </p>
                                {/* <p>
                                            {correspondenceAddress?.street || ''} {correspondenceAddress?.streetNumber || ''}
                                            <br />
                                            {correspondenceAddress?.zipCode || ''} {correspondenceAddress?.city || ''}
                                        </p> */}
                            </div>
                            {/* </>
                            )} */}
                        </CardBody>
                    </Card>
                )}

                {/* Help button */}
                {/* <HelpLinkWithModal showAsCard className="my-4" linkTitle="common.dialog-help" field="DOM_PAYMENT_TYPE_CHANGE_HELP" /> */}

                {currentValue.isUsedInCarbonFootprint ? (
                    <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!isSubmitAllowed}>
                        <Trans i18nKey="common.next" />
                    </Button>
                ) : (
                    <>
                        <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={!isSubmitAllowed}>
                            <Trans i18nKey="delivery-point.detail.contact-data.change-payment-method-button" />
                        </Button>
                        <Button type="reset" block color="secondary" size="lg" className="mt-3">
                            <Trans i18nKey="delivery-point.detail.modals.cancel" />
                        </Button>
                    </>
                )}
            </form>
        </>
    );
};

export default ChangePaymentMethodForm;
