import moment from 'moment';
import { nextTick } from 'process';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import DatePicker from '../../../../../components/common/datePicker/datePicker';
import Input from '../../../../../components/common/input';
import { TooltipWrapper } from '../../../../../components/common/tooltip-wrapper';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IRootState } from '../../../../../reducers';
import { formatPhoneNumber } from '../../../../../utils/utils';
import { CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import { displayTextRelationToRealEstate } from '../../../customer-request-utils';

type DateFormType = {
    date: string;
    phoneNumber?: string;
};

const BlockDate: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, customerRequestTemplate, additionalData } = useSelector((state: IRootState) => state.customerRequest);
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);

    const blockParams = currentBlock?.params;
    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.DATE;
    const objectData = (content[objectName] as string) ?? (blockParams?.defaultCurrentDate ? new Date().toISOString() : undefined);

    const [t] = useTranslation();

    const { formRules, dateRules, phoneRules } = useFormRules();

    const dateValidations = useMemo(() => {
        if (blockParams?.minDate) return dateRules('NOT_OLDER_THAN', new Date().toISOString()).isValidDate;
        else if (blockParams?.maxDate) return dateRules().notFutureDate;
        else return {};
    }, [blockParams, dateRules]);

    const { register, errors, handleSubmit, getValues, setValue, trigger } = useForm<DateFormType>({
        defaultValues: {
            date: objectData,
            phoneNumber:
                (content.preferredContact?.phoneNumber && formatPhoneNumber(content.preferredContact?.phoneNumber)) ??
                (additionalData?.udpd?.businessPartner?.phone && formatPhoneNumber(additionalData?.udpd?.businessPartner?.phone)) ??
                (loggedInCustomer?.phone && formatPhoneNumber(loggedInCustomer?.phone)),
        },
    });

    const onSubmit = (data: DateFormType) => {
        const payload = {
            [objectName]: data.date,
            preferredContact: {
                phoneNumber: data.phoneNumber,
            },
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;

    let rules = {
        ...formRules.required,
        ...dateRules().isValidDate,
        ...dateValidations,
    };

    if (customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZOPAOO) {
        rules = {
            ...formRules.required,
            ...dateRules().isValidDate,
            ...dateValidations,
            ...dateRules(
                'NOT_OLDER_THAN',
                moment()
                    .add(1, 'days')
                    .toISOString(),
            ).isValidDate,
        };
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="mt-4">
            <FormGroup className="d-flex flex-row w-100">
                <DatePicker
                    register={register(rules)}
                    errors={errors}
                    trigger={trigger}
                    getValues={getValues}
                    setValue={setValue}
                    minDate={
                        customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZOPAOO
                            ? moment()
                                  .add(1, 'days')
                                  .toDate()
                            : blockParams?.minDate && new Date()
                    }
                    maxDate={blockParams?.maxDate && new Date()}
                    label={t(`customer-request.steps.${currentBlock?.key}.required-date`)}
                    touched={touched}
                    name="date"
                />
            </FormGroup>
            {blockParams?.phoneNumber && content.contractTerminationReason?.reason?.code !== 'SUPPLIER_CHANGE' && (
                <div className="mt-4">
                    <p>{t(`customer-request.steps.termination-contract-date.technician-will-contact-you`)}</p>

                    <TooltipWrapper id="phoneNumber" tooltipContent={t('common.input-rules.phone-number-info')}>
                        <Input
                            id="phoneNumber"
                            trigger={trigger}
                            ref={register({ ...formRules.required, ...phoneRules })}
                            errors={errors}
                            type="tel"
                            name="phoneNumber"
                            label={
                                <Trans i18nKey="customer-request.steps.termination-contract-date.phone-number-technician">
                                    Telefónny kontakt pre technika
                                </Trans>
                            }
                            touched={touched}
                        />
                    </TooltipWrapper>
                </div>
            )}
            {customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZOUZ &&
                displayTextRelationToRealEstate(content.contractTerminationReason?.reason?.code) && (
                    <p style={{ width: '300px', margin: '75px auto 0px', textAlign: 'center' }}>
                        {t('customer-request.steps.completion-transcript.termination-date-statement')}
                    </p>
                )}
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockDate;
