import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyData } from '../../../actions/add-delivery-point-actions';
import BaseSwitch from '../../../components/common/base-switch';
import { CharacterFilter } from '../../../components/common/character-filtering';
import Input from '../../../components/common/input';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { TooltipWrapper } from '../../../components/common/tooltip-wrapper';
import HelpLinkWithModal from '../../../components/help/help-link-with-modal';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../models/model';
import { formatPhoneNumber } from '../../../utils/utils';
import DeliveryPointBreadcrumb from './add-delivery-point-breadcrumb';

type EnterCompanyDataScreenProps = {
    enterData: (companyData: CompanyData) => void;
    goBack: () => void;
    isLoading: boolean;
    additionalPhone?: string;
};

type FormValuesType = {
    companyId: string;
    externalNumber: string;
    additionalPhone: string;
};

enum ExternalNumberType {
    CUSTOMER = 'CUSTOMER',
    CONTRACT = 'CONTRACT',
}

const EnterCompanyDataScreen: React.FC<EnterCompanyDataScreenProps> = (props) => {
    const [identifier, setIdentifier] = useState<ExternalNumberType>(ExternalNumberType.CUSTOMER);
    const initialRender = useRef(true);
    const { register, handleSubmit, errors, setError, trigger } = useForm({
        defaultValues: { additionalPhone: props.additionalPhone ? props.additionalPhone : '+421', externalNumber: '' },
    });
    const { formRules, phoneRules, requiredTrimed, mergeValidations, firstCharMustBe } = useFormRules();
    const api = useApi();
    const { t } = useTranslation();

    const [mutatePhoneValidate] = useMutationWithError(async (phone: string) => api.validations.checkPasswordComplexityWithPhone({ phone }), {
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error) {
                setError('additionalPhone', {
                    message: t('common.input-rules.phoneNumber'),
                });
                return true;
            }
            return false;
        },
    });

    const onSubmit = (formData: FormValuesType) => {
        const additionalPhone = formatPhoneNumber(formData.additionalPhone);
        const formattedCompanyId = formData.companyId != null ? formData.companyId.padStart(8, '0') : formData.companyId;
        mutatePhoneValidate(additionalPhone).then((result) => {
            if (!!result) {
                props.enterData({
                    companyId: formattedCompanyId,
                    customerNumber: identifier === ExternalNumberType.CUSTOMER && formData.externalNumber ? formData.externalNumber : undefined,
                    contractNumber: identifier === ExternalNumberType.CONTRACT && formData.externalNumber ? formData.externalNumber : undefined,
                    additionalPhone,
                });
            }
        });
    };

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            trigger('externalNumber');
        }
    }, [identifier, trigger]);

    return (
        <>
            <DeliveryPointBreadcrumb goBack={props.goBack} />
            {props.isLoading && <LoadingIndicator />}
            <h4 className="text-center">
                <Trans i18nKey="delivery-point.add-delivery-point.enter-company.title">Zadajte vaše údaje</Trans>:
            </h4>
            <form className="my-3" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                    trigger={trigger}
                    ref={register(
                        mergeValidations({
                            ...requiredTrimed.validate,
                            length: (value: string | undefined) => {
                                if (value == null) return true;
                                if (value.length >= 6 && value.length <= 8) return true;
                                const errorMessage = t('common.input-rules.invalid-company-registration-number-format');
                                return errorMessage;
                            },
                        }),
                    )}
                    errors={errors}
                    type="text"
                    name="companyId"
                    label={t('delivery-point.add-delivery-point.enter-company.ico')}
                />

                <h5 className="forms-headline mt-5">
                    <Trans i18nKey="delivery-point.add-delivery-point.enter-company.switch-title">Zadajte ešte jeden z údajov</Trans>
                </h5>

                <BaseSwitch
                    selectedValue={identifier}
                    dataButtons={[
                        {
                            title: <Trans i18nKey="delivery-point.add-delivery-point.enter-company.switch-customer-number">Zákaznícke číslo</Trans>,
                            id: ExternalNumberType.CUSTOMER,
                        },
                        {
                            title: <Trans i18nKey="delivery-point.add-delivery-point.enter-company.switch-contract-number">Číslo zmluvy</Trans>,
                            id: ExternalNumberType.CONTRACT,
                        },
                    ]}
                    onValueSelect={(value) => {
                        setIdentifier(value as ExternalNumberType);
                    }}
                    className="btn-group d-flex my-3"
                />

                <Input
                    trigger={trigger}
                    ref={register({
                        ...formRules.requiredInteger,
                        validate: {
                            ...requiredTrimed.validate,
                            ...firstCharMustBe(identifier === ExternalNumberType.CONTRACT ? '9' : '5', true).validate,
                            ...formRules.length(10).validate,
                        },
                    })}
                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                    errors={errors}
                    type="number"
                    name="externalNumber"
                    label={
                        identifier === ExternalNumberType.CONTRACT
                            ? t('delivery-point.add-delivery-point.enter-company.switch-contract-number')
                            : t('delivery-point.add-delivery-point.enter-company.switch-customer-number')
                    }
                />

                <p className="mb-0">
                    {identifier === ExternalNumberType.CONTRACT
                        ? t('delivery-point.add-delivery-point.enter-company.contract-number-msg')
                        : t('delivery-point.add-delivery-point.enter-company.customer-number-msg')}
                </p>

                {identifier === ExternalNumberType.CONTRACT && (
                    <HelpLinkWithModal
                        className="text-center d-block mb-5"
                        classNameClickableElement="text-decoration-underline-inverse"
                        linkTitle="delivery-point.add-delivery-point.where-can-be-data-found"
                        title={t('help.screen-field-title.CONTRACT_NUMBER_PLACEMENT_ON_CONTRACT')}
                        field="ODM_CONTRACT_NUMBER_PLACEMENT_ON_CONTRACT"
                    />
                )}

                <div className="mt-5">
                    <TooltipWrapper id="additionalPhone" tooltipContent={t('common.input-rules.phone-number-info')}>
                        <Input
                            id="additionalPhone"
                            trigger={trigger}
                            ref={register({ ...formRules.required, ...phoneRules })}
                            errors={errors}
                            type="text"
                            name="additionalPhone"
                            label={t('delivery-point.add-delivery-point.enter-person.phone-placeholder')}
                        />
                    </TooltipWrapper>
                </div>

                <p>
                    <Trans i18nKey="delivery-point.add-delivery-point.enter-person.phone-msg">
                        Číslo, kde vás môžeme kontaktovať v prípade problémov s načítaním odberných miest.
                    </Trans>
                </p>

                <button type="submit" className="btn btn-primary btn-block btn-lg">
                    <Trans i18nKey="common.confirm">Potvrdiť</Trans>
                </button>
            </form>
        </>
    );
};

export default EnterCompanyDataScreen;
