import { SkSppNzpBeApiCodelistConfigParameter } from '@spp/spp-meru-frontend-common';
import { useApi } from './use-api';
import { useQueryWithError } from './use-query-with-error';

export interface IConfig {
    facebookLogin: boolean;
    googleLogin: boolean;
}

const getValue: (configValue: SkSppNzpBeApiCodelistConfigParameter) => any = (val) => {
    if (val.value === undefined) {
        return;
    }
    switch (val.type) {
        case 'STRING':
            return String(val.value);
        case 'INTEGER':
        case 'LONG':
        case 'DECIMAL':
            return Number.parseFloat(val.value);
        case 'BOOLEAN':
            return val.value === 'true' ? true : false;
        default:
            return JSON.parse(val.value);
    }
};

const useConfig = (): { data: IConfig | undefined; isLoading: boolean } => {
    const api = useApi();

    const { data, isLoading } = useQueryWithError<IConfig>(
        ['config'],
        async () =>
            await api.config
                .getConfig({ secure: true })
                .then((res) => res.data)
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const parsedData = data.reduce<{ [key: string]: any }>((acc, configValue) => {
                            if (!configValue.id) {
                                return acc;
                            }
                            acc[configValue.id] = getValue(configValue);
                            return acc;
                        }, {});

                        return {
                            facebookLogin:
                                typeof parsedData['customer.login.facebookLogin'] === 'boolean' ? parsedData['customer.login.facebookLogin'] : false,
                            googleLogin:
                                typeof parsedData['customer.login.googleLogin'] === 'boolean' ? parsedData['customer.login.googleLogin'] : false,
                        };
                    }
                    return { facebookLogin: false, googleLogin: false };
                }),
        {
            cacheTime: 10 * 60 * 1000,
            staleTime: 10 * 60 * 1000,
            keepPreviousData: true,
        },
    );

    return { data, isLoading };
};

export default useConfig;
