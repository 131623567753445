import { Reducer } from 'react';
import { AppSettingsActionsEnum, AppSettingsActionsType } from '../actions/app-settings-actions';

export interface IAppSettingsStore {
    privacyScreen: boolean;
}

const initialState: IAppSettingsStore = {
    privacyScreen: false,
};

export const AppSettingsReducer: Reducer<IAppSettingsStore, AppSettingsActionsType> = (state = initialState, action) => {
    switch (action.type) {
        case AppSettingsActionsEnum.PRIVACY_SCREEN: {
            return {
                ...state,
                privacyScreen: action.payload,
            };
        }
        default:
            return state;
    }
};
