import { nextTick } from 'process';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useCodeList from '../../../../../hooks/use-code-list';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IContractDelivery } from '../../../../../models/customer-request-model';
import { ContractDeliveryEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties, formatBusinessPartnerName } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import AddressFormFields from '../block-bank-connection-list/components/address-form-fields';

const BlockContractDelivery: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, businessPartner } = useSelector((state: IRootState) => state.customerRequest);
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);
    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.CONTRACT_DELIVERY;
    const contractDelivery = content[objectName] as IContractDelivery;
    const { t, i18n } = useTranslation();
    const { formRules } = useFormRules();
    const [editAddress, setEditAddress] = useState(content.accountInfo ? true : false);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [key, setKey] = useState(0);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY || 'undefined';

    const useRecaptcha =
        process.env.REACT_APP_RECAPTCHA === 'true' &&
        !loggedInCustomer &&
        (content.reCaptchaToken?.remainingUsesCount === undefined || content.reCaptchaToken?.remainingUsesCount === 0);

    const api = useApi();
    const [mutateRecaptchaToken, { isLoading: isLoadingRecaptcha }] = useMutationWithError(
        async (recaptcha: string) =>
            api.customers.reCaptchaToken(
                { tokenType: 'ANONYMOUS_CUSTOMER_REQUEST' },
                { headers: { 'X-GoogleReCaptcha-Code': recaptcha }, secure: true },
            ),
        {
            onSuccess: (resp) => {
                const insertedCustomerEmail = content.accountInfo?.email || content.contact?.email;
                if (resp.data?.token && insertedCustomerEmail) {
                    dispatch(CustomerRequestActions.setData({ reCaptchaToken: resp.data }));
                    mutateCheckEmail({ email: insertedCustomerEmail, recaptchaToken: resp.data?.token });
                }
            },
        },
    );

    const [mutateCheckEmail, { isLoading: isLoadingEmail }] = useMutationWithError(
        async ({ email, recaptchaToken }: { email: string; recaptchaToken: string }) =>
            api.customers.checkEmailExists({ email: email }, { headers: { 'X-ReCaptcha-Token': recaptchaToken }, secure: true }).finally(() => {
                content.reCaptchaToken?.remainingUsesCount &&
                    dispatch(
                        CustomerRequestActions.setData({
                            reCaptchaToken: {
                                ...content.reCaptchaToken,
                                remainingUsesCount: content.reCaptchaToken?.remainingUsesCount - 1,
                            },
                        }),
                    );
            }),
        {
            onSuccess: (resp) => {
                if (resp.data?.exists === false) {
                    dispatch(CustomerRequestActions.addRegistrationBlock());
                } else {
                    dispatch(CustomerRequestActions.removeRegistrationBlock());
                }

                nextTick(() => {
                    dispatch(CustomerRequestActions.nextStep());
                });
            },
        },
    );

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const address = useMemo(() => {
        if (content.contractDelivery?.address) {
            return content.contractDelivery.address;
        }
        if (content.correspondenceAddress) {
            return { ...content.correspondenceAddress, name: formatBusinessPartnerName(content.businessPartner) };
        }
        if (content.address) {
            return { ...content.address, name: formatBusinessPartnerName(content.businessPartner) };
        }

        return {
            name: formatBusinessPartnerName(businessPartner),
            street: businessPartner?.primaryAddress?.street || content.newDeliveryPointAddress?.street,
            number: businessPartner?.primaryAddress?.streetNumber || content.newDeliveryPointAddress?.number,
            city: businessPartner?.primaryAddress?.city || content.newDeliveryPointAddress?.city,
            country: countryCodeList?.find((item) => item.code === businessPartner?.primaryAddress?.country),
            postalCode: businessPartner?.primaryAddress?.zipCode || content.newDeliveryPointAddress?.postalCode,
            floor: content.newDeliveryPointAddress?.floor,
            flatNumber: content.newDeliveryPointAddress?.flatNumber,
        };
    }, [
        content.contractDelivery,
        content.correspondenceAddress,
        content.address,
        content.businessPartner,
        content.newDeliveryPointAddress,
        businessPartner,
        countryCodeList,
    ]);

    const objectData = {
        type: contractDelivery?.type ?? ContractDeliveryEnum.POST,
        address: address,
    };

    const { register, handleSubmit, errors, trigger, watch, setValue, getValues } = useForm<IContractDelivery>({ defaultValues: { ...objectData } });

    const onSubmit = (data: IContractDelivery) => {
        const reCaptchaHelp = recaptchaRef?.current?.getValue();
        if (!editAddress && useRecaptcha && !reCaptchaHelp) {
            setReCaptchaError(true);
            return;
        }
        useRecaptcha && recaptchaRef?.current?.reset();

        const addressFinal = data.address
            ? { ...data.address, country: countryCodeList?.find((item) => item.uuid === data.address.country?.uuid) }
            : address;

        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties({ ...data, address: addressFinal }),
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));

        if (editAddress) {
            setEditAddress(false);
            return;
        }

        if (!loggedInCustomer && reCaptchaHelp) {
            mutateRecaptchaToken(reCaptchaHelp);
            return;
        }
        if (!loggedInCustomer && content.accountInfo && content.reCaptchaToken?.token) {
            mutateCheckEmail({ email: content.accountInfo.email, recaptchaToken: content.reCaptchaToken.token });
            return;
        }

        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        setKey(key + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    return (
        <>
            {(isLoadingEmail || isLoadingRecaptcha) && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="my-5">
                    <p>
                        <b>
                            <Trans i18nKey="customer-request.steps.contract-delivery.way">Spôsob doručenia zmluvy</Trans>
                        </b>
                    </p>
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={ContractDeliveryEnum.POST}
                        id="post"
                        name="type"
                        className="mt-0 py-2"
                        label={<Trans i18nKey="customer-request.steps.contract-delivery.post" />}
                    />
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={ContractDeliveryEnum.DELIVERY_MAN}
                        id="deliveryMan"
                        name="type"
                        className="mt-0 mb-2 py-2"
                        label={<Trans i18nKey="customer-request.steps.contract-delivery.delivery-man" />}
                    />
                    <span>{t('customer-request.steps.contract-delivery.delivery-is-free')}</span>
                </div>
                <p>
                    <b>
                        <Trans i18nKey="customer-request.steps.contract-delivery.address-contract-delivery">Adresa pre doručenie zmluvy</Trans>
                    </b>
                </p>
                <AddressFormFields
                    register={register}
                    editAddress={editAddress}
                    setEditAddress={setEditAddress}
                    errors={errors}
                    trigger={trigger}
                    watch={watch}
                    setValue={setValue}
                    address={address}
                    countryCodeList={countryCodeList}
                    label={false}
                    loggedInCustomer={loggedInCustomer ? true : false}
                />

                {useRecaptcha && !editAddress && (
                    <div className="mt-4">
                        <ReCAPTCHA
                            key={key}
                            ref={recaptchaRef}
                            sitekey={useRecaptcha ? recaptchaSiteKey : 'undefined'}
                            onChange={() => setReCaptchaError(false)}
                            hl={i18n.language === 'en' ? 'en' : 'sk'}
                        />
                        {reCaptchaError && <span style={{ color: 'red' }}>{t('common.input-rules.required')}</span>}
                    </div>
                )}

                <BaseButton type="submit" disabled={editAddress}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockContractDelivery;
