import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofilePairBusinessPartnerRequestScope as PairBusinessPartnerRequestScope,
    SkSppNzpBeApiCustomerprofilePairBusinessPartnerResponse as PairBusinessPartnerResponse,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate as CustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import { AddDeliveryPointActions, AddDeliveryPointScreen, CommodityChoice, CompanyData, PersonData } from '../../actions/add-delivery-point-actions';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { IResponseError, RequestTemplateCode } from '../../models/model';
import { IRootState } from '../../reducers';
import { CustomerRequestRoutes } from '../../routes/routes';
import { CustomerRequestEnum, CustomerRequestTypeEnum } from '../../views/customer-request/config/enums';
import { CustomerRequestActions } from './../../actions/customer-request-actions';
import ChooseCommodityScreen from './component/choose-commodity-screen';
import ChooseExistingOrNewScreen from './component/choose-existing-or-new-screen';
import ChoosePersonOrCompanyScreen from './component/choose-person-or-company-screen';
import EnterCompanyDataScreen from './component/enter-company-data-screen';
import EnterPersonDataScreen from './component/enter-person-data-screen';
import EnteredDataScreen from './component/entered-data-screen';
import ErrorScreen from './component/error-screen';
import VerifyBusinessPartnerPhoneNumberScreen from './component/verify-busines-partner-phone-number-screen';

interface IBusinessPartnerPairingProps {
    customerNumber?: string;
    contractNumber?: string;
    companyId?: string;
    additionalPhone: string;
}

type Props = {
    reloadDeliveryPoints: () => void;
};

// UF-5 WF-2006 Modal
const AddDeliveryPoint: React.FC<Props> = ({ reloadDeliveryPoints }) => {
    const dispatch = useDispatch();
    const addDeliveryPointState = useSelector((state: IRootState) => state.addDeliveryPoint);

    const showHide = function() {
        if (!addDeliveryPointState.screen) {
            dispatch(AddDeliveryPointActions.start(false));
        } else {
            dispatch(AddDeliveryPointActions.cancel());
        }
    };

    const api = useApi();
    const history = useHistory();
    const { addToast } = useToasts();

    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);

    const requestBusinessPartnerPairing = async ({ companyId, contractNumber, customerNumber, additionalPhone }: IBusinessPartnerPairingProps) => {
        if (loggedInCustomer?.id) {
            const scopes: PairBusinessPartnerRequestScope[] = [];
            if (customerNumber) scopes.push({ scope: 'BUSINESS_PARTNER_EXTERNAL_ID', value: customerNumber });
            if (contractNumber) scopes.push({ scope: 'CONTRACT_EXTERNAL_ID', value: contractNumber });
            if (companyId) scopes.push({ scope: 'BUSINESS_PARTNER_ICO', value: companyId });
            return await api.customers
                .customerPairingBusinessPartner(loggedInCustomer?.id, { scopes, phone: additionalPhone }, { secure: true })
                .then((res) => res.data);
        }
    };

    const handleSuccessPairing = (
        isCompany: boolean,
        pairingResponse: PairBusinessPartnerResponse,
        pairingRequest?: IBusinessPartnerPairingProps,
    ) => {
        if (pairingResponse.businessPartner) {
            dispatch(AddDeliveryPointActions.setPairedBusinessPartner(pairingResponse.businessPartner));
        }

        switch (pairingResponse.result) {
            case 'SUCCESS':
                dispatch(AddDeliveryPointActions.sucessPairing(false));
                break;
            case 'VERIFY_EMPLOYEE':
                dispatch(AddDeliveryPointActions.sucessPairing(true));
                break;
            case 'VERIFY_SMS':
                if (isCompany)
                    dispatch(
                        AddDeliveryPointActions.enterCompanyData(
                            pairingRequest as CompanyData,
                            pairingResponse?.challengeUuid,
                            pairingResponse?.phone,
                        ),
                    );
                else
                    dispatch(
                        AddDeliveryPointActions.enterPersonData(pairingRequest as PersonData, pairingResponse?.challengeUuid, pairingResponse?.phone),
                    );
                break;
            default:
                break;
        }
    };

    const handlePairingError = (err?: { error: IResponseError }) => {
        dispatch(AddDeliveryPointActions.error(err?.error?.code == null ? '' : err.error.code.toString()));
    };

    const [mutateBusinesPartnerParingRequest, { isLoading }] = useMutation(requestBusinessPartnerPairing, {
        onError: handlePairingError,
        onSuccess: (data, variables) => {
            if (data) {
                handleSuccessPairing(!!variables.companyId, data, variables);
            } else {
                handlePairingError();
            }
        },
    });

    const [mutateBusinesPartnerEmailSync, { isLoading: isRequestingEmailSync }] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileBusinessPartnerSummary | null,
        unknown,
        { bpId: string; email: string }
    >(
        async ({ bpId, email }) => {
            return api.businessPartners.changeEmail(bpId, { email }, { secure: true }).then((res) => res.data);
        },
        {
            onErrorWithGlobalErrorHandling: () => false,
            onSuccess: (data) => {
                if (data) {
                    dispatch(AddDeliveryPointActions.setEmailSyncRequested());
                    addToast(<Trans i18nKey="delivery-point.add-delivery-point.entered-data.change-email-toast-success"></Trans>, {
                        appearance: 'success',
                    });
                }
            },
        },
    );

    const [mutateRequestTemplate] = useMutationWithError<CustomerRequestTemplate | null | undefined, unknown, { codeList: RequestTemplateCode[] }>(
        async ({ codeList }) =>
            api.customerRequestTemplates.searchTemplates({ codes: codeList }, { secure: true }).then((res) => {
                if (res.data?.result && res.data?.result) {
                    if (res.data.result.length > 1) {
                        dispatch(CustomerRequestActions.setCustomerRequestTemplates(res.data.result));
                    }
                    dispatch(CustomerRequestActions.setCustomerRequestTemplate(res.data.result[0]));
                    return res.data?.result && res.data?.result[0];
                }
            }),
        {
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const getComodityRequest = async (comodity: CommodityChoice) => {
        dispatch(AddDeliveryPointActions.finished());
        let requestId = CustomerRequestEnum.GAS_SUPPLY_NEW;
        let codeList: RequestTemplateCode[] = [CustomerRequestTypeEnum.ZOM_ZODE];
        switch (comodity) {
            case CommodityChoice.GAS:
                requestId = CustomerRequestEnum.GAS_SUPPLY_NEW;
                codeList = [CustomerRequestTypeEnum.ZOM_ZODP];
                break;
            case CommodityChoice.ELECTRICITY:
                requestId = CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW;
                codeList = [CustomerRequestTypeEnum.ZOM_ZODE];
                break;
            default: {
                return;
            }
        }
        await mutateRequestTemplate({ codeList });
        history.push(`${CustomerRequestRoutes.NEW_REQUEST}/${requestId}`);
    };

    let screenContent;
    switch (addDeliveryPointState.screen) {
        case AddDeliveryPointScreen.VERIFY_PHONE_NUMBER:
            screenContent = (
                <VerifyBusinessPartnerPhoneNumberScreen
                    lastThreePhoneDigits={addDeliveryPointState.lastThreePhoneDigits}
                    challengeUuid={addDeliveryPointState.phoneChallengeCode}
                    onVerified={(responseData) => handleSuccessPairing(!!addDeliveryPointState.companyData, responseData)}
                    onError={handlePairingError}
                    goBack={() => dispatch(AddDeliveryPointActions.back())}
                />
            );
            break;
        case AddDeliveryPointScreen.PHONE_NUMBER_IS_NEEDED:
        case AddDeliveryPointScreen.ERROR:
            screenContent = <ErrorScreen />;
            break;
        case AddDeliveryPointScreen.CHOOSE_COMMODITY:
            screenContent = (
                <ChooseCommodityScreen
                    commodity={addDeliveryPointState.commodity}
                    enterCommodity={getComodityRequest}
                    goBack={() => dispatch(AddDeliveryPointActions.back())}
                />
            );
            break;
        case AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY:
            screenContent = (
                <ChoosePersonOrCompanyScreen
                    onlyLoad={addDeliveryPointState.onlyLoad}
                    proceedWithPerson={() => dispatch(AddDeliveryPointActions.proceedWithPerson())}
                    proceedWithCompany={() => dispatch(AddDeliveryPointActions.proceedWithCompany())}
                    goBack={() => dispatch(AddDeliveryPointActions.back())}
                />
            );
            break;
        case AddDeliveryPointScreen.ENTER_PERSON_DATA:
            screenContent = (
                <EnterPersonDataScreen
                    enterData={(personData) => mutateBusinesPartnerParingRequest(personData)}
                    goBack={() => dispatch(AddDeliveryPointActions.back())}
                    isLoading={isLoading}
                    additionalPhone={loggedInCustomer?.phone}
                />
            );
            break;
        case AddDeliveryPointScreen.ENTER_COMPANY_DATA:
            screenContent = (
                <EnterCompanyDataScreen
                    enterData={(companyData) => mutateBusinesPartnerParingRequest(companyData)}
                    goBack={() => dispatch(AddDeliveryPointActions.back())}
                    isLoading={isLoading}
                    additionalPhone={loggedInCustomer?.phone}
                />
            );
            break;
        case AddDeliveryPointScreen.ENTERED_DATA:
            screenContent = (
                <EnteredDataScreen
                    approvalRequired={!!addDeliveryPointState.approvalRequired}
                    close={() => dispatch(AddDeliveryPointActions.cancel())}
                    portalEmail={loggedInCustomer?.email}
                    onUpdateBpEmail={(bpId: string, email: string) => mutateBusinesPartnerEmailSync({ bpId, email })}
                    businessPartner={addDeliveryPointState.pairedBusinessPartner}
                    isLoading={isRequestingEmailSync}
                    emailSyncRequested={!!addDeliveryPointState.emailSyncRequested}
                />
            );
            break;
        default:
            screenContent = (
                <ChooseExistingOrNewScreen
                    proceedWithExistingDeliveryPoint={() =>
                        dispatch(AddDeliveryPointActions.proceedWithExistingDeliveryPoint(loggedInCustomer?.phone))
                    }
                    proceedWithNewDeliveryPoint={() => dispatch(AddDeliveryPointActions.proceedWithNewDeliveryPoint())}
                />
            );
    }

    return (
        <Modal
            isOpen={addDeliveryPointState.isOpen}
            onClosed={() => {
                reloadDeliveryPoints();
                dispatch(AddDeliveryPointActions.finished());
            }}
            modalClassName="modal-fullscreen"
            centered
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    {addDeliveryPointState.screen === AddDeliveryPointScreen.CHOOSE_EXISTING_OR_NEW ||
                    addDeliveryPointState.screen === AddDeliveryPointScreen.CHOOSE_COMMODITY ? (
                        <Trans i18nKey="delivery-point.add-delivery-point.title1">Pridať odberné miesto</Trans>
                    ) : (
                        <Trans i18nKey="delivery-point.add-delivery-point.title2">Načítanie odberných miest</Trans>
                    )}
                </h3>
                <button type="button" onClick={showHide} className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
            </div>
            <ModalBody>{screenContent}</ModalBody>
        </Modal>
    );
};

export default AddDeliveryPoint;
