import { SkSppNzpBeApiHelpComponentHelp } from '@spp/spp-meru-frontend-common';
import { useSelector } from 'react-redux';
import { ErrorObject } from '../components/show-error';
import { IRootState } from '../reducers';
import { useApi } from './use-api';
import { useQueryWithError } from './use-query-with-error';

export function getHelpHash(screen?: string, field?: string, locale?: string): string {
    return `${screen ?? '_'}/${field ?? '_'}/${locale ?? '_'}`;
}

export const matchHelpHash = (helpsList: string[], searchValue: string): boolean => {
    return helpsList.some((x) => x.toLocaleLowerCase() === searchValue.toLocaleLowerCase());
};

export function useHelpText(screen: string, field: string, locale: string): [string | undefined, ErrorObject | null] {
    const api = useApi();
    const help = useSelector((state: IRootState) => state.help);

    const { error, data } = useQueryWithError<SkSppNzpBeApiHelpComponentHelp[] | null, ErrorObject>(
        ['componentHelps-content', screen, field, locale, help.loaded],
        async () =>
            help.loaded && matchHelpHash(help.availableHelps, getHelpHash(screen, field, locale))
                ? api.componentHelps
                      .getAllComponents({
                          content: true,
                          screen: [screen],
                          field: [field],
                          locale,
                      })
                      .then((res) => {
                          return res.data;
                      })
                : null,
    );

    return [data && data.length && data[0] && data[0].i18ns && data[0].i18ns[0] ? data[0].i18ns[0].content : undefined, error];
}
