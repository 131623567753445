import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
} from '@spp/spp-meru-frontend-common';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { buildCustomerRequestsSearchQueryKey, buildUnitedDeliveryPointsSearchQueryKey, IRootState, useApi } from '../../../../main';
import { createIndexedGroups } from './../../../../utils/utils';
import { isEInvoiceActiveForDP } from './einvoice-utils';

export const useCanUnifyEinvoiceEmail = () => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();

    const unitedDeliveryPointsSearchObject: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch = useMemo(
        () => ({
            deliveryPoint: { hidden: false },
            shared: false,
            pairingDone: true,
            includeInactive: false,
            paging: { page: 0, size: 5000 },
        }),
        [],
    );
    const {
        isLoading: isLoadingDeliveryPoints,
        data: deliveryPointsData,
    } = useQueryWithError<SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, unitedDeliveryPointsSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? null
                : api.customers
                      .searchUnitedDeliveryPoint(loggedInCustomer.id, unitedDeliveryPointsSearchObject, { fetch: ['E_INVOICE'] }, { secure: true })
                      .then((res) => res.data),
    );

    const customerRequestsSearchObject: SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery = useMemo(
        () => ({
            unitedDeliveryPointUuids: deliveryPointsData?.result?.map((udp) => udp.id || ''),
            statuses: ['CREATED', 'REGISTERED', 'SAP_OPEN', 'SAP_IN_PROGRESS'],
            codes: ['ZZU_ADEF'],
            paging: { page: 0, size: 5000 },
        }),
        [deliveryPointsData],
    );

    const { isLoading: isLoadingCustomerRequests, data: customerRequestsData } = useQueryWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | undefined
    >(
        buildCustomerRequestsSearchQueryKey(loggedInCustomer?.id, customerRequestsSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? undefined
                : api.customers
                      .customerSearchCustomerRequests(loggedInCustomer.id, customerRequestsSearchObject, undefined, { secure: true })
                      .then((res) => res.data?.result),
        {
            enabled: deliveryPointsData != null,
        },
    );

    // udpId => CustomerRequest
    const requestsIndexedByCA: Record<string, SkSppNzpBeApiCustomerrequestCustomerRequestSummary[]> | undefined = useMemo(() => {
        if (customerRequestsData == null) {
            return undefined;
        }
        return createIndexedGroups<SkSppNzpBeApiCustomerrequestCustomerRequestSummary>(
            customerRequestsData || [],
            (x) => x.customerRequestContracts?.find(() => true)?.contractAccount?.id,
        );
    }, [customerRequestsData]);

    const udpCanUnifyEmailMapping = useMemo(() => {
        if (deliveryPointsData == null || requestsIndexedByCA == null) {
            return undefined;
        }

        const udpMap: Record<string, boolean> = {};
        deliveryPointsData?.result?.forEach((udp) => {
            const udpId = udp.id;
            const dps = udp.deliveryPoints;
            if (udpId == null || dps == null) {
                return;
            }

            const hasUdpActiveRequest = dps.some((dp) => {
                const dpCaId = dp.einvoice?.contractAccountId;
                const activeRequestsForCA = dpCaId != null ? requestsIndexedByCA[dpCaId] : [];
                const hasActiveRequest = activeRequestsForCA != null && activeRequestsForCA.length > 0;
                return hasActiveRequest;
            });

            const hasUdpActiveEinvoice = dps.some((dp) => isEInvoiceActiveForDP(dp));

            udpMap[udpId] = hasUdpActiveEinvoice && !hasUdpActiveRequest;
        });

        return udpMap;
    }, [deliveryPointsData, requestsIndexedByCA]);

    const canUnifyEInvoiceEmails = useMemo(() => {
        if (udpCanUnifyEmailMapping == null) {
            return false;
        }
        return Object.values(udpCanUnifyEmailMapping).filter((x) => !!x).length > 0;
    }, [udpCanUnifyEmailMapping]);

    return { isLoading: isLoadingDeliveryPoints || isLoadingCustomerRequests, udpCanUnifyEmailMapping, canUnifyEInvoiceEmails };
};
