import React, { MouseEventHandler } from 'react';

interface IClickableElementProps {
    onClick: MouseEventHandler<any>;
    className?: string;
    isText?: boolean;
    disabled?: boolean;
    [key: string]: unknown;
}

export const ClickableElement: React.FC<IClickableElementProps> = ({ onClick, children, isText, className, disabled, ...props }) => {
    return (
        <span
            onClick={(e) => !disabled && onClick(e)}
            className={`${disabled ? '' : 'cursor-pointer clickable-element'} ${isText ? 'clickable-element--text' : ''} ${className || ''}`}
            {...props}
        >
            {children}
        </span>
    );
};
