import {
    SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValues,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionSearch,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionValue,
} from '@spp/spp-meru-frontend-common';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/use-api';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';

export type Data = {
    x: Date;
    y: number;
    from: Date;
    to: Date;
    yMin?: number;
    yMax?: number;
    yAverage?: number;
};

type Props = {
    contractId: string | undefined;
    timestampFrom?: string;
    timestampTo?: string;
    units: SkSppNzpCommonsApiCustomerconsumptionConsumptionSearch['units'];
    interval: number;
};

const useFetchSmartMeterReading = ({
    contractId,
    timestampFrom,
    timestampTo,
    units,
    interval,
}: Props): {
    isLoading: boolean;
    isFetching: boolean;
    error: unknown;
    data: Data[];
    reservedValues?: SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValues;
} => {
    const [mappedData, setMappedData] = useState<Data[]>([]);
    const [reservedValuesData, setReservedValuesData] = useState<SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValues>();

    const api = useApi();

    const { isLoading, isFetching, error, data } = useQueryWithError<SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse | null>(
        // TODO use correct key string
        ['smart-metering-consumption', contractId, timestampFrom, timestampTo, units, interval],
        async () =>
            contractId != null
                ? api.deliveryPoints
                      .searchConsumptions(
                          contractId,
                          {
                              readAt: {
                                  from: timestampFrom ? timestampFrom : '',
                                  to: timestampTo ? timestampTo : '',
                              },
                              type: 'INTERVAL_METER_READING',
                              interval,
                              units,
                          },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
    );

    useEffect(() => {
        const tariffs = data?.tariffs?.reduce<SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff[]>((acc, tariff) => {
            if (tariff) {
                return acc.concat(tariff);
            } else {
                return acc;
            }
        }, []);
        const values = tariffs?.reduce<SkSppNzpCommonsApiCustomerconsumptionConsumptionValue[]>((acc, tariff) => {
            if (tariff.values) {
                return acc.concat(tariff.values);
            } else {
                return acc;
            }
        }, []);
        if (values) {
            const mappedDataTmp: Data[] = values
                .filter(
                    (consumptionValue) =>
                        (consumptionValue.value !== undefined || consumptionValue.average) &&
                        consumptionValue.period?.from !== undefined &&
                        consumptionValue.period?.to !== undefined,
                )
                .map((consumptionValue) => {
                    const { period, value, average, min, max } = consumptionValue;
                    const from = period?.from ? new Date(period.from) : new Date();
                    const to = period?.to ? new Date(period.to) : new Date();
                    const result = {
                        from,
                        to,
                        x: from,
                        // dateTo: new Date(period.to),
                        y: value || max || 0,
                        yMin: min,
                        yMax: max,
                        yAverage: average,
                    };
                    return result;
                });
            if (mappedDataTmp) {
                setReservedValuesData(data?.reservedValues);
                setMappedData(mappedDataTmp);
            }
        }
    }, [setMappedData, data]);

    return { isLoading, isFetching, error, data: mappedData, reservedValues: reservedValuesData };
};

export default useFetchSmartMeterReading;
