import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { QueryKeysEnum } from '../../../../../../main';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: CodeListItem;
    bold?: boolean;
};

const ProductLevelItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: zpLevelCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CARBON_STOP_LEVEL,
        codeListTypeEnum: CodeListTypeEnum.CARBON_STOP_LEVEL,
        paging: {
            size: 10,
        },
    });

    const zpLevel = useMemo(() => {
        return zpLevelCodeList?.find((item) => item.uuid === data?.uuid);
    }, [zpLevelCodeList, data]);

    if (isObjectEmpty(data)) return <></>;
    return (
        <>
            <ItemPreview title={t('customer-request.steps.product-level.title')}>
                <ItemPreviewContent bold={bold}>
                    {zpLevel?.name}
                    <span className="ml-2">
                        ({zpLevel?.attributes?.price + ' € / ' + t('customer-request.steps.block-carbon-footprint-completion.monthly')})
                    </span>
                </ItemPreviewContent>
            </ItemPreview>
        </>
    );
};

export default ProductLevelItem;
