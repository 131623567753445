import { SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IInvoiceDelivery } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IInvoiceDelivery;
    bold?: boolean;
    requestContent: any;
    code?: SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent['type'];
};

const InvoiceDeliveryItem: React.FC<Props> = ({ data, bold, requestContent, code }) => {
    const [t] = useTranslation();

    const { address, email } = data;

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 30,
        },
    });

    const paymentTypeCode = useMemo(() => {
        return paymentTypeCodeList?.find((item) => item.uuid === requestContent?.paymentMethod?.paymentType?.uuid)?.name;
    }, [paymentTypeCodeList, requestContent?.paymentMethod?.paymentType]);

    const isTranscript = code === CustomerRequestTypeEnum.ZOM_P;

    if (isObjectEmpty(data)) return <></>;

    return (
        <ItemPreview
            title={
                isTranscript ? t('customer-request.steps.invoice-delivery.invoices-and-payments') : t('customer-request.steps.invoice-delivery.title')
            }
        >
            {isTranscript && t('customer-request.steps.invoice-delivery.title')}
            {address && !isObjectEmpty(address) && address.street && (
                <>
                    {address.name && <ItemPreviewContent bold={bold}>{address.name}</ItemPreviewContent>}
                    <ItemPreviewContent bold={bold}>{`${address.postalCode}, ${address.city}`}</ItemPreviewContent>
                    <ItemPreviewContent bold={bold}>{`${address.street} ${address.number}`}</ItemPreviewContent>
                </>
            )}
            {email && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.invoice-delivery.via-email')}: ${email}`}</ItemPreviewContent>}
            {isTranscript && requestContent?.paymentMethod?.paymentType?.uuid && (
                <>
                    <br />
                    <ItemPreviewHeader title={t('customer-request.steps.payment-method.title')} />
                    <ItemPreviewContent bold={bold}>{paymentTypeCode ?? ''}</ItemPreviewContent>
                    {requestContent?.paymentMethod?.iban && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${
                            requestContent?.paymentMethod?.iban
                        }`}</ItemPreviewContent>
                    )}
                    {requestContent?.paymentMethod?.bicSwift && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${`${t(
                            'customer-request.steps.bank-details.bic-swift',
                        )}: ${requestContent?.paymentMethod?.bicSwift}`}`}</ItemPreviewContent>
                    )}
                    {requestContent?.paymentMethod?.sipoNumber && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.payment-method.sipo')}: ${
                            requestContent?.paymentMethod?.sipoNumber
                        }`}</ItemPreviewContent>
                    )}
                </>
            )}
        </ItemPreview>
    );
};

export default InvoiceDeliveryItem;
