import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import HelpLinkWithModal from '../../../../../../components/help/help-link-with-modal';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';

type Props = DefaultFormType & {
    lastLowValue?: number;
};

const MerterOneTariffShort: React.FC<Props> = ({ lastLowValue, register, errors, trigger }) => {
    const { formRules } = useFormRules();
    return (
        <>
            <InputWithAppend
                trigger={trigger}
                ref={register({ ...formRules.requiredInteger, ...formRules.min(lastLowValue || 0) })}
                characterFilter={CharacterFilter.POSITIVE_INTEGER}
                errors={errors}
                type="number"
                name="value"
                id="value"
                label={<Trans i18nKey="customer-request.steps.gauge-condition.title" />}
                inputGroupAppendChildren={
                    <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                        m<sup>3</sup>
                    </button>
                }
            />
            <Trans i18nKey="customer-request.steps.gauge-condition.meter-condition-help2" />
            <div className="my-1">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                <HelpLinkWithModal
                    className="d-inline"
                    classNameClickableElement="text-decoration-underline-inverse"
                    title="customer-request.steps.gauge-condition.meter-reading-help"
                    field="SOT_GAS_METER_WHERE_I_FIND_IT"
                />
            </div>
        </>
    );
};
export default MerterOneTariffShort;
