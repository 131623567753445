import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerrequestCustomerRequestSummary } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../components/common/clickable-element';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IBusinessPartnerDeliveryPointData } from '../../../../models/model';
import BulkActivateSelectDeliveryPoints from './bulk-activate-select-delivery-points';
import BulkActivateSelectEmail from './bulk-activate-select-email';
import BulkActivateSuccess from './bulk-activate-success';

enum BulkActivateStepsEnum {
    SELECT_DELIVERY_POINT = 1,
    SELECT_EMAIL,
    SUCCESS,
}

interface IBulkActivateEInvoiceModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    onBulkActivate?: (businessPartnerId: string, email: string) => void;
}

const BulkActivateEInvoiceModal: React.FC<IBulkActivateEInvoiceModalProps> = ({ isOpen, onCloseModal, onBulkActivate }) => {
    const [step, setStep] = useState(BulkActivateStepsEnum.SELECT_DELIVERY_POINT);
    const [selectedBusinessPartnerData, setSelectedBusinessPartnerData] = useState<IBusinessPartnerDeliveryPointData | undefined>(undefined);

    const api = useApi();
    const [mutateEInvoiceBulkActivation, { isLoading, reset: resetMutationState }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | null,
        undefined,
        { bpId: string; email: string }
    >(
        async ({ bpId, email }) =>
            api.businessPartners
                .activateEInvoice(
                    bpId,
                    {
                        email: email,
                    },
                    { secure: true },
                )
                .then((res) => res.data),
        {
            onErrorWithGlobalErrorHandling: () => false,
            onSuccess: (data, variables) => {
                onBulkActivate && onBulkActivate(variables.bpId, variables.email);
                setStep(BulkActivateStepsEnum.SUCCESS);
                resetMutationState();
            },
        },
    );

    useEffect(() => {
        if (isOpen) {
            setStep(BulkActivateStepsEnum.SELECT_DELIVERY_POINT);
            setSelectedBusinessPartnerData(undefined);
        }
    }, [isOpen]);

    // const onIntroConfirm = () => {
    //     setStep(BulkActivateStepsEnum.SELECT_DELIVERY_POINT);
    // };

    const onSelectDeliveryPoint = (businessPartnerData: IBusinessPartnerDeliveryPointData) => {
        setSelectedBusinessPartnerData(businessPartnerData);
        setStep(BulkActivateStepsEnum.SELECT_EMAIL);
    };

    const onSelectEmail = (email: string) => {
        if (selectedBusinessPartnerData?.businessPartner.id) {
            mutateEInvoiceBulkActivation({ bpId: selectedBusinessPartnerData.businessPartner.id, email });
        }
    };

    const onSuccessConfirm = () => {
        onCloseModal();
    };

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="settings.e-invoices.bulk-activation.bulk-einvoice-activation" />
                    </h3>
                    <button type="button" onClick={onCloseModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody>
                    {isLoading && <LoadingIndicator />}

                    {step === BulkActivateStepsEnum.SELECT_DELIVERY_POINT && (
                        <>
                            <BulkActivateSelectDeliveryPoints
                                selectedBusinessPartnerId={selectedBusinessPartnerData?.businessPartner.id}
                                onSelect={onSelectDeliveryPoint}
                            />
                        </>
                    )}
                    {step === BulkActivateStepsEnum.SELECT_EMAIL && selectedBusinessPartnerData != null && (
                        <>
                            <div className="mb-4">
                                <ClickableElement onClick={() => setStep(BulkActivateStepsEnum.SELECT_DELIVERY_POINT)}>
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-3 text-muted" />
                                </ClickableElement>
                            </div>
                            <BulkActivateSelectEmail onSelect={onSelectEmail} businessPartnerData={selectedBusinessPartnerData} />
                        </>
                    )}
                    {step === BulkActivateStepsEnum.SUCCESS && <BulkActivateSuccess onConfirm={onSuccessConfirm} />}
                </ModalBody>
            </Modal>
        </>
    );
};

export default BulkActivateEInvoiceModal;
