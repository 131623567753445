import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { ClickableElement, IRootState } from '../../../main';

export const NotificationSettingsInfo: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    return (
        <>
            <div className="accordion md-accordion mt-3" id="accordionPayment" role="tablist" aria-multiselectable="true">
                <Card>
                    <CardHeader role="tab">
                        <ClickableElement className={isExpanded ? '' : 'collapsed'} onClick={() => setIsExpanded(!isExpanded)}>
                            <div className="font-weight-bold d-flex mb-0">
                                <Trans i18nKey="settings.notifications.notifications-info.where-are-notifications-sent" />
                                <i
                                    className={`icon-chevron-down-16 la-xs text-secondary ml-auto my-auto smooth-transition ${
                                        isExpanded ? 'rotateZ-180' : ''
                                    }`}
                                />
                            </div>
                        </ClickableElement>
                    </CardHeader>
                    <Collapse isOpen={isExpanded}>
                        <CardBody style={{ borderTop: '1px solid #dee2e6' }}>
                            <p>
                                <Trans
                                    i18nKey="settings.notifications.notifications-info.notifications-will-be-sent-to-login-email"
                                    values={{ email: loggedInCustomer?.email || '' }}
                                />
                            </p>

                            <p className="mb-0">
                                {!!loggedInCustomer?.phone ? (
                                    <Trans
                                        i18nKey="settings.notifications.notifications-info.notifications-will-be-sent-to-verified-phone"
                                        values={{ phone: loggedInCustomer?.phone || '' }}
                                    />
                                ) : (
                                    <Trans i18nKey="settings.notifications.notifications-info.sms-notifications-will-be-sent-after-verified-phone" />
                                )}
                            </p>
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
        </>
    );
};
