import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Collapse } from 'reactstrap';
import BaseRadio from '../../../../../../components/common/base-radio';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { formatAddress, formatBusinessPartnerName } from '../../../../../../utils/utils';

interface IDeliveryPointCollapseList extends DefaultFormType {
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    deliveryPoints: UnitedDeliveryPointSummary[];
    setSelectedDP?: (val: SkSppNzpBeApiCustomerprofileDeliveryPointSummary) => void;
    onlyEE?: boolean;
    selectedBusinessPartnerId?: string;
}

export const DeliveryPointCollapseList: React.FC<IDeliveryPointCollapseList> = ({
    businessPartner,
    deliveryPoints,
    register,
    setSelectedDP,
    onlyEE,
    selectedBusinessPartnerId,
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(selectedBusinessPartnerId === businessPartner.id);
    const { t } = useTranslation();
    const { formRules } = useFormRules();

    return (
        <div className="my-4">
            <p className="font-weight-bold d-flex my-2" onClick={() => setIsExpanded(!isExpanded)}>
                <div>
                    {formatBusinessPartnerName(businessPartner)}
                    <br />
                    <small className="text-secondary">
                        {t('delivery-point.customer-number')} {businessPartner.externalId}
                    </small>
                </div>
                <div className="ml-auto">
                    <small className="text-secondary">
                        {t('settings.e-invoices.delivery-points-count', {
                            count: deliveryPoints.length,
                        })}
                    </small>
                    <i
                        className={`la-xs text-secondary ml-5 my-auto smooth-transition ${
                            isExpanded ? 'icon-chevron-top-16' : 'icon-chevron-down-16'
                        }`}
                    />
                </div>
            </p>
            {isExpanded && <hr className="mb-2" />}
            {deliveryPoints.map((item) => (
                <>
                    {item.deliveryPoints
                        ?.filter((dp) => (onlyEE ? dp.type === 'EE' : true))
                        .map((dp) => (
                            <Collapse key={`${businessPartner.externalId}_${dp.externalId}`} isOpen={isExpanded}>
                                <Card className="mb-3">
                                    <CardBody className="p-3">
                                        <BaseRadio
                                            ref={register(formRules.required)}
                                            value={dp.id}
                                            id={`businessPartnerDeliveryPointsRadio${dp.externalId}`}
                                            name="deliveryPointId"
                                            className="mt-0"
                                            onChange={() => setSelectedDP && setSelectedDP(dp)}
                                            label={
                                                <>
                                                    <b>{`${formatAddress(dp.address)} · ${t(`enums.CommodityEnum.${dp.type}`)}`}</b>
                                                    <br />
                                                    <small className="text-secondary">
                                                        {t('delivery-point.contract-number', {
                                                            number: dp.contract?.externalId,
                                                        })}
                                                    </small>
                                                </>
                                            }
                                        />
                                    </CardBody>
                                </Card>
                            </Collapse>
                        ))}
                </>
            ))}
            <hr />
        </div>
    );
};
