import React from 'react';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';

type Props = {
    children: React.ReactNode;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
    disabled?: boolean;
    autofocus?: boolean;
};

const BaseButton: React.FC<Props> = ({ children, onClick, type, disabled, className, autofocus }: Props) => {
    const localRef = useAutofocusRef<HTMLButtonElement>(autofocus);

    return (
        <button
            ref={localRef}
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`btn ${className || 'btn-block btn-primary btn-lg mt-4'}`}
        >
            {children}
        </button>
    );
};

export default BaseButton;
