import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMeterReading } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IMeterReading;
    bold?: boolean;
    deliveryPointType: false | 'EE' | 'ZP' | undefined;
};

const MeterReadingItem: React.FC<Props> = ({ data, bold, deliveryPointType }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { value, date, valueLow, valueHigh } = data;

    const unit = () =>
        deliveryPointType === 'EE' ? (
            'kWh'
        ) : (
            <>
                m<sup>3</sup>
            </>
        );

    return (
        <ItemPreview title={t('customer-request.steps.gauge-condition.title')}>
            {date && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.gauge-condition.date')}: ${moment(date).format(
                    'DD.MM.YYYY',
                )}`}</ItemPreviewContent>
            )}
            {value && (
                <ItemPreviewContent bold={bold}>
                    {`${t('customer-request.steps.gauge-condition.title')}: ${value}`} {unit()}
                </ItemPreviewContent>
            )}
            {valueHigh && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.gauge-condition.gauge-hight')}: ${valueHigh} kWh`}</ItemPreviewContent>
            )}
            {valueLow && (
                <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.gauge-condition.gauge-low')}: ${valueLow} kWh`}</ItemPreviewContent>
            )}
        </ItemPreview>
    );
};

export default MeterReadingItem;
