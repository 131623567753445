import React from 'react';
import { Trans } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import useSso from '../../../../hooks/sso/use-sso';
import Routes from '../../../../routes/routes';

type PropsType = {
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const ForgotEmailCard: React.FC<PropsType> = ({ activeCard, toggleActive }) => {
    const { ssoLogin } = useSso();
    const backUrl = Routes.HOME;
    const method = 'customer.smsTokenMethod';
    const index = 2;

    return (
        <Card>
            <CardHeader role="tab" id={`heading${index}`}>
                <a
                    className={activeCard === index ? '' : 'collapsed'}
                    onClick={(event) => toggleActive(event, index)}
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                >
                    <div className="d-flex">
                        <strong>
                            <Trans i18nKey="login-issues.forgot-email">Nepamätám si e-mail</Trans>
                        </strong>
                        <i className="icon-chevron-down-16 la-xs text-secondary ml-auto my-auto"></i>
                    </div>
                </a>
            </CardHeader>
            <Collapse isOpen={activeCard === index}>
                <CardBody>
                    <p>
                        <Trans i18nKey="login-issues.enter-your-phone-to-receive-sms-code">
                            Máte problém s prihlásením cez e-mail? Ak máte k účtu priradené telefónne číslo, pošleme vám SMS kód pre prihlásenie.
                        </Trans>
                    </p>
                    <Row>
                        <Col>
                            <button className="btn btn-primary btn-block btn-lg" onClick={() => ssoLogin({ backUrl, method })}>
                                <Trans i18nKey="action.login-via-phone-number">Prihlásiť sa tel. číslom</Trans>
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default ForgotEmailCard;
