import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseTextarea from '../../../../../../components/common/base-textarea';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

type Props = DefaultFormType & {
    objectName?: string;
    fieldName: string;
};

const ComplaintFormFields: React.FC<Props> = ({ register, errors, fieldName }) => {
    const { requiredTrimed } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <>
            <BaseTextarea
                ref={register(requiredTrimed)}
                errors={errors}
                name={fieldName}
                rows={5}
                label={<Trans i18nKey="customer-request.steps.complaint.complaint" />}
                touched={touched}
            />
        </>
    );
};

export default ComplaintFormFields;
