import { IDeliveryPoint } from '../models/model';

export type DeliveryPointsState = {
    /**
     * Card delivery points are displayed as cards on list-delivery-points screen. cardDeliveryPoints are sorted
     * in the way which allows on-the-fly grouping by business partners. Also the sharing criteria is applied
     * in the sorting order so the first DPs are not shared and the last are shared.
     */
    cardDeliveryPoints: IDeliveryPoint[];
    /**
     * Table delivery points are displayed as rows in table on list-delivery-points screen. The sorting can be changed
     * by the use user clicking on the table's column header.
     */
    tableDeliveryPoints: IDeliveryPoint[];
};

export enum DeliveryPointsActionType {
    DELETE = 'deliveryPoints/DELETE',
}

export type DeliveryPointsAction = {
    type: DeliveryPointsActionType;
    deliveryPointNumber: string;
};

export const DeliveryPointsActions = {
    delete: (deliveryPointNumber: string): DeliveryPointsAction => ({
        type: DeliveryPointsActionType.DELETE,
        deliveryPointNumber: deliveryPointNumber,
    }),
};
