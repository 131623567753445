import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

interface IPenComponentProps {
    onClick: () => void;
    iconClass?: string;
    disabled?: boolean;
}

export const PenComponent: React.FC<IPenComponentProps> = ({ onClick, iconClass, disabled }) => {
    const divColRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <div
                ref={divColRef}
                className={`pen-component mr-1 ${disabled ? 'disabled' : 'cursor-pointer'}`}
                onClick={disabled ? () => null : onClick}
            >
                <i className={`icon-edit-16 text-secondary ${disabled ? 'disabled' : ''} ${iconClass ? iconClass : ''}`} />
            </div>
            <UncontrolledTooltip placement="top" target={divColRef}>
                {disabled ? <Trans i18nKey="delivery-point.detail.change-is-requested" /> : <Trans i18nKey="delivery-point.detail.edit-value" />}
            </UncontrolledTooltip>
        </>
    );
};
