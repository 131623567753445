import React from 'react';
import BaseRadio from '../../../../components/common/base-radio';
import { DefaultFormType } from '../../../../models/model';

type Props = DefaultFormType & {
    value: string;
    name: string;
    imageUrl?: string;
};

const EpayMethodItem: React.FC<Props> = ({ register, errors, value, name, imageUrl }) => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-4 mb-3" style={{ height: 40 }}>
                <BaseRadio ref={register({})} errors={errors} value={value} id={value} name="paymentMethod" label={name} className="text-left" />
                {imageUrl ? <img src={imageUrl} alt="" style={{ width: '80px' }} /> : <i className="icon-credit-card " />}
            </div>
        </>
    );
};

export default EpayMethodItem;
