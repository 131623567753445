import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import { useSelectedPoints } from '../share-delivery-points/contexts/selected-delivery-points-context';
import { BulkRemoveSharingDeliveryPointsModal } from '../share-delivery-points/modals/bulk-remove-sharing-delivery-points-modal';
import { BulkShareDeliveryPointsModal } from '../share-delivery-points/modals/bulk-share-delivery-points-modal';

const ShareSelectedDeliveryPointsToolbar: React.FC = () => {
    const { selectedDeliveryPoints: selectedUnitedDeliveryPointIds } = useSelectedPoints();

    const [isBulkShareModalOpen, setIsBulkShareModalOpen] = useState(false);
    const [isBulkRemoveSharingModalOpen, setIsBulkRemoveSharingModalOpen] = useState(false);

    const onShareButtonClicked = () => {
        setIsBulkShareModalOpen(true);
    };
    const onRemoveSharingButtonClicked = () => {
        setIsBulkRemoveSharingModalOpen(true);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="mt-4 mb-1 mr-2">
                    <Trans
                        i18nKey="delivery-point.share-delivery-points.selected-delivery-points-count"
                        values={{ count: selectedUnitedDeliveryPointIds?.length || 0 }}
                    />
                </p>
                <div>
                    {selectedUnitedDeliveryPointIds?.length > 0 && (
                        <>
                            <Button type="button" color="primary" size="lg" onClick={onShareButtonClicked} className="mx-2">
                                <Trans i18nKey="common.share" />
                            </Button>
                            <Button type="button" color="primary" size="lg" onClick={onRemoveSharingButtonClicked} className="mx-2">
                                <Trans i18nKey="delivery-point.share-delivery-points.remove-sharing" />
                            </Button>
                        </>
                    )}
                </div>
            </div>

            <BulkShareDeliveryPointsModal
                isOpen={isBulkShareModalOpen}
                close={() => setIsBulkShareModalOpen(false)}
                unitedDeliveryPointIds={selectedUnitedDeliveryPointIds}
            />

            <BulkRemoveSharingDeliveryPointsModal
                isOpen={isBulkRemoveSharingModalOpen}
                close={() => setIsBulkRemoveSharingModalOpen(false)}
                unitedDeliveryPointIds={selectedUnitedDeliveryPointIds}
            />
        </>
    );
};

export default ShareSelectedDeliveryPointsToolbar;
