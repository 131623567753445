import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../../main';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: number;
    bold?: boolean;
};

const AmountItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.overdue-invoice.receivable-amount')}>
                    <ItemPreviewContent bold={bold}>{formatCurrency(data)}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default AmountItem;
