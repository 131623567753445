import { faArrowRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint, SkSppNzpBeApiCustomerrequestCustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { ClickableElement } from '../../../components/common/clickable-element';
import HelpLinkWithModal from '../../../components/help/help-link-with-modal';
import { useApi } from '../../../hooks/use-api';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { CommodityEnum, KINDcodeHome, LoadingIndicator, PRICE_LINK_ZP, Routes } from '../../../main';
import { IRootState } from '../../../reducers';
import { formatAddress, isCustomerRequestPaid } from '../../../utils/utils';
import { useOpenRequestFromDeliveryPointDetail } from '../../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';
import { CustomerRequestEnum, CustomerRequestTypeEnum } from '../config/enums';
import PromptResumptionOfGas from './prompt-resumption-of-gas-content';

interface ICustomerRequestFilterProps {
    isOpen: boolean;
    onCloseModal: () => void;
    unitedDeliveryPoint?: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
}

type ICustomerRequestTemplate = {
    [key in CustomerRequestTypeEnum]: SkSppNzpBeApiCustomerrequestCustomerRequestTemplate;
};

const CustomerRequestAddNewModal: React.FC<ICustomerRequestFilterProps> = ({ isOpen, onCloseModal, unitedDeliveryPoint }) => {
    const [openDialog, setOpenDialog] = useState<'PROMPT_RESUMPTION_OF_GAS' | 'RESUMPTION_OF_GAS'>();
    const [t] = useTranslation();
    const api = useApi();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeRequestCreationProcess(requestName, {}, {}, paidTypeInRedux(requestName));
    };

    const codes = [
        CustomerRequestTypeEnum.ZOM_ZOZODZP, // prerusenie, obnovenie dodavky ZP z dovodu rekonstrukcie
        CustomerRequestTypeEnum.ZOP_P, // podanie podnetu
        CustomerRequestTypeEnum.ZOM_ZOFMC, // vystavenie faktury mimo cyklu
        CustomerRequestTypeEnum.ZOM_POVZ, // potvrdenie o vyske zavazkov
        CustomerRequestTypeEnum.ZOM_ZOPAOO, // Žiadosť o prerušenie dodávky plynu z dôvodu rekonštrukcie
        CustomerRequestTypeEnum.ZOM_POZV, // potvrdenie o zmluvnom vztahu
        CustomerRequestTypeEnum.ZOM_ZOUZ, // ziadost o ukoncenie zmluvy
        CustomerRequestTypeEnum.ZOP_US, // uhlikova stopka
        CustomerRequestTypeEnum.ZOM_CE, // cista EE
        CustomerRequestTypeEnum.ZOM_DOS, // ziadost o splatkovy kalendar
        CustomerRequestTypeEnum.ZOM_ZOUSM, // ziadost o uradnu skusku meradla ZP
        CustomerRequestTypeEnum.ZOM_P, // prepis
    ];

    const menuItem: CSSProperties = { fontSize: '1rem' };

    const fetchCustomerRequestTemplate = useCallback(
        (codesList) => async () =>
            await api.customerRequestTemplates
                .searchTemplates({ codes: codesList, paging: { offset: 0, size: codes.length } }, { secure: true })
                .then(
                    (res) =>
                        res.data?.result &&
                        res.data?.result.reduce<ICustomerRequestTemplate>((acc, tpl) => {
                            const key = tpl.code?.toString();
                            return key ? { ...acc, [key]: tpl } : acc;
                        }, {} as ICustomerRequestTemplate),
                ),
        [api.customerRequestTemplates, codes.length],
    );

    const { data: customerRequestTemplatesData } = useQuery<ICustomerRequestTemplate | undefined>({
        queryKey: ['customer-request-templates', codes, isOpen],
        queryFn: codes && isOpen && fetchCustomerRequestTemplate(codes),
    });

    const paidTypeInRedux = (requestName: CustomerRequestEnum) => {
        if (requestName === CustomerRequestEnum.REQUEST_OFFICIAL_TEST_METER_SHORT && isPaid(CustomerRequestTypeEnum.ZOM_ZOUSM)) {
            return { paid: true, comodity: CommodityEnum.GAS };
        }
        return undefined;
    };

    const isPaid = (key: CustomerRequestTypeEnum): boolean => {
        if (customerRequestTemplatesData && customerRequestTemplatesData[key] && unitedDeliveryPoint?.businessPartner?.queue) {
            return isCustomerRequestPaid(customerRequestTemplatesData[key], unitedDeliveryPoint?.businessPartner?.queue);
        }
        return false;
    };

    const isGasDeliveryPointPresent = useMemo(
        () => unitedDeliveryPoint?.deliveryPoints?.find((deliveryPoint) => deliveryPoint.type === 'ZP' && deliveryPoint.status === 'ACTIVE'),
        [unitedDeliveryPoint],
    );
    const isEletricityDeliveryPointPresent = useMemo(
        () => unitedDeliveryPoint?.deliveryPoints?.find((deliveryPoint) => deliveryPoint.type === 'EE' && deliveryPoint.status === 'ACTIVE'),
        [unitedDeliveryPoint],
    );

    const isBusinessPartnerHome = useMemo(() => unitedDeliveryPoint?.businessPartner?.kind?.code === KINDcodeHome, [
        unitedDeliveryPoint?.businessPartner,
    ]);

    const requestInvoicesData = useCallback(
        async () =>
            isOpen && loggedInCustomer?.id && unitedDeliveryPoint?.id
                ? api.customers
                      .customerInvoiceSearch(
                          loggedInCustomer?.id,
                          { unitedDeliveryPointIds: [unitedDeliveryPoint.id], customerUnpaid: true, shared: false, paging: { size: 1000 } },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
        [loggedInCustomer, api, unitedDeliveryPoint, isOpen],
    );

    const [mutateInvoicesData, { isLoading, data: invoicesData }] = useMutationWithError(requestInvoicesData, {});

    useEffect(() => {
        if (isOpen) {
            mutateInvoicesData();
        }
    }, [isOpen, mutateInvoicesData]);

    return (
        <Modal isOpen={isOpen} centered>
            <ModalBody className="p-5 m-auto">
                {isLoading && <LoadingIndicator />}
                <div className="d-flex align-items-start">
                    <h1 className="mb-5">
                        <Trans i18nKey="customer-request.new-request">Nová žiadosť</Trans>
                    </h1>
                    <button type="button" onClick={onCloseModal} className="close ml-auto" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <Row>
                    <Col md={12} className="p-3 mb-4">
                        <h6 className="mb-4">{t('customer-request.request-groups.order-services')}</h6>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_ZODE">Žiadosť o dodávku elektriny</Trans>
                            </div>
                        </ClickableElement>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.GAS_SUPPLY_NEW)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_ZODP">Žiadosť o dodávku plynu</Trans>
                            </div>
                        </ClickableElement>
                        {/*isEletricityDeliveryPointPresent && isBusinessPartnerHome && (
                            <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CLEAN_ELECTRICITY_SHORT)}>
                                <div style={menuItem} className="my-3">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOM_CE">Čistá elektrika</Trans>
                                </div>
                            </ClickableElement>
                        )*/}
                        {/*isBusinessPartnerHome && (
                            <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CARBON_SHANK_SHORT)}>
                                <div style={menuItem} className="my-3">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOP_US">Uhlíková stopka</Trans>
                                </div>
                            </ClickableElement>
                        )*/}
                    </Col>
                    <Col md={12} className="p-3 mb-4">
                        <h6 className="mb-4">{t('customer-request.request-groups.confirmation-documents')}</h6>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CONFIRMATION_AMOUNT_LIABILITIES_SHORT)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_POVZ">Potvrdenie o výške záväzkov</Trans>
                            </div>
                        </ClickableElement>
                        <ClickableElement
                            isText
                            onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CONFIRMATION_CONTRACTUAL_RELATIONSHIP_SHORT)}
                        >
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_POZV">Potvrdenie o zmluvnom vzťahu</Trans>
                            </div>
                        </ClickableElement>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REQUEST_INVOICE_OUT_CYCLE_SHORT)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_ZOFMC">Žiadosť o faktúru mimo cyklu</Trans>
                            </div>
                        </ClickableElement>
                    </Col>
                    {isGasDeliveryPointPresent && (
                        <Col md={12} className="p-3 mb-4">
                            <h6 className="mb-4">{t('customer-request.request-groups.interruption-and-resumption-of-supply')}</h6>
                            {isGasDeliveryPointPresent && (
                                <ClickableElement
                                    isText
                                    onClick={() =>
                                        startRequestInRequestsTab(CustomerRequestEnum.REQUEST_INTERRUPTION_AND_RESUMPTION_CONSUPTION_SHORT)
                                    }
                                >
                                    <div style={menuItem} className="my-3">
                                        <Trans i18nKey="enums.CustomerRequestType.ZOM_ZOPAOO">
                                            Žiadosť o prerušenie dodávky plynu z dôvodu rekonštrukcie
                                        </Trans>
                                    </div>
                                </ClickableElement>
                            )}
                            {isGasDeliveryPointPresent && (
                                <ClickableElement isText onClick={() => setOpenDialog('PROMPT_RESUMPTION_OF_GAS')}>
                                    <div style={menuItem} className="my-3">
                                        <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.title">
                                            Žiadosť o zrýchlené obnovenie dodávky zemného plynu
                                        </Trans>
                                    </div>
                                </ClickableElement>
                            )}
                            {isGasDeliveryPointPresent && (
                                <ClickableElement isText onClick={() => setOpenDialog('RESUMPTION_OF_GAS')}>
                                    <div style={menuItem} className="my-3">
                                        <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-gas-delivery" />
                                    </div>
                                </ClickableElement>
                            )}
                        </Col>
                    )}
                    <Col md={12} className="p-3 mb-4">
                        <h6 className="mb-4">{t('customer-request.request-groups.others')}</h6>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.TRANSCRIPT_NEW)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_P">Prepis</Trans>
                            </div>
                        </ClickableElement>
                        {isGasDeliveryPointPresent && (
                            <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REQUEST_OFFICIAL_TEST_METER_SHORT)}>
                                <div style={menuItem} className="my-3">
                                    <Trans i18nKey="customer-request.steps.official-test-of-the-meter.title">
                                        Žiadosť o úradnú skúšku meradla ZP
                                    </Trans>
                                </div>
                            </ClickableElement>
                        )}
                        {invoicesData?.result?.find((item) => item.installmentVisibility) && (
                            <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REPAYMENT_AGREEMENT_SHORT)}>
                                <div style={menuItem} className="my-3">
                                    <Trans i18nKey="enums.CustomerRequestType.ZOM_DOS">Dohoda o splátkach</Trans>
                                </div>
                            </ClickableElement>
                        )}
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REQUEST_TERMINATION_CONTRACT_SHORT)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="enums.CustomerRequestType.ZOM_ZOUZ">Žiadosť o ukončenie zmluvy ZP/EE</Trans>
                            </div>
                        </ClickableElement>
                        <ClickableElement isText onClick={() => startRequestInRequestsTab(CustomerRequestEnum.COMPLAINT_SHORT)}>
                            <div style={menuItem} className="my-3">
                                <Trans i18nKey="customer-request.steps.complaint.title">Podanie podnetu</Trans>
                            </div>
                        </ClickableElement>
                    </Col>
                    <Col md={12} className="p-3 mb-4">
                        <h6 className="mb-4">{t('customer-request.request-groups.change-data-and-payment')}</h6>
                        <div className="text-secondary mb-2 mr-2">{t('customer-request.request-groups.change-data-and-payment-description')}</div>
                        <Link
                            to={Routes.DELIVERY_POINTS + '/' + unitedDeliveryPoint?.id + '/data'}
                            className="d-flex align-items-center"
                            onClick={onCloseModal}
                        >
                            <ClickableElement onClick={() => null} isText className="text-decoration-underline-inverse">
                                <div style={menuItem} className="my-3">
                                    <b style={{ color: 'black' }}>{t('delivery-point.detail.actions.link-to-data')}</b>
                                </div>
                            </ClickableElement>
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </Link>
                    </Col>
                </Row>
            </ModalBody>
            <Modal isOpen={openDialog === 'RESUMPTION_OF_GAS'} centered>
                <ModalBody>
                    <button
                        type="button"
                        onClick={() => setOpenDialog(undefined)}
                        className="close text-right ml-auto mb-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <div style={menuItem} className="my-4">
                        <h4 className="mb-2">
                            <b style={{ color: 'black' }}>
                                <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-gas-delivery">
                                    Obnovenie dodávky plynu
                                </Trans>
                            </b>
                        </h4>
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-delivery-info-1">
                            Obnovenie dodávky plynu po skončení rekonštrukcie je potrebné riešiť osobne v
                        </Trans>{' '}
                        <a href="https://www.spp.sk/sk/domacnosti/kontakty/#map" target="_blank" rel="noopener noreferrer">
                            <Trans i18nKey="customer-request.steps.interaption-extra-info.customer-center">Zákazníckom centre SPP</Trans>
                        </a>
                        <Trans i18nKey="customer-request.steps.interaption-extra-info.resumption-of-delivery-info-2">alebo u distribútora*.</Trans>
                    </div>
                    <Trans i18nKey="customer-request.steps.interaption-extra-info.more-detail">Podrobnejšie informácie nájdete tu:</Trans>
                    <br />
                    <HelpLinkWithModal
                        className="d-inline"
                        classNameClickableElement="text-decoration-underline-inverse"
                        title="customer-request.steps.interaption-extra-info.how-resumption-of-delivery"
                        field={'NZT_ZOM_ZOPAOO'}
                    />
                    <div style={menuItem}>
                        <small className="text-secondary">
                            <Trans i18nKey="customer-request.steps.interaption-extra-info.company-operates-distribution-network">
                                * spoločnosť, ktorá prevádzkuje distribučnú sieť
                            </Trans>
                        </small>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={openDialog === 'PROMPT_RESUMPTION_OF_GAS'} centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.title">
                            Žiadosť o zrýchlené obnovenie dodávky zemného plynu
                        </Trans>
                    </h3>
                    <button type="button" onClick={() => setOpenDialog(undefined)} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>

                <div className="modal-header font-weight-bold">
                    <span>
                        {formatAddress(unitedDeliveryPoint?.address)} - {t('common.commodity.gas')}
                    </span>
                </div>
                {isPaid(CustomerRequestTypeEnum.ZOM_ZOZODZP) && (
                    <div className="modal-header rounded-0 font-weight-bold" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <Trans i18nKey="customer-request.prepare.attention-text">Táto žiadosť bude spoplatnená podľa</Trans>
                        <a href={PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer" className="ml-1">
                            {t('customer-request.prepare.actual-price-list')}
                        </a>
                    </div>
                )}
                <ModalBody>
                    {unitedDeliveryPoint && (
                        <PromptResumptionOfGas onClose={() => setOpenDialog(undefined)} unitedDeliveryPoints={unitedDeliveryPoint} />
                    )}
                </ModalBody>
            </Modal>
        </Modal>
    );
};

export default CustomerRequestAddNewModal;
