import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { IConsumptionAdditionalFilter, IConsumptionFilter } from '../consumption';
import ConsumptionFilterForm from './consumption-filter-form';

interface IConsumptionFilterProps {
    isOpen: boolean;
    onCloseModal: () => void;
    filter: Partial<IConsumptionFilter>;
    additionalFilter: Partial<IConsumptionAdditionalFilter>;
    onFilterChange: (newFilter: Partial<IConsumptionFilter>) => void;
    onAdditionalFilterChange: (newFilter: Partial<IConsumptionAdditionalFilter>) => void;
    selectedCommodity?: 'ZP' | 'EE';
    onRemoveFilter: () => void;
}
const ConsumptionFilterModal: React.FC<IConsumptionFilterProps> = ({
    isOpen,
    onCloseModal,
    filter,
    additionalFilter,
    onFilterChange,
    onAdditionalFilterChange,
    selectedCommodity,
    onRemoveFilter,
}) => {
    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <ModalBody>
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="common.tables.filter" />
                    </h3>
                    <ConsumptionFilterForm
                        currentFilter={filter}
                        currentAdditionalFilter={additionalFilter}
                        selectedCommodity={selectedCommodity}
                        onSubmit={(newFilter, newAdditionalFilter) => {
                            console.log(newFilter, newAdditionalFilter);
                            if (newFilter) {
                                onFilterChange(newFilter);
                            }
                            if (newAdditionalFilter) {
                                onAdditionalFilterChange(newAdditionalFilter);
                            }

                            onCloseModal();
                        }}
                        onRemoveFilter={onRemoveFilter}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default ConsumptionFilterModal;
