import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import { CommodityEnum } from '../../../models/enums';
import { formatAddress, formatDate } from '../../../utils/utils';
import ConsumptionReadModal from './consumption/components/consumption-read-modal';

interface IProps {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    backUrl: string;
    setShowHelpForElectricity2: (val: boolean) => void;
    setShowHelpForElectricity1: (val: boolean) => void;
    setShowHelpForGasReading: (val: boolean) => void;
}
const ConsumptionReadCard: React.FC<IProps> = ({
    deliveryPoint,
    backUrl,
    setShowHelpForElectricity2,
    setShowHelpForElectricity1,
    setShowHelpForGasReading,
}) => {
    const [t] = useTranslation();
    const [isSelfReadModalOpen, setIsSelfReadModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        deliveryPoint.type === 'ZP' && deliveryPoint?.readingCycleInfo?.requested && setShowHelpForGasReading(true);
        deliveryPoint.type === 'EE' &&
            deliveryPoint?.readingCycleInfo?.requested &&
            deliveryPoint.contract?.eeTariffCount === 2 &&
            setShowHelpForElectricity2(true);
        deliveryPoint.type === 'EE' &&
            deliveryPoint?.readingCycleInfo?.requested &&
            deliveryPoint.contract?.eeTariffCount !== 2 &&
            setShowHelpForElectricity1(true);
    }, [
        deliveryPoint.type,
        deliveryPoint.contract?.eeTariffCount,
        deliveryPoint?.readingCycleInfo?.requested,
        setShowHelpForGasReading,
        setShowHelpForElectricity1,
        setShowHelpForElectricity2,
    ]);

    return (
        <>
            {deliveryPoint?.readingCycleInfo?.requested && (
                <Card
                    key={deliveryPoint.id}
                    className="card text-decoration-none mb-5"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setIsSelfReadModalOpen(true);
                    }}
                >
                    <div className="card-body d-flex justify-content-between">
                        <div>
                            <h4>
                                <Trans
                                    i18nKey="delivery-point.detail.consumption.enter-self-read"
                                    values={{
                                        type:
                                            deliveryPoint.type === CommodityEnum.GAS
                                                ? t('enums.HelpVariantCommodityEnum.GAS')
                                                : t('enums.HelpVariantCommodityEnum.ELECTRICITY'),
                                    }}
                                />
                                {deliveryPoint.externalId && ` (${deliveryPoint.externalId})`}
                            </h4>
                            <p className="mb-0">
                                <Trans i18nKey="delivery-point.detail.consumption.self-read-period-to-enter" />
                                <br />
                                {deliveryPoint?.readingCycleInfo?.readingCycleFrom &&
                                    `${formatDate(new Date(deliveryPoint?.readingCycleInfo?.readingCycleFrom))}`}
                                {` - `}
                                {deliveryPoint?.readingCycleInfo?.readingCycleTo &&
                                    `${formatDate(new Date(deliveryPoint?.readingCycleInfo?.readingCycleTo))}`}
                            </p>
                        </div>
                        <i className="icon-chevron-right-16 text-secondary mt-2"></i>
                    </div>
                </Card>
            )}
            {deliveryPoint.contract?.id && (
                <ConsumptionReadModal
                    modalLabel={t('delivery-point.detail.consumption.self-meter-read')}
                    isOpen={isSelfReadModalOpen}
                    defaultSelectedCommodity={{ id: deliveryPoint.id, type: deliveryPoint.type }}
                    contractId={deliveryPoint.contract?.id}
                    dual={deliveryPoint?.type === CommodityEnum.ELECTRICITY && deliveryPoint?.contract?.eeTariffCount === 2}
                    onClose={(submit) => {
                        setIsSelfReadModalOpen(false);
                        if (submit) history.push(backUrl);
                    }}
                    title={formatAddress(deliveryPoint?.address)}
                    lastLowValue={deliveryPoint?.readingCycleInfo?.lastLowValue}
                    lastHighValue={deliveryPoint?.readingCycleInfo?.lastHighValue}
                    readingCycleFrom={deliveryPoint?.readingCycleInfo?.readingCycleFrom}
                    readingCycleTo={deliveryPoint?.readingCycleInfo?.readingCycleTo}
                />
            )}
        </>
    );
};

export default ConsumptionReadCard;
