import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

interface ITooltipWrapperProps {
    id: string;
    tooltipContent: string;
}

export const TooltipWrapper: React.FC<ITooltipWrapperProps> = ({ id, children, tooltipContent }) => {
    const wrapperId = `${id}-tooltip-wrapper`;
    return (
        <span id={wrapperId}>
            {children}
            <UncontrolledTooltip container={wrapperId} placement="top" target={id} popperClassName="mx-1">
                {tooltipContent}
            </UncontrolledTooltip>
        </span>
    );
};
