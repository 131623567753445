import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IComplainedAdvance } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IComplainedAdvance;
    bold?: boolean;
};

const ComplainedAdvanceItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const paymentTypeName = useMemo(() => {
        return data?.paymentType && paymentTypeCodeList?.find((item) => item.uuid === data?.paymentType?.uuid)?.name;
    }, [paymentTypeCodeList, data]);

    if (isObjectEmpty(data)) return <></>;
    const { amount, date, vs } = data;

    return (
        <>
            <ItemPreview>
                {date && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-advances.payment-date')} />
                        <ItemPreviewContent bold={bold}>{formatDateToPreview(date)}</ItemPreviewContent>
                    </>
                )}
                {amount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-advances.amount-paid')} />
                        <ItemPreviewContent bold={bold}>{amount} €</ItemPreviewContent>
                    </>
                )}
                {vs && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-invoice.variable-symbol')} />
                        <ItemPreviewContent bold={bold}>{vs}</ItemPreviewContent>
                    </>
                )}
                {paymentTypeName && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.complained-advances.payment-method')} />
                        <ItemPreviewContent bold={bold}>{paymentTypeName}</ItemPreviewContent>
                    </>
                )}
            </ItemPreview>
        </>
    );
};

export default ComplainedAdvanceItem;
