import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { forwardRef, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncing } from '../../hooks/use-debouncing';

interface IBaseSearchBoxProps {
    errors?: { [key: string]: any };
    getValue: () => string | undefined | null;
    setValue: (value: string | undefined | null) => void;
}

const BaseTextSearchBox = forwardRef(({ errors, setValue, getValue }: IBaseSearchBoxProps, ref: any) => {
    const [isFocused, setIsFocused] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();

    const clearInputContent = () => {
        setValue && setValue('');
        buttonRef.current?.click();
    };

    const inputValue = getValue();

    return (
        <div
            className={classNames('input-group search-box', {
                'is-focused': isFocused, // hack for IE11 as IE11 doesn't support focus-within
            })}
        >
            <input
                ref={ref}
                name="fullText"
                type="text"
                className="form-control"
                placeholder={t('action.search')}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />

            <div className={`search-input--clear pr-4 fade ${!!inputValue && isFocused ? 'show' : ''}`}>
                <FontAwesomeIcon icon={faTimes} size="lg" className="cursor-pointer" onClick={clearInputContent} />
            </div>

            <div className="input-group-append">
                <button ref={buttonRef} type="submit" className="btn btn-secondary text-primary">
                    <i className="icon-search fa-lg"></i>
                </button>
            </div>
            {errors && (
                <div style={{ color: 'red' }}>
                    <ErrorMessage errors={errors} name="fullText" />
                </div>
            )}
        </div>
    );
});

interface IFullTextSearchBoxProps {
    fullText?: string;
    setFullText: (fullText: string) => void;
    className?: string;
}

const FullTextSearchBox: React.FC<IFullTextSearchBoxProps> = ({ fullText, setFullText, className }) => {
    const { register, handleSubmit, errors, setValue, getValues } = useForm<{ fullText: string }>({ defaultValues: { fullText } });

    const debounce = useDebouncing();

    const onSubmit = handleSubmit((field) => {
        debounce(() => setFullText(field.fullText), 600);
    });
    const onReset = () => setFullText('');

    return (
        <form className={className} onSubmit={onSubmit} onReset={onReset} onChange={onSubmit}>
            <BaseTextSearchBox
                ref={register()}
                errors={errors}
                setValue={(val) => setValue('fullText', val)}
                getValue={() => getValues('fullText')}
            />
        </form>
    );
};

export default FullTextSearchBox;
