import React from 'react';
import { useTranslation } from 'react-i18next';
import { IProducts } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IProducts;
    bold?: boolean;
};
/** not usable */
const ProductsItem: React.FC<Props> = ({ data, bold }) => {
    const { ee, zp } = data;
    const [t] = useTranslation();

    if (isObjectEmpty(data) || (!ee && !zp)) return <></>;
    return (
        <ItemPreview title={t('customer-request.steps.new-customer-choice.title')}>
            {ee && <ItemPreviewContent bold={bold}>{t('customer-request.steps.new-customer-choice.change-electricity')}</ItemPreviewContent>}
            {zp && <ItemPreviewContent bold={bold}>{t('customer-request.steps.new-customer-choice.change-gas')}</ItemPreviewContent>}
        </ItemPreview>
    );
};

export default ProductsItem;
