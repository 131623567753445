import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { CommodityEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { DeliveryPointSwitchLabel } from '../../../../delivery-points/detail/delivery-point-switch/delivery-point-switch-label';
import { DeliveryPointSwitchModalButton } from '../../../../delivery-points/detail/delivery-point-switch/select/delivery-point-switch-modal-button';
import { CustomerRequestBlockTitleEnum } from '../../../config/enums';
import { blocksOfficialTestMeterWithoutMeterNumber } from '../../../config/request-config';

const BlockMeterNumber: React.FC = () => {
    const dispatch = useDispatch();
    const { additionalData, step, deliveryPointZp, request } = useSelector((state: IRootState) => state.customerRequest);

    const [deliveryPoint, setDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint>();
    const [deliveryPoints, setDeliveryPoints] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint[]>();

    const submit = () => {
        const selected = deliveryPoints?.find((db) => db.id === deliveryPoint?.id);
        dispatch(CustomerRequestActions.setDeliveryPointZp(selected));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    useEffect(() => {
        const onlyGasDPs = additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.type === CommodityEnum.GAS);
        onlyGasDPs && dispatch(CustomerRequestActions.setDeliveryPoint(onlyGasDPs[0])); //just for adress of delivery point in request header
        setDeliveryPoints(onlyGasDPs);
        const selectedDpUuid = deliveryPointZp?.id ?? additionalData?.dpuuid;
        if (selectedDpUuid) {
            const selected = onlyGasDPs?.find((db) => db.id === selectedDpUuid);
            if (selected) {
                dispatch(CustomerRequestActions.setDeliveryPointZp(selected));
                setDeliveryPoint(selected);
                return;
            }
        }
    }, [additionalData, step, dispatch, deliveryPointZp]);

    useEffect(() => {
        if (
            deliveryPoints?.length === 1 &&
            request?.blocks &&
            request?.blocks.length > 0 &&
            request?.blocks[0].key === CustomerRequestBlockTitleEnum.BLOCK_METER_NUMBER
        ) {
            dispatch(CustomerRequestActions.replaceBlocks(blocksOfficialTestMeterWithoutMeterNumber));
            dispatch(CustomerRequestActions.setDeliveryPointZp(deliveryPoints[0]));
            nextTick(() => {
                dispatch(CustomerRequestActions.openStep(0));
            });
        }
    }, [deliveryPoints, dispatch, request?.blocks]);

    return (
        <>
            {deliveryPoints
                ?.filter((dp) => dp.type === CommodityEnum.GAS)
                .map((dp) => (
                    <DeliveryPointSwitchModalButton
                        key={`deliverypoint-modal-${dp.type}-${dp.id}`}
                        isSelected={deliveryPoint?.id === dp.id}
                        onClick={() => setDeliveryPoint(dp)}
                    >
                        <DeliveryPointSwitchLabel deliveryPoint={dp} showMeterNumber />
                    </DeliveryPointSwitchModalButton>
                ))}

            <BaseButton type="button" disabled={deliveryPoints?.find((db) => db.id === deliveryPoint?.id) ? false : true} onClick={submit}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockMeterNumber;
