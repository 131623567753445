import { SkSppNzpBeApiCustomerprofileInvoicePaymentInfo, SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { BaseButton } from '../../../../components/common';
import { formatAddress, formatAddressShort, formatCurrency, formatDate } from '../../../../utils/utils';
import { InvoiceType } from '../../components/invoice-type';

type Props = {
    handleSetPaymentAmount: (amountToPaid: number, invoiceNumbers: string[]) => void;
    invoiceAmount: number;
    invoiceId: string;
    paymentInfoData?: SkSppNzpBeApiCustomerprofileInvoicePaymentInfo | null;
    invoiceTypeCodeList?: SkSppNzpCommonsApiCodelistCodeListItem[] | null;
};

const PaymentInvoicePick: React.FC<Props> = ({ handleSetPaymentAmount, invoiceAmount, invoiceId, paymentInfoData, invoiceTypeCodeList }) => {
    const { t } = useTranslation();
    const unpaidInvoicesSummary = paymentInfoData?.invoicePaymentSummary?.unpaid;

    return (
        <>
            <div>
                <div className="bigger">
                    <Trans i18nKey="invoices.e-pay.invoice-pick-title">Uhradiť všetky faktúry?</Trans>
                </div>
                {unpaidInvoicesSummary?.invoices?.map((invoice) => (
                    <div key={'unpaidSummary_' + invoice.id} className="vertical-line my-2">
                        <div className="row bigger">
                            <div className="col-auto">
                                <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />
                            </div>
                            <div className="col font-weight-bold">
                                {invoice.unpaid != null ? formatCurrency(Math.abs(invoice.unpaid), invoice?.typeGroup === 'CREDIT') : ''}
                            </div>
                        </div>
                        <div className="row text-secondary small">
                            <div className="col">
                                {invoice.issueAt && formatDate(new Date(invoice.issueAt))},&nbsp;
                                {invoice?.deliveryPoints && !!invoice?.deliveryPoints.length && invoice?.deliveryPoints[0].address && (
                                    <>
                                        <span id={'ustuAddress' + invoice.id}>
                                            {formatAddressShort(invoice?.deliveryPoints[0].address)}
                                            &nbsp;-&nbsp;
                                            {invoice?.deliveryPoints && !!invoice?.deliveryPoints.length && invoice?.deliveryPoints[0].type && (
                                                <Trans i18nKey={`enums.CommodityEnum.${invoice?.deliveryPoints[0].type}`} />
                                            )}
                                            {invoice?.deliveryPoints && invoice?.deliveryPoints.length > 1 && <>&nbsp;...</>}
                                        </span>
                                        <UncontrolledTooltip target={'ustuAddress' + invoice.id}>
                                            {invoice.deliveryPoints.map((dp, index) => (
                                                <span key={'ustuAddress' + invoice.id + '_' + index}>
                                                    {formatAddress(dp.address)} - <Trans i18nKey={`enums.CommodityEnum.${dp.type}`} />
                                                    <br />
                                                </span>
                                            ))}
                                        </UncontrolledTooltip>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <BaseButton
                onClick={() =>
                    handleSetPaymentAmount(
                        unpaidInvoicesSummary?.amount ?? 0,
                        unpaidInvoicesSummary?.invoices?.map((inv) => inv.externalId?.toString() ?? '') ?? [],
                    )
                }
            >
                {t('invoices.e-pay.invoice-pick-all-button', {
                    sum: formatCurrency(unpaidInvoicesSummary?.amount ?? 0),
                    count: unpaidInvoicesSummary?.count,
                })}
            </BaseButton>
            <BaseButton onClick={() => handleSetPaymentAmount(invoiceAmount, [invoiceId])} className="btn-block btn-inverted btn-lg mt-3">
                {t('invoices.e-pay.invoice-pick-one-button', { sum: formatCurrency(invoiceAmount) })}
            </BaseButton>
        </>
    );
};

export default PaymentInvoicePick;
