import React from 'react';
import { useTranslation } from 'react-i18next';
import useSso from '../../hooks/sso/use-sso';

const LoginFallback: React.FC = () => {
    const [t] = useTranslation();
    const { ssoLogin } = useSso();

    return (
        <div className="container">
            <div className="row text-center py-5">
                <div className="col-12 position-static">
                    <i className="icon-circle-info-16 fa-5x text-warning d-block my-3"></i>
                    <h3 className="my-3 text-warning">{t('login-fallback.headline')}</h3>
                    <div className="text-center mt-4">
                        {t('login-fallback.message')}
                        <br />
                        <br />
                        <button className="btn btn-primary btn-lg" onClick={() => ssoLogin()}>
                            {t('login-fallback.action')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginFallback;
