import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import Routes from '../../../routes/routes';

const IssueConfirmMessageStep: React.FC = () => {
    return (
        <Col>
            <Card>
                <CardBody className="card-body text-center py-5">
                    <i className="icon-circle-ok-16 fa-5x text-success d-block mb-3"></i>
                    <h4 className="text-success mb-5">
                        <Trans i18nKey="report-issue.success-message" />
                    </h4>

                    <Link to={Routes.HOME} className="btn btn-primary btn-lg btn-block btn-md-inline">
                        <Trans i18nKey="report-issue.back-to-home" />
                    </Link>
                </CardBody>
            </Card>
        </Col>
    );
};

export default IssueConfirmMessageStep;
