import i18n from 'i18next';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { getHelpHash, matchHelpHash } from '../../hooks/use-help-text';
import { IRootState } from '../../reducers';
import { ClickableElement } from '../common';
import HelpModal from './help-modal';

type HelpLinkWithModalProps = {
    className?: string;
    classNameClickableElement?: string;
    wide?: boolean;
    showAsCard?: boolean;
    linkTitle?: string;
    title?: string;
    field?: string;
    hideTitle?: boolean;
};

const HelpLinkWithModal: React.FC<HelpLinkWithModalProps> = ({
    className,
    classNameClickableElement,
    wide,
    showAsCard,
    linkTitle,
    title,
    field,
    hideTitle,
}) => {
    const availableHelps = useSelector((state: IRootState) => state.help.availableHelps);
    const [show, setShow] = useState(false);
    const helpAvailable = field && matchHelpHash(availableHelps, getHelpHash(field.substring(0, 3), field, i18n.language));
    const finalTitle = title ? title : 'help.screen-field-title.' + (field ? field.substring(4) : 'null');
    const finalLinkTitle = linkTitle ? linkTitle : finalTitle;
    const finalScreen = field ? field.substring(0, 3) : 'NO';
    const finalField = field ? field : '';
    const cancel = () => {
        setShow(false);
    };

    return (
        <>
            {helpAvailable && !showAsCard && (
                <p className={className}>
                    <ClickableElement
                        onClick={(e) => {
                            setShow(true);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        isText
                        className={classNameClickableElement}
                    >
                        <Trans i18nKey={finalLinkTitle} />
                    </ClickableElement>
                    <HelpModal wide={wide} show={show} title={hideTitle ? '' : finalTitle} screen={finalScreen} field={finalField} cancel={cancel} />
                </p>
            )}

            {helpAvailable && !!showAsCard && (
                <Card className={className} onClick={() => setShow(true)}>
                    <CardBody className="cursor-pointer">
                        <div className="text-decoration-none d-flex small font-weight-bold align-items-center">
                            <Trans i18nKey={finalLinkTitle} />
                            <i className="icon-chevron-right-16 ml-auto text-secondary"></i>
                        </div>
                        <HelpModal
                            wide={wide}
                            show={show}
                            title={hideTitle ? '' : finalTitle}
                            screen={finalScreen}
                            field={finalField}
                            cancel={cancel}
                        />
                    </CardBody>
                </Card>
            )}
        </>
    );
};

export default HelpLinkWithModal;
