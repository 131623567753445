import { Api } from '@spp/spp-meru-frontend-common';
import { EpayApi } from '../views/invoices/e-pay/api/e-pay-api';

const apiConfig = {
    baseUrl: process.env.REACT_APP_API_URL,
    baseApiParams: {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
    },
    securityWorker: (securityData: any): RequestInit => {
        return {
            headers: securityData,
        };
    },
};

let api: Api;
let adminApi: Api;
let epayApi: EpayApi;

const useApi: () => Api = () => {
    if (!api) {
        api = new Api(apiConfig);
    }
    return api;
};

const useAdminApi: () => Api = () => {
    if (!adminApi) {
        adminApi = new Api(apiConfig);
    }
    return adminApi;
};

const useEpayApi: () => EpayApi = () => {
    if (!epayApi) {
        epayApi = new EpayApi({ ...apiConfig, baseUrl: process.env.REACT_APP_EPAY_URL });
    }
    return epayApi;
};

export { useApi, useAdminApi, useEpayApi };
