import { SkSppNzpBeApiCustomeraccessChallenge } from '@spp/spp-meru-frontend-common';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClickableElement } from '../../components/common';
import LoadingIndicator from '../../components/common/loading-indicator';
import useSso from '../../hooks/sso/use-sso';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IRootState } from '../../reducers';
import Routes from '../../routes/routes';

export const EmailChangeConfirmation: React.FC = () => {
    const urlQuery = useUrlQuery();
    const api = useApi();

    const isUserLogged = useSelector((s: IRootState) => s.user.isUserLogged);
    const code = urlQuery.get('challengeCode');
    const uuid = urlQuery.get('challengeCodeUuid');
    const [mutateApprovalChange, { isLoading, isSuccess, isError }] = useMutationWithError(
        async (emailChallengeData: SkSppNzpBeApiCustomeraccessChallenge) => api.customers.requestEmailChangeChallenge(emailChallengeData),
        {
            onSuccess: () => {
                if (isUserLogged) {
                    ssoLogout(null);
                }
            },
        },
    );
    const { ssoLogin, ssoLogout } = useSso();

    useEffect(() => {
        mutateApprovalChange({
            code: code || '',
            uuid: uuid || '',
        });
    }, [mutateApprovalChange, code, uuid]);

    return (
        <>
            <div className="container">
                <div className="row text-center py-5">
                    {isLoading && <LoadingIndicator size="large" fullscreen />}
                    <div className="col-12 position-static">
                        {isSuccess && (
                            <>
                                <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                                <h3 className="my-3 text-success">
                                    <Trans i18nKey="confirm-email-change.success" />
                                </h3>
                                <div className="text-center mt-4">
                                    <ClickableElement
                                        isText
                                        className="text-decoration-underline-inverse"
                                        onClick={(event) => ssoLogin({ backUrl: Routes.HOME })}
                                    >
                                        <Trans i18nKey="confirm-email-change.navigate-home" />
                                    </ClickableElement>
                                </div>
                            </>
                        )}
                        {isError && (
                            <>
                                <h3 className="my-3 text-danger">
                                    <Trans i18nKey="confirm-email-change.error" />
                                </h3>
                                <div className="text-center mt-4">
                                    <Link to={Routes.HOME}>
                                        <Trans i18nKey="confirm-email-change.navigate-home" />
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
