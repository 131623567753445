import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import removeAccent from 'remove-accents';
import { accessNestedProperty } from '../../../utils/utils';
import LoadingIndicator from '../loading-indicator';
import TypeaheadItem, { HighlightedItem } from './type-ahead-item';

interface ITypeahead<T> {
    labelKey: string;
    options: T[];
    defaultSelected?: T[];
    onSelect: (val: T[]) => void;
    onChange?: (val: string) => void;
    multiple?: boolean;
    placeholder?: string;
    emptyLabel: string;
    disabled?: boolean;
    hideChevron?: boolean;
    hideInputWhenOptionSelected?: boolean;
    optionsShown: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onResize?: (width: number, height: number) => void;
    setOptionsShown: (value: boolean) => void;
    loading?: boolean;
    errors?: FieldErrors;
    name?: string;
    className?: string;
    menuOptionsMaxWidth?: string;
}

function getLabel<T>(option: T, labelKey: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (option as any)[labelKey] as string;
}

function highlightItem<T>(option: T, labelKey: string, filter?: string): HighlightedItem<T> {
    const label = getLabel(option, labelKey);
    const ll = removeAccent(label.toLocaleLowerCase());
    const fl = filter ? filter.length : 0;
    if (filter && filter.length > 0) {
        const pos = ll.indexOf(filter);
        if (pos >= 0) {
            return {
                l1: label.substring(0, pos),
                l2: label.substring(pos, pos + fl),
                l3: label.substring(pos + fl),
                option: option,
            };
        }
    }
    return {
        l1: label,
        l2: '',
        l3: '',
        option: option,
    };
}

function highlightItems<T>(options: T[], selected: T[] | undefined, labelKey: string, filter?: string): Array<HighlightedItem<T>> {
    const lcFilter = filter ? removeAccent(filter.toLocaleLowerCase()) : undefined;
    return options
        .filter((item) => {
            const label = getLabel(item, labelKey);
            if (selected) {
                if (selected.some((item) => getLabel(item, labelKey) === label)) {
                    return false;
                }
            }
            const lcLabel = removeAccent(label.toLocaleLowerCase());
            return !lcFilter || lcLabel.indexOf(lcFilter) >= 0;
        })
        .map((item) => highlightItem(item, labelKey, lcFilter));
}

let preventOptionsToShow = false;

function Typeahead<T>({
    labelKey,
    options,
    defaultSelected,
    onSelect,
    onChange,
    loading,
    placeholder,
    emptyLabel,
    disabled,
    optionsShown,
    hideChevron,
    hideInputWhenOptionSelected,
    onFocus,
    onBlur,
    onResize,
    setOptionsShown,
    multiple,
    errors,
    name,
    className,
    menuOptionsMaxWidth,
}: ITypeahead<T>): ReactElement {
    const inputBoxElement = React.createRef<HTMLDivElement>();
    const inputElement = React.createRef<HTMLInputElement>();
    let timeoutId: NodeJS.Timeout;
    const [isFocused, setFocused] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [selected, setSelected] = useState<T[]>(defaultSelected ? defaultSelected : []);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));
    const isValid = errors == null || errorProperty == null;

    useEffect(() => {
        if (!hideInputWhenOptionSelected) {
            setSelected(defaultSelected || []);
        }
    }, [defaultSelected, hideInputWhenOptionSelected]);

    useEffect(() => {
        const width = inputBoxElement.current?.clientWidth;
        const height = inputBoxElement.current?.clientHeight;
        if (onResize && width && height && (size.width !== width || size.height !== height)) {
            setSize({ width, height });
            onResize(width, height);
        }
    }, [onResize, inputBoxElement, size]);

    const [filteredOptions, setFilteredOptions] = useState(highlightItems(options, selected, labelKey));
    const [filteredOptionsIndex, setFilteredOptionsIndex] = useState<number>(-1);

    useEffect(() => {
        setFilteredOptions(highlightItems(options, selected, labelKey));
    }, [options, labelKey, selected]);

    const doFocus = () => {
        if (inputElement?.current) {
            inputElement.current.focus();
        }
    };

    const onInputBoxKeyDown = (e: KeyboardEvent<Element>) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
            if (selectedIndex >= 0) {
                removeOption(selectedIndex);
            }
        }
        if (e.key === 'Backspace' && selectedIndex === -1 && !inputElement.current?.value) {
            setSelectedIndex(selected.length - 1);
        }
        if (e.key === 'ArrowDown') {
            setOptionsShown(true);
            if (filteredOptionsIndex < filteredOptions.length - 1) {
                setFilteredOptionsIndex(filteredOptionsIndex + 1);
            }
        }
        if (e.key === 'ArrowUp') {
            setOptionsShown(true);
            if (filteredOptionsIndex >= 0) {
                setFilteredOptionsIndex(filteredOptionsIndex - 1);
            }
        }
        if (e.key === 'Enter') {
            if (filteredOptionsIndex >= 0) {
                e.preventDefault();
                const option = filteredOptions[filteredOptionsIndex];
                if (option) {
                    addOption(filteredOptions[filteredOptionsIndex]);
                }
            }
        }
    };

    const removeOption = (index: number) => {
        const newSelected = [...selected];
        newSelected.splice(index, 1);
        onSelect(newSelected);
        const filter = inputElement.current?.value;
        setSelectedIndex(-1);
        setSelected(newSelected);
        setFilteredOptions(highlightItems(options, newSelected, labelKey, filter));
        setFilteredOptionsIndex(-1);
        setOptionsShown(false);
        preventOptionsToShow = true;
        setTimeout(() => {
            preventOptionsToShow = false;
        }, 0);
    };

    const addOption = (item: HighlightedItem<T>) => {
        let newSelected: T[] = [];
        if (multiple) {
            newSelected = [...selected];
        }
        newSelected.push(item.option);
        onSelect(newSelected);
        if (inputElement.current) {
            inputElement.current.value = '';
        }
        setSelected(newSelected);

        setFilteredOptions(highlightItems(options, newSelected, labelKey));
        setFilteredOptionsIndex(-1);
        doFocus();
        setOptionsShown(false);
        preventOptionsToShow = true;
        setTimeout(() => {
            preventOptionsToShow = false;
        }, 0);
    };

    const handleOnFocus = () => {
        clearTimeout(timeoutId);
        setFocused(true);
        if (onFocus) {
            onFocus();
        }
    };

    const handleOnBlur = () => {
        const elementToClear = inputElement.current;
        timeoutId = setTimeout(() => {
            setFocused(false);
            setOptionsShown(false);
            if (elementToClear) {
                elementToClear.value = '';
            }
            if (onBlur) {
                onBlur();
            }
        }, 0);
    };

    const applyFilter = () => {
        const filter = inputElement.current?.value;
        onChange && onChange(filter || '');
        setOptionsShown(true);
        setFilteredOptionsIndex(-1);
        setFilteredOptions(highlightItems(options, selected, labelKey, filter));
    };

    const hideInput = hideInputWhenOptionSelected && !!selected.length;

    return (
        <div className="rbt" tabIndex={-1} style={{ outline: 'none', position: 'relative' }} onFocus={handleOnFocus} onBlur={handleOnBlur}>
            <div
                ref={inputBoxElement}
                className={classNames('rbt-input-multi', 'form-control', 'rbt-input', {
                    'is-focused': isFocused,
                    disabled: disabled,
                })}
                onClick={doFocus}
                onKeyDown={onInputBoxKeyDown}
            >
                <div className="rbt-input-wrapper">
                    {selected
                        ? selected.map((option, index) => (
                              <div
                                  key={index}
                                  className={classNames('rbt-token', 'rbt-token-removeable', {
                                      'rbt-token-active': index === selectedIndex,
                                  })}
                                  tabIndex={0}
                                  onFocus={() => setSelectedIndex(index)}
                              >
                                  {getLabel(option, labelKey)}
                                  <button
                                      tabIndex={-1}
                                      aria-label="Remove"
                                      className="close rbt-close rbt-token-remove-button"
                                      type="button"
                                      onClick={() => removeOption(index)}
                                      disabled={disabled}
                                  >
                                      <span aria-hidden="true">×</span>
                                      <span className="sr-only">Remove</span>
                                  </button>
                              </div>
                          ))
                        : null}
                    {!hideInput && (
                        <div style={{ display: 'flex', flex: '1 1 0%', height: '100%', position: 'relative' }}>
                            <input
                                ref={inputElement}
                                autoComplete="off"
                                type="text"
                                aria-autocomplete="list"
                                aria-haspopup="listbox"
                                className={classNames(className ? className : 'rbt-input-main', {
                                    'is-invalid': !isValid,
                                    'is-valid': isValid,
                                })}
                                placeholder={selected.length === 0 ? placeholder : ''}
                                onChange={applyFilter}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: '0px',
                                    boxShadow: 'none',
                                    cursor: 'inherit',
                                    outline: 'none',
                                    padding: '0px',
                                    width: '100%',
                                    zIndex: 1,
                                }}
                                aria-owns="typeahead-autocomplete"
                                onFocus={() => {
                                    preventOptionsToShow || setOptionsShown(true);
                                }}
                                onClick={() => {
                                    preventOptionsToShow || setOptionsShown(true);
                                }}
                                disabled={disabled}
                            />
                            {!hideChevron && <FontAwesomeIcon style={{ margin: 'auto', color: '#a6a6a6' }} icon={faChevronDown} />}
                            {!isValid && errors != null && (
                                <div className="invalid-feedback small d-none d-sm-block">
                                    <ErrorMessage errors={errors} name={name || ''} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {inputBoxElement.current?.clientHeight}
            {optionsShown && (
                <div
                    aria-label="menu-options"
                    className="rbt-menu dropdown-menu show"
                    role="listbox"
                    style={{
                        position: 'absolute',
                        display: 'block',
                        maxHeight: '300px',
                        overflow: 'auto',
                        willChange: 'transform',
                        maxWidth: menuOptionsMaxWidth,
                        width: size.width ? size.width + 10 + 'px' : '100%',
                        top: size.width ? '0px' : 'unset',
                        left: '0px',
                        transform: 'translate3d(0px, ' + size.height + 'px, 0px)',
                    }}
                >
                    {loading ? (
                        <span role="option" aria-selected={false} className="dropdown-item disabled" style={{ height: 50 }}>
                            <LoadingIndicator size="medium" />
                        </span>
                    ) : filteredOptions.length > 0 ? (
                        filteredOptions.map((item, index) => (
                            <TypeaheadItem key={index} item={item} index={index} selectedIndex={filteredOptionsIndex} addOption={addOption} />
                        ))
                    ) : (
                        <span role="option" aria-selected={false} className="dropdown-item disabled">
                            {emptyLabel}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}

export default Typeahead;
