import {
    SkSppNzpBeApiCustomerrequestCustomerRequest as CustomerRequest,
    SkSppNzpBeApiCustomerprofileBusinessPartner,
} from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Alert, ListGroup } from 'reactstrap';
import BaseButton from '../../../../../components/common/base-button';
import Paginator from '../../../../../components/common/paginator';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { CustomerRequestStatusEnum } from '../../../../../models/enums';
import { CustomerRequestPayloadType, IStep } from '../../../../../reducers/interfaces/customer-request-state';
import Routes from '../../../../../routes/routes';
import { dateFromIsoToFormat } from '../../../../../utils/date-utils';
import { CanceledRequestStatuses, CustomerRequestEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import { complaintDataComponent } from '../../../config/request-data';
import AgreePaidRequest from '../../steps-customer-request/block-completion/items/agree-paid-request';
import BusinessPartnerSummaryItem from '../../steps-customer-request/block-completion/items/business-partner-summary-item';
import DeliveryPointSummaryItem from '../../steps-customer-request/block-completion/items/delivery-point-summary-item';
import DeliveryPointSummaryTranscript from '../../steps-customer-request/block-completion/items/delivery-point-summary-transcript';
import NewPropertyOwnerSummaryItem from '../../steps-customer-request/block-completion/items/new-property-owner-summary-item';
import AttachmentsItem from './../../steps-customer-request/block-completion/items/attachments-item';

type Props = {
    request: CustomerRequest;
    onRequestCancel: () => void;
    onRequestSend: () => void;
    onRequestContinue: (
        requestName: CustomerRequestEnum,
        step: IStep,
        content: CustomerRequestPayloadType,
        businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartner,
    ) => void;
};

const InfoDetailCustomerRequest: React.FC<Props> = ({ request, onRequestCancel, onRequestContinue }) => {
    const showCancelButton: boolean = useMemo(() => {
        if (request.customerRequestTemplate.automated === true) {
            return false;
        }
        const isCanceled =
            request.status != null &&
            Object.values(CanceledRequestStatuses).includes(request.status?.code as CanceledRequestStatuses) &&
            request.content.type !== CustomerRequestTypeEnum.ZZU_ADEF;
        const cancelRequested = request.requestCancel;
        return !isCanceled && !cancelRequested;
    }, [request]);

    const [pageIndex, setPageIndex] = useState(0);
    const history = useHistory();
    const pageSize = 2;

    const requestName =
        request.content.type === CustomerRequestTypeEnum.ZOM_ZODE
            ? CustomerRequestEnum.ELECTRICITY_SUPPLY_NEW
            : request.content.type === CustomerRequestTypeEnum.ZOM_ZODP
            ? CustomerRequestEnum.GAS_SUPPLY_NEW
            : undefined;

    const draftMetadata = () => {
        const parsed = request.draftMetadata && JSON.parse(request.draftMetadata);
        const step = parsed.step;
        const blocks = parsed.blocks;
        const content = { ...parsed.content, savedRequestBlocks: blocks };
        const businessPartner = parsed.businessPartner;
        return { step, content, businessPartner };
    };

    return (
        <>
            <div className="col-12 col-lg-6 order-2 order-lg-1">
                <h5 className="forms-headline mt-4 mb-4">
                    <Trans i18nKey="customer-request.detail.info-title">Údaje k žiadosti</Trans>
                </h5>

                <ListGroup className="list-group list-group-flush">
                    {request?.businessPartner && (
                        <BusinessPartnerSummaryItem data={request?.businessPartner} code={request.content.type} requestContent={request.content} />
                    )}
                    {request?.customerRequestContracts?.map((contract, i) => {
                        if (request.content.type === CustomerRequestTypeEnum.ZOM_P) {
                            //transcript additional info about delivery point
                            const isEe = contract.deliveryPoint?.type === 'EE';
                            return (
                                contract.deliveryPoint && (
                                    <DeliveryPointSummaryTranscript
                                        key={`dpSummary${contract.deliveryPoint.id}_${i}`}
                                        data={contract?.deliveryPoint}
                                        status={request.status?.code as CustomerRequestStatusEnum}
                                        contentInfoZp={(request.content as any).deliveryPointsZp}
                                        contentInfoEe={(request.content as any).deliveryPointsEe}
                                        isEe={isEe}
                                    />
                                )
                            );
                        } else {
                            return (
                                contract.deliveryPoint && (
                                    <DeliveryPointSummaryItem
                                        key={`dpSummary${contract.deliveryPoint.id}_${i}`}
                                        data={contract?.deliveryPoint}
                                        requestContent={request.content}
                                        code={request.content.type}
                                    />
                                )
                            );
                        }
                    })}
                    {Object.entries(request.content).map(([key, item]) => {
                        const ItemComponent = complaintDataComponent[key];

                        return (
                            ItemComponent && (
                                <ItemComponent
                                    key={key}
                                    data={item}
                                    code={request.content.type}
                                    requestContent={request.content}
                                    deliveryPointType={
                                        request.customerRequestContracts &&
                                        request.customerRequestContracts.length > 0 &&
                                        request.customerRequestContracts[0].deliveryPoint?.type
                                    }
                                    businessPartner={request.businessPartner}
                                />
                            )
                        );
                    })}
                    {request?.completionCustomerEmail && (
                        <NewPropertyOwnerSummaryItem email={request?.completionCustomerEmail} completionView={request.completionView} />
                    )}
                    {request?.content?.paymentApproval && <AgreePaidRequest />}
                    {request?.attachments && <AttachmentsItem data={request?.attachments} bold cropped={request?.attachmentsCropped} />}
                </ListGroup>

                {request.content.type === CustomerRequestTypeEnum.ZOM_ZOPAOO && (
                    <div className="mt-2 mx-1">
                        <HelpLinkWithModal
                            className="d-inline"
                            classNameClickableElement="text-decoration-underline-inverse"
                            title="customer-request.steps.interaption-extra-info.how-resumption-of-delivery"
                            field={'NZT_ZOM_ZOPAOO'}
                        />
                    </div>
                )}

                <div className="text-center my-5">
                    {request.status?.code === CustomerRequestStatusEnum.PRE_CREATED && requestName && (
                        <BaseButton
                            onClick={() =>
                                onRequestContinue(requestName, draftMetadata().step, draftMetadata().content, draftMetadata().businessPartner)
                            }
                            className="btn-primary btn-lg"
                        >
                            <Trans i18nKey="customer-request.detail.continue-request" />
                        </BaseButton>
                    )}
                    {request.status?.code === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS && request.completionView === 'COMPLETION' && (
                        <BaseButton
                            onClick={() => history.push(`${Routes.CUSTOMER_REQUEST_TRANSCRIPT_NEW_CUSTOMER}?customerRequestId=${request.uuid}`)}
                            className="btn-primary btn-lg"
                        >
                            <Trans i18nKey="customer-request.detail.fill-request" />
                        </BaseButton>
                    )}
                    {showCancelButton && (
                        <BaseButton onClick={onRequestCancel} className="btn-primary btn-lg  ml-2">
                            <Trans i18nKey="customer-request.detail.cancel-request"> Zrušiť žiadosť</Trans>
                        </BaseButton>
                    )}
                </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
                {request.notes && request.notes?.length > 0 && (
                    <h5 className="forms-headline mt-5 mb-4">
                        <Trans i18nKey="customer-request.detail.notes-title">Poznamky k žiadosti</Trans>
                    </h5>
                )}
                {request.notes && request.notes?.length > 0 && (
                    <div className="mb-2">
                        {request.notes
                            .sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime())
                            .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
                            .map((item) => (
                                <Alert color="info" className="p-3 mb-1" key={item?.uuid}>
                                    <span style={{ fontSize: '0.8rem !important' }}>{dateFromIsoToFormat(item?.createdAt)}</span>
                                    <p>
                                        {item?.text?.split('\\n').map((t, index) => (
                                            <>
                                                {index > 0 && <br />}
                                                {t}
                                            </>
                                        ))}
                                    </p>
                                </Alert>
                            ))}
                    </div>
                )}
                <Paginator
                    pageIndex={pageIndex}
                    dataLength={request.notes?.length}
                    onPageChanged={(newPage: number) => {
                        setPageIndex(newPage);
                    }}
                    pageSize={pageSize}
                />
            </div>
        </>
    );
};

export default InfoDetailCustomerRequest;
