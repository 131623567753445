import React from 'react';
import { Trans } from 'react-i18next';
import { ClickableElement } from '../../common';

type Props = {
    extraClass?: string;
    collapsed: boolean;
    onToggle: () => void;
};

const HamburgerNavButton: React.FC<Props> = ({ extraClass, collapsed, onToggle }) => {
    return (
        <ClickableElement
            onClick={onToggle}
            className={`navbar-toggler py-0 ${extraClass} ${collapsed ? 'collapsed' : ''}`}
            style={{ width: '100px', textAlign: 'center' }}
            aria-controls="navbarSupportedContent"
            aria-expanded={!collapsed}
            aria-label="Toggle navigation"
        >
            <span className="icon-bar top-bar mx-auto"></span>
            <span className="icon-bar middle-bar mx-auto"></span>
            <span className="icon-bar bottom-bar mx-auto"></span>
            <span className="navbar-text pb-1">
                {collapsed && <Trans i18nKey="common.menu">Menu</Trans>}
                {!collapsed && <Trans i18nKey="common.to-close">Zatvoriť</Trans>}
            </span>
        </ClickableElement>
    );
};

export default HamburgerNavButton;
