import { SkSppNzpBeApiCustomerprofileBusinessPartnerSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KINDcodeHome } from '../../../../../../main';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import BusinessPartnerCompanyItem from './business-partner-company-item';
import BusinessPartnerPersonItem from './business-partner-person-item';

type Props = {
    data: IBusinessPartner;
    bold?: boolean;
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
};

const NewBusinessPartnerDataItem: React.FC<Props> = ({ data, bold, businessPartner }) => {
    const [t] = useTranslation();

    return (
        <>
            {businessPartner.kind?.code === KINDcodeHome ? (
                <BusinessPartnerPersonItem data={data} bold={bold} title={t('customer-request.steps.new-data.new-business-partner-data')} />
            ) : (
                <BusinessPartnerCompanyItem
                    data={data}
                    bold={bold}
                    title={t('customer-request.steps.new-data.new-business-partner-data')}
                ></BusinessPartnerCompanyItem>
            )}
        </>
    );
};

export default NewBusinessPartnerDataItem;
