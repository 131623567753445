import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileEInvoiceRequest, SkSppNzpBeApiCustomerrequestCustomerRequestSummary } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../components/common';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { IBusinessPartnerDeliveryPointData } from '../../../../models/model';
import UnifyEmailSelectDeliveryPoints from './unify-email-select-delivery-point';
import UnifyEmailSelectEmail from './unify-email-select-email';
import UnifyEmailSuccess from './unify-email-success';

interface IUnifyEmailModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    onUnifyEmail: (businessPartnerId: string, email: string) => void;
}

enum UnifyEmailStepsEnum {
    SELECT_DELIVERY_POINT = 1,
    SELECT_EMAIL,
    SUCCESS,
}

const UnifyEmailModal: React.FC<IUnifyEmailModalProps> = ({ isOpen, onCloseModal, onUnifyEmail }) => {
    const [step, setStep] = useState(UnifyEmailStepsEnum.SELECT_DELIVERY_POINT);
    const [selectedBusinessPartnerData, setSelectedBusinessPartnerData] = useState<IBusinessPartnerDeliveryPointData | undefined>(undefined);
    const [newEmail, setNewEmail] = useState<string | undefined>(undefined);

    const api = useApi();

    const [mutateEInvoiceUnifyEmail, { isLoading }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | null,
        unknown,
        { bpId: string; data: SkSppNzpBeApiCustomerprofileEInvoiceRequest }
    >(async (variables) => api.businessPartners.updateEInvoiceEmail(variables.bpId, variables.data, { secure: true }).then((res) => res.data), {
        onErrorWithGlobalErrorHandling: () => false,
        onSuccess: (data, variables) => {
            if (variables.data.email != null) {
                onUnifyEmail(variables.bpId, variables.data.email);
                setStep(UnifyEmailStepsEnum.SUCCESS);
            }
        },
    });

    useEffect(() => {
        if (isOpen) {
            setStep(UnifyEmailStepsEnum.SELECT_DELIVERY_POINT);
            setNewEmail(undefined);
            setSelectedBusinessPartnerData(undefined);
        }
    }, [isOpen]);

    const onSelectDeliveryPoint = (businessPartnerData: IBusinessPartnerDeliveryPointData) => {
        setSelectedBusinessPartnerData(businessPartnerData);
        setStep(UnifyEmailStepsEnum.SELECT_EMAIL);
    };

    const onSelectEmail = (email: string) => {
        setNewEmail(email);
        if (selectedBusinessPartnerData?.businessPartner.id) {
            mutateEInvoiceUnifyEmail({ bpId: selectedBusinessPartnerData.businessPartner.id, data: { email: email } });
        }
    };

    const onSuccessConfirm = () => {
        onCloseModal();
    };

    return (
        <>
            <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="settings.e-invoices.setting-united-email" />
                    </h3>
                    <button type="button" onClick={onCloseModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody>
                    {isLoading && <LoadingIndicator />}

                    {step === UnifyEmailStepsEnum.SELECT_DELIVERY_POINT && (
                        <UnifyEmailSelectDeliveryPoints
                            selectedBusinessPartnerId={selectedBusinessPartnerData?.businessPartner.id}
                            onSelect={onSelectDeliveryPoint}
                        />
                    )}
                    {step === UnifyEmailStepsEnum.SELECT_EMAIL && selectedBusinessPartnerData != null && (
                        <>
                            <div className="mb-4">
                                <ClickableElement onClick={() => setStep(UnifyEmailStepsEnum.SELECT_DELIVERY_POINT)}>
                                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-3 text-muted" />
                                </ClickableElement>
                            </div>
                            <UnifyEmailSelectEmail onSelect={onSelectEmail} businessPartnerData={selectedBusinessPartnerData} />
                        </>
                    )}
                    {step === UnifyEmailStepsEnum.SUCCESS && newEmail != null && <UnifyEmailSuccess email={newEmail} onConfirm={onSuccessConfirm} />}
                </ModalBody>
            </Modal>
        </>
    );
};

export default UnifyEmailModal;
