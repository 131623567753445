import { MutationConfig, MutationFunction, MutationResultPair, useMutation as useReactQueryMutation } from 'react-query';
import useGlobalErrorHandling from '../utils/global-error-handling-fn';

interface IMutationConfigWithError<TResult, TError = unknown, TVariables = undefined, TSnapshot = unknown>
    extends Omit<MutationConfig<TResult, TError, TVariables, TSnapshot>, 'onError'> {
    onErrorWithGlobalErrorHandling?: (err: TError, variables: TVariables) => boolean;
}
export interface IUseMutationObjectConfig<TResult, TError = unknown, TVariables = undefined, TSnapshot = unknown> {
    mutationFn: MutationFunction<TResult, TVariables>;
    config?: IMutationConfigWithError<TResult, TError, TVariables, TSnapshot>;
}

export default function useMutationWithError<TResult, TError = unknown, TVariables = undefined, TSnapshot = unknown>(
    mutationFn: MutationFunction<TResult, TVariables>,
    config?: IMutationConfigWithError<TResult, TError, TVariables, TSnapshot>,
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
    const { globalErrorHandlingFn } = useGlobalErrorHandling();

    return useReactQueryMutation<TResult, TError, TVariables, TSnapshot>(mutationFn, {
        ...config,
        onError: (err: any, variables: TVariables) => {
            if (
                config?.onErrorWithGlobalErrorHandling == null ||
                (config?.onErrorWithGlobalErrorHandling != null && config?.onErrorWithGlobalErrorHandling(err, variables) === false)
            ) {
                globalErrorHandlingFn(err?.error?.code);
            }
        },
    });
}
