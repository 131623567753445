import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryCounts,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../actions/customer-request-actions';
import { LoadingIndicator } from '../../../../components/common';
import LeavingPageGuard from '../../../../components/leaving-page-guard';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { IRootState } from '../../../../reducers';
import Routes, { CustomerRequestRoutes } from '../../../../routes/routes';
import { clearObjectProperties, createIndexedGroups, hideCustomerRequestBlockHeader } from '../../../../utils/utils';
import { useSendRequest } from '../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../delivery-points/detail/data/change-request-sent';
import { CodeListTypeEnum, CustomerRequestBlockTitleEnum, CustomerRequestEnum, CustomerRequestTypeEnum } from '../../config/enums';
import { blocksEeSupplyNewConnection, blocksZpSupplyNewConnection } from '../../config/request-config';
import { requestList } from '../../config/request-data';
import { eeSupllyRequest, zpSupllyRequest } from '../../customer-request-utils';
import { SubmissionStateType } from '../submission-customer-request/submission-customer-request';
import StepManagerHeader from './components/step-manager-header';
import StepsBlock from './components/steps-block';
import StepsBlockHeader from './components/steps-block-header';
import StepsProgressView from './components/steps-progress-view';

type ParamsProps = {
    requestId: CustomerRequestEnum;
};

const StepsManagerCustomerRequest: React.FC = () => {
    const history = useHistory();
    const { requestId } = useParams<ParamsProps>();
    const [invoiceTypeCodeList, setInvoiceTypeCodeList] = useState<SkSppNzpCommonsApiCodelistCodeListItem[] | undefined | null>();
    const initializeRequestCreationProcess = useSendRequest();
    const [isRequestSaved, setIsRequestSaved] = useState(false);
    const [isRequestSaving, setIsRequestSaving] = useState(false);

    const api = useApi();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const { customer, isUserLogged } = useSelector((state: IRootState) => state.user);
    const customerRequest = useSelector((state: IRootState) => state.customerRequest);

    const {
        request,
        currentBlock,
        additionalData,
        customerRequestTemplate,
        metadata,
        code,
        deliveryPoint,
        deliveryPointZp,
        deliveryPointEe,
        content,
        paid,
        attachments,
        step,
    } = customerRequest;

    const requestSend = useSelector((s: IRootState) => s.customerRequest.send);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentBlock]);

    const FormName = useMemo(() => currentBlock?.component, [currentBlock]);

    useEffect(() => {
        if (!customerRequestTemplate) history.replace(CustomerRequestRoutes.SELECT_NEW);
    }, [customerRequestTemplate, history]);

    const [mutatePartnerDeliveryPoints, { isLoading: isDeliveryPointLoading }] = useMutationWithError(
        async (customerId: string) =>
            api.customers
                .searchUnitedDeliveryPoint(
                    customerId,
                    {
                        type: code === 'ZOM_ZTP' ? ['ZP'] : code === 'ZOM_ZSE' ? ['EE'] : undefined,
                        shared: false,
                        deliveryPoint: { hidden: false },
                        paging: { size: 4999 },
                        includeInactive: false,
                    },
                    undefined,
                    { secure: true },
                )
                .then((res) => res.data?.result),
        {
            onSuccess: (resp) => {
                if (resp) {
                    const grouping = createIndexedGroups<UnitedDeliveryPointSummary>(resp, (udp) => udp.businessPartner?.id);
                    dispatch(CustomerRequestActions.setPartnersDeliveryPoints(grouping));
                }
            },
        },
    );

    useEffect(() => {
        return () => {
            dispatch(CustomerRequestActions.closeRequest());
        };
    }, [dispatch]);

    useEffect(() => {
        if (additionalData) {
            dispatch(
                CustomerRequestActions.setMetaData(
                    clearObjectProperties({ businessPartnerId: additionalData?.bpuuid, deliveryPointId: additionalData?.dpuuid }),
                ),
            );
        }
    }, [additionalData, dispatch]);

    useEffect(() => {
        const requestFromList = requestList[requestId];
        if (!requestFromList) history.push(CustomerRequestRoutes.SELECT_NEW);
        if (metadata?.businessPartnerId) {
            const blocks = requestFromList.blocks.filter((item) => item.key !== CustomerRequestBlockTitleEnum.BLOCK_ADDRESS);
            dispatch(CustomerRequestActions.setRequest({ ...requestFromList, blocks, step }));
        } else {
            let blocks = requestFromList?.blocks;
            if (content.savedRequestBlocks) {
                if (
                    requestFromList.type === CustomerRequestTypeEnum.ZOM_ZODE &&
                    content.savedRequestBlocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_OWNER_OBJECT
                ) {
                    blocks = blocksEeSupplyNewConnection;
                }
                if (
                    requestFromList.type === CustomerRequestTypeEnum.ZOM_ZODP &&
                    content.savedRequestBlocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_CONSUMPTION
                ) {
                    blocks = blocksZpSupplyNewConnection;
                }
            }
            if (
                request?.type === CustomerRequestTypeEnum.ZOM_ZODE &&
                request?.blocks.length > 1 &&
                request?.blocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_OWNER_OBJECT
            ) {
                blocks = blocksEeSupplyNewConnection;
            }
            if (
                request?.type === CustomerRequestTypeEnum.ZOM_ZODP &&
                request?.blocks.length > 1 &&
                request?.blocks[2]?.key === CustomerRequestBlockTitleEnum.BLOCK_CONSUMPTION
            ) {
                blocks = blocksZpSupplyNewConnection;
            }
            dispatch(CustomerRequestActions.setRequest({ ...requestFromList, blocks, step }));
        }
        if (step.current !== 0) {
            dispatch(CustomerRequestActions.openStep(step.current));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, history, dispatch, additionalData, metadata]);

    const { data: udpCounts } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryCounts | null>(
        ['delivery-points-search-count', customer?.id],
        async () =>
            customer?.id ? api.customers.unitedDeliveryPointSearchCount(customer?.id, {}, { secure: true }).then((response) => response.data) : null,
    );

    const hasActiveDeliveryPoints = udpCounts?.active != null && udpCounts.active > 0 && udpCounts?.owned != null && udpCounts.owned > 0;

    const [mutateInvoiceTypeCodeList] = useMutationWithError(
        async () =>
            api.codeLists
                .list(CodeListTypeEnum.INVOICE_TYPE, { paging: { size: 64 } }, { secure: true })
                .then((res) => (res.data?.result ? res.data?.result : null)),
        {
            onErrorWithGlobalErrorHandling: () => {
                return false;
            },
        },
    );

    useEffect(() => {
        if (request?.key === CustomerRequestEnum.CLAIM_INVOICE_SHORT) {
            mutateInvoiceTypeCodeList().then((resp) => setInvoiceTypeCodeList(resp));
        }
        if (customer?.id && request?.requiredPartnerDeliveryPoints) {
            mutatePartnerDeliveryPoints(customer.id);
        }
    }, [request?.key, request?.requiredPartnerDeliveryPoints, mutateInvoiceTypeCodeList, mutatePartnerDeliveryPoints, customer?.id]);

    const onRequestSave = () => {
        dispatch(CustomerRequestActions.setData({ saveRequested: true }));
    };

    useEffect(() => {
        if (content.saved) {
            setIsRequestSaving(true);
            const requestData =
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE
                    ? eeSupllyRequest(customerRequest, true)
                    : zpSupllyRequest(customerRequest, true);
            initializeRequestCreationProcess(requestData, attachments, undefined, true)
                .then(() => {
                    dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                    setIsRequestSaved(true);
                })
                .catch((err) => err.message)
                .finally(() => setIsRequestSaving(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saved]);

    return (
        <>
            {isRequestSaving && <LoadingIndicator />}
            <LeavingPageGuard
                messageBody={t(`customer-request.leave-body`)}
                messageHeader={t(`customer-request.leave-header`)}
                confirmButtonText={t(`customer-request.leave-confirm`)}
                cancelButtonText={t(`customer-request.leave-cancel`)}
                when={requestSend ? false : true}
                requestUuid={content.transcriptRequestId}
                beforeUnload
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={(location) => {
                    if (customerRequestTemplate) {
                        if ((location?.state as SubmissionStateType)?.userData && customerRequestTemplate) return false;
                        return true;
                    }
                    return false;
                }}
            />
            <StepManagerHeader
                onRequestCancel={history.goBack}
                deliveryPoint={deliveryPoint ?? deliveryPointEe ?? deliveryPointZp}
                invoiceTypeCodeList={invoiceTypeCodeList}
                invoice={content.invoice}
                onRequestSave={() => onRequestSave()}
                customerRequestTemplate={customerRequestTemplate}
                paid={paid}
                lastOpened={step.lastOpened}
            />

            <div className="container">
                {hasActiveDeliveryPoints ||
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_P ||
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOP_US ||
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE ||
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODP ? (
                    <>
                        {isUserLogged && isDeliveryPointLoading ? (
                            <LoadingIndicator fullscreen />
                        ) : (
                            <>
                                {request?.blocks && request.blocks.length > 1 && <StepsProgressView />}
                                <StepsBlock>
                                    {!hideCustomerRequestBlockHeader(request?.key) ? (
                                        <StepsBlockHeader
                                            title={t(`customer-request.steps.${currentBlock?.key}.title`)}
                                            subtitle={t(`customer-request.steps.${currentBlock?.key}.subtitle`)}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <>{FormName && <FormName />} </>
                                </StepsBlock>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="row justify-content-lg-center mb-5">
                            <div className="col-12">
                                <div className="text-center pt-5">
                                    <h4>
                                        <Trans i18nKey="customer-request.no-active-delivery-points" />
                                    </h4>
                                    <br />
                                    <Link to={`${Routes.CUSTOMER_REQUESTS}/${CustomerRequestRoutes.SELECT_NEW}`}>
                                        <Trans i18nKey="customer-request.back-to-request-type-selection" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Modal isOpen={isRequestSaved} centered>
                <ModalBody>
                    <ChangeRequestSent label={t('delivery-point.detail.modals.request-saved')} onClose={history.goBack} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default StepsManagerCustomerRequest;
