import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDealAmountEe, IDealAmountZp } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IDealAmountEe & IDealAmountZp;
    bold?: boolean;
};

const DealAmountItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { dealAmount, maximumReserveAmount, reserveAmount, maximumDailyAmount } = data;

    return (
        <>
            <ItemPreview>
                {maximumDailyAmount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.amount-gas.day-max-amount')} />
                        <ItemPreviewContent bold={bold}>{maximumDailyAmount}</ItemPreviewContent>
                    </>
                )}
                {dealAmount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.amount-electricity.reserved-capacity')} />
                        <ItemPreviewContent bold={bold}>{dealAmount}</ItemPreviewContent>
                    </>
                )}
                {maximumReserveAmount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.amount-electricity.max-reserved-capacity')} />
                        <ItemPreviewContent bold={bold}>{maximumReserveAmount}</ItemPreviewContent>
                    </>
                )}
                {reserveAmount && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.amount-electricity.contract-quantity')} />
                        <ItemPreviewContent bold={bold}>{reserveAmount}</ItemPreviewContent>
                    </>
                )}
            </ItemPreview>
        </>
    );
};

export default DealAmountItem;
