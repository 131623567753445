import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { formatBusinessPartner } from '../../../../main';
import { isEInvoiceActive } from '../utils/einvoice-utils';
import { IUdpToCustomerRequestMapping } from '../utils/use-fetch-multiple-customer-requests';
import { ActiveEinvoiceUdpGroup } from './active-einvoice-udp-group';
import { InactiveEinvoiceUdpGroup } from './inactive-einvoice-udp-group';

interface IEinvoiceBusinessPartnerBlock {
    unitedDeliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[];
    customerRequests: IUdpToCustomerRequestMapping[] | null | undefined;
    hideBusinessPartnerName?: boolean;
}

export const EinvoiceBusinessPartnerBlock: React.FC<IEinvoiceBusinessPartnerBlock> = ({
    unitedDeliveryPoints,
    customerRequests,
    hideBusinessPartnerName,
}) => {
    const businessPartner = unitedDeliveryPoints[0].businessPartner;

    return (
        <div className="mb-5" style={{ borderBottom: '1px solid #d3d3d3' }}>
            {!hideBusinessPartnerName && <h4 className="forms-headline mb-4">{formatBusinessPartner(businessPartner)}</h4>}

            {unitedDeliveryPoints.map((udp) =>
                isEInvoiceActive(udp) ? (
                    <ActiveEinvoiceUdpGroup key={udp.id} unitedDeliveryPoint={udp} customerRequests={customerRequests} />
                ) : (
                    <InactiveEinvoiceUdpGroup key={udp.id} unitedDeliveryPoint={udp} customerRequests={customerRequests} />
                ),
            )}
        </div>
    );
};
