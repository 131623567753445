import { faHourglass } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { Routes } from '../../../main';

interface IInfoDialogProps {
    visible: boolean;
    onClick: () => void;
}

const DpIsLoadingDialog: React.FC<IInfoDialogProps> = ({ visible, onClick }) => {
    return (
        <Modal isOpen={visible} centered>
            <ModalBody>
                <button type="button" onClick={onClick} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <FontAwesomeIcon icon={faHourglass} className="fa-lg text-center mx-auto mt-4 mb-3 d-block w-100" />
                <h3 className="text-center">
                    <Trans i18nKey="delivery-point.list-delivery-points.loading-in-progress">Prebieha načítanie odberného miesta</Trans>
                </h3>
                <p className="mb-4 text-center">
                    <Trans i18nKey="delivery-point.list-delivery-points.loading-in-progress-more-info">
                        Zvyčajne trvá 15 minút, o výsledku vás budeme informovať e-mailom.
                    </Trans>
                </p>
                <p className="mb-4 text-center">
                    <Link to={Routes.MANAGE_DELIVERY_POINTS}>
                        <Trans i18nKey="delivery-point.list-delivery-points.manage">Spravovať odberné miesta</Trans>
                    </Link>
                </p>
            </ModalBody>
        </Modal>
    );
};

export default DpIsLoadingDialog;
