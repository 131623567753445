import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { forwardRef, UIEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'reactstrap';
import { accessNestedProperty } from '../../utils/utils';

type BaseTextareaPropsType = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    name: string;
    label?: string | JSX.Element;
    counter?: string | JSX.Element;
    placeholder?: string;
    className?: string;
    errors?: { [key: string]: any };
    trigger?: ((name: any) => Promise<boolean>) | undefined;
    bottomLabel?: string | JSX.Element;
    optional?: boolean;
    touched?: boolean;
};

const BaseTextarea = forwardRef(
    (
        { name, label, placeholder, className, counter, errors, trigger, bottomLabel, optional, touched = false, ...params }: BaseTextareaPropsType,
        ref: any,
    ) => {
        const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));
        const isValid = errors == null || errorProperty == null;
        const [isTouched, setIsTouched] = useState(touched);
        const [scrollTop, setScrollTop] = useState(0);
        const [scrollbarShown, setScrollbarShown] = useState(false);
        const { i18n, t } = useTranslation();

        const manualErrorCheck = useCallback(() => {
            trigger && trigger(name);
        }, [name, trigger]);

        useEffect(() => {
            !isValid && manualErrorCheck();
        }, [i18n.language, isValid, manualErrorCheck]);

        const onScroll = (e: UIEvent<HTMLTextAreaElement>) => {
            setScrollTop(e.currentTarget?.scrollTop);
            setScrollbarShown(e.currentTarget?.scrollHeight > e.currentTarget?.clientHeight);
        };

        return (
            <FormGroup>
                <textarea
                    {...params}
                    className={classNames(className || '', 'form-control', {
                        'is-invalid': !isValid,
                        'is-valid': isValid && isTouched,
                        'is-scrollbar': scrollbarShown,
                    })}
                    ref={ref}
                    name={name}
                    required // for style application only, is not validated as required
                    placeholder={placeholder || ' '}
                    onChange={(e) => {
                        setScrollbarShown(e.currentTarget?.scrollHeight > e.currentTarget?.clientHeight);
                        manualErrorCheck();
                        if (isTouched === false) {
                            setIsTouched(true);
                        }
                    }}
                    onScroll={onScroll}
                />
                {scrollTop === 0 && (
                    <label className="control-label">
                        {label}
                        {` ${optional ? t('common.input-rules.optional') : ''}`}
                    </label>
                )}
                <small>{counter}</small>
                {!!bottomLabel && (
                    <small>
                        <br />
                        {bottomLabel}
                    </small>
                )}
                {!isValid && errors != null && (
                    <div className="invalid-feedback small">
                        <ErrorMessage errors={errors} name={name} />
                    </div>
                )}
            </FormGroup>
        );
    },
);

export default BaseTextarea;
