import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValue,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    createContainer,
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryTooltipProps,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
} from 'victory';
import { BaseSelect, BaseSwitch, ClickableElement, LoadingIndicator } from '../../../../../components/common';
import { triggerDownloadFile } from '../../../../../components/common/export/export-modal';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { useApi, useDebouncing } from '../../../../../main';
import { ConsumptionPowerGraphFormattedUnits, ConsumptionValueUnits, DateScaleConsumption } from '../../../../../models/enums';
import { dateFromIsoToFormat } from '../../../../../utils/date-utils';
import { formatDateDayMonthYearTime, formatNumber } from '../../../../../utils/utils';
import useFetchSmartMeterReading, { Data } from '../use-fetch-smart-meter-reading';
import ConsumptionMeteringGraphExportModalReadModal from './consumption-metering-graph-export-modal';

type Props = {
    contractId: string | undefined;
    timestampFrom?: string;
    timestampTo?: string;
    commodityType: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
    units: ConsumptionValueUnits;
    scale: DateScaleConsumption;
    changeScale: (value: DateScaleConsumption) => void;
    minimumTimestamp?: Date;
    maximumTimestamp?: Date;
};

const CHART_HEIGHT = 350;
const GRAPH_FONT_SIZE = '14';
// TODO get color from variable $color-nav-bg
const METTERING_LINE_COLOR = '#FFD500';
const RK_COLOR = '#70D696';
const MRK_COLOR = '#ED9F84';
const DMM_COLOR = '#88BCF7';

const fontFamily = 'inherit';

const ANGLED = 930;
const PADDING_LEFT = 55;
const PADDING_RIGHT = 55;
const PADDING_TOP = 30;
const PADDING_BOTTOM = 60;

const TOOLTIP_WIDTH = 160;

enum LINE_NAME {
    reserveAmount = 'reserveAmount',
    maximumReserveAmount = 'maximumReserveAmount',
    maximumDailyAmount = 'maximumDailyAmount',
    main = 'main',
}

// const NOW = 1608640126683;
// const QUARTER_OF_HOUR = 900000;

// const dataRawFakeData = [
//     ...Array(35000)
//         .fill(0)
//         .map((x, index) => {
//             return { x: new Date(NOW + QUARTER_OF_HOUR * index), y: Math.random() * 2500 };
//         }),
// ];

const MAX_POINTS = 700 * 2;

const ConsumptionMeteringGraph: React.FC<Props> = ({ contractId, commodityType, units, scale, changeScale, minimumTimestamp, maximumTimestamp }) => {
    const programsColumnRef = useRef<HTMLDivElement>(null);
    const [isExportModalOpen, setExportModalOpen] = useState<boolean>(false);

    /*
    const [timestampFrom, setTimestampFrom] = useState<Date>();
    const [timestampTo, setTimestampTo] = useState<Date>();
    */

    const [timeInterval, setTimeInterval] = useState<{ from: Date; to: Date }>();
    const [loadingTimeInterval, setLoadingTimeInterval] = useState<{ from: Date; to: Date; scale: DateScaleConsumption }>();

    const debounceHandler = useDebouncing();

    // const [minimalDifference, setMinimalDifference] = useState<number | undefined>(undefined);
    /*
    const { data: dataRawRealData, reservedValues, isLoading, isFetching } = useFetchSmartMeterReading({
        contractId,
        timestampFrom,
        timestampTo,
        units,
        scale,
    });
    */

    const { data, reservedValues, isLoading } = useFetchSmartMeterReading({
        contractId: loadingTimeInterval ? contractId : undefined,
        timestampFrom: loadingTimeInterval ? loadingTimeInterval.from.toISOString() : undefined,
        timestampTo: loadingTimeInterval ? new Date(loadingTimeInterval.to.getTime() + 10000000).toISOString() : undefined,
        units: commodityType === 'EE' ? 'KW' : 'M3',
        interval: MAX_POINTS,
    });

    useEffect(() => {
        if (!timeInterval || !minimumTimestamp || !maximumTimestamp) {
            return;
        }
        const timeIntervalFrom = timeInterval.from < minimumTimestamp ? minimumTimestamp : timeInterval.from;
        const timeIntervalTo = timeInterval.to > maximumTimestamp ? maximumTimestamp : timeInterval.to;
        if (
            loadingTimeInterval &&
            timeIntervalFrom >= loadingTimeInterval.from &&
            timeIntervalTo <= loadingTimeInterval.to &&
            loadingTimeInterval.scale === scale
        ) {
            return;
        }

        const preloadingInterval = (timeInterval.to.getTime() - timeInterval.from.getTime()) * 0.5;
        const min = new Date(timeInterval.from.getTime() - preloadingInterval);
        const max = new Date(timeInterval.to.getTime() + preloadingInterval);
        if (min < minimumTimestamp) {
            min.setTime(minimumTimestamp.getTime());
        }
        if (max > maximumTimestamp) {
            max.setTime(maximumTimestamp.getTime());
        }
        debounceHandler(() => {
            setLoadingTimeInterval({
                from: min,
                to: max,
                scale,
            });
        }, 100);
    }, [timeInterval, setLoadingTimeInterval, debounceHandler, scale, minimumTimestamp, maximumTimestamp, loadingTimeInterval]);

    const reserveAmount = reservedValues?.reserveAmount;
    const maximumReserveAmount = reservedValues?.maximumReserveAmount;
    const maximumDailyAmount = reservedValues?.maximumDailyAmount;
    const [width, setWidth] = useState(window.innerWidth);
    const { t } = useTranslation();

    const updateWidth = useCallback(() => {
        if (programsColumnRef) {
            const newWidth = programsColumnRef.current?.clientWidth;
            if (newWidth) {
                setWidth(newWidth);
            }
        }
    }, [setWidth]);

    useEffect(() => {
        updateWidth();
    }, [updateWidth]);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);

        // Removes listener on unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [updateWidth]);

    const CustomTooltip: React.FC<VictoryTooltipProps & { unit: ConsumptionPowerGraphFormattedUnits | null }> = (props) => {
        const xx = props.x || 0;
        const xwidth = (props.width || 1000) - PADDING_LEFT - PADDING_RIGHT;
        const xPos =
            xx < TOOLTIP_WIDTH / 2 + PADDING_LEFT
                ? PADDING_LEFT
                : xx > xwidth - TOOLTIP_WIDTH / 2
                ? xwidth - TOOLTIP_WIDTH / 2
                : xx - TOOLTIP_WIDTH / 2;

        const textList: JSX.Element[] = [];
        props.activePoints?.forEach((label: Data & { childName: LINE_NAME }) => {
            if (label.childName === LINE_NAME.main) {
                textList[0] = (
                    <div>
                        <b>{formatNumber(Math.round(label.y))}</b>&nbsp;{props.unit}
                        <br />
                        <span style={{ fontSize: '90%' }}>
                            {dateFromIsoToFormat(label.from, 'DD/MM/YYYY') === dateFromIsoToFormat(label.to, 'DD/MM/YYYY') ? (
                                <>
                                    {dateFromIsoToFormat(label.from, 'D.M.YYYY')} {dateFromIsoToFormat(label.from, 'H:mm') + ' - '}
                                    {dateFromIsoToFormat(label.to, 'H:mm')}
                                </>
                            ) : (
                                <>
                                    {dateFromIsoToFormat(label.from, 'D.M.YYYY HH:mm')} - {dateFromIsoToFormat(label.to, 'DD.MM.YYYY HH:mm')}
                                </>
                            )}
                        </span>
                    </div>
                );
            } else if (label.childName === LINE_NAME.reserveAmount) {
                textList[1] = (
                    <>
                        <span style={{ color: RK_COLOR }}>{'\u25A0'}</span>
                        &nbsp;
                        <Trans i18nKey="delivery-point.detail.consumption.reserved-capacity-acronym">RK</Trans>:&nbsp;
                        <b>{formatNumber(Math.round(label.y))}</b>&nbsp;
                        {props.unit}
                    </>
                );
            } else if (label.childName === LINE_NAME.maximumReserveAmount) {
                textList[2] = (
                    <>
                        <span style={{ color: MRK_COLOR }}>{'\u25A0'}</span>
                        &nbsp;
                        <Trans i18nKey="delivery-point.detail.consumption.maximal-reserved-capacity-acronym">MRK</Trans>:&nbsp;
                        <b>{formatNumber(Math.round(label.y))}</b>&nbsp;
                        {props.unit}
                    </>
                );
            } else if (label.childName === LINE_NAME.maximumDailyAmount) {
                textList[3] = (
                    <>
                        <span style={{ color: DMM_COLOR }}>{'\u25A0'}</span>
                        &nbsp;
                        <Trans i18nKey="delivery-point.detail.consumption.maximal-daily-capacity-acronym">DMM</Trans>:&nbsp;
                        <b>{formatNumber(Math.round(label.y))}</b>&nbsp;
                        {props.unit}
                    </>
                );
            }
        });

        //return <VictoryTooltip {...props} />;

        return (
            <g>
                <line
                    transform={`translate(${(props.x || 0) < PADDING_LEFT ? PADDING_LEFT : xx}, 0)`}
                    x1={0}
                    y1={PADDING_TOP}
                    y2={CHART_HEIGHT - PADDING_BOTTOM - (width > ANGLED ? 0 : PADDING_TOP)}
                    stroke="#000"
                    strokeWidth={0.5}
                />
                <foreignObject x={xPos} y={PADDING_TOP} width={TOOLTIP_WIDTH} height={'100%'}>
                    <div
                        style={{
                            backgroundColor: '#FFFFFFD0',
                            color: 'black',
                            padding: 10,
                            border: 'solid',
                            borderColor: 'gray',
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                    >
                        {textList.map((f, i) => (
                            <div key={'tooltipindex' + i}>
                                {(i === 1 || i === 3) && <div style={{ height: 10 }}></div>}
                                {f}
                            </div>
                        ))}
                    </div>
                </foreignObject>
            </g>
        );
    };

    useEffect(() => {
        if (!minimumTimestamp || !maximumTimestamp) {
            return;
        }

        const midDate = new Date();
        if (!timeInterval) {
            midDate.setTime(new Date(maximumTimestamp).getTime());
        } else {
            midDate.setTime((timeInterval.from.getTime() + timeInterval.to.getTime()) / 2);
        }

        const maxDate = new Date(midDate);
        switch (scale) {
            case DateScaleConsumption.DAY:
                maxDate.setHours(maxDate.getHours() + 12);
                break;
            case DateScaleConsumption.WEEK:
                maxDate.setHours(maxDate.getHours() + 84); // 3.5days, 3.5*24=80hours
                break;
            case DateScaleConsumption.MONTH:
                maxDate.setDate(maxDate.getDate() + 15);
                break;
            case DateScaleConsumption.YEAR:
                maxDate.setMonth(maxDate.getMonth() + 6);
                break;
            default:
                maxDate.setHours(maxDate.getHours() + 12);
        }

        if (maxDate > maximumTimestamp) {
            maxDate.setTime(maximumTimestamp.getTime());
        }

        const minDate = new Date(maxDate);
        switch (scale) {
            case DateScaleConsumption.DAY:
                minDate.setDate(minDate.getDate() - 1);
                break;
            case DateScaleConsumption.WEEK:
                minDate.setDate(minDate.getDate() - 7);
                break;
            case DateScaleConsumption.MONTH:
                minDate.setMonth(minDate.getMonth() - 1);
                break;
            case DateScaleConsumption.YEAR:
                minDate.setFullYear(minDate.getFullYear() - 1);
                break;
            default:
                minDate.setDate(minDate.getDate() - 1);
        }

        if (minDate < minimumTimestamp) {
            minDate.setTime(minimumTimestamp.getTime());
        }

        setTimeInterval({ from: minDate, to: maxDate });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scale, minimumTimestamp, maximumTimestamp]);

    // not one-liner because chrome can't Math.max/min(array), when array is bigger then 125519, firefox has no problem
    // https://stackoverflow.com/questions/42623071/maximum-call-stack-size-exceeded-with-math-min-and-math-max
    const getDomain = useMemo(() => {
        //console.log('getDomain');
        const reservedAmountTMP = reserveAmount
            ? reserveAmount
                  .map((reserveAmountSingle) => (reserveAmountSingle.value ? reserveAmountSingle.value : -Infinity))
                  .filter((reserveAmountSingle) => reserveAmountSingle !== undefined)
            : [-Infinity];
        const maximumReserveAmountTMP = maximumReserveAmount
            ? maximumReserveAmount
                  .map((maximumReserveAmountSingle) => (maximumReserveAmountSingle.value ? maximumReserveAmountSingle.value : -Infinity))
                  .filter((maximumReserveAmountSingle) => maximumReserveAmountSingle !== undefined)
            : [-Infinity];
        const maximumDailyAmountTMP = maximumDailyAmount
            ? maximumDailyAmount
                  .map((maximumDailyAmountSingle) => (maximumDailyAmountSingle.value ? maximumDailyAmountSingle.value : -Infinity))
                  .filter((maximumDailyAmountSingle) => maximumDailyAmountSingle !== undefined)
            : [-Infinity];

        const max = data.reduce(
            (prevMax, curr) =>
                Math.max(prevMax, curr.y, Math.max(...reservedAmountTMP), Math.max(...maximumReserveAmountTMP), Math.max(...maximumDailyAmountTMP)),
            -Infinity,
        );
        if (data.length > 0 && timeInterval) {
            return {
                x: [timeInterval.from, timeInterval.to] as [Date, Date],
                // x: [minimumTimestamp, maximumTimestamp] as [Date, Date],
                y: [0, max ? max + max / 5 : 0.15] as [number, number],
            };
        } else {
            return { x: [new Date(), new Date()] as [Date, Date], y: [0, 2500] as [number, number] };
        }
    }, [data, maximumDailyAmount, maximumReserveAmount, reserveAmount, timeInterval]);

    const capacityLinesData = (capacityLines: SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValue[]) => {
        // console.log('capacityLinesData');
        const result: {
            from: string | Date | undefined;
            to: string | Date | undefined;
            x: string | Date | undefined;
            y: number | undefined;
            realFrom: string | Date | undefined;
            realTo: string | Date | undefined;
        }[] = [];

        const dataCapacityLines: {
            from: string | Date | undefined;
            to: string | Date | undefined;
            x: string | Date | undefined;
            y: number | undefined;
        }[] = [];

        capacityLines.forEach((capacityLine) => {
            dataCapacityLines.push(capacityLineData(capacityLine)[0], capacityLineData(capacityLine)[1]);
        });

        //to each value from tariffs add value from from the given reservedValues - fixed issue with tooltip
        data.forEach((dataX) => {
            const correctInterval = dataCapacityLines.find(
                (capacityLine) =>
                    capacityLine.from &&
                    capacityLine.to &&
                    new Date(capacityLine.from).getTime() <= dataX.from.getTime() &&
                    new Date(capacityLine.to).getTime() >= dataX.to.getTime(),
            );
            if (correctInterval) {
                result.push({
                    ...dataX,
                    y: correctInterval.y,
                    realFrom: correctInterval.from,
                    realTo: correctInterval.to,
                });
            }
        });
        return result;
    };

    const capacityLineData = (capacityLine: SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValue) => {
        // console.log('capacityLineData');
        const object = [
            {
                from: capacityLine.valid?.from && new Date(capacityLine.valid.from),
                to: capacityLine.valid?.to && new Date(capacityLine.valid.to),
                x: capacityLine.valid?.from && new Date(capacityLine.valid.from),
                y: capacityLine.value,
            },
            {
                from: capacityLine.valid?.from
                    ? new Date(capacityLine.valid.from)
                    : capacityLine.valid?.from === null
                    ? new Date()
                    : capacityLine.valid?.from,
                to: capacityLine.valid?.to ? new Date(capacityLine.valid.to) : capacityLine.valid?.to === null ? new Date() : capacityLine.valid?.to,
                x: capacityLine.valid?.to ? new Date(capacityLine.valid.to) : capacityLine.valid?.to === null ? new Date() : capacityLine.valid?.to,
                y: capacityLine.value,
            },
        ];
        return object;
    };

    const capacityLine = (capacityLines: SkSppNzpCommonsApiCustomerconsumptionConsumptionReservedValue[], color: string, name: LINE_NAME) => {
        // console.log('capacityLine');
        return (
            <VictoryLine
                data={capacityLinesData(capacityLines)}
                style={{
                    data: { stroke: color, strokeWidth: 2 },
                    labels: { fill: color },
                }}
                name={name.toString()}
            />
        );
    };

    const legend = useMemo<
        {
            name?: string;
            labels?: {
                fill?: string;
            };
            symbol?: {
                fill?: string;
                type?: string;
            };
        }[]
    >(() => {
        //console.log('legend');
        const legend = [
            {
                name: t('delivery-point.detail.consumption.your-withdraw'),
                symbol: { fill: METTERING_LINE_COLOR, type: 'square' },
            },
        ];
        if (/*scale === DateScaleConsumption.ALL &&*/ commodityType === 'EE') {
            if (reserveAmount !== undefined && reserveAmount.length > 0) {
                legend.push({
                    name: t('delivery-point.detail.consumption.reserved-capacity'),
                    symbol: { fill: RK_COLOR, type: 'square' },
                });
            }
            if (maximumReserveAmount !== undefined && maximumReserveAmount.length > 0) {
                legend.push({
                    name: t('delivery-point.detail.consumption.maximal-reserved-capacity'),
                    symbol: { fill: MRK_COLOR, type: 'square' },
                });
            }
        }
        if (/*scale === DateScaleConsumption.DAY &&*/ commodityType === 'ZP' && maximumDailyAmount !== undefined && maximumDailyAmount.length > 0) {
            legend.push({
                name: t('delivery-point.detail.consumption.maximal-daily-capacity'),
                symbol: { fill: DMM_COLOR, type: 'square' },
            });
        }
        return legend;
    }, [commodityType, maximumDailyAmount, maximumReserveAmount, reserveAmount /*, scale*/, t]);

    const graphAxisLabelUnit = useMemo<ConsumptionPowerGraphFormattedUnits | null>(() => {
        //console.log('graphAxisLabelUnit');
        if (data == null) {
            return null;
        }
        if (units === ConsumptionValueUnits.KW) {
            const graphMaxValue = Math.max(...data.map((x) => x.y));
            if (graphMaxValue >= 10 ** (6 + 1)) {
                return ConsumptionPowerGraphFormattedUnits.GW;
            }
            if (graphMaxValue >= 10 ** (3 + 1)) {
                return ConsumptionPowerGraphFormattedUnits.MW;
            } else {
                return ConsumptionPowerGraphFormattedUnits.KW;
            }
        } else {
            return ConsumptionPowerGraphFormattedUnits.M3;
        }
    }, [units, data]);

    const formatGraphAxisLabelValue = (value: number): string => {
        //console.log('formatGraphAxisLabelValue');
        const convertValue = (): number => {
            if (units === ConsumptionValueUnits.KWH) {
                if (graphAxisLabelUnit === ConsumptionPowerGraphFormattedUnits.GW) {
                    return value / 10 ** 6;
                }
                if (graphAxisLabelUnit === ConsumptionPowerGraphFormattedUnits.MW) {
                    return value / 10 ** 3;
                } else {
                    return value;
                }
            } else {
                return value;
            }
        };
        const convertedValue = convertValue();
        let retValue = '';

        if (convertedValue >= 10 ** 6) {
            const cutValue = convertedValue / 10 ** 6;
            retValue = t('delivery-point.detail.consumption.short-number-format.millions', { num: cutValue });
        } else if (convertedValue >= 10 ** 3) {
            const cutValue = convertedValue / 10 ** 3;
            retValue = t('delivery-point.detail.consumption.short-number-format.thousands', { num: cutValue });
        } else {
            retValue = `${convertedValue}`;
        }
        return retValue.replace('.', ',');
    };

    const VictoryZoomVoronoiContainer = useMemo(
        () => createContainer<VictoryZoomContainerProps, VictoryVoronoiContainerProps>('zoom', 'voronoi'),
        [],
    );

    //@ts-ignore
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    const sclaes = useMemo(() => {
        //console.log('comodityType scales');
        const scales = [
            {
                id: DateScaleConsumption.YEAR,
                title: t('delivery-point.detail.consumption.metering-scale.year'),
            },
            { id: DateScaleConsumption.MONTH, title: t('delivery-point.detail.consumption.metering-scale.month') },
            { id: DateScaleConsumption.WEEK, title: t('delivery-point.detail.consumption.metering-scale.week') },
            //{ id: DateScaleConsumption.DAY, title: t('delivery-point.detail.consumption.metering-scale.day') },
        ];
        if (commodityType === 'EE') {
            scales.push({ id: DateScaleConsumption.DAY, title: t('delivery-point.detail.consumption.metering-scale.day') });
        }
        return scales.reverse();
    }, [commodityType, t]);

    const api = useApi();
    const [mutateExport] = useMutationWithError(
        async (params: { from: Date; to: Date; format: 'XLSX' | 'CSV' }) => {
            setExportModalOpen(false);
            if (contractId == null) return;

            return api.deliveryPoints
                .searchConsumptionsExport(
                    contractId,
                    { format: params.format },
                    {
                        readAt: {
                            from: params.from.toISOString(),
                            to: params.to.toISOString(),
                        },
                        type: 'INTERVAL_METER_READING',
                        units: commodityType === 'EE' ? 'KW' : 'M3',
                    },
                    { secure: true },
                )
                .then((response: Response) => response.blob())
                .then(triggerDownloadFile);
        },
        {
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    return (
        <>
            <div className="row">
                <div className="col-md-9 col-lg-10">
                    <h2 className="w-100 overlay-graph pt-2 mb-4">
                        <Trans i18nKey="delivery-point.detail.consumption.interval-metering" />
                    </h2>
                </div>
                <div className="col-md-3 col-lg-2 d-sm-none d-md-block text-right mt-3">
                    <FontAwesomeIcon icon={faDownload} size="lg" />
                    &nbsp;
                    <ClickableElement className="text-decoration-underline-inverse" isText onClick={() => setExportModalOpen(true)}>
                        <span className="mr-2">
                            <Trans i18nKey="portal-account.activity-log.actions.export" />
                        </span>
                    </ClickableElement>
                </div>
            </div>
            {timeInterval && (
                <ConsumptionMeteringGraphExportModalReadModal
                    isOpen={isExportModalOpen}
                    onClose={() => setExportModalOpen(false)}
                    minDate={timeInterval.from}
                    maxDate={timeInterval.to}
                    onExport={(from, to, format) => mutateExport({ from, to, format })}
                />
            )}
            <div id="consumptionChart">
                <div className="mx-2 py-2 d-none d-md-flex justify-content-end align-items-center">
                    <BaseSwitch
                        className="scale-graph"
                        selectedValue={scale}
                        dataButtons={sclaes}
                        onValueSelect={(value) => {
                            changeScale(value as DateScaleConsumption);
                        }}
                    />
                </div>
                <div className="mx-2 py-2 d-flex d-md-none justify-content-center align-items-center">
                    <BaseSelect
                        label={t('delivery-point.detail.consumption.graph-scale')}
                        className="form-control-filter"
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => changeScale(event.target.value as DateScaleConsumption)}
                    >
                        {sclaes.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.title}
                            </option>
                        ))}
                    </BaseSelect>
                </div>
                <div ref={programsColumnRef} className="position-relative">
                    {data && data.length === 0 && !isLoading && (
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded overlay-graph overlay-graph-background">
                            <p className="text-primary">
                                <Trans i18nKey="delivery-point.detail.consumption.no-data-meter-reading" />
                            </p>
                        </div>
                    )}
                    {!(data && data.length === 0 && !isLoading) && data && data.length === 0 && !isLoading && (
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded overlay-graph overlay-graph-background">
                            <p className="text-primary">
                                <Trans i18nKey="delivery-point.detail.consumption.no-data-meter-reading" />
                            </p>
                        </div>
                    )}

                    {/* https://github.com/FormidableLabs/victory/issues/1717 */}
                    {(!data || (data && data.length === 0)) && isLoading && (
                        <>
                            <LoadingIndicator layer={'10'} />
                            <VictoryChart
                                domainPadding={{ x: width / 10, y: 40 }}
                                height={CHART_HEIGHT}
                                width={width}
                                padding={{
                                    left: PADDING_LEFT, //distance from left
                                    right: PADDING_RIGHT,
                                    top: PADDING_TOP,
                                    bottom: PADDING_BOTTOM,
                                }}
                                style={{ parent: { height: CHART_HEIGHT } }}
                                scale={{ x: 'time' }}
                            >
                                <VictoryAxis
                                    // X-axis
                                    orientation="bottom"
                                    style={{
                                        axis: { stroke: 'black' },
                                        grid: { stroke: 'lightgray' },
                                    }}
                                    tickLabelComponent={<VictoryLabel style={{ fontSize: GRAPH_FONT_SIZE, fontFamily: fontFamily }} dy={-5} />}
                                    tickFormat={(value) => formatDateDayMonthYearTime(value)}
                                />
                                <VictoryAxis
                                    //  Y-axis
                                    dependentAxis
                                    orientation="left"
                                    style={{
                                        axis: { stroke: 'black' },
                                        grid: { stroke: 'lightgray' },
                                        ticks: { stroke: 'grey' },
                                        tickLabels: { fontSize: GRAPH_FONT_SIZE, padding: 5, fontFamily: fontFamily },
                                    }}
                                    axisLabelComponent={<VictoryLabel angle={0} x={30} y={25} style={{ fontFamily: fontFamily }} />}
                                    label={`[${graphAxisLabelUnit || '-'}]`}
                                    tickFormat={formatGraphAxisLabelValue}
                                />
                            </VictoryChart>
                        </>
                    )}
                    {/* https://github.com/FormidableLabs/victory/issues/1717 */}
                    {data !== undefined && timeInterval && (
                        <>
                            <VictoryChart
                                name="chart"
                                domain={getDomain}
                                domainPadding={{ x: width / 10, y: 40 }}
                                height={CHART_HEIGHT}
                                width={width}
                                padding={{
                                    left: PADDING_LEFT, //distance from left
                                    right: PADDING_RIGHT,
                                    top: PADDING_TOP,
                                    bottom: width >= ANGLED ? PADDING_BOTTOM : 100,
                                }}
                                /*
                                events={[
                                    {
                                        target: 'parent',
                                        eventHandlers: {
                                            onClick: () => ({
                                                target: 'allowPan',
                                                mutation: (props) => {
                                                    console.log('click', props);
                                                    return true;
                                                },
                                            }),
                                        },
                                    },
                                ]}
                                */
                                style={{ parent: { height: CHART_HEIGHT } }}
                                scale={{ x: 'time' }}
                                containerComponent={
                                    <VictoryZoomVoronoiContainer
                                        //this function fix scrolling through graph on touch devices so IE doesn't need it
                                        events={{
                                            onWheel: (e) => {
                                                const scaleIndex = sclaes.findIndex((sc) => sc.id === scale);
                                                if (scaleIndex >= 0) {
                                                    if (e.deltaY > 0 && scaleIndex - 1 >= 0) {
                                                        changeScale(sclaes[scaleIndex - 1].id);
                                                    }
                                                    if (e.deltaY < 0 && scaleIndex + 1 < sclaes.length) {
                                                        changeScale(sclaes[scaleIndex + 1].id);
                                                    }
                                                }
                                            },
                                            //onMouseDown: (e) => console.log('on mouse down', e),
                                            //onClick: (e) => console.log('clixk'),
                                        }}
                                        //onActivated={(points, props) => console.log('onActivated', points, props)}
                                        style={
                                            isIE11
                                                ? undefined
                                                : {
                                                      touchAction: 'pan-x pan-y',
                                                      overflow: 'visible',
                                                      cursor: 'grab',
                                                  }
                                        }
                                        allowPan
                                        allowZoom={false}
                                        zoomDimension="x"
                                        mouseFollowTooltips
                                        voronoiDimension="x"
                                        //show both values in one tooltip
                                        // voronoiDimension='x'
                                        zoomDomain={{ x: [timeInterval.from, timeInterval.to] }}
                                        //zoomDomain={{ x: [/*xDomainMin*/ new Date('2019-01-01'), /*xDomainMax*/ new Date('2021-01-01')] }}
                                        onZoomDomainChange={(domain) => {
                                            //console.log('onZoomChange', domain, props);
                                            const { x } = domain;
                                            const [xLeft, xRight] = x;
                                            setTimeInterval({ from: new Date(xLeft), to: new Date(xRight) });
                                        }}
                                        labels={({ datum }) => {
                                            return `${formatGraphAxisLabelValue(
                                                Math.round(datum.y * 100) / 100,
                                            )} [${graphAxisLabelUnit}]\n ${dateFromIsoToFormat(datum.from, 'DD/MM/YYYY HH:mm:ss')}${' -\n' +
                                                dateFromIsoToFormat(datum.to, 'DD/MM/YYYY HH:mm:ss')}`;
                                            /*
                                            if (datum.realFrom || datum.realTo) {
                                                // return `${formatGraphAxisLabelValue(datum.y)} [${graphAxisLabelUnit}]`;
                                                return `${formatGraphAxisLabelValue(datum.y)} [${graphAxisLabelUnit}]\n ${formatDateMonthYearWithTime(
                                                    datum.realFrom,
                                                )}${' -\n' + formatDateMonthYearWithTime(datum.realTo)}`;
                                            }
                                            if (scale === DateScaleConsumption.ALL) {
                                                return `${formatGraphAxisLabelValue(datum.y)} [${graphAxisLabelUnit}]\n ${formatDateMonthYearWithTime(
                                                    datum.from,
                                                )}${' -\n' + formatDateMonthYearWithTime(datum.to)}`;
                                            }
                                            return `${formatGraphAxisLabelValue(datum.y)} [${graphAxisLabelUnit}], ${formatDate(datum.from)}${
                                                formatDate(datum.from) === formatDate(datum.to) ? '' : ' - ' + formatDate(datum.to)
                                            }`;*/
                                        }}
                                        labelComponent={
                                            <CustomTooltip
                                                unit={graphAxisLabelUnit}
                                                // @ts-ignore https://github.com/FormidableLabs/victory/issues/1810
                                                constrainToVisibleArea
                                                flyoutPadding={{ top: 10, bottom: 10, left: 15, right: 15 }}
                                                flyoutStyle={{
                                                    fontSize: GRAPH_FONT_SIZE,
                                                    backgroundColor: '#FF0000',
                                                    fontFamily: fontFamily,
                                                }}
                                                // flyoutStyle={{ fontSize: GRAPH_FONT_SIZE, fill: 'white', fontFamily: fontFamily }}
                                            />
                                        }
                                    />
                                }
                            >
                                <VictoryAxis
                                    // X-axis
                                    orientation="bottom"
                                    style={{
                                        axis: { stroke: 'black' },
                                        grid: { stroke: 'lightgray' },
                                    }}
                                    tickLabelComponent={
                                        <VictoryLabel
                                            angle={width >= ANGLED ? 0 : 315}
                                            style={{ fontSize: GRAPH_FONT_SIZE, fontFamily: fontFamily }}
                                            dx={width >= ANGLED ? 0 : -18}
                                            dy={width >= ANGLED ? 10 : 13}
                                            textAnchor="middle"
                                            verticalAnchor="middle"
                                        />
                                    }
                                    tickFormat={(value) => formatDateDayMonthYearTime(value)}
                                />
                                <VictoryAxis
                                    //  Y-axis
                                    dependentAxis
                                    orientation="left"
                                    style={{
                                        axis: { stroke: 'black' },
                                        grid: { stroke: 'lightgray' },
                                        ticks: { stroke: 'grey' },
                                        tickLabels: { fontSize: GRAPH_FONT_SIZE, padding: 5, fontFamily: fontFamily },
                                    }}
                                    axisLabelComponent={<VictoryLabel angle={0} x={30} y={25} style={{ fontFamily: fontFamily }} />}
                                    label={`[${graphAxisLabelUnit || '-'}]`}
                                    tickFormat={formatGraphAxisLabelValue}
                                />
                                <VictoryLine
                                    data={data}
                                    style={{
                                        data: { stroke: METTERING_LINE_COLOR, strokeWidth: 3 },
                                    }}
                                    name={LINE_NAME.main.toString()}
                                />

                                {commodityType === 'EE' &&
                                    reservedValues?.reserveAmount &&
                                    // scale === DateScaleConsumption.ALL &&
                                    capacityLine(reservedValues.reserveAmount, RK_COLOR, LINE_NAME.reserveAmount)}
                                {commodityType === 'EE' &&
                                    // scale === DateScaleConsumption.ALL &&
                                    reservedValues?.maximumReserveAmount &&
                                    capacityLine(reservedValues.maximumReserveAmount, MRK_COLOR, LINE_NAME.maximumReserveAmount)}
                                {commodityType === 'ZP' &&
                                    reservedValues?.maximumDailyAmount &&
                                    // scale === DateScaleConsumption.DAY &&
                                    capacityLine(reservedValues.maximumDailyAmount, DMM_COLOR, LINE_NAME.maximumDailyAmount)}
                            </VictoryChart>
                            {legend.length > 0 && data.length > 0 && (
                                <div className="w-100 overlay-graph my-2 fixed-bottom position-absolute">
                                    <div className="footer-body d-flex align-items-center justify-content-center flex-wrap">
                                        {legend.map((color, index) => {
                                            return (
                                                <div key={index} className="d-flex align-items-center mx-2">
                                                    <div className="legend-color mx-2" style={{ backgroundColor: color.symbol?.fill }}></div>
                                                    <span>{color.name}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ConsumptionMeteringGraph;
