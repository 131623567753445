import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { CommodityChoice } from '../../../actions/add-delivery-point-actions';
import BaseRadio from '../../../components/common/base-radio';
import DeliveryPointBreadcrumb from './add-delivery-point-breadcrumb';

type ScreenChooseCommodityScreenProps = {
    commodity?: CommodityChoice;
    enterCommodity: (commodity: CommodityChoice) => void;
    goBack: () => void;
};

const ScreenChooseCommodityScreen: React.FC<ScreenChooseCommodityScreenProps> = (props) => {
    const [commodity, setComodity] = useState(props.commodity);

    return (
        <>
            <DeliveryPointBreadcrumb goBack={props.goBack} />

            <h2 className="text-center">
                <Trans i18nKey="delivery-point.add-delivery-point.comodity.title">Zvoľte komoditu</Trans>:
            </h2>

            <form
                action="WF-2003b.html"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (commodity) {
                        props.enterCommodity(commodity);
                    }
                }}
            >
                <BaseRadio
                    className="mb-3"
                    id="GAS"
                    name="customRadio"
                    onChange={() => setComodity(CommodityChoice.GAS)}
                    checked={commodity === CommodityChoice.GAS}
                    label={<Trans i18nKey="delivery-point.add-delivery-point.comodity.gas">Plyn</Trans>}
                />
                <BaseRadio
                    className="mb-3"
                    id="ELECTRICITY"
                    name="customRadio"
                    onChange={() => setComodity(CommodityChoice.ELECTRICITY)}
                    checked={commodity === CommodityChoice.ELECTRICITY}
                    label={<Trans i18nKey="delivery-point.add-delivery-point.comodity.electricity">Elektrina</Trans>}
                />
                <button type="submit" className="btn btn-primary btn-block btn-lg">
                    <Trans i18nKey="common.continue">Pokračovať</Trans>
                </button>
            </form>
        </>
    );
};

export default ScreenChooseCommodityScreen;
