import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { IResponseError } from '../models/model';

const handledErrorCodes = [
    400,
    404,
    500,
    1000,
    1002,
    1003,
    1004,
    1005,
    1006,
    1007,
    1008,
    1009,
    1010,
    1011,
    1012,
    1013,
    1014,
    1020,
    1021,
    1101,
    1102,
    1103,
    1104,
    1201,
    1202,
    1301,
    2001,
    2002,
    2003,
    3001,
    3002,
];

export default function useEPayErrorHandling(): { ePayErrorHandlingFn: (error: IResponseError['code']) => void } {
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const ePayErrorHandlingFn = useCallback(
        (error: IResponseError['code']) => {
            if (error) {
                if (handledErrorCodes.includes(error)) {
                    addToast(`${t(`error-epay.${error}`)}`, { appearance: 'error' });
                } else {
                    addToast(`${t(`error-epay.default`)}`, { appearance: 'error' });
                }
            } else {
                addToast(`${t(`error-epay.default`)}`, { appearance: 'error' });
            }
        },
        [addToast, t],
    );

    return { ePayErrorHandlingFn };
}
