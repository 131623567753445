import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { eInvoiceSettingsActions } from '../../actions';
import { AuthActions } from '../../actions/auth-actions';
import { UserActions } from '../../actions/user';
import { AuthEnum } from '../../models/enums';
import { IRootState } from '../../reducers';
import Routes, { ErrorRoutes, PortalAccountsRoutes } from '../../routes/routes';
import { useAdminApi, useApi, useEpayApi } from '../use-api';
import logger from './../../utils/logger';
import { decodeJwt, useAuthUtils } from './use-auth-utils';
import { useCodeChallenge } from './use-code-challenge';
import useSilentRefresh, { ISilentRefreshResult } from './use-silent-refresh';
type BackUrlType = string | undefined;

type TokensType = {
    access_token: string;
    access_token_exp?: number;
    refresh_token: string;
    id_token: string;
    expires_in: number;
    scope: string;
    type: string;
    error: string;
};

export type SsoMethodType = 'customer.passwordMethod' | 'customer.smsTokenMethod' | 'customer.facebookMethod' | 'customer.googleMethod';

export type LoginHandlerType = (ssoUrl: string) => void;

let loginHandler: LoginHandlerType = (ssoUrl) => {
    window.location.href = ssoUrl;
    return true;
};

let logoutHandler: (confirmPage: string | null, isTimeoutLogout?: boolean, logoutUrl?: string) => void = () => {
    return true;
};

export const bindUrlStateParamsToUuid = (urlStateParams: string): string => {
    const uuid = uuidv4();
    sessionStorage.setItem(uuid, urlStateParams);
    return uuid;
};

let silentRefreshInProgress = false;

const publicUrl = process.env.REACT_APP_PUBLIC_URL;
const defaultRedirectUri = `${window.location.origin}${publicUrl ? publicUrl : ''}${Routes.SSO_SUCCESS}`;
export const AUTH_REDIRECT_URI: { [key: string]: string } = {
    nzp: defaultRedirectUri,
    mnzp: defaultRedirectUri,
    lmnzp: `${window.location.origin}${publicUrl ? publicUrl : ''}${Routes.SSO_MOBILE_SUCCESS}`,
    enzp: defaultRedirectUri,
};

const useSso: () => {
    ssoLogin: (option?: { backUrl?: BackUrlType; method?: SsoMethodType; clientId?: string; state?: string }) => void;
    ssoLogout: (confirmPage: string | null, isTimeoutLogout?: boolean) => void;
    ssoActorEmployeePartialLogout: () => void;
    ssoActorEmployeeLogin: (customerId: string, returnUrl: string) => void;
    triggerRefreshToken: (token?: string, clientId?: string) => Promise<TokensType | undefined>;
    triggerSecondaryRefreshToken: () => Promise<TokensType | undefined>;
    redirectToSso: (ssoUrl: string) => void;
    setSsoLoginHandler: (loginHandler: LoginHandlerType) => void;
    setSsoLogoutHandler: (callback: (confirmPage: string | null, isTimeoutLogout?: boolean, logoutUrl?: string) => void) => void;
} = () => {
    const authUrl = process.env.REACT_APP_SSO_AUTH_URL;
    const logoutUrl = process.env.REACT_APP_SSO_LOGOUT_URL;
    const { i18n } = useTranslation();
    const history = useHistory();
    const queryCache = useQueryCache();
    const {
        accessToken,
        refreshToken,
        clientId: clientIdHint,
        idToken: idTokenHint,
        secondaryAccessToken,
        secondaryRefreshToken,
        secondaryIdToken: secondaryIdTokenHint,
    } = useSelector((state: IRootState) => state.auth);
    const employeeKeyValueStore = useSelector((root: IRootState) => root.employeeKeyValueStore);
    const portalAccountsPage = Number(employeeKeyValueStore[PortalAccountsRoutes.CUSTOMERS + '@pageIndex'] ?? 0);
    const [silentRefresh] = useSilentRefresh();
    const { getTokens, getCustomer, dispatchCustomer } = useAuthUtils();

    const getCodeChallenge = useCodeChallenge();
    const dispatch = useDispatch();

    const api = useApi();
    const adminApi = useAdminApi();
    const epayApi = useEpayApi();

    const isBackUrlPointingToAuthSuccess: (backUrl: string) => boolean = (backUrl) => {
        return (backUrl.indexOf(Routes.SSO_SUCCESS) || backUrl.indexOf(Routes.SSO_MOBILE_SUCCESS)) > -1;
    };

    const isAdminUrl: (backUrl: string) => boolean = (backUrl) => {
        return backUrl.indexOf('/admin') > -1;
    };

    const getLoginUrl = useCallback(
        function(options: { codeChallenge: string; backUrl: BackUrlType; method?: SsoMethodType; clientId?: string; state?: string }) {
            const ssoClientId = options.clientId ? options.clientId : '' + process.env.REACT_APP_SSO_CLIENT_ID;
            const redirectUri = AUTH_REDIRECT_URI[ssoClientId] ?? defaultRedirectUri;
            let backUrl = options.backUrl ? options.backUrl : window.location.pathname + window.location.search;
            if (isBackUrlPointingToAuthSuccess(backUrl)) {
                backUrl = isAdminUrl(backUrl) ? PortalAccountsRoutes.CUSTOMERS : Routes.DELIVERY_POINTS;
            }
            const state = options.state ?? backUrl;
            const stateAsUuid = bindUrlStateParamsToUuid(state);
            return (
                authUrl +
                '?' +
                new URLSearchParams({
                    code_challenge_method: AuthEnum.CODE_CHALLENGE_METHOD,
                    code_challenge: options.codeChallenge,
                    response_type: AuthEnum.RESPONSE_TYPE_CODE,
                    redirect_uri: redirectUri,
                    client_id: ssoClientId,
                    state: stateAsUuid,
                    scope: '' + process.env.REACT_APP_SSO_SCOPE,
                    locale: i18n.language,
                    ...(process.env.REACT_APP_SSO_APP_VER ? { authnAttrs: 'appVer:' + process.env.REACT_APP_SSO_APP_VER } : {}),
                    ...(options.method && { method: options.method }),
                })
            );
        },
        [authUrl, i18n.language],
    );

    const ssoLogin = useCallback(
        function(options?: { backUrl?: BackUrlType; method?: SsoMethodType; clientId?: string; state?: string }) {
            logger.debug('use-sso - ssoLogin, options:', options);
            getCodeChallenge()
                .then((codeChallenge) => {
                    const loginUrl = getLoginUrl({
                        codeChallenge,
                        backUrl: options?.backUrl,
                        method: options?.method,
                        clientId: options?.clientId,
                        state: options?.state,
                    });
                    return loginUrl;
                })
                .then((ssoUrl) => {
                    redirectToSso(ssoUrl);
                });
        },
        [getCodeChallenge, getLoginUrl],
    );

    const redirectToSso = (ssoUrl: string) => {
        loginHandler(ssoUrl);
    };

    function setSsoLoginHandler(ssoLoginHandler: LoginHandlerType) {
        loginHandler = ssoLoginHandler;
    }

    const setSsoLogoutHandler: (callback: (confirmPage: string | null, isTimeoutLogout?: boolean, logoutUrl?: string) => void) => void = (
        callback,
    ) => {
        logoutHandler = callback;
    };

    const ssoLogout = useCallback(
        function(confirmPage: string | null, isTimeoutLogout?: boolean) {
            logger.debug('use-sso - ssoLogout, params:', { confirmPage, isTimeoutLogout, accessToken });
            if (!accessToken) throw new Error("Cannot logout, missing logged user's AccessToken");
            const redirectUri = AUTH_REDIRECT_URI[clientIdHint] ?? defaultRedirectUri;
            const decodedJwt = decodeJwt<{ sid: string }>(accessToken);
            const finalUrl =
                logoutUrl +
                '?' +
                new URLSearchParams({
                    state: `${window.location.href}`,
                    post_logout_redirect_uri: redirectUri,
                    id_token_hint: idTokenHint,
                    sid: decodedJwt.sid,
                });

            if (process.env.REACT_APP_MOBILE === 'true') {
                logoutHandler(confirmPage, isTimeoutLogout, finalUrl);
                return;
            }

            fetch(finalUrl, {
                headers: { ...(isTimeoutLogout && { 'X-On-Timeout': 'no-value' }) },
            }).finally(() => {
                api.setSecurityData({ Authorization: '' });
                adminApi.setSecurityData({ Authorization: '' });
                dispatch(AuthActions.setAuthTokens('', '', '', '', undefined));
                dispatch(AuthActions.setSecondaryAuthTokens('', '', '', undefined));
                dispatch(UserActions.logoutUser());
                window.sessionStorage.clear(); //TODO erase user specific sessionStorage items

                if (confirmPage) {
                    history.push(confirmPage);
                }
            });
        },
        [api, adminApi, accessToken, idTokenHint, logoutUrl, history, dispatch, clientIdHint],
    );

    function ssoActorEmployeePartialLogout() {
        if (!secondaryAccessToken) {
            onSuccessPartialLogout();
            return;
        }
        ssoPartialLogout(secondaryAccessToken, secondaryIdTokenHint)
            .then(onSuccessPartialLogout)
            .catch((err) => logger.error('use-sso - ssoActorEmployeePartialLogout', err));
    }

    function onSuccessPartialLogout() {
        if (secondaryAccessToken) {
            dispatch(AuthActions.setSecondaryAuthTokens('', '', '', undefined));
        }
        queryCache.clear(); //TODO erase user specific queries from queryCache
        dispatch(UserActions.logoutCustomer());
        dispatch(eInvoiceSettingsActions.reset());
        history.replace(PortalAccountsRoutes.CUSTOMERS + (portalAccountsPage >= 0 ? '?page=' + (portalAccountsPage + 1) : ''));
    }

    function ssoActorEmployeeLogin(customerId: string, returnUrl: string) {
        if (!customerId) {
            return;
        }
        if (secondaryAccessToken) {
            history.replace(returnUrl);
            return;
        }

        const clientId = 'ecnzp';
        const scope = 'openid actorEmployee';

        if (!silentRefreshInProgress) {
            silentRefreshInProgress = true;
            silentRefresh(clientId, scope, process.env.PUBLIC_URL, customerId)
                .then(processLoginAsCustomer)
                .catch(processLoginAsCustomerError);
        }

        function processLoginAsCustomer(result: ISilentRefreshResult) {
            if (result.error) {
                processLoginAsCustomerError();
            } else {
                const redirectUri = '' + process.env.REACT_APP_SSO_RESOLVE_URL;
                getTokens(clientId, scope, result.code, redirectUri)
                    .then((tokens) => {
                        getCustomer(
                            tokens,
                            returnUrl,
                            (newUser) => {
                                setTimeout(() => {
                                    silentRefreshInProgress = false;
                                }, 200);
                                dispatchCustomer(newUser, tokens, returnUrl);
                            },
                            (error) => {
                                setTimeout(() => {
                                    silentRefreshInProgress = false;
                                }, 200);
                                logger.error('use-sso : processLoginAsCustomer failed: ', error);
                            },
                        );
                    })
                    .catch((err: Record<string, any>) => {
                        setTimeout(() => {
                            silentRefreshInProgress = false;
                        }, 200);
                        logger.error('getTokens network error', err);
                    });
            }
        }

        function processLoginAsCustomerError() {
            silentRefreshInProgress = false;
            history.push(ErrorRoutes.INSUFFICIENT_PRIVILEGES);
        }
    }

    const triggerRefreshToken = useCallback(
        async function(token?: string, clientId?: string) {
            logger.debug('use-sso - triggerRefreshToken', { token, clientId });
            if (!refreshToken && !token) return;
            if (!clientIdHint && !clientId) return;

            const ssoClientId = clientId ? clientId : clientIdHint;
            const response: Response = await fetch('' + process.env.REACT_APP_SSO_TOKEN_URL, {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${btoa(ssoClientId + ':')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                credentials: 'include',
                body: `grant_type=refresh_token&refresh_token=${token ?? refreshToken}&client_id=${ssoClientId}`,
            });
            logger.info('use-sso - triggerRefreshToken', {
                clientId: ssoClientId,
                status: response.status,
                statusText: response.statusText,
                type: response.type,
            });
            if (response instanceof Error) {
                logger.warn('use-sso - triggerrefreshToken response Error', ssoClientId, 'response', response);
                throw response;
            }

            const newTokens: TokensType = await response
                .json()
                .catch((err) => logger.error('use-sso, triggerRefreshToken, Response parsing error', ssoClientId, err));

            logger.info('use-sso - triggerRefreshToken, tokens:', newTokens);
            if (!newTokens.error) {
                if (ssoClientId === 'enzp') {
                    adminApi.setSecurityData({ Authorization: `Bearer ${newTokens.access_token}` });
                } else {
                    api.setSecurityData({ Authorization: `Bearer ${newTokens.access_token}` });
                    epayApi.setSecurityData({ Authorization: `Bearer ${newTokens.access_token}` });
                }
                const jwtToken = decodeJwt(newTokens.access_token);
                dispatch(
                    AuthActions.setAuthTokens(
                        newTokens.access_token,
                        newTokens.refresh_token,
                        newTokens.id_token,
                        ssoClientId,
                        newTokens.expires_in,
                        jwtToken.exp,
                    ),
                );
            } else {
                logger.warn('use-sso - triggerRefreshToken failed', ssoClientId, 'tokens', newTokens);
            }
            return newTokens;
        },
        [dispatch, refreshToken, api, adminApi, epayApi, clientIdHint],
    );

    const triggerSecondaryRefreshToken = useCallback(
        async function() {
            if (!secondaryRefreshToken) return;

            try {
                const clientId = 'ecnzp';
                const response = await fetch('' + process.env.REACT_APP_SSO_TOKEN_URL, {
                    method: 'POST',
                    headers: {
                        Authorization: `Basic ${btoa(clientId + ':')}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    credentials: 'include',
                    body: `grant_type=refresh_token&refresh_token=${secondaryRefreshToken}&client_id=${clientId}`,
                });
                const newTokens: TokensType = await response
                    .json()
                    .catch((err) => logger.error('use-sso - triggerSecondaryRefreshToken: Response parsing error', err));

                if (!newTokens.error) {
                    api.setSecurityData({ Authorization: `Bearer ${newTokens.access_token}` });
                    epayApi.setSecurityData({ Authorization: `Bearer ${newTokens.access_token}` });
                    dispatch(
                        AuthActions.setSecondaryAuthTokens(newTokens.access_token, newTokens.refresh_token, newTokens.id_token, newTokens.expires_in),
                    );
                } else {
                    logger.log('tuse-sso - triggerSecondaryRefreshToken: failed', newTokens);
                }
                return newTokens;
            } catch (e) {
                return undefined;
            }
        },
        [dispatch, secondaryRefreshToken, api, epayApi],
    );

    return {
        ssoLogin,
        ssoLogout,
        ssoActorEmployeePartialLogout,
        ssoActorEmployeeLogin,
        triggerRefreshToken,
        triggerSecondaryRefreshToken,
        setSsoLoginHandler,
        redirectToSso,
        setSsoLogoutHandler,
    };
};

//when going back in browser history, need ssoPartialLogout without clearing customer from redux
export function ssoPartialLogout(secondaryAccessToken: string | undefined, secondaryIdTokenHint: string | undefined): Promise<void | Response> {
    if (!secondaryAccessToken || !secondaryIdTokenHint) return Promise.resolve();

    const logoutUrl = process.env.REACT_APP_SSO_LOGOUT_URL;
    const publicUrl = process.env.REACT_APP_PUBLIC_URL;
    const decodedJwt = decodeJwt(secondaryAccessToken);
    const finalUrl =
        logoutUrl +
        '?' +
        new URLSearchParams({
            post_logout_redirect_uri: `${window.location.origin}${publicUrl ? publicUrl : ''}${Routes.SSO_SUCCESS}`,
            id_token_hint: secondaryIdTokenHint,
            sid: decodedJwt.sid,
        });

    return fetch(finalUrl);
}

export default useSso;
