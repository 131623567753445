import {
    SkSppNzpBeApiCustomerprofileAddress,
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequest,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { CustomAddressModal } from '../../../../../components/common/custom-address-modal';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    Checkbox,
    ClickableElement,
    CommodityEnum,
    emailRegex,
    IRootState,
    LoadingIndicator,
    postalCodeRegex,
    postalCodeSkRegex,
    QueryKeysEnum,
    RequestTemplateCode,
    useApi,
    useCodeList,
} from '../../../../../main';
import { IApiResponse } from '../../../../../models/model';
import { ChangePersonalDataStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import {
    addressReplaceSpaceinZipCodeAndCity,
    formatAddress,
    formatAddressShort,
    formatPhoneNumber,
    formatSkPhoneNumber,
    latinCharactersRegex,
} from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../customer-request/config/enums';
import { useSendRequest } from '../../customer-requests/use-send-request';
import { DeliveryPointSwitchSelect } from '../../delivery-point-switch/select/delivery-point-switch-select';
import AddressFields from './component/address-fields';
import BlockEmailPhoneAddress from './component/block-email-phone-address';
import ContactFields from './component/contact-fields';
import ChangeRequestSent from './component/request-sent';

interface IChangePersonalDataModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    openChangePersonalDataModal: () => void;
}

interface IPersonalDataForm {
    email?: string;
    phone?: string;
    primaryAddress?: SkSppNzpBeApiCustomerprofileAddress;
}

const validateAddress = (address?: SkSppNzpBeApiCustomerprofileAddress) => {
    if (address?.street && address?.streetNumber && address.city && address.zipCode && address.country) {
        const isStreetValid = latinCharactersRegex.test(address.street);
        const isStreetNumberValid = latinCharactersRegex.test(address.streetNumber);
        const isCityValid = latinCharactersRegex.test(address.city);
        const isZipCodeValid = address.country === 'SK' ? postalCodeSkRegex.test(address.zipCode) : postalCodeRegex.test(address.zipCode);
        const isCountryValid = latinCharactersRegex.test(address.country);
        return isStreetValid && isStreetNumberValid && isCityValid && isZipCodeValid && isCountryValid;
    }
    return false;
};

export const ChangeCustomerAccountDataModal: React.FC<IChangePersonalDataModalProps> = ({
    isOpen,
    onClose,
    deliveryPoint,
    openChangePersonalDataModal,
}) => {
    const deliveryPoints = useSelector((store: IRootState) => store.customerRequest.additionalData?.udpd?.deliveryPoints) || [];
    const [step, setStep] = useState<ChangePersonalDataStepEnum>(ChangePersonalDataStepEnum.EDITING);
    const [updateOtherServices, setUpdateOtherServices] = useState<boolean>(true);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined>(deliveryPoint);
    const [isBusinessPartnerPhoneValid, setIsBusinessPartnerPhoneValid] = useState<boolean>(false);
    const contractAccount = selectedDeliveryPoint?.contract?.contractAccount;
    const businessPartnerData = selectedDeliveryPoint?.contract?.contractAccount?.businessPartner;
    const isSameAsBillingAddress: boolean =
        JSON.stringify(businessPartnerData?.primaryAddress, Object.keys(businessPartnerData?.primaryAddress || {}).sort()) ===
        JSON.stringify(contractAccount?.postAddress, Object.keys(contractAccount?.postAddress || {}).sort());

    const formData: IPersonalDataForm = {
        email: contractAccount?.email || businessPartnerData?.email,
        phone: contractAccount?.phone || businessPartnerData?.phone,
        primaryAddress: contractAccount?.postAddress || businessPartnerData?.primaryAddress,
    };

    const { register, errors, trigger, watch, setValue, getValues, setError, clearErrors, handleSubmit, reset } = useForm<IPersonalDataForm>({
        defaultValues: formData,
    });

    const [mutatePhoneValidate, { isLoading }] = useMutationWithError(
        async ({ phone }: { phone: string; type: 'contractAccount' | 'businessPartner' }) =>
            api.validations.checkPasswordComplexityWithPhone({ phone }),
        {
            onErrorWithGlobalErrorHandling: (response: IApiResponse, params) => {
                if (response.error) {
                    params.type === 'contractAccount' &&
                        setError('phone', {
                            message: t('common.input-rules.phoneNumber'),
                        });
                    return true;
                }
                return false;
            },
        },
    );

    const isBusinessPartnerEmailValid = businessPartnerData?.email ? emailRegex.test(businessPartnerData?.email) : false;
    const isBusinessPartnerAddressValid = validateAddress(businessPartnerData?.primaryAddress);

    const isBusinessPartnerValid = isBusinessPartnerAddressValid && isBusinessPartnerEmailValid && isBusinessPartnerPhoneValid;

    const isBusinessPartnerSameAsContract =
        isBusinessPartnerEmailValid &&
        isBusinessPartnerAddressValid &&
        (!contractAccount?.phone || contractAccount?.phone === businessPartnerData?.phone) &&
        (!contractAccount?.email || contractAccount?.email === businessPartnerData?.email) &&
        isSameAsBillingAddress;

    const [businessPartnerSameAsContract, setBusinessPartnerSameAsContract] = useState<boolean>(isBusinessPartnerSameAsContract);

    const contractAccountDataIsSameAsBusinessPartnerData = isBusinessPartnerSameAsContract && isBusinessPartnerPhoneValid;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const isDataChanged = useMemo(() => {
        const item = countryCodeList?.find((item) => item.code === businessPartnerData?.primaryAddress?.country);

        const newAddress = JSON.stringify({
            street: getValues('primaryAddress.street'),
            streetNumber: getValues('primaryAddress.streetNumber'),
            city: getValues('primaryAddress.city'),
            zipCode: getValues('primaryAddress.zipCode'),
            country: getValues('primaryAddress.country'),
        });

        const contractAccountAddress = JSON.stringify({
            ...contractAccount?.postAddress,
            country: item?.uuid,
        });
        const isSame =
            newAddress === contractAccountAddress &&
            (getValues('email') || '') === (contractAccount?.email || '') &&
            (getValues('phone') || '') === (contractAccount?.phone || '');

        return !isSame;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch(['primaryAddress', 'phone', 'email']), businessPartnerData, contractAccount, countryCodeList, getValues]);

    const isSendDisabled = () => {
        if (businessPartnerSameAsContract && contractAccountDataIsSameAsBusinessPartnerData) {
            return true;
        }
        if (!businessPartnerSameAsContract && !isDataChanged) {
            return true;
        }
        return false;
    };

    const correspondenceAddress = useMemo<string | undefined>(() => {
        if (step !== ChangePersonalDataStepEnum.EDITING && !businessPartnerSameAsContract) {
            return formatAddress(
                addressReplaceSpaceinZipCodeAndCity({
                    street: getValues('primaryAddress.street'),
                    streetNumber: getValues('primaryAddress.streetNumber'),
                    city: getValues('primaryAddress.city'),
                    zipCode: getValues('primaryAddress.zipCode'),
                    country: getValues('primaryAddress.country'),
                }),
                true,
            );
        }
        if (!!contractAccount?.postAddress?.city || !!businessPartnerData?.primaryAddress) {
            return formatAddress(addressReplaceSpaceinZipCodeAndCity(businessPartnerData?.primaryAddress || contractAccount?.postAddress), true);
        }
        return undefined;
    }, [contractAccount?.postAddress, businessPartnerData?.primaryAddress, getValues, businessPartnerSameAsContract, step]);

    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const [openCustomAddressModal, setOpenCustomAddressModal] = useState<boolean>(false);
    const [disableAddress, setDisableAddress] = useState<boolean>(true);
    const [customAddressInserted, setCustomAddressInserted] = useState<boolean>(false);
    const [customAddress, setCustomAddress] = useState<SkSppNzpBeApiCustomerprofileAddress>();
    const [newAddress, setNewAddress] = useState<SkSppNzpBeApiCustomerprofileAddress>();

    const initializeRequestCreationProcess = useSendRequest();

    const api = useApi();
    const [template, setTemplate] = useState<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate>();
    const { addToast } = useToasts();

    useEffect(() => {
        if (isOpen) {
            setStep(ChangePersonalDataStepEnum.EDITING);
            setNewAddress(contractAccount?.postAddress || businessPartnerData?.primaryAddress);
            businessPartnerData?.phone &&
                mutatePhoneValidate({ phone: businessPartnerData?.phone, type: 'businessPartner' }).then((result) =>
                    setIsBusinessPartnerPhoneValid(!!result),
                );
        }
    }, [isOpen, dispatch, contractAccount, businessPartnerData, mutatePhoneValidate]);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    useEffect(() => {
        const contractAccount = deliveryPoint?.contract?.contractAccount;
        const businessPartnerData = deliveryPoint?.contract?.contractAccount?.businessPartner;
        setSelectedDeliveryPoint(deliveryPoint);
        reset({
            email: contractAccount?.email || businessPartnerData?.email,
            phone: contractAccount?.phone || businessPartnerData?.phone,
            primaryAddress: contractAccount?.postAddress || businessPartnerData?.primaryAddress,
        });
        isOpen &&
            getRequestTemplates(
                { code: CustomerRequestTypeEnum.ZOP_ZOUA },
                {
                    onSuccess: (data) => {
                        if (data && data[0] != null) {
                            const foundTemplate = data[0];
                            setTemplate(foundTemplate);
                        } else {
                            addToast(t('error-customer-request.default'), {
                                appearance: 'error',
                            });
                            onClose();
                        }
                    },
                    onError: () => {
                        addToast(t('error-customer-request.default'), {
                            appearance: 'error',
                        });
                        onClose();
                    },
                },
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const onFormSubmit = async (formData: IPersonalDataForm) => {
        if (businessPartnerSameAsContract) {
            if (deliveryPoints.filter((dp) => dp.status === 'ACTIVE').length > 1) {
                setStep(ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES);
            } else {
                onSubmit();
            }
        } else {
            const phone = formatPhoneNumber(formData.phone || '');
            if (!phone) {
                setError('phone', {
                    message: t('common.input-rules.phoneNumber'),
                });
                return;
            }
            mutatePhoneValidate({ phone, type: 'contractAccount' }).then((result) => {
                if (!!result) {
                    if (deliveryPoints.filter((dp) => dp.status === 'ACTIVE').length > 1) {
                        setStep(ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES);
                    } else {
                        onSubmit({ ...formData, phone }, false);
                    }
                }
            });
        }
    };

    const onSubmit = (formData?: IPersonalDataForm, updateAllServices?: boolean) => {
        setUpdateOtherServices(updateAllServices || false);
        if (template) {
            const primaryAddressStreetNumber = businessPartnerSameAsContract
                ? businessPartnerData?.primaryAddress?.streetNumber || contractAccount?.postAddress?.streetNumber
                : formData?.primaryAddress?.streetNumber;

            const primaryAddressCountry = countryCodeList?.find((item) =>
                businessPartnerSameAsContract
                    ? item.code === (businessPartnerData?.primaryAddress?.country || contractAccount?.postAddress?.country)
                    : item.uuid === getValues('primaryAddress.country'),
            );

            const newAddress = {
                street: businessPartnerSameAsContract
                    ? businessPartnerData?.primaryAddress?.street || contractAccount?.postAddress?.street
                    : getValues('primaryAddress.street'),
                city: businessPartnerSameAsContract
                    ? businessPartnerData?.primaryAddress?.city || contractAccount?.postAddress?.city
                    : getValues('primaryAddress.city'),
                postalCode: businessPartnerSameAsContract
                    ? businessPartnerData?.primaryAddress?.zipCode || contractAccount?.postAddress?.zipCode
                    : getValues('primaryAddress.zipCode'),
                country: { uuid: primaryAddressCountry?.uuid },
                number: primaryAddressStreetNumber,
                phoneNumber: businessPartnerSameAsContract ? businessPartnerData?.phone || contractAccount?.phone : formData?.phone,
                email: businessPartnerSameAsContract ? businessPartnerData?.email || contractAccount?.email : formData?.email,
            };

            const content = {
                newCorrespondenceAddress: newAddress,
                entityReferences: updateAllServices
                    ? deliveryPoints
                          .filter((dp) => dp.status === 'ACTIVE')
                          .map((dp) => {
                              return { contractId: dp?.contract?.id, deliveryPointId: dp.id, contractAccountId: dp?.contract?.contractAccount?.id };
                          })
                    : [
                          {
                              contractId: selectedDeliveryPoint?.contract?.id,
                              deliveryPointId: selectedDeliveryPoint?.id,
                              contractAccountId: selectedDeliveryPoint?.contract?.contractAccount?.id,
                          },
                      ],
                appliedTo: updateAllServices ? 'CONTRACT_ACCOUNTS' : 'CONTRACT_ACCOUNT',
            };

            const metadata = {
                businessPartnerId: selectedDeliveryPoint?.contract?.contractAccount?.businessPartner?.id,
                processManualOverride: customAddressInserted || primaryAddressCountry?.code !== 'SK',
            };

            const data: SkSppNzpBeApiCustomerrequestCustomerRequest = {
                content: { metadata, ...content, type: CustomerRequestTypeEnum.ZOP_ZOUA },
                customerRequestTemplate: template,
            };
            setLoading(true);
            initializeRequestCreationProcess(data)
                .then(() => setStep(ChangePersonalDataStepEnum.SUCCESS))
                .catch(() => setStep(ChangePersonalDataStepEnum.EDITING))
                .finally(() => setLoading(false));
        }
    };

    const { t } = useTranslation();

    useEffect(() => {
        const country = countryCodeList?.find((item) => item.uuid === getValues('primaryAddress.country'));
        if (country?.code === 'SK') {
            setDisableAddress(true);
            const addressObject: SkSppNzpBeApiCustomerprofileAddress = {
                street: getValues('primaryAddress.street'),
                streetNumber: getValues('primaryAddress.streetNumber'),
                city: getValues('primaryAddress.city'),
                zipCode: getValues('primaryAddress.zipCode'),
                country: country?.code,
            };

            if (newAddress && JSON.stringify(addressObject) !== JSON.stringify(newAddress)) {
                setNewAddress(undefined);
                setValue('primaryAddress.street', '');
                setValue('primaryAddress.streetNumber', '');
                setValue('primaryAddress.city', '');
                setValue('primaryAddress.zipCode', '');
            }
        } else {
            setDisableAddress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('primaryAddress.country')]);

    const setAddressFromCustomAddressModal = (data: SkSppNzpBeApiCustomerprofileAddress) => {
        const selectedCountry = countryCodeList?.find((item) => item.uuid === data.country);
        data.country = selectedCountry?.code;
        setNewAddress(data);
        setCustomAddress(data);
        setValue('primaryAddress.street', data.street);
        setValue('primaryAddress.streetNumber', data.streetNumber);
        setValue('primaryAddress.city', data.city);
        setValue('primaryAddress.zipCode', data.zipCode);
        setValue('primaryAddress.country', selectedCountry?.uuid);
        setCustomAddressInserted(true);
        setOpenCustomAddressModal(false);
    };

    const newInfo = {
        email: businessPartnerSameAsContract ? businessPartnerData?.email || contractAccount?.email : getValues('email'),
        phoneNumber: formatSkPhoneNumber(
            (businessPartnerSameAsContract ? businessPartnerData?.phone || contractAccount?.phone : getValues('phone')) || '',
        ),
    };

    const displayUdpWithCommodity = (deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPoint[]) => {
        const ee = deliveryPoints.filter((dp) => dp.type === CommodityEnum.ELECTRICITY && dp.status === 'ACTIVE');
        const zp = deliveryPoints.filter((dp) => dp.type === CommodityEnum.GAS && dp.status === 'ACTIVE');
        return (
            <p className="text-black font-weight-500 mb-0">
                {`${formatAddressShort(deliveryPoints?.[0]?.address)} - ${zp.length > 1 ? `${zp.length} x ` : ''}${
                    zp.length ? t('enums.CommodityEnum.ZP') : ''
                }${ee.length > 0 && zp.length > 0 ? ', ' : ''} ${ee.length > 1 ? `${ee.length} x ` : ''}${
                    ee.length ? t('enums.CommodityEnum.EE') : ''
                }`}
            </p>
        );
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    {step === ChangePersonalDataStepEnum.EDITING && (
                        <h3 className="mb-4">
                            <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.title">Kontaktné údaje</Trans>
                        </h3>
                    )}
                    {step === ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES && (
                        <h3 className="mb-4">
                            <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.change-contact-for-other-services">
                                Prajete si aktualizovať kontaktné údaje pre všetky vaše služby?
                            </Trans>
                        </h3>
                    )}

                    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                        <div className={classNames({ 'd-none': step !== ChangePersonalDataStepEnum.EDITING })}>
                            {deliveryPoints.length && deliveryPoints.length > 1 && (
                                <div className="delivery-point-switch mb-4">
                                    <DeliveryPointSwitchSelect
                                        selectedDeliveryPoint={selectedDeliveryPoint}
                                        deliveryPoints={deliveryPoints}
                                        onSelect={(id) => {
                                            const newDeliveryPoint = deliveryPoints.find((dp) => dp.id === id);
                                            const contractAccount = newDeliveryPoint?.contract?.contractAccount;
                                            const businessPartnerData = newDeliveryPoint?.contract?.contractAccount?.businessPartner;
                                            setSelectedDeliveryPoint(newDeliveryPoint);
                                            reset({
                                                email: contractAccount?.email || businessPartnerData?.email,
                                                phone: contractAccount?.phone || businessPartnerData?.phone,
                                                primaryAddress: contractAccount?.postAddress || businessPartnerData?.primaryAddress,
                                            });
                                        }}
                                        showInactive={false}
                                        toggleInactive={undefined}
                                    />
                                </div>
                            )}

                            {isBusinessPartnerValid && (
                                <Card className="mb-4">
                                    <CardBody>
                                        <Row>
                                            <Col sm={1}>
                                                <div>
                                                    <Checkbox
                                                        checked={businessPartnerSameAsContract}
                                                        onChange={() => setBusinessPartnerSameAsContract(!businessPartnerSameAsContract)}
                                                        id="business-partner-same-as-contract"
                                                        name="business-partner-same-as-contract"
                                                        label=""
                                                        className="mt-3"
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={11}>
                                                <p className="font-weight-500 text-black">
                                                    <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.contact-data-same-as-business-partner-data" />
                                                </p>
                                                <BlockEmailPhoneAddress
                                                    email={businessPartnerData?.email || contractAccount?.email}
                                                    phone={formatSkPhoneNumber(businessPartnerData?.phone || contractAccount?.phone || '')}
                                                    address={correspondenceAddress}
                                                />

                                                <div className="mt-2">
                                                    <ClickableElement
                                                        isText
                                                        onClick={() => openChangePersonalDataModal()}
                                                        className="text-decoration-underline-inverse font-weight-500 text-black"
                                                    >
                                                        {t('delivery-point.detail.contact-data.change-personal-data-modal.edit-customer-info')}
                                                    </ClickableElement>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            )}

                            {(!businessPartnerSameAsContract || !isBusinessPartnerValid) && (
                                <Card className="mb-4">
                                    <CardBody>
                                        <ContactFields register={register} errors={errors} trigger={trigger} />
                                        <p className="mb-0 pb-1 text-black font-weight-500">{t('customer-request.steps.new-data.new-address')}</p>
                                        <AddressFields
                                            register={register}
                                            errors={errors}
                                            trigger={trigger}
                                            setValue={setValue}
                                            address={newAddress}
                                            watch={watch}
                                            objectName="primaryAddress."
                                            type={openCustomAddressModal ? 'normal' : disableAddress ? 'search' : 'normal'}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            onAddressSelected={(address) => {
                                                setCustomAddressInserted(false);
                                                setNewAddress(address);
                                            }}
                                        />
                                        <div className="mb-2">
                                            <ClickableElement
                                                isText
                                                onClick={() => setOpenCustomAddressModal(true)}
                                                className="text-decoration-underline-inverse"
                                            >
                                                {t('customer-request.steps.custom-address-modal.my-address-not-in-list')}
                                            </ClickableElement>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                            <Button type="submit" block color="primary" size="lg" className="mt-5" disabled={isSendDisabled()}>
                                {t('delivery-point.detail.contact-data.change-contact-data-modal.change')}
                            </Button>
                        </div>

                        <div className={classNames({ 'd-none': step !== ChangePersonalDataStepEnum.UPDATE_OTHER_SERVICES })}>
                            <div className="mb-1">{t('delivery-point.detail.contact-data.change-contact-data-modal.new')}:</div>
                            <BlockEmailPhoneAddress email={newInfo.email} phone={newInfo.phoneNumber} address={correspondenceAddress} />
                            <div className="mt-2">{t('delivery-point.detail.contact-data.change-contact-data-modal.for-your-services')}:</div>
                            {displayUdpWithCommodity(deliveryPoints)}
                            <Button type="button" block color="primary" size="lg" className="mt-5" onClick={() => onSubmit(getValues(), true)}>
                                {t('delivery-point.detail.contact-data.change-contact-data-modal.update-for-all-services')}
                            </Button>
                            <div onClick={() => null} className="text-center mt-2">
                                <ClickableElement isText onClick={() => onSubmit(getValues(), false)} className="font-weight-500 text-black">
                                    {t('delivery-point.detail.contact-data.change-contact-data-modal.no-update')}
                                </ClickableElement>
                            </div>
                        </div>
                        <div className={classNames({ 'd-none': step !== ChangePersonalDataStepEnum.SUCCESS })}>
                            <ChangeRequestSent
                                onClose={onClose}
                                selectedDeliveryPoint={selectedDeliveryPoint}
                                deliveryPoints={deliveryPoints.filter((dp) => dp.status === 'ACTIVE')}
                                allServicesSame={updateOtherServices}
                                newInfo={{ ...newInfo, address: correspondenceAddress }}
                            />
                        </div>
                    </form>
                    {(loading || isLoading) && <LoadingIndicator />}
                </ModalBody>
            </Modal>
            {openCustomAddressModal && (
                <CustomAddressModal
                    closeModal={() => setOpenCustomAddressModal(false)}
                    onFormSubmit={setAddressFromCustomAddressModal}
                    address={customAddress}
                />
            )}
        </>
    );
};
