import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../actions/auth-actions';
import { AuthEnum } from '../../models/enums';
import { clearCodeVerifierFromSessionStorage } from '../../utils/utils';
import { useCodeChallenge } from './use-code-challenge';
import { bindUrlStateParamsToUuid } from './use-sso';

export interface ISilentRefreshResult {
    code: string;
    state: string;
    scope: string;
    error: string;
    error_description: string;
}

type SilentRefreshType = (clientId: string, scope: string, publicUrl?: string, customerId?: string) => Promise<ISilentRefreshResult>;
type ResolveSilentRefreshType = () => void;

const useSilentRefresh = (): [SilentRefreshType, ResolveSilentRefreshType] => {
    const getCodeChallenge = useCodeChallenge();
    const dispatch = useDispatch();

    const silentRefresh = useCallback(
        async function silentRefresh(clientId: string, scope: string, publicUrl?: string, customerId?: string | undefined) {
            const codeChallenge = await getCodeChallenge();
            const stateAsUuid = bindUrlStateParamsToUuid(window.location.pathname + window.location.search);
            const url =
                process.env.REACT_APP_SSO_AUTH_URL +
                '?' +
                new URLSearchParams({
                    redirect_uri: '' + process.env.REACT_APP_SSO_RESOLVE_URL,
                    response_type: AuthEnum.RESPONSE_TYPE_CODE,
                    code_challenge_method: AuthEnum.CODE_CHALLENGE_METHOD,
                    code_challenge: codeChallenge,
                    prompt: 'none',
                    client_id: clientId,
                    state: stateAsUuid,
                    scope: scope,
                    ...(process.env.REACT_APP_SSO_APP_VER ? { authnAttrs: 'appVer:' + process.env.REACT_APP_SSO_APP_VER } : {}),
                    ...(customerId && { authnAttrs: `customerUid:${customerId}` }),
                });
            return new Promise<ISilentRefreshResult>(function(resolve, reject) {
                fetch(url, { credentials: 'include' })
                    .then((response) => {
                        response
                            .json()
                            .then((body) => {
                                sessionStorage.removeItem(stateAsUuid);
                                resolve(body);
                            })
                            .catch((error) => {
                                sessionStorage.removeItem(stateAsUuid);
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        sessionStorage.removeItem(stateAsUuid);
                        reject(error);
                    });
            });
        },
        [getCodeChallenge],
    );

    const resolveSilentRefresh = useCallback(() => {
        clearCodeVerifierFromSessionStorage();
        dispatch(AuthActions.setIsSilentRefreshResolved(true));
    }, [dispatch]);

    return [silentRefresh, resolveSilentRefresh];
};

export default useSilentRefresh;
