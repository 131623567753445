import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

interface IBinComponentProps {
    cardClassName?: string;
    bold?: boolean;
    link: string;
}

export const NewsAndInformation: React.FC<IBinComponentProps> = ({ bold, link, cardClassName }) => {
    return (
        <Card className={classNames('d-lg-block', { cardClassName: !!cardClassName })}>
            <CardBody>
                <Row className="d-flex">
                    <Col>
                        <p className={classNames('m-0', { 'font-weight-bold': bold })}>{t('settings.login-details.news-and-information')}</p>
                    </Col>
                    <Col className="text-right">
                        <p className={classNames('m-0', { 'font-weight-bold': bold })}>
                            <Link to={link} className="clickable-element clickable-element--text text-blue">
                                {t('settings.login-details.news-and-information-manage')}
                                <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                            </Link>
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
