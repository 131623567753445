import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import Input from '../../../../../components/common/input';
import { useFormRules } from '../../../../../hooks/use-form-rules';

interface IPersonalName {
    firstName: string;
    lastName: string;
}

interface INameChangeFormProps {
    value: IPersonalName;
    onSubmit: (newValue: IPersonalName) => void;
}

const NameChangeForm: React.FC<INameChangeFormProps> = ({ value, onSubmit }) => {
    const { register, handleSubmit, errors, trigger } = useForm<IPersonalName>({ defaultValues: value });
    const { requiredTrimed, formRules } = useFormRules();
    const [t] = useTranslation();

    const onFormSubmit = handleSubmit((newName) => onSubmit(newName));
    return (
        <>
            <form onSubmit={onFormSubmit} noValidate>
                <Input
                    trigger={trigger}
                    ref={register({ ...requiredTrimed, ...formRules.validName })}
                    errors={errors}
                    type="text"
                    name="firstName"
                    label={t('common.name')}
                    autoFocus
                />

                <Input
                    trigger={trigger}
                    ref={register({ ...formRules.validName })}
                    errors={errors}
                    type="text"
                    name="lastName"
                    label={t('common.surname')}
                />
                <Button type="submit" block color="primary" size="lg" className="my-4">
                    <Trans i18nKey="settings.login-details.accept-changes" />
                </Button>
            </form>
        </>
    );
};

export default NameChangeForm;
