import {
    SkSppNzpCommonsApiCustomerconsumptionConsumptionSearch,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff,
    SkSppNzpCommonsApiCustomerconsumptionConsumptionValue,
} from '@spp/spp-meru-frontend-common/dist/api';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/use-api';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';

/*
const fakeData: SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse = {
    readAt: {},
    reservedValues: {
        maximumDailyAmount: [],
        reserveAmount: [
            {
                valid: {
                    from: '2021-03-01T00:00:00',
                },
                value: 33.5274,
                type: 'ERKA',
            },
        ],
        maximumReserveAmount: [],
        tariffCount: [
            {
                eeTariff: '2T',
                valid: {
                    from: '2018-04-01T00:00:00',
                    to: '2019-03-31T23:59:59.999999999',
                },
            },
            {
                eeTariff: '2T',
                valid: {
                    from: '2017-04-04T00:00:00',
                    to: '2018-03-31T23:59:59.999999999',
                },
            },
            {
                eeTariff: '2T',
                valid: {
                    from: '2021-04-01T00:00:00',
                },
            },
            {
                eeTariff: '2T',
                valid: {
                    from: '2019-04-01T00:00:00',
                    to: '2021-03-31T23:59:59.999999999',
                },
            },
        ],
    },
    tariffs: [
        {
            tariffIndex: {
                uuid: '0a297567-3a33-4624-96b5-ca50b6b5006c',
                type: 'METER_READING_REGISTER_KIND',
                code: 'NT',
                name: 'Nízka tarifa',
            },
            values: [
                {
                    value: 184.0,
                    period: {
                        from: '2021-04-01T00:00:00',
                        to: '2021-04-30T00:00:00',
                    },
                },
                {
                    value: 230.0,
                    period: {
                        from: '2021-05-01T00:00:00',
                        to: '2021-05-31T00:00:00',
                    },
                },
                {
                    value: 409.0,
                    period: {
                        from: '2021-06-01T00:00:00',
                        to: '2021-06-30T00:00:00',
                    },
                },
            ],
        },
        {
            tariffIndex: {
                uuid: '40d78b78-99e0-4ccc-b651-9d25016cc148',
                type: 'METER_READING_REGISTER_KIND',
                code: 'VT',
                name: 'Vysoká tarifa',
            },
            values: [
                {
                    value: 1100.0,
                    period: {
                        from: '2021-03-01T00:00:00',
                        to: '2021-04-30T00:00:00',
                    },
                },
                {
                    value: 349.0,
                    period: {
                        from: '2021-05-01T00:00:00',
                        to: '2021-05-31T00:00:00',
                    },
                },
                {
                    value: 481.0,
                    period: {
                        from: '2021-06-01T00:00:00',
                        to: '2021-06-30T00:00:00',
                    },
                },
            ],
        },
    ],
};
*/

/*
const fakeData: SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse = {
    readAt: {},
    resultsAggregated: false,
    reservedValues: {
        maximumDailyAmount: [],
        reserveAmount: [
            { valid: { from: '2017-01-01T00:00:00', to: '2020-04-15T00:00:00' }, value: 16.435, type: 'ERKA' },
            { valid: { from: '2014-04-01T00:00:00', to: '2016-12-31T00:00:00' }, value: 16.435, type: 'ERKA' },
            { valid: { from: '2020-04-16T00:00:00', to: undefined }, value: 16.435, type: 'ERKA' },
        ],
        maximumReserveAmount: [],
        tariffCount: [
            { eeTariff: '1T', valid: { from: '2020-12-01T00:00:00', to: undefined } },
            { valid: { from: '2014-04-01T00:00:00', to: '2015-12-31T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2017-01-01T00:00:00', to: '2017-12-31T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2016-06-01T00:00:00', to: '2016-12-31T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2016-01-01T00:00:00', to: '2016-05-31T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2019-12-01T00:00:00', to: '2020-11-30T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2018-01-01T00:00:00', to: '2018-11-30T23:59:59.999999999' } },
            { eeTariff: '1T', valid: { from: '2018-12-01T00:00:00', to: '2019-11-30T23:59:59.999999999' } },
        ],
    },
    tariffs: [
        {
            tariffIndex: { uuid: '0a297567-3a33-4624-96b5-ca50b6b5006c', type: 'METER_READING_REGISTER_KIND', code: 'NT', name: 'Nízka tarifa' },
            values: [{ value: 0.0, period: { from: '2014-04-01T00:00:00', to: '2018-04-11T00:00:00' } }],
        },
        {
            tariffIndex: { uuid: '40d78b78-99e0-4ccc-b651-9d25016cc148', type: 'METER_READING_REGISTER_KIND', code: 'VT', name: 'Vysoká tarifa' },
            values: [
                { value: 16024.0, period: { from: '2014-04-01T00:00:00', to: '2019-04-15T00:00:00' } },
                { value: 3951.0, period: { from: '2019-04-16T00:00:00', to: '2020-04-15T00:00:00' } },
                { value: 3814.0, period: { from: '2020-04-16T00:00:00', to: '2021-04-12T00:00:00' } },
            ],
        },
    ],
};*/

export type Data = {
    dateFrom: Date;
    dateTo: Date;
    value: number;
};

type Props = {
    callMutation: boolean;
    contractId: string | undefined;
    timestampFrom?: string;
    timestampTo?: string;
    units: SkSppNzpCommonsApiCustomerconsumptionConsumptionSearch['units'];
};

const useFetchConsumption = ({
    callMutation,
    contractId,
    timestampFrom,
    timestampTo,
    units,
}: Props): { isLoading: boolean; error: unknown; data: Data[] | null; dataTariff?: SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff[] } => {
    const [mappedData, setMappedData] = useState<Data[]>([]);
    const api = useApi();

    const [
        mutateFetchConsumption,
        { isLoading, error, data },
    ] = useMutationWithError<SkSppNzpCommonsApiCustomerconsumptionConsumptionSearchResponse | null>(async () =>
        contractId != null
            ? api.deliveryPoints
                  .searchConsumptions(
                      contractId,
                      {
                          readAt: {
                              from: timestampFrom ? timestampFrom : '',
                              to: timestampTo ? timestampTo : '',
                          },
                          type: 'DELIVERY_POINT_FACT',
                          units,
                      },
                      { secure: true },
                  )
                  .then((res) => {
                      //const res = { data: fakeData };
                      return {
                          ...res.data,
                          tariffs: res.data?.tariffs?.map((tariff) => ({
                              ...tariff,
                              values: tariff.values?.map((val) => ({
                                  ...val,
                                  period: { ...val.period, to: val.period?.to?.replace('00:00:00', '23:59:59') },
                              })),
                          })),
                      };
                  })
            : null,
    );

    useEffect(() => {
        if (callMutation) {
            mutateFetchConsumption();
        }
    }, [callMutation, mutateFetchConsumption, units, contractId, timestampFrom, timestampTo]);

    useEffect(() => {
        if (data) {
            const values = data.tariffs?.reduce<SkSppNzpCommonsApiCustomerconsumptionConsumptionValue[]>((acc, tariff) => {
                if (tariff.values) {
                    return acc.concat(tariff.values);
                } else {
                    return acc;
                }
            }, []);
            if (values) {
                const mappedDataTmp = values.reduce((accumulator: Data[], record) => {
                    const { period, value } = record;
                    if (period?.from && period?.to && value !== undefined) {
                        const result = {
                            dateFrom: new Date(period.from),
                            dateTo: new Date(period.to),
                            value,
                        };
                        //console.log('period', period, 'result', result);
                        return accumulator.concat(result);
                    } else {
                        return accumulator;
                    }
                }, []);
                if (mappedDataTmp) {
                    setMappedData(mappedDataTmp);
                }
            }
        }
    }, [setMappedData, data]);

    return { isLoading, error, data: mappedData, dataTariff: data?.tariffs };
};

export default useFetchConsumption;
