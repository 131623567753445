import React from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { CodeListTypeEnum, QueryKeysEnum } from '../../../../../../main';
import { IContractTerminationReason } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IContractTerminationReason;
    bold?: boolean;
};

const ContractTerminationReasonItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: contractTerminationReasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CONTRACT_TERMINATION_REASON,
        codeListTypeEnum: CodeListTypeEnum.CONTRACT_TERMINATION_REASON,
        paging: {
            size: 64,
        },
    });

    if (isObjectEmpty(data)) return <></>;

    const { meterRemoval, notOwnerOfPropertyDeclaration, reason } = data;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.termination-reason.title')}>
                {reason && (
                    <ItemPreviewContent bold={bold}>
                        {contractTerminationReasonCodeList?.find((item) => item.uuid === reason.uuid)?.name}
                    </ItemPreviewContent>
                )}
                {meterRemoval && <ItemPreviewContent bold={bold}>{meterRemoval}</ItemPreviewContent>}
                {notOwnerOfPropertyDeclaration && <ItemPreviewContent bold={bold}>{notOwnerOfPropertyDeclaration}</ItemPreviewContent>}
            </ItemPreview>
        </>
    );
};

export default ContractTerminationReasonItem;
