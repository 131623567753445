import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import Routes from '../routes/routes';
import ConfirmDialog from './dialog/confirm-dialog';

type Props = {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    beforeUnload?: boolean;
    messageHeader?: string | JSX.Element;
    messageBody?: string | JSX.Element;
    confirmButtonText?: string;
    cancelButtonText?: string;
    requestUuid?: string;
};
const LeavingPageGuard: React.FC<Props> = ({
    when,
    navigate,
    shouldBlockNavigation,
    messageHeader,
    messageBody,
    beforeUnload,
    confirmButtonText,
    cancelButtonText,
    requestUuid,
}) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        return (event.returnValue = 'You sure you want to leave?');
    };

    useEffect(() => {
        if (!beforeUnload) return;
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [beforeUnload]);

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation) && nextLocation.pathname !== Routes.AUTO_LOGOUT_CONFIRMATION) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleNavigationClick = (action: string) => {
        if (action === 'confirm') {
            setConfirmedNavigation(true);
        }
        setModalVisible(false);
    };
    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname + (requestUuid ? `?customerRequestId=${requestUuid}` : ''));
        }
    }, [confirmedNavigation, lastLocation, navigate, requestUuid]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmDialog
                visible={modalVisible}
                header={messageHeader}
                body={messageBody || t('common.leaving-page')}
                onClick={handleNavigationClick}
                confirmButton={confirmButtonText}
                cancelButton={cancelButtonText}
            />
        </>
    );
};
export default LeavingPageGuard;
