import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { IAssumedZpConsumption } from '../../../../../models/customer-request-model';
import { MeterReadingEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties } from '../../../../../utils/utils';
import { CustomerRequestDataEnum } from '../../../config/enums';
import MerterOneTariffShort from './components/meter-one-tariff-short';

const BlockMeterReadingZpShort: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, deliveryPointZp, content } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.ZP_METER_READING;
    const objectData = content[objectName] as IAssumedZpConsumption;

    const { register, errors, handleSubmit, getValues, setValue, trigger } = useForm<IAssumedZpConsumption>({ defaultValues: { ...objectData } });

    const onSubmit = (data: IAssumedZpConsumption) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: {
                ...clearObjectProperties(data),
                type: MeterReadingEnum.GAS,
            },
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <MerterOneTariffShort
                lastLowValue={deliveryPointZp?.readingCycleInfo?.lastHighValue}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
            />
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockMeterReadingZpShort;
