import { SkSppNzpCommonsApiEmployeeaccessEmployee } from '@spp/spp-meru-frontend-common/src/api';
import { can } from '../../components/permissions';
import { IAccountInfo } from '../../reducers/user-reducer';
import Routes, { ErrorRoutes, PortalAccountsRoutes, SystemManagementRoutes } from '../../routes/routes';

export const getProperHomepage = (user: IAccountInfo): string => {
    if (!!user.employee && !!user.customer) {
        if (user.customer.id) {
            return employeeAsCustomerHomepage(user.customer.id); //employee as customer
        } else {
            console.error('Missing customerId in url, returning to employeeHomepage');
            return employeeHomepage(user.employee);
        }
    }

    if (!!user.employee && !user.customer) return employeeHomepage(user.employee); //employee only
    return customerHomepage(); //customer only

    function customerHomepage() {
        return Routes.DELIVERY_POINTS;
    }

    function employeeHomepage(employee: SkSppNzpCommonsApiEmployeeaccessEmployee) {
        if (can('CUSTOMERS_VIEW', employee)) return PortalAccountsRoutes.CUSTOMERS;
        if (can(['AUDIT_LOGS_EMPLOYEES', 'AUDIT_LOGS_CUSTOMERS', 'AUDIT_LOGS_CUSTOMERS_WITH_EMPLOYEES'], employee)) {
            return PortalAccountsRoutes.EMPLOYEE_ACTIVITY_LOGS;
        }
        if (can('REPORTS_VIEW', employee)) return PortalAccountsRoutes.REPORTS;
        if (can(['NOTIFICATION_TEMPLATES_VIEW', 'COMPONENT_HELP_VIEW', 'ACCESS_GROUPS_VIEW'], employee)) {
            return SystemManagementRoutes.SYSTEM_MANAGEMENT;
        }

        return ErrorRoutes.INSUFFICIENT_PRIVILEGES;
    }

    function employeeAsCustomerHomepage(userId: string) {
        return PortalAccountsRoutes.CUSTOMER_DETAIL.replace(':customerId', userId);
    }
};
