import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { useEpayApi } from '../../../../hooks/use-api';
import { PaymentProviderResponse } from '../api/e-pay-api';

const useFetchPaymentProviders = (clientCode: string): { isLoading: boolean; error: unknown; data?: PaymentProviderResponse[] | null } => {
    const epayApi = useEpayApi();
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const { isLoading, error, data } = useQuery<PaymentProviderResponse[] | undefined | null>(
        ['EPAY_PROVIDERS', clientCode],
        async () =>
            epayApi.epay
                .getPaymentProviders(
                    {
                        clientCode,
                    },
                    { secure: true },
                )
                .then((res) => (!!res.data ? res.data.filter((provider) => provider.initialized) : [])),
        {
            staleTime: 60000,
            onError: () => {
                addToast(t('error-epay.1101'), { appearance: 'error' });
            },
        },
    );

    return { isLoading, error, data };
};

export default useFetchPaymentProviders;
