import React, { useMemo } from 'react';
import { FilterDescription } from '../../../../components/common/filter-description';
import { formatDateToPreview } from '../../../../main';
import { IConsumptionAdditionalFilter, IConsumptionFilter } from './consumption';

interface IConsumptionFilterDescriptionProps {
    filter: Partial<IConsumptionFilter>;
    additionalFilter: Partial<IConsumptionAdditionalFilter>;
}

export const ConsumptionFilterDescription: React.FC<IConsumptionFilterDescriptionProps> = ({ filter, additionalFilter }) => {
    const elements: React.ReactNode[] = useMemo(() => {
        if (filter == null) {
            return [];
        }
        const result: React.ReactNode[] = [];

        if (!!filter?.consumptionTimestampFrom || !!filter?.consumptionTimestampTo) {
            const repr = `${formatDateToPreview(filter.consumptionTimestampFrom?.split('T')[0]) || '  '} - ${formatDateToPreview(
                filter.consumptionTimestampTo?.split('T')[0],
            ) || '  '}`;
            result.push(repr);
        }

        // if (!!additionalFilter?.consumptionUnits) {
        //     result.push(additionalFilter?.consumptionUnits);
        // }

        return result;
    }, [filter]);

    return <FilterDescription elements={elements} />;
};
