import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch } from '@spp/spp-meru-frontend-common/src/api';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useApi } from '../../../../hooks/use-api';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { IRootState } from '../../../../main';
import { IBusinessPartnerDeliveryPointData } from '../../../../models/model';
import { buildUnitedDeliveryPointsSearchQueryKey } from '../../../../utils/react-query-utils';
import { createIndexedGroups } from '../../../../utils/utils';
import { SelectBusinessPartnerDeliveryPointsFormField } from '../select-business-partner-delivery-points';
import { hasActiveRequest, isEInvoiceActive } from '../utils/einvoice-utils';
import { useFetchMultipleCustomerRequests } from '../utils/use-fetch-multiple-customer-requests';

interface IUnifyEmailSelectDeliveryPointsProps {
    selectedBusinessPartnerId: string | undefined;
    onSelect: (businessPartnerData: IBusinessPartnerDeliveryPointData) => void;
}

const UnifyEmailSelectDeliveryPoints: React.FC<IUnifyEmailSelectDeliveryPointsProps> = ({ selectedBusinessPartnerId, onSelect }) => {
    const { register, handleSubmit, errors } = useForm<{ businessPartnerId: string }>({
        defaultValues: { businessPartnerId: selectedBusinessPartnerId },
    });

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();

    const [groupedDeliveryPoints, setGroupedDeliveryPoints] = useState<Record<string, UnitedDeliveryPointSummary[]>>({});

    const unitedDeliveryPointsSearchObject: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch = {
        deliveryPoint: { hidden: false },
        shared: false,
        pairingDone: true,
        includeInactive: false,
        paging: { page: 0, size: 5000 },
    };
    const {
        isFetching: isFetchingUnitedDeliveryPoints,
        data: unitedDeliveryPointsData,
    } = useQueryWithError<SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, unitedDeliveryPointsSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? null
                : api.customers
                      .searchUnitedDeliveryPoint(loggedInCustomer?.id, unitedDeliveryPointsSearchObject, { fetch: ['E_INVOICE'] }, { secure: true })
                      .then((res) => res.data),
        { staleTime: 30000 },
    );

    const skipSelectionIfSingleBpInList = (grouping: Record<string, UnitedDeliveryPointSummary[]>) => {
        if (Object.keys(grouping).length === 1 && selectedBusinessPartnerId == null) {
            const [, deliveryPoints] = Object.entries(grouping)[0];
            const businessPartner = deliveryPoints[0]?.businessPartner;
            if (businessPartner) {
                onSelect({ businessPartner, unitedDeliveryPoints: deliveryPoints });
            }
        }
    };

    const { isFetching: isFetchingCustomerRequests, data: customerRequestsData } = useFetchMultipleCustomerRequests(unitedDeliveryPointsData?.result);

    useEffect(() => {
        if (unitedDeliveryPointsData?.result == null || customerRequestsData == null) return;
        const indexedGroups = createIndexedGroups<UnitedDeliveryPointSummary>(unitedDeliveryPointsData.result, (udp) => udp.businessPartner?.id);
        // display BPs who have at least one active eInvoice
        const grouping: Record<string, UnitedDeliveryPointSummary[]> = {};
        Object.entries(indexedGroups).forEach(([bpId, udps]) => {
            if (udps.some((udp) => isEInvoiceActive(udp) && !hasActiveRequest(udp.id, customerRequestsData))) {
                grouping[bpId] = udps;
            }
        });

        // if only one BP can be selected - instantly submit first BP
        skipSelectionIfSingleBpInList(grouping);

        setGroupedDeliveryPoints(grouping);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitedDeliveryPointsData, customerRequestsData]);

    const onFormSubmit = handleSubmit((fields) => {
        const businessPartner =
            groupedDeliveryPoints[fields.businessPartnerId] && groupedDeliveryPoints[fields.businessPartnerId][0]?.businessPartner;
        if (businessPartner) {
            onSelect({ businessPartner, unitedDeliveryPoints: groupedDeliveryPoints[fields.businessPartnerId] });
        }
    });

    return (
        <>
            {(isFetchingUnitedDeliveryPoints || isFetchingCustomerRequests) && <LoadingIndicator size="medium" />}

            <p>
                <Trans i18nKey="settings.e-invoices.unify-email.you-will-receive-einvoices-to-single-email" />
            </p>
            <hr className="my-3" />
            <h4 className="mb-3">
                <Trans i18nKey="settings.e-invoices.unify-email.select-delivery-point-for-email-union" />
            </h4>
            <form onSubmit={onFormSubmit}>
                <SelectBusinessPartnerDeliveryPointsFormField
                    groupedDeliveryPoints={groupedDeliveryPoints}
                    register={register}
                    errors={errors}
                    name="businessPartnerId"
                />

                <hr className="my-4" />

                <Button type="submit" color="primary" size="lg" block className="mt-5">
                    <Trans i18nKey="settings.e-invoices.set-united-email" />
                </Button>
            </form>
        </>
    );
};

export default UnifyEmailSelectDeliveryPoints;
