import { SkSppNzpBeApiCustomerrequestCustomerRequestTemplate as CustomerRequestTemplate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { CustomerRequestActions } from '../../../../actions/customer-request-actions';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import useSso from '../../../../hooks/sso/use-sso';
import { useApi } from '../../../../hooks/use-api';
import { IRootState, useUrlQuery } from '../../../../main';
import { CustomerRequestRoutes } from '../../../../routes/routes';
import PrepareHeader from '../prepare-customer-request/components/prepare-header';
import StepsBlock from '../steps-customer-request/components/steps-block';

const LoginOnStartRequest: React.FC = () => {
    const history = useHistory();
    const api = useApi();
    const dispatch = useDispatch();
    const { key, code } = useSelector((state: IRootState) => state.customerRequest);
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const { ssoLogin } = useSso();
    const urlQuery = useUrlQuery();

    const requestCode = urlQuery.get('code') || code;
    const requestKey = urlQuery.get('key') || key;

    const { t } = useTranslation();

    const fetchCustomerRequestTemplate = useCallback(
        (code) => async () =>
            await api.customerRequestTemplates
                .searchTemplates({ codes: [code] }, { secure: true })
                .then((res) => res.data?.result && res.data?.result[0]),
        [api.customerRequestTemplates],
    );

    const { data: customerRequestTemplate, isLoading } = useQuery<CustomerRequestTemplate>({
        queryKey: ['customer-request-tempaltes', [requestCode]],
        queryFn: requestCode && fetchCustomerRequestTemplate(requestCode),
    });

    useEffect(() => {
        if (!requestCode) {
            history.replace(CustomerRequestRoutes.SELECT_NEW);
        }
    }, [requestCode, history]);

    useEffect(() => {
        if (customerRequestTemplate) {
            dispatch(CustomerRequestActions.setCustomerRequestTemplate(customerRequestTemplate));
            loggedInCustomer &&
                nextTick(() => {
                    history.push(`${CustomerRequestRoutes.NEW_REQUEST}/${requestKey}`);
                });
        }
    }, [customerRequestTemplate, dispatch, loggedInCustomer, history, requestKey]);

    return (
        <>
            {isLoading && <LoadingIndicator fullscreen />}

            <PrepareHeader code={requestCode} goBack={() => history.replace(CustomerRequestRoutes.SELECT_NEW)} />

            <Container>
                <StepsBlock>
                    <h3 className="text-center text-lg mt-2 mt-lg-5">{t(`customer-request.steps.block-login.title`)}</h3>
                    <div className="text-center m-auto" style={{ width: 350 }}>
                        <p className="text-lg mb-3 ">{t(`customer-request.steps.block-login.subtitle`)}</p>
                        <button
                            className="btn btn-lg btn-primary mt-3"
                            style={{ width: 280 }}
                            onClick={() => ssoLogin({ backUrl: `${CustomerRequestRoutes.LOGIN}?code=${requestCode}&key=${key}` })}
                        >
                            <Trans i18nKey="customer-request.prepare.sign-in-to-spp">Prihlásiť sa do Moje SPP</Trans>
                        </button>
                    </div>
                    <hr className="text-center w-25 my-4" />
                    <h3 className="text-center text-lg">{t('customer-request.steps.block-login.do-not-have-account-yet')}</h3>
                    <div className="text-center m-auto" style={{ width: 350 }}>
                        <p className="text-lg mb-4">{t(`customer-request.steps.block-login.you-can-register-in-next-steps`)}</p>
                        <div className="m-auto skip-login-button" onClick={() => history.push(`${CustomerRequestRoutes.NEW_REQUEST}/${key}`)}>
                            {t('customer-request.steps.block-login.continue-without-sign-in')}
                        </div>
                    </div>
                </StepsBlock>
            </Container>
        </>
    );
};

export default LoginOnStartRequest;
