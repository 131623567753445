import classNames from 'classnames';
import React from 'react';
import { ISwitchButton } from '../../models/model';

interface IBaseSwitchProps {
    selectedValue: string;
    onValueSelect: (value: string) => void;
    dataButtons: ISwitchButton[];
    className?: string;
}

const BaseSwitch: React.FC<IBaseSwitchProps> = ({ selectedValue, onValueSelect, dataButtons, className }) => {
    const onButtonClick = (value: string) => {
        onValueSelect(value);
    };

    const createButton = (title: string | JSX.Element, value: string, disabled?: boolean, className?: string) => {
        return (
            <button
                type="button"
                key={value}
                disabled={disabled ? true : false}
                className={classNames('btn', [className], { active: value === selectedValue })}
                onClick={() => onButtonClick(value)}
            >
                {title}
            </button>
        );
    };

    return (
        <div className={`btn-group ${className ?? 'btn-block my-4 mt-lg-0'}`} role="group">
            {dataButtons.map((value: ISwitchButton) => createButton(value.title, value.id, value.disabled, value.className))}
        </div>
    );
};

export default BaseSwitch;
