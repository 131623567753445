import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerrequestCustomerRequestSummary } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { LoadingIndicator } from '../../../../../components/common';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { buildDeliveryPointQueryKey, QueryKeysEnum, useApi, useReloadQueryCache } from '../../../../../main';
import { ChangeFormStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import ChangeRequestSent from '../change-request-sent';
import ChangeEInvoiceEmailForm from '../forms/change-einvoice-email';

interface IChangeEInvoiceEmailModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    unitedDeliveryPointId?: string;
}

export const ChangeEInvoiceEmailModal: React.FC<IChangeEInvoiceEmailModalProps> = ({ isOpen, onClose, deliveryPoint }) => {
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [newData, setNewData] = useState<string | undefined>();

    const contractAccount = deliveryPoint.contract?.contractAccount;

    useEffect(() => {
        if (isOpen) {
            setStep(ChangeFormStepEnum.EDITING);
            setNewData(undefined);
        }
    }, [isOpen]);

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const reloadQueryCache = useReloadQueryCache();

    const api = useApi();
    const [mutateEmailChange, { isLoading: isLoadingEmailChange }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary[] | null,
        unknown,
        { email: string }
    >(async ({ email }) => {
        const contractId = deliveryPoint.contract?.id;
        if (contractId != null) {
            return api.deliveryPoints.updateEInvoiceEmailByContract(contractId, { email: email }, { secure: true }).then((res) => res.data);
        }
        return null;
    });

    const createRequestForEmailChange = async (newEmail: string) => {
        mutateEmailChange(
            { email: newEmail },
            {
                onSuccess: () => {
                    reloadQueryCache(buildDeliveryPointQueryKey(deliveryPoint.id));
                    reloadQueryCache(QueryKeysEnum.DELIVERY_POINT_REQUESTS_SEARCH);
                    reloadQueryCache(QueryKeysEnum.DELIVERY_POINT);
                    goToNextStep();
                },
            },
        );
    };

    const onFormSubmit = (email: string) => {
        if (email != null) {
            createRequestForEmailChange(email);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="delivery-point.detail.contact-data.change-email-einvoice-notification">Zmena emailu pre eFaktúru</Trans>
                    </h3>

                    {step === ChangeFormStepEnum.EDITING && (
                        <ChangeEInvoiceEmailForm
                            currentValue={contractAccount?.email || ''}
                            editedValue={newData}
                            onSubmit={onFormSubmit}
                            onReset={onClose}
                        />
                    )}

                    {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onClose} />}

                    {isLoadingEmailChange && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
