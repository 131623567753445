import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerrequestCustomerRequestSummary as PagedResponseCustomerRequestSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery as CustomerRequestSearchQuery,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary as CustomerRequest,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { CustomerRequestActions } from '../../../../actions/customer-request-actions';
import Checkbox from '../../../../components/common/checkbox';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import PageableTable from '../../../../components/common/table/pageable-table';
import TableFilterControl from '../../../../components/common/table/table-filter-control';
import { IColumnConfiguration } from '../../../../components/common/table/table-interfaces';
import { useApi } from '../../../../hooks/use-api';
import useCodeList from '../../../../hooks/use-code-list';
import { useKeyValueStore } from '../../../../hooks/use-key-value';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { useUrlQuery } from '../../../../hooks/use-url-query';
import { CustomerRequestStatusEnum, CustomerRequestStatusFilterEnum } from '../../../../models/enums';
import { IRootState } from '../../../../reducers';
import { CustomerRequestRoutes } from '../../../../routes/routes';
import { QueryKeysEnum } from '../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../utils/utils';
import { customerRequestColumnConfigurations } from '../../components/customer-request-column-configuration';
import { CodeListTypeEnum } from '../../config/enums';
import CustomerRequestFilterModal from '../../modal/customer-request-filter-modal';
import { CustomerRequestsFilterDescription } from '../delivery-point-customer-requests/customer-requests-filter-description';
import CustomerRequestHeader from './components/customer-request-header';

export const statusesOfOpenRequest = [
    CustomerRequestStatusEnum.PRE_CREATED,
    CustomerRequestStatusEnum.CREATED,
    CustomerRequestStatusEnum.REGISTERED,
    CustomerRequestStatusEnum.SAP_OPEN,
    CustomerRequestStatusEnum.SAP_IN_PROGRESS,
    CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS,
];

export const statusesOfAllRequests = [
    CustomerRequestStatusEnum.PRE_CREATED,
    CustomerRequestStatusEnum.CREATED,
    CustomerRequestStatusEnum.REGISTERED,
    CustomerRequestStatusEnum.GENERATED,
    CustomerRequestStatusEnum.CANCELLED_BY_USER,
    CustomerRequestStatusEnum.SAP_OPEN,
    CustomerRequestStatusEnum.SAP_IN_PROGRESS,
    CustomerRequestStatusEnum.SAP_CANCELLED,
    CustomerRequestStatusEnum.SAP_CANCELLED_BY_USER,
    CustomerRequestStatusEnum.SAP_FINISHED,
    CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS,
    CustomerRequestStatusEnum.COMPLETION_REJECTED,
    CustomerRequestStatusEnum.EXPIRED,
];

const MyListCustomerRequest: React.FC = () => {
    const pageSize = 10;

    const history = useHistory();
    const location = useLocation<{ page: number }>();
    const urlQuery = useUrlQuery();

    const { t, i18n } = useTranslation();
    const api = useApi();
    const dispatch = useDispatch();

    const { customer } = useSelector((state: IRootState) => state.user);

    const [filter, setFilter] = useKeyValueStore<Partial<CustomerRequestSearchQuery>>('my-list-customer-table-filter', {});
    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
    const [columnConfigurations, setColumnConfigurations] = useState<IColumnConfiguration<CustomerRequest>[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [onlyOpen, setOnlyOpen] = useKeyValueStore<boolean>('request-table-additional-filter', false);
    const [, setStoredPageIndex] = useKeyValueStore(CustomerRequestRoutes.MY_LIST + '@pageIndex', 0);

    const queriedPage = location.state?.page ?? (Number(urlQuery.get('page')) || undefined);

    const fetchCustomerRequests = useCallback(
        async (uuid, data: CustomerRequestSearchQuery = {}) => {
            const status = data.statuses?.slice() || [];
            data.statuses?.forEach((item) => {
                if (
                    item === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
                    item === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
                    item === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
                ) {
                    status.push(CustomerRequestStatusFilterEnum.SAP_CANCELLED);
                    status.push(CustomerRequestStatusFilterEnum.CANCELLED_BY_USER);
                    status.push(CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER);
                }
                if (
                    item === CustomerRequestStatusFilterEnum.CREATED ||
                    item === CustomerRequestStatusFilterEnum.REGISTERED ||
                    item === CustomerRequestStatusFilterEnum.SAP_OPEN
                ) {
                    status.push(CustomerRequestStatusFilterEnum.CREATED);
                    status.push(CustomerRequestStatusFilterEnum.REGISTERED);
                    status.push(CustomerRequestStatusFilterEnum.SAP_OPEN);
                }
            });
            const uniqueStatus = [...new Set(status)];
            data.statuses = uniqueStatus;

            if (onlyOpen && isObjectEmpty(filter)) {
                data.statuses = statusesOfOpenRequest;
            }
            if (data.statuses == null || data.statuses.length === 0) {
                data.statuses = statusesOfAllRequests;
            }
            return api.customers.customerSearchCustomerRequests(uuid, data, undefined, { secure: true });
        },
        [api.customers, onlyOpen, filter],
    );

    const fetchOpenCustomerRequests = useCallback(
        async (uuid) => {
            return api.customers.customerSearchCustomerRequests(uuid, { statuses: statusesOfOpenRequest }, undefined, { secure: true });
        },
        [api.customers],
    );

    const { data: statusCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_CUSTOMER_REQUEST_STATUS,
        codeListTypeEnum: CodeListTypeEnum.CUSTOMER_REQUEST_STATUS,
        paging: {
            size: 20,
        },
    });

    const { data: deliveryPointsData } = useQueryWithError<UnitedDeliveryPointSummary[] | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH, customer?.id],
        async () =>
            customer?.id
                ? api.customers
                      .searchUnitedDeliveryPoint(
                          customer.id,
                          {
                              deliveryPoint: { hidden: false },
                              includeInactive: false,
                              paging: { size: 5000, sort: [{ attribute: 'street', direction: 'ASC' }] },
                          },
                          undefined,
                          { secure: true },
                      )
                      .then((res) => res.data?.result ?? null)
                : null,
    );

    useEffect(() => {
        dispatch(CustomerRequestActions.clearAdditionalData());
    }, [dispatch]);

    const { isLoading, resolvedData } = usePaginatedQuery<PagedResponseCustomerRequestSummary>({
        queryKey: [QueryKeysEnum.CUSTOMER_REQUESTS_SEARCH, filter, pageIndex, i18n.language, onlyOpen],
        queryFn: () =>
            fetchCustomerRequests(customer?.id, { ...filter, paging: { page: pageIndex, size: pageSize } }).then((response) => response.data),
    });

    const { resolvedData: openCustomerRequests } = usePaginatedQuery<PagedResponseCustomerRequestSummary>({
        queryKey: [QueryKeysEnum.CUSTOMER_OPEN_REQUESTS_SEARCH],
        queryFn: () => fetchOpenCustomerRequests(customer?.id).then((response) => response.data),
    });

    useEffect(() => {
        setColumnConfigurations(customerRequestColumnConfigurations());
    }, [t]);

    const openRequestDetail = (requestId: string) => {
        history.push({ pathname: `${CustomerRequestRoutes.MY_LIST}/${requestId}`, state: { page: queriedPage } });
    };

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    return (
        <>
            <CustomerRequestHeader />
            {isLoading ? (
                <LoadingIndicator size="large" />
            ) : (
                <Container>
                    <Row className="align-items-center">
                        <Col xs={6}>
                            {isObjectEmpty(filter) && (
                                <Checkbox
                                    checked={onlyOpen}
                                    onChange={() => setOnlyOpen(!onlyOpen)}
                                    id="onlyOpen"
                                    name="onlyOpen"
                                    className=""
                                    label={t('customer-request.only-open', { count: openCustomerRequests?.paging?.total })}
                                />
                            )}
                        </Col>
                        <Col xs={{ size: isObjectEmpty(filter) ? 6 : 12 }}>
                            <TableFilterControl
                                isFilterActive={!isObjectEmpty(filter)}
                                onEditFilter={() => setIsFilterModalOpen(true)}
                                onRemoveFilter={() => {
                                    setFilter({});
                                    onPageChange(0);
                                }}
                                filterDescription={
                                    <CustomerRequestsFilterDescription
                                        filter={filter}
                                        deliveryPoints={deliveryPointsData || undefined}
                                        statusCodeList={statusCodeList || []}
                                    />
                                }
                            />
                        </Col>
                    </Row>

                    <PageableTable<CustomerRequest>
                        data={resolvedData?.result ? resolvedData.result : []}
                        columnConfigurations={columnConfigurations}
                        page={pageIndex}
                        pageSize={pageSize}
                        totalDataCount={resolvedData?.paging?.total}
                        onPageChange={onPageChange}
                        onRowSelect={(item) => openRequestDetail(item.uuid ?? '')}
                        collapseIntoCards
                        tableLayoutFixed
                    />
                </Container>
            )}

            <CustomerRequestFilterModal
                statusCodeList={statusCodeList ?? []}
                deliveryPoints={deliveryPointsData || undefined}
                extra
                isOpen={isFilterModalOpen}
                onCloseModal={() => setIsFilterModalOpen(false)}
                filter={filter}
                onFilterChange={(newFilter) => {
                    setFilter(newFilter);
                    onPageChange(0);
                }}
            />
        </>
    );
};

export default MyListCustomerRequest;
