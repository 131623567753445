import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, CardHeader, Col, Container, Row } from 'reactstrap';
import LoadingIndicator from '../../components/common/loading-indicator';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../hooks/use-query-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import { IMeterReadingWithDpId } from '../../models/customer-request-model';
import { CommodityEnum } from '../../models/enums';
import { IApiResponse } from '../../models/model';
import { CustomerRequestPayloadType } from '../../reducers/interfaces/customer-request-state';
import Routes from '../../routes/routes';
import { QueryKeysEnum } from '../../utils/react-query-utils';
import { formatAddressShort } from '../../utils/utils';
import { CustomerRequestEnum } from '../customer-request/config/enums';
import { useOpenRequestFromDeliveryPointDetail } from '../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';
import { Stepper } from './components/stepper';

export const CustomerRequestTranscriptNewCustomer: React.FC = () => {
    const urlQuery = useUrlQuery();
    const uuid = urlQuery.get('customerRequestId');
    const history = useHistory();

    const [deliveryPoints, setdDeliveryPoints] = useState<any>([]);
    const [preparingRequest, setPreparingRequest] = useState(false);
    const [requestNotFound, setRequestNotFound] = useState(false);

    const { isLoading, data: customerRequestData } = useQueryWithError<any | null>(
        [QueryKeysEnum.CUSTOMER_REQUEST, uuid],
        async () => {
            if (uuid == null) return null;
            return api.customerRequests.getCustomerRequest(uuid, { secure: true }).then((res) => res.data);
        },
        {
            onError: (err) => {
                if (err?.error?.code === 403 || err?.error?.code === 404) {
                    setRequestNotFound(true);
                    return true;
                }
                return false;
            },
            enabled: uuid != null,
        },
    );

    useEffect(() => {
        if (!uuid) {
            history.push(`${Routes.DELIVERY_POINTS}`);
        }
    }, [uuid, history]);

    useEffect(() => {
        if (!isLoading && customerRequestData) {
            const ZPs =
                customerRequestData.content.deliveryPointsZp.map((dp: any) => ({
                    ...dp.deliveryPointZp,
                    type: CommodityEnum.GAS,
                    contract: { id: dp.contractId },
                    meterReading: dp.meterReadingZp,
                })) ?? [];
            const EEs =
                customerRequestData.content.deliveryPointsEe.map((dp: any) => ({
                    ...dp.deliveryPointEe,
                    type: CommodityEnum.ELECTRICITY,
                    contract: { id: dp.contractId },
                    meterReading: dp.meterReadingEe,
                })) ?? [];

            setdDeliveryPoints([...ZPs, ...EEs]);
        }
    }, [customerRequestData, isLoading]);

    const api = useApi();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum, content: CustomerRequestPayloadType) => {
        initializeRequestCreationProcess(requestName, {}, content);
    };

    const [mutateDeliveryPointByContractId] = useMutationWithError<
        SkSppNzpBeApiCustomerprofileDeliveryPoint | null,
        IApiResponse,
        { contractId: string; requestId?: string }
    >(async ({ contractId, requestId }) =>
        api.deliveryPoints.getByContractUuid(contractId, { aet: `dpbcrczomp:${requestId}` }, { secure: true }).then((res) => res.data),
    );

    const startRequest = async () => {
        setPreparingRequest(true);
        const meterReadings: IMeterReadingWithDpId[] = [];
        if (uuid) {
            const deliveryPointsZP = await Promise.all(
                deliveryPoints
                    ?.filter((dp: any) => dp.type === CommodityEnum.GAS)
                    .map(async (dp: any) => {
                        const deliveryPoint = await mutateDeliveryPointByContractId({ contractId: dp.contract.id, requestId: uuid });
                        meterReadings.push({ ...dp.meterReading, deliveryPointId: deliveryPoint?.id });
                        return deliveryPoint;
                    }),
            );

            const deliveryPointsEE = await Promise.all(
                deliveryPoints
                    ?.filter((dp: any) => dp.type === CommodityEnum.ELECTRICITY)
                    .map(async (dp: any) => {
                        const deliveryPoint = await mutateDeliveryPointByContractId({ contractId: dp.contract.id, requestId: uuid });
                        meterReadings.push({ ...dp.meterReading, deliveryPointId: deliveryPoint?.id });
                        return deliveryPoint;
                    }),
            );
            if (deliveryPointsZP.length > 0 || deliveryPointsEE.length > 0) {
                setPreparingRequest(false);
                startRequestInRequestsTab(CustomerRequestEnum.TRANSCRIPT_NEW_CUSTOMER, {
                    deliveryPointsEE: deliveryPointsEE as SkSppNzpBeApiCustomerprofileDeliveryPoint[],
                    deliveryPointsZP: deliveryPointsZP as SkSppNzpBeApiCustomerprofileDeliveryPoint[],
                    transcriptRequestId: uuid || undefined,
                    meterReadings: meterReadings as IMeterReadingWithDpId[],
                    originalCustomerBusinessPartner: customerRequestData?.businessPartner,
                });
            }
        }
    };

    return (
        <>
            {(isLoading || preparingRequest) && <LoadingIndicator />}
            <div className="page-header pt-4 pb-md-3 mb-4">
                <Container className="my-3">
                    <Row>
                        <Col>
                            <h3>{t('customer-request.transcript-new-customer-introduction-page.transcript-dp')}</h3>
                            {customerRequestData?.completionView === 'COMPLETION' && customerRequestData?.status?.code === 'COMPLETION_IN_PROGRESS' && (
                                <p style={{ fontSize: 16 }}>
                                    {t('customer-request.transcript-new-customer-introduction-page.waiting-request')}
                                    <br />
                                    <b>
                                        {customerRequestData?.completionValidTo &&
                                            t('customer-request.transcript-new-customer-introduction-page.need-to-be-done-due', {
                                                date: moment(customerRequestData.completionValidTo).format('DD.MM.YYYY'),
                                            })}
                                    </b>
                                </p>
                            )}
                        </Col>
                        {customerRequestData?.completionView === 'COMPLETION' &&
                            customerRequestData?.status?.code === 'COMPLETION_IN_PROGRESS' &&
                            customerRequestData?.completionValidTo &&
                            new Date(customerRequestData?.completionValidTo).getTime() > new Date().getTime() && (
                                <Col className="text-right">
                                    <Button color="primary" className="btn-block btn-md-inline" onClick={startRequest}>
                                        {t('customer-request.transcript-new-customer-introduction-page.fill-request')}
                                    </Button>
                                </Col>
                            )}
                    </Row>
                </Container>
            </div>
            {customerRequestData && (
                <Container>
                    {customerRequestData?.completionValidTo && new Date(customerRequestData?.completionValidTo).getTime() < new Date().getTime() ? (
                        <div className="container" style={{ position: 'relative' }}>
                            <div className="alert p-4 d-flex mt-5" style={{ backgroundColor: '#dddbdb' }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                                <div className="ml-4 font-weight-bold">{t('customer-request.transcript-new-customer-introduction-page.expired')}</div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {customerRequestData?.status?.code === 'COMPLETION_REJECTED' && (
                                <div className="container" style={{ position: 'relative' }}>
                                    <div className="alert p-4 d-flex bg-danger text-white mt-5">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                                        <div className="ml-4 font-weight-bold">
                                            {customerRequestData?.completionView === 'COMPLETION' ? (
                                                <Trans i18nKey="customer-request.transcript-new-customer-introduction-page.rejected-request">
                                                    Žiadosť ste zamietli pre nesprávny stav meradla/ meradiel
                                                </Trans>
                                            ) : (
                                                <Trans i18nKey="customer-request.transcript-new-customer-introduction-page.rejected-request-by-completitor">
                                                    Žiadosť bola zamietnutá novým odberateľom pre nesprávny stav meradla/ meradiel
                                                </Trans>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {customerRequestData?.status?.code === 'CANCELLED_BY_USER' && (
                                <div className="container" style={{ position: 'relative' }}>
                                    <div className="alert p-4 d-flex bg-warning text-white mt-5">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                                        <div className="ml-4 font-weight-bold">
                                            <Trans i18nKey="customer-request.transcript-new-customer-introduction-page.canceled-request">
                                                Prepis nie je možné zrealizovať. Žiadosť bola zrušená.
                                            </Trans>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(customerRequestData?.status?.code === 'REGISTERED' || customerRequestData?.status?.code === 'CREATED') && (
                                <div className="container" style={{ position: 'relative' }}>
                                    <div className="alert p-4 d-flex bg-success text-white mt-5">
                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                        <div className="ml-4 font-weight-bold">
                                            <Trans i18nKey="customer-request.message.request-been-sent">Žiadosť bola odoslaná</Trans>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <p>
                                {t('customer-request.transcript-new-customer-introduction-page.dps-for-transcript', { count: deliveryPoints.length })}
                            </p>

                            {deliveryPoints?.map((dp: any) => (
                                <CardHeader
                                    key={dp.id}
                                    className="border-bottom-0 rounded mb-4 p-4"
                                    style={{ backgroundColor: 'white', maxWidth: 450 }}
                                >
                                    <b>
                                        {formatAddressShort({ street: dp.address?.street, streetNumber: dp.address?.number })} -{' '}
                                        {dp.type === CommodityEnum.GAS ? t('enums.CommodityEnum.ZP') : t('enums.CommodityEnum.EE')}
                                    </b>
                                    <br />
                                    {dp.address?.city}, {dp.address?.postalCode}
                                    <br />
                                    {dp.address?.country?.name}
                                    <p className="mt-1 mb-0">
                                        <small>
                                            {dp.pod && (
                                                <>{`${t('customer-request.transcript-new-customer-introduction-page.device-number-zp')}: ${
                                                    dp.pod
                                                }`}</>
                                            )}
                                            {dp.eic && (
                                                <>{`${t('customer-request.transcript-new-customer-introduction-page.device-number')}: ${dp.eic}`}</>
                                            )}
                                        </small>
                                    </p>
                                </CardHeader>
                            ))}
                            <div className="my-5 my-md-4">
                                <p>{t('customer-request.transcript-new-customer-introduction-page.request-state')}</p>
                                <Stepper
                                    date={moment(customerRequestData.completionValidTo).format('DD.MM.YYYY')}
                                    isRequestFinished={
                                        customerRequestData?.status?.code === 'REGISTERED' || customerRequestData?.status?.code === 'CREATED'
                                    }
                                />
                            </div>
                            {customerRequestData?.completionView === 'COMPLETION' &&
                                customerRequestData?.status?.code === 'COMPLETION_IN_PROGRESS' &&
                                customerRequestData?.completionValidTo &&
                                new Date(customerRequestData?.completionValidTo).getTime() > new Date().getTime() && (
                                    <div className="text-center my-4">
                                        <Button color="primary" type="button" className="btn-lg" onClick={startRequest}>
                                            {t('customer-request.transcript-new-customer-introduction-page.fill-request')}
                                        </Button>
                                    </div>
                                )}
                        </>
                    )}
                </Container>
            )}
            {requestNotFound && (
                <div className="container" style={{ position: 'relative' }}>
                    <div className="alert p-4 d-flex mt-5" style={{ backgroundColor: '#dddbdb' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                        <div className="my-auto ml-4 font-weight-bold">{t('common.error-request-wrong-user')}</div>
                    </div>
                </div>
            )}
        </>
    );
};
