import React, { ReactElement } from 'react';
import { formatCurrency } from '../../../../main';
interface IDataRow {
    label?: string | ReactElement;
    // value: string | number | undefined | null;
    value: string | number | undefined | null | object;
    price?: number;
    priceComponent?: JSX.Element | undefined;
    invoiceNumber?: string;
    isCredit?: boolean;
}

const DataRow = ({ label, value, price, priceComponent, invoiceNumber, isCredit }: IDataRow): ReactElement => {
    return (
        <li className="list-group-item">
            <div className="row align-items-center">
                <div className="col">
                    <small className="text-secondary">{label}</small>
                    <br />
                    {value}
                    {invoiceNumber && (
                        <small className="text-secondary">
                            <br />
                            {invoiceNumber}
                        </small>
                    )}
                </div>
                {!!priceComponent ? priceComponent : price !== undefined && <div className="col-auto">{formatCurrency(price, isCredit)}</div>}
            </div>
        </li>
    );
};

export default DataRow;
