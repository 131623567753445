import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React from 'react';
import { DeliveryPointSwitchLabel } from './delivery-point-switch-label';

interface IDeliveryPointSwitchButtonProps {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    isSelected: boolean;
    onClick: (id: string) => void;
    showInvoiceNumber?: boolean;
}

export const DeliveryPointSwitchButton: React.FC<IDeliveryPointSwitchButtonProps> = ({ deliveryPoint, isSelected, onClick, showInvoiceNumber }) => {
    return (
        <>
            <button
                onClick={() => deliveryPoint.id && onClick(deliveryPoint.id)}
                className={classNames('btn text-left', 'delivery-point-switch-button', { active: isSelected })}
            >
                <DeliveryPointSwitchLabel deliveryPoint={deliveryPoint} showInvoiceNumber={showInvoiceNumber} />
            </button>
        </>
    );
};
