import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { UserActions } from '../../../actions/user';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../main';
import { IRootState } from '../../../reducers';
import { ClickableElement } from '../clickable-element';
import Input from '../input';
import LoadingIndicator from '../loading-indicator';

interface IVerifyPhoneNumber {
    phone: string;
    phoneChallengeCode: string;
    onSuccess: () => void;
    onError?: () => void;
}

interface IPhoneChangeChallengeData {
    phone: string;
    smsCode: string;
}

const VerifyPhoneNumber: React.FC<IVerifyPhoneNumber> = ({ phone, phoneChallengeCode, onSuccess }) => {
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);

    const { register, handleSubmit, errors, setError, trigger } = useForm<IPhoneChangeChallengeData>();
    const { requiredTrimed } = useFormRules();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const api = useApi();
    const dispatch = useDispatch();

    const requestPhoneVerification = useCallback(
        async ({ smsCode }: { smsCode: string }) =>
            loggedInCustomer?.id
                ? api.customers
                      .requestPhoneChallenge(
                          loggedInCustomer.id,
                          {
                              code: smsCode,
                              uuid: phoneChallengeCode,
                          },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
        [loggedInCustomer, api, phoneChallengeCode],
    );

    const [mutateVerifyPhoneRequest, { isLoading }] = useMutationWithError(requestPhoneVerification, {
        onSuccess: () => {
            dispatch(UserActions.setTelephoneNumber(phone));
            onSuccess();
        },
        onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
            if (response.error?.code === 2006) {
                addToast(t('phone-validation.reached-change-request-limit'), {
                    appearance: 'error',
                });
                return true;
            } else if (response.error?.code === 409) {
                addToast(t('phone-validation.phone-number-already-exists'), {
                    appearance: 'error',
                });
                return true;
            } else if (response?.error?.code === 2005) {
                setError('smsCode', {
                    type: 'incorrect-sms-code',
                    message: t('common.input-rules.incorrect-sms-code'),
                    shouldFocus: true,
                });
                return true;
            }
            return false;
        },
    });

    const onSubmit = (formData: IPhoneChangeChallengeData) => {
        const cleanSMSCode = formData.smsCode.replace(/\b/g, '');
        mutateVerifyPhoneRequest({ smsCode: cleanSMSCode });
    };

    const [mutateEnterPhoneRequest, { isLoading: isLoadingResend }] = useMutationWithError(
        async (challengeId: string) =>
            loggedInCustomer?.id == null
                ? null
                : api.customers.resendPhoneChallenge(loggedInCustomer.id, { challengeId }, { secure: true }).then((res) => res.data),
        {
            onSuccess: () => {
                addToast(t('phone-validation.sms-sent'), {
                    appearance: 'success',
                });
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                if (response.error?.code === 2006) {
                    addToast(t('phone-validation.reached-sms-request-limit'), {
                        appearance: 'error',
                    });
                    return true;
                }
                return false;
            },
        },
    );
    const resendPhoneChangeRequest = () => mutateEnterPhoneRequest(phoneChallengeCode);

    return (
        <form className="my-3" onSubmit={handleSubmit(onSubmit)} noValidate>
            {(isLoading || isLoadingResend) && <LoadingIndicator />}
            <Input
                ref={register({ ...requiredTrimed })}
                errors={errors}
                type="text"
                name="phone"
                value={phone}
                label={t('phone-validation.your-phone-number')}
                readOnly
                trigger={trigger}
                autoFocus
                autoFocusDelay={550} // fix for tooltip placement during modal opening
            />
            <Input
                ref={register({ ...requiredTrimed })}
                errors={errors}
                trigger={trigger}
                type="text"
                name="smsCode"
                label={t('phone-validation.enter-sms-code')}
            />

            <button type="submit" className="btn btn-primary btn-block btn-lg">
                <Trans i18nKey="common.confirm">Potvrdiť</Trans>
            </button>

            <div className="text-center mt-5">
                <Trans i18nKey="phone-validation.sms-not-received">Neprišiel vám SMS kód?</Trans>
                <br />
                <ClickableElement onClick={resendPhoneChangeRequest} isText>
                    <Trans i18nKey="phone-validation.resend-sms-code">Poslať znova</Trans>
                </ClickableElement>
            </div>
        </form>
    );
};

export default VerifyPhoneNumber;
