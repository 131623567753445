import { SkSppNzpBeApiCustomerprofileBusinessPartnerSummary } from '@spp/spp-meru-frontend-common';

export enum AddDeliveryPointScreen {
    CHOOSE_EXISTING_OR_NEW = 'CHOOSE_EXISTING_OR_NEW',
    VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER',
    PHONE_NUMBER_IS_NEEDED = 'PHONE_NUMBER_IS_NEEDED',
    CHOOSE_PERSON_OR_COMPANY = 'CHOOSE_PERSON_OR_COMPANY',
    ENTER_PERSON_DATA = 'ENTER_PERSON_DATA',
    ENTER_COMPANY_DATA = 'ENTER_COMPANY_DATA',
    ENTERED_DATA = 'ENTERED_DATA',
    CHOOSE_COMMODITY = 'CHOOSE_COMMODITY',
    BACK = 'BACK',
    ERROR = 'ERROR',
}

export const AddDeliveryPointBackScreenMap = new Map<AddDeliveryPointScreen, AddDeliveryPointScreen>([
    [AddDeliveryPointScreen.VERIFY_PHONE_NUMBER, AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY],
    [AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY, AddDeliveryPointScreen.CHOOSE_EXISTING_OR_NEW],
    [AddDeliveryPointScreen.ENTER_PERSON_DATA, AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY],
    [AddDeliveryPointScreen.ENTER_COMPANY_DATA, AddDeliveryPointScreen.CHOOSE_PERSON_OR_COMPANY],
    [AddDeliveryPointScreen.CHOOSE_COMMODITY, AddDeliveryPointScreen.CHOOSE_EXISTING_OR_NEW],
    [AddDeliveryPointScreen.ERROR, AddDeliveryPointScreen.CHOOSE_EXISTING_OR_NEW],
]);

export type PersonData = {
    customerNumber: string; // Číslo zákazníka
    contractNumber: string; // Čislo zmluvy
    additionalPhone: string; // Doplnkové telefónne číslo
};

export type CompanyData = {
    companyId: string; // IČO
    customerNumber?: string; // Číslo zákazníka
    contractNumber?: string; // Čislo zmluvy
    additionalPhone: string; // Doplnkové telefónne číslo
};

export enum CommodityChoice {
    GAS = 'GAS',
    ELECTRICITY = 'ELECTRICITY',
    GAS_AND_ELECTRICITY = 'GAS_AND_ELECTRICITY',
}

export type AddDeliveryPointState = {
    screen?: AddDeliveryPointScreen;
    isOpen?: boolean;
    phoneNumber?: string;
    commodity?: CommodityChoice;
    personData?: any;
    companyData?: any;
    errorMsg?: string;
    phoneChallengeCode?: string;
    approvalRequired?: boolean;
    onlyLoad?: boolean;
    lastThreePhoneDigits?: string;
    pairedBusinessPartner?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    emailSyncRequested?: boolean;
};

export enum AddDeliveryPointActionType {
    START = 'addDeliveryPoint/START',
    CANCEL = 'addDeliveryPoint/CANCEL',
    FINISHED = 'addDeliveryPoint/FINISHED',
    CHOOSE_ADDING_EXISTING_DP = 'addDeliveryPoint/CHOOSE_ADDING_EXISTING_DP',
    CHOOSE_ADDING_NEW_DP = 'addDeliveryPoint/CHOOSE_ADDING_NEW_DP',
    CHOOSE_NO_PHONE_NUMBER = 'addDeliveryPoint/CHOOSE_NO_PHONE_NUMBER',
    ENTER_PHONE_NUMBER = 'addDeliveryPoint/ENTER_PHONE_NUMBER',
    SEND_SMS_AGAIN = 'addDeliveryPoint/SEND_SMS_AGAIN',
    SUCCESS_PAIRING = 'addDeliveryPoint/SUCCESS_PAIRING',
    CHOOSE_PERSON = 'addDeliveryPoint/CHOOSE_PERSON',
    CHOOSE_COMPANY = 'addDeliveryPoint/CHOOSE_COMPANY',
    ENTER_PERSON_DATA = 'addDeliveryPoint/ENTER_PERSON_DATA',
    ENTER_COMPANY_DATA = 'addDeliveryPoint/ENTER_COMPANY_DATA',
    ENTER_COMMODITY = 'addDeliveryPoint/ENTER_COMMODITY',
    SET_PAIRED_BUSINESS_PARTNER = 'addDeliveryPoint/SET_PAIRED_BUSINESS_PARTNER',
    SET_EMAIL_SYNC_REQUESTED = 'addDeliveryPoint/SET_EMAIL_SYNC_REQUESTED',
    BACK = 'addDeliveryPoint/BACK',
    ERROR = 'addDeliveryPoint/ERROR',
}

export type AddDeliveryPointAction = {
    type: AddDeliveryPointActionType;
    onlyLoad?: boolean;
    phoneNumber?: string;
    smsCode?: string;
    commodity?: CommodityChoice;
    personData?: PersonData;
    companyData?: CompanyData;
    errorMsg?: string;
    phoneChallengeCode?: string;
    approvalRequired?: boolean;
    lastThreePhoneDigits?: string;
    pairedBusinessPartner?: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
};

export const AddDeliveryPointActions = {
    start: (onlyLoad: boolean): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.START,
        onlyLoad,
    }),

    cancel: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.CANCEL,
    }),

    finished: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.FINISHED,
    }),

    proceedWithExistingDeliveryPoint: (phoneNumber?: string): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.CHOOSE_ADDING_EXISTING_DP,
        phoneNumber,
    }),

    proceedWithNewDeliveryPoint: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.CHOOSE_ADDING_NEW_DP,
    }),

    sucessPairing: (approvalRequired: boolean): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.SUCCESS_PAIRING,
        approvalRequired: approvalRequired,
    }),

    proceedWithPerson: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.CHOOSE_PERSON,
    }),

    proceedWithCompany: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.CHOOSE_COMPANY,
    }),

    enterPersonData: (
        personData: PersonData,
        phoneChallengeCode: string | undefined,
        lastThreePhoneDigits: string | undefined,
    ): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.ENTER_PERSON_DATA,
        personData,
        phoneChallengeCode,
        lastThreePhoneDigits,
    }),

    enterCompanyData: (
        companyData: CompanyData,
        phoneChallengeCode: string | undefined,
        lastThreePhoneDigits: string | undefined,
    ): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.ENTER_COMPANY_DATA,
        companyData,
        phoneChallengeCode,
        lastThreePhoneDigits,
    }),

    enterCommodity: (commodity: CommodityChoice): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.ENTER_COMMODITY,
        commodity,
    }),

    setPairedBusinessPartner: (pairedBusinessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.SET_PAIRED_BUSINESS_PARTNER,
        pairedBusinessPartner,
    }),

    setEmailSyncRequested: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.SET_EMAIL_SYNC_REQUESTED,
    }),

    back: (): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.BACK,
    }),

    error: (errorMsg: string): AddDeliveryPointAction => ({
        type: AddDeliveryPointActionType.ERROR,
        errorMsg,
    }),
};
