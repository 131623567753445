import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import Checkbox from '../../../../../components/common/checkbox';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { BaseTextarea, LoadingIndicator, PRICE_LINK_ZP, QueryKeysEnum, useCodeList, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { formatAddress, formatDate, formatPhoneNumber } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { CodeListTypeEnum } from '../../../config/enums';
import InterruptionConsumptionExtraInfo from './components/interruption-consumption-extra-info';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

const NOTE_MAX_COUNT = 510;

interface IBlockCompletionRequestOfficialTestMeterShort {
    condition: boolean;
    note?: string;
}

export const BlockCompletionInterruptionConsumptionShort: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const { deliveryPointZp, additionalData, content, customerRequestTemplate, paid } = useSelector((state: IRootState) => state.customerRequest);
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [showNote, setShowNote] = useState<boolean>(false);
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { formRules } = useFormRules();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors, watch } = useForm<IBlockCompletionRequestOfficialTestMeterShort>({
        defaultValues: { condition: false, note: content.note },
    });

    const message: string | undefined = watch('note');

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    useEffect(() => {
        dispatch(CustomerRequestActions.setData({ note: message }));
        setMessageCounter(message?.length ?? 0);
    }, [message, dispatch]);

    const { data: reasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ZOM_ZOPAOO_REASON,
        codeListTypeEnum: CodeListTypeEnum.ZOM_ZOPAOO_REASON,
        paging: {
            size: 80,
        },
    });

    const onFormSubmit = (formData: IBlockCompletionRequestOfficialTestMeterShort) => {
        const data: any = {
            content: {
                metadata: {
                    businessPartnerId: additionalData?.bpuuid,
                    deliveryPointId: deliveryPointZp?.id,
                },
                statement: formData.condition,
                note: formData.note,
                date: content.date,
                reason: { uuid: reasonCodeList?.find((reason) => reason.code === 'SUSPEND')?.uuid },
                type: customerRequestTemplate?.code,
                preferredContact: content.preferredContact?.phoneNumber ? { phoneNumber: content.preferredContact?.phoneNumber } : undefined,
                deliveryPoint: { id: deliveryPointZp?.id },
                entityReferences: [
                    {
                        contractId: deliveryPointZp?.contract?.id,
                        deliveryPointId: deliveryPointZp?.id,
                        contractAccountId: deliveryPointZp?.contract?.contractAccount?.id,
                    },
                ],
                paymentApproval: formData.condition,
            },
            customerRequestTemplate,
        };
        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const checkBoxLabel = paid?.paid && (
        <>
            {t('customer-request.steps.accept-current-price-list')}
            <br />
            <a href={PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                {t('customer-request.steps.current-price-list')}&nbsp;
            </a>
        </>
    );

    const date = content.date && moment(content.date).isValid() ? formatDate(new Date(content.date)) : '';

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.delivery-point.title')}>
                    {deliveryPointZp?.address != null && (
                        <>
                            <ItemPreviewContent>
                                <b>
                                    {formatAddress(deliveryPointZp.address)} - {t(`enums.CommodityEnum.${deliveryPointZp.type}`)}
                                </b>
                                <br />
                                <small>{t('delivery-point.contract-number', { number: deliveryPointZp.contract?.externalId })} </small>
                            </ItemPreviewContent>
                        </>
                    )}
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.interaption-date.title')}>
                    <ItemPreviewContent bold>{date}</ItemPreviewContent>
                </ItemPreview>
                {content.preferredContact?.phoneNumber && (
                    <ItemPreview title={t('customer-request.steps.termination-contract-date.phone-number-technician')}>
                        <ItemPreviewContent bold>{formatPhoneNumber(content.preferredContact?.phoneNumber)}</ItemPreviewContent>
                    </ItemPreview>
                )}
            </ListGroup>
            <div className="my-2">
                <ClickableElement onClick={() => setShowNote(!showNote)} isText>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                    <Trans i18nKey="customer-request.steps.prompt-resumption-of-gas.add-note">Pridať poznámku</Trans>
                </ClickableElement>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                {(showNote || messageCounter > 0) && (
                    <BaseTextarea
                        ref={register({ ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                        errors={errors}
                        name={'note'}
                        maxLength={NOTE_MAX_COUNT}
                        label={t('customer-request.steps.attachments.title')}
                        counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
                        optional
                    />
                )}
                {checkBoxLabel && (
                    <div className="p-3 my-4 rounded" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            id="condition"
                            name="condition"
                            label={checkBoxLabel}
                            className="mb-1"
                        />
                    </div>
                )}

                <Button type="submit" size="lg" color="primary" block className="mt-5">
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>
            </form>
            <Modal isOpen={isRequestCreated} centered style={{ maxWidth: 650 }}>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} confirmButtonLabel={t('delivery-point.detail.modals.understand')}>
                        <InterruptionConsumptionExtraInfo />
                    </ChangeRequestSent>
                </ModalBody>
            </Modal>
        </>
    );
};
