import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { QueryKeysEnum, useReloadQueryCache } from '../../../utils/react-query-utils';
import UnifyEmailModal from './unify-einvoice-email/unify-email-modal';
import { useCanUnifyEinvoiceEmail } from './utils/use-can-unify-einvoice-email';

const EInvoicesSettingsSubHeader: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const reloadQueryCache = useReloadQueryCache();
    const afterUnifyEmails = useCallback(() => {
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH);
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
    }, [reloadQueryCache]);

    /* 
    can unify emails if at least one eInvoice (one DP) has active eInvoice 
    */
    const { canUnifyEInvoiceEmails } = useCanUnifyEinvoiceEmail();

    return (
        <>
            <div className="d-flex justify-content-between align-content-center my-4">
                <h2 className="d-inline mb-0">
                    <Trans i18nKey="settings.navigation.e-invoices" />
                </h2>
                {canUnifyEInvoiceEmails && (
                    <>
                        <Button type="button" color="primary" className="d-none d-lg-block mr-1 ml-auto" onClick={() => setIsModalOpen(true)}>
                            <Trans i18nKey="settings.e-invoices.set-united-email" />
                        </Button>

                        <UncontrolledDropdown className="d-inline d-block d-lg-none">
                            <DropdownToggle
                                type="button"
                                id="eInvoiceDropdownMenuButton"
                                className="btn btn-light btn-round"
                                data-toggle="dropdown"
                                aria-haspopup
                                aria-expanded="false"
                            >
                                <i className="icon-context-points"></i>
                            </DropdownToggle>

                            <DropdownMenu right aria-labelledby="eInvoiceDropdownMenuButton">
                                <DropdownItem className="py-3" onClick={() => setIsModalOpen(true)}>
                                    <Trans i18nKey="settings.e-invoices.set-united-email" />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                )}
            </div>

            <UnifyEmailModal isOpen={isModalOpen} onUnifyEmail={afterUnifyEmails} onCloseModal={() => setIsModalOpen(false)} />
        </>
    );
};

export default EInvoicesSettingsSubHeader;
