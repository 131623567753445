import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, FormGroup } from 'reactstrap';
import { Input } from '../../../../../components/common';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../models/model';

type IShareDeliveryPointFormProps = DefaultFormType & {};

export const ShareDeliveryPointFormFields: React.FC<IShareDeliveryPointFormProps> = ({ register, errors, trigger }) => {
    const { requiredTrimed, formRules } = useFormRules();

    const [t] = useTranslation();

    return (
        <>
            <FormGroup>
                <Input
                    trigger={trigger}
                    ref={register({ ...requiredTrimed, ...formRules.email })}
                    errors={errors}
                    type="text"
                    name="email"
                    label={t('delivery-point.detail.actions.share-delivery-point.insert-email')}
                    autoFocus
                    autoCapitalize="off"
                    autoCorrect="off"
                />
            </FormGroup>

            <Button type="submit" block color="primary" size="lg">
                {<Trans i18nKey="common.share" />}
            </Button>
        </>
    );
};
