import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { BaseButton, LoadingIndicator } from '../../../../components/common';
import { useEpayApi } from '../../../../hooks/use-api';
import Routes from '../../../../routes/routes';
import { TransactionStatus, TransactionStatusResponse } from '../api/e-pay-api';

const PaymentStatus: React.FC = () => {
    const epayApi = useEpayApi();
    const params = useParams<{ transactionId: string }>();
    const history = useHistory();
    const location = useLocation();
    const [statePollingEnabled, setStatePollingEnabled] = useState(true);

    const { isLoading, data: paymentStatusData } = useQuery<TransactionStatusResponse | null | undefined>(
        ['epay-transaction-status', params.transactionId],
        async () =>
            params.transactionId ? epayApi.epay.transactionStatus(params.transactionId, { secure: true }).then((res) => res.data) : undefined,
        {
            enabled: statePollingEnabled,
            refetchInterval: 2000,
        },
    );

    // stop state polling if payment procedure is finnished
    useEffect(() => {
        switch (paymentStatusData?.status) {
            case 'ONLINE_RESPONSE_OK':
            case 'MAIL_RESPONSE_OK':
            case 'ONLINE_RESPONSE_FAILED':
            case 'MAIL_RESPONSE_FAILED':
            case 'TRANSACTION_TIMEOUT':
                setStatePollingEnabled(false);
                break;
            default:
                break;
        }
    }, [paymentStatusData, setStatePollingEnabled]);

    // navigate to previous page
    const goBack = () => {
        history.replace(location.pathname.split(Routes.EPAY_STATUS)[0]);
    };

    const renderPaymentStatus = (transactionStatus: TransactionStatus | undefined) => {
        if (!params.transactionId) {
            return (
                <>
                    <i className="icon-circle-alert-16 fa-5x text-warning d-block mb-3"></i>
                    <h4 className="text-warning mb-5">
                        <Trans i18nKey="invoices.e-pay.error-message">Platba nebola dokončená, alebo sa nepodarilo overiť jej stav</Trans>
                    </h4>
                </>
            );
        }

        switch (transactionStatus) {
            case 'ONLINE_RESPONSE_OK':
            case 'MAIL_RESPONSE_OK':
                return (
                    <>
                        <i className="icon-circle-ok-16 fa-5x text-success d-block mb-3"></i>
                        <h4 className="text-success mb-5">
                            <Trans i18nKey="invoices.e-pay.success-message">Faktúra bola uhradená</Trans>
                        </h4>
                    </>
                );

            case 'INIT':
            case 'REDIRECTED_TO_IB':
            case 'ONLINE_RESPONSE_BANK_TIMEOUT':
                return (
                    <>
                        <div>
                            <div className="loading-indicator" style={{ position: 'relative', backgroundColor: 'transparent' }}>
                                <div className="spinner spinner-large" />
                            </div>
                        </div>
                        <h4 className="mb-5">
                            <Trans i18nKey="invoices.e-pay.waiting-message">Čakáme na stav platby</Trans>
                        </h4>
                    </>
                );

            case 'TRANSACTION_TIMEOUT':
            case 'MAIL_RESPONSE_FAILED':
            case 'ONLINE_RESPONSE_FAILED':
            default:
                return (
                    <>
                        <i className="icon-circle-alert-16 fa-5x text-warning d-block mb-3"></i>
                        <h4 className="text-warning mb-5">
                            <Trans i18nKey="invoices.e-pay.error-message">Platba nebola dokončená, alebo sa nepodarilo overiť jej stav</Trans>
                        </h4>
                    </>
                );
        }
    };

    if (isLoading) return <LoadingIndicator />;

    return (
        <Container>
            <div className="text-center py-5">
                {renderPaymentStatus(paymentStatusData?.status)}
                <BaseButton onClick={goBack} className="btn btn-primary btn-lg btn-block btn-md-inline">
                    <Trans i18nKey="common.ok">OK</Trans>
                </BaseButton>
            </div>
        </Container>
    );
};

export default PaymentStatus;
