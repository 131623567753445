import jwt, { JwtPayload } from 'jwt-decode';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthActions } from '../../actions/auth-actions';
import { UserActions } from '../../actions/user';
import { AuthEnum } from '../../models/enums';
import { IAccountInfo } from '../../reducers/user-reducer';
import Routes from '../../routes/routes';
import logger from '../../utils/logger';
import { getProperHomepage } from '../../views/home/get-proper-homepage';
import { useAdminApi, useApi, useEpayApi } from '../use-api';

export type FetchedTokensType = {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
    clientId: string;
};

export const useAuthUtils = () => {
    const api = useApi();
    const epayApi = useEpayApi();
    const adminApi = useAdminApi();
    const history = useHistory();
    const dispatch = useDispatch();

    const tokenUrl = process.env.REACT_APP_SSO_TOKEN_URL;

    const getTokens = useCallback(
        async (clientId: string, scope: string, authorizationCode: string, redirectUri: string): Promise<FetchedTokensType> => {
            logger.debug('use-auth-utils - getTokens', { clientId, scope, authorizationCode, tokenUrl });
            const codeVerifier = getVerifierCodeFromSessionStorage();

            if (!tokenUrl) throw new Error('missing tokenUrl from .env');
            if (!codeVerifier) throw new Error('missing code verifier');
            if (!authorizationCode) throw new Error('missing url param: code');

            const requestConfig: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: [
                    'grant_type=authorization_code',
                    `code=${authorizationCode}`,
                    `client_id=${clientId}`,
                    `redirect_uri=${redirectUri}`,
                    `code_verifier=${codeVerifier}`,
                ].join('&'),
            };

            let fetchedTokens;
            try {
                const response = await fetch(tokenUrl, requestConfig);
                fetchedTokens = response.json().then((res: any) => ({ ...res, clientId }));
            } catch (error) {
                clearCodeVerifierFromSessionStorage();
                logger.warn('use-auth-utils - getTokens failed', error);
            }
            return fetchedTokens;
        },
        [tokenUrl],
    );

    const dispatchCustomer = useCallback(
        (user: IAccountInfo, tokens: FetchedTokensType, backUrlOrCallback?: string | (() => void)) => {
            logger.debug('use-auth-utils - dispatchCustomer', { user, tokens });
            api.setSecurityData({ Authorization: `Bearer ${tokens.access_token}` });
            epayApi.setSecurityData({ Authorization: `Bearer ${tokens.access_token}` });
            // user must be dispatched before the followings
            dispatch(
                UserActions.setUser({
                    ...user,
                    userSkippedPhoneVerification: !!user.employee,
                }),
            );
            if (!user.employee) {
                const jwtPayload = decodeJwt(tokens.access_token);
                dispatch(
                    AuthActions.setAuthTokens(
                        tokens.access_token,
                        tokens.refresh_token,
                        tokens.id_token,
                        tokens.clientId,
                        tokens.expires_in,
                        jwtPayload.exp,
                    ),
                );
            } else {
                dispatch(AuthActions.setSecondaryAuthTokens(tokens.access_token, tokens.refresh_token, tokens.id_token, tokens.expires_in));
            }
            dispatch(AuthActions.setIsSilentRefreshResolved(true));

            if (typeof backUrlOrCallback === 'function') {
                return backUrlOrCallback();
            }
            if (backUrlOrCallback === Routes.HOME) {
                const properHomepage = getProperHomepage(user);
                history.replace(properHomepage);
            } else if (backUrlOrCallback) {
                history.replace(backUrlOrCallback);
            }
        },
        [api, dispatch, epayApi, history],
    );

    const getCustomer = useCallback(
        async (tokens: FetchedTokensType, backUrl?: string, onSuccess?: (user: IAccountInfo) => void, onError?: (error: string) => void) => {
            logger.debug('use-auth-utils - getCustomer', { tokens, backUrl });
            return api.me
                .getMe({ headers: { Authorization: `Bearer ${tokens.access_token}` } })
                .then((user) => getCustomerSuccess(user, tokens))
                .catch(getCustomerFail);

            function getCustomerSuccess(response: any, tokens: FetchedTokensType) {
                clearCodeVerifierFromSessionStorage();
                const newUser = response.data;
                if (!newUser) {
                    onError && onError('getMe response is empty');
                    throw new Error('getMe response is empty');
                } else {
                    onSuccess ? onSuccess(newUser) : dispatchCustomer(newUser, tokens, backUrl);
                }
            }

            function getCustomerFail(err: Error) {
                clearCodeVerifierFromSessionStorage();
                onError && onError('getCustomer fail');
                logger.warn('getCustomer fail', err);
            }
        },
        [api, dispatchCustomer],
    );

    const getAdmin = useCallback(
        async (tokens: FetchedTokensType, backUrl?: string) => {
            adminApi.setSecurityData({ Authorization: `Bearer ${tokens.access_token}` });
            adminApi.me
                .getMe({ secure: true })
                .then((user) => getAdminSuccess(user, tokens))
                .catch(getAdminFail);

            function getAdminSuccess(response: any, tokens: FetchedTokensType) {
                clearCodeVerifierFromSessionStorage();
                const newUser = response.data;
                if (!newUser) throw new Error('getMe response is empty');
                dispatchEverything(newUser, tokens);
            }

            function getAdminFail(err: Error) {
                clearCodeVerifierFromSessionStorage();
                logger.warn('getAdmin fail', err);
            }

            function dispatchEverything(user: IAccountInfo, tokens: FetchedTokensType) {
                dispatch(
                    UserActions.setUser({
                        ...user,
                        userSkippedPhoneVerification: true,
                    }),
                );
                // user must be dispatched before the followings
                const jwtPayload = decodeJwt(tokens.access_token);
                dispatch(
                    AuthActions.setAuthTokens(
                        tokens.access_token,
                        tokens.refresh_token,
                        tokens.id_token,
                        tokens.clientId,
                        tokens.expires_in,
                        jwtPayload.exp,
                    ),
                );
                dispatch(AuthActions.setIsSilentRefreshResolved(true));

                if (backUrl === Routes.HOME || !backUrl) {
                    const properHomepage = getProperHomepage(user);
                    history.push(properHomepage);
                } else {
                    history.push(backUrl);
                }
            }
        },
        [adminApi, history, dispatch],
    );

    function getVerifierCodeFromSessionStorage() {
        return sessionStorage.getItem(AuthEnum.CODE_VERIFIER_NAME);
    }

    function clearCodeVerifierFromSessionStorage() {
        sessionStorage.removeItem(AuthEnum.CODE_VERIFIER_NAME);
    }

    return { getTokens, getCustomer, getAdmin, getVerifierCodeFromSessionStorage, changeUrlIfBlacklisted, dispatchCustomer };
};

export const getStateFromSessionStorage = (uuid: string): string | null => {
    if (!uuid) {
        logger.warn('use-sso-utils - getStateFromSessionStorage', 'Missing uuid to load state params from session storage');
        throw new Error('Missing uuid to load state params from session storage');
    }
    const stateParams = sessionStorage.getItem(uuid);
    sessionStorage.removeItem(uuid);
    return stateParams;
};

export const changeUrlIfBlacklisted = (url: string, blacklist: string[], urlFallback: string): string => {
    return blacklist.includes(url) ? urlFallback : url;
};

export function decodeJwt<T = Record<string, any>>(token: string): T & JwtPayload {
    try {
        return jwt<T & JwtPayload>(token);
    } catch (ex) {
        logger.error('use-sso-utils - decodeJwt', token, ex);
        return {} as any;
    }
}
