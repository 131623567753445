import { SkSppNzpCommonsApiCodelistCodeListItem } from '@spp/spp-meru-frontend-common';
import { IExportConfiguration } from '../../../../components/common/export/export-modal';

export const consumptionExportConfigurations = (data: SkSppNzpCommonsApiCodelistCodeListItem[]): IExportConfiguration<any>[] => {
    let columns: IExportConfiguration<any>[] = [];
    if (data) {
        columns = data.map((column) => ({
            label: column.name || '',
            field: column.code || '',
            defaultChecked: true,
        }));
    }
    return columns;
};
