import { SkSppNzpBeApiCustomerprofileAccountManager, SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import usersvg from '../../../../../assets/images/user.svg';
import BaseButton from '../../../../../components/common/base-button';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { ReservedValuesEnum } from '../../../../../models/enums';

interface IForChangeContactSalesManagerModalProps {
    isOpen: boolean;
    onClose: () => void;
    manager?: SkSppNzpBeApiCustomerprofileAccountManager;
    type?: 'EE' | 'ZP';
    reservedValueType?: ReservedValuesEnum;
    label?: string;
    button?: boolean;
    deliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
}

export const ForChangeContactSalesManagerModal: React.FC<IForChangeContactSalesManagerModalProps> = ({
    isOpen,
    onClose,
    manager,
    type,
    reservedValueType,
    label,
    button,
    deliveryPoint,
}) => {
    const api = useApi();
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const [mutateCancelReqeust, { isLoading }] = useMutationWithError(
        async () =>
            deliveryPoint?.contract?.id && reservedValueType
                ? api.deliveryPoints
                      .changeReservedValues(deliveryPoint.contract.id, { type: reservedValueType }, { secure: true })
                      .then((res) => res.data)
                : null,
        {
            onSuccess: (resp) => {
                if (resp?.id) {
                    addToast(t('delivery-point.detail.consumption.contact-successfully'));
                    onClose();
                }
            },
        },
    );

    return (
        <>
            {isLoading && <LoadingIndicator />}
            <Modal isOpen={isOpen} centered>
                <ModalBody>
                    <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h6 className="mb-3">
                        {label ? (
                            label
                        ) : type === 'ZP' ? (
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-tariff-not-supported" />
                        ) : (
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-rate-not-supported" />
                        )}
                    </h6>
                    <div className="d-flex align-items-center">
                        <div className="mr-4">
                            <img src={usersvg} className="carousel-photo display-inline" alt="manager_photo" />
                        </div>
                        <div>
                            <p className="mb-2">
                                <b>
                                    {manager?.firstName}
                                    {''}
                                    {manager?.lastName}
                                </b>
                                <br />
                                <Trans i18nKey="delivery-point.detail.payment-data.tariffs.sales-manager">Manažér predaja</Trans>
                            </p>
                            <a href={`mailto:${manager?.email}`}>{manager?.email}</a>
                            <br />
                            <a href={`tel:${manager?.phone}`}>{manager?.phone}</a>
                        </div>
                    </div>
                    {button && deliveryPoint && (
                        <BaseButton onClick={mutateCancelReqeust}>
                            <Trans i18nKey="delivery-point.detail.consumption.contact" />
                        </BaseButton>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};
