import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import useCodeList from '../../../../../hooks/use-code-list';
import { IAddress } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties, formatAddressShort, isObjectEmpty } from '../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestDataEnum } from '../../../config/enums';
import AddressFields from './components/address-fields';

type AddressFormType = {
    address: IAddress;
    correspondenceAddress: IAddress;
    correspondenceFields: boolean;
};

const BlockAddressSupply: React.FC = () => {
    const dispatch = useDispatch();
    const { currentBlock, content, partnersDeliveryPoints, additionalData } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPointAddress =
        additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.status === 'ACTIVE').length === 1
            ? {
                  ...additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.address,
                  number: additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.address?.streetNumber,
                  postalCode: additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.address?.zipCode,
              }
            : undefined;
    const addressName = currentBlock?.dataKey ?? CustomerRequestDataEnum.ADDRESS;
    const addressData = (content[addressName] as IAddress) ?? deliveryPointAddress;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    const { register, handleSubmit, errors, watch, setValue, trigger, getValues } = useForm<AddressFormType>({
        defaultValues: {
            address: { ...addressData },
        },
    });

    const onSubmit = (data: AddressFormType) => {
        const address = data?.address && clearObjectProperties(data.address);
        const payload = {
            [addressName]: isObjectEmpty(address) ? undefined : address,
            selectedDPorBPCountry: countryCodeList?.find((item) => item.code === 'SK'),
            saved: content.saveRequested ? true : undefined,
        };
        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    const deliveryPoints = useMemo(() => {
        const DPsTMP: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] = [];
        let moreThanLimit = false;
        const limit = 3;
        if (partnersDeliveryPoints) {
            Object.entries((partnersDeliveryPoints as Record<string, SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[]>) ?? []).some(
                ([bpId, deliveryPoints]) => {
                    deliveryPoints.some((dp) => {
                        DPsTMP.push(dp);
                        if (DPsTMP.length === limit) {
                            moreThanLimit = true;
                        }
                        return DPsTMP.length === limit;
                    });
                    if (DPsTMP.length === limit) {
                        moreThanLimit = true;
                    }
                    return DPsTMP.length === limit;
                },
            );
        }

        return moreThanLimit ? [] : DPsTMP;
    }, [partnersDeliveryPoints]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {deliveryPoints?.length > 0 && (
                <div style={{ backgroundColor: '#e0e0e1' }} className="rounded py-3 px-4 mb-3">
                    <span className="mx-2">
                        <Trans i18nKey="customer-request.steps.address-supply.prefill-data">Predvyplniť údaje?</Trans>
                    </span>
                    {deliveryPoints.map((dp) => (
                        <ClickableElement
                            className="mx-2 text-decoration-underline-inverse"
                            style={{ color: 'black' }}
                            isText
                            key={`address_${dp.id}`}
                            onClick={() => setValue('address', { ...dp.address, number: dp.address?.streetNumber, postalCode: dp.address?.zipCode })}
                        >
                            {formatAddressShort(dp.address)}
                        </ClickableElement>
                    ))}
                </div>
            )}
            <AddressFields
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                disabledCountrySelect
                disabled={false}
                trigger={trigger}
            />
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockAddressSupply;
