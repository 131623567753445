import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import { ClickableElement } from '../../../components/common/clickable-element';
import SwitchInput from '../../../components/common/switch-input';
import { ICategory, ICategoryType, IType } from '../interface';

interface ISection {
    categoryType?: ICategoryType;
    category: ICategory;
    types: IType[];
    acceptedCategoryTypeIds: string[];
    handleDetailsConsent: (id: string) => void;
}

const Section: React.FC<ISection> = ({ categoryType, category, types, acceptedCategoryTypeIds, handleDetailsConsent }) => {
    const { t, i18n } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [subSectionExpandedByIds, setSubSectionExpandedByIds] = useState<string[]>([]);

    const name = i18n.language === 'en' ? categoryType?.en.name : categoryType?.sk.name;
    const description = i18n.language === 'en' ? categoryType?.en.description : categoryType?.sk.description;
    const providers = category.providers;
    const providersLength = providers?.length;
    let providersCount = 0;
    providers?.forEach((provider) => {
        providersCount += provider.details.length;
    });

    const handleSubSection = (id: string) => {
        setSubSectionExpandedByIds((currentIds) => {
            const newIds = new Set(currentIds);
            if (newIds.has(id)) {
                newIds.delete(id);
            } else {
                newIds.add(id);
            }
            return Array.from(newIds);
        });
    };

    return (
        <div className="mt-4">
            <div className="d-flex">
                <ClickableElement onClick={() => setIsExpanded((prev) => !prev)}>
                    <div className="d-flex">
                        <div className="mr-4">
                            <i
                                className={`la-xs ml-auto my-auto smooth-transition cookies-yellow-circle {
                            ${isExpanded ? 'icon-chevron-top-16' : 'icon-chevron-down-16'}`}
                            ></i>
                        </div>
                        <div>
                            <p className="font-weight-500 font-size17">
                                {name} <span className="cookies-grey-circle ml-2">{providersCount}</span>
                            </p>
                            <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description || '') }} />
                        </div>
                    </div>
                </ClickableElement>
                <SwitchInput
                    name={'section_consent'}
                    label={''}
                    id={category.id}
                    className="ml-auto cookies-switch"
                    checked={acceptedCategoryTypeIds.includes(categoryType?.id || '')}
                    onChange={() => handleDetailsConsent(categoryType?.id || '')}
                    disabled={categoryType?.necessary}
                />
            </div>
            <Collapse isOpen={isExpanded}>
                <div className="py-4 px-3 cookies-section-border">
                    {providers?.map((provider, providerIndex) => {
                        const name = i18n.language === 'en' ? provider.en.name : provider.sk.name;
                        const link = i18n.language === 'en' ? provider.en.link : provider.sk.link;
                        const isOpen = subSectionExpandedByIds.includes(provider.id);
                        const detailsCount = provider.details.length;
                        return (
                            <div key={provider.id}>
                                <ClickableElement
                                    onClick={(e) => {
                                        handleSubSection(provider.id);
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="d-flex">
                                        <div className="mr-4">
                                            <i
                                                className={`la-xs ml-auto my-auto smooth-transition cookies-yellow-circle {
                                    ${isOpen ? 'icon-chevron-top-16' : 'icon-chevron-down-16'}`}
                                            ></i>
                                        </div>
                                        <div>
                                            <p className="font-weight-500 font-size17">
                                                {name} <span className="cookies-grey-circle ml-2">{detailsCount}</span>
                                            </p>
                                            <ClickableElement
                                                onClick={() => window.open(link, '_blank')}
                                                className="font-weight-500 text-decoration-underline-inverse cookies-clickable 0 font-size17"
                                            >
                                                <span>{t('cookies-alert.modal.link')}</span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                {/* unbreakable space text with icon always together*/}
                                                <FontAwesomeIcon icon={faExternalLinkAlt} className="cookies-external-link" />
                                            </ClickableElement>
                                        </div>
                                    </div>
                                </ClickableElement>
                                {providersLength !== providerIndex + 1 && <hr className="mt-4" />}
                                <Collapse isOpen={isOpen}>
                                    {provider.details.map((detail, detailIndex) => {
                                        const name = i18n.language === 'en' ? detail.en.name : detail.sk.name;
                                        const description = i18n.language === 'en' ? detail.en.description : detail.sk.description;
                                        const validity = i18n.language === 'en' ? detail.en.validity : detail.sk.validity;
                                        const type = types.find((type) => type.id === detail.typeFk.id);
                                        const typeName = i18n.language === 'en' ? type?.en.name : type?.sk.name;
                                        return (
                                            <React.Fragment key={detail.id}>
                                                {detailIndex === 0 && providersLength === providerIndex + 1 && <hr />}
                                                <div className="cookies-section-border cookies-section-detail p-3">
                                                    <p className="font-weight-500 font-size17">{name}</p>
                                                    <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description || '') }} />
                                                    <hr />
                                                    <Row>
                                                        <Col xs="6">
                                                            <span className="font-weight-500 font-size17">{t('cookies-alert.modal.validity')}</span>
                                                            <span className="font-size17">{validity}</span>
                                                        </Col>
                                                        <Col xs="6">
                                                            <span className="font-weight-500 font-size17">{t('cookies-alert.modal.type')}</span>
                                                            <span className="font-size17">{typeName}</span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {detailsCount !== detailIndex + 1 && <div className="my-3" />}
                                                {detailsCount === detailIndex + 1 && providersLength !== providerIndex + 1 && <hr />}
                                            </React.Fragment>
                                        );
                                    })}
                                </Collapse>
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};

export default Section;
