import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import HelpLinkWithModal from '../../../../../../components/help/help-link-with-modal';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { CommodityEnum } from '../../../../../../models/enums';
import { DefaultFormType } from '../../../../../../models/model';
import { formatAddressShort } from '../../../../../../utils/utils';

interface ISingleMeterReading extends DefaultFormType {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    index: number;
}

const SingleMeterReading: React.FC<ISingleMeterReading> = ({ deliveryPoint, index, trigger, register, errors }) => {
    const { t } = useTranslation();
    const { formRules } = useFormRules();

    // SNVI-283 - vypnutie kontroly
    // const minValue =
    // (deliveryPoint.type === 'EE' ? deliveryPoint?.readingCycleInfo?.lastHighValue : deliveryPoint?.readingCycleInfo?.lastLowValue) || 0;
    const minValue = 0;

    return (
        <div className="mt-4">
            {index !== 0 && <hr className="my-4" />}
            <div>
                <h4>
                    {formatAddressShort(deliveryPoint.address)} - {t(`enums.CommodityEnum.${deliveryPoint.type}`)}{' '}
                </h4>
                <p>
                    {t('delivery-point.contract-number', {
                        number: deliveryPoint.contract?.externalId,
                    })}
                </p>
                {deliveryPoint?.contract?.eeTariffCount === 2 ? (
                    <>
                        <InputWithAppend
                            trigger={trigger}
                            ref={register({ ...formRules.requiredInteger, ...formRules.min(minValue) })}
                            characterFilter={CharacterFilter.POSITIVE_INTEGER}
                            errors={errors}
                            type="number"
                            id={`valueHigh.${deliveryPoint.id}`}
                            name={deliveryPoint.id + '.valueHigh'}
                            label={
                                t('customer-request.steps.meter-reading-transcript.meter-value-ee') +
                                ' - ' +
                                t('delivery-point.detail.consumption.meter-value-electricity-high')
                            }
                            inputGroupAppendChildren={
                                <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                    kWh
                                </button>
                            }
                        />
                        <InputWithAppend
                            trigger={trigger}
                            ref={register({ ...formRules.requiredInteger, ...formRules.min(minValue) })}
                            characterFilter={CharacterFilter.POSITIVE_INTEGER}
                            errors={errors}
                            type="number"
                            id={`valueLow.${deliveryPoint.id}`}
                            name={deliveryPoint.id + '.valueLow'}
                            label={
                                t('customer-request.steps.meter-reading-transcript.meter-value-ee') +
                                ' - ' +
                                t('delivery-point.detail.consumption.meter-value-electricity-low')
                            }
                            inputGroupAppendChildren={
                                <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                    kWh
                                </button>
                            }
                        />
                    </>
                ) : (
                    <InputWithAppend
                        trigger={trigger}
                        ref={register({ ...formRules.requiredInteger, ...formRules.min(minValue) })}
                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                        errors={errors}
                        type="number"
                        id={`value.${deliveryPoint.id}`}
                        name={deliveryPoint.id + '.value'}
                        label={
                            deliveryPoint.type === CommodityEnum.GAS
                                ? t('customer-request.steps.meter-reading-transcript.meter-value-zp')
                                : t('customer-request.steps.meter-reading-transcript.meter-value-ee')
                        }
                        inputGroupAppendChildren={
                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                {deliveryPoint.type === CommodityEnum.GAS ? (
                                    <>
                                        m<sup>3</sup>
                                    </>
                                ) : (
                                    'kWh'
                                )}
                            </button>
                        }
                    />
                )}
                <Trans i18nKey="customer-request.steps.gauge-condition.meter-condition-help2" />
                <div className="my-2">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    <HelpLinkWithModal
                        className="d-inline"
                        classNameClickableElement="text-decoration-underline-inverse"
                        title="customer-request.steps.gauge-condition.meter-reading-help"
                        field={
                            deliveryPoint.type === CommodityEnum.GAS
                                ? 'SOT_GAS_METER_WHERE_I_FIND_IT'
                                : deliveryPoint?.contract?.eeTariffCount === 2
                                ? 'SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT'
                                : 'SOT_ELECTRICITY_METER_WHERE_I_FIND_IT'
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default SingleMeterReading;
