import React from 'react';
import ColumnHeaderCell from './column-header-cell';
import { IColumnConfiguration, ISort, SortDirection } from './table-interfaces';

interface IColumnHeaderToolbarProps<T> {
    columnConfigurations: IColumnConfiguration<T>[];
    sort?: ISort | undefined;
    onSortChange?: (columnId: string, value: SortDirection | undefined) => void;
    tableLayoutFixed?: boolean;
}

const ColumnHeaderToolbar = <T,>({ columnConfigurations, sort, onSortChange, tableLayoutFixed }: IColumnHeaderToolbarProps<T>): JSX.Element => {
    const onColumnSortChange = (columnId: string) => (newSort: SortDirection | undefined) => (onSortChange ? onSortChange(columnId, newSort) : null);

    return (
        <>
            {columnConfigurations.map((col) => (
                <ColumnHeaderCell
                    key={col.columnId}
                    label={col.label}
                    width={
                        tableLayoutFixed
                            ? col.columnWidth
                                ? col.columnWidth
                                : undefined
                            : col.columnWidth && col.columnWidth.endsWith('%')
                            ? col.columnWidth
                            : undefined
                    }
                    minWidth={!tableLayoutFixed && col.columnWidth && col.columnWidth.endsWith('px') ? col.columnWidth : undefined}
                    sort={sort && sort.attribute === col.columnId ? sort.direction : undefined}
                    sortable={col.sortable}
                    onSortChange={col.sortable && col.columnId && onColumnSortChange ? onColumnSortChange(col.columnId) : () => null}
                />
            ))}
        </>
    );
};

export default ColumnHeaderToolbar;
