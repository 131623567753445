import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary as BusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoiceSearch,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequest,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
    SkSppNzpBeApiCustomerrequestFieldGroupUpdate,
    SkSppNzpBeApiNotificationNotificationTemplate,
    SkSppNzpCommonsApiCodelistCodeListItem,
    SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent
} from '@spp/spp-meru-frontend-common';
import { ArrayField, ErrorOption, FieldErrors } from 'react-hook-form';
import { IAddress, IMeterReading } from './customer-request-model';
import {
    BussinessPartnerState,
    CommodityEnum,
    CustomerActivityTypeEnum,
    CustomerStateEnum,
    CustomerTypeEnum,
    EmployeeActivityTypeEnum,
    HelpVariantCommodityEnum,
    HelpVariantSegmentEnum,
    InvoiceState,
    NotificationGroupEnum,
    NotificationPriorityEnum,
    NotificationStatusEnum,
    NotificationTypeEnum,
    NotificationTypesEnum,
    PaymentType
} from './enums';

export type DefaultFormType = {
    register: (options: Record<string, any>) => (ref: any) => void;
    unregister?: (name: any) => void;
    trigger?: ((name: any) => Promise<boolean>) | undefined;
    errors?: FieldErrors;
    setError?: (name: string, error: ErrorOption) => void;
    watch?: any;
    setValue?: (name: any, b: any) => any;
    getValues?: (name: any, b?: any) => any;
    control?: any;
    fields?: Partial<ArrayField<Record<string, any>, 'id'>>[];
    append?: (value: Partial<Record<string, any>> | Partial<Record<string, any>>[], shouldFocus?: boolean | undefined) => void;
    remove?: (index?: number | number[] | undefined) => void;
    autoFocus?: boolean;
    touched?: boolean;
};

export type InputErrors = { [key: string]: string | React.ReactElement | null };
export interface IPerson {
    number: string;
    name: string; // person full name or company name
    companyId?: string; // IČO
    sharingPerson?: boolean;
}
export interface IAdress {
    street: string;
    city: string;
    zipCode: string;
}
export interface ISharedWithPerson {
    email?: string;
    phoneNumber?: string;
}

export interface IDeliveryPoint {
    number: string;
    person: IPerson;
    address: IAdress;
    sharedWith?: ISharedWithPerson[];
    commodities: CommodityEnum[];
    visible: boolean;
}
export interface IInvoice {
    uuid?: string;
    externalId?: string;
    issueAt?: string;
    group?: SkSppNzpBeApiCustomerprofileInvoiceSearch['invoiceGroup'];
    deliveryPoint?: string;
    commodity?: CommodityEnum;
    status?: InvoiceState;
    amount?: number;
    dueAt?: string;
    vs?: string;
    payments?: IPayments[];
    queue?: 'INDIVIDUAL' | 'COLLECTIVE';
}
export interface ICustomers {
    id: number;
    type: CustomerTypeEnum[];
    name: string;
    customer_numbers: string[];
    email: string;
    phone_number: string;
    state: CustomerStateEnum;
}
export interface IPayments {
    date?: string;
    type?: PaymentType;
    sum?: number;
}
export interface ISwitchButton {
    id: string;
    title: string | JSX.Element;
    className?: string;
    disabled?: boolean;
}
export interface IPaymentInkaso {
    iban: string;
    bicSwift: string;
}
export interface IPaymentSipo {
    sipoNumber: string;
}

export interface IEmployeeActivityLog {
    id: number;
    timestamp: string;
    actor: string;
    actorBPNumbers: string[] | null;
    activity: EmployeeActivityTypeEnum;
    target: string;
    targetBPNumbers: string[];
}

export interface ICustomerActivityLog {
    id: number;
    timestamp: string;
    actor: string;
    actorBPNumbers: string[] | null;
    activity: CustomerActivityTypeEnum;
}

export interface INotification {
    uuid: string;
    createdAt?: Date;
    updatedAt?: Date;
    version?: number;
    code?: string; //change to enum
    status?: NotificationStatusEnum;
    type?: NotificationTypeEnum;
    group?: NotificationGroupEnum;
    name?: string;
    priorita?: NotificationPriorityEnum;
    description?: string;
    defaultEmail?: boolean;
    defaultSMS?: boolean;
    attributes?: string;
}

export interface IHelp {
    id: number;
    uuid: string;
    location: string;
    status: NotificationStatusEnum;
}

export interface IHelpVariant {
    id: number;
    uuid: string;
    commodity: HelpVariantCommodityEnum;
    sector: HelpVariantSegmentEnum;
    templateEn: string;
    templateSk: string;
}

export interface IDeliveryPointNotificationSettingDef {
    deliveryPointId: string;
    deliveryPointAddress: string;
    email: NotificationTypesEnum[];
    sms: NotificationTypesEnum[];
}

export interface IAccountManager {
    src: string;
    name: string;
    email: string;
    phoneNumber: string;
}

export interface ISocialLoginJWTPayload {
    email?: string;
    first_name?: string;
    iat?: number;
    id: string;
    iss?: string;
    jti?: string;
    last_name?: string;
    typ?: 'fb' | 'google';
}

export interface IBussinessPartner {
    name: string;
    customerId: string;
    state: BussinessPartnerState;
}

export interface ICustomerPortal {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    registrationDate: string;
    marketingAgree: number;
    lastLogin: string;
    state: CustomerStateEnum;
    type: CustomerTypeEnum[];
    accountManagers: IAccountManager[];
    bussinessPartners: IBussinessPartner[];
    overdueInvoices: number;
    openRequests: number;
}

export interface IHttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D | null;
    error: E | null;
}

export type NotificationChannel = 'SMS' | 'EMAIL';

export type NotificationTemplateCode = NonNullable<SkSppNzpBeApiNotificationNotificationTemplate['code']>;
export type NotificationTemplateGroup = NonNullable<SkSppNzpBeApiNotificationNotificationTemplate['group']>;

export type RequestTemplateCode = NonNullable<SkSppNzpBeApiCustomerrequestCustomerRequestTemplate['code']>;

export type RequestTemplateCodeExtra =
    | 'ZOM_ZAOM'
    | 'ZOM_ZSPAPPP'
    | 'ZOM_ZVAPPP'
    | 'ZOM_ZSE'
    | 'ZOM_ZTP'
    | 'ZOM_RPVF'
    | 'ZOM_RF'
    | 'ZOP_P'
    | 'ZOM_P'
    | 'ZOM_ZODE'
    | 'ZOM_ZODP'
    | 'ZOM_ZOUZ'
    | 'ZOM_ZOPAOO'
    | 'ZOM_ZOUSM'
    | 'ZOM_ZOFMC'
    | 'ZOM_ZOZODZP'
    | 'ZOM_DOS'
    | 'ZOM_POVZ'
    | 'ZOM_POZV'
    | 'ZOM_ZOVDF'
    | 'ZOM_ZODS'
    | 'ZOP_RS'
    | 'ZZU_ADEF'
    | 'ZOP_ZOUA'
    | 'ZOP_ZP'
    | 'ZOP_ZFU'
    | 'ZOP_ZBU'
    | 'ZOM_O'
    | 'ZOP_US'
    | 'ZI_PMNUZOZDP'
    | 'ZI_PMNUZOZDE'
    | 'ZI_S'
    | 'ZI_SNVZODP'
    | 'ZI_SNVZODE'
    | 'ZOM_ZODE_ZODP'
    | 'ZOM_CE';

export interface IBusinessPartnerDeliveryPointData {
    businessPartner: BusinessPartnerSummary;
    unitedDeliveryPoints: UnitedDeliveryPointSummary[];
}

export interface IResponseError {
    code?: number;
    errors?: string[];
    exception?: string;
    message?: string;
    parameters?: any;
    requestId?: string;
    timestamp?: string;
}

export interface IApiResponse {
    data?: any;
    error?: IResponseError;
    ok?: boolean;
    redirected?: boolean;
    status?: number;
    statusText?: string;
    type?: string;
    url?: string;
}

export interface IRequestPaid {
    comodity?: CommodityEnum;
    paid: boolean;
}

export type CustomerRequestFieldUpdateType = NonNullable<SkSppNzpBeApiCustomerrequestFieldGroupUpdate['type']>;
export type CustomerRequestFieldUpdateAttributeType = NonNullable<SkSppNzpBeApiCustomerrequestFieldGroupUpdate['attributeChanges']>[number];

interface IEInvoiceActivationCustomerRequestContent extends SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent {
    invoiceDelivery?: {
        email?: string;
        type?: 'EMAIL' | 'ADDRESS';
        address?: IAddress;
    };
}

export interface IEInvoiceActivationCustomerRequest extends SkSppNzpBeApiCustomerrequestCustomerRequest {
    content: IEInvoiceActivationCustomerRequestContent;
}

export type CustomerRequestStatus = NonNullable<SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery['statuses']> extends (infer T)[] ? T : never;

export type InvoicePaymentVisibility = NonNullable<SkSppNzpBeApiCustomerprofileInvoice['paymentVisibility']>;

export const PRICE_LINK_EE =
    'https://www.spp.sk/sk/Cds/AdminDownload/?filename=3326_Cennik_externych_sluzieb_a_vykonov_dodavka_elektriny__od_8-2-2021';
export const PRICE_LINK_ZP = 'https://www.spp.sk/sk/Cds/AdminDownload/?filename=2868_Cennik_externych_sluzieb_PLYN_od_1-09-2019_F';

export interface IDeliveryPointsEe {
    componentType: string;
    contractId: string;
    deliveryPointEe: {
        componentType: string;
        address: {
            componentType: string;
            street: string;
            number: string;
            city: string;
            postalCode: string;
            country: {
                uuid: string;
                type: string;
                code: string;
                name: string;
                description: string;
                attributes: {
                    additionalProp1: string;
                    additionalProp2: string;
                    additionalProp3: string;
                };
                status: string;
                locale: string;
                parent: string;
            };
            floor: string;
            flatNumber: string;
        };
        contractNumber: string;
        meterNumber: string;
        deliveryPointNumber: string;
        eic: string;
        pod: string;
    };
    meterReadingEe: IMeterReading;
    tariffEe: {
        componentType: string;
        tariff: SkSppNzpCommonsApiCodelistCodeListItem;
        changeDate: string;
    };
    assumedConsumptionEe: {
        componentType: string;
        tariff: string;
        advancePayment: string;
        value: string;
        valueLow: string;
        valueHigh: string;
        date: string;
    };
    advancePaymentPeriodEe: {
        componentType: string;
        period: SkSppNzpCommonsApiCodelistCodeListItem;
    };
}

export interface IDeliveryPointsZp {
    componentType: string;
    contractId: string;
    deliveryPointZp: {
        componentType: string;
        address: {
            componentType: string;
            street: string;
            number: string;
            city: string;
            postalCode: string;
            country: {
                uuid: string;
                type: string;
                code: string;
                name: string;
                description: string;
                attributes: {
                    additionalProp1: string;
                    additionalProp2: string;
                    additionalProp3: string;
                };
                status: string;
                locale: string;
                parent: string;
            };
            floor: string;
            flatNumber: string;
        };
        contractNumber: string;
        meterNumber: string;
        deliveryPointNumber: string;
        eic: string;
        pod: string;
    };
    meterReadingZp: IMeterReading;
    tariffZp: {
        componentType: string;
        tariff: SkSppNzpCommonsApiCodelistCodeListItem;
        changeDate: string;
    };
    assumedConsumptionZp: {
        componentType: string;
        tariff: string;
        advancePayment: string;
        value: string;
        valueLow: string;
        valueHigh: string;
        date: string;
    };
    advancePaymentPeriodZp: {
        componentType: string;
        period: SkSppNzpCommonsApiCodelistCodeListItem;
    };
}
