import { SkSppNzpBeApiCustomerrequestCustomerRequestConfirmation } from '@spp/spp-meru-frontend-common';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../components/common/loading-indicator';
import { useApi } from '../../hooks/use-api';
import useMutationWithError from '../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../hooks/use-url-query';
import Routes from '../../routes/routes';

interface IConfirmRequestPayload {
    uuid: string;
    data: SkSppNzpBeApiCustomerrequestCustomerRequestConfirmation;
}

export const RequestConfirmation: React.FC = () => {
    const urlQuery = useUrlQuery();
    const api = useApi();

    const uuid = urlQuery.get('customerRequestId');
    const code = urlQuery.get('confirmationCode');
    const [mutateApprovalChange, { isLoading, isSuccess, isError }] = useMutationWithError(
        async (inputData: IConfirmRequestPayload) => api.customerRequests.confirmRequest(inputData.uuid, inputData.data),
        {},
    );

    useEffect(() => {
        mutateApprovalChange({
            uuid: uuid || '',
            data: { code: code || '' },
        });
    }, [mutateApprovalChange, code, uuid]);

    return (
        <>
            <div className="container">
                <div className="row text-center py-5">
                    {isLoading && <LoadingIndicator size="large" fullscreen />}
                    <div className="col-12 position-static">
                        {isSuccess && (
                            <>
                                <i className="icon-circle-ok-16 fa-5x text-success d-block my-3"></i>
                                <h3 className="my-3 text-success">
                                    <Trans i18nKey="confirm-request.success" />
                                </h3>
                                <div className="text-center mt-4">
                                    <Link to={Routes.HOME}>
                                        <Trans i18nKey="confirm-email-change.navigate-home" />
                                    </Link>
                                </div>
                            </>
                        )}
                        {isError && (
                            <>
                                <i className="icon-circle-alert-16 fa-5x text-danger d-block my-3"></i>
                                <h3 className="my-3 text-danger">
                                    <Trans i18nKey="confirm-request.error" />
                                </h3>
                                <div className="text-center mt-4">
                                    <Link to={Routes.HOME}>
                                        <Trans i18nKey="confirm-email-change.navigate-home" />
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
