import React from 'react';
import { Trans } from 'react-i18next';

interface IProps {
    visible: boolean;
    onClick: () => void;
}
const SelfDeductionsButton: React.FC<IProps> = ({ onClick, visible }) => {
    return (
        <>
            {visible && (
                <button type="button" className="btn btn-primary btn-block btn-md-inline my-3 mr-3 my-lg-0" onClick={onClick}>
                    <Trans i18nKey="delivery-point.detail.actions.enter-self-deduction">Zadajte samoodpočet</Trans>
                </button>
            )}
        </>
    );
};

export default SelfDeductionsButton;
