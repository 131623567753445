import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBusinessPartner } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';
import BusinessPartnerCompanyItem from './business-partner-company-item';
import BusinessPartnerPersonItem from './business-partner-person-item';

type Props = {
    data: IBusinessPartner;
    bold?: boolean;
};

const BusinessPartnerItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    return (
        <>
            {data?.firstName && <BusinessPartnerPersonItem data={data} bold={bold} title={t('customer-request.steps.businessPartner.title')} />}
            {data?.name && (
                <BusinessPartnerCompanyItem
                    data={data}
                    bold={bold}
                    title={t('customer-request.steps.businessPartner.title')}
                ></BusinessPartnerCompanyItem>
            )}
            {!data?.firstName && !data?.name && (
                <ItemPreview title={t('customer-request.steps.businessPartner.title')}>
                    <ItemPreviewContent bold={bold}> {`${t('customer-request.prepare.customer-number')}: ${data?.bpNumber}`}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default BusinessPartnerItem;
