import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: string;
    bold?: boolean;
};

const ChangeLastNameReasonItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.change-lastname-reason.title')}>
                    <ItemPreviewContent bold={bold}>{t(`enums.LastNameChangeReasonEnum.${data}`)}</ItemPreviewContent>
                </ItemPreview>
            )}
        </>
    );
};

export default ChangeLastNameReasonItem;
