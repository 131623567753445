import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { BaseButton, BaseRadio, DatePicker } from '../../../../../components/common';
import DatePickerIntervalContainer from '../../../../../components/common/datePicker/datepicker-interval-container';
import { useFormRules } from '../../../../../hooks/use-form-rules';

enum ExportFormat {
    CSV = 'CSV',
    XLSX = 'XLSX',
}

enum ExportInterval {
    CUSTOM_INTERVAL = 'CUSTOM_INTERVAL',
    CURRENT_YEAR = 'CURRENT_YEAR',
    PREVIOUS_YEAR = 'PREVIOUS_YEAR',
    LAST_12_MONTHS = 'LAST_12_MONTHS',
}

type QueryOptionsFormType = { format: ExportFormat; exportInterval: ExportInterval; timestampFrom: string; timestampTo: string };

type Props = {
    isOpen: boolean;
    onClose: () => void;
    minDate: Date;
    maxDate: Date;
    onExport: (from: Date, to: Date, format: 'XLSX' | 'CSV') => void;
};

const ConsumptionMeteringGraphExportModalReadModal: React.FC<Props> = ({ isOpen, onClose, minDate, maxDate, onExport }) => {
    const [t] = useTranslation();
    const { register, handleSubmit, setValue, getValues, trigger, errors, watch } = useForm<QueryOptionsFormType>({
        defaultValues: {
            format: ExportFormat.CSV,
            exportInterval: ExportInterval.CURRENT_YEAR,
            timestampFrom: minDate.toISOString(),
            timestampTo: maxDate.toISOString(),
        },
    });
    const { formRules, dateRules } = useFormRules();

    const exportInterval = watch('exportInterval');

    useEffect(() => {
        if (isOpen === false) {
            return;
        }
        if (exportInterval === ExportInterval.CUSTOM_INTERVAL) {
            const minDateOffset = minDate.getTimezoneOffset();
            const maxDateOffset = maxDate.getTimezoneOffset();
            setValue(
                'timestampFrom',
                new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, -minDateOffset, 0, 999).toISOString(),
            );
            setValue(
                'timestampTo',
                new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 23, 59 - maxDateOffset, 59, 999).toISOString(),
            );
        }
    }, [exportInterval, setValue, minDate, maxDate, isOpen]);

    const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormat.CSV);

    const onHandleExport = (fields: QueryOptionsFormType) => {
        const currentDate = new Date();
        const offset = currentDate.getTimezoneOffset();
        switch (exportInterval) {
            case ExportInterval.CURRENT_YEAR:
                onExport(new Date(currentDate.getFullYear(), 0, 1, 0, -offset, 0), new Date(currentDate), fields.format);
                return;
            case ExportInterval.LAST_12_MONTHS:
                onExport(
                    moment()
                        .subtract(12, 'months')
                        .add(-offset, 'minutes')
                        .toDate(),
                    currentDate,
                    fields.format,
                );
                return;
            case ExportInterval.PREVIOUS_YEAR:
                onExport(
                    new Date(currentDate.getFullYear() - 1, 0, 1, 0, -offset, 0),
                    new Date(currentDate.getFullYear(), 0, 1, 0, -offset, 0),
                    fields.format,
                );
                return;
            case ExportInterval.CUSTOM_INTERVAL:
                onExport(new Date(fields.timestampFrom), new Date(fields.timestampTo), fields.format);
                return;
        }
    };

    return (
        <Modal isOpen={isOpen} centered>
            <ModalBody>
                <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">
                    <Trans i18nKey={'export.header-export-values'}>Exportovať XXX</Trans>
                </h3>
                <form onSubmit={handleSubmit(onHandleExport)}>
                    <div className="row mb-5">
                        <div className="col-8">
                            <h5 className="forms-headline mb-3">
                                <Trans i18nKey={'export.choose-interval'}>Výber obdobia:</Trans>
                            </h5>
                            <BaseRadio
                                ref={register(formRules.required)}
                                id={'exportInterval' + ExportInterval.CURRENT_YEAR}
                                name="exportInterval"
                                value={ExportInterval.CURRENT_YEAR}
                                label={<Trans i18nKey="export.interval-current-year">Aktuálny rok</Trans>}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                id={'exportInterval' + ExportInterval.PREVIOUS_YEAR}
                                name="exportInterval"
                                value={ExportInterval.PREVIOUS_YEAR}
                                label={<Trans i18nKey="export.interval-previous-year">Predchádzajúci rok</Trans>}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                id={'exportInterval' + ExportInterval.LAST_12_MONTHS}
                                name="exportInterval"
                                value={ExportInterval.LAST_12_MONTHS}
                                label={<Trans i18nKey="export.interval-12-months">Posledných 12 mesiacov</Trans>}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                id={'exportInterval' + ExportInterval.CUSTOM_INTERVAL}
                                name="exportInterval"
                                value={ExportInterval.CUSTOM_INTERVAL}
                                label={<Trans i18nKey="export.interval-custom">Vlastné obdobie</Trans>}
                            />
                        </div>
                        <div className="col-4">
                            <h5 className="forms-headline mb-3">
                                <Trans i18nKey={'export.header-file-type'}>Typ súboru:</Trans>
                            </h5>
                            <BaseRadio
                                ref={register(formRules.required)}
                                id="csvRadio"
                                name="format"
                                value={ExportFormat.CSV}
                                onChange={() => setExportFormat(ExportFormat.CSV)}
                                label={<Trans i18nKey="export.option-csv" />}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                id="xlsRadio"
                                name="format"
                                value={ExportFormat.XLSX}
                                onChange={() => setExportFormat(ExportFormat.XLSX)}
                                label={<Trans i18nKey="export.option-xls" />}
                            />
                        </div>
                    </div>
                    {exportInterval === ExportInterval.CUSTOM_INTERVAL && (
                        <div className="row">
                            <div className="col">
                                <DatePickerIntervalContainer>
                                    <DatePicker
                                        label={t('delivery-point.detail.consumption.time-from')}
                                        {...register('timestampFrom', dateRules().isValidDate)}
                                        getValues={getValues}
                                        setValue={setValue}
                                        trigger={trigger}
                                        errors={errors}
                                        name="timestampFrom"
                                        position="L"
                                        showInputWhileError={false}
                                        border={false}
                                    />
                                    <DatePicker
                                        label={t('delivery-point.detail.consumption.time-to')}
                                        {...register('timestampTo', {
                                            ...dateRules('NOT_OLDER_THAN', getValues('timestampFrom') ? getValues('timestampFrom') : undefined)
                                                .isValidDate,
                                        })}
                                        getValues={getValues}
                                        setValue={setValue}
                                        position="R"
                                        trigger={trigger}
                                        errors={errors}
                                        name="timestampTo"
                                        includeDay
                                        showInputWhileError={false}
                                        border={false}
                                    />
                                </DatePickerIntervalContainer>
                            </div>
                        </div>
                    )}
                    <div className="row text-center col">
                        <div className="col">
                            <BaseButton type="submit" className="btn-primary">
                                <>
                                    <Trans i18nKey="export.download-button" /> {exportFormat === ExportFormat.CSV ? '.csv' : '.xlsx'}
                                </>
                            </BaseButton>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default ConsumptionMeteringGraphExportModalReadModal;
