import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary as BusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSearch,
    SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common/src/api';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { usePaginatedQueryWithError } from '../../../hooks/use-paginated-query-with-error';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import {
    AutoComplete,
    CustomerAccountTypeEnum,
    IRootState,
    LoadingIndicator,
    Paginator,
    QueryKeysEnum,
    buildUnitedDeliveryPointsSearchQueryKey,
    formatBusinessPartnerName,
    useApi,
    useUrlQuery,
} from '../../../main';
import { searchQueryDataForUnitedDeliveryPointsData } from '../../invoices/invoices';
import { NotificationSettingsNotAvailable } from '../notifications/notification-settings-list/notification-setting-not-available';
import MarketingConsentsBusinessPartnerCard from './business-partner-card';
import Header from './header';

interface IOptions {
    name: string;
    value: string;
}

const MarketingConsentsListBusinessPartner: React.FC = () => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();
    const pageSize = 10;
    const history = useHistory();
    const urlParams = useParams<{ businessPartnerId: string }>();
    const query = useUrlQuery();
    const [pageIndex, setPageIndex] = React.useState(
        query.get('page') && !isNaN(Number(query.get('page'))) ? Math.abs(Number(query.get('page')) - 1) : 0,
    );
    const [expandedBusinessPartner, setExpandedBusinessPartner] = React.useState<string | undefined>(undefined);
    const [businessPartnerFromUrl, setBusinessPartnerFromUrl] = React.useState<
        SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary | undefined
    >(undefined);
    const [businessPartnerSelected, setBusinessPartnerSelected] = useState<string>();
    const [t] = useTranslation();
    const businessPartnersFilter: SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSearch = {
        businessPartnerSearch: {
            paging: {
                page: pageIndex,
                size: pageSize,
                sort: [{ attribute: 'nameSearchable', direction: 'ASC' }],
            },
            id: businessPartnerSelected,
            queue: CustomerAccountTypeEnum.BULK,
        },
        unitedDeliveryPointSearch: {
            includeInactive: false,
            shared: false,
            deliveryPoint: { hidden: false },
        },
        includeDeliveryPoints: true,
    };

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    const {
        isFetching: isFetchingBusinessPartnersData,
        resolvedData: businessPartnersData,
        isLoading: isLoadingBusinessPartnersData,
        refetch: refetchBusinessPartners,
    } = usePaginatedQueryWithError<BusinessPartnerSummary | null>({
        queryKey: [QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH, loggedInCustomer?.id, businessPartnersFilter],
        queryFn: async () => {
            return loggedInCustomer?.id == null
                ? null
                : api.customers.searchBusinessPartners(loggedInCustomer.id, businessPartnersFilter, { secure: true }).then((res) => res.data);
        },
        config: {
            onSuccess: (data) => {
                const firstPbWithoutRequestInProgress = data?.result?.find((bp) => !bp.consentRequestId);
                !expandedBusinessPartner && !urlParams.businessPartnerId && setExpandedBusinessPartner(firstPbWithoutRequestInProgress?.id);
            },
        },
    });

    const {
        isFetching: isFetchingBusinessPartnerData,
        isLoading: isLoadingBusinessPartnerData,
        refetch: refetchBusinessPartner,
    } = usePaginatedQueryWithError<BusinessPartnerSummary | null>({
        queryKey: [QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH, loggedInCustomer?.id, urlParams.businessPartnerId],
        queryFn: async () => {
            return loggedInCustomer?.id == null
                ? null
                : api.customers
                      .searchBusinessPartners(loggedInCustomer.id, { businessPartnerSearch: { id: urlParams.businessPartnerId } }, { secure: true })
                      .then((res) => res.data);
        },
        config: {
            enabled: urlParams.businessPartnerId,
            onSuccess: (data) => {
                const bp = data?.result?.[0];
                setBusinessPartnerFromUrl(bp);
                !bp?.consentRequestId && setExpandedBusinessPartner(bp?.id);
            },
        },
    });

    const { data: unitedDeliveryPoints } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, {
            ...searchQueryDataForUnitedDeliveryPointsData,
        }),
        async () =>
            loggedInCustomer?.id
                ? api.customers
                      .searchUnitedDeliveryPoint(
                          loggedInCustomer?.id,
                          {
                              ...searchQueryDataForUnitedDeliveryPointsData,
                              businessPartner: { queue: CustomerAccountTypeEnum.BULK },
                              shared: false,
                              includeInactive: false,
                          },
                          undefined,
                          {
                              secure: true,
                          },
                      )
                      .then((res) => (res.data?.result ? res.data?.result : null))
                : null,
    );

    const businessPartnerOptions = useMemo<IOptions[]>(() => {
        const result: IOptions[] = [];
        unitedDeliveryPoints?.forEach((deliveryPoint) => {
            if (deliveryPoint.businessPartner?.id) {
                if (!result.find((businessPartner) => businessPartner.value === deliveryPoint.businessPartner?.id)) {
                    result.push({
                        name: formatBusinessPartnerName(deliveryPoint.businessPartner),
                        value: deliveryPoint.businessPartner.id,
                    });
                }
            }
        });
        const sorted = result.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    }, [unitedDeliveryPoints]);

    return (
        <>
            {(isFetchingBusinessPartnersData || isLoadingBusinessPartnersData || isLoadingBusinessPartnerData || isFetchingBusinessPartnerData) && (
                <LoadingIndicator fullscreen />
            )}
            <Header />
            <Container>
                <Row className="justify-content-lg-center pb-5 pt-2 pt-lg-5">
                    <Col xs={12} xl={10} className="position-static">
                        {((businessPartnersData?.paging?.total || 0) > 10 || businessPartnerSelected) && (
                            <AutoComplete<IOptions>
                                options={businessPartnerOptions}
                                placeholder={t('invoices.filter.customer')}
                                onSelect={(val) => {
                                    setPageIndex(0);
                                    setExpandedBusinessPartner(undefined);
                                    setBusinessPartnerSelected(val?.[0]?.value);
                                }}
                                menuOptionsMaxWidth="100%"
                            />
                        )}
                        {businessPartnersData?.paging?.total === 0 ? (
                            <NotificationSettingsNotAvailable />
                        ) : (
                            <>
                                {businessPartnerFromUrl && pageIndex === 0 && (
                                    <MarketingConsentsBusinessPartnerCard
                                        key={`marketing_consents_${businessPartnerFromUrl.id}`}
                                        businessPartner={businessPartnerFromUrl}
                                        onToggle={(bpId) => setExpandedBusinessPartner(bpId)}
                                        isExpanded={expandedBusinessPartner === businessPartnerFromUrl.id}
                                        refetchBusinessPartners={refetchBusinessPartner}
                                    />
                                )}
                                {businessPartnersData?.result
                                    ?.filter((bp) => bp.id !== businessPartnerFromUrl?.id)
                                    .map((businessPartner) => (
                                        <MarketingConsentsBusinessPartnerCard
                                            key={`marketing_consents_${businessPartner.id}`}
                                            businessPartner={businessPartner}
                                            onToggle={(bpId) => setExpandedBusinessPartner(bpId)}
                                            isExpanded={expandedBusinessPartner === businessPartner.id}
                                            refetchBusinessPartners={refetchBusinessPartners}
                                        />
                                    ))}
                                <Paginator
                                    className="mt-3"
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    dataLength={businessPartnersData?.paging?.total}
                                    onPageChanged={onPageChange}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default MarketingConsentsListBusinessPartner;
