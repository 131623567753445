import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBankConnection } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IBankConnection;
    bold?: boolean;
};

const NewBankConnectionItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { bicSwift, iban } = data;

    return (
        <ItemPreview title={t('customer-request.steps.new-bank-detail.title')}>
            {iban && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${iban}`}</ItemPreviewContent>}
            {bicSwift && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.bic-swift')}: ${bicSwift}`}</ItemPreviewContent>}
        </ItemPreview>
    );
};

export default NewBankConnectionItem;
