import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as pageResponse,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch as UnitedDeliveryPointSearch,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../../hooks/use-api';
import { usePaginatedQueryWithError } from '../../../hooks/use-paginated-query-with-error';
import { useWindowWidthBreakpoints } from '../../../hooks/use-window-width-breakpoints';
import { ISort, Routes } from '../../../main';
import { IRootState } from '../../../reducers';
import { DeliveryPointsTableFilterData } from '../component/list-delivery-points-table-filter';
import { SelectedDeliveryPointsProvider } from './contexts/selected-delivery-points-context';
import { ShareSingleDeliveryPointModalProvider } from './contexts/share-single-delivery-point-context';
import ShareDeliveryPointsScreen from './share-delivery-points-screen';

const ShareDeliveryPoints: React.FC = () => {
    const pageSize = 10;
    const [page, setPage] = useState<number>(0);
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const api = useApi();

    const [tableColumnFilter, setTableColumnFilter] = useState<DeliveryPointsTableFilterData>({});

    const [sort, setSort] = useState<ISort | undefined>();

    const pageChangedHandler = (page: number) => {
        setPage(page);
    };

    const onFilterChange = useCallback(
        (filterData: DeliveryPointsTableFilterData) => {
            setTableColumnFilter(filterData);
            setPage(0);
        },
        [setTableColumnFilter],
    );

    const fetchDeliveryPoint = async (uuid: string | undefined, search: UnitedDeliveryPointSearch = {}) => {
        if (!uuid) {
            return null;
        }

        search.ft = '';
        return api.customers.searchUnitedDeliveryPoint(uuid, search, undefined, { secure: true });
    };

    const { data: deliveryPoints, isFetching } = usePaginatedQueryWithError<pageResponse | null>({
        //following queryKey should match delivery-points-search's queryKey, to make use of reactQuery Cache
        //entering explicit values instead of some variables (which are not used in this component), to match default queryKey in delivery-points-screen
        //candidate for refactor
        queryKey: [
            'delivery-points-search',
            false, //sharedWithMe
            false, //showInactiveDeliveryPoints
            tableColumnFilter,
            '', //fulltext
            page,
            sort, //sort
            true, //tableMode
            false, //show data as cards
            false, //showFullTextSearch()
            loggedInCustomer?.id,
        ],
        queryFn: () =>
            fetchDeliveryPoint(loggedInCustomer?.id, {
                shared: false,
                includeInactive: false,
                type: tableColumnFilter?.type,
                businessPartner: {
                    ft: tableColumnFilter?.customer,
                },
                ft: '',
                deliveryPoint: {
                    ftId: tableColumnFilter?.dpExternalId,
                    ftAddress: tableColumnFilter?.address,
                    hidden: false,
                },
                paging: {
                    page: page,
                    size: pageSize,
                    sort:
                        sort != null
                            ? [
                                  {
                                      attribute: sort.attribute,
                                      direction: sort.direction,
                                  },
                              ]
                            : [
                                  { attribute: 'ownershipType', direction: 'ASC' },
                                  { attribute: 'customerEmail', direction: 'ASC' },
                                  { attribute: 'bpExternalId', direction: 'ASC' },
                              ],
                },
            }).then((response) => (response != null ? response.data : null)),
        config: {},
    });

    const windowBreakpoints = useWindowWidthBreakpoints();

    const history = useHistory();
    useEffect(() => {
        if (windowBreakpoints != null && windowBreakpoints.lg === false) {
            history.replace(Routes.DELIVERY_POINTS);
        }
    }, [windowBreakpoints, history]);

    return (
        <SelectedDeliveryPointsProvider>
            <ShareSingleDeliveryPointModalProvider>
                <ShareDeliveryPointsScreen
                    deliveryPoints={deliveryPoints}
                    page={page}
                    pageChangedHandler={pageChangedHandler}
                    tableColumnFilter={tableColumnFilter}
                    onFilterChange={onFilterChange}
                    sort={sort}
                    onSortChange={setSort}
                    isLoading={isFetching}
                />
            </ShareSingleDeliveryPointModalProvider>
        </SelectedDeliveryPointsProvider>
    );
};

export default ShareDeliveryPoints;
