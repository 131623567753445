import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { useFormRules } from '../../../hooks/use-form-rules';
import { downloadHandler } from '../../../utils/utils';
import BaseButton from '../base-button';
import BaseRadio from '../base-radio';
import Checkbox from '../checkbox';
interface IExportProps<T> {
    modalLabel: string | JSX.Element;
    titleComponent?: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    exportConfiguration?: IExportConfiguration<T>[];
    onExport?: (exportQueryOptions: IExportQueryOptions) => void;
    selectedCommodity?: 'ZP' | 'EE';
    displayUnitsKWH_M3?: boolean;
    wider_modal?: boolean;
}

export interface IExportConfiguration<T> {
    label: string | JSX.Element;
    field: keyof T;
    required?: boolean;
    defaultChecked?: boolean;
    header?: boolean;
    parent?: string;
}

enum ExportFormat {
    CSV = 'CSV',
    XLSX = 'XLSX',
}

enum ExportUnit {
    M3 = 'M3',
    KWH = 'KWH',
}

export interface IExportQueryOptions {
    format: ExportFormat;
    unit?: ExportUnit;
    columns: string[];
}

type QueryOptionsFormType = {
    format: ExportFormat;
    columns: { [key: string]: boolean };
};

export const triggerDownloadFile = (blob: Blob, fileName?: string): void => {
    let fileSuffix = '';
    switch (blob.type) {
        case 'text/csv':
            fileSuffix = '.csv';
            break;
        case 'application/vnd.ms-excels':
            fileSuffix = '.xlsx';
            break;
    }
    const file = (fileName ?? `export-${new Date().toISOString()}`) + fileSuffix;

    downloadHandler(blob, file, blob.type);
};

const ExportModal = <T,>({
    isOpen,
    onClose,
    modalLabel,
    titleComponent,
    exportConfiguration,
    onExport,
    selectedCommodity,
    displayUnitsKWH_M3,
    wider_modal = false,
}: IExportProps<T>): JSX.Element => {
    const defaultCheckboxConfig =
        exportConfiguration && Object.fromEntries(exportConfiguration.map((config) => [config.field.toString(), config.defaultChecked]));

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { format: ExportFormat.CSV, unit: ExportUnit.KWH, columns: defaultCheckboxConfig || [] },
    });
    const { formRules } = useFormRules();
    const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormat.CSV);
    const [unitType, setUnitType] = useState<ExportUnit>(ExportUnit.KWH);

    useEffect(() => {
        setUnitType(ExportUnit.KWH);
    }, [selectedCommodity]);

    const onHandleExport = (fields: QueryOptionsFormType) => {
        if (onExport) {
            const columns: string[] = [];
            fields.columns &&
                Object.keys(fields.columns).forEach((column) => {
                    if (fields.columns[column]) columns.push(column);
                });
            onExport({
                format: exportFormat,
                unit: displayUnitsKWH_M3 ? unitType : undefined,
                columns,
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClosed={() => setExportFormat(ExportFormat.CSV)}
            centered
            className={classNames({ export_modal_wider: wider_modal })}
        >
            <ModalBody>
                <button type="button" onClick={onClose} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
                <h3 className="mb-4">{modalLabel}</h3>
                {titleComponent}
                <form onSubmit={handleSubmit(onHandleExport)}>
                    <div className="row mb-5">
                        {exportConfiguration && (
                            <div className="col-8">
                                <h5 className="forms-headline mb-3">
                                    <Trans i18nKey={'export.header-export-columns'}>Exportovať tieto údaje:</Trans>
                                </h5>
                                {exportConfiguration.map((column) => {
                                    const fieldName = column.field.toString();
                                    const headerPrefix = 'headers.';
                                    const columnPrefix = 'columns.';
                                    const columnId = (column.header ? headerPrefix : columnPrefix) + fieldName;
                                    return (
                                        <Checkbox
                                            id={columnId}
                                            key={columnId}
                                            ref={register({})}
                                            name={columnId}
                                            label={column.header ? <b>{column.label}</b> : column.label}
                                            disabled={column.required}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (column.header)
                                                    exportConfiguration.forEach((config) => {
                                                        if (config.parent && config.parent.toString() === fieldName && !config.required) {
                                                            setValue(columnPrefix + config.field.toString(), e.target.checked);
                                                        }
                                                    });
                                            }}
                                            className={column.header ? 'mb-2 pt-2' : 'mb-2'}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <div className="col-4">
                            <h5 className="forms-headline mb-3">
                                <Trans i18nKey={'export.header-file-type'}>Typ súboru:</Trans>
                            </h5>
                            <BaseRadio
                                ref={register(formRules.required)}
                                id="csvRadio"
                                name="format"
                                value={ExportFormat.CSV}
                                onChange={() => setExportFormat(ExportFormat.CSV)}
                                label={<Trans i18nKey="export.option-csv" />}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                id="xlsRadio"
                                name="format"
                                value={ExportFormat.XLSX}
                                onChange={() => setExportFormat(ExportFormat.XLSX)}
                                label={<Trans i18nKey="export.option-xls" />}
                            />
                            {displayUnitsKWH_M3 && (
                                <>
                                    <h5 className="forms-headline mt-3">
                                        <Trans i18nKey={'export.header-unit-type'}>Volba jednotiek:</Trans>
                                    </h5>
                                    <BaseRadio
                                        ref={register(formRules.required)}
                                        id="kwh"
                                        name="unit"
                                        value={ExportUnit.KWH}
                                        onChange={() => setUnitType(ExportUnit.KWH)}
                                        label="kwh"
                                    />
                                    <BaseRadio
                                        ref={register(formRules.required)}
                                        id="m3"
                                        name="unit"
                                        disabled={selectedCommodity === 'EE'}
                                        value={ExportUnit.M3}
                                        onChange={() => setUnitType(ExportUnit.M3)}
                                        // label="m3"
                                        label={
                                            <div>
                                                m<sup>3</sup>
                                            </div>
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="row text-center col">
                        <div className="col">
                            <BaseButton type="submit" className="btn-primary">
                                <>
                                    <Trans i18nKey="export.download-button" /> {exportFormat === ExportFormat.CSV ? '.csv' : '.xlsx'}
                                </>
                            </BaseButton>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default ExportModal;
