import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import HelpText from '../../components/help/help-text';

const Cookies = (): ReactElement => {
    return (
        <>
            {/* Page Header */}
            <div className="page-header py-4">
                <div className="container">
                    <div className="d-flex justify-content-between my-4">
                        <h2 className="mb-0">
                            <Trans i18nKey="cookies.title">Informácie o používaní súborov cookies</Trans>
                        </h2>
                    </div>
                </div>
            </div>
            {/* / Page Header */}

            <div className="container" style={{ position: 'relative' }}>
                <HelpText screen="COO" field="COO_CONTENT" />
            </div>
        </>
    );
};

export default Cookies;
