import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import Checkbox from '../../../../../components/common/checkbox';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestDataEnum } from '../../../config/enums';

type StatementFormType = {
    statement: boolean;
};

const BlockStatement: React.FC = () => {
    const dispatch = useDispatch();

    const { currentBlock, content, request } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.STATEMENT;
    const objectData = content[objectName] as boolean;
    const { t } = useTranslation();

    const { register, handleSubmit, errors } = useForm<StatementFormType>({ defaultValues: { statement: objectData } });

    const { formRules } = useFormRules();

    const onSubmit = (data: StatementFormType) => {
        const payload = {
            [objectName]: data.statement,
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <p>
                <Trans i18nKey={`customer-request.steps.statement.${request?.type}`} />
            </p>
            <Checkbox
                ref={register({ ...formRules.required })}
                errors={errors}
                className="mt-2 mb-5"
                id="statement"
                name="statement"
                label={t('customer-request.steps.statement.condition')}
            />
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockStatement;
