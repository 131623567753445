import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofilePayment,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { Fragment, ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Collapse, UncontrolledTooltip } from 'reactstrap';
import { CustomerRequestActions } from '../../../actions/customer-request-actions';
import { ClickableElement, LoadingIndicator } from '../../../components/common';
import { can } from '../../../components/permissions';
import { useApi } from '../../../hooks/use-api';
import useCodeList from '../../../hooks/use-code-list';
import { useKeyValueStore } from '../../../hooks/use-key-value';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { IRootState } from '../../../reducers';
import Routes from '../../../routes/routes';
import { QueryKeysEnum } from '../../../utils/react-query-utils';
import { formatAddress, formatAddressShort, formatBusinessPartnerName, formatCurrency, formatDate } from '../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestEnum } from '../../customer-request/config/enums';
import { useOpenRequestFromDeliveryPointDetail } from '../../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';
import { InvoiceType } from '../components/invoice-type';
import EpayModal from '../e-pay/e-pay-modal';
import DataRow from './components/detail-data-row';
import DetailInvoiceStatus from './components/detail-invoice-status';
import { DownloadInvoiceAsPDFButton } from './components/download-invoice-as-pdf-button';
import { InvoicePaymentButton } from './components/invoice-payment-button';

interface IInvoiceDetailView {
    invoice?: SkSppNzpBeApiCustomerprofileInvoice | null;
    unitedDeliveryPointId: string;
    deliveryPointId?: string;
    invoiceLoading: boolean;
    commodity?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
}

//sort payment by date
function compare(a: SkSppNzpBeApiCustomerprofilePayment, b: SkSppNzpBeApiCustomerprofilePayment) {
    if (a.executeAt && b.executeAt) {
        if (new Date(a.executeAt).getTime() > new Date(b.executeAt).getTime()) {
            return -1;
        }
        if (new Date(a.executeAt).getTime() < new Date(b.executeAt).getTime()) {
            return 1;
        }
    }
    return 0;
}

const InvoiceDetailView = ({ invoice, unitedDeliveryPointId, deliveryPointId, invoiceLoading, commodity }: IInvoiceDetailView): ReactElement => {
    const [t] = useTranslation();
    const history = useHistory();
    const dpMatch = useRouteMatch({ path: Routes.DELIVERY_POINTS });
    const dispatch = useDispatch();

    const [isPaymentModal, setPaymentModal] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState<number | undefined>();
    const employee = useSelector((state: IRootState) => state.user.employee);
    const isEmployee = useSelector((state: IRootState) => !!state.user.employee);
    const [isOpen, setIsOpen] = useState(false);
    const [storedPageIndex] = useKeyValueStore(Routes.INVOICES + '@pageIndex', 0);
    const user = useSelector((state: IRootState) => state.user);

    const payments = invoice?.payments;
    const invoiceItems = invoice?.invoiceItems;

    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        // if (invoice?.deliveryPoints) {
        //     if (invoice.deliveryPoints.length > 1) {
        //         dispatch(CustomerRequestActions.setData({ claimInvoiceDPs: invoice.deliveryPoints }));
        //     } else {
        //         dispatch(CustomerRequestActions.setDeliveryPoint(invoice.deliveryPoints[0]));
        //     }
        // }
        if (invoice?.deliveryPoints && invoice.deliveryPoints.length === 1) {
            dispatch(CustomerRequestActions.setDeliveryPoint(invoice.deliveryPoints[0]));
        }
        invoice && dispatch(CustomerRequestActions.setData({ invoice: invoice }));
        dispatch(CustomerRequestActions.setBusinessPartner(invoice?.businessPartner));
        initializeRequestCreationProcess(requestName, { businessPartnerId: invoice?.businessPartner?.id });
    };

    const toggle = () => setIsOpen(!isOpen);

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 10,
        },
    });

    const { data: invoiceTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_INVOICE_TYPE,
        codeListTypeEnum: CodeListTypeEnum.INVOICE_TYPE,
        paging: {
            size: 64,
        },
    });

    const shouldSeeInvoice = employee ? can('ENTITY_INVOICES_VIEW', employee) : true;

    const api = useApi();

    const { data: unitedDeliveryPointData, isLoading } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, unitedDeliveryPointId],
        async () =>
            unitedDeliveryPointId
                ? api.unitedDeliveryPoints.getUdpByUuid(unitedDeliveryPointId, { includeInactive: true }, { secure: true }).then((x) => x.data)
                : null,
    );

    const isInvoiceShared = invoice?.sharing?.by && invoice?.sharing?.by?.email !== user.customer?.email;

    return (
        <>
            {!!invoice?.unpaid && (
                <EpayModal
                    isOpen={isPaymentModal}
                    invoiceAmount={paymentAmount ? paymentAmount : invoice?.unpaid}
                    closeModal={() => setPaymentModal(false)}
                    invoice={invoice}
                    onClosedModal={() => setPaymentAmount(undefined)}
                    commodity={commodity}
                    invoiceTypeCodeList={invoiceTypeCodeList}
                />
            )}
            {dpMatch && (
                <>
                    {/* <!-- Breadcrumb with more items --> */}
                    <nav aria-label="breadcrumb" className="breadcrumb-more-items border-primary">
                        <div className="container ">
                            <ol className="breadcrumb d-inline-flex p-0 mb-0">
                                {unitedDeliveryPointId && (
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to={`${Routes.DELIVERY_POINTS}/${unitedDeliveryPointId}`}>
                                            {formatAddressShort(unitedDeliveryPointData?.address)}
                                        </Link>
                                    </li>
                                )}
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link
                                        to={
                                            unitedDeliveryPointId
                                                ? `${Routes.DELIVERY_POINTS}/${unitedDeliveryPointId}/invoices/${deliveryPointId || ''}?page=` +
                                                  (storedPageIndex + 1)
                                                : '/invoices?page=' + (storedPageIndex + 1)
                                        }
                                    >
                                        {t('invoices.header')}
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link to="#">Detail</Link>
                                </li>
                            </ol>
                        </div>
                    </nav>
                    {/* <!-- / Breadcrumb with more items --> */}
                </>
            )}
            {/* <!-- Breadcrumb --> */}
            <div className={classNames('container', { 'd-none': dpMatch })}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page">
                            <Link
                                to={
                                    unitedDeliveryPointId
                                        ? `${Routes.DELIVERY_POINTS}/${unitedDeliveryPointId}/invoices/${deliveryPointId || ''}?page=` +
                                          (storedPageIndex + 1)
                                        : '/invoices?page=' + (storedPageIndex + 1)
                                }
                            >
                                <i className="icon-arrow-left-16"></i> {t('invoices.header')}
                            </Link>
                        </li>
                    </ol>
                </nav>
            </div>
            {/* <!-- / Breadcrumb --> */}
            {invoiceLoading && <LoadingIndicator />}
            <div className="container mt-3 mb-5">
                {/* <!-- Page title --> */}
                <div className="row">
                    <div className="col">
                        <h2 className="text-left">
                            <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />
                        </h2>
                    </div>
                    <div className="col text-right">
                        {invoice?.fileArchiveId && <DownloadInvoiceAsPDFButton invoiceId={invoice.id} disabled={!shouldSeeInvoice} />}
                    </div>
                </div>
                <div className="row mb-2 mb-md-0">
                    <div className="col-auto pr-0">{invoice && <DetailInvoiceStatus invoice={invoice} />}</div>
                    <div className="col-auto">
                        {t('invoices.detail.date-issue')} {invoice?.issueAt && formatDate(new Date(invoice?.issueAt))} ·{' '}
                        {t('invoices.detail.maturityDate')} {invoice?.dueAt && formatDate(new Date(invoice?.dueAt))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 order-lg-2">
                        <h5 className="forms-headline mt-5 mb-4 d-none d-lg-block">{t('invoices.detail.data')}</h5>
                        <ul className="list-group list-group-flush">
                            <DataRow
                                label={t('invoices.detail.delivery-point')}
                                value={
                                    <>
                                        {invoice?.deliveryPoints &&
                                            invoice?.deliveryPoints
                                                .filter((value, index) => index < 2)
                                                .map((deliveryPointSingle, index) => {
                                                    if (invoice.deliveryPoints && invoice.deliveryPoints.length > 2 && index === 1) {
                                                        return (
                                                            deliveryPointSingle && (
                                                                <div key={deliveryPointSingle.id} className={`${!isOpen && 'too-much'}`}>
                                                                    {formatAddress(deliveryPointSingle.address)}
                                                                    {deliveryPointSingle.address && deliveryPointSingle.type && ' - '}
                                                                    {deliveryPointSingle.type && t(`enums.CommodityEnum.${deliveryPointSingle.type}`)}
                                                                    <br />
                                                                </div>
                                                            )
                                                        );
                                                    } else {
                                                        return (
                                                            deliveryPointSingle && (
                                                                <Fragment key={deliveryPointSingle.id}>
                                                                    {formatAddress(deliveryPointSingle.address)}
                                                                    {deliveryPointSingle.address && deliveryPointSingle.type && ' - '}
                                                                    {deliveryPointSingle.type && t(`enums.CommodityEnum.${deliveryPointSingle.type}`)}
                                                                    <br />
                                                                </Fragment>
                                                            )
                                                        );
                                                    }
                                                })}
                                        {invoice?.deliveryPoints && invoice?.deliveryPoints.length > 0 && (
                                            <>
                                                <Collapse isOpen={isOpen}>
                                                    {invoice?.deliveryPoints
                                                        .filter((value, index) => index > 1)
                                                        .map((deliveryPointSingle) => {
                                                            return (
                                                                deliveryPointSingle && (
                                                                    <Fragment key={deliveryPointSingle.id}>
                                                                        {formatAddress(deliveryPointSingle.address)}
                                                                        {deliveryPointSingle.address && deliveryPointSingle.type && ' - '}
                                                                        {deliveryPointSingle.type &&
                                                                            t(`enums.CommodityEnum.${deliveryPointSingle.type}`)}
                                                                        <br />
                                                                    </Fragment>
                                                                )
                                                            );
                                                        })}
                                                </Collapse>
                                                {invoice?.deliveryPoints.length > 2 && (
                                                    <div className="d-flex justify-content-center">
                                                        <Button color="link" outline={false} onClick={toggle}>
                                                            {isOpen ? (
                                                                <Trans i18nKey="invoices.detail.show-less" />
                                                            ) : (
                                                                <Trans i18nKey="invoices.detail.show-more" />
                                                            )}
                                                            <i
                                                                className={classNames('ml-1 fa-lg', {
                                                                    'icon-chevron-top-16': isOpen,
                                                                    'icon-chevron-down-16': !isOpen,
                                                                })}
                                                            ></i>
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <DataRow label={t('invoices.detail.customer')} value={formatBusinessPartnerName(invoice?.businessPartner)} />
                            <DataRow
                                label={t('invoices.detail.group')}
                                value={<InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />}
                            />
                            <DataRow
                                label={
                                    <>
                                        {invoice?.typeGroup === 'CREDIT' ? t('invoices.detail.sum-credit') : t('invoices.detail.sum')}
                                        <span className="ml-1" id={`invoice_sum_${invoice?.id}`}>
                                            <i className="icon-circle-info-16 text-black"></i>
                                            <UncontrolledTooltip placement="top" target={`invoice_sum_${invoice?.id}`}>
                                                <Trans i18nKey="invoices.detail.sum-tooltip">
                                                    Suma, ktorá označuje celkový nedoplatok (sumu na vrátenie) alebo preplatok (sumu na úhradu) s DPH
                                                    na faktúre. Pre detaily faktúry klinite na link <u>Stiahnuť PDF faktúru</u> vpravo hore.
                                                </Trans>
                                            </UncontrolledTooltip>
                                        </span>
                                    </>
                                }
                                value={invoice?.amount && formatCurrency(invoice.amount, invoice?.typeGroup === 'CREDIT')}
                            />
                            <DataRow label={t('invoices.detail.number')} value={invoice?.reference} />
                            <DataRow label={t('invoices.detail.vs')} value={invoice?.vs} />
                            <DataRow label={t('invoices.detail.date-of-issue')} value={invoice?.issueAt && formatDate(new Date(invoice?.issueAt))} />
                            <DataRow label={t('invoices.detail.maturity')} value={invoice?.dueAt && formatDate(new Date(invoice?.dueAt))} />
                        </ul>
                    </div>
                    <div className="col-12 col-lg-6 order-lg-3">
                        <h5 className="forms-headline mt-5 mb-4">{t('invoices.detail.payment-history')}</h5>

                        <ul className="list-group list-group-flush">
                            {payments ? (
                                payments.length === 0 ? (
                                    <Trans i18nKey={`common.empty-data`} />
                                ) : (
                                    payments
                                        .sort(compare)
                                        .map((payment, index) => (
                                            <DataRow
                                                key={index}
                                                label={payment.executeAt && formatDate(new Date(payment.executeAt))}
                                                value={
                                                    payment.overpaid
                                                        ? t('invoices.detail.payment-overpaid')
                                                        : payment.type &&
                                                          (payment.type === 'BANK_INKASO' ||
                                                              payment.type === 'FOREIGN_BANK_INKASO' ||
                                                              payment.type === 'BANK_TRANSFER' ||
                                                              payment.type === 'POSTAL_ORDER' ||
                                                              payment.type === 'SIPO')
                                                        ? t(`enums.PaymentType.${payment.type}`)
                                                        : payment?.paymentType &&
                                                          paymentTypeCodeList?.find((code) => code.code === payment?.paymentType?.code)?.name
                                                }
                                                invoiceNumber={t('invoices.detail.number') + ': ' + invoice?.reference}
                                                price={payment.amount}
                                                isCredit={invoice?.typeGroup === 'CREDIT'}
                                            />
                                        ))
                                )
                            ) : (
                                <Trans i18nKey={`common.data-not-available`} />
                            )}
                        </ul>
                        {(invoice?.typeGroup === 'REPAYMENT_PLAN' || invoice?.containsPaymentPlan) && (
                            <>
                                <h5 className="forms-headline mt-5 mb-4">{t('invoices.detail.invoice-items')}</h5>

                                <ul className="list-group list-group-flush">
                                    {invoiceItems ? (
                                        invoiceItems.length === 0 ? (
                                            <Trans i18nKey={`common.empty-data`} />
                                        ) : (
                                            invoiceItems.map((invoiceItem, index) => (
                                                <DataRow
                                                    key={index}
                                                    label={
                                                        invoiceItem?.status === 'PAID'
                                                            ? invoiceItem.executeAt && formatDate(new Date(invoiceItem.executeAt))
                                                            : invoiceItem.dueAt &&
                                                              `${t('invoices.table.invoice-maturity', {
                                                                  date: formatDate(new Date(invoiceItem.dueAt)),
                                                              })}`
                                                    }
                                                    value={
                                                        <p className={`${invoiceItem?.status === 'PAID' ? 'text-success' : 'text-danger'} mb-0`}>
                                                            <i className="icon-circle-alert-16 mr-2"></i>
                                                            {invoiceItem?.status && (
                                                                <Trans i18nKey={`enums.InvoiecItemStatus.${invoiceItem.status}`} />
                                                            )}
                                                        </p>
                                                    }
                                                    price={invoiceItem.amount}
                                                    priceComponent={
                                                        invoiceItem?.repaymentPlanId ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-lg"
                                                                onClick={() =>
                                                                    history.push(`${Routes.INVOICES_DETAIL}/${invoiceItem.repaymentPlanId}`)
                                                                }
                                                            >
                                                                {t('invoices.detail.payment-plan')}
                                                            </button>
                                                        ) : invoiceItem?.status === 'PAID' ? (
                                                            undefined
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setPaymentAmount(invoiceItem.amount);
                                                                    setPaymentModal(true);
                                                                }}
                                                                disabled={isEmployee}
                                                            >
                                                                {t('invoices.detail.pay-repayment', { sum: formatCurrency(invoiceItem.amount || 0) })}
                                                            </button>
                                                        )
                                                    }
                                                />
                                            ))
                                        )
                                    ) : (
                                        <Trans i18nKey={`common.data-not-available`} />
                                    )}
                                </ul>
                            </>
                        )}
                    </div>
                    {invoice && !!invoice.unpaid && (
                        <div className="col-12 order-lg-1 mt-3">
                            <div className="card card-mobil-transparent">
                                <div className="card-body d-flex flex-column flex-lg-row">
                                    <div className="col col-lg-2 px-0">
                                        {invoice.typeGroup !== 'CREDIT' && invoice.unpaid > 0 && <span>{t('invoices.detail.to-pay')}</span>}
                                        <h3 className={'font-weight-bold d-block my-1' + (invoice.unpaid > 0 ? ' text-danger' : '')}>
                                            {formatCurrency(invoice.unpaid, invoice?.typeGroup === 'CREDIT')}
                                        </h3>
                                        {(invoice.typeGroup === 'CREDIT' || invoice.unpaid > 0) && (
                                            <>
                                                <div>
                                                    {invoice.amount && Math.abs(invoice.unpaid) !== Math.abs(invoice.amount)
                                                        ? t('invoices.detail.remains-to-pay-from-total', {
                                                              sum: formatCurrency(Math.abs(invoice.amount)),
                                                          })
                                                        : ''}
                                                </div>
                                                <div>
                                                    {invoice.typeGroup === 'CREDIT' && <b> {t('invoices.table.from-spp-site')}</b>}
                                                    {(invoice?.overpaid || 0) > 0 && (
                                                        <>{`${t('invoices.table.overpayment')} ${formatCurrency(invoice.overpaid || 0)}`}</>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="ml-0 mr-0 mb-lg-0 d-flex col align-items-center">
                                        {invoice && (
                                            <InvoicePaymentButton invoice={invoice} isEmployee={isEmployee} onClick={() => setPaymentModal(true)} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {!isInvoiceShared && (
                    <>
                        <div className="mt-4">
                            <b>
                                <Trans i18nKey="customer-request.steps.invoice-claim.find-mistake">Našli ste vo faktúre chybu?</Trans>
                            </b>
                            <br />
                            <ClickableElement
                                onClick={() => startRequestInRequestsTab(CustomerRequestEnum.CLAIM_INVOICE_SHORT)}
                                isText
                                className="text-decoration-underline-inverse"
                            >
                                <Trans i18nKey="customer-request.steps.invoice-claim.make-claim">Podajte reklamáciu</Trans>
                            </ClickableElement>
                        </div>

                        {invoice?.installmentVisibility && (
                            <div className="mt-3">
                                <b>
                                    <Trans i18nKey="customer-request.can-not-pay-whole-invoice">Nedokážete faktúru uhradiť naraz?</Trans>
                                </b>
                                <br />
                                <ClickableElement
                                    onClick={() => startRequestInRequestsTab(CustomerRequestEnum.REPAYMENT_AGREEMENT_SHORT)}
                                    isText
                                    className="text-decoration-underline-inverse"
                                >
                                    <Trans i18nKey="customer-request.ask-installment-agreement">Požiadajte o splátkový kalendár</Trans>
                                </ClickableElement>
                            </div>
                        )}
                    </>
                )}
            </div>

            {isLoading && <LoadingIndicator />}
        </>
    );
};

export default InvoiceDetailView;
