import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ChatHelpRoute } from '../../routes/chat-help-route';
import Routes, { SettingsRoutes } from '../../routes/routes';
import EInvoicesSettings from './e-invoices/einvoices-settings';
import SettingsHeader from './header/settings-header';
import LoginDetailsSettings from './login-details/login-details-settings';
import NotificationsSettings from './notifications/notifications-settings';

const Settings: React.FC = () => {
    const location = useLocation();

    const isMainPage = location.pathname === SettingsRoutes.LOGIN_DETAILS;

    return (
        <>
            <SettingsHeader isSubpage={!isMainPage} />
            <Switch>
                <ChatHelpRoute exact path={SettingsRoutes.LOGIN_DETAILS} contextualHelp={'NUC'} component={LoginDetailsSettings} />
                <ChatHelpRoute exact path={SettingsRoutes.EINVOICES} contextualHelp={'NUE'} component={EInvoicesSettings} />
                <ChatHelpRoute exact path={SettingsRoutes.NOTIFICATIONS} contextualHelp={'NUN'} component={NotificationsSettings} />
                <Redirect to={Routes.CUSTOMER_SETTINGS} />
            </Switch>
        </>
    );
};

export default Settings;
