import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    id: string;
    name: string;
    description: string | JSX.Element;
    isChecked?: boolean;
    onClick?: () => void;
    value?: number | string | undefined;
    isPreviouslySelected?: boolean;
    uuid?: string;
    transcript?: boolean;
};

const TariffRadioButton = forwardRef(
    ({ id, name, description, isChecked, onClick, value, isPreviouslySelected, uuid, transcript }: Props, ref: any) => {
        const [t] = useTranslation();

        const inputId = uuid ? `radio${uuid}` : `radio${id}`;
        return (
            <div className="custom-control custom-radio mt-3">
                <input
                    ref={ref}
                    type="radio"
                    name={name}
                    className="custom-control-input"
                    id={inputId}
                    value={value}
                    checked={isChecked}
                    onClick={onClick}
                    disabled={transcript ? false : isPreviouslySelected ? true : false}
                />
                <label className="custom-control-label text-left mb-3" htmlFor={inputId}>
                    <b className="d-block">
                        {id}{' '}
                        {isPreviouslySelected &&
                            (transcript
                                ? `(${t('delivery-point.detail.payment-data.tariffs.original-customer-tariff')})`
                                : `(${t('delivery-point.detail.payment-data.tariffs.your-current-tariff')})`)}
                    </b>
                    {description}
                </label>
            </div>
        );
    },
);

export default TariffRadioButton;
