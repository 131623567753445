import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../../main';
import { IUnderchargeSettlementInvoice } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IUnderchargeSettlementInvoice;
    bold?: boolean;
};

const UnderchangeSettlementInvoiceItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    const { amount, invoiceNumber, repaymentCount } = data;
    return (
        <>
            {data && (
                <ItemPreview title={t('customer-request.steps.overdue-invoice.title')}>
                    {invoiceNumber && (
                        <ItemPreviewContent bold={bold}>{`${t(
                            'customer-request.steps.overdue-invoice.invoice-number',
                        )}: ${invoiceNumber} `}</ItemPreviewContent>
                    )}
                    {amount && (
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.overdue-invoice.receivable-amount')}: ${formatCurrency(
                            amount,
                        )} `}</ItemPreviewContent>
                    )}
                    {repaymentCount && (
                        <ItemPreviewContent bold={bold}>{`${t(
                            'customer-request.steps.undercharge-settlement-invoice.repayment-count',
                        )}: ${repaymentCount} `}</ItemPreviewContent>
                    )}
                </ItemPreview>
            )}
        </>
    );
};

export default UnderchangeSettlementInvoiceItem;
