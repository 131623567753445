import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
    selectedValue: boolean;
    onValueChange: (value: boolean) => void;
};

const SharedWithMeSwitch: React.FC<Props> = ({ selectedValue, onValueChange }) => {
    const onButtonClick = (value: boolean) => {
        onValueChange(value);
    };

    const createButton = (title: string | JSX.Element, value: boolean, index: number) => {
        return (
            <button key={index} type="button" className={`btn ${value === selectedValue ? 'active' : ''}`} onClick={() => onButtonClick(value)}>
                {title}
            </button>
        );
    };

    return (
        <div className="btn-group" role="group">
            {[
                { title: <Trans i18nKey="delivery-point.list-delivery-points.filter.all">Všetky</Trans>, id: false },
                { title: <Trans i18nKey="delivery-point.list-delivery-points.filter.shared-with-me">Zdieľané so mnou</Trans>, id: true },
            ].map((item, index) => createButton(item.title, item.id, index))}
        </div>
    );
};

export default SharedWithMeSwitch;
