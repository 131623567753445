import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Collapse } from 'reactstrap';
import BaseRadio from '../../../../../../components/common/base-radio';
import Checkbox from '../../../../../../components/common/checkbox';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { formatAddress, formatBusinessPartnerName } from '../../../../../../utils/utils';

interface IDeliveryPointCollapseList extends DefaultFormType {
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerSummary;
    unitedDeliveryPoints: UnitedDeliveryPointSummary[];
    setSelectedDeliveryPoints: (val?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[]) => void;
    selectedDeliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
}

export const DeliveryPointCollapseComodity: React.FC<IDeliveryPointCollapseList> = ({
    businessPartner,
    unitedDeliveryPoints,
    register,
    errors,
    setSelectedDeliveryPoints,
    selectedDeliveryPoints,
    watch,
}) => {
    const [expandedBlockId, setExpandedBlockId] = useState<string>();
    const { t } = useTranslation();
    const { formRules } = useFormRules();

    const unitedDeliveryPoint = watch && watch('unitedDeliveryPoint');

    useEffect(() => {
        if (unitedDeliveryPoint) {
            setExpandedBlockId(unitedDeliveryPoint);
        }
    }, [unitedDeliveryPoint]);

    return (
        <div className="my-4">
            <p className="font-weight-bold d-flex my-2">
                <div>
                    {formatBusinessPartnerName(businessPartner)}
                    <br />
                    <small className="text-secondary">
                        {t('delivery-point.customer-number')} {businessPartner.externalId}
                    </small>
                </div>
            </p>
            {unitedDeliveryPoints.map((item) => {
                const dp = item.deliveryPoints && item.deliveryPoints[0];
                return (
                    <>
                        <Card key={`${businessPartner.externalId}_${dp?.externalId}`} className="mb-3">
                            <CardBody className="p-3">
                                <BaseRadio
                                    ref={register(formRules.required)}
                                    value={item.id}
                                    name="unitedDeliveryPoint"
                                    id={`unitedDeliveryPoint${item.id}`}
                                    className="mt-0"
                                    label={<b>{formatAddress(dp?.address)}</b>}
                                    onClick={() => {
                                        if (expandedBlockId !== item.id) {
                                            setExpandedBlockId(item.id);
                                            setSelectedDeliveryPoints(undefined);
                                        }
                                    }}
                                    errors={errors}
                                />
                            </CardBody>
                        </Card>
                        <Collapse isOpen={expandedBlockId === item?.id}>
                            <Card
                                key={`${businessPartner.externalId}_${dp?.externalId}`}
                                className="mb-3"
                                style={{ backgroundColor: '#dddbdb', color: 'black' }}
                            >
                                {item.deliveryPoints?.map((dp) => (
                                    <CardBody key={`${businessPartner.externalId}_${dp.externalId}_dp`} className="p-3">
                                        <Checkbox
                                            checked={selectedDeliveryPoints?.find((selectedDP) => selectedDP.id === dp.id) ? true : false}
                                            id={dp.id || 'deliveryPointId'}
                                            name={'deliveryPointId.' + dp.id}
                                            className="mt-0"
                                            label={
                                                <div className="px-4">
                                                    <b>{t(`enums.CommodityEnum.${dp.type}`)}</b>
                                                    <br />
                                                    <small className="text-secondary">
                                                        {t('delivery-point.contract-number', {
                                                            number: dp.contract?.externalId,
                                                        })}
                                                    </small>
                                                </div>
                                            }
                                            onClick={() => {
                                                const isAlreadySelected = selectedDeliveryPoints?.find((selectedDP) => selectedDP.id === dp.id)
                                                    ? true
                                                    : false;
                                                let newSelected = selectedDeliveryPoints ? [...selectedDeliveryPoints] : [];
                                                if (isAlreadySelected) {
                                                    newSelected = newSelected.filter((selectedDP) => selectedDP.id !== dp.id);
                                                } else {
                                                    dp.id && newSelected.push(dp);
                                                }
                                                if (newSelected.length === 0) {
                                                    setSelectedDeliveryPoints(undefined);
                                                } else {
                                                    setSelectedDeliveryPoints(newSelected);
                                                }
                                            }}
                                            errors={errors}
                                        />
                                    </CardBody>
                                ))}
                            </Card>
                        </Collapse>
                    </>
                );
            })}
        </div>
    );
};
