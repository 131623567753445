import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { CommodityEnum } from '../../../../../main';
import { formatAddress } from '../../../../../utils/utils';
import { DeliveryPointsSwitchFetchWrapped } from '../../delivery-point-switch/delivery-points-switch-fetch-wrapped';
import ConsumptionReadForm from './consumption-read-form';

interface IConsumptionReadModalWithDpSelectProps {
    modalLabel: string | JSX.Element;
    myRead?: boolean;
    isOpen: boolean;
    onClose: () => void;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint;
}

export const ConsumptionReadModalWithDpSelect: React.FC<IConsumptionReadModalWithDpSelectProps> = ({
    isOpen,
    onClose,
    modalLabel,
    myRead,
    unitedDeliveryPoint,
}) => {
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint | undefined>();

    const dual = useMemo(() => {
        return selectedDeliveryPoint?.type === CommodityEnum.ELECTRICITY && selectedDeliveryPoint?.contract?.eeTariffCount === 2;
    }, [selectedDeliveryPoint]);

    const helpText = useMemo(() => {
        if (selectedDeliveryPoint?.type === CommodityEnum.GAS) {
            return 'SOT_GAS_METER_WHERE_I_FIND_IT';
        } else if (selectedDeliveryPoint?.type === CommodityEnum.ELECTRICITY) {
            if (dual) {
                return 'SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT';
            } else {
                return 'SOT_ELECTRICITY_METER_WHERE_I_FIND_IT';
            }
        }

        return '';
    }, [dual, selectedDeliveryPoint?.type]);

    const [optionsCount, setOptionsCount] = useState<number>(0);

    return (
        <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
            <div className="modal-header">
                <h3 className="modal-title">{modalLabel}</h3>
                <button type="button" onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
            </div>
            <ModalBody className="px-4 pb-5">
                <h4 className="mb-4">
                    <Trans i18nKey="delivery-point.detail.consumption.delivery-point" />
                    {formatAddress(unitedDeliveryPoint.address)}
                </h4>

                {optionsCount > 1 && (
                    <h5 className="forms-headline mb-2">
                        <Trans i18nKey="delivery-point.detail.consumption.i-want-add-selfread-for" />
                    </h5>
                )}
                <div className="mb-5">
                    {unitedDeliveryPoint.id && (
                        <DeliveryPointsSwitchFetchWrapped
                            selectedDeliveryPoint={selectedDeliveryPoint}
                            onSelect={setSelectedDeliveryPoint}
                            unitedDeliveryPointId={unitedDeliveryPoint.id}
                            setOptionsCount={setOptionsCount}
                        />
                    )}
                </div>
                {selectedDeliveryPoint?.contract?.id && (
                    <ConsumptionReadForm
                        onClose={onClose}
                        deliveryPointId={selectedDeliveryPoint?.id}
                        contractId={selectedDeliveryPoint.contract.id}
                        commodityType={selectedDeliveryPoint?.type}
                        helpText={helpText}
                        myRead={myRead}
                        dual={dual}
                        lastLowValue={selectedDeliveryPoint?.readingCycleInfo?.lastLowValue}
                        lastHighValue={selectedDeliveryPoint?.readingCycleInfo?.lastHighValue}
                    />
                )}
            </ModalBody>
        </Modal>
    );
};
