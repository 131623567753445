import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import PasswordInput from '../../../../../components/common/password-input';
import HelpText from '../../../../../components/help/help-text';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IApiResponse, Input, IResponseError, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';

interface IEmailChangeFormFields {
    email: string;
    password: string;
}

interface ILoginEmailChangeModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    password?: string;
}

const LoginEmailChangeModal: React.FC<ILoginEmailChangeModalProps> = ({ isOpen, onCloseModal, password }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const [isSubmitted, setIsSubmitted] = useState<boolean>(true);
    const [newEmail, setNewEmail] = useState<string | undefined>(loggedInCustomer?.email);
    const [isPasswordVerified, setIsPasswordVerified] = useState<boolean>(password ? true : false);

    const { register, handleSubmit, errors, setError, trigger, reset } = useForm<IEmailChangeFormFields>({ defaultValues: { email: newEmail } });
    const { requiredTrimed, sppDomain } = useFormRules();

    const [t] = useTranslation();

    useEffect(() => {
        reset();
        if (isOpen) {
            setIsSubmitted(false);
            setNewEmail(undefined);
            setIsPasswordVerified(password ? true : false);
        }
    }, [isOpen, reset, password]);

    const handleEmailChangeError = (error: IResponseError): boolean => {
        if (error.code === 1003) {
            setError('password', {
                message: t('common.input-rules.incorrect-password'),
            });
            return true;
        }
        return false;
    };

    const api = useApi();
    const [mutateEmailChange, { isLoading: isLoadingEmailChange }] = useMutationWithError(
        async (emailChangeData: { email: string; password: string }) =>
            loggedInCustomer?.id != null ? api.customers.requestEmailChange(loggedInCustomer.id, emailChangeData, { secure: true }) : null,
        {
            onSuccess: () => {
                reset();
                setIsSubmitted(true);
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                setIsPasswordVerified(false);
                const error = response?.error;
                if (error == null) return false;
                return handleEmailChangeError(error);
            },
        },
    );
    const [mutateResendEmail, { isLoading: isLoadingResend }] = useMutationWithError(
        async () =>
            loggedInCustomer?.id == null ? null : api.customers.resendEmailChange(loggedInCustomer.id, { secure: true }).then((res) => res.data),
        { onErrorWithGlobalErrorHandling: () => false },
    );
    const onResend = () => mutateResendEmail();

    const [mutateVerifyPassword, { isLoading: isLoadingPasswordVerification }] = useMutationWithError(
        async (passwordData: { password: string }) =>
            loggedInCustomer?.id != null ? api.customers.verifyPasswordAccount({ password: passwordData.password }, { secure: true }) : null,
        {
            onSuccess: () => {
                setIsPasswordVerified(true);
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error == null) return false;
                return handleEmailChangeError(error);
            },
        },
    );

    const onFormSubmit = (fields: FieldValues) => {
        if (isPasswordVerified) {
            setNewEmail(fields.email);
            mutateEmailChange({ email: fields.email, password: password ? password : fields.password });
        } else {
            mutateVerifyPassword({ password: fields.password });
        }
    };

    const closeModal = () => {
        setIsSubmitted(false);
        onCloseModal();
    };

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody className="px-4 pb-5">
                    {(isLoadingEmailChange || isLoadingResend || isLoadingPasswordVerification) && <LoadingIndicator />}
                    <button type="button" onClick={closeModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="settings.change-login-email" />
                    </h3>
                    {!isSubmitted && (
                        <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                            <div hidden={!isPasswordVerified}>
                                <Input
                                    trigger={trigger}
                                    ref={register({ ...requiredTrimed, ...sppDomain })}
                                    errors={errors}
                                    type="text"
                                    name="email"
                                    label={t('settings.login-details.new-login-email')}
                                    autoFocus
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                />
                            </div>
                            <div hidden={isPasswordVerified}>
                                <h4 className="mb-2">
                                    <Trans i18nKey="settings.login-details.password-verification-required" />
                                </h4>
                                <PasswordInput
                                    ref={register({ ...requiredTrimed })}
                                    id="password"
                                    errors={errors}
                                    name={'password'}
                                    label={t('common.password')}
                                    doNotValidate
                                    trigger={trigger}
                                />
                            </div>

                            <Button type="submit" block color="primary" size="lg" className="my-4">
                                <Trans i18nKey="settings.login-details.accept-changes" />
                            </Button>
                        </form>
                    )}

                    {isSubmitted && (
                        <>
                            <h4 className="mb-3">
                                <Trans i18nKey="settings.login-details.email-change-email-sent" />
                                {'  '}
                            </h4>
                            <p>
                                <Trans
                                    i18nKey="settings.login-details.to-finish-email-change-click-link"
                                    components={{
                                        Link: (
                                            <a href={`mailto:${newEmail}`} className="font-weight-bold">
                                                &nbsp;
                                            </a>
                                        ),
                                    }}
                                    values={{ email: newEmail }}
                                />
                                {'  '}
                                <Trans i18nKey="settings.login-details.new-email-will-activate-after-login" />
                            </p>
                            <p>
                                <Trans i18nKey="settings.login-details.check-spam-folder" />
                            </p>

                            <div className="mt-4">
                                <HelpText screen="COM" field="COM_FAST_CONTACT" />
                            </div>

                            <div className="text-center mt-5">
                                <Trans i18nKey="settings.login-details.did-not-receive-email" />
                                <br />
                                <ClickableElement className="text-decoration-underline-inverse" isText onClick={onResend}>
                                    <Trans i18nKey="settings.login-details.send-again">Poslať znova</Trans>
                                </ClickableElement>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default LoginEmailChangeModal;
