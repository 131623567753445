import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { ChangeEventHandler, forwardRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';
import { accessNestedProperty, combineRefs } from '../../utils/utils';

type InputPropsType = {
    name: string;
    label?: string | JSX.Element;
    placeholder?: string;
    className?: string;
    children: JSX.Element | JSX.Element[];
    errors: { [key: string]: any };
    [key: string]: any;
    autoFocus?: boolean;
    disabled?: boolean;
    touched?: boolean;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
};

const BaseSelect = forwardRef(
    (
        { name, label, placeholder, className, children, errors, autoFocus, disabled, touched = false, onChange, ...params }: InputPropsType,
        ref: any,
    ) => {
        const localRef = useAutofocusRef<HTMLSelectElement>(autoFocus);
        const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));
        const isValid = errors == null || errorProperty == null;
        const [isTouched, setIsTouched] = useState(touched);

        return (
            <div className="mb-3">
                <FormGroup className="mb-0">
                    <select
                        {...params}
                        className={classNames('form-control', className || '', {
                            'is-invalid': !isValid && !params.disabled,
                            'is-valid': isValid && isTouched && !params.disabled,
                        })}
                        ref={combineRefs<HTMLSelectElement>([ref, localRef])}
                        name={name}
                        onChange={(e) => {
                            if (isTouched === false) {
                                setIsTouched(true);
                            }
                            onChange && onChange(e);
                        }}
                        disabled={disabled}
                        placeholder={placeholder || ' '}
                    >
                        {children}
                    </select>
                    <label className="control-label">{label}</label>
                    {errors && (
                        <>
                            <div className="invalid-feedback small d-none d-sm-block">
                                <ErrorMessage errors={errors} name={name} />
                            </div>
                            <div className="invalid-feedback small d-sm-none">
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                            </div>
                        </>
                    )}
                </FormGroup>
                {errors != null && !params.disabled && (
                    <div className="font-weight-bold text-danger ml-3 d-sm-none">
                        <ErrorMessage errors={errors} name={name} />
                    </div>
                )}
            </div>
        );
    },
);

export default BaseSelect;
