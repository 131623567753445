import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from '../../../../../../components/common/input';
import { TooltipWrapper } from '../../../../../../components/common/tooltip-wrapper';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { IRootState } from '../../../../../../reducers';

type Props = DefaultFormType & {
    objectName?: string;
};

const ContactFormFields: React.FC<Props> = ({ register, errors, objectName = '', trigger }) => {
    const { formRules, phoneRules } = useFormRules();
    const { t } = useTranslation();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <>
            <TooltipWrapper id="phoneNumber" tooltipContent={t('common.input-rules.phone-number-info')}>
                <Input
                    id="phoneNumber"
                    trigger={trigger}
                    ref={register({ ...formRules.required, ...phoneRules })}
                    errors={errors}
                    type="tel"
                    name={`${objectName}phoneNumber`}
                    label={<Trans i18nKey="customer-request.steps.contact.phone-number">Tel. číslo</Trans>}
                    touched={touched}
                />
            </TooltipWrapper>
            <Input
                trigger={trigger}
                ref={register({ ...formRules.email, ...formRules.required })}
                errors={errors}
                type="text"
                name={`${objectName}email`}
                label={<Trans i18nKey="customer-request.steps.contact.email">E-mail</Trans>}
                autoCapitalize="off"
                autoCorrect="off"
                touched={touched}
            />
        </>
    );
};

export default ContactFormFields;
