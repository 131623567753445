import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { can } from '../../../components/permissions';
import { IRootState } from '../../../reducers';
import { TABSEnums } from './delivery-point-detail';

type Props = {
    onTabChange: (value: TABSEnums) => void;
    activeTab: TABSEnums | undefined;
    children?: (navigationProps: TabsData) => void;
    unpaidExpired?: number;
    unpaid?: number;
};

export type TabsData = Record<TABSEnums, ITabProps>;

export interface ITabProps {
    name: string;
    clickEvent?: () => void;
    active: boolean;
    classes?: string;
    extraClasses?: string;
    icon: string;
    message?: string;
    visible?: boolean;
}

function DeliveryPointDetailNavigationData({ onTabChange, activeTab, children, unpaidExpired, unpaid }: Props): ReactElement {
    const [t] = useTranslation();
    const employee = useSelector((state: IRootState) => state.user.employee);

    const [navigationProps, setNavigationProps] = useState<TabsData>({
        invoices: {
            classes: 'order-1 ',
            icon: 'icon-file-text',
            active: activeTab === TABSEnums.invoices,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.invoices',
            message: '',
            visible: employee ? can('ENTITY_INVOICES_VIEW', employee) : true,
        },
        consumption: {
            classes: 'order-2 ',
            icon: 'icon-chart-bar',
            active: activeTab === TABSEnums.consumption || activeTab === TABSEnums.notes,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.consumption',
        },
        requests: {
            classes: 'order-3 ',
            extraClasses: 'd-none',
            icon: 'icon-clipboard',
            active: activeTab === TABSEnums.requests,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.requests',
            visible: employee ? can('ENTITY_REQUESTS_VIEW', employee) : true,
        },
        data: {
            classes: 'order-4 ',
            icon: 'icon-bars',
            active: activeTab === TABSEnums.data,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.data',
        },
        notes: {
            classes: 'd-none',
            icon: 'icon-book-open',
            active: activeTab === TABSEnums.notes2 || activeTab === TABSEnums.notes || activeTab === TABSEnums.consumption,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.notes',
        },
        notes2: {
            classes: 'd-none',
            icon: 'icon-book-open',
            active: false,
            clickEvent: undefined,
            name: 'delivery-point.detail.navigation.notes',
        },
    });

    useEffect(() => {
        setNavigationProps((prevState) => {
            const navigationPropsTmp = {
                ...prevState,
            };

            Object.keys(TABSEnums).forEach((key) => {
                navigationPropsTmp[key as keyof typeof TABSEnums] = {
                    ...prevState[key as keyof typeof TABSEnums],
                    active:
                        activeTab === TABSEnums[key as keyof typeof TABSEnums] || (activeTab === TABSEnums.notes && key === TABSEnums.consumption),
                };
            });
            return navigationPropsTmp;
        });
    }, [activeTab]);

    useEffect(() => {
        setNavigationProps((prevState) => {
            const navigationPropsTmp = {
                ...prevState,
            };

            Object.keys(TABSEnums).forEach((key) => {
                navigationPropsTmp[key as keyof typeof TABSEnums] = {
                    ...prevState[key as keyof typeof TABSEnums],
                    clickEvent: () => onTabChange(key as TABSEnums),
                };
            });
            return navigationPropsTmp;
        });
    }, [onTabChange]);

    useEffect(() => {
        setNavigationProps((prevState) => {
            const navigationPropsTmp = {
                ...prevState,
                invoices: {
                    ...prevState.invoices,
                    message:
                        unpaidExpired && unpaidExpired > 0
                            ? t(`delivery-point.detail.navigation.overdue-invoices`, { count: unpaidExpired })
                            : unpaid && unpaid > 0
                            ? t(`delivery-point.detail.navigation.unpaid-invoices`, { count: unpaid })
                            : '',
                },
            };

            return navigationPropsTmp;
        });
    }, [unpaidExpired, unpaid, t]);

    return <>{children && children(navigationProps)}</>;
}

export default DeliveryPointDetailNavigationData;
