import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { IAttachments } from '../../../../../../models/customer-request-model';

type Props = {
    item: IAttachments;
    onDelete: (item: IAttachments) => void;
};

const FileItem: React.FC<Props> = ({ item, onDelete }) => {
    return (
        <ListGroupItem>
            <div className="text-decoration-none d-flex justify-content-between align-items-center">
                {item.getErrorMessage != null ? (
                    <FontAwesomeIcon className="text-danger mr-2" icon={faExclamationTriangle} />
                ) : (
                    <FontAwesomeIcon className="mr-2" icon={faFile} />
                )}
                <span className="mr-auto text-cut-dots">{item.file?.name || item.name}</span>
                {item.uuid && (
                    <FontAwesomeIcon className="text-danger cursor-pointer" onClick={() => item.uuid && onDelete(item)} icon={faTrashAlt} />
                )}
            </div>
            {item.getErrorMessage != null && <span className="text-danger ml-4">{item.getErrorMessage() || ''}</span>}
        </ListGroupItem>
    );
};

export default FileItem;
