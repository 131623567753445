import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary as BusinessPartnerSummary,
    SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
} from '@spp/spp-meru-frontend-common/src/api';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { RefetchOptions } from 'react-query/types/core/query';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import {
    Checkbox,
    ClickableElement,
    CustomerRequestRoutes,
    CustomerRequestTypeEnum,
    HelpModal,
    HelpText,
    LoadingIndicator,
    MARKETING_CONSENTS_ACCEPTED,
    MARKETING_CONSENTS_REJECTED,
    RequestTemplateCode,
    expireMarketingConsentsSoon,
    formatBusinessPartnerName,
    useApi,
    useFormRules,
} from '../../../main';
import { useSendRequest } from '../../delivery-points/detail/customer-requests/use-send-request';

interface IMarketingConsentsBusinessPartnerCardProps {
    isExpanded: boolean;
    onToggle: (businessPartnerId?: string) => void;
    businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartnerUnitedDeliveryPointsSummary;
    refetchBusinessPartners: (options?: RefetchOptions | undefined) => Promise<BusinessPartnerSummary | null | undefined>;
}

interface IMarketingConsentsDataForm {
    email: boolean;
    sms: boolean;
    phone: boolean;
    letter: boolean;
    newsFromSpp: boolean;
    confirmProcessing: boolean;
}

const MarketingConsentsBusinessPartnerCard: React.FC<IMarketingConsentsBusinessPartnerCardProps> = ({
    isExpanded,
    onToggle,
    businessPartner,
    refetchBusinessPartners,
}) => {
    const { addToast } = useToasts();
    const { formRules } = useFormRules();
    const { t } = useTranslation();
    const bpConsentsEmail = businessPartner.businessPartnerConsents?.find((consent) => consent.consentCommunicationChannel === 'EMAIL');
    const bpConsentsPhone = businessPartner.businessPartnerConsents?.find((consent) => consent.consentCommunicationChannel === 'PHONE');
    const bpConsentsLetter = businessPartner.businessPartnerConsents?.find((consent) => consent.consentCommunicationChannel === 'POST');
    const bpConsentsSms = businessPartner.businessPartnerConsents?.find((consent) => consent.consentCommunicationChannel === 'SMS');

    const { register, handleSubmit, errors, watch } = useForm({
        defaultValues: {
            email: bpConsentsEmail?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
            phone: bpConsentsPhone?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
            sms: bpConsentsSms?.consent === MARKETING_CONSENTS_REJECTED ? false : true,
            letter: bpConsentsLetter?.consent === MARKETING_CONSENTS_ACCEPTED,
            newsFromSpp: businessPartner.businessPartnerConsents?.find((consent) => consent.partnersConsent),
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const initializeRequestCreationProcess = useSendRequest();
    const [expireSoon, setExpireSoon] = useState(0);
    const [isPersonalInfoProcessingConcentOpen, setIsPersonalInfoProcessingConcentOpen] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [personalInfoProcessingConcentShown, setPersonalInfoProcessingConcentShown] = useState(false);
    const [newsFromSppDisabled, setNewsFromSppDisabled] = useState(false);
    const [displayConfirmProcessing, setDisplayConfirmProcessing] = useState(true);
    const api = useApi();
    const showPersonalInfoProcessingConcent = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsPersonalInfoProcessingConcentOpen(true);
    };

    const email = watch('email');
    const sms = watch('sms');
    const phone = watch('phone');
    const letter = watch('letter');

    useEffect(() => {
        const expireSoon = expireMarketingConsentsSoon(businessPartner.businessPartnerConsents);
        if (
            // !eachMarketingConsentIsRejected(businessPartner.businessPartnerConsents) &&
            expireSoon &&
            expireSoon > 0
        ) {
            setExpireSoon(expireSoon);
        }
        if (businessPartner.consentRequestId) {
            setRequestInProgress(true);
        }
    }, [bpConsentsEmail, bpConsentsPhone, bpConsentsLetter, bpConsentsSms, businessPartner, requestInProgress]);

    useEffect(() => {
        if (email || sms || phone || letter) {
            setNewsFromSppDisabled(false);
            setDisplayConfirmProcessing(true);
        } else {
            setNewsFromSppDisabled(true);
            setDisplayConfirmProcessing(false);
        }
    }, [email, sms, phone, letter]);

    const [getRequestTemplates] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestTemplate[] | undefined,
        unknown,
        { code: RequestTemplateCode }
    >(async ({ code }) => api.customerRequestTemplates.searchTemplates({ codes: [code] }, { secure: true }).then((res) => res.data?.result));

    const onFormSubmit = async (formData: IMarketingConsentsDataForm) => {
        setLoading(true);
        const template = await getRequestTemplates({ code: CustomerRequestTypeEnum.X_MS });
        if (template?.[0] != null && businessPartner.id) {
            const data: any = {
                content: {
                    metadata: {
                        businessPartnerId: businessPartner.id,
                    },
                    type: CustomerRequestTypeEnum.X_MS,
                    consent: {
                        NEWS_SPP: formData.confirmProcessing,
                        NEWS_3RD_PARTY: formData.newsFromSpp,
                        COMMUNICATION_INT: formData.email,
                        COMMUNICATION_PAG: formData.sms,
                        COMMUNICATION_LET: formData.letter,
                        COMMUNICATION_TEL: formData.phone,
                    },
                },
                customerRequestTemplate: template[0],
            };
            initializeRequestCreationProcess(data)
                .then(() => {
                    addToast(t('settings.marketing-consents.saved-success', { customer: formatBusinessPartnerName(businessPartner) }));
                    refetchBusinessPartners();
                    onToggle(undefined);
                })
                .catch((err) => err.message)
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
            addToast(t('settings.marketing-consents.template-error', { appearance: 'error' }));
        }
    };

    useEffect(() => {
        if (isPersonalInfoProcessingConcentOpen) setPersonalInfoProcessingConcentShown(true);
    }, [isPersonalInfoProcessingConcentOpen, setPersonalInfoProcessingConcentShown]);

    return (
        <>
            <HelpModal
                show={isPersonalInfoProcessingConcentOpen}
                wide
                title="consents.privacy-policy.title"
                screen="NZD"
                field="NZD_AGREEMENT_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setIsPersonalInfoProcessingConcentOpen(false)}
            />

            <Card key={businessPartner.id} className="my-4">
                <CardHeader className="bg-white">
                    <ClickableElement
                        onClick={() => !requestInProgress && onToggle(isExpanded ? undefined : businessPartner.id)}
                        className="w-100 position-relative"
                    >
                        <div className="d-flex">
                            <div>
                                <small className="text-secondary">{t('common.customer')}</small>
                                <p className="small font-weight-bold d-flex mb-0">{formatBusinessPartnerName(businessPartner)}</p>
                            </div>
                            {!!expireSoon && (
                                <div className="ml-auto mr-4 my-auto font-weight-500 text-danger">
                                    <Trans i18nKey="settings.marketing-consents.expire-in" values={{ count: expireSoon }} />
                                </div>
                            )}
                            <div className={classNames('my-auto', { 'ml-auto': !expireSoon })}>
                                <i
                                    className={`la-xs text-secondary ml-auto my-auto smooth-transition  ${
                                        isExpanded ? 'icon-chevron-top-16' : 'icon-chevron-down-16'
                                    }`}
                                ></i>
                            </div>
                        </div>
                    </ClickableElement>
                </CardHeader>
                <Collapse isOpen={isExpanded}>
                    <CardBody className="p-4">
                        {loading && <LoadingIndicator />}
                        {!!expireSoon && (
                            <div className="mb-3 font-weight-500 text-danger">
                                <Trans i18nKey="settings.marketing-consents.update-consents" />
                            </div>
                        )}
                        <p className="font-weight-500 text-black">{t('settings.marketing-consents.communication-option')}</p>
                        <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id={`email_${businessPartner.id}`}
                                name="email"
                                disabled={requestInProgress}
                                label={
                                    <div className="d-flex">
                                        <div className="font-weight-500 text-black">{t('settings.marketing-consents.email')}</div>
                                        <small className="font-weight-bold text-black marketing-consent-recommended ml-2 px-2 my-auto">
                                            {t('settings.marketing-consents.recommended')}
                                        </small>
                                    </div>
                                }
                            />
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id={`sms_${businessPartner.id}`}
                                name="sms"
                                disabled={requestInProgress}
                                label={<span className="font-weight-500 text-black">{t('settings.marketing-consents.sms')}</span>}
                            />
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id={`phone_${businessPartner.id}`}
                                name="phone"
                                disabled={requestInProgress}
                                label={<span className="font-weight-500 text-black">{t('settings.marketing-consents.phone')}</span>}
                            />
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id={`letter_${businessPartner.id}`}
                                name="letter"
                                disabled={requestInProgress}
                                label={<span className="font-weight-500 text-black">{t('settings.marketing-consents.letter')}</span>}
                            />
                            <div className="text-secondary mb-4">
                                <Trans i18nKey="settings.marketing-consents.news-send-to-your-customer-data" />
                            </div>
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id={`newsFromSpp_${businessPartner.id}`}
                                name="newsFromSpp"
                                disabled={newsFromSppDisabled || requestInProgress}
                                label={<HelpText screen="NUN" field="NUN_NEWS_SPP_PARTNERS" />}
                            />
                            {displayConfirmProcessing && (
                                <Checkbox
                                    ref={register(formRules.required)}
                                    errors={errors}
                                    id={`confirmProcessing_${businessPartner.id}`}
                                    name="confirmProcessing"
                                    disabled={requestInProgress}
                                    label={
                                        <span className="font-weight-500 text-black">
                                            <Trans
                                                i18nKey="settings.marketing-consents.info-processing"
                                                components={{
                                                    Link: (
                                                        <ClickableElement
                                                            isText
                                                            onClick={showPersonalInfoProcessingConcent}
                                                            className="text-decoration-underline-inverse text-black"
                                                        >
                                                            &nbsp
                                                        </ClickableElement>
                                                    ),
                                                }}
                                            />
                                        </span>
                                    }
                                    onChange={() => {
                                        if (!personalInfoProcessingConcentShown) setIsPersonalInfoProcessingConcentOpen(true);
                                    }}
                                />
                            )}
                            <Button type="submit" color="primary" size="lg" className="mt-2" disabled={requestInProgress}>
                                {!!expireSoon ? <Trans i18nKey="common.update">Aktualizovať</Trans> : <Trans i18nKey="common.save">Uložiť</Trans>}
                            </Button>
                        </form>
                    </CardBody>
                </Collapse>
                {requestInProgress && (
                    <div className="p-4 font-weight-500 text-secondary">
                        <Trans
                            i18nKey="settings.marketing-consents.request-in-progress"
                            components={{
                                Link: <Link to={`${CustomerRequestRoutes.MY_LIST}/${businessPartner.consentRequestId}`} className="text-secondary" />,
                            }}
                        />
                    </div>
                )}
            </Card>
        </>
    );
};

export default MarketingConsentsBusinessPartnerCard;
