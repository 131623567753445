import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as PagedResponseUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryCounts as UnitedDeliveryCounts,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch as UnitedDeliveryPointSearch,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddDeliveryPointActions } from '../../actions/add-delivery-point-actions';
import FullTextSearchBox from '../../components/common/full-text-search-box';
import LoadingIndicator from '../../components/common/loading-indicator';
import Paginator from '../../components/common/paginator';
import SharedWithMeSwitch from '../../components/common/shared-with-me-switch';
import { ISort, SortDirection } from '../../components/common/table/table-interfaces';
import HelpText from '../../components/help/help-text';
import { useApi } from '../../hooks/use-api';
import { useKeyValueStore } from '../../hooks/use-key-value';
import { usePaginatedQueryWithError } from '../../hooks/use-paginated-query-with-error';
import { useQueryWithError } from '../../hooks/use-query-with-error';
import { SessionStorageEnum, useSessionStorage } from '../../hooks/use-session-storage';
import { useUrlQuery } from '../../hooks/use-url-query';
import { useWindowSize } from '../../hooks/use-window-size';
import { IApiResponse } from '../../models/model';
import { IRootState } from '../../reducers';
import Routes from '../../routes/routes';
import AddDeliveryPoint from './add-delivery-point';
import DpIsLoadingDialog from './component/dp-is-loading-dialog';
import ListDeliveryPointsCards from './component/list-delivery-points-cards';
import DeliveryPointFirstLoad from './component/list-delivery-points-first-load';
import ListDeliveryPointsSwitchView from './component/list-delivery-points-switch-view';
import ListDeliveryPointsTable from './component/list-delivery-points-table';
import { DeliveryPointsTableFilterData } from './component/list-delivery-points-table-filter';
import PhoneNumberVerification from './component/phone-number-verification/phone-number-verification';
import { DeliveryPointsHeader } from './delivery-points-header';

const expandedDeliveryPointsCount = 5;

const ListDeliveryPoints: React.FC = () => {
    const pageSize = 10;

    const dispatch = useDispatch();

    // persistent fields
    const [fullText, setFullText] = useKeyValueStore(Routes.DELIVERY_POINTS + '@fullText', '');
    const [tableMode, setTableMode] = useKeyValueStore(Routes.DELIVERY_POINTS + '@tableMode', true);
    const [pageIndex, setPageIndex] = useState(0);
    const [, setStoredPageIndex] = useKeyValueStore(Routes.DELIVERY_POINTS + '@pageIndex', 0);

    const [sharedWithMe, setSharedWithMe] = useKeyValueStore(Routes.DELIVERY_POINTS + '@sharedWithMe', false);

    const [deliveryPoints, setDeliveryPoints] = useState<UnitedDeliveryPointSummary[]>();
    const [deliveryPointsPaginationTotalCount, setDeliveryPointsPaginationTotalCount] = useState<number>(0);

    const [deliveryPointsAllTotalCount, setDeliveryPointsAllTotalCount] = useState<number | null>(null);
    const [deliveryPointsInactiveTotalCount, setDeliveryPointsInactiveTotalCount] = useState<number | null>(null);
    const [deliveryPointsOwnedTotalCount, setDeliveryPointsOwnedTotalCount] = useState<number | null>(null);
    const [deliveryPointsSharedTotalCount, setDeliveryPointsSharedTotalCount] = useState<number | null>(null);
    const [showInactiveDeliveryPoints, setShowInactiveDeliveryPoints] = useSessionStorage<boolean>(
        SessionStorageEnum.showInactiveDeliveryPoints,
        false,
    );

    const [showIsLoadingDialog, setShowIsLoadingDialog] = useState<boolean>(false);
    const [_sort, setSort] = useKeyValueStore<ISort | undefined>(Routes.DELIVERY_POINTS + '@sort', undefined);

    const sort = useMemo<ISort | undefined>(() => {
        // validate partial sort
        if (_sort != null && _sort.attribute != null && _sort.direction != null) {
            return { attribute: _sort.attribute, direction: _sort.direction };
        }
        return undefined;
    }, [_sort]);

    const [tableColumnFilter, setTableColumnFilter] = useKeyValueStore<DeliveryPointsTableFilterData>(
        Routes.DELIVERY_POINTS + '@tableColumnFilter',
        {},
    );

    const user = useSelector((s: IRootState) => s.user.customer);

    const urlQuery = useUrlQuery();
    const queriedPage = Number(urlQuery.get('page')) || undefined;

    const api = useApi();
    const history = useHistory();

    const windowSize = useWindowSize();

    const showFullTextSearch = useCallback(
        function(): boolean {
            return !!deliveryPointsAllTotalCount && deliveryPointsAllTotalCount >= expandedDeliveryPointsCount;
        },
        [deliveryPointsAllTotalCount],
    );

    const showDataInTable = useCallback(
        function(): boolean {
            // to show data in table (not cards)
            return !!deliveryPointsAllTotalCount && deliveryPointsAllTotalCount >= expandedDeliveryPointsCount;
        },
        [deliveryPointsAllTotalCount],
    );

    const isMobileScreen = useCallback(
        function(): boolean {
            return !!windowSize.width && windowSize.width <= 1024;
        },
        [windowSize.width],
    );

    const fetchDeliveryPoint = useCallback(
        async (uuid: string, search: UnitedDeliveryPointSearch = {}) => {
            if (!uuid) {
                return;
            }

            const showDPsAsCards = !showDataInTable() || !tableMode || isMobileScreen();
            if (search.paging?.sort && (showDPsAsCards || !search.paging.sort[0].attribute)) {
                // if DPs are shown as cards the following sorting must be applied
                search.paging.sort = [
                    { attribute: 'ownershipType', direction: 'ASC' },
                    { attribute: 'customerEmail', direction: 'ASC' },
                    { attribute: 'bpExternalId', direction: 'ASC' },
                ];
            }

            if (showDPsAsCards && search.businessPartner && search.deliveryPoint) {
                // if DPs are shown as cards the following filter must be not applied
                search.businessPartner.ft = '';
                search.deliveryPoint.ftId = '';
                search.deliveryPoint.ftAddress = '';
                search.type = []; // all commodities
            }

            if (!showDPsAsCards || !showFullTextSearch()) {
                // if the DPs are shown in table or the full text filter is not shown in cards view,
                // the following filter must be not applied
                search.ft = '';
            }

            return api.customers.searchUnitedDeliveryPoint(uuid, search, undefined, { secure: true });
        },
        [api.customers, tableMode, isMobileScreen, showFullTextSearch, showDataInTable],
    );

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    const { isLoading, isFetching, refetch: refetchDeliveryPoints } = usePaginatedQueryWithError<PagedResponseUnitedDeliveryPointSummary | null>({
        queryKey: [
            'delivery-points-search',
            sharedWithMe,
            showInactiveDeliveryPoints,
            tableColumnFilter,
            fullText,
            pageIndex,
            sort,
            tableMode,
            !showDataInTable() || !tableMode || isMobileScreen(), // condition to show data as cards
            showFullTextSearch(),
            user?.id,
        ],
        queryFn: () =>
            user?.id != null
                ? fetchDeliveryPoint(user.id, {
                      shared: sharedWithMe ? sharedWithMe : undefined,
                      includeInactive: showInactiveDeliveryPoints,
                      type: tableColumnFilter?.type,
                      businessPartner: {
                          ft: tableColumnFilter?.customer,
                      },
                      ft: fullText,
                      deliveryPoint: {
                          ftId: tableColumnFilter?.dpExternalId,
                          ftAddress: tableColumnFilter?.address,
                          hidden: false,
                      },
                      paging: {
                          page: pageIndex,
                          size: pageSize,
                          sort: [
                              {
                                  attribute: sort?.attribute,
                                  direction: sort?.direction,
                              },
                          ],
                      },
                  }).then((response) => (response != null ? response.data : null))
                : null,
        config: {
            onSuccess: (data: PagedResponseUnitedDeliveryPointSummary | null) => {
                setDeliveryPoints(data?.result || undefined);
                setDeliveryPointsPaginationTotalCount(data?.paging?.total || 0);
                if (data?.paging?.page != null && data.paging.size != null && data.paging.total != null) {
                    if (data.paging.page * data.paging.size > data.paging.total) {
                        onPageChange(0);
                    }
                }
            },
            onError: (err: IApiResponse) => {
                return false;
            },
        },
    });

    const { isLoading: isLoadingDPCount, refetch: refetchDPCount, error: errorDPCount } = useQueryWithError<UnitedDeliveryCounts | null>(
        ['delivery-points-search-count', user?.id],
        async () =>
            user?.id ? api.customers.unitedDeliveryPointSearchCount(user?.id, {}, { secure: true }).then((response) => response.data) : null,
        {
            onSuccess: (totalCount: UnitedDeliveryCounts | null) => {
                setDeliveryPointsOwnedTotalCount(totalCount?.owned || 0);
                setDeliveryPointsAllTotalCount(totalCount?.all || 0);
                setDeliveryPointsInactiveTotalCount(totalCount?.inactive || 0);
                setDeliveryPointsSharedTotalCount(totalCount?.shared || 0);
                if (!totalCount?.shared) {
                    setSharedWithMe(false);
                }
            },
            onError: () => {
                return false;
            },
        },
    );

    const loadDeliveryPoint = function() {
        dispatch(AddDeliveryPointActions.start(true));
    };

    const filterBy = function(filterData: DeliveryPointsTableFilterData) {
        setTableColumnFilter(filterData);
        onPageChange(0);
    };

    const handleSortChange = (field: keyof DeliveryPointsTableFilterData, dir: SortDirection | undefined) => {
        if (dir) {
            setSort({ attribute: field, direction: dir });
        } else {
            setSort(undefined);
        }
        onPageChange(0);
    };

    const onOpen = (deliveryPointSummary: UnitedDeliveryPointSummary) => {
        history.push(`${Routes.DELIVERY_POINTS}/${deliveryPointSummary.id || ''}`);
    };

    const onLoading = () => {
        setShowIsLoadingDialog(true);
    };

    useEffect(() => {
        const isWaitingForPairing = deliveryPoints?.some((deliveryPoint) => deliveryPoint.pairingStatus === 'IN_PROGRESS');
        if (isWaitingForPairing) {
            const timerId = setInterval(() => {
                refetchDeliveryPoints();
            }, 30000);
            return () => clearTimeout(timerId);
        }
    }, [deliveryPoints, refetchDeliveryPoints]);

    return (
        <>
            <PhoneNumberVerification />
            <AddDeliveryPoint
                reloadDeliveryPoints={() => {
                    refetchDeliveryPoints();
                    refetchDPCount();
                }}
            />
            <DpIsLoadingDialog visible={showIsLoadingDialog} onClick={() => setShowIsLoadingDialog(false)}></DpIsLoadingDialog>

            <DeliveryPointsHeader
                showInactiveDeliveryPointsToggler={!!deliveryPointsInactiveTotalCount}
                showInactiveDeliveryPoints={showInactiveDeliveryPoints ? showInactiveDeliveryPoints : false}
                toggleShowInactiveDeliveryPoints={() => {
                    setShowInactiveDeliveryPoints(!showInactiveDeliveryPoints);
                    onPageChange(0);
                }}
            />

            <div className="container" style={{ position: 'relative' }}>
                <div className="row">
                    <div className="col">
                        <div id="banner"></div>
                    </div>
                </div>

                {(isLoading || isLoadingDPCount) && !isFetching && <LoadingIndicator fullscreen />}
                <>
                    {deliveryPointsOwnedTotalCount === 0 && <DeliveryPointFirstLoad loadDeliveryPoint={loadDeliveryPoint} />}

                    {errorDPCount && (
                        <div className="alert p-4 d-flex bg-danger text-white mt-5">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                            <div className="ml-4 font-weight-bold">
                                <Trans i18nKey="delivery-point.list-delivery-points.load-my-delivery-points-error">
                                    Ľutujeme, odberné miesta sa nepodarilo načítať. Akciu skúste neskôr.
                                </Trans>
                            </div>
                        </div>
                    )}
                    {!!deliveryPointsOwnedTotalCount && (
                        <div className="row align-items-center mb-4">
                            {!!deliveryPointsSharedTotalCount && (
                                <div className="col-12 col-md-auto mb-3 mb-md-0">
                                    <SharedWithMeSwitch
                                        selectedValue={sharedWithMe}
                                        onValueChange={(val: boolean) => {
                                            setSharedWithMe(val);
                                            onPageChange(0);
                                        }}
                                    />
                                </div>
                            )}

                            <div className="col">
                                {(!showDataInTable() || !tableMode || isMobileScreen()) && showFullTextSearch() ? (
                                    <FullTextSearchBox
                                        fullText={fullText}
                                        setFullText={(newFullText) => {
                                            setFullText(newFullText);
                                            onPageChange(0);
                                        }}
                                        className="ml-auto max-width-300"
                                    />
                                ) : null}
                            </div>

                            {showDataInTable() && !isMobileScreen() ? (
                                <ListDeliveryPointsSwitchView
                                    tableMode={tableMode}
                                    setTableMode={(tableMode) => {
                                        setTableMode(tableMode);
                                        onPageChange(0);
                                    }}
                                />
                            ) : null}
                        </div>
                    )}

                    {!showDataInTable() || !tableMode || isMobileScreen() ? (
                        <>
                            {isFetching ? (
                                <LoadingIndicator fullscreen />
                            ) : (
                                <ListDeliveryPointsCards deliveryPoints={deliveryPoints} onLoading={onLoading} onOpen={onOpen} />
                            )}
                        </>
                    ) : (
                        <ListDeliveryPointsTable
                            isFetching={isFetching}
                            deliveryPoints={deliveryPoints}
                            onLoading={onLoading}
                            onOpen={onOpen}
                            sort={sort}
                            handleSortChange={handleSortChange}
                            filterBy={filterBy}
                            tableColumnFilter={tableColumnFilter}
                        />
                    )}

                    {!isFetching && (
                        <Paginator
                            pageIndex={pageIndex}
                            dataLength={deliveryPointsPaginationTotalCount}
                            onPageChanged={onPageChange}
                            pageSize={pageSize}
                        />
                    )}
                    <div className="mb-2 mt-5">
                        <HelpText screen="ODM" field="ODM_ADVERTISEMENT" />
                    </div>
                </>
            </div>
        </>
    );
};

export default ListDeliveryPoints;
