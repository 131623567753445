import React from 'react';
import { IMeterReading } from '../../../../../../models/customer-request-model';
import DeliveryPointMeteringItem from './reading-item';

type Props = {
    data: IMeterReading[];
    bold?: boolean;
};

const ReadingsItem: React.FC<Props> = ({ data, bold }) => {
    return (
        <>
            {data.map((item, index) => (
                <DeliveryPointMeteringItem key={index} data={item} bold={bold} />
            ))}
        </>
    );
};

export default ReadingsItem;
