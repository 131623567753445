import React from 'react';

export const useAutofocusRef = <T extends HTMLElement>(enabled: boolean | undefined, delay?: number): React.RefObject<T> => {
    const localRef = React.useRef<T>(null);
    React.useEffect(() => {
        if (enabled) {
            setTimeout(() => localRef.current?.focus(), delay);
        }
    }, [enabled, delay]);
    return localRef;
};
