import {
    SkSppNzpBeApiCustomeraccessCustomerAccount,
    SkSppNzpBeApiIdentitymanagementCustomer,
    SkSppNzpCommonsApiEmployeeaccessEmployee,
} from '@spp/spp-meru-frontend-common/src/api';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import useSso from '../../../hooks/sso/use-sso';
import Routes, { PortalAccountsRoutes } from '../../../routes/routes';
import { ClickableElement } from '../../common';
import { Can, can } from '../../permissions';
import Brand from '../components/brand';
import HamburgerNavButton from '../components/hamburger-nav-button';
import ProfileMenu from '../components/profile-menu';
import RedirectLinks from '../components/redirect-links';
import NavLinksEmployee from './nav-links-employee';

type NavbarEmployeePropsType = {
    customer?: SkSppNzpBeApiIdentitymanagementCustomer;
    employee?: SkSppNzpCommonsApiEmployeeaccessEmployee;
    logout: () => void;
    goHome: () => void;
    employeeAsCustomer: boolean;
};

const NavbarEmployee: React.FC<NavbarEmployeePropsType> = ({ customer, employee, logout, goHome, employeeAsCustomer }) => {
    const [collapsed, setCollapsed] = useState(true);

    const fullName = customer ? customer.firstName + ` ${customer.lastName || ''}` : '';

    const customerId = customer?.id ?? '';

    const { ssoActorEmployeePartialLogout, ssoActorEmployeeLogin } = useSso();

    const toggleMenu = () => {
        setCollapsed((o) => !o);
    };

    const closeMenu = () => {
        setCollapsed(true);
    };

    const loginAsCustomer = () => {
        ssoActorEmployeeLogin(customerId, getCustomerViewHomeLink());
    };

    const getCustomerViewHomeLink = (): string => {
        if (employee) {
            if (can('ENTITY_DELIVERY_POINTS_VIEW', employee)) return Routes.DELIVERY_POINTS;
            if (can('ENTITY_INVOICES_VIEW', employee)) return Routes.INVOICES;
            if (can('ENTITY_REQUESTS_VIEW', employee)) return Routes.CUSTOMER_REQUESTS;
        }
        return Routes.CUSTOMER_SETTINGS;
    };

    if (!employee) return null;
    const pathname = window.location.pathname;

    return (
        <div className="nav-container" style={{ backgroundColor: employeeAsCustomer ? 'black' : '#FFD500' }}>
            <div className="container-lg">
                {!customer && (
                    <div className="navbar navbar-expand-lg navbar-light position-relative px-0">
                        <Brand extraClass="order-1" onClick={goHome} />
                        <HamburgerNavButton extraClass="order-3" collapsed={collapsed} onToggle={toggleMenu} />
                        <Collapse isOpen={!collapsed} navbar className="order-4 order-lg-2" id="navbarSupportedContent">
                            <NavLinksEmployee linkClicked={closeMenu} />
                            <RedirectLinks linkClicked={closeMenu} />
                        </Collapse>
                        <ProfileMenu linkClicked={closeMenu} userDetails={employee} logout={logout} />
                    </div>
                )}

                {!!customer && (
                    <div className="navbar navbar-expand-lg navbar-light position-relative py-3 px-0">
                        <button
                            className="navbar-brand btn btn-primary"
                            style={{
                                paddingLeft: '1rem',
                                color: employeeAsCustomer ? 'black' : 'white',
                                backgroundColor: employeeAsCustomer ? 'white' : 'black',
                            }}
                            onClick={() => {
                                ssoActorEmployeePartialLogout();
                            }}
                        >
                            <i className="icon-logout"></i> <Trans i18nKey="action.logout">Odhlásiť</Trans>
                        </button>
                        <Collapse navbar>
                            <ul className="navbar-nav mx-auto pt-3 pt-lg-0">
                                <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                                    <Link
                                        className={classNames('nav-link', 'nav-link3', 'py-3', {
                                            'link3-active': pathname.includes(PortalAccountsRoutes.CUSTOMERS_INDEX),
                                            'nav-link4':
                                                employeeAsCustomer &&
                                                !pathname.includes(PortalAccountsRoutes.CUSTOMERS_INDEX) &&
                                                !pathname.includes('activity-logs/customer'),
                                        })}
                                        style={{ color: employeeAsCustomer ? 'white' : 'black' }}
                                        to={PortalAccountsRoutes.CUSTOMER_DETAIL.replace(':customerId', customerId)}
                                    >
                                        <Trans i18nKey="navbar.navigation.dashboard">Dashboard</Trans>
                                    </Link>
                                </li>
                                {(customer?.status === 'PRE_REGISTRATION' ||
                                    customer?.status === 'ACTIVE' ||
                                    customer?.status === 'PRE_REGISTRATION_BATCH' ||
                                    customer?.status === 'PRE_REGISTRATION_SAP') && (
                                    <Can
                                        permissions="CUSTOMERS_ACTOR_EMPLOYEE"
                                        queue={(customer as SkSppNzpBeApiCustomeraccessCustomerAccount).queueCategory}
                                    >
                                        <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                                            <ClickableElement
                                                onClick={loginAsCustomer}
                                                className={classNames('nav-link', 'nav-link3', 'py-3', {
                                                    'link4-active':
                                                        employeeAsCustomer &&
                                                        !pathname.includes(PortalAccountsRoutes.CUSTOMERS_INDEX) &&
                                                        !pathname.includes('activity-logs/customer'),
                                                    'nav-link4':
                                                        employeeAsCustomer &&
                                                        !pathname.includes(PortalAccountsRoutes.CUSTOMERS_INDEX) &&
                                                        !pathname.includes('activity-logs/customer'),
                                                })}
                                                style={{ color: employeeAsCustomer ? 'white' : 'black' }}
                                            >
                                                <Trans i18nKey="navbar.navigation.customer-view">Pohľad zákazníka</Trans>
                                            </ClickableElement>
                                        </li>
                                    </Can>
                                )}
                                <Can permissions={['AUDIT_LOGS_EMPLOYEES', 'AUDIT_LOGS_CUSTOMERS_WITH_EMPLOYEES', 'AUDIT_LOGS_CUSTOMERS']}>
                                    <li className="nav-item my-4 my-lg-0 mx-0 mx-lg-2">
                                        <Link
                                            className={classNames('nav-link', 'nav-link3', 'py-3', {
                                                'link3-active': pathname.includes('/portal-accounts/activity-logs/'),
                                                'nav-link4':
                                                    employeeAsCustomer &&
                                                    !pathname.includes(PortalAccountsRoutes.CUSTOMERS_INDEX) &&
                                                    !pathname.includes('activity-logs/customer'),
                                            })}
                                            style={{ color: employeeAsCustomer ? 'white' : 'black' }}
                                            to={PortalAccountsRoutes.CUSTOMER_ACTIVITY_LOGS.replace(':customerId', customerId)}
                                        >
                                            <Trans i18nKey="navbar.navigation.activity">Aktivita</Trans>
                                        </Link>
                                    </li>
                                </Can>
                            </ul>
                            <hr className="navigation-divider" />
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <span className="nav-link my-3 my-lg-0 py-3 py-lg-0 d-flex flex-row d-lg-none">
                                        <i className="icon-user fa-lg mr-4 d-lg-none" />
                                        <div className="mt-n2">
                                            <div className="forms-headline d-lg-none">
                                                <Trans i18nKey="navbar.logged-as-customer" style={{ color: employeeAsCustomer ? 'white' : '#777' }}>
                                                    Prihlásený ako zákazník
                                                </Trans>
                                            </div>
                                            <span className="text-truncate" style={{ color: employeeAsCustomer ? 'white' : 'rgba(0,0,0,.5)' }}>
                                                {fullName}
                                            </span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </Collapse>
                        <div className="navbar-text d-none d-lg-block small">
                            <span style={{ color: employeeAsCustomer ? 'white' : '#0b0b0b' }}>
                                <Trans i18nKey="navbar.logged-as-customer" style={{ color: employeeAsCustomer ? 'white' : '#777' }}>
                                    Prihlásený ako zákazník
                                </Trans>
                            </span>
                            <br />
                            <span className="text-truncate" style={{ color: employeeAsCustomer ? 'white' : 'rgba(0,0,0,.5)' }}>
                                {fullName}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavbarEmployee;
