import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { ClickableElement } from '../clickable-element';

interface ITableFilterControlProps {
    hideFilterButton?: boolean;
    isFilterActive: boolean;
    onEditFilter: () => void;
    onRemoveFilter: () => void;
    filterDescription?: React.ReactNode;
}

const TableFilterControl: React.FC<ITableFilterControlProps> = ({
    hideFilterButton = false,
    isFilterActive,
    onEditFilter,
    onRemoveFilter,
    filterDescription,
}) => {
    const editFilterButton = (
        <ClickableElement onClick={onEditFilter}>
            <div className="text-center my-auto py-3 p-md-0">
                <i className="icon-edit-16 fa-lg mr-2 mr-md-0 text-decoration-none d-inline-block d-md-block" />
                <Trans i18nKey="common.tables.edit-filter" />
            </div>
        </ClickableElement>
    );

    const removeFilterButton = (
        <ClickableElement onClick={onRemoveFilter}>
            <div className="text-center text-danger my-auto py-3 p-md-0">
                <div className="d-inline-block d-md-block">
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" className="fa-lg mr-2 mr-md-0 text-decoration-none" />
                </div>
                <Trans i18nKey="common.tables.remove" />
            </div>
        </ClickableElement>
    );

    return (
        <>
            {isFilterActive ? (
                <Card className="table-filter-control-card my-4 mx-0">
                    <div className="border-strip border-strip--top d-block d-md-none"></div>
                    <CardBody className="pt-3 pb-0 px-0 px-md-4 py-md-3">
                        <Row className="align-items-right">
                            <Col xs={12} sm={12} md={8} xl={10} className="d-none d-md-inline-block mb-md-0 align-self-center">
                                <b className="mb-0 d-block">
                                    <Trans i18nKey="common.tables.filter-is-active" />
                                </b>
                                {filterDescription != null && <div>{filterDescription}</div>}
                            </Col>
                            <Col xs={12} sm={12} md={8} xl={10} className="text-center d-block d-md-none mb-3 mb-md-0">
                                <b className="mb-0">
                                    <Trans i18nKey="common.tables.filter-is-active" />
                                </b>
                            </Col>
                            <Col xs={6} sm={6} md={2} xl={1} className="pr-0">
                                <div className="d-block d-md-none" style={{ borderTop: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
                                    {editFilterButton}
                                </div>
                                <div className="d-none d-md-block">{editFilterButton}</div>
                            </Col>
                            <Col xs={6} sm={6} md={2} xl={1} className="pl-0">
                                <div className="d-block d-md-none" style={{ borderTop: '1px solid #f0f0f0' }}>
                                    {removeFilterButton}
                                </div>
                                <div className="d-none d-md-block">{removeFilterButton}</div>
                            </Col>
                        </Row>
                    </CardBody>
                    <div className="border-strip border-strip--bottom d-none d-md-inline-block"></div>
                </Card>
            ) : (
                !hideFilterButton && (
                    <Row className="my-3">
                        <Col xs={12} className="text-right">
                            <ClickableElement isText onClick={onEditFilter} className="text-decoration-underline-inverse">
                                <div className="p-3 m-n3 d-inline-block">
                                    <Trans i18nKey="common.tables.show-filter" />
                                </div>
                            </ClickableElement>
                        </Col>
                    </Row>
                )
            )}
        </>
    );
};

export default TableFilterControl;
