import { Reducer } from 'react';
import { KeyValueStoreActionsEnum, KeyValueStoreActionsType } from '../actions/key-value-store-actions';

export interface IKeyValueStore {
    [key: string]: unknown;
}

const initialState: IKeyValueStore = {
    privacyScreen: false,
};

export const KeyValueStoreReducer: Reducer<IKeyValueStore, KeyValueStoreActionsType> = (state = initialState, action) => {
    switch (action.type) {
        case KeyValueStoreActionsEnum.SET_VALUE: {
            const key = action.payload.key;
            const value = action.payload.value;
            return {
                ...state,
                [key]: value,
            };
        }
        case KeyValueStoreActionsEnum.CLEAR_ALL: {
            return {};
        }
        default:
            return state;
    }
};
