// setError(name: FieldName<TFieldValues>, error: ErrorOption): void;

import { TFunction } from 'i18next';
import { ErrorOption } from 'react-hook-form';
import { IResponseError } from '../models/model';

export const handlePasswordError = (t: TFunction, setError: (obj: ErrorOption) => void, error: IResponseError): void => {
    const errors = error.errors;
    if (errors == null) return;
    if (errors.includes('HISTORICAL_PASSWORD')) {
        setError({
            type: 'historical-password',
            message: t('common.input-rules.historical-password'),
        });
    } else if (errors.includes('LOGIN_INCLUDED')) {
        setError({
            type: 'login-included',
            message: t('common.input-rules.login-included'),
        });
    } else if (errors.includes('INSUFFICIENT_SPECIAL')) {
        setError({
            type: 'mustContainSpecialChar',
            message: t('common.input-rules.mustContainSpecialChar'),
        });
    } else if (errors.includes('INSUFFICIENT_DIGIT')) {
        setError({
            type: 'mustContainSpecialChar',
            message: t('common.input-rules.mustContainSpecialChar'),
        });
    } else if (errors.includes('INSUFFICIENT_UPPERCASE')) {
        setError({
            type: 'mustContainUpperCase',
            message: t('common.input-rules.mustContainUpperCase'),
        });
    } else if (errors.includes('INSUFFICIENT_LOWERCASE')) {
        setError({
            type: 'mustContainLowerCase',
            message: t('common.input-rules.mustContainLowerCase'),
        });
    } else if (errors.includes('TOO_LONG')) {
        setError({
            type: 'too-long-password',
            message: t('common.input-rules.too-long-password'),
        });
    } else if (errors.includes('TOO_SHORT')) {
        setError({
            type: 'too-short-password',
            message: t('common.input-rules.too-short-password'),
        });
    } else if (errors.includes('INSUFFICIENT_CHARACTERISTICS')) {
        setError({
            type: 'password-does-not-meet-requirements',
            message: t('common.input-rules.password-does-not-meet-requirements'),
        });
    } else {
        setError({
            type: 'password-does-not-meet-requirements',
            message: t('common.input-rules.password-does-not-meet-requirements'),
        });
    }
};
