import { SkSppNzpBeApiCommonPaging } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { LoadingIndicator } from '../../../../../components/common';
import Paginator from '../../../../../components/common/paginator';
import { useApi } from '../../../../../hooks/use-api';
import useCodeList from '../../../../../hooks/use-code-list';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { ConsumptionValueUnits, MeteringTypes } from '../../../../../models/enums';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { getUnits } from '../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../customer-request/config/enums';
import { CommodityProps, MeteringTypeEnum } from '../consumption';
import useFetchMeterReading from '../use-fetch-meter-reading';
import ConsumptionRow from './consumption-row';

const DEFAULT_PAGING: SkSppNzpBeApiCommonPaging = {
    size: 10,
    page: 0,
    sort: [
        {
            attribute: 'readAt',
            direction: 'DESC',
        },
    ],
};
type Props = {
    deliveryPointUuid: string;
    timestampFrom?: string;
    timestampTo?: string;
    units: ConsumptionValueUnits;
    meteringType: MeteringTypeEnum;
    selectedCommodity: CommodityProps;
    contractId?: string;
};

const ConsumptionTable: React.FC<Props> = ({
    deliveryPointUuid,
    timestampFrom,
    timestampTo,
    units,
    meteringType,
    selectedCommodity,
    contractId,
}: Props) => {
    const api = useApi();
    const queryCache = useQueryCache();
    const { addToast } = useToasts();

    const [paging, setPaging] = useState<SkSppNzpBeApiCommonPaging>(DEFAULT_PAGING);

    const { isLoading, data, error } = useFetchMeterReading({
        contractId: contractId,
        timestampFrom,
        timestampTo,
        paging,
        categories:
            meteringType === MeteringTypeEnum.ALL_METERING
                ? [MeteringTypes.SAP, MeteringTypes.INFO]
                : meteringType === MeteringTypeEnum.DISTRIBUTION_METERING
                ? [MeteringTypes.SAP]
                : meteringType === MeteringTypeEnum.MY_DIARY
                ? [MeteringTypes.INFO]
                : [],
        units: units === ConsumptionValueUnits.CUBIC_METRE ? 'M3' : 'KWH',
        selectedCommodity,
    });

    const { data: meterReadingKindCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_METER_READING_KIND,
        codeListTypeEnum: CodeListTypeEnum.METER_READING_KIND,
        paging: {
            size: 64,
        },
    });

    const { data: meterReadingReasonCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_METER_READING_REASON,
        codeListTypeEnum: CodeListTypeEnum.METER_READING_REASON,
        paging: {
            size: 31,
        },
    });

    const [mutateMyConsumption] = useMutationWithError(async (uuid: string) => api.meterReadings.deleteMeterReadingInfo(uuid, { secure: true }), {
        onSuccess: () => {
            queryCache.invalidateQueries(QueryKeysEnum.CONSUMPTION_TABLE);
        },
        onErrorWithGlobalErrorHandling: () => false,
    });

    useEffect(() => {
        if (!isLoading) {
            if (!error && data) {
                setPaging(data.paging ?? {});
            } else {
                addToast('CHYBA', {
                    appearance: 'error',
                });
            }
        }
    }, [isLoading, data, error, addToast]);

    useEffect(() => {
        setPaging(DEFAULT_PAGING);
    }, [deliveryPointUuid, timestampFrom, timestampTo, meteringType]);

    const renderEmptyTableMessage = () => {
        return (
            <tr>
                <td className="text-center" colSpan={5}>
                    <Trans i18nKey="common.tables.empty-table" />
                </td>
            </tr>
        );
    };

    const renderNoDataMessage = () => {
        return (
            <tr>
                <td className="text-center" colSpan={5}>
                    <Trans i18nKey="common.tables.no-data-table" />
                </td>
            </tr>
        );
    };

    return (
        <>
            <div className="container mb-5">
                <div className="row mb-4">
                    <div className="table-responsive">
                        <table className="table table-light" id="tableConsumption">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">
                                        <Trans i18nKey="delivery-point.detail.consumption.date" />
                                    </th>
                                    <th scope="col" className="d-none d-lg-table-cell">
                                        <Trans i18nKey="delivery-point.detail.consumption.consumption-type" />
                                    </th>
                                    <th scope="col" className="text-right">
                                        <Trans i18nKey="delivery-point.detail.consumption.value" /> ({getUnits(units)})
                                    </th>
                                    <th scope="col" className="d-none d-lg-table-cell">
                                        <Trans i18nKey="delivery-point.detail.consumption.tariff" />
                                    </th>
                                    <th scope="col" style={{ width: '8.33%' }}>
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-light">
                                {isLoading && (
                                    <tr>
                                        <td>{<LoadingIndicator />}</td>
                                    </tr>
                                )}
                                {error && (
                                    <tr>
                                        <td>
                                            <Trans i18nKey="delivery-point.detail.consumption.error-occured" />
                                        </td>
                                    </tr>
                                )}
                                {(data === undefined || data.result === undefined) && renderNoDataMessage()}
                                {data.result?.length === 0 && renderEmptyTableMessage()}
                                {data.result?.map((mappedRecord) => {
                                    const { id, readAt, value, isOpen, reason, kind, registerKind, description, toggle } = mappedRecord;
                                    return (
                                        <ConsumptionRow
                                            key={id}
                                            kindName={meterReadingKindCodeList?.find((code) => code.code === kind?.code)?.name}
                                            reasonName={meterReadingReasonCodeList?.find((code) => code.code === reason?.code)?.name}
                                            description={description}
                                            readAt={readAt}
                                            value={value}
                                            tariff={registerKind?.name}
                                            isOpen={isOpen}
                                            toggle={toggle}
                                            isInfo={mappedRecord?.category === 'INFO'}
                                            onDelete={mappedRecord?.category === 'INFO' && id ? () => mutateMyConsumption(id) : undefined}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Paginator
                    pageIndex={paging.page ?? 0}
                    pageSize={paging.size ?? 10}
                    dataLength={paging.total}
                    onPageChanged={(page) => {
                        setPaging((prev) => {
                            return {
                                ...prev,
                                page,
                            };
                        });
                    }}
                />
            </div>
        </>
    );
};

export default ConsumptionTable;
