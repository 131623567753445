import { SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary } from '@spp/spp-meru-frontend-common';
import { useSelector } from 'react-redux';
import { can } from '../components/permissions';
import { QueryKeysEnum } from '../main';
import { IRootState } from '../reducers';
import { useReloadQueryCache } from '../utils/react-query-utils';
import { useApi } from './use-api';
import { useQueryWithError } from './use-query-with-error';

export function useInvoiceSummary(): {
    unpaid?: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary['unpaid'];
    unpaidExpired?: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary['unpaidExpired'];
    overpaid?: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary['overpaid'];
    data: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined;
    reload: () => void;
    isLoading: boolean;
} {
    const user = useSelector((state: IRootState) => state.user);
    const reloadQueryCache = useReloadQueryCache();
    const api = useApi();

    const getInvoicesSummaryRequest = () => {
        if (user?.customer?.id != null) {
            return api.customers.invoicesSummary(user.customer.id, { secure: true }).then((res) => res.data);
        }
        return null;
    };

    const { data, isLoading } = useQueryWithError<SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined>(
        [QueryKeysEnum.INVOICE_SUMMARY, user.customer?.id],
        async () => getInvoicesSummaryRequest(),
        {
            enabled: user.employee ? can('ENTITY_INVOICES_VIEW', user.employee) : true,
            staleTime: 10 * 60 * 1000,
        },
    );

    const reload: () => void = () => {
        reloadQueryCache([QueryKeysEnum.INVOICE_SUMMARY]);
    };

    return { unpaid: data?.unpaid, unpaidExpired: data?.unpaidExpired, overpaid: data?.overpaid, data, reload, isLoading };
}
