import { faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatAddress } from '../../../../main';
import { isEInvoiceActiveForDP } from '../../../settings/e-invoices/utils/einvoice-utils';
import { ChangeEInvoiceEmailModal } from './contact-data-modals/change-einvoice-email-modal';
import FormListItem from './form-list-item';

interface IDeliveryPointDetailInvoiceData {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    canActivateEInvoice: boolean;
    setActivateEInvoiceButtonClicked: (val: boolean) => void;
    setIsActivateEInvoiceModalOpen: (val: boolean) => void;
    canEditOverride: boolean;
}

const DeliveryPointDetailInvoiceData: React.FC<IDeliveryPointDetailInvoiceData> = ({
    deliveryPoint,
    canActivateEInvoice,
    setActivateEInvoiceButtonClicked,
    setIsActivateEInvoiceModalOpen,
    canEditOverride,
}) => {
    const [t] = useTranslation();
    const contractAccount = deliveryPoint.contract?.contractAccount;
    const hasEInvoiceActive = isEInvoiceActiveForDP(deliveryPoint);
    const correspondenceAddress = useMemo<string | undefined>(() => {
        if (!!contractAccount?.postAddress?.city) {
            return formatAddress(contractAccount?.postAddress);
        }
        return undefined;
    }, [contractAccount?.postAddress]);
    const [changeEinvoice, setChangeEinvoice] = useState(false);

    const isEInvoiceActivationRequested = useMemo(() => {
        const eInvoiceRequests = deliveryPoint.fieldUpdates?.filter((item) => item.type === 'EINVOICE_ACTIVATION') || [];
        let requestUuid = '';
        if (eInvoiceRequests?.length === 1) {
            requestUuid = eInvoiceRequests[0].customerRequest?.uuid || '';
        }
        return { result: eInvoiceRequests.length !== 0, customerRequestUuid: requestUuid };
    }, [deliveryPoint]);

    return (
        <div className="card card-without-border mt-4 py-2 px-1">
            <ul className="list-group list-group-flush">
                <FormListItem valueText={<h3 className="mt-2">{t('delivery-point.detail.payment-data.invoice-sedning')}</h3>} />
                <FormListItem
                    label={t('delivery-point.detail.payment-data.invoice-sedning')}
                    valueText={
                        <span className="attribute-info">
                            {hasEInvoiceActive ? (
                                <>{deliveryPoint.einvoice?.email || '-'}</>
                            ) : (
                                <>
                                    {correspondenceAddress ? (
                                        <>
                                            {t('delivery-point.detail.payment-data.by-post-on-address')} - {correspondenceAddress}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </>
                            )}
                        </span>
                    }
                    isEditable={hasEInvoiceActive && canEditOverride}
                    isChangeRequested={isEInvoiceActivationRequested.result}
                    onClick={() => setChangeEinvoice(true)}
                    customerRequestUuid={isEInvoiceActivationRequested.customerRequestUuid}
                />
                {hasEInvoiceActive ? (
                    <FormListItem
                        valueText={
                            <span className="text-success">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <FontAwesomeIcon icon={faTree} className="mr-3" size="2x" />
                                    </div>
                                    <div>{t('delivery-point.detail.payment-data.e-invoice-active')}</div>
                                </div>
                            </span>
                        }
                    />
                ) : (
                    canActivateEInvoice && (
                        <div className="bg-success text-light p-3 rounded m-3">
                            <h4 className="text-light">{t('delivery-point.detail.payment-data.e-invoice-inactive')}</h4>
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <button
                                        className="mt-3 px-3 btn btn-outline-green"
                                        onClick={() => {
                                            setActivateEInvoiceButtonClicked(true);
                                            setIsActivateEInvoiceModalOpen(true);
                                        }}
                                    >
                                        <Trans i18nKey="delivery-point.detail.actions.activate-e-invoice">Aktivovať eFaktúru</Trans>
                                    </button>
                                </div>
                                <div className="col-6 text-right opacity-50 mt-3">
                                    <FontAwesomeIcon icon={faTree} size="3x" />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </ul>
            <ChangeEInvoiceEmailModal deliveryPoint={deliveryPoint} isOpen={changeEinvoice} onClose={() => setChangeEinvoice(false)} />
        </div>
    );
};

export default DeliveryPointDetailInvoiceData;
