function textEncode(str: string) {
    if (window.TextEncoder) {
        return new TextEncoder().encode(str);
    }
    const utf8 = unescape(encodeURIComponent(str));
    const result = new Uint8Array(utf8.length);
    for (let i = 0; i < utf8.length; i++) {
        result[i] = utf8.charCodeAt(i);
    }
    return result;
}

//CODE_CHALLENGE VIA CODE_CHALLENGE_METHOD
export const sha256 = (plainString: string) => {
    // returns Promise<ArrayBuffer>
    const data = textEncode(plainString);
    if (window.crypto) {
        return crypto.subtle.digest('SHA-256', data);
    } else {
        // @ts-ignore
        const crypt = window.msCrypto.subtle.digest('SHA-256', data);
        // this must be as it is because IE is very very very stupid!!!!!
        crypt.toString();
        const result = 'result';
        return Promise.resolve(crypt[result]);
    }
};

export const base64urlencode = (a: ArrayBuffer) => {
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    let str = '';

    for (let i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    //return binary to ASCII of string
    return btoa(str)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};
