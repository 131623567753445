import { SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { ITariff } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum, CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

interface IRequestContent extends SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent {
    deliveryPointConnectionEe?: { distributionArea: string };
}

type Props = {
    data: ITariff;
    bold?: boolean;
    code: string;
    requestContent?: IRequestContent;
};

const TariffItem: React.FC<Props> = ({ data, bold, code, requestContent }) => {
    const [t] = useTranslation();

    delete data.componentType;
    const { data: tariffCodeList } = useCodeList({
        queryKey: QueryKeysEnum.PRODUCT_TARIFF_RATE,
        codeListTypeEnum: CodeListTypeEnum.TARIFF_RATE,
        paging: {
            size: 80,
        },
    });
    const { data: distributionAreaCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 80,
        },
    });

    const distributionArea = useMemo(() => {
        return distributionAreaCodeList?.find((item) => {
            return requestContent?.deliveryPointConnectionEe?.distributionArea === item.code;
        });
    }, [distributionAreaCodeList, requestContent?.deliveryPointConnectionEe?.distributionArea]);

    const tariff = useMemo(() => {
        return tariffCodeList?.find((item) => item.uuid === data?.tariff?.uuid)?.name;
    }, [tariffCodeList, data.tariff]);

    if (isObjectEmpty(data)) return <></>;

    return (
        <ItemPreview
            title={
                code === CustomerRequestTypeEnum.ZOM_ZODE || code === CustomerRequestTypeEnum.ZOM_ZSE
                    ? t('customer-request.detail.rate')
                    : t('delivery-point.detail.payment-data.tariff')
            }
        >
            <ItemPreviewContent bold={bold}>{tariff}</ItemPreviewContent>
            {distributionArea && (
                <>
                    <ItemPreviewHeader title={t('customer-request.steps.electricity-connection.distribution-area')} />
                    <ItemPreviewContent>{distributionArea?.name}</ItemPreviewContent>
                </>
            )}
        </ItemPreview>
    );
};

export default TariffItem;
