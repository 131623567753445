import { faExclamationTriangle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerrequestCustomerRequestTemplate,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { CommodityEnum, CustomerRequestTypeEnum, IRootState } from '../../../../../main';
import { IRequestPaid, PRICE_LINK_EE, PRICE_LINK_ZP } from '../../../../../models/model';
import { formatAddress } from '../../../../../utils/utils';
import { InvoiceType } from '../../../../invoices/components/invoice-type';

type Props = {
    onRequestCancel: () => void;
    deliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary;
    invoiceTypeCodeList?: SkSppNzpCommonsApiCodelistCodeListItem[] | undefined | null;
    invoice?: SkSppNzpBeApiCustomerprofileInvoice;
    customerRequestTemplate?: SkSppNzpBeApiCustomerrequestCustomerRequestTemplate;
    paid?: IRequestPaid | undefined;
    onRequestSave?: () => void;
    lastOpened?: number;
};

const StepManagerHeader: React.FC<Props> = ({
    onRequestCancel,
    deliveryPoint,
    invoiceTypeCodeList,
    invoice,
    customerRequestTemplate,
    paid,
    onRequestSave,
    lastOpened,
}) => {
    const [t] = useTranslation();
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    return (
        <div className="page-header bg-white">
            {/* {deliveryPoint && (
                <>
                    <div className="container py-2">
                        <h6 className="mb-0">
                            {formatAddress(deliveryPoint.address)}{' '}
                            {invoice && (
                                <>
                                    {' | '}
                                    <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />{' '}
                                    <Trans i18nKey="customer-request.steps.reason-for-claim.number">č.</Trans> {invoice.reference}
                                </>
                            )}
                        </h6>
                    </div>
                    <hr style={{ color: '#f0f0f0' }} className="m-0" />
                </>
            )} */}
            <div className="container d-flex justify-content-between align-items-center">
                <div className="my-4">
                    <h2 className="mb-0">{t(`enums.CustomerRequestType.${customerRequestTemplate?.code}`)}</h2>
                    {/* <Trans i18nKey={'enums.CustomerRequestType.' + customerRequestTemplate?.code + '_DESC'}>&nbsp;</Trans> */}
                    {deliveryPoint && (
                        <div className="mt-1">
                            {formatAddress(deliveryPoint.address)}{' '}
                            {invoice && (
                                <>
                                    {' | '}
                                    <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />{' '}
                                    <Trans i18nKey="customer-request.steps.reason-for-claim.number">č.</Trans> {invoice.reference}
                                </>
                            )}
                        </div>
                    )}
                </div>
                {loggedInCustomer &&
                    (lastOpened || 0) > 0 &&
                    (customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE ||
                        customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODP) && (
                        <div
                            role="button"
                            onClick={() => onRequestSave && onRequestSave()}
                            className="ml-auto text-decoration-none cancel-request my-1 mr-4"
                        >
                            <Button className="d-none d-lg-inline-block" color="light">
                                {t('customer-request.steps.save-and-continue-later')}
                            </Button>
                            <FontAwesomeIcon icon={faSave} size="2x" className="d-lg-none ml-2" />
                        </div>
                    )}
                <div role="button" onClick={onRequestCancel} className="text-decoration-none cancel-request my-4">
                    <span className="d-none d-lg-inline-block">
                        <b>{t('customer-request.steps.close-request')}</b>
                        <span className="ml-2" style={{ border: '2px solid', borderRadius: '50%' }}>
                            <i className="icon-Times" style={{ margin: 3 }}></i>
                        </span>
                    </span>
                    <span className="d-lg-none ml-2" style={{ border: '2px solid', borderRadius: '50%', fontSize: '1.5rem' }}>
                        <i className="icon-Times" style={{ margin: 3 }}></i>
                    </span>
                    {/* <i className="d-lg-none icon-Times ml-2" style={{ fontSize: '1.5rem' }}></i> */}
                </div>
            </div>
            {paid?.paid && (
                <div style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                    <div className="container font-weight-bold py-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <Trans i18nKey="customer-request.prepare.attention-text">Táto žiadosť bude spoplatnená podľa</Trans>{' '}
                        {paid?.comodity ? (
                            <>
                                <a
                                    href={paid?.comodity === CommodityEnum.ELECTRICITY ? PRICE_LINK_EE : PRICE_LINK_ZP}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('customer-request.prepare.actual-price-list')}
                                </a>
                            </>
                        ) : (
                            t('customer-request.prepare.actual-price-list')
                        )}
                        .
                    </div>
                </div>
            )}
        </div>
    );
};

export default StepManagerHeader;
