import { SkSppNzpBeApiCustomerconsumptionMeterReadingInfoRequest, SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import DatePicker from '../../../../../components/common/datePicker/datePicker';
import InputWithAppend from '../../../../../components/common/input-with-append';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { useApi } from '../../../../../hooks/use-api';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { ClickableElement, Routes } from '../../../../../main';
import { CommodityEnum } from '../../../../../models/enums';
import { IApiResponse } from '../../../../../models/model';
import { buildDeliveryPointSelfReadQueryKey, QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { clearObjectProperties } from '../../../../../utils/utils';

const errorsHandled = [
    'NULL_VALUE_LOW',
    'NEGATIVE_VALUE_LOW',
    'INVALID_VALUE_LOW',
    'NULL_VALUE_HIGH',
    'NEGATIVE_VALUE_HIGH',
    'INVALID_VALUE_HIGH',
    'INVALID_READ_AT_DATE',
    'METER_READING_NOT_ALLOWED',
];

type ReadDataPost = {
    data: SkSppNzpBeApiCustomerconsumptionMeterReadingInfoRequest;
};

interface IConsumptionReadFormProps {
    onClose: () => void;
    deliveryPointId: SkSppNzpBeApiCustomerprofileDeliveryPoint['id'];
    contractId: string;
    myRead?: boolean;
    commodityType: SkSppNzpBeApiCustomerprofileDeliveryPoint['type'];
    helpText: string;
    dual?: boolean;
    customer?: boolean;
    lastLowValue?: number;
    lastHighValue?: number;
    readingCycleFrom?: string;
    readingCycleTo?: string;
}

const MIN_VALUE = 0;

const ConsumptionReadForm: React.FC<IConsumptionReadFormProps> = ({
    onClose,
    deliveryPointId,
    contractId,
    myRead,
    commodityType,
    helpText,
    dual,
    lastLowValue,
    lastHighValue,
    readingCycleFrom,
    readingCycleTo,
}) => {
    const [t] = useTranslation();
    const defaultValues: Partial<SkSppNzpBeApiCustomerconsumptionMeterReadingInfoRequest> = {
        readAt: new Date().toISOString(),
    };
    const { register, handleSubmit, setValue, getValues, setError, trigger, errors } = useForm({ defaultValues: defaultValues });
    const { formRules, dateRules } = useFormRules();

    const { addToast } = useToasts();
    const api = useApi();
    const queryCache = useQueryCache();
    const history = useHistory();

    // vypnutie kontroly na minValue v pripade Mojho odpoctu
    const minimalLowValue = lastLowValue && !myRead ? lastLowValue : MIN_VALUE;
    const minimalHighValue = lastHighValue && !myRead ? lastHighValue : MIN_VALUE;

    const [mutateMyConsumption, { isLoading }] = useMutationWithError(
        async ({ data }: ReadDataPost) =>
            myRead
                ? api.deliveryPoints.createMeterReadingInfo(contractId, data, { secure: true })
                : api.deliveryPoints.createMeterReadingCustomer(contractId, data, { secure: true }),
        {
            onSuccess: () => {
                onClose();
                addToast(t('delivery-point.detail.consumption.reading-added'));
                queryCache.invalidateQueries(QueryKeysEnum.CONSUMPTION_TABLE);
                queryCache.invalidateQueries(QueryKeysEnum.DELIVERY_POINT);
                queryCache.removeQueries(buildDeliveryPointSelfReadQueryKey(deliveryPointId));
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                if (response.error?.code === 5002 && response.error?.errors) {
                    if (response.error?.errors.includes('NULL_VALUE_LOW')) {
                        setError('valueLow', {
                            message: t('common.input-rules.required'),
                        });
                    }
                    if (response.error?.errors.includes('NEGATIVE_VALUE_LOW')) {
                        setError('valueLow', {
                            message: t('common.input-rules.only-positive'),
                        });
                    }
                    if (response.error?.errors.includes('INVALID_VALUE_LOW')) {
                        setError('valueLow', {
                            message: t('common.input-rules.min', { min: minimalLowValue }),
                        });
                    }
                    if (response.error?.errors.includes('NULL_VALUE_HIGH')) {
                        setError('valueHigh', {
                            message: t('common.input-rules.required'),
                        });
                    }
                    if (response.error?.errors.includes('NEGATIVE_VALUE_HIGH')) {
                        setError('valueHigh', {
                            message: t('common.input-rules.only-positive'),
                        });
                    }
                    if (response.error?.errors.includes('INVALID_VALUE_HIGH')) {
                        setError('valueHigh', {
                            message: t('common.input-rules.min', { min: minimalHighValue }),
                        });
                    }
                    if (response.error?.errors.includes('INVALID_READ_AT_DATE')) {
                        //readAt null / from future / before last readAt
                        setError('readAt', {
                            message: t('common.datepicker'),
                        });
                    }
                    if (response.error?.errors.includes('METER_READING_NOT_ALLOWED')) {
                        addToast(t('common.input-rules.meter-reading-not-allowed'), {
                            appearance: 'error',
                        });
                    }
                    if (response.error?.errors.every((currentValue) => errorsHandled.includes(currentValue))) {
                        return true;
                    }
                    //
                    return false;
                }
                return false;
            },
        },
    );

    const onFormSubmit = handleSubmit((data: SkSppNzpBeApiCustomerconsumptionMeterReadingInfoRequest) => {
        const filter = { ...data };

        mutateMyConsumption({ data: clearObjectProperties(filter) });
    });

    const dateLimitFrom = readingCycleFrom ? new Date(readingCycleFrom) : undefined;
    const dateLimitTo = readingCycleTo ? new Date(readingCycleTo) : undefined;

    const showMinValueInfo = useMemo(() => {
        if (errors.valueLow?.type === 'min' || errors.valueHigh?.type === 'min') {
            return true;
        }
        return false;
    }, [errors.valueLow, errors.valueHigh]);

    return (
        <>
            <form onSubmit={onFormSubmit} noValidate>
                <div className="form-group mb-2">
                    {commodityType === CommodityEnum.ELECTRICITY &&
                        (dual ? (
                            <Row>
                                <Col className="col-12">
                                    <InputWithAppend
                                        type="number"
                                        aria-describedby="inputPayment"
                                        placeholder=" "
                                        name="valueHigh"
                                        ref={register({
                                            ...formRules.requiredInteger,
                                            ...formRules.min(minimalHighValue),
                                        })}
                                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                        errors={errors}
                                        min={`${minimalHighValue}`}
                                        trigger={trigger}
                                        label={<Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-high" />}
                                        inputGroupAppendChildren={
                                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                                kWh
                                            </button>
                                        }
                                    />
                                    <InputWithAppend
                                        type="number"
                                        aria-describedby="inputPayment"
                                        placeholder=" "
                                        name="valueLow"
                                        ref={register({
                                            ...formRules.requiredInteger,
                                            ...formRules.min(minimalLowValue),
                                        })}
                                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                        errors={errors}
                                        min={`${minimalLowValue}`}
                                        trigger={trigger}
                                        label={<Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity-low" />}
                                        inputGroupAppendChildren={
                                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                                kWh
                                            </button>
                                        }
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col className="col-12">
                                    <InputWithAppend
                                        type="number"
                                        aria-describedby="inputPayment"
                                        placeholder=" "
                                        name="valueHigh"
                                        ref={register({
                                            ...formRules.requiredInteger,
                                            ...formRules.min(minimalHighValue),
                                        })}
                                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                        errors={errors}
                                        min={`${minimalHighValue}`}
                                        trigger={trigger}
                                        label={<Trans i18nKey="delivery-point.detail.consumption.meter-value-electricity" />}
                                        inputGroupAppendChildren={
                                            <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                                kWh
                                            </button>
                                        }
                                    />
                                </Col>
                            </Row>
                        ))}
                    {commodityType === CommodityEnum.GAS && (
                        <Row>
                            <Col className="col-12">
                                <InputWithAppend
                                    type="number"
                                    aria-describedby="inputPayment"
                                    placeholder=" "
                                    name="valueHigh"
                                    ref={register({
                                        ...formRules.requiredInteger,
                                        ...formRules.min(minimalHighValue),
                                    })}
                                    characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                    errors={errors}
                                    min={`${minimalHighValue}`}
                                    trigger={trigger}
                                    label={<Trans i18nKey="delivery-point.detail.consumption.meter-value-gas" />}
                                    inputGroupAppendChildren={
                                        <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                                            m<sup>3</sup>
                                        </button>
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                    {showMinValueInfo && (
                        <div className="mx-1 mb-1">
                            <span className="text-danger">{t(`delivery-point.detail.consumption.value-lower-than-in-system`)}</span>
                            <br />
                            {t(`delivery-point.detail.consumption.please`)}
                            <ClickableElement isText onClick={() => history.push(Routes.CONTACT)} className="text-decoration-underline-inverse">
                                {t(`delivery-point.detail.consumption.contact-us`)}.
                            </ClickableElement>
                        </div>
                    )}

                    <HelpLinkWithModal
                        className="text-center d-block mb-5"
                        classNameClickableElement="text-decoration-underline-inverse"
                        title="delivery-point.detail.consumption.where-info"
                        field={helpText}
                        hideTitle
                    />
                </div>
                <FormGroup className=" d-flex flex-row">
                    <DatePicker
                        label={t(`delivery-point.detail.consumption.read-date`)}
                        errors={errors}
                        trigger={trigger}
                        register={register({
                            ...formRules.required,
                            validate: {
                                ...dateRules(
                                    'BETWEEN',
                                    dateLimitFrom && moment(dateLimitFrom).toISOString(),
                                    dateLimitTo && moment(dateLimitTo).toISOString(),
                                ).isValidDate.validate,
                                ...dateRules().notFutureDate.validate,
                            },
                        })}
                        setValue={setValue}
                        getValues={getValues}
                        minDate={dateLimitFrom}
                        maxDate={dateLimitTo ? (dateLimitTo < new Date() ? dateLimitTo : new Date()) : new Date()}
                        name="readAt"
                    />
                </FormGroup>
                {myRead && (
                    <div className="form-group">
                        <input
                            type="textarea"
                            className="form-control"
                            aria-describedby="inputDescription"
                            placeholder=" "
                            ref={register({})}
                            name="description"
                        />
                        <label className="control-label">
                            <Trans i18nKey="delivery-point.detail.consumption.description" />
                        </label>
                    </div>
                )}
                <Button type="submit" color="primary" size="lg" block disabled={isLoading}>
                    <Trans i18nKey="delivery-point.detail.consumption.send-read" />
                </Button>
            </form>
        </>
    );
};

export default ConsumptionReadForm;
