import classnames from 'classnames';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import CookiesButtons from './components/cookies-buttons';
import Section from './components/section';
import { AcceptCookiesEnum } from './cookies-alert';
import { ICookiesInformation } from './interface';

interface ICookieSettingsModalProps {
    cookiesInformation?: ICookiesInformation;
    handleCookies: (value: string[] | AcceptCookiesEnum) => void;
}

export enum TabsEnum {
    CONSENT = 'Consent',
    DETAILS = 'Details',
    ABOUT_COOKIES = 'About cookies',
}

export const CookieSettingsModal: React.FC<ICookieSettingsModalProps> = ({ cookiesInformation, handleCookies }) => {
    const { t, i18n } = useTranslation();
    const [selectedTabs, setSelectedTabs] = useState<TabsEnum>(TabsEnum.DETAILS);
    const necessaryIds =
        cookiesInformation?.config.categoryTypes.filter((categoryType) => categoryType.necessary).map((categoryType) => categoryType.id) || [];
    const [acceptedCategoryTypeIds, setAcceptedCategoryTypeIds] = useState<string[]>(necessaryIds);

    const handleDetailsConsent = (id: string) => {
        setAcceptedCategoryTypeIds((currentIds) => {
            const newIds = new Set(currentIds);
            if (newIds.has(id)) {
                newIds.delete(id);
            } else {
                newIds.add(id);
            }
            return Array.from(newIds);
        });
    };

    const consentTab = i18n.language === 'en' ? cookiesInformation?.config.en.consent : cookiesInformation?.config.sk.consent;
    const aboutCookiesTab = i18n.language === 'en' ? cookiesInformation?.config.en.about : cookiesInformation?.config.sk.about;

    const categoriesLength = cookiesInformation?.config.categories.length;
    const detailsTab = cookiesInformation?.config.categories.map((category, index) => (
        <React.Fragment key={category.id}>
            <Section
                category={category}
                categoryType={cookiesInformation.config.categoryTypes.find((categoryType) => categoryType.id === category.categoryTypeFk.id)}
                types={cookiesInformation.config.types}
                acceptedCategoryTypeIds={acceptedCategoryTypeIds}
                handleDetailsConsent={handleDetailsConsent}
            />
            {categoriesLength !== index + 1 && <hr />}
        </React.Fragment>
    ));
    return (
        <Modal isOpen centered className="cookies-settings-modal">
            <ModalBody className="body-bg-white">
                <h2 className="modal-title mb-2">
                    <Trans i18nKey="cookies-alert.modal.title" />
                </h2>
                <hr />
                <div className="tabs mt-4">
                    <div
                        className={classnames('text-uppercase', { active: selectedTabs === TabsEnum.CONSENT })}
                        onClick={() => {
                            setSelectedTabs(TabsEnum.CONSENT);
                        }}
                    >
                        {t('cookies-alert.modal.tabs.consent')}
                    </div>
                    <div
                        className={classnames('text-uppercase', { active: selectedTabs === TabsEnum.DETAILS })}
                        onClick={() => {
                            setSelectedTabs(TabsEnum.DETAILS);
                        }}
                    >
                        {t('cookies-alert.modal.tabs.details')}
                    </div>
                    <div
                        className={classnames('text-uppercase', { active: selectedTabs === TabsEnum.ABOUT_COOKIES })}
                        onClick={() => {
                            setSelectedTabs(TabsEnum.ABOUT_COOKIES);
                        }}
                    >
                        {t('cookies-alert.modal.tabs.about-cookies')}
                    </div>
                </div>
                <hr className="mt-0" />
                {selectedTabs === TabsEnum.CONSENT && <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(consentTab || '') }} />}
                {selectedTabs === TabsEnum.ABOUT_COOKIES && <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(aboutCookiesTab || '') }} />}
                {selectedTabs === TabsEnum.DETAILS && detailsTab}
                <hr />
                <CookiesButtons handleCookies={handleCookies} acceptedCategoryTypeIds={acceptedCategoryTypeIds} />
            </ModalBody>
        </Modal>
    );
};
