import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCommonSharingInfo, SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Card, ListGroup, ListGroupItem, Modal, ModalBody } from 'reactstrap';
import { ClickableElement } from '../../../../../components/common';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import HelpLinkWithModal from '../../../../../components/help/help-link-with-modal';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IApiResponse } from '../../../../../models/model';
import { IRootState } from '../../../../../reducers';
import { buildUnitedDeliveryPointQueryKey } from '../../../../../utils/react-query-utils';
import { formatAddress } from '../../../../../utils/utils';
import { ShareDeliveryPointFormFields } from './share-delivery-point-form-fields';

interface IUserListGroupItemProps {
    sharingInfo: SkSppNzpBeApiCommonSharingInfo;
    onRemove: () => void;
}

export const UserListGroupItem: React.FC<IUserListGroupItemProps> = ({ sharingInfo, onRemove }) => {
    return (
        <ListGroupItem>
            <div className="row align-items-center py-2 text-decoration-none">
                <div className="col">
                    <p className="mb-0">{sharingInfo.email}</p>
                </div>
                <div className="col-auto">
                    <ClickableElement onClick={onRemove}>
                        <FontAwesomeIcon icon={faTrashAlt} className="fa-lg text-danger" />
                    </ClickableElement>
                </div>
            </div>
        </ListGroupItem>
    );
};

interface IShareDeliveryPointModalProps {
    isModalOpen: boolean;
    closeModal: () => void;
    unitedDeliveryPoint: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary;
}

const ShareDeliveryPointModal: React.FC<IShareDeliveryPointModalProps> = ({ isModalOpen, closeModal, unitedDeliveryPoint }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const [t] = useTranslation();
    const { addToast } = useToasts();

    const queryCache = useQueryCache();
    const unitedDeliveryPointsQuery = queryCache.getQuery(buildUnitedDeliveryPointQueryKey(unitedDeliveryPoint.id));
    const { register, handleSubmit, errors, trigger, setValue } = useForm<{ email: string }>();
    const isUdpInactive = unitedDeliveryPoint?.deliveryPoints?.every((dp) => dp.status !== 'ACTIVE');

    const api = useApi();
    const [mutateDeleteSharing, { isLoading: isLoadingDeleteSharing }] = useMutationWithError(
        async (email: string) =>
            unitedDeliveryPoint.id == null || loggedInCustomer?.id == null
                ? null
                : api.customers.deleteSharing(
                      loggedInCustomer.id,
                      unitedDeliveryPoint.id,
                      {
                          email: email,
                      },
                      { secure: true },
                  ),
        {
            onSuccess: (data: unknown, variable: string) => {
                addToast(t('delivery-point.detail.actions.share-delivery-point.sharing-removed', { email: variable }), {
                    appearance: 'success',
                });
                if (isUdpInactive && (unitedDeliveryPoint.sharing?.to || []).length <= 1) {
                    closeModal();
                }
                queryCache.invalidateQueries(buildUnitedDeliveryPointQueryKey(unitedDeliveryPoint.id));
                queryCache.invalidateQueries('delivery-points-search');
            },
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const [mutateAddSharing, { isLoading: isLoadingAddSharing }] = useMutationWithError(
        async (email: string) =>
            unitedDeliveryPoint.id == null || loggedInCustomer?.id == null
                ? null
                : api.customers.postSharing(
                      loggedInCustomer.id,
                      unitedDeliveryPoint.id,
                      {
                          email: email,
                      },
                      { secure: true },
                  ),
        {
            onSuccess: (data: unknown, variable: string) => {
                setValue('email', '');
                addToast(t('delivery-point.detail.actions.share-delivery-point.sharing-added', { email: variable }), {
                    appearance: 'success',
                });
                queryCache.invalidateQueries(buildUnitedDeliveryPointQueryKey(unitedDeliveryPoint.id));
                queryCache.invalidateQueries('delivery-points-search');
            },
            onErrorWithGlobalErrorHandling: (responseError: IApiResponse, variable: string) => {
                if (responseError.status === 409) {
                    const error = responseError.error;
                    if (error?.code === 3004) {
                        addToast(t('delivery-point.detail.actions.share-delivery-point.sharing-already-exists', { email: variable }), {
                            appearance: 'warning',
                        });
                        return true;
                    } else if (error?.code === 409) {
                        addToast(t('delivery-point.detail.actions.share-delivery-point.cannot-share-to-yourself', { email: variable }), {
                            appearance: 'warning',
                        });
                        return true;
                    }
                }
                return false;
            },
        },
    );

    const sharedTo: SkSppNzpBeApiCommonSharingInfo[] = unitedDeliveryPoint.sharing?.to || [];

    const removeSharing = (email: string | undefined) => () => {
        mutateDeleteSharing(email);
    };

    const onSubmit = (formFields: { email: string }) => {
        mutateAddSharing(formFields.email);
    };

    return (
        <>
            <Modal isOpen={isModalOpen} modalClassName="modal-fullscreen" centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        {t('delivery-point.detail.actions.share-delivery-point.modal-title')} <br />
                        {formatAddress(unitedDeliveryPoint.address)}
                    </h3>
                    <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <ModalBody>
                    {(unitedDeliveryPointsQuery?.state.isFetching || isLoadingDeleteSharing || isLoadingAddSharing) && <LoadingIndicator />}
                    <i className="icon-users-16 fa-lg mb-3 d-block"></i>
                    {sharedTo.length > 0 && (
                        <>
                            <h5 className="forms-headline my-4">{t('delivery-point.detail.actions.share-delivery-point.shared-to')}:</h5>
                            <Card>
                                <ListGroup>
                                    {sharedTo.map((sharing) => (
                                        <UserListGroupItem key={sharing.email} sharingInfo={sharing} onRemove={removeSharing(sharing.email)} />
                                    ))}
                                </ListGroup>
                            </Card>
                        </>
                    )}

                    {!isUdpInactive && (
                        <>
                            <h5 className="forms-headline mt-4 mb-4">{t('delivery-point.detail.actions.share-delivery-point.share-to')}:</h5>

                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <ShareDeliveryPointFormFields register={register} errors={errors} trigger={trigger} />
                            </form>
                        </>
                    )}

                    <HelpLinkWithModal
                        showAsCard
                        className="my-4"
                        title="delivery-point.detail.actions.share-delivery-point.what-rights-does-the-person-i-share-with-have"
                        field="ZOM_RIGHTS_OF_PERSON_WHO_I_AM_SHARING_WITH"
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default ShareDeliveryPointModal;
