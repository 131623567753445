import { Reducer } from 'react';
import { DeliveryPointsAction, DeliveryPointsActionType, DeliveryPointsState } from '../actions/delivery-points-actions';

const initState: DeliveryPointsState = {
    cardDeliveryPoints: [],
    tableDeliveryPoints: [],
};

const DeliveryPointsReducer: Reducer<DeliveryPointsState, DeliveryPointsAction> = (state = initState, action) => {
    switch (action.type) {
        case DeliveryPointsActionType.DELETE:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default DeliveryPointsReducer;
