import {
    SkSppNzpBeApiCustomerprofileBusinessPartnerSummary as BusinessPartnerSummary,
    SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent,
} from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { hasRequestOriginalValues, isObjectEmpty } from '../../../../../../utils/utils';
import { CustomerRequestTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data?: BusinessPartnerSummary;
    bold?: boolean;
    code?: SkSppNzpCommonsApiCustomerrequestRequestBaseCustomerRequestContent['type'];
    requestContent: any;
};

const BusinessPartnerSummaryItem: React.FC<Props> = ({ data, bold, code, requestContent }) => {
    const [t] = useTranslation();
    if ((data && isObjectEmpty(data)) || code === CustomerRequestTypeEnum.ZOM_P) return <></>;
    let businessPartner = data;

    if (code && requestContent.originalValues?.businessPartnerData && hasRequestOriginalValues(code)) {
        businessPartner = requestContent.originalValues.businessPartnerData;
    }

    return (
        <ItemPreview title={t('customer-request.steps.businessPartner.title')}>
            <ItemPreviewContent bold={bold}>{`${businessPartner?.firstName ?? ''} ${businessPartner?.lastName ?? ''}`}</ItemPreviewContent>
            {businessPartner?.name && <ItemPreviewContent bold={bold}> {businessPartner?.name ?? ''}</ItemPreviewContent>}
        </ItemPreview>
    );
};

export default BusinessPartnerSummaryItem;
