import React from 'react';
import { Trans } from 'react-i18next';
import { CharacterFilter } from '../../../../../../components/common/character-filtering';
import InputWithAppend from '../../../../../../components/common/input-with-append';
import { useFormRules } from '../../../../../../main';
import { DefaultFormType } from '../../../../../../models/model';

type Props = DefaultFormType;

const ElectricityConsumptionV1FormFields: React.FC<Props> = ({ register, errors, trigger }) => {
    const { formRules } = useFormRules();
    return (
        <InputWithAppend
            trigger={trigger}
            ref={register({ ...formRules.requiredInteger, ...formRules.min(0) })}
            characterFilter={CharacterFilter.POSITIVE_INTEGER}
            errors={errors}
            type="number"
            name={`value`}
            label={<Trans i18nKey="customer-request.steps.electricity-consumption.expected-consumption" />}
            componentClassName="mb-1"
            inputGroupAppendChildren={
                <button type="button" tabIndex={-1} className="btn text-primary cursor-default">
                    kWh
                </button>
            }
        />
    );
};

export default ElectricityConsumptionV1FormFields;
