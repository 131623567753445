import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { DeliveryPointCommodityBadges } from '../delivery-point-commodity-badges';

type Props = { deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[] };

export const ServiceTypesCell: React.FC<Props> = ({ deliveryPoints }) => (
    <td>
        <DeliveryPointCommodityBadges deliveryPoints={deliveryPoints || []} />
    </td>
);
