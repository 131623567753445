import {
    SkSppNzpBeApiCustomerprofileDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequestSummary,
    SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { LoadingIndicator } from '../../../../../components/common';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import {
    buildDeliveryPointQueryKey,
    CommodityEnum,
    ConsumptionCategoryEnum,
    emailRegex,
    IRootState,
    PaymentMethodCodeEnum,
    QueryKeysEnum,
    useApi,
    useReloadQueryCache,
} from '../../../../../main';
import { ChangeFormStepEnum } from '../../../../../reducers/interfaces/delivery-point-detail-state';
import { formatAddressShort } from '../../../../../utils/utils';
import ChangeRequestSent from '../change-request-sent';
import ChangePaymentMethodForm, { IPaymentMethodData } from '../forms/change-payment-method';

interface IChangePaymentMethodModalProps {
    isOpen: boolean;
    onClose: () => void;
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
}

export const ChangePaymentMethodModal: React.FC<IChangePaymentMethodModalProps> = ({ isOpen, onClose, deliveryPoint }) => {
    const [step, setStep] = useState<ChangeFormStepEnum>(ChangeFormStepEnum.EDITING);
    const [newData, setNewData] = useState<IPaymentMethodData | undefined>();
    const customerEmail = useSelector((state: IRootState) => state.user.customer?.email);

    useEffect(() => {
        if (isOpen) {
            setStep(ChangeFormStepEnum.EDITING);
            setNewData(undefined);
        }
    }, [isOpen]);

    const goToNextStep = (): void => {
        if (step === ChangeFormStepEnum.EDITING) {
            setStep(ChangeFormStepEnum.SUCCESS);
        }
    };

    const reloadQueryCache = useReloadQueryCache();

    const api = useApi();
    const [mutateCreateRequest, { isLoading: isLoadingCreateRequest }] = useMutationWithError<
        SkSppNzpBeApiCustomerrequestCustomerRequestSummary | null,
        unknown,
        SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod
    >(
        async (requestData) =>
            deliveryPoint.contract?.id != null
                ? api.deliveryPoints.changePaymentMethod(deliveryPoint.contract?.id, requestData, { secure: true }).then((res) => res.data)
                : null,
        {
            onSuccess: () => {
                goToNextStep();
            },
        },
    );

    const createRequestForPaymentMethodChange = async (paymentMethodData: IPaymentMethodData) => {
        const requestData: SkSppNzpCommonsApiCustomerrequestComponentPaymentMethod = {
            paymentType: { code: paymentMethodData.paymentType, uuid: paymentMethodData.paymentTypeId },
            iban: paymentMethodData.iban,
            bicSwift: paymentMethodData.bicSwift,
            sipoNumber: paymentMethodData.sipoNumber,
            ...(paymentMethodData.inkasoEmail ? { address: { email: paymentMethodData.inkasoEmail } } : {}),
            processManualOverride: paymentMethodData.processManualOverride,
        };
        mutateCreateRequest(requestData);
    };

    const onFormSubmit = (formData: IPaymentMethodData) => {
        setNewData(formData);
        createRequestForPaymentMethodChange(formData);
    };

    const groupPaymentType = (paymentType: string | undefined): string | undefined => {
        switch (paymentType) {
            case 'F':
                return PaymentMethodCodeEnum.INKASO;
            default:
                return paymentType;
        }
    };

    const onSuccessAccepted = () => {
        reloadQueryCache(buildDeliveryPointQueryKey(deliveryPoint.contract?.id));
        reloadQueryCache(QueryKeysEnum.DELIVERY_POINT_REQUESTS_SEARCH);
        onClose();
    };

    const inkasoEmails = useMemo(() => {
        const emails = [];
        customerEmail && emails.push(customerEmail);
        deliveryPoint.contract?.contractAccount?.email &&
            emailRegex.test(deliveryPoint.contract?.contractAccount?.email) &&
            emails.push(deliveryPoint.contract?.contractAccount?.email);
        deliveryPoint.contract?.contractAccount?.businessPartner?.email &&
            emailRegex.test(deliveryPoint.contract?.contractAccount?.businessPartner?.email) &&
            emails.push(deliveryPoint.contract?.contractAccount?.businessPartner?.email);
        return [...new Set(emails)];
    }, [deliveryPoint.contract?.contractAccount?.email, deliveryPoint.contract?.contractAccount?.businessPartner?.email, customerEmail]);

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <div className="modal-header">
                    <h3 className="modal-title">
                        <Trans i18nKey="delivery-point.detail.contact-data.change-payment-method">Zmena spôsobu platby</Trans>
                    </h3>
                    <button type="button" onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                {step === ChangeFormStepEnum.EDITING && (
                    <div className="modal-header">
                        <span>
                            {formatAddressShort(deliveryPoint.address)} -{' '}
                            {deliveryPoint.type === CommodityEnum.GAS ? (
                                <Trans i18nKey="common.commodity.gas" />
                            ) : (
                                <Trans i18nKey="common.commodity.electricity" />
                            )}
                        </span>
                    </div>
                )}

                <ModalBody>
                    {step === ChangeFormStepEnum.EDITING && (
                        <ChangePaymentMethodForm
                            currentValue={{
                                paymentType: groupPaymentType(deliveryPoint.contract?.contractAccount?.paymentType?.code),
                                paymentTypeId: deliveryPoint.contract?.contractAccount?.paymentType?.uuid,
                                iban: deliveryPoint.contract?.contractAccount?.iban,
                                bicSwift: '',
                                sipoNumber: deliveryPoint.contract?.contractAccount?.sipo,
                                deliveryCategory: deliveryPoint.deliveryCategory
                                    ? ConsumptionCategoryEnum[deliveryPoint.deliveryCategory]
                                    : undefined,
                                inkasoEmail:
                                    deliveryPoint.contract?.contractAccount?.email ||
                                    deliveryPoint.contract?.contractAccount?.businessPartner?.email ||
                                    customerEmail,
                            }}
                            editedValue={newData}
                            correspondenceAddress={deliveryPoint.contract?.contractAccount?.postAddress}
                            onSubmit={onFormSubmit}
                            onReset={onClose}
                            inkasoEmails={inkasoEmails}
                            deliveryPoint={deliveryPoint}
                        />
                    )}

                    {step === ChangeFormStepEnum.SUCCESS && <ChangeRequestSent onClose={onSuccessAccepted} />}

                    {isLoadingCreateRequest && <LoadingIndicator />}
                </ModalBody>
            </Modal>
        </>
    );
};
