import React from 'react';
import { FormGroup } from 'reactstrap';
import { useWindowWidthBreakpoints } from '../../../hooks/use-window-width-breakpoints';

const DatePickerIntervalContainer: React.FC = ({ children }) => {
    const windowWidthBreakpoints = useWindowWidthBreakpoints();

    return (
        <>
            {windowWidthBreakpoints?.sm && <FormGroup className="d-flex flex-row">{children}</FormGroup>}
            {!windowWidthBreakpoints?.sm && (
                <div>
                    {React.Children.map(children, (child) => (
                        <FormGroup className="d-flex flex-row">{child}</FormGroup>
                    ))}
                </div>
            )}
        </>
    );
};

export default DatePickerIntervalContainer;
