import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CodeListTypeEnum, QueryKeysEnum, useCodeList } from '../../../../../../main';
import { IDeliveryPointEeConnection } from '../../../../../../models/customer-request-model';
import { DeliveryPointEeConnectionEnum } from '../../../../../../models/enums';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IDeliveryPointEeConnection;
    bold?: boolean;
};

const DeliveryPointEeConnectionItem: React.FC<Props> = ({ data, bold }) => {
    const { t } = useTranslation();

    const { data: distributionAreaCodelist } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_DISTRIBUTION_AREA,
        codeListTypeEnum: CodeListTypeEnum.DISTRIBUTION_AREA,
        paging: {
            size: 5000,
        },
    });

    const distributionAreaName = useMemo<string | undefined>(() => {
        if (data.distributionArea == null || distributionAreaCodelist == null) {
            return undefined;
        }
        const found = distributionAreaCodelist.find((x) => x.code === data.distributionArea);
        return found?.name;
    }, [distributionAreaCodelist, data.distributionArea]);

    if (isObjectEmpty(data)) return <></>;
    return (
        <>
            <ItemPreview title={t('customer-request.steps.electricity-connection.title')}>
                <div className="mt-1">
                    {data.type === DeliveryPointEeConnectionEnum.NEW ? (
                        <>{t('customer-request.steps.electricity-connection.new-connection')}</>
                    ) : (
                        <>{t('customer-request.steps.electricity-connection.switching-provider')}</>
                    )}
                </div>
                {data.contractNumber && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.contract-number')}: ${
                        data.contractNumber
                    }`}</ItemPreviewContent>
                )}
                {data.businessPartnerId && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.distributor-number')}: ${
                        data.businessPartnerId
                    }`}</ItemPreviewContent>
                )}
                {distributionAreaName && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.electricity-connection.distribution-area',
                    )}: ${distributionAreaName}`}</ItemPreviewContent>
                )}
                <div className="mt-1">
                    {data.currentDistributor && (
                        <>
                            <ItemPreviewHeader title={t('customer-request.steps.electricity-connection.original-supplier')} />
                            {data.currentDistributor}
                        </>
                    )}
                </div>
                {data.type !== DeliveryPointEeConnectionEnum.NEW && data.contractTermination != null && (
                    <div className="mt-1">
                        <ItemPreviewHeader title={t('customer-request.steps.change-supplier.arrange-contract-termination-on-your-behalf')} />
                        <p>
                            {data.contractTermination ? (
                                <Trans i18nKey="common.yes" />
                            ) : (
                                <Trans i18nKey="customer-request.steps.change-supplier.no-arrange-termination-myself" />
                            )}
                        </p>
                    </div>
                )}

                {data.contractTermination && (
                    <>
                        <ItemPreviewHeader title={t('customer-request.steps.change-supplier.binding-on-your-contract')} />
                        <p>
                            {data.bindingTo ? (
                                <Trans i18nKey="common.yes" />
                            ) : (
                                <Trans i18nKey="customer-request.steps.change-supplier.contract-no-binding" />
                            )}
                        </p>
                    </>
                )}
                {data.bindingTo && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.binding-to')}: ${formatDateToPreview(
                        data.bindingTo,
                    )}`}</ItemPreviewContent>
                )}
                {data.noticePeriod != null && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.electricity-connection.notice-period')}: ${
                        data.noticePeriod
                    }`}</ItemPreviewContent>
                )}
            </ItemPreview>
        </>
    );
};

export default DeliveryPointEeConnectionItem;
