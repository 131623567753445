import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary,
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse as DeliveryPointNotificationSettingResponse,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Checkbox from '../../../../components/common/checkbox';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { IRootState, useApi } from '../../../../main';
import { NotificationTemplateCode, NotificationTemplateGroup } from '../../../../models/model';
import { buildUnitedDeliveryPointsSearchQueryKey } from '../../../../utils/react-query-utils';
import { translateNotificationTemplateGroup } from '../notification-setting-utils';

interface IBulkNotificationSettingSelectNotificationsProps {
    selectedNotificationTemplateGroups: NotificationTemplateGroup[];
    onSelect: (
        selectedNotificationTemplateGroups: NotificationTemplateGroup[],
        selectedNotificationTemplateCodes: NotificationTemplateCode[],
    ) => void;
}

const BulkNotificationSettingSelectNotifications: React.FC<IBulkNotificationSettingSelectNotificationsProps> = ({
    selectedNotificationTemplateGroups,
    onSelect,
}) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    const api = useApi();
    const udpSearchObject = {
        deliveryPoint: { hidden: false },
        pairingDone: true,
        includeInactive: false,
        shared: false,
    };
    const { isLoading: isLoadingDeliveryPoints, data: deliveryPointsData, isSuccess: isSuccessDeliveryPoints } = useQueryWithError<
        UnitedDeliveryPointSummary[] | undefined
    >(buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, udpSearchObject), async () =>
        loggedInCustomer?.id == null
            ? undefined
            : api.customers
                  .searchUnitedDeliveryPoint(loggedInCustomer.id, udpSearchObject, undefined, { secure: true })
                  .then((res) => res.data?.result),
    );
    const [groups, setGroups] = useState<NotificationTemplateGroup[] | undefined>(undefined);
    const [groupToTypeMapping, setGroupToTypeMapping] = useState<Record<string, NotificationTemplateCode[]>>({});

    const createGroupingFromNotificationSettings = (
        notificationSettingResponses: DeliveryPointNotificationSettingResponse[],
    ): [Set<NotificationTemplateGroup>, Record<string, NotificationTemplateCode[]>] => {
        const groupSet: Set<NotificationTemplateGroup> = new Set();
        const group2typeMapping: Record<string, NotificationTemplateCode[]> = {};
        notificationSettingResponses.forEach((nsr) =>
            nsr.notificationSettings?.forEach((nt) => {
                const group = nt.notificationTemplate?.group;
                if (group != null) {
                    groupSet.add(group);
                    const type = nt.notificationTemplate?.code;
                    if (type != null) {
                        if (group2typeMapping[group] == null) {
                            group2typeMapping[group] = [type];
                        } else if (!group2typeMapping[group].includes(type)) {
                            group2typeMapping[group].push(type);
                        }
                    }
                }
            }),
        );
        return [groupSet, group2typeMapping];
    };

    const { isLoading: isLoadingSettings, refetch: fetchNotificationSettings } = useQueryWithError<DeliveryPointNotificationSettingResponse[] | null>(
        ['delivery-point-notification-settings', deliveryPointsData],
        async () => {
            if (loggedInCustomer?.id == null) return null;
            const dpIds = (deliveryPointsData || []).reduce<string[]>((acc, dp) => (dp.id ? acc.concat([dp.id]) : acc), []);
            return api.customers.getNotificationSettings(loggedInCustomer.id, dpIds, { secure: true }).then((res) => res.data);
        },
        {
            enabled: false,
            onSuccess: (data: DeliveryPointNotificationSettingResponse[] | null) => {
                if (data == null) {
                    setGroups([]);
                    setGroupToTypeMapping({});
                    return;
                }
                const [groupSet, group2typeMapping] = createGroupingFromNotificationSettings(data);
                setGroups(Array.from(groupSet));
                setGroupToTypeMapping(group2typeMapping);
            },
        },
    );

    useEffect(() => {
        if (isSuccessDeliveryPoints && deliveryPointsData != null) {
            fetchNotificationSettings();
        }
    }, [isSuccessDeliveryPoints, deliveryPointsData, fetchNotificationSettings]);

    const [selectedGroups, setSelected] = useState<NotificationTemplateGroup[]>(selectedNotificationTemplateGroups);

    const areAllChecked = (): boolean => {
        return groups != null && groups.every((group) => selectedGroups.includes(group));
    };
    const toggleAll = () => {
        if (areAllChecked()) {
            setSelected([]);
        } else {
            setSelected(groups || []);
        }
    };
    const onToggle = (notificationTemplateGroup: NotificationTemplateGroup) => () => {
        if (selectedGroups.includes(notificationTemplateGroup)) {
            setSelected((prev) => prev.filter((x) => x !== notificationTemplateGroup));
        } else {
            setSelected((prev) => prev.concat([notificationTemplateGroup]));
        }
    };

    const onAccept = () => {
        let selectedTypes: NotificationTemplateCode[] = [];
        selectedGroups.forEach((sg) => (selectedTypes = selectedTypes.concat(groupToTypeMapping[sg])));
        onSelect(selectedGroups, selectedTypes || []);
    };

    return (
        <>
            <h4 className="mb-3">
                <Trans i18nKey="settings.notifications.notification-types.select-notifications" />
            </h4>

            <form>
                {(isLoadingDeliveryPoints || isLoadingSettings) && <LoadingIndicator />}

                {groups != null && (
                    <>
                        <Checkbox
                            checked={areAllChecked()}
                            onChange={toggleAll}
                            id={'selectAllNotificationTypes'}
                            name=""
                            label={<Trans i18nKey="settings.notifications.notification-types.select-all" />}
                        />
                        <hr className="w-100" />

                        {groups.map((notificationTempalteGroup) => (
                            <Checkbox
                                key={notificationTempalteGroup}
                                id={`notificationTemplates.${notificationTempalteGroup}`}
                                name="notificationTemplates"
                                label={translateNotificationTemplateGroup(notificationTempalteGroup)}
                                checked={selectedGroups.includes(notificationTempalteGroup)}
                                onChange={onToggle(notificationTempalteGroup)}
                            />
                        ))}
                    </>
                )}
                <Button type="button" block color="primary" className="mt-4" onClick={onAccept}>
                    <Trans i18nKey="settings.notifications.confirm" />
                </Button>
            </form>
        </>
    );
};

export default BulkNotificationSettingSelectNotifications;
