import { Reducer } from 'react';
import { HelpActionType, HelpActionTypesEnum } from '../actions/help-actions';

export interface IHelpState {
    availableHelps: string[];
    loaded: boolean;
    isLoading: boolean;
    actualHelpPage: string;
    appVersion?: string;
}

const initialState: IHelpState = {
    availableHelps: [],
    loaded: false,
    isLoading: false,
    actualHelpPage: '',
    appVersion: '',
};

const HelpReducer: Reducer<IHelpState, HelpActionType> = (state = initialState, action) => {
    switch (action.type) {
        case HelpActionTypesEnum.INITIALIZE_HELPS:
            return {
                ...state,
                isLoading: true,
                appVersion: action.appVersion,
            };
        case HelpActionTypesEnum.SET_AVAILABLE_HELPS:
            return {
                ...state,
                availableHelps: action.payload && action.payload.availableHelps ? action.payload.availableHelps : [],
                loaded: true,
                isLoading: false,
            };
        case HelpActionTypesEnum.SET_HELP_PAGE:
            return {
                ...state,
                actualHelpPage: action.payload && action.payload.helpPage ? action.payload.helpPage : '',
            };
        default:
            return state;
    }
};

export default HelpReducer;
