import { SkSppNzpBeApiIdentitymanagementAccountInfo } from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Button, Modal, ModalBody } from 'reactstrap';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import PasswordInput from '../../../../../components/common/password-input';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { ClickableElement, IApiResponse, IResponseError, useFormRules } from '../../../../../main';
import { IRootState } from '../../../../../reducers';

interface IEmailChangeFormFields {
    password: string;
}

interface IRemoveFacebookOrGoogleModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    type: 'facebook' | 'google' | 'apple';
    mutateMe: () => Promise<SkSppNzpBeApiIdentitymanagementAccountInfo | null | undefined>;
    password?: string;
}

const RemoveFacebookOrGoogleModal: React.FC<IRemoveFacebookOrGoogleModalProps> = ({ isOpen, onCloseModal, type, mutateMe, password }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const [showForm, setShowForm] = useState(false);
    const { register, handleSubmit, errors, setError, trigger, reset } = useForm<IEmailChangeFormFields>();
    const { requiredTrimed } = useFormRules();
    const { addToast } = useToasts();

    const [t] = useTranslation();

    useEffect(() => {
        reset();
    }, [reset]);

    const handleEmailChangeError = (error: IResponseError): boolean => {
        if (error.code === 1003) {
            setError('password', {
                message: t('common.input-rules.incorrect-password'),
            });
            return true;
        }
        return false;
    };

    const api = useApi();
    const [mutateRemoveFacebookOrGoogle, { isLoading: isLoadingRemoveFacebookOrGoogle }] = useMutationWithError(
        async (password: string) =>
            type === 'facebook'
                ? api.customers.unpairFbAccount(loggedInCustomer?.id || '', { password }, { secure: true }).then((res) => res.data)
                : type === 'google'
                ? api.customers.unpairGoogleAccount(loggedInCustomer?.id || '', { password }, { secure: true }).then((res) => res.data)
                : api.customers.unpairAppleAccount(loggedInCustomer?.id || '', { password }, { secure: true }).then((res) => res.data),
        {
            onSuccess: () => {
                reset();
                addToast(
                    type === 'facebook'
                        ? t('settings.login-details.facebook-login-delete-success')
                        : t('settings.login-details.google-login-delete-success'),
                    {
                        appearance: 'success',
                    },
                );
                mutateMe();
                setShowForm(false);
                onCloseModal();
            },
        },
    );

    const [mutateVerifyPassword, { isLoading: isLoadingPasswordVerification }] = useMutationWithError(
        async (passwordData: { password: string }) =>
            loggedInCustomer?.id != null ? api.customers.verifyPasswordAccount({ password: passwordData.password }, { secure: true }) : null,
        {
            onSuccess: (resp, passwordData) => {
                mutateRemoveFacebookOrGoogle(passwordData.password);
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error == null) return false;
                return handleEmailChangeError(error);
            },
        },
    );

    const onFormSubmit = handleSubmit((fields) => {
        mutateVerifyPassword({ password: password ? password : fields.password });
    });

    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody className="px-4 pb-4">
                    {(isLoadingRemoveFacebookOrGoogle || isLoadingPasswordVerification) && <LoadingIndicator />}
                    <button
                        type="button"
                        onClick={() => {
                            onCloseModal();
                            setShowForm(false);
                        }}
                        className="close text-right ml-auto mb-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">{t('settings.login-details.disconnect-social-network-account')}</h3>
                    <p>{t('settings.login-details.disconnect-social-network-account-cannot-access-with-account', { type })}</p>
                    <small className="text-secondary">
                        <Trans i18nKey="settings.login-details.disconnect-social-network-lost-access-to-myspp" />
                    </small>

                    {!showForm && (
                        <div className="mt-2">
                            <ClickableElement isText onClick={() => setShowForm(true)} className="text-decoration-underline-inverse">
                                <b>{t('settings.login-details.understand-consequences')}</b>
                            </ClickableElement>
                        </div>
                    )}

                    {showForm && (
                        <form onSubmit={onFormSubmit} noValidate>
                            {!password && (
                                <div className="mt-4">
                                    <p className="mb-2 text-black">
                                        <Trans i18nKey="settings.login-details.password-required-for-next-step" />
                                    </p>
                                    <PasswordInput
                                        ref={register({ ...requiredTrimed })}
                                        id="password"
                                        errors={errors}
                                        name={'password'}
                                        label={t('common.password')}
                                        doNotValidate
                                        trigger={trigger}
                                    />
                                </div>
                            )}

                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-outline-primary mr-2"
                                    onClick={() => {
                                        onCloseModal();
                                        setShowForm(false);
                                    }}
                                >
                                    {t('settings.login-details.keep')}
                                </button>
                                <Button type="submit" color="danger" size="lg" className="my-2">
                                    {t('settings.login-details.disconnect')}
                                </Button>
                            </div>
                        </form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default RemoveFacebookOrGoogleModal;
