import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import { BusinessPartnerTypeEnum, clearObjectProperties, ConsumptionCategoryEnum, KINDcodeHome, LoadingIndicator } from '../../../../../main';
import { ITariff } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CustomerRequestDataEnum } from '../../../config/enums';
import TariffFields from '../components/tariff-fields';

const BlockEeTariff: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();

    const { currentBlock, content, partnersDeliveryPoints, businessPartner, additionalData } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.TARIFF;
    const objectData = content[objectName] as ITariff;

    const useAdditionalDataDeliveryPoint = additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.status === 'ACTIVE').length === 1;
    const additionalDataDeliveryCategory = useAdditionalDataDeliveryPoint
        ? additionalData?.udpd?.deliveryPoints?.find((dp) => dp.status === 'ACTIVE')?.deliveryCategory
        : undefined;
    const [consumptionCategory, setConsumptionCategory] = useState<ConsumptionCategoryEnum | undefined>(
        content.tariffZp?.tariff?.category ?? (additionalDataDeliveryCategory ? ConsumptionCategoryEnum[additionalDataDeliveryCategory] : undefined),
    );

    const { register, handleSubmit, errors, trigger, getValues } = useForm<ITariff>({ defaultValues: { ...objectData } });

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    useEffect(() => {
        if (!consumptionCategory) {
            if (businessPartner) {
                businessPartner.kind?.code === KINDcodeHome
                    ? setConsumptionCategory(ConsumptionCategoryEnum.HOME)
                    : setConsumptionCategory(ConsumptionCategoryEnum.RETAIL);
            } else if (content.accountInfo) {
                content.accountInfo.category === BusinessPartnerTypeEnum.COMPANY
                    ? setConsumptionCategory(ConsumptionCategoryEnum.RETAIL)
                    : setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            } else if (content.businessPartner) {
                content.businessPartner.type === BusinessPartnerTypeEnum.COMPANY
                    ? setConsumptionCategory(ConsumptionCategoryEnum.RETAIL)
                    : setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            } else {
                setConsumptionCategory(ConsumptionCategoryEnum.HOME);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersDeliveryPoints]);

    const { data: tariffRates, isLoading: isLoadingTariffRates } = useQueryWithError<TariffRate[] | undefined>(
        [QueryKeysEnum.PRODUCT_TARIFF_RATE, { type: 'ZP', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }],
        async () =>
            consumptionCategory == null
                ? undefined
                : api.productCatalogs
                      .getTariffsAndRates({ type: 'ZP', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }, { secure: true })
                      .then((res) => res.data?.result),
    );

    const onSubmit = (data: ITariff) => {
        const selectedTariff = tariffRates?.find((rate) => rate.uuid === data.tariff?.uuid);
        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties({ ...data, tariff: selectedTariff }),
            saved: content.saveRequested ? true : undefined,
        };

        dispatch(CustomerRequestActions.setData(payload));
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    return (
        <>
            {isLoadingTariffRates && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {tariffRates && (
                    <>
                        {tariffRates && <TariffFields register={register} errors={errors} data={tariffRates} trigger={trigger} />}
                        <BaseButton type="submit" disabled={tariffRates?.length === 0}>
                            <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                        </BaseButton>
                    </>
                )}
                {tariffRates && tariffRates.length === 0 && (
                    <div className="alert alert-danger p-4 d-flex mb-5" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                        <div className="ml-4">
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-tariff-not-supported"></Trans>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
};

export default BlockEeTariff;
