import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../reducers';

export enum SessionStorageEnum {
    showInactiveDeliveryPoints = 'show-inactive-delivery-points',
}

/**
 * https://stackoverflow.com/questions/60048276/alternative-to-custom-hook-call-inside-useeffect
 * @param key
 * @param initialValue
 */
export function useSessionStorage<T>(key: string, initialValue?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
    const loggedInEmployee = useSelector((state: IRootState) => state.user.employee);
    const loggedInCustomer = useSelector((state: IRootState) => state.user.customer);

    const encryptKey = useCallback(() => {
        const empKey = loggedInEmployee?.id != null ? loggedInEmployee.id : '';
        const cusKey = loggedInCustomer?.id != null ? loggedInCustomer.id : '';
        return [empKey, cusKey];
    }, [loggedInEmployee, loggedInCustomer]);

    const getStorageKey = useCallback(() => {
        const [empKey, cusKey] = encryptKey();
        return `${empKey}-${cusKey}-${key}`;
    }, [encryptKey, key]);

    const getSessionStorageValue = useCallback(() => {
        try {
            const storageKey = getStorageKey();
            const currValue = window.sessionStorage.getItem(storageKey);
            return currValue ? JSON.parse(currValue) : initialValue;
        } catch (e) {
            return initialValue;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStorageKey]);

    const [storedValue, setStoredValue] = useState<T | undefined>(initialValue);

    useEffect(() => {
        setStoredValue(getSessionStorageValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setValue = useCallback(
        (value: SetStateAction<T | undefined>) => {
            try {
                setStoredValue(value);
                const currValue: T = getSessionStorageValue();
                const newValue = value instanceof Function ? value(currValue) : value;
                const storageKey = getStorageKey();
                window.sessionStorage.setItem(storageKey, JSON.stringify(newValue));
            } catch (e) {}
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getSessionStorageValue, getStorageKey],
    );

    const clear = () => {
        const storageKey = getStorageKey();
        window.sessionStorage.removeItem(storageKey);
    };

    return [storedValue, setValue, clear];
}
