import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';
import { AcceptCookiesEnum } from '../cookies-alert';

interface ICookiesButtons {
    setShowSettingsModal?: (val: boolean) => void;
    handleCookies: (value: string[] | AcceptCookiesEnum) => void;
    acceptedCategoryTypeIds?: string[];
}

const CookiesButtons: React.FC<ICookiesButtons> = ({ setShowSettingsModal, handleCookies, acceptedCategoryTypeIds }) => {
    return (
        <div className="d-flex cookies-buttons-container mt-4">
            <Button className="btn btn-primary btn-block buttons order-3 order-md-1" onClick={() => handleCookies(AcceptCookiesEnum.NECESSARY)}>
                <Trans i18nKey="cookies-alert.reject-all">Odmietnuť všetky</Trans>
            </Button>
            <Button
                className="btn btn-primary btn-block buttons order-2"
                onClick={() => (acceptedCategoryTypeIds ? handleCookies(acceptedCategoryTypeIds) : setShowSettingsModal?.(true))}
            >
                <span className="set-cookies">
                    <Trans i18nKey="cookies-alert.set-cookies">Nastaviť cookies</Trans>
                </span>
            </Button>
            <Button className="btn btn-primary btn-block buttons order-1 order-md-3" onClick={() => handleCookies(AcceptCookiesEnum.ALL)}>
                <Trans i18nKey="cookies-alert.enable-all">Povoliť všetky</Trans>
            </Button>
        </div>
    );
};

export default CookiesButtons;
