import { faComment, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getHelpHash, matchHelpHash } from '../../hooks/use-help-text';
import { IRootState } from '../../reducers';
import HelpText from './help-text';

const HelpContextualWithChat: React.FC = () => {
    const actualPage = useSelector((state: IRootState) => state.help.actualHelpPage);
    const canShowAsistant = process.env.REACT_APP_SHOW_ASSISTANT === 'true';

    return (
        <>
            <div className="container" style={{ position: 'absolute', zIndex: 10 }}>
                <div className="row">
                    <div className="col">
                        <div className="popup-buttons">
                            {actualPage && <HelpContextual screen={actualPage} />}
                            &nbsp;
                            {canShowAsistant && <Chat />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Chat: React.FC = () => {
    const [chatShow, setChatShow] = useState(false);

    return (
        <>
            <button className="btn btn-round btn-inverted btn-popup" onClick={() => setChatShow(true)}>
                <FontAwesomeIcon icon={faComment} />
            </button>
            <div className={`popup-chat px-4 pt-4 bg-white ${chatShow ? 'show' : ''}`} style={{ width: 255 }}>
                <div className="d-flex align-items-center">
                    <button type="button" className="close ml-auto " onClick={() => setChatShow(false)}>
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                </div>
                <div className="row">
                    <div className="col">
                        <div>
                            <iframe
                                src="https://avay.spp.sk/avay/smartassistant.php"
                                id="smartassistant"
                                width="205"
                                height="300"
                                scrolling="no"
                                title="chatBox"
                                frameBorder={0}
                                className="d-block"
                            ></iframe>
                            {/* allowTransparency */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

type HelpContextualProps = {
    screen: string;
};

const HelpContextual: React.FC<HelpContextualProps> = ({ screen }) => {
    const [helpShown, setHelpShown] = useState(false);

    const help = useSelector((state: IRootState) => state.help);

    return (
        <>
            {help && help.availableHelps && matchHelpHash(help.availableHelps, getHelpHash(screen, screen + '_HELP', i18n.language)) && (
                <>
                    <button className="btn btn-round btn-inverted btn-popup" onClick={() => setHelpShown(true)}>
                        <FontAwesomeIcon icon={faQuestion} />
                    </button>
                    {!helpShown ? null : (
                        <div className="popup-help bg-white container show" style={{ zIndex: 1000 }}>
                            <div className="d-flex align-items-center p-4 border-bottom">
                                <div style={{ textAlign: 'left' }}>
                                    <h4>
                                        <Trans i18nKey="help.title">Pomoc</Trans>
                                    </h4>
                                    <Trans className="mb-0" i18nKey={`help.screen-title.${screen}`} />
                                </div>
                                <button type="button" className="close ml-auto" onClick={() => setHelpShown(false)}>
                                    <i className="icon-Times" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="row p-4 popup-content">
                                <div className="col text-left">
                                    <HelpText screen={screen} field={screen + '_HELP'} />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export { HelpContextual, HelpContextualWithChat, Chat };
