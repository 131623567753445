import React from 'react';
import { Container, Row } from 'reactstrap';
import Benefits from '../../components/common/benefits';
import { useUrlQuery } from '../../hooks/use-url-query';
import IssueConfirmMessageStep from './components/issue-confirm-message-step';
import ReportIssueStep from './components/report-issue-step';

const ReportIssue: React.FC = () => {
    const urlQuery = useUrlQuery();
    const step = urlQuery.get('regform');

    const renderReportIssueStep = () => {
        switch (step) {
            case 'submitted':
                return <IssueConfirmMessageStep />;
            default:
                return <ReportIssueStep />;
        }
    };

    return (
        <Container className="my-5">
            <Row>
                <Benefits />
                {renderReportIssueStep()}
            </Row>
        </Container>
    );
};

export default ReportIssue;
