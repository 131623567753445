import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducers';

interface ISsoRefresherProps {
    refreshInterval?: number;
}

const SsoRefresher: React.FC<ISsoRefresherProps> = ({ refreshInterval }) => {
    const isUserLogged = useSelector((state: IRootState) => state.user.isUserLogged);

    useEffect(() => {
        const interval = refreshInterval || Number(process.env.REACT_APP_SSO_RENEW_SESSION_INTERVAL) || 300000;
        const ssoTimer = setInterval(() => {
            if (process.env.REACT_APP_SSO_RENEW_SESSION_URL && isUserLogged) {
                console.log('refresh session.gif', interval, new Date().toLocaleString());
                fetch(process.env.REACT_APP_SSO_RENEW_SESSION_URL, { credentials: 'include' }).then(() => undefined);
            }
        }, interval);
        return () => clearInterval(ssoTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLogged]);

    return <></>;

    /*
    const isUserLogged = useSelector((state: IRootState) => state.user.isUserLogged);
    const { getLastActiveTime } = useIdleTimer({
        timeout: 365 * 24 * 60 * 60 * 1000,
        onIdle: () => { },
    });
    const [ssoRefresher, setSsoRefresher] = useState<number>(0);

    useEffect(() => {
        const interval = refreshInterval || Number(process.env.REACT_APP_SSO_RENEW_SESSION_INTERVAL);
        if (!interval) {
            return;
        }
        const actualTime = new Date().getTime();
        if (actualTime - getLastActiveTime() < interval && process.env.REACT_APP_SSO_RENEW_SESSION_URL && isUserLogged) {
            fetch(process.env.REACT_APP_SSO_RENEW_SESSION_URL, { credentials: 'include' }).then(() => { });
        }
        const trigger = setTimeout(() => {
            setSsoRefresher(ssoRefresher + 1);
        }, interval);
        return () => {
            clearTimeout(trigger);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoRefresher, isUserLogged]);

    return <></>;
    */
};

export default SsoRefresher;
