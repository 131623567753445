import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeliveryPoint } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IDeliveryPoint;
    bold?: boolean;
};

const DeliveryPointItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();
    delete (data as any).componentType;
    if (isObjectEmpty(data)) return <></>;

    const { address, contractNumber, deliveryPointNumber, meterNumber, eic, pod } = data;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.delivery-point.title')}>
                {address && !isObjectEmpty(address) && (
                    <>
                        <ItemPreviewContent bold={bold}>{`${address?.street || ''} ${address?.number || ''}`}</ItemPreviewContent>
                        <p>
                            <ItemPreviewContent bold={bold}>{`${address?.postalCode || ''}, ${address?.city || ''}`}</ItemPreviewContent>
                        </p>
                        {address?.floor && (
                            <>
                                {t('customer-request.steps.address.floor')}
                                <p>{address?.floor}.</p>
                            </>
                        )}
                        {address?.flatNumber && (
                            <>
                                {t('customer-request.steps.address.appartments-number')}
                                <p>{address?.flatNumber}</p>
                            </>
                        )}
                    </>
                )}
                {contractNumber && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point.contract-number',
                    )}: ${contractNumber}`}</ItemPreviewContent>
                )}
                {meterNumber && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point.gauge-number',
                    )}: ${meterNumber}`}</ItemPreviewContent>
                )}
                {deliveryPointNumber && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point.delivey-point-number',
                    )}: ${deliveryPointNumber}`}</ItemPreviewContent>
                )}
                {eic && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.delivery-point.eic')}: ${eic}`}</ItemPreviewContent>}
                {pod && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.delivery-point.pod')}: ${pod}`}</ItemPreviewContent>}
            </ItemPreview>
        </>
    );
};

export default DeliveryPointItem;
