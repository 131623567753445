import classNames from 'classnames';
import { nextTick } from 'process';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { ClickableElement } from '../../../../../components/common/clickable-element';
import { IRootState } from '../../../../../reducers';
import { formatCurrency } from '../../../../../utils/utils';

const BlockNumberOfInstallments: React.FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const { content, invoices, additionalData } = useSelector((state: IRootState) => state.customerRequest);

    const [installmentsNumber, setInstallmentsNumber] = useState(content.underchargeSettlementInvoice?.repaymentCount || 2);
    const [installmentsOption, setInstallmentsOption] = useState(
        (content.underchargeSettlementInvoice?.repaymentCount || 0) > 4 ? [2, 3, 4, 5, 6] : [2, 3, 4],
    );

    const onSubmit = () => {
        dispatch(
            CustomerRequestActions.setData({ underchargeSettlementInvoice: { repaymentCount: installmentsNumber, invoiceNumber: '', amount: 0 } }),
        );
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const overallPrice = () => {
        let price = 0;
        if (content.invoice) {
            return content.invoice.unpaid;
        }
        invoices?.forEach((item) => (price = item.unpaid ? price + item.unpaid : price));
        return price;
    };

    return (
        <>
            <div className="text-center">
                <ButtonGroup
                    className="d-md-inline-flex custom-buttons-group mb-4 w-100"
                    style={{ maxWidth: installmentsOption.length === 3 ? 350 : '' }}
                >
                    {installmentsOption.map((option) => (
                        <button
                            key={`option_${option}`}
                            onClick={() => setInstallmentsNumber(option)}
                            className={classNames('btn', {
                                active: option === installmentsNumber,
                            })}
                        >
                            {option}
                        </button>
                    ))}
                </ButtonGroup>
            </div>
            <div className="mt-2 mb-4 text-center overall-price">
                {t('customer-request.steps.unpaid-invoices.overall-price', { price: formatCurrency(overallPrice() || 0) })}
            </div>
            {installmentsOption.length > 3 && additionalData?.udpd?.businessPartner?.queue === 'INDIVIDUAL' && (
                <div className="px-5 m-4 text-center">
                    <Trans i18nKey="customer-request.steps.number-of-installments.other-installments">
                        V prípade záujmu o iné podmienky splátok kontaktujte svojho manažéra predaja.
                    </Trans>
                </div>
            )}
            {installmentsOption.length === 3 && (
                <ClickableElement isText onClick={() => setInstallmentsOption([2, 3, 4, 5, 6])} className="text-decoration-underline-inverse">
                    <div className="text-decoration-underline-inverse text-center">
                        <Trans i18nKey="customer-request.steps.number-of-installments.need-more-installments" />
                    </div>
                </ClickableElement>
            )}
            <BaseButton type="button" onClick={() => onSubmit()}>
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockNumberOfInstallments;
