import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IRootState } from '../../../../../reducers';

type DeliveryPointFormType = {
    deliveryPointId: string;
};

const BlockDeliveryPointShort: React.FC = () => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const { formRules } = useFormRules();

    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;

    const { invoice } = useSelector((state: IRootState) => state.customerRequest.content);
    const { deliveryPoint } = useSelector((state: IRootState) => state.customerRequest);

    const { register, handleSubmit } = useForm<DeliveryPointFormType>({
        defaultValues: {
            deliveryPointId: deliveryPoint?.id ?? (invoice?.deliveryPoints && invoice?.deliveryPoints.length > 0 ? invoice.deliveryPoints[0].id : ''),
        },
    });

    const onSubmit = (data: DeliveryPointFormType) => {
        const deliveryPoint = invoice?.deliveryPoints?.find((DP) => DP.id === data.deliveryPointId);
        dispatch(CustomerRequestActions.setDeliveryPoint(deliveryPoint));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <>
            <div className="text-center my-2 my-lg-5">
                <h3>{<Trans i18nKey="customer-request.steps.reason-for-claim-detail.choose-delivery-point">Vyberte odberné miesto:</Trans>}</h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {invoice?.deliveryPoints?.map((item) => (
                    <BaseRadio
                        ref={register({ ...formRules.required })}
                        id={`deliveryPoint${item.id}`}
                        key={item.id}
                        name="deliveryPointId"
                        value={item.id}
                        label={`${item.address?.street} ${item.address?.streetNumber}, ${item.address?.city}, ${t(
                            `enums.CommodityEnum.${item.type}`,
                        )}, (${item.externalId}) `}
                        touched={touched}
                    />
                ))}

                <BaseButton type="submit">
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
        </>
    );
};

export default BlockDeliveryPointShort;
