import { faSortAmountDownAlt, faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { SortDirection } from './table-interfaces';

const shiftSort = (sort: SortDirection | undefined): SortDirection | undefined => {
    switch (sort) {
        case 'ASC':
            return 'DESC';
        case 'DESC':
            return undefined;
        default:
            return 'ASC';
    }
};
interface IColumnHeaderCellProps {
    label: ReactNode;
    width?: string;
    minWidth?: string;
    sort?: SortDirection | undefined;
    sortable?: boolean;
    colSpan?: number;
    onSortChange?: (sort: SortDirection | undefined) => void;
}

const ColumnHeaderCell: React.FC<IColumnHeaderCellProps> = ({ label, width, minWidth, sort, sortable, colSpan, onSortChange }) => {
    return (
        <>
            <th
                scope="col"
                style={{ width: width, minWidth: minWidth }}
                className={classNames({ sortable }, sort)}
                colSpan={colSpan}
                onClick={() => onSortChange && onSortChange(shiftSort(sort))}
            >
                {label}
                {sortable && (
                    <>
                        {'  '}
                        <FontAwesomeIcon icon={sort === 'DESC' ? faSortAmountDownAlt : faSortAmountUpAlt} className={sort ? sort : 'ASC'} />
                    </>
                )}
            </th>
        </>
    );
};

export default ColumnHeaderCell;
