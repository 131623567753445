import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { QueryKeysEnum } from '../../../../../../main';
import { IPaymentMethod } from '../../../../../../models/customer-request-model';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IPaymentMethod;
    bold?: boolean;
};

const NewPaymentMethodItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    const { data: paymentTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_ADVANCE_PAYMENT_TYPE,
        codeListTypeEnum: CodeListTypeEnum.PAYMENT_TYPE,
        paging: {
            size: 30,
        },
    });

    const paymentTypeCode = useMemo(() => {
        return paymentTypeCodeList?.find((item) => item.uuid === data.paymentType.uuid)?.name;
    }, [paymentTypeCodeList, data.paymentType]);

    if (isObjectEmpty(data)) return <></>;

    const { bicSwift, iban, sipoNumber } = data;

    return (
        <ItemPreview title={t('customer-request.steps.new-data.new-payment-method')}>
            <ItemPreviewContent bold={bold}>{paymentTypeCode ?? ''}</ItemPreviewContent>
            {iban && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${iban}`}</ItemPreviewContent>}
            {bicSwift && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.bic-swift')}: ${bicSwift}`}</ItemPreviewContent>}

            {sipoNumber && <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.payment-method.sipo')}: ${sipoNumber}`}</ItemPreviewContent>}
        </ItemPreview>
    );
};

export default NewPaymentMethodItem;
