import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal, ModalBody } from 'reactstrap';
import { UserActions } from '../../../../../actions/user';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useApi } from '../../../../../hooks/use-api';
import useMutationWithError from '../../../../../hooks/use-mutation-with-error';
import { IRootState } from '../../../../../reducers';
import NameChangeForm from './name-change-form';

interface INameChangeModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
}

const NameChangeModal: React.FC<INameChangeModalProps> = ({ isOpen, onCloseModal }) => {
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const { addToast } = useToasts();
    const api = useApi();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const onNameUpdateSuccess = useCallback(
        (firstName: string, lastName: string) => {
            addToast(t('settings.login-details.name-has-been-changed'), {
                appearance: 'success',
            });
            dispatch(UserActions.setName(firstName, lastName));
            onCloseModal();
        },
        [addToast, onCloseModal, dispatch, t],
    );

    const [mutateNameChange, { isLoading, reset: resetMutationState }] = useMutationWithError(
        async (newName: { firstName: string; lastName: string }) => {
            const cleanName = { firstName: newName.firstName.trim(), lastName: newName.lastName.trim() };
            return loggedInCustomer?.id != null ? api.customers.changeName(loggedInCustomer.id, cleanName, { secure: true }) : null;
        },
        {
            onSuccess: (data: unknown, variables: { firstName: string; lastName: string }) => {
                onNameUpdateSuccess(variables.firstName, variables.lastName);
                resetMutationState();
            },
            onErrorWithGlobalErrorHandling: () => false,
        },
    );

    const onFormSubmit = (newName: { firstName: string; lastName: string }) => {
        mutateNameChange(newName);
    };

    const currentName = { firstName: loggedInCustomer?.firstName?.trim() || '', lastName: loggedInCustomer?.lastName?.trim() || '' };

    if (loggedInCustomer == null) return null;
    return (
        <>
            <Modal isOpen={isOpen} centered>
                <ModalBody className="px-4 pb-5">
                    {isLoading && <LoadingIndicator />}
                    <button type="button" onClick={onCloseModal} className="close text-right ml-auto mb-4" data-dismiss="modal" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>
                    <h3 className="mb-4">
                        <Trans i18nKey="settings.change-name-surname" />
                    </h3>
                    <NameChangeForm value={currentName} onSubmit={onFormSubmit} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default NameChangeModal;
