import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAttachments } from '../../../../../../models/customer-request-model';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IAttachments[];
    bold?: boolean;
};

const AppendixItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (!data.length) return <></>;

    return (
        <ItemPreview title={t('customer-request.steps.attachments.attachments-title')}>
            {data.map((item, index) => (
                <ItemPreviewContent key={index} bold={bold} classNames={'text-cut-dots'}>
                    {item.file?.name || item.name}
                </ItemPreviewContent>
            ))}
        </ItemPreview>
    );
};

export default AppendixItem;
