import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiNotificationCustomerNotificationSummary } from '@spp/spp-meru-frontend-common';
import { SkSppNzpBeApiNotificationCustomerNotificationSummary } from '@spp/spp-meru-frontend-common/src/api';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { useApi } from '../../../hooks/use-api';
import { Routes } from '../../../main';
import { IRootState } from '../../../reducers';
import { ClickableElement } from '../../common';
import LoadingIndicator from '../../common/loading-indicator';
import { DeleteAllNotifications } from './delete-all-notifications';
import NotificationsDropdownItem from './notifications-dropdown-item';

const Notifications: React.FC = () => {
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const loggedInEmployee = useSelector((s: IRootState) => s.user.employee);

    const [allReaded, setAllReaded] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState<SkSppNzpBeApiNotificationCustomerNotificationSummary[]>([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const PAGE_SIZE = 20;

    const history = useHistory();
    const api = useApi();

    const isNotificationRead = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => !!notification.readAt;
    const anyNotifications = notifications.length > 0;

    const [olderNotificationsCount, setOlderNotificationsCount] = useState<number>(0);

    const aRef: React.Ref<HTMLAnchorElement> = useRef(null);

    const {
        isLoading: isLoadingNotifications,
        fetchMore,
        clear,
    } = useInfiniteQuery<SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiNotificationCustomerNotificationSummary | null>(
        ['notifications', loggedInCustomer?.id],
        // key1 and key2 are placeholders for items in QueryKey array
        async (key1, key2, pageParam) =>
            loggedInCustomer?.id
                ? api.customers
                      .getCustomerNotificationsSearch(
                          loggedInCustomer.id,
                          {
                              statuses: ['RENDERED', 'SENT'],
                              paging: { sort: [{ attribute: 'createdAt', direction: 'DESC' }], size: PAGE_SIZE, page: pageParam || 0 },
                          },
                          { secure: true },
                      )
                      .then((res) => res.data)
                : null,
        {
            onSuccess: (notificationsData) => {
                const notifications = notificationsData.map((x) => x?.result || []).flat();
                if (notifications == null) {
                    return;
                }
                setNotifications(notifications);

                const paging = notificationsData[notificationsData.length - 1]?.paging;
                if (paging != null) {
                    const total = paging.total || 0;
                    const pageEnd = (pageIndex + 1) * PAGE_SIZE;
                    setOlderNotificationsCount(Math.max(total - pageEnd, 0));
                }

                setAllReaded(notifications.every((notification) => isNotificationRead(notification)));
                const unread = notifications.filter((x) => !isNotificationRead(x)).length;
                setUnreadNotificationsCount(unread);
            },
        },
    );

    useEffect(() => {
        fetchMore(pageIndex);
    }, [pageIndex, fetchMore]);

    useEffect(() => {
        // mark not read notifications as read
        if (dropdownOpen && notifications && !loggedInEmployee) {
            const uuids = Array.from(notifications.filter((not) => !not.readAt).map((not) => '' + not.uuid));
            if (uuids.length > 0) {
                api.notifications.putNotificationsRead(uuids, { secure: true });
            }
        }
    }, [notifications, dropdownOpen, api, loggedInEmployee]);

    const showDropdown = () => {
        setDropdownOpen(true);
    };

    const hideDropdown = () => {
        if (!loggedInEmployee) {
            setAllReaded(true);
        }
        setDropdownOpen(false);
    };

    const toggleMenu = () => {
        if (dropdownOpen) {
            hideDropdown();
        } else {
            showDropdown();
        }
    };

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (dropdownOpen) {
            setPageIndex(0);
            clear();
            if (dropdownRef?.current?.scrollTop != null) {
                dropdownRef.current.scrollTop = 0;
            }
        }
    }, [dropdownOpen, clear]);

    const isClickable = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => {
        if (notification.executionType === 'AUTOMATIC') {
            if (notification.entityId) {
                switch (notification.entityType) {
                    case 'INVOICE':
                    case 'CUSTOMER_REQUEST':
                        return true;
                }
            }
            if (notification.unitedDeliveryPointUuid) {
                return true;
            }
        }
        return !!notification.headerUrl;
    };

    const handleManualExecutionNotificatonClick = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => {
        if (!!notification.headerUrl) {
            if (notification.headerUrl.startsWith('http://') || notification.headerUrl.startsWith('https://')) {
                // is absolute
                if (aRef?.current != null) {
                    aRef.current.href = notification.headerUrl || '';
                    aRef.current.click();
                }
            } else {
                // is relative
                history.push(notification.headerUrl);
            }
        }
    };

    const handleAutomaticExecutionNotificatonClick = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => {
        if (notification.templateCode === 'METER_READING_CUSTOMER' && notification.unitedDeliveryPointUuid) {
            history.push(`${Routes.DELIVERY_POINTS}/${notification.unitedDeliveryPointUuid}${Routes.SELF_READ}`);
            hideDropdown();
        } else {
            if (notification.entityId) {
                switch (notification.entityType) {
                    case 'INVOICE':
                        history.push(`${Routes.INVOICES_DETAIL}/${notification.entityId}`);
                        hideDropdown();
                        break;
                    case 'CUSTOMER_REQUEST':
                        history.push(`${Routes.CUSTOMER_REQUESTS}/${notification.entityId}`);
                        hideDropdown();
                        break;
                }
            }
            if (notification.unitedDeliveryPointUuid) {
                history.push(`${Routes.DELIVERY_POINTS}/${notification.unitedDeliveryPointUuid}`);
                hideDropdown();
            }
        }
    };

    const onClick = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => {
        if (notification.executionType === 'AUTOMATIC') {
            handleAutomaticExecutionNotificatonClick(notification);
        } else {
            handleManualExecutionNotificatonClick(notification);
        }
    };

    const onDelete = (notification: SkSppNzpBeApiNotificationCustomerNotificationSummary) => {
        api.notifications.deleteByUuid(notification.uuid + '', { secure: true }).then(() => {
            const index = notifications.indexOf(notification);
            if (index >= 0) {
                notifications.splice(index, 1);
                setNotifications([...notifications]);
            }
        });
    };

    const changePage = (step: number) => {
        setPageIndex((prev) => Math.max(prev + step, 0));
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleMenu} className="ml-auto mr-lg-5 mb-1 order-2 order-lg-3">
            <DropdownToggle tag="a" className="text-decoration-none cursor-pointer">
                <div className="navbar-nav mr-auto ml-0">
                    <div className="nav-item mx-0 mx-lg-2 text-center" style={{ position: 'relative' }}>
                        <div className={`nav-link nav-link2 py-3`} style={{ minWidth: 40 }}>
                            {!allReaded ? (
                                <span>
                                    <FontAwesomeIcon icon={faBell} style={{ position: 'relative', fontSize: '1.4rem' }} />
                                    <span className="badge badge-secondary">
                                        <span style={{ position: 'relative', top: 3 }}>{unreadNotificationsCount}</span>
                                    </span>
                                </span>
                            ) : (
                                <i className="icon-bell fa-lg" style={{ position: 'relative', fontSize: '1.4rem' }} />
                            )}
                            <div className="d-lg-none p-0 text-center">
                                <p className="navbar-text pt-0 pb-0 mb-0 d-lg-none">
                                    <Trans i18nKey="notification-bar.notifications">Upozornenia</Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </DropdownToggle>
            <div
                className={`dropdown-menu notification-menu dropdown-menu-right p-0 mt-2 mr-lg-4 text-primary ${dropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownNotification"
            >
                {isLoadingNotifications && <LoadingIndicator size="medium" />}

                <div className={`top-radius px-4 py-3 bg-white`}>
                    <h3 className={`d-inline ${anyNotifications ? 'd-lg-none' : ''}`}>
                        {anyNotifications ? (
                            <Trans i18nKey="notification-bar.notifications">Upozornenia</Trans>
                        ) : (
                            <Trans i18nKey="notification-bar.no-notifications">Žiadne upozornenia</Trans>
                        )}
                    </h3>
                    <button type="button" onClick={toggleMenu} className="close" aria-label="Close">
                        <i className="icon-Times" aria-hidden="true"></i>
                    </button>

                    {anyNotifications && <DeleteAllNotifications />}
                </div>

                {
                    // eslint-disable-next-line
                    <a target="_blank" rel="noreferrer" ref={aRef} hidden />
                }

                <div ref={dropdownRef} className="dropdown-body no-top-radius border-top">
                    {notifications.map((notification) => (
                        <NotificationsDropdownItem
                            key={notification.uuid}
                            notification={notification}
                            isClickable={isClickable}
                            isReaded={isNotificationRead}
                            onClick={onClick}
                            onDelete={onDelete}
                        />
                    ))}

                    {olderNotificationsCount > 0 && !isLoadingNotifications && (
                        <ClickableElement isText onClick={() => changePage(1)}>
                            <div className="text-center p-3">
                                <Trans i18nKey="notification-bar.load-older-notifications"> Načítať staršie upozornenia</Trans>
                            </div>
                        </ClickableElement>
                    )}
                </div>
            </div>
        </Dropdown>
    );
};

export default Notifications;
