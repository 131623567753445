import {
    SkSppNzpBeApiCustomeraccessLoginPreLoginChallenge,
    SkSppNzpBeApiCustomeraccessRegistrationPreRegistrationChallenge,
} from '@spp/spp-meru-frontend-common';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Row } from 'reactstrap';
import Checkbox from '../../../components/common/checkbox';
import { ClickableElement } from '../../../components/common/clickable-element';
import Input from '../../../components/common/input';
import LoadingIndicator from '../../../components/common/loading-indicator';
import PasswordInput from '../../../components/common/password-input';
import HelpModal from '../../../components/help/help-modal';
import { useCodeChallenge } from '../../../hooks/sso/use-code-challenge';
import { AUTH_REDIRECT_URI } from '../../../hooks/sso/use-sso';
import { useApi } from '../../../hooks/use-api';
import { useFormRules } from '../../../hooks/use-form-rules';
import { useKeyValueStore } from '../../../hooks/use-key-value';
import useMutationWithError from '../../../hooks/use-mutation-with-error';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { IApiResponse } from '../../../main';
import { AuthEnum, PRE_REGISTRATION } from '../../../models/enums';

interface IForm {
    email: string;
    'new-password': string;
    privacyPolicy: boolean;
    termsAndConditions: boolean;
}

interface IPreRegistrationProps {
    jwt?: string;
    decodedJwtEmail?: string;
    sessionData?: ISessionData;
}

export interface ISessionData {
    challengeCode?: string;
    challengeUuid?: string;
    type?: string;
    email?: string;
}

const EnterPreRegistrationDataScreen: React.FC<IPreRegistrationProps> = ({ jwt, sessionData, decodedJwtEmail }) => {
    const urlQuery = useUrlQuery();
    const challengeCode = urlQuery.get('challengeCode') || sessionData?.challengeCode;
    const challengeUuid = urlQuery.get('challengeCodeUuid') || sessionData?.challengeUuid;
    const firstName = urlQuery.get('firstName');
    const lastName = urlQuery.get('lastName');
    const email = urlQuery.get('email') || sessionData?.email || decodedJwtEmail;
    const [t] = useTranslation();
    const { formRules, passwordRules, requiredTrimed, sppDomain } = useFormRules();
    const [isTermsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [termsAndConditionsShown, setTermsAndConditionsShown] = useState(false);
    const [privacyPolicyShown, setPrivacyPolicyShown] = useState(false);
    const api = useApi();
    const getCodeChallenge = useCodeChallenge();
    const benefitsObj = t('registration.benefits', { returnObjects: true });
    const benefits = Object.values(benefitsObj);
    const socialLogin = false; //!!jwt;
    const [_, setIsPreRegistrationPage] = useKeyValueStore(PRE_REGISTRATION, true);
    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToasts();

    useEffect(() => {
        if (socialLogin) {
            const searchParams = new URLSearchParams(location.search);
            challengeCode && searchParams.set('challengeCode', challengeCode);
            challengeUuid && searchParams.set('challengeUuid', challengeUuid);
            email && searchParams.set('email', email);
            searchParams.delete('state');
            searchParams.set('type', PRE_REGISTRATION);
            history.push({
                search: searchParams.toString(),
                hash: location.hash,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsPreRegistrationPage(true);
        return () => setIsPreRegistrationPage(false);
    }, [setIsPreRegistrationPage]);

    const [mutatePreRegistrationChallenge, { isLoading: isLoadingPreRegistrationChallenge }] = useMutationWithError(
        async (data: SkSppNzpBeApiCustomeraccessRegistrationPreRegistrationChallenge) => api.customers.confirmPreRegistrationChallenge(data),
        {
            onSuccess: (response) => {
                getCodeChallenge().then((codeChallenge) => {
                    const scopes = process.env.REACT_APP_SSO_SCOPE?.split(' ');
                    const ssoClientId = '' + process.env.REACT_APP_SSO_CLIENT_ID;
                    response.data &&
                        mutatePreLogin({
                            code: response.data.code,
                            uuid: response.data.uuid,
                            challengeCode: codeChallenge,
                            challengeMethod: AuthEnum.CODE_CHALLENGE_METHOD,
                            responseType: AuthEnum.RESPONSE_TYPE_CODE,
                            clientId: process.env.REACT_APP_SSO_CLIENT_ID || '',
                            scopes: scopes,
                            redirectUri: AUTH_REDIRECT_URI[ssoClientId],
                        });
                });
            },
            onErrorWithGlobalErrorHandling: (response: IApiResponse) => {
                const error = response?.error;
                if (error?.code === 2005) {
                    addToast(t('registration.error.used-token'), { appearance: 'error' });
                    return true;
                }
                return false;
            },
        },
    );

    const [mutatePreLogin, { isLoading: isLoadingPreLogin }] = useMutationWithError(
        async (data: SkSppNzpBeApiCustomeraccessLoginPreLoginChallenge) => api.customers.preLogin(data),
        {
            onSuccess: (response) => {
                const token = response.data?.token;
                if (token) {
                    const ssoUrl = `${process.env.REACT_APP_SSO_AUTH_PRE_LOGIN_URL}?auth=${token}`;
                    window.location.href = ssoUrl;
                }
            },
        },
    );

    const { register, handleSubmit, errors, trigger } = useForm<IForm>({
        defaultValues: { email: email || '', 'new-password': '' },
    });

    const onSubmit = (formData: IForm) => {
        if (socialLogin) {
            mutatePreRegistrationChallenge({
                code: challengeCode || '',
                uuid: challengeUuid,
                jwt: jwt,
                approvals: [
                    { type: 'CONTRACT_CONDITIONS', approval: formData.termsAndConditions },
                    { type: 'PROTECTION_PERSONAL_DATA', approval: formData.privacyPolicy },
                ],
            });
            return;
        }
        if (challengeCode && challengeUuid) {
            mutatePreRegistrationChallenge({
                code: challengeCode,
                uuid: challengeUuid,
                password: formData['new-password'],
                approvals: [
                    { type: 'CONTRACT_CONDITIONS', approval: formData.termsAndConditions },
                    { type: 'PROTECTION_PERSONAL_DATA', approval: formData.privacyPolicy },
                ],
            });
            return;
        }
    };

    const showTermsAndConditions = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setTermsAndConditionsOpen(true);
    };

    const showPrivacyPolicy = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setPrivacyPolicyOpen(true);
    };

    useEffect(() => {
        if (isPrivacyPolicyOpen) setPrivacyPolicyShown(true);
    }, [isPrivacyPolicyOpen, setPrivacyPolicyShown]);

    useEffect(() => {
        if (isTermsAndConditionsOpen) setTermsAndConditionsShown(true);
    }, [isTermsAndConditionsOpen, setTermsAndConditionsShown]);

    return (
        <div className="container pre-registration">
            <HelpModal
                show={isTermsAndConditionsOpen}
                wide
                title="consents.terms-and-conditions.title"
                screen="REG"
                field="REG_PORTAL_USAGE_CONDITIONS"
                cancel={() => setTermsAndConditionsOpen(false)}
            />
            <HelpModal
                show={isPrivacyPolicyOpen}
                wide
                title="consents.privacy-policy.title"
                screen="REG"
                field="REG_RULES_OF_PRIVATE_DATA_PROCESSING"
                cancel={() => setPrivacyPolicyOpen(false)}
            />
            <Row className="h-100 m-0 bg-white">
                {(isLoadingPreRegistrationChallenge || isLoadingPreLogin) && <LoadingIndicator />}
                <div
                    className="col-lg-6 d-none d-lg-block bg-trees p-0"
                    style={{ background: "url('../bg-trees.jpg') transparent no-repeat right top" }}
                >
                    <div className="features-holder">
                        <img src="/moje-spp-white.svg" alt="Moje SPP" width="107" className="mb-3 mb-md-4" />
                        <h3 className="mb-2 pb-2 text-white">
                            <Trans i18nKey="registration.promo-headline">
                                Všetko vybavíte
                                <br />
                                jednoducho online!
                            </Trans>
                        </h3>
                        <ul className="list-unstyled">
                            {benefits.map((benefit) => (
                                <li key={benefit} className="d-flex align-items-center mb-2 position-relative">
                                    <span className="mr-2 check-white" /> {benefit}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6 p-4 p-lg-5">
                    <img src="/moje-spp-black.svg" alt="Moje SPP" width="107" className="mb-3 mb-md-4 d-inline d-lg-none" />
                    <h2>
                        {jwt ? (
                            <Trans i18nKey="common.completion-registration">Dokončenie registrácie</Trans>
                        ) : (
                            <Trans i18nKey="registration.welcome-last-step" components={{ firstName, lastName }} />
                        )}
                    </h2>
                    <p className="font-weight-500">{jwt ? t('registration.approvals-confirmation') : t('registration.create-password')}</p>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Input
                            trigger={trigger}
                            ref={register({ ...requiredTrimed, ...sppDomain })}
                            errors={errors}
                            type="text"
                            name="email"
                            label={<Trans i18nKey="common.email">Email</Trans>}
                            autoCapitalize="off"
                            autoCorrect="off"
                            readOnly={!!email}
                        />
                        {!socialLogin && (
                            <PasswordInput
                                id="registration-password"
                                ref={register({ ...passwordRules.isValidPassword, ...formRules.minLengthX(8) })}
                                errors={errors}
                                name="new-password" // new-password name will disable prefilling - any other name will enable autofill
                                label={<Trans i18nKey="common.password">Heslo</Trans>}
                                trigger={trigger}
                            />
                        )}
                        <Checkbox
                            className="mt-4 mb-3"
                            ref={register(formRules.required)}
                            errors={errors}
                            name="termsAndConditions"
                            label={
                                <Trans
                                    i18nKey="consents.terms-and-conditions.check"
                                    components={{
                                        Link: (
                                            <ClickableElement isText onClick={showTermsAndConditions} className="text-decoration-underline-inverse">
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            id={'checkboxTerms'}
                            onChange={() => {
                                if (!termsAndConditionsShown) setTermsAndConditionsOpen(true);
                            }}
                        />
                        <Checkbox
                            ref={register(formRules.required)}
                            errors={errors}
                            name="privacyPolicy"
                            label={
                                <Trans
                                    i18nKey="consents.privacy-policy.check"
                                    components={{
                                        Link: (
                                            <ClickableElement isText onClick={showPrivacyPolicy} className="text-decoration-underline-inverse">
                                                &nbsp
                                            </ClickableElement>
                                        ),
                                    }}
                                />
                            }
                            id={'checkboxPrivacy'}
                            onChange={() => {
                                if (!privacyPolicyShown) setPrivacyPolicyOpen(true);
                            }}
                        />
                        <button
                            type="submit"
                            className={`btn btn-primary btn-lg ${socialLogin ? 'mt-4' : 'mt-2'} `}
                            disabled={isLoadingPreRegistrationChallenge}
                        >
                            <Trans i18nKey="action.sign-up-and-enter">Registrovať a vstúpiť do Moje SPP</Trans>
                        </button>
                    </form>
                    {/*!socialLogin && <SocialButtons state={JSON.stringify({ challengeCode, challengeUuid, type: PRE_REGISTRATION, email })} />*/}
                </div>
            </Row>
        </div>
    );
};
export default EnterPreRegistrationDataScreen;
