import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import BaseRadio from '../../../../../components/common/base-radio';
import { CharacterFilter } from '../../../../../components/common/character-filtering';
import Checkbox from '../../../../../components/common/checkbox';
import DatePicker from '../../../../../components/common/datePicker/datePicker';
import Input from '../../../../../components/common/input';
import LoadingIndicator from '../../../../../components/common/loading-indicator';
import { useFormRules } from '../../../../../hooks/use-form-rules';
import { IAttachments, ISupplier } from '../../../../../models/customer-request-model';
import { ContractBindingEnum, ContractTerminationEnum } from '../../../../../models/enums';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { clearObjectProperties } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import Attachments from '../../../../delivery-points/detail/data/contact-data-modals/component/block-attachments';
import { CustomerRequestDataEnum, CustomerRequestTypeEnum } from '../../../config/enums';
import { eeSupllyRequest, zpSupllyRequest } from '../../../customer-request-utils';

const BlockSupplier: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const customerRequest = useSelector((state: IRootState) => state.customerRequest);
    const loggedInCustomer = useSelector((s: IRootState) => s.user.customer);
    const { currentBlock, content, attachments, customerRequestTemplate } = customerRequest;

    const [isRequestSaved, setIsRequestSaved] = useState(false);
    const [isRequestSaving, setIsRequestSaving] = useState(false);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.SUPPLIER;
    const objectData = content[objectName] as ISupplier;
    const [files, setFiles] = useState<IAttachments[]>(attachments ?? []);
    const initializeRequestCreationProcess = useSendRequest();
    const { t } = useTranslation();

    const { formRules, dateRules } = useFormRules();
    const { register, errors, handleSubmit, watch, trigger, setValue, getValues, clearErrors, setError } = useForm<ISupplier>({
        defaultValues: {
            ...objectData,
            contractTermination: objectData?.contractTermination ?? ContractTerminationEnum.YES,
            contractBinding: objectData?.contractBinding ?? ContractBindingEnum.YES,
        },
    });

    const contractTermination = watch('contractTermination');
    const contractBinding = watch('contractBinding');
    const dontHaveDocument = watch('dontHaveDocument');

    const onSubmit = (data: ISupplier) => {
        if (
            contractTermination === ContractTerminationEnum.NO &&
            files.filter((item) => item.info === 'supplier').length === 0 &&
            !content.saveRequested
        ) {
            setError('files', { type: 'required', message: 'Povinný údaj' });
            return;
        }

        const payload: CustomerRequestPayloadType = {
            [objectName]: clearObjectProperties({
                ...data,
                contractTermination,
                contractBinding: contractTermination === ContractTerminationEnum.YES ? contractBinding : undefined,
            }),
            saved: content.saveRequested ? true : undefined,
        };
        dispatch(CustomerRequestActions.setData(payload));
        dispatch(
            CustomerRequestActions.setAttachments(
                contractTermination === ContractTerminationEnum.NO ? files : files.filter((item) => item.info !== 'supplier'),
            ),
        );
        !content.saveRequested &&
            nextTick(() => {
                dispatch(CustomerRequestActions.nextStep());
            });
    };

    const onRequestSave = () => {
        dispatch(CustomerRequestActions.setData({ saveRequested: true }));
    };

    useEffect(() => {
        if (content.saveRequested) {
            const data = getValues();
            onSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saveRequested]);

    useEffect(() => {
        if (content.saved) {
            setIsRequestSaving(true);
            const requestData =
                customerRequestTemplate?.code === CustomerRequestTypeEnum.ZOM_ZODE
                    ? eeSupllyRequest(customerRequest, true)
                    : zpSupllyRequest(customerRequest, true);
            initializeRequestCreationProcess(requestData, files, undefined, true)
                .then(() => {
                    dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                    setIsRequestSaved(true);
                })
                .catch((err) => err.message)
                .finally(() => setIsRequestSaving(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content.saved]);

    return (
        <>
            {isRequestSaving && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="my-5">
                    <p>
                        <Trans i18nKey="customer-request.steps.change-supplier.arrange-contract-termination-on-your-behalf">
                            Prajete si, aby sme ukončenie zmluvy s vaším aktuálnym dodávateľom vybavili za vás?
                        </Trans>
                    </p>
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={ContractTerminationEnum.YES}
                        id="contractTermination_yes"
                        name="contractTermination"
                        className="mt-0 py-2"
                        onClick={() => clearErrors('files')}
                        label={<Trans i18nKey="common.yes" />}
                    />
                    <BaseRadio
                        ref={register(formRules.required)}
                        value={ContractTerminationEnum.NO}
                        id="contractTermination_no"
                        name="contractTermination"
                        className="mt-0 py-2"
                        label={<Trans i18nKey="customer-request.steps.change-supplier.no-arrange-termination-myself" />}
                    />
                </div>
                {contractTermination === ContractTerminationEnum.NO && (
                    <>
                        <Attachments
                            files={files}
                            setFiles={setFiles}
                            label={t('customer-request.steps.change-supplier.document-of-contract-termination')}
                            errors={errors}
                            clearErrors={clearErrors}
                            className="mb-4"
                            info="supplier"
                        />
                        {loggedInCustomer && (
                            <Checkbox
                                ref={register()}
                                errors={errors}
                                id="dontHaveDocument"
                                name="dontHaveDocument"
                                label={t('customer-request.steps.change-supplier.dont-have-document-yet')}
                            />
                        )}

                        {loggedInCustomer && dontHaveDocument && (
                            <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-4 my-4">
                                <FontAwesomeIcon icon={faInfoCircle} size="lg" /> <br />
                                <p>
                                    <b>{t('customer-request.steps.change-supplier.must-provide-document')}</b>
                                </p>
                                <p>{t('customer-request.steps.change-supplier.can-continue-later')}</p>
                                <div style={{ width: '70%', margin: 'auto' }}>
                                    <BaseButton type="button" onClick={onRequestSave}>
                                        <Trans i18nKey="customer-request.steps.save-and-continue-later">Uložiť žiadosť a dokončiť neskôr</Trans>
                                    </BaseButton>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {contractTermination === ContractTerminationEnum.YES && (
                    <>
                        <div className="my-5">
                            <p>
                                <Trans i18nKey="customer-request.steps.change-supplier.binding-on-your-contract">
                                    Máte na zmluve s vaším aktuálnym dodávateľom viazanosť?
                                </Trans>
                            </p>
                            <BaseRadio
                                ref={register(formRules.required)}
                                value={ContractBindingEnum.YES}
                                id="contractBiding_yes"
                                name="contractBinding"
                                className="mt-0 py-2"
                                label={<Trans i18nKey="common.yes" />}
                            />
                            <BaseRadio
                                ref={register(formRules.required)}
                                value={ContractBindingEnum.NO}
                                id="contractBiding_no"
                                name="contractBinding"
                                className="mt-0 py-2"
                                label={<Trans i18nKey="customer-request.steps.change-supplier.contract-no-binding" />}
                            />
                        </div>
                        {contractBinding === ContractBindingEnum.YES && (
                            <>
                                <div className="my-5">
                                    <FormGroup className="d-flex flex-row mb-1">
                                        <DatePicker
                                            name={'bindingTo'}
                                            register={register({ ...formRules.required, ...dateRules().isValidDate, ...dateRules().notPastDate })}
                                            setValue={setValue}
                                            getValues={getValues}
                                            errors={errors}
                                            trigger={trigger}
                                            label={<Trans i18nKey="customer-request.steps.complained-advances.payment-bindingTo" />}
                                            minDate={new Date()}
                                        />
                                    </FormGroup>
                                    <div className="d-flex mx-1 p-2">
                                        <div className="mr-3 my-auto">
                                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                        </div>
                                        <Trans i18nKey="customer-request.steps.change-supplier.binding-period">
                                            Viazanosť nájdete na zmluve s vaším aktuálnym dodávateľom, resp. na dodatku zmluvy
                                        </Trans>
                                    </div>
                                </div>
                                <div className="my-5">
                                    <Input
                                        trigger={trigger}
                                        ref={register({ ...formRules.requiredInteger, ...formRules.min(0) })}
                                        characterFilter={CharacterFilter.POSITIVE_INTEGER}
                                        errors={errors}
                                        type="number"
                                        name="noticePeriod"
                                        label={<Trans i18nKey="customer-request.steps.electricity-connection.notice-period" />}
                                        className="mb-0"
                                        componentClassName="mb-1"
                                    />
                                    <div className="d-flex mx-1 p-2">
                                        <div className="mr-3 my-auto">
                                            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                                        </div>
                                        <Trans i18nKey="customer-request.steps.change-supplier.notice-period">
                                            Výpovednú lehotu nájdete na zmluve s vaším aktuálnym dodávateľom, resp. na dodatku zmluvy
                                        </Trans>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                <BaseButton type="submit" disabled={contractTermination === ContractTerminationEnum.NO && !!dontHaveDocument}>
                    <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                </BaseButton>
            </form>
            <Modal isOpen={isRequestSaved} centered>
                <ModalBody>
                    <ChangeRequestSent label={t('delivery-point.detail.modals.request-saved')} onClose={history.goBack} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default BlockSupplier;
