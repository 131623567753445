import {
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
    SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FilterDescription } from '../../../../components/common/filter-description';
import { formatDateToPreview } from '../../../../main';
import { joinNodes } from '../../../../utils/react-utils';
import { formatAddressShort } from '../../../../utils/utils';
import { isRequestStatusCancelled, isRequestStatusSent } from '../../customer-request-utils';

interface ICustomerRequestsFilterDescriptionProps {
    filter: Partial<SkSppNzpBeApiCustomerrequestCustomerRequestSearchQuery>;
    deliveryPoints: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[] | undefined;
    statusCodeList: SkSppNzpCommonsApiCodelistCodeListItem[];
}

export const CustomerRequestsFilterDescription: React.FC<ICustomerRequestsFilterDescriptionProps> = ({ filter, deliveryPoints, statusCodeList }) => {
    const { t } = useTranslation();

    const translateRequestType = useCallback(() => {
        if (filter.codes == null) {
            return [];
        }
        return filter.codes.map((code) => <Trans key={code} i18nKey={`enums.CustomerRequestType.${code}`} />);
    }, [filter.codes]);

    const elements: React.ReactNode[] = useMemo(() => {
        if (filter == null) {
            return [];
        }
        const result: React.ReactNode[] = [];

        if (!!filter?.createdAt) {
            const repr = `${formatDateToPreview(filter.createdAt.from?.split('T')[0]) || '  '} - ${formatDateToPreview(
                filter.createdAt.to?.split('T')[0],
            ) || '  '}`;
            result.push(repr);
        }
        if (filter.unitedDeliveryPointUuid != null && deliveryPoints != null) {
            const dp = deliveryPoints?.find((x) => x.id === filter.unitedDeliveryPointUuid);
            if (dp?.address != null) {
                const element = formatAddressShort(dp.address);
                result.push(element);
            }
        }

        if (!!filter?.product) {
            const element = filter.product === 'EE' ? <Trans i18nKey="enums.CommodityEnum.EE" /> : <Trans i18nKey="enums.CommodityEnum.ZP" />;
            result.push(element);
        }

        if (!!filter.statuses) {
            const statusNames = filter.statuses.map((status) => {
                let name = statusCodeList?.find((x) => x.code === status)?.name || '';
                if (isRequestStatusCancelled(status)) {
                    name = t('customer-request.filter.status-option-canceled');
                }
                if (isRequestStatusSent(status)) {
                    name = t('customer-request.filter.status-option-sent');
                }
                return name;
            });
            if (statusNames.length > 0) {
                const element = (
                    <>
                        <Trans i18nKey="customer-request.filter.request-status" />: {joinNodes(statusNames, ' / ')}
                    </>
                );
                result.push(element);
            }
        }

        if (!!filter.codes && filter.codes.length > 0) {
            const types = translateRequestType();
            if (types.length > 0) {
                const element = (
                    <>
                        <Trans i18nKey="customer-request.filter.request-type" />: {joinNodes(types, ' / ')}
                    </>
                );
                result.push(element);
            }
        }

        if (!!filter.externalId) {
            const element = (
                <>
                    <Trans i18nKey="customer-request.filter.request-number" />: {`"${filter.externalId}"`}
                </>
            );
            result.push(element);
        }

        return result;
    }, [deliveryPoints, filter, statusCodeList, t, translateRequestType]);

    return <FilterDescription elements={elements} />;
};
