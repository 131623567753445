import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useSso from '../../../../hooks/sso/use-sso';
import useConfig from '../../../../hooks/use-config';
import Routes from '../../../../routes/routes';

interface ISocialButtonsProps {
    state?: string;
}

const SocialButtons: React.FC<ISocialButtonsProps> = ({ state }) => {
    const { data: config } = useConfig();
    const { t } = useTranslation();
    const { ssoLogin } = useSso();
    const backUrl = Routes.HOME;

    return (
        <div className="mt-4">
            {(config?.facebookLogin || config?.googleLogin) && (
                <div className="text-secondary mb-1">{t('registration.or-continue-with-social-login')}</div>
            )}
            <div className="d-flex">
                {config?.facebookLogin && (
                    <button
                        className="my-2 px-3 mr-2 btn btn-outline-primary"
                        onClick={() => ssoLogin({ backUrl, method: 'customer.facebookMethod', state })}
                    >
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/facebook.svg`}
                            width="25"
                            className="mr-2"
                        />
                        <Trans i18nKey="action.sign-up-facebook">Registrovať cez Facebook</Trans>
                    </button>
                )}
                {config?.facebookLogin && config.googleLogin && <br />}
                {config?.googleLogin && (
                    <button
                        className="my-2 px-3 btn btn-outline-primary"
                        onClick={() => ssoLogin({ backUrl, method: 'customer.googleMethod', state })}
                    >
                        <img
                            src={`${process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : ''}/google.svg`}
                            width="25"
                            className="mr-2"
                        />
                        <Trans i18nKey="action.sign-up-google">Registrovať cez Google</Trans>
                    </button>
                )}
            </div>
        </div>
    );
};

export default SocialButtons;
