import { faCheck, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ToastContainerProps, ToastProps } from 'react-toast-notifications';
import { Col, Container, Row } from 'reactstrap';

const getToastProperties = (appearance: 'error' | 'info' | 'success' | 'warning') => {
    switch (appearance) {
        case 'error':
            return { icon: faTimes, color: 'bg-danger', textColor: 'text-white' };
        case 'warning':
            return { icon: faExclamation, color: 'bg-warning', textColor: 'text-dark' };
        default:
            return { icon: faCheck, color: 'bg-success-toast-spp-color', textColor: 'text-black' };
    }
};

export const CustomToast: React.FC<ToastProps> = ({ appearance, children, onDismiss }) => {
    const toastProperties = getToastProperties(appearance);
    return (
        <>
            <div
                className={`alert d-flex align-items-center ${toastProperties.textColor} ${toastProperties.color}`}
                onClick={() => onDismiss()}
                style={{ cursor: 'pointer', fontSize: '1.2em' }}
            >
                <FontAwesomeIcon icon={toastProperties.icon} size="sm" className="mr-4" />
                <span style={{ fontWeight: 500 }}>{children}</span>
            </div>
        </>
    );
};

export const CustomToastContainer: React.FC<ToastContainerProps> = (props) => (
    <div className="custom-toast-container" style={{ position: 'fixed', top: '7rem', width: '100%', pointerEvents: 'none', zIndex: 2000 }}>
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} xl={8} style={{ pointerEvents: 'auto' }}>
                    {props.children}
                </Col>
            </Row>
        </Container>
    </div>
);
