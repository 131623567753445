import i18n from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { getHelpHash, matchHelpHash } from '../../hooks/use-help-text';
import { IRootState } from '../../reducers';
import HelpFullWidthStrip from './help-full-width-strip';

const HelpBreakingNews: React.FC = () => {
    const screen = 'ALL';
    const availableHelps = useSelector((state: IRootState) => state.help.availableHelps);

    const isHelpAvailable = (field: string) => {
        const helpHash = getHelpHash(screen, field, i18n.language);
        return matchHelpHash(availableHelps, helpHash);
    };

    if (isHelpAvailable('ALL_BREAKING_NEWS')) return <HelpFullWidthStrip screen={screen} field={'ALL_BREAKING_NEWS'} warning />;
    if (isHelpAvailable('ALL_NOTIFICATION')) return <HelpFullWidthStrip screen={screen} field={'ALL_NOTIFICATION'} />;
    return <></>;
};

export default HelpBreakingNews;
