import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    date: string;
    isRequestFinished: boolean;
}

export const Stepper: React.FC<IProps> = ({ date, isRequestFinished }) => {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-md-12 stepper">
                <div className="row active">
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" className="mr-3" />
                    <p className="mb-0 position-relative w-75">
                        <b>{t('customer-request.transcript-new-customer-introduction-page.step-1')}</b>
                    </p>
                </div>

                <div className="row active">
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" className="mr-3" />
                    <p className="mb-0 position-relative w-75">
                        <b>{t('customer-request.transcript-new-customer-introduction-page.step-2')}</b>
                    </p>
                </div>

                <div className="row">
                    <FontAwesomeIcon icon={isRequestFinished ? faCheckCircle : faCircle} size="lg" className="mr-3" />
                    <p className="mb-0 position-relative w-75">
                        <b>{t('customer-request.transcript-new-customer-introduction-page.step-3')}</b>
                        <br />
                        <span style={{ position: 'absolute' }}>
                            {t('customer-request.transcript-new-customer-introduction-page.step-3-set-info')}
                        </span>
                    </p>
                </div>

                <div className="row">
                    <FontAwesomeIcon icon={isRequestFinished ? faCheckCircle : faCircle} size="lg" className="mr-3" />
                    <p className="mb-0 position-relative w-75">
                        <b>{t('customer-request.transcript-new-customer-introduction-page.step-4')}</b>
                        <br />
                        <span style={{ position: 'absolute' }}>
                            {t('customer-request.transcript-new-customer-introduction-page.step-4-send-due', {
                                date: date,
                            })}
                        </span>
                    </p>
                </div>

                <div className="row">
                    <FontAwesomeIcon icon={isRequestFinished ? faCheckCircle : faCircle} size="lg" className="mr-3" />
                    <p className="mb-0 position-relative w-75">
                        <b>{t('customer-request.transcript-new-customer-introduction-page.step-5')}</b>
                        <br />
                        <span style={{ position: 'absolute' }}>{t('customer-request.transcript-new-customer-introduction-page.step-5-info')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
