import * as H from 'history';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    title: string | JSX.Element;
    route?: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>);
    onClick?: () => void;
};

const Breadcrumb = (props: Props): ReactElement => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                    {props.route ? (
                        <Link to={props.route}>
                            <i className="icon-arrow-left-16"></i> {props.title}
                        </Link>
                    ) : (
                        <div onClick={props.onClick} role="button">
                            <i className="icon-arrow-left-16"></i> {props.title}
                        </div>
                    )}
                </li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;
