import { SkSppNzpBeApiIdentitymanagementAccountInfo } from '@spp/spp-meru-frontend-common';
import { Reducer } from 'react';
import { UserActionType, UserActionTypesEnum } from '../actions/user';

export interface IAccountInfo extends SkSppNzpBeApiIdentitymanagementAccountInfo {
    userSkippedPhoneVerification: boolean;
}

export interface IUserState extends IAccountInfo {
    isUserLogged: boolean;
}

const initialState: IUserState = {
    isUserLogged: false,
    userSkippedPhoneVerification: false,
};

const UserReducer: Reducer<IUserState, UserActionType> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypesEnum.SET_NAME:
            return state.customer == null
                ? state
                : {
                      ...state,
                      customer: {
                          ...state.customer,
                          firstName: action.payload.name,
                          lastName: action.payload.surname,
                      },
                  };
        case UserActionTypesEnum.SET_LOGIN_EMAIL:
            return {
                ...state,
                loginEmail: action.payload.loginEmail,
            };
        case UserActionTypesEnum.SET_TELEPHONE_NUMBER:
            return state.customer == null
                ? state
                : {
                      ...state,
                      customer: {
                          ...state.customer,
                          phone: action.payload.telephoneNumber,
                      },
                  };

        case UserActionTypesEnum.USER_SKIPPED_PHONE_VERIFICATION:
            return {
                ...state,
                userSkippedPhoneVerification: action.payload.userSkippedPhoneVerification,
            };
        case UserActionTypesEnum.CHANGE_APPROVAL: {
            const changedApproval = action.payload.approval;
            let newApprovalsList = [];
            if (state.customer?.approvals == null) {
                newApprovalsList = [changedApproval];
            } else if (state.customer?.approvals?.some((a) => a.type === changedApproval.type)) {
                newApprovalsList = state.customer.approvals.map((x) => (x.type === changedApproval.type ? changedApproval : x));
            } else {
                newApprovalsList = state.customer.approvals.concat([changedApproval]);
            }
            return {
                ...state,
                customer: {
                    ...state.customer,
                    approvals: newApprovalsList,
                },
            };
        }
        case UserActionTypesEnum.SET_USER:
            return { ...state, ...action.payload, isUserLogged: true };
        case UserActionTypesEnum.LOGOUT_CUSTOMER:
            const isStillLoggedAsEmployee = !!state.employee;
            return { ...state, customer: undefined, isUserLogged: isStillLoggedAsEmployee };
        case UserActionTypesEnum.LOGOUT_USER:
            return { ...state, customer: undefined, employee: undefined, isUserLogged: false };
        case UserActionTypesEnum.LOGOUT_EMPLOYEE:
            return { ...state, customer: undefined, employee: undefined, isUserLogged: false };
        case UserActionTypesEnum.SET_CUSTOMER:
            return { ...state, customer: action.payload };
        default:
            return state;
    }
};

export default UserReducer;
