import 'bootstrap/dist/css/bootstrap.css';
import fetchIntercept from 'fetch-intercept';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthActions } from './actions/auth-actions';
import { HelpActions } from './actions/help-actions';
import { AppContext, appContextParams } from './app-context';
import './app.scss';
import './assets/css/style-moje-spp-icons.scss';
import './assets/css/style.scss';
import { Exponea, LoadingIndicator, SsoRefresher } from './components/common';
import InfoDialog from './components/dialog/info-dialog';
import { HelpBreakingNews } from './components/help';
import { HelpContextualWithChat } from './components/help/help-contextual';
import { Navbar } from './components/navbar';
import { useAuthUtils } from './hooks/sso/use-auth-utils';
import useSilentRefresh, { ISilentRefreshResult } from './hooks/sso/use-silent-refresh';
import useSso from './hooks/sso/use-sso';
import { useApi } from './hooks/use-api';
import { getHelpHash } from './hooks/use-help-text';
import { useUrlQuery } from './hooks/use-url-query';
import { IRootState } from './reducers';
import { CustomerAndEmployeeAsCustomerRoutes } from './routes/customer-and-employee-as-customer-routes';
import Routes, { CustomerRequestRoutes } from './routes/routes';
import { clearCodeVerifierFromSessionStorage } from './utils/utils';
import CookiesAlert from './views/cookies-alert/cookies-alert';
import TermsModal from './views/sso/terms-modal';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pkg = require('../package.json');
let helpSystemInitialized = false;
let silentRefreshInitialized = false;
let countDownTimer: NodeJS.Timeout | undefined = undefined;

function App(): JSX.Element {
    const api = useApi();
    const query = useUrlQuery();
    const history = useHistory();
    const location = useLocation();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { ssoLogin, ssoLogout, triggerRefreshToken } = useSso();
    const { getTokens, getCustomer } = useAuthUtils();
    const { i18n } = useTranslation();
    const [silentRefresh] = useSilentRefresh();

    const URL_LOCALE_PARAM = 'locale';
    const user = useSelector((state: IRootState) => state.user);

    const userApprovals = user.customer?.approvals?.map((a) => a.approval && a.type);
    const areTermsApproved = userApprovals?.includes('CONTRACT_CONDITIONS') && userApprovals?.includes('PROTECTION_PERSONAL_DATA');
    const showTermsModal = user?.customer && !areTermsApproved;

    const tokenExpiresIn = useSelector((state: IRootState) => state.auth.tokenExpiresIn);
    const accessToken = useSelector((state: IRootState) => state.auth.accessToken);
    const [countDown, setCountDown] = useState<number>();
    const [userLastActivityTime, setUserLastActivityTime] = useState<number>();
    const [locale] = useState<string | null>(query.get(URL_LOCALE_PARAM));

    const notEnforceLoginFor = [
        Routes.REGISTRATION_CONFIRMATION,
        Routes.REGISTRATION,
        Routes.EMAIL_CONFIRMATION,
        Routes.PASSWORD_RECOVERY_CONFIRMATION,
        Routes.LOGIN_ISSUES,
        Routes.CONTACT,
        Routes.COOKIES,
        Routes.LOGOUT_CONFIRMATION,
        Routes.AUTO_LOGOUT_CONFIRMATION,
        Routes.REPORT_ISSUE,
        Routes.ACCOUNT_DEACTIVATION_CONFIRMATION,
        Routes.REQUEST_CONFIRMATION,
        CustomerRequestRoutes.SELECT_NEW,
        CustomerRequestRoutes.NEW_REQUEST,
        CustomerRequestRoutes.LOGIN,
    ];
    const isNotEnforceLoginRoute = !!useRouteMatch({ path: notEnforceLoginFor });
    const isSsoSuccessRouteWeb = useRouteMatch({ path: Routes.SSO_SUCCESS })?.isExact;
    const isSsoSuccessRouteMobile = useRouteMatch({ path: Routes.SSO_MOBILE_SUCCESS })?.isExact;
    const isSsoSuccessRoute = isSsoSuccessRouteWeb || isSsoSuccessRouteMobile;
    const isSilentRefreshResolved = useSelector((state: IRootState) => state.auth.isSilentRefreshResolved);

    const queryCache = useMemo(
        () =>
            new QueryCache({
                defaultConfig: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            }),
        [],
    );

    useEffect(() => {
        let isFirstResponseOfDeactivedAccount = true;
        const unregister = fetchIntercept.register({
            request: function(url, config) {
                if (config?.headers) {
                    config.headers['Accept-Language'] = i18n.language;
                }
                return [url, config];
            },
            response: function(response) {
                switch (response.status) {
                    case 400:
                        return response;
                    case 401:
                        if (response.headers.get('content-type')?.includes('application/json')) {
                            // if there is a detail code error provided then this is not real 401 but
                            // only error from changeEmail or changePassword routine.
                            response
                                .clone()
                                .json()
                                .then((data) => {
                                    if (data.code === 1005) {
                                        //deactivated account
                                        if (isFirstResponseOfDeactivedAccount) {
                                            ssoLogout(Routes.HOME);
                                        }
                                        isFirstResponseOfDeactivedAccount = false;
                                    }

                                    if (!data.code || data.code === 401) {
                                        console.error('Unexpected 401 object', data);
                                        ssoLogin();
                                    }
                                })
                                .catch((err) => console.error('Response parsing error', err));
                        } else {
                            ssoLogin();
                        }
                        return response;
                    case 403:
                        return response;
                    case 404:
                        return response;
                    case 500:
                        return response;
                    default:
                        return response;
                }
            },
        });
        return () => {
            unregister();
        };
    }, [ssoLogin, ssoLogout, i18n.language]);

    const idleTimerTimeout = Number(process.env.REACT_APP_IDLE_TIMER_TIMEOUT) || 365 * 24 * 60 * 60 * 1000; // default 1 year

    useIdleTimer({
        timeout: idleTimerTimeout,
        onIdle: () => {
            if (user.isUserLogged) {
                userLastActivityTime === undefined && setUserLastActivityTime(Math.floor(Date.now() / 1000)); //unix timestamp in seconds
                setCountDown(60);
            }
        },
    });

    // redirect from '/' to login page
    useEffect(() => {
        const unlisten = history.listen((location) => {
            if (isSilentRefreshResolved && !user.isUserLogged && location.pathname === '/') {
                ssoLogin();
            }
        });
        return () => unlisten();
    }, [history, ssoLogin, isSilentRefreshResolved, user.isUserLogged]);

    // logout after inactivity
    useEffect(() => {
        countDownTimer && clearTimeout(countDownTimer);
        if (countDown === undefined || userLastActivityTime === undefined) {
            return;
        }
        if (countDown > 0) {
            countDownTimer = setTimeout(() => {
                setCountDown(userLastActivityTime + 60 - Math.floor(Date.now() / 1000));
            }, 1000);
        } else {
            setCountDown(undefined);
            setUserLastActivityTime(undefined);
            ssoLogout(Routes.AUTO_LOGOUT_CONFIRMATION, true);
        }
    }, [dispatch, countDown, ssoLogout, userLastActivityTime]);

    useEffect(() => {
        if (!accessToken || !tokenExpiresIn)
            return () => {
                clearTimeout(trigger);
            };

        const safety = 10;
        const xSeconds = (tokenExpiresIn - safety) * 1000;
        const trigger = setTimeout(triggerRefreshToken, xSeconds);

        return () => {
            clearTimeout(trigger);
        };
    }, [tokenExpiresIn, accessToken, triggerRefreshToken, dispatch]);

    // applying language from URL
    useEffect(() => {
        if (!locale) return;
        if (locale !== i18n.language) {
            if (!i18n.languages.includes(locale)) {
                console.error("Locale from url param is not included in app's supported languages.");
                return;
            }
            localStorage.setItem('i18n.locale', locale);
            i18n.changeLanguage(locale);
        }
    }, [locale, i18n]);

    // initializing the help system
    useEffect(() => {
        if (helpSystemInitialized) return;
        helpSystemInitialized = true;
        dispatch(HelpActions.initializeHelp(pkg.version));
        api.componentHelps.getAllComponents({ content: false }).then((data) => {
            const helpList = data?.data ? data.data : undefined;
            const availableHelps: string[] = [];
            if (helpList && helpList.length) {
                for (const help of helpList) {
                    if (help.status === 'ACTIVE') {
                        help.i18ns?.forEach(({ locale }) => {
                            locale && availableHelps.push(getHelpHash(help.screen, help.field, locale));
                        });
                    }
                }
                dispatch(HelpActions.setAvailableHelps(availableHelps));
            }
        });
    }, [dispatch, api]);

    // initializing the silent refresh
    useEffect(() => {
        if (silentRefreshInitialized) return;
        silentRefreshInitialized = true;
        if (isSsoSuccessRoute || location.pathname.indexOf('/prihlasenie') >= 0) {
            dispatch(AuthActions.setIsSilentRefreshResolved(true));
            return;
        }
        const clientId = '' + process.env.REACT_APP_SSO_CLIENT_ID;
        const scope = '' + process.env.REACT_APP_SSO_SCOPE;
        trySilentRefresh();

        ////////////////////////////////////////////////////////////

        function trySilentRefresh() {
            silentRefresh(clientId, scope)
                .then(processSilentRefresh)
                .catch(processSilentRefreshError);
        }

        function processSilentRefresh(result: ISilentRefreshResult) {
            if (result.error) {
                processSilentRefreshError();
            } else {
                const redirectUri = '' + process.env.REACT_APP_SSO_RESOLVE_URL;
                getTokens(clientId, scope, result.code, redirectUri).then(getCustomer);
            }
        }

        function processSilentRefreshError() {
            clearCodeVerifierFromSessionStorage();

            if (isNotEnforceLoginRoute) {
                dispatch(AuthActions.setIsSilentRefreshResolved(true));
            } else {
                // redirect to login page
                ssoLogin();
            }
        }
    }, [location, isSsoSuccessRoute, ssoLogin, getTokens, getCustomer, dispatch, silentRefresh, isNotEnforceLoginRoute]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname, location.search]);

    if (!isSilentRefreshResolved) {
        return <LoadingIndicator fullscreen />;
    }

    return (
        <>
            <ReactQueryCacheProvider queryCache={queryCache}>
                <AppContext.Provider value={appContextParams}>
                    <Navbar />
                    <HelpBreakingNews />
                    <CustomerAndEmployeeAsCustomerRoutes />
                    <HelpContextualWithChat />
                    <SsoRefresher />
                    <Exponea />
                    {showTermsModal && <TermsModal customer={user?.customer} />}
                    {countDown && (
                        <InfoDialog
                            visible
                            header={t('idleTimer.header')}
                            body={t('idleTimer.body', { count: countDown })}
                            onClick={() => {
                                setCountDown(undefined);
                                setUserLastActivityTime(undefined);
                            }}
                        />
                    )}
                    {!!process.env.REACT_APP_COOKIES_ALERT_URL && <CookiesAlert />}
                </AppContext.Provider>
            </ReactQueryCacheProvider>
        </>
    );
}

export default App;
