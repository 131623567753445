import { SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary } from '@spp/spp-meru-frontend-common/dist/api';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Badge, Button, Collapse, Container } from 'reactstrap';
import HelpLinkWithModal from '../../components/help/help-link-with-modal';
import { useWindowSize } from '../../hooks/use-window-size';
import { formatCurrency } from '../../main';
import InvoicesTransactions from './invoices-transactions';
import TransactionModal from './transactions-modal';

interface IInvoicesSummaryProps {
    showInfo: boolean;
    invoicesSummary: SkSppNzpBeApiCustomerprofileInvoicesummaryInvoicePaymentSummary | null | undefined;
}

const InvoicesSummary: React.FC<IInvoicesSummaryProps> = ({ showInfo, invoicesSummary: invoicesSummaryData }) => {
    const [t] = useTranslation();
    const [collapse, setCollapse] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState<boolean>(false);
    const toggle = () => setCollapse(!collapse);
    const toggleTransactionModal = () => setShowTransactionModal(!showTransactionModal);

    const windowSize = useWindowSize();

    const isSmallScreen = useCallback(
        function(): boolean {
            return !!windowSize.width && windowSize.width < 992;
        },
        [windowSize.width],
    );

    const dataToServe = invoicesSummaryData?.transactionSummary?.filter((transaction) => transaction.finishedAt);

    return (
        <Container className="invoice-summary-container">
            <div className="invoice-summary-row row mb-3">
                <div className="row col-12  col-lg-9 text-center" style={{ display: isSmallScreen() ? 'contents' : 'flex' }}>
                    {invoicesSummaryData && invoicesSummaryData?.unpaidExpired?.count !== undefined && invoicesSummaryData?.unpaidExpired?.count > 0 && (
                        <div className="col-12 col-lg-4 text-center">
                            <span className="alert alert-danger text-decoration-none invoice-summary-item d-block py-2 py-lg-4" role="alert">
                                <i className="icon-file-text fa-lg mr-2 d-inline-block d-lg-none"></i>
                                <h3 className="d-none d-lg-block">{formatCurrency(invoicesSummaryData?.unpaidExpired?.amount || 0)}</h3>
                                {invoicesSummaryData?.unpaidExpired?.count &&
                                    t(`delivery-point.detail.navigation.overdue-invoices`, {
                                        count: invoicesSummaryData?.unpaidExpired?.count,
                                    })}
                                {invoicesSummaryData?.unpaidExpired?.amount && (
                                    <span className="d-inline-block d-lg-none">
                                        &nbsp;({formatCurrency(invoicesSummaryData?.unpaidExpired?.amount || 0)})
                                    </span>
                                )}
                            </span>
                        </div>
                    )}

                    {invoicesSummaryData &&
                        invoicesSummaryData?.unpaid?.count !== undefined &&
                        invoicesSummaryData?.unpaid?.count > 0 &&
                        invoicesSummaryData?.unpaid?.count !== invoicesSummaryData?.unpaidExpired?.count && (
                            <div className="col-12 col-lg-4 text-center">
                                <span className="alert alert-danger text-decoration-none invoice-summary-item d-block py-2 py-lg-4" role="alert">
                                    <i className="icon-file-text fa-lg mr-2 d-inline-block d-lg-none"></i>
                                    <h3 className="d-none d-lg-block">{formatCurrency(invoicesSummaryData?.unpaid?.amount || 0)}</h3>
                                    {invoicesSummaryData?.unpaid?.count &&
                                        t(`delivery-point.detail.navigation.unpaid-invoices`, { count: invoicesSummaryData?.unpaid?.count })}
                                    {invoicesSummaryData?.unpaid?.amount && (
                                        <span className="d-inline-block d-lg-none">
                                            &nbsp;({formatCurrency(invoicesSummaryData?.unpaid?.amount || 0)})
                                        </span>
                                    )}
                                </span>
                            </div>
                        )}

                    {invoicesSummaryData && invoicesSummaryData?.overpaid?.count !== undefined && invoicesSummaryData?.overpaid?.count > 0 && (
                        <div className="col-12 col-lg-4 text-center">
                            <span className="alert alert-success text-decoration-none invoice-summary-item d-block mb-0 py-2 py-lg-4" role="alert">
                                <i className="icon-file-text fa-lg mr-2 d-inline-block d-lg-none"></i>
                                <h3 className="d-none d-lg-block">{formatCurrency(invoicesSummaryData?.overpaid?.amount || 0)}</h3>
                                <Trans i18nKey="invoices.overpayment" />
                                <span className="d-inline-block d-lg-none">&nbsp;({formatCurrency(invoicesSummaryData?.overpaid?.amount || 0)})</span>
                            </span>
                            <HelpLinkWithModal className="mt-1 d-inline-block" title="invoices.overpaymentRecieve" field="FAK_OVERPAYMENT_INFO" />
                        </div>
                    )}
                    {showInfo && invoicesSummaryData?.unpaidExpired?.count === 0 && invoicesSummaryData?.unpaid?.count === 0 && (
                        <div className="col-12 col-lg-4 text-center">
                            <span
                                className="alert alert-success text-decoration-none font-sm-bold invoice-summary-item d-block py-2 py-lg-4"
                                role="alert"
                            >
                                <i className="icon-circle-ok-16 mr-2 d-inline-block fa-lg d-lg-none"></i>
                                <i className="icon-circle-ok-16 d-none d-lg-inline-block fa-lg"></i>
                                <h4 className="d-none d-lg-block alert-success">
                                    <Trans i18nKey="invoices.all-paid" />
                                </h4>
                                <span className="d-inline-block d-lg-none">
                                    <Trans i18nKey="invoices.all-paid" />
                                </span>
                            </span>
                        </div>
                    )}
                </div>
                {invoicesSummaryData?.unpaid?.count !== undefined && invoicesSummaryData?.unpaid.count > 0 && dataToServe && dataToServe.length > 0 && (
                    <>
                        <div className="col-m-12 col-lg-3 text-right invoice-summary-transactions">
                            <Button className="btn-block d-lg-none" color="primary" onClick={toggle} style={{ display: '', marginBottom: '1rem' }}>
                                <i className={`${!collapse ? 'icon-chevron-down-16' : 'icon-chevron-top-16'} d-lg-none`}></i>
                                {` ${t('invoices.transactions-realised')} `}
                                <Badge className="bg-primary color-text-primary" pill>
                                    {dataToServe.length}
                                </Badge>
                            </Button>
                            <button
                                className="btn btn-primary btn-block btn-md-inline my-3 my-lg-0 d-none d-lg-inline-block"
                                color="primary"
                                onClick={toggleTransactionModal}
                                style={{ display: '', marginBottom: '1rem' }}
                            >
                                {`${t('invoices.transactions-realised')} `}
                                <Badge className="bg-primary color-text-primary" pill>
                                    {dataToServe.length}
                                </Badge>
                            </button>
                            <div className={'d-table d-lg-none m-auto w-100 text-center'}>
                                <Collapse isOpen={collapse} toggler="#toggler">
                                    <InvoicesTransactions transactionSummary={dataToServe} />
                                </Collapse>
                            </div>
                        </div>

                        <TransactionModal
                            isOpen={showTransactionModal}
                            onCloseModal={() => setShowTransactionModal(false)}
                            transactionSummary={dataToServe}
                        />
                    </>
                )}
            </div>
        </Container>
    );
};

export default InvoicesSummary;
