import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
    extraClass?: string;
};

const NoInternetConnection: React.FC<Props> = () => {
    return (
        <div className="bg-dark text-white text-center">
            <Trans i18nKey="mobile.no-internet">Žiadne internetové pripojenie</Trans>
        </div>
    );
};

export default NoInternetConnection;
