import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BenefitItem from './create-benefit-item';

const Benefits: React.FC = () => {
    const [t] = useTranslation();

    const benefitsObj = t('registration.benefits', { returnObjects: true });
    const benefits = Object.values(benefitsObj);
    const benefitsList = benefits.map(BenefitItem);

    return (
        <div className="col d-none d-md-block">
            <h1 className="mb-4">
                <Trans i18nKey="registration.promo-headline">
                    Všetko vybavíte
                    <br />
                    jednoducho online!
                </Trans>
            </h1>
            <ul className="list-unstyled">{benefitsList}</ul>
        </div>
    );
};

export default Benefits;
