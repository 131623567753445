import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ChatHelpProtectedRouter } from '../../routes/chat-help-protected-route';
import { ChatHelpRoute } from '../../routes/chat-help-route';
import Routes, { CustomerRequestRoutes } from '../../routes/routes';
import DetailCustomerRequest from './screen/detail-customer-request/detail-customer-request';
import ListCustomerRequest from './screen/list-customer-request/list-customer-request';
import LoginOnStartRequest from './screen/login-on-start-request/login-on-start-request';
import MyListCustomerRequest from './screen/my-list-customer-request/my-list-customer-request';
import PrepareCustomerRequest from './screen/prepare-customer-request/prepare-customer-request';
import StepsManagerCustomerRequest from './screen/steps-customer-request/steps-manager-customer-request';
import SumbissionCustomerRequest from './screen/submission-customer-request/submission-customer-request';

const CustomerRequest: React.FC = () => {
    return (
        <>
            <Switch>
                <ChatHelpProtectedRouter exact path={CustomerRequestRoutes.MY_LIST} contextualHelp={'ZDS'} component={MyListCustomerRequest} />
                <ChatHelpRoute exact path={CustomerRequestRoutes.SELECT_NEW} contextualHelp={'NZD'} component={ListCustomerRequest} />
                <ChatHelpProtectedRouter exact path={`${CustomerRequestRoutes.MY_LIST}/:requestId`} component={DetailCustomerRequest} />
                <ChatHelpRoute exact path={CustomerRequestRoutes.PREPARE} component={PrepareCustomerRequest} />
                <ChatHelpRoute exact path={`${CustomerRequestRoutes.NEW_REQUEST}/:requestId`} component={StepsManagerCustomerRequest} />
                <ChatHelpRoute exact path={CustomerRequestRoutes.SUBMISSION} component={SumbissionCustomerRequest} />
                <ChatHelpRoute exact path={CustomerRequestRoutes.LOGIN} component={LoginOnStartRequest} />
                <Redirect to={Routes.CUSTOMER_REQUESTS} />
            </Switch>
        </>
    );
};

export default CustomerRequest;
