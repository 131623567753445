import { Reducer } from 'react';
import { AuthActionType, AuthActionTypesEnum } from '../actions/auth-actions';

export type AuthStateType = {
    isSilentRefreshResolved: boolean;
    accessToken: string;
    accessTokenExpiresIn: number | undefined;
    refreshToken: string;
    contextToken: string;
    idToken: string;
    tokenExpiresIn: number | undefined;
    clientId: string;
    secondaryAccessToken: string;
    secondaryRefreshToken: string;
    secondaryContextToken: string;
    secondaryIdToken: string;
    secondaryExpiresIn: number | undefined;
};

const initialState = {
    isSilentRefreshResolved: false,
    tokenExpiresIn: undefined,
    accessToken: '',
    accessTokenExpiresIn: undefined,
    refreshToken: '',
    contextToken: '',
    idToken: '',
    clientId: '',
    secondaryExpiresIn: undefined,
    secondaryAccessToken: '',
    secondaryRefreshToken: '',
    secondaryContextToken: '',
    secondaryIdToken: '',
};

const AuthReducer: Reducer<AuthStateType, AuthActionType> = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypesEnum.SET_IS_SILENT_REFRESH_RESOLVED:
            if (action.isTrue === undefined) return { ...state };

            return {
                ...state,
                isSilentRefreshResolved: action.isTrue,
            };

        case AuthActionTypesEnum.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_CONTEXT_TOKEN:
            return {
                ...state,
                contextToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_ID_TOKEN:
            return {
                ...state,
                idToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_TOKEN_EXPIRES_IN:
            if (!action.expiresIn) return { ...state };
            return {
                ...state,
                tokenExpiresIn: action.expiresIn,
            };
        case AuthActionTypesEnum.SET_SECONDARY_ACCESS_TOKEN:
            return {
                ...state,
                secondaryAccessToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_SECONDARY_REFRESH_TOKEN:
            return {
                ...state,
                secondaryRefreshToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_SECONDARY_CONTEXT_TOKEN:
            return {
                ...state,
                secondaryContextToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_SECONDARY_ID_TOKEN:
            return {
                ...state,
                secondaryIdToken: action.payload ?? '',
            };
        case AuthActionTypesEnum.SET_AUTH_TOKENS:
            return {
                ...state,
                accessToken: action.tokens?.accessToken ?? '',
                refreshToken: action.tokens?.refreshToken ?? '',
                idToken: action.tokens?.idToken ?? '',
                tokenExpiresIn: action.tokens?.expiresIn ?? undefined,
                clientId: action.tokens?.clientId ?? '',
                accessTokenExpiresIn: action.accessTokenExpiresIn ?? undefined,
            };
        case AuthActionTypesEnum.SET_SECONDARY_AUTH_TOKENS:
            return {
                ...state,
                secondaryAccessToken: action.tokens?.accessToken ?? '',
                secondaryRefreshToken: action.tokens?.refreshToken ?? '',
                secondaryIdToken: action.tokens?.idToken ?? '',
                secondaryExpiresIn: action.tokens?.expiresIn ?? undefined,
            };
        default:
            return state;
    }
};

export default AuthReducer;
