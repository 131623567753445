import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';

interface IConsumptionHeaderProps {
    setIsSelfReadModalOpen: (value: boolean) => void;
    getDeliveryPointSwitch: (additionalButton?: { label: React.ReactNode }) => React.ReactNode;
    notes?: boolean;
}

export const ConsumptionHeader: React.FC<IConsumptionHeaderProps> = ({ getDeliveryPointSwitch, setIsSelfReadModalOpen, notes }) => {
    const { t } = useTranslation();

    const headerContent = (
        <div className="container">
            <Row>
                <div className="col-12 d-md-none mt-4 mb-3">
                    <div className="d-flex flex-wrap w-100 justify-content-between">
                        <h2 className="my-2 mr-2" style={{ flex: '1 1 auto' }}>
                            {notes ? t('delivery-point.detail.navigation.notes') : t('delivery-point.detail.navigation.consumption')}
                        </h2>
                        <button
                            type="button"
                            className="btn btn-primary text-nowrap"
                            onClick={() => setIsSelfReadModalOpen(true)}
                            style={{ flex: '1 1 0' }}
                        >
                            <small className="mr-2">
                                <FontAwesomeIcon icon={faPlus} />
                            </small>
                            <Trans i18nKey="delivery-point.detail.consumption.new-read" />
                        </button>
                    </div>
                </div>

                <div className="col-12 d-none d-md-block">{getDeliveryPointSwitch()}</div>
            </Row>
        </div>
    );

    const headerContent2 = (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">{getDeliveryPointSwitch()}</div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="bg-white mb-0 d-md-none">{headerContent}</div>
            <div className="mb-3 d-none d-md-block">{headerContent}</div>

            <div className="bg-two-colors d-md-none mb-4">{headerContent2}</div>
        </>
    );
};
