import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { ClickableElement } from '../../../components/common';
import { faAlignJustify, faThLarge } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

type Props = {
    tableMode: boolean;
    setTableMode: (tableMode: boolean) => void;
};

const ListDeliveryPointsSwitchView = ({ tableMode, setTableMode }: Props): ReactElement => {
    return (
        <div className="col-auto text-left text-sm-right">
            <ClickableElement
                onClick={() => {
                    setTableMode(false);
                }}
            >
                <span className={classNames('switch-button', { active: !tableMode })}>
                    <FontAwesomeIcon className="fa-lg" icon={faThLarge} />
                </span>
            </ClickableElement>
            <ClickableElement
                style={{ marginLeft: '12px' }}
                onClick={() => {
                    setTableMode(true);
                }}
            >
                <span className={classNames('switch-button', { active: tableMode })}>
                    <FontAwesomeIcon className="fa-lg" icon={faAlignJustify} />
                </span>
            </ClickableElement>
        </div>
    );
};

export default ListDeliveryPointsSwitchView;
