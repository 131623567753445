import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import BaseTextarea from '../../../../../../../components/common/base-textarea';
import { useFormRules } from '../../../../../../../hooks/use-form-rules';
import { IRootState } from '../../../../../../../main';
import { DefaultFormType } from '../../../../../../../models/model';

type Props = DefaultFormType & {
    problem: string;
};

const NOTE_MAX_COUNT = 510;

const DetailInvoiceIncorrect: React.FC<Props> = ({ register, errors, problem, watch }) => {
    const { requiredTrimed, formRules } = useFormRules();
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    const [messageCounter, setMessageCounter] = useState<number>(0);

    const message: string | undefined = watch('reason');

    useEffect(() => {
        setMessageCounter(message?.length ?? 0);
    }, [message]);

    return (
        <>
            <div className="text-center  my-2 my-lg-5">
                <h3>{<Trans i18nKey="customer-request.steps.reason-for-claim-detail.title-1">Popíšte dôvod reklamácie bližšie</Trans>}</h3>
            </div>
            <div className="text-center my-4">
                <p>
                    {<Trans i18nKey="customer-request.steps.reason-for-claim.title" />}:{' '}
                    {<Trans i18nKey={`enums.ComplainedInvoiceProblemDescriptionEnum.${problem}`} />}
                </p>
            </div>

            <BaseTextarea
                ref={register({ ...requiredTrimed, ...formRules.maxLengthX(NOTE_MAX_COUNT) })}
                errors={errors}
                name="reason"
                rows={5}
                maxLength={NOTE_MAX_COUNT}
                label={<Trans i18nKey="customer-request.steps.reason-for-claim-detail.detailed-description" />}
                touched={touched}
                counter={`${messageCounter}/${NOTE_MAX_COUNT}`}
            />
        </>
    );
};

export default DetailInvoiceIncorrect;
