import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import BulkNotificationSettingModal from './bulk-notification-setting/bulk-notification-setting-modal';

interface INotificationsSettingsSubHeaderProps {
    canBulkSet: boolean;
}

const NotificationsSettingsSubHeader: React.FC<INotificationsSettingsSubHeaderProps> = ({ canBulkSet }) => {
    const [isBulkSettingModalOpen, setIsBulkSettingModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className="d-flex justify-content-between align-content-center my-4">
                <h2 className="d-inline mb-0">
                    <Trans i18nKey="settings.navigation.notifications" />
                </h2>

                {canBulkSet && (
                    <>
                        <Button
                            type="button"
                            color="primary"
                            className="d-none d-lg-block mr-1 ml-auto"
                            onClick={() => setIsBulkSettingModalOpen(true)}
                        >
                            <Trans i18nKey="settings.notifications.bulk-notification-setting.bulk-notification-setting" />
                        </Button>

                        <UncontrolledDropdown className="d-inline d-block d-lg-none">
                            <DropdownToggle type="button" id="eInvoiceDropdownMenuButton" className="btn btn-light btn-round">
                                <i className="icon-context-points" />
                            </DropdownToggle>
                            <DropdownMenu right aria-labelledby="eInvoiceDropdownMenuButton">
                                <DropdownItem className="py-3" onClick={() => setIsBulkSettingModalOpen(true)}>
                                    <Trans i18nKey="settings.notifications.bulk-notification-setting.bulk-notification-setting" />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                )}
            </div>

            <BulkNotificationSettingModal isOpen={isBulkSettingModalOpen} onCloseModal={() => setIsBulkSettingModalOpen(false)} />
        </>
    );
};

export default NotificationsSettingsSubHeader;
