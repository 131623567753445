import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Benefits from '../../components/common/benefits';
import Routes from '../../routes/routes';
import ForgotPasswordSubmitted from './components/forgot-password-submitted';
import MainAccordion from './components/main-accordion';
import { LoginIssuesSteps, LoginIssuesStepsType } from './login-issues';

type PropsType = {
    step: LoginIssuesStepsType;
    activeCard: number | null;
    toggleActive: (event: React.MouseEvent, index: number | null) => void;
};

const LoginIssuesView: React.FC<PropsType> = ({ step, activeCard, toggleActive }) => {
    const history = useHistory();
    const [t] = useTranslation();
    const renderStep = (issueStep: LoginIssuesStepsType) => {
        switch (issueStep) {
            case LoginIssuesSteps.FORGOT_PASSWORD_SUBMITTED_STEP:
                return <ForgotPasswordSubmitted />;
            default:
                return <MainAccordion activeCard={activeCard} toggleActive={toggleActive} />;
        }
    };

    return (
        <>
            <div className="container my-5">
                <Row>
                    <Benefits />
                    <Col>
                        {renderStep(step)}
                        <Row className="mt-3">
                            <Col>
                                <h3 className="mt-3 mb-3">{t('common.do-not-have-acount')}</h3>
                                <button
                                    onClick={() => {
                                        history.push(Routes.REGISTRATION);
                                    }}
                                    className="btn btn-registration btn-inverted btn-lg btn-block px-3 px-sm-4 text-left d-flex align-items-center position-relative"
                                >
                                    <i className="icon icon-user"></i>
                                    {t('common.sign-up')}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default LoginIssuesView;
