import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ClickableElement } from '../../../../../../components/common/clickable-element';
import { CommodityEnum } from '../../../../../../models/enums';
import { IRootState } from '../../../../../../reducers';
import Routes from '../../../../../../routes/routes';
import { QueryKeysEnum, useReloadQueryCache } from '../../../../../../utils/react-query-utils';
import {
    addressReplaceSpaceinZipCodeAndCity,
    createIndexedGroups,
    formatAddress,
    formatAddressShort,
    formatBusinessPartnerName,
    formatSkPhoneNumber,
} from '../../../../../../utils/utils';
import BlockEmailPhoneAddress from './block-email-phone-address';

type Props = {
    onClose: () => void;
    selectedDeliveryPoint?: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    deliveryPoints?: SkSppNzpBeApiCustomerprofileDeliveryPoint[];
    allServicesSame: boolean;
    newInfo: {
        email?: string;
        phoneNumber?: string;
        address?: string;
        firstName?: string;
        lastName?: string;
        titleFront?: string;
        titleBehind?: string;
        name?: string;
        companyRegistrationNumber?: string;
        taxIdNumber?: string;
        vatRegistrationNumber?: string;
    };
    isHome: boolean;
};

const ChangeBusinessPartnerRequestSent: React.FC<Props> = ({ onClose, selectedDeliveryPoint, deliveryPoints, allServicesSame, newInfo, isHome }) => {
    const reloadQueryCache = useReloadQueryCache();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const history = useHistory();
    const businessPartnerData = selectedDeliveryPoint?.contract?.contractAccount?.businessPartner;
    const contractAccount = selectedDeliveryPoint?.contract?.contractAccount;

    const groupingUDP = useMemo(
        () => createIndexedGroups<SkSppNzpBeApiCustomerprofileDeliveryPointSummary>(deliveryPoints || [], (dp) => dp.unitedDeliveryPointId),
        [deliveryPoints],
    );

    const groupingDP = useCallback(
        (deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[]) =>
            createIndexedGroups<SkSppNzpBeApiCustomerprofileDeliveryPointSummary>(
                deliveryPoints || [],
                (dp) =>
                    `${dp.contract?.contractAccountSummary?.email}-${formatSkPhoneNumber(
                        dp.contract?.contractAccountSummary?.phone || '',
                    )}-${formatAddress(dp.address)}`,
            ),
        [],
    );

    const businessPartnerName = `${newInfo?.titleFront ?? ''} ${formatBusinessPartnerName({
        name: newInfo.name,
        firstName: newInfo.firstName,
        lastName: newInfo.lastName,
    })} ${newInfo?.titleBehind ?? ''}`;

    const displayUdpWithCommodity = (udp: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[]) => {
        const ee = udp.filter((dp) => dp.type === CommodityEnum.ELECTRICITY && dp.status === 'ACTIVE');
        const zp = udp.filter((dp) => dp.type === CommodityEnum.GAS && dp.status === 'ACTIVE');
        return `${formatAddressShort(udp[0].address)} - ${zp.length > 1 ? `${zp.length} x ` : ''}${zp.length ? t('enums.CommodityEnum.ZP') : ''}${
            ee.length > 0 && zp.length > 0 ? ', ' : ''
        } ${ee.length > 1 ? `${ee.length} x ` : ''}${ee.length ? t('enums.CommodityEnum.EE') : ''}`;
    };

    return (
        <div className="py-4">
            <i className="icon-circle-ok-16 fa-lg text-black" style={{ fontSize: 38 }} />
            <h3 className="mt-4">
                <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.request-sent">Židadosť o zmenu odoslaná</Trans>
            </h3>
            {loggedInCustomer && (
                <p className="text-black">
                    <Trans i18nKey="delivery-point.detail.contact-data.change-contact-data-modal.check-state">
                        Stav vybavenia môžete sledovať v časti
                    </Trans>{' '}
                    <ClickableElement isText onClick={() => history.push(Routes.CUSTOMER_REQUESTS)} className="text-decoration-underline-inverse">
                        {t('delivery-point.detail.contact-data.change-contact-data-modal.requests')}
                    </ClickableElement>
                    .
                </p>
            )}
            <hr className="my-4" />
            <div className="mb-2 font-size16 font-weight-500 text-black">
                {t('delivery-point.detail.contact-data.change-contact-data-modal.check-your-data')}:
            </div>
            <div className="font-size16 font-weight-500 text-black">{t('delivery-point.detail.contact-data.change-personal-data-modal.title')}</div>
            <div className="mb-1">
                {businessPartnerName.trim()} ({t('delivery-point.customer-number')}: {businessPartnerData?.externalId})
            </div>
            {newInfo.companyRegistrationNumber && (
                <div>
                    {t('delivery-point.detail.contact-data.company-registration-number')}:{' '}
                    <span className="font-weight-500 text-black">{newInfo.companyRegistrationNumber}</span>
                </div>
            )}
            {newInfo.taxIdNumber && (
                <div>
                    {t('delivery-point.detail.contact-data.tax-id-number')}: <span className="font-weight-500 text-black">{newInfo.taxIdNumber}</span>
                </div>
            )}
            {newInfo.vatRegistrationNumber && (
                <div>
                    {t('delivery-point.detail.contact-data.vat-registration-number')}:{' '}
                    <span className="font-weight-500 text-black">{newInfo.vatRegistrationNumber}</span>
                </div>
            )}
            <BlockEmailPhoneAddress
                email={newInfo.email}
                phone={newInfo.phoneNumber}
                address={newInfo.address}
                addressFieldName={
                    isHome
                        ? t('delivery-point.detail.contact-data.change-personal-data-modal.address')
                        : t('delivery-point.detail.contact-data.change-personal-data-modal.company-address')
                }
            />
            <div className="font-size16 font-weight-500 text-black mt-4">
                {t('delivery-point.detail.contact-data.change-contact-data-modal.title')}
            </div>
            {deliveryPoints?.length === 0 && (
                <BlockEmailPhoneAddress
                    email={allServicesSame ? newInfo.email : contractAccount?.email || businessPartnerData?.email}
                    phone={allServicesSame ? newInfo.phoneNumber : formatSkPhoneNumber(contractAccount?.phone || businessPartnerData?.phone || '')}
                    address={
                        allServicesSame
                            ? newInfo.address
                            : formatAddress(
                                  addressReplaceSpaceinZipCodeAndCity(contractAccount?.postAddress || businessPartnerData?.primaryAddress),
                                  true,
                              )
                    }
                />
            )}
            {Object.entries(groupingUDP).map(([id, udp]) => (
                <>
                    {allServicesSame ? (
                        <>
                            <div className="font-size16 font-weight-500 text-black mt-2 mb-1">{displayUdpWithCommodity(udp)}</div>
                            <BlockEmailPhoneAddress email={newInfo?.email} phone={newInfo?.phoneNumber} address={newInfo?.address} />
                        </>
                    ) : (
                        <>
                            {Object.entries(groupingDP(udp)).map(([id, item]) => (
                                <>
                                    <div className="font-size16 font-weight-500 text-black mt-2 mb-1">{displayUdpWithCommodity(udp)}</div>
                                    <BlockEmailPhoneAddress
                                        email={item[0].contract?.contractAccountSummary?.email}
                                        phone={formatSkPhoneNumber(item[0].contract?.contractAccountSummary?.phone || '')}
                                        address={formatAddress(addressReplaceSpaceinZipCodeAndCity(item[0].address), true)}
                                    />
                                </>
                            ))}
                        </>
                    )}
                </>
            ))}
            <hr className="mt-4" />
            <Button
                color="primary"
                className="mt-3"
                size="lg"
                block
                onClick={() => {
                    reloadQueryCache(QueryKeysEnum.DELIVERY_POINT);
                    onClose();
                }}
            >
                <Trans i18nKey="delivery-point.detail.modals.ok">OK</Trans>
            </Button>
        </div>
    );
};

export default ChangeBusinessPartnerRequestSent;
