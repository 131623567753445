import React from 'react';
import circle from '../../assets/images/check-circle.svg';

const BenefitItem = (text: string, index: number): JSX.Element => (
    <li className="d-flex align-items-center mb-1" key={index}>
        <img src={circle} alt="" className="mr-2" />
        {text}
    </li>
);

export default BenefitItem;
