import { SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { DatePicker } from '../../../../../../components/common';
import { IRootState } from '../../../../../../reducers';
import { SelectBusinessPartnerDeliveryPointsFormField } from '../../../../../settings/e-invoices/select-business-partner-delivery-points';
import { useFormRules } from './../../../../../../hooks/use-form-rules';
import { BusinessPartnerProps } from './business-partner-fields';
import BusinessPartnerForm from './business-partner-form';

type Props = BusinessPartnerProps;
export const BUSINESS_PARTNER_NEW = 'BUSINESS_PARTNER_NEW';

const BusinessPartnerLoggedIn: React.FC<Props> = ({
    register,
    errors,
    onSwitchChange,
    activeType,
    objectsName,
    getValues,
    setValue,
    trigger,
    watch,
    opNumberRequired,
    hideBirthDate,
}) => {
    const { partnersDeliveryPoints, additionalData, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);
    const { formRules, dateRules } = useFormRules();

    const bpValue = watch('businessPartnerId');

    const businessPartners = useMemo(() => {
        if (partnersDeliveryPoints && additionalData?.bpuuid) {
            return Object.fromEntries(Object.entries(partnersDeliveryPoints).filter(([index, _item]) => index === additionalData.bpuuid));
        }
        return partnersDeliveryPoints;
    }, [partnersDeliveryPoints, additionalData]);

    const showBirthDate = useMemo(() => {
        if (customerRequestTemplate?.type === 'PDF_GENERATED' && bpValue && bpValue !== BUSINESS_PARTNER_NEW) {
            if (businessPartners && bpValue) {
                const bp = Object.entries(businessPartners).find(([bpId]) => bpId === bpValue);
                return bp && bp[1][0]?.businessPartner?.lastName;
            }
            return true;
        }
        return false;
    }, [businessPartners, bpValue, customerRequestTemplate]);

    const birthDateThreshold = new Date();
    birthDateThreshold.setFullYear(new Date().getFullYear() - 18);
    const { lastOpened, current } = useSelector((state: IRootState) => state.customerRequest.step);
    const touched = lastOpened > current ? true : false;
    return (
        <>
            <SelectBusinessPartnerDeliveryPointsFormField
                groupedDeliveryPoints={(businessPartners as Record<string, UnitedDeliveryPointSummary[]>) ?? []}
                register={register}
                errors={errors}
                name="businessPartnerId"
            >
                {/** 
                <BaseRadio
                    key={'bp-new'}
                    ref={register(formRules.required)}
                    id={`businessPartnerDeliveryPointsRadionewBp`}
                    name="businessPartnerId"
                    value={BUSINESS_PARTNER_NEW}
                    label={<Trans i18nKey="common.another" />}
                    className="mb-3"
                />
                 */}
            </SelectBusinessPartnerDeliveryPointsFormField>

            {showBirthDate && (
                <FormGroup className=" d-flex flex-row">
                    <DatePicker
                        register={register({
                            ...formRules.required,
                            ...dateRules().dateMin18Age,
                        })}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                        name={`birthDate`}
                        maxDate={birthDateThreshold}
                        label={<Trans i18nKey="customer-request.steps.businessPartner.birthday">Dátum narodenia</Trans>}
                        touched={touched}
                    />
                </FormGroup>
            )}

            {bpValue && bpValue === BUSINESS_PARTNER_NEW && (
                <BusinessPartnerForm
                    opNumberRequired={opNumberRequired}
                    register={register}
                    errors={errors}
                    onSwitchChange={onSwitchChange}
                    activeType={activeType}
                    objectsName={objectsName}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    hideBirthDate={hideBirthDate}
                />
            )}
        </>
    );
};
export default BusinessPartnerLoggedIn;
