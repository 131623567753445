import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nextTick } from 'process';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { IRootState } from '../../../../../reducers';
import Email from './components/email';

const BlockNewCustomerEmail: React.FC = () => {
    const dispatch = useDispatch();

    const { content, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);

    const { register, handleSubmit, errors, trigger } = useForm<{ email: string }>({ defaultValues: { email: content.newCustomerEmail } });

    const onSubmit = (data: { email: string }) => {
        dispatch(CustomerRequestActions.setData({ newCustomerEmail: data.email }));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="my-5">
                <Email register={register} errors={errors} trigger={trigger} />
            </div>

            <div style={{ backgroundColor: '#e0e0e1' }} className="rounded p-4">
                <div className="row  m-1">
                    <div className="pt-1">
                        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                    </div>
                    <div className="col">
                        <b className="bigger">
                            <Trans i18nKey="customer-request.steps.new-customer-transcript.application-completion-by-new-customer.title">
                                Ako prebieha dokončenie žiadostinovým odberateľom?
                            </Trans>
                        </b>
                    </div>
                </div>
                <ul className="mt-4 px-4">
                    <li>
                        <Trans
                            i18nKey="customer-request.steps.new-customer-transcript.application-completion-by-new-customer.step-1"
                            defaults="Nový odberateľ dostanena vami zadaný e-mail notifikáciu, že musí žiadosť dokončiť a podať <bold>najneskôr do {{date}}</bold>. V prípade, že tak neurobí, budeme vás informovať."
                            values={{
                                //default value: 2 days - in case BE doesn't provide any number
                                days: customerRequestTemplate?.completionValidDays || 2,
                            }}
                            components={{ bold: <strong /> }}
                        />
                    </li>
                    <br />
                    <li>
                        <Trans
                            i18nKey="customer-request.steps.new-customer-transcript.application-completion-by-new-customer.step-2"
                            defaults="Nový odberateľ <bold>neuvidí ani nebude môcť zmeniť</bold> vami zadané údaje. Výnimkou je dohodnutý stav meradla, ktorý sa mu zobrazí (bez možnosti zmeny) za účelom odsúhlasenia."
                            components={{ bold: <strong /> }}
                        />
                    </li>
                    <br />
                    <li>
                        <Trans
                            i18nKey="customer-request.steps.new-customer-transcript.application-completion-by-new-customer.step-3"
                            defaults="<bold>O úspešnom podaní žiadosti</bold> novým odberateľom, či iných zmenách, vás budeme informovať."
                            components={{ bold: <strong /> }}
                        />
                    </li>
                </ul>
            </div>
            <BaseButton type="submit">
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </form>
    );
};

export default BlockNewCustomerEmail;
