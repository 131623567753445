import {
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoicePaymentInfo,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { LoadingIndicator } from '../../../components/common';
import { useApi } from '../../../hooks/use-api';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { IApiResponse } from '../../../models/model';
import { IRootState } from '../../../reducers';
import Routes from '../../../routes/routes';
import useEPayErrorHandling from '../../../utils/e-pay-error-handling';
import { getConsumptionCategory } from '../../../utils/utils';
import { TABSEnums } from '../../delivery-points/detail/delivery-point-detail';
import EpayMethodHeader from './components/e-pay-method-header';
import PaymentInvoicePick from './screen/e-pay-invoice-pick';
import PaymentMethod from './screen/e-pay-method';

export enum PaymentStepsEnum {
    PaymentMethod,
    PaymentPick,
}

type Props = {
    closeModal: () => void;
    isOpen: boolean;
    invoiceAmount: number;
    invoice: SkSppNzpBeApiCustomerprofileInvoice;
    onClosedModal?: () => void;
    commodity?: SkSppNzpBeApiCustomerprofileDeliveryPointSummary['type'];
    invoiceTypeCodeList?: SkSppNzpCommonsApiCodelistCodeListItem[] | null;
};

export type EPayHandlerType = (returnUrl: string) => string;

let ePayHandler: EPayHandlerType = (returnUrl) => returnUrl;

// Handler for mobile app
export const setEPayHandler = (handler: EPayHandlerType): void => {
    ePayHandler = handler;
};

const EpayModal: React.FC<Props> = ({ isOpen, closeModal, invoiceAmount, invoice, onClosedModal, commodity, invoiceTypeCodeList }) => {
    const reference = invoice.reference;
    const urlParams = useParams<{ unitedDeliveryPointId: string; deliveryPointId: string; tab: TABSEnums; invoiceId: string }>();
    const [actualStep, setActualStep] = useState<PaymentStepsEnum>(PaymentStepsEnum.PaymentMethod);
    const [amountToPay, setAmountToPay] = useState<number>(invoiceAmount);
    const [invoiceNumbers, setInvoiceNumbers] = useState<string[]>([reference ?? '']);
    const [variableSymbol, setVariableSymbol] = useState<string>(invoice.vs ?? '');
    const api = useApi();
    const history = useHistory();
    const { ePayErrorHandlingFn } = useEPayErrorHandling();
    const customer = useSelector((state: IRootState) => state.user.customer);

    useEffect(() => {
        setAmountToPay(invoiceAmount);
    }, [invoiceAmount]);

    const handleSetPaymentAmount = useCallback((amountToPay: number, invoiceNumbers: string[]) => {
        setAmountToPay(amountToPay);
        setInvoiceNumbers(invoiceNumbers);
        setActualStep(PaymentStepsEnum.PaymentMethod);
    }, []);

    const onTransactionError = (error?: IApiResponse) => {
        if (error?.error?.code === 500 && error?.error?.message && error.error.message.includes('code')) {
            const errorCodes = error?.error?.message.split(',').filter((item) => item.includes('code'));
            const errorCode = parseInt(errorCodes[0].replace(/[^0-9]/g, ''));
            if (errorCode) {
                ePayErrorHandlingFn(errorCode);
                return;
            }
        }
        ePayErrorHandlingFn(error?.error?.code);
    };

    const { data: paymentInfoData, isLoading: isLoadingPaymentInfo } = useQueryWithError<SkSppNzpBeApiCustomerprofileInvoicePaymentInfo | null>(
        ['invoicePaymentInfo', invoice.deliveryPoints, invoice.id],
        async () => {
            const contractIds = invoice.deliveryPoints?.reduce<string[]>(
                (acc, dp) => (dp.contract?.id != null ? acc.concat(dp.contract.id) : acc),
                [],
            );
            if (invoice.id && contractIds != null) {
                return api.invoices.getInvoicePaymentInfo(invoice.id, { contractIds: contractIds }, { secure: true }).then((res) => res.data);
            }
            return null;
        },
    );

    const getInitialStep = useCallback((): PaymentStepsEnum => {
        const unpaidInvoicesCount = paymentInfoData?.invoicePaymentSummary?.unpaid?.count;
        const unpaidInvoicesAmount = paymentInfoData?.invoicePaymentSummary?.unpaid?.amount;
        const unpaidInvoices = paymentInfoData?.invoicePaymentSummary?.unpaid?.invoices;
        const step =
            unpaidInvoicesCount && unpaidInvoicesAmount && unpaidInvoices && unpaidInvoicesCount > 1
                ? PaymentStepsEnum.PaymentPick
                : PaymentStepsEnum.PaymentMethod;
        if (step === PaymentStepsEnum.PaymentMethod) {
            handleSetPaymentAmount(invoiceAmount, [reference ?? '']);
        }
        return step;
    }, [paymentInfoData, reference, handleSetPaymentAmount, invoiceAmount]);

    useEffect(() => {
        if (paymentInfoData) {
            const isBulkPayment = invoiceNumbers.length > 1;
            const variableSymbol =
                isBulkPayment && paymentInfoData.businessPartnerSummary?.externalId
                    ? paymentInfoData.businessPartnerSummary?.externalId
                    : invoice.vs ?? '';
            setVariableSymbol(variableSymbol);
        }
    }, [paymentInfoData, invoice.vs, invoiceNumbers]);

    useEffect(() => {
        if (paymentInfoData) {
            const initialStep = getInitialStep();
            setActualStep(initialStep);
        }
    }, [paymentInfoData, getInitialStep]);

    // TODO: add valid description
    const [mutatePayment, { isLoading }] = useMutation(
        async (providerCode: string) => {
            if (paymentInfoData && customer?.id) {
                let returnUrlPath = '';
                if (urlParams.deliveryPointId) {
                    returnUrlPath = `${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}/${TABSEnums.invoices}/${urlParams.deliveryPointId}${Routes.EPAY_STATUS}`;
                } else {
                    returnUrlPath = `${Routes.INVOICES}${Routes.EPAY_STATUS}`;
                }
                const returnUrl = `${window.location.origin}${returnUrlPath}`;
                const isBulkPayment = invoiceNumbers.length > 1;
                const initResponse = await api.customers
                    .initEpayTransaction(
                        customer.id,
                        {
                            transactionInit: {
                                clientCode: paymentInfoData.queue === 'INDIVIDUAL' ? 'NZP_IND' : 'NZP',
                                providerCode,
                                amount: amountToPay,
                                variableSymbol,
                                constantSymbol: '0308',
                                returnUrl: ePayHandler(returnUrl),
                                description: '',
                            },
                            invoiceRefList: isBulkPayment
                                ? paymentInfoData.invoicePaymentSummary?.unpaid?.invoices ?? []
                                : [
                                      {
                                          id: invoice.id,
                                          externalId: reference,
                                      },
                                  ],
                            bulk: isBulkPayment,
                            deliveryPointId: urlParams.deliveryPointId,
                        },
                        { secure: true },
                    )
                    .then((res) => res.data);
                if (initResponse?.transactionUrl) {
                    window.location.href = initResponse?.transactionUrl;
                    history.push(`${returnUrlPath}/${initResponse.transactionId}`);
                } else {
                    onTransactionError();
                }
            }
        },
        {
            onError: (error: IApiResponse) => {
                onTransactionError(error);
            },
        },
    );

    const renderStep = () => {
        switch (actualStep) {
            case PaymentStepsEnum.PaymentPick:
                return (
                    <PaymentInvoicePick
                        paymentInfoData={paymentInfoData}
                        invoiceAmount={invoiceAmount}
                        handleSetPaymentAmount={handleSetPaymentAmount}
                        invoiceId={reference ?? ''}
                        invoiceTypeCodeList={invoiceTypeCodeList}
                    />
                );
            default:
                return (
                    <PaymentMethod
                        onPaymentMethodPick={(paymentMethod: string) => {
                            mutatePayment(paymentMethod);
                        }}
                        variableSymbol={variableSymbol}
                        amountToPay={amountToPay}
                        isLoading={isLoading}
                        paymentInfoData={paymentInfoData}
                        commodity={commodity}
                        invoiceNumbers={invoiceNumbers}
                        cc={getConsumptionCategory(paymentInfoData?.businessPartnerSummary)}
                    />
                );
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClosed={() => {
                setActualStep(getInitialStep());
                !!onClosedModal && onClosedModal();
            }}
            size={actualStep === PaymentStepsEnum.PaymentPick ? 'md' : 'lg'}
            centered
        >
            <div className="modal-header">
                <h3 className="modal-title" style={{ fontWeight: 400 }}>
                    {actualStep === PaymentStepsEnum.PaymentMethod ? (
                        <EpayMethodHeader invoiceNumbers={invoiceNumbers} />
                    ) : (
                        <Trans i18nKey="invoices.e-pay.header-method-more">Uhradiť viac faktúr naraz</Trans>
                    )}
                </h3>
                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
            </div>
            <ModalBody>{isLoadingPaymentInfo ? <LoadingIndicator size="medium" /> : renderStep()}</ModalBody>
        </Modal>
    );
};

export default EpayModal;
