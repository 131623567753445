export enum ChangeFormStepEnum {
    EDITING,
    ENTER_PHONE_NUMBER,
    SUCCESS,
}

export enum ChangePersonalDataStepEnum {
    EDITING,
    UPDATE_OTHER_SERVICES,
    SUCCESS,
}

export enum DetailPaymentFormFieldEnum {
    ADVANCE_PAYMENT = 'ADVANCE_PAYMENT',
    // GAS_TARIFF = 'GAS_TARIFF',
    EE_RATE = 'EE_RATE',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export enum DetailContactFormFieldEnum {
    CONTACT_CUSTOMER_INFOROMATIOM = 'CONTACT_CUSTOMER_INFORMATIOM',
    CONTACT_CUSTOMER_ACCOUNT_INFOROMATIOM = 'CONTACT_CUSTOMER_ACCOUNT_INFORMATIOM',
    DP_ADDRESS = 'DP_ADDRESS',
}

export interface IDeliveryPointDetailState {
    editedFormField: DetailPaymentFormFieldEnum | DetailContactFormFieldEnum | undefined;
    formState: ChangeFormStepEnum | undefined;
}
