import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { IRootState } from '../../../../reducers';
import Routes from '../../../../routes/routes';
import { QueryKeysEnum, useReloadQueryCache } from '../../../../utils/react-query-utils';

type Props = {
    onClose: () => void;
    children?: ReactNode;
    confirmButtonLabel?: string;
    label?: string;
    subTitle?: ReactNode;
};

const ChangeRequestSent: React.FC<Props> = ({ onClose, children, confirmButtonLabel, label, subTitle }) => {
    const reloadQueryCache = useReloadQueryCache();
    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);

    return (
        <Card>
            <div className="card-body text-center py-5">
                <i className="icon-circle-ok-16 fa-lg"></i>
                <h4>{label ? label : <Trans i18nKey="delivery-point.detail.modals.request-sent">Žiadosť odoslaná</Trans>}</h4>
                {subTitle}
                {loggedInCustomer && (
                    <p>
                        <Trans i18nKey="delivery-point.detail.modals.follow-requests-status-in-my-spp-reqests">
                            Stav žiadosti môžete sledovať v Moje&nbsp;SPP - v časti Žiadosti.
                        </Trans>
                    </p>
                )}
                {children}
                {loggedInCustomer && (
                    <Link to={Routes.CUSTOMER_REQUESTS} className="btn btn-light btn-block btn-lg mt-3">
                        <Trans i18nKey="delivery-point.detail.modals.navigate-to-requests">Prejsť na žiadosti</Trans>
                    </Link>
                )}
                <Button
                    color="primary"
                    block
                    size="lg"
                    className="mt-3"
                    onClick={() => {
                        reloadQueryCache(QueryKeysEnum.DELIVERY_POINT);
                        onClose();
                    }}
                >
                    {confirmButtonLabel ? confirmButtonLabel : <Trans i18nKey="delivery-point.detail.modals.ok">OK</Trans>}
                </Button>
            </div>
        </Card>
    );
};

export default ChangeRequestSent;
