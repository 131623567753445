import { PaginatedQueryConfig, PaginatedQueryResult, QueryFunction, QueryKey, usePaginatedQuery as useReactPaginatedQuery } from 'react-query';
import { IApiResponse } from '../models/model';
import useGlobalErrorHandling from '../utils/global-error-handling-fn';

interface IQueryConfigWithError<TResult = unknown, TError = unknown> extends PaginatedQueryConfig<TResult, TError> {
    onError?: (err: TError) => boolean;
}
export interface IUseQueryObjectConfig<TResult = unknown, TError = unknown> {
    queryKey: QueryKey;
    queryFn: QueryFunction<TResult>;
    config?: IQueryConfigWithError<TResult, TError>;
}

export function usePaginatedQueryWithError<TResult = unknown, TError extends IApiResponse = IApiResponse>(
    query: IUseQueryObjectConfig<TResult, TError>,
): PaginatedQueryResult<TResult, TError> {
    const { globalErrorHandlingFn } = useGlobalErrorHandling();

    return useReactPaginatedQuery<TResult, TError>(query.queryKey, query.queryFn, {
        ...query.config,
        onError: (err: TError) => {
            if (query.config?.onError == null || (query.config?.onError && query.config.onError(err) === false)) {
                globalErrorHandlingFn(err?.error?.code);
            }
        },
    });
}
