import {
    SkSppNzpBeApiCustomerprofileBusinessPartner,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint,
    SkSppNzpBeApiCustomerrequestCustomerRequest as CustomerRequestResponse,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Alert, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import LoadingIndicator from '../../../../components/common/loading-indicator';
import { ConfirmDialog } from '../../../../components/dialog';
import InfoDialog from '../../../../components/dialog/info-dialog';
import { useApi } from '../../../../hooks/use-api';
import { useKeyValueStore } from '../../../../hooks/use-key-value';
import useMutationWithError from '../../../../hooks/use-mutation-with-error';
import { useQueryWithError } from '../../../../hooks/use-query-with-error';
import { SessionStorageEnum, useSessionStorage } from '../../../../hooks/use-session-storage';
import { CustomerRequestStatusEnum, CustomerRequestStatusFilterEnum } from '../../../../models/enums';
import { IRootState } from '../../../../reducers';
import { CustomerRequestPayloadType, IStep } from '../../../../reducers/interfaces/customer-request-state';
import Routes, { CustomerRequestRoutes } from '../../../../routes/routes';
import { formatDateToPreview } from '../../../../utils/date-utils';
import { QueryKeysEnum } from '../../../../utils/react-query-utils';
import { formatAddress, formatAddressShort } from '../../../../utils/utils';
import { useOpenRequestFromDeliveryPointDetail } from '../../../delivery-points/detail/customer-requests/use-open-request-from-dp-detail';
import { CustomerRequestEnum, CustomerRequestTypeEnum } from '../../config/enums';
import { contentElementOrder } from '../../config/request-data';
import InfoDetailCustomerRequest from './components/info-detail-customer-request';

const DetailCustomerRequest: React.FC = () => {
    const params = useParams<{ requestId: string; unitedDeliveryPointId: string; deliveryPointId?: string }>();
    const location = useLocation();
    const history = useHistory();
    const dpMatch = useRouteMatch({ path: Routes.DELIVERY_POINTS });
    const api = useApi();
    const { t, i18n } = useTranslation();

    const [request, setRequest] = useState<CustomerRequestResponse>();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [storedPageIndex] = useKeyValueStore(Routes.CUSTOMER_REQUESTS + '@pageIndex', 0);
    const employee = useSelector((state: IRootState) => state.user.employee);
    const [showInactiveDP] = useSessionStorage<boolean>(SessionStorageEnum.showInactiveDeliveryPoints);
    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();
    const startRequestInRequestsTab = (
        requestName: CustomerRequestEnum,
        step: IStep,
        content: CustomerRequestPayloadType,
        businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartner,
    ) => {
        const attachments = request?.attachments?.map((attachment) => ({
            ...attachment,
            info: attachment.draftMetadata,
        }));
        initializeRequestCreationProcess(requestName, {}, content, undefined, step, request?.uuid, businessPartner, attachments);
    };

    const fetchCustomerRequest = useCallback(
        async (uuid) => {
            return api.customerRequests.getCustomerRequest(uuid, { secure: true });
        },
        [api.customerRequests],
    );

    const { isLoading, error, data } = useQueryWithError<CustomerRequestResponse | null>(
        ['customer-request-item', params.requestId, i18n.language],
        () => fetchCustomerRequest(params.requestId).then((response) => response.data),
        {
            onError: () => {
                history.replace(CustomerRequestRoutes.SELECT_NEW);
                return false;
            },
        },
    );
    const { data: unitedDeliveryPointData, isLoading: isLoadingUdpData } = useQueryWithError<SkSppNzpBeApiCustomerprofileUnitedDeliveryPoint | null>(
        [QueryKeysEnum.UNITED_DELIVERY_POINT, params.unitedDeliveryPointId, showInactiveDP],
        async () =>
            params.unitedDeliveryPointId
                ? api.unitedDeliveryPoints
                      .getUdpByUuid(params.unitedDeliveryPointId, { includeInactive: showInactiveDP }, { secure: true })
                      .then((x) => x.data)
                : null,
    );

    const [mutateCancelReqeust, { isLoading: isCancelLoading }] = useMutationWithError(
        async () =>
            request?.uuid == null ? null : api.customerRequests.cancelCustomerRequest(request?.uuid, { secure: true }).then((res) => res.data),
        // {
        //     onSuccess: (data: CustomerRequestResponse | null) => {
        //         data && setRequest(data);
        //     },
        // },
    );

    useEffect(() => {
        if (!isLoading) {
            if (!error && data) {
                // reorder elements in object
                const orderedContent: { [key: string]: any } = {};
                if (data.content.type && contentElementOrder[data.content.type]) {
                    for (const elementName of contentElementOrder[data.content.type]) {
                        if ((data.content as any)[elementName] !== undefined) {
                            if (typeof (data.content as any)[elementName] === 'object') {
                                orderedContent[elementName] = Object.assign({}, (data.content as any)[elementName]);
                            } else {
                                orderedContent[elementName] = (data.content as any)[elementName];
                            }
                            delete (data.content as any)[elementName];
                        }
                    }
                }
                setRequest({
                    ...data,
                    content: {
                        ...orderedContent,
                        ...data.content,
                    },
                });
            }
        }
    }, [isLoading, data, error]);

    const handleCanceRequest = async (action: string) => {
        if (action === 'confirm') {
            setShowConfirm(false);
            const request = await mutateCancelReqeust();
            if (request?.status?.code === CustomerRequestStatusEnum.CANCELLED_BY_USER) {
                // history.replace({ pathname: CustomerRequestRoutes.MY_LIST, state: location.state });
                closeRequestDetail();
            } else {
                setShowInfo(true);
            }
        } else {
            setShowConfirm(false);
        }
    };

    const [sendRequestAgain] = useMutationWithError(
        async () => request?.uuid && (await api.customerRequests.sendCustomerRequest(request?.uuid, { secure: true }).then((res) => res.data)),
    );

    const closeRequestDetail = () => {
        if (dpMatch) {
            history.replace({
                pathname: `${Routes.DELIVERY_POINTS}/${params.unitedDeliveryPointId}/requests/${params.deliveryPointId || ''}`,
                state: location.state,
            });
        } else {
            history.replace({ pathname: CustomerRequestRoutes.MY_LIST, state: location.state });
        }
    };

    let status;

    if (
        request?.status?.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
        request?.status?.code === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
        request?.status?.code === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
    ) {
        status = t('customer-request.filter.status-option-canceled');
    }
    if (
        request?.status?.code === CustomerRequestStatusFilterEnum.CREATED ||
        request?.status?.code === CustomerRequestStatusFilterEnum.REGISTERED ||
        request?.status?.code === CustomerRequestStatusFilterEnum.SAP_OPEN
    ) {
        status = t('customer-request.filter.status-option-sent');
    }
    if (request?.status?.code === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS) {
        status = t('customer-request.detail.in-progress');
    }
    if (request?.status?.code === CustomerRequestStatusEnum.COMPLETION_REJECTED) {
        status = t('customer-request.detail.rejected');
    }
    if (request?.status?.code === CustomerRequestStatusEnum.EXPIRED) {
        status = t('customer-request.detail.expired');
    }

    return (
        <>
            {dpMatch && (
                <>
                    {/* <!-- Breadcrumb with more items --> */}
                    <nav aria-label="breadcrumb" className="breadcrumb-more-items border-primary">
                        <div className="container ">
                            <ol className="breadcrumb d-inline-flex p-0 mb-0">
                                {params.unitedDeliveryPointId && (
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to={`${Routes.DELIVERY_POINTS}/${params.unitedDeliveryPointId}`}>
                                            {unitedDeliveryPointData && formatAddressShort(unitedDeliveryPointData.address)}
                                        </Link>
                                    </li>
                                )}
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link
                                        to={
                                            params.unitedDeliveryPointId
                                                ? `${Routes.DELIVERY_POINTS}/${params.unitedDeliveryPointId}/requests/${params.deliveryPointId ||
                                                      ''}?page=` +
                                                  (storedPageIndex + 1)
                                                : '/requests?page=' + (storedPageIndex + 1)
                                        }
                                    >
                                        {t('customer-request.title')}
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link to="#">Detail</Link>
                                </li>
                            </ol>
                        </div>
                    </nav>
                    {/* <!-- / Breadcrumb with more items --> */}
                </>
            )}

            {/* <!-- Breadcrumb --> */}
            <div className={classNames('container', { 'd-none': dpMatch })}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page">
                            <Link
                                to={
                                    params.unitedDeliveryPointId
                                        ? `${Routes.DELIVERY_POINTS}/${params.unitedDeliveryPointId}/requests/${params.deliveryPointId || ''}?page=` +
                                          (storedPageIndex + 1)
                                        : '/customer-requests?page=' + (storedPageIndex + 1)
                                }
                            >
                                <i className="icon-arrow-left-16"></i> {t('customer-request.title')}
                            </Link>
                        </li>
                    </ol>
                </nav>
            </div>
            {/* <!-- / Breadcrumb --> */}

            {(isCancelLoading || isLoadingUdpData) && <LoadingIndicator fullscreen />}

            {isLoading ? (
                <LoadingIndicator fullscreen />
            ) : (
                <>
                    <Container className="my-3">
                        {request && (
                            <>
                                <Row>
                                    <Col lg={6} className="mb-3">
                                        <h2 className="text-left">
                                            <Trans i18nKey={`enums.CustomerRequestType.${request.content.type}`} />
                                        </h2>
                                        <p>
                                            {request.externalId && (
                                                <>
                                                    <Trans i18nKey={`customer-request.detail.number`} />
                                                    {request.externalId}
                                                </>
                                            )}
                                            <br />
                                            <Trans i18nKey={`customer-request.detail.filed`} />
                                            {formatDateToPreview(request.issuedAt)}
                                            <br />
                                            {request.customerRequestContracts &&
                                                request.customerRequestContracts.length > 0 &&
                                                request.customerRequestContracts.map((contract, i) => {
                                                    if (contract.deliveryPoint) {
                                                        return (
                                                            <span key={`customerRequestContractsDp${contract.deliveryPoint.id}_${i}`}>
                                                                {i === 0 ? '' : <br />}
                                                                {formatAddress(contract.deliveryPoint.address)}
                                                                , <Trans i18nKey={`enums.CommodityEnum.${contract.deliveryPoint.type}`} />
                                                                {request.content.type === CustomerRequestTypeEnum.ZOM_P &&
                                                                request.completionView === 'COMPLETION' ? (
                                                                    <>
                                                                        {contract.deliveryPoint?.externalId &&
                                                                            ' (' +
                                                                                t('common.delivery-point') +
                                                                                ' ' +
                                                                                contract.deliveryPoint?.externalId +
                                                                                ')'}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {contract.contract?.externalId &&
                                                                            ' (' +
                                                                                t('customer-request.detail.contract-number', {
                                                                                    number: contract.contract?.externalId,
                                                                                }) +
                                                                                ')'}
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    } else if (contract.contractAccount) {
                                                        return (
                                                            <span key={`customerRequestContractsCA${contract.contractAccount.id}_${i}`}>
                                                                {i === 0 ? '' : <br />}
                                                                <Trans i18nKey={'delivery-point.detail.contract-data.contractual-account'} />:{' '}
                                                                {contract.contractAccount.externalId}
                                                            </span>
                                                        );
                                                    }
                                                })}
                                        </p>
                                    </Col>
                                    <Col lg={6}>
                                        <h5 className="forms-headline  mb-4">
                                            <Trans i18nKey="customer-request.filter.request-status" />
                                        </h5>
                                        {request?.status?.code && (
                                            <Alert color="primary" className="p-3 mb-1 text-center">
                                                <span id={`request_state_tooltip_help`}>
                                                    <i className="icon-circle-alert-16 mr-2"></i>
                                                    {!employee && request.status.name && (
                                                        <UncontrolledTooltip target={`request_state_tooltip_help`} placement="top">
                                                            {request.status.name}
                                                            {request?.status?.code === CustomerRequestStatusEnum.COMPLETION_IN_PROGRESS &&
                                                                t('customer-request.detail.in-progress')}
                                                            {request?.status?.code === CustomerRequestStatusEnum.COMPLETION_REJECTED &&
                                                                t('customer-request.detail.rejected')}
                                                        </UncontrolledTooltip>
                                                    )}
                                                    {status ? status : request.status.name}
                                                    <br />
                                                    {request?.status?.code === CustomerRequestStatusEnum.SAP_FINISHED &&
                                                        request?.synced === false && (
                                                            <Trans i18nKey="customer-request.detail.finished-not-synced">
                                                                Zmena sa prejaví v priebehu nasledujúceho dňa
                                                            </Trans>
                                                        )}
                                                </span>
                                                {employee && (
                                                    <p>
                                                        {t('customer-request.detail.technical-state')}
                                                        {request.status.name} ({request.status.code})
                                                    </p>
                                                )}
                                                {request.requestCancel && !request.finnished && (
                                                    <div className="font-weight-bold mt-2">
                                                        <Trans i18nKey="customer-request.detail.cancel-requested" />
                                                    </div>
                                                )}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    {/* <RequireChangesDetailCustomerRequest request={request} /> */}
                                    <InfoDetailCustomerRequest
                                        request={request}
                                        onRequestSend={sendRequestAgain}
                                        onRequestCancel={() => setShowConfirm(true)}
                                        onRequestContinue={(
                                            requestName: CustomerRequestEnum,
                                            step: IStep,
                                            content: CustomerRequestPayloadType,
                                            businessPartner: SkSppNzpBeApiCustomerprofileBusinessPartner,
                                        ) => startRequestInRequestsTab(requestName, step, content, businessPartner)}
                                    />
                                </Row>
                            </>
                        )}
                    </Container>
                    <ConfirmDialog visible={showConfirm} onClick={handleCanceRequest} />
                    <InfoDialog visible={showInfo} body={t('customer-request.detail.cancel-info-message')} onClick={() => closeRequestDetail()} />
                </>
            )}
        </>
    );
};

export default DetailCustomerRequest;
