import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ClickableElement } from './clickable-element';

type PaginatorProps = {
    pageIndex: number;
    pageSize?: number; // default 10
    dataLength?: number;
    onPageChanged?: (pageIndex: number, from: number, to: number) => void;
    className?: string;
};

const Paginator = (props: PaginatorProps) => {
    const DEFAULT_PAGE_SIZE = 10;

    const pageIndexes = function(from: number, to: number): number[] {
        const array = [];
        for (let i = from; i <= to; i++) {
            array.push(i);
        }
        return array;
    };

    const activeClass = function(pageIndex: number) {
        return pageIndex === props.pageIndex + 1 ? 'active' : '';
    };

    const onPageChanged = (pageIndex: number) => {
        const pageSize = props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE;
        props.onPageChanged && props.onPageChanged(pageIndex, pageIndex * pageSize, (pageIndex + 1) * pageSize - 1);
    };

    const page = props.pageIndex + 1;
    const pageSize = props.pageSize ? props.pageSize : DEFAULT_PAGE_SIZE;
    const pageCount = Math.trunc(((props.dataLength ? props.dataLength : 0) + pageSize - 1) / pageSize);
    let pages: number[];
    if (pageCount <= 10) {
        pages = pageIndexes(1, pageCount);
    } else {
        const headOrTail = 4;
        const p1 = page > headOrTail - 1 ? 1 : Math.min(headOrTail, pageCount);
        const p4 = Math.max(p1 + 1, page < pageCount - headOrTail + 2 ? pageCount : pageCount - headOrTail + 1);
        const p2 = Math.max(p1 + 1, page - 1);
        const p3 = Math.min(p4 - 1, page + 1);
        pages = pageIndexes(1, p1);
        pages.push(-1);
        if (p2 < p3) {
            pages.push(...pageIndexes(p2, p3));
            pages.push(-2);
        }
        pages.push(...pageIndexes(p4, pageCount));
    }

    return (
        <div className={`row ${props.className} ${pageCount > 1 ? '' : 'd-none'}`}>
            <div className="col">
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${props.pageIndex === 0 ? 'disabled' : ''}`}>
                            <ClickableElement className="page-link" onClick={() => onPageChanged(props.pageIndex - 1)}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </ClickableElement>
                        </li>
                        {pages.map((pageIndex) => (
                            <li key={pageIndex} className="page-item">
                                {pageIndex > 0 ? (
                                    <div className={`page-link ${activeClass(pageIndex)}`} onClick={() => onPageChanged(pageIndex - 1)}>
                                        {pageIndex}
                                    </div>
                                ) : (
                                    <span>...</span>
                                )}
                            </li>
                        ))}
                        <li className={`page-item ${props.pageIndex + 1 === pageCount ? 'disabled' : ''}`}>
                            <ClickableElement className="page-link" onClick={() => onPageChanged(props.pageIndex + 1)}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </ClickableElement>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Paginator;
