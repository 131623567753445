import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerrequestFieldGroupUpdate } from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { CustomerRequestEnum, formatCurrency, formatIBAN, PaymentMethodCodeEnum } from '../../../../main';
import { ConsumptionCategoryEnum, MANDATE_CID } from '../../../../models/enums';
import { CustomerRequestFieldUpdateType, RequestTemplateCode } from '../../../../models/model';
import { DetailPaymentFormFieldEnum } from '../../../../reducers/interfaces/delivery-point-detail-state';
import { useOpenRequestFromDeliveryPointDetail } from '../customer-requests/use-open-request-from-dp-detail';
import FormListItem from './form-list-item';
import { ChangeAdvancePaymentModal } from './payment-data-modals/change-advance-payment-modal';
import { ChangeBankAccountModal } from './payment-data-modals/change-bank-account-modal';
import { ChangePaymentMethodModal } from './payment-data-modals/change-payment-method-modal';
import { ForChangeContactSalesManagerModal } from './payment-data-modals/for-change-contact-sales-manager-modal';

interface IDeliveryPointDetailPaymentData {
    deliveryPoint: SkSppNzpBeApiCustomerprofileDeliveryPoint;
    isFieldChangeRequested: (code: RequestTemplateCode) => boolean;
    canEditOverride: boolean;
}

const DeliveryPointDetailPaymentData: React.FC<IDeliveryPointDetailPaymentData> = ({ deliveryPoint, isFieldChangeRequested, canEditOverride }) => {
    const [editedFormField, setEditedFormField] = useState<DetailPaymentFormFieldEnum | undefined>();
    const [contactSalesManagerModalOpen, setContactSalesManagerModalOpen] = useState(false);
    const [t] = useTranslation();
    const onStartEditFormField = (formField: DetailPaymentFormFieldEnum) => () => {
        setEditedFormField(formField);
    };

    const onModalClose = () => {
        setEditedFormField(undefined);
    };

    const hasDeliveryPointRequest = (type: CustomerRequestFieldUpdateType) => {
        const fieldUpdates = deliveryPoint.fieldUpdates?.filter((fu) => fu.type === type);
        const customerRequestUuids = fieldUpdates?.map((x) => x.customerRequest?.uuid).filter((x, i, a) => a.indexOf(x) === i) || [];
        return {
            result: fieldUpdates != null && fieldUpdates?.length > 0,
            customerRequestUuid: customerRequestUuids.length === 1 ? customerRequestUuids[0] : undefined,
        };
    };
    const hasContractAccountRequest = (
        type: CustomerRequestFieldUpdateType,
        attribute?: SkSppNzpBeApiCustomerrequestFieldGroupUpdate['attributeChanges'],
    ) => {
        const fieldUpdates = deliveryPoint.contract?.contractAccount?.fieldUpdates
            ?.filter((fu) => fu.type === type)
            .filter((fu) => (attribute ? attribute?.find((at) => at === fu.attributeChanges?.toString()) : fu));
        const customerRequestUuids = fieldUpdates?.map((x) => x.customerRequest?.uuid).filter((x, i, a) => a.indexOf(x) === i) || [];
        return {
            result: fieldUpdates != null && fieldUpdates?.length > 0,
            customerRequestUuid: customerRequestUuids.length === 1 ? customerRequestUuids[0] : undefined,
        };
    };

    const initializeRequestCreationProcess = useOpenRequestFromDeliveryPointDetail();

    const startRequestInRequestsTab = (requestName: CustomerRequestEnum) => {
        initializeRequestCreationProcess(requestName, { deliveryPointId: deliveryPoint.id, contractId: deliveryPoint.contract?.id });
        // const url = `${Routes.DELIVERY_POINTS}/${urlParams.unitedDeliveryPointId}/${TABSEnums.requests}/${deliveryPoint.id || ''}`;
        // history.push({ pathname: url, search: `request=${requestName}` });
    };

    return (
        <>
            <div className="card card-without-border py-2 px-1">
                <ul className="list-group list-group-flush">
                    <FormListItem
                        valueText={
                            <h3 className="mt-2">
                                <Trans i18nKey="delivery-point.detail.payment-data.title">Základné údaje</Trans>
                            </h3>
                        }
                    />
                    <li className="list-group-item pb-0">
                        <h4 className="mb-0">{deliveryPoint.type === 'EE' ? t('enums.CommodityEnum.EE') : t('enums.CommodityEnum.ZP')}</h4>
                        {deliveryPoint.contract?.product?.name && <span className="text-secondary">{deliveryPoint.contract?.product?.name}</span>}
                        <hr className="mb-0" />
                    </li>
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.payment-data.advance-payment">Preddavková platba</Trans>}
                        valueText={
                            <span className="attribute-info">
                                <span>{deliveryPoint.advancePayment ? `${formatCurrency(deliveryPoint.advancePayment)}` : '-'}</span>
                                <span>
                                    {deliveryPoint.contract?.advancePaymentPeriod?.name
                                        ? ` / ${deliveryPoint.contract?.advancePaymentPeriod?.name}`
                                        : ''}
                                </span>
                            </span>
                        }
                        onClick={onStartEditFormField(DetailPaymentFormFieldEnum.ADVANCE_PAYMENT)}
                        isEditable={deliveryPoint.contract?.advancePaymentPeriod && canEditOverride}
                        isChangeRequested={hasDeliveryPointRequest('ADVANCE_PAYMENT').result}
                        customerRequestUuid={hasDeliveryPointRequest('ADVANCE_PAYMENT').customerRequestUuid}
                    />
                    <FormListItem
                        label={
                            deliveryPoint.type === 'EE' ? (
                                <Trans i18nKey="delivery-point.detail.payment-data.rate">Sadzba</Trans>
                            ) : (
                                <Trans i18nKey="delivery-point.detail.payment-data.tariff">Tariff</Trans>
                            )
                        }
                        valueText={
                            <>
                                {deliveryPoint.tariffRate == null ? (
                                    '-'
                                ) : (
                                    <span className="attribute-info">
                                        <span className="mr-1">{deliveryPoint.tariffRate.code}</span>
                                        <FontAwesomeIcon icon={faInfoCircle} id="TariffInfoTooltip" />
                                        <UncontrolledTooltip placement="right" target="TariffInfoTooltip">
                                            {deliveryPoint.tariffRate?.description ? deliveryPoint.tariffRate?.description : '-'}
                                        </UncontrolledTooltip>
                                    </span>
                                )}
                            </>
                        }
                        onClick={() =>
                            deliveryPoint.deliveryCategory === ConsumptionCategoryEnum.WHOLESALE
                                ? setContactSalesManagerModalOpen(true)
                                : startRequestInRequestsTab(
                                      deliveryPoint.type === 'EE'
                                          ? CustomerRequestEnum.CHANGE_ELECTRICITY_RATE_SHORT
                                          : CustomerRequestEnum.CHANGE_GAS_TARIFF_SHORT,
                                  )
                        }
                        isEditable={canEditOverride}
                        isChangeRequested={hasDeliveryPointRequest('TARIFF').result}
                        customerRequestUuid={hasDeliveryPointRequest('TARIFF').customerRequestUuid}
                    />
                    <FormListItem
                        label={<Trans i18nKey="delivery-point.detail.payment-data.payment-method">Spôsob platby</Trans>}
                        valueText={
                            <span className="attribute-info">
                                <span className="mr-1">{deliveryPoint.contract?.contractAccount?.paymentType?.name || ''}</span>
                                {['I', 'F'].includes(deliveryPoint.contract?.contractAccount?.paymentType?.code || '') &&
                                    deliveryPoint.contract?.contractAccount?.mandateReference && (
                                        <>
                                            <FontAwesomeIcon icon={faInfoCircle} id="mandateRefference" />
                                            <UncontrolledTooltip placement="right" target="mandateRefference">
                                                <Trans
                                                    i18nKey="delivery-point.detail.payment-data.mandate-reference"
                                                    values={{ UMR: deliveryPoint.contract.contractAccount.mandateReference, CID: MANDATE_CID }}
                                                >
                                                    UMR: {deliveryPoint.contract.contractAccount.mandateReference}, CID: SK71ZZZ70000000056
                                                </Trans>
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                            </span>
                        }
                        onClick={onStartEditFormField(DetailPaymentFormFieldEnum.PAYMENT_METHOD)}
                        isEditable={canEditOverride}
                        isChangeRequested={hasContractAccountRequest('PAYMENT_METHOD', ['ZOM_ZSPAPPP_PAYMENT_METHOD']).result}
                        customerRequestUuid={hasContractAccountRequest('PAYMENT_METHOD', ['ZOM_ZSPAPPP_PAYMENT_METHOD']).customerRequestUuid}
                    />
                    {deliveryPoint.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.SIPO && (
                        <>
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.payment-data.sipo-number">Evidenčné číslo SIPO</Trans>}
                                valueText={<span className="attribute-info">{deliveryPoint.contract?.contractAccount?.sipo || '-'}</span>}
                            />
                        </>
                    )}{' '}
                    {(deliveryPoint.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.BANK_TRANSFER ||
                        deliveryPoint.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.INKASO ||
                        deliveryPoint.contract?.contractAccount?.paymentType?.code === PaymentMethodCodeEnum.INKASO_OTHER_BANK) && (
                        <>
                            <FormListItem
                                label={<Trans i18nKey="delivery-point.detail.payment-data.my-bank-account">Môj bankový účet</Trans>}
                                valueText={
                                    <span className="attribute-info">
                                        {deliveryPoint.contract.contractAccount.billingIban ? (
                                            <>
                                                {`${t('delivery-point.detail.payment-data.paid-by-other')} (${formatIBAN(
                                                    deliveryPoint.contract.contractAccount.billingIban,
                                                )})`}
                                            </>
                                        ) : (
                                            formatIBAN(deliveryPoint.contract.contractAccount.iban) || '-'
                                        )}
                                    </span>
                                }
                                onClick={onStartEditFormField(DetailPaymentFormFieldEnum.BANK_ACCOUNT)}
                                isEditable={canEditOverride}
                                isChangeRequested={hasContractAccountRequest('PAYMENT_METHOD', ['ZOM_ZSPAPPP_PAYMENT_METHOD']).result}
                                customerRequestUuid={hasContractAccountRequest('PAYMENT_METHOD', ['ZOM_ZSPAPPP_PAYMENT_METHOD']).customerRequestUuid}
                            />
                        </>
                    )}
                </ul>
            </div>

            <ChangeAdvancePaymentModal
                isOpen={editedFormField === DetailPaymentFormFieldEnum.ADVANCE_PAYMENT}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
            />

            <ChangePaymentMethodModal
                isOpen={editedFormField === DetailPaymentFormFieldEnum.PAYMENT_METHOD}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
            />

            <ChangeBankAccountModal
                isOpen={editedFormField === DetailPaymentFormFieldEnum.BANK_ACCOUNT}
                onClose={onModalClose}
                deliveryPoint={deliveryPoint}
                setEditedFormField={setEditedFormField}
            />

            <ForChangeContactSalesManagerModal
                isOpen={contactSalesManagerModalOpen}
                onClose={() => setContactSalesManagerModalOpen(false)}
                manager={deliveryPoint.contract?.contractAccount?.businessPartner?.accountManager}
                type={deliveryPoint.type}
            />
        </>
    );
};

export default DeliveryPointDetailPaymentData;
