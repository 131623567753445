import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAutofocusRef } from '../../hooks/use-autofocus-ref';
import { accessNestedProperty, combineRefs } from '../../utils/utils';
import { CharacterFilter, CharacterFiltering } from './character-filtering';

type InputType =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

interface IInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    type: InputType;
    name: string;
    label?: string | JSX.Element;
    placeholder?: string;
    className?: string;
    containerClassName?: string;
    componentClassName?: string;
    errors?: FieldErrors;
    trigger: ((name: any) => Promise<boolean>) | undefined;
    border?: boolean;
    autoFocus?: boolean;
    autoFocusDelay?: number;
    characterFilter?: CharacterFilter;
    optional?: boolean;
    touched?: boolean;
    disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
    (
        {
            type,
            name,
            label,
            placeholder,
            className,
            containerClassName,
            componentClassName,
            errors,
            trigger,
            autoFocus,
            autoFocusDelay,
            characterFilter,
            optional,
            touched = false,
            ...params
        },
        ref,
    ) => {
        const errorProperty = name == null ? undefined : accessNestedProperty({ ...errors }, name.split('.'));
        const isValid = errors == null || errorProperty == null;
        const [isTouched, setIsTouched] = useState(touched);
        const localRef = useAutofocusRef<HTMLInputElement>(autoFocus, autoFocusDelay);
        const { t, i18n } = useTranslation();

        const manualErrorCheck = useCallback(() => {
            trigger && trigger(name);
        }, [name, trigger]);

        useEffect(() => {
            !isValid && manualErrorCheck();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [i18n.language, manualErrorCheck]);

        return (
            <div className={componentClassName ? componentClassName : 'mb-3'}>
                <div className={`form-group mb-0 ${containerClassName || ''}`}>
                    <input
                        {...params}
                        ref={combineRefs<HTMLInputElement>([ref, localRef])}
                        type={type}
                        name={name}
                        className={classNames('form-control', className || '', {
                            'is-invalid': !isValid && !params.disabled,
                            'is-valid': isValid && isTouched && !params.disabled,
                        })}
                        placeholder={placeholder || ' '}
                        required // for style application only, is not validated as required
                        disabled={params.disabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            params.onChange && params.onChange(e);
                            manualErrorCheck();
                            if (isTouched === false) {
                                setIsTouched(true);
                            }
                        }}
                        onKeyPress={(e) => {
                            params.onKeyPress && params.onKeyPress(e);
                            if (!CharacterFiltering.matchKey(characterFilter, e.key)) {
                                e.preventDefault();
                                manualErrorCheck();
                            }
                        }}
                        onPaste={(e) => {
                            params.onPaste && params.onPaste(e);
                            const text = e.clipboardData.getData('Text').toLowerCase();
                            if (!CharacterFiltering.matchText(characterFilter, text)) {
                                e.preventDefault();
                            }
                        }}
                        onDrop={(e) => {
                            params.onDrop && params.onDrop(e);
                            const text = e.dataTransfer.getData('Text').toLowerCase();
                            if (!CharacterFiltering.matchText(characterFilter, text)) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <label className="control-label">
                        {label}
                        {` ${optional ? t('common.input-rules.optional') : ''}`}
                    </label>
                    {!isValid && errors != null && !params.disabled && (
                        <>
                            <div className="invalid-feedback small d-none d-sm-block">
                                <ErrorMessage errors={errors} name={name} />
                            </div>
                            <div className="invalid-feedback small d-sm-none">
                                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                            </div>
                        </>
                    )}
                </div>
                {!isValid && errors != null && !params.disabled && (
                    <div className="font-weight-bold text-danger ml-3 d-sm-none">
                        <ErrorMessage errors={errors} name={name} />
                    </div>
                )}
            </div>
        );
    },
);

export default Input;
