import { SkSppNzpCommonsApiEmployeeaccessEmployee } from '@spp/spp-meru-frontend-common/src/api';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { useInvoiceSummary } from '../../../hooks/use-invoice-summary';
import Routes from '../../../routes/routes';
import { can } from '../../permissions';

type NavLinksLoggedPropsType = {
    linkClicked: () => void;
    employee?: SkSppNzpCommonsApiEmployeeaccessEmployee;
};

const NavLinksLogged: React.FC<NavLinksLoggedPropsType> = ({ linkClicked, employee }) => {
    const pathname = window.location.pathname;
    const { unpaid, isLoading } = useInvoiceSummary();

    return (
        <ul className="navbar-nav mr-auto ml-0 ml-lg-4 pt-3 pt-lg-0">
            <li className="nav-item mx-0 mx-lg-2">
                <Link
                    className={`nav-link menu-item py-3 ${pathname.includes(Routes.DELIVERY_POINTS) ? 'link-active' : ''} ${
                        !employee || can('ENTITY_DELIVERY_POINTS_VIEW', employee) ? '' : 'disabled'
                    }`}
                    to={Routes.DELIVERY_POINTS}
                    onClick={linkClicked}
                >
                    <i className="icon-map-pin fa-lg mr-3 d-lg-none"></i>
                    <Trans i18nKey="navbar.navigation.delivery-points">Odberné miesta</Trans>
                </Link>
            </li>
            <li className="nav-item mx-0 mx-lg-2">
                <Link
                    className={`nav-link menu-item py-3 ${
                        pathname.includes(Routes.INVOICES_DETAIL) && !pathname.includes(Routes.DELIVERY_POINTS) ? 'link-active' : ''
                    } ${!employee || (can('ENTITY_INVOICES_VIEW', employee) && can('ENTITY_DELIVERY_POINTS_VIEW', employee)) ? '' : 'disabled'}`}
                    to={Routes.INVOICES}
                    onClick={linkClicked}
                >
                    <i className="icon-file-text fa-lg mr-3 d-lg-none"></i>
                    <Trans i18nKey="navbar.navigation.invoices">Faktúry</Trans>&nbsp;
                    {isLoading && '...'}
                    {unpaid && (unpaid.count || 0) > 0 && (
                        <>
                            <Badge color="danger" pill>
                                {unpaid.count}
                            </Badge>
                        </>
                    )}
                </Link>
            </li>
            <li className="nav-item mx-0 mx-lg-2">
                <Link
                    className={`nav-link menu-item py-3 ${pathname.includes(Routes.CUSTOMER_REQUESTS) ? 'link-active' : ''} ${
                        !employee || can('ENTITY_REQUESTS_VIEW', employee) ? '' : 'disabled'
                    }`}
                    to={Routes.CUSTOMER_REQUESTS}
                    onClick={linkClicked}
                >
                    <i className="icon-clipboard fa-lg mr-3 d-lg-none"></i>
                    <Trans i18nKey="navbar.navigation.customer-requests">Žiadosti</Trans>
                </Link>
            </li>
            <li className="nav-item mx-0 mx-lg-2 d-lg-none">
                <Link className="nav-link menu-item py-3" to={Routes.CUSTOMER_SETTINGS} onClick={linkClicked}>
                    <i className="icon-settings fa-lg mr-3 d-lg-none"></i>
                    <Trans i18nKey="navbar.settings">Nastavenia</Trans>
                </Link>
            </li>
        </ul>
    );
};

export default NavLinksLogged;
