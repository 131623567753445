import { SkSppNzpBeApiCustomerprofileDeliveryPoint } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { BaseRadio, CommodityEnum, formatAddressShort, isCustomerRequestPaid } from '../../../../../main';
import { IRootState } from '../../../../../reducers';

const BlockDeliveryPointInterruptionConsumption: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { additionalData, deliveryPointZp, customerRequestTemplate } = useSelector((state: IRootState) => state.customerRequest);

    const deliveryPoints = useMemo(() => additionalData?.udpd?.deliveryPoints?.filter((dp) => dp.type === CommodityEnum.GAS), [additionalData]);

    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState<SkSppNzpBeApiCustomerprofileDeliveryPoint>();

    useEffect(() => {
        deliveryPoints && dispatch(CustomerRequestActions.setDeliveryPoint(deliveryPoints[0])); //just for adress of delivery point in request header
        const selectedDpUuid = deliveryPointZp?.id ?? additionalData?.dpuuid;
        if (selectedDpUuid) {
            const selected = deliveryPoints?.find((db) => db.id === selectedDpUuid);
            if (selected) {
                setSelectedDeliveryPoint(selected);
                return;
            }
        }
        if (deliveryPoints?.length === 1) {
            setSelectedDeliveryPoint(deliveryPoints[0]);
        }
    }, [dispatch, deliveryPointZp?.id, additionalData, deliveryPoints]);

    useEffect(() => {
        if (
            customerRequestTemplate &&
            additionalData?.udpd?.businessPartner?.queue &&
            isCustomerRequestPaid(customerRequestTemplate, additionalData?.udpd?.businessPartner?.queue)
        ) {
            dispatch(CustomerRequestActions.setRequestIsPaid({ paid: true, comodity: CommodityEnum.GAS }));
        }
    }, [additionalData, customerRequestTemplate, dispatch]);

    return (
        <>
            {deliveryPoints?.map((dp) => (
                <Card key={`deliveryPoint_${dp.id}`} className="mb-3">
                    <CardBody className="py-2 px-4">
                        <BaseRadio
                            className="mt-1"
                            id={`radio-delivery-point-${dp.id}`}
                            onChange={() => setSelectedDeliveryPoint(dp)}
                            checked={selectedDeliveryPoint?.id === dp.id}
                            label={
                                <>
                                    <b>
                                        {formatAddressShort(dp.address)} - {t('enums.CommodityEnum.ZP')}
                                    </b>
                                    <p className="mb-0"> {t('delivery-point.contract-number', { number: dp.contract?.externalId })} </p>
                                </>
                            }
                        />
                    </CardBody>
                </Card>
            ))}
            <BaseButton
                type="button"
                onClick={() => {
                    dispatch(CustomerRequestActions.setDeliveryPointZp(selectedDeliveryPoint));
                    nextTick(() => {
                        dispatch(CustomerRequestActions.nextStep());
                    });
                }}
                disabled={deliveryPoints?.find((db) => db.id === selectedDeliveryPoint?.id) ? false : true}
            >
                <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
            </BaseButton>
        </>
    );
};

export default BlockDeliveryPointInterruptionConsumption;
