import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'reactstrap';

interface IUnifyEmailSuccessProps {
    email: string;
    onConfirm: () => void;
}

const UnifyEmailSuccess: React.FC<IUnifyEmailSuccessProps> = ({ email, onConfirm }) => {
    return (
        <>
            <div className="text-center">
                <i className="icon-circle-ok-16 fa-5x text-success d-block mb-3"></i>
                <h4 className="text-success mb-5">
                    <Trans i18nKey="settings.e-invoices.unify-email.email-has-been-set" />
                </h4>
                <p>
                    <Trans
                        i18nKey="settings.e-invoices.unify-email.all-einvoices-will-be-delivered-to-email"
                        components={{ Email: <span className="text-nowrap" /> }}
                        values={{ email: email }}
                    />
                </p>

                <Button type="submit" color="primary" size="lg" block className="mt-5" onClick={onConfirm}>
                    <Trans i18nKey="common.ok" />
                </Button>
            </div>
        </>
    );
};

export default UnifyEmailSuccess;
