import { ErrorMessage } from '@hookform/error-message';
import { SkSppNzpCommonsApiCodelistCodeListItem as CodeListItem } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardHeader } from 'reactstrap';
import BaseRadio from '../../../../../../components/common/base-radio';
import { useFormRules } from '../../../../../../hooks/use-form-rules';
import { DefaultFormType } from '../../../../../../models/model';
import { formatCurrency } from '../../../../../../utils/utils';

type Props = DefaultFormType & {
    codeList: (CodeListItem & { months: number; monthPostfix: string })[];
    totalPrice: number;
};

const PaymentPeriodFormFieldsCarbonFootprint: React.FC<Props> = ({ register, errors, codeList, totalPrice }) => {
    const { formRules } = useFormRules();
    const { t } = useTranslation();

    const acceptCurrentPriceListLabel = (name = '', months: number, monthPostfix: string) => (
        <>
            <b>{name}</b>
            <br /> {t('customer-request.steps.payment-period.price')} {formatCurrency(Number((totalPrice * months).toFixed(2)))} /{' '}
            {t('customer-request.steps.payment-period.' + monthPostfix)}
        </>
    );

    return (
        <>
            {codeList.map((item, index) => (
                <CardHeader key={index} className="border-bottom-0 rounded w-100 mb-1" style={{ backgroundColor: 'white', color: 'black' }}>
                    <BaseRadio
                        ref={register({ ...formRules.required })}
                        key={item.uuid}
                        id={item.uuid}
                        name="period.uuid"
                        value={item.uuid}
                        label={acceptCurrentPriceListLabel(item.name, item.months, item.monthPostfix)}
                        className="mt-2"
                    />
                </CardHeader>
            ))}
            {errors && (
                <div style={{ color: 'red' }} className="mt-2">
                    <ErrorMessage errors={errors} name={'period.uuid'} />
                </div>
            )}
        </>
    );
};

export default PaymentPeriodFormFieldsCarbonFootprint;
