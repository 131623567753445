import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';
interface IAgreePaidRequest {
    value?: string;
    title?: string;
}

const AgreePaidRequest: React.FC<IAgreePaidRequest> = ({ value, title }) => {
    const [t] = useTranslation();

    return (
        <ItemPreview title={title ? title : t('customer-request.steps.agree-paid-request')}>
            <ItemPreviewContent bold>{value ? value : t('common.yes')}</ItemPreviewContent>
        </ItemPreview>
    );
};

export default AgreePaidRequest;
