import {
    SkSppNzpBeApiCustomerprofileContractAccountSummary,
    SkSppNzpBeApiCustomerprofileDeliveryPointSummary,
    SkSppNzpBeApiCustomerprofileInvoice,
} from '@spp/spp-meru-frontend-common';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ListGroup, Modal, ModalBody } from 'reactstrap';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import Checkbox from '../../../../../components/common/checkbox';
import {
    CodeListTypeEnum,
    CommodityEnum,
    LoadingIndicator,
    PRICE_LINK_EE,
    PRICE_LINK_ZP,
    QueryKeysEnum,
    useCodeList,
    useFormRules,
} from '../../../../../main';
import { IRootState } from '../../../../../reducers';
import { formatCurrency, formatDate } from '../../../../../utils/utils';
import { useSendRequest } from '../../../../delivery-points/detail/customer-requests/use-send-request';
import ChangeRequestSent from '../../../../delivery-points/detail/data/change-request-sent';
import { InvoiceType } from '../../../../invoices/components/invoice-type';
import { CustomerRequestTypeEnum } from '../../../config/enums';
import ItemPreview, { ItemPreviewContent } from './components/item-preview';

interface IBlockCompletionRequestOfficialTestMeterShort {
    condition: boolean;
}

interface IDeliveryPointSummaryWithContractAccout extends SkSppNzpBeApiCustomerprofileDeliveryPointSummary {
    contractAccount?: SkSppNzpBeApiCustomerprofileContractAccountSummary;
}

export const BlockCompletionRepaymentAgreement: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const initializeRequestCreationProcess = useSendRequest();
    const { additionalData, content, customerRequestTemplate, invoices: reduxInvoices, businessPartner, paid } = useSelector(
        (state: IRootState) => state.customerRequest,
    );
    const [isLoadingCreateRequest, setIsLoadingCreateRequest] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState<boolean>(false);
    const { formRules } = useFormRules();
    const dispatch = useDispatch();
    const invoices = content.invoice ? [content.invoice] : reduxInvoices;

    const { register, handleSubmit, errors } = useForm<IBlockCompletionRequestOfficialTestMeterShort>({
        defaultValues: {},
    });

    const overallPrice = () => {
        let price = 0;
        invoices?.forEach((item: SkSppNzpBeApiCustomerprofileInvoice) => (price = item.unpaid ? price + item.unpaid : price));
        return price;
    };

    const onRequestSentSuccessConfirmed = () => {
        setIsRequestCreated(false);
        history.goBack();
    };

    const onFormSubmit = (formData: IBlockCompletionRequestOfficialTestMeterShort) => {
        const deliveryPoints: IDeliveryPointSummaryWithContractAccout[] = [];

        invoices?.forEach((invoice: SkSppNzpBeApiCustomerprofileInvoice) => {
            invoice.deliveryPoints?.forEach((dp) => deliveryPoints.push({ ...dp, contractAccount: invoice.contractAccount }));
        });

        const deliveryPointsUniqueById = [...new Map(deliveryPoints.map((item) => [item.id, item])).values()];

        const data: any = {
            content: {
                metadata: {
                    businessPartnerId: businessPartner?.id ?? additionalData?.bpuuid,
                },
                invoiceNumbers: (invoices as SkSppNzpBeApiCustomerprofileInvoice[])?.map((invoice) => {
                    return { number: invoice.reference };
                }),
                underchargeSettlementInvoice: {
                    amount: overallPrice(),
                    repaymentCount: content.underchargeSettlementInvoice?.repaymentCount,
                },
                entityReferences: deliveryPointsUniqueById.map((dp) => ({
                    contractId: dp.contract?.id,
                    deliveryPointId: dp.id,
                    contractAccountId: dp.contractAccount?.id,
                })),
                note: content.reason,
                type: CustomerRequestTypeEnum.ZOM_DOS,
                paymentApproval: formData.condition,
            },
            customerRequestTemplate,
        };
        setIsLoadingCreateRequest(true);
        initializeRequestCreationProcess(data)
            .then(() => {
                dispatch(CustomerRequestActions.setSend(true)); //deactivate page guard
                setIsRequestCreated(true);
            })
            .catch((err) => err.message)
            .finally(() => setIsLoadingCreateRequest(false));
    };

    const checkBoxLabel = paid?.paid && (
        <>
            {t('customer-request.steps.accept-current-price-list')}
            <br />
            <a href={paid?.comodity === CommodityEnum.ELECTRICITY ? PRICE_LINK_EE : PRICE_LINK_ZP} target="_blank" rel="noopener noreferrer">
                {t('customer-request.steps.current-price-list')}&nbsp;
            </a>
        </>
    );

    const { data: invoiceTypeCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_INVOICE_TYPE,
        codeListTypeEnum: CodeListTypeEnum.INVOICE_TYPE,
        paging: {
            size: 64,
        },
    });

    return (
        <>
            {isLoadingCreateRequest && <LoadingIndicator fullscreen />}
            <ListGroup>
                <ItemPreview title={t('customer-request.steps.unpaid-invoices.title')}>
                    <ItemPreviewContent bold>
                        {(invoices as SkSppNzpBeApiCustomerprofileInvoice[])?.map((invoice) => (
                            <p key={`invoice_${invoice.id}`} className="mb-0">
                                <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} /> z{' '}
                                {invoice.issueAt && formatDate(new Date(invoice.issueAt))}{' '}
                                {invoice.unpaid && '(' + formatCurrency(invoice.unpaid) + ')'}
                            </p>
                        ))}
                    </ItemPreviewContent>
                </ItemPreview>

                {/* <ItemPreview title={t('customer-request.steps.invoice-out-of-cycle-data.date-for-issue-invoice')}> */}
                <ItemPreview title="Celková suma na splátky">
                    <ItemPreviewContent bold>{formatCurrency(overallPrice())}</ItemPreviewContent>
                </ItemPreview>

                <ItemPreview title={t('customer-request.steps.number-of-installments.title')}>
                    <ItemPreviewContent bold>{content.underchargeSettlementInvoice?.repaymentCount}</ItemPreviewContent>
                </ItemPreview>
                <ItemPreview title={t('customer-request.steps.reason.title')}>
                    <ItemPreviewContent bold>{content.reason}</ItemPreviewContent>
                </ItemPreview>
            </ListGroup>

            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                {checkBoxLabel && (
                    <div className="p-3 my-4 rounded" style={{ backgroundColor: '#dddbdb', color: 'black' }}>
                        <Checkbox
                            ref={register({ ...formRules.required })}
                            errors={errors}
                            id="condition"
                            name="condition"
                            label={checkBoxLabel}
                            className="mb-1"
                        />
                    </div>
                )}
                <Button type="submit" size="lg" color="primary" block className="mt-5">
                    <Trans i18nKey="customer-request.steps.send-request" />
                </Button>
            </form>

            <Modal isOpen={isRequestCreated} centered>
                <ModalBody>
                    <ChangeRequestSent onClose={onRequestSentSuccessConfirmed} />
                </ModalBody>
            </Modal>
        </>
    );
};
