import {
    SkSppNzpBeApiCommonPagedResponseSkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary as UnitedDeliveryPointSummaryResponse,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch,
    SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary,
} from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { eInvoiceSettingsActions, EInvoiceSettingsModalTypes } from '../../../actions/einvoice-settings-actions';
import { Paginator } from '../../../components/common';
import LoadingIndicator from '../../../components/common/loading-indicator';
import { useApi } from '../../../hooks/use-api';
import { useKeyValueStore } from '../../../hooks/use-key-value';
import { useQueryWithError } from '../../../hooks/use-query-with-error';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { createIndexedGroups, ISort } from '../../../main';
import { IRootState } from '../../../reducers';
import Routes, { SettingsRoutes } from '../../../routes/routes';
import { buildUnitedDeliveryPointsSearchQueryKey, QueryKeysEnum, useReloadQueryCache } from '../../../utils/react-query-utils';
import ActivateEInvoiceModal from './activate-einvoice/activate-einvoice-modal';
import BulkActivateEInvoiceModal from './bulk-activate-einvoice/bulk-activate-einvoice-modal';
import BulkEInvoiceActivation from './bulk-einvoice-activation';
import ChangeDeliveryPointEmailModal from './change-delivery-point-email-modal';
import EInvoicesSettingsSubHeader from './einvoices-subheader';
import { EinvoiceBusinessPartnerBlock } from './list-items/einvoice-business-partner-block';
import { useCanBulkActivateEinvoice } from './utils/use-can-bulk-activate-einvoice';
import { useFetchMultipleCustomerRequests } from './utils/use-fetch-multiple-customer-requests';

const EInvoicesSettings: React.FC = () => {
    const pageSize = 10;

    const loggedInCustomer = useSelector((store: IRootState) => store.user.customer);
    const api = useApi();
    const history = useHistory();
    const [pageIndex, setPageIndex] = useState(0);
    const [, setStoredPageIndex] = useKeyValueStore(SettingsRoutes.EINVOICES + '@pageIndex', 0);

    const [deliveryPointGroups, setDeliveryPointGroups] = useState<Record<string, SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary[]>>();

    const unitedDeliveryPointsSearchObject: SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSearch = useMemo(() => {
        const sort: ISort[] = [{ attribute: 'bpName', direction: 'ASC' }];
        return {
            deliveryPoint: { hidden: false },
            shared: false,
            pairingDone: true,
            includeInactive: false,
            paging: { page: pageIndex, size: pageSize, sort: sort },
        };
    }, [pageIndex, pageSize]);
    const { isFetching: isFetchingDeliveryPoints, data: deliveryPointsData } = useQueryWithError<UnitedDeliveryPointSummaryResponse | null>(
        buildUnitedDeliveryPointsSearchQueryKey(loggedInCustomer?.id, unitedDeliveryPointsSearchObject),
        async () =>
            loggedInCustomer?.id == null
                ? null
                : api.customers
                      .searchUnitedDeliveryPoint(loggedInCustomer.id, unitedDeliveryPointsSearchObject, { fetch: ['E_INVOICE'] }, { secure: true })
                      .then((res) => res.data),
        {
            onSuccess: (data) => {
                const groupedByBP = createIndexedGroups<SkSppNzpBeApiCustomerprofileUnitedDeliveryPointSummary>(
                    data?.result || [],
                    (x) => x.businessPartner?.id,
                );
                setDeliveryPointGroups(groupedByBP);
            },
        },
    );

    const deliveryPointForActivation = useSelector((state: IRootState) => state.eInvoiceSettings.deliveryPointForActivation);
    const modalType = useSelector((state: IRootState) => state.eInvoiceSettings.modalType);

    const dispatch = useDispatch();
    const onCloseModal = () => {
        dispatch(eInvoiceSettingsActions.openModal(undefined));
    };

    const reloadQueryCache = useReloadQueryCache();
    const afterEInvoiceActivated = useCallback(() => {
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
        reloadQueryCache([QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH]);
    }, [reloadQueryCache]);

    const afterEInvoicesBulkActivated = useCallback(() => {
        reloadQueryCache(QueryKeysEnum.UNITED_DELIVERY_POINT);
        reloadQueryCache([QueryKeysEnum.UNITED_DELIVERY_POINTS_SEARCH]);
    }, [reloadQueryCache]);

    const openBulkActivateEInvoiceModal = () => dispatch(eInvoiceSettingsActions.openModal(EInvoiceSettingsModalTypes.BULK_ACTIVATE_EINVOICE_MODAL));

    const urlQuery = useUrlQuery();
    const queriedPage = Number(urlQuery.get('page')) || undefined;

    useEffect(() => {
        if (queriedPage != null && queriedPage > 0) {
            setPageIndex(queriedPage - 1);
        } else {
            setStoredPageIndex(0);
        }
    }, [queriedPage, setPageIndex, setStoredPageIndex]);

    const onPageChange = (newPage: number) => {
        setPageIndex(newPage);
        setStoredPageIndex(newPage);
        history.replace({
            search: `?page=${newPage + 1}`,
        });
    };

    const { isFetching: isFetchingCustomerRequests, data: customerRequestsData } = useFetchMultipleCustomerRequests(deliveryPointsData?.result);
    const { isLoading: isLoadingCanBulkActivate, canBulkActivateEinvoice } = useCanBulkActivateEinvoice();

    return (
        <>
            {(isFetchingDeliveryPoints || isFetchingCustomerRequests) && <LoadingIndicator fullscreen />}

            <Container>
                <Row className="justify-content-lg-center pb-5 pt-2 pt-lg-5">
                    <Col xs={12} xl={8} className="position-static">
                        <nav aria-label="breadcrumb" className="d-lg-none">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link to={Routes.CUSTOMER_SETTINGS} className="text-decoration-none">
                                        <i className="icon-arrow-left-16"></i> <Trans i18nKey="settings.title" />
                                    </Link>
                                </li>
                            </ol>
                        </nav>

                        <EInvoicesSettingsSubHeader />

                        {(deliveryPointsData === null || deliveryPointsData?.paging?.total === 0) && (
                            <Card>
                                <CardBody className="text-center">
                                    <i className="icon-circle-alert-16 fa-lg d-block mb-2"></i>
                                    <p>
                                        <Trans i18nKey="settings.e-invoices.user-has-no-delivery-points-with-e-invoice-options" />
                                    </p>
                                </CardBody>
                            </Card>
                        )}

                        <div className="mb-4">
                            {deliveryPointsData != null && deliveryPointGroups != null && (
                                <>
                                    <div className="position-relative mb-5">
                                        {isLoadingCanBulkActivate && (
                                            <div className="p-4">
                                                <LoadingIndicator size="medium" />
                                            </div>
                                        )}
                                        {canBulkActivateEinvoice && <BulkEInvoiceActivation />}
                                    </div>

                                    {Object.keys(deliveryPointGroups).map((bpId) => (
                                        <EinvoiceBusinessPartnerBlock
                                            key={bpId}
                                            unitedDeliveryPoints={deliveryPointGroups[bpId]}
                                            customerRequests={customerRequestsData}
                                            hideBusinessPartnerName={Object.keys(deliveryPointGroups).length === 1}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                        <Paginator
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            dataLength={deliveryPointsData?.paging?.total}
                            onPageChanged={onPageChange}
                        />
                    </Col>
                </Row>
            </Container>

            <ChangeDeliveryPointEmailModal
                isOpen={modalType === EInvoiceSettingsModalTypes.EDIT_DELIVERY_POINT_EMAIL_MODAL}
                onCloseModal={onCloseModal}
            />

            <ActivateEInvoiceModal
                isOpen={modalType === EInvoiceSettingsModalTypes.ACTIVATE_EINVOICE_MODAL}
                onCloseModal={onCloseModal}
                unitedDeliveryPointId={deliveryPointForActivation?.id}
                onActivate={afterEInvoiceActivated}
                canBulkActivate={canBulkActivateEinvoice}
                startBulkActivate={openBulkActivateEInvoiceModal}
            />

            <BulkActivateEInvoiceModal
                isOpen={modalType === EInvoiceSettingsModalTypes.BULK_ACTIVATE_EINVOICE_MODAL}
                onCloseModal={onCloseModal}
                onBulkActivate={afterEInvoicesBulkActivated}
            />
        </>
    );
};

export default EInvoicesSettings;
