import React from 'react';

export const ReCaptchaInfoMessage = React.memo(() => {
    return null;
    /*
    return (
        <p>
            {t('common.recaptcha.protected')}
            <a href={`https://policies.google.com/privacy?hl=${i18n.language}`} target="_blank" rel="noopener noreferrer nofollow">
                {t('common.recaptcha.privacy-policy')}
            </a>
            {` ${t('common.recaptcha.and')} `}
            <a href={`https://policies.google.com/terms?hl=${i18n.language}`} target="_blank" rel="noopener noreferrer nofollow">
                {t('common.recaptcha.term')}
            </a>
            {i18n.language === 'en' && ' apply'}.
        </p>
    );*/
});
