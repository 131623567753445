import React from 'react';
import { Checkbox } from '../../../../components/common';
import { useSelectedPoints } from '../../share-delivery-points/contexts/selected-delivery-points-context';

type Props = {
    id?: string;
};

export const CheckboxCell: React.FC<Props> = ({ id }) => {
    const { selectedDeliveryPoints, selectedChangedHandler } = useSelectedPoints();

    const isChecked = id ? selectedDeliveryPoints.includes(id) : false;

    const getSelectedWithThisId = () => {
        return id ? [...selectedDeliveryPoints, id] : [...selectedDeliveryPoints];
    };

    const getSelectedWithoutThisId = () => {
        return selectedDeliveryPoints.filter((deliveryPointId) => deliveryPointId !== id);
    };

    const onChangeHandler = () => {
        const wasCheckedBeforeClick = isChecked;
        selectedChangedHandler(wasCheckedBeforeClick ? getSelectedWithoutThisId() : getSelectedWithThisId());
    };

    return (
        <td>
            <Checkbox id={`checkbox-${id}`} name="checkbox" label="" className="table-checkbox" checked={isChecked} onChange={onChangeHandler} />
        </td>
    );
};
