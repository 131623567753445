import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMeterReading } from '../../../../../../models/customer-request-model';
import { formatDateToPreview } from '../../../../../../utils/date-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import ItemPreview, { ItemPreviewContent } from '../components/item-preview';

type Props = {
    data: IMeterReading;
    bold?: boolean;
};

const ReadingItem: React.FC<Props> = ({ data, bold }) => {
    const [t] = useTranslation();

    if (isObjectEmpty(data)) return <></>;

    const { value, valueHigh, valueLow, date } = data;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.gauge-condition.title')}>
                {/* {deliveryPointId && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point-meter.delivery-point-id',
                    )} ${deliveryPointId} `}</ItemPreviewContent>
                )} */}
                {value && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.delivery-point-meter.value')} ${value} `}</ItemPreviewContent>
                )}
                {valueHigh && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point-meter.value-high',
                    )} ${valueHigh} `}</ItemPreviewContent>
                )}
                {valueLow && (
                    <ItemPreviewContent bold={bold}>{`${t(
                        'customer-request.steps.delivery-point-meter.value-low',
                    )} ${valueLow} `}</ItemPreviewContent>
                )}
                {date && (
                    <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.gauge-condition.date')} ${formatDateToPreview(
                        date,
                    )} `}</ItemPreviewContent>
                )}
            </ItemPreview>
        </>
    );
};

export default ReadingItem;
