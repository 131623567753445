import React from 'react';
import { useTranslation } from 'react-i18next';
import useCodeList from '../../../../../../hooks/use-code-list';
import { IInvoiceDelivery } from '../../../../../../models/customer-request-model';
import { QueryKeysEnum } from '../../../../../../utils/react-query-utils';
import { isObjectEmpty } from '../../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../config/enums';
import ItemPreview, { ItemPreviewContent, ItemPreviewHeader } from '../components/item-preview';

type Props = {
    data: IInvoiceDelivery;
    bold?: boolean;
    requestContent: any;
};

const LastInvoiceDeliveryItem: React.FC<Props> = ({ data, bold, requestContent }) => {
    const [t] = useTranslation();

    const { email } = data;

    const bankConnection = requestContent?.overpaymentSettlement?.bankConnection;
    const address = requestContent?.overpaymentSettlement?.address;

    const { data: countryCodeList } = useCodeList({
        queryKey: QueryKeysEnum.CODE_LIST_COUNTRY,
        codeListTypeEnum: CodeListTypeEnum.COUNTRY,
        paging: {
            size: 300,
            sort: ['name,ASC'],
        },
    });

    if (isObjectEmpty(data)) return <></>;

    return (
        <>
            <ItemPreview title={t('customer-request.steps.invoice-delivery.end-subscription')}>
                {email && (
                    <>
                        {t('customer-request.steps.invoice-delivery.send-invoice-ended-subscription')}
                        <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.invoice-delivery.via-email')}: ${email}`}</ItemPreviewContent>
                    </>
                )}
                {email && (bankConnection || address) && (
                    <>
                        <br />
                        <ItemPreviewHeader title={t('customer-request.steps.settlement-overpayment.title')} />
                    </>
                )}

                {bankConnection && (
                    <>
                        {bankConnection.iban && (
                            <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.iban')}: ${
                                bankConnection.iban
                            }`}</ItemPreviewContent>
                        )}
                        {bankConnection.bicSwift && (
                            <ItemPreviewContent bold={bold}>{`${t('customer-request.steps.bank-details.bic-swift')}: ${
                                bankConnection.bicSwift
                            }`}</ItemPreviewContent>
                        )}
                    </>
                )}
                {address && !isObjectEmpty(address) && (
                    <>
                        <p>
                            {address?.name || ''} <br />
                            {address?.street || ''} {address?.number || ''}
                            <br />
                            {address?.city || ''} {address?.postalCode || ''}
                            <br />
                            {countryCodeList?.find((item) => item.uuid === address?.country?.uuid)?.name || ''}
                        </p>
                        {address?.floor && (
                            <>
                                {t('customer-request.steps.address.floor')}
                                <p>{address?.floor}.</p>
                            </>
                        )}
                        {address?.flatNumber && (
                            <>
                                {t('customer-request.steps.address.appartments-number')}
                                <p>{address?.flatNumber}</p>
                            </>
                        )}
                    </>
                )}
            </ItemPreview>
        </>
    );
};

export default LastInvoiceDeliveryItem;
