import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { CommodityEnum } from '../../../../../models/enums';
import { CommodityProps } from '../consumption';
import ConsumptionReadForm from './consumption-read-form';

type Props = {
    modalLabel: string | JSX.Element;
    defaultSelectedCommodity: CommodityProps;
    contractId: string;
    title?: string;
    myRead?: boolean;
    dual?: boolean;
    isOpen: boolean;
    onClose: (submit: boolean) => void;
    lastLowValue?: number;
    lastHighValue?: number;
    readingCycleFrom?: string;
    readingCycleTo?: string;
};

const ConsumptionReadModal: React.FC<Props> = ({
    isOpen,
    onClose,
    modalLabel,
    title,
    myRead,
    dual,
    defaultSelectedCommodity,
    contractId,
    lastLowValue,
    lastHighValue,
    readingCycleFrom,
    readingCycleTo,
}) => {
    const helpText = () => {
        if (defaultSelectedCommodity.type === CommodityEnum.GAS) {
            return 'SOT_GAS_METER_WHERE_I_FIND_IT';
        } else if (defaultSelectedCommodity.type === CommodityEnum.ELECTRICITY) {
            if (dual) {
                return 'SOT_ELECTRICITY_METERS_WHERE_I_FIND_IT';
            } else {
                return 'SOT_ELECTRICITY_METER_WHERE_I_FIND_IT';
            }
        }

        return '';
    };

    return (
        <Modal isOpen={isOpen} modalClassName="modal-fullscreen" centered>
            <div className="modal-header">
                <h3 className="modal-title">{modalLabel}</h3>
                <button type="button" onClick={() => onClose(false)} className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-Times" aria-hidden="true"></i>
                </button>
            </div>
            <ModalBody className="px-4 pb-5">
                <h4 className="mb-3">
                    <Trans i18nKey="delivery-point.detail.consumption.delivery-point" />
                    {title}
                </h4>
                <ConsumptionReadForm
                    onClose={() => onClose(true)}
                    deliveryPointId={defaultSelectedCommodity.id}
                    contractId={contractId}
                    commodityType={defaultSelectedCommodity.type}
                    helpText={helpText()}
                    myRead={myRead}
                    dual={dual}
                    lastLowValue={lastLowValue}
                    lastHighValue={lastHighValue}
                    readingCycleFrom={readingCycleFrom}
                    readingCycleTo={readingCycleTo}
                />
            </ModalBody>
        </Modal>
    );
};

export default ConsumptionReadModal;
