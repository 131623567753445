import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkSppNzpBeApiCustomerprofileDeliveryPoint, SkSppNzpBeApiCustomerprofileTariffRate as TariffRate } from '@spp/spp-meru-frontend-common';
import { nextTick } from 'process';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRequestActions } from '../../../../../actions/customer-request-actions';
import BaseButton from '../../../../../components/common/base-button';
import { useApi } from '../../../../../hooks/use-api';
import { useQueryWithError } from '../../../../../hooks/use-query-with-error';
import { BusinessPartnerTypeEnum, ConsumptionCategoryEnum, LoadingIndicator } from '../../../../../main';
import { ITariff } from '../../../../../models/customer-request-model';
import { IRootState } from '../../../../../reducers';
import { CustomerRequestPayloadType } from '../../../../../reducers/interfaces/customer-request-state';
import { buildDeliveryPointQueryKey, QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { CustomerRequestDataEnum } from '../../../config/enums';
import TariffFields from '../components/tariff-fields';

export const BlockTariffSelectionShort: React.FC = () => {
    const dispatch = useDispatch();
    const api = useApi();

    const { currentBlock, content, metadata, deliveryPointZp } = useSelector((state: IRootState) => state.customerRequest);

    const objectName = currentBlock?.dataKey ?? CustomerRequestDataEnum.TARIFF;
    const objectData = content[objectName] as ITariff;
    const currentTariff = deliveryPointZp?.tariffRate?.uuid;

    //Request for transcription
    const newBusinessPartner = content[CustomerRequestDataEnum.NEW_BUSINESS_PARTNER]?.type;

    const [consumptionCategory, setConsumptionCategory] = useState<ConsumptionCategoryEnum | undefined>(
        newBusinessPartner === BusinessPartnerTypeEnum.PERSON
            ? ConsumptionCategoryEnum.HOME
            : newBusinessPartner === BusinessPartnerTypeEnum.COMPANY
            ? ConsumptionCategoryEnum.RETAIL
            : undefined,
    );

    const { register, handleSubmit, errors, trigger, getValues, watch } = useForm<ITariff>({ defaultValues: { ...objectData } });

    const { isLoading: isLoadingDeliveryPoint } = useQueryWithError<SkSppNzpBeApiCustomerprofileDeliveryPoint | null>(
        buildDeliveryPointQueryKey(metadata.contractId),
        async () => {
            return metadata.contractId == null
                ? null
                : api.deliveryPoints.getByContractUuid(metadata.contractId, {}, { secure: true }).then((res) => res.data);
        },
        {
            onSuccess: (deliveryPointData) => {
                dispatch(CustomerRequestActions.setDeliveryPointZp(deliveryPointData || undefined));
                if (
                    deliveryPointData &&
                    newBusinessPartner !== BusinessPartnerTypeEnum.PERSON &&
                    newBusinessPartner !== BusinessPartnerTypeEnum.COMPANY
                ) {
                    if (deliveryPointData.deliveryCategory != null) {
                        setConsumptionCategory(ConsumptionCategoryEnum[deliveryPointData.deliveryCategory]);
                    } else {
                        setConsumptionCategory(ConsumptionCategoryEnum.HOME);
                    }
                } else {
                    if (newBusinessPartner !== BusinessPartnerTypeEnum.PERSON && newBusinessPartner !== BusinessPartnerTypeEnum.COMPANY) {
                        setConsumptionCategory(metadata.category);
                    }
                }
            },
            onError: () => {
                dispatch(CustomerRequestActions.setDeliveryPointZp(undefined));
                return false;
            },
        },
    );

    const { data: tariffRates, isLoading: isLoadingTariffRates } = useQueryWithError<TariffRate[] | undefined>(
        [QueryKeysEnum.PRODUCT_TARIFF_RATE, { type: 'ZP', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }],
        async () =>
            consumptionCategory == null
                ? undefined
                : api.productCatalogs
                      .getTariffsAndRates({ type: 'ZP', status: 'ACTIVE', category: consumptionCategory, paging: { size: 5000 } }, { secure: true })
                      .then((res) => res.data?.result),
    );

    const onSubmit = (data: ITariff) => {
        const payload: CustomerRequestPayloadType = {
            [objectName]: data,
        };

        dispatch(CustomerRequestActions.setData(payload));
        nextTick(() => {
            dispatch(CustomerRequestActions.nextStep());
        });
    };

    const isSubmitAllowed = useMemo(
        () => !!getValues('tariff.uuid'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [watch && watch('tariff.uuid'), getValues],
    );

    return (
        <>
            {(isLoadingTariffRates || isLoadingDeliveryPoint) && <LoadingIndicator />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                {tariffRates && (
                    <>
                        {tariffRates && (
                            <TariffFields register={register} errors={errors} data={tariffRates} trigger={trigger} currentTariff={currentTariff} />
                        )}
                        <BaseButton type="submit" disabled={!isSubmitAllowed}>
                            <Trans i18nKey="customer-request.steps.next">Ďalej</Trans>
                        </BaseButton>
                    </>
                )}
                {tariffRates && tariffRates.length === 0 && consumptionCategory === 'WHOLESALE' && (
                    <div className="alert alert-danger p-4 d-flex mb-5" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mt-2" />
                        <div className="ml-4">
                            <Trans i18nKey="delivery-point.detail.payment-data.tariffs.change-of-tariff-not-supported"></Trans>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
};
