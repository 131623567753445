import { SkSppNzpBeApiCustomerprofileDeliveryPointSummary } from '@spp/spp-meru-frontend-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommodityEnum } from '../../../main';

interface IDeliveryPointCommodityBadgesProps {
    deliveryPoints: SkSppNzpBeApiCustomerprofileDeliveryPointSummary[];
}

export const DeliveryPointCommodityBadges: React.FC<IDeliveryPointCommodityBadgesProps> = ({ deliveryPoints }) => {
    const { t } = useTranslation();

    const allInactive = deliveryPoints.every((dp) => dp.status !== 'ACTIVE');

    const gasDPsCount = deliveryPoints.filter((dp) => dp.type === CommodityEnum.GAS && dp.status === 'ACTIVE').length || 0;
    const electricityDPsCount = deliveryPoints.filter((dp) => dp.type === CommodityEnum.ELECTRICITY && dp.status === 'ACTIVE').length || 0;

    return (
        <>
            {allInactive ? (
                <span className="badge-base badge-inactive text-nowrap text-uppercase mr-1 mb-1 ">{t('delivery-point.inactive')}</span>
            ) : (
                <>
                    {gasDPsCount > 0 && (
                        <span className="badge-base badge-ng text-nowrap mr-1 mb-1">
                            {gasDPsCount > 1 && <>{`${gasDPsCount}x`}&nbsp;</>}
                            {t('enums.Commodity.GAS')}
                        </span>
                    )}
                    {electricityDPsCount > 0 && (
                        <span className="badge-base badge-ec text-nowrap mr-1 mb-1">
                            {electricityDPsCount > 1 && <>{`${electricityDPsCount}x`}&nbsp;</>}
                            {t('enums.Commodity.ELECTRICITY')}
                        </span>
                    )}
                </>
            )}
        </>
    );
};
