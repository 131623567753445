import {
    SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse,
    SkSppNzpBeApiNotificationSettingNotificationSettingResponse,
} from '@spp/spp-meru-frontend-common';
import { Reducer } from 'react';
import { NotificationSettingsAction, NotificationSettingsActionType } from '../actions/notification-settings-actions';

export interface INotificationSettingsState {
    deliveryPointsNotificationSettings: SkSppNzpBeApiNotificationSettingDeliveryPointNotificationSettingResponse[];
}

const initState: INotificationSettingsState = { deliveryPointsNotificationSettings: [] };

const NotificationSettingsReducer: Reducer<INotificationSettingsState, NotificationSettingsAction> = (state = initState, action) => {
    switch (action.type) {
        case NotificationSettingsActionType.SET_DELIVERY_POINT_NOTIFICATION_SETTINGS_LIST: {
            return { ...state, deliveryPointsNotificationSettings: action.payload.deliveryPointNotificationSettings };
        }
        case NotificationSettingsActionType.SET_NOTIFICATION_SETTING: {
            const { deliveryPointId, notificationSetting } = action.payload;
            const deliveryPointsNotificationSettings = state.deliveryPointsNotificationSettings.map((dpSetting) =>
                dpSetting.unitedDeliveryPoint?.id === deliveryPointId ? { ...dpSetting, notificationSettings: notificationSetting } : dpSetting,
            );
            return {
                ...state,
                deliveryPointsNotificationSettings: deliveryPointsNotificationSettings,
            };
        }

        case NotificationSettingsActionType.SET_BULK_NOTIFICATION_SETTING: {
            const { deliveryPointIds, notificationTemplateTypes, notificationChannel, enabled } = action.payload;
            const settingValues = notificationChannel.reduce(
                (acc, channel) => (channel === 'EMAIL' ? { ...acc, email: enabled } : { ...acc, sms: enabled }),
                {},
            );
            const modifySetting = (setting: SkSppNzpBeApiNotificationSettingNotificationSettingResponse) =>
                setting.notificationTemplate?.code && notificationTemplateTypes.includes(setting.notificationTemplate?.code)
                    ? { ...setting, ...settingValues }
                    : setting;
            const deliveryPointsNotificationSettings = state.deliveryPointsNotificationSettings.map((dpSetting) =>
                dpSetting.unitedDeliveryPoint?.id && deliveryPointIds.includes(dpSetting.unitedDeliveryPoint?.id)
                    ? { ...dpSetting, notificationSettings: dpSetting.notificationSettings?.map((setting) => modifySetting(setting)) }
                    : dpSetting,
            );
            return {
                ...state,
                deliveryPointsNotificationSettings: deliveryPointsNotificationSettings,
            };
        }
        default:
            return state;
    }
};

export default NotificationSettingsReducer;
