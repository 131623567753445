import { SkSppNzpBeApiCustomerprofileTariffRate } from '@spp/spp-meru-frontend-common';
import {
    AssumedEeConsumptionEnum,
    clearObjectProperties,
    ConsumptionCategoryEnum,
    ContractTerminationEnum,
    CustomerRequestStatus,
    CustomerRequestStatusFilterEnum,
    DeliveryPointEeConnectionEnum,
    DeliveryPointZpConnectionEnum,
    DistributionAreasEnum,
    DistributorOptionsEnum,
    Rates,
} from '../../main';
import { IApprovals } from '../../models/customer-request-model';
import { ICustomerRequestState } from '../../reducers/interfaces/customer-request-state';

export const isRequestStatusCancelled = (status: CustomerRequestStatus): boolean => {
    return (
        status === CustomerRequestStatusFilterEnum.SAP_CANCELLED ||
        status === CustomerRequestStatusFilterEnum.CANCELLED_BY_USER ||
        status === CustomerRequestStatusFilterEnum.SAP_CANCELLED_BY_USER
    );
};

export const isRequestStatusSent = (status: CustomerRequestStatus): boolean => {
    return (
        status === CustomerRequestStatusFilterEnum.CREATED ||
        status === CustomerRequestStatusFilterEnum.REGISTERED ||
        status === CustomerRequestStatusFilterEnum.SAP_OPEN
    );
};

export const displayTextRelationToRealEstate = (reason?: string): boolean => {
    return reason !== 'FUEL_CHANGE' && reason !== 'WITHOUT_GIVING_REASON' && reason !== 'DEMOLITION_WORKS' && reason !== 'SUPPLIER_CHANGE';
};

export const eeSupllyRequest = (customerRequest: ICustomerRequestState, inProgress?: boolean, approvalsForm?: Partial<IApprovals>): any => {
    const { businessPartner, customerRequestTemplate, content, step, uuid, attachments, request } = customerRequest;

    //additional info set only if request is saved in progress
    delete customerRequest.content.saveRequested;
    delete customerRequest.content.saved;
    delete customerRequest.content.savedRequestBlocks;

    const approvals = clearObjectProperties({
        personalInfoProcessingConcent: approvalsForm?.personalInfoProcessingConcent ? true : false,
        personalInfoProcessing: approvalsForm?.personalInfoProcessing ? true : false,
    });
    const assumedConsumptionEe = clearObjectProperties({
        advancePayment: content.advancePayment?.amount,
        value: content.assumedConsumption?.value,
        valueHigh: content.assumedConsumption?.valueHigh,
        valueLow: content.assumedConsumption?.valueLow,
        tariff: content.assumedConsumption?.tariff,
        date: content.assumedConsumption?.date,
    });

    const connectionDetailEe = clearObjectProperties({
        mainCircuitBreakerValue: content.assumedConsumption?.mainCircuitValue,
        circuitPhaseCount: content.assumedConsumption?.phase,
    });

    const paymentMethod = clearObjectProperties({
        paymentType: content.paymentMethod?.paymentType.uuid && { uuid: content.paymentMethod?.paymentType.uuid },
        iban: content.paymentMethod?.iban,
        bicSwift: content.paymentMethod?.bicSwift,
        sipoNumber: content.paymentMethod?.sipoNumber,
    });

    const contractDelivery = clearObjectProperties({
        type: content.contractDelivery?.type,
        address: content.contractDelivery?.address,
    });

    const invoiceDelivery = clearObjectProperties({
        type: content.invoiceDelivery?.type,
        address: content.invoiceDelivery?.address,
        email: content.invoiceDelivery?.email,
    });

    const contact =
        content.contact ||
        (content.accountInfo?.phoneNumber && content.accountInfo?.email
            ? { phoneNumber: content.accountInfo.phoneNumber, email: content.accountInfo.email }
            : undefined);

    return {
        content: {
            metadata: businessPartner?.id
                ? {
                      businessPartnerId: businessPartner?.id,
                  }
                : undefined,
            businessPartner: content.accountInfo?.bpNumber ? { bpNumber: content.accountInfo?.bpNumber } : content.businessPartner,
            address: content.address,
            correspondenceAddress: content.correspondenceAddress,
            contact,
            owner: content.owner,
            ownerAddress: content.ownerAddress,
            ownerCorrespondenceAddress: content.ownerCorrespondenceAddress,
            ownerContact: content.ownerContact,
            deliveryPoint: {
                address: { ...content.newDeliveryPointAddress, country: undefined },
                eic: content.assumedConsumption?.deliveryPointNumber,
            },
            deliveryPointConnectionEe: content.deliveryPointConnectionEe?.type && {
                type: content.deliveryPointConnectionEe?.type,
                currentDistributor:
                    content.deliveryPointConnectionEe?.currentDistributor === 'other'
                        ? content.deliveryPointConnectionEe?.currentDistributorCustom
                        : DistributorOptionsEnum[content.deliveryPointConnectionEe?.currentDistributor as keyof typeof DistributorOptionsEnum],
                bindingTo: content.supplier?.bindingTo,
                noticePeriod: content.supplier?.noticePeriod,
                contractTermination: content.supplier?.contractTermination === ContractTerminationEnum.YES ? true : false,
                distributionArea: content.tariffEe?.distributionArea,
            },
            paymentMethod: Object.keys(paymentMethod).length > 0 ? paymentMethod : undefined,
            advancePaymentPeriod: content.advancePaymentPeriod?.period.uuid && { period: { uuid: content.advancePaymentPeriod?.period.uuid } },
            invoiceDelivery: Object.keys(invoiceDelivery).length > 0 ? invoiceDelivery : undefined,
            tariffEe: { ...content.tariffEe, distributionArea: undefined },
            connectionDetailEe: Object.keys(connectionDetailEe).length > 0 ? connectionDetailEe : undefined,
            assumedConsumptionEe: Object.keys(assumedConsumptionEe).length > 0 ? assumedConsumptionEe : undefined,
            contractDelivery: Object.keys(contractDelivery).length > 0 ? contractDelivery : undefined,
            approvals: Object.keys(approvals).length > 0 ? approvals : undefined,
            note: content?.note,
            type: customerRequestTemplate?.code,
        },
        customerRequestTemplate: customerRequestTemplate,
        draftMetadata: inProgress
            ? JSON.stringify({
                  step,
                  content,
                  businessPartner,
                  blocks: request?.blocks,
                  attachments: attachments?.map((item) => ({ uuid: item.uuid, info: item.info, name: item.file?.name || item.name })),
              })
            : undefined, //info about content and stage where request was saved
        uuid: uuid,
        attachments: attachments?.filter((attachment) => attachment.name),
        customerRegistration: content.registration,
    };
};

export const zpSupllyRequest = (customerRequest: ICustomerRequestState, inProgress?: boolean, approvalsForm?: Partial<IApprovals>): any => {
    const { businessPartner, customerRequestTemplate, content, step, uuid, attachments, request } = customerRequest;

    //additional info set only if request is saved in progress
    delete customerRequest.content.saveRequested;
    delete customerRequest.content.saved;
    delete customerRequest.content.savedRequestBlocks;

    const approvals = clearObjectProperties({
        personalInfoProcessingConcent: approvalsForm?.personalInfoProcessingConcent ? true : false,
        personalInfoProcessing: approvalsForm?.personalInfoProcessing ? true : false,
    });
    const assumedConsumptionZp = clearObjectProperties({
        advancePayment: content.advancePayment?.amount,
        value: content.assumedConsumption?.value,
        tariff: content.assumedConsumption?.tariff,
        date: content.assumedConsumption?.date,
    });

    const paymentMethod = clearObjectProperties({
        paymentType: content.paymentMethod?.paymentType.uuid && { uuid: content.paymentMethod?.paymentType.uuid },
        iban: content.paymentMethod?.iban,
        bicSwift: content.paymentMethod?.bicSwift,
        sipoNumber: content.paymentMethod?.sipoNumber,
    });

    const contractDelivery = clearObjectProperties({
        type: content.contractDelivery?.type,
        address: content.contractDelivery?.address,
    });

    const invoiceDelivery = clearObjectProperties({
        type: content.invoiceDelivery?.type,
        address: content.invoiceDelivery?.address,
        email: content.invoiceDelivery?.email,
    });

    const contact =
        content.contact ||
        (content.accountInfo?.phoneNumber && content.accountInfo?.email
            ? { phoneNumber: content.accountInfo.phoneNumber, email: content.accountInfo.email }
            : undefined);

    return {
        content: {
            metadata: businessPartner?.id
                ? {
                      businessPartnerId: businessPartner?.id,
                  }
                : undefined,
            businessPartner: content.accountInfo?.bpNumber ? { bpNumber: content.accountInfo?.bpNumber } : content.businessPartner,
            address: content.address,
            correspondenceAddress: content.correspondenceAddress,
            contact,
            deliveryPoint: {
                address: { ...content.newDeliveryPointAddress, country: undefined },
                pod: content.assumedConsumption?.deliveryPointNumber,
            },
            deliveryPointConnectionZp: content.deliveryPointConnectionZp?.type && {
                type:
                    content.deliveryPointConnectionZp?.type === DeliveryPointEeConnectionEnum.NEW
                        ? DeliveryPointZpConnectionEnum.NEW
                        : DeliveryPointZpConnectionEnum.RENEWAL,
                currentDistributor:
                    content.deliveryPointConnectionZp?.currentDistributor === 'other'
                        ? content.deliveryPointConnectionZp?.currentDistributorCustom
                        : DistributorOptionsEnum[content.deliveryPointConnectionZp?.currentDistributor as keyof typeof DistributorOptionsEnum],
                bindingTo: content.supplier?.bindingTo,
                noticePeriod: content.supplier?.noticePeriod,
                contractTermination: content.supplier?.contractTermination === ContractTerminationEnum.YES ? true : false,
            },
            paymentMethod: Object.keys(paymentMethod).length > 0 ? paymentMethod : undefined,
            advancePaymentPeriod: content.advancePaymentPeriod,
            invoiceDelivery: Object.keys(invoiceDelivery).length > 0 ? invoiceDelivery : undefined,
            tariffZp: content.tariffZp,
            assumedConsumptionZp: Object.keys(assumedConsumptionZp).length > 0 ? assumedConsumptionZp : undefined,
            contractDelivery: Object.keys(contractDelivery).length > 0 ? contractDelivery : undefined,
            approvals: Object.keys(approvals).length > 0 ? approvals : undefined,
            note: content.note,
            type: customerRequestTemplate?.code,
        },
        customerRequestTemplate: customerRequestTemplate,
        draftMetadata: inProgress
            ? JSON.stringify({
                  step,
                  content,
                  businessPartner,
                  blocks: request?.blocks,
                  attachments: attachments?.map((item) => ({ uuid: item.uuid, info: item.info, name: item.file?.name || item.name })),
              })
            : undefined, //info about content and stage where request was saved
        uuid: uuid,
        attachments: attachments?.filter((attachment) => attachment.name),
        customerRegistration: content.registration,
    };
};

export const ratesForSpecificDistributionAreaBasedOnOriginalDeliveryPointRate = (
    rates?: SkSppNzpBeApiCustomerprofileTariffRate[],
    originalRate?: Rates,
    originalDistributionArea?: string,
): SkSppNzpBeApiCustomerprofileTariffRate[] | undefined => {
    if (!rates || !originalRate || !originalDistributionArea) {
        return rates;
    }

    //Zapadne slovensko
    if (originalDistributionArea === DistributionAreasEnum.ZZS) {
        if (originalRate === Rates['C2-X3 (TDO1)']) {
            return rates?.filter((rate) => rate.code === Rates.DD1 || rate.code === Rates.DD2);
        }
        if (originalRate === Rates['C2-X3 (TDO2)']) {
            return rates?.filter((rate) => rate.code === Rates.DD3 || rate.code === Rates.DD4);
        }
        if (originalRate === Rates['C2-X3 (TDO3)']) {
            return rates?.filter((rate) => rate.code === Rates.DD5 || rate.code === Rates.DD6);
        }
        if (originalRate === Rates['C2-X3 (TDO8)']) {
            return rates?.filter((rate) => rate.code === Rates.DD7 || rate.code === Rates.DD8);
        }

        if (originalRate === Rates.DD1 || originalRate === Rates.DD2) {
            return rates?.filter((rate) => rate.code === Rates['C2-X3 (TDO1)']);
        }
        if (originalRate === Rates.DD3 || originalRate === Rates.DD4) {
            return rates?.filter((rate) => rate.code === Rates['C2-X3 (TDO2)']);
        }
        if (originalRate === Rates.DD5 || originalRate === Rates.DD6) {
            return rates?.filter((rate) => rate.code === Rates['C2-X3 (TDO3)']);
        }
        if (originalRate === Rates.DD7 || originalRate === Rates.DD8) {
            return rates?.filter((rate) => rate.code === Rates['C2-X3 (TDO8)']);
        }
    }

    //Stredne slovensko
    if (originalDistributionArea === DistributionAreasEnum.ZSS) {
        if (originalRate === Rates.C1 || originalRate === Rates.C2) {
            return rates?.filter((rate) => rate.code === Rates.DD1 || rate.code === Rates.DD2);
        }
        if (originalRate === Rates.C3 || originalRate === Rates.C4) {
            return rates?.filter((rate) => rate.code === Rates.DD3 || rate.code === Rates.DD4);
        }
        if (originalRate === Rates.C5 || originalRate === Rates.C6) {
            return rates?.filter((rate) => rate.code === Rates.DD5 || rate.code === Rates.DD6);
        }
        if (originalRate === Rates.C7 || originalRate === Rates.C8) {
            return rates?.filter((rate) => rate.code === Rates.DD7 || rate.code === Rates.DD8);
        }

        if (originalRate === Rates.DD1 || originalRate === Rates.DD2) {
            return rates?.filter((rate) => rate.code === Rates.C1 || rate.code === Rates.C2);
        }
        if (originalRate === Rates.DD3 || originalRate === Rates.DD4) {
            return rates?.filter((rate) => rate.code === Rates.C3 || rate.code === Rates.C4);
        }
        if (originalRate === Rates.DD5 || originalRate === Rates.DD6) {
            return rates?.filter((rate) => rate.code === Rates.C5 || rate.code === Rates.C6);
        }
        if (originalRate === Rates.DD7 || originalRate === Rates.DD8) {
            return rates?.filter((rate) => rate.code === Rates.C7 || rate.code === Rates.C8);
        }
    }

    //Vychodne slovensko
    if (originalDistributionArea === DistributionAreasEnum.ZVS) {
        if (originalRate === Rates['X3-C2 (TDO1)']) {
            return rates?.filter((rate) => rate.code === Rates.DD1 || rate.code === Rates.DD2);
        }
        if (originalRate === Rates['X3-C2 (TDO2)']) {
            return rates?.filter((rate) => rate.code === Rates.DD3 || rate.code === Rates.DD4);
        }
        if (originalRate === Rates['X3-C2 (TDO3)']) {
            return rates?.filter((rate) => rate.code === Rates.DD5 || rate.code === Rates.DD6);
        }
        if (originalRate === Rates['X3-C2 (TDO8)']) {
            return rates?.filter((rate) => rate.code === Rates.DD7 || rate.code === Rates.DD8);
        }

        if (originalRate === Rates.DD1 || originalRate === Rates.DD2) {
            return rates?.filter((rate) => rate.code === Rates['X3-C2 (TDO1)']);
        }
        if (originalRate === Rates.DD3 || originalRate === Rates.DD4) {
            return rates?.filter((rate) => rate.code === Rates['X3-C2 (TDO2)']);
        }
        if (originalRate === Rates.DD5 || originalRate === Rates.DD6) {
            return rates?.filter((rate) => rate.code === Rates['X3-C2 (TDO3)']);
        }
        if (originalRate === Rates.DD7 || originalRate === Rates.DD8) {
            return rates?.filter((rate) => rate.code === Rates['X3-C2 (TDO8)']);
        }
    }

    return rates;
};

//rateType T1/T2
export const ratesForSpecificDistributionAreaBasedOnRateType = (
    rates?: SkSppNzpBeApiCustomerprofileTariffRate[],
    rateType?: AssumedEeConsumptionEnum,
    consumptionCategory?: ConsumptionCategoryEnum,
    distributionArea?: DistributionAreasEnum,
): SkSppNzpBeApiCustomerprofileTariffRate[] | undefined => {
    if (!rates || !rateType || !consumptionCategory) {
        return rates;
    }
    if (consumptionCategory === ConsumptionCategoryEnum.HOME) {
        if (rateType === AssumedEeConsumptionEnum.T1) {
            return rates.filter((rate) => rate.code === Rates.DD1 || rate.code === Rates.DD2);
        }
        return rates.filter((rate) => rate.code !== Rates.DD1 && rate.code !== Rates.DD2);
    } else {
        if (distributionArea === DistributionAreasEnum.ZZS) {
            if (rateType === AssumedEeConsumptionEnum.T1) {
                return rates.filter((rate) => rate.code === Rates['C2-X3 (TDO1)']);
            }
            return rates.filter((rate) => rate.code !== Rates['C2-X3 (TDO1)']);
        }
        if (distributionArea === DistributionAreasEnum.ZSS) {
            if (rateType === AssumedEeConsumptionEnum.T1) {
                return rates.filter((rate) => rate.code === Rates.C1 || rate.code === Rates.C2);
            }
            return rates.filter((rate) => rate.code !== Rates.C1 && rate.code !== Rates.C2);
        }
        if (distributionArea === DistributionAreasEnum.ZVS) {
            if (rateType === AssumedEeConsumptionEnum.T1) {
                return rates.filter((rate) => rate.code === Rates['X3-C2 (TDO1)']);
            }
            return rates.filter((rate) => rate.code !== Rates['X3-C2 (TDO1)']);
        }
    }
    return rates;
};
