import { SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff } from '@spp/spp-meru-frontend-common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryChartProps,
    VictoryLabel,
    VictoryLabelProps,
    VictoryStack,
    VictoryTooltipProps,
    VictoryVoronoiContainer,
} from 'victory';
import { D3Scale } from 'victory-core';
import useCodeList from '../../../../../hooks/use-code-list';
import { ConsumptionGraphFormattedUnits, ConsumptionValueUnits } from '../../../../../models/enums';
import { QueryKeysEnum } from '../../../../../utils/react-query-utils';
import { formatDate, formatDateDayMonthYearTime, formatDateMonthYear } from '../../../../../utils/utils';
import { CodeListTypeEnum } from '../../../../customer-request/config/enums';
import useFetchConsumption, { Data } from '../use-fetch-consumption';

// TODO: check - spravit poriadok, pripadne pouzivat props.x.range() ...
const PADDING_LEFT = 55 + 10;
const PADDING_RIGHT = 55 - 40;
const PADDING_TOP = 30;
const TOOLTIP_WIDTH = 200;

const GRAPH_FONT_SIZE = '14';
const CHART_HEIGHT = 350;
const ANGLED = 930;
const DOMAIN_PADDING = { x: 10, y: 40 };

const fontFamily = 'inherit';

const tariffColors: { [key: string]: string } = {
    undefined: '#FFD500',
    ST: '#7f6a00',
    NT: '#70d696',
    VT: '#376a4a',
    PL: '#d8252a', //neni su farby od odyzea
    C2: '#ed9f84',
    C1: '#764f41',
    B2: '#88bcf7',
    B1: '#435d7b',
    A2: '#43c0ad', //neni su farby od odyzea
    A1: '#ad074c', //neni su farby od odyzea
};
const getTariffColor: (tariff?: string, dataTariff?: SkSppNzpCommonsApiCustomerconsumptionConsumptionTariff[]) => string = (tariff, data) => {
    if (!data || data.length === 1) {
        return tariffColors['undefined'];
    }
    return tariffColors[tariff || 'undefined'] || tariffColors['undefined'];
};

type Props = {
    contractId: string | undefined;
    timestampFrom?: string;
    timestampTo?: string;
    units: ConsumptionValueUnits;
    dataBarGraphOverall: Data[] | null;
    isLoadingOverall: boolean;
    isLoadingConsumptionInfo: boolean;
    deliveryPointFact: boolean;
};

const ConsumptionGraph: React.FC<Props> = ({
    contractId,
    //timestampFrom,
    //timestampTo,
    units,
    dataBarGraphOverall,
    isLoadingOverall,
    isLoadingConsumptionInfo,
    deliveryPointFact,
}) => {
    const { t } = useTranslation();
    const programsColumnRef = useRef<HTMLDivElement>(null);
    const { data: dataBarGraph, dataTariff, isLoading } = useFetchConsumption({
        callMutation: !isLoadingConsumptionInfo && deliveryPointFact,
        contractId,
        //timestampFrom,
        //timestampTo,
        units,
    });
    const [tickValues, setTickValues] = useState<Date[]>([]);
    const [tickInterval, setTickInterval] = useState<'hour' | 'day' | 'month' | 'year'>('hour');
    const [width, setWidth] = useState(window.innerWidth);
    const [isHover, setIsHover] = useState<boolean>(false);

    const { data: tariffCodeList } = useCodeList({
        queryKey: QueryKeysEnum.DELIVERY_POINT_INVOICES_SUMMARY,
        codeListTypeEnum: CodeListTypeEnum.METER_READING_REGISTER_KIND,
        paging: {
            size: 100,
        },
    });

    const updateWidth = useCallback(() => {
        if (programsColumnRef) {
            const newWidth = programsColumnRef.current?.clientWidth;
            if (newWidth) {
                setWidth(newWidth);
            }
        }
    }, [setWidth]);

    useEffect(() => {
        updateWidth();
    }, [updateWidth]);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);

        // Removes listener on unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [updateWidth]);

    const legend = useMemo<
        {
            name?: string;
            code?: string;
            symbol?: {
                fill?: string;
                type?: string;
            };
        }[]
    >(() => {
        if (dataTariff) {
            const legend = dataTariff?.reduce<
                {
                    name?: string;
                    code?: string;
                    symbol?: {
                        fill?: string;
                        type?: string;
                    };
                }[]
            >((accumulator, dataTariffSingle) => {
                const tariffIndexCode = dataTariffSingle.tariffIndex?.code || undefined;
                const findColor = getTariffColor(tariffIndexCode, dataTariff);
                if (findColor) {
                    const tariffCode = tariffCodeList?.find((code) => code.code === tariffIndexCode);

                    return accumulator.concat({
                        name: tariffCode ? tariffCode.name : t('delivery-point.detail.consumption.unknown-tariff'),
                        code: tariffIndexCode,
                        symbol: { fill: findColor, type: 'square' },
                    });
                } else {
                    return accumulator;
                }
            }, []);
            if (legend && legend.length === 1) {
                return [];
            }
            return legend;
        }
        return [];
    }, [dataTariff, tariffCodeList, t]);

    const getDomain: VictoryChartProps['domain'] = useMemo(() => {
        if (!dataBarGraph || dataBarGraph.length === 0) {
            return;
        }

        const minX = dataBarGraph.reduce((acc, currVal) => Math.min(acc, currVal.dateFrom.getTime()), new Date().getTime()) || 0;
        const maxX = dataBarGraph.reduce((acc, currVal) => Math.max(acc, currVal.dateTo.getTime()), 0) || 0;
        const maxY = dataBarGraph.reduce((prevVal, currVal) => Math.max(prevVal, currVal.value), 0);

        const minXDate = new Date(minX);

        const minXmillis = new Date(minX).getTime();
        const maxXmillis = new Date(maxX).getTime();
        const diffXMillis = maxXmillis - minXmillis;

        const oneYear = 1 * 365 * 24 * 60 * 60 * 1000;
        const oneMonth = 1 * 30 * 24 * 60 * 60 * 1000;
        const oneDay = 1 * 24 * 60 * 60 * 1000;

        const tickArray: Date[] = [];
        if (diffXMillis < oneDay) {
            setTickInterval('hour');
            let tickDate = new Date(minXDate.getFullYear(), minXDate.getMonth(), minXDate.getDate() - 1, 0, 0, 0);
            tickArray.push(tickDate);
            while (true) {
                tickDate = new Date(
                    tickDate.getFullYear(),
                    tickDate.getMonth(),
                    tickDate.getDate(),
                    tickDate.getHours() + 1,
                    tickDate.getMinutes(),
                    tickDate.getSeconds(),
                );
                tickArray.push(tickDate);
                if (tickDate.getTime() >= maxXmillis) {
                    break;
                }
            }
        }

        if (diffXMillis < oneMonth) {
            setTickInterval('day');
            let tickDate = new Date(minXDate.getFullYear(), minXDate.getMonth(), 1, 0, 0, 0);
            tickArray.push(tickDate);
            while (true) {
                tickDate = new Date(
                    tickDate.getFullYear(),
                    tickDate.getMonth() + 1,
                    tickDate.getDate(),
                    tickDate.getHours(),
                    tickDate.getMinutes(),
                    tickDate.getSeconds(),
                );
                tickArray.push(tickDate);
                if (tickDate.getTime() >= maxXmillis) {
                    break;
                }
            }
        }

        if (diffXMillis < oneYear) {
            setTickInterval('month');
            let tickDate = new Date(minXDate.getFullYear(), minXDate.getMonth(), 1, 0, 0, 0);
            tickArray.push(tickDate);
            while (true) {
                tickDate = new Date(
                    tickDate.getFullYear(),
                    tickDate.getMonth() + 1,
                    tickDate.getDate(),
                    tickDate.getHours(),
                    tickDate.getMinutes(),
                    tickDate.getSeconds(),
                );
                tickArray.push(tickDate);
                if (tickDate.getTime() >= maxXmillis) {
                    break;
                }
            }
        }

        if (tickArray.length === 0) {
            setTickInterval('year');
            let tickDate = new Date(minXDate.getFullYear(), 0, 1, 0, 0, 0);
            tickArray.push(tickDate);
            while (true) {
                tickDate = new Date(
                    tickDate.getFullYear() + 1,
                    tickDate.getMonth(),
                    tickDate.getDate(),
                    tickDate.getHours(),
                    tickDate.getMinutes(),
                    tickDate.getSeconds(),
                );
                tickArray.push(tickDate);
                if (tickDate.getTime() >= maxXmillis) {
                    break;
                }
            }
        }

        setTickValues(tickArray);

        return { x: [tickArray[0], tickArray[tickArray.length - 1]], y: maxY < 0.15 ? [0, 0.15] : undefined };
    }, [dataBarGraph]);

    //const dataToServe = dataBarGraph;
    // const dataToServe = dataBarGraph && dataBarGraph.slice(0, 1);

    //@ts-ignore
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    const graphAxisLabelUnit = useMemo<ConsumptionGraphFormattedUnits | null>(() => {
        if (dataBarGraph == null) {
            return null;
        }
        if (units === ConsumptionValueUnits.KWH) {
            const graphMaxValue = Math.max(...dataBarGraph.map((x) => x.value));
            if (graphMaxValue >= 10 ** (6 + 1)) {
                return ConsumptionGraphFormattedUnits.GWH;
            }
            if (graphMaxValue >= 10 ** (3 + 1)) {
                return ConsumptionGraphFormattedUnits.MWH;
            } else {
                return ConsumptionGraphFormattedUnits.KWH;
            }
        } else {
            return ConsumptionGraphFormattedUnits.M3;
        }
    }, [units, dataBarGraph]);

    const formatGraphAxisLabelValue = (value: number): string => {
        const convertValue = (): number => {
            if (units === ConsumptionValueUnits.KWH) {
                if (graphAxisLabelUnit === ConsumptionGraphFormattedUnits.GWH) {
                    return value / 10 ** 6;
                }
                if (graphAxisLabelUnit === ConsumptionGraphFormattedUnits.MWH) {
                    return value / 10 ** 3;
                } else {
                    return value;
                }
            } else {
                return value;
            }
        };
        const convertedValue = convertValue();

        if (convertedValue >= 10 ** 6) {
            const cutValue = convertedValue / 10 ** 6;
            return t('delivery-point.detail.consumption.short-number-format.millions', { num: cutValue.toString().replace('.', ',') });
        }
        if (convertedValue >= 10 ** 3) {
            const cutValue = convertedValue / 10 ** 3;
            return t('delivery-point.detail.consumption.short-number-format.thousands', { num: cutValue.toString().replace('.', ',') });
        }
        return `${convertedValue.toString().replace('.', ',')}`;
    };

    const CustomTooltip: React.FC<VictoryTooltipProps & { scale?: any; datum?: any }> = (props) => {
        const xmouse = props?.x || 0;
        const xwidth = (props.width || 1000) - PADDING_LEFT - PADDING_RIGHT;
        const xPos =
            xmouse < TOOLTIP_WIDTH / 2 + PADDING_LEFT
                ? PADDING_LEFT
                : xmouse > xwidth - TOOLTIP_WIDTH / 2 + 15
                ? xwidth - TOOLTIP_WIDTH + 15
                : xmouse - TOOLTIP_WIDTH / 2;

        const text = useMemo(() => {
            const activePoints = dataTariff?.reduce<{ from?: string; to?: string; value: number; code?: string; name?: string }[]>(
                (acc, currentTariff) => {
                    const findTariffValue = currentTariff.values?.filter((val) => {
                        if (!props.datum.x || (!val.period?.from && !val.period?.to)) {
                            return undefined;
                        }
                        const periodFrom = val.period?.from ? new Date(val.period.from).getTime() : 0;
                        const periodTo = val.period?.to ? new Date(val.period.to).getTime() : Number.MAX_SAFE_INTEGER;
                        const xTime = props.datum.x.getTime();
                        return xTime > periodFrom && xTime < periodTo;
                    });
                    if (findTariffValue) {
                        findTariffValue.forEach((val) =>
                            acc.push({
                                from: val.period?.from,
                                to: val.period?.to,
                                value: val.value || val.max || 0,
                                code: currentTariff.tariffIndex?.code,
                                name: currentTariff.tariffIndex?.name,
                            }),
                        );
                    }
                    return acc;
                },
                [],
            );

            if (activePoints && activePoints.length > 0) {
                const isPeriodsEquals = !activePoints.find((p) => `${p.from}-${p.to}` !== `${activePoints[0].from}-${activePoints[0].to}`);
                if (isPeriodsEquals) {
                    const fromDate = new Date(activePoints[0].from || 0);
                    const toDate = new Date(activePoints[0].to || 0);

                    return (
                        <div>
                            {activePoints.map((p, i) => (
                                <div key={'tooltip' + i}>
                                    <span style={{ color: getTariffColor(p.code, dataTariff) }}>{'\u25A0'}</span>
                                    &nbsp;
                                    <b>{formatGraphAxisLabelValue(p.value)}</b> {graphAxisLabelUnit}
                                    <br />
                                </div>
                            ))}
                            <span style={{ fontSize: '90%' }}>
                                {fromDate.getFullYear() === toDate.getFullYear()
                                    ? `${fromDate.getDate()}.${fromDate.getMonth() + 1}. - ${toDate.getDate()}.${toDate.getMonth() +
                                          1}.${toDate.getFullYear()}`
                                    : `${fromDate.getDate()}.${fromDate.getMonth() +
                                          1}.${fromDate.getFullYear()} - ${toDate.getDate()}.${toDate.getMonth() + 1}.${toDate.getFullYear()}`}
                            </span>
                        </div>
                    );
                } else {
                    return activePoints.map((p, i) => {
                        const fromDate = new Date(p.from || 0);
                        const toDate = new Date(p.to || 0);
                        return (
                            <div key={'tooltipx' + i}>
                                <div>
                                    <span style={{ color: getTariffColor(p.code, dataTariff) }}>{'\u25A0'}</span>
                                    &nbsp;
                                    <b>{formatGraphAxisLabelValue(p.value)}</b> {graphAxisLabelUnit}
                                    <br />
                                </div>
                                <span style={{ fontSize: '90%' }}>
                                    {fromDate.getFullYear() === toDate.getFullYear()
                                        ? `${fromDate.getDate()}.${fromDate.getMonth() + 1}. - ${toDate.getDate()}.${toDate.getMonth() +
                                              1}.${toDate.getFullYear()}`
                                        : `${fromDate.getDate()}.${fromDate.getMonth() +
                                              1}.${fromDate.getFullYear()} - ${toDate.getDate()}.${toDate.getMonth() + 1}.${toDate.getFullYear()}`}
                                </span>
                            </div>
                        );
                    });
                }
            }

            return <></>;
            /*
            return textList.map((f, i) => (
                <div key={'tooltipindex' + i}>
                    {i === 1 && <br />}
                    {f}
                </div>
            ));*/
        }, [props.datum.x]);

        const barYValue = props.activePoints?.reduce((sum, ac) => sum + ac.y, 0);
        const maxYValue = props.scale?.y?.domain()[1] - props.scale?.y?.domain()[0];
        const maxYPx = props.scale?.y.range()[0] - props.scale?.y.range()[1];

        return (
            <g>
                <line
                    transform={`translate(${props.x}, 0)`}
                    x1={0}
                    y1={props.scale?.y.range()[1]}
                    y2={maxYPx - (barYValue * maxYPx) / maxYValue + props.scale?.y.range()[1]}
                    stroke="#000"
                    strokeWidth={0.5}
                />
                <foreignObject x={xPos} y={PADDING_TOP} width={TOOLTIP_WIDTH} height={'100%'}>
                    <div
                        style={{
                            backgroundColor: '#FFFFFFD0',
                            color: 'black',
                            padding: 10,
                            border: 'solid',
                            borderColor: 'gray',
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                    >
                        {text}
                    </div>
                </foreignObject>
            </g>
        );
    };

    const victoryBars = useMemo(
        () =>
            dataTariff &&
            dataTariff
                .filter((dataGroup) => (dataGroup.values ? dataGroup.values.length > 0 : false))
                .map((dataGroup, index) => {
                    const color = getTariffColor(dataGroup.tariffIndex?.code, dataTariff);
                    if (dataGroup.values) {
                        return (
                            <VictoryBar
                                name={dataGroup.tariffIndex?.code || 'UNKNOWN'}
                                key={index}
                                barWidth={({ scale, data, datum }) => {
                                    if (
                                        !scale?.x ||
                                        !datum.from ||
                                        !datum.to ||
                                        !datum.x ||
                                        datum.from.getTime() === datum.x.getTime() ||
                                        datum.to.getTime() === datum.x.getTime()
                                    ) {
                                        return 0;
                                    }

                                    const domain = scale.x.domain();
                                    if (!domain || domain.length !== 2) {
                                        return 0;
                                    }

                                    const range = scale.x.range();
                                    const scaleWidth = Math.abs(range[1] - range[0]); // graph width [px]
                                    const barTimeInterval = datum.to.getTime() - datum.from.getTime(); // time interval in bar
                                    const xAxisTimeInterval = domain[1].getTime() - domain[0].getTime();

                                    return (barTimeInterval * scaleWidth) / xAxisTimeInterval - 2;
                                }}
                                data={dataGroup.values.reduce<{ x: Date; y: number; from: Date; to: Date }[]>((accumulator, value) => {
                                    const from = value?.period?.from ? new Date(value?.period?.from) : new Date();
                                    const to = value?.period?.to ? new Date(value?.period?.to) : new Date();

                                    if (from.getTime() === to.getTime()) {
                                        from.setHours(2, 0, 0, 0);
                                        to.setHours(23, 59, 59, 999);
                                    }

                                    // bug kniznice, ak 'to' minuleho bodu je rovnaky ako 'from' tohoto, tak sa nezobrazi hodnota, preto je aktualny 'to' znizeny o 10ms
                                    to.setTime(to.getTime() - 10);

                                    const middleDate = new Date((from.getTime() + to.getTime()) / 2);
                                    if (value.value !== undefined && value.value > -1 && from && to) {
                                        return accumulator.concat([
                                            {
                                                from,
                                                to,
                                                x: middleDate,
                                                y: value.value,
                                            },
                                        ]);
                                    } else return accumulator;
                                }, [])}
                                style={{
                                    data: {
                                        fill: color,
                                        opacity: (props) => (isHover ? (props.active ? 1 : 0.4) : 1),
                                    },
                                }}
                                alignment="middle"
                            />
                        );
                    }
                    return null;
                }),
        [dataTariff, isHover],
    );

    const MyVictoryLabelComponent: React.FC<VictoryLabelProps & { scale?: { x: D3Scale; y: D3Scale }; datum?: Date; ticks?: Date[] }> = (props) => {
        const getProps: () => { text?: VictoryLabelProps['text']; x?: number } | undefined = () => {
            if (props.scale && props.ticks && props.datum) {
                const maxXRange = props?.scale?.x?.range()[1];
                const diffRange = maxXRange - props?.scale?.x?.range()[0];
                const diffDomain = props?.scale?.x?.domain()[1].getTime() - props?.scale?.x?.domain()[0].getTime();
                const diffTickDomain = props?.ticks[1].getTime() - props?.ticks[0].getTime();
                const offsetRange = ((diffTickDomain / 2) * diffRange) / diffDomain;
                const xPos = (props.x || 0) + offsetRange;

                const text = xPos > maxXRange ? '' : tickInterval === 'month' ? formatDateMonthYear(props.datum) : props.text;
                return { text: text, x: xPos, y: (props.y || 5) - 5 };
            }
            return;
        };

        return <VictoryLabel {...props} {...getProps()} textAnchor="middle" verticalAnchor="middle" />;
    };

    return (
        <>
            <h2 className="w-100 overlay-graph my-4">
                <Trans i18nKey="delivery-point.detail.consumption.billing-graph" />
            </h2>
            <div
                ref={programsColumnRef}
                className="position-relative"
                id="consumptionChart"
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall && (
                    <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded overlay-graph overlay-graph-background">
                        <p className="text-primary text-center m-5">{<Trans i18nKey="delivery-point.detail.consumption.no-data-overall" />}</p>
                    </div>
                )}
                {!(dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall) &&
                    dataBarGraph &&
                    dataBarGraph.length === 0 &&
                    !isLoading && (
                        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center rounded overlay-graph overlay-graph-background">
                            <p className="text-primary text-center m-5">{<Trans i18nKey="delivery-point.detail.consumption.no-data" />}</p>
                        </div>
                    )}
                {dataBarGraph && (
                    <>
                        {legend.length > 0 && dataBarGraph.length > 0 && (
                            <div className="position-absolute w-100 overlay-graph fixed-bottom my-2">
                                <div className="footer-body d-flex align-items-center justify-content-center flex-wrap">
                                    {legend.map((color, index) => {
                                        return (
                                            <div key={index} className="d-flex align-items-center mx-2">
                                                <div className="legend-color mx-2" style={{ backgroundColor: color.symbol?.fill }}></div>
                                                <span>{color.name}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <VictoryChart
                            domainPadding={DOMAIN_PADDING}
                            domain={getDomain}
                            height={CHART_HEIGHT}
                            width={width}
                            padding={{
                                left: 55, //distance from left
                                right: 55,
                                top: 55,
                                bottom: width >= ANGLED ? 60 : 95,
                            }}
                            scale={{ x: 'time' }}
                            style={{
                                parent: { height: CHART_HEIGHT },
                            }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    mouseFollowTooltips
                                    voronoiDimension="x"
                                    //this function fix scrolling through graph on touch devices so IE doesn't need it
                                    style={
                                        isIE11
                                            ? {
                                                  width:
                                                      dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall ? '30%' : '100%',

                                                  height:
                                                      dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall
                                                          ? 'auto'
                                                          : `${CHART_HEIGHT}px`,
                                              }
                                            : {
                                                  touchAction: 'pan-x pan-y',
                                                  width:
                                                      dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall ? '30%' : '100%',
                                                  height:
                                                      dataBarGraphOverall && dataBarGraphOverall.length === 0 && !isLoadingOverall
                                                          ? 'auto'
                                                          : `${CHART_HEIGHT}px`,
                                              }
                                    }
                                    labels={({ datum }) => {
                                        if (!datum.from || !datum.to) {
                                            return '';
                                        }
                                        return `${formatGraphAxisLabelValue(datum.y)} [${graphAxisLabelUnit}], ${formatDate(
                                            datum.from,
                                        )} - ${formatDate(datum.to)}`;
                                    }}
                                    labelComponent={<CustomTooltip />}
                                />
                            }
                        >
                            <VictoryAxis
                                // X-axis
                                tickFormat={(value) => formatDateDayMonthYearTime(value)}
                                style={{
                                    grid: { stroke: 'lightgray' },
                                    ticks: { stroke: 'grey', size: 10 },
                                }}
                                tickValues={tickValues}
                                tickLabelComponent={
                                    <MyVictoryLabelComponent
                                        angle={width >= ANGLED ? 0 : 315}
                                        style={{
                                            fontSize: GRAPH_FONT_SIZE,
                                            fontFamily: fontFamily,
                                        }}
                                        dx={width >= ANGLED ? 0 : -18}
                                        dy={width >= ANGLED ? 5 : 13}
                                        textAnchor="middle"
                                        verticalAnchor="middle"
                                    />
                                }
                            />
                            <VictoryAxis
                                //  Y-axis
                                dependentAxis
                                orientation="left"
                                style={{
                                    axis: { stroke: 'black' },
                                    grid: { stroke: 'lightgray' },
                                    ticks: { stroke: 'grey' },
                                    tickLabels: { fontSize: GRAPH_FONT_SIZE, padding: 5, fontFamily: fontFamily },
                                }}
                                axisLabelComponent={<VictoryLabel angle={0} x={30} y={47} style={{ fontFamily: fontFamily }} />}
                                label={`[${graphAxisLabelUnit || '-'}]`}
                                tickFormat={formatGraphAxisLabelValue}
                            />
                            {dataTariff && dataTariff.length > 0 && <VictoryStack>{victoryBars}</VictoryStack>}
                        </VictoryChart>
                    </>
                )}
            </div>
        </>
    );
};

export default ConsumptionGraph;
