import { combineReducers } from 'redux';
import { AddDeliveryPointState } from '../actions/add-delivery-point-actions';
import { DeliveryPointsState } from '../actions/delivery-points-actions';
import AddDeliveryPointReducer from './add-delivery-point-reducer';
import { AppSettingsReducer, IAppSettingsStore } from './app-settings-reducer';
import AuthReducer, { AuthStateType } from './auth-reducer';
import CustomerRequestReducer from './customer-request-reducer';
import DeliveryPointsReducer from './delivery-points-reducer';
import EInvoiceSettingsReducer, { IEInvoiceSettingsState } from './einvoice-settings-reducer';
import HelpReducer, { IHelpState } from './help-reducer';
import { ICustomerRequestState } from './interfaces/customer-request-state';
import { IDeliveryPointDetailState } from './interfaces/delivery-point-detail-state';
import { KeyValueStoreEmployeeReducer } from './key-value-store-employee-reducer';
import { IKeyValueStore, KeyValueStoreReducer } from './key-value-store-reducer';
import NotificationSettingsReducer, { INotificationSettingsState } from './notification-settings-reducer';
import UserReducer, { IUserState } from './user-reducer';

export default combineReducers({
    addDeliveryPoint: AddDeliveryPointReducer,
    deliveryPoints: DeliveryPointsReducer,
    user: UserReducer,
    auth: AuthReducer,
    eInvoiceSettings: EInvoiceSettingsReducer,
    notificationSettings: NotificationSettingsReducer,
    customerRequest: CustomerRequestReducer,
    help: HelpReducer,
    keyValueStore: KeyValueStoreReducer,
    employeeKeyValueStore: KeyValueStoreEmployeeReducer,
    appSettings: AppSettingsReducer,
});

export interface IRootState {
    addDeliveryPoint: AddDeliveryPointState;
    deliveryPoints: DeliveryPointsState;
    deliveryPointDetail: IDeliveryPointDetailState;
    customerRequest: ICustomerRequestState;
    user: IUserState;
    auth: AuthStateType;
    eInvoiceSettings: IEInvoiceSettingsState;
    notificationSettings: INotificationSettingsState;
    help: IHelpState;
    keyValueStore: IKeyValueStore;
    employeeKeyValueStore: IKeyValueStore;
    appSettings: IAppSettingsStore;
}
