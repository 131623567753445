import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    SkSppNzpBeApiCustomerprofileInvoice,
    SkSppNzpBeApiCustomerprofileInvoiceSummary,
    SkSppNzpCommonsApiCodelistCodeListItem,
} from '@spp/spp-meru-frontend-common';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { IColumnConfiguration } from '../../components/common/table/table-interfaces';
import { InvoiceState } from '../../main';
import { formatAddressShort, formatBusinessPartnerName, formatCurrency, formatDate } from '../../utils/utils';
import { InvoiceType } from './components/invoice-type';

interface IInvoicesColumnConfigurationProps {
    invoiceTypeCodeList: SkSppNzpCommonsApiCodelistCodeListItem[] | undefined;
}

export const invoicesColumnConfigurations = (
    { invoiceTypeCodeList }: IInvoicesColumnConfigurationProps,
    payAction: (invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary) => void,
    isEmployee: boolean,
    userEmail?: string,
): IColumnConfiguration<SkSppNzpBeApiCustomerprofileInvoice>[] => [
    {
        label: <Trans i18nKey="invoices.table.column-dateOfIssue" />,
        columnId: 'INVOICE_ISSUE_AT',
        columnWidth: '220px',
        sortable: true,
        cellComponent: (invoice: SkSppNzpBeApiCustomerprofileInvoice): JSX.Element => (
            <td className="d-none d-lg-table-cell" key={`invoice_dateOfIssue_${invoice.id}`}>
                {invoice.issueAt && formatDate(new Date(invoice.issueAt))}
                <br />
                <small className="text-secondary">
                    <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />
                </small>
            </td>
        ),
    },
    {
        label: <Trans i18nKey="invoices.table.column-delivery_point" />,
        columnId: 'INVOICE_BP_NAME',
        columnWidth: '',
        sortable: true,
        cellComponent: (invoice: SkSppNzpBeApiCustomerprofileInvoice): JSX.Element => (
            <td className="d-none d-lg-table-cell text-break" key={`invoice_deliveryPoint_${invoice.id}`}>
                {invoice?.deliveryPoints &&
                    !!invoice?.deliveryPoints.length &&
                    invoice?.deliveryPoints[0].address &&
                    formatAddressShort(invoice?.deliveryPoints[0].address)}
                &nbsp;-&nbsp;
                {invoice?.deliveryPoints && !!invoice?.deliveryPoints.length && invoice?.deliveryPoints[0].type && (
                    <Trans i18nKey={`enums.CommodityEnum.${invoice?.deliveryPoints[0].type}`} />
                )}
                {invoice.sharing && invoice.sharing?.by?.email !== userEmail && (
                    <span>
                        <i id={`tooltip_dp_${invoice.id}_share-icon`} className="icon-users-16 ml-2" />
                        <UncontrolledTooltip placement="top" target={`tooltip_dp_${invoice.id}_share-icon`}>
                            <Trans i18nKey={'invoices.table.invoice-from-shared-delivery-point'} />
                        </UncontrolledTooltip>
                    </span>
                )}
                {invoice?.deliveryPoints &&
                    !!invoice?.deliveryPoints.length &&
                    invoice?.deliveryPoints.length > 1 &&
                    invoice?.deliveryPoints[1].address && (
                        <>
                            <br />
                            <div className="too-much">
                                {formatAddressShort(invoice?.deliveryPoints[1].address)}&nbsp;-&nbsp;
                                {invoice?.deliveryPoints && !!invoice?.deliveryPoints.length && invoice?.deliveryPoints[1].type && (
                                    <Trans i18nKey={`enums.CommodityEnum.${invoice?.deliveryPoints[1].type}`} />
                                )}
                            </div>
                        </>
                    )}
                <div>
                    <small className="text-secondary">{formatBusinessPartnerName(invoice?.businessPartner)}</small>
                </div>
            </td>
        ),
    },
    {
        label: <Trans i18nKey="invoices.table.column-state" />,
        columnId: 'INVOICE_STATUS',
        columnWidth: '210px',
        sortable: true,
        cellComponent: (invoice) => (
            <td className="flex-grow-1 flex-basis-0 pr-0 pr-lg-3" key={`invoice_state_${invoice.id}`}>
                {invoice.status === InvoiceState.PAID ? (
                    <p className="text-success font-sm-bold mb-0">
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        {invoice.typeGroup === 'CREDIT' ? (
                            <Trans i18nKey="invoices.detail.payment-overpaid" />
                        ) : (
                            <Trans i18nKey={`enums.InvoiceState.${invoice?.status}`} />
                        )}
                    </p>
                ) : (
                    <>
                        {invoice.unpaid != null && invoice.unpaid < 0 ? (
                            <>
                                <p className="text-success font-sm-bold mb-0 d-inline-flex align-items-center">
                                    <Trans i18nKey="invoices.detail.payment-overpaid" />
                                    <span className="ml-2" id={`invoice_state_preplatok_${invoice.id}_${invoice.dueAt}`}>
                                        <i className="icon-circle-info-16 text-black"></i>
                                        {invoice.dueAt && (
                                            <UncontrolledTooltip placement="top" target={`invoice_state_preplatok_${invoice.id}_${invoice.dueAt}`}>
                                                <Trans
                                                    i18nKey="invoices.table.invoice-overpayment-return"
                                                    values={{ date: formatDate(new Date(invoice.dueAt)) }}
                                                />
                                            </UncontrolledTooltip>
                                        )}
                                    </span>
                                </p>
                            </>
                        ) : invoice.containsPaymentPlan ? (
                            <p className="text-success font-sm-bold mb-0 d-inline-flex align-items-center">
                                <span className="mr-2">
                                    <i className="icon-circle-info-16"></i>
                                </span>
                                <Trans i18nKey="enums.InvoiceState.contains-payment-plan" />
                            </p>
                        ) : (
                            invoice.status &&
                            (invoice.status === 'INVALID' ||
                                invoice.status === 'UNPAID' ||
                                invoice.status === 'PARTIALLY_PAID' ||
                                invoice.status === 'CANCELLED') && (
                                <>
                                    <p className="text-danger font-sm-bold mb-0 d-inline-flex align-items-center">
                                        <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                        <Trans i18nKey={`enums.InvoiceState.${invoice?.status}`} />
                                    </p>
                                    {invoice?.typeGroup === 'CREDIT' && (
                                        <span style={{ color: 'black' }}>
                                            {' - '}
                                            <Trans i18nKey="invoices.table.from-spp-site" />
                                        </span>
                                    )}
                                    <p className="text-secondary small mb-0 mb-1">
                                        {invoice.dueAt && (
                                            <Trans i18nKey="invoices.table.invoice-maturity" values={{ date: formatDate(new Date(invoice.dueAt)) }} />
                                        )}
                                    </p>
                                </>
                            )
                        )}
                    </>
                )}
                <p className="text-secondary mb-0 d-block d-lg-none">
                    {invoice?.deliveryPoints &&
                        invoice?.deliveryPoints.length &&
                        invoice?.deliveryPoints[0].address &&
                        formatAddressShort(invoice?.deliveryPoints[0].address)}
                    <br />
                    {invoice?.deliveryPoints &&
                        invoice?.deliveryPoints.length &&
                        invoice?.deliveryPoints.length > 1 &&
                        invoice?.deliveryPoints[1].address && (
                            <span className="too-much d-block">{formatAddressShort(invoice?.deliveryPoints[1].address)}</span>
                        )}
                    {invoice?.issueAt && formatDate(new Date(invoice.issueAt))}
                    {invoice?.issueAt && invoice?.type && ' - '}
                    <InvoiceType invoice={invoice} invoiceTypeCodeList={invoiceTypeCodeList} />
                </p>
            </td>
        ),
    },
    {
        label: <Trans i18nKey="invoices.table.column-sum" />,
        columnId: 'INVOICE_AMOUNT',
        columnWidth: '100px',
        sortable: true,
        cellComponent: (invoice: SkSppNzpBeApiCustomerprofileInvoice): JSX.Element => (
            <td className="flex-grow-0 text-right pr-5 pr-lg-3" key={`invoice_price_${invoice.id}`}>
                {invoice?.status === InvoiceState.PAID ? (
                    <>
                        <span className="font-weight-bold">
                            {invoice.amount != null ? formatCurrency(Math.abs(invoice.amount), invoice?.typeGroup === 'CREDIT') : ''}
                        </span>
                    </>
                ) : (
                    <>
                        <span className="font-weight-bold">
                            {invoice.unpaid != null ? formatCurrency(Math.abs(invoice.unpaid), invoice?.typeGroup === 'CREDIT') : ''}
                        </span>
                    </>
                )}
                {invoice.sharing && invoice.sharing?.by?.email !== userEmail && (
                    <span className="d-block d-lg-none mt-4">
                        <i id={`tooltip_mob_${invoice.id}_share-icon`} className="icon-users-16" />
                        <UncontrolledTooltip placement="top" target={`tooltip_mob_${invoice.id}_share-icon`}>
                            <Trans i18nKey={'invoices.table.invoice-from-shared-delivery-point'} />
                        </UncontrolledTooltip>
                    </span>
                )}
            </td>
        ),
    },
    {
        label: '',
        columnId: 'INVOICE_ACTION',
        columnWidth: '136px',
        cellComponent: (invoice) => (
            <InvoiceActionCell key={'invoice_action_' + invoice.id} invoice={invoice} isEmployee={isEmployee} payAction={payAction} />
        ),
    },
];

interface IInvoiceActionCellProps {
    invoice: SkSppNzpBeApiCustomerprofileInvoice;
    payAction: (invoice: SkSppNzpBeApiCustomerprofileInvoiceSummary) => void;
    isEmployee: boolean;
}
const InvoiceActionCell: React.FC<IInvoiceActionCellProps> = ({ invoice, isEmployee, payAction }) => {
    const isPaymentButtonRendered = invoice.paymentVisibility !== 'HIDDEN';
    const isPaymentDisabled = invoice.paymentVisibility?.startsWith('DISABLED');

    const isButtonDisabled = isEmployee || isPaymentDisabled;
    const paymentButtonRef = useRef<HTMLDivElement>(null);

    return (
        <td className="flex-basis-100 text-center pt-0 pt-lg-3" key={`invoice_undefined_${invoice.id}`}>
            {isPaymentButtonRendered && (
                <>
                    <div ref={paymentButtonRef}>
                        <button
                            type="button"
                            className={classNames('btn btn-primary btn-block', { 'disabled cursor-default': isButtonDisabled })}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!isButtonDisabled) {
                                    payAction(invoice);
                                }
                            }}
                        >
                            <Trans i18nKey="invoices.table.invoice-pay-button" />
                            <span className="d-inline-block d-lg-none">&nbsp;{invoice.unpaid != null ? formatCurrency(invoice.unpaid) : ''}</span>
                        </button>
                    </div>
                    {isPaymentDisabled && (
                        <UncontrolledTooltip placement="top" target={paymentButtonRef}>
                            {(invoice.paymentVisibility === 'DISABLED_PAYMENT_INIT' ||
                                invoice.paymentVisibility === 'DISABLED_PAYMENT_OK' ||
                                invoice.paymentVisibility === 'DISABLED_REPAYMENT_PLAN') && <Trans i18nKey="invoices.table.payment-in-processing" />}
                            {invoice.paymentVisibility === 'DISABLED_COLLECION' && <Trans i18nKey="invoices.table.payment-expected-by-due-date" />}
                            {invoice.paymentVisibility === 'DISABLED_SYNC' && <Trans i18nKey="invoices.table.payment-sync" />}
                        </UncontrolledTooltip>
                    )}
                </>
            )}
        </td>
    );
};
