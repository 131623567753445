import { useCallback } from 'react';
import { base64urlencode, sha256 } from './crypto-utils';

type UsePkceType = {
    createCodeVerifier: () => string;
    createCodeChallenge: (codeVerifier: string) => Promise<string>;
};

export const usePkce = (): UsePkceType => {
    //CODE_VERIFIER
    const decimal2hex = (dec: number) => {
        return ('0' + dec.toString(16)).substr(-2);
    };

    const createCodeVerifier = useCallback(() => {
        const array = new Uint32Array(56 / 2);
        // @ts-ignore
        const crypto = window.crypto || window.msCrypto;
        crypto.getRandomValues(array);
        return Array.from(array, decimal2hex).join('');
    }, []);

    const createCodeChallenge = useCallback(async function generateCodeChallenge(codeVerifier: string) {
        const hashed = await sha256(codeVerifier);
        const base64encoded = base64urlencode(hashed);
        return base64encoded;
    }, []);

    return { createCodeVerifier, createCodeChallenge };
};
