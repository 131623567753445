import React from 'react';

type Props = {
    children: JSX.Element | JSX.Element[];
};

const StepsBlock = ({ children }: Props) => {
    return (
        <div className="row justify-content-lg-center mb-5">
            <div className="col-12 col-lg-6">{children}</div>
        </div>
    );
};

export default StepsBlock;
