import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import { can, Permission } from '../components/permissions';
import { IRootState } from '../reducers';
import { ChatHelpRoute } from './chat-help-route';
import { ErrorRoutes } from './routes';

interface IChatHelpProps extends RouteProps {
    component: React.ComponentType<any>;
    contextualHelp?: string;
    permissions?: Permission | Permission[];
}

export const ChatHelpProtectedRouter: React.FC<IChatHelpProps> = (props) => {
    const { isUserLogged, employee } = useSelector((s: IRootState) => s.user);
    const isEmployeeAsCustomer = !!useSelector((s: IRootState) => s.auth.secondaryAccessToken);
    const hasPermission = !employee || ((!props.permissions || can(props.permissions, employee)) && isEmployeeAsCustomer);

    if (isUserLogged && hasPermission) {
        return <ChatHelpRoute {...props} />;
    } else if (!isUserLogged) {
        return <Redirect to="/" />;
    } else {
        return <Redirect to={ErrorRoutes.INSUFFICIENT_PRIVILEGES} />;
    }
};
