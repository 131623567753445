/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum LogLevel {
    Debug = 0,
    Info = 1,
    Log = 2,
    Warn = 3,
    Error = 4,
}

export interface ILoggerStream {
    debug(message?: any, ...optionalParams: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    log(message?: any, ...optionalParams: any[]): void;
    warn(message?: any, ...optionalParams: any[]): void;
    error(message?: any, ...optionalParams: any[]): void;
}

let consoleStream: ILoggerStream = console;

export const setLoggerStream: (stream: ILoggerStream) => void = (stream) => {
    consoleStream = stream;
};

class Logger implements ILoggerStream {
    logLevel: LogLevel;

    private getPrefix(): string {
        return new Date().toISOString();
    }

    private getLoggerStream(): ILoggerStream {
        return consoleStream;
    }

    log(message: any, ...optionalParams: any[]) {
        this.logLevel <= LogLevel.Log && this.getLoggerStream().log(this.getPrefix(), message, ...optionalParams);
    }

    info(message?: any, ...optionalParams: any[]) {
        this.logLevel <= LogLevel.Info && this.getLoggerStream().info(this.getPrefix(), message, ...optionalParams);
    }

    warn(message?: any, ...optionalParams: any[]) {
        this.logLevel <= LogLevel.Warn && this.getLoggerStream().warn(this.getPrefix(), message, ...optionalParams);
    }

    error(message?: any, ...optionalParams: any[]) {
        this.logLevel <= LogLevel.Error && this.getLoggerStream().error(this.getPrefix(), message, ...optionalParams);
    }

    debug(message?: any, ...optionalParams: any[]) {
        this.logLevel <= LogLevel.Debug && this.getLoggerStream().debug(this.getPrefix(), message, ...optionalParams);
    }

    constructor(params: { logLevel: LogLevel }) {
        this.logLevel = params.logLevel;
    }
}

const defaultLogLevel =
    String(Number(process.env.REACT_APP_LOG_LEVEL)) == process.env.REACT_APP_LOG_LEVEL ? Number(process.env.REACT_APP_LOG_LEVEL) : LogLevel.Error;

export default new Logger({ logLevel: defaultLogLevel });
